import React from "react";
import {
  Input,
  Button,
  FormGroup,
  Badge,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  ListGroup,
  ListGroupItem,
} from "reactstrap";
// import { Link } from "react-router-dom";
import NewAgentModal from "../components/new-agent-modal";
import ReactDatetime from "react-datetime";
import {
  deepClone,
  showToast,
  formatDateAndTime,
  checkPermission,
  getDayFromDate,
  refreshFunc,
  deleteLocalStorageData,
  errorHandler,
} from "../../../helper-methods";
import { connect } from "react-redux";
import {
  getAllSigningCompanyAgents,
  signingCompanyUpdateAgentStatus,
  signingCompanyMarkFavouriteAgentById,
  getRating,
  uploadAgentCSV,
} from "../../../http/http-calls";
import { capitalize } from "@material-ui/core";
import usaStates from "../../../config/usa_states_titlecase";
import ViewRatingModal from "../components/view-rating-modal";
import { DEFAULT_PROFILE_PICTURE } from "../../../config";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomTable from "../components/CustomTable";
import StarRatings from "react-star-ratings";
import CustomCard from "../components/CustomCard";
import {
  googlePlaceDetails,
  googlePlaceSearch,
} from "../../../helper-methods/googleService";

function debounce(fn, time) {
  let timeoutId;
  return wrapper;
  function wrapper(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  }
}
class Agents extends React.Component {
  state = {
    agents: [],
    agentsTotalCount: 0,
    agentsHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      { id: "name", label: "Name", noSort: true },
      { id: "workType", label: "Type", noSort: true },
      { id: "address", label: "Location", noSort: true },
      { id: "closingCompleted", label: "Jobs Completed", noSort: true },
      { id: "totalRating", label: "Ratings", noSort: true },
      { id: "credential", label: "Credential", noSort: true },
      { id: "isActive", label: "Status", noSort: true },
    ],
    agentsCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    loading: {
      statusChangeLoading: false,
      showTableLoading: false,
      bulkFile: false,
    },
    newAgentModal: {
      isOpen: false,
      data: null,
    },
    bulkUploadResponse: {
      isOpen: false,
      data: null,
      success: null,
    },
    viewRatingModal: {
      isOpen: false,
      data: null,
      mode: null,
    },
    filters: {
      type: "",
      jobCompleted: {},
      rating: {},
      state: "",
      availability: "",
      location: "",
      fee: "",
      search: "",
    },
    tableConfig: {
      skip: 0,
      limit: 10,
      sort: {
        sortBy: "createdAt",
        sortOrder: "desc",
      },
    },
    filterDropdownValues: {
      jobCompleted: [
        { name: "All", value: { min: undefined, max: undefined } },
        { name: "0", value: { min: 0, max: 0 } },
        { name: "Less than 100", value: { min: 0, max: 100 } },
        { name: "Less than 500", value: { min: 0, max: 500 } },
        { name: "Less than 1000", value: { min: 0, max: 1000 } },
        { name: "1000+", value: { min: 1000 } },
      ],
      rating: [
        { name: "All", value: { min: undefined, max: undefined } },
        { name: "Less than 2", value: { min: 0, max: 2 } },
        { name: "Less than 3", value: { min: 0, max: 3 } },
        { name: "Less than 4", value: { min: 0, max: 4 } },
        { name: "4+", value: { min: 4 } },
      ],
      fee: [
        { name: "All", value: { min: undefined, max: undefined } },
        { name: "Less than $50", value: { min: 0, max: 50 } },
        { name: "$50 - $75", value: { min: 50, max: 75 } },
        { name: "$75 - $100", value: { min: 75, max: 100 } },
        { name: "$100 - $150", value: { min: 100, max: 150 } },
        { name: "$150+", value: { min: 150 } },
      ],
    },
    selectedFile: {},
    isClassAdded: false,
    show: null,
    searchResult: [],
  };

  _onToggleNewAgentModal = () => {
    let { newAgentModal } = deepClone(this.state);
    newAgentModal.isOpen = !newAgentModal.isOpen;
    this.setState({
      newAgentModal,
    });
  };
  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  _onToggleBulkInfoModal = () => {
    let { bulkUploadResponse } = deepClone(this.state);
    bulkUploadResponse.isOpen = !bulkUploadResponse.isOpen;
    bulkUploadResponse.data = null;
    bulkUploadResponse.success = null;
    this.setState({
      bulkUploadResponse,
      selectedFile: {},
    });
  };

  _filterOnChange = async (type, value) => {
    console.log(type, value);
    const { filters } = this.state;

    if (type === "availability")
      filters[type] = value ? new Date(value).toISOString() : "";
    else if (["jobCompleted", "rating", "fee"].includes(type))
      filters[type] = JSON.parse(value);
    else filters[type] = value;

    this.setState({ filters });

    if (type === "location") {
      if (value.trim().length > 0) {
        try {
          const res = await googlePlaceSearch(value);
          this.setState({ searchResult: res, show: type });
        } catch (err) {
          showToast("Errors");
        }
      } else {
        this.setState({ searchResult: [], show: null });
        // this._getAllAgents();
        this._paginate();
        this._persistFilter();
      }
    } else if (type === "search") {
      this.searchOnChange();
    } else {
      // this._getAllAgents();
      this._paginate();
      this._persistFilter();
    }
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          type: "",
          jobCompleted: "",
          rating: "",
          state: "",
          availability: "",
          location: "",
          fee: "",
          search: "",
        },
      },
      () => {
        this._paginate();
        refreshFunc("company-agents-responsive");
        deleteLocalStorageData("agents");
      }
    );
  };

  _redirectToAgentDetails = (data) => {
    // if (e) e.preventDefault();
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/agent-details/${data.id}`);
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  componentDidMount = () => {
    // this._getAllAgents();
    this._setFilterDataFromLocalStorage();
    HeaderEventEmitter.subscribe("reset-filter", () => {
      this._resetFilter();
      refreshFunc("company-agents");
    });
    HeaderEventEmitter.subscribe("add-agent", () => {
      this._onToggleNewAgentModal();
    });
    HeaderEventEmitter.subscribe("file-uploaded", () => {});
  };

  _getAllAgents = () => {
    const { filters, tableConfig } = this.state;
    this._manageLoading("showTableLoading", true);

    if (filters.type && filters.type.length) {
      Object.assign(tableConfig, { workType: filters.type });
    } else {
      delete tableConfig.workType;
    }

    if (
      filters.location &&
      filters.location.length
      // !isNaN(filters.location)
    ) {
      Object.assign(tableConfig, { location: filters.location });
    } else {
      delete tableConfig.location;
    }

    if (filters.search && filters.search.length) {
      Object.assign(tableConfig, { search: filters.search });
    } else {
      delete tableConfig.search;
    }

    if (filters.fee && filters.fee.length) {
      Object.assign(tableConfig, { standardFee: filters.fee });
    } else {
      delete tableConfig.standardFee;
    }

    if (filters.availability) {
      Object.assign(tableConfig, {
        availability: getDayFromDate(filters.availability),
      });
    } else {
      delete tableConfig.availability;
    }

    if (filters.state) {
      Object.assign(tableConfig, { state: filters.state });
    } else {
      delete tableConfig.state;
    }

    delete tableConfig.sort;
    // console.log(tableConfig);
    let tableConfigData = this._structureQueryParams(tableConfig);

    let params = `jobCompleted[min]=${filters.jobCompleted.min}&jobCompleted[max]=${filters.jobCompleted.max}&rating[min]=${filters.rating.min}&rating[max]=${filters.rating.max}&standardFee[min]=${filters.fee.min}&standardFee[max]=${filters.fee.max}${tableConfigData}`;

    getAllSigningCompanyAgents(params)
      .then((res) => {
        this._manageLoading("showTableLoading", false);
        this.setState({
          agents: res?.agents || [],
          agentsTotalCount: res?.totalCount || 0,
        });
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _structureQueryParams = (params) => {
    let queryStrings = "&";
    const keys = Object.keys(params);
    keys.forEach((key, index) => {
      queryStrings += key + "=" + params[key];
      if (params[keys[index + 1]]) {
        queryStrings += "&";
      }
    });
    return queryStrings;
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "workType": {
        // return cell ? cell : "N/A";

        return cell ? (
          <>
            <div style={{ width: 60 }}>{capitalize(cell)}</div>
          </>
        ) : (
          "N/A"
        );
      }
      case "name": {
        return cell ? (
          <>
            <div className="tableUserInfo">
              {row ? (
                !row.isFavourite ? (
                  <Button
                    color="link"
                    className="actionBtn"
                    disabled={!checkPermission("agents", "canUpdate")}
                    onClick={() => this._markAgentAsFavorite(row.id)}
                  >
                    <img
                      src={require("../../../assets/img/iconFav.svg").default}
                      alt="fav icon"
                    />
                  </Button>
                ) : (
                  <Button
                    color="link"
                    className="actionBtn"
                    onClick={() => this._markAgentAsFavorite(row.id)}
                  >
                    <img
                      src={
                        require("../../../assets/img/iconFavFill.svg").default
                      }
                      alt="fav icon"
                    />
                  </Button>
                )
              ) : (
                "N/A"
              )}
              <div className="userImage">
                <img
                  src={
                    row?.profilePicture
                      ? row.profilePicture
                      : DEFAULT_PROFILE_PICTURE
                  }
                  alt="Avatar"
                />
              </div>
              <div className="userContent">
                <span
                  className="signerName"
                  onClick={() => this._redirectToAgentDetails(row)}
                  disabled={!checkPermission("agents", "canUpdate")}
                >
                  {" "}
                  {capitalize(cell?.first + " " + cell?.last)}
                </span>
                <a href={`mailto:${row?.email}`}>
                  <Button
                    color="link"
                    className="actionBtn mr-2"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={row?.email}
                  >
                    <i className="fa fa-envelope-o" />
                  </Button>
                </a>
                <a href={`tel:${row?.phone}`}>
                  <Button
                    color="link"
                    className="actionBtn"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={row?.phone}
                  >
                    <i className="fa fa-phone" />
                  </Button>
                </a>
              </div>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "address": {
        return cell ? (
          cell?.city &&
          cell?.city !== "" &&
          cell?.state &&
          cell?.state !== "" ? (
            <>
              {/* {cell?.line1 ? `${cell?.line1}, ` : ""} */}
              {/* {cell?.line2 ? `${cell?.line2}, ` : ""} */}
              {cell?.city ? `${cell?.city}, ` : ""}
              {cell?.state ? `${cell?.state}, ` : ""}
              {/* {cell?.zip ? `${cell?.zip}` : ""} */}
            </>
          ) : (
            "N/A"
          )
        ) : (
          "N/A"
        );
      }
      case "closingCompleted": {
        return cell >= 0 ? (
          <>
            Total:{cell} 3mnth:
            {row?._jobLastThreeMonth ? row?._jobLastThreeMonth : 0}
          </>
        ) : (
          "N/A"
        );
      }
      // case "closingScheduled": {
      //   return cell >= 0 ? (
      //     <div className="text-underline cursorPointer">{cell}</div>
      //   ) : (
      //     "N/A"
      //   );
      // }

      // case "accreditation": {
      //   if (row.address?.state) {
      //     let stateName = usaStates.filter(
      //       (item) => item.abbreviation === row.address.state
      //     )[0].name;
      //     return stateName || "N/A";
      //   } else {
      //     return "N/A";
      //   }
      // }
      case "totalRating": {
        return (
          <>
            {row?.totalRating ? (
              // <>

              // <img src={require('../../../assets/img/ratingFill.svg').default} alt="rating"/>
              // <img src={require('../../../assets/img/ratingFill.svg').default} alt="rating"/>
              // <img src={require('../../../assets/img/ratingFill.svg').default} alt="rating"/>
              // <img src={require('../../../assets/img/ratingFill.svg').default} alt="rating"/>
              // <img src={require('../../../assets/img/rating.svg').default} alt="rating"/>
              //   <div
              //     className="text-underline cursorPointer"
              //     onClick={() =>
              //       this._onToggleViewRatingModal(row, row?.totalRating || null)
              //     }
              //   >
              //     {row?.totalRating}
              //   </div>
              // </>
              <div className="tableRating">
                <StarRatings
                  rating={row?.totalRating}
                  starRatedColor="#FED82F"
                  starHoverColor="#FED82F"
                  numberOfStars={5}
                  name="rating"
                />
              </div>
            ) : (
              "N/A"
            )}
            {/* <Button
        color="link"
        className="actionBtn cursorPointer"
        // onClick={() => this._markAgentAsFavorite(row.id)}
        onClick={() => this._onToggleAddRatingModal(row,row.totalRating||null)}
      >        
        <i className="fa fa-pencil" />
      </Button> */}
          </>
        );
      }
      case "states": {
        return cell ? row.address.state : "N/A";
      }
      // case "idVerification": {
      //   return cell ? (
      //     // add class statusPending for PENDING, statusSuccess for APPROVED, statusCancelled for CANCELLED
      //     <span
      //       className={`${
      //         cell.isVerified === "Approved" || cell.isVerified === "Completed"
      //           ? "statusSuccess"
      //           : cell.isVerified === "Pending"
      //           ? "statusPending"
      //           : "statusCancelled"
      //       }`}
      //     >
      //       {" "}
      //       {cell.isVerified}{" "}
      //     </span>
      //   ) : (
      //     "N/A"
      //   );
      // }
      // case "availability": {
      //   return cell
      //     ? Object.entries(cell)
      //         .map(([key, val]) => {
      //           let value;
      //           if (val === true) {
      //             value = key[0].toUpperCase();
      //           }
      //           return value;
      //         })
      //         .join("") || "N/A"
      //     : "N/A";
      // }

      case "credential": {
        return (
          <>
            {/* {row?.notaryLicense?.isVerified !== "Approved" &&
              row?.idVerification?.isVerified !== "Approved" &&
              row?.backgroundCheckReport?.isVerified !== "Approved" &&
              row?.eoInsurance?.isVerified !== "Approved" &&
              row?.nnaCertification?.isVerified !== "Approved" &&
              row?.lssCertification?.isVerified !== "Approved" &&
              row?.w9Credentials?.isVerified !== "Approved" &&
              "N/A"} */}
            {
              // row?.notaryLicense?.isVerified !== "Approved" &&
              row?.notaryLicense?.every(
                (item) => item?.isVerified !== "Approved"
              ) &&
                (!row?.ronCredentials ||
                  row?.ronCredentials?.every(
                    (item) => item?.isVerified !== "Approved"
                  )) &&
                row?.idVerification?.isVerified !== "Approved" &&
                row?.backgroundCheckReport?.isVerified !== "Approved" &&
                row?.eoInsurance?.isVerified !== "Approved" &&
                row?.nnaCertification?.isVerified !== "Approved" &&
                row?.titleProducerLicense?.isVerified !== "Approved" &&
                row?.mdTitleProducerLicense?.isVerified !== "Approved" &&
                row?.nyTotalCloser?.isVerified !== "Approved" &&
                row?.lssCertification?.isVerified !== "Approved" &&
                row?.w9Credentials?.isVerified !== "Approved" &&
                "N/A"
              // <span className="statusSuccess">Verified</span>
            }

            <div className="credentialWrap">
              {/* {row?.notaryLicense?.isVerified === "Approved" && (
                <Badge color="primary"> Notary </Badge>
              )} */}
              {row?.idVerification?.isVerified === "Approved" && (
                <Badge color="success" className="outline" pill>
                  {" "}
                  ID{" "}
                </Badge>
              )}
              {row?.backgroundCheckReport?.isVerified === "Approved" && (
                <Badge color="success" className="outline" pill>
                  {" "}
                  BGC{" "}
                </Badge>
              )}
              {row?.eoInsurance?.isVerified === "Approved" && (
                <Badge color="success" className="outline" pill>
                  {" "}
                  E&O{" "}
                </Badge>
              )}
              {row?.nnaCertification?.isVerified === "Approved" && (
                <Badge color="success" className="outline" pill>
                  {" "}
                  NNA{" "}
                </Badge>
              )}
              {row?.lssCertification?.isVerified === "Approved" && (
                <Badge color="success" className="outline" pill>
                  {" "}
                  {/* LSS{" "} */}
                  NTC
                </Badge>
              )}
              {row?.w9Credentials?.isVerified === "Approved" && (
                <Badge color="success" className="outline" pill>
                  {" "}
                  W9{" "}
                </Badge>
              )}
              {row?.notaryLicense?.length > 0 &&
                row?.notaryLicense?.every(
                  (item) => item?.isVerified === "Approved"
                ) && (
                  <Badge color="success" className="outline" pill>
                    {" "}
                    NL{" "}
                  </Badge>
                )}
              {row?.ronCredentials?.length > 0 &&
                row?.ronCredentials?.every(
                  (item) => item?.isVerified === "Approved"
                ) && (
                  <Badge color="success" className="outline" pill>
                    {" "}
                    RON{" "}
                  </Badge>
                )}
            </div>
          </>
        );
      }

      case "isActive": {
        return row ? (
          <>
            <div className="customSelectWrap" style={{ width: 95 }}>
              {/* <Input
                type="select"
                name="status"
                style={{ textAlign: "center" }}
                className={`status ${getDropdownColor(
                  row.isActive ? "active" : "inactive"
                )}`}
                disabled={true}
                value={row.isActive ? "active" : "inactive"}
                onChange={(event) => this._agentStatusUpdate(row)}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Input>
              <div className="downArrow">
                <i className="fa fa-chevron-down"></i>
              </div> */}
              {row?.isActive ? (
                <Badge color="success" className="outline" pill>
                  Active
                </Badge>
              ) : (
                <Badge color="warning" className="outline" pill>
                  Inactive
                </Badge>
              )}
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "action": {
        return (
          <>
            {" "}
            {row ? (
              <Button
                color="link"
                className="actionBtn"
                onClick={() => this._redirectToAgentDetails(row)}
                disabled={!checkPermission("agents", "canUpdate")}
              >
                <i className="fa fa-eye" />
              </Button>
            ) : (
              "N/A"
            )}
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    // console.log("pageNumber, pageSize :", pageNumber, pageSize);
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllAgents();
    });
  };

  // _onSortChange = (sortName, sortOrder) => {
  // console.log("sortName, sortOrder :", sortName, sortOrder);
  // this._paginate(1, 10);
  // };

  _agentStatusUpdate = (data) => {
    this._manageLoading("statusChangeLoading", true);

    // console.log(data);

    signingCompanyUpdateAgentStatus(data.id)
      .then((res) => {
        showToast("Status Updated Successfully", "success");
        // console.log(res);
        const { agents } = deepClone(this.state);
        const agent = agents.find((item) => item.id === data.id);
        agent["isActive"] = res?.agent?.isActive || false;
        this.setState({ agents });
        this._manageLoading("statusChangeLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("statusChangeLoading", false);
      });
  };

  _markAgentAsFavorite = async (id) => {
    try {
      await signingCompanyMarkFavouriteAgentById(id);
      this._getAllAgents();
      this._manageLoading("statusChangeLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("statusChangeLoading", false);
    }
  };

  _onToggleViewRatingModal = (row, previousRating) => {
    let { viewRatingModal } = JSON.parse(JSON.stringify(this.state));
    viewRatingModal.isOpen = !viewRatingModal.isOpen;
    viewRatingModal.data = {
      agentId: row?.id,
      previousRating: previousRating,
      reviews: row?._ratings,
    };
    viewRatingModal.mode = previousRating ? "edit" : "add";
    this.setState({
      viewRatingModal,
    });
  };

  _getrating = (id) => {
    const { closingDetails } = deepClone(this.state);

    if (closingDetails?._agent) {
      getRating(id)
        .then((res) => {
          this.setState({
            previousRating: res?.previousRating || null,
          });
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  };

  _persistFilter = () => {
    const { filters, tableConfig } = this.state;

    //  console.log(
    //    "sdchdchccedcdcudchedcj",
    //   filters &&
    //   (filters.status ||
    //     filters.loanType ||
    //     filters.unassigned ||
    //     filters.client ||
    //     filters.agentAssigned ||
    //     filters.attorneyAssigned ||
    //     filters.state ||
    //     filters.startDate ||
    //     filters.endDate ||
    //     filters.showDraft ||
    //     (filters.search && filters.search.length))
    //  )

    if (
      (filters &&
        (filters.type ||
          // filters.jobCompleted ||
          Object.keys(filters.rating).length !== 0 ||
          Object.keys(filters.jobCompleted).length !== 0 ||
          filters.state ||
          filters.fee ||
          filters.availability ||
          (filters.location && filters.location.length))) ||
      tableConfig.pageNumber !== 1
    ) {
      console.log("232 ", filters, tableConfig);
      let data = { filters, tableConfig };
      localStorage.agents = JSON.stringify(data);
    } else {
      delete localStorage.agents;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    // const { filters, tableConfig } = deepClone(this.state);

    if (localStorage && localStorage.agents) {
      try {
        const filters = JSON.parse(localStorage.agents);
        console.log({ filters });
        this.setState(
          { filters: filters.filters, tableConfig: filters.tableConfig },
          () => {
            // let filterData = this._prepareFilterData();
            // this._getAllSigningClosing();
            this._getAllAgents();
          }
        );
      } catch (e) {
        // let filterData = this._prepareFilterData();
        // this._getAllSigningClosing();
        this._getAllAgents();
      }
    } else {
      // let filterData = this._prepareFilterData();
      // this._getAllSigningClosing();
      this._getAllAgents();
    }
  };

  _prepareFilterData = () => {
    const { filters } = deepClone(this.state);

    if (!filters.type) delete filters.type;
    // if (!filters.jobCompleted) delete filters.jobCompleted;
    if (Object.keys(filters.rating).length === 0) delete filters.rating;
    if (Object.keys(filters.jobCompleted).length === 0)
      delete filters.jobCompleted;
    // if (!filters.rating) delete filters.rating;
    if (!filters.state) delete filters.state;
    if (!filters.fee) delete filters.fee;
    // if (!filters.location) delete filters.location;
    if (!filters.availability) delete filters.availability;
    if (!filters.location?.trim()?.length) delete filters.location;

    return filters;
  };

  _onFileSelect = (fieldName, e) => {
    // console.log(e.target.files);
    if (e.target.files?.[0]) {
      // this.setState({ [fieldName]: e.target.files?.[0] });
      this.setState(
        {
          isFormDirty: true,
          [fieldName]: {
            previewBlob: URL.createObjectURL(e.target.files[0]),
            uploadData: e.target.files[0],
          },
        },
        () => this._handleSubmit()
      );
    }
  };

  _handleSubmit = async () => {
    this._manageLoading("bulkFile", true);

    try {
      const formData = new FormData();
      formData.append("file", this.state.selectedFile?.uploadData);
      formData.append("firstName", "firstName");
      formData.append("lastName", "lastName");
      formData.append("email", "email");
      formData.append("phone", "phone");
      formData.append("workType", "workType");
      let response = await uploadAgentCSV(formData);
      this._manageLoading("bulkFile", false);
      // console.log("2222", response);
      if (typeof response.error === "string") {
        errorHandler(response.error);
      } else {
        !response.error && showToast("Uploaded Successfully", "success");
        this.setState({
          bulkUploadResponse: {
            isOpen: true,
            data: response?.reason || null,
            success: response.list || null,
          },
        });
      }
    } catch (error) {
      this._manageLoading("bulkFile", false);
      // console.log("444444", error);
      this.setState({
        bulkUploadResponse: {
          isOpen: true,
          data: error.reason,
          success: error.list || null,
        },
      });
    }
  };

  _rowClassName = (row, rowIdx) => {
    // console.log("q234", row);
    if (row?.isBlocked) {
      return "agentBlocked";
    }
    return "";
  };
  _cardHeaderFormat = (cell, row, header) => {
    // console.log("23456", row, cell, header);
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="tableUserInfo">
              {row ? (
                !row?.isFavourite ? (
                  <Button
                    color="link"
                    className="actionBtn"
                    style={{ marginLeft: 0 }}
                    disabled={!checkPermission("agents", "canUpdate")}
                    onClick={() => this._markAgentAsFavorite(row.id)}
                  >
                    <img
                      src={require("../../../assets/img/iconFav.svg").default}
                      alt="fav icon"
                    />
                  </Button>
                ) : (
                  <Button
                    color="link"
                    className="actionBtn"
                    onClick={() => this._markAgentAsFavorite(row.id)}
                  >
                    <img
                      src={
                        require("../../../assets/img/iconFavFill.svg").default
                      }
                      alt="fav icon"
                    />
                  </Button>
                )
              ) : (
                "N/A"
              )}
              <div className="userImage">
                <img
                  src={
                    row?.profilePicture
                      ? row?.profilePicture
                      : DEFAULT_PROFILE_PICTURE
                  }
                  alt="Avatar"
                />
              </div>
              <div className="userContent">
                <span
                  className="signerName"
                  onClick={() => this._redirectToAgentDetails(row)}
                  disabled={!checkPermission("agents", "canUpdate")}
                >
                  {" "}
                  {capitalize(row?.name?.first + " " + row?.name?.last)}
                </span>
              </div>
              <a href={`mailto:${row?.email}`}>
                <Button
                  color="link"
                  className="actionBtn"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={row?.email}
                >
                  <i className="fa fa-envelope-o" />
                </Button>
              </a>
              <a href={`tel:${row?.phone}`}>
                <Button
                  color="link"
                  className="actionBtn"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={row?.phone}
                >
                  <i className="fa fa-phone" />
                </Button>
              </a>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Type </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.workType ? capitalize(row?.workType) : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Location </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.address ? (
                row?.address?.city &&
                row?.address?.city !== "" &&
                row?.address?.state &&
                row?.address?.state !== "" ? (
                  <>
                    {/* {row?.address?.line1 ? `${row?.address?.line1}, ` : ""} */}
                    {/* {row?.address?.line2 ? `${row?.address?.line2}, ` : ""} */}
                    {row?.address?.city ? `${row?.address?.city}, ` : ""}
                    {row?.address?.state ? `${row?.address?.state}, ` : ""}
                    {/* {row?.address?.zip ? `${row?.address?.zip}` : ""} */}
                  </>
                ) : (
                  "N/A"
                )
              ) : (
                "N/A"
              )}
            </div>
          </div>{" "}
        </li>

        <li>
          <div className="dataWrap">
            <Label>Jobs Completed </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.closingCompleted >= 0 ? (
                <>
                  Total:{row?.closingCompleted} 3mnth:
                  {row?._jobLastThreeMonth ? row?._jobLastThreeMonth : 0}
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Ratings </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {
                <>
                  {row?.totalRating ? (
                    <div className="tableRating">
                      <StarRatings
                        rating={row?.totalRating}
                        starRatedColor="#FED82F"
                        starHoverColor="#FED82F"
                        numberOfStars={5}
                        name="rating"
                      />
                    </div>
                  ) : (
                    "N/A"
                  )}
                </>
              }
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Credential </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {
                <>
                  {/* {row?.notaryLicense?.isVerified !== "Approved" &&
              row?.idVerification?.isVerified !== "Approved" &&
              row?.backgroundCheckReport?.isVerified !== "Approved" &&
              row?.eoInsurance?.isVerified !== "Approved" &&
              row?.nnaCertification?.isVerified !== "Approved" &&
              row?.lssCertification?.isVerified !== "Approved" &&
              row?.w9Credentials?.isVerified !== "Approved" &&
              "N/A"} */}
                  {
                    // row?.notaryLicense?.isVerified !== "Approved" &&
                    row?.notaryLicense?.every(
                      (item) => item?.isVerified !== "Approved"
                    ) &&
                      (!row?.ronCredentials ||
                        row?.ronCredentials?.every(
                          (item) => item?.isVerified !== "Approved"
                        )) &&
                      row?.idVerification?.isVerified !== "Approved" &&
                      row?.backgroundCheckReport?.isVerified !== "Approved" &&
                      row?.eoInsurance?.isVerified !== "Approved" &&
                      row?.nnaCertification?.isVerified !== "Approved" &&
                      row?.titleProducerLicense?.isVerified !== "Approved" &&
                      row?.mdTitleProducerLicense?.isVerified !== "Approved" &&
                      row?.nyTotalCloser?.isVerified !== "Approved" &&
                      row?.lssCertification?.isVerified !== "Approved" &&
                      row?.w9Credentials?.isVerified !== "Approved" &&
                      "N/A"
                    // <span className="statusSuccess">Verified</span>
                  }

                  <div className="credentialWrap w-100">
                    {/* {row?.notaryLicense?.isVerified === "Approved" && (
                <Badge color="primary"> Notary </Badge>
              )} */}
                    {row?.idVerification?.isVerified === "Approved" && (
                      <Badge color="success" className="outline" pill>
                        {" "}
                        ID{" "}
                      </Badge>
                    )}
                    {row?.backgroundCheckReport?.isVerified === "Approved" && (
                      <Badge color="success" className="outline" pill>
                        {" "}
                        BGC{" "}
                      </Badge>
                    )}
                    {row?.eoInsurance?.isVerified === "Approved" && (
                      <Badge color="success" className="outline" pill>
                        {" "}
                        E&O{" "}
                      </Badge>
                    )}
                    {row?.nnaCertification?.isVerified === "Approved" && (
                      <Badge color="success" className="outline" pill>
                        {" "}
                        NNA{" "}
                      </Badge>
                    )}
                    {row?.lssCertification?.isVerified === "Approved" && (
                      <Badge color="success" className="outline" pill>
                        {" "}
                        NTC{" "}
                      </Badge>
                    )}
                    {row?.w9Credentials?.isVerified === "Approved" && (
                      <Badge color="success" className="outline" pill>
                        {" "}
                        W9{" "}
                      </Badge>
                    )}
                    {row?.notaryLicense?.length > 0 &&
                      row?.notaryLicense?.every(
                        (item) => item?.isVerified === "Approved"
                      ) && (
                        <Badge color="success" className="outline" pill>
                          {" "}
                          NL{" "}
                        </Badge>
                      )}
                    {row?.ronCredentials?.length > 0 &&
                      row?.ronCredentials?.every(
                        (item) => item?.isVerified === "Approved"
                      ) && (
                        <Badge color="success" className="outline" pill>
                          {" "}
                          RON{" "}
                        </Badge>
                      )}
                  </div>
                </>
              }
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap d-flex align-items-center justify-content-between">
            <Label>Status </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row ? (
                <>
                  <div className="customSelectWrap mr-0">
                    {/* <Input
                      type="select"
                      name="status"
                      style={{ textAlign: "center" }}
                      className={`status ${getDropdownColor(
                        row?.isActive ? "active" : "inactive"
                      )}`}
                      disabled={true}
                      value={row?.isActive ? "active" : "inactive"}
                      onChange={(event) => this._agentStatusUpdate(row)}
                    >
                      <option value="active">Active</option>
                      <option value="inactive">Inactive</option>
                    </Input>
                    <div className="downArrow">
                      <i className="fa fa-chevron-down"></i>
                    </div> */}
                    {row?.isActive ? (
                      <Badge color="success" className="outline" pill>
                        Active
                      </Badge>
                    ) : (
                      <Badge color="warning" className="outline" pill>
                        Inactive
                      </Badge>
                    )}
                  </div>
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>
      </ul>
    );
  };

  getPlaceDetail = async (pid, type) => {
    const { formFields, filters } = deepClone(this.state);
    let detail = await googlePlaceDetails(pid.place_id);
    console.log(detail);

    if (detail) {
      let state = usaStates.filter((state) => state.name === detail.state)[0]
        ?.abbreviation;
      if (detail.postal) {
        filters["location"] =
          detail?.address +
          "," +
          detail?.city +
          "," +
          state +
          "," +
          detail?.postal +
          "," +
          detail?.country;
        this.setState({ filters }, () => {
          this._getAllAgents();
          this._persistFilter();
        });
      } else {
        showToast("Invalid Address", "error");
      }

      this.setState({ formFields, show: true });
    }
  };

  searchOnChange = debounce(() => {
    this._paginate();
  }, 1000);

  render() {
    const {
      filters,
      filterDropdownValues,
      agents,
      agentsHeaderKeys,
      loading,
      agentsTotalCount,
      tableConfig,
      viewRatingModal,
      isClassAdded,
      agentsCardHeaderKeys,
      // bulkUploadResponse,
    } = this.state;
    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>Agents</h2>

            <div className="rightSide">
              <Label className="uploadLink">
                <Input
                  type="file"
                  hidden
                  accept=".csv"
                  value=""
                  disabled={loading.bulkFile}
                  onChange={(e) => this._onFileSelect("selectedFile", e)}
                />
                {loading.bulkFile ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : (
                  <>
                    <img
                      src={
                        require("../../../assets/img/uploadIconBlue.svg")
                          .default
                      }
                      className="mr-2"
                      alt="upload"
                    />
                    Upload
                  </>
                )}
              </Label>
              {/* <Link
                to={`/signingcompany/bulk-upload?companyAgent`}
                className="mr-3"
              >
                Bulk upload
              </Link> */}

              {checkPermission("agents", "canAdd") && (
                <Button
                  className="floatingButton"
                  color="primary"
                  onClick={() => this._onToggleNewAgentModal()}
                >
                  New Agent
                </Button>
              )}
              <Button color="link" onClick={this._resetFilter}>
                <img
                  id="company-agents-responsive"
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                  height={14}
                />
              </Button>

              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="Filter"
                  height={14}
                />
              </Button>
            </div>
          </div>

          {/* search filed for responsive will show below 767px */}
          <div className="mobileSearchFiled">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img
                    src={require("../../../assets/img/searchIcon.svg").default}
                    alt="searchIcon"
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="search"
                value={filters.search}
                onChange={(e) =>
                  this._filterOnChange("search", e.target.value, true)
                }
                placeholder="Search Agents"
              />
            </InputGroup>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.filterShowMobile}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer  responsiveFilter ${
              isClassAdded ? "show" : ""
            }`}
          >
            <div className="filterIcon">
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
              />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>
            <div className="filterWrapper">
              <FormGroup className="searchTable">
                <Label>Search</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <img
                        src={
                          require("../../../assets/img/searchIcon.svg").default
                        }
                        alt="searchIcon"
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="search"
                    value={filters.search}
                    onChange={
                      (e) =>
                        this._filterOnChange("search", e.target.value, true)
                      //  this.searchOnChange(e.target.value)
                    }
                    placeholder="Search Agents"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label>Type</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="type"
                    value={filters.type}
                    onChange={(e) =>
                      this._filterOnChange("type", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    <option value="Notary">Notary</option>
                    <option value="Attorney">Attorney</option>
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Jobs</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="jobCompleted"
                    value={JSON.stringify(filters.jobCompleted)}
                    onChange={(e) =>
                      this._filterOnChange("jobCompleted", e.target.value)
                    }
                  >
                    {filterDropdownValues.jobCompleted.map((job, index) => (
                      <option key={index} value={JSON.stringify(job.value)}>
                        {job.name}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Rating</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="rating"
                    value={JSON.stringify(filters.rating)}
                    onChange={(e) =>
                      this._filterOnChange("rating", e.target.value)
                    }
                    style={{ minWidth: 60 }}
                  >
                    {filterDropdownValues.rating.map((obj, index) => (
                      <option key={index} value={JSON.stringify(obj.value)}>
                        {obj.name}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Availability</Label>
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Select",
                    value: formatDateAndTime(filters.availability),
                  }}
                  onChange={(e) => this._filterOnChange("availability", e._d)}
                  timeFormat={true}
                />
              </FormGroup>
              {/* <FormGroup>
                <Label>State</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="state"
                    value={filters.state}
                    onChange={(e) =>
                      this._filterOnChange("state", e.target.value)
                    }
                    style={{ minWidth: 65 }}
                  >
                    <option value="">All</option>
                    {usaStates.map((state) => (
                      <option
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.name}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup> */}
              <FormGroup>
                <Label>Location</Label>
                <Input
                  type="text"
                  placeholder="Address"
                  value={filters.location}
                  onChange={(e) =>
                    this._filterOnChange("location", e.target.value)
                  }
                />
                {filters?.location?.trim()?.length > 0 &&
                this.state.show === "location" ? (
                  <ListGroup flush className="customSearchOptions">
                    {" "}
                    {this.state.searchResult &&
                      this.state.searchResult.map((i) => {
                        return (
                          <ListGroupItem
                            style={{ cursor: "pointer" }}
                            onClick={() => this.getPlaceDetail(i)}
                          >
                            {i.description}
                          </ListGroupItem>
                        );
                      })}
                  </ListGroup>
                ) : null}
              </FormGroup>
              <FormGroup>
                <Label>Fee</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="fee"
                    value={JSON.stringify(filters.fee)}
                    onChange={(e) =>
                      this._filterOnChange("fee", e.target.value)
                    }
                  >
                    {filterDropdownValues.fee.map((obj, index) => (
                      <option key={index} value={JSON.stringify(obj.value)}>
                        {obj.name}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
            </div>

            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>

          <div className="hideDesktop">
            <CustomCard
              isPageStartFromOne={true}
              // pageNumber={tableConfig.pageNumber}
              pageNumber={tableConfig.skip / tableConfig.limit + 1}
              tableData={agents}
              headerKeys={agentsCardHeaderKeys}
              dataFormat={this._dataFormat}
              totalCount={agentsTotalCount}
              onPaginate={(pageNumber, pageSize) =>
                this._paginate(pageNumber, pageSize)
              }
              rowSelection={false}
              showTableLoading={loading.showTableLoading}
              cardHeaderFormat={this._cardHeaderFormat}
              cardDataFormat={this._cardDataFormat}
            />
          </div>

          <div className="hideMobile">
            {agentsHeaderKeys && agentsHeaderKeys.length && (
              <CustomTable
                striped
                isPageStartFromOne={true}
                tableData={agents}
                headerKeys={agentsHeaderKeys}
                dataFormat={this._dataFormat}
                totalCount={agentsTotalCount}
                pageNumber={tableConfig.skip / tableConfig.limit + 1}
                rowSelection={false}
                onPaginate={(pageNumber, pageSize) =>
                  this._paginate(pageNumber, pageSize)
                }
                // onSortChange={(sortName, sortOrder) =>
                //   this._onSortChange(sortName, sortOrder)
                // }
                showTableLoading={loading.showTableLoading}
              />
            )}
          </div>

          <NewAgentModal
            isOpen={this.state.newAgentModal.isOpen}
            creatorData={this.state.newAgentModal.data}
            toggle={() => this._onToggleNewAgentModal()}
          ></NewAgentModal>
          {/* <BulkUploadInfo
            isOpen={bulkUploadResponse.isOpen}
            toggle={() => this._onToggleBulkInfoModal()}
            data={bulkUploadResponse.data}
            success={bulkUploadResponse.success}
            type={"Agents"}
          /> */}
          <ViewRatingModal
            isOpen={viewRatingModal.isOpen}
            data={viewRatingModal.data}
            mode={viewRatingModal.mode}
            agentId={true}
            toggle={() => this._onToggleViewRatingModal()}
            // resetDetails={() => this._getAllAgents()}
            // ratingDetail={() => this._getrating(viewRatingModal.data.id)}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

export default connect(mapStateToProps, null)(Agents);
