import React, { useEffect, useState } from "react";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { errorHandler } from "../../../../../helper-methods";
import { companyGetAssistantTeams } from "../../../../../http/http-calls";
import CustomCard from "../../CustomCard";
import CustomTable from "../../CustomTable";

const clientTeamsHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "name", label: "Team Name", noSort: true },
  { id: "teamCount", label: "No Of Team Members Added", noSort: true },
];

const clientTeamsCardHeaderKeys = [
  { id: "id", label: "id" },
  { id: "name", label: "Name" },
];

export const ClientTeams = ({ isOpen, data, toggle }) => {
  console.log({ data });

  const [clientTeams, setClientTeams] = useState({
    data: [],
    totalCount: null,
  });

  const [loading, setLoading] = useState({
    dataLoading: false,
  });
  const [tableConfig, setTableConfig] = useState({
    skip: 0,
    limit: 10,
    pageNumber: 1,
  });

  const _manageLoadingState = (key = "", value = false) => {
    setLoading((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _closeModal = () => {
    toggle();
  };

  const _getAllClientTeams = async (newTableConfig) => {
    try {
      _manageLoadingState("dataLoading", true);
      const payload = {
        clientId: data?.id,
        ...newTableConfig,
      };
      const res = await companyGetAssistantTeams(payload);
      console.log({ res });

      setClientTeams({
        data: res?.assistantTeams,
        totalCount: res?.totalCount,
      });
      _manageLoadingState("dataLoading", false);
    } catch (err) {
      errorHandler(err);
      _manageLoadingState("dataLoading", false);
    }
  };

  const _paginate = (pageNumber = 1, pageSize = 10) => {
    const newTableConfig = { ...tableConfig };

    newTableConfig["skip"] = (pageNumber - 1) * pageSize;
    newTableConfig["limit"] = pageSize;
    newTableConfig["pageNumber"] = pageNumber;

    setTableConfig(newTableConfig);
    _getAllClientTeams(newTableConfig);
  };

  useEffect(() => {
    _getAllClientTeams(tableConfig);
  }, []);

  const _dataFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return row?.teamName ? <p>{row?.teamName}</p> : "N/A";
      }

      case "teamCount": {
        return row?.teamMembers?.length ? (
          <p>{row?.teamMembers?.length}</p>
        ) : (
          "N/A"
        );
      }

      default: {
        return cell;
      }
    }
  };

  const _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return row?.teamName ? <span>{row?.teamName || "N/A"}</span> : "N/A";
      }

      default: {
        return cell;
      }
    }
  };

  const _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>No Of Team Members Added</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row?.teamMembers?.length ? (
                  <>
                    <div
                      style={{
                        minWidth: 80,
                        whiteSpace: "normal",
                      }}
                    >
                      {row?.teamMembers?.length || "N/A"}
                    </div>
                  </>
                ) : (
                  "N/A"
                )}
              </span>
            </div>
          </div>
        </li>
      </ul>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalHeader toggle={() => _closeModal()}>Client Teams</ModalHeader>
      <ModalBody>
        <div className="hideMobile">
          {clientTeamsHeaderKeys && clientTeamsHeaderKeys?.length && (
            <CustomTable
              striped
              isPageStartFromOne={true}
              pageNumber={tableConfig?.pageNumber}
              pageSize={tableConfig?.limit}
              tableData={clientTeams?.data}
              headerKeys={clientTeamsHeaderKeys}
              dataFormat={_dataFormat}
              totalCount={clientTeams?.totalCount}
              rowSelection={false}
              onPaginate={(pageNumber, pageSize) =>
                _paginate(pageNumber, pageSize)
              }
              showTableLoading={loading?.dataLoading}
            />
          )}
        </div>

        <div className="hideDesktop">
          <CustomCard
            columnSizeMD={12}
            isPageStartFromOne={true}
            pageNumber={tableConfig?.pageNumber}
            tableData={clientTeams?.data}
            dataFormat={_dataFormat}
            headerKeys={clientTeamsCardHeaderKeys}
            totalCount={clientTeams?.totalCount}
            onPaginate={(pageNumber, pageSize) =>
              _paginate(pageNumber, pageSize)
            }
            showTableLoading={loading?.dataLoading}
            cardHeaderFormat={_cardHeaderFormat}
            cardDataFormat={_cardDataFormat}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline onClick={() => _closeModal()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
