import React, { useState, useEffect } from "react";

import {
  Card,
  CardBody,
  Button,
  CustomInput,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  UncontrolledCollapse,
  CardHeader,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  capitalize,
  errorHandler,
  formatDateTimeFlexible,
  formattedStr,
  formatTime24hour,
  getDropdownColor,
  showToast,
  sortByNameInAscOrder,
} from "../../../helper-methods";
import { dayNames, FrequencyEnum } from "../../../config";
import {
  companyGetClientWiseAutomation,
  companyUpdateClientWiseAutomation,
  updateSigningCompanySetting,
} from "../../../http/http-calls";
const initialValues = {
  frequency: "1stDayOfMonth",
  day: "",
  time: "10:00",
  isActive: false,
};
// eslint-disable-next-line no-unused-vars
const initialClientValues = {
  _id: "",
  client: "",
  frequency: "1stDayOfMonth",
  day: "",
  time: "10:00",
  isActive: false,
};
let initialIsDirty = {
  frequency: false,
  day: false,
};
export default function SettingsInvoicing({
  companySettings,
  getAllCompanySettings,
}) {
  const [invoiceAutomationSetting, setInvoiceAutomationSetting] =
    useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [paymentInvoiceOverDue, setPaymentInvoiceOverDue] = useState([]);
  const [isDirty, setIsDirty] = useState(initialIsDirty);
  const [isOpenReactDatetime, setIsOpenReactDatetime] = useState(false);
  const [errors, setErrors] = useState({});

  const [clientWiseAutomationSetting, setclientWiseAutomationSetting] =
    useState([]);

  const [isDirtyClient, setIsDirtyClient] = useState([
    initialIsDirty,
    initialIsDirty,
  ]);
  // eslint-disable-next-line no-unused-vars
  const [errorsClient, setErrorsClient] = useState([]);

  const _onChangeFormFields = (key, value, index) => {
    const updatedObj = { ...invoiceAutomationSetting };
    const newIsDirty = { ...isDirty };

    updatedObj[key] = value;
    if (
      value === "1stDayOfMonth" ||
      value === "lastDayOfMonth" ||
      value === "15thOfMonth" ||
      value === "biWeekly" ||
      value === "eachOrder"
    ) {
      updatedObj["day"] = "";
    }
    if (value === "eachOrder") {
      updatedObj["time"] = "10:00";
    }
    setInvoiceAutomationSetting(updatedObj);
    setIsDirty(newIsDirty);
    _validateForm(updatedObj, newIsDirty);

    // const newArray = invoiceAutomationSetting.map((item, i) => {
    //   if (index === i) {
    //     return { ...item, [key]: value };
    //   } else {
    //     return item;
    //   }
    // });
    // setInvoiceAutomationSetting(newArray);
  };

  const _onChangeFormFieldsClientWise = (key, value, index) => {
    const updatedObj = [...clientWiseAutomationSetting];
    const newIsDirty = { ...isDirtyClient };

    updatedObj[index][key] = value;
    updatedObj[index]["isChanged"] = true;
    if (
      value === "1stDayOfMonth" ||
      value === "lastDayOfMonth" ||
      value === "15thOfMonth" ||
      value === "biWeekly" ||
      value === "eachOrder"
    ) {
      updatedObj[index]["day"] = "";
    }
    if (value === "eachOrder") {
      updatedObj[index]["time"] = "10:00";
    }

    if (key === "isActive") {
      updatedObj[index]["isActive"] = value === "active" ? true : false;
    }
    setclientWiseAutomationSetting(updatedObj);

    // const newArray = clientWiseAutomationSetting.map((item, i) => {
    //   if (index === i) {
    //     return { ...item, [key]: value , isChanged:true};
    //   } else {
    //     return item;
    //   }
    // });
    // setclientWiseAutomationSetting(newArray);
    setIsDirtyClient(newIsDirty);
    _validateFormClient(updatedObj, newIsDirty);
  };

  // const _addInvoiceAutomationSetting = () => {
  //   const newInvoiceAutomationSetting = [...invoiceAutomationSetting];

  //   newInvoiceAutomationSetting.push(initialValues);
  //   setInvoiceAutomationSetting(newInvoiceAutomationSetting);
  // };
  // const _deleteInvoiceAutomationSetting = (index) => {
  //   const newInvoiceAutomationSetting = [...invoiceAutomationSetting];

  //   newInvoiceAutomationSetting.splice(index, 1);
  //   setInvoiceAutomationSetting(newInvoiceAutomationSetting);
  // };
  const _settingInvoicing = async () => {
    const newIsDirty = { ...isDirty };
    Object.keys(isDirty).forEach((each) => (newIsDirty[each] = true));

    const error = await _validateForm(invoiceAutomationSetting, newIsDirty);

    const newIsDirtyClient = { ...isDirtyClient };
    Object.keys(isDirtyClient).forEach(
      (each) => (newIsDirtyClient[each] = true)
    );

    const errorClient = await _validateFormClient(
      clientWiseAutomationSetting,
      newIsDirtyClient
    );
    let payload = {
      invoiceAutomationSetting: {
        frequency: invoiceAutomationSetting.frequency,
        day: invoiceAutomationSetting.day.trim().length
          ? invoiceAutomationSetting.day
          : undefined,
        time:
          invoiceAutomationSetting.frequency !== "eachOrder"
            ? invoiceAutomationSetting.time
            : undefined,
        isActive: invoiceAutomationSetting.isActive,
      },
      paymentInvoiceOverDue: paymentInvoiceOverDue
        ? paymentInvoiceOverDue
        : undefined,
    };
    // let clientWisePayload = {};
    // _updateClientAutomation();
    if (error && errorClient) {
      setLoading(true);
      try {
        await updateSigningCompanySetting(payload);
        getAllCompanySettings();
        _updateClientAutomation();
        // await companyUpdateClientWiseAutomation(id,clientWisePayload)
        showToast("Settings are updated", "success");
        setLoading(false);
      } catch (error) {
        errorHandler(error);
        setLoading(false);
      }
    }
  };
  const _updateClientAutomation = () => {
    return new Promise((resolve, reject) => {
      const promises = clientWiseAutomationSetting
        .filter((item) => item?.isChanged)
        .map((element) => {
          // Make an API request and return the Promise
          if (element?.day?.trim()?.length === 0) {
            delete element?.day;
          }

          delete element?.isChanged;
          delete element?.client;
          return companyUpdateClientWiseAutomation(element?._id, element);
        });

      Promise.all(promises)
        .then((response) => {
          _companyGetClientWiseAutomation();
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  useEffect(() => {
    _companyGetClientWiseAutomation();
    if (companySettings?.invoiceAutomationSetting) {
      setInvoiceAutomationSetting({
        frequency: companySettings?.invoiceAutomationSetting?.frequency || "",
        day: companySettings?.invoiceAutomationSetting?.day || "",
        time: companySettings?.invoiceAutomationSetting?.time || "",
        isActive: companySettings?.invoiceAutomationSetting?.isActive || false,
      });
    }
    setPaymentInvoiceOverDue(companySettings.paymentInvoiceOverDue || []);
  }, [companySettings]);

  const _companyGetClientWiseAutomation = async () => {
    try {
      let response = await companyGetClientWiseAutomation();
      if (response?.automationClients?.length > 0) {
        let resArray = [];
        response?.automationClients.forEach((item, index) => {
          resArray.push({
            _id: item._id,
            client: item._client,
            frequency: item.frequency,
            day: item.day,
            time: item.time,
            isActive: item.isActive,
          });
        });
        setclientWiseAutomationSetting(resArray);
      }
      // showToast("Settings are updated", "success");
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _validateForm = (newFormFields, isDirty) => {
    return new Promise((resolve, reject) => {
      const error = { ...errors };
      let isFormValid = true;

      Object.keys(newFormFields).forEach((fieldName) => {
        switch (fieldName) {
          case "frequency": {
            if (!newFormFields[fieldName].trim().length && isDirty[fieldName]) {
              error[fieldName] = "*Required";
              isFormValid = false;
            } else {
              delete error[fieldName];
              isDirty[fieldName] = false;
            }
            break;
          }
          case "day": {
            if (
              newFormFields["frequency"] === "weekly" &&
              // ||newFormFields["frequency"] === "biWeekly" ||
              //   newFormFields["frequency"] === "monthly"
              isDirty[fieldName]
            ) {
              if (!newFormFields[fieldName].trim().length) {
                error[fieldName] = "*Required";
                isFormValid = false;
              } else {
                delete error[fieldName];
                isDirty[fieldName] = false;
              }
            } else {
              delete error[fieldName];
              isDirty[fieldName] = false;
            }
            break;
          }

          default: {
          }
        }
      });

      setIsDirty(isDirty);
      setErrors(error);
      resolve(isFormValid);
    });
  };

  const _validateFormClient = (newFormFields, isDirty) => {
    return new Promise((resolve, reject) => {
      const error = { ...errors };
      let isFormValid = true;
      newFormFields.map((item) =>
        Object.keys(item).forEach((fieldName) => {
          switch (fieldName) {
            case "frequency": {
              if (!item[fieldName]?.trim()?.length && isDirty[fieldName]) {
                error[fieldName] = "*Required";
                isFormValid = false;
              } else {
                delete error[fieldName];
                isDirty[fieldName] = false;
              }
              break;
            }
            case "day": {
              if (
                newFormFields["frequency"] === "weekly" &&
                // || newFormFields["frequency"] === "biWeekly"
                // || newFormFields["frequency"] === "monthly"
                isDirty[fieldName]
              ) {
                if (!newFormFields[fieldName]?.trim()?.length) {
                  error[fieldName] = "*Required";
                  isFormValid = false;
                } else {
                  delete error[fieldName];
                  isDirty[fieldName] = false;
                }
              } else {
                delete error[fieldName];
                isDirty[fieldName] = false;
              }
              break;
            }

            default: {
            }
          }
        })
      );

      setIsDirtyClient(isDirty);
      setErrorsClient(error);
      resolve(isFormValid);
    });
  };

  const _handleOnPaymentInvoiceChange = (value) => {
    // setPaymentInvoiceOverDue(paymentInvoiceOverDue ? "" : value);
    let paymentInvoiceOverDueArray = [...paymentInvoiceOverDue];

    if (paymentInvoiceOverDueArray.includes(value)) {
      const index = paymentInvoiceOverDueArray.indexOf(value);
      paymentInvoiceOverDueArray.splice(index, 1);
    } else {
      paymentInvoiceOverDueArray.push(value);
    }
    // return paymentInvoiceOverDueArray;
    setPaymentInvoiceOverDue(paymentInvoiceOverDueArray);
  };
  return (
    <>
      <div className="invoicingFrequency">
        <h5>Default Automated Invoicing Frequency</h5>
        <Row>
          <Col md="6" xl="6">
            <FormGroup className="floatingLabel custom-select-wrapper">
              <Input
                type="select"
                value={invoiceAutomationSetting.frequency}
                onChange={(e) =>
                  _onChangeFormFields("frequency", e.target.value)
                }
              >
                {FrequencyEnum.map((item) => (
                  <option value={item}>{formattedStr(item)}</option>
                ))}
              </Input>
              <Label>Select</Label>
            </FormGroup>
          </Col>
          <Col md="6" xl="6">
            <FormGroup className="floatingLabel custom-select-wrapper">
              <Input
                type="select"
                value={invoiceAutomationSetting.day}
                onChange={(e) => _onChangeFormFields("day", e.target.value)}
                disabled={
                  invoiceAutomationSetting.frequency === "1stDayOfMonth" ||
                  invoiceAutomationSetting.frequency === "lastDayOfMonth" ||
                  invoiceAutomationSetting.frequency === "15thOfMonth" ||
                  invoiceAutomationSetting.frequency === "biWeekly" ||
                  invoiceAutomationSetting.frequency === "eachOrder"
                }
              >
                <option value="">Select</option>
                {dayNames.map((item, index) => (
                  <option value={item.value} key={index}>
                    {capitalize(item.value)}
                  </option>
                ))}
              </Input>
              <Label>Start Day</Label>
              {errors?.day && (
                <div className="validation-error">{errors?.day}</div>
              )}
            </FormGroup>
          </Col>
          <Col md="6" xl="6">
            <FormGroup
              className={`floatingLabel ${
                invoiceAutomationSetting.time || isOpenReactDatetime
                  ? "valueAdded"
                  : ""
              }`}
            >
              <ReactDatetime
                dateFormat={false}
                inputProps={{
                  className: "form-control customTimeWrap",
                  placeholder: "Select Time",
                  value: formatDateTimeFlexible(
                    invoiceAutomationSetting.time,
                    "HH:mm",
                    "hh:mm A"
                  ),
                  disabled: invoiceAutomationSetting.frequency === "eachOrder",
                }}
                onChange={(e) =>
                  e &&
                  e > 0 &&
                  _onChangeFormFields(
                    "time",
                    // formatTime(new Date(e._d)?.toISOString())
                    formatTime24hour(new Date(e._d)?.toISOString())
                  )
                }
                timeConstraints={{ minutes: { step: 15 } }}
                timeFormat="HH:mm A"
                onOpen={() => setIsOpenReactDatetime(true)}
                onClose={() => setIsOpenReactDatetime(false)}
                // closeOnSelect={true}
              />
              <Label>Time</Label>
              {errors?.time && (
                <div className="validation-error">{errors?.time}</div>
              )}
            </FormGroup>
          </Col>
          <Col md="6" xl="6">
            <CustomInput
              type="switch"
              id="exampleCustomSwitch"
              name="customSwitch"
              label=""
              className="mt-2"
              checked={invoiceAutomationSetting.isActive}
              onChange={(e) =>
                _onChangeFormFields("isActive", e.target.checked)
              }
            />
            <Label>
              Turn on to activate default invoice automation for new clients
            </Label>
          </Col>
          {/* {invoiceAutomationSetting.length > 1 && (
                <Button
                  className="btn btn-danger"
                  disabled={loading}
                  onClick={() => _deleteInvoiceAutomationSetting()}
                >
                  Delete
                </Button>
              )} */}
        </Row>
        <div className="automationAccordian">
          <div id="toggler" className="accordianHead">
            Client wise Automation Setting
            {/* toggle this class -> "toggleIcon" on click */}
            <i className="fa fa-chevron-down" />
          </div>
          <UncontrolledCollapse toggler="toggler">
            <ul className="automationList">
              {clientWiseAutomationSetting.length > 0 ? (
                sortByNameInAscOrder(clientWiseAutomationSetting)?.map(
                  (item, index) => (
                    <li>
                      <div className="automationItem">
                        <Label>Client</Label>
                        <span>
                          {item?.client?.companyName
                            ? capitalize(item?.client?.companyName)
                            : capitalize(item?.client?.name?.full)}
                        </span>
                      </div>
                      <div className="automationItem">
                        <Label>Selected Frequency</Label>
                        <div
                          className="custom-select-wrapper"
                          style={{ width: 130 }}
                        >
                          <Input
                            type="select"
                            value={item.frequency}
                            onChange={(e) =>
                              _onChangeFormFieldsClientWise(
                                "frequency",
                                e.target.value,
                                index
                              )
                            }
                          >
                            <option>Select</option>
                            {FrequencyEnum.map((item) => (
                              <option value={item}>{formattedStr(item)}</option>
                            ))}
                          </Input>
                        </div>
                      </div>
                      <div className="automationItem">
                        <Label>Starting From</Label>
                        <div
                          className="custom-select-wrapper"
                          style={{ width: 130 }}
                        >
                          <Input
                            type="select"
                            value={item.day}
                            onChange={(e) =>
                              _onChangeFormFieldsClientWise(
                                "day",
                                e.target.value,
                                index
                              )
                            }
                            disabled={
                              item.frequency === "1stDayOfMonth" ||
                              item.frequency === "lastDayOfMonth" ||
                              item.frequency === "15thOfMonth" ||
                              item.frequency === "biWeekly" ||
                              item.frequency === "eachOrder"
                            }
                          >
                            <option>Select</option>
                            {dayNames.map((item, index) => (
                              <option value={item.value} key={index}>
                                {capitalize(item.value)}
                              </option>
                            ))}
                          </Input>
                        </div>
                        {errors?.day && (
                          <div className="validation-error">{errors?.day}</div>
                        )}
                      </div>
                      <div className="automationItem">
                        <Label>Time</Label>
                        <div
                          className="custom-select-wrapper"
                          style={{ width: 100 }}
                        >
                          <ReactDatetime
                            dateFormat={false}
                            inputProps={{
                              className: "form-control customTimeWrap",
                              placeholder: "Select Time",
                              // value: item.time,
                              value: formatDateTimeFlexible(
                                item.time,
                                "HH:mm",
                                "hh:mm A"
                              ),
                              disabled: item.frequency === "eachOrder",
                            }}
                            onChange={(e) =>
                              e &&
                              e > 0 &&
                              _onChangeFormFieldsClientWise(
                                "time",
                                formatTime24hour(new Date(e._d).toISOString()),
                                index
                              )
                            }
                            timeConstraints={{ minutes: { step: 15 } }}
                            timeFormat="HH:mm A"
                            closeOnSelect={true}
                          />
                        </div>
                        {errors?.time && (
                          <div className="validation-error">{errors?.time}</div>
                        )}
                      </div>
                      <div className="automationItem">
                        {/* <CustomInput
                        type="switch"
                        // id="exampleCustomSwitch"
                        id={`exampleCustomSwitchClient${index}`}
                        name="customSwitch"
                        label=""
                        className="ml-2"
                        checked={item.isActive}
                        onChange={(e) =>
                          _onChangeFormFieldsClientWise(
                            "isActive",
                            e.target.checked,
                            index
                          )
                        }
                      /> */}
                        <div
                          className="customSelectWrap"
                          style={{ width: 100 }}
                        >
                          <Input
                            type="select"
                            name="status"
                            className={`status ${getDropdownColor(
                              item.isActive ? "active" : "inactive"
                            )}`}
                            value={item.isActive ? "active" : "inactive"}
                            onChange={(e) =>
                              _onChangeFormFieldsClientWise(
                                "isActive",
                                e.target.value,
                                index
                              )
                            }
                          >
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                          </Input>
                          <div className="downArrow">
                            <i className="fa fa-chevron-down"></i>
                          </div>
                        </div>
                      </div>
                    </li>
                  )
                )
              ) : (
                <div className="text text-center mt-3">No Client Found!</div>
              )}
            </ul>
          </UncontrolledCollapse>
        </div>
      </div>

      <Card className="mt-4">
        <CardHeader>Payment Invoice Overdue</CardHeader>
        <CardBody>
          <div className="d-flex mb-1">
            <CustomInput
              type="checkbox"
              id="60Days"
              label="60 Days"
              className="mr-4"
              checked={paymentInvoiceOverDue.includes(60)}
              onChange={(event) => _handleOnPaymentInvoiceChange(60)}
            />
            <CustomInput
              type="checkbox"
              id="45Days"
              label="45 Days"
              className="mr-4"
              checked={paymentInvoiceOverDue.includes(45)}
              onChange={(event) => _handleOnPaymentInvoiceChange(45)}
            />
          </div>
        </CardBody>
      </Card>
      <div className="text-center">
        <Button
          color="primary"
          size="lg"
          disabled={loading}
          onClick={() => _settingInvoicing()}
        >
          Save
        </Button>
      </div>
    </>
  );
}
