/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { connect } from "react-redux";
import { Row, Col, Button, Badge, Label } from "reactstrap";
import {
  formatDate,
  formatCurrencyValue,
  formatDateHideYearIfSame,
  getLoggedInUserId,
  openUrlOnNewTab,
  errorHandler,
} from "../../../helper-methods";
import {
  billingSigningCompany,
  cancelSigningCompanySubscription,
} from "../../../http/http-calls";
import { deepClone, showToast } from "../../../helper-methods";

import { BASE_URL } from "../../../config";
import CustomTable from "../components/CustomTable";
import Swal from "sweetalert2";
import CustomCard from "../components/CustomCard";

class Billing extends React.Component {
  state = {
    billingData: [],
    billingDataTotalCount: 0,
    billingDataHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      // { id: "invoicePdf", label: "Invoice", noSort: true },
      { id: "createdAt", label: "Billing Date", noSort: true },
      { id: "totalBillingAmount", label: "Amount", noSort: true },
      { id: "plan", label: "Plan", noSort: true },
      { id: "currentSubscription", label: "Description", noSort: true },
      // { id: "receipt", label: "Receipt", noSort: true },
      { id: "downloadAction", label: " ", noSort: true },
    ],
    billingDataCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    subscription: {},
    loading: {
      showTableLoading: false,
    },
    modalloading: false,
    confirmationModal: {
      isOpen: false,
    },
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
      sort: {
        sortBy: "createdAt",
        sortOrder: "desc",
      },
    },
  };

  _dataFormat = (cell, row, header) => {
    console.log("12345 ", row);
    switch (header) {
      case "createdAt": {
        return cell ? formatDate(cell) : "N/A";
      }
      // case "invoicePdf": {
      //   return cell ? (
      //     <>
      //       <div className="invoicePdf">
      //         <img
      //           src={require("../../../assets/img/pdf.svg").default}
      //           alt="pdf"
      //         />
      //         <div className="invoiceName">Invoice #007 - Dec 2022</div>
      //         <Badge color="success" className="outline" pill>
      //           <i className="fa fa-check"></i>
      //           paid
      //         </Badge>
      //       </div>
      //     </>
      //   ) : (
      //     "N/A"
      //   );
      // }
      case "downloadAction": {
        return !cell ? (
          <div>
            <Button
              color="link"
              outline
              onClick={() => this._downloadData(row)}
            >
              Download
              <img
                src={require("../../../assets/img/download_blue.png").default}
                alt="download"
              />
            </Button>
          </div>
        ) : (
          "N/A"
        );
      }
      case "currentSubscription": {
        return cell ? (
          <>
            Payment for period{" "}
            {(cell.start && formatDateHideYearIfSame(cell.start)) || "N/A"} to{" "}
            {(cell.end && formatDateHideYearIfSame(cell.end)) || "N/A"}
          </>
        ) : (
          "N/A"
        );
      }
      // case "plan": {
      //   return cell ? "Monthly" : "Yearly";
      // }
      case "plan": {
        return row?.currentSubscription?.plan
          ? row?.currentSubscription?.plan
          : "N/A";
      }
      case "totalBillingAmount": {
        return cell && cell > 0 ? formatCurrencyValue(cell) : <>$0</>;
      }
      // case "receipt": {
      //   return row ? (
      //     <Button
      //       color="link"
      //       className="actionBtn"
      //       onClick={() => this._downloadData(row)}
      //     >
      //       <i className="fa fa-download" />
      //     </Button>
      //   ) : (
      //     "N/A"
      //   );
      // }
      default: {
        return cell;
      }
    }
  };

  componentDidMount() {
    this._signingCompanyBilling();
  }

  _signingCompanyBilling() {
    const { tableConfig } = deepClone(this.state);

    this._manageLoading("showTableLoading", true);

    let data = {
      skip: tableConfig.skip,
      limit: tableConfig.limit,
    };

    billingSigningCompany(data)
      .then((res) => {
        // console.log(res);
        this.setState({
          subscription: res?.subscription || {},
          billingData: res?.billings || [],
          billingDataTotalCount: res?.totalCount || null,
        });
        this._manageLoading("showTableLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showDataLoading", false);
      });
  }
  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _cancelSubscription = () => {
    this.setState({ modalloading: true });
    // console.log(confirmationModal.data);

    cancelSigningCompanySubscription()
      .then((res) => {
        // console.log(res);
        showToast("Cancelled Successfully", "success");
        // this._onToggleSubscriptionConfirmationModal();
        // this.setState({ modalloading: false });
        this._signingCompanyBilling();
      })
      .catch((error) => {
        errorHandler(error);
        // this._onToggleSubscriptionConfirmationModal();
        // this.setState({ modalloading: false });
      });
  };

  _onToggleSubscriptionConfirmationModal = (data, type) => {
    // let { confirmationModal } = deepClone(this.state);
    // confirmationModal.isOpen = !confirmationModal.isOpen;
    // this.setState({
    //   confirmationModal,
    // });

    Swal.fire({
      title: "Confirm",
      text: `Do you want to Cancel this Plan?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirm",
    }).then((result) => {
      if (result.isConfirmed) {
        // _deleteDocument(document._id);
        this._cancelSubscription();
      }
    });
  };

  _paginate = (pageNumber, pageSize) => {
    console.log("pageNumber, pageSize :", pageNumber, pageSize);
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    this.setState({ tableConfig }, () => {
      this._signingCompanyBilling();
    });
  };

  _onSortChange = (sortName, sortOrder) => {
    // console.log("sortName, sortOrder :", sortName, sortOrder);
    this._paginate(1, 10);
  };

  _downloadData = (data) => {
    const apiUrl = `${BASE_URL}/signingcompany/download/subscription-invoice?userId=${getLoggedInUserId()}&billingId=${
      data.id
    }`;
    openUrlOnNewTab(apiUrl);
  };

  // _cardHeaderFormat = (cell, row, header) => {
  //   console.log("23456", row, cell, header);
  //   switch (header) {
  //     case "name": {
  //       return row ? (
  //         <>
  //           <div className="invoicePdf">
  //             <Badge color="success" className="outline" pill>
  //               <i className="fa fa-check"></i>
  //               paid
  //             </Badge>
  //           </div>

  //           <div className="dataWrap text-center">
  //             <Button
  //               color="link"
  //               outline
  //               onClick={() => this._downloadData(row)}
  //             >
  //               Download
  //               <a href={row?.invoicePdf} target="_blank">
  //                 <img
  //                   src={
  //                     require("../../../assets/img/download_blue.png").default
  //                   }
  //                   alt="download"
  //                 />
  //               </a>
  //             </Button>
  //           </div>
  //         </>
  //       ) : (
  //         "N/A"
  //       );
  //     }
  //     default: {
  //       return cell;
  //     }
  //   }
  // };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Billing Date </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.createdAt
                ? formatDateHideYearIfSame(row?.createdAt)
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Amount </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.totalBillingAmount || row?.totalBillingAmount === 0
                ? formatCurrencyValue(row?.totalBillingAmount)
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Plan </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.currentSubscription?.plan
                ? row?.currentSubscription?.plan
                : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Description </Label>

            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.currentSubscription ? (
                <>
                  Payment for period{" "}
                  {(row?.currentSubscription?.start &&
                    formatDateHideYearIfSame(
                      row?.currentSubscription?.start
                    )) ||
                    "N/A"}{" "}
                  to{" "}
                  {(row?.currentSubscription?.end &&
                    formatDateHideYearIfSame(row?.currentSubscription?.end)) ||
                    "N/A"}
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap text-center">
            <Badge color="success" className="outline mr-3" pill>
              <i className="fa fa-check mr-1"></i>
              paid
            </Badge>

            <Button
              color="link"
              outline
              onClick={() => this._downloadData(row)}
            >
              Download
              <a href={row?.invoicePdf} target="_blank">
                <img
                  src={require("../../../assets/img/download_blue.png").default}
                  alt="download"
                />
              </a>
            </Button>
          </div>
        </li>
      </ul>
    );
  };

  render() {
    const {
      billingDataHeaderKeys,
      billingDataTotalCount,
      billingData,
      loading,
      subscription,
      confirmationModal,
      modalloading,
      tableConfig,
      billingDataCardHeaderKeys,
    } = this.state;
    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>My Billing</h2>
            <div className="rightSide">
              {subscription !== null || subscription?.isExpired === true ? (
                <div>
                  <div>
                    Next Billing On:{" "}
                    <span style={{ fontWeight: 600 }}>
                      {formatDate(subscription?.end)}
                    </span>
                  </div>

                  {subscription.isAuto && (
                    <Button
                      color="primary"
                      size="md"
                      className="floatingButton"
                      onClick={() =>
                        this._onToggleSubscriptionConfirmationModal()
                      }
                    >
                      CANCEL SUBSCRIPTION
                    </Button>
                  )}
                </div>
              ) : null}
            </div>
          </div>

          <div className="d-none d-lg-block">
            {subscription !== null || subscription?.isExpired === true ? (
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ marginBottom: 15 }}
              >
                <div>
                  Next Billing On:{" "}
                  <span style={{ fontWeight: 600 }}>
                    {formatDate(subscription?.end)}
                  </span>
                </div>

                {subscription.isAuto && (
                  <Button
                    color="primary"
                    size="md"
                    onClick={() =>
                      this._onToggleSubscriptionConfirmationModal()
                    }
                  >
                    CANCEL SUBSCRIPTION
                  </Button>
                )}
              </div>
            ) : null}
          </div>

          <Row>
            <Col md="12">
              <div className="hideDesktop">
                <CustomCard
                  isPageStartFromOne={true}
                  pageNumber={tableConfig.pageNumber}
                  tableData={billingData}
                  // headerKeys={billingDataCardHeaderKeys}
                  // bodyKeys={agentClosingCardBodyKeys}
                  dataFormat={this._dataFormat}
                  totalCount={billingDataTotalCount}
                  onPaginate={(pageNumber, pageSize) =>
                    this._paginate(pageNumber, pageSize)
                  }
                  rowSelection={false}
                  // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
                  showTableLoading={loading.showTableLoading}
                  cardHeaderFormat={this._cardHeaderFormat}
                  cardDataFormat={this._cardDataFormat}
                />
              </div>

              <div className="hideMobile">
                {billingDataHeaderKeys && billingDataHeaderKeys.length && (
                  <CustomTable
                    striped
                    isPageStartFromOne={true}
                    tableData={billingData}
                    headerKeys={billingDataHeaderKeys}
                    dataFormat={this._dataFormat}
                    totalCount={billingDataTotalCount}
                    pageNumber={tableConfig.skip / tableConfig.limit + 1}
                    rowSelection={false}
                    onPaginate={(pageNumber, pageSize) =>
                      this._paginate(pageNumber, pageSize)
                    }
                    onSortChange={(sortName, sortOrder) =>
                      this._onSortChange(sortName, sortOrder)
                    }
                    showTableLoading={loading.showTableLoading}
                  />
                )}
              </div>
            </Col>
          </Row>
          {/* <Modal
            isOpen={confirmationModal.isOpen}
            toggle={() => this._onToggleSubscriptionConfirmationModal()}
            className="modal-dialog-centered"
          >
            <ModalHeader
              toggle={() => this._onToggleSubscriptionConfirmationModal()}
            >
              Confirm
            </ModalHeader>
            <ModalBody>
              <h3>Do you want to Cancel this Plan?</h3>
            </ModalBody>
            <ModalFooter className="d-flex justify-content-between align-items-between">
              <div>
                <Button
                  className="modalBtnCancel"
                  onClick={() => this._onToggleSubscriptionConfirmationModal()}
                >
                  Cancel
                </Button>
                <Button
                  className="modalBtnSave success"
                  onClick={() => this._cancelSubscription()}
                  disabled={modalloading}
                >
                  {modalloading ? (
                    <i className="fa fa-spinner fa-spin mr-2" />
                  ) : null}{" "}
                  Confirm
                </Button>
              </div>
            </ModalFooter>
          </Modal> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

export default connect(mapStateToProps, null)(Billing);
