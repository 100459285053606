import React from "react";
import { Row, Col, Button, Alert } from "reactstrap";
// import Switch from "react-bootstrap-switch";
import BuySubscriptionModal from "../components/buy-subscription-modal";
import {
  getSigningCompanySubscription,
  getSettingDetail,
} from "../../../http/http-calls";
import {
  deepClone,
  formatCurrencyValue,
  errorHandler,
} from "../../../helper-methods";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { clearUserData, updateUserObj } from "../../../redux/actions/user-data";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import ShowVideoModal from "../components/ShowVideoModal";
import TitleCompanySubscription from "../components/titleCompanySubscription";

class MySubscription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      buySubscriptionModal: {
        isOpen: false,
        data: null,
        subscriptionPlan: null,
      },
      subscription: null,
      billingCycle: {
        Basic: false,
        Premium: false,
      },
      billingCycleBackup: {
        Basic: false,
        Premium: false,
      },
      loading: {
        showDataLoading: false,
      },
      freePlan: null,
      basicPlan: null,
      premiumPlan: null,
      titleSettings: null,
      companySettings: null,
      isOpen: false,
      role: "",
    };
  }

  _onToggleBuySubscriptionModal = (data, type) => {
    let { buySubscriptionModal, freePlan, basicPlan, premiumPlan } = deepClone(
      this.state
    );
    buySubscriptionModal.isOpen = !buySubscriptionModal.isOpen;
    buySubscriptionModal.data = { plan: data, type: type };
    buySubscriptionModal.subscriptionPlan = {
      freePlan,
      basicPlan,
      premiumPlan,
    };
    this.setState({
      buySubscriptionModal,
    });
  };

  componentDidMount = () => {
    const { userData } = this.props;
    this._companySubscriptionDetail();
    this._getSettingDetail();
    HeaderEventEmitter.subscribe("billing", () => {
      userData.user.isCardAdded && this._goToBillingPage();
    });

    if (!userData.user?.isWelcomeVideo) {
      this.setState({
        isOpen: true,
        role: userData.type,
      });
    }
  };

  _goToBillingPage = () => {
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/billing`);
  };

  redirectToCompanyProfile = () => {
    let data = {
      isCardAdded: true,
    };
    this.props.updateUserObj(data);
    this.props.history.push("/signingcompany/profile");
  };

  _getSettingDetail = () => {
    this.props.showLoader("Fetching Details...");
    getSettingDetail()
      .then((res) => {
        // console.log(res);

        let freePlan = res?.setting?.subscriptionPlan?.find(
          (item) => item.plan === "Free"
        );
        let basicPlan = res?.setting?.subscriptionPlan?.find(
          (item) => item.plan === "Basic"
        );
        let premiumPlan = res?.setting?.subscriptionPlan?.find(
          (item) => item.plan === "Premium"
        );

        this.setState({
          freePlan,
          basicPlan,
          premiumPlan,
          titleSettings: res?.setting?.titleCompanySubscriptionPlan,
          companySettings: res?.setting?.subscriptionPlan,
        });
        this.props.hideLoader();
      })
      .catch((error) => {
        errorHandler(error);
        this.props.hideLoader();
      });
  };

  _companySubscriptionDetail = () => {
    this.props.showLoader("Fetching Details...");
    getSigningCompanySubscription()
      .then((res) => {
        // console.log(res);
        if (res?.subscription) {
          this.setState({
            subscription: res?.subscription,
            billingCycle: {
              ...this.state.billingCycle,
              [res?.subscription?.plan]:
                res?.subscription?.billingCycle === 365 ? true : false,
            },
            billingCycleBackup: {
              ...this.state.billingCycle,
              [res.subscription.plan]:
                res?.subscription?.billingCycle === 365 ? true : false,
            },
          });
        }
        this.props.updateUserObj({ _subscription: res?.subscription });
        this.props.hideLoader();
      })
      .catch((error) => {
        errorHandler(error);
        this.props.hideLoader();
      });
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  handleSwitch = (el, state) => {
    this.setState({
      billingCycle: { ...this.state.billingCycle, [el.props.name]: state },
    });
  };

  render() {
    const {
      subscription,
      billingCycle,
      billingCycleBackup,
      // freePlan,
      // basicPlan,
      premiumPlan,
      titleSettings,
      companySettings,
      isOpen,
      role,
    } = deepClone(this.state);

    const { userData } = this.props;
    // console.log(this.state, this.props);
    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>My Subscription</h2>

            <div className="rightSide">
              <Button
                className="floatingButton"
                color="primary"
                onClick={() => {
                  this.props.history.push(`/${userData.type}/billing`);
                }}
              >
                Billing
              </Button>
            </div>
          </div>
          {
            //  userData.user.isTitleCompany
            false ? (
              <TitleCompanySubscription
                subscription={subscription}
                subscriptionsettings={titleSettings}
                onToggleBuySubscriptionModal={
                  this._onToggleBuySubscriptionModal
                }
              />
            ) : (
              <Row className="justify-content-center justify-content-xl-start">
                {!userData.user.isCardAdded ? (
                  <Col md="12">
                    <Alert color="danger" className="mb-4">
                      <i className="fa fa-exclamation-triangle" /> Please Select
                      any Plan
                    </Alert>
                  </Col>
                ) : null}

                <Col md={6} lg={6} xl={4} className="mb-4 mb-xl-0">
                  <div className="subscriptionPlanCard">
                    <h1 className="subscriptionTitle">
                      <span>Pay As You Grow</span>
                      {subscription?.plan === "Free" ? (
                        <div className="currentPlan">Current Plan</div>
                      ) : null}
                    </h1>
                    <div className="priceWrap">
                      <span className="price">$20</span>{" "}
                      <span className="subMonth">per month</span>
                    </div>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        marginBottom: 0,
                        marginTop: "20px",
                      }}
                    >
                      2$ per Closing
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        marginBottom: 10,
                        marginTop: "2px",
                      }}
                    >
                      {/* (10 closings included with monthly fee) */}
                    </p>
                    <div className="subscriptionContent text-center">
                      {subscription !== null ? (
                        subscription?.isExpired ? (
                          <Button
                            color="primary"
                            outline
                            onClick={() =>
                              this._onToggleBuySubscriptionModal(
                                "Free",
                                "renew"
                              )
                            }
                          >
                            Renew
                          </Button>
                        ) : subscription?.plan === "Basic" ||
                          subscription?.plan === "Premium" ? (
                          <Button
                            color="primary"
                            outline
                            onClick={() =>
                              this._onToggleBuySubscriptionModal(
                                "Free",
                                "downgrade"
                              )
                            }
                          >
                            Downgrade
                          </Button>
                        ) : null
                      ) : (
                        <Button
                          color="primary"
                          onClick={() =>
                            this._onToggleBuySubscriptionModal("Free", "buy")
                          }
                        >
                          Buy
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>

                <Col md={6} lg={6} xl={4} className="mb-4 mb-xl-0">
                  <div className="subscriptionPlanCard">
                    <h1 className="subscriptionTitle">
                      <span>Professional</span>
                      {subscription?.plan === "Basic" ? (
                        <div className="currentPlan">Current Plan</div>
                      ) : null}
                    </h1>
                    <div className="priceWrap">
                      <span className="price">$50</span>{" "}
                      <span className="subMonth">per month</span>
                    </div>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        marginBottom: 0,
                        marginTop: "20px",
                      }}
                    >
                      $1.75 per closing
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        marginBottom: 10,
                        marginTop: "2px",
                      }}
                    >
                      {/* (30 closings included with monthly fee) */}
                    </p>
                    <div className="subscriptionContent text-center">
                      {subscription !== null ? (
                        subscription?.isExpired ? (
                          <Button
                            color="primary"
                            outline
                            onClick={() =>
                              this._onToggleBuySubscriptionModal(
                                "Basic",
                                "renew"
                              )
                            }
                          >
                            Renew
                          </Button>
                        ) : subscription?.plan === "Premium" ? (
                          <Button
                            color="primary"
                            outline
                            onClick={() =>
                              this._onToggleBuySubscriptionModal(
                                "Basic",
                                "downgrade"
                              )
                            }
                          >
                            Downgrade
                          </Button>
                        ) : subscription?.plan !== "Basic" ||
                          billingCycle.Basic !== billingCycleBackup.Basic ? (
                          <Button
                            color="primary"
                            onClick={() =>
                              this._onToggleBuySubscriptionModal(
                                "Basic",
                                "upgrade"
                              )
                            }
                          >
                            Upgrade
                          </Button>
                        ) : null
                      ) : (
                        <Button
                          color="primary"
                          onClick={() =>
                            this._onToggleBuySubscriptionModal("Basic", "buy")
                          }
                        >
                          Buy
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>

                <Col md={6} lg={6} xl={4}>
                  <div className="subscriptionPlanCard">
                    <h1 className="subscriptionTitle">
                      <span>Advanced</span>
                      {subscription?.plan === "Premium" ? (
                        <div className="currentPlan">Current Plan</div>
                      ) : null}
                    </h1>
                    <div className="priceWrap">
                      <span className="price">
                        {formatCurrencyValue(premiumPlan?.amount)}
                      </span>{" "}
                      <span className="subMonth">per month</span>
                    </div>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        marginBottom: 0,
                        marginTop: "20px",
                      }}
                    >
                      $1.50 per closing
                    </p>
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: 600,
                        marginBottom: 10,
                        marginTop: "2px",
                      }}
                    >
                      {/* (70 closings included with monthly fee) */}
                    </p>
                    <div className="subscriptionContent text-center">
                      {subscription !== null ? (
                        subscription?.isExpired ? (
                          <Button
                            color="primary"
                            onClick={() =>
                              this._onToggleBuySubscriptionModal(
                                "Premium",
                                "renew"
                              )
                            }
                          >
                            Renew
                          </Button>
                        ) : subscription?.plan !== "Premium" ||
                          billingCycle.Premium !==
                            billingCycleBackup.Premium ? (
                          <Button
                            color="primary"
                            onClick={() =>
                              this._onToggleBuySubscriptionModal(
                                "Premium",
                                "upgrade"
                              )
                            }
                          >
                            Upgrade
                          </Button>
                        ) : null
                      ) : (
                        <Button
                          color="primary"
                          onClick={() =>
                            this._onToggleBuySubscriptionModal("Premium", "buy")
                          }
                        >
                          Buy
                        </Button>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            )
          }

          <BuySubscriptionModal
            isOpen={this.state.buySubscriptionModal.isOpen}
            data={this.state.buySubscriptionModal.data}
            toggle={() => this._onToggleBuySubscriptionModal()}
            billingCycle={this.state.billingCycle}
            reload={() => {
              this._companySubscriptionDetail();
              this._getSettingDetail();
            }}
            subscriptionPlan={this.state.buySubscriptionModal.subscriptionPlan}
            redirect={() => {
              this.redirectToCompanyProfile();
            }}
            isTitleCompany={userData.user.isTitleCompany}
            companySubscriptionPlan={companySettings}
            titleCompanySubsPlan={titleSettings}
          ></BuySubscriptionModal>
          {/* {isOpen && (
            <ShowVideoModal
              isOpen={isOpen}
              role={role}
              toggle={() => this.setState({ isOpen: false })}
              userData={userData}
            />
          )} */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
    clearUserData: () => dispatch(clearUserData()),
    updateUserObj: (payload) => dispatch(updateUserObj(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MySubscription);
