import React from "react";
import { CustomInput, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import {
  updateSigningCompanySetting,
  getAllCompanySettings,
} from "../../../http/http-calls";
import { showToast, errorHandler } from "../../../helper-methods";

class Settings extends React.Component {
  state = {
    loading: true,
    isEmailActive: false,
    isPushActive: false,
    emailClosingStatus: false,
    agentInvoice: false,
    bidRevieved: false,
    emailDocumentsUpload: false,
    newBid: false,
    pushClosingStatus: false,
    pushDocumentsUpload: false,
    id: "",
  };

  _goBack = () => {
    this.props.history.goBack();
  };

  componentDidMount() {
    this._getSigningCompanyDetail();
  }

  _getSigningCompanyDetail = () => {
    return new Promise((resolve, reject) => {
      // signingCompanyDetail()
      getAllCompanySettings()
        .then((res) => {
          let emailClosingStatus =
            res.companySetting.notificationSettings.email.closingStatus;
          let agentInvoice =
            res.companySetting.notificationSettings.email.agentInvoice;
          let bidRevieved =
            res.companySetting.notificationSettings.email.bidRevieved;
          let emailDocumentsUpload =
            res.companySetting.notificationSettings.email.documentsUpload;

          let pushClosingStatus =
            res.companySetting.notificationSettings.push.closingStatus;
          let pushDocumentsUpload =
            res.companySetting.notificationSettings.push.documentsUpload;
          let newBid = res.companySetting.notificationSettings.push.newBid;

          let id = res.companySetting._id;

          let isEmailActive =
            emailClosingStatus &&
            agentInvoice &&
            bidRevieved &&
            emailDocumentsUpload;
          let isPushActive = pushClosingStatus && pushDocumentsUpload && newBid;

          this.setState({
            emailClosingStatus,
            agentInvoice,
            bidRevieved,
            emailDocumentsUpload,
            pushClosingStatus,
            pushDocumentsUpload,
            newBid,
            isEmailActive,
            isPushActive,
            id,
          });
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _handleClick = (fieldName, value) => {
    if (fieldName === "isEmailActive") {
      this.setState(
        {
          isEmailActive: value,
          emailClosingStatus: value,
          agentInvoice: value,
          bidRevieved: value,
          emailDocumentsUpload: value,
        },
        () => this._updateProfile()
      );
    } else if (fieldName === "isPushActive") {
      this.setState(
        {
          isPushActive: value,
          newBid: value,
          pushClosingStatus: value,
          pushDocumentsUpload: value,
        },
        () => this._updateProfile()
      );
    } else {
      this.setState({ [fieldName]: value }, () => {
        this._updateProfile();
      });
    }
  };

  _updateProfile = () => {
    let data = {
      notificationSettings: {
        email: {
          closingStatus: this.state.emailClosingStatus,
          agentInvoice: this.state.agentInvoice,
          bidRevieved: this.state.bidRevieved,
          documentsUpload: this.state.emailDocumentsUpload,
        },
        push: {
          closingStatus: this.state.pushClosingStatus,
          documentsUpload: this.state.pushDocumentsUpload,
          newBid: this.state.newBid,
        },
      },
    };

    this._updateSigningCompanyProfile(this.state.id, data);
  };

  _updateSigningCompanyProfile = (id, data) => {
    updateSigningCompanySetting(data)
      .then(async (res) => {
        showToast("Settings Updated", "success");
        this._getSigningCompanyDetail();
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  render() {
    return (
      <>
        <Card className="settingCard">
          <CardHeader>
            <div>
              <CardTitle>Email Notifications</CardTitle>
              <p>Receive E-mails About</p>
            </div>
            <CustomInput
              type="switch"
              id="email"
              name="customSwitch"
              label=""
              key=""
              checked={this.state.isEmailActive}
              onClick={() =>
                this._handleClick("isEmailActive", !this.state.isEmailActive)
              }
            />
          </CardHeader>
          <CardBody>
            <ul className="profitLossReport">
              <li>
                Order Status Updated
                <CustomInput
                  type="switch"
                  id="2"
                  name="customSwitch5"
                  label=""
                  checked={this.state.emailClosingStatus}
                  onClick={() =>
                    this._handleClick(
                      "emailClosingStatus",
                      !this.state.emailClosingStatus
                    )
                  }
                />
              </li>
              <li>
                Invoice
                <CustomInput
                  type="switch"
                  id="3"
                  name="customSwitch5"
                  label=""
                  checked={this.state.agentInvoice}
                  onClick={() =>
                    this._handleClick("agentInvoice", !this.state.agentInvoice)
                  }
                />
              </li>

              <li>
                Bid Received
                <CustomInput
                  type="switch"
                  id="4"
                  name="customSwitch5"
                  label=""
                  checked={this.state.bidRevieved}
                  onClick={() =>
                    this._handleClick("bidRevieved", !this.state.bidRevieved)
                  }
                />
              </li>

              <li>
                Documents Uploaded
                <CustomInput
                  type="switch"
                  id="5"
                  name="customSwitch5"
                  label=""
                  checked={this.state.emailDocumentsUpload}
                  onClick={() =>
                    this._handleClick(
                      "emailDocumentsUpload",
                      !this.state.emailDocumentsUpload
                    )
                  }
                />
              </li>
            </ul>
          </CardBody>
        </Card>

        <Card className="settingCard">
          <CardHeader>
            <div>
              <CardTitle>Push Notifications</CardTitle>
              <p>Receive Website Alerts About</p>
            </div>
            <CustomInput
              type="switch"
              id="push"
              name="customSwitch"
              label=""
              key=""
              checked={this.state.isPushActive}
              onClick={() =>
                this._handleClick("isPushActive", !this.state.isPushActive)
              }
            />
          </CardHeader>
          <CardBody>
            <ul className="profitLossReport">
              <li>
                Order Status Updated
                <CustomInput
                  type="switch"
                  id="6"
                  name="customSwitch5"
                  label=""
                  checked={this.state.pushClosingStatus}
                  onClick={() =>
                    this._handleClick(
                      "pushClosingStatus",
                      !this.state.pushClosingStatus
                    )
                  }
                />
              </li>
              <li>
                Signed Documents Uploaded
                <CustomInput
                  type="switch"
                  id="7"
                  name="customSwitch5"
                  label=""
                  checked={this.state.pushDocumentsUpload}
                  onClick={() =>
                    this._handleClick(
                      "pushDocumentsUpload",
                      !this.state.pushDocumentsUpload
                    )
                  }
                />
              </li>
              <li>
                New Bid Received
                <CustomInput
                  type="switch"
                  id="8"
                  name="customSwitch5"
                  label=""
                  checked={this.state.newBid}
                  onClick={() =>
                    this._handleClick("newBid", !this.state.newBid)
                  }
                />
              </li>
            </ul>
          </CardBody>
        </Card>
      </>
    );
  }
}

export default Settings;
