import React, { memo, useEffect, useState } from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Label,
  FormGroup,
  Input,
  Spinner,
} from "reactstrap";
import {
  companyCreateNotesForAgent,
  companyFetchAllNotesForAgent,
  companyUpdateNotesForAgent,
} from "../../../../http/http-calls";
import CustomPagination from "../CustomPagination";
import {
  errorHandler,
  formatDateAndTime,
  showToast,
} from "../../../../helper-methods";
import ReadMore from "../readMore";

const AddEditNotesModal = ({ isOpen, toggle, data, ...props }) => {
  const [comment, setComment] = useState("");
  const [commentId, setCommentId] = useState(null);
  const [comments, setComments] = useState([]);
  const [commentsCount, setCommentsCount] = useState(0);
  const [commentsPayload, setCommentsPayload] = useState({
    skip: 0,
    limit: 5,
    companyId: data?._id,
    clientId: data?._id,
  });
  const [loading, setLoading] = useState(false);
  const [addCommentLoading, setAddCommentLoading] = useState(false);

  console.log("isOpen  >>", props);

  const _fetchNotes = async (payload) => {
    const res = await companyFetchAllNotesForAgent(payload);
    if (!res.error) {
      setComments(res?.contents?.length ? res.contents : []);
      setCommentsCount(res?.totalCount || 0);
    }
  };

  const _editComment = (comment = null) => {
    setComment(comment?.text || "");
    setCommentId(comment?._id || null);
  };

  const _sendComment = async (comment) => {
    try {
      if (!comment?.trim()?.length) {
        showToast("Please add notes", "error");
        return;
      }

      setAddCommentLoading(true);

      const payload = {
        text: comment.trim(),
        agentId: data?._id,
      };

      if (commentId) {
        const newComments = [...comments];
        const findComment = newComments.find((each) => each._id === commentId);
        if (findComment) {
          findComment.text = payload.text;
          setComments(newComments);
        }
        await companyUpdateNotesForAgent(commentId, payload);
      } else {
        await companyCreateNotesForAgent(payload);
        _onChangePagination();
      }

      showToast(`Notes has been ${commentId ? "updated" : "added"}`, "success");

      _resetCommentState();
    } catch (error) {
      _onChangePagination();
      setAddCommentLoading(false);
      errorHandler(error);
    }
  };

  const _onChangePagination = (pageNo = 1) => {
    const newCommentsPayload = { ...commentsPayload };
    newCommentsPayload.skip = (pageNo - 1) * newCommentsPayload.limit;
    setCommentsPayload(newCommentsPayload);
    _fetchNotes(newCommentsPayload);
  };

  const _resetCommentState = () => {
    setTimeout(() => {
      setComment("");
    }, 0);
    setCommentId(null);
    setAddCommentLoading(false);
    toggle(null);
  };

  useEffect(() => {
    if (data?._id) {
      const newCommentsPayload = { ...commentsPayload };
      newCommentsPayload.agentId = data?._id;
      setCommentsPayload(newCommentsPayload);
      _fetchNotes(newCommentsPayload);
    }
  }, [data?._id]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle(null)}
      className="modal-dialog-centered"
    >
      <ModalHeader toggle={() => toggle(null)}>
        Notes {loading ? <Spinner size="sm" /> : null}
      </ModalHeader>
      <ModalBody className="modalContent">
        <>
          <FormGroup className="floatingLabel">
            <Input
              type="textarea"
              rows="6"
              placeholder=""
              value={comment}
              onChange={(e) => setComment(e.target.value)}
              disabled={addCommentLoading}
            />
            <Label>Comment</Label>
          </FormGroup>
          <div className="d-flex justify-content-center mt-3">
            {commentId ? (
              <Button color="primary" onClick={() => _resetCommentState()}>
                Cancel
              </Button>
            ) : null}
            <Button
              color="primary"
              onClick={() => _sendComment(comment)}
              disabled={addCommentLoading}
            >
              {commentId ? "Update" : "Create"} Comment
            </Button>
          </div>
        </>
        <div className="chatWrapper commentNote">
          {comments?.length ? (
            comments.map((each, index) => (
              <>
                <div className="chatWrap">
                  <div className="chatItem" key={each._id + index}>
                    <div className="chatHeader">
                      <div className="date">
                        {formatDateAndTime(each.createdAt)}
                      </div>
                      <div className="action">
                        {each._id === commentId ? (
                          <Button
                            color="link"
                            onClick={() => _editComment()}
                            disabled={each.isLoading}
                          >
                            <i className="fa fa-times" />
                          </Button>
                        ) : (
                          <>
                            <Button
                              color="link"
                              onClick={() => _editComment(each)}
                              disabled={each.isLoading}
                            >
                              <img
                                src={
                                  require("../../../../assets/img/pencil.svg")
                                    .default
                                }
                                alt="pencil"
                              />
                            </Button>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="Chatcontent">
                      <ReadMore text={each.text} />
                    </div>
                  </div>
                </div>
              </>
            ))
          ) : (
            <>
              {loading ? (
                <Spinner />
              ) : (
                <div className="nodata">
                  <img
                    src={require("../../../../assets/img/noData.svg").default}
                    alt="no data"
                  />
                  <p>There is no data to display</p>
                </div>
              )}
            </>
          )}

          <CustomPagination
            data={comments}
            dataCount={commentsCount}
            pageNumber={
              parseInt(commentsPayload.skip / commentsPayload.limit) + 1
            }
            pageSize={commentsPayload.limit}
            onPageChange={(pageNo) => _onChangePagination(pageNo)}
            isPageStartFromOne
            isHideForLessData
          />
        </div>
      </ModalBody>
    </Modal>
  );
};

export default memo(AddEditNotesModal);
