import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  Collapse,
  CustomInput,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  ListGroup,
  ListGroupItem,
  Row,
} from "reactstrap";
import { RegexConfig } from "../../../../config/RegexConfig";
import {
  deepClone,
  errorHandler,
  getImageDimensionClass,
  isProPlusUser,
  showToast,
  uploadFileOnServer,
} from "../../../../helper-methods";
import {
  createAgentWebsiteDetail,
  fetchGoogleRating,
  getAgentProfileDetail,
  getAgentWebsiteDetail,
  getClientListForWebsitePage,
  updateAgentWebsiteDetail,
} from "../../../../http/http-calls";
import WhiteLabelUIColor from "../../components/Modals/Company/WhiteLabelUIColor";
import PersonalAddDescriptionModal from "../Modals/Agents/PersonalAddDescriptionModal";

import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { agentWebsiteCardCollapseHeading } from "../../../../config";
import { usaStatesWIthCities } from "../../../../config/UsaStatesWithCities";
import {
  googlePlaceDetails,
  googlePlaceSearch,
} from "../../../../helper-methods/googleService";
import SvgIcons from "../SvgIcons";
import ImageCropModal from "./ImageCropModal";
import PersonalWebsiteWarningModal from "./PersonalWebsiteWarningModal";

const MAX_SERVICES_ALLOWED = 10; // the max no of services allowed

/**
 * function to generate additional services objects
 *
 * @param  count -> how maany objects you wanted to generate
 * @param type ->  it define what type of object to generate, whether a service, an isDirty or an errors object related to services section count
 * @returns an array of objects of specified type and with length of specified count
 */
const generateAdditionalServices = (count, type) => {
  const additionalServices = [];

  for (let i = 0; i < count; i++) {
    if (type === "service") {
      additionalServices.push({
        topic: "",
        description: "",
        logo: "",
        isDisplay: false,
      });
    } else if (type === "isDirty") {
      additionalServices.push({
        topic: false,
        description: false,
      });
    } else if (type === "errors") {
      additionalServices.push({
        topic: null,
        description: null,
      });
    }
  }

  return additionalServices;
};

const initialFormFields = {
  name: "",
  phone: "",
  email: "",
  nnaLink: "",
  linkedinLink: "",
  facebookLink: "",
  instagramLink: "",
  credentials: {
    backgroundCheckReport: false,
    eoInsurance: false,
    idVerification: false,
    lssCertification: false,
    mdTitleProducerLicense: false,
    nnaCertification: false,
    notaryBond: false,
    notaryLicense: false,
    nyTotalCloser: false,
    ronCredentials: false,
    titleProducerLicense: false,
    w9Credentials: false,
    barLicense: false,
  },
  bannerSection: {
    heading: "Notarize With Ease",
    subHeading: "Expertise Beyond The Seal",
    caption:
      "Redefining the standards of notary services by merging professionalism with unparalleled convenience. We are highly skilled and rigorously trained notary publics.",
    noOfHappyClients: "",
    noOfCompletedJobs: "",
    noOfInformationJobs: "",
    compliant: { value: "Compliant", isChecked: true },
    secure: { value: "Secure", isChecked: true },
    global: { value: "Global", isChecked: true },
  },
  aboutUsSection: {
    heading: "Convenient notarization at your fingertips",
    caption:
      "We specialize in a comprehensive range of notarial services including acknowledgements, jurats, oaths, affirmations, and more! Tailored to meet the diverse notary needs of individuals, businesses, and legal entities we aim for quick and through services.",
    aboutUsPicture: {},
  },
  // setting prefilled data
  servicesSection: [
    {
      topic: "Legal / General Notarizations",
      description:
        "From marriage documents to power of attorney, court filings to divorce agreements, we ensure the proper notarization and security of your important documents. For questions about your needs call us today!",
      logo: "",
      isDisplay: true,
    },
    {
      topic: "Real Estate Loan Documents",
      description:
        "Streamline your real estate transactions with our expert Notary services for Loan Documents! Ensure smooth closings and hassle-free paperwork, backed by professional expertise. Contact us today to schedule an appointment!",
      logo: "",
      isDisplay: true,
    },
    {
      topic: "Estate Planning",
      description:
        "Ensure your legacy's secure future with our comprehensive Estate Planning Document services. As a trusted notary, we specialize in notarizing legally binding wills, trusts, and power of attorney documents tailored to your unique needs.Call today to get started.",
      logo: "",
      isDisplay: true,
    },
    {
      topic: "Apostille Services",
      description:
        "Unlock hassle-free global document authentication with our expert apostille services! As a trusted notary, we ensure your documents are legalized swiftly and securely for international use. Say goodbye to bureaucratic hurdles and hello to seamless certification. Contact us today for peace of mind and unparalleled efficiency!",
      logo: "",
      isDisplay: true,
    },
    {
      topic: "Other notary services",
      description:
        "From document authentication to witnessing signatures, trust us for seamless transactions. Contact us today to ensure your paperwork is handled with precision and professionalism.",
      logo: "",
      isDisplay: true,
    },
    ...generateAdditionalServices(MAX_SERVICES_ALLOWED - 5, "service"),
  ],
  coverageAreaSection: [],
  testimonialsSection: [
    {
      name: {},
      title: "",
      text: "",
      designation: "",
      testimonialPicture: {},
      isFromDatabase: false,
    },
  ],
  faqSection: [
    {
      query: "What type of notarization do I need?",
      reply:
        "Because the Notary cannot advise or choose for you, it's essential that you know what type of notarization to request. Of the various notarial services available, these are three of the most common: Acknowledgment, Jurat, Copy Certification If you're unsure what type of notarization you need, contact the document drafter or receiving agency to confirm",
    },
    {
      query: "What do I need to bring for Notarization?",
      reply:
        "To ensure a smooth notarization process, please bring a valid government-issued \n photo ID such as a driver's license or passport. Additionally, if your document requires witnesses, please arrange for them to be present at the time of notarization. If you have any specific requirements or questions regarding your document, feel free to contact us in advance for guidance.",
    },
    {
      query: "What type of Identification do I need?",
      reply: `A Notary cannot perform a notarization unless you present an acceptable form of identification. Make sure you bring one of the following to your appointment: ● State-issued driver's license ● State-issued ID card ● U.S. passport issued by the U.S. Department of State ● U.S. military ID ● State, county, and local government IDs ● Permanent resident card or "green card" issued by the U.S. Keep in mind that acceptable identity documents can vary from state to state. If you don't have proper ID, you may be able to use a credible identifying witness—a person with an acceptable ID who will swear to the Notary that they know you. For more information, ask your Notary.`,
    },
    {
      query: "What are the costs associated with notary fees?",
      reply:
        "Notary fees typically range from $2 to $2MAX_SERVICES_ALLOWED - 5 per act, as set by state regulations. In states without a fee schedule, Notaries may determine a reasonable fee with the signer's agreement. Additionally, mobile Notaries in select states can set their own travel fees, often advised to be communicated upfront. Depending on circumstances such as last-minute requests or 24/7 availability, convenience fees may also apply.",
    },
  ],
  footerSection: {
    isShowFacebookUrl: false,
    isShowInstagramUrl: false,
    isShowLinkedinUrl: false,
    isShowTiktokUrl: false,
  },
  showClientsWorkedWith: false,
  showTotalJobsCompleted: false,
  totalJobsCompleted: 0,
  totalClientsWorkedWith: 0,
  profileLogo: {},
  profilePicture: {},
  favIcon: {},
  currentUI: "demoUI1",
  searchCompany: {
    value: "",
    placeId: null,
  },
  searchResult: [],
  show: "",
  googleRating: null,
};

const initialIsDirty = {
  name: false,
  phone: false,
  email: false,
  nnaLink: false,
  linkedinLink: false,
  facebookLink: false,
  instagramLink: false,
  bannerSection: {
    heading: false,
    subHeading: false,
    caption: false,
    noOfHappyClients: false,
    noOfCompletedJobs: false,
    noOfInformationJobs: false,
    compliant: false,
    secure: false,
    global: false,
  },
  aboutUsSection: {
    heading: false,
    caption: false,
    aboutUsPicture: false,
  },
  servicesSection: [
    {
      topic: false,
      description: false,
    },
    {
      topic: false,
      description: false,
    },
    {
      topic: false,
      description: false,
    },
    {
      topic: false,
      description: false,
    },
    {
      topic: false,
      description: false,
    },
    ...generateAdditionalServices(MAX_SERVICES_ALLOWED - 5, "isDirty"),
  ],
  testimonialsSection: [
    {
      name: false,
      title: false,
      text: false,
      designation: false,
      // testimonialPicture: false,
    },
  ],
  faqSection: [
    {
      query: false,
      reply: false,
    },
    {
      query: false,
      reply: false,
    },
    {
      query: false,
      reply: false,
    },
    {
      query: false,
      reply: false,
    },
  ],
  searchCompany: false,
  profileLogo: false,
  profilePicture: false,
  favIcon: false,
};

const initialErrors = {
  bannerSection: {
    heading: null,
    subHeading: null,
    caption: null,
    noOfHappyClients: null,
    noOfCompletedJobs: null,
    noOfInformationJobs: null,
    compliant: null,
    secure: null,
    global: null,
  },
  aboutUsSection: {
    heading: null,
    caption: null,
    aboutUsPicture: null,
  },
  servicesSection: [
    {
      topic: null,
      description: null,
      isDisplay: null,
    },
    {
      topic: null,
      description: null,
      isDisplay: null,
    },
    {
      topic: null,
      description: null,
      isDisplay: null,
    },
    {
      topic: null,
      description: null,
      isDisplay: null,
    },
    {
      topic: null,
      description: null,
      isDisplay: null,
    },
    ...generateAdditionalServices(MAX_SERVICES_ALLOWED - 5, "errors"),
  ],
  testimonialsSection: [
    {
      name: null,
      text: null,
      title: null,
      designation: null,
      // testimonialPicture: null,
    },
  ],
  faqSection: [
    {
      query: null,
      reply: null,
    },
    {
      query: null,
      reply: null,
    },
    {
      query: null,
      reply: null,
    },
    {
      query: null,
      reply: null,
    },
  ],
  searchCompany: null,
  profileLogo: null,
  profilePicture: null,
  favIcon: null,
};

const colorsUI = {
  demoUI1: {
    primary: "#4c96ce", // default color
    secondary: "#16346c",
    tertiary: "#0f2550",
  },
  demoUI2: {
    primary: "#4d648d",
    secondary: "#0f1c2e",
    tertiary: "#172c48",
  },
  demoUI3: {
    primary: "#c53628",
    secondary: "#062536",
    tertiary: "#093953",
  },
  demoUI4: {
    primary: "#ffc700",
    secondary: "#115080",
    tertiary: "#0d3e63",
  },
};

const sectionErrors = {
  basicInfo: false,
  // branding: false,
  bannerSection: false,
  aboutUsSection: false,
  servicesSection: false,
  // coverageAreaSection: false,
  testimonialsSection: false,
  faqSection: false,
  // footerSection: false,
};

const PersonalWebsiteSetp1 = ({ manageLoadingState }, ref) => {
  const history = useHistory();

  const ImageLogoRef = useRef();
  const ImageProfilePicRef = useRef();
  const ImageFavIconRef = useRef();
  const ImageAboutUsPicRef = useRef();
  const ImageTestimonialsPicRef = useRef();

  const [agentWebsiteDetails, setAgentWebsiteDetails] = useState({});
  const [formFields, setFormFields] = useState(initialFormFields);
  const [isDirty, setIsDirty] = useState(initialIsDirty);
  const [errors, setErrors] = useState(initialErrors);
  const [isFormDirty, setIsFormDirty] = useState(false);
  const [serviceDetails, setServiceDetails] = useState([]);
  const [isDefaultUiSelected, setIsDefaultUiSelected] = useState(true);
  const [imageLogoClass, setImageLogoClass] = useState("");
  const [isOpenCollapse, setIsOpenCollapse] = useState("");
  const [clientList, setClientList] = useState([]);
  const [selectedClients, setSelectedClients] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);

  const [customizedUI, setCustomizedUI] = useState({
    isChosen: false,
    data: null,
  });

  const [websiteWarningModal, setWebsiteWarningModal] = useState({
    isOpen: false,
    data: null,
  });

  const [imageCropModal, setImageCropModal] = useState({
    isOpen: false,
    data: null,
    fieldName: "",
    fileType: "",
    fileName: "",
  });

  const _getImagePath = (option) => {
    switch (option) {
      case "demoUI1":
        return require("../../../../assets/img/AgentWebiste1.png").default;
      case "demoUI2":
        return require("../../../../assets/img/AgentWebiste2.png").default;
      case "demoUI3":
        return require("../../../../assets/img/AgentWebiste3.png").default;
      case "demoUI4":
        return require("../../../../assets/img/AgentWebiste4.png").default;
      default:
        return require("../../../../assets/img/customizePreview.jpg").default;
    }
  };

  const _togglewebsiteWarningModal = (isOpen = false, data = null) => {
    setWebsiteWarningModal({ isOpen, data });
  };

  const _toggleImageCropModal = (
    isOpen = false,
    data = null,
    fieldName = "",
    fileType = "",
    fileName = ""
  ) => {
    setImageCropModal({ isOpen, data, fieldName, fileType, fileName });
  };

  const _toggleCollapse = (key) => {
    setIsOpenCollapse(key ? key : "");
  };

  const [seed, setSeed] = useState(0);

  const [addServiceModal, setAddServiceModal] = useState({
    isOpen: false,
    data: null,
  });

  const [whiteLabelUIColorModal, setWhiteLabelUIColorModal] = useState({
    isOpen: false,
  });

  const _toggleAddServiceModal = (isOpen = false, data = null) => {
    setAddServiceModal({ isOpen, data });
  };

  const _setFormData = async (agentWebsite, agentDetails = null) => {
    const newFormFields = { ...formFields };

    if (!Object.keys(agentWebsite)?.length) {
      // if agent-website data is not yet created, then will prefill these data
      if (agentDetails?.coverPhoto) {
        newFormFields["profileLogo"]["uploadedUrl"] = agentDetails?.coverPhoto;
      }
      if (agentDetails?.profilePicture) {
        newFormFields["profilePicture"]["uploadedUrl"] =
          agentDetails?.profilePicture;
      }
      setFormFields(newFormFields);
      return;
    }

    const newIsDirty = { ...isDirty };
    const newErrors = { ...errors };
    const newSelectedStates = [...selectedStates];

    newFormFields["name"] = agentWebsite?.name || "";
    newFormFields["email"] = agentWebsite?.email || "";
    newFormFields["phone"] = agentWebsite?.phone || "";
    newFormFields["showClientsWorkedWith"] =
      agentWebsite?.showClientsWorkedWith || false;
    newFormFields["showTotalJobsCompleted"] =
      agentWebsite?.showTotalJobsCompleted || false;
    newFormFields["totalJobsCompleted"] =
      agentWebsite?.totalJobsCompleted || "";
    newFormFields["totalClientsWorkedWith"] =
      agentWebsite?.totalClientsWorkedWith || "";

    newFormFields["profileLogo"]["uploadedUrl"] = agentWebsite?.logo || "";
    newFormFields["profilePicture"]["uploadedUrl"] =
      agentWebsite?.profilePicture || "";
    newFormFields["favIcon"]["uploadedUrl"] = agentWebsite?.favIcon || "";

    const services = agentWebsite?.serviceDetails?.map((each) => ({
      id: Date.now() + Math.random(),
      topic: each?.topic,
      description: each?.description,
    }));

    setServiceDetails(services || []);

    if (agentWebsite?.colorSetting !== "customizedUiSetting") {
      newFormFields["currentUI"] = agentWebsite?.colorSetting || "demoUI1";
    } else {
      newFormFields["currentUI"] = null;
      setCustomizedUI({
        isChosen: true,
        data: agentWebsite?.customizedUiSetting,
      });
    }

    if (agentWebsite?.placeId) {
      try {
        let detail = await googlePlaceDetails(agentWebsite?.placeId);

        if (detail?.postal) {
          newFormFields["searchCompany"].value =
            detail?.address +
            "," +
            detail?.city +
            "," +
            detail?.postal +
            "," +
            detail?.country;

          newFormFields["searchCompany"].placeId = agentWebsite?.placeId;
          newFormFields["googleRating"] = agentWebsite?.rating || null;
        }
      } catch (err) {
        console.log(err);
      }
    }
    // banner section
    if (agentWebsite?.bannerSection) {
      const bannerSection = {
        heading: agentWebsite?.bannerSection?.heading || "",
        subHeading: agentWebsite?.bannerSection?.subHeading || "",
        caption: agentWebsite?.bannerSection?.caption || "",
        noOfHappyClients: agentWebsite?.totalClientsWorkedWith || "",
        noOfCompletedJobs: agentWebsite?.totalJobsCompleted || "",
        noOfInformationJobs: agentWebsite?.totalInformationJobs || "",
        compliant: { value: "", isChecked: false },
        secure: { value: "", isChecked: false },
        global: { value: "", isChecked: false },
      };
      bannerSection["compliant"].value = agentWebsite?.compliant || "";
      bannerSection["compliant"].isChecked =
        agentWebsite?.isShowCompliant || false;
      bannerSection["secure"].value = agentWebsite?.secure || "";
      bannerSection["secure"].isChecked = agentWebsite?.isShowSecure || false;
      bannerSection["global"].value = agentWebsite?.global || "";
      bannerSection["global"].isChecked = agentWebsite?.isShowGlobal || false;

      newFormFields["bannerSection"] = bannerSection;
    }

    // about us section
    if (agentWebsite?.aboutUs) {
      const aboutUsSection = {
        ...formFields?.aboutUsSection,
        heading: agentWebsite?.aboutUs?.heading,
        caption: agentWebsite?.aboutUs?.caption,
      };
      if (agentWebsite?.aboutUs?.logo) {
        aboutUsSection["aboutUsPicture"]["uploadedUrl"] =
          agentWebsite?.aboutUs?.logo || "";
      }

      newFormFields["aboutUsSection"] = aboutUsSection;
    }

    //services section
    if (agentWebsite?.serviceDetails) {
      newFormFields["servicesSection"] =
        [
          ...agentWebsite?.serviceDetails,
          ...generateAdditionalServices(
            MAX_SERVICES_ALLOWED - agentWebsite?.serviceDetails?.length,
            "service"
          ),
        ] || [];
    }

    // coverageArea section
    newFormFields["coverageAreaSection"] = agentWebsite?.coverageArea;

    agentWebsite?.coverageArea?.forEach((each) => {
      newSelectedStates.push(each?.state);
    });
    setSelectedStates(newSelectedStates);

    // testimonials section
    const selectedClients = [];
    const testimonialsSection = [];
    agentWebsite?.manageClients?.forEach((each) => {
      selectedClients?.push(each?._agentClient?.id);

      testimonialsSection?.push({
        name: {
          value:
            each?._agentClient === undefined
              ? each?.name?.full
              : each?._agentClient?.id,
          label:
            each?._agentClient?.name?.full || each?._agentClient?.companyName,
        },
        title: each?.position,
        text: each?.description,
        designation: each?.designation,
        isFromDatabase: true, // 'true' for db saved data/clients
        testimonialPicture: {
          uploadedUrl: each?.logo
            ? each?.logo
            : each?._agentClient?.logo
            ? each?._agentClient?.logo
            : null,
        },
      });
    });
    newFormFields["testimonialsSection"] = testimonialsSection;
    agentWebsite?.manageClients?.forEach((each) => {
      newIsDirty["testimonialsSection"]?.push(
        deepClone(initialIsDirty["testimonialsSection"][0])
      );
      // here deepClone is used to make a deep copy of the inital object instead of shallow copy which would reference the same object from all instances
      newErrors["testimonialsSection"]?.push(
        deepClone(initialErrors["testimonialsSection"][0])
      );
    });

    setSelectedClients(selectedClients);

    // faq section
    if (agentWebsite?.faqDetails) {
      newIsDirty["faqSection"] = [];
      newErrors["faqSection"] = [];
      const faqSection = agentWebsite?.faqDetails;
      newFormFields["faqSection"] = faqSection;
      agentWebsite?.faqDetails?.forEach((each) => {
        newIsDirty["faqSection"]?.push({
          query: false,
          reply: false,
        });
        newErrors["faqSection"]?.push({
          query: null,
          reply: null,
        });
      });
    }

    // footer section
    const footerSection = {
      isShowInstagramUrl: agentWebsite?.isShowInstagramUrl,
      isShowLinkedinUrl: agentWebsite?.isShowLinkedinUrl,
      isShowFacebookUrl: agentWebsite?.isShowFacebookUrl,
      isShowTiktokUrl: agentWebsite?.isShowTiktokUrl,
    };
    newFormFields["footerSection"] = footerSection;

    setIsDirty(newIsDirty);
    setErrors(newErrors);
    setFormFields(newFormFields);

    setSeed(Math.random());
  };

  const _getAgentWebsiteDetails = async (agentDetails) => {
    try {
      const res = await getAgentWebsiteDetail();

      if (res?.agentWebsite) {
        setAgentWebsiteDetails(res?.agentWebsite);
        _setFormData(res?.agentWebsite, agentDetails);
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  const _checkForKYCAndStandardFee = async () => {
    return new Promise(async (resolve) => {
      try {
        const res = await getAgentProfileDetail();

        if (
          (res?.agent?.kyc &&
            !res?.agent?.kyc?.paymentEnabled &&
            !res?.agent?.kyc?.payoutEnabled) ||
          !res?.agent?.isAtleastOneStandardFeesAndWitnessFeeAvailable
        ) {
          _togglewebsiteWarningModal(true, res?.agent);
          resolve({ isValid: false, agentDetails: res?.agent });
          return;
        }

        resolve({ isValid: true, agentDetails: res?.agent });
      } catch (err) {
        errorHandler(err);
        history.push("/agent/profile");
        resolve({ isValid: false, agentDetails: null });
      }
    });
  };

  const _checkForErrorsAndSetData = async () => {
    const { isValid, agentDetails } = await _checkForKYCAndStandardFee();

    if (!isValid) {
      return;
    }
    _getAgentWebsiteDetails(agentDetails);
    _getClientList();
  };

  useEffect(() => {
    if (!isProPlusUser()) {
      history.goBack();
      return;
    }

    _checkForErrorsAndSetData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _getClientList = async () => {
    try {
      const res = await getClientListForWebsitePage();
      setClientList(res?.clients || []);
    } catch (err) {
      errorHandler(err);
    }
  };

  const _onFormFieldsChange = async (
    key,
    value,
    subKey = undefined,
    subKeyType = undefined, // subKey should be present if subKeyType is there  --  mainly used for services section, compliant, secure and global formfields,
    index = undefined // for testimonials/faqs section
  ) => {
    const newFormFields = { ...formFields };
    // console.log(
    //   "_onFormFieldsChange",
    //   { key },
    //   { value },
    //   { subKey },
    //   { subKeyType },
    //   { index }
    // );

    switch (key) {
      case "credentials": {
        let temp = newFormFields[key][value];

        if (temp) {
          newFormFields[key][value] = false;
        } else {
          newFormFields[key][value] = true;
        }
        break;
      }

      case "totalJobsCompleted":
      case "totalClientsWorkedWith": {
        if (isNaN(value) || +value < 0 || value.includes(".")) return;
        newFormFields[key] = value;
        break;
      }

      case "currentUI": {
        setIsDefaultUiSelected(true);

        setCustomizedUI({
          isChosen: false,
          data: null,
        });
        setSeed(Math.random());

        newFormFields[key] = value;
        break;
      }

      case "bannerSection":
      case "aboutUsSection":
      case "footerSection": {
        if (subKey) {
          if (subKeyType) {
            newFormFields[key][subKey][subKeyType] = value; // for: compliant, secure and global formfields in bannerSection
          } else {
            newFormFields[key][subKey] = value;
          }
        }
        break;
      }

      case "servicesSection": {
        if (subKey) {
          newFormFields[key][index][subKey] = value;
        }
        break;
      }

      case "testimonialsSection":
      case "faqSection": {
        if (subKey) {
          // setting profile picture for client on client choose
          if (key === "testimonialsSection" && subKey === "name") {
            // on client dropdown select, add clientId to selecteClient arr to filter it out from options,
            // also have to remove previously selected client from selectedClient arr
            const newSelectedClients = [...selectedClients];

            // console.log("newSelectedClients >>", newSelectedClients);

            // remove old one
            const prevVal = newFormFields?.[key]?.[index]?.[subKey]?.value;
            // previous value of the dropdown before change, using 'formfields' as 'newFormFields' will return updated value
            const indx = newSelectedClients?.findIndex(
              (each) => each === prevVal
            );
            if (indx > -1) {
              newSelectedClients?.splice(indx, 1);
            }

            // add new one
            if (value?.value) {
              // console.log("value?.value >>", value?.value);
              newSelectedClients?.push(value?.value);
            }

            // done for autofilling logo on client dropdown select
            const foundClient = clientList?.find(
              (each) => each?.id === value?.value
            );
            // console.log("foundClient >>", foundClient);
            if (foundClient === undefined) {
              // For createable client
              // Not in List
              newFormFields[key][index][subKey] = {
                full: value.value,
                ...value,
              };
            } else {
              // For selected client
              newFormFields[key][index][subKey] = value;
            }

            // console.log("newFormFields >>", newFormFields);

            if (foundClient && foundClient?.logo) {
              newFormFields[key][index]["testimonialPicture"]["uploadedUrl"] =
                foundClient?.logo;
            } else {
              newFormFields[key][index]["testimonialPicture"] = {};
            }

            // newFormFields[key][index][subKey] = value;
            setSelectedClients(newSelectedClients);
          } else {
            newFormFields[key][index][subKey] = value;
          }
        }
        break;
      }

      case "coverageAreaSection": {
        if (subKey) {
          if (subKey === "state") {
            const newSelectedStates = [...selectedStates];
            newSelectedStates.push(value);
            setSelectedStates(newSelectedStates);

            const newStateObj = { [subKey]: value, cities: [] };
            newFormFields[key].push(newStateObj);
          } else {
            newFormFields[key][index][subKey] = [
              ...newFormFields[key][index][subKey],
              value,
            ];
          }
        }
        break;
      }

      case "searchCompany": {
        newFormFields[key].value = value;

        const res = await googlePlaceSearch(value);

        newFormFields["searchResult"] = res;
        newFormFields["show"] = key;

        if (!value) {
          newFormFields["googleRating"] = null;
        }
        break;
      }

      default: {
        newFormFields[key] = value;
      }
    }
    setFormFields(newFormFields);
    setIsFormDirty(true);
  };

  const _onBlurFormFields = (key, subKey = undefined, index = 0) => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };

    if (
      key === "testimonialsSection" ||
      key === "faqSection" ||
      key === "servicesSection"
    ) {
      newIsDirty[key][index][subKey] = true;
      _validateTestimonials({ newFormFields, newIsDirty, type: key });
    } else if (key === "bannerSection" || key === "aboutUsSection") {
      newIsDirty[key][subKey] = true;
      _validateFormfields({ newFormFields, newIsDirty });
    } else {
      newIsDirty[key] = true;
      _validateFormfields({ newFormFields, newIsDirty });
    }

    setIsDirty(newIsDirty);
    setIsFormDirty(true);
  };

  const _validateFormfields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      let isFormValid = true;
      const newErrors = { ...errors };

      Object.keys(newFormFields)?.forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "name": {
              if (newIsDirty[key]) {
                if (newFormFields[key]?.trim()?.length) {
                  if (
                    newFormFields[key].length >= 2 &&
                    newFormFields[key].length <= 50
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] =
                      "*Minimum 2 characters and Maximum 50 characters allowed ";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }

            case "phone": {
              if (newIsDirty[key]) {
                if (String(newFormFields[key])?.trim()?.length) {
                  if (RegexConfig?.phone?.test(newFormFields[key])) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = "*Invalid phone";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }

            case "email": {
              if (newIsDirty[key]) {
                if (newFormFields[key]?.trim()?.length) {
                  if (RegexConfig?.email?.test(newFormFields[key])) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = "*Invalid email";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }

            case "profileLogo":
            case "profilePicture":
            case "favIcon": {
              if (newIsDirty?.[key]) {
                if (Object.keys(newFormFields?.[key])?.length) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }

            // new agent website fields
            case "bannerSection":
            case "aboutUsSection": {
              Object.keys(newFormFields?.[key])?.forEach((subKey) => {
                switch (subKey) {
                  // bannerSection + aboutUsSection(heading, caption)
                  case "heading":
                  case "subHeading":
                  case "caption":
                  case "noOfHappyClients":
                  case "noOfCompletedJobs": {
                    // case "noOfInformationJobs":
                    if (newIsDirty?.[key]?.[subKey]) {
                      if (
                        newFormFields?.[key]?.[subKey] &&
                        String(newFormFields?.[key]?.[subKey])?.trim()?.length
                      ) {
                        newErrors[key][subKey] = null;
                        newIsDirty[key][subKey] = false;
                      } else {
                        newErrors[key][subKey] = "*Required";
                        isFormValid = false;
                      }
                    }
                    break;
                  }

                  case "compliant":
                  case "secure":
                  case "global": {
                    if (newIsDirty?.[key]?.[subKey]) {
                      if (
                        String(newFormFields?.[key]?.[subKey]?.value)?.trim()
                          ?.length
                      ) {
                        newErrors[key][subKey] = null;
                        newIsDirty[key][subKey] = false;
                      } else {
                        newErrors[key][subKey] = "*Required";
                        isFormValid = false;
                      }
                    }
                    break;
                  }

                  case "aboutUsPicture": {
                    if (newIsDirty?.[key]?.[subKey]) {
                      if (Object.keys(newFormFields?.[key]?.[subKey])?.length) {
                        newErrors[key][subKey] = null;
                        newIsDirty[key][subKey] = false;
                      } else {
                        newErrors[key][subKey] = "*Required";
                        isFormValid = false;
                      }
                    }
                    break;
                  }

                  default:
                }
              });
              break;
            }

            default:
          }
        }
      });

      // find section errors
      _evaluateSectionErrors(newErrors, "obj");

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));
      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));
      resolve(isFormValid);
    });
  };

  const _evaluateSectionErrors = (newErrors, type = "obj") => {
    if (type === "obj") {
      // basic info section
      if (
        newErrors?.name ||
        newErrors?.phone ||
        newErrors?.email ||
        newErrors?.profileLogo ||
        newErrors?.profilePicture ||
        newErrors?.favIcon
      ) {
        sectionErrors["basicInfo"] = true;
      } else {
        sectionErrors["basicInfo"] = false;
      }

      // banner and about us section
      Object.keys(newErrors).forEach((each) => {
        if (each === "bannerSection" || each === "aboutUsSection") {
          // return 'true' if any of the value is not null, i.e have any error msg
          if (Object.values(newErrors?.[each])?.some((subEach) => subEach)) {
            sectionErrors[each] = true;
          } else {
            sectionErrors[each] = false;
          }
        }
      });
    } else if (type === "arr") {
      // services, testimonial and faq section
      Object.keys(newErrors)?.forEach((each) => {
        if (
          each === "servicesSection" ||
          each === "testimonialsSection" ||
          each === "faqSection"
        ) {
          // return 'true' if any of the value is not null, i.e have any error msg
          const isError = newErrors?.[each]?.some(
            (subEach) => !Object.values(subEach)?.every((val) => val === null)
          );

          if (isError) {
            sectionErrors[each] = true;
          } else {
            sectionErrors[each] = false;
          }
        }
      });
    }
  };

  const _validateTestimonials = ({
    newFormFields,
    newIsDirty,
    type,
    updatedErrors,
  }) => {
    return new Promise((resolve) => {
      let isFormValid = true;
      const newErrors = updatedErrors ? { ...updatedErrors } : { ...errors };

      newFormFields?.[type]?.map((each, index) => {
        return Object.keys(each).forEach((subEach) => {
          if (!newIsDirty?.[type]?.[index]?.[subEach]) {
            return;
          }

          switch (subEach) {
            // services
            case "topic":
            case "description": {
              // if isDisplay: false, return for servicesSection case, i.e only required when isDisplay: true
              if (!each?.[subEach]?.length && each?.isDisplay) {
                newErrors[type][index][subEach] = "*Required";
                isFormValid = false;
              } else {
                newIsDirty[type][index][subEach] = false;
                newErrors[type][index][subEach] = null;
              }
              break;
            }

            // testimonial fields
            case "name": {
              if (!each?.[subEach]?.value?.length) {
                newErrors[type][index][subEach] = "*Required";
                isFormValid = false;
              } else {
                newIsDirty[type][index][subEach] = false;
                newErrors[type][index][subEach] = null;
              }
              break;
            }

            case "text":
            case "designation": {
              if (!each?.[subEach]?.length) {
                newErrors[type][index][subEach] = "*Required";
                isFormValid = false;
              } else {
                newIsDirty[type][index][subEach] = false;
                newErrors[type][index][subEach] = null;
              }
              break;
            }

            // faq fields
            case "query":
            case "reply": {
              if (!each?.[subEach]?.length) {
                newErrors[type][index][subEach] = "*Required";
                isFormValid = false;
              } else {
                newIsDirty[type][index][subEach] = false;
                newErrors[type][index][subEach] = null;
              }
              break;
            }

            default:
          }
        });
      });

      // find section errors
      _evaluateSectionErrors(newErrors, "arr");

      setIsDirty({ ...isDirty, ...newIsDirty });
      setErrors({ ...errors, ...newErrors });
      resolve(isFormValid);
    });
  };

  const getPlaceDetail = async (pid, type) => {
    const newFormFields = { ...formFields };

    try {
      const ratingAvalaible = await fetchGoogleRating(pid?.place_id);

      if (!ratingAvalaible?.rating) {
        errorHandler("No Google rating found for this address.");
        return;
      }

      let detail = await googlePlaceDetails(pid.place_id);

      if (detail?.postal) {
        newFormFields["googleRating"] = ratingAvalaible?.rating;

        newFormFields[type].value =
          detail?.address +
          "," +
          detail?.city +
          "," +
          detail?.postal +
          "," +
          detail?.country;

        newFormFields[type].placeId = pid?.place_id;
      } else {
        errorHandler({ reason: "No Company Address found" });
        newFormFields[type].value = "";
        newFormFields[type].placeId = null;
      }

      newFormFields["show"] = "";

      setFormFields(newFormFields);
    } catch (err) {
      newFormFields[type].value = "";
      newFormFields[type].placeId = null;
      newFormFields["show"] = "";

      setFormFields(newFormFields);
      errorHandler(err);
    }
  };

  const _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const newIsDirty = { ...isDirty };
      Object.keys(newIsDirty).forEach((e) => {
        if (
          e === "testimonialsSection" ||
          e === "faqSection" ||
          e === "servicesSection"
        ) {
          newIsDirty[e]?.forEach((obj, index) => {
            Object.keys(obj).forEach((key) => {
              newIsDirty[e][index][key] = true;
            });
          });
        } else if (e === "bannerSection" || e === "aboutUsSection") {
          Object.keys(newIsDirty?.[e]).forEach((subKey) => {
            newIsDirty[e][subKey] = true;
          });
        } else {
          newIsDirty[e] = true;
        }
      });

      setIsDirty(newIsDirty);

      resolve(newIsDirty);
    });
  };

  const _updateFileImageBasicDetail = (
    fieldName,
    event,
    subField = undefined,
    index = 0 // for testimonials section
  ) => {
    const newFormFields = { ...formFields };
    setIsFormDirty(true);

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      let objFile = event.target.files[0];
      let objFileType = objFile.type.split("/")[0];

      if (objFileType === "image") {
        if (objFile?.size / 1000 > 500) {
          errorHandler({
            reason: "Max allowed file size: 500kb",
          });
          return;
        }

        if (
          fieldName !== "profileLogo" &&
          fieldName !== "aboutUsSection" &&
          fieldName !== "testimonialsSection"
        ) {
          _toggleImageCropModal(
            true,
            URL.createObjectURL(objFile),
            fieldName,
            objFileType,
            objFile?.name
          );
        } else if (fieldName === "aboutUsSection") {
          if (subField) {
            newFormFields[fieldName][subField].file = {
              previewBlob: URL.createObjectURL(objFile),
              uploadData: objFile,
              type: objFileType === "application" ? "pdf" : objFileType,
            };
            if (newFormFields?.[fieldName]?.[subField]?.uploadedUrl) {
              delete newFormFields?.[fieldName]?.[subField]?.["uploadedUrl"];
            }
            setFormFields(newFormFields);
          }
        } else if (fieldName === "testimonialsSection") {
          if (subField) {
            newFormFields[fieldName][index][subField].file = {
              previewBlob: URL.createObjectURL(objFile),
              uploadData: objFile,
              type: objFileType === "application" ? "pdf" : objFileType,
            };
            if (newFormFields?.[fieldName]?.[index]?.[subField]?.uploadedUrl) {
              delete newFormFields?.[fieldName]?.[index]?.[subField]?.[
                "uploadedUrl"
              ];
            }
            setFormFields(newFormFields);
          }
        } else {
          newFormFields[fieldName].file = {
            previewBlob: URL.createObjectURL(objFile),
            uploadData: objFile,
            type: objFileType === "application" ? "pdf" : objFileType,
          };
          if (newFormFields?.[fieldName]?.uploadedUrl) {
            delete newFormFields?.[fieldName]?.["uploadedUrl"];
          }

          setFormFields(newFormFields);
        }
      } else {
        showToast("Only Image file is allowed", "error");
      }
    }
  };

  const _setCroppedImg = (objFile, fieldName, fileType) => {
    const newFormFields = { ...formFields };

    newFormFields[fieldName].file = {
      previewBlob: URL.createObjectURL(objFile),
      uploadData: objFile,
      type: fileType === "application" ? "pdf" : fileType,
    };
    if (newFormFields?.[fieldName]?.uploadedUrl) {
      delete newFormFields?.[fieldName]?.["uploadedUrl"];
    }

    setFormFields(newFormFields);
  };

  const _createPayload = async () => {
    const payload = {
      name: formFields?.name || "",
      phone: formFields?.phone || "",
      email: formFields?.email || "",
    };

    if (formFields?.profileLogo?.file) {
      const uploadedFilesRes = await uploadFileOnServer([
        formFields?.profileLogo?.file,
      ]);
      payload["logo"] = uploadedFilesRes[0].url;
    }

    if (formFields?.profilePicture?.file) {
      const uploadedFilesRes = await uploadFileOnServer([
        formFields?.profilePicture?.file,
      ]);
      payload["profilePicture"] = uploadedFilesRes[0].url;
    }

    if (formFields?.favIcon?.file) {
      const uploadedFilesRes = await uploadFileOnServer([
        formFields?.favIcon?.file,
      ]);
      payload["favIcon"] = uploadedFilesRes[0].url;
    }

    if (customizedUI?.isChosen) {
      payload["customizedUiSetting"] = customizedUI?.data;
      payload["colorSetting"] = "customizedUiSetting";
    } else {
      if (formFields?.currentUI) {
        payload["colorSetting"] = formFields?.currentUI;
        payload["defaultUiSetting"] = colorsUI[formFields?.currentUI];
      }
    }
    payload["isDefaultUiSelected"] = isDefaultUiSelected;

    // --------- agent website new changes payload added below ------------
    // place id
    if (formFields?.searchCompany?.placeId) {
      payload["placeId"] = formFields?.searchCompany?.placeId || "";
    }

    if (formFields?.bannerSection?.noOfHappyClients) {
      payload["totalClientsWorkedWith"] =
        Number(formFields?.bannerSection?.noOfHappyClients) || 0;
    }
    if (formFields?.bannerSection?.noOfCompletedJobs) {
      payload["totalJobsCompleted"] =
        Number(formFields?.bannerSection?.noOfCompletedJobs) || 0;
    }
    if (formFields?.bannerSection?.noOfInformationJobs) {
      payload["totalInformationJobs"] =
        Number(formFields?.bannerSection?.noOfInformationJobs) || 0;
    }

    if (formFields?.bannerSection?.compliant?.value) {
      payload["compliant"] = formFields?.bannerSection?.compliant?.value;
      payload["isShowCompliant"] =
        formFields?.bannerSection?.compliant?.isChecked;
    }
    if (formFields?.bannerSection?.secure?.value) {
      payload["secure"] = formFields?.bannerSection?.secure?.value;
      payload["isShowSecure"] = formFields?.bannerSection?.secure?.isChecked;
    }
    if (formFields?.bannerSection?.global?.value) {
      payload["global"] = formFields?.bannerSection?.global?.value;
      payload["isShowGlobal"] = formFields?.bannerSection?.global?.isChecked;
    }

    // banner section
    const bannerSection = {
      heading: formFields?.bannerSection?.heading || "",
      subHeading: formFields?.bannerSection?.subHeading || "",
      caption: formFields?.bannerSection?.caption || "",
    };
    payload["bannerSection"] = bannerSection;

    // about us section
    let aboutUs = {
      heading: formFields?.aboutUsSection?.heading || "",
      caption: formFields?.aboutUsSection?.caption || "",
    };
    if (formFields?.aboutUsSection?.aboutUsPicture?.file) {
      const uploadedFilesRes = await uploadFileOnServer([
        formFields?.aboutUsSection?.aboutUsPicture?.file,
      ]);
      aboutUs["logo"] = uploadedFilesRes[0].url;
    }
    payload["aboutUs"] = aboutUs;

    // services section
    payload["serviceDetails"] = formFields?.servicesSection;

    // coverageArea section
    payload["coverageArea"] = formFields?.coverageAreaSection;

    // testimonials section
    const manageClients = await _handleManageClients(
      formFields?.testimonialsSection
    );

    payload["manageClients"] = manageClients;

    // faq section
    payload["faqDetails"] = formFields?.faqSection;

    // footer section
    payload["isShowFacebookUrl"] =
      formFields?.footerSection?.isShowFacebookUrl || false;
    payload["isShowInstagramUrl"] =
      formFields?.footerSection?.isShowInstagramUrl || false;
    payload["isShowLinkedinUrl"] =
      formFields?.footerSection?.isShowLinkedinUrl || false;
    payload["isShowTiktokUrl"] =
      formFields?.footerSection?.isShowTiktokUrl || false;

    return payload;
  };

  const _handleManageClients = (arr) => {
    return new Promise(async (resolve) => {
      const files = [];

      for (let i = 0; i < arr.length; i++) {
        let obj = {
          position: arr[i]?.title,
          description: arr[i]?.text,
          designation: arr[i]?.designation,
          logo: arr[i]?.testimonialPicture?.uploadedUrl || undefined,
        };

        if (arr[i]?.name?.full?.length) {
          obj.name = {
            full: arr[i]?.name?.full,
            first: arr[i]?.name?.full.split(" ")[0],
            last: arr[i]?.name?.full.split(" ")[1] || undefined,
          };
        } else {
          obj._agentClient = arr[i]?.name?.value;
        }

        if (arr[i]?.testimonialPicture?.file) {
          const uploadedFilesRes = await uploadFileOnServer([
            arr[i]?.testimonialPicture?.file,
          ]);
          obj["logo"] = uploadedFilesRes[0].url;
        }

        files.push(obj);
      }

      resolve(files);
    });
  };

  const _toggleWhiteLabelUIColor = (isOpen = false) => {
    setWhiteLabelUIColorModal({ isOpen });
  };

  const _onSaveCustomizedUIColor = (data) => {
    setCustomizedUI({
      isChosen: true,
      data: data,
    });
    const newFormFields = { ...formFields };

    newFormFields["currentUI"] = null;
    setFormFields(newFormFields);
    setIsFormDirty(true);
    setIsDefaultUiSelected(false);
  };

  const _onSubmit = async () => {
    try {
      manageLoadingState("stepLoading", true);
      const newFormFields = { ...formFields };
      const newIsDirty = await _markAllFieldDirty();

      const isValidate = await _validateFormfields({
        newFormFields,
        newIsDirty,
      });
      const isValidateServices = await _validateTestimonials({
        newFormFields,
        newIsDirty,
        type: "servicesSection",
      });
      const isValidateTestimonial = await _validateTestimonials({
        newFormFields,
        newIsDirty,
        type: "testimonialsSection",
      });
      const isValidateFaq = await _validateTestimonials({
        newFormFields,
        newIsDirty,
        type: "faqSection",
      });

      if (
        !isValidate ||
        !isValidateServices ||
        !isValidateTestimonial ||
        !isValidateFaq
      ) {
        errorHandler("Please fill all mandatory form fields correctly!");
        manageLoadingState("stepLoading", false);
        return false;
      }

      if (!isFormDirty) {
        manageLoadingState("stepLoading", false);
        return true;
      }

      const payload = await _createPayload();

      const res = await getAgentWebsiteDetail();

      if (Object?.keys(res?.agentWebsite)?.length) {
        await updateAgentWebsiteDetail(payload);
      } else {
        await createAgentWebsiteDetail(payload);
      }
      showToast("Successfully created", "success");
      manageLoadingState("stepLoading", false);
      setIsFormDirty(false);
      return true;
    } catch (err) {
      errorHandler(err);
      manageLoadingState("stepLoading", false);
      return false;
    }
  };

  const _isNumber = (e) => {
    var charCode = e.which ? e.which : e.keyCode;

    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return e.preventDefault();
    return;
  };

  useImperativeHandle(ref, () => ({
    _onSubmit,
    isWebsiteLaunched: agentWebsiteDetails?.isWebsiteLaunched,
  }));

  const _addTestimonialsOrFaq = (type) => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };
    const newErrors = { ...errors };

    if (
      type === "testimonialsSection" &&
      newFormFields?.testimonialsSection?.length >= 8
    ) {
      errorHandler({ reason: "Can only add maximum 8 testimonials" });
      return;
    } else if (
      type === "faqSection" &&
      newFormFields?.faqSection?.length >= 10
    ) {
      errorHandler({ reason: "Can only add maximum 10 FAQ's" });
      return;
    }

    if (type === "testimonialsSection") {
      newFormFields?.testimonialsSection?.push({
        name: "",
        title: "",
        text: "",
        designation: "",
        testimonialPicture: {},
      });
      newIsDirty?.testimonialsSection?.push(
        deepClone(initialIsDirty["testimonialsSection"][0])
      );
      // here deepClone is used to make a deep copy of the inital object instead of shallow copy which would reference the same object from all instances
      newErrors.testimonialsSection?.push(
        deepClone(initialErrors["testimonialsSection"][0])
      );
    } else if (type === "faqSection") {
      newFormFields?.faqSection?.push({
        query: "",
        reply: "",
      });
      newIsDirty?.faqSection?.push({
        query: false,
        reply: false,
      });
      newErrors?.faqSection?.push({
        query: null,
        reply: null,
      });
    }

    setIsDirty(newIsDirty);
    setErrors(newErrors);
    setFormFields(newFormFields);
    setIsFormDirty(true);
  };

  const _deleteTestimonialsOrFaq = (type, index, data = null) => {
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };
    const newErrors = { ...errors };

    newFormFields[type]?.splice(index, 1);
    newIsDirty[type]?.splice(index, 1);
    newErrors[type]?.splice(index, 1);

    if (type === "testimonialsSection" && data) {
      const testimonialIdx = selectedClients?.findIndex(
        (each) => each === data?.name?.value
      );

      if (testimonialIdx > -1) {
        const newSelectedClients = [...selectedClients];
        newSelectedClients?.splice(testimonialIdx, 1);
        setSelectedClients(newSelectedClients);
      }
    }

    setFormFields(newFormFields);
    setIsFormDirty(true);
  };

  const _deleteStateOrCity = (type, stateIndex, cityIndex, stateValue) => {
    const newFormFields = { ...formFields };

    if (type === "cities") {
      newFormFields?.["coverageAreaSection"]?.[stateIndex]?.["cities"]?.splice(
        cityIndex,
        1
      );
    } else {
      const newSelectedStates = [...selectedStates];
      const indx = newSelectedStates?.findIndex((each) => each === stateValue);

      if (indx > -1) {
        newSelectedStates?.splice(indx, 1);
      }
      setSelectedStates(newSelectedStates);

      newFormFields?.["coverageAreaSection"]?.splice(stateIndex, 1);
    }

    setFormFields(newFormFields);
    setIsFormDirty(true);
  };

  const cardBodyObject = {
    basicInfo: (
      <CardBody>
        <Row>
          <Col lg={4} md={6} sm={6} className="text-center mt-3 mb-4">
            {/* you have to add class according to there length  the class is rectangle and square*/}
            <Label className="d-block mb-2">
              Upload Logo<span className="text-danger">*</span>
            </Label>
            <Label
              className={`profileCompanyLogo ${imageLogoClass || "rectangle"}`}
            >
              <Input
                type="file"
                style={{ display: "none" }}
                accept="image/x-png,image/gif,image/jpeg"
                value=""
                onChange={(event) =>
                  _updateFileImageBasicDetail("profileLogo", event)
                }
                placeholder=" "
              />
              <img
                ref={ImageLogoRef}
                src={
                  formFields?.profileLogo?.uploadedUrl ||
                  (formFields?.profileLogo?.file &&
                  formFields?.profileLogo?.file?.previewBlob
                    ? formFields?.profileLogo?.file?.previewBlob
                    : require("../../../../assets/img/placeholder-img.png")
                        .default)
                }
                alt="Profile Img"
                onLoad={() =>
                  setImageLogoClass(getImageDimensionClass(ImageLogoRef))
                }
              />
              <i className="fa fa-pencil"></i>
            </Label>
            {errors?.profileLogo ? (
              <p className="validation-error">{errors?.profileLogo}</p>
            ) : null}
          </Col>
          <Col lg={4} md={6} sm={6} className="text-center mt-3 mb-4">
            {/* you have to add class according to there length  the class is rectangle and square*/}
            <Label className="d-block mb-2">
              Profile Pic<span className="text-danger">*</span>
            </Label>
            <Label
              className={`profileCompanyLogo ${imageLogoClass || "rectangle"}`}
            >
              <Input
                type="file"
                style={{ display: "none" }}
                accept="image/x-png,image/gif,image/jpeg"
                value=""
                onChange={(event) =>
                  _updateFileImageBasicDetail("profilePicture", event)
                }
                placeholder=" "
              />
              <img
                ref={ImageProfilePicRef}
                src={
                  formFields?.profilePicture?.uploadedUrl ||
                  (formFields?.profilePicture?.file &&
                  formFields?.profilePicture?.file?.previewBlob
                    ? formFields?.profilePicture?.file?.previewBlob
                    : require("../../../../assets/img/placeholder-img.png")
                        .default)
                }
                alt="Profile Img"
                onLoad={() =>
                  setImageLogoClass(getImageDimensionClass(ImageProfilePicRef))
                }
              />
              <i className="fa fa-pencil"></i>
            </Label>
            {errors?.profilePicture ? (
              <p className="validation-error">{errors?.profilePicture}</p>
            ) : null}
          </Col>
          <Col lg={4} md={12} className="text-center mt-3 mb-4">
            {/* you have to add class according to there length  the class is rectangle and square*/}
            <Label className="d-block mb-2">
              Favicon<span className="text-danger">*</span>
            </Label>
            <Label
              className={`profileCompanyLogo ${imageLogoClass || "rectangle"}`}
            >
              <Input
                type="file"
                style={{ display: "none" }}
                accept="image/x-png,image/gif,image/jpeg"
                value=""
                onChange={(event) =>
                  _updateFileImageBasicDetail("favIcon", event)
                }
                placeholder=" "
              />
              <img
                ref={ImageFavIconRef}
                src={
                  formFields?.favIcon?.uploadedUrl ||
                  (formFields?.favIcon?.file &&
                  formFields?.favIcon?.file?.previewBlob
                    ? formFields?.favIcon?.file?.previewBlob
                    : require("../../../../assets/img/placeholder-img.png")
                        .default)
                }
                alt="Fav Icon"
                onLoad={() =>
                  setImageLogoClass(getImageDimensionClass(ImageFavIconRef))
                }
              />
              <i className="fa fa-pencil"></i>
            </Label>
            {errors?.favIcon ? (
              <p className="validation-error">{errors?.favIcon}</p>
            ) : null}
          </Col>
          <Col xl={4}>
            <FormGroup className="floatingLabel">
              <Input
                placeholder=" "
                require
                value={formFields?.name}
                onChange={(e) => _onFormFieldsChange("name", e.target.value)}
                onBlur={() => _onBlurFormFields("name")}
              />
              <Label>
                Name<span className="text-danger">*</span>
              </Label>
              {errors?.name ? (
                <p className="validation-error">{errors?.name}</p>
              ) : null}
            </FormGroup>
          </Col>
          <Col xl={4}>
            <FormGroup className="floatingLabel">
              <Input
                placeholder=" "
                require
                value={formFields?.email}
                onChange={(e) => _onFormFieldsChange("email", e.target.value)}
                onBlur={() => _onBlurFormFields("email")}
              />
              <Label>
                Email<span className="text-danger">*</span>
              </Label>
              {errors?.email ? (
                <p className="validation-error">{errors?.email}</p>
              ) : null}
            </FormGroup>
          </Col>
          <Col xl={4}>
            <FormGroup className="floatingLabel">
              <Input
                placeholder=" "
                require
                value={formFields?.phone}
                onChange={(e) => _onFormFieldsChange("phone", e.target.value)}
                onBlur={() => _onBlurFormFields("phone")}
                onKeyPress={(e) => _isNumber(e)}
              />
              <Label>
                Phone<span className="text-danger">*</span>
              </Label>
              {errors?.phone ? (
                <p className="validation-error">{errors?.phone}</p>
              ) : null}
            </FormGroup>
          </Col>
          <Col xl={4}>
            <FormGroup className="floatingLabel withInputGroup">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <img
                      src={
                        require("../../../../assets/img/mapMarker_blue.svg")
                          .default
                      }
                      alt="map marker"
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  placeholder=" "
                  value={formFields?.searchCompany?.value}
                  name="searchCompany"
                  onChange={(event) =>
                    _onFormFieldsChange("searchCompany", event.target.value)
                  }
                />
                <Label>Company Address</Label>
              </InputGroup>
              {/* {errors?.searchCompany && (
                <div className="validation-error">{errors?.searchCompany}</div>
              )} */}

              {formFields?.show === "searchCompany" ? (
                <ListGroup flush className="customSearchOptions">
                  {formFields?.searchResult?.length
                    ? formFields?.searchResult?.map((i) => {
                        return (
                          <ListGroupItem
                            style={{ cursor: "pointer" }}
                            onClick={() => getPlaceDetail(i, "searchCompany")}
                          >
                            {i.description}
                          </ListGroupItem>
                        );
                      })
                    : null}
                </ListGroup>
              ) : null}

              {formFields?.googleRating ? (
                <p className="mt-2 small">
                  Google Review Rating:{" "}
                  <span className="font-weight-bold">
                    {formFields?.googleRating}
                  </span>
                </p>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    ),

    branding: (
      <CardBody>
        <Row>
          <Col md={6}>
            <CustomInput
              type="radio"
              id="demoUI1"
              className="mb-3"
              checked={formFields?.currentUI === "demoUI1"}
              onClick={() => _onFormFieldsChange("currentUI", "demoUI1")}
              label="Default"
            />
            <CustomInput
              type="radio"
              id="demoUI2"
              className="mb-3"
              checked={formFields?.currentUI === "demoUI2"}
              onClick={() => _onFormFieldsChange("currentUI", "demoUI2")}
              label="Charcoal gray"
            />
            <CustomInput
              type="radio"
              id="demoUI3"
              className="mb-3"
              checked={formFields?.currentUI === "demoUI3"}
              onClick={() => _onFormFieldsChange("currentUI", "demoUI3")}
              label="Cornell Red"
            />
            <CustomInput
              type="radio"
              id="demoUI4"
              className="mb-3"
              checked={formFields?.currentUI === "demoUI4"}
              onClick={() => _onFormFieldsChange("currentUI", "demoUI4")}
              label="Bright Matte Yellow"
            />
            <CustomInput
              type="radio"
              id="customizeUI"
              className="mb-3"
              checked={customizedUI?.isChosen && !formFields?.currentUI} // customized ui shhould be chosen and currentUi should be null
              onClick={() => _toggleWhiteLabelUIColor(true)}
              label="Customize"
            />
          </Col>
          <Col md={6}>
            <div className="themePreview">
              <img
                src={_getImagePath(formFields?.currentUI)}
                alt="Selected Option"
              />
            </div>
          </Col>
        </Row>
      </CardBody>
    ),

    bannerSection: (
      <CardBody>
        <Row>
          <Col md={12}>
            <FormGroup className="floatingLabel">
              <Input
                type="text"
                placeholder=" "
                value={formFields?.bannerSection?.heading}
                onChange={(e) =>
                  _onFormFieldsChange(
                    "bannerSection",
                    e.target.value,
                    "heading"
                  )
                }
                onBlur={() => _onBlurFormFields("bannerSection", "heading")}
              />
              <Label>Heading</Label>
              {errors?.bannerSection?.heading ? (
                <p className="validation-error">
                  {errors?.bannerSection?.heading}
                </p>
              ) : null}
            </FormGroup>
            <FormGroup className="floatingLabel">
              <Input
                type="text"
                placeholder=""
                value={formFields?.bannerSection?.subHeading}
                onChange={(e) =>
                  _onFormFieldsChange(
                    "bannerSection",
                    e.target.value,
                    "subHeading"
                  )
                }
                onBlur={() => _onBlurFormFields("bannerSection", "subHeading")}
              />
              <Label>Sub Heading</Label>
              {errors?.bannerSection?.subHeading ? (
                <p className="validation-error">
                  {errors?.bannerSection?.subHeading}
                </p>
              ) : null}
            </FormGroup>
            <FormGroup className="floatingLabel">
              <Input
                rows={5}
                type="textarea"
                placeholder=" "
                value={formFields?.bannerSection?.caption}
                onChange={(e) =>
                  _onFormFieldsChange(
                    "bannerSection",
                    e.target.value,
                    "caption"
                  )
                }
                onBlur={() => _onBlurFormFields("bannerSection", "caption")}
              />
              <Label>Caption</Label>
              {errors?.bannerSection?.caption ? (
                <p className="validation-error">
                  {errors?.bannerSection?.caption}
                </p>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="floatingLabel">
              <Input
                type="text"
                placeholder=" "
                value={formFields?.bannerSection?.noOfHappyClients}
                onChange={(e) =>
                  _onFormFieldsChange(
                    "bannerSection",
                    e.target.value,
                    "noOfHappyClients"
                  )
                }
                onBlur={() =>
                  _onBlurFormFields("bannerSection", "noOfHappyClients")
                }
                onKeyPress={(e) => _isNumber(e)}
              />
              <Label>No. of Happy Clients</Label>
              {errors?.bannerSection?.noOfHappyClients ? (
                <p className="validation-error">
                  {errors?.bannerSection?.noOfHappyClients}
                </p>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup className="floatingLabel">
              <Input
                type="text"
                placeholder=" "
                value={formFields?.bannerSection?.noOfCompletedJobs}
                onChange={(e) =>
                  _onFormFieldsChange(
                    "bannerSection",
                    e.target.value,
                    "noOfCompletedJobs"
                  )
                }
                onBlur={() =>
                  _onBlurFormFields("bannerSection", "noOfCompletedJobs")
                }
                onKeyPress={(e) => _isNumber(e)}
              />
              <Label>Completed Jobs</Label>
              {errors?.bannerSection?.noOfCompletedJobs ? (
                <p className="validation-error">
                  {errors?.bannerSection?.noOfCompletedJobs}
                </p>
              ) : null}
            </FormGroup>
          </Col>
          {/* <Col md={4}>
            <FormGroup className="floatingLabel">
              <Input
                type="text"
                placeholder=" "
                value={formFields?.bannerSection?.noOfInformationJobs}
                onChange={(e) =>
                  _onFormFieldsChange(
                    "bannerSection",
                    e.target.value,
                    "noOfInformationJobs"
                  )
                }
                onBlur={() =>
                  _onBlurFormFields("bannerSection", "noOfInformationJobs")
                }
                onKeyPress={(e) => _isNumber(e)}
              />
              <Label>Information Jobs</Label>
              {errors?.bannerSection?.noOfInformationJobs ? (
                <p className="validation-error">
                  {errors?.bannerSection?.noOfInformationJobs}
                </p>
              ) : null}
            </FormGroup>
          </Col> */}
          <Col md={4}>
            <FormGroup className="floatingLabel withInputGroup">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <CustomInput
                      type="checkbox"
                      id="Compliant"
                      checked={formFields?.bannerSection?.compliant?.isChecked}
                      onChange={(e) =>
                        _onFormFieldsChange(
                          "bannerSection",
                          e.target.checked,
                          "compliant",
                          "isChecked"
                        )
                      }
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder=" "
                  value={formFields?.bannerSection?.compliant?.value}
                  onChange={(e) =>
                    _onFormFieldsChange(
                      "bannerSection",
                      e.target.value,
                      "compliant",
                      "value"
                    )
                  }
                  onBlur={() => _onBlurFormFields("bannerSection", "compliant")}
                />
                <Label>Tag 1</Label>
              </InputGroup>
              {errors?.bannerSection?.compliant ? (
                <p className="validation-error">
                  {errors?.bannerSection?.compliant}
                </p>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className="floatingLabel withInputGroup">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <CustomInput
                      type="checkbox"
                      id="Secure"
                      checked={formFields?.bannerSection?.secure?.isChecked}
                      onChange={(e) =>
                        _onFormFieldsChange(
                          "bannerSection",
                          e.target.checked,
                          "secure",
                          "isChecked"
                        )
                      }
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder=" "
                  value={formFields?.bannerSection?.secure?.value}
                  onChange={(e) =>
                    _onFormFieldsChange(
                      "bannerSection",
                      e.target.value,
                      "secure",
                      "value"
                    )
                  }
                  onBlur={() => _onBlurFormFields("bannerSection", "secure")}
                />
                <Label>Tag 2</Label>
              </InputGroup>
              {errors?.bannerSection?.secure ? (
                <p className="validation-error">
                  {errors?.bannerSection?.secure}
                </p>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={4}>
            <FormGroup className="floatingLabel withInputGroup">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <CustomInput
                      type="checkbox"
                      id="Global"
                      checked={formFields?.bannerSection?.global?.isChecked}
                      onChange={(e) =>
                        _onFormFieldsChange(
                          "bannerSection",
                          e.target.checked,
                          "global",
                          "isChecked"
                        )
                      }
                      // disabled={!formFields?.bannerSection?.global?.value}
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder=" "
                  value={formFields?.bannerSection?.global?.value}
                  onChange={(e) =>
                    _onFormFieldsChange(
                      "bannerSection",
                      e.target.value,
                      "global",
                      "value"
                    )
                  }
                  onBlur={() => _onBlurFormFields("bannerSection", "global")}
                />
                <Label>Tag 3</Label>
              </InputGroup>
              {errors?.bannerSection?.global ? (
                <p className="validation-error">
                  {errors?.bannerSection?.global}
                </p>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
      </CardBody>
    ),

    aboutUsSection: (
      <CardBody>
        <Row>
          <Col md={8}>
            <FormGroup className="floatingLabel">
              <Input
                type="text"
                placeholder=" "
                value={formFields?.aboutUsSection?.heading}
                onChange={(e) =>
                  _onFormFieldsChange(
                    "aboutUsSection",
                    e.target.value,
                    "heading"
                  )
                }
                onBlur={() => _onBlurFormFields("aboutUsSection", "heading")}
              />
              <Label>Heading</Label>
              {errors?.aboutUsSection?.heading ? (
                <p className="validation-error">
                  {errors?.aboutUsSection?.heading}
                </p>
              ) : null}
            </FormGroup>
            <FormGroup className="floatingLabel">
              <Input
                rows={5}
                type="textarea"
                placeholder=" "
                value={formFields?.aboutUsSection?.caption}
                onChange={(e) =>
                  _onFormFieldsChange(
                    "aboutUsSection",
                    e.target.value,
                    "caption"
                  )
                }
                onBlur={() => _onBlurFormFields("aboutUsSection", "caption")}
              />
              <Label>Caption</Label>
              {errors?.aboutUsSection?.caption ? (
                <p className="validation-error">
                  {errors?.aboutUsSection?.caption}
                </p>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={4}>
            <Label
              className={`profileCompanyLogo ${imageLogoClass || "rectangle"}`}
            >
              <Input
                type="file"
                style={{ display: "none" }}
                accept="image/x-png,image/gif,image/jpeg"
                value=""
                onChange={(event) =>
                  _updateFileImageBasicDetail(
                    "aboutUsSection",
                    event,
                    "aboutUsPicture"
                  )
                }
                placeholder=" "
                onBlur={() =>
                  _onBlurFormFields("aboutUsSection", "aboutUsPicture")
                }
              />
              <img
                ref={ImageAboutUsPicRef}
                src={
                  formFields?.aboutUsSection?.aboutUsPicture?.uploadedUrl ||
                  (formFields?.aboutUsSection?.aboutUsPicture?.file &&
                  formFields?.aboutUsSection?.aboutUsPicture?.file?.previewBlob
                    ? formFields?.aboutUsSection?.aboutUsPicture?.file
                        ?.previewBlob
                    : require("../../../../assets/img/placeholder-img.png")
                        .default)
                }
                alt="About Us Img"
                onLoad={() =>
                  setImageLogoClass(getImageDimensionClass(ImageAboutUsPicRef))
                }
              />
              <i className="fa fa-pencil"></i>
            </Label>
            {errors?.aboutUsSection?.aboutUsPicture ? (
              <p className="validation-error">
                {errors?.aboutUsSection?.aboutUsPicture}
              </p>
            ) : null}
          </Col>
        </Row>
      </CardBody>
    ),

    servicesSection: (
      <CardBody>
        {formFields?.servicesSection?.map((service, index) => (
          <FormGroup key={index}>
            <div className="d-flex align-items-center justify-content-between mb-3">
              <div className="floatingLabel w-100 mr-3">
                <Input
                  placeholder=" "
                  value={service?.topic}
                  onChange={(e) =>
                    _onFormFieldsChange(
                      "servicesSection",
                      e.target.value,
                      "topic",
                      undefined,
                      index
                    )
                  }
                  onBlur={() =>
                    _onBlurFormFields("servicesSection", "topic", index)
                  }
                />
                <Label>Service Name</Label>
                {errors?.servicesSection?.[index]?.["topic"] ? (
                  <p className="validation-error">
                    {errors?.servicesSection?.[index]?.["topic"]}
                  </p>
                ) : null}
              </div>

              <CustomInput
                id={`services_${index}`}
                name={service?.topic}
                type="checkbox"
                checked={service?.isDisplay}
                onChange={(e) =>
                  _onFormFieldsChange(
                    "servicesSection",
                    e.target.checked,
                    "isDisplay",
                    undefined,
                    index
                  )
                }
                onBlur={() =>
                  _onBlurFormFields("servicesSection", "isDisplay", index)
                }
              />
            </div>
            <div className="floatingLabel">
              <Input
                rows={5}
                type="textarea"
                placeholder=" "
                value={service?.description}
                onChange={(e) =>
                  _onFormFieldsChange(
                    "servicesSection",
                    e.target.value,
                    "description",
                    undefined,
                    index
                  )
                }
                onBlur={() =>
                  _onBlurFormFields("servicesSection", "description", index)
                }
              />
              <Label>Description</Label>
              {errors?.servicesSection?.[index]?.["description"] ? (
                <p className="validation-error">
                  {errors?.servicesSection?.[index]?.["description"]}
                </p>
              ) : null}
            </div>
          </FormGroup>
        ))}
      </CardBody>
    ),

    coverageAreaSection: (
      <CardBody>
        <FormGroup className="floatingLabel">
          <div className="custom-select-wrapper">
            <Input
              type="select"
              name="state"
              onChange={(e) =>
                _onFormFieldsChange(
                  "coverageAreaSection",
                  e.target.value,
                  "state"
                )
              }
            >
              <option value="">All</option>
              {Object.keys(usaStatesWIthCities)
                ?.filter((state) => !selectedStates?.includes(state))
                ?.map((state, index) => (
                  <option key={state + index} value={state}>
                    {state}
                  </option>
                ))}
            </Input>
            <Label>Select States</Label>
          </div>
        </FormGroup>
        <Row>
          {formFields?.coverageAreaSection?.length
            ? formFields?.coverageAreaSection?.map((each, index) => (
                <Col md={4} key={each?.state + index}>
                  <Card className="stateList">
                    <CardHeader>
                      <CardTitle>State: {each?.state}</CardTitle>
                      <Button
                        color="link"
                        className="remove"
                        onClick={() =>
                          _deleteStateOrCity(
                            "state",
                            index,
                            undefined,
                            each?.state
                          )
                        }
                      >
                        <SvgIcons type="remove" />
                      </Button>
                    </CardHeader>
                    <CardBody>
                      <FormGroup className="floatingLabel ">
                        <Input
                          type="select"
                          name="select"
                          id="exampleSelect"
                          value=""
                          onChange={(e) =>
                            _onFormFieldsChange(
                              "coverageAreaSection",
                              e.target.value,
                              "cities",
                              undefined,
                              index
                            )
                          }
                        >
                          <option value="">Select</option>
                          {usaStatesWIthCities?.[each?.state]
                            ?.filter(
                              (subEach) => !each?.cities?.includes(subEach)
                            )
                            ?.map((subEach) => (
                              <option value={subEach}>{subEach}</option>
                            ))}
                        </Input>
                        <Label>Cities</Label>
                      </FormGroup>

                      <ul>
                        {each?.cities?.length
                          ? each?.cities?.map((city, cityIndex) => (
                              <li>
                                <span>{city}</span>
                                <div
                                  className="remove"
                                  onClick={() =>
                                    _deleteStateOrCity(
                                      "cities",
                                      index,
                                      cityIndex
                                    )
                                  }
                                >
                                  <SvgIcons type={"cross"} />
                                </div>
                              </li>
                            ))
                          : null}
                      </ul>
                    </CardBody>
                  </Card>
                </Col>
              ))
            : null}
        </Row>
      </CardBody>
    ),

    testimonialsSection: (
      <CardBody>
        <Row>
          {formFields?.testimonialsSection?.map((testimonial, index) => (
            <Col md={4}>
              <Card className="testimonialsWrap">
                <CardBody className="text-center">
                  <Label
                    className={`profileCompanyLogo mb-3 mx-auto ${
                      imageLogoClass || "rectangle"
                    }`}
                  >
                    <Input
                      type="file"
                      style={{ display: "none" }}
                      accept="image/x-png,image/gif,image/jpeg"
                      value=""
                      onChange={(event) =>
                        _updateFileImageBasicDetail(
                          "testimonialsSection",
                          event,
                          "testimonialPicture",
                          index
                        )
                      }
                      placeholder=" "
                    />
                    <img
                      ref={ImageTestimonialsPicRef}
                      src={
                        testimonial?.testimonialPicture?.uploadedUrl ||
                        (testimonial?.testimonialPicture?.file &&
                        testimonial?.testimonialPicture?.file?.previewBlob
                          ? testimonial?.testimonialPicture?.file?.previewBlob
                          : require("../../../../assets/img/placeholder-img.png")
                              .default)
                      }
                      alt="Profile Img"
                    />
                    <i className="fa fa-pencil"></i>
                  </Label>
                  <FormGroup className="floatingLabel">
                    <CreatableSelect
                      isClearable
                      options={clientList
                        ?.filter(
                          (client) => !selectedClients?.includes(client?.id)
                        )
                        ?.map((each) => ({
                          value: each?.id,
                          label: each?._client
                            ? each?.name?.full
                            : each?.companyName,
                        }))}
                      value={
                        testimonial?.name
                          ? {
                              value: testimonial?.name?.value,
                              label: testimonial?.name?.label,
                            }
                          : ""
                      }
                      onChange={(e) =>
                        _onFormFieldsChange(
                          "testimonialsSection",
                          e,
                          "name",
                          undefined,
                          index
                        )
                      }
                      onBlur={() =>
                        _onBlurFormFields("testimonialsSection", "name", index)
                      }
                      placeholder="Select*"
                      className="customMultiSelect"
                      isDisabled={testimonial?.isFromDatabase}
                    />
                    <Label>
                      Name<span className="text-danger">*</span>
                    </Label>
                    {errors?.testimonialsSection?.[index]?.["name"] ? (
                      <p className="validation-error">
                        {errors?.testimonialsSection?.[index]?.["name"]}
                      </p>
                    ) : null}
                  </FormGroup>
                  <FormGroup className="floatingLabel">
                    <Input
                      placeholder=" "
                      value={testimonial?.title}
                      onChange={(e) =>
                        _onFormFieldsChange(
                          "testimonialsSection",
                          e.target.value,
                          "title",
                          undefined,
                          index
                        )
                      }
                      onBlur={() =>
                        _onBlurFormFields("testimonialsSection", "title", index)
                      }
                    />

                    <Label>Title</Label>
                  </FormGroup>
                  <FormGroup className="floatingLabel">
                    <Input
                      placeholder=" "
                      require
                      value={testimonial?.text}
                      onChange={(e) =>
                        _onFormFieldsChange(
                          "testimonialsSection",
                          e.target.value,
                          "text",
                          undefined,
                          index
                        )
                      }
                      onBlur={() =>
                        _onBlurFormFields("testimonialsSection", "text", index)
                      }
                    />
                    <Label>
                      Text<span className="text-danger">*</span>
                    </Label>
                    {errors?.testimonialsSection?.[index]?.["text"] ? (
                      <p className="validation-error">
                        {errors?.testimonialsSection?.[index]?.["text"]}
                      </p>
                    ) : null}
                  </FormGroup>
                  <FormGroup className="floatingLabel">
                    <Input
                      placeholder=" "
                      require
                      value={testimonial?.designation}
                      onChange={(e) =>
                        _onFormFieldsChange(
                          "testimonialsSection",
                          e.target.value,
                          "designation",
                          undefined,
                          index
                        )
                      }
                      onBlur={() =>
                        _onBlurFormFields(
                          "testimonialsSection",
                          "designation",
                          index
                        )
                      }
                    />
                    <Label>
                      Designation<span className="text-danger">*</span>
                    </Label>
                    {errors?.testimonialsSection?.[index]?.["designation"] ? (
                      <p className="validation-error">
                        {errors?.testimonialsSection?.[index]?.["designation"]}
                      </p>
                    ) : null}
                  </FormGroup>
                  <Button
                    color="danger"
                    className="w-100"
                    onClick={() =>
                      _deleteTestimonialsOrFaq(
                        "testimonialsSection",
                        index,
                        testimonial
                      )
                    }
                  >
                    Delete
                  </Button>
                </CardBody>
              </Card>
            </Col>
          ))}

          <Col md={12} className="text-center">
            <Button
              color="primary"
              onClick={() => _addTestimonialsOrFaq("testimonialsSection")}
            >
              Add Testimonials
            </Button>
          </Col>
        </Row>
      </CardBody>
    ),

    faqSection: (
      <CardBody>
        <Row>
          {formFields?.faqSection?.length
            ? formFields?.faqSection?.map((faq, index) => (
                <Col md={4} className="mb-2">
                  <div className="questionWrap">
                    <div className="questionbox">
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          name="question"
                          placeholder=" "
                          value={faq?.query}
                          onChange={(e) =>
                            _onFormFieldsChange(
                              "faqSection",
                              e.target.value,
                              "query",
                              undefined,
                              index
                            )
                          }
                          onBlur={() =>
                            _onBlurFormFields("faqSection", "query", index)
                          }
                        />
                        <Label>Question</Label>
                        {errors?.faqSection?.[index]?.["query"] ? (
                          <p className="validation-error">
                            {errors?.faqSection?.[index]?.["query"]}
                          </p>
                        ) : null}
                      </FormGroup>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          name="question"
                          placeholder=" "
                          value={faq?.reply}
                          onChange={(e) =>
                            _onFormFieldsChange(
                              "faqSection",
                              e.target.value,
                              "reply",
                              undefined,
                              index
                            )
                          }
                          onBlur={() =>
                            _onBlurFormFields("faqSection", "reply", index)
                          }
                        />
                        <Label>Answer</Label>
                        {errors?.faqSection?.[index]?.["reply"] ? (
                          <p className="validation-error">
                            {errors?.faqSection?.[index]?.["reply"]}
                          </p>
                        ) : null}
                      </FormGroup>
                    </div>
                    {formFields?.faqSection?.length !== 1 ? (
                      <Button
                        color="link"
                        className="remove"
                        onClick={() =>
                          _deleteTestimonialsOrFaq("faqSection", index)
                        }
                      >
                        <SvgIcons type="remove" />
                      </Button>
                    ) : null}
                  </div>
                </Col>
              ))
            : null}
        </Row>
        <div
          className="text-center mt-4 mb-3"
          onClick={() => _addTestimonialsOrFaq("faqSection")}
        >
          <Button color="primary">Add Question</Button>
        </div>
      </CardBody>
    ),

    footerSection: (
      <CardBody className="d-flex justify-content-between">
        <div>
          <p>Choose review Links to be displayed</p>
          <CustomInput
            type="checkbox"
            name="social media"
            id="facebook"
            inline
            label="Facebook"
            checked={formFields?.footerSection?.isShowFacebookUrl}
            onChange={(e) =>
              _onFormFieldsChange(
                "footerSection",
                e.target.checked,
                "isShowFacebookUrl"
              )
            }
          />
          <CustomInput
            type="checkbox"
            name="social media"
            id="Instagram"
            inline
            label="Instagram"
            checked={formFields?.footerSection?.isShowInstagramUrl}
            onChange={(e) =>
              _onFormFieldsChange(
                "footerSection",
                e.target.checked,
                "isShowInstagramUrl"
              )
            }
          />
          <CustomInput
            type="checkbox"
            name="social media"
            id="Linkdin"
            inline
            label="LinkedIn"
            checked={formFields?.footerSection?.isShowLinkedinUrl}
            onChange={(e) =>
              _onFormFieldsChange(
                "footerSection",
                e.target.checked,
                "isShowLinkedinUrl"
              )
            }
          />
          <CustomInput
            type="checkbox"
            name="social media"
            id="Tiktok"
            inline
            label="Tiktok"
            checked={formFields?.footerSection?.isShowTiktokUrl}
            onChange={(e) =>
              _onFormFieldsChange(
                "footerSection",
                e.target.checked,
                "isShowTiktokUrl"
              )
            }
          />
        </div>
        <Link to="/agent/profile?5" target="_blank">
          {/* <p onClick={() => history.push("/agent/profile?5", "_blank")}> */}
          Review Links
          {/* </p> */}
        </Link>
      </CardBody>
    ),
  };

  return (
    <>
      {agentWebsiteCardCollapseHeading?.map((each) => (
        <Card
          className={`accordianWrap ${
            isOpenCollapse === each?.value ? "open" : ""
          } ${
            sectionErrors?.[each?.value] ? "border border-2 border-danger" : ""
          }`}
        >
          <CardHeader
            onClick={() =>
              _toggleCollapse(isOpenCollapse === each?.value ? "" : each?.value)
            }
          >
            <div>
              {each?.label}
              {sectionErrors?.[each?.value] ? (
                <span className="text-danger">*</span>
              ) : null}
            </div>
            <SvgIcons className={"arrow"} type="chevron-down" />
          </CardHeader>

          <Collapse isOpen={isOpenCollapse === each?.value}>
            {cardBodyObject?.[each?.value]}
          </Collapse>
        </Card>
      ))}

      <WhiteLabelUIColor
        key={seed}
        isOpen={whiteLabelUIColorModal?.isOpen}
        toggle={() => _toggleWhiteLabelUIColor()}
        onSave={(colors) => _onSaveCustomizedUIColor(colors)}
        customizedUIData={customizedUI?.data}
        hideFromAgentWebsite={true}
      />

      <PersonalAddDescriptionModal
        isOpen={addServiceModal?.isOpen}
        data={addServiceModal?.data}
        toggle={_toggleAddServiceModal}
        serviceDetails={serviceDetails}
        setServiceDetails={setServiceDetails}
        setIsFormDirty={setIsFormDirty}
      />

      <PersonalWebsiteWarningModal
        isOpen={websiteWarningModal?.isOpen}
        data={websiteWarningModal?.data}
        toggle={_togglewebsiteWarningModal}
      />

      <ImageCropModal
        isOpen={imageCropModal?.isOpen}
        imgData={imageCropModal?.data}
        fieldName={imageCropModal?.fieldName}
        fileType={imageCropModal?.fileType}
        fileName={imageCropModal?.fileName}
        toggle={_toggleImageCropModal}
        onSuccess={(objFile, fieldName, fileType) =>
          _setCroppedImg(objFile, fieldName, fileType)
        }
      />
    </>
  );
};

export default forwardRef(PersonalWebsiteSetp1);
