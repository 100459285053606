import React, { useEffect, useState } from "react";
import { Label } from "reactstrap";
import { errorHandler } from "../../../helper-methods";
import { getAllLoanTypes } from "../../../http/http-calls";

const ProductType = ({
  value = [],
  loanCategories = [],
  onChange = null,
  blur = null,
  error = null,
  isMulti = true,
  onClickOutside = null,
  productTypeField = "",
  selectedCategoriesField = "",
  isCompanyFeeSettings = false,
  isClientCreateOrder = false,
  clientCreateOrderOptions = null,
  hidePlaceholder = false,
  showFullWidth = "", // pass this prop when using this component as a filter dropdown
}) => {
  const [loanTypeOptions, setLoanTypeOptions] = useState({});
  const [loanTypeOptionsInitial, setLoanTypeOptionsInitial] = useState({});
  const [selectedCategories, setSelectedCategories] = useState(loanCategories);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [classess, setClassess] = useState({
    isOpen1: false,
    isOpen2: false,
  });

  const [seed, setSeed] = useState(0);

  const _toggleClassess = (key) => {
    if (!isMulti && value?.length === 1) {
      return;
    }

    setClassess((prev) => {
      if (key === "isOpen1" && prev[key] === true) {
        return {
          isOpen1: false,
          isOpen2: false,
        };
      }

      return {
        ...prev,
        [key]: !prev[key],
      };
    });
  };

  const _handleCategoryChange = (categoryName) => {
    setSelectedCategory(categoryName);

    if (categoryName === "Other") {
      const ans = [...value, { value: "Other", label: "Other" }];
      onChange(productTypeField, ans);

      const newClasses = { ...classess };
      newClasses["isOpen1"] = false;
      newClasses["isOpen2"] = false;
      setClassess(newClasses);
    } else {
      onChange(selectedCategoriesField, categoryName);
      _toggleClassess("isOpen2");
    }
  };

  const _fetchLoanType = async () => {
    try {
      // api call
      const res = await getAllLoanTypes();

      setLoanTypeOptions(res?.loanType);
      setLoanTypeOptionsInitial(res?.loanType);
      _setLoanTypeOptions(value, res?.loanType);
    } catch (err) {
      errorHandler(err);
    }
  };

  const _setLoanTypeOptions = (updatedValueArr, loanTypeOptionsInitialProp) => {
    const newLoanTypeOptions = { ...loanTypeOptionsInitialProp };

    Object.keys(newLoanTypeOptions)?.forEach((each) => {
      let newValues = [];
      newLoanTypeOptions[each]?.forEach((subEach) => {
        if (!updatedValueArr?.find((val) => val?.value === subEach)) {
          newValues.push(subEach);
        }
      });
      newLoanTypeOptions[each] = newValues;
    });

    setLoanTypeOptions(newLoanTypeOptions);
  };

  const _handleLoanTypeChange = (loanType, category) => {
    if (!isMulti) {
      const payload = {
        value: loanType,
        label: loanType,
        category: category,
      };
      onChange(productTypeField, payload);
      _setLoanTypeOptions([payload], loanTypeOptionsInitial); // passing payload as an array bcoz we want an array to traverse to in that function
    } else {
      const payload = [
        ...value,
        { value: loanType, label: loanType, category: category },
      ];
      onChange(productTypeField, payload);
      _setLoanTypeOptions(payload, loanTypeOptionsInitial);
    }

    const newClasses = { ...classess };
    newClasses["isOpen1"] = false;
    newClasses["isOpen2"] = false;

    setClassess(newClasses);

    const findCategoryIndex = selectedCategories.findIndex(
      (each) => each === selectedCategory
    );

    if (findCategoryIndex === -1) {
      setSelectedCategories((prev) => [...prev, selectedCategory]);
    }
  };

  // useEffect(() => {
  //   if (!isCompanyFeeSettings) {
  //     onChange(selectedCategoriesField, selectedCategories);
  //   }
  // }, [selectedCategories]);

  const _removeLoanType = (e, loanTypeObj) => {
    if (e) e.stopPropagation();
    const newValues = [...value];

    const deleteIndex = newValues?.findIndex(
      (each) => each.value === loanTypeObj?.value
    );

    if (deleteIndex !== -1) {
      let payload;
      newValues.splice(deleteIndex, 1);

      if (isMulti) {
        payload = newValues;
        _setLoanTypeOptions(payload, loanTypeOptionsInitial);
        // onChange(productTypeField, newValues);
      } else {
        payload = {};
        _setLoanTypeOptions([], loanTypeOptionsInitial);
        // onChange(productTypeField, {});
      }
      onChange(productTypeField, payload);
    }
    // const newLoanTypeOptions = { ...loanTypeOptions };

    // const index = loanTypeOptionsInitial[loanTypeObj?.category]?.findIndex(
    //   (each) => each === loanTypeObj?.value
    // );

    // newLoanTypeOptions[loanTypeObj?.category].splice(
    //   index,
    //   1,
    //   loanTypeObj?.value
    // );
    // setLoanTypeOptions(newLoanTypeOptions);
    setSeed(Math.random());

    const newClasses = { ...classess };
    newClasses["isOpen1"] = false;
    newClasses["isOpen2"] = false;
    setClassess(newClasses);

    // const res = [];
    // const newloanTypeOptionsInitial = { ...loanTypeOptionsInitial };

    // selectedCategories?.forEach((each) =>
    //   Object.keys(newloanTypeOptionsInitial)?.forEach((subEach) => {
    //     if (newloanTypeOptionsInitial[subEach].includes(each)) {
    //       res.push(each);
    //     }
    //   })
    // );

    // setSelectedCategories(res);
  };

  useEffect(() => {
    if (clientCreateOrderOptions) {
      setLoanTypeOptions(clientCreateOrderOptions);
      setLoanTypeOptionsInitial(clientCreateOrderOptions);
    }
    // else {
    //   _fetchLoanType();
    // }
  }, [clientCreateOrderOptions]);

  useEffect(() => {
    if (value?.length && Object.keys(loanTypeOptionsInitial)?.length) {
      _setLoanTypeOptions(value, loanTypeOptionsInitial);
    }
  }, [value, loanTypeOptionsInitial]);

  // const _onClickOutsidefunc = () => {
  //   const newClasses = { ...classess };
  //   newClasses["isOpen1"] = false;
  //   newClasses["isOpen2"] = false;

  //   setClassess(newClasses);

  //   onClickOutside();
  // };

  // useEffect(() => {
  //   const handleClickOutside = (event) => {
  //     if (ref.current && !ref.current.contains(event.target)) {
  //       onClickOutside && _onClickOutsidefunc();
  //     }
  //   };
  //   document.addEventListener("click", handleClickOutside, true);
  //   return () => {
  //     document.removeEventListener("click", handleClickOutside, true);
  //   };
  // }, [onClickOutside]);

  function close() {
    const newClasses = { ...classess };
    newClasses["isOpen1"] = false;
    newClasses["isOpen2"] = false;

    setClassess(newClasses);
  }

  const innerDropDown = loanTypeOptions?.[selectedCategory]?.length
    ? loanTypeOptions?.[selectedCategory]
    : [];

  // innerDropDown.length = 4;

  console.log("innerDropDown", innerDropDown);

  return (
    <>
      <div
        className={`productTypelist ${showFullWidth?.length ? "showFullWidth" : ""
          }`}
        tabIndex={0}
        onBlur={close}
      >
        <div
          className="productWrap"
          onClick={() => _toggleClassess("isOpen1")}
        // ref={ref}
        >
          <div className="selectedValue">
            {/* this will show when you select any item */}
            {value?.length
              ? value?.map((each) => (
                <>
                  <div className="valueChip">
                    {each?.label}
                    <img
                      onClick={(e) => _removeLoanType(e, each)}
                      src={require("../../../assets/img/cross.png").default}
                      alt="delete"
                    />
                  </div>
                </>
              ))
              : "Select"}
          </div>
          {/* this will hide when you select any item */}
          {/* <h3>Select</h3> */}

          {/* <h3>Loan Modifications</h3> */}
          {hidePlaceholder ? null : <Label>Product Type</Label>}
        </div>

        <div className={`typeList ${classess.isOpen1 ? "show" : " "}`}>
          <ul className={`frontView ${classess.isOpen2 ? "moveLeft" : " "}`}>
            {(!isClientCreateOrder ||
              loanTypeOptions["RealEstateDocuments"]?.length > 0) && (
              <li onClick={() => _handleCategoryChange("RealEstateDocuments")}>
                Real Estate Documents
              </li>
            )}
            {(!isClientCreateOrder ||
              loanTypeOptions["LegalGeneralDocuments"]?.length > 0) && (
              <li
                onClick={() => _handleCategoryChange("LegalGeneralDocuments")}
              >
                Legal/General Documents
              </li>
            )}
            {(!isClientCreateOrder ||
              loanTypeOptions["EstatePlanning"]?.length > 0) && (
              <li onClick={() => _handleCategoryChange("EstatePlanning")}>
                Estate Planning
              </li>
            )}
            {(!isClientCreateOrder ||
              loanTypeOptions["Apostille"]?.length > 0) && (
              <li onClick={() => _handleCategoryChange("Apostille")}>
                Apostille
              </li>
            )}
            {!isCompanyFeeSettings &&
            value.findIndex((each) => each.value === "Other") === -1 ? (
              <li onClick={() => _handleCategoryChange("Other")}>Others</li>
            ) : null}
          </ul>
          {selectedCategory && loanTypeOptions?.[selectedCategory] ? (
            <ul
              className={`innerView ${classess.isOpen2 ? "rightSide" : " "}`}
              key={seed}
            >
              <li
                className="backBtn"
                onClick={() => _toggleClassess("isOpen2")}
              >
                <img
                  src={
                    require("../../../assets/img/arrowLeftBlack.png").default
                  }
                  height={16}
                  alt="back"
                />{" "}
                {selectedCategory}
              </li>
              {innerDropDown?.length ? (
                innerDropDown?.map((each) => (
                  <li
                    onClick={() =>
                      _handleLoanTypeChange(each, selectedCategory)
                    }
                  >
                    {each}
                  </li>
                ))
              ) : (
                <li>No Data</li>
              )}
            </ul>
          ) : null}
        </div>

        {error ? <div className="validation-error">{error}</div> : null}
      </div>
    </>
  );
};

export default ProductType;
