export const TeamMemberInvitationClone = `
Dear {{name}} ,

{{companyName}} has added you as a team member on their CloseWise account. You are now able to login your own personal account and access granted permissions associated with {{companyName}}.

To get started, please click on the following link to login and access your personal account: your email : {{email}} and password: {{password}}

If you have any questions or concerns about this invitation, please don't hesitate to contact us at {{companyEmail}} or reach out to our CloseWise support team at support@CloseWise.com.

Thank you,

{{companyName}}`;

export const customClientTeamMemberInvitaionClone = `
Dear {{name}},

We are pleased to invite you to join companyName Connect Portal on CloseWise, an exclusive platform for our team members to manage and collaborate on orders efficiently.

To get started, please use the link below to create your personal account: your email : {{email}} and password: {{password}}

Once you have created your account, you will have access to your granted permissions which could include our company's orders, order creation, doc upload, order statuses, communication with other team members, and more.

If you have any questions or issues regarding your account, please do not hesitate to contact us at {{clientEmail}}.

You can also reach out to support@closewise.com for additional support

Thank you for joining companyName Connect Portal. We look forward to working with you on this platform.

Best regards,

companyName`;

export const customAgentPayPeriodClone = `
Dear {{name}} ,

 Attached you will find the pay period breakdown for your review and approval. Please take the time to carefully review the document, which includes all of the orders you completed during the pay period, as well as the fees associated with each order.

To ensure that your payment is made in a timely manner and to avoid any disruptions in service please respond in a timely manner.

If you have any questions or concerns, please do not hesitate to reach out to us. We are always here to help.

Thank you for your continued partnership with us.

Best regards,

{{companyName}}`;

export const customAgentRegistrationInviteClone = `
Dear {{name}},

 {{companyName}} would like to extend an invitation for you to connect with CloseWise

CloseWise is a platform that provides a comprehensive solution for signing agents to receive, manage, and complete orders. By connecting with CloseWise, you will have access to a streamlined and efficient way of managing your orders received from our team.

To connect, simply click the button below and follow the steps to complete your registration.

If you have any questions or concerns, please don't hesitate to reach out to CloseWise's support team at support@closewise.com. They are available to assist you as needed.

Best regards,

{{companyName}}`;

export const cutomPaymentReceivedByCompanyFromClientClone = `{{companyName}}
Hello companyName ,

A payment in the amount of \${{amount}} was sent to you by {{companyName}} . Please allow 2-3 days to process and show in your account. A Payment breakdown is attached in this email for your records.

Thank you!`;

export const customAgentCreateInvoiceClone = `
Dear {{companyName}},

I hope this email finds you well. I am writing to let you know that I have created a new invoice for services rendered. The invoice is attached to this email for your review.

The invoice includes all the relevant details, such as the order number, and the amount due. If you have any questions or concerns regarding the invoice, please do not hesitate to reach out to me.

I appreciate your business and look forward to working with you in the future.

Thank you,

{{agentName}}`;

export const customCompanyInvoiceClone = `
Dear {{clientName}},

 We are writing to inform you that a new invoice has been generated and sent to you in connection with the services we provided. We appreciate your continued trust in our company.

The invoice includes all the relevant details, such as the order number, and the amount due.

If you have any questions or concerns regarding the invoice or the services we provided, please do not hesitate to contact us. Our team is always ready to assist you in any way possible.

Thank you for your prompt attention to this matter.

Best regards,

{{companyName}}`;

export const customAgentInvoiceClone = `
Dear {{companyName}},

 We are writing to send you the invoice for the service that we provided to your company. We appreciate your business and are committed to providing you with excellent service.

Please find attached a detailed breakdown of the charges for your reference.

We kindly request that you settle the invoice in a timely manner. If you have any questions or concerns regarding the invoice, please do not hesitate to contact me at {{email}} or {{phone}} .

Thank you for your prompt attention to this matter.

Best regards,

{{agentName}}`;

export const customClientInvitesCompanyToConnectClone = `
Dear {{name}} ,

We are emailing to inform you that we would like to connect and send orders to you in the future on the CloseWise.com platform.

We appreciate your business and believe that this partnership will enable us to deliver high-quality services to our customers. As a registered user of Closewise.com, you will be able to receive orders, schedule, track status, and manage your orders from our team more efficiently.

To complete the registration process, please follow the steps below:

1. Click on the link provided in this email to create your account on Closewise.com 2. Follow the instructions to set up your account and verify your email address. 3. Once your account is verified, you can log in and start receiving orders.

If you already have an account simply click the connect button in this email. The CloseWise support team is always available to answer any questions you may have and assist you with any issues you encounter at support@closewise.com.

We look forward to a successful partnership with you.. If you require any assistance, please do not hesitate to contact us at {{email}} or {{phone}} .

Best regards,

{{companyName}}`;

export const customClientInvitesUnregisteredSiginingCompanyToConnectOnClosewiseClone = `
Dear {{name}},

We are emailing to inform you that we would like to connect and send orders to you in the future on the CloseWise.com platform.

We appreciate your business and believe that this partnership will enable us to deliver high-quality services to our customers. As a registered user of Closewise.com, you will be able to receive orders, schedule, track status, and manage your orders from our team more efficiently.

To complete the registration process, please follow the steps below:

4. Click on the link provided in this email to create your account on Closewise.com 5. Follow the instructions to set up your account and verify your email address. 6. Once your account is verified, you can log in and start receiving orders.

If you already have an account simply click the connect button in this email. The CloseWise support team is always available to answer any questions you may have and assist you with any issues you encounter at support@closewise.com.

We look forward to a successful partnership with you.. If you require any assistance, please do not hesitate to contact us at {{email}} or {{phone}} .

Best regards,

{{companyName}}`;

export const customCompanyInvitesClientToCreateClosewiseConnectAccountClone = `
Dear {{name}} ,

 {{companyName}} would like to extend an invitation for you to connect with CloseWise.

CloseWise offers a free Connect Portal, which provides a convenient way for you to monitor and send new orders to us. By connecting with CloseWise, you will have access to a streamlined and efficient way of managing your orders with us.

To connect, simply click the button below and follow the steps to complete your registration.

If you have any questions or concerns, please don't hesitate to reach out to CloseWise's support team. They are available to assist you as needed.

Best regards,

{{companyName}}`;

export const customCompanyInvitesClientToConnectClone = `
Dear {{name}} ,

 {{companyName}} would like to extend an invitation for you to connect with CloseWise.

CloseWise offers a free Connect Portal, which provides a convenient way for you to monitor and send new orders to us. By connecting with CloseWise, you will have access to a streamlined and efficient way of managing your orders with us.

To connect, simply click the button below and follow the steps to complete your registration.

If you have any questions or concerns, please don't hesitate to reach out to CloseWise's support team. They are available to assist you as needed.

Best regards,

{{companyName}}`;

export const customAgentFeeUpdateClone = `
Dear {{name}},

 We are writing to inform you that we have updated the fee for the order {{fileNo}} that you have been assigned to. The updated fee is {{agentFee}}.

We believe that the updated fee is a fair reflection of the work required for this order. We value your partnership with us and are committed to providing you with the best possible compensation for your efforts.

If you have any questions or concerns regarding this matter, please do not hesitate to contact us at {{email}} or {{phone}}. We would be happy to discuss this with you and address any queries you may have.

We appreciate your continued dedication and hard work.

Best regards,

{{companyName}}`;

export const customCompanyUploadedDocumentClone = `
Dear {{name}},

We writing to inform you that your documents are now available for download. To access the documents, please follow the instructions below:

1. Click on the link provided in this email to log in to your Closewise account dashboard. 2. Once logged in, navigate to the “Docs Ready” tab. 3. Find the Card titled “[Documents]” and download available uploads.

Please review any instructions pertaining to the order in your order's details.

If you encounter any issues while accessing or downloading the documents, please contact us at {{email}} or {{phone}}. Our support team is always available to assist you.

Best regards,

{{companyName}}`;

export const customCompanyVerifiedDocsClone = `
Dear {{agentName}},

We're happy to inform you that your uploaded documents for the order #{{fileNo}} have been reviewed and approved. Thank you for your hard work and dedication. We value your partnership and look forward to working with you on future projects.

If you have any questions, please contact us at {{email}} or {{phone}}.

Best regards,

{{companyName}}`;

export const customConfirmAppointmentReminderClone = `
Dear {{agentName}} ,

Just a quick reminder to confirm the upcoming appointment with the signers on Closewise. The appointment is scheduled for {{{date}}} at {{time}} with the following signers:

{{listOfSigners}}

Please confirm their availability as soon as possible to avoid any last-minute changes. Let us know immediately if there are any changes to the appointment.

Thank you for your attention to this matter. Please contact us if you have any questions.

Best regards,

{{companyName}}`;

export const customClosingStatusAgentClone = `
Dear {{companyName}},

 I’m reaching out to provide you with an update on the status of your order.

Order File #{{fileNo}}: {{status}}

To review the details of your order in closewise, simply click the button below.

If you have any questions or concerns regarding the status of your order, please don't hesitate to reach out to me.

Best regards,

{{name}}`;

export const customAchPaymentClone = `

Dear {{name}},

 We wanted to let you know that a payment has been initiated via ACH to your bank account for the attached payment breakdown amount.

Please allow 2-3 business days for the funds to appear in your account. If you have any questions or concerns, please don't hesitate to contact our support team.

Thank you for your hard work and dedication to our company.

Best regards,

{{companyName}}
`;

export const customAgentScheduleClone = `

Dear {{name}},

 We are emailing to provide you with the schedule of assignments that we have assigned to you as our trusted agent.

Please find attached the schedule of assignments that includes the following details:

Assignment Description Deadline Client Name Client’s Contact Information Payment Amount

We kindly request that you carefully review the schedule and let us know if you have any questions or concerns. We understand that unforeseen circumstances may arise, and we are always open to discussing any issues you may have regarding the assignments.

If you require any assistance or support, please do not hesitate to contact us at {{companyEmail}} or {{companyPhone}}.

Thank you for your commitment and dedication to {{companyName}}.

Best regards,

{{companyName}}
`;

export const customClosingAssignedClone = `

Hello {{name}},

{{companyName}} has assigned the {{orderType}} for {{{date}}} and {{time}} at {{address}}. If you have any questions or concerns regarding the order, please don't hesitate to reach out via email or in the order chat log. We’d be happy to assist with questions you may have.

Please review and follow any special instructions added in the order including any scan back requirements.

Click the button below to review the order details on CloseWise and confirm your signing appointment with the signers.

Best regards,

{{companyName}}
`;

export const customClosingUnassignedClone = `
Dear {{name}},

You've been unassigned from the order #{{fileNo}} previously set for {{{date}}} at {{time}} .Please do reach out to borrowers or proceed with closing.

We are writing to inform you that we have unassigned you from the order #{{fileNo}} previously set for {{{date}}} at {{time}}, due to unforeseen circumstances beyond our control. We apologize for any inconvenience caused and appreciate your understanding in this matter.

If you have any questions or concerns regarding this matter, please do not hesitate to contact us at {{email}} or {{phone}} .

Thank you for your understanding.

Best regards,

{{companyName}}`;

export const customClosingStatusCompanyClone = `
Dear {{name}} ,

 {{companyName}} is reaching out to provide you with an update on the status of your order with us.

Order File# {{fileNo}} : {{status}}

To review the details of your order, simply click the button below.

If you have any questions or concerns regarding the status of your order, please don't hesitate to reach out to us. Our team is always here to assist you.

Best regards,

{{companyName}}
`;

export const customNewMatchedClosingClone = `
Dear {{name}} ,

{{companyName}} is reaching out to inquire about your availability for a:

{{closingType}} for {{{date}}} and {{time}} at {{city}} , {{state}} . The offered fee for this order is {{offeredFee}}

To let us know if you are available, decline the offer, or make a counter offer, simply click the button below. You will be able to review all order details before making your final decision.

If you have any questions or concerns regarding this order offer, please don't hesitate to reach out to us. Our team is always here to assist you.

Best regards,

{{companyName}}`;

export const customAppointmentPreReminderClone = `

Dear {{name}},

This is a friendly reminder that your scheduled appointment is set to begin in 15 minutes. We hope that you are on time or have arrived at the designated location.

To ensure a smooth start to your appointment, please remember to check-in on Closewise.com and let us know that you are on time. This will enable us to confirm your presence and make any necessary preparations for your appointment.

If you encounter any unexpected delays or need to reschedule your appointment, please notify us as soon as possible so that we can make any necessary adjustments.

Thank for your cooperation.
Best regards,

{{companyName}}
`;

export const customAppointmentPostReminderClone = `
Dear {{name}},

This is a gentle reminder that it has been an hour since the start time of the order #{{fileNo}} you are working on, and we kindly request that you update the completion status on Closewise.com.

It is essential that we keep track of the progress of our orders to ensure timely delivery and to provide our clients with up-to-date information. By updating the completion status, we can accurately track the status of the order and take appropriate action if necessary.

To update the order status you can click the link to select an order status in your CloseWise account. If you have any issues please feel free to contact us at {{email}}.

Thank you for your prompt attention to this matter.

Best regards,

{{companyName}}
`;

export const customAgentUploadedDocumentClone = `
Dear {{companyName}},

I am writing to inform you that I have successfully uploaded the scanbacks for #{{fileNo}} . All the necessary documents have been uploaded onto the system and are ready for review.

Please let me know if there are any further actions required from my end or if you need any additional information.

Thank you for your support throughout the process. I look forward to continuing to work with you.

Best regards,

{{agentName}}
`;
export const footerClone = `CloseWise | 501 W Broadway San Diego, CA 92101 | (619)800-4426

http://CloseWise.com

Copyright © 2023 CloseWise, LLC. All rights reserved.

Confidentiality Notice: This email and any attachments may contain confidential and proprietary information. If you are not the intended recipient, you are hereby notified that any use, dissemination, distribution, or copying of this email and its attachments is strictly prohibited. If you have received this email in error, please delete the message and its attachments and notify us immediately.

This email is intended only for the use of the person to whom it is addressed and may contain information that is privileged, confidential, and exempt from disclosure under applicable law. Any unauthorized review, use, disclosure or distribution is strictly prohibited. If you have received this email in error, please immediately delete this email and notify the sender.`;
