import React from "react";
import {
  Card,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  CustomInput,
  Button,
  Tooltip,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  getClientProfileDetail,
  updateClientProfileDetail,
} from "../../../http/http-calls";
import {
  getPhoneNumberFromBrackets,
  showToast,
  deepClone,
  errorHandler,
  uploadFileOnServer,
} from "../../../helper-methods";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import { RegexConfig } from "../../../config/RegexConfig";
import { updateUserObj } from "../../../redux/actions/user-data";
import usaStates from "../../../config/usa_states_titlecase";
import TextEditor from "../components/TextEdittor";
import {
  googlePlaceSearch,
  googlePlaceDetails,
} from "../../../helper-methods/googleService";
import { DEFAULT_PROFILE_PICTURE } from "../../../config";

class ClientProfileDetail extends React.Component {
  state = {
    clientDetail: null,
    formFields: {
      search: {
        value: "",
        placeId: null,
        isDirty: false,
        isValidate: true,
      },
      street: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      line1: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      line2: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      city: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      state: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      zip: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      country: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      companyName: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      email: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      firstname: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      lastname: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      homePhone: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      workPhone: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      phone: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      phoneCountry: {
        value: "US",
        isValid: false,
        isDirty: false,
      },
      websiteURL: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      emergencyContactName: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      emergencyContactPhone: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      accountingEmail: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      schedulingEmail: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      note: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      password: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      logo: {
        file: {
          previewBlob: null,
          uploadData: null,
        },
        error: null,
        isDirty: false,
      },
      profilePicture: {
        file: {
          previewBlob: null,
          uploadData: null,
        },
        error: null,
        isDirty: false,
      },
      companySignature: {
        file: {
          previewBlob: null,
          uploadData: null,
        },
        error: null,
        isDirty: false,
      },
      signature: {
        value: "",
        isValid: false,
        isDirty: false,
      },
    },
    showPassword: {
      password: false,
    },
    changePassword: false,
    isSearch: false,
    tooltipOpen1: false,
    tooltipOpen: false,
    activeTab: "1",
  };

  _toggleTab = (tab = "1") => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  toggle1 = () => {
    this.setState({ tooltipOpen1: !this.state.tooltipOpen1 });
  };

  toggle = () => {
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  };
  componentDidMount = async () => {
    try {
      const { userData } = this.props;

      if (!userData?.user?.isAssistant) {
        this.props.showLoader("Fetching Details...");
        await this._getClientProfileDetail();
        this.props.hideLoader();
      }
    } catch (error) {
      errorHandler(error);
      this.props.hideLoader();
    }
  };

  _setFormData = (data) => {
    const { formFields } = deepClone(this.state);

    if (data && Object?.keys(data)?.length) {
      formFields["firstname"].value = data.name.first;
      formFields["lastname"].value = data.name.last;

      formFields["companyName"].value = data.companyName
        ? data.companyName
        : "";
      formFields["email"].value = data.email ? data.email : "";
      formFields["accountingEmail"].value = data.accountingEmail
        ? data.accountingEmail
        : "";
      formFields["schedulingEmail"].value = data.schedulingEmail
        ? data.schedulingEmail
        : "";
      formFields["websiteURL"].value = data.websiteURL ? data.websiteURL : "";
      formFields["note"].value = data.note ? data.note : "";

      formFields["phoneCountry"].value = data.phoneCountry
        ? data.phoneCountry
        : "US";
      formFields["homePhone"].value = data.homePhone
        ? getPhoneNumberFromBrackets(data.homePhone)
        : "";
      formFields["workPhone"].value = data.workPhone
        ? getPhoneNumberFromBrackets(data.workPhone)
        : "";
      formFields["phone"].value = data.phone
        ? getPhoneNumberFromBrackets(data.phone)
        : "";

      if (data.address) {
        formFields["line1"].value = data.address.line1
          ? data.address.line1
          : "";
        formFields["line2"].value = data.address.line2
          ? data.address.line2
          : "";
        formFields["city"].value = data.address.city ? data.address.city : "";
        formFields["state"].value = data.address.state
          ? data.address.state
          : "";
        formFields["zip"].value = data.address.zip ? data.address.zip : "";
        formFields["country"].value = data.address.country
          ? data.address.country
          : "";
      }

      if (data.afterHoursContact) {
        formFields["emergencyContactName"].value = data.afterHoursContact.name
          ? data.afterHoursContact.name
          : "";
        formFields["emergencyContactPhone"].value = data.afterHoursContact.phone
          ? getPhoneNumberFromBrackets(data.afterHoursContact.phone)
          : "";
      }

      formFields["logo"].file = {
        previewBlob: data.logo ? data.logo : null,
        uploadData: null,
      };
      formFields["profilePicture"].file = {
        previewBlob: data.profilePicture ? data.profilePicture : null,
        uploadData: null,
      };
      formFields["companySignature"].file = {
        previewBlob: data.companySignature ? data.companySignature : null,
        uploadData: null,
      };
      formFields["signature"].value = data.signature ? data.signature : "";

      this.setState({
        formFields,
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  _getClientProfileDetail = () => {
    return new Promise((resolve, reject) => {
      const { userData } = this.props;

      getClientProfileDetail()
        .then((res) => {
          this.setState({ clientDetail: res?.client || {} });
          this._setFormData(res?.client || {});
          if (!userData?.user.isAssistant) {
            this.props.updateUserObj(res?.client);
          }
          this.props.hideLoader();
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields, isSearch, activeTab } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty) {
          switch (key) {
            case "companyName":
            case "lastname":
            case "firstname": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "line1":
            case "city":
            case "state":
            case "zip":
            case "country": {
              if (activeTab === "1") {
                if (!isSearch) {
                  if (
                    formFields[key].value &&
                    formFields[key].value.trim().length
                  ) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Required";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              }
              break;
            }
            case "search": {
              if (isSearch) {
                if (formFields[key].value?.trim().length) {
                  if (formFields[key].placeId) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Invalid Address";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            case "websiteURL": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                if (
                  RegexConfig.url.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid URL";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            case "email": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                if (
                  RegexConfig.email.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "schedulingEmail":
            case "accountingEmail": {
              if (
                formFields[key]?.value &&
                formFields[key]?.value?.trim()?.length
              ) {
                if (
                  RegexConfig.email.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            // phone number is not required - validation
            case "homePhone":
            case "workPhone":
            case "emergencyContactPhone": {
              if (formFields[key].value && formFields[key].value.length) {
                if (
                  RegexConfig.phone.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  isFormValid = false;
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Phone Number";
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            // phone number is required - validation
            case "phone": {
              if (formFields[key].value && formFields[key].value.length) {
                if (
                  RegexConfig.phone.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  isFormValid = false;
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Phone Number";
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "password": {
              if (formFields[key].value?.trim().length) {
                if (
                  RegexConfig.password.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].error = null;
                  formFields[key].isDirty = false;
                } else {
                  formFields[key].error =
                    "Min password length should be 8 , Req 1 digit , a special char";
                  isFormValid = false;
                }
              } else {
                formFields[key].error = null;
                formFields[key].isDirty = false;
              }
              break;
            }
            default:
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _updateFileImage = (fieldName, event) => {
    const { formFields } = deepClone(this.state);

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      let objFile = event.target.files[0];
      let objFileType = objFile.type.split("/")[0];
      if (objFileType === "image") {
        formFields[fieldName].file = {
          previewBlob: URL.createObjectURL(objFile),
          uploadData: objFile,
          type: objFileType,
        };
        this.setState({ formFields });
      } else {
        showToast("Only Image file is allowed", "error");
      }
    }
    this.setState({ formFields });
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = this.state;
    if (
      fieldName === "homePhone" ||
      fieldName === "phone" ||
      fieldName === "workPhone"
    ) {
      formFields[fieldName].value = value.replace(/\D/g, "");
    } else {
      formFields[fieldName].value = value;
    }
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // Validate
      this._validateForm();
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _updateClientProfileDetail = (id, payload) => {
    const { formFields } = this.state;
    updateClientProfileDetail({ id, payload })
      .then(async (res) => {
        showToast("Profile has been Updated", "success");
        this.props.hideLoader();
        formFields["password"].value = "";
        this.setState({
          formFields,
          showPassword: {
            password: false,
          },
          changePassword: false,
        });
      })
      .catch((error) => {
        errorHandler(error);
        this.props.hideLoader();
      });
  };

  _onSaveProfileData = async () => {
    await this._markAllFieldDirty();

    const isFormValid = await this._validateForm();

    if (!isFormValid) {
      showToast("Please Fill The Required Fields", "error");
      return;
    }

    this.props.showLoader("Update Client Profile...");

    const { formFields, clientDetail } = this.state;

    const payload = {
      companyName: formFields.companyName.value.trim(),
      websiteURL: formFields.websiteURL.value.trim(),
      email: formFields.email.value.trim(),
      name: {
        first: formFields.firstname.value.trim(),
        last: formFields.lastname.value.trim(),
      },
      address: {
        line1: formFields.line1.value.trim(),
        line2: formFields?.line2?.value?.trim() || "",
        city: formFields.city.value.trim(),
        state: formFields.state.value.trim(),
        zip: formFields.zip.value.trim(),
        country: formFields.country.value.trim(),
      },
      accountingEmail:
        clientDetail?.accountingEmail !==
        formFields.accountingEmail.value?.trim()
          ? formFields.accountingEmail.value?.trim()
          : undefined,
      schedulingEmail: formFields.schedulingEmail.value?.trim(),
      note: formFields.note.value.trim(),
      // phoneCountry is static - set to -> 'US'
      phoneCountry: formFields.phoneCountry.value,
      password: formFields.password?.value?.trim().length
        ? formFields.password.value
        : undefined,
      companySignature: formFields.companySignature?.value?.trim().length
        ? formFields.companySignature.value
        : "",
      signature: formFields.signature?.value?.trim().length
        ? formFields.signature.value
        : undefined,
    };

    if (formFields.line2.value) {
      payload.address["line2"] = formFields.line2.value.trim();
    }
    if (formFields.phone.value) {
      payload["phone"] = `(+1)${formFields.phone.value}`;
    }
    if (formFields.homePhone.value) {
      payload["homePhone"] = `(+1)${formFields.homePhone.value}`;
    }
    if (formFields.workPhone.value) {
      payload["workPhone"] = `(+1)${formFields.workPhone.value}`;
    }
    if (
      formFields.emergencyContactName.value ||
      formFields.emergencyContactPhone.value
    ) {
      payload["afterHoursContact"] = {
        name: formFields.emergencyContactName.value
          ? formFields.emergencyContactName.value.trim()
          : "",
        phone: formFields.emergencyContactPhone.value
          ? `(+1)${formFields.emergencyContactPhone.value}`
          : "",
      };
    }

    const uploadImages = [];

    if (formFields.logo.file && formFields.logo.file.uploadData) {
      uploadImages.push({
        forKeyName: "logo",
        ...formFields.logo.file,
      });
    }

    if (
      formFields.profilePicture.file &&
      formFields.profilePicture.file.uploadData
    ) {
      uploadImages.push({
        forKeyName: "profilePicture",
        ...formFields.profilePicture.file,
      });
    }

    if (
      formFields.companySignature.file &&
      formFields.companySignature.file.uploadData
    ) {
      uploadImages.push({
        forKeyName: "companySignature",
        ...formFields.companySignature.file,
      });
    }

    if (uploadImages?.length) {
      const uploadedRes = await uploadFileOnServer(uploadImages);

      uploadedRes.forEach((each) => {
        payload[each.forKeyName] = each.url;
        formFields[each.forKeyName].file.uploadData = null;
      });

      this.setState({ formFields });
    }

    this._updateClientProfileDetail(clientDetail?._id, payload);
  };

  _onDeleteSignatureImage = () => {
    const { formFields } = this.state;

    formFields["companySignature"].file = {
      previewBlob: null,
      uploadData: null,
    };
    this.setState({ formFields });
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields } = deepClone(this.state);
    formFields[fieldName].value = value;
    this.setState({ formFields });

    googlePlaceSearch(value)
      .then((res) => {
        this.setState({ searchResult: res, show: fieldName });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getPlaceDetail = async (pid) => {
    const { formFields } = deepClone(this.state);

    let detail = await googlePlaceDetails(pid.place_id);

    if (detail) {
      if (detail.postal) {
        formFields["line1"].value = detail.address;
        formFields["line2"].value = "";
        formFields["city"].value = detail.city;
        let state = usaStates.filter((state) => state.name === detail.state)[0]
          ?.abbreviation;
        formFields["state"].value = state;
        formFields["zip"].value = detail.postal;
        formFields["country"].value = detail.country;
        formFields["search"].value =
          detail.address +
          "," +
          detail.city +
          "," +
          detail.postal +
          "," +
          detail.country;
        formFields["search"].placeId = pid.place_id;
        formFields["search"].isDirty = true;
      } else {
        showToast("Invalid Address", "error");
      }

      this.setState({ formFields, show: true }, () => {
        this._validateForm();
      });
    }
  };

  render() {
    const { activeTab, clientDetail, formFields, showPassword, isSearch } =
      this.state;

    return (
      <>
        <Card>
          <Nav tabs className="greyBg">
            <NavItem>
              <NavLink
                className={activeTab === "1" ? "active" : ""}
                onClick={() => {
                  this._toggleTab("1");
                }}
              >
                Address Info
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={activeTab === "2" ? "active" : ""}
                onClick={() => {
                  this._toggleTab("2");
                }}
              >
                Contact Info
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "3" ? "active" : ""}
                onClick={() => {
                  this._toggleTab("3");
                }}
              >
                Personal Info
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row form>
                <Col md={12}>
                  <Label className="uploadProfile">
                    <Input
                      type="file"
                      style={{ display: "none" }}
                      accept="image/x-png,image/gif,image/jpeg"
                      value=""
                      onChange={(event) => this._updateFileImage("logo", event)}
                    />
                    <img
                      src={
                        formFields.logo.file && formFields.logo.file.previewBlob
                          ? formFields.logo.file.previewBlob
                          : require("../../../assets/img/default_user_icon.svg")
                              .default
                      }
                      alt="logo"
                      style={{ objectFit: "contain" }}
                    />
                    <i className="fa fa-pencil" />
                  </Label>
                </Col>
                <Col md={6}>
                  <FormGroup className="floatingLabel">
                    <Input
                      type="text"
                      name="companyName"
                      placeholder=" "
                      value={formFields.companyName.value}
                      onChange={(e) =>
                        this._updateFieldValue("companyName", e.target.value)
                      }
                    />
                    <Label>Company Name</Label>
                    {formFields.companyName.error && (
                      <div className="validation-error">
                        {formFields.companyName.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={6}>
                  <FormGroup className="floatingLabel withInputGroup">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <img
                            src={
                              require("../../../assets/img/globeIcon.svg")
                                .default
                            }
                            alt="website"
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder=" "
                        name="websiteURL"
                        value={formFields?.websiteURL?.value}
                        onChange={(e) =>
                          this._updateFieldValue("websiteURL", e.target.value)
                        }
                      />
                      <Label>Website</Label>
                    </InputGroup>
                    {formFields?.websiteURL?.error && (
                      <div className="validation-error">
                        {formFields?.websiteURL?.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <div className="d-flex mt-1 mb-3 align-items-start">
                    <Label className="mb-0 mr-2">Search Address</Label>
                    <div className="customSwitch">
                      <CustomInput
                        type="switch"
                        checked={!isSearch}
                        onChange={(e) =>
                          this.setState({
                            isSearch: !e.target.checked,
                          })
                        }
                        id="closingAddress_Custom_Switch"
                        name="closingAddress"
                        label="Enter Manually"
                      />
                    </div>
                  </div>
                </Col>
                {isSearch ? (
                  <Col md={12}>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        placeholder=" "
                        value={formFields.search.value}
                        name="search"
                        onChange={(event) =>
                          this._onChangeFormField("search", event.target.value)
                        }
                      />
                      <Label>Search Address</Label>

                      {this.state.show === "search" ? (
                        <ListGroup flush className="customSearchOptions">
                          {this.state.searchResult &&
                            this.state.searchResult.map((i) => {
                              return (
                                <ListGroupItem
                                  key={i.place_id}
                                  style={{ cursor: "pointer" }}
                                  onClick={() => this._getPlaceDetail(i)}
                                >
                                  {i.description}
                                </ListGroupItem>
                              );
                            })}
                        </ListGroup>
                      ) : null}

                      {formFields.search.error && (
                        <div className="validation-error">
                          {formFields.search.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                ) : (
                  <>
                    <Col md={12}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          value={formFields.line1.value}
                          placeholder=" "
                          onChange={(e) =>
                            this._updateFieldValue("line1", e.target.value)
                          }
                        />
                        <Label>Address Line 1</Label>
                        {formFields.line1.error && (
                          <div className="validation-error">
                            {formFields.line1.error}
                          </div>
                        )}
                      </FormGroup>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          placeholder=" "
                          value={formFields.line2.value}
                          onChange={(e) =>
                            this._updateFieldValue("line2", e.target.value)
                          }
                        />
                        <Label>Address Line 2</Label>
                      </FormGroup>
                    </Col>

                    <Col sm={6} lg={6}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          name="city"
                          placeholder=" "
                          value={formFields.city.value}
                          onChange={(e) =>
                            this._updateFieldValue("city", e.target.value)
                          }
                        />
                        <Label>City</Label>
                        {formFields.city.error && (
                          <div className="validation-error">
                            {formFields.city.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm={6} lg={6}>
                      <FormGroup className="floatingLabel">
                        <div className="custom-select-wrapper">
                          <Input
                            type="select"
                            name="state"
                            placeholder=" "
                            value={formFields.state.value}
                            onChange={(e) =>
                              this._updateFieldValue("state", e.target.value)
                            }
                          >
                            <option value="">Select</option>
                            {usaStates.map((state) => (
                              <option
                                key={state.abbreviation}
                                value={state.abbreviation}
                              >
                                {state.name}
                              </option>
                            ))}
                          </Input>
                          <Label>State</Label>
                        </div>
                        {formFields.state.error && (
                          <div className="validation-error">
                            {formFields.state.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm={6} lg={6}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          name="zip"
                          placeholder=" "
                          value={formFields.zip.value}
                          onChange={(e) =>
                            this._updateFieldValue("zip", e.target.value)
                          }
                        />
                        <Label>Zip</Label>
                        {formFields.zip.error && (
                          <div className="validation-error">
                            {formFields.zip.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm={6} lg={6}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          name="country"
                          placeholder=" "
                          value={formFields.country.value}
                          onChange={(e) =>
                            this._updateFieldValue("country", e.target.value)
                          }
                        />
                        <Label>Country</Label>
                        {formFields.country.error && (
                          <div className="validation-error">
                            {formFields.country.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </>
                )}
                <Col md={12} className="text-center my-3">
                  <Button
                    color="primary"
                    style={{ width: 200 }}
                    onClick={() => this._onSaveProfileData()}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2" className="p-3">
              <Row form>
                <Col sm="6" lg="6">
                  <FormGroup className="floatingLabel withInputGroup">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>+1</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        value={formFields.homePhone.value}
                        onChange={(e) =>
                          this._updateFieldValue("homePhone", e.target.value)
                        }
                        placeholder=" "
                      />
                      <Label>Scheduling Phone</Label>
                    </InputGroup>
                    {formFields.homePhone.error && (
                      <div className="validation-error">
                        {formFields.homePhone.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col sm="6" lg="6">
                  <FormGroup className="floatingLabel withInputGroup">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>+1</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder=" "
                        value={formFields.phone.value}
                        onChange={(e) =>
                          this._updateFieldValue("phone", e.target.value)
                        }
                      />
                      <Label>General Phone </Label>
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          {clientDetail?.isPhoneVerified ? (
                            <span className="text-success fs-12">Verified</span>
                          ) : /* <span className="text-danger fs-12">
                              Unverified
                            </span> */
                          null}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    {formFields.phone.error && (
                      <div className="validation-error">
                        {formFields.phone.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col sm="6" lg="6">
                  <FormGroup className="floatingLabel withInputGroup">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>+1</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        value={formFields.workPhone.value}
                        placeholder=" "
                        onChange={(e) =>
                          this._updateFieldValue("workPhone", e.target.value)
                        }
                      />
                      <Label>Accounting Phone</Label>
                    </InputGroup>
                    {formFields.workPhone.error && (
                      <div className="validation-error">
                        {formFields.workPhone.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col sm="6" lg="6">
                  <FormGroup className="floatingLabel">
                    <InputGroup>
                      <Input
                        type="text"
                        placeholder=" "
                        name="schedulingEmail"
                        value={formFields.schedulingEmail.value}
                        onChange={(e) =>
                          this._updateFieldValue(
                            "schedulingEmail",
                            e.target.value
                          )
                        }
                      />
                      <Label>Scheduling Email</Label>
                      <InputGroupAddon addonType="append">
                        <InputGroupText id="TooltipExample">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    <Tooltip
                      placement="top"
                      isOpen={this.state.tooltipOpen}
                      target="TooltipExample"
                      toggle={this.toggle}
                    >
                      If left blank this email defaults to general email
                    </Tooltip>

                    {formFields.schedulingEmail.error && (
                      <div className="validation-error">
                        {formFields.schedulingEmail.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col sm="6" lg="6">
                  <FormGroup className="floatingLabel">
                    <InputGroup>
                      <Input
                        type="text"
                        name="accountingEmail"
                        placeholder=" "
                        value={formFields.accountingEmail.value}
                        onChange={(e) =>
                          this._updateFieldValue(
                            "accountingEmail",
                            e.target.value
                          )
                        }
                      />
                      <Label>Accounting Email</Label>
                      <InputGroupAddon addonType="append">
                        <InputGroupText id="TooltipExample1">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    <Tooltip
                      placement="top"
                      isOpen={this.state.tooltipOpen1}
                      target="TooltipExample1"
                      toggle={this.toggle1}
                    >
                      If left blank this email defaults to general email
                    </Tooltip>
                    {formFields.accountingEmail.error && (
                      <div className="validation-error">
                        {formFields.accountingEmail.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col sm="6" lg="6">
                  <FormGroup className="floatingLabel">
                    <InputGroup>
                      <Input
                        type="text"
                        name="email"
                        placeholder=" "
                        value={formFields.email.value}
                        onChange={(e) =>
                          this._updateFieldValue("email", e.target.value)
                        }
                        disabled
                      />
                      <Label>General Email </Label>
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          {clientDetail?.isEmailVerified ? (
                            <span className="text-success fs-12">Verified</span>
                          ) : (
                            <span className="text-danger fs-12">
                              Unverified
                            </span>
                          )}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    {formFields.email.error && (
                      <div className="validation-error">
                        {formFields.email.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <div className="CardTitle mt-2">
                    <h2 class="sectionTtile"> After Hours Contact </h2>
                  </div>
                </Col>
                <Col sm="6" lg="6">
                  <FormGroup className="floatingLabel">
                    <Input
                      type="text"
                      placeholder=" "
                      value={formFields.emergencyContactName.value}
                      onChange={(e) =>
                        this._updateFieldValue(
                          "emergencyContactName",
                          e.target.value
                        )
                      }
                    />
                    <Label>Name</Label>
                  </FormGroup>
                </Col>
                <Col sm="6" lg="6">
                  <FormGroup className="floatingLabel withInputGroup">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>+1</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder=" "
                        value={formFields.emergencyContactPhone.value}
                        onChange={(e) =>
                          this._updateFieldValue(
                            "emergencyContactPhone",
                            e.target.value
                          )
                        }
                      />
                      <Label>Phone</Label>
                    </InputGroup>
                    {formFields.emergencyContactPhone.error && (
                      <div className="validation-error">
                        {formFields.emergencyContactPhone.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={12}>
                  <FormGroup className="floatingLabel">
                    <Input
                      type="textarea"
                      rows={6}
                      value={formFields.note.value}
                      onChange={(e) =>
                        this._updateFieldValue("note", e.target.value)
                      }
                      placeholder=" "
                    />
                    <Label>Note</Label>
                    {formFields.note.error && (
                      <div className="validation-error">
                        {formFields.note.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={12} className="text-center my-3">
                  <Button
                    color="primary"
                    style={{ width: 200 }}
                    onClick={() => this._onSaveProfileData()}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Label className="uploadProfile">
                <Input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/x-png,image/gif,image/jpeg"
                  value=""
                  onChange={(event) =>
                    this._updateFileImage("profilePicture", event)
                  }
                />
                <img
                  src={
                    formFields.profilePicture.file &&
                    formFields.profilePicture.file.previewBlob
                      ? formFields.profilePicture.file.previewBlob
                      : DEFAULT_PROFILE_PICTURE
                  }
                  alt="Profile Img"
                />
                <i className="fa fa-pencil" />
              </Label>

              <small className="text-center d-block mx-auto text-muted">
                Your photo will appear on your CloseWise profile and be visible
                to consumers and companies.
              </small>

              <Row form className="mt-4">
                <Col sm={6} lg={6}>
                  <FormGroup className="floatingLabel">
                    <Input
                      type="text"
                      placeholder=" "
                      name="firstname"
                      value={formFields.firstname.value}
                      onChange={(e) =>
                        this._updateFieldValue("firstname", e.target.value)
                      }
                    />
                    <Label>First Name</Label>
                    {formFields.firstname.error && (
                      <div className="validation-error">
                        {formFields.firstname.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col sm={6} lg={6}>
                  <FormGroup className="floatingLabel">
                    <Input
                      type="text"
                      name="lastname"
                      placeholder=" "
                      value={formFields.lastname.value}
                      onChange={(e) =>
                        this._updateFieldValue("lastname", e.target.value)
                      }
                    />
                    <Label>Last Name</Label>
                    {formFields.lastname.error && (
                      <div className="validation-error">
                        {formFields.lastname.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={12} className="mb-4 mt-3">
                  {formFields.companySignature?.file?.previewBlob ? (
                    <>
                      <div className="signaturePreview">
                        <img
                          src={formFields.companySignature.file.previewBlob}
                          alt="Signature"
                        />
                        {formFields.companySignature.file &&
                        formFields.companySignature.file.previewBlob ? (
                          <Button
                            color="danger"
                            className="deletePreview"
                            onClick={() => this._onDeleteSignatureImage()}
                          >
                            <img
                              src={
                                require("../../../assets/img/close.png").default
                              }
                              alt="deletePreview"
                            />
                          </Button>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <>
                      <Label className="uploadLink text-primary">
                        <Input
                          type="file"
                          style={{ display: "none" }}
                          accept="image/x-png,image/gif,image/jpeg"
                          value=""
                          onChange={(event) =>
                            this._updateFileImage("companySignature", event)
                          }
                        />
                        Add company signature to automated emails.
                      </Label>
                    </>
                  )}
                </Col>
                <Col md={12}>
                  <TextEditor
                    content={formFields.signature.value}
                    onChange={(event) =>
                      this._updateFieldValue("signature", event)
                    }
                  />

                  <div className="my-4">
                    <CustomInput
                      type="checkbox"
                      name="unassigned"
                      checked={this.state.changePassword}
                      onChange={(e) =>
                        this.setState({
                          changePassword: !this.state.changePassword,
                        })
                      }
                      id="passwordChange"
                      label=" Change Password"
                    />
                  </div>
                </Col>
                {this.state.changePassword ? (
                  <Col md={6}>
                    <FormGroup className="floatingLabel">
                      <InputGroup>
                        <Input
                          type={showPassword.password ? "text" : "password"}
                          value={formFields.password.value}
                          name="password"
                          onChange={(event) =>
                            this._updateFieldValue(
                              "password",
                              event.target.value
                            )
                          }
                        />
                        <Label>Enter New Password</Label>
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <img
                              src={
                                require(`../../../assets/img/${
                                  showPassword.password
                                    ? "eyeIcon.svg"
                                    : "eyeIconSlash.svg"
                                }`).default
                              }
                              onClick={() =>
                                this.setState({
                                  showPassword: {
                                    ...this.state.showPassword,
                                    password: !this.state.showPassword.password,
                                  },
                                })
                              }
                              alt="eye icon"
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>

                      {formFields.password.error && (
                        <div className="validation-error">
                          {formFields.password.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                ) : null}
                <Col md={12} className="text-center my-3">
                  <Button
                    color="primary"
                    style={{ width: 200 }}
                    onClick={() => this._onSaveProfileData()}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserObj: (user) => dispatch(updateUserObj(user)),
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientProfileDetail);
