import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  // Table,
  FormGroup,
  Input,
  Label,
  Badge,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";

import {
  capitalize,
  deepClone,
  // formatDateHideYearIfSame,
  formatDateAsPerTimeZOne,
  getTimeZoneAbbr,
  // formatTime,
  // showToast,
  // formatCurrencyValue,
  // getLoggedInUserId,
  // structureQueryParams,
  // openUrlOnNewTab,
} from "../../../helper-methods";
import CustomTable from "./CustomTable";

class PayAgentModal extends Component {
  state = {
    closings: [],
    closingsTotalCount: 0,
    closingsHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      { id: "closingNumber", label: "Order ID", noSort: true },
      { id: "appointmentDate", label: "Appointment Date", noSort: true },
      { id: "agentFee", label: "Fee", noSort: true },
      { id: "_borrower", label: "Signer", noSort: true },
      // { id: "orderid", label: "Order ID", noSort: true },
      // { id: "fileNumber", label: "File No.", noSort: true },
      // { id: "_agent", label: "Agent", noSort: true },
      // { id: "status", label: "Status", noSort: true },
      // { id: "duePayment", label: "Balance Due", noSort: true },
      // { id: "action", label: "Action", noSort: true },
    ],
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    },
    loading: {
      showDataLoading: false,
      markPaidLoading: false,
    },
    note: "",
  };

  _closeModal = (action) => {
    console.log("in _closeModal");
  };

  //   componentDidMount() {
  //     console.log("componentDidMount", this.props);
  //   }

  componentDidUpdate = async (previousProps, previousState) => {
    const { isOpen, data } = this.props;
    // console.log("componentDidMount", this.props);

    if (isOpen && data && previousProps.isOpen !== isOpen) {
      //   console.log("data",data);
      //   // this._getClientById(data.id);
      //   try {
      //         // await this._getDefaultCard();
      //       } catch (error) {
      //         this.setState({ error });
      //         showToast(
      //           error.reason && error.reason.length ? error.reason : "Server Error",
      //           "error"
      //         );
      //       }

      this.setState({
        loading: {
          showDataLoading: false,
          markPaidLoading: false,
        },
        closings: data,
        note: "",
      });
    }
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "_borrower": {
        // return row && row._borrower.name.full
        //   ? capitalize(row._borrower.name.full)
        //   : "N/A";
        return (
          <div>
            {/* show the below span only when there are more than 1 signers  */}
            {(cell && capitalize(cell[0]?.name?.full)) || "N/A"}{" "}
            {cell && cell?.length > 1 && (
              <>
                <span
                  id={`PopoverLegacy${row._id}`}
                  className="themeColor cursorPointer"
                  style={{ fontWeight: "600" }}
                >
                  +{cell.length - 1}
                </span>
                <UncontrolledPopover
                  trigger="legacy"
                  placement="bottom"
                  target={`PopoverLegacy${row._id}`}
                  className="signerPopover"
                >
                  <PopoverHeader>Signers</PopoverHeader>
                  <PopoverBody>
                    {cell.map((item) => (
                      <p>{capitalize(item.name.full)}</p>
                    ))}
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            )}
          </div>
        );
      }
      case "appointmentDate": {
        return cell && row.appointmentDate ? (
          <>
            {" "}
            {formatDateAsPerTimeZOne(cell, row.closingAddress.timeZone)}
            <span className="mt-1">
              {getTimeZoneAbbr(cell, row.closingAddress.timeZone)}
            </span>
          </>
        ) : (
          "N/A"
        );
      }

      case "fileNumber": {
        return row ? row._closing[0].fileNumber : "N/A";
      }

      case "_closing": {
        return cell && cell.length ? (
          <span
            className="textUnderline themeColor cursorPointer"
            style={{ fontWeight: 500 }}
            onClick={() => this._onToggleJobsIncludedModal(true, row)}
          >
            {cell.length}
          </span>
        ) : (
          "0"
        );
      }

      case "agentFee": {
        return cell ? "$" + cell : "N/A";
      }

      default: {
        return cell;
      }
    }
  };

  _calculateAgentTotalFee = () => {
    const { closings } = deepClone(this.state);
    let arr = closings.map((item) =>
      item.agentFee !== undefined ? item.agentFee : 0
    );
    return arr.reduce((a, b) => a + b, 0);
  };
  _onChangeField = (type, value) => {
    this.setState({
      [type]: value,
    });
  };

  _recordPayment = (note) => {
    this.setState({
      loading: {
        ...this.state.loading,
        markPaidLoading: true,
      },
    });
    this.props.recordPayment(note);
  };
  render() {
    const {
      closingsHeaderKeys,
      closings,
      closingsTotalCount,
      tableConfig,
      loading,
      note,
    } = deepClone(this.state);

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle(null)}
        className="modal-lg modal-dialog-scrollable"
      >
        <ModalHeader toggle={() => this.props.toggle(null)}>
          Make Payment
        </ModalHeader>
        <ModalBody>
          
          {closingsHeaderKeys && closingsHeaderKeys.length && (
            <CustomTable
              pageNumber={tableConfig.pageNumber}
              tableData={closings}
              headerKeys={closingsHeaderKeys}
              dataFormat={this._dataFormat}
              totalCount={closingsTotalCount}
              rowSelection={false}
              onPaginate={(pageNumber, pageSize) =>
                this._paginate(pageNumber, pageSize)
              }
              onSortChange={(sortName, sortOrder) =>
                this._onSortChange(sortName, sortOrder)
              }
              showTableLoading={loading.showTableLoading}
            // setSelectedRows={(data) => this._setSelectedRows(data)}
            //   ref={this.tableRef}
            />
          )}

          <FormGroup className="floatingLabel mt-3">
            <Input
              type="textarea"
              rows="3"
              placeholder=" "
              value={note}
              onChange={(e) => this._onChangeField("note", e.target.value)}
            />
            <Label>Notes</Label>
          </FormGroup>
          <p className="text-primary text-center" style={{ fontWeight: 600 }}>
            Total: ${this._calculateAgentTotalFee()}
          </p>
        </ModalBody>
        <ModalFooter>
          {/* <Button
              className="modalBtnCancel px-3"
              onClick={() => this.props.toggle()}
            >
              Cancel
            </Button> */}
          <Button
            color="primary"
            outline
            disabled={loading.markPaidLoading ? true : false}
            onClick={() => this._recordPayment(this.state.note)}
          >
            Record Payment
          </Button>
          <Button
            color="primary"
            onClick={() => this.props.openAchModal(this.state.note)}
          >
            Pay ACH
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default PayAgentModal;
