import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useHistory } from "react-router-dom";

const WebsiteLaunchedModal = ({ isOpen, toggle, agentWebsiteDetails }) => {
  const history = useHistory();

  const _closeModal = () => {
    history.push("/agent/dashboard");
  };
  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => _closeModal()}
        size="lg"
        centered
        scrollable
        className="checkOutModal"
      >
        <ModalHeader>Congratulations!!!!</ModalHeader>
        <ModalBody>
          <div className="text-center pt-4">
            {agentWebsiteDetails?.isWebsiteLaunched ? (
              <p>Website is updated</p>
            ) : (
              <p>
                The website launching process has initiated. We will update you
                once your website is launched via email
              </p>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={_closeModal}>
            Okay
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default WebsiteLaunchedModal;
