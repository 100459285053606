import { capitalize } from "@material-ui/core";
import React from "react";
import { Button, Input, Label, Spinner } from "reactstrap";
import { DEFAULT_COVER_PICTURE } from "../../../config";
import {
  checkPermission,
  deepClone,
  errorHandler,
  getDropdownColor,
  refreshFunc,
  showToast,
} from "../../../helper-methods";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import {
  getAllOrderClients,
  updateOrderClientStatus,
} from "../../../http/http-calls";
import AddOrderClient from "../components/AddOrderClient";
import CustomTable from "../components/CustomTable";
import CustomCard from "../components/CustomCard";

class OrderClient extends React.Component {
  state = {
    data: [],
    dataCount: 0,
    dataHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      { id: "name", label: "Name", noSort: true },
      { id: "email", label: "Email", noSort: true },
      { id: "phone", label: "Phone", noSort: true },
      { id: "pointOfContact", label: "Point Of Contact", noSort: true },
      { id: "status", label: "Status", noSort: true },
      {
        id: "action",
        label: "Action",
        noSort: true,
        style: { textAlign: "center", width: 100 },
      },
    ],
    dataCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    addOrderClient: { isOpen: false, data: null },
    loading: {
      showTableLoading: false,
    },
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    },
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = this.state;
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _getAllOrderClients = () => {
    this._manageLoading("showTableLoading", true);
    getAllOrderClients(this.state.tableConfig)
      .then((res) => {
        this.setState({
          data: res?.lenderClients?.length ? res.lenderClients : [],
          dataCount: res?.totalCount || 0,
        });
        this._manageLoading("showTableLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllOrderClients();
    });
    document.querySelector(".content").scrollIntoView();
  };

  componentDidMount = () => {
    // this._paginate();
    this._setFilterDataFromLocalStorage();
    HeaderEventEmitter.subscribe("reset-filter", () => {
      this._paginate();
      refreshFunc("client-order-client");
    });
    HeaderEventEmitter.subscribe("add", () => {
      this._toggleAddOrderClient(true);
    });
  };

  _toggleAddOrderClient = (isOpen = false, data = null) => {
    this.setState({ addOrderClient: { isOpen, data } });
  };

  _userStatusUpdate = (row) => {
    this._manageLoading("statusChangeLoading", true);

    updateOrderClientStatus(row.id)
      .then((res) => {
        showToast("Status has been updated", "success");
        const { data } = deepClone(this.state);
        const findData = data.find((each) => each.id === row.id);
        findData["isActive"] = res.lenderClient.isActive;
        this.setState({ data });
        this._manageLoading("statusChangeLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("statusChangeLoading", false);
      });
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return (
          <>
            <div className="tableUserInfo">
              <div className="userImage">
                <img
                  className="clientImg"
                  src={row.picUrl || DEFAULT_COVER_PICTURE}
                  onError={(e) => (e.target.value = DEFAULT_COVER_PICTURE)}
                  loading="lazy"
                  alt="avatar"
                />
              </div>
              <div className="userContent">
                <span className="">
                  {row.name?.full ? capitalize(row.name.full) : "N/A"}
                </span>
              </div>
            </div>
          </>
        );
      }
      case "email": {
        return row.contactInfo?.length ? (
          <>
            {row.contactInfo.map((each, index) => (
              <div style={{ width: 180 }} key={index}>
                {each.contactEmail}{" "}
                {row.contactInfo.length > index ? <br /> : null}
              </div>
            ))}
          </>
        ) : (
          "N/A"
        );
      }
      case "phone": {
        return row.contactInfo?.length ? (
          <>
            {row.contactInfo.map((each, index) => (
              <div style={{ width: 100 }} key={index}>
                {each.contactNumber}{" "}
                {row.contactInfo.length > index ? <br /> : null}
              </div>
            ))}
          </>
        ) : (
          "N/A"
        );
      }
      case "pointOfContact": {
        return row.contactInfo?.length ? (
          <>
            {row.contactInfo.map((each, index) => (
              <div style={{ width: 110 }} key={index}>
                {each.pointOfContact}{" "}
                {row.contactInfo.length > index ? <br /> : null}
              </div>
            ))}
          </>
        ) : (
          "N/A"
        );
      }
      case "status": {
        return row ? (
          <div className="customSelectWrap" style={{ width: 100 }}>
            <Input
              type="select"
              name="status"
              className={`status ${getDropdownColor(
                row.isActive ? "active" : "inactive"
              )}`}
              disabled={
                this.state.loading.statusChangeLoading ||
                !checkPermission("lenderClient", "canUpdate")
              }
              value={row.isActive ? "active" : "inactive"}
              onChange={(event) =>
                this._userStatusUpdate(row, event.target.value)
              }
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input>
            <div className="downArrow">
              <i className="fa fa-chevron-down"></i>
            </div>
          </div>
        ) : (
          "N/A"
        );
      }
      case "action": {
        return (
          <Button
            color="link"
            onClick={() => this._toggleAddOrderClient(true, row)}
            disabled={!checkPermission("lenderClient", "canUpdate")}
          >
            <img
              src={require("../../../assets/img/pencil.svg").default}
              alt="action"
            />
          </Button>
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardHeaderFormat = (cell, row, header) => {
    console.log("23456", row, cell, header);
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="tableUserInfo">
              <div className="userImage">
                <img
                  className="clientImg"
                  src={
                    row.picUrl
                      ? row.picUrl
                      : require("../../../assets/img/default_user_icon.svg")
                          .default
                  }
                  loading="lazy"
                  alt="avatar"
                />
              </div>
              <div className="userContent">
                <span className="">
                  {row?.name?.full ? capitalize(row?.name?.full) : "N/A"}
                </span>
              </div>
            </div>
            {/* <Button
              color={`${
                row?.appointmentStatus === "Confirmed" && !row.leftMessage
                  ? "success "
                  : row?.appointmentStatus === "Rescheduled" && !row.leftMessage
                  ? "danger "
                  : row.leftMessage
                  ? "warning"
                  : "danger "
              }`}
              className={`confirmButton btnConfirm`}
              onClick={() => this._onToggleSignerAvailabilityModal(true, row)}
            >
              {row?.appointmentStatus === "Confirmed" ? "Confirmed" : "Confirm"}
            </Button> */}

            <Button
              color="link"
              onClick={() => this._toggleAddOrderClient(true, row)}
              disabled={!checkPermission("lenderClient", "canUpdate")}
            >
              <img
                src={require("../../../assets/img/pencil.svg").default}
                alt="action"
              />
            </Button>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Email </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.contactInfo?.length ? (
                <>
                  {row?.contactInfo?.map((each, index) => (
                    <span key={index}>
                      {each?.contactEmail}{" "}
                      {row?.contactInfo?.length > index ? <br /> : null}
                    </span>
                  ))}
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Phone </Label>

            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.contactInfo?.length ? (
                <>
                  {row?.contactInfo?.map((each, index) => (
                    <span key={index}>
                      {each?.contactNumber}{" "}
                      {row?.contactInfo?.length > index ? <br /> : null}
                    </span>
                  ))}
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Point Of Contact </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.contactInfo?.length ? (
                <>
                  {row?.contactInfo?.map((each, index) => (
                    <span key={index}>
                      {each?.pointOfContact}{" "}
                      {row?.contactInfo?.length > index ? <br /> : null}
                    </span>
                  ))}
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap  d-flex align-items-center justify-content-between">
            <Label className="mb-0">Status </Label>

            {row ? (
              <div
                className="customSelectWrap"
                style={{ width: 120, marginRight: "0" }}
              >
                <Input
                  type="select"
                  name="status"
                  className={`status ${getDropdownColor(
                    row?.isActive ? "active" : "inactive"
                  )}`}
                  disabled={
                    this.state.loading.statusChangeLoading ||
                    !checkPermission("lenderClient", "canUpdate")
                  }
                  value={row?.isActive ? "active" : "inactive"}
                  onChange={(event) =>
                    this._userStatusUpdate(row, event.target.value)
                  }
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Input>
                <div className="downArrow">
                  <i className="fa fa-chevron-down"></i>
                </div>
              </div>
            ) : (
              "N/A"
            )}
          </div>
        </li>
      </ul>
    );
  };

  _persistFilter = () => {
    const { tableConfig } = this.state;

    if (tableConfig.pageNumber !== 1) {
      let data = { tableConfig };
      localStorage.clientOrderClientPage = JSON.stringify(data);
    } else {
      delete localStorage.clientOrderClientPage;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.clientOrderClientPage) {
      try {
        const filters = JSON.parse(localStorage.clientOrderClientPage);

        this.setState({ tableConfig: filters?.tableConfig }, () => {
          this._getAllOrderClients();
        });
      } catch (e) {
        console.log("error", e);
        this._getAllOrderClients();
      }
    } else {
      this._getAllOrderClients();
    }
  };

  render() {
    const {
      data,
      dataHeaderKeys,
      dataCount,
      loading,
      tableConfig,
      addOrderClient,
      dataCardHeaderKeys,
    } = this.state;

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>
              Order Client {loading.showTableLoading ? <Spinner /> : null}
            </h2>

            <div className="rightSide">
              <Button
                color="link"
                onClick={() => {
                  this._paginate();
                  refreshFunc("client-order-client-responsive");
                }}
              >
                <img
                  id="client-order-client-responsive"
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                />
              </Button>

              {checkPermission("lenderClient", "canAdd") ? (
                <>
                  <Button
                    color="primary"
                    onClick={() => this._toggleAddOrderClient(true)}
                    className="floatingButton"
                  >
                    Add Client
                  </Button>
                </>
              ) : null}
            </div>
          </div>

          <div className="hideDesktop">
            <CustomCard
              isPageStartFromOne={true}
              pageNumber={tableConfig.pageNumber}
              tableData={data}
              headerKeys={dataCardHeaderKeys}
              // bodyKeys={agentClosingCardBodyKeys}
              dataFormat={this._dataFormat}
              totalCount={dataCount}
              onPaginate={(pageNumber, pageSize) =>
                this._paginate(pageNumber, pageSize)
              }
              rowSelection={false}
              // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
              showTableLoading={loading.showTableLoading}
              cardHeaderFormat={this._cardHeaderFormat}
              cardDataFormat={this._cardDataFormat}
            />
          </div>

          <div className="hideMobile">
            {dataHeaderKeys?.length && (
              <CustomTable
                striped
                isPageStartFromOne={true}
                pageNumber={tableConfig.pageNumber}
                tableData={data}
                headerKeys={dataHeaderKeys}
                dataFormat={this._dataFormat}
                totalCount={dataCount}
                rowSelection={false}
                pageSize={tableConfig.limit}
                onPaginate={(pageNumber, pageSize) =>
                  this._paginate(pageNumber, pageSize)
                }
                onSortChange={(sortName, sortOrder) => this._paginate()}
                showTableLoading={loading.showTableLoading}
              />
            )}
          </div>
        </div>
        {/* modal */}
        <AddOrderClient
          isOpen={addOrderClient.isOpen}
          data={addOrderClient.data}
          toggle={() => this._toggleAddOrderClient()}
          onSuccess={() => this._getAllOrderClients()}
        />
      </>
    );
  }
}

export default OrderClient;
