import React, { useEffect, useRef, useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  InputGroup,
  InputGroupAddon,
  Input,
  Button,
  FormGroup,
  Label,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { getAllFaqCategories } from "../../../../http/http-calls";
import { errorHandler } from "../../../../helper-methods";
import TablePagination from "../TablePagination";
import { useSelector } from "react-redux";

const Categories = () => {
  const searchInterval = useRef({ current: null });

  const userData = useSelector((state) => state?.userData);

  const [data, setData] = useState([]);
  const [dataCount, setDataCount] = useState(0);
  const [dataPayload, setDataPayload] = useState({
    filters: {},
    skip: 0,
    pageNumber: 0,
    limit: 12,
    search: "",
  });
  const [loading, setLoading] = useState(false);
  const [searchLoading, setSearchLoading] = useState(false);

  const _getAllFaqCategories = async (payload) => {
    try {
      setLoading(true);
      const res = await getAllFaqCategories(payload);

      setData(res?.faqCategories?.length ? res.faqCategories : []);
      setDataCount(res?.totalCount || 0);
      setLoading(false);
      setSearchLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
      setSearchLoading(false);
    }
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = pageNumber * newDataPayload["limit"];
    newDataPayload["pageNumber"] = pageNumber;
    setDataPayload(newDataPayload);
    _getAllFaqCategories(newDataPayload);
  };

  const _onChangeSearch = (searchValue) => {
    if (searchInterval?.current) clearInterval(searchInterval.current);
    setSearchLoading(true);
    const newDataPayload = { ...dataPayload };
    newDataPayload["search"] = searchValue;
    newDataPayload["skip"] = 0;
    newDataPayload["pageNumber"] = 0;
    setDataPayload(newDataPayload);
    searchInterval.current = setTimeout(() => {
      _getAllFaqCategories(newDataPayload);
    }, 1000);
  };

  useEffect(() => {
    _getAllFaqCategories(dataPayload);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <p className="text-center">
        {" "}
        Some tips to help you. If you don't find an answer to your issue here,
        write to us at support@closewise.com
      </p>

      <div className="helpSearch">
        <FormGroup className="floatingLabel">
          <InputGroup>
            <Input
              type="text"
              id="input1-group2"
              name="input1-group2"
              placeholder=" "
              value={dataPayload.search}
              onChange={(e) => _onChangeSearch(e.target.value)}
            />
            <Label>Search</Label>
            <InputGroupAddon addonType="append">
              <Button type="button" color="primary">
                {searchLoading ? (
                  <i className="fa fa-spinner fa-spin" />
                ) : dataPayload.search ? (
                  <i
                    className="fa fa-times"
                    onClick={() => _onChangeSearch("")}
                  />
                ) : (
                  <i className="fa fa-search" />
                )}
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </FormGroup>
      </div>

      <Card>
        <CardBody>
          <div className="categoriesWrap">
            {data?.length ? (
              data.map((each) => (
                <Link
                  key={each._id}
                  to={`/${userData?.user?.type?.toLowerCase()}/topic-list/${
                    each._id
                  }`}
                >
                  <h5>{each.category || "N/A"}</h5>
                  {each.description && <p>{each.description}</p>}
                </Link>
              ))
            ) : (
              <>
                {loading ? (
                  <Spinner style={{ margin: "auto" }} />
                ) : (
                  <div className="nodata">
                    <img
                      src={require("../../../../assets/img/noData.svg").default}
                      alt="no data"
                    />
                    <p>There is no data to display</p>
                  </div>
                )}
              </>
            )}
          </div>

          {/* <TablePagination
            data={data}
            dataCount={dataCount}
            pageNumber={dataPayload.pageNumber}
            pageSize={dataPayload.limit}
            onPageChange={_onPageChange}
          /> */}
        </CardBody>
      </Card>
    </>
  );
};

export default Categories;
