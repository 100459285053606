import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  PopoverBody,
  PopoverHeader,
  Table,
  UncontrolledPopover,
} from "reactstrap";
import Swal from "sweetalert2";
import {
  agentTodayOrFutureDate,
  capitalize,
  errorHandler,
  formatAddressInSingleText,
  formatCurrencyValue,
  formatDateAsPerTimeZOne,
  formatDateHideYearIfSame,
  getDropdownColor,
  getTimeZoneAbbr,
  isRegularUser,
  openAppleMapOnNewTab,
  openGoogleMapOnNewTab,
  showToast,
} from "../../../helper-methods";
import {
  deleteAgentDraft,
  getAgentDraftClosings,
} from "../../../http/http-calls";
import CustomTable from "./CustomTable";
import { useHistory } from "react-router-dom";
import CustomCard from "./CustomCard";

const draftClosingCardHeaderKeys = [
  { id: "id", label: "id" },
  { id: "name", label: "Name" },
];

const AgentDashboardimportModal = ({
  isOpen,
  toggle,
  draftClosingsList,
  draftTableConfig,
  draftClosingHeaderKeys,
  draftClosingTotalCount,
  paginateDraft,
  resetData,
}) => {
  const [draftClosings, setDraftClosings] = useState(null);
  const [signingCompanyId, setSigningCompanyId] = useState("");
  const [offeredFee, setOfferedFee] = useState("");
  const [loading, setLoading] = useState(false);

  const history = useHistory();

  const _closeModal = () => {
    toggle();
  };

  useEffect(() => {
    if (isOpen) {
      resetData();
      // _getAgentDraftClosings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const _getAgentDraftClosings = () => {
    setLoading(true);

    getAgentDraftClosings()
      .then((res) => {
        setDraftClosings(res?.closings);
        setLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        setLoading(false);

        // _closeModal();
      });
  };

  const _dataFormat = (cell, row, header) => {
    switch (header) {
      case "appointmentDate": {
        return row ? (
          <>
            <div className="tableUserInfo">
              <div className="userImage">
                <img
                  className="clientImg"
                  src={
                    row?._signingCompany?.logo
                      ? row?._signingCompany?.logo
                      : row?._client?.logo
                      ? row?._client?.logo
                      : require("../../../assets/img/default_user_icon.svg")
                          .default
                  }
                  loading="lazy"
                  alt="avatar"
                />
              </div>
              <div className="userContent">
                <span
                  id={`borrower_table_agent_dashboard_${row._id}`}
                  className="signerName"
                >
                  {row?._borrower[0]?.name?.full
                    ? row?._borrower[0]?.name?.full
                    : "N/A"}
                  {row?._borrower?.length > 1
                    ? `+${row?._borrower?.length - 1}`
                    : null}
                </span>

                {row?._borrower?.length ? (
                  <>
                    <UncontrolledPopover
                      trigger="legacy"
                      placement="bottom"
                      target={`borrower_table_agent_dashboard_${row._id}`}
                      className="signerPopover"
                    >
                      <PopoverHeader>Signers</PopoverHeader>
                      <PopoverBody>
                        <Table className="responsive-sm">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>Language</th>
                              <th>Time Zone</th>
                            </tr>
                          </thead>
                          <tbody>
                            {row?._borrower.map((each) => (
                              <>
                                <tr key={each._id}>
                                  <td>{each.name?.full || "N/A"}</td>
                                  <td>{each.email || "N/A"}</td>
                                  <td>{each.phone?.work || "N/A"}</td>
                                  <td>{each.language || "N/A"}</td>
                                  <td>{each.timeZone || "N/A"}</td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </Table>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </>
                ) : null}
                {/* <span className="dateTimeFormat">
                  {formatDateAsPerTimeZOne(cell, row.closingAddress?.timeZone)}{" "}
                  <span>
                    {getTimeZoneAbbr(cell, row.closingAddress?.timeZone)}
                  </span>
                </span> */}

                {/* <Button
                  color={`${
                    row?.appointmentStatus === "Confirmed" && !row.leftMessage
                      ? "success "
                      : row?.appointmentStatus === "Rescheduled" &&
                        !row.leftMessage
                      ? "danger "
                      : row.leftMessage
                      ? "warning"
                      : "danger "
                  }`}
                  className={`confirmButton `}
                  onClick={() =>
                    this._onToggleSignerAvailabilityModal(true, row)
                  }
                >
                  {row?.appointmentStatus === "Confirmed"
                    ? "Confirmed"
                    : "Confirm"}
                </Button> */}
              </div>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "fileNumber": {
        return row ? (
          <>
            <div
              // className="tableLink"
              style={{
                minWidth: 80,
                whiteSpace: "normal",
                // textDecoration: "underline",
              }}
              onClick={() => this._redirectToClosingDetails(row)}
            >
              {row.fileNumber || "N/A"}
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "loanType": {
        return (
          <>
            <div style={{ width: 100 }}>
              <span
                style={{
                  marginRight: 6,
                  display: "inline-block",
                  fontWeight: 600,
                }}
              >
                {row.signingType} {","}
              </span>

              {cell && cell.length > 0 ? (
                cell.indexOf("Other") > -1 ? (
                  <span
                    style={{
                      marginRight: 6,
                      display: "inline-block",
                      fontWeight: 600,
                    }}
                  >
                    {row?.loanTypeOther}
                  </span>
                ) : null
              ) : null}

              {React.Children.toArray(
                cell
                  .filter((each) => each !== "Other")
                  .map((item) => (
                    <span
                      key={item._id}
                      style={{
                        marginRight: 6,
                        display: "inline-block",
                        fontWeight: 600,
                      }}
                    >
                      {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                    </span>
                  ))
              )}
            </div>
          </>
        );
      }
      case "agentFee": {
        return (
          <>
            <div style={{ fontWeight: 600, width: 60 }}>
              {cell ? formatCurrencyValue(cell) : formatCurrencyValue(0)}
            </div>
          </>
        );
      }
      case "closingAddress": {
        return (
          <>
            <span style={{ fontWeight: 600, width: 120, display: "block" }}>
              {formatAddressInSingleText(cell) || "N/A"}
            </span>
            <Button
              color="link"
              className="p-0"
              title={formatAddressInSingleText(cell)}
              onClick={() => openGoogleMapOnNewTab(row.closingAddress)}
            >
              <img
                src={
                  require("../../../assets/img/Google_Maps_Logo_2020.svg")
                    .default
                }
                alt="map icon"
                className="mr-1"
              />
              Google Map
            </Button>
            <Button
              color="link"
              className="p-0"
              title={formatAddressInSingleText(cell)}
              onClick={() => openAppleMapOnNewTab(row.closingAddress)}
            >
              <img
                src={
                  require("../../../assets/img/Apple_Maps_(WatchOS).svg")
                    .default
                }
                alt="map icon"
                className="mr-1"
              />
              Apple Map
            </Button>
          </>
        );
      }
      case "docs": {
        return row ? (
          <div className="docStatus">
            {/* Last Uploaded by Company: $DateTime */}
            <Button
              color="link"
              className="actionBtn"
              data-toggle="tooltip"
              data-placement="top"
              title={
                row?.companyLastDocumentUploadedAt &&
                `Last Uploaded by Company: ${formatDateAsPerTimeZOne(
                  row?.companyLastDocumentUploadedAt,
                  row?.closingAddress?.timeZone
                )}`
              }
              onClick={() => this._redirectToClosingDetails(row)}
            >
              {row?.documents?.length && !row.documents[0].isPrivate ? (
                row.isDocumentCompleted ? (
                  <span
                    className="fa fa-circle text-success"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                ) : (
                  <span
                    className="fa fa-circle text-primary"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )
              ) : (
                <span
                  className="fa fa-circle text-primary"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )}
              <span className="value">
                {row.isDocumentCompleted ? "Docs Ready" : "Not Ready"}
              </span>
            </Button>

            {/* First downloaded by Agent: DateTime */}
            {/* turns green only when agent downloaded all documents  */}
            <Button
              color="link"
              className="actionBtn"
              data-toggle="tooltip"
              data-placement="top"
              title={
                row?.isAgentViewDocumentTime &&
                `First downloaded by Agent:  ${formatDateAsPerTimeZOne(
                  row?.isAgentViewDocumentTime,
                  row?.closingAddress?.timeZone
                )}`
              }
            >
              {row.isAllDocumentsDownloaded ? (
                <span
                  className="fa fa-circle text-success"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              ) : (
                <span
                  className="fa fa-circle text-warning"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )}
              <span className="value">
                {row?.isAllDocumentsDownloaded ? "Docs Downloaded" : "Pending"}
              </span>
            </Button>

            {/* Last Uploaded by Agent: DateTime */}
            <Button
              color="link"
              className="actionBtn"
              data-toggle="tooltip"
              data-placement="top"
              title={
                row?.agentLastDocumentUploadedAt &&
                `Last Uploaded by Agent:  ${formatDateAsPerTimeZOne(
                  row?.agentLastDocumentUploadedAt,
                  row?.closingAddress?.timeZone
                )}`
              }
            >
              {row.isScanBackNeeded ? (
                row.isDocumentVerified ? (
                  <>
                    <span
                      className="fa fa-circle text-success"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span className="value">Shipping Approved</span>
                  </>
                ) : row.isDocumentCompletedByAgent ? (
                  <>
                    <span
                      className="fa fa-circle text-primary"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span
                      className="value"
                      onClick={() => this._redirectToClosingDetails(row)}
                    >
                      Scan Upload
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className="fa fa-circle text-danger"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span className="value">Scan Needed</span>
                  </>
                )
              ) : row?.documents?.length && !row.documents[0].isPrivate ? (
                row.isDocumentCompletedByAgent ? (
                  <>
                    <span
                      className="fa fa-circle text-success"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span className="value">Verified</span>
                  </>
                ) : (
                  <span
                    className="fa fa-circle"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )
              ) : (
                <span
                  className="fa fa-circle"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )}
            </Button>
          </div>
        ) : (
          "N/A"
        );
      }
      case "status": {
        return cell ? (
          <div className="customSelectWrap" style={{ width: 120 }}>
            <Input
              type="select"
              name="status"
              className={`status ${getDropdownColor(cell)}`}
              value={cell}
              disabled={this.state.loading.statusChangeLoading ? true : false}
              onChange={(event) =>
                this._updateClosingDetailsByIdStatus(row, event.target.value)
              }
            >
              <option key="CCA" value="CCA">
                Can't Complete
              </option>
              <option
                key="Pending"
                value="Pending"
                // disabled={row._signingCompany !== undefined}
                disabled={!row.isCreatedForAgent}
              >
                Pending
              </option>
              <option
                key="Closed"
                value="Closed"
                disabled={agentTodayOrFutureDate(row) && cell !== "Arrived"}
              >
                Closed
              </option>
              <option
                key="Cancelled"
                value="Cancelled"
                disabled={!row.isCreatedForAgent}
              >
                Cancelled
              </option>
              <option
                key="DNC"
                value="DNC"
                disabled={agentTodayOrFutureDate(row) && cell !== "Arrived"}
              >
                Did not close
              </option>
              <option
                key="Arrived"
                value="Arrived"
                disabled={
                  this._isDisabledCheckStatusDropdown(row) || cell === "Closed"
                }
              >
                Arrived
              </option>
            </Input>
            <div className="downArrow">
              <i className="fa fa-chevron-down"></i>
            </div>
          </div>
        ) : (
          "N/A"
        );
      }
      case "action": {
        return (
          <>
            {" "}
            <Button
              color="link"
              onClick={() => _redirectToCreateClosing(row?._id)}
            >
              <img
                style={{ height: 14 }}
                src={require("../../../assets/img/uploadIconBlue.svg").default}
                alt=""
              />
            </Button>
            <Button color="link" onClick={() => _deleteDraftAlert(row?._id)}>
              <img
                src={require("../../../assets/img/deleteIcon.svg").default}
                alt="new"
              />
            </Button>
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  const _rowClassName = (row, rowIdx) => {
    const statusEnum = ["Closed", "DNC", "Cancelled", "CCA"];
    if (row?.status && statusEnum.includes(row.status)) {
      return "rowStatus";
    }
    return "";
  };

  const _deleteDraft = (commentId) => {
    // setDeleteCommentLoading(commentId);
    setLoading(true);

    // const newComments = [...comments];
    // const findCommentIndex = newComments.findIndex(
    //   (each) => each._id === commentId
    // );
    // if (findCommentIndex > -1) {
    //   newComments.splice(findCommentIndex, 1);
    //   setComments(newComments);
    // }

    deleteAgentDraft(commentId)
      .then(async (res) => {
        Swal.fire("Deleted!", "Draft has been deleted.", "success");
        // if (newComments.length === 0) {
        //   _onChangePagination();
        // }
        // setDeleteCommentLoading(false);
        setLoading(false);
        resetData();
      })
      .catch((error) => {
        // _onChangePagination();
        errorHandler(error);
        // setDeleteCommentLoading(false);
        setLoading(false);
      });
  };

  const _deleteDraftAlert = (id) => {
    if (!id) {
      showToast("Draft not found", "error");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _deleteDraft(id);
      }
    });
  };

  const _redirectToCreateClosing = (id) => {
    // console.log(history)
    history.push(`/agent/create-closing?${id}`);
  };

  const _cardHeaderFormat = (cell, row, header) => {
    console.log("weerer", cell, header, row);
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="tableUserInfo">
              <div className="userImage">
                <img
                  className="clientImg"
                  src={
                    row?._signingCompany?.logo
                      ? row?._signingCompany?.logo
                      : row?._client?.logo
                      ? row?._client?.logo
                      : require("../../../assets/img/default_user_icon.svg")
                          .default
                  }
                  loading="lazy"
                  alt="avatar"
                />
              </div>
              <div className="userContent">
                <span
                  id={`borrower_table_agent_dashboard_${row._id}`}
                  className="signerName"
                >
                  {row?._borrower[0]?.name?.full
                    ? row?._borrower[0]?.name?.full
                    : "N/A"}
                  {row?._borrower?.length > 1
                    ? `+${row?._borrower?.length - 1}`
                    : null}
                </span>

                {row?._borrower?.length ? (
                  <>
                    <UncontrolledPopover
                      trigger="legacy"
                      placement="bottom"
                      target={`borrower_table_agent_dashboard_${row._id}`}
                      className="signerPopover"
                    >
                      <PopoverHeader>Signers</PopoverHeader>
                      <PopoverBody>
                        <Table className="responsive-sm">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>Language</th>
                              <th>Time Zone</th>
                            </tr>
                          </thead>
                          <tbody>
                            {row?._borrower.map((each) => (
                              <>
                                <tr key={each._id}>
                                  <td>{each.name?.full || "N/A"}</td>
                                  <td>{each.email || "N/A"}</td>
                                  <td>{each.phone?.work || "N/A"}</td>
                                  <td>{each.language || "N/A"}</td>
                                  <td>{each.timeZone || "N/A"}</td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </Table>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </>
                ) : null}
                {/* <span className="dateTimeFormat">
                  {formatDateAsPerTimeZOne(cell, row.closingAddress?.timeZone)}{" "}
                  <span>
                    {getTimeZoneAbbr(cell, row.closingAddress?.timeZone)}
                  </span>
                </span> */}

                {/* <Button
                  color={`${
                    row?.appointmentStatus === "Confirmed" && !row.leftMessage
                      ? "success "
                      : row?.appointmentStatus === "Rescheduled" &&
                        !row.leftMessage
                      ? "danger "
                      : row.leftMessage
                      ? "warning"
                      : "danger "
                  }`}
                  className={`confirmButton `}
                  onClick={() =>
                    this._onToggleSignerAvailabilityModal(true, row)
                  }
                >
                  {row?.appointmentStatus === "Confirmed"
                    ? "Confirmed"
                    : "Confirm"}
                </Button> */}
              </div>
            </div>
            <Button color="link" onClick={() => _deleteDraftAlert(row?._id)}>
              <img
                src={require("../../../assets/img/deleteIcon.svg").default}
                alt="new"
              />
            </Button>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  const _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>File</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row ? (
                  <>
                    <div
                      // className="tableLink"
                      style={{
                        minWidth: 80,
                        whiteSpace: "normal",
                        // textDecoration: "underline",
                      }}
                      onClick={() => this._redirectToClosingDetails(row)}
                    >
                      {row?.fileNumber || "N/A"}
                    </div>
                  </>
                ) : (
                  "N/A"
                )}
              </span>
            </div>
          </div>

          <div className="dataWrap">
            <Label>Product Type</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {
                  <>
                    <div style={{ width: 100 }}>
                      <span
                        style={{
                          marginRight: 6,
                          display: "inline-block",
                          fontWeight: 600,
                        }}
                      >
                        {row.signingType} {","}
                      </span>

                      {row?.loanType && row?.loanType?.length > 0 ? (
                        row?.loanType?.indexOf("Other") > -1 ? (
                          <span
                            style={{
                              marginRight: 6,
                              display: "inline-block",
                              fontWeight: 600,
                            }}
                          >
                            {row?.loanTypeOther}
                          </span>
                        ) : null
                      ) : null}

                      {React.Children.toArray(
                        row?.loanType
                          .filter((each) => each !== "Other")
                          .map((item) => (
                            <span
                              key={item._id}
                              style={{
                                marginRight: 6,
                                display: "inline-block",
                                fontWeight: 600,
                              }}
                            >
                              {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                            </span>
                          ))
                      )}
                    </div>
                  </>
                }
              </span>
            </div>
          </div>
          <div className="dataWrap">
            <Label>Fee</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {
                  <>
                    <div style={{ fontWeight: 600, width: 60 }}>
                      {row?.agentFee
                        ? formatCurrencyValue(row?.agentFee)
                        : formatCurrencyValue(0)}
                    </div>
                  </>
                }
              </span>
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Location</Label>
            <div className="d-flex justify-content-between">
              <div style={{ fontWeight: 600, flex: 1, marginRight: 10 }}>
                {formatAddressInSingleText(row?.closingAddress) || "N/A"}
              </div>
              <div className="d-flex">
                <Button
                  color="link"
                  className="p-0"
                  title={formatAddressInSingleText(row?.closingAddress)}
                  onClick={() => openGoogleMapOnNewTab(row.closingAddress)}
                >
                  <img
                    src={
                      require("../../../assets/img/Google_Maps_Logo_2020.svg")
                        .default
                    }
                    alt="map icon"
                    className="mr-1"
                  />
                </Button>
                <Button
                  color="link"
                  className="p-0"
                  title={formatAddressInSingleText(row?.closingAddress)}
                  onClick={() => openAppleMapOnNewTab(row.closingAddress)}
                >
                  <img
                    src={
                      require("../../../assets/img/Apple_Maps_(WatchOS).svg")
                        .default
                    }
                    alt="map icon"
                    className="mr-1"
                  />
                </Button>
              </div>
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap text-center">
            {
              <>
                {" "}
                <Button
                  color="primary"
                  size="md"
                  onClick={() => _redirectToCreateClosing(row?._id)}
                >
                  <img
                    style={{ height: 12, marginRight: 10 }}
                    src={require("../../../assets/img/uploadIcon.svg").default}
                    alt=""
                  />
                  Upload
                </Button>
              </>
            }
          </div>
        </li>
      </ul>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered"
      size="xl"
    >
      <ModalHeader toggle={() => _closeModal()}>Import Data</ModalHeader>
      <ModalBody>
        <div className="CustomTableWrapper hideMobile">
          <CustomTable
            striped
            isPageStartFromOne={true}
            pageNumber={draftTableConfig.pageNumber}
            tableData={draftClosingsList}
            headerKeys={draftClosingHeaderKeys}
            dataFormat={_dataFormat}
            totalCount={draftClosingTotalCount}
            onPaginate={(pageNumber, pageSize) =>
              paginateDraft(pageNumber, pageSize)
            }
            rowClassName={(row, rowIdx) => _rowClassName(row, rowIdx)}
            showTableLoading={loading.showTableLoading}
          />
        </div>
        <div className="hideDesktop">
          <CustomCard
            columnSizeMD={12}
            isPageStartFromOne={true}
            pageNumber={draftTableConfig.pageNumber}
            tableData={draftClosingsList}
            headerKeys={draftClosingCardHeaderKeys}
            // bodyKeys={agentClosingCardBodyKeys}
            dataFormat={_dataFormat}
            totalCount={draftClosingTotalCount}
            onPaginate={(pageNumber, pageSize) =>
              paginateDraft(pageNumber, pageSize)
            }
            // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
            showTableLoading={loading.showTableLoading}
            cardHeaderFormat={_cardHeaderFormat}
            cardDataFormat={_cardDataFormat}
          />
        </div>
      </ModalBody>

      <ModalFooter>
        <Button size="lg" color="primary" outline onClick={() => _closeModal()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AgentDashboardimportModal;
