import React from "react";
import { Button, Table, Spinner, Label } from "reactstrap";
import CustomCard from "./CustomCard";
import useScreenWidth from "./HelpModule/useScreenWidth";

export default function FeeSettingTable({
  loading,
  companySettings,
  toggleFeeRateModal,
  removeFeeSettings,
  isForClient = false,
}) {
  const { isForMobile } = useScreenWidth();

  const DataFormat =
    companySettings?.standardFeeRates &&
    companySettings?.standardFeeRates.some(
      (obj) => obj.isForClient === isForClient
    )
      ? companySettings.standardFeeRates.filter(
          (item) => item.isForClient === isForClient
        )
      : [];

  const _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>State</Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.state ? row?.state : "N/A"}
            </div>
          </div>
          {isForClient && (
            <div className="dataWrap">
              <Label>Client</Label>
              <div
                style={{
                  fontWeight: 600,
                }}
              >
                {row?._client?.companyName || "N/A"}
              </div>
            </div>
          )}
          <div className="dataWrap">
            <Label>City</Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.county ? row?.county : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Signing Type </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.signingType ? row?.signingType : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Product Type </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.productType ? row?.productType : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Offer Rate: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.offerRate ? `$${row?.offerRate}` : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap text-center">
            {
              <>
                <Button
                  color="primary"
                  size="md"
                  onClick={() => toggleFeeRateModal(true, row)}
                  disabled={loading ? true : false}
                >
                  Edit
                </Button>
                <Button
                  color="danger"
                  size="md"
                  className=""
                  onClick={() => removeFeeSettings(row)}
                  disabled={loading ? true : false}
                >
                  Delete
                </Button>
              </>
            }
          </div>
        </li>
      </ul>
    );
  };
  return (
    <>
      {!isForMobile ? (
        <div className="CustomTableWrapper hideMobile">
          <Table responsive striped>
            <thead>
              <tr>
                {isForClient && <th>client</th>}
                <th>State</th>
                <th>City</th>
                <th>Signing Type</th>
                <th>Product Type</th>
                <th>Offer Rate</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {DataFormat?.length > 0 ? (
                DataFormat.map((item) => (
                  <tr key={item.id}>
                    {isForClient && (
                      <td>{item?._client?.companyName || "N/A"}</td>
                    )}

                    <td>{item.state || "N/A"}</td>
                    <td>{item.county || "N/A"}</td>
                    <td>{item.signingType}</td>
                    <td>{item.productType}</td>
                    <td>${item.offerRate}</td>
                    <td>
                      <>
                        <Button
                          color="link"
                          onClick={() => toggleFeeRateModal(true, item)}
                          disabled={loading ? true : false}
                        >
                          <img
                            src={
                              require("../../../assets/img/pencil.svg").default
                            }
                            alt="edit"
                          />
                        </Button>
                        <Button
                          color="link"
                          onClick={() => removeFeeSettings(item)}
                          disabled={loading ? true : false}
                        >
                          <img
                            src={
                              require("../../../assets/img/deleteIcon.svg")
                                .default
                            }
                            alt="delete"
                          />
                        </Button>
                      </>
                    </td>
                  </tr>
                ))
              ) : (
                <tr className="text-center">
                  <td colSpan="8">
                    {loading ? (
                      <Spinner />
                    ) : (
                      <div className="nodata">
                        <img
                          src={
                            require("../../../assets/img/noData.svg").default
                          }
                          alt="no data"
                        />
                        <p>There is no data to display</p>
                      </div>
                    )}
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      ) : (
        <div className="hideDesktop">
          <CustomCard
            isPageStartFromOne={true}
            tableData={DataFormat}
            totalCount={DataFormat?.length}
            columnSizeMD={12}
            isPagination={false}
            rowSelection={false}
            showTableLoading={loading.showTableLoading}
            cardDataFormat={_cardDataFormat}
          />
        </div>
      )}
    </>
  );
}
