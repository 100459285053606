import React, { useState } from "react";
import { Card, TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";

// import ReactDatetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import SettingAgentPayPeriod from "../components/settingAgentPayPeriod";
import SettingsInvoicing from "../components/settingsInvoicing";
import { useSelector } from "react-redux";

const AccountingAutomation = ({ companySettings, getAllCompanySettings }) => {
  const [activeTab, setActiveTab] = useState("1");

  const signingCompany = useSelector((state) => state);

  const _toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <Card className="innerTabs">
      <Nav tabs className="greyBg">
        {!signingCompany?.userData?.user?.isTitleCompany && (
          <NavItem>
            <NavLink
              className={activeTab === "1" ? "active" : ""}
              onClick={() => _toggleTab("1")}
            >
              Invoicing Automation
            </NavLink>
          </NavItem>
        )}
        <NavItem>
          <NavLink
            className={activeTab === "2" ? "active" : ""}
            onClick={() => _toggleTab("2")}
          >
            Agent Pay Period Breakdown Setting
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <SettingsInvoicing
            companySettings={companySettings}
            getAllCompanySettings={getAllCompanySettings}
          />
        </TabPane>
        <TabPane tabId="2">
          <SettingAgentPayPeriod
            companySettings={companySettings}
            getAllCompanySettings={getAllCompanySettings}
          />
        </TabPane>
      </TabContent>
    </Card>
  );
};

export default AccountingAutomation;
