import React, { useRef, useState } from "react";
import PersonalWebsiteSetp1 from "../../components/Agents/PersonalWebsiteSetp1";
import PersonalWebsiteSetp2 from "../../components/Agents/PersonalWebsiteSetp2";
import PersonalWebsiteSetp3 from "../../components/Agents/PersonalWebsiteSetp3";
import PersonalWebsiteSetp4 from "../../components/Agents/PersonalWebsiteSetp4";
import { errorHandler } from "../../../../helper-methods";
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap";

const PersonalWebsiteManagement = () => {
  const step1Ref = useRef();
  const step2Ref = useRef();
  const step3Ref = useRef();
  const step4Ref = useRef();

  const [activeTab, setActiveTab] = useState("1");
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [loading, setloading] = useState({
    stepLoading: false,
  });

  const _manageLoadingState = (key = "", value = false) => {
    setloading((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const _stepIncrement = (activeTab, incrementBy = 1) => {
    if (Number(activeTab) === 4) {
      console.log({ activeTab });
      return;
    }

    let temp = Number(activeTab) + incrementBy;
    setActiveTab(String(temp));
  };

  const _moveToNextStep = async (activeTab) => {
    if (Number(activeTab) === 1) {
      try {
        const res = await step1Ref.current._onSubmit();
        // const isWebsiteLaunched = await step1Ref.current.isWebsiteLaunched;

        if (res) {
          _stepIncrement(activeTab, 1);
          // if (isWebsiteLaunched) {
          //   _stepIncrement(activeTab, 2);
          // } else {
          //   _stepIncrement(activeTab, 1);
          // }
        }
      } catch (err) {
        console.log({ err });
      }
    } else if (Number(activeTab) === 2) {
      try {
        const isDomainNamePresent = await step2Ref.current.isDomainNamePresent;
        if (!isDomainNamePresent) {
          errorHandler({ reason: "Please enter domain details" });
          return;
        }
        // const res = await step2Ref.current._onSubmit();
        // if (res) {
        //   _stepIncrement(activeTab, 1);
        // }
        _stepIncrement(activeTab, 1);
      } catch (err) {
        errorHandler(err);
      }
    } else if (Number(activeTab) === 3) {
      try {
        await step3Ref.current._onSubmit();
        // _stepIncrement(activeTab, 1);
      } catch (err) {
        errorHandler(err);
      }
    } else if (Number(activeTab) === 4) {
      try {
        await step4Ref.current._onSubmit();
      } catch (err) {
        errorHandler(err);
      }
    } else {
      _stepIncrement(activeTab, 1);
    }
  };

  const _moveToPrevStep = async (activeTab) => {
    if (Number(activeTab) === 1) {
      return;
    }

    if (Number(activeTab) === 4) {
      setIsFormSubmitted(false);
    }

    let temp = Number(activeTab) - 1;
    setActiveTab(String(temp));
  };

  return (
    <>
      <div className="content">
        <div className="responsiveTitle">
          <h2>Personal Website Management</h2>
        </div>
        <div className="personalWebsiteSetps">
          <Nav pills>
            <NavItem>
              <NavLink
                className={{ active: +activeTab >= 1 }}
                onClick={() => {
                  toggle("1");
                }}
              >
                <div
                  className={`stepCount ${
                    Number(activeTab) > 1 ? "success" : null
                  }`}
                >
                  {Number(activeTab) > 1 ? (
                    <img
                      src={require("../../../../assets/img/check.png").default}
                      alt="icon"
                    />
                  ) : (
                    1
                  )}
                </div>
                <span className="stepTitle">Step 1</span>
                <span>Basic Detail</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: +activeTab >= 2 }}
                onClick={() => {
                  toggle("2");
                }}
              >
                <div
                  className={`stepCount ${
                    Number(activeTab) > 2 ? "success" : null
                  }`}
                >
                  {Number(activeTab) > 2 ? (
                    <img
                      src={require("../../../../assets/img/check.png").default}
                      alt="icon"
                    />
                  ) : (
                    2
                  )}
                </div>
                <span className="stepTitle">Step 2</span>
                <span>Domain Setting</span>
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: +activeTab >= 3 }}
                onClick={() => {
                  toggle("3");
                }}
              >
                <div
                  // className={`stepCount ${
                  //   Number(activeTab) > 3 ? "success" : null
                  // }`}
                  className={`stepCount ${isFormSubmitted ? "success" : null}`}
                >
                  {/* {Number(activeTab) > 3 ? (
                    <img
                      src={require("../../../../assets/img/check.png").default}
                      alt="icon"
                    />
                  ) : (
                    3
                  )} */}
                  {isFormSubmitted ? (
                    <img
                      src={require("../../../../assets/img/check.png").default}
                      alt="icon"
                    />
                  ) : (
                    3
                  )}
                </div>
                <span className="stepTitle">Step 3</span>
                <span>Add Agent</span>
              </NavLink>
            </NavItem>
            {/* <NavItem>
              <NavLink
                className={{ active: +activeTab >= 4 }}
                onClick={() => {
                  toggle("4");
                }}
              >
                <div
                  className={`stepCount ${isFormSubmitted ? "success" : null}`}
                >
                  {isFormSubmitted ? (
                    <img
                      src={require("../../../../assets/img/check.png").default}
                      alt="icon"
                    />
                  ) : (
                    4
                  )}
                </div>
                <span className="stepTitle">Step 4</span>
                <span>Manage Clients</span>
              </NavLink>
            </NavItem> */}
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <PersonalWebsiteSetp1
                manageLoadingState={_manageLoadingState}
                ref={step1Ref}
              />
            </TabPane>
            <TabPane tabId="2">
              {Number(activeTab) === 2 ? (
                <PersonalWebsiteSetp2
                  manageLoadingState={_manageLoadingState}
                  ref={step2Ref}
                />
              ) : null}
            </TabPane>
            <TabPane tabId="3">
              {Number(activeTab) === 3 ? (
                <PersonalWebsiteSetp3
                  manageLoadingState={_manageLoadingState}
                  setIsFormSubmitted={setIsFormSubmitted}
                  ref={step3Ref}
                />
              ) : null}
            </TabPane>
            {/* <TabPane tabId="4">
              {Number(activeTab) === 4 ? (
                <PersonalWebsiteSetp4
                  manageLoadingState={_manageLoadingState}
                  setIsFormSubmitted={setIsFormSubmitted}
                  ref={step4Ref}
                />
              ) : null}
            </TabPane> */}
          </TabContent>

          <div className="d-flex align-items-center justify-content-center">
            {Number(activeTab) !== 1 ? (
              <Button
                color="primary"
                outline
                onClick={() => _moveToPrevStep(activeTab)}
              >
                Previous
              </Button>
            ) : null}
            <Button
              color="primary"
              onClick={() => _moveToNextStep(activeTab)}
              disabled={loading?.stepLoading}
            >
              {loading?.stepLoading ? (
                <i className="fa fa-spinner fa-spin mr-2" />
              ) : null}
              {Number(activeTab) < 4 ? "Save & Next" : "Save"}
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalWebsiteManagement;
