import React, { useEffect, useState } from "react";
import { Button, Input, Label, Table } from "reactstrap";
import { errorHandler, showToast } from "../../../../helper-methods";
import {
  addForwardingAgentToAgentWebsite,
  deleteForwardingAgent,
  forwardingAgentsSearchByEmail,
  getAllAgentsAddedAsForwardedAgents,
} from "../../../../http/http-calls";
import Swal from "sweetalert2";
import WebsiteInviteAgentModal from "./WebsiteInviteAgentModal";
import { RegexConfig } from "../../../../config/RegexConfig";

const PersonalWebsiteAddAgents = () => {
  const [inputValue, setInputValue] = useState("");
  const [searchedAgents, setSearchedAgents] = useState([]);
  const [forwardedAgentsList, setForwardedAgentsList] = useState([]);
  const [loading, setLoading] = useState({
    showTableLoading: false,
    showSearchLoading: false,
    showAddLoading: false,
    showDeleteLoading: false,
  });
  const [websiteInviteAgentModal, setWebsiteInviteAgentModal] = useState({
    isOpen: false,
    data: null,
  });

  const _toggleWebsiteInviteAgentModal = (isOpen = false, data = null) => {
    setWebsiteInviteAgentModal({ isOpen, data });
  };

  // Create a function to handle input changes
  const _handleInputChange = (value) => {
    setInputValue(value);

    if (value === "") {
      setSearchedAgents([]);
    }
  };

  const _manageLoadingState = (key = "", value = false) => {
    setLoading((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _searchAgents = async () => {
    try {
      if (
        !inputValue?.trim()?.length ||
        !RegexConfig?.email?.test(inputValue?.trim())
      ) {
        errorHandler({ reason: "Please enter email correctly" });
        return;
      }

      _manageLoadingState("showSearchLoading", true);

      const payload = {
        email: inputValue?.trim(),
      };
      const res = await forwardingAgentsSearchByEmail(payload);

      if (!res?.agents?.length) {
        if (!res?.userType) {
          _toggleWebsiteInviteAgentModal(true, { ...payload });
          setInputValue("");
        } else {
          errorHandler({ reason: `User already present as ${res?.userType}` });
          setInputValue("");
        }
      }

      setSearchedAgents(res?.agents || []);

      _manageLoadingState("showSearchLoading", false);
    } catch (err) {
      errorHandler(err);
      _manageLoadingState("showSearchLoading", false);
    }
  };

  const _addForwardingAgent = async (agentId) => {
    try {
      _manageLoadingState("showAddLoading", true);
      await addForwardingAgentToAgentWebsite(agentId);

      setSearchedAgents([]);
      _getAllForwardedAgents();
      showToast("Added Successfully", "success");
      _manageLoadingState("showAddLoading", false);
      setInputValue("");
    } catch (err) {
      errorHandler(err);
      setSearchedAgents([]);
      _manageLoadingState("showAddLoading", false);
    }
  };

  const _getAllForwardedAgents = async () => {
    try {
      _manageLoadingState("showTableLoading", true);
      const res = await getAllAgentsAddedAsForwardedAgents();
      setForwardedAgentsList(res?.agentWebsite?._jobForwardingAgents);
      _manageLoadingState("showTableLoading", false);
    } catch (err) {
      errorHandler(err);
      _manageLoadingState("showTableLoading", false);
    }
  };

  const _removeAgent = async (id) => {
    try {
      _manageLoadingState("showDeleteLoading", true);
      await deleteForwardingAgent(id);

      _manageLoadingState("showDeleteLoading", false);

      _getAllForwardedAgents();
    } catch (err) {
      _manageLoadingState("showDeleteLoading", false);
      errorHandler(err);
    }
  };

  const _removeAgentAlert = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove this agent.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _removeAgent(id);
      }
    });
  };

  useEffect(() => {
    _getAllForwardedAgents();
  }, []);

  return (
    <>
      <div className="searchList">
        <div className="floatingLabel w-100">
          <Input
            type="search"
            placeholder=" "
            value={inputValue}
            onChange={(e) => _handleInputChange(e.target.value)}
          />
          <Label>Search agent via email</Label>
        </div>
        {searchedAgents?.length > 0
          ? searchedAgents?.map((each) => (
              <div className="searchForm">
                <ul>
                  <li>
                    <Label for="email">
                      {each?.email} ({each?.name.full})
                    </Label>
                  </li>
                </ul>
                <Button
                  color="link"
                  onClick={() => _addForwardingAgent(each?.id)}
                  disabled={loading?.showAddLoading}
                >
                  {/* Add Selected Agent */}
                  {loading?.showAddLoading ? (
                    <i className="fa fa-spinner fa-spin mr-2" />
                  ) : null}{" "}
                  Add Agent
                </Button>
              </div>
            ))
          : null}
        <Button
          color="primary"
          style={{ whiteSpace: "nowrap", marginLeft: 20 }}
          outline
          onClick={() => _searchAgents()}
          disabled={loading?.showSearchLoading}
        >
          {loading?.showSearchLoading ? (
            <i className="fa fa-spinner fa-spin mr-2" />
          ) : null}{" "}
          Search
        </Button>
      </div>

      <h1 className="fs-14 mb-3 mt-4">Agent List</h1>
      <div className="CustomTableWrapper">
        <Table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {forwardedAgentsList?.length ? (
              forwardedAgentsList?.map((each) => (
                <tr>
                  <td>{each?.name?.full}</td>
                  <td>{each?.email}</td>
                  <td>{each?.phone}</td>
                  <td>
                    <Button
                      color="link"
                      onClick={() => _removeAgentAlert(each?.id)}
                      disabled={loading?.showDeleteLoading}
                    >
                      <img
                        src={
                          require("../../../../assets/img/deleteIcon.svg")
                            .default
                        }
                        alt="delete"
                      />
                    </Button>
                  </td>
                </tr>
              ))
            ) : loading?.showTableLoading ? (
              <tr>
                <td colSpan={4} className="text-center">
                  <i className="fa fa-spinner fa-spin mr-2" />
                </td>
              </tr>
            ) : (
              <tr>
                <td colSpan={4}>
                  <div className="nodata">
                    <img
                      src={require("../../../../assets/img/noData.svg").default}
                      alt="no data"
                    />
                    <p>There is no data to display</p>
                  </div>
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
      <WebsiteInviteAgentModal
        isOpen={websiteInviteAgentModal?.isOpen}
        data={websiteInviteAgentModal?.data}
        toggle={_toggleWebsiteInviteAgentModal}
      />
    </>
  );
};

export default PersonalWebsiteAddAgents;
