import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  CardSubtitle,
  CardTitle,
  ListGroup,
  ListGroupItem,
  Spinner,
  Button,
} from "reactstrap";
import { errorHandler } from "../../../../helper-methods";
import {
  findFaqCategoryById,
  getAllFaqTopics,
} from "../../../../http/http-calls";
import { useHistory, useParams } from "react-router-dom";
import TablePagination from "../../components/TablePagination";
import { useSelector } from "react-redux";
import { HeaderEventEmitter } from "../../../../helper-methods/HeaderEvents";

const TopicList = () => {
  const history = useHistory();

  const { id } = useParams();

  const userData = useSelector((state) => state?.userData);

  const [data, setData] = useState([]);
  const [categoryData, setCategoryData] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [dataPayload, setDataPayload] = useState({
    filters: {},
    skip: 0,
    pageNumber: 0,
    limit: 10,
    search: "",
    categoryId: id,
  });
  const [loading, setLoading] = useState(false);

  const _findFaqCategoryById = async () => {
    try {
      const response = await findFaqCategoryById(id);
      setCategoryData(response?.faqCategory || {});
    } catch (error) {
      errorHandler(error);
    }
  };

  const _getAllFaqTopics = async (payload) => {
    try {
      setLoading(true);
      const res = await getAllFaqTopics(payload);

      setData(res?.faqTopics?.length ? res.faqTopics : []);
      setDataCount(res?.totalCount || 0);
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _onPageChange = (pageNumber = 0) => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["skip"] = pageNumber * newDataPayload["limit"];
    newDataPayload["pageNumber"] = pageNumber;
    setDataPayload(newDataPayload);
    _getAllFaqTopics(newDataPayload);
  };

  const _onChangeCategoryId = () => {
    const newDataPayload = { ...dataPayload };
    newDataPayload["categoryId"] = id;
    setDataPayload(newDataPayload);
    _getAllFaqTopics(newDataPayload);
  };

  const _goBack = () => {
    history.goBack();
  };

  useEffect(() => {
    _onChangeCategoryId();
    _findFaqCategoryById();

    HeaderEventEmitter.subscribe("back-button", _goBack);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <>
      <div className="content">
        <div className="responsiveTitle">
          <div className="d-flex align-items-center">
            <Button
              color="link"
              className="backBtn"
              onClick={() => history.goBack()}
            >
              <img
                src={require("../../../../assets/img/arrowLeft.svg").default}
                alt="backbutton"
                height={14}
              />
            </Button>
            <h2>Topics</h2>
          </div>
        </div>

        <Card>
          {categoryData?.category && (
            <CardHeader>
              <CardTitle>{categoryData.category}</CardTitle>
              {categoryData?.description && (
                <CardSubtitle className="mt-1">
                  {categoryData.description}
                </CardSubtitle>
              )}
            </CardHeader>
          )}

          <CardBody>
            <ListGroup className="topicList">
              {data?.length ? (
                data.map((each) => (
                  <ListGroupItem
                    tag="a"
                    className="cursorPointer"
                    onClick={() =>
                      history.push(
                        `/${userData?.user?.type?.toLowerCase()}/topic/${
                          each._id
                        }`
                      )
                    }
                  >
                    {each?.topicName || "N/A"}
                  </ListGroupItem>
                ))
              ) : (
                <p className="text-center my-3">
                  {loading ? <Spinner /> : "There is no data to display"}
                </p>
              )}
            </ListGroup>
          </CardBody>

          {/* <TablePagination
                data={data}
                dataCount={dataCount}
                pageNumber={dataPayload.pageNumber}
                pageSize={dataPayload.limit}
                onPageChange={_onPageChange}
              /> */}
        </Card>
      </div>
    </>
  );
};

export default TopicList;
