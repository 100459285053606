import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  FormGroup,
  Input,
  Button,
  CustomInput,
  Collapse,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import {
  deepClone,
  showToast,
  getPhoneNumberFromBrackets,
  errorHandler,
  formatDateMoment,
  uploadFileOnServer,
  addUnixTimeToString,
  getFileExtension,
  sleepTime,
} from "../../../helper-methods";
import { RegexConfig } from "../../../config/RegexConfig";
import { countryCodes } from "../../../config/country-codes";
import { reportingCommentForAgentClosing } from "../../../http/http-calls";
import { googlePlaceDetails } from "../../../helper-methods/googleService";
import usaStates from "../../../config/usa_states_titlecase";
import { PostManager } from "../../../post-manager";
import { UploadQueueManager } from "../../../upload-queue-manager";

class ReportModal extends Component {
  state = {
    formFields: {
      comment: {
        value: "",
        error: null,
        isDirty: false,
      },
    },
    loading: false,
  };

  _resetModalState = () => {
    this.setState({
      formFields: {
        comment: {
          value: "",
          error: null,
          isDirty: false,
        },
      },
    });
  };

  _closeModal = (action) => {
    // console.log("in _closeModal");
    this._resetModalState();
    this.props.toggle();
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      this._validateForm();
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        if (formFields[e].isDirty !== undefined) formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty) {
          switch (key) {
            case "comment": {
              if (
                formFields[key]?.value &&
                formFields[key]?.value?.trim()?.length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            default:
          }
        }
      });
      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _sendReportingComment = async (payload) => {
    try {
      await reportingCommentForAgentClosing(payload);
      showToast("Sent Successfully", "success");
      this.setState({ loading: false });
      this._closeModal();
    } catch (err) {
      console.log(err);
      errorHandler(err);
      this.setState({ loading: false });
      this._closeModal();
    }
  };

  _reportingComment = async () => {
    console.log("submitting reporting comment");
    const { aiParserResponse } = this.props.data;
    const { formFields } = deepClone(this.state);

    await this._markAllFieldDirty();
    const isFormValid = await this._validateForm();

    if (!isFormValid) {
      console.log("Form not valid");
      return;
    }

    try {
      this.setState({ loading: true });

      const payload = {
        aiParserResponse: aiParserResponse,
        comments: formFields?.comment?.value,
      };

      const actualResponse = await this.props.getParentpayload();
      // console.log("actualResponse: ", actualResponse);

      payload["actualResponse"] = actualResponse;

      // console.log("payload: ", payload);

      if (this.props.uploadFiles && this.props.uploadFiles.length) {
        payload["file"] = [];

        let postID = PostManager.addMediaFilesCount(
          this.props.uploadFiles.length
        );

        PostManager.onAllMediaFilesUploadCompleted(postID, async (id) => {
          if (id.postID === postID) {
            await sleepTime(500);
            this._sendReportingComment(payload);
            PostManager.deletePostID(postID);
          } else {
            return;
          }
        });

        this.props.uploadFiles.forEach((uploadFile) => {
          // for logo images
          let mediaData = {
            file: uploadFile.uploadData,
            blobObject: uploadFile.previewBlob,
          };
          const uploadId = UploadQueueManager.addMediaToQueue(mediaData, "pdf");

          // Listen for upload complete
          UploadQueueManager.onUploadComplete(
            uploadId,
            async (mediaResponse) => {
              PostManager.onSingleMediaFileUploadCompleted(postID);

              // Upload complete
              // Get content id from backend
              payload.file.push({
                title: addUnixTimeToString(uploadFile.uploadData.name),
                url: mediaResponse.fileUrl,
                docType: getFileExtension(uploadFile.uploadData.name),
              });
            }
          );
        });
      } else {
        this._sendReportingComment(payload);
      }
    } catch (err) {
      console.log("reporting comment err: ", err);
      errorHandler(err);
      this.setState({ loading: false });
      this._closeModal();
    }
  };

  render() {
    const { formFields, loading } = deepClone(this.state);
    console.log("fc", this.state);
    // console.log(this.props.data);
    console.log(this.props);

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._closeModal()}
        className="modal-dialog-scrollable"
      >
        <ModalHeader toggle={() => this._closeModal()}>
          Do you want to report this?
        </ModalHeader>
        <ModalBody>
          <FormGroup className="floatingLabel">
            <Input
              type="textarea"
              placeholder=" "
              value={formFields.comment.value}
              name="comment"
              onChange={(event) =>
                this._onChangeFormField("comment", event.target.value)
              }
            />
            <Label>Comment</Label>
            {formFields.comment.error && (
              <div className="validation-error">{formFields.comment.error}</div>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            disabled={loading}
            onClick={this._reportingComment}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
            Report
          </Button>
          <Button color="primary" outline onClick={() => this._closeModal()}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ReportModal;
