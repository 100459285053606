import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Input,
  FormGroup,
  CardHeader,
  CardBody,
  Card,
} from "reactstrap";
import {
  showToast,
  formatDateMomentMonthFirst,
  errorHandler,
} from "../../../helper-methods";

class AddNotesModal extends Component {
  state = {
    notes: "",
    loading: false,
  };

  _resetStateModal = () => {
    this.setState({
      notes: "",
      loading: false,
    });
  };

  _closeModal = () => {
    this._resetStateModal();
    this.props.toggle();
  };

  _onChangeNotes = (notes) => {
    this.setState({ notes });
  };

  componentDidUpdate = (prevProps) => {
    const { isOpen, data } = this.props;
    if (isOpen && data && isOpen !== prevProps.isOpen) {
      if (
        data.hasOwnProperty("payPeriodPaymentNotesClient") &&
        data.payPeriodPaymentNotesClient
      ) {
        this.setState({ notes: data.payPeriodPaymentNotesClient });
      } else {
        if (data.notes) this.setState({ notes: data.notes });
      }
    }
  };

  _onSaveNotes = async () => {
    try {
      this.setState({ loading: true });

      const { data } = this.props;
      const { notes } = this.state;

      await this.props.onSaveNotes(data.id, notes?.trim() || "");

      this._closeModal();

      showToast("Notes updated", "success");
    } catch (error) {
      this.setState({ loading: false });
      errorHandler(error);
    }
  };

  render() {
    const { notes, loading } = this.state;

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this._closeModal}
        centered
        scrollbar
      >
        <ModalHeader toggle={this._closeModal}>Notes</ModalHeader>
        <ModalBody>
          <FormGroup className="floatingLabel">
            <Input
              type="textarea"
              rows="5"
              placeholder=" "
              value={notes}
              onChange={(e) => this._onChangeNotes(e.target.value)}
            />
            <Label>
              {" "}
              {this?.props?.data?.paymentHistory ? "Description" : "Notes"}
            </Label>
          </FormGroup>
          {this?.props?.data?.paymentHistory ? (
            <Card>
              <CardHeader>
                {this?.props?.data?.paymentHistory ? (
                  <>Payment History</>
                ) : null}
              </CardHeader>
              <CardBody>
                {this.props?.data?.paymentHistory &&
                  this.props?.data?.paymentHistory.map((item) => (
                    <p style={{ color: "#1B2430", marginBottom: 5 }}>
                      {" "}
                      ${item.paymentAmount} was paid{" "}
                      {item.referenceNumber && "via check#"}
                      {item.referenceNumber} {item.checkDate && "on"}{" "}
                      {formatDateMomentMonthFirst(item.checkDate)}
                    </p>
                  ))}
              </CardBody>
            </Card>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" size="lg" outline onClick={this._closeModal}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={loading}
            size="lg"
            onClick={this._onSaveNotes}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null} Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddNotesModal;
