import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  FormGroup,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Progress,
  Spinner,
} from "reactstrap";
import {
  deepClone,
  errorHandler,
  getCountryDialCodeFromCountryCode,
  getPhoneNumberFromBrackets,
  uploadFileOnServer,
} from "../../../helper-methods";
import { showToast } from "../../../helper-methods";
import { RegexConfig } from "../../../config/RegexConfig";
import {
  connectCompanyByClient,
  enquiryCompanyDetailsByEmail,
  updateCompanyByClient,
} from "../../../http/http-calls";
import { connect } from "react-redux";
import { DEFAULT_COVER_PICTURE } from "../../../config";

class InviteCompany extends Component {
  state = {
    formFields: {
      name: {
        value: "",
        error: null,
        isDirty: false,
      },
      phone: {
        value: "",
        error: null,
        isDirty: false,
      },
      phoneCountry: {
        value: "US",
        error: null,
        isDirty: false,
      },
      email: {
        value: "",
        error: null,
        isDirty: false,
      },
      pointOfContact: {
        value: "",
        error: null,
        isDirty: false,
      },
      logo: {
        file: {
          uploadData: null,
          previewBlob: null,
          type: "image",
          uploadUrl: null,
        },
        error: null,
        isDirty: false,
      },
    },
    loading: false,
    enquiryLoading: false,
  };

  _setModalState = (data = null) => {
    this.setState({
      formFields: {
        name: {
          value: data?.companyName || "",
          error: null,
          isDirty: false,
        },
        phone: {
          value: data?.phone ? getPhoneNumberFromBrackets(data.phone) : "",
          error: null,
          isDirty: false,
        },
        phoneCountry: {
          value: data?.phoneCountry || "US",
          error: null,
          isDirty: false,
        },
        email: {
          value: data?.email || "",
          error: null,
          isDirty: false,
        },
        pointOfContact: {
          value: data?.pointOfContact || "",
          error: null,
          isDirty: false,
        },
        logo: {
          file: {
            uploadData: null,
            previewBlob: null,
            type: "image",
            uploadUrl: data?.logo || null,
          },
          error: null,
          isDirty: false,
        },
      },
      loading: false,
    });
  };

  componentDidUpdate(previousProps, previousState) {
    const { isOpen, data } = this.props;
    console.log(this.props.data);
    if (isOpen && data && previousProps.isOpen !== isOpen) {
      // console.log(data);
      // this.setState({
      //   rating: data.previousRating !== null ? data.previousRating.rating : 5,
      //   comment:
      //     data.previousRating !== null ? data.previousRating.comments : "",
      // });
      const { formFields } = deepClone(this.state);
      formFields["name"].value = data.companyName;

      formFields["phone"].value = data.phone
        ? getPhoneNumberFromBrackets(data.phone)
        : "";

      formFields["logo"].file = {
        previewBlob: data.logo ? data.logo : null,
        uploadData: null,
      };
      formFields["email"].value = data.email ? data.email : "";
      formFields["pointOfContact"].value = data?.pointOfContact;
      this.setState({ formFields });
    }
  }

  _enquiryCompanyDetailsByEmail = async () => {
    try {
      const { formFields } = this.state;

      this.setState({ enquiryLoading: true });

      const { company } = await enquiryCompanyDetailsByEmail({
        email: formFields.email.value.trim(),
      });

      this._setModalState(company);

      this.setState({ enquiryLoading: false });
    } catch (error) {
      errorHandler(error);
      this.setState({ enquiryLoading: false });
    }
  };

  _closeModal = () => {
    this._setModalState();
    this.props.toggle();
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty) {
          switch (key) {
            case "name": {
              if (formFields[key].value?.trim().length) {
                // if (
                //   formFields[key].value?.trim().length > 1 &&
                //   RegexConfig.name.test(
                //     String(formFields[key].value).toLowerCase()
                //   )
                // ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
                // } else {
                //   formFields[key].error =
                //     "*Minimum 2 characters & Maximum 25 characters, number and special character not allowed except '";
                //   isFormValid = false;
                // }
              } else {
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            // case "pointOfContact": {
            //   if (this.props.data) {
            //     if (formFields[key].value?.trim().length) {
            //       formFields[key].isDirty = false;
            //       formFields[key].error = null;
            //     } else {
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Required";
            //       isFormValid = false;
            //     }
            //   }
            //   break;
            // }
            case "email": {
              if (formFields[key].value?.trim().length) {
                if (
                  RegexConfig.email.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  this._enquiryCompanyDetailsByEmail();
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "phone": {
              if (formFields[key].value?.trim().length) {
                if (
                  RegexConfig.phone.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  isFormValid = false;
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Phone Number";
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _updateLogoImage = (e) => {
    try {
      if (e?.target?.files?.length) {
        const { formFields } = this.state;

        const file = e.target.files[0];

        const fileType = file.type.split("/")[0];

        if (fileType === "image") {
          const previewBlob = URL.createObjectURL(file);

          formFields.logo.file["uploadData"] = file;
          formFields.logo.file["previewBlob"] = previewBlob;
          formFields.logo.file["type"] = fileType;
          formFields.logo.file["uploadUrl"] = null;
        } else {
          showToast("Only image file is allowed", "error");
          return;
        }

        this.setState({ formFields });
      }
    } catch (error) {
      errorHandler();
    }
  };

  _onChangeFormFields = (key, value) => {
    const { formFields } = deepClone(this.state);

    if (key === "phone" && (isNaN(value) || value.includes("."))) {
      return;
    }

    formFields[key].value = value;

    this.setState({ formFields });
  };

  _onBlurFormFields = (key) => {
    const { formFields } = deepClone(this.state);
    formFields[key].isDirty = true;
    this.setState({ formFields }, () => {
      // Validation
      this._validateForm();
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((key) => {
        formFields[key].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _connectCompanyByClient = (payload) => {
    connectCompanyByClient(payload)
      .then((res) => {
        showToast("Company has been added", "success");
        this.props.onSuccess();
        this._closeModal();
      })
      .catch((error) => {
        errorHandler(error);
        this.setState({ loading: false });
      });
  };

  _updatedCompanyByClient = (payload) => {
    updateCompanyByClient(this.props?.data?.id, payload)
      .then((res) => {
        showToast("Company has been Updated", "success");
        this.props.onSuccess();
        this._closeModal();
      })
      .catch((error) => {
        errorHandler(error);
        this.setState({ loading: false });
      });
  };

  _saveClientData = async () => {
    await this._markAllFieldDirty();

    const isFormValid = await this._validateForm();

    if (isFormValid) {
      this.setState({ loading: true });

      const { formFields } = deepClone(this.state);

      // const splitName = formFields.name.value.trim().split(" ");

      const countryDialCode = await getCountryDialCodeFromCountryCode(
        formFields.phoneCountry.value
      );

      const payload = {
        // name: {
        //   first: splitName.slice(0, 1).join(" "),
        //   last: splitName[1] ? splitName.slice(1).join(" ") : "",
        // },
        companyName: formFields.name.value.trim(),
        email: formFields.email.value.trim(),
        phoneCountry: formFields.phoneCountry.value,
        pointOfContact: formFields?.pointOfContact?.value?.trim(),
      };

      if (countryDialCode && formFields.phone.value) {
        payload["phone"] = `(${countryDialCode})${formFields.phone.value}`;
      }

      if (formFields.logo.file.uploadData) {
        const response = await uploadFileOnServer([
          { ...formFields.logo.file },
        ]);

        payload["logo"] = response[0].url;

        formFields.logo.file["uploadUrl"] = response[0].url;
        formFields.logo.file["uploadData"] = null;

        this.setState({ formFields });
      } else if (formFields.logo.file.uploadUrl) {
        payload["logo"] = formFields.logo.file.uploadUrl;
      }
      this._addOrEditCompanyClient(payload);
      // this._connectCompanyByClient(payload);
    }
  };

  _addOrEditCompanyClient = (payload) => {
    console.log("2333", this.props.data);
    if (this.props?.data === undefined) {
      this._connectCompanyByClient(payload);
    } else {
      this._updatedCompanyByClient(payload);
    }
  };

  render() {
    const { formFields, loading, enquiryLoading } = this.state;

    const { isOpen, data } = this.props;

    return (
      <Modal isOpen={isOpen} toggle={() => this._closeModal()} size="md" scrollable centered>
        <ModalHeader toggle={this._closeModal}>
          {data ? "Update" : "Invite"} Company{" "}
          {enquiryLoading ? <Spinner size="sm" /> : null}
        </ModalHeader>
        <ModalBody>
          <Label className="uploadProfile">
            <Input
              type="file"
              style={{ display: "none" }}
              accept="image/x-png,image/gif,image/jpeg"
              value=""
              onChange={this._updateLogoImage}
            />
            <img
              src={
                formFields.logo.file.previewBlob ||
                formFields.logo.file.uploadUrl ||
                DEFAULT_COVER_PICTURE
                // require("../../../assets/img/company-logo.png").default
              }
              loading="lazy"
              alt="Client Img"
            />
            <i className="fa fa-pencil" />
          </Label>
          <FormGroup className="floatingLabel">
            <Input
              type="text"
              value={formFields.name.value}
              onChange={(e) => this._onChangeFormFields("name", e.target.value)}
              onBlur={() => this._onBlurFormFields("name")}
              placeholder=" "
            />
            <Label>Company Name</Label>
            {formFields.name.error && (
              <div className="validation-error">{formFields.name.error}</div>
            )}
          </FormGroup>
          <FormGroup className="floatingLabel withInputGroup">
            {/* country code static (+1) not change by user */}
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>+1</InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder=" "
                value={formFields.phone.value}
                onChange={(e) =>
                  this._onChangeFormFields("phone", e.target.value)
                }
                onBlur={() => this._onBlurFormFields("phone")}
              />
              <Label>Contact Number</Label>
            </InputGroup>
            {formFields.phone.error && (
              <div className="validation-error">{formFields.phone.error}</div>
            )}
          </FormGroup>
          <FormGroup className="floatingLabel">
            <Input
              type="text"
              value={formFields.email.value}
              onChange={(e) =>
                this._onChangeFormFields("email", e.target.value)
              }
              onBlur={() => this._onBlurFormFields("email")}
              disabled={this.props?.data !== undefined ? true : false}
              placeholder=" "
            />
            <Label>General Email</Label>
            {formFields.email.error && (
              <div className="validation-error">{formFields.email.error}</div>
            )}
          </FormGroup>
          <FormGroup className="floatingLabel">
            <Input
              type="text"
              value={formFields.pointOfContact.value}
              onChange={(e) =>
                this._onChangeFormFields("pointOfContact", e.target.value)
              }
              onBlur={() => this._onBlurFormFields("pointOfContact")}
              placeholder=" "
            />
            <Label>Point Of Contact</Label>
            {formFields.pointOfContact.error && (
              <div className="validation-error">
                {formFields.pointOfContact.error}
              </div>
            )}
          </FormGroup>
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            outline
            size="lg"
            onClick={() => this._closeModal()}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            disabled={loading}
            onClick={() => this._saveClientData()}
            size="lg"
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
            {data ? "Update" : "Invite"}
          </Button>
        </ModalFooter>

        {loading && Number(this.props.percentage) > 0 && (
          <Progress animated color="success" value={this.props.percentage} />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    percentage:
      state.uploadFilePercentage && state.uploadFilePercentage.percentage
        ? state.uploadFilePercentage.percentage
        : "0",
  };
};

export default connect(mapStateToProps, null)(InviteCompany);
