import React, { useEffect, useState } from "react";
import { Card, CardBody, Button } from "reactstrap";
import { errorHandler } from "../../../helper-methods";
import {
  agentFindCustomEmailTemplates,
  clientFindCustomEmailTemplates,
  getAllCustomEmailCompany,
} from "../../../http/http-calls";
import CustomizeEmailModal from "./customizeEmailModal";
import {
  EmailCustomizationTitle,
  EmailCustomizationVariable,
} from "../../../config";
import { useSelector } from "react-redux";

const EmailCustomization = () => {
  const userData = useSelector((state) => state.userData);

  const [emailModal, setEmailModal] = useState({
    isOpen: false,
    emailData: null,
  });

  const [data, setData] = useState([]);

  const _toggleModal = (isOpen = false, item) => {
    let emailData = data.find((each) => each.title === item);
    setEmailModal({
      isOpen,
      emailData:
        emailData !== undefined ? emailData : item !== undefined ? item : null,
    });
  };

  useEffect(() => {
    _getAllCustomizedEmail();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _getAllCustomizedEmail = () => {
    const userType = userData?.user?.type;

    switch (userType) {
      case "SigningCompany":
        _getCustomizedEmail();
        break;
      case "Agent":
        _agentFindCustomEmailTemplates();
        break;
      case "Client":
        _clientFindCustomEmailTemplates();
        break;
      default:
        break;
    }
  };

  const _getCustomizedEmail = async () => {
    try {
      let response = await getAllCustomEmailCompany();

      setData(response?.emailTemplates);
    } catch (error) {
      errorHandler(error);
    }
  };
  const _agentFindCustomEmailTemplates = async () => {
    try {
      let response = await agentFindCustomEmailTemplates();
      setData(response?.emailTemplates);
    } catch (error) {
      errorHandler(error);
    }
  };
  const _clientFindCustomEmailTemplates = async () => {
    try {
      let response = await clientFindCustomEmailTemplates();

      setData(response?.emailTemplates);
    } catch (error) {
      errorHandler(error);
    }
  };

  const clientEmailCustomization = [
    "custom-client-team-member-invitaion",
    "cutom-payment-received-by-company-from-client",
    "custom-client-invites-company-to-connect",
    "custom-client-invites-unregistered-sigining-company-to-connect-on-closewise",
    "custom-closing-assigned",
    "custom-closing-unassigned",
  ];

  const companyEmailCustomization = [
    "custom-team-member-invitaion",
    // "custom-client-team-member-invitaion",
    "custom-agent-pay-period",
    "custom-agent-registration-invite",
    // "cutom-payment-received-by-company-from-client",
    // "custom-agent-create-invoice",
    "custom-company-invoice",
    // "custom-agent-invoice",
    // "custom-client-invites-company-to-connect",
    // "custom-client-invites-unregistered-sigining-company-to-connect-on-closewise",
    "custom-company-invites-client-to-create-closewise-connect-account",
    "custom-company-invites-client-to-connect",
    "custom-agent-fee-update",
    "custom-company-uploaded-document",
    "custom-company-verified-docs",
    "custom-confirm-appointment-reminder",
    // "custom-closing-status-agent",
    "custom-ach-payment",
    "custom-agent-schedule",
    "custom-closing-assigned",
    "custom-closing-unassigned",
    "custom-closing-status-company",
    "custom-new-matched-closing",
    "custom-appointment-pre-reminder",
    "custom-appointment-post-reminder",
  ];
  const agentEmailCustomization = [
    "custom-agent-create-invoice",
    "custom-agent-invoice",
    // "custom-closing-status-company",
    "custom-closing-status-agent",
    "custom-agent-uploaded-document",
  ];

  const ObjectToShow = () => {
    const userType = userData?.user?.type;

    switch (userType) {
      case "SigningCompany":
        return _filteredEmailCustomizationVariable(companyEmailCustomization);

      case "Agent":
        return _filteredEmailCustomizationVariable(agentEmailCustomization);

      case "Client":
        return _filteredEmailCustomizationVariable(clientEmailCustomization);

      default:
    }
  };

  const _filteredEmailCustomizationVariable = (array) => {
    return Object.keys(EmailCustomizationVariable)
      .filter((key) => array.includes(key))
      .reduce((obj, key) => {
        obj[key] = EmailCustomizationVariable[key];
        return obj;
      }, {});
  };

  return (
    <>
      <Card className="settingCard">
        <CardBody>
          <ul>
            {Object.keys(ObjectToShow()).map((item, index) => (
              <li>
                <div>{EmailCustomizationTitle[item]}</div>
                <Button
                  color="link"
                  outline
                  className="downloadButton"
                  onClick={() => _toggleModal(true, item)}
                >
                  Customize
                </Button>
              </li>
            ))}
          </ul>
        </CardBody>
      </Card>

      <CustomizeEmailModal
        isOpen={emailModal.isOpen}
        data={emailModal.emailData}
        toggle={_toggleModal}
        userData={userData.user}
        getCustomizedEmail={_getAllCustomizedEmail}
      />
    </>
  );
};

export default EmailCustomization;
