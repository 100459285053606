import React, { Component } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {
  Row,
  Col,
  Input,
  FormGroup,
  Spinner,
  Card,
  Button,
  CardBody,
  Label,
} from "reactstrap";
import {
  deepClone,
  formatCurrencyValue,
  getDateRangeValueSummarie,
  showToast,
  isRegularUser,
  formatOnlyDateMoment,
  refreshFunc,
  errorHandler,
} from "../../../helper-methods";
import {
  agentGetAllUnregisteredClient,
  agentGetReportingSummaries,
} from "../../../http/http-calls";
import { customDateRangeConfig, monthShortNames } from "../../../config";
import BarGraph from "../components/graph/BarGraph";
import LineGraph from "../components/graph/LineGraph";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";

class SummariesReportingAgent extends Component {
  state = {
    cardList: {
      incomes: 0,
      closings: 0,
      expenses: 0,
      net: 0,
    },
    orderGraphData: {
      colors: ["#07B8E7"],
      tooltipName1: "Order #",
      series1: [],
    },
    incomeGraphData: {
      colors: ["#4C96CE"],
      tooltipName1: "Income",
      series1: [],
      formatter1: (value) => formatCurrencyValue(value || "0"),
    },
    expenseAndNetGraphData: {
      colors: ["#07B8E7", "#F0B634"],
      tooltipName1: "Expenses",
      series1: [],
      formatter1: (value) => formatCurrencyValue(value || "0"),
      tooltipName2: "Net",
      series2: [],
      formatter2: (value) => formatCurrencyValue(value || "0"),
    },
    filterDropdownValues: {
      signingCompany: [],
      clients: [],
    },
    filters: {
      dateRangeValue: null,
      customDateRangeValue: "month",
      client: "",
    },
    loading: {
      showTableLoading: false,
    },
    isClassAdded: false,
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  _setGraphData = (closingsGraph = [], incomeGraph = [], expenseGraph = []) => {
    try {
      const {
        orderGraphData,
        incomeGraphData,
        expenseAndNetGraphData,
        filters,
      } = deepClone(this.state);

      // closingsGraph
      orderGraphData.series1 = closingsGraph?.length
        ? closingsGraph.map((each) => {
            let label = "";

            if (
              filters.customDateRangeValue === "month" ||
              filters.customDateRangeValue === "day" ||
              filters.customDateRangeValue === "week"
            ) {
              label = `${each._id.day} ${monthShortNames[each._id.month - 1]}`;
            } else if (filters.customDateRangeValue === "allTime") {
              label = `${each._id.year}`;
            } else {
              label = `${monthShortNames[each._id.month - 1]} ${each._id.year}`;
            }

            return { label, value: each.count };
          })
        : [];

      // incomeGraph
      incomeGraphData.series1 = incomeGraph?.length
        ? incomeGraph.map((each) => {
            let label = "";

            if (
              filters.customDateRangeValue === "month" ||
              filters.customDateRangeValue === "day" ||
              filters.customDateRangeValue === "week"
            ) {
              label = `${each._id.day} ${monthShortNames[each._id.month - 1]}`;
            } else if (filters.customDateRangeValue === "allTime") {
              label = `${each._id.year}`;
            } else {
              label = `${monthShortNames[each._id.month - 1]} ${each._id.year}`;
            }

            return { label, value: each.amount };
          })
        : null;

      // expenseGraph
      expenseAndNetGraphData.series1 = expenseGraph?.length
        ? expenseGraph.map((each) => {
            let label = "";

            if (
              filters.customDateRangeValue === "month" ||
              filters.customDateRangeValue === "day" ||
              filters.customDateRangeValue === "week"
            ) {
              label = `${each._id.day} ${monthShortNames[each._id.month - 1]}`;
            } else if (filters.customDateRangeValue === "allTime") {
              label = `${each._id.year}`;
            } else {
              label = `${monthShortNames[each._id.month - 1]} ${each._id.year}`;
            }

            return { label, value: each.amount };
          })
        : [];
      // netGraphConfig = (incomeGraph - expenseGraph)
      if (incomeGraph?.length && expenseGraph?.length) {
        let netPofiteGraph = [];
        let incomeArray = incomeGraph;
        let expenseArray = expenseGraph;

        netPofiteGraph = incomeArray.map((income) => {
          const incomeDateObjString = JSON.stringify(income._id);
          const expenseIndexOfSameDate = expenseArray.findIndex(
            (exp) => JSON.stringify(exp._id) === incomeDateObjString
          );
          if (expenseIndexOfSameDate > -1) {
            let expenseObj = expenseArray.splice(expenseIndexOfSameDate, 1);
            return {
              amount: income.amount - expenseObj[0].amount,
              _id: income._id,
            };
          }
          return income;
        });

        if (expenseArray?.length) {
          expenseArray = expenseArray.map((expense) => {
            return {
              amount: -expense.amount,
              _id: expense._id,
            };
          });
          netPofiteGraph = [...netPofiteGraph, ...expenseArray];
          netPofiteGraph.sort((a, b) => {
            if (a._id?.day > b._id?.day) return 1;
            if (a._id?.day < b._id?.day) return -1;
            if (a._id?.month > b._id?.month) return 1;
            if (a._id?.month < b._id?.month) return -1;
            return 0;
          });
        }

        expenseAndNetGraphData.series2 = netPofiteGraph.map((each, index) => {
          let label = "";

          if (
            filters.customDateRangeValue === "month" ||
            filters.customDateRangeValue === "day" ||
            filters.customDateRangeValue === "week"
          ) {
            label = `${each._id.day} ${monthShortNames[each._id.month - 1]}`;
          } else if (filters.customDateRangeValue === "allTime") {
            label = `${each._id.year}`;
          } else {
            label = `${monthShortNames[each._id.month - 1]} ${each._id.year}`;
          }

          const findInExpense = expenseAndNetGraphData.series1.find(
            (each) => each.label === label
          );
          if (!findInExpense) {
            expenseAndNetGraphData.series1.splice(index, 0, {
              label,
              value: 0,
            });
          }

          return { label, value: each.amount };
        });
      } else if (incomeGraph?.length && !expenseGraph?.length) {
        expenseAndNetGraphData.series2 = incomeGraph.map((each, index) => {
          let label = "";

          if (
            filters.customDateRangeValue === "month" ||
            filters.customDateRangeValue === "day" ||
            filters.customDateRangeValue === "week"
          ) {
            label = `${each._id.day} ${monthShortNames[each._id.month - 1]}`;
          } else if (filters.customDateRangeValue === "allTime") {
            label = `${each._id.year}`;
          } else {
            label = `${monthShortNames[each._id.month - 1]} ${each._id.year}`;
          }

          const findInExpense = expenseAndNetGraphData.series1.find(
            (each) => each.label === label
          );
          if (!findInExpense) {
            expenseAndNetGraphData.series1.splice(index, 0, {
              label,
              value: 0,
            });
          }

          return { label, value: each.amount };
        });
      } else if (!incomeGraph?.length && expenseGraph?.length) {
        expenseAndNetGraphData.series2 = expenseGraph.map((each, index) => {
          let label = "";

          if (
            filters.customDateRangeValue === "month" ||
            filters.customDateRangeValue === "day" ||
            filters.customDateRangeValue === "week"
          ) {
            label = `${each._id.day} ${monthShortNames[each._id.month - 1]}`;
          } else if (filters.customDateRangeValue === "allTime") {
            label = `${each._id.year}`;
          } else {
            label = `${monthShortNames[each._id.month - 1]} ${each._id.year}`;
          }

          const findInExpense = expenseAndNetGraphData.series1.find(
            (each) => each.label === label
          );
          if (!findInExpense) {
            expenseAndNetGraphData.series1.splice(index, 0, {
              label,
              value: 0,
            });
          }

          return { label, value: -each.amount };
        });
      } else {
        expenseAndNetGraphData.series2 = [];
      }

      this.setState({
        orderGraphData,
        incomeGraphData,
        expenseAndNetGraphData,
      });
    } catch (error) {
      console.log("error >> ", error);
      showToast("Somthing went wrong. Try again after sometime.", "error");
    }
  };

  _agentGetReportingSummaries = () => {
    this._manageLoading("showTableLoading", true);

    const { filters, filterDropdownValues } = deepClone(this.state);

    const payload = {
      filters: {},
    };
    console.log("t", filters.client && filters.client.length > 0);

    if (filters.client && filters.client.length > 0) {
      filterDropdownValues.clients.find((each) => {
        console.log("o", each._id === filters.client);
        if (each._id === filters.client) {
          // console.log("i", each.hasOwnProperty("_client"));
          if (each.hasOwnProperty("_client") && each._client !== "") {
            payload.filters["clientId"] = each._client || filters.client;
            payload.filters["companyId"] = "unregisteredClient";
          } else if (
            each.hasOwnProperty("_signingCompany") &&
            each._signingCompany !== ""
          ) {
            payload.filters["companyId"] = each._signingCompany;
          } else {
            payload.filters["assistantId"] = each._assistant;
          }
        }
      });
    }
    // payload.filters["companyId"] = filters.client;

    if (filters.dateRangeValue) {
      payload.filters["startDate"] = formatOnlyDateMoment(
        filters.dateRangeValue[0]
      );
      payload.filters["endDate"] = formatOnlyDateMoment(
        filters.dateRangeValue[1]
      );
    }
    if (filters.customDateRangeValue) {
      // AgentExpensesSummaries -
      // {String} [groupByUnit] `enum = ["month", "day", "year"]
      if (
        filters.customDateRangeValue === "month" ||
        filters.customDateRangeValue === "day" ||
        filters.customDateRangeValue === "week"
      ) {
        payload.filters["groupByUnit"] = "day";
      } else if (filters.customDateRangeValue === "allTime") {
        payload.filters["groupByUnit"] = "year";
      }
    }
    console.log("payload", payload);
    agentGetReportingSummaries(payload)
      .then((res) => {
        this._setGraphData(
          res?.closingsGraph,
          res?.incomeGraph,
          res?.expenseGraph
        );

        this.setState(
          {
            cardList: {
              incomes: res?.incomes,
              closings: res?.closings,
              expenses: res?.expenses,
              net: res?.net,
            },
          },
          () => this._manageLoading("showTableLoading", false)
        );
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _setDateRangeValue = (value = "month") => {
    const { filters } = this.state;

    switch (value) {
      case "day":
      case "week":
      case "month":
      case "year": {
        filters["dateRangeValue"] = getDateRangeValueSummarie(value);
        break;
      }
      case "allTime": {
        filters["dateRangeValue"] = null;
        break;
      }
      case "customDateRange": {
        filters["dateRangeValue"] = null;
        filters["customDateRangeValue"] = value;
        this.setState({ filters });
        return;
      }
      default: {
        showToast("Something went wrong. Try again after sometime.", "error");
        return;
      }
    }

    filters["customDateRangeValue"] = value;
    this.setState({ filters }, () => {
      this._agentGetReportingSummaries();
      this._persistFilter();
    });
  };

  _filterOnChange = (type, value) => {
    const { filters } = this.state;
    if (type === "dateRangeValue") {
      if (value) {
        filters["customDateRangeValue"] = "customDateRange";
        filters[type] = value;
        this.setState({ filters }, () => {
          this._agentGetReportingSummaries();
          this._persistFilter();
        });
      } else {
        this._setDateRangeValue();
      }
    } else {
      filters[type] = value;
      this.setState({ filters }, () => {
        this._agentGetReportingSummaries();
        this._persistFilter();
      });
    }
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          dateRangeValue: null,
          customDateRangeValue: "month",
          client: "",
        },
      },
      () => {
        this._setDateRangeValue();
        refreshFunc("agent-reporting-summaries-responsive");
      }
    );
  };

  // _getAllFiltersData = () => {
  //   getAllFiltersData()
  //     .then((res) => {
  //       const { filterDropdownValues } = deepClone(this.state);
  //       filterDropdownValues["signingCompany"] = res.signingCompany;
  //       this.setState({ filterDropdownValues });
  //     })
  //     .catch((error) => {
  //       showToast(
  //         error && error.reason && error.reason.length
  //           ? error.reason
  //           : "Server error. Try again after sometime.",
  //         "error"
  //       );
  //     });
  // };

  _agentGetAllUnregisteredClient = () => {
    agentGetAllUnregisteredClient()
      .then((res) => {
        const { filterDropdownValues } = deepClone(this.state);
        filterDropdownValues["clients"] = res?.clients;
        this.setState({ filterDropdownValues }, () => {
          this._setFilterDataFromLocalStorage();
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  componentDidMount = () => {
    if (isRegularUser()) {
      // this._getAllFiltersData();
      this._agentGetAllUnregisteredClient();
      // this._setFilterDataFromLocalStorage();
      // call function after set date range -> _agentGetReportingSummaries();
      // this._setDateRangeValue();
      HeaderEventEmitter.subscribe("reset-filter", () => {
        console.log("filter reset hit");
        this._resetFilter();
        refreshFunc("agent-reporting-summaries");
      });
    } else {
      this.props.history.goBack();
    }
  };

  _persistFilter = () => {
    const { filters } = this.state;
    if (
      filters &&
      (filters.client ||
        (filters.customDateRangeValue &&
          filters.customDateRangeValue !== "month"))
    ) {
      localStorage.agentexpensesummary = JSON.stringify(filters);
    } else {
      delete localStorage.agentexpensesummary;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    // const { filters, tableConfig } = deepClone(this.state);

    if (localStorage && localStorage.agentexpensesummary) {
      try {
        const filters = JSON.parse(localStorage.agentexpensesummary);
        this.setState({ filters }, () => {
          // this._getAllStatusReport();
          this._setDateRangeValue();
        });
      } catch (e) {
        // this._getAllStatusReport();
        this._setDateRangeValue();
      }
    } else {
      // this._getAllStatusReport();
      this._setDateRangeValue();
    }
  };

  _prepareFilterData = () => {
    const { filters } = deepClone(this.state);

    if (!filters.states) delete filters.agent;
    if (!filters.status) delete filters.status;

    return filters;
  };

  render() {
    const {
      incomeGraphData,
      orderGraphData,
      expenseAndNetGraphData,
      filters,
      filterDropdownValues,
      cardList,
      loading,
      isClassAdded,
    } = this.state;

    return (
      <div className="content">
        <div className="responsiveTitle">
          <h2>Summaries</h2>

          <div className="rightSide">
            <Button color="link" onClick={this._resetFilter}>
              <img
                id="agent-reporting-summaries-responsive"
                src={require("../../../assets/img/refresh.svg").default}
                alt="refresh"
              />
            </Button>
            <Button
              className="filterBarIcon"
              color="link"
              onClick={this.filterShowMobile}
            >
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="Filter"
              />
            </Button>
          </div>
        </div>
        <Row>
          <Col md="12">
            {/* filter for tablet and web */}
            <div
              onClick={this.handleButtonClick}
              className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
            ></div>
            <div
              className={`filterContainer responsiveFilter ${
                isClassAdded ? "show" : ""
              }`}
            >
              <div className="filterIcon">
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="filter icon"
                />
                Filter by
              </div>
              <div className="mobileTitle">
                <h2>Filter by</h2>
                <Button
                  color="link"
                  className="closeButton"
                  onClick={this.filterShowMobile}
                >
                  <img
                    src={require("../../../assets/img/close_grey.svg").default}
                    alt="close"
                    height={12}
                  />
                </Button>
              </div>
              <div className="filterWrapper">
                <FormGroup>
                  <Label>Date Added</Label>
                  <div className="custom-select-wrapper">
                    <Input
                      type="select"
                      value={filters.customDateRangeValue}
                      name="customDateRangeValue"
                      onChange={(e) => this._setDateRangeValue(e.target.value)}
                    >
                      {customDateRangeConfig.map((obj) => (
                        <option key={obj.value} value={obj.value}>
                          {obj.label}
                        </option>
                      ))}
                    </Input>
                  </div>
                </FormGroup>
                <FormGroup className="dateRange">
                  <Label>Date Range</Label>
                  <DateRangePicker
                    isOpen={
                      !filters.dateRangeValue &&
                      filters.customDateRangeValue === "customDateRange"
                        ? true
                        : false
                    }
                    // clearIcon={null}
                    className="dateRange"
                    format="MM-dd-y"
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                    onChange={(dateRangeValue) =>
                      this._filterOnChange("dateRangeValue", dateRangeValue)
                    }
                    // maxDate={new Date()}
                    value={filters.dateRangeValue}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Client</Label>
                  <div className="custom-select-wrapper">
                    <Input
                      type="select"
                      value={filters.client}
                      name="client"
                      onChange={(e) =>
                        this._filterOnChange("client", e.target.value)
                      }
                    >
                      <option value="">All</option>
                      {/* <option value="unregisteredClient">
                      Unregistered Client
                    </option> */}
                      {filterDropdownValues.clients.map((obj) => (
                        <option key={obj.id} value={obj.id}>
                          {/* {(obj.companyName && capitalize(obj.companyName)) ||
                          "N/A Company"} */}
                          {obj?._signingCompany
                            ? obj.companyName
                            : obj?._client
                            ? obj.name?.full
                            : obj?._assistant
                            ? // : obj?._clientAssistant
                              // ? obj.name?.full
                              // : obj?._companyAssistant
                              obj.name?.full
                            : "N/A"}
                        </option>
                      ))}
                    </Input>
                  </div>
                </FormGroup>
              </div>
              <div className="clearButton">
                <Button
                  size="md"
                  color="primary"
                  onClick={this.filterShowMobile}
                >
                  Close
                </Button>
              </div>
            </div>

            {loading.showTableLoading ? (
              <div className="text-center mb-4 mt-2">
                <Spinner />
              </div>
            ) : (
              <Row className="mt-2">
                <Col xl={3} lg={6} xs={6}>
                  <Card className="infoCard">
                    <CardBody>
                      <div className="">
                        <span>Closing Orders</span>
                        <h4>{cardList.closings || 0}</h4>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            require("../../../assets/img/TotalOrders.svg")
                              .default
                          }
                          alt="notaryActs"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} lg={6} xs={6}>
                  <Card className="infoCard">
                    <CardBody>
                      <div className="">
                        <span>Total Income</span>
                        <h4>
                          {cardList.incomes
                            ? formatCurrencyValue(cardList.incomes)
                            : "$0"}
                        </h4>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            require("../../../assets/img/Income.png").default
                          }
                          alt="notaryActs"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} lg={6} xs={6}>
                  <Card className="infoCard">
                    <CardBody>
                      <div className="">
                        <span>Total Expenses</span>
                        <h4>
                          {cardList.expenses
                            ? formatCurrencyValue(cardList.expenses)
                            : "$0"}
                        </h4>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            require("../../../assets/img/Expenses.svg").default
                          }
                          alt="notaryActs"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} lg={6} xs={6}>
                  <Card className="infoCard">
                    <CardBody>
                      <div>
                        <span>Net Profit</span>
                        <h4
                          className={
                            cardList.net <= 0 ? "text-danger" : "text-success"
                          }
                        >
                          {cardList.net
                            ? formatCurrencyValue(cardList.net)
                            : "$0"}
                        </h4>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            require("../../../assets/img/TotalIncome.svg")
                              .default
                          }
                          alt="notaryActs"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}

            {!loading.showTableLoading && (
              <Row>
                <Col md="6" className="mb-4">
                  {/* closing graph */}
                  {orderGraphData?.series1?.length ? (
                    <div className="graphWrap h-100">
                      <h1>Order</h1>
                      <BarGraph data={orderGraphData} height={400} />
                    </div>
                  ) : (
                    <div className="graphWrap noData h-100">
                      <img
                        src={require("../../../assets/img/noData.svg").default}
                        alt="no data"
                      />

                      <p>There is no closing data to display on chart.</p>
                    </div>
                  )}
                </Col>

                <Col md="6" className="mb-4">
                  {/* income graph */}
                  {incomeGraphData?.series1?.length ? (
                    <div className="graphWrap h-100">
                      <h1>Income</h1>
                      <BarGraph data={incomeGraphData} height={400} />
                    </div>
                  ) : (
                    <div className="graphWrap noData h-100">
                      <img
                        src={require("../../../assets/img/noData.svg").default}
                        alt="no data"
                      />

                      <p>There is no income data to display on chart.</p>
                    </div>
                  )}
                </Col>

                <Col md="12">
                  {/* expense & net graph */}
                  {expenseAndNetGraphData?.series1?.length ||
                  expenseAndNetGraphData?.series2?.length ? (
                    <div className="graphWrap">
                      <h1>Expenses and Net Profit</h1>
                      <LineGraph data={expenseAndNetGraphData} height={400} />
                    </div>
                  ) : (
                    <div className="graphWrap noData h-100">
                      <img
                        src={require("../../../assets/img/noData.svg").default}
                        alt="no data"
                      />

                      <p>There is no expense data to display on chart.</p>
                    </div>
                  )}
                </Col>
              </Row>
            )}
          </Col>
          {/* <p className="ml-3">
            Note: *income reflects total amount paid and pending{" "}
          </p> */}
        </Row>
      </div>
    );
  }
}

export default SummariesReportingAgent;
