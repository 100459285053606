import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Nav,
  NavItem,
  NavLink,
  Button,
  Card,
  FormGroup,
  Input,
  Label,
  Spinner,
  CustomInput,
  TabContent,
  TabPane,
} from "reactstrap";
import {
  checkPermission,
  errorHandler,
  formatDateHideYearIfSame,
  formatTime,
  openUrlOnNewTab,
  showToast,
  structureQueryParams,
  uploadFileOnServer,
} from "../../../helper-methods";
import {
  addDocument,
  agentDownloadedDocumentClosing,
  deleteDocumentForClosing,
  getAllDocuments,
  updateClosingDetailsById,
} from "../../../http/http-calls";
import DocumentViewModal from "./documentViewModal";
import cuid from "cuid";
import { BASE_URL } from "../../../config";
import Swal from "sweetalert2";
import CustomPagination from "./CustomPagination";
import moment from "moment";

const SecureDocuments = ({ closingDetails, updateClosingState }) => {
  const userData = useSelector((state) => state?.userData);

  const [data, setData] = useState({
    documents: [],
    scanbacks: [],
    confirmation: [],
  });
  const [loading, setLoading] = useState(false);
  const [isStatusLoading, setIsStatusLoading] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [deleteCommentLoading, setDeleteDocumentLoading] = useState(false);
  const [documentsPageNumber, setDocumentsPageNumber] = useState(0);

  const [documentViewModalData, setDocumentViewModalData] = useState({
    isOpen: false,
    externalUrl: null,
    modalHeading: null,
    fileType: null,
  });
  // documents or scanbacks or confirmation
  const [activeTabName, setActiveTabName] = useState("documents");

  const _toggleActiveTabName = (tabName = "documents") => {
    if (activeTabName !== tabName) {
      setDocumentsPageNumber(0);
      setActiveTabName(tabName);
    }
  };

  const _onToggleDocumentViewModal = (doc = null, isOpen = false) => {
    let newDocumentViewModalData = { ...documentViewModalData };

    if (doc && isOpen) {
      newDocumentViewModalData = {
        isOpen: true,
        externalUrl: doc.url,
        modalHeading: doc.title,
        fileType: doc.docType,
      };

      if (
        userData?.user?.type?.toLowerCase() === "signingcompany" &&
        !closingDetails?.isCompanyViewDocument &&
        userData?.user?.hasOwnProperty("isAssistant") &&
        !userData?.user?.isAssistant
      ) {
        _updateClosingDetailsByIdStatus(closingDetails, {
          isCompanyViewDocument: true,
        });
      }
    } else {
      newDocumentViewModalData = {
        isOpen: false,
        externalUrl: null,
        modalHeading: null,
        fileType: null,
      };
    }

    setDocumentViewModalData(newDocumentViewModalData);
  };

  const _addDocument = ({ documents, category }) => {
    const newData = { ...data };
    documents.forEach((each) => {
      newData[category].unshift({
        _id: cuid(),
        isLoading: true,
        createdAt: new Date(),
        ...each,
      });
    });
    setData(newData);

    addDocument({
      id: closingDetails?._id,
      userType: userData?.user?.type,
      payload: { documents },
    })
      .then((res) => {
        _getAllDocuments();
        showToast("Document has been uploaded", "success");
        setFileUploadLoading(false);
      })
      .catch((error) => {
        _getAllDocuments();
        errorHandler(error);
        setFileUploadLoading(false);
      });
  };

  const _updateFile = async (event) => {
    let objFile, objFileType;

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      if (activeTabName) {
        let uploadFiles = [];

        for (let index = 0; index < event.target.files.length; index++) {
          objFile = event.target.files[index];
          objFileType = objFile.type.split("/")[0];
          uploadFiles.push({
            previewBlob: URL.createObjectURL(objFile),
            uploadData: objFile,
            type: objFileType === "application" ? "pdf" : objFileType,
          });
        }

        if (uploadFiles?.length) {
          const category = activeTabName;
          setFileUploadLoading(true);
          let documents = await uploadFileOnServer(uploadFiles);
          documents = documents.map((each) => ({ ...each, category }));
          _addDocument({ documents, category });
        }
      } else {
        showToast("Upload type is required");
        return;
      }
    }
  };

  // only for agent
  const _downloadClosingDocuments = () => {
    try {
      if (!userData?.token || !closingDetails?._id) return;

      const params = {
        closingid: closingDetails?._id,
        token: userData?.token,
        documentCategory: activeTabName,
      };
      const queryString = structureQueryParams(params);
      // openUrlOnNewTab(`${BASE_URL}/download/closing-documents/${queryString}`);
      openUrlOnNewTab(
        `${BASE_URL}/download/all/closing-documents/${queryString}`
      );
    } catch (error) {
      errorHandler(error);
    }
  };

  const _downloadDocument = async (row) => {
    if (
      !closingDetails?.isCompanyViewDocument &&
      userData?.user?.type?.toLowerCase() === "signingcompany"
    ) {
      _updateClosingDetailsByIdStatus(closingDetails?._id, {
        isCompanyViewDocument: true,
      });
      // openUrlOnNewTab(row.url);
    }
    if (
      userData?.user?.type?.toLowerCase() === "signingcompany" ||
      userData?.user?.type?.toLowerCase() === "client"
    ) {
      openUrlOnNewTab(row.url);
    }

    if (userData?.user?.type?.toLowerCase() === "agent") {
      let res;
      try {
        res = await agentDownloadedDocumentClosing({
          closingId: closingDetails._id,
          documentId: row._id,
        });

        const params = {
          closingId: closingDetails?._id,
          documentId: row._id,
          token: userData?.token,
        };
        const queryString = structureQueryParams(params);
        openUrlOnNewTab(
          `${BASE_URL}/download/one/closing-document/${queryString}`
        );
      } catch (err) {
        errorHandler(err);
      }

      if (!res?.error) {
        _getAllDocuments();
      }
    }

    // if (userData?.user?.type?.toLowerCase() === "agent") {
    //   const res = await agentDownloadedDocumentClosing({
    //     closingId: closingDetails._id,
    //     documentId: row._id,
    //   });
    //   if (!res?.error) {
    //     _getAllDocuments();
    //   }
    // }

    // openUrlOnNewTab(row.url);
  };

  const _deleteDocument = (documentId) => {
    try {
      const newData = { ...data };
      const findIndex = newData[activeTabName].findIndex(
        (each) => each._id === documentId
      );
      newData[activeTabName].splice(findIndex, 1);
      setData(newData);

      deleteDocumentForClosing(closingDetails?._id, { documentId })
        .then((res) => {
          Swal.fire("Deleted!", "Document has been deleted.", "success");
          setDeleteDocumentLoading(false);
          _getAllDocuments();
        })
        .catch((error) => {
          errorHandler(error);
          setDeleteDocumentLoading(false);
          _getAllDocuments();
        });
    } catch (error) {
      errorHandler(error);
      setDeleteDocumentLoading(false);
    }
    setDeleteDocumentLoading(documentId);
  };

  const _deleteDocumentAlert = (doc) => {
    if (!doc?._id) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this document.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _deleteDocument(doc._id);
      }
    });
  };

  // const _rowStyleFormat = (row) => {
  //   if (row?._signingCompany) {
  //     return { backgroundColor: "#eee" };
  //   } else if (row?._agent) {
  //     return { backgroundColor: "#cfe2ff" };
  //   }
  //   return {};
  // };

  const _rowTypeFormat = (row) => {
    if (row?._signingCompany) {
      return <span>by company</span>;
    } else if (row?._agent) {
      return <span>by agent</span>;
    } else if (row?._client) {
      return <span>by client</span>;
    }
    return null;
  };

  const _updateClosingDetailsByIdStatus = async (id, payload) => {
    try {
      setIsStatusLoading(true);

      await updateClosingDetailsById({ id, payload });

      showToast("Status has been updated", "success");

      const newClosingDetails = { ...closingDetails };

      if (payload.hasOwnProperty("isDocumentVerified")) {
        newClosingDetails["isDocumentVerified"] = payload.isDocumentVerified;
      }
      if (payload.hasOwnProperty("isDocumentCompleted")) {
        newClosingDetails["isDocumentCompleted"] = payload.isDocumentCompleted;
      }
      if (payload.hasOwnProperty("isDocumentCompletedByClient")) {
        newClosingDetails["isDocumentCompletedByClient"] =
          payload?.isDocumentCompletedByClient;
      }
      if (payload.hasOwnProperty("isCompanyViewDocument")) {
        newClosingDetails["isCompanyViewDocument"] =
          payload.isCompanyViewDocument;
      }

      updateClosingState(newClosingDetails);

      setIsStatusLoading(false);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _toggleIsDocumentCompleted = async (
    id,
    isDocumentCompletedByAgent = false
  ) => {
    try {
      setIsStatusLoading(true);

      await updateClosingDetailsById({
        id,
        payload: { isDocumentCompletedByAgent },
      });

      showToast("Status has been updated", "success");

      const newClosingDetails = { ...closingDetails };

      newClosingDetails["isDocumentCompletedByAgent"] =
        isDocumentCompletedByAgent;

      updateClosingState(newClosingDetails);

      setIsStatusLoading(false);
    } catch (error) {
      errorHandler(error);
      setIsStatusLoading(false);
    }
  };

  const _getAllDocuments = () => {
    setLoading(true);

    getAllDocuments(closingDetails?._id)
      .then((res) => {
        setData({
          documents: res?.documents?.length ? res.documents : [],
          scanbacks: res?.scanbacks?.length ? res.scanbacks : [],
          confirmation: res?.confirmation?.length ? res.confirmation : [],
        });
        setLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        setLoading(false);
      });
  };

  const _renderList = (data) => {
    return (
      <>
        {data?.[activeTabName]
          .slice(documentsPageNumber * 5, documentsPageNumber * 5 + 5)
          .map((each, index) => (
            <>
              <li
                key={each?._id}
                // style={_rowStyleFormat(each, index)}
              >
                <div className="documentItem">
                  <div className="userInfo">
                    {/* {console.log('whichDocfile' + each.docType)} */}
                    <div
                      className={`userImage ${
                        each.docType === "pdf" ? "pdfFile" : "docFile"
                      }`}
                    >
                      {each?.docType === "pdf" ? (
                        <img
                          src={
                            require("../../../assets/img/pdf-white.svg").default
                          }
                          alt="pdf file"
                        />
                      ) : (
                        <img
                          src={
                            require("../../../assets/img/nodes-white.svg")
                              .default
                          }
                          alt="doc file"
                        />
                      )}
                    </div>
                    <div className="userContent">
                      <span>Title</span>
                      <h6>{each?.title}</h6>
                    </div>
                  </div>
                </div>
                <div className="documentItem">
                  <span>Added on</span>
                  <h6>
                    {formatDateHideYearIfSame(each?.createdAt)},{" "}
                    {formatTime(each?.createdAt)}
                    <span>
                      {","} {_rowTypeFormat(each, index)}
                    </span>
                  </h6>
                </div>
                <div className="actionItem">
                  <>
                    {/* <Button
                                color="link"
                                className="actionBtn"
                                disabled={each.isLoading}
                                onClick={() =>
                                  _onToggleDocumentViewModal(each, true)
                                }
                              >
                                <i className="fa fa-eye" />
                              </Button> */}
                    {!each?.isPrivate
                      ? (userData.type === "signingcompany" ||
                          each._agent === userData.user.id ||
                          each._client === userData.user.id) && (
                          <Button
                            color="link"
                            disabled={
                              each?.isLoading ||
                              deleteCommentLoading === each._id
                                ? true
                                : false
                            }
                            onClick={() => _deleteDocumentAlert(each)}
                          >
                            <img
                              src={
                                require("../../../assets/img/deleteIcon.svg")
                                  .default
                              }
                              alt=""
                            />
                          </Button>
                        )
                      : null}
                    {each?.isDownloadedByAgent ? (
                      <Button
                        color="link"
                        disabled={each?.isLoading}
                        onClick={() => _downloadDocument(each)}
                      >
                        <img
                          src={
                            require("../../../assets/img/download_green.png")
                              .default
                          }
                          alt="download"
                        />
                      </Button>
                    ) : (
                      <Button
                        color="link"
                        disabled={each?.isLoading}
                        onClick={() => _downloadDocument(each)}
                      >
                        <img
                          src={
                            require("../../../assets/img/download_blue.png")
                              .default
                          }
                          alt="download"
                        />
                      </Button>
                    )}
                  </>
                </div>
              </li>
            </>
          ))}
      </>
    );
  };

  useEffect(() => {
    if (closingDetails?._id) {
      _getAllDocuments();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closingDetails?._id]);

  const _isShowUploadBtnForAgentScanbacks = () => {
    // let prevDate = new Date(new Date().setDate(new Date().getDate() - 2));    // dummy date, giving date 2 days prior to current date
    let ans = moment
      .tz(
        moment(closingDetails?.closingClosedDateAndTime)
          // moment(
          //   prevDate
          // )
          .add(72, "hours"),
        closingDetails?.closingAddress?.timeZone
      )
      .isAfter(moment.tz(moment(), closingDetails?.closingAddress?.timeZone));

    // console.log(
    //   "ans: ",
    //   moment(closingDetails?.closingClosedDateAndTime),
    //   moment(),
    //   ans
    // );
    return ans;
  };

  useEffect(() => {
    if (!closingDetails) return;

    _isShowUploadBtnForAgentScanbacks();
  }, [closingDetails]);

  // console.log(
  //   "userData?.user?.type?.toLowerCase() >>",
  //   userData?.user?.type?.toLowerCase()
  // );

  return (
    <>
      <Card className="h-100">
        <Nav tabs className="greyBg">
          <NavItem>
            <NavLink
              className={activeTabName === "documents" ? "active" : ""}
              onClick={() => {
                _toggleActiveTabName("documents");
              }}
            >
              Signing Documents
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTabName === "scanbacks" ? "active" : ""}
              onClick={() => {
                _toggleActiveTabName("scanbacks");
              }}
            >
              Scanbacks
            </NavLink>
          </NavItem>
          {userData?.user?.type?.toLowerCase() !== "agent" ||
          closingDetails.isCreatedForAgent ? (
            <NavItem>
              <NavLink
                className={activeTabName === "confirmation" ? "active" : ""}
                onClick={() => {
                  _toggleActiveTabName("confirmation");
                }}
              >
                Order Confirmation
              </NavLink>
            </NavItem>
          ) : null}
        </Nav>
        <TabContent>
          <TabPane>
            <ul className="documentList">
              {data?.[activeTabName]?.length ? (
                userData?.user?.type?.toLowerCase() !== "agent" ||
                activeTabName !== "documents" ||
                closingDetails?.isCreatedForAgent ? (
                  _renderList(data)
                ) : closingDetails?.isDocumentCompleted ? (
                  _renderList(data)
                ) : (
                  <li className="documentNoVisible">
                    Documents are being revised/added
                  </li>
                )
              ) : (
                <li>
                  <div className="nodata">
                    <img
                      src={require("../../../assets/img/noData.svg").default}
                      alt="no data"
                    />
                    <p>There is no data to display</p>
                  </div>
                </li>
              )}
            </ul>

            <div className="d-flex align-items-center justify-content-center flex-wrap mt-3">
              {
                /* userData?.user?.type?.toLowerCase() === "agent" && */
                ((activeTabName === "documents" && data?.documents?.length) ||
                  (activeTabName === "scanbacks" && data?.scanbacks?.length) ||
                  (activeTabName === "confirmation" &&
                    data?.confirmation?.length)) &&
                (userData?.user?.type?.toLowerCase() === "signingcompany" ||
                  userData?.user?.type?.toLowerCase() === "client" ||
                  (userData?.user?.type?.toLowerCase() === "agent" &&
                    /* activeTabName !== "scanbacks" && */
                    /* agent side -> if activeTabName -> "documents", then check for condition otherwise show btn */
                    ((activeTabName === "documents" &&
                      /* (closingDetails?.isCreatedForAgent || */
                      closingDetails?.isDocumentCompleted) ||
                      /* ) */
                      activeTabName !== "documents"))) ? (
                  <Button
                    color="link"
                    outline
                    className="mr-3"
                    onClick={() => _downloadClosingDocuments()}
                  >
                    Download All
                    <img
                      src={
                        require("../../../assets/img/download_blue.png").default
                      }
                      alt="download all"
                    />
                  </Button>
                ) : null
              }

              {userData?.user?.type?.toLowerCase() === "signingcompany" &&
              activeTabName === "scanbacks" ? (
                <>
                  {data?.scanbacks?.length ? (
                    <>
                      <CustomInput
                        type="checkbox"
                        disabled={isStatusLoading}
                        checked={
                          closingDetails?.isDocumentVerified ? true : false
                        }
                        onChange={(event) =>
                          _updateClosingDetailsByIdStatus(closingDetails?._id, {
                            isDocumentVerified: event.target.checked,
                          })
                        }
                        id={"approvedScan"}
                        label=" Approved Scan"
                        className="mr-3"
                      />
                    </>
                  ) : null}
                </>
              ) : null}

              {userData?.user?.type?.toLowerCase() === "signingcompany" ||
              userData?.user?.type?.toLowerCase() === "client" ? (
                <>
                  {activeTabName === "documents" && data?.documents?.length ? (
                    <>
                      <CustomInput
                        className="mr-2"
                        type="checkbox"
                        disabled={isStatusLoading}
                        checked={
                          closingDetails?.[
                            userData?.user?.type?.toLowerCase() === "client"
                              ? "isDocumentCompletedByClient"
                              : "isDocumentCompleted"
                          ]
                            ? true
                            : false
                        }
                        onChange={(event) =>
                          _updateClosingDetailsByIdStatus(closingDetails?._id, {
                            [userData?.user?.type?.toLowerCase() === "client"
                              ? "isDocumentCompletedByClient"
                              : "isDocumentCompleted"]: event.target.checked,
                          })
                        }
                        id="uploadComplete"
                        label="Upload Complete"
                      />
                      {/* <FormGroup className="mb-0">
                      <Label className="mb-0" style={{ fontWeight: 700 }}>
                        <Input
                          type="checkbox"
                          disabled={isStatusLoading}
                          checked={
                            closingDetails?.isDocumentCompleted ? true : false
                          }
                          onChange={(event) =>
                            _updateClosingDetailsByIdStatus(
                              closingDetails?._id,
                              {
                                isDocumentCompleted: event.target.checked,
                              }
                            )
                          }
                        />
                        Upload Completed
                      </Label>
                    </FormGroup> */}
                    </>
                  ) : null}
                </>
              ) : null}

              {(userData?.user?.type?.toLowerCase() === "client"
                ? activeTabName === "scanbacks"
                  ? false
                  : checkPermission(
                      "dashboard",
                      "canReviewDocumentsOrOrderDetail"
                    )
                : checkPermission("dashboard", "canEditOrderEntry")) &&
              ((userData?.user?.type?.toLowerCase() === "agent" &&
                activeTabName === "scanbacks" &&
                _isShowUploadBtnForAgentScanbacks()) ||
                (userData?.user?.type?.toLowerCase() === "agent" &&
                activeTabName === "documents" &&
                closingDetails?.isCreatedForAgent === true
                  ? true
                  : false) ||
                (userData?.user?.type?.toLowerCase() === "agent" &&
                activeTabName === "confirmation" &&
                closingDetails?.isCreatedForAgent === true
                  ? true
                  : false) ||
                userData?.user?.type?.toLowerCase() !== "agent") ? (
                <>
                  <Label className="uploadBtn fs-12">
                    {fileUploadLoading ? (
                      <i className="fa fa-spinner fa-spin" />
                    ) : (
                      <img
                        src={
                          require("../../../assets/img/uploadIcon.svg").default
                        }
                        alt="upload icon"
                      />
                    )}{" "}
                    Upload
                    <Input
                      type="file"
                      accept=".pdf,.doc,.docx,image,video/mp4,video/x-m4v,video/*,image/jpeg,image/*"
                      disabled={fileUploadLoading}
                      title="Add Documents"
                      multiple
                      value=""
                      onChange={(event) => _updateFile(event)}
                    />
                  </Label>
                </>
              ) : null}

              {userData?.user?.type?.toLowerCase() === "agent" &&
              data?.scanbacks?.length &&
              data?.scanbacks?.filter((item) => item?._agent)?.length &&
              activeTabName === "scanbacks" ? (
                <CustomInput
                  type="checkbox"
                  className="ml-2"
                  id="agent_closing_detail_is_document_completed"
                  label="Scanback upload complete"
                  disabled={isStatusLoading}
                  checked={
                    closingDetails.isDocumentCompletedByAgent ? true : false
                  }
                  onChange={(event) =>
                    _toggleIsDocumentCompleted(
                      closingDetails?._id,
                      event.target.checked
                    )
                  }
                />
              ) : null}
            </div>

            {/* 
              1. if user is agent 
              2. scanbacks doc is length is true
              3. tab should be scanbacks
              4. show text if user is not checked Scanback upload complete checkbox
             */}
            {userData?.user?.type?.toLowerCase() === "agent" &&
            data?.scanbacks?.length &&
            !closingDetails.isDocumentCompletedByAgent &&
            data?.scanbacks?.filter((item) => item?._agent)?.length &&
            activeTabName === "scanbacks" ? (
              <p className="text-danger text-center fs-12 mt-2">
                *Please mark this as complete to notify the company
              </p>
            ) : null}

            <CustomPagination
              data={data?.[activeTabName]?.slice(
                documentsPageNumber * 5,
                documentsPageNumber * 5 + 5
              )}
              isPageStartFromOne={true}
              dataCount={data?.[activeTabName]?.length}
              pageNumber={documentsPageNumber + 1}
              pageSize={5}
              onPageChange={(pageNumber = 0) =>
                setDocumentsPageNumber(pageNumber - 1)
              }
              isHideForLessData={true}
            />
          </TabPane>
        </TabContent>
      </Card>
      <DocumentViewModal
        isOpen={documentViewModalData.isOpen}
        externalUrl={documentViewModalData.externalUrl}
        fileType={documentViewModalData.fileType}
        modalHeading={documentViewModalData.modalHeading}
        toggle={() => _onToggleDocumentViewModal()}
      />
    </>
  );
};

export default SecureDocuments;
