import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import {
  companyGetCustomOrderForm,
  createCustomOrderForm,
} from "../../../http/http-calls";
import { errorHandler, showToast } from "../../../helper-methods";

const initialForm = [
  { query: "", queryOrder: "" },
  { query: "", queryOrder: "" },
  { query: "", queryOrder: "" },
  { query: "", queryOrder: "" },
  { query: "", queryOrder: "" },
  { query: "", queryOrder: "" },
];
const CustomOrderQuestion = ({ client, refresh }) => {
  const [questions, setQuestions] = useState(initialForm);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    client?.questions?.length > 0
      ? client?.questions?.forEach((item2) => {
          const index = item2.queryOrder - 1;
          if (index >= 0 && index < questions.length) {
            setQuestions((prevArray1) => [
              ...prevArray1.slice(0, index),
              { query: item2.query, queryOrder: item2.queryOrder },
              ...prevArray1.slice(index + 1),
            ]);
          }
        })
      : setQuestions(initialForm);
  }, [client]);

  const handleInputChange = (field, value, index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    updatedQuestions[index]["queryOrder"] = index + 1;
    setQuestions(updatedQuestions);
  };

  const _onSubmit = async () => {
    let payload = {
      questions: questions.filter((obj) => obj.query !== ""),
    };
    let clientId = client?.id;
    setLoading(true);
    try {
      setLoading(true);
      await createCustomOrderForm(clientId, payload);
      setLoading(false);
      refresh();
      showToast("Questions are Set Successfully!", "success");
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  return (
    <>
      <Card>
        <CardBody>
          <h1 style={{ fontSize: 16 }}>Add Additional Order Questions</h1>
          <Row>
            {questions.length > 0 &&
              questions?.map((question, index) => (
                <Col md={6}>
                  <Label>Q{index + 1}</Label>
                  <FormGroup className="floatingLabel">
                    <Input
                      placeholder=" "
                      value={question.query}
                      onChange={(e) =>
                        handleInputChange("query", e.target.value, index)
                      }
                    />
                    <Label>Enter</Label>
                  </FormGroup>
                </Col>
              ))}
            <Col md={12} className="text-center mt-4 mb-4">
              <Button
                color="primary"
                onClick={() => _onSubmit()}
                disabled={loading ? true : false}
              >
                Save
              </Button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </>
  );
};

export default CustomOrderQuestion;
