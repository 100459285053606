import React, { useCallback, useEffect, useState } from "react";
import Cropper from "react-easy-crop";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

const ImageCropModal = ({
  isOpen,
  imgData,
  fieldName,
  fileName,
  fileType,
  toggle,
  onSuccess,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState(null);
  const [aspectRatio, setAspectRatio] = useState(4 / 3);

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const _closeModal = () => {
    setCrop({ x: 0, y: 0 });
    setZoom(1);
    setCroppedArea(null);
    setAspectRatio(4 / 3);
    toggle();
  };

  const getCroppedImg = (sourceImg, crop, fileName) => {
    try {
      let sourceImage = new Image();
      sourceImage.src = sourceImg;

      const canvas = document.createElement("canvas");
      const scaleX = sourceImage.naturalWidth / sourceImage.width;
      const scaleY = sourceImage.naturalHeight / sourceImage.height;
      canvas.width = crop.width;
      canvas.height = crop.height;

      const ctx = canvas.getContext("2d");
      ctx.drawImage(
        sourceImage,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
      );

      return new Promise((resolve) => {
        canvas.toBlob((file) => {
          // resolve(URL.createObjectURL(file));
          resolve(file);
        }, "image/jpeg");
      });
    } catch (error) {
      console.log(error);
      return null;
    }
  };

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(imgData, croppedArea, 0);
      return croppedImage;
    } catch (error) {
      console.error(error);
    }
  }, [croppedArea, imgData]);

  const _handleAspectRatioChange = (event) => {
    setAspectRatio(+event.target.value); // converting to number before setting it
  };

  const _onCropDone = async () => {
    const newImgData = await showCroppedImage();

    const imgFile = new File([newImgData], fileName, {
      type: "image/png",
    });

    onSuccess(imgFile, fieldName, fileType);

    _closeModal();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      size="lg"
      centered
      scrollable
      className="checkOutModal"
    >
      <ModalHeader toggle={() => _closeModal()}>Crop Image</ModalHeader>
      <ModalBody>
        <div
          className="text-center pt-4"
          style={{
            width: "400px",
            minHeight: "350px",
          }}
        >
          {imgData ? (
            <div>
              <Cropper
                image={imgData}
                crop={crop}
                zoom={zoom}
                aspect={aspectRatio}
                onCropChange={setCrop}
                onCropComplete={onCropComplete}
                onZoomChange={setZoom}
                style={{
                  containerStyle: {
                    width: "100%",
                    height: "80%",
                    backgroundColor: "#fff",
                  },
                }}
              />
            </div>
          ) : null}
        </div>

        <Form className="w-50 d-flex justify-content-center align-items-center mx-auto">
          <Label className="my-0 mr-2">Zoom: </Label>
          <Input
            className="w-50 inputRangeCustom"
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(+e.target.value); // converting to number before setting it
            }}
          />
        </Form>

        <Form className="d-flex justify-content-center align-items-center gap-2">
          <Label>Aspect Ratio: </Label>
          <FormGroup check>
            <Label for="aspectRatio1" check>
              <Input
                id="aspectRatio1"
                type="radio"
                name="aspectRatio"
                value={1 / 1}
                onChange={(e) => _handleAspectRatioChange(e)}
                checked={aspectRatio === 1 / 1}
              />{" "}
              1:1
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label for="aspectRatio2" check>
              <Input
                id="aspectRatio2"
                type="radio"
                name="aspectRatio"
                value={4 / 3}
                onChange={(e) => _handleAspectRatioChange(e)}
                checked={aspectRatio === 4 / 3}
              />{" "}
              4:3
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label for="aspectRatio3" check>
              <Input
                id="aspectRatio3"
                type="radio"
                name="aspectRatio"
                value={16 / 9}
                onChange={(e) => _handleAspectRatioChange(e)}
                checked={aspectRatio === 16 / 9}
              />{" "}
              16:9
            </Label>
          </FormGroup>

          <FormGroup check>
            <Label for="aspectRatio4" check>
              <Input
                id="aspectRatio4"
                type="radio"
                name="aspectRatio"
                value={5 / 4}
                onChange={(e) => _handleAspectRatioChange(e)}
                checked={aspectRatio === 5 / 4}
              />{" "}
              5:4
            </Label>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={_closeModal}>
          Cancel
        </Button>
        <Button color="primary" onClick={() => _onCropDone(croppedArea)}>
          Crop & Apply
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ImageCropModal;
