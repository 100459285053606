import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  CustomInput,
} from "reactstrap";
import Payment from "../../payment";
import {
  errorHandler,
  formatCurrencyValue,
  showToast,
} from "../../../../../helper-methods";
import {
  agentPurchaseDomainForAgentWebsite,
  saveDomainDetailForAgentWebsite,
} from "../../../../../http/http-calls";

const BuyDomainCheckout = ({
  isOpen,
  data,
  toggle,
  toggleWebsiteVerifyModal,
  onSuccess,
}) => {
  const _closeModal = () => {
    toggle();
  };

  const [loading, setLoading] = useState(false);

  const _onBuySubscription = async (payload) => {
    try {
      setLoading(true);

      const payload = {
        domainName: data?.domainName,
        amount: data?.amount,
      };
      await agentPurchaseDomainForAgentWebsite(payload);
      // console.log({ res });

      toggleWebsiteVerifyModal(true);
      // // to save agent details after purchasing, after this(20-30 mins) only isWebsiteLaunched field will get to true
      // payload["isAutoGeneratedAwsRoute53"] = true;
      // const res2 = await saveDomainDetailForAgentWebsite(payload);
      // console.log({ res2 });

      showToast(
        "Congratulation!! Domain purchase was successful. It might take 20-30 min to complete setup process. Plz check your email for further update.",
        "success"
      );
      setLoading(false);
      onSuccess();
      _closeModal();
    } catch (err) {
      errorHandler(err);
      console.log({ err });
      setLoading(false);
    }
  };

  console.log({ isOpen }, { data });
  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => _closeModal()}
        size="lg"
        centered
        scrollable
        className="checkOutModal"
      >
        <ModalHeader toggle={() => _closeModal()}>Checkout</ModalHeader>
        <ModalBody>
          <div className="checkOutWrapper">
            <div className="chcekoutInfo">
              <ul>
                <li>
                  <span>Domain Name</span>
                  <div>{data?.domainName}</div>
                </li>
                <li>
                  <span>Price</span>
                  <div>{formatCurrencyValue(data?.amount)}</div>
                </li>

                <li>
                  <span>Total</span>
                  <div>{formatCurrencyValue(data?.amount)}</div>
                </li>
              </ul>
            </div>
            <div className="payementCardList">
              <Payment
                cancelButton={true}
                loading={loading}
                manageLoading={(value) => setLoading(value)}
                onCancelButton={_closeModal}
                submitButtonText={"Buy Domain" || ""}
                onPaymentConfirmation={() => _onBuySubscription()}
              />

              {/* <div className="paymentTitle">
                <span>Select Payment Method</span>
                <Button color="link">+ Add New Card</Button>
              </div>

              <div className="cardList">
                <Label for="card1">
                  <CustomInput type="radio" id="card1" name="customRadio" />
                  Card ending with 4242 (4/24)
                </Label>
                <Button color="link">
                  <img
                    src={
                      require("../../../../../assets/img/deleteIcon.svg")
                        .default
                    }
                    alt="final"
                  />
                </Button>
              </div>
              <div className="cardList">
                <Label for="card1">
                  <CustomInput type="radio" id="card1" name="customRadio" />
                  Card ending with 4242 (4/24)
                </Label>
                <Button color="link">
                  <img
                    src={
                      require("../../../../../assets/img/deleteIcon.svg")
                        .default
                    }
                    alt="final"
                  />
                </Button>
              </div> */}
            </div>
          </div>
        </ModalBody>
        {/* <ModalFooter>
           <Button color="primary" outline onClick={_closeModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={_closeModal}>
            Buy
          </Button>{" "} 
        </ModalFooter> */}

        {loading && (
          <div className="table-overlay">
            <div>
              <i className="fa fa-spinner fa-spin" />
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default BuyDomainCheckout;
