import React from "react";
import { connect } from "react-redux";
import {
  Row,
  Col,
  Button,
  CustomInput,
  Input,
  Label,
  Card,
  CardBody,
} from "reactstrap";
import {
  deepClone,
  errorHandler,
  formatCurrencyValue,
  formatDate,
  formatOnlyDateMoment,
  fourteenDaysTrialPeriod,
  showToast,
} from "../../../helper-methods";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import BuySubscriptionAgentModal from "../components/buy-subscription-agent-modal";
import {
  agentGetSubscription,
  getSettingDetail,
  agentBuySubscription,
  agentDowngradeSubscription,
  agentRenewSubscription,
  agentUpgradeSubscription,
  addNotaryPromo,
  agentSubscriptionProDowngrade,
  agentSubscriptionUpgradeDetail,
  agentSubscriptionDowngradeDetail,
  agentRequestForBetaSubscription,
} from "../../../http/http-calls";
import { updateUserObj } from "../../../redux/actions/user-data";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import ShowVideoModal from "../components/ShowVideoModal";

class SubscriptionAgent extends React.Component {
  state = {
    subscription: null,
    agentSubscriptionFreePlan: null,
    agentSubscriptionPremiumPlan: null,
    agentSubscriptionPromoPlan: null,
    agentSubscriptionPremiumYearlyPlan: null,
    agentSubscriptionProPlusPlan: null,
    agentSubscriptionProPlusYearlyPlan: null,
    agentSubscriptionPlan: [],
    buySubscriptionAgentModal: {
      isOpen: false,
      data: {
        billingCycle: null,
        plan: null,
        amount: null,
        type: null, // Purchase, Renew, Upgrade, Downgrade
      },
    },
    planAmount: null,
    planBillingCycle: null,
    selectedAmount: null,
    selectedPlanBillingCycle: null,
    freeSubscriptionLoading: false,
    promoSubscriptionLoading: false,
    selectedPackage: "Monthly",
    isOpen: false,
    role: "",
  };

  componentDidMount = async () => {
    try {
      this.props.showLoader("Fetching Subscription Data...");
      await this._getSettingDetail();
      await this._agentGetSubscription();
      this.props.hideLoader();
    } catch (error) {
      this.props.hideLoader();
      errorHandler(error);
    }
    HeaderEventEmitter.subscribe("billing", () => {
      // console.log("billing");
      this._goToBillingPage();
    });
    const { userData } = this.props;

    if (!userData.user?.isWelcomeVideo) {
      this.setState({
        isOpen: true,
        role: userData.type,
      });
    }
  };

  _getSettingDetail = async () => {
    try {
      const res = await getSettingDetail();

      this.setState({
        agentSubscriptionFreePlan: res?.setting?.agentSubscriptionPlan?.find(
          (sub) => sub.plan === "Free"
        ),
        agentSubscriptionPremiumPlan: res?.setting?.agentSubscriptionPlan?.find(
          (sub) => sub.package === "Monthly" && sub.plan === "Premium"
        ),
        agentSubscriptionPremiumYearlyPlan:
          res?.setting?.agentSubscriptionPlan?.find(
            (sub) => sub.package === "Yearly" && sub.plan === "Premium"
          ),
        agentSubscriptionProPlusPlan: res?.setting?.agentSubscriptionPlan?.find(
          (sub) => sub.package === "Monthly" && sub.plan === "Pro-Plus"
        ),
        agentSubscriptionProPlusYearlyPlan:
          res?.setting?.agentSubscriptionPlan?.find(
            (sub) => sub.package === "Yearly" && sub.plan === "Pro-Plus"
          ),
        agentSubscriptionPlan: res?.setting?.agentSubscriptionPlan,
      });

      this.props.hideLoader();
      return res;
    } catch (error) {
      this.props.hideLoader();
      throw error;
    }
  };

  _agentGetSubscription = async () => {
    try {
      const res = await agentGetSubscription();

      if (res?.subscription) {
        this.setState({
          subscription: res?.subscription,
          planAmount: res?.subscription?.amount,
          planBillingCycle:
            res?.subscription?.upgradePlanTo === "Yearly"
              ? 365
              : res?.subscription?.billingCycle,
          selectedAmount: null,
          selectedPlanBillingCycle: null,
          selectedPackage: res?.subscription?.package,
        });
        this.props.updateUserObj({ _subscription: res?.subscription });
        this.props.hideLoader();
      } else {
        this.props.hideLoader();
      }

      return res;
    } catch (error) {
      this.props.hideLoader();
      throw error;
    }
  };

  _onToggleBuySubscriptionAgentModal = async (
    isOpen = false,
    plan = null,
    billingCycle = null,
    amount = null,
    type = null
  ) => {
    const { agentSubscriptionPlan, subscription, selectedPackage } = deepClone(
      this.state
    );
    let detail;

    if (type === "Upgrade") {
      detail = await agentSubscriptionUpgradeDetail({
        billingCycle: selectedPackage !== "Yearly" ? 30 : 365,
        plan,
      });
    }
    if (type === "Downgrade") {
      detail = await agentSubscriptionDowngradeDetail({
        billingCycle: selectedPackage !== "Yearly" ? 30 : 365,
        plan,
      });
      console.log("dfdf", detail);
    }
    let findPlan = agentSubscriptionPlan.find(
      (each) => each.package === selectedPackage && each.plan === plan
    );
    // console.log("pl",findPlan)

    this.setState({
      buySubscriptionAgentModal: {
        isOpen,
        data: {
          billingCycle: findPlan
            ? findPlan?.package === "Monthly"
              ? 30
              : 365
            : null,
          plan,
          amount: findPlan ? findPlan?.amount : null,
          type,
          detail: detail?.payload,
        },
      },
    });
  };

  _goToBillingPage = () => {
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/billing`);
  };

  _purchaseSubscription = (type, payload) => {
    const { subscription } = deepClone(this.state);

    return new Promise(async (resolve, reject) => {
      try {
        let successText = "Subscription Purchased";
        switch (type) {
          case "Purchase": {
            await agentBuySubscription(payload);
            break;
          }
          case "Renew": {
            await agentRenewSubscription(payload);
            successText = "Subscription Renewed";
            break;
          }
          case "Upgrade": {
            // if (subscription.plan === "Free") {
            await agentUpgradeSubscription(payload);
            // } else {
            //   console.log("pay",payload)
            //   // payload.plan
            //     // payload.billingCycle === 365 ? "Yearly" : "Premium";
            //   // delete payload.billingCycle;
            //   await agentSubscriptionProDowngrade(payload);
            // }
            successText = "Subscription Upgraded";
            break;
          }
          case "Downgrade": {
            if (payload.plan === "Free") {
              await agentDowngradeSubscription(payload);
            } else {
              // delete payload.billingCycle;
              await agentSubscriptionProDowngrade(payload);
            }
            successText = "Subscription Downgraded";
            break;
          }

          case "Notary Promo": {
            await this._addNotaryPromo();
            successText = "Notary Promo Added Successfully";
            break;
          }

          default:
            reject();
        }
        await this._agentGetSubscription();
        resolve({ successText });
      } catch (error) {
        reject(error);
      }
    });
  };

  _selectFreeSubscription = async (plan, billingCycle, type) => {
    try {
      const payload = {
        billingCycle,
        plan,
      };

      this.setState({ freeSubscriptionLoading: true });

      const { successText } = await this._purchaseSubscription(type, payload);

      if (type === "Downgrade") {
        showToast(successText || "Free Subscription Activated", "success");
      } else {
        showToast("Free Subscription Activated", "success");
      }

      this.setState({ freeSubscriptionLoading: false });
    } catch (error) {
      errorHandler(error);
      this.setState({ freeSubscriptionLoading: false });
    }
  };

  _addNotaryPromo = async () => {
    try {
      this.setState({ promoSubscriptionLoading: true });

      const response = await addNotaryPromo();

      if (response) {
        this._agentGetSubscription();
      }
      this.setState({ promoSubscriptionLoading: false });
    } catch (error) {
      errorHandler(error);
      this.setState({ promoSubscriptionLoading: false });
    }
  };

  _handleSubscriptionPlanAmount = (id) => {
    const { buySubscriptionAgentModal, agentSubscriptionPlan } = deepClone(
      this.state
    );
    let planObj = agentSubscriptionPlan.find((item) => item._id === id);

    buySubscriptionAgentModal.data["amount"] = planObj.amount;
    buySubscriptionAgentModal.data["plan"] =
      planObj.plan === "Yearly" ? 365 : 30;
    this.setState({
      buySubscriptionAgentModal,
    });
  };

  _handleChangeAmount = (planType, isYearPlan = false) => {
    const { agentSubscriptionPlan, subscription } = deepClone(this.state);
    console.log("ioity", planType, isYearPlan);

    let findPlan;

    if (isYearPlan) {
      // year plan
      findPlan = agentSubscriptionPlan.find((each) =>
        each.package === isYearPlan
          ? "Yearly"
          : "Monthly" && each.plan === planType
      );
    } else {
      // month plan
      findPlan = agentSubscriptionPlan.find(
        (each) =>
          each.package === isYearPlan
            ? "Yearly"
            : "Monthly" && each.plan === planType
        // each.package === "Premium" && each.plan === planType
      );
    }
    console.log("ioi", findPlan.amount !== 10);
    if (!findPlan) return;

    this.setState({
      selectedAmount:
        subscription.amount !== findPlan.amount ? findPlan.amount : null,
      selectedPlanBillingCycle:
        subscription.amount !== findPlan.amount
          ? findPlan.amount !== 10
            ? 365
            : 30
          : null,
      planAmount: findPlan.amount,
      planBillingCycle: findPlan.amount !== 10 ? 365 : 30,
    });
    // this.setState({
    //   planPackage: findPlan.package,
    //   planBillingCycle:
    //     findPlan.amount !== 10 ? (findPlan.amount !== 47 ? 365 : 30) : 30,
    // selectedAmount: findPlan.amount
    // subscription.amount !== findPlan.amount ? findPlan.amount : null,
    // selectedPlanBillingCycle:
    //   subscription.amount !== findPlan.amount
    //     ? findPlan.amount !== 10
    //       ? 365
    //       : 30
    //     : null,
    // planAmount: findPlan.amount,
    // planBillingCycle: findPlan.amount !== 10 ? 365 : 30,
    // });
  };

  _14DaysTrialPeriod = () => {
    const { subscription } = deepClone(this.state);
    fourteenDaysTrialPeriod(subscription?.start);
  };

  _handleShowOfPremiumButton = (btnType) => {
    const { subscription, selectedPlanBillingCycle, selectedPackage } =
      deepClone(this.state);
    if (
      subscription
      // subscription?.isAgentFreeTrialPremiumSubscriptionOver ||
      // subscription?.plan === "Free"
    ) {
      switch (btnType) {
        case "upgrade": {
          // return (
          //   subscription.billingCycle !== 365 &&
          //   (subscription?.plan === "Free" ||
          //     selectedPlanBillingCycle !== null) &&
          //   !subscription.isUpgradedForPremiumPlan
          // );
          // break;
          return (
            subscription.plan === "Free" ||
            (subscription.plan === "Premium" &&
              subscription.package !== "Yearly" &&
              subscription.package !== selectedPackage)
            // &&
            // subscription.package !== selectedPackage
          );
        }

        case "downgrade": {
          return (
            // subscription.package !== "Monthly" &&
            // selectedPackage !== "Yearly"
            // &&
            (subscription.plan === "Premium" &&
              subscription.package !== "Monthly" &&
              subscription.package !== selectedPackage) ||
            subscription.plan === "Pro-Plus"
          );
          // return subscription.plan === "Pro-Plus";
          // break;
        }

        default:
          return true;
      }
    } else {
      return false;
    }
  };

  _handleShowOfProplusButton = (btnType) => {
    const { subscription, selectedPlanBillingCycle, selectedPackage } =
      deepClone(this.state);
    if (
      subscription
      // subscription?.isAgentFreeTrialPremiumSubscriptionOver ||
      // subscription?.plan === "Free"||subscription?.plan==="Premium"
    ) {
      switch (btnType) {
        case "upgrade": {
          // return (
          //   subscription.billingCycle !== 365 &&
          //   (subscription?.plan === "Free" ||
          //     selectedPlanBillingCycle !== null) &&
          //   !subscription.isUpgradedForPremiumPlan
          // );
          // break;
          // console.log(
          //   "qwe",
          //   subscription.plan === "Free",
          //   subscription.plan === "Premium",
          //   subscription.plan === "Pro-Plus" && selectedPackage !== "Monthly"
          // );
          return (
            // subscription.package!==selectedPackage&&
            subscription.plan === "Free" ||
            subscription.plan === "Premium" ||
            (subscription.plan === "Pro-Plus" &&
              subscription.package !== "Yearly" &&
              selectedPackage !== "Monthly")
          );
        }

        case "downgrade": {
          // return (
          //   subscription?.plan === "Premium" &&
          //   subscription.billingCycle !== 30 &&
          //   selectedPlanBillingCycle !== null &&
          //   !subscription.isUpgradedForPremiumPlan
          // );
          // break;
          return (
            subscription.package !== "Monthly" &&
            selectedPackage !== "Yearly" &&
            subscription.plan !== "Premium"
          );
        }

        default:
          return true;
      }
    } else {
      return false;
    }
  };

  _handleToggle = (isYearPlan = false) => {
    this.setState({
      selectedPackage: isYearPlan ? "Yearly" : "Monthly",
    });
  };

  _requestForBetaSubscription = async () => {
    try {
      const response = await agentRequestForBetaSubscription();
      showToast("Requested for Beta", "success");
      this._agentGetSubscription();
    } catch (error) {
      // console.error("Error fetching data:", error.message);
      errorHandler(error);
    }
  };
  render() {
    const {
      subscription,
      // agentSubscriptionFreePlan,
      agentSubscriptionPremiumPlan,
      buySubscriptionAgentModal,
      freeSubscriptionLoading,
      agentSubscriptionPremiumYearlyPlan,
      agentSubscriptionProPlusPlan,
      agentSubscriptionProPlusYearlyPlan,
      // promoSubscriptionLoading,
      // agentSubscriptionPromoPlan,
      planAmount,
      selectedPackage,
      planBillingCycle,
      isOpen,
      role,
    } = this.state;
    const { userData } = this.props;
    this._14DaysTrialPeriod();
    console.log("ere", this.state);
    return (
      <div className="content">
        <div className="responsiveTitle">
          <h2>Subscription</h2>

          <div className="rightSide">
            <Button
              className="floatingButton"
              color="primary"
              onClick={this._goToBillingPage}
            >
              My Billing
            </Button>
          </div>
        </div>

        {/* free subscription card */}

        <Row>
          <Col md={12}>
            <div className="chooseSubscriptionPlan">
              <div className="chooseSubContent">
                <h2>Choose the plan that's right for your business</h2>
                <p>
                  Get started for free, try out our platform for an unlimited
                  period of time. Explore our monthly and yearly plans and pick
                  the one that best suits your needs.
                </p>
              </div>
              <div className="switchButton">
                <span className="activeWrap"></span>
                <Input
                  type="checkbox"
                  id="toggleButton"
                  name="toggle"
                  onClick={(e) => this._handleToggle(e.target.checked)}
                  checked={selectedPackage === "Yearly" ? true : false}
                />
                <Label for="toggleButton">
                  <span className="month">Monthly</span>
                  <span className="year">Yearly</span>
                </Label>
              </div>
            </div>
            {subscription?.isBetaMode ? (
              <p>
                Beta Mode Started On:{" "}
                {formatOnlyDateMoment(subscription?.betaModeStartDate)}
              </p>
            ) : (
              <p>
                you can request for beta access{" "}
                <Button
                  color="link"
                  className="requestAccess"
                  onClick={this._requestForBetaSubscription}
                  disabled={subscription?.isBetaMode}
                >
                  {subscription?.isRequestedForBetaMode
                    ? "Requested"
                    : "Request Beta access"}
                </Button>
              </p>
            )}
          </Col>

          <Col md={6} lg={6} xl={4} className="mb-4 mb-xl-0">
            <Card
              className={`subscriptionCard ${subscription?.plan === "Free" &&
                subscription.package === selectedPackage
                ? "active"
                : ""
                }`}
            >
              <CardBody>
                <div className="subscriptionInfo">
                  <h1>Free</h1>
                  <p>
                    List your profile to be available for Notary Jobs
                  </p>
                </div>
                {/* <Button color="primary" className="d-block w-100 h-auto">
                  Get Started
                </Button> */}
                <div className="subcriptionPrice">
                  <span className="price">$0</span>
                  <span>/month</span>
                </div>
                <div className="subscriptionPlanContent">
                  {/* <h1>Free</h1> */}

                  <ul>
                    <li>
                      <img
                        src={
                          require("../../../assets/img/checkIcon.svg").default
                        }
                        alt="check"
                      />
                      <div>Closewise Marketplace Listing</div>
                    </li>
                    <li>
                      <img
                        src={
                          require("../../../assets/img/checkIcon.svg").default
                        }
                        alt="check"
                      />
                      <div>
                        Unlimited Order tracking
                      </div>
                    </li>
                    <li>
                      <img
                        src={
                          require("../../../assets/img/checkIcon.svg").default
                        }
                        alt="check"
                      />
                      <div>
                        Master Schedule Over
                      </div>
                    </li>
                    <li>
                      <img
                        src={
                          require("../../../assets/img/checkIcon.svg").default
                        }
                        alt="check"
                      />
                      <div>
                        Pending Paid Income (Processing limit)
                      </div>
                    </li>
                    <li>
                      <img
                        src={
                          require("../../../assets/img/checkIcon.svg").default
                        }
                        alt="check"
                      />
                      <div>
                        Auto Mileage Log (Processing limit)
                      </div>
                    </li>
                  </ul>
                </div>
                {/* <Button className="btnSubscriped" color="primary" outline>
                  Subscribed
                </Button> */}
                {/* {subscription?.plan === "Pro-Plus" ? (
                  <Button className="btnSubscriped" color="primary" outline disabled>
                    Subscribed
                  </Button>
                ) : null} */}
                {subscription && (
                  // subscription.isExpired ? (
                  //   <Button
                  //     color="primary"
                  //     className="btnSubscriped"
                  //     disabled={
                  //       freeSubscriptionLoading ||
                  //       subscription.isNotAbleToSwitchSubscription
                  //     }
                  //     onClick={() =>
                  //       this._selectFreeSubscription("Free", 30, "Renew")
                  //     }
                  //   >
                  //     {freeSubscriptionLoading ? (
                  //       <i className="fa fa-spinner fa-spin mr-2" />
                  //     ) : null}{" "}
                  //     Select
                  //   </Button>
                  // ) : (
                  <>
                    {!subscription?.isBetaMode &&
                      (subscription.plan === "Premium" ||
                        subscription.plan === "Pro-Plus") && (
                        // !subscription.isUpgradedForPremiumPlan &&
                        <Button
                          color="primary"
                          className="btnSubscriped"
                          disabled={
                            freeSubscriptionLoading ||
                            subscription.isNotAbleToSwitchSubscription
                          }
                          onClick={() =>
                            this._selectFreeSubscription(
                              "Free",
                              30,
                              "Downgrade"
                            )
                          }
                        >
                          {freeSubscriptionLoading ? (
                            <i className="fa fa-spinner fa-spin mr-2" />
                          ) : null}{" "}
                          Downgrade
                        </Button>
                      )}
                  </>
                  // )
                  // ) : (
                  //   <Button
                  //     color="primary"
                  //     className="btnSubscriped"
                  //     disabled={freeSubscriptionLoading}
                  //     onClick={() =>
                  //       this._selectFreeSubscription("Free", 30, "Purchase")
                  //     }
                  //   >
                  //     {freeSubscriptionLoading ? (
                  //       <i className="fa fa-spinner fa-spin mr-2" />
                  //     ) : null}{" "}
                  //     Select
                  //   </Button>
                )}
                {subscription?.isNotAbleToSwitchSubscription && (
                  <>
                    Plan will start from{" "}
                    {formatDate(subscription?.freePlanStartDate)}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>

          <Col md={6} lg={6} xl={4} className="mb-4 mb-xl-0">
            <Card
              // className="subscriptionCard active"
              className={`subscriptionCard ${subscription?.plan === "Premium" &&
                subscription.package === selectedPackage
                ? "active"
                : ""
                }`}
            >
              <CardBody>
                <div className="subscriptionInfo">

                  <h1>
                    Pro + NotaryNearme.com
                  </h1>
                  <p>
                    Everything you get in FREE plus
                  </p>
                </div>
                {/* <Button color="primary" className="d-block w-100 h-auto">
                  Get Started
                </Button> */}
                <div className="subcriptionPrice">
                  <span className="price">
                    {selectedPackage === "Yearly"
                      ? formatCurrencyValue(
                        agentSubscriptionPremiumYearlyPlan?.amount
                      )
                      : formatCurrencyValue(
                        agentSubscriptionPremiumPlan?.amount
                      )}
                  </span>
                  <span>
                    /{selectedPackage === "Yearly" ? "year" : "month"}
                  </span>
                </div>
                <div className="subscriptionPlanContent">
                  {/* <h1>Unlimited Entries</h1> */}
                  <ul>
                    <li>
                      <img
                        src={
                          require("../../../assets/img/checkIcon.svg").default
                        }
                        alt="check"
                      />
                      <div>Display Contact Info on NotaryNearMe.com to receive direct jobs</div>
                    </li>
                    <li>
                      <img
                        src={
                          require("../../../assets/img/checkIcon.svg").default
                        }
                        alt="check"
                      />
                      <div>Featured CloseWise Marketplace Listing</div>
                    </li>
                    <li>
                      <img
                        src={
                          require("../../../assets/img/checkIcon.svg").default
                        }
                        alt="check"
                      />
                      <div>Al Order Inputs (HUGE TIME SAVER)</div>
                    </li>

                    <li>
                      <img
                        src={
                          require("../../../assets/img/checkIcon.svg").default
                        }
                        alt="check"
                      />
                      <div>Full Accounting Suite</div>
                    </li>
                    <li>
                      <img
                        src={
                          require("../../../assets/img/checkIcon.svg").default
                        }
                        alt="check"
                      />
                      <div>Enhanced Mileage Tracking</div>
                    </li>
                    <li>
                      <img
                        src={
                          require("../../../assets/img/checkIcon.svg").default
                        }
                        alt="check"
                      />
                      <div>Analytics Summaries</div>
                    </li>
                    <li>
                      <img
                        src={
                          require("../../../assets/img/checkIcon.svg").default
                        }
                        alt="check"
                      />
                      <div>Digital Business Card</div>
                    </li>

                    <li>
                      <img
                        src={
                          require("../../../assets/img/checkIcon.svg").default
                        }
                        alt="check"
                      />
                      <div>Google Calendar Sync
                      </div>
                    </li>
                  </ul>
                </div>
                {/* <Button className="btnSubscriped" color="primary">
                  Subscribed
                </Button> */}
                {!subscription?.isBetaMode &&
                  this._handleShowOfPremiumButton("downgrade") && (
                    <Button
                      color="primary"
                      className="btnSubscriped"
                      disabled={
                        freeSubscriptionLoading ||
                        subscription.isNotAbleToSwitchSubscription
                      }
                      // onClick={() =>
                      //   this._selectFreeSubscription("Premium", 30, "Downgrade")
                      // }
                      onClick={() =>
                        this._onToggleBuySubscriptionAgentModal(
                          true,
                          "Premium",
                          planBillingCycle !== null && planBillingCycle !== 0
                            ? planBillingCycle
                            : 30,
                          planAmount !== null && planAmount !== 0
                            ? planAmount
                            : agentSubscriptionPremiumPlan?.amount,
                          // 30,
                          // agentSubscriptionPremiumPlan?.amount,
                          "Downgrade"
                        )
                      }
                    >
                      {freeSubscriptionLoading ? (
                        <i className="fa fa-spinner fa-spin mr-2" />
                      ) : null}{" "}
                      Downgrade
                    </Button>
                  )}
                {subscription && (
                  // subscription.isExpired ? (
                  //   <Button
                  //     color="primary"
                  //     className="btnSubscriped"
                  //     onClick={() =>
                  //       this._onToggleBuySubscriptionAgentModal(
                  //         true,
                  //         "Premium",
                  //         buySubscriptionAgentModal.data.plan !== null
                  //           ? buySubscriptionAgentModal.data.plan
                  //           : 30,
                  //         buySubscriptionAgentModal.data.amount !== false
                  //           ? buySubscriptionAgentModal.data.amount
                  //           : agentSubscriptionPremiumPlan?.amount,
                  //         // 30,
                  //         // agentSubscriptionPremiumPlan?.amount,
                  //         "Renew"
                  //       )
                  //     }
                  //   >
                  //     Renew
                  //    </Button>
                  // ) : (
                  <>
                    {
                      // subscription.plan === "Free" &&
                      //   // ||
                      //   // subscription.planBillingCycle !== planBillingCycle)
                      //   (subscription?.isAgentFreeTrialPremiumSubscriptionOver ||
                      //     subscription.plan === "Free") &&
                      //   subscription.billingCycle !== 365
                      !subscription?.isBetaMode &&
                      this._handleShowOfPremiumButton("upgrade") && (
                        <Button
                          color="primary"
                          className="btnSubscriped"
                          disabled={
                            freeSubscriptionLoading ||
                            subscription.isNotAbleToSwitchSubscription
                          }
                          onClick={() =>
                            this._onToggleBuySubscriptionAgentModal(
                              true,
                              "Premium",
                              planBillingCycle !== null &&
                                planBillingCycle !== 0
                                ? planBillingCycle
                                : 30,
                              planAmount !== null && planAmount !== 0
                                ? planAmount
                                : agentSubscriptionPremiumPlan?.amount,
                              // 30,
                              // agentSubscriptionPremiumPlan?.amount,
                              "Upgrade"
                            )
                          }
                        >
                          Upgrade
                        </Button>
                      )
                    }
                    {/* {console.log("first", subscription.amount, planAmount)} */}
                  </>
                  // )
                  // ) : (
                  //   <Button
                  //     color="primary"
                  //     className="btnSubscriped"
                  //     onClick={() =>
                  //       this._onToggleBuySubscriptionAgentModal(
                  //         true,
                  //         "Premium",
                  //         buySubscriptionAgentModal.data.plan !== null
                  //           ? buySubscriptionAgentModal.data.plan
                  //           : 30,
                  //         buySubscriptionAgentModal.data.amount !== null
                  //           ? buySubscriptionAgentModal.data.amount
                  //           : agentSubscriptionPremiumPlan?.data.amount,
                  //         // 30,
                  //         // agentSubscriptionPremiumPlan?.amount,
                  //         "Purchase"
                  //       )
                  //     }
                  //   >
                  //     Purchase
                  //   </Button>
                )}
              </CardBody>
            </Card>
          </Col>

          <Col md={6} lg={6} xl={4}>
            <Card
              // className="subscriptionCard"
              className={`subscriptionCard ${subscription?.plan === "Pro-Plus" &&
                subscription.package === selectedPackage
                ? "active"
                : ""
                }`}
            >
              <CardBody>
                <div className="subscriptionInfo">

                  <h1>Professional Growth</h1>
                  <p>
                    Everything you get in the Professional Account and a full scale notary business managed in one place!
                  </p>
                </div>
                <Button color="primary" disabled className="d-block w-100 h-auto">
                  Coming Soon
                </Button>
                <div className="subcriptionPrice">
                  {selectedPackage === "Yearly" ? (
                    <span className="price mainPrice">$800</span>
                  ) : (
                    <span className="price mainPrice">$80</span>
                  )}
                  <span className="price">
                    {selectedPackage === "Yearly"
                      ? formatCurrencyValue(
                        agentSubscriptionProPlusYearlyPlan?.amount
                      )
                      : formatCurrencyValue(
                        agentSubscriptionProPlusPlan?.amount
                      )}
                  </span>
                  <span>
                    /{selectedPackage === "Yearly" ? "year" : "month"}
                  </span>
                </div>
                <div className="subscriptionPlanContent">
                  <p className="text-primary text-center">
                    Includes Hosting & Premium Support
                  </p>


                  <div className="allfeature">
                    <div className="feature">
                      <h2>Notary Website + Booking Capability (Launch Today!)</h2>
                      <ul>
                        <li>
                          <img
                            src={
                              require("../../../assets/img/checkIcon.svg")
                                .default
                            }
                            alt="check"
                          />
                          <div>Custom Domain</div>
                        </li>
                        <li>
                          <img
                            src={
                              require("../../../assets/img/checkIcon.svg")
                                .default
                            }
                            alt="check"
                          />
                          <div>Booking & Payments</div>
                        </li>
                        <li>
                          <img
                            src={
                              require("../../../assets/img/checkIcon.svg")
                                .default
                            }
                            alt="check"
                          />
                          <div>Coop with your Notary Network</div>
                        </li>
                      </ul>
                    </div>
                    <div className="feature">
                      <h2>CRM Campaign Manager+</h2>
                      <ul>
                        <li>
                          <img
                            src={
                              require("../../../assets/img/checkIcon.svg")
                                .default
                            }
                            alt="check"
                          />
                          <div>Client Multi Tagging</div>
                        </li>
                        <li>
                          <img
                            src={
                              require("../../../assets/img/checkIcon.svg")
                                .default
                            }
                            alt="check"
                          />
                          <div>Campaign Templates</div>
                        </li>
                        <li>
                          <img
                            src={
                              require("../../../assets/img/checkIcon.svg")
                                .default
                            }
                            alt="check"
                          />
                          <div>Bulk Email + Text</div>
                        </li>

                      </ul>
                    </div>
                    <div className="feature">
                      <h2>Notary Near Me Premium Listing!</h2>

                      <ul>
                        <li>
                          <img
                            src={
                              require("../../../assets/img/checkIcon.svg")
                                .default
                            }
                            alt="check"
                          />
                          <div>Be featured as a recommended notary</div>
                        </li>
                        <li>
                          <img
                            src={
                              require("../../../assets/img/checkIcon.svg")
                                .default
                            }
                            alt="check"
                          />
                          <div>Retail Store Listing Option to display your in office notary business (optional)</div>
                        </li>
                        <li>
                          <img
                            src={
                              require("../../../assets/img/checkIcon.svg")
                                .default
                            }
                            alt="check"
                          />
                          <div>
                            Link your Business website for enhanced traffic to your booking page

                          </div>
                        </li>
                      </ul>
                    </div>


                  </div>
                  <p className="text-primary">
                    Includes 500 marketing Emails
                  </p>
                  <div className="extraFeature">
                    <h2> Additional Charges</h2>
                    <p>
                      <img
                        src={
                          require("../../../assets/img/checkIcon.svg").default
                        }
                        alt="icon"
                      />{" "}
                      one cent per 10 Emails
                    </p>
                    <p>
                      <img
                        src={
                          require("../../../assets/img/checkIcon.svg").default
                        }
                        alt="icon"
                      />{" "}
                      one cent per 1 text message
                    </p>
                  </div>
                </div>
                {/* <Button className="btnSubscriped" color="primary" outline>
                  Subscribed
                </Button> */}

                {!subscription?.isBetaMode &&
                  this._handleShowOfProplusButton("downgrade") && (
                    <Button
                      color="primary"
                      className="btnSubscriped"
                      // disabled={
                      //   freeSubscriptionLoading ||
                      //   subscription.isNotAbleToSwitchSubscription
                      // }
                      disabled
                      // onClick={() =>
                      //   this._selectFreeSubscription("Pro-Plus", 30, "Downgrade")
                      // }
                      onClick={() =>
                        this._onToggleBuySubscriptionAgentModal(
                          true,
                          "Pro-Plus",
                          planBillingCycle !== null && planBillingCycle !== 0
                            ? planBillingCycle
                            : 30,
                          planAmount !== null && planAmount !== 0
                            ? planAmount
                            : agentSubscriptionPremiumPlan?.amount,
                          // 30,
                          // agentSubscriptionPremiumPlan?.amount,
                          "Downgrade"
                        )
                      }
                    >
                      {freeSubscriptionLoading ? (
                        <i className="fa fa-spinner fa-spin mr-2" />
                      ) : null}{" "}
                      {/* Downgrade */}
                      Coming Soon...
                    </Button>
                  )}
                {subscription && (
                  // subscription.isExpired ? (
                  //   <Button
                  //     className="btnSubscriped"
                  //     color="primary"
                  //     onClick={() =>
                  //       this._onToggleBuySubscriptionAgentModal(
                  //         true,
                  //         "Pro-Plus",
                  //         buySubscriptionAgentModal.data.plan !== null
                  //           ? buySubscriptionAgentModal.data.plan
                  //           : 30,
                  //         buySubscriptionAgentModal.data.amount !== false
                  //           ? buySubscriptionAgentModal.data.amount
                  //           : agentSubscriptionPremiumPlan?.amount,
                  //         // 30,
                  //         // agentSubscriptionPremiumPlan?.amount,
                  //         "Renew"
                  //       )
                  //     }
                  //   >
                  //     Renew
                  //   </Button>
                  // ) : (
                  <>
                    {!subscription?.isBetaMode &&
                      this._handleShowOfProplusButton("upgrade") && (
                        <Button
                          className="btnSubscriped"
                          color="primary"
                          // disabled={
                          //   freeSubscriptionLoading ||
                          //   subscription.isNotAbleToSwitchSubscription
                          // }
                          disabled
                          onClick={() =>
                            this._onToggleBuySubscriptionAgentModal(
                              true,
                              "Pro-Plus",
                              planBillingCycle !== null &&
                                planBillingCycle !== 0
                                ? planBillingCycle
                                : 30,
                              planAmount !== null && planAmount !== 0
                                ? planAmount
                                : agentSubscriptionPremiumPlan?.amount,
                              // 30,
                              // agentSubscriptionPremiumPlan?.amount,
                              "Upgrade"
                            )
                          }
                        >
                          {/* Upgrade */}
                          Coming Soon...
                        </Button>
                      )}
                    {/* {console.log("first", subscription.amount, planAmount)} */}
                  </>
                  // )
                  // ) : (
                  //   <Button
                  //     color="primary"
                  //     onClick={() =>
                  //       this._onToggleBuySubscriptionAgentModal(
                  //         true,
                  //         "Pro-Plus",
                  //         buySubscriptionAgentModal.data.plan !== null
                  //           ? buySubscriptionAgentModal.data.plan
                  //           : 30,
                  //         buySubscriptionAgentModal.data.amount !== null
                  //           ? buySubscriptionAgentModal.data.amount
                  //           : agentSubscriptionPremiumPlan?.data.amount,
                  //         // 30,
                  //         // agentSubscriptionPremiumPlan?.amount,
                  //         "Purchase"
                  //       )
                  //     }
                  //   >
                  //     Purchase
                  //   </Button>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>

        {buySubscriptionAgentModal.isOpen && (
          <BuySubscriptionAgentModal
            isOpen={buySubscriptionAgentModal.isOpen}
            data={buySubscriptionAgentModal.data}
            subscription={subscription}
            toggle={() => this._onToggleBuySubscriptionAgentModal()}
            purchaseSubscription={this._purchaseSubscription}
          // upgradeDowngradeDetail={}
          />
        )}

        {/* {isOpen && (
          <ShowVideoModal
            isOpen={isOpen}
            role={role}
            toggle={() => this.setState({ isOpen: false })}
            userData={userData}
          />
        )} */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateUserObj: (user) => dispatch(updateUserObj(user)),
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionAgent);
