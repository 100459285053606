import React from "react";
import {
  Card,
  CardBody,
  Table,
  Row,
  Col,
  Input,
  Button,
  FormGroup,
  Spinner,
  Badge,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Label,
} from "reactstrap";
import {
  showToast,
  deepClone,
  formatDateAsPerTimeZoneWithYear,
  openUrlOnNewTab,
  structureQueryParams,
  getLoggedInUserId,
  getTimeZoneAbbr,
  formatOnlyDateMoment,
  convertIsoToUtc,
  capitalize,
  getDropdownColor,
  errorHandler,
  formatCurrencyValue,
  refreshFunc,

  // getDateRangeValueSummarie,
} from "../../../helper-methods";
// import usaStates from "../../../config/usa_states_titlecase";
import { signingCompanyFetchAllStatusReport } from "../../../http/http-calls";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { agentClosingStatus, BASE_URL } from "../../../config";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomPagination from "../components/CustomPagination";
import usaStates from "../../../config/usa_states_titlecase";
import CustomCard from "../components/CustomCard";
import { connect } from "react-redux";

class StatusReportCompany extends React.Component {
  state = {
    closings: [],
    closingsBackup: [],
    dateRangeValue: null,
    filters: {
      states: "",
      status: "",
    },
    activeTab: "Pending",
    loading: false,
    pageNumber: 1,
    isClassAdded: false,
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  _toggleTab = async (tab) => {
    const { activeTab } = deepClone(this.state);

    if (tab === activeTab) {
      return;
    }

    try {
      this.setState({ activeTab: tab, pageNumber: 1 }, () => {
        this._paginateArray(this.state.pageNumber);
      });
    } catch (error) {
      errorHandler(error);
      this.setState({ loading: false, activeTab: "Pending", pageNumber: 1 });
    }
  };
  componentDidMount() {
    // this._getAllStatusReport();
    this.setState({ loading: true });
    this._setFilterDataFromLocalStorage();
    HeaderEventEmitter.subscribe("reset-filter", () => {
      console.log("filter reset hit");
      this._resetFilter();
      refreshFunc("company-reporting-status-report");
    });

    HeaderEventEmitter.subscribe("download-status-report", () => {
      console.log("status-report");
      this._downloadData();
    });
  }

  _getAllStatusReport = async () => {
    try {
      // this.setState({ loading: true });
      const { filters, dateRangeValue } = deepClone(this.state);

      const filterPayload = {};

      if (filters.states && filters.states.length)
        filterPayload["states"] = filters.states;

      if (filters.status && filters.status.trim().length)
        filterPayload["status"] =
          filters.status !== "" ? filters.status : undefined;

      if (dateRangeValue) {
        filterPayload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
        filterPayload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
      }

      let payload = {
        filters: filterPayload,
      };
      let response = await signingCompanyFetchAllStatusReport(payload);

      this.setState(
        {
          closings: response?.closings?.slice(0, 10) || [],
          closingsBackup: response?.closings || [],
          loading: false,
        },
        () => {
          this._paginateArray(this.state.pageNumber);
        }
      );
    } catch (error) {
      this.setState({ loading: false });
      errorHandler(error);
    }
  };

  _downloadData = (action = "download") => {
    const { closings, filters, dateRangeValue } = this.state;

    if (!Object.keys(closings).length) {
      showToast(`No data for ${action}`, "error");
      return;
    }

    const payload = {
      userId: getLoggedInUserId(),
    };

    if (filters.states && filters.states.length)
      payload["states"] = filters.states;

    if (filters.status && filters.status.trim().length)
      payload["status"] = filters.status.trim();

    if (dateRangeValue) {
      payload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      payload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
    }

    const queryParams = structureQueryParams(payload);

    if (action === "download")
      openUrlOnNewTab(
        `${BASE_URL}/signingcompany/download/status-report${queryParams}`
      );
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue, loading: true }, () => {
      // this.setState({ loading: true });
      this._persistFilter();
      this._getAllStatusReport();
    });
  };

  _filterOnChange = (type, value, isDelay = false) => {
    if (isDelay) clearTimeout(this.searchTimer);
    this.setState({ loading: true });

    const { filters } = deepClone(this.state);
    filters[type] = value;

    this.setState({ filters }, () => {
      if (isDelay) {
        this.searchTimer = setTimeout(() => {
          this._getAllStatusReport();
          this._persistFilter();
        }, 1000);
      } else {
        this._getAllStatusReport();
        this._persistFilter();
      }
    });
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          states: "",
          status: "",
        },
        dateRangeValue: null,
      },
      () => {
        this._getAllStatusReport();
        refreshFunc("company-reporting-status-report-responsive");
      }
    );
  };
  _persistFilter = () => {
    const { filters, dateRangeValue, pageNumber } = this.state;
    if (
      (filters &&
        (filters.states || (filters.status && filters.status !== ""))) ||
      dateRangeValue !== null ||
      pageNumber !== 1
    ) {
      let data = { filters, dateRangeValue, pageNumber };
      localStorage.companystatusreport = JSON.stringify(data);
    } else {
      delete localStorage.companystatusreport;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    // const { filters, tableConfig } = deepClone(this.state);

    if (localStorage && localStorage.companystatusreport) {
      try {
        const filters = JSON.parse(localStorage.companystatusreport);

        let dateRange = null;
        if (filters.dateRangeValue) {
          dateRange = [
            new Date(convertIsoToUtc(filters.dateRangeValue[0])),
            new Date(convertIsoToUtc(filters.dateRangeValue[1])),
          ];
        }

        this.setState(
          {
            filters: filters.filters,
            dateRangeValue: dateRange,
            pageNumber: filters?.pageNumber,
          },
          () => {
            this._getAllStatusReport();
            // this._setDateRangeValue();
          }
        );
      } catch (e) {
        this._getAllStatusReport();
        // this._setDateRangeValue();
      }
    } else {
      this._getAllStatusReport();
      // this._setDateRangeValue();
    }
  };

  _prepareFilterData = () => {
    const { filters } = deepClone(this.state);

    if (!filters.states) delete filters.agent;
    if (!filters.status) delete filters.status;
    // if(dateRangeValue===null) delete dateRangeValue;

    return filters;
  };

  _tableFormatWithArray = (data) => {
    console.log("fg", data);
    return (
      <>
        <div className="CustomTableWrapper">
          <Table responsive striped>
            <thead>
              <tr>
                <th>Status</th>
                <th>Date</th>
                <th>Service</th>
                <th>Company</th>
                <th>Signer</th>
                <th>Agent</th>
                <th>Reference</th>
                {!this.props.userData?.user?.isTitleCompany && (
                  <th>Company Fee</th>
                )}
                <th>Agent Fee</th>
              </tr>
            </thead>
            <tbody>
              {data.length !== 0 &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Badge
                          className="outline"
                          color={`${getDropdownColor(item.status)}`}
                        >
                          {item.status}
                        </Badge>
                        {console.log("asfasdf " + item.status)}
                      </td>

                      <td>
                        <div style={{ width: 120 }}>
                          {formatDateAsPerTimeZoneWithYear(
                            item.appointmentDate,
                            item.closingAddress.timeZone
                          )}
                          {" | "}
                          {getTimeZoneAbbr(
                            item.appointmentDate,
                            item.closingAddress.timeZone
                          )}
                        </div>
                      </td>
                      <td>
                        <>
                          <div style={{ width: 150 }}>
                            <span
                              style={{
                                marginRight: 6,
                                display: "inline-block",
                              }}
                            >
                              {item.signingType}
                              {","}
                            </span>

                            {item.loanType && item.loanType.length > 0 ? (
                              item.loanType.indexOf("Other") > -1 ? (
                                <>
                                  <span
                                    style={{
                                      marginRight: 6,
                                      display: "inline-block",
                                    }}
                                  >
                                    {" "}
                                    {item?.loanTypeOther} {","}
                                  </span>
                                </>
                              ) : null
                            ) : null}
                            {item.loanType
                              .filter((each) => each !== "Other")
                              .map((item, index) => (
                                <span
                                  key={`loanType_${index}`}
                                  style={{
                                    marginRight: 6,
                                    display: "inline-block",
                                  }}
                                >
                                  {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                                  {","}
                                </span>
                              ))}
                          </div>
                        </>
                      </td>
                      <td>
                        <div style={{ width: 120 }}>
                          {item?.signingCompanyName || "N/A"}
                        </div>
                      </td>
                      <td>
                        <div style={{ width: 120 }}>
                          {/* show the below span only when there are more than 1 signers  */}
                          {capitalize(item?._borrower[0]?.name?.full) || "N/A"}{" "}
                          {item?._borrower?.length > 1 && (
                            <>
                              <span
                                id={`PopoverLegacy${item?._id}`}
                                className="themeColor cursorPointer"
                                style={{ fontWeight: "600" }}
                              >
                                +{item?._borrower?.length - 1}
                              </span>
                              <UncontrolledPopover
                                trigger="legacy"
                                placement="bottom"
                                target={`PopoverLegacy${item?._id}`}
                                className="signerPopover"
                              >
                                <PopoverHeader>Signers</PopoverHeader>
                                <PopoverBody>
                                  {item._borrower?.map((item, itemIndex) => (
                                    <p key={`borrower_name_${itemIndex}`}>
                                      {capitalize(item?.name?.full)}
                                    </p>
                                  ))}
                                </PopoverBody>
                              </UncontrolledPopover>
                            </>
                          )}
                        </div>
                      </td>
                      <td>
                        <div style={{ width: 120 }}>
                          {item.agentName || "N/A"}
                        </div>
                      </td>
                      <td>
                        {" "}
                        <div style={{ width: 120 }}>{item.fileNumber}</div>
                      </td>
                      {console.log(
                        "status report",
                        this.props.userData?.user?.isTitleCompany
                      )}
                      {!this.props.userData?.user?.isTitleCompany && (
                        <td>
                          <div style={{ width: 100 }}>
                            {item.signingCompanyFee ? (
                              <>{formatCurrencyValue(item.signingCompanyFee)}</>
                            ) : (
                              "N/A"
                            )}
                          </div>
                        </td>
                      )}
                      <td>
                        <div style={{ width: 120 }}>
                          {item.agentFee ? (
                            <>{formatCurrencyValue(item.agentFee)}</>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </>
    );
  };

  _calculateTotalFee = (array) => {
    let arr = array.map((item) =>
      item.signingCompanyFee !== undefined ? item.signingCompanyFee : 0
    );
    return arr.reduce((a, b) => a + b, 0);
  };

  _calculateAgentTotalFee = (array) => {
    const { activeTab } = deepClone(this.state);

    // let arr = array.map((item) =>
    //   item.agentFee !== undefined ? item.agentFee : 0
    // );

    return array.reduce((a, b) => {
      console.log("aaa", a, b.agentFee);
      if (b.status === activeTab) {
        return b.agentFee !== undefined ? b.agentFee + a : a;
      } else {
        return a;
      }
    }, 0);
  };

  _paginateArray = (currentPage, itemsPerPage = 10) => {
    const { closingsBackup, activeTab } = deepClone(this.state);
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    console.log("qwer", startIndex, endIndex);
    let newArray = closingsBackup
      .filter((item) => item.status === activeTab)
      .slice(startIndex, endIndex);
    this.setState(
      {
        closings: newArray,
        pageNumber: currentPage,
      },
      () => {
        this._persistFilter();
      }
    );
  };

  _cardDataFormat = (row) => {
    console.log("23322 ", row);
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Date </Label>
            <div style={{ fontWeight: 600 }}>
              {row?.appointmentDate
                ? formatDateAsPerTimeZoneWithYear(
                    row?.appointmentDate,
                    row?.closingAddress?.timeZone
                  ) +
                  " | " +
                  getTimeZoneAbbr(
                    row?.appointmentDate,
                    row?.closingAddress?.timeZone
                  )
                : "N/A"}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Service </Label>
            <div style={{ fontWeight: 600 }}>
              <>
                <span
                  style={{
                    marginRight: 6,
                    display: "inline-block",
                  }}
                >
                  {row?.signingType}
                  {","}
                </span>

                {row?.loanType && row?.loanType?.length > 0 ? (
                  row?.loanType.indexOf("Other") > -1 ? (
                    <>
                      <span
                        style={{
                          marginRight: 6,
                          display: "inline-block",
                        }}
                      >
                        {" "}
                        {row?.loanTypeOther} {","}
                      </span>
                    </>
                  ) : null
                ) : null}
                {row?.loanType
                  .filter((each) => each !== "Other")
                  .map((item, index) => (
                    <span
                      key={`loanType_${index}`}
                      style={{
                        marginRight: 6,
                        display: "inline-block",
                      }}
                    >
                      {item?.replace(/([a-z])([A-Z])/g, "$1 $2")}
                      {","}
                    </span>
                  ))}
              </>
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Company </Label>
            <div style={{ fontWeight: 600 }}>
              {row?.signingCompanyName ? row?.signingCompanyName : "N/A"}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Signer </Label>
            <div style={{ fontWeight: 600 }}>
              <>
                {row?._borrower[0]?.name?.full
                  ? capitalize(row?._borrower[0]?.name?.full)
                  : "N/A"}
                {/* show the below span only when there are more than 1 signers  */}
                {row?._borrower?.length > 1 && (
                  <>
                    <span
                      id={`PopoverLegacy${row?._id}`}
                      className="themeColor cursorPointer"
                      style={{ fontWeight: "600" }}
                    >
                      +{row?._borrower?.length - 1}
                    </span>
                    <UncontrolledPopover
                      trigger="legacy"
                      placement="bottom"
                      target={`PopoverLegacy${row?._id}`}
                      className="signerPopover"
                    >
                      <PopoverHeader>Signers</PopoverHeader>
                      <PopoverBody>
                        {row?._borrower?.map((item, itemIndex) => (
                          <p key={`borrower_name_${itemIndex}`}>
                            {capitalize(item?.name?.full)}
                          </p>
                        ))}
                      </PopoverBody>
                    </UncontrolledPopover>
                  </>
                )}
              </>
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Reference </Label>
            <div style={{ fontWeight: 600 }}>
              {row?.fileNumber ? row?.fileNumber : "N/A"}
            </div>
          </div>

          {!this.props.userData?.user?.isTitleCompany && (
            <div className="dataWrap">
              <Label>Company Fee: </Label>
              <div style={{ fontWeight: 600 }}>
                {row?.signingCompanyFee
                  ? formatCurrencyValue(row?.signingCompanyFee)
                  : "N/A"}
              </div>
            </div>
          )}
        </li>

        <li>
          <div className="dataWrap">
            <Label>Agent</Label>
            <div style={{ fontWeight: 600 }}>
              {row?.agentName ? row?.agentName : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Agent Fee: </Label>
            <div style={{ fontWeight: 600 }}>
              {row?.agentFee ? formatCurrencyValue(row?.agentFee) : "N/A"}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap d-flex align-items-center justify-content-between">
            <Label className="mb-0">Status</Label>
            <Badge
              className="outline"
              color={`${getDropdownColor(row?.status)}`}
            >
              {row?.status}
            </Badge>
          </div>
        </li>
      </ul>
    );
  };

  render() {
    const {
      dateRangeValue,
      filters,
      closings,
      loading,
      activeTab,
      pageNumber,
      closingsBackup,
      isClassAdded,
    } = this.state;

    // let groupedObj =
    //   closings.length > 0 &&
    //   closings.reduce((acc, cur) => {
    //     if (acc[cur.closingAddress.state] === undefined) {
    //       // acc.[cur.closingAddress.state] = [cur]
    //       acc[cur.closingAddress.state] = {
    //         [cur.status]: [cur],
    //       };
    //     } else if (acc[cur.closingAddress.state][cur.status] === undefined) {
    //       acc[cur.closingAddress.state][cur.status] = [cur];
    //     } else {
    //       acc[cur.closingAddress.state][cur.status].push(cur);
    //     }
    //     return acc;
    //   }, {});
    // let closingData =
    //   closings.length > 0 &&
    //   closings.filter((item) => item.status === activeTab);
    // console.log("fgrg", this.state);
    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>Status Report</h2>
            {console.log("qwer", this.state)}
            <div className="rightSide">
              <Button color="link" onClick={this._resetFilter}>
                <img
                  id="company-reporting-status-report-responsive"
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                />
              </Button>

              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="Filter"
                />
              </Button>
            </div>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.filterShowMobile}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer  responsiveFilter ${
              isClassAdded ? "show" : ""
            }`}
          >
            <div className="filterIcon">
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
              />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>
            <div className="filterWrapper">
              <FormGroup className="dateRange">
                <Label>Date Range</Label>
                <DateRangePicker
                  className="dateRange"
                  format="MM-dd-y"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  onChange={(dateRangeValue) =>
                    this._onChangeDatePicker(dateRangeValue)
                  }
                  value={dateRangeValue}
                />
              </FormGroup>
              <FormGroup>
                <Label>States</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    value={filters.states}
                    name="states"
                    onChange={(e) =>
                      this._filterOnChange("states", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {usaStates.map((state) => (
                      <option
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.name}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              {/* <FormGroup>
                <Label>Status</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    value={filters.status}
                    name="status"
                    onChange={(e) =>
                      this._filterOnChange("status", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {agentClosingStatus.map((each) => (
                      <option key={each.key} value={each.key}>
                        {each.label}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup> */}

              <Button
                color="link"
                outline
                className="downloadButton ml-auto"
                onClick={() => this._downloadData()}
              >
                Download
                <img
                  src={require(`../../../assets/img/download_blue.png`).default}
                  alt="download"
                />
              </Button>
            </div>
            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>

          <div className="downloadButtonPos">
            <Button
              color="link"
              outline
              onClick={() => this._downloadData("download")}
            >
              Download
              <img
                src={require(`../../../assets/img/download_blue.png`).default}
                alt="download"
              />
            </Button>
          </div>

          <Row>
            <Col md="12">
              <div className="verticalTabs">
                <Nav tabs>
                  {agentClosingStatus.map((each) => (
                    <NavItem key={`NavItem_${each.value}`}>
                      <NavLink
                        className={activeTab === each.value ? "active" : ""}
                        onClick={() => {
                          this._toggleTab(each.value);
                        }}
                      >
                        <img
                          src={
                            require(`../../../assets/img/${
                              activeTab === each.value
                                ? each.imageBlueName
                                : each.imageBlackName
                            }.png`).default
                          }
                          alt={each.label}
                        />
                        {each.label}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>

                <TabContent>
                  <TabPane>
                    <Row>
                      <Col xs={6}>
                        <Card className="infoCard">
                          <CardBody>
                            <div>
                              <span>{activeTab}</span>
                              <h4 className={`${getDropdownColor(activeTab)}`}>
                                {closingsBackup?.length ? (
                                  // closings.length
                                  closingsBackup.reduce(
                                    (accumulator, currentObject) => {
                                      if (currentObject.status === activeTab) {
                                        return accumulator + 1;
                                      } else {
                                        return accumulator;
                                      }
                                    },
                                    0
                                  )
                                ) : loading ? (
                                  <Spinner />
                                ) : (
                                  "0"
                                )}
                              </h4>
                            </div>

                            <div className="icon">
                              <img
                                src={
                                  require("../../../assets/img/invoicepending.svg")
                                    .default
                                }
                                alt="notaryActs"
                              />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xs={6}>
                        <Card className="infoCard">
                          <CardBody>
                            <div className="">
                              <span>Agent Fee</span>
                              <h4>
                                {closings?.length ? (
                                  formatCurrencyValue(
                                    this._calculateAgentTotalFee(closingsBackup)
                                  )
                                ) : loading ? (
                                  <Spinner />
                                ) : (
                                  formatCurrencyValue(0)
                                )}
                              </h4>
                            </div>
                            <div className="icon">
                              <img
                                src={
                                  require("../../../assets/img/invoicePaid.svg")
                                    .default
                                }
                                alt="notaryActs"
                              />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col md={12}>
                        {closings?.length ? (
                          <>
                            <div className="hideDesktop">
                              <CustomCard
                                isPageStartFromOne={true}
                                pageNumber={pageNumber}
                                tableData={closings}
                                isPagination={false}
                                // headerKeys={staatusReportCardHeaderKeys}
                                // bodyKeys={agentClosingCardBodyKeys}
                                dataFormat={this._dataFormat}
                                columnSizeMD={12}
                                totalCount={
                                  closingsBackup?.filter(
                                    (item) => item?.status === activeTab
                                  )?.length
                                }
                                onPaginate={(pageNumber, pageSize) =>
                                  this._paginate(pageNumber, pageSize)
                                }
                                rowSelection={false}
                                // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
                                showTableLoading={loading.showTableLoading}
                                cardHeaderFormat={this._cardHeaderFormat}
                                cardDataFormat={this._cardDataFormat}
                              />
                            </div>

                            <div className="hideMobile">
                              {this._tableFormatWithArray(closings)}

                              <CustomPagination
                                // data={closingData.slice(
                                //   pageNumber*5,
                                //   pageNumber*5 + 5
                                // )}
                                data={closings}
                                dataCount={
                                  closingsBackup?.filter(
                                    (item) => item?.status === activeTab
                                  )?.length
                                }
                                isPageStartFromOne={true}
                                pageNumber={pageNumber}
                                pageSize={10}
                                onPageChange={
                                  (pageNumber) =>
                                    this._paginateArray(pageNumber)
                                  // this.setState({ pageNumber })
                                }
                                isHideForLessData={true}
                              />
                            </div>
                          </>
                        ) : (
                          <Card>
                            <div className="nodata">
                              {loading ? (
                                <Spinner />
                              ) : (
                                <>
                                  <img
                                    src={
                                      require("../../../assets/img/noData.svg")
                                        .default
                                    }
                                    alt="no data"
                                  />
                                  <p>There is no data to display</p>
                                </>
                              )}
                            </div>
                          </Card>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};
// export default StatusReportCompany;
export default connect(mapStateToProps, null)(StatusReportCompany);
