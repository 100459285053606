import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,

  Card,
  CardBody,

  CardHeader,
  CardTitle,
} from "reactstrap";
import AddBankInfoModal from "./AddBankInfoModal";
import KYCAlertCard from "../components/kyc-alert";
import ReKYCCard from "./re-kycalert";

const AgentBankInfo = ({ loading, agentDetails, getAgentProfileDetail }) => {
  const reduxState = useSelector((state) => state?.userData);

  const [isOpenAddBankInfoModal, setIsOpenAddBankInfoModal] = useState(false);

  const _toggleAddBankInfoModal = (isOpenAddBankInfoModal = false) => {
    setIsOpenAddBankInfoModal(isOpenAddBankInfoModal);
  };

  const [showKyc, setShowKyc] = useState(true);
  console.log("Dsd", agentDetails);
  const { kyc } = reduxState?.user;
  return (
    <>
      {/* {showKyc ? ( */}
      <>
        <div onClick={() => setShowKyc(!showKyc)}>
          {kyc && (!kyc.paymentEnabled || !kyc.payoutEnabled) ? (
            <KYCAlertCard />
          ) : null}
        </div>
        <div>{kyc?.isReKycNeeded && !kyc?.showKycBanner && <ReKYCCard hideCancelButton={true} hideDoNotShowButton={true} />}</div>
      </>
      {/* // ) : ( */}

      {agentDetails?.hasBank ? (
        <>
          <Card>
            <CardHeader>
              <CardTitle tag="h6">
                Bank Info <small>( ACH Deposit )</small>
              </CardTitle>
            </CardHeader>

            <CardBody>
              {/* <Row>
                <Col xl={6} lg={12} md={12}>
                  <FormGroup className="floatingLabel">
                    <Input
                      type="text"
                      disabled
                      value={agentDetails?.bank?.accountNumber}
                      readOnly
                      placeholder=""
                    />
                    <Label>Account Number</Label>
                  </FormGroup>
                </Col>
                <Col xl={6} lg={12} md={12}>
                  <FormGroup className="floatingLabel">
                    <Input
                      type="text"
                      disabled
                      value={agentDetails?.bank?.routingNumber}
                      readOnly
                      placeholder=""
                    />
                    <Label>Routing Number</Label>
                  </FormGroup>
                </Col>
              </Row> */}

              <div className="text-center mt-3 mb-3">
                <Button
                  color="primary"
                  size="md"
                  disabled={loading}
                  onClick={() => _toggleAddBankInfoModal(true)}
                >
                  {loading ? (
                    <i className="fa fa-spinner fa-spin mr-1" />
                  ) : null}{" "}
                  Update Account
                </Button>
              </div>
            </CardBody>
          </Card>
        </>
      ) : (
        <>
          <div className="nodata">
            <img
              src={require("../../../assets/img/noData.svg").default}
              alt="no data"
            />
            <p> No Bank Account ( for ACH Deposit )</p>
            <Button
              color="primary"
              className="mt-3 mb-2"
              size="md"
              disabled={loading}
              onClick={() => _toggleAddBankInfoModal(true)}
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
              Add Account
            </Button>
          </div>
        </>
      )}

      {/* // )} */}

      <AddBankInfoModal
        isOpen={isOpenAddBankInfoModal}
        toggle={() => _toggleAddBankInfoModal()}
        agentDetails={agentDetails}
        getAgentProfileDetail={() => getAgentProfileDetail()}
      />
    </>
  );
};

export default AgentBankInfo;
