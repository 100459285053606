import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Badge,
  UncontrolledPopover,
  PopoverBody,
  PopoverHeader,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import {
  capitalize,
  formatCurrencyValue,
  formatDateHideYearIfSame,
  formatDateAsPerTimeZOne,
  getTimeZoneAbbr,
  getDropdownColor,
} from "../../../helper-methods";
// import CustomTableSmall from "./custom-table-small";
import CustomTable from "./CustomTable";
import CustomCard from "./CustomCard";

class JobsIncludedModal extends Component {
  state = {
    closingHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      { id: "closingNumber", label: "Order#", noSort: true },
      { id: "appointmentDate", label: "Date", noSort: true },
      { id: "_borrower", label: "Signer", noSort: true },
      {
        id: "amount",
        label: "Amount",
        noSort: true,
        tooltip: "The amount shows the previous due amount.",
      },
      { id: "status", label: "Status", noSort: true },
    ],
    closingCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
  };

  _closeModal = () => {
    this.props.toggle();
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "_borrower": {
        // return row?._closing?._borrower?.name
        //   ? capitalize(row?._closing?._borrower?.name?.full)
        //   : "N/A";
        return (
          <div>
            {/* show the below span only when there are more than 1 signers  */}
            {row?._closing?._borrower
              ? capitalize(row?._closing?._borrower[0]?.name?.full)
              : row?._income?._borrower
              ? capitalize(row?._income?._borrower[0]?.name?.full)
              : "N/A"}{" "}
            {((row?._closing?._borrower &&
              row?._closing?._borrower?.length > 1) ||
              (row?._income?._borrower &&
                row?._income?._borrower?.length > 1)) && (
              <>
                <span
                  id={`PopoverLegacy${row._id}`}
                  className="text-primary cursorPointer"
                >
                  +
                  {(row?._closing?._borrower?.length ||
                    row?._income?._borrower?.length) - 1}
                </span>
                <UncontrolledPopover
                  trigger="legacy"
                  placement="bottom"
                  target={`PopoverLegacy${row._id}`}
                  className="signerPopover"
                >
                  <PopoverHeader>Signers</PopoverHeader>
                  <PopoverBody>
                    {row?._closing?._borrower?.length
                      ? row?._closing?._borrower.map((item) => (
                          <p>{capitalize(item.name.full)}</p>
                        ))
                      : row?._income?._borrower.map((item) => (
                          <p>{capitalize(item.name.full)}</p>
                        ))}
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            )}
          </div>
        );
      }
      case "closingNumber": {
        return row?._closing
          ? row?._closing?.closingNumber
            ? row?._closing?.closingNumber
            : "N/A"
          : row?._income
          ? row?._income?._closing && row?._income?._closing?.fileNumber
            ? row?._income?._closing?.fileNumber
            : row?._income?.fileNumber
            ? row?._income?.fileNumber
            : "N/A"
          : "N/A";
      }
      case "lender": {
        return cell ? capitalize(cell) : "N/A";
      }
      // case "signingCompanyFee": {
      //   return cell || row.agentFee
      //     ? (cell && formatCurrencyValue(cell)) ||
      //         (row.agentFee && formatCurrencyValue(row.agentFee))
      //     : "N/A";
      // }

      case "amount": {
        return row.previousDuePayment !== undefined
          ? formatCurrencyValue(row?.previousDuePayment)
          : "N/A";
      }

      case "appointmentDate": {
        return row?._closing?.appointmentDate ? (
          <>
            {/* {formatDateHideYearIfSame(cell)} <br /> {formatTime(cell)}{" "} */}
            {formatDateAsPerTimeZOne(
              row?._closing?.appointmentDate,
              row?._closing?.closingAddress?.timeZone
            )}
            {" | "}

            {getTimeZoneAbbr(
              row?._closing?.appointmentDate,
              row?._closing?.closingAddress?.timeZone
            )}
          </>
        ) : row?._income ? (
          row?._income?._closing && row?._income?._closing?._appointmentDate ? (
            <>
              {/* {formatDateHideYearIfSame(cell)} <br /> {formatTime(cell)}{" "} */}
              {formatDateAsPerTimeZOne(
                row?._income?._closing?.appointmentDate,
                row?._income?._closing?.closingAddress?.timeZone
              )}
              {" | "}

              {getTimeZoneAbbr(
                row?._income._closing?.appointmentDate,
                row?._income._closing?.closingAddress?.timeZone
              )}
            </>
          ) : (
            <>
              {formatDateAsPerTimeZOne(
                row?._income?.displayAppointmentDate,
                "America/Los_Angeles"
              )}
            </>
          )
        ) : (
          "N/A"
        );
      }
      case "status": {
        return row?._closing?.status ? (
          <>
            <Badge
              color={`${getDropdownColor(row?._closing?.status)}`}
              className="outline"
              pill
            >
              {capitalize(row?._closing?.status)}
            </Badge>
          </>
        ) : row?._income ? (
          row?._income?._closing && row?._income?._closing?.status ? (
            <>
              <Badge
                color={`${getDropdownColor(row?._income?._closing?.status)}`}
                className="outline"
                pill
              >
                {capitalize(row?._income?._closing?.status)}
              </Badge>
            </>
          ) : (
            <Badge
              color={`${getDropdownColor(row?._income?.status)}`}
              className="outline"
              pill
            >
              {capitalize(row?._income?.status)}
            </Badge>
          )
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Order#: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?._closing
                ? row?._closing?.closingNumber
                  ? row?._closing?.closingNumber
                  : "N/A"
                : row?._income
                ? row?._income?._closing && row?._income?._closing?.fileNumber
                  ? row?._income?._closing?.fileNumber
                  : row?._income?.fileNumber
                  ? row?._income?.fileNumber
                  : "N/A"
                : "N/A"}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Date: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?._closing?.appointmentDate ? (
                <>
                  {/* {formatDateHideYearIfSame(cell)} <br /> {formatTime(cell)}{" "} */}
                  {formatDateAsPerTimeZOne(
                    row?._closing?.appointmentDate,
                    row?._closing?.closingAddress?.timeZone
                  )}
                  {" | "}

                  {getTimeZoneAbbr(
                    row?._closing?.appointmentDate,
                    row?._closing?.closingAddress?.timeZone
                  )}
                </>
              ) : row?._income ? (
                row?._income?._closing &&
                row?._income?._closing?._appointmentDate ? (
                  <>
                    {/* {formatDateHideYearIfSame(cell)} <br /> {formatTime(cell)}{" "} */}
                    {formatDateAsPerTimeZOne(
                      row?._income?._closing?.appointmentDate,
                      row?._income?._closing?.closingAddress?.timeZone
                    )}
                    {" | "}

                    {getTimeZoneAbbr(
                      row?._income._closing?.appointmentDate,
                      row?._income._closing?.closingAddress?.timeZone
                    )}
                  </>
                ) : (
                  <>
                    {formatDateAsPerTimeZOne(
                      row?._income?.displayAppointmentDate,
                      "America/Los_Angeles"
                    )}
                  </>
                )
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Signer: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {
                <div>
                  {/* show the below span only when there are more than 1 signers  */}
                  {row?._closing?._borrower
                    ? capitalize(row?._closing?._borrower[0]?.name?.full)
                    : row?._income?._borrower
                    ? capitalize(row?._income?._borrower[0]?.name?.full)
                    : "N/A"}{" "}
                  {((row?._closing?._borrower &&
                    row?._closing?._borrower?.length > 1) ||
                    (row?._income?._borrower &&
                      row?._income?._borrower?.length > 1)) && (
                    <>
                      <span
                        id={`PopoverLegacy${row._id}`}
                        className="text-primary cursorPointer"
                      >
                        +
                        {(row?._closing?._borrower?.length ||
                          row?._income?._borrower?.length) - 1}
                      </span>
                      <UncontrolledPopover
                        trigger="legacy"
                        placement="bottom"
                        target={`PopoverLegacy${row._id}`}
                        className="signerPopover"
                      >
                        <PopoverHeader>Signers</PopoverHeader>
                        <PopoverBody>
                          {row?._closing?._borrower?.length
                            ? row?._closing?._borrower.map((item) => (
                                <p>{capitalize(item.name.full)}</p>
                              ))
                            : row?._income?._borrower.map((item) => (
                                <p>{capitalize(item.name.full)}</p>
                              ))}
                        </PopoverBody>
                      </UncontrolledPopover>
                    </>
                  )}
                </div>
              }
            </div>
          </div>

          <div className="dataWrap">
            <Label>Amount: </Label>
            <i
              id={`amountTooltip_${row?._id}`}
              className="fa fa-info-circle ml-2"
              style={{
                fontSize: "14px",
                color: "blue",
                cursor: "pointer",
              }}
            />
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.previousDuePayment !== undefined
                ? formatCurrencyValue(row?.previousDuePayment)
                : "N/A"}
              <UncontrolledTooltip
                placement="bottom"
                target={`amountTooltip_${row?._id}`}
              >
                The amount shows the previous due amount.
              </UncontrolledTooltip>
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap d-flex align-items-center justify-content-between">
            <Label>Status </Label>
            {row?._closing?.status ? (
              <>
                <Badge
                  color={`${getDropdownColor(row?._closing?.status)}`}
                  className="outline"
                  pill
                >
                  {capitalize(row?._closing?.status)}
                </Badge>
              </>
            ) : row?._income ? (
              row?._income?._closing && row?._income?._closing?.status ? (
                <>
                  <Badge
                    color={`${getDropdownColor(
                      row?._income?._closing?.status
                    )}`}
                    className="outline"
                    pill
                  >
                    {capitalize(row?._income?._closing?.status)}
                  </Badge>
                </>
              ) : (
                <Badge
                  color={`${getDropdownColor(row?._income?.status)}`}
                  className="outline"
                  pill
                >
                  {capitalize(row?._income?.status)}
                </Badge>
              )
            ) : (
              "N/A"
            )}
          </div>
        </li>
      </ul>
    );
  };

  render() {
    const { closingHeaderKeys, closingCardHeaderKeys } = this.state;
    const { data, userData } = this.props;

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this._closeModal}
        size="lg"
        scrollable
        centered
      >
        <ModalHeader toggle={this._closeModal}>Jobs Included</ModalHeader>
        <ModalBody>
          <ul className="jobsIncluded">
            <li>
              <span>Client</span>
              <span>
                {data?._signingCompany?.name?.full ||
                (data?._client && data?._client?.name)
                  ? (data?._signingCompany?.name?.full &&
                      capitalize(data?._signingCompany.companyName)) ||
                    // (data?._client?.name &&
                    //   capitalize(data?._client?.name.full)) ||
                    (data?._client?.name?.full &&
                      capitalize(data?._client?.name?.full))
                  : (data?._assistant?.name?.full &&
                      capitalize(data?._assistant?.name?.full)) ||
                    "N/A"}
              </span>
            </li>
            <li>
              <div className="d-flex justify-content-center align-items-center">
                <span>Amount</span>
                <i
                  id="amountTooltip"
                  className="fa fa-info-circle ml-2"
                  style={{
                    fontSize: "14px",
                    color: "blue",
                    cursor: "pointer",
                  }}
                />
                <UncontrolledTooltip target="amountTooltip">
                  The amount displayed is the pending amount at the time of
                  invoice creation.
                </UncontrolledTooltip>
              </div>
              <span>
                {data?.amount !== undefined
                  ? formatCurrencyValue(data.amount)
                  : "N/A"}
              </span>
            </li>
            <li>
              <span> Date Created</span>
              <span>
                {(data?.createdAt &&
                  formatDateHideYearIfSame(data.createdAt)) ||
                  "N/A"}
              </span>
            </li>
          </ul>

          <div className="hideDesktop">
            <CustomCard
              isPageStartFromOne={true}
              tableData={
                data?.closings?.length ? data?.closings : data?.incomes
              }
              dataFormat={this._dataFormat}
              onPaginate={(pageNumber, pageSize) =>
                this._paginate(pageNumber, pageSize)
              }
              columnSizeMD={12}
              rowSelection={false}
              cardDataFormat={this._cardDataFormat}
            />
          </div>

          <div className="hideMobile">
            {closingHeaderKeys && closingHeaderKeys.length && (
              <>
                <CustomTable
                  striped
                  tableData={
                    data?.closings?.length ? data?.closings : data?.incomes
                  }
                  headerKeys={closingHeaderKeys}
                  dataFormat={this._dataFormat}
                />
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline size="lg" onClick={this._closeModal}>
            CANCEL
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default JobsIncludedModal;
