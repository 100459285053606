import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const DocumentViewModal = (props) => {
  const _renderDocument = () => {
    switch (props.fileType) {
      case "doc":
      case "docx":
      case "pdf": {
        return (
          <div style={{ height: "400px" }}>
            <embed
              src={`https://docs.google.com/gview?url=${props.externalUrl}&embedded=true`}
              width="100%"
              height="100%"
            />
          </div>
        );
      }
      case "image": {
        return <img src={props.externalUrl} alt="document" loading="lazy" />;
      }
      case "video": {
        return (
          <video
            width="100%"
            src={props?.externalUrl}
            controls
            controlsList="nodownload"
          />
        );
      }
      default: {
        return "Document Not Found!";
      }
    }
  };

  return (
    <Modal isOpen={props.isOpen} toggle={() => props.toggle()} centered
    scrollbar>
      <ModalHeader toggle={() => props.toggle()}>
        {props.modalHeading || "Document View"}
      </ModalHeader>

      <ModalBody className="text-center">{_renderDocument()}</ModalBody>

      <ModalFooter>
        <Button className="modalBtnCancel" onClick={() => props.toggle()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DocumentViewModal;
