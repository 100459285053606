import React from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Label,
  FormGroup,
  Badge,
  InputGroup,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  InputGroupText,
  Card,
  CardBody,
  InputGroupAddon,
} from "reactstrap";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import AddNotesModal from "../components/add-notes-income-list-modal";
import {
  capitalize,
  deepClone,
  formatDate,
  formatCurrencyValue,
  // formatDateHideYearIfSame,
  showToast,
  getDateRangeValue,
  getTimeZoneAbbr,
  // formatDateAsPerTimeZOne,
  formatOnlyDateMoment,
  convertIsoToUtc,
  checkIsDateValid,
  getDropdownColor,
  refreshFunc,
  deleteLocalStorageData,
  errorHandler,
} from "../../../helper-methods";
import {
  signingCompanyFetchAllIncome,
  getAllFiltersData,
  updateSigningcompanyIncome,
  signingCompanyMarkIncomeAsPaid,
  updateCompanyIncomeForRecordPayment,
  companyGetListTeamsOfClient,
  deleteCompanyIncomeById,
} from "../../../http/http-calls";
import AddIncomeModalCompany from "../components/add-income-modal-company";
import ReactDatetime from "react-datetime";
import { agentClosingStatus, monthShortNames } from "../../../config";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomTable from "../components/CustomTable";
import CustomCard from "../components/CustomCard";
import Swal from "sweetalert2";

function debounce(fn, time) {
  let timeoutId;
  return wrapper;
  function wrapper(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  }
}

class IncomeListAccountingCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      incomeGraphConfig: {
        options: {
          maintainAspectRatio: false,
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: function (context) {
                  var label = context.label || ""; // changeable
                  var dataFor = context.dataset.label || ""; // constant
                  if (label) {
                    label += ": ";
                  }
                  if (dataFor) {
                    label += dataFor + ": ";
                  }
                  if (context.parsed !== null) {
                    label += formatCurrencyValue(context.parsed);
                  }
                  return label;
                },
              },
            },
          },
          scales: {
            x: {
              display: false,
            },
            y: {
              display: false,
            },
          },
        },
        type: "pie",
        chartData: {
          labels: [],
          datasets: [
            {
              label: "Income",
              backgroundColor: "rgba(30, 41, 92, 0.3)",
              borderColor: "rgba(30, 41, 92, 1)",
              borderWidth: 1,
              hoverBackgroundColor: "rgba(30, 41, 92, 0.5)",
              hoverBorderColor: "rgba(30, 41, 92, 1)",
              data: [],
            },
          ],
        },
      },
      incomeList: [],
      incomeListBackup: [],
      incomeTotalCount: 0,
      totalAmount: 0,
      totalPaidAmount: 0,
      totalPendingDueAmount: 0,
      incomeHeaderKeys: [
        { id: "id", label: "id", noSort: true },
        { id: "appointmentDate", label: "Date", noSort: true },
        { id: "fileNumber", label: "File Number", noSort: true },
        { id: "_borrower", label: "Signer", noSort: true },
        { id: "_client", label: "Client", noSort: true },
        { id: "amount", label: "Fee", noSort: true },
        { id: "status", label: "Status", noSort: true },
        { id: "notes", label: "Notes", noSort: true },
        { id: "duePayment", label: "Balance Due", noSort: true },
        { id: "action", label: "Action", noSort: true },
        { id: "delete", label: "", noSort: true },
      ],
      incomeCardHeaderKeys: [
        { id: "id", label: "id" },
        { id: "name", label: "Name" },
      ],
      tableConfig: {
        skip: 0,
        limit: 50,
        pageNumber: 1,
      },
      addNotesModal: {
        isOpen: false,
        data: null,
      },
      filterDropdownValues: {
        payment: [
          { name: "Pending Payment", value: "Pending" },
          { name: "Paid", value: "Paid" },
        ],
        clients: [],
        clientTeamsList: [],
      },
      addIncomeModalCompany: {
        isOpen: false,
        data: null,
      },
      filters: {
        payment: "",
        client: "",
        clientTeam: "",
        loanType: "",
        search: "",
      },
      dateRangeValue: null,
      loading: {
        showTableLoading: false,
        markPaidLoading: false,
        deleteIncomeLoading: false,
      },
      selectedRows: [],
      amount: "",
      incomeObj: [],
      referenceNumber: "",
      checkDate: "",
      isClassAdded: false,
    };
    this.tableRef = React.createRef();
  }

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  componentDidMount = () => {
    // this._companyFetchAllIncome();
    this._getAllFiltersData();
    this._setFilterDataFromLocalStorage();
    HeaderEventEmitter.subscribe("reset-filter", () => {
      // console.log("filter reset hit");
      this._resetFilter();
      refreshFunc("company-accounting-income");
    });
    HeaderEventEmitter.subscribe("add-income", () => {
      // console.log("add income");
      this._onToggleAddIncomeModalCompany(true);
    });
  };

  _setGraphData = (resData = []) => {
    try {
      const { incomeGraphConfig } = deepClone(this.state);

      incomeGraphConfig.chartData.labels = resData?.length
        ? resData.map((obj) => {
            return `${monthShortNames[obj._id.month - 1]} ${obj._id.year}`;
          })
        : [];
      incomeGraphConfig.chartData.datasets[0].data = resData?.length
        ? resData.map((obj) => obj.amount)
        : [];

      this.setState({ incomeGraphConfig });
    } catch (error) {
      showToast("Somthing went wrong. Try again after sometime.", "error");
    }
  };

  _companyFetchAllIncome = () => {
    this._manageLoading("showTableLoading", true);

    const { filters, tableConfig, dateRangeValue } = deepClone(this.state);

    const filterPayload = {};

    if (filters.client && filters.client.length)
      filterPayload["clientId"] = filters.client;

    if (filters?.client && filters?.clientTeam && filters?.clientTeam?.length)
      filterPayload["clientAssistantTeam"] = filters?.clientTeam;

    if (filters.payment && filters.payment.length)
      filterPayload["status"] = filters.payment;
    if (dateRangeValue) {
      filterPayload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      filterPayload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
    }
    if (filters.search && filters.search.length)
      tableConfig["search"] = filters.search;

    tableConfig["filters"] = { ...filterPayload };

    signingCompanyFetchAllIncome(tableConfig)
      .then((res) => {
        if (res?.incomeGraph?.length) {
          this._setGraphData(res?.incomeGraph);
        }
        this.setState(
          {
            incomeList: res?.incomes || [],
            incomeListBackup: [
              ...this.state.incomeListBackup,
              res?.incomes,
            ].flat(),
            incomeTotalCount: res?.totalCount || 0,
            totalAmount: res?.totalAmount || 0,
            totalPaidAmount: res?.totalPaidAmount || 0,
            totalPendingDueAmount: res?.totalPendingDueAmount || 0,
          },
          () => {
            this._manageLoading("showTableLoading", false);
          }
        );
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _getAllFiltersData = () => {
    getAllFiltersData()
      .then((res) => {
        const { filterDropdownValues } = deepClone(this.state);
        filterDropdownValues["clients"] = res?.clients || [];
        this.setState({ filterDropdownValues });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => {
      this._paginate();
      this._persistFilter();
    });
  };

  _filterOnChange = (type, value) => {
    const { filters, filterDropdownValues } = this.state;
    filters[type] = value;

    if (type === "client") {
      filters["clientTeam"] = "";
      if (value?.length) {
        this._getCompanyListTeamsOfClient(value);
      } else {
        filterDropdownValues["clientTeamsList"] = [];
      }
    }

    this.setState({ filters, filterDropdownValues }, () => {
      if (type === "search") {
        this._searchOnChange();
      } else {
        this._paginate();
        this._persistFilter();
      }
    });
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          payment: "",
          client: "",
          clientTeam: "",
          loanType: "",
          search: "",
        },
        dateRangeValue: null,
      },
      () => {
        this._paginate();
        refreshFunc("company-accounting-income-responsive");
        deleteLocalStorageData("companyIncome");
      }
    );
  };

  _paginate = (pageNumber = 1, pageSize = 50) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._companyFetchAllIncome();
    });
  };

  _onToggleAddIncomeModalCompany = (isOpen = false, data = null) => {
    this.setState({
      addIncomeModalCompany: {
        isOpen,
        data,
      },
    });
  };

  _onToggleAddNotesModal = (isOpen = false, data = null) => {
    this.setState({
      addNotesModal: {
        isOpen,
        data,
      },
    });
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "_borrower": {
        return (
          <div style={{ width: 110 }}>
            {/* show the below span only when there are more than 1 signers  */}
            {cell?.length > 0 ? capitalize(cell[0]?.name?.full) : "N/A"}{" "}
            {cell && cell?.length > 1 && (
              <>
                <span
                  id={`PopoverLegacy${row._id}`}
                  className="text-primary cursorPointer"
                  style={{ fontWeight: "600" }}
                >
                  +{cell.length - 1}
                </span>
                <UncontrolledPopover
                  trigger="legacy"
                  placement="bottom"
                  target={`PopoverLegacy${row._id}`}
                  className="signerPopover"
                >
                  <PopoverHeader>Signers</PopoverHeader>
                  <PopoverBody>
                    {cell.map((item) => (
                      <p>{capitalize(item.name.full)}</p>
                    ))}
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            )}
          </div>
        );
      }
      case "fileNumber": {
        return (
          <>
            {" "}
            <div style={{ width: 90 }}>
              {row?._closing?.fileNumber || "N/A"}
            </div>
          </>
        );
      }
      case "_client": {
        return (
          <>
            <div style={{ width: 90 }}>
              {cell && cell.companyName && cell.companyName
                ? capitalize(cell.companyName)
                : "N/A"}
            </div>
          </>
        );
      }
      case "amount": {
        // return cell ? formatCurrencyValue(cell) : "N/A";
        return (
          <>
            <div style={{ width: 90 }}>
              {cell || cell === 0 ? formatCurrencyValue(cell) : "N/A"}
            </div>
          </>
        );
      }
      case "appointmentDate": {
        return cell ? (
          <>
            <div style={{ width: 100 }}>
              <span style={{ fontWeight: 600 }}>{formatDate(cell)}</span>
              {row?._closing && (
                <span>
                  {" "}
                  {getTimeZoneAbbr(
                    cell,
                    row?._closing?.closingAddress?.timeZone
                  )}
                </span>
              )}
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "notes": {
        return row ? (
          <Button
            color="link"
            onClick={() => this._onToggleAddNotesModal(true, row)}
          >
            <img
              src={require("../../../assets/img/nodes.svg").default}
              alt="Add Notes"
            />
          </Button>
        ) : (
          "-"
        );
      }
      case "status": {
        // return cell
        //   ? agentClosingStatus.find((obj) => obj.value === cell)?.label || "N/A"
        //   : "N/A";
        return cell ? (
          <>
            <Badge
              color={`${getDropdownColor(
                agentClosingStatus.find((obj) => obj.value === cell)?.label
              )}`}
              className="outline"
              pill
            >
              {agentClosingStatus.find((obj) => obj.value === cell)?.label ||
                "N/A"}
            </Badge>
          </>
        ) : (
          "N/A"
        );
      }

      case "duePayment": {
        return (
          <>
            <div style={{ width: 90 }}>
              {cell ? formatCurrencyValue(cell) : "$0"}
            </div>
          </>
        );
      }

      case "delete": {
        return (
          <Button
            style={{ width: 20 }}
            color="link"
            onClick={() => this._removeIncomeAlert(row?._id)}
            disabled={
              row?._closing?.id || this.state.loading?.deleteIncomeLoading
            }
          >
            <img
              src={require("../../../assets/img/deleteIcon.svg").default}
              alt="delete"
            />
          </Button>
        );
      }

      case "action": {
        return row.isPaid ? (
          <Badge color="success" className="outline" pill>
            Paid
          </Badge>
        ) : (
          <Input
            type="text"
            name="amount"
            value={
              this.state.incomeObj.length > 0
                ? this.state.incomeObj.find((item) => item?.id === row?._id)
                    ?.paymentAmount || ""
                : ""
            }
            disabled={
              !this.state.selectedRows.find((item) => item._id === row?._id)
            }
            onChange={(event) =>
              this._onChangeFormField(row, event.target.value)
            }
            style={{ width: 90 }}
          />
        );
      }
      default: {
        return cell;
      }
    }
  };

  _signingCompanyUpdateIncomeMarkPaidById = (id) => {
    this._manageLoading("showTableLoading", true);

    signingCompanyMarkIncomeAsPaid(id)
      .then((res) => {
        this._manageLoading("showTableLoading", false);
        this._companyFetchAllIncome();
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _onSaveNotes = (id, notes) => {
    return new Promise((resolve, reject) => {
      updateSigningcompanyIncome(id, {
        notes: notes?.trim() ? notes.trim() : "",
      })
        .then((res) => {
          this._companyFetchAllIncome();
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // _setSelectedRows = (selectedRows = []) => {
  //   let uniqueArray = this.state.incomeListBackup.filter(
  //     (value, index, array) => {
  //       return array.indexOf(value) === index;
  //     }
  //   );

  //   let oldValue;

  //   const alreadyPushIds = this.state.incomeObj.map((e) => e._id);

  //   oldValue = this.state.incomeObj;

  //   if (this.state.incomeObj.length < selectedRows.length) {
  //     const pushIds = selectedRows.filter(
  //       (item) => alreadyPushIds.indexOf(item) < 0
  //     );
  //     pushIds.forEach((pushId) => {
  //       // console.log(pushId)
  //       const pushObject = uniqueArray.find(
  //         (el) => String(el._id) === String(pushId)
  //       );
  //       oldValue.push(pushObject);
  //     });
  //   } else {
  //     const removeIds = alreadyPushIds.filter(
  //       (item) => selectedRows.indexOf(item) < 0
  //     );
  //     oldValue = oldValue.filter((el) => removeIds.indexOf(String(el._id)) < 0);
  //   }

  //   this.setState(
  //     {
  //       selectedRows,
  //       incomeObj: oldValue,
  //     },
  //     () => {
  //       let isObjectPaymentDue = this.state.incomeObj.filter(
  //         (each) => each?.duePayment === 0 || each?.duePayment === undefined
  //       );
  //       // console.log("isObjectPaymentDue", isObjectPaymentDue);
  //       if (isObjectPaymentDue.length > 0) {
  //         isObjectPaymentDue.forEach((item) =>
  //           this.tableRef.current.onDeRowSelect(item)
  //         );
  //       }
  //     }
  //   );
  // };

  // _setSelectedRows = (selectedRows = []) => {
  //   try {
  //     selectedRows = selectedRows.filter((each) => each?.duePayment);

  //     this.setState({ selectedRows, incomeObj: selectedRows });
  //   } catch (error) {
  //     console.log({ error });
  //   }
  // };

  _setSelectedRows = (selectedRows = []) => {
    const { incomeObj } = deepClone(this.state);

    selectedRows = selectedRows.filter(
      (each) => !each?.isPaid
        // each?.duePayment !== 0 || each?.duePayment !== undefined
    );

    incomeObj.forEach((obj) => {
      let index = selectedRows.findIndex((each) => obj._id === each._id);
      if (index > -1) {
        selectedRows[index] = obj;
      }
      return selectedRows;
    });

    this.setState({ selectedRows, incomeObj: selectedRows });
  };

  _calTotalCheckAmount = () => {
    const { incomeObj } = this.state;

    return incomeObj?.length
      ? incomeObj.reduce((acc, each) => {
          acc = (+acc || 0) + (+each?.paymentAmount || 0);
          return acc;
        }, 0)
      : null;
  };
  _onFieldChange = (fieldName, value) => {
    this.setState({
      [fieldName]: value,
    });
  };

  _onChangeFormField = (row, value) => {
    const { incomeObj } = deepClone(this.state);

    this.state.incomeObj.forEach((item) => {
      // if (item.id === row.id) {
      if (value <= row.duePayment) {
        const objIndex = incomeObj.findIndex((obj) => obj.id === row.id);

        const updatedObj = {
          ...incomeObj[objIndex],
          paymentAmount: value,
        };
        const updatedProjects = [
          ...incomeObj.slice(0, objIndex),
          updatedObj,
          ...incomeObj.slice(objIndex + 1),
        ];

        let replaceArray = this.state.incomeList.map(
          (i) => updatedProjects.find((o) => o.id === i.id) || i
        );
        // console.log("replaceArray", replaceArray);

        this.setState({
          incomeObj: updatedProjects,
          incomeList: replaceArray,
        });
        // }
        var regex = /^\d+(\.\d{0,2})?$/g;
        if (!regex.test(value)) {
          showToast("Invalid Input", "error");
        }
      } else {
        showToast("Entered Value is greater than Due Payment", "error");
      }
      // }
    });
  };

  _updateIncomeForRecordPayment = () => {
    const { incomeObj, referenceNumber, checkDate, selectedRows } = this.state;

    let commanArray = selectedRows.reduce((arr, item1) => {
      const match = incomeObj.find((item2) => item1 === item2.id);
      match && arr.push(match);
      return arr;
    }, []);

    let incomeList = commanArray.map((item) => ({
      id: item.id,
      paymentAmount: item?.paymentAmount,
    }));

    let noPaymentAmount = incomeList.find(
      (item) => item.paymentAmount === undefined
    );

    if (noPaymentAmount) {
      showToast("Payment Amount Should Not be Empty", "error");
    } else {
      // if (incomeObj?.length && checkDate && referenceNumber) {
      this._manageLoading("markPaidLoading", true);
      let incomeList = incomeObj.map((item) => ({
        id: item.id,
        paymentAmount: item?.paymentAmount || "0",
      }));

      const payload = {
        incomeList,
        checkDate,
        referenceNumber,
      };

      const totalCheckAmount = this._calTotalCheckAmount();

      if (totalCheckAmount) payload["totalCheckAmount"] = totalCheckAmount;

      updateCompanyIncomeForRecordPayment(payload)
        .then((res) => {
          this.setState({
            checkDate: "",
            referenceNumber: "",
            selectedRows: [],
          });
          // this.tableRef.current.onDeSelectAll();
          this._companyFetchAllIncome();
          this.setState({ incomeObj: [] });
          this._manageLoading("markPaidLoading", false);
          showToast("Recorded Successfully", "success");
        })
        .catch((error) => {
          console.log("errer", error);

          this._setDateRangeValue();
          // showToast(
          //   error && error.reason && error.reason.length
          //     ? error.reason
          //     : "Server error. Try again after sometime.",
          //   "error"
          // );
          showToast("Enter valid amount");
          this._manageLoading("markPaidLoading", false);
        });
      // } else {
      //   showToast("CheckDate And ReferenceNumber Cannot be Empty", "error");
      // }
    }
  };
  _setDateRangeValue = (value = "month") => {
    const { filters } = this.state;

    switch (value) {
      case "day":
      case "week":
      case "month":
      case "year": {
        filters["dateRangeValue"] = getDateRangeValue(value);
        break;
      }
      case "allTime": {
        filters["dateRangeValue"] = null;
        break;
      }
      case "customDateRange": {
        filters["dateRangeValue"] = null;
        filters["customDateRangeValue"] = value;
        this.setState({ filters });
        return;
      }
      default: {
        showToast("Something went wrong. Try again after sometime.", "error");
        return;
      }
    }

    filters["customDateRangeValue"] = value;
    this.setState({ filters }, () => this._paginate());
  };

  _persistFilter = () => {
    const { filters, dateRangeValue, tableConfig } = this.state;

    if (
      (filters &&
        (filters.payment ||
          filters.client ||
          filters.loanType ||
          (filters.search && filters.search.length))) ||
      dateRangeValue !== null ||
      tableConfig.pageNumber !== 1
    ) {
      let data = { filters, dateRangeValue, tableConfig };
      localStorage.companyIncome = JSON.stringify(data);
    } else {
      delete localStorage.companyIncome;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage?.companyIncome) {
      try {
        const filters = JSON.parse(localStorage?.companyIncome);

        let start =
          filters.dateRangeValue !== null &&
          checkIsDateValid(filters.dateRangeValue[0]);
        let end =
          filters.dateRangeValue !== null &&
          checkIsDateValid(filters.dateRangeValue[1]);

        if (start && end) {
          let dateRange = [
            new Date(convertIsoToUtc(filters.dateRangeValue[0])),
            new Date(convertIsoToUtc(filters.dateRangeValue[1])),
          ];
          this.setState(
            {
              filters: filters.filters,
              dateRangeValue: dateRange,
              tableConfig: filters.tableConfig,
            },
            () => {
              this._companyFetchAllIncome();
              if (this.state.filters?.client) {
                this._getCompanyListTeamsOfClient(this.state.filters?.client);
              }
            }
          );
        }
      } catch (e) {
        this._companyFetchAllIncome();
      }
    } else {
      this._companyFetchAllIncome();
    }
  };

  _getCompanyListTeamsOfClient = async (clientId) => {
    try {
      const res = await companyGetListTeamsOfClient(clientId);
      const { filterDropdownValues } = deepClone(this.state);

      filterDropdownValues["clientTeamsList"] = res?.teams;

      this.setState({ filterDropdownValues });
    } catch (err) {
      errorHandler(err);
    }
  };

  _prepareFilterData = () => {
    const { filters } = deepClone(this.state);

    if (!filters.payment) delete filters.payment;
    if (!filters.client) delete filters.client;
    if (!filters.loanType) delete filters.loanType;
    if (!filters.search?.trim()?.length) delete filters.search;

    return filters;
  };

  _removeIncomeAlert = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to remove this income.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, remove it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this._removeIncome(id);
      }
    });
  };

  _removeIncome = async (id) => {
    try {
      this._manageLoading("deleteIncomeLoading", true);
      await deleteCompanyIncomeById(id);
      this._companyFetchAllIncome();
      this._manageLoading("deleteIncomeLoading", false);
    } catch (err) {
      errorHandler(err);
      this._manageLoading("deleteIncomeLoading", false);
    }
  };

  _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return (
          <>
            {row?.status ? (
              <>
                <div className="tableUserInfo">
                  <div className="userContent d-flex">
                    {/* show the below span only when there are more than 1 signers  */}
                    {row?._borrower?.length > 0
                      ? capitalize(row?._borrower[0]?.name?.full)
                      : "N/A"}{" "}
                    {row?._borrower && row?._borrower?.length > 1 && (
                      <>
                        <span
                          id={`PopoverLegacy${row._id}`}
                          className="text-primary cursorPointer"
                          style={{ fontWeight: "600" }}
                        >
                          +{row?._borrower.length - 1}
                        </span>
                        <UncontrolledPopover
                          trigger="legacy"
                          placement="bottom"
                          target={`PopoverLegacy${row._id}`}
                          className="signerPopover"
                        >
                          <PopoverHeader>Signers</PopoverHeader>
                          <PopoverBody>
                            {row?._borrower.map((item) => (
                              <p>{capitalize(item.name.full)}</p>
                            ))}
                          </PopoverBody>
                        </UncontrolledPopover>
                      </>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="tableUserInfo">N/A</div>
            )}

            <Button
              color="link"
              onClick={() => this._removeIncomeAlert(row?._id)}
              disabled={
                row?._closing?.id || this.state.loading?.deleteIncomeLoading
              }
            >
              <img
                src={require("../../../assets/img/deleteIcon.svg").default}
                alt="delete"
              />
            </Button>
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Date </Label>
            <div>
              {row?.appointmentDate ? (
                <>
                  <span style={{ fontWeight: 600 }}>
                    {formatDate(row?.appointmentDate)}
                  </span>
                  {row?._closing && (
                    <span>
                      {" "}
                      {getTimeZoneAbbr(
                        row?.appointmentDate,
                        row?._closing?.closingAddress?.timeZone
                      )}
                    </span>
                  )}
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
          <div className="dataWrap">
            <Label>File Number </Label>
            <div style={{ fontWeight: 600 }}>
              {row?._closing?.fileNumber ? row?._closing?.fileNumber : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Client </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?._client?.companyName
                ? capitalize(row?._client?.companyName)
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Fee </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.amount || row?.amount === 0
                ? formatCurrencyValue(row?.amount)
                : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Notes </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row ? (
                <Button
                  color="link"
                  onClick={() => this._onToggleAddNotesModal(true, row)}
                >
                  <img
                    src={require("../../../assets/img/nodes.svg").default}
                    alt="Add Notes"
                  />
                </Button>
              ) : (
                "-"
              )}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Balance Due </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.duePayment ? formatCurrencyValue(row?.duePayment) : "$0"}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap d-flex align-items-center justify-content-between">
            <Label>Status</Label>
            <div>
              <Badge
                color={`${getDropdownColor(
                  agentClosingStatus?.find((obj) => obj.value === row?.status)
                    ?.label
                )}`}
                className="outline"
                pill
              >
                {agentClosingStatus?.find((obj) => obj.value === row?.status)
                  ?.label || "N/A"}
              </Badge>
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap text-center">
            {row.isPaid ? (
              <Badge color="success" className="outline" pill>
                Paid
              </Badge>
            ) : (
              <Input
                type="text"
                name="amount"
                value={
                  this.state.incomeObj.length > 0
                    ? this.state.incomeObj.find((item) => item?.id === row?._id)
                        ?.paymentAmount || ""
                    : ""
                }
                disabled={
                  !this.state.selectedRows.find((item) => item._id === row?._id)
                }
                onChange={(event) =>
                  this._onChangeFormField(row, event.target.value)
                }
                style={{ width: 110, height: 35, margin: "auto" }}
              />
            )}
          </div>
        </li>
      </ul>
    );
  };

  _searchOnChange = debounce(() => {
    this._paginate();
    this._persistFilter();
  }, 1000);

  render() {
    const {
      dateRangeValue,
      filters,
      incomeList,
      incomeTotalCount,
      incomeHeaderKeys,
      filterDropdownValues,
      tableConfig,
      loading,
      addIncomeModalCompany,
      totalAmount,
      selectedRows,
      totalPaidAmount,
      isClassAdded,
      totalPendingDueAmount,
      incomeCardHeaderKeys,
    } = this.state;
    const calTotalCheckAmount = this._calTotalCheckAmount();

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>Income List</h2>

            <div className="rightSide">
              <Button
                className="floatingButton"
                color="primary"
                onClick={() => this._onToggleAddIncomeModalCompany(true)}
              >
                + Add Income
              </Button>

              <Button color="link" onClick={this._resetFilter}>
                <img
                  id="company-accounting-income-responsive"
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                  height={14}
                />
              </Button>
              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="Filter"
                  height={14}
                />
              </Button>
            </div>
          </div>

          {/* search filed for responsive will show below 767px */}
          <div className="mobileSearchFiled">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img
                    src={require("../../../assets/img/searchIcon.svg").default}
                    alt="searchIcon"
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="File Number"
                value={filters.search}
                onChange={(e) =>
                  this._filterOnChange("search", e.target.value, true)
                }
              />
            </InputGroup>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.filterShowMobile}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer  responsiveFilter ${
              isClassAdded ? "show" : ""
            }`}
          >
            <div className="filterIcon">
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
              />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>
            <div className="filterWrapper">
              <FormGroup className="searchTable">
                <Label>Search</Label>
                <InputGroup>
                  <InputGroupText addonType="prepend">
                    <img
                      src={
                        require("../../../assets/img/searchIcon.svg").default
                      }
                      alt="searchIcon"
                    />
                  </InputGroupText>
                  <Input
                    type="text"
                    placeholder="File Number"
                    value={filters.search}
                    onChange={(e) =>
                      this._filterOnChange("search", e.target.value, true)
                    }
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="dateRange">
                <Label>Date Range</Label>
                <DateRangePicker
                  className="dateRange"
                  format="MM-dd-y"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  onChange={(dateRangeValue) =>
                    this._onChangeDatePicker(dateRangeValue)
                  }
                  // minDate={new Date("January 01, 2000")}
                  // maxDate={new Date("January 01, 9999")}
                  value={dateRangeValue}
                />
              </FormGroup>
              <FormGroup>
                <Label>Client</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    value={filters.client}
                    name="client"
                    onChange={(e) =>
                      this._filterOnChange("client", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {filterDropdownValues.clients.map((obj) => (
                      <option key={obj.id} value={obj.id}>
                        {(obj?.companyName
                          ? capitalize(obj.companyName)
                          : capitalize(obj.name.full)) || "N/A Client"}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup style={{ minWidth: "150px" }}>
                <Label>Client Teams</Label>
                <div
                  className={`custom-select-wrapper ${
                    filters?.client === "" ? "disabled" : ""
                  }`}
                >
                  <Input
                    type="select"
                    placeholder=" "
                    value={filters?.clientTeam}
                    onChange={(event) =>
                      this._filterOnChange("clientTeam", event.target.value)
                    }
                    disabled={filters?.client === ""}
                    name="clientTeamsId"
                  >
                    <option value="">All Teams</option>
                    {filterDropdownValues?.clientTeamsList?.map((team) => (
                      <option key={team?._id} value={team?._id}>
                        {team?.teamName ? capitalize(team?.teamName) : ""}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Payment</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    value={filters.payment}
                    name="payment"
                    onChange={(e) =>
                      this._filterOnChange("payment", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {filterDropdownValues.payment.map((obj, index) => (
                      <option key={index} value={obj.value}>
                        {obj.name}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
            </div>
            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>

          {totalAmount || incomeTotalCount ? (
            <Row className="mt-4">
              <Col xl={3} lg={6} xs={6}>
                <Card className="infoCard">
                  <CardBody>
                    <div className="">
                      <span>Total Orders</span>
                      <h4>{incomeTotalCount || "0"}</h4>
                    </div>
                    <div className="icon">
                      <img
                        src={
                          require("../../../assets/img/TotalOrders.svg").default
                        }
                        alt="notaryActs"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3} lg={6} xs={6}>
                <Card className="infoCard">
                  <CardBody>
                    <div className="">
                      <span>Total Income</span>
                      <h4>
                        {totalAmount ? formatCurrencyValue(totalAmount) : "$0"}
                      </h4>
                    </div>
                    <div className="icon">
                      <img
                        src={
                          require("../../../assets/img/TotalIncome.svg").default
                        }
                        alt="notaryActs"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3} lg={6} xs={6}>
                <Card className="infoCard">
                  <CardBody>
                    <div className="">
                      <span>Paid</span>
                      <h4>{formatCurrencyValue(totalPaidAmount)}</h4>
                    </div>
                    <div className="icon">
                      <img
                        src={
                          require("../../../assets/img/invoicePaid.svg").default
                        }
                        alt="notaryActs"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3} lg={6} xs={6}>
                <Card className="infoCard">
                  <CardBody>
                    <div className="">
                      <span>Pending</span>
                      <h4 className="text-danger">
                        {formatCurrencyValue(totalPendingDueAmount)}
                      </h4>
                    </div>
                    <div className="icon">
                      <img
                        src={
                          require("../../../assets/img/invoicepending.svg")
                            .default
                        }
                        alt="notaryActs"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : null}

          <div className="hideDesktop">
            <CustomCard
              isPageStartFromOne={true}
              pageNumber={tableConfig.pageNumber}
              tableData={incomeList}
              headerKeys={incomeCardHeaderKeys}
              // bodyKeys={agentClosingCardBodyKeys}
              dataFormat={this._dataFormat}
              totalCount={incomeTotalCount}
              pageSize={tableConfig?.limit}
              onPaginate={(pageNumber, pageSize) =>
                this._paginate(pageNumber, pageSize)
              }
              rowSelection={true}
              selectedRows={selectedRows}
              setSelectedRows={(selectedRows) =>
                this._setSelectedRows(selectedRows)
              }
              // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
              showTableLoading={loading.showTableLoading}
              cardHeaderFormat={this._cardHeaderFormat}
              cardDataFormat={this._cardDataFormat}
            />
          </div>

          <div className="hideMobile">
            {incomeHeaderKeys && incomeHeaderKeys.length && (
              <CustomTable
                striped
                isPageStartFromOne={true}
                pageNumber={tableConfig.pageNumber}
                tableData={incomeList}
                headerKeys={incomeHeaderKeys}
                rowSelection={true}
                dataFormat={this._dataFormat}
                totalCount={incomeTotalCount}
                pageSize={tableConfig?.limit}
                onPaginate={(pageNumber, pageSize) =>
                  this._paginate(pageNumber, pageSize)
                }
                onSortChange={(sortName, sortOrder) =>
                  // sort function is disabled
                  this._paginate()
                }
                selectedRows={selectedRows}
                setSelectedRows={(selectedRows) =>
                  this._setSelectedRows(selectedRows)
                }
                showTableLoading={loading.showTableLoading}
                // ref={this.tableRef}
              />
            )}
          </div>

          <div className="incomeListAgent">
            <Row>
              <Col md={6}>
                <FormGroup
                  className={`floatingLabel ${
                    this.state.checkDate || this.state.isOpenReactDatetime
                      ? "valueAdded"
                      : ""
                  }`}
                >
                  <ReactDatetime
                    inputProps={{
                      className: "form-control",
                      placeholder: " ",
                      value: formatDate(this.state.checkDate),
                      disabled: loading.markPaidLoading,
                    }}
                    value={
                      this.state.checkDate ? new Date(this.state.checkDate) : ""
                    }
                    onOpen={() => this.setState({ isOpenReactDatetime: true })}
                    onClose={() =>
                      this.setState({ isOpenReactDatetime: false })
                    }
                    onChange={(e) =>
                      this._onFieldChange(
                        "checkDate",
                        e._d ? new Date(e._d).toISOString() : ""
                      )
                    }
                    closeOnSelect={true}
                    dateFormat={true}
                    timeFormat={false}
                  />
                  <Label>Check Date</Label>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="floatingLabel">
                  <Input
                    type="text"
                    placeholder=" "
                    value={this.state.referenceNumber}
                    name="referenceNumber"
                    onChange={(event) =>
                      this._onFieldChange("referenceNumber", event.target.value)
                    }
                  />
                  <Label>Reference No</Label>
                </FormGroup>
              </Col>
            </Row>
            <div className="recordPayment">
              {calTotalCheckAmount || calTotalCheckAmount === 0 ? (
                <h4>
                  Received amount is{" "}
                  <span>{formatCurrencyValue(calTotalCheckAmount)}</span>
                </h4>
              ) : null}

              <Button
                color="primary"
                className="mt-4 mb-2"
                size="md"
                disabled={loading.markPaidLoading}
                onClick={() => this._updateIncomeForRecordPayment()}
              >
                {loading.markPaidLoading ? (
                  <i className="fa fa-spinner fa-spin mr-2" />
                ) : null}{" "}
                Record Payment
              </Button>
            </div>
          </div>

          <AddNotesModal
            isOpen={this.state.addNotesModal.isOpen}
            data={this.state.addNotesModal.data}
            toggle={() => this._onToggleAddNotesModal()}
            resetDetails={() => this._paginate()}
            onSaveNotes={(id, notes) => this._onSaveNotes(id, notes)}
          />

          <AddIncomeModalCompany
            isOpen={addIncomeModalCompany.isOpen}
            data={addIncomeModalCompany.data}
            clients={filterDropdownValues.clients}
            toggle={this._onToggleAddIncomeModalCompany}
            resetDetails={() => this._paginate()}
          />
        </div>
      </>
    );
  }
}

export default IncomeListAccountingCompany;
