import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import { useHistory } from "react-router-dom";

const WebsiteVerifyDomainModal = ({ isOpen, toggle }) => {
  // const history = useHistory();

  const _closeModal = () => {
    // history.push("/agent/dashboard");
    toggle();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => _closeModal()}
        size="lg"
        centered
        scrollable
        className="checkOutModal"
      >
        <ModalHeader>Congratulations!!!!</ModalHeader>
        <ModalBody>
          <p className="text-center mt-4 mb-2">
            The domain has been bought successfully. Please verify it from the
            email sent to your registered email address.
          </p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={_closeModal}>
            Okay
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default WebsiteVerifyDomainModal;
