import React from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Row,
  Col,
  Input,
  Button,
  ListGroupItem,
  CustomInput,
  Table,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Collapse,
  CardHeader,
  Label,
} from "reactstrap";
import {
  showToast,
  formatCurrencyValue,
  deepClone,
  capitalize,
  formatDateHideYearIfSame,
  formatTime,
  openUrlOnNewTab,
  uploadFileOnServer,
  getPhoneNumberFromBrackets,
  formatAddressInSingleText,
  formatDateAsPerTimeZOne,
  agentTodayOrFutureDate,
  enableTimeBetweenStartTimeAndEndTime,
  getTimeZoneAbbr,
  getDropdownColor,
  openGoogleMapOnNewTab,
  openAppleMapOnNewTab,
  errorHandler,
} from "../../../helper-methods";
import {
  getClosingDetailsById,
  updateClosingDetailsById,
  addAgentDocuments,
  addAgentExpenses,
  deleteAgentClosingExpense,
  getAllAgentExpensesForClosing,
  agentUpdateClosingMileage,
  activityLogClosing,
  deleteDocumentForClosing,
  agentDownloadedDocumentClosing,
  getAgentClient,
} from "../../../http/http-calls";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import SigningStatusModal from "../components/signing-status";
import SignerAvailabilityModal from "../components/Modals/signer-availability-for-agents-modal";
import DocumentViewModal from "../components/documentViewModal";
import { RegexConfig } from "../../../config/RegexConfig";
import {
  googlePlaceSearch,
  googlePlaceDetails,
} from "../../../helper-methods/googleService";
import TextEditor from "../components/TextEdittor";
// import { CopyToClipboard } from "react-copy-to-clipboard";
import ChatLogCardComponent from "../components/ChatLogCardComponent";
import SecureDocumentCardComponent from "../components/SecureDocumentCardComponent";
import MilageExpanceTabs from "../components/MilageExpanceTabs";
// import Chips from "react-chips";
// import CreatableSelect from "react-select";
// import { LanguageList, loanTypeConfig } from "../../../config";
import usaStates from "../../../config/usa_states_titlecase";
import EditOrderDetailsModal from "../components/Modals/EditOrderDetailsModal";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import { loanTypeConfig } from "../../../config";
import DownloadInvoiceModal from "../components/Modals/DownloadInvoiceModal";
import { DateTimeShowWithClosingDetails } from "../components/Date-time-show";

class ClosingDetails extends React.Component {
  constructor(props) {
    super(props);
    this.child = React.createRef(); //newRef is now available for use throughout our component
    this.state = {
      closingDetails: null,
      closingExpenses: [],
      closingExpensesTotalAmount: 0,
      loading: {
        statusChangeLoading: false,
        fileUploadLoading: false,
        expensesLoading: false,
        deleteExpensesLoading: false,
        cardUpdateLoading: false,
        isDocumentCompletedLoading: false,
        mileageUpdateLoading: false,
      },
      signingStatusModal: {
        isOpen: false,
        data: null,
        status: null,
      },
      signerAvailabilityModal: {
        isOpen: false,
        data: null,
      },
      documentViewModalData: {
        isOpen: false,
        externalUrl: null,
        modalHeading: null,
        fileType: null,
      },
      expenseFormData: {
        item: {
          value: "",
          error: null,
        },
        amount: {
          value: "",
          error: null,
        },
        receipt: {
          file: {
            previewBlob: null,
            uploadData: null,
            type: null,
          },
          error: null,
        },
      },
      expensesHeaderKeys: [
        { id: "_id", label: "id" },
        { id: "item", label: "Item" },
        { id: "amount", label: "Amount" },
        { id: "receipt", label: "receipt" },
        { id: "action", label: "Action", noSort: true },
      ],
      editMode: null,
      formFields: {
        borrower: [
          {
            _id: {
              value: "",
            },
            borrowerName: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            email: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            workPhone: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            isForeignNational: {
              value: false,
              isDirty: false,
              isValidate: true,
            },
            language: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            languageTypeOther: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            timeZone: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
          },
        ],
        fileNumber: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        clientName: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        clientEmail: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        clientId: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        borrowerAddress: {
          value: "",
          placeId: null,
          error: null,
          isDirty: false,
          isValidate: true,
        },
        lender: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        trackingInfo: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: false,
        },
        personOfContactName: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        personOfContactEmail: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        signingType: {
          value: "Mobile",
          isDirty: false,
          isValidate: true,
        },
        witnessCount: {
          value: 0,
          isDirty: false,
          isValidate: true,
        },
        loanOfficer: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        titleCompany: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        agentFee: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        closingAddress: {
          value: "",
          placeId: null,
          error: null,
          isDirty: false,
          isValidate: true,
        },
        agentNotes: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        personalNotes: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        instructions: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        miles: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        loanType: {
          value: [],
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        loanTypeOther: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
      },
      googleAddressResult: [],
      isAddressListShow: false,
      appointmentMapType: "google",
      activityLog: [],
      activityLogPage: {
        skip: 0,
        limit: 5,
        totalCount: null,
      },
      clients: [],
      editOrderDetailsModal: {
        isOpen: false,
        data: null,
      },
      downloadInvoiceModal: {
        isOpen: false,
        data: null,
      },
      isOpenCollapse: true,
      seed: 0, // used to refresh the component it is passed to as key
    };
  }

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _updateClosingState = (closingDetails) => {
    this.setState({ closingDetails });
  };

  componentDidMount = async () => {
    this._getAgentClient();
    let LoanType = [
      "Refinance",
      "HomeEquity",
      "AttorneyPhone",
      "Purchase",
      "LoanMod",
      "ReverseMtg",
      "HybridESign",
      "DeedInLieu",
      "SingleDoc",
      "LoanApplication",
      "Other",
    ];

    let options = LoanType.map((item) => ({
      value: item,
      label: item.replace(/([A-Z])/g, " $1").trim(),
    }));
    options.splice(0, 0, { value: "", label: "Select" });
    this.setState({ loanType: options });
    try {
      // const id = this.props.match.params.id;
      const id = this.props.match.params?.id?.includes("guestuser")
        ? this.props.match.params?.id?.replace("&guestuser", "")
        : this.props.match.params?.id;
      this.props.showLoader("Fetching Closing Details...");
      await this._getClosingDetailsById(id);
      await this._getAllAgentExpensesForClosing(id);
      this.props.hideLoader();
    } catch (error) {
      errorHandler(error);
      this.props.hideLoader();
      this._redirectToDashboard();
    }

    HeaderEventEmitter.subscribe("back-button", () => {
      this._goBackBtn();
    });
    HeaderEventEmitter.subscribe("download-invoice", () => {
      this._onToggleDownloadInvoiceModal(true, this.state?.closingDetails);
    });

    document.querySelector("#scroll").scrollTo(0, 0);
  };

  _goBackBtn = () => {
    // eslint-disable-next-line no-unused-vars
    const { closingDetails } = this.state;

    // this.props.history.push(`/agent/dashboard`);
    this.props.history.goBack();
  };

  _getAgentClient = () => {
    getAgentClient()
      .then((res) => {
        this.setState({ clients: res?.clients || [] });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _activityLogClosing = () => {
    const { activityLogPage } = deepClone(this.state);

    let payload = `closingId=${this.props.match.params.id}&skip=${activityLogPage.skip}&limit=${activityLogPage.limit}`;

    activityLogClosing(payload)
      .then((res) => {
        this.setState({
          activityLog: res?.logs || [],
          activityLogPage: {
            ...this.state.activityLogPage,
            totalCount: res?.totalCount || 0,
          },
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getClosingDetailsById = async (id = null) => {
    if (!id && this.state.closingDetails && this.state.closingDetails.id) {
      id = this.state.closingDetails.id;
    }

    try {
      const res = await getClosingDetailsById(id);

      this._handleActivityLogPagination();
      this.setState({
        closingDetails: res?.closing || {},
        seed: Math.random(), // used for component refresh purposes
      });

      this._setFormFields();
      HeaderEventEmitter.dispatch("closing-details", {
        closingDetails: res?.closing || {},
      });

      return res;
    } catch (error) {
      throw error;
    }
  };

  _updateClosingDetailsById = (id, payload) => {
    return new Promise((resolve, reject) => {
      updateClosingDetailsById({ id, payload })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _addAgentDocuments = (id, payload) => {
    addAgentDocuments(id, payload)
      .then((res) => {
        this._getClosingDetailsById(id);
        showToast("Document Updated", "success");
        this._manageLoading("fileUploadLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("fileUploadLoading", false);
      });
  };

  _deleteAgentClosingExpense = (expensesId) => {
    this._manageLoading("deleteExpensesLoading", true);

    const { closingDetails } = deepClone(this.state);

    deleteAgentClosingExpense(closingDetails.id, expensesId)
      .then(async (res) => {
        await this._getAllAgentExpensesForClosing(closingDetails.id);
        showToast("Expense Deleted", "success");
        this._manageLoading("deleteExpensesLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("deleteExpensesLoading", false);
      });
  };

  _validateAgentExpenseForm = () => {
    return new Promise((resolve, reject) => {
      const { expenseFormData } = deepClone(this.state);

      let isFormValid = true;
      let errors = {};

      Object.keys(expenseFormData).forEach((key) => {
        switch (key) {
          case "item": {
            if (
              expenseFormData[key].value &&
              expenseFormData[key].value.trim().length
            ) {
              expenseFormData[key].error = null;
            } else {
              expenseFormData[key].error = "*Required";
              errors[key] = "*Required";
              isFormValid = false;
            }
            break;
          }
          case "amount": {
            if (
              expenseFormData[key].value &&
              expenseFormData[key].value.trim().length
            ) {
              if (
                isNaN(expenseFormData[key].value) ||
                Number(expenseFormData[key].value) <= 0
              ) {
                expenseFormData[key].error =
                  "*Amount must be a positive number & greater than 0";
                errors[key] =
                  "*Amount must be a positive number & greater than 0";
                isFormValid = false;
              } else {
                expenseFormData[key].error = null;
              }
            } else {
              expenseFormData[key].error = "*Required";
              errors[key] = "*Required";
              isFormValid = false;
            }
            break;
          }
          default: {
          }
        }
      });

      if (errors && Object.keys(errors) && Object.keys(errors).length) {
        setTimeout(() => this._resetExpenseFormDataError(), 4000);
      }

      this.setState({ expenseFormData }, () => resolve(isFormValid));
    });
  };

  _resetExpenseFormDataError = () => {
    const { expenseFormData } = deepClone(this.state);
    Object.keys(expenseFormData).forEach((key) => {
      expenseFormData[key].error = null;
    });
    this.setState({ expenseFormData });
  };

  _resetExpenseFormData = () => {
    this.setState(
      {
        expenseFormData: {
          item: {
            value: "",
            error: null,
          },
          amount: {
            value: "",
            error: null,
          },
          receipt: {
            file: {
              previewBlob: null,
              uploadData: null,
              type: null,
            },
            error: null,
          },
        },
      },
      () => {
        this._manageLoading("expensesLoading", false);
      }
    );
  };

  _getAllAgentExpensesForClosing = async (id = null) => {
    try {
      if (!id && this.state.closingDetails && this.state.closingDetails.id) {
        id = this.state.closingDetails.id;
      }

      const res = await getAllAgentExpensesForClosing(id);

      this.setState({
        closingExpenses: res?.expenses || [],
        closingExpensesTotalAmount: res?.totalAmount || 0,
      });

      return res;
    } catch (error) {
      throw error;
    }
  };

  _addAgentExpenses = async (id) => {
    const isFormValid = await this._validateAgentExpenseForm();

    if (isFormValid) {
      this._manageLoading("expensesLoading", true);

      const { expenseFormData } = deepClone(this.state);

      const payload = {
        expenses: {
          item: expenseFormData.item.value.trim(),
          amount: Number(expenseFormData.amount.value),
          // receipt: '',
        },
      };

      if (
        expenseFormData.receipt &&
        expenseFormData.receipt.file &&
        expenseFormData.receipt.file.uploadData
      ) {
        const uploadedFilesRes = await uploadFileOnServer([
          expenseFormData.receipt.file,
        ]);
        payload.expenses["receipt"] = uploadedFilesRes[0].url;
        payload.expenses["docType"] = uploadedFilesRes[0].docType;
      }

      addAgentExpenses(id, payload)
        .then(async (res) => {
          this._resetExpenseFormData();
          await this._getAllAgentExpensesForClosing(id);
          showToast("Expense Updated", "success");
        })
        .catch((error) => {
          errorHandler(error);
          this._manageLoading("expensesLoading", false);
        });
    }
  };

  _deleteExpenseFile = () => {
    const { expenseFormData } = deepClone(this.state);
    expenseFormData["receipt"] = {
      file: {
        previewBlob: null,
        uploadData: null,
        type: null,
      },
      error: null,
    };
    this.setState({ expenseFormData });
  };

  _onChangeExpenseForm = (fieldName, value) => {
    const { expenseFormData } = deepClone(this.state);
    if (fieldName === "amount") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }
    expenseFormData[fieldName].value = value;
    this.setState({ expenseFormData });
  };

  _updateClosingDetailsByIdStatus = async (data, status) => {
    try {
      if (
        status.status === "Closed" ||
        status.status === "DNC" ||
        status.status === "CCA"
      ) {
        this._onToggleSigningStatusModal(true, data, status.status);
      } else {
        this._manageLoading("statusChangeLoading", true);

        const updateAgentRes = await this._updateClosingDetailsById(
          data.id,
          status
        );

        showToast("Status Updated Successfully", "success");
        const { closingDetails } = deepClone(this.state);
        closingDetails["status"] = updateAgentRes?.closing?.status;
        closingDetails["isAgentViewDocument"] =
          updateAgentRes?.closing?.isAgentViewDocument;
        this._handleActivityLogPagination();
        this._getClosingDetailsById();
        this.setState({ closingDetails });
        this._manageLoading("statusChangeLoading", false);
      }
    } catch (error) {
      errorHandler(error);
      this._manageLoading("statusChangeLoading", false);
    }
  };

  _updateFile = async (uploadType, event) => {
    const { closingDetails, expenseFormData } = deepClone(this.state);

    let objFile, objFileType;

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      if (uploadType === "expense") {
        objFile = event.target.files[0];
        objFileType = objFile.type.split("/")[0];
        if (objFileType === "image" || objFile.type.includes("pdf")) {
          expenseFormData.receipt.file = {
            previewBlob: URL.createObjectURL(objFile),
            uploadData: objFile,
            type: objFileType === "application" ? "pdf" : objFileType,
          };

          this.setState({ expenseFormData });
        } else {
          showToast("Only Image or PDF file is allowed", "error");
        }
      } else if (uploadType === "document") {
        this._manageLoading("fileUploadLoading", true);
        let uploadFiles = [];

        for (let index = 0; index < event.target.files.length; index++) {
          objFile = event.target.files[index];
          objFileType = objFile.type.split("/")[0];
          uploadFiles.push({
            previewBlob: URL.createObjectURL(objFile),
            uploadData: objFile,
            type: objFileType === "application" ? "pdf" : objFileType,
          });
        }
        if (uploadFiles && uploadFiles.length) {
          const documents = await uploadFileOnServer(uploadFiles);
          this._addAgentDocuments(closingDetails._id, { documents });
        }
      } else {
        showToast("Upload type is required");
        return;
      }
    }
  };

  _redirectToDashboard = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/dashboard`);
  };

  _onToggleSigningStatusModal = (
    isOpen = false,
    data = null,
    status = null
  ) => {
    this.setState({
      signingStatusModal: {
        isOpen,
        data,
        status,
      },
    });
  };

  _onToggleSignerAvailabilityModal = (isOpen = false, data = null) => {
    this.setState({
      signerAvailabilityModal: {
        isOpen,
        data,
      },
    });
  };

  _onToggleDownloadInvoiceModal = (isOpen = false, data = null) => {
    this.setState({
      downloadInvoiceModal: {
        isOpen,
        data,
      },
    });
  };

  _onToggleDocumentViewModal = (doc = null, isOpen = false) => {
    let { documentViewModalData } = deepClone(this.state);
    if (doc && isOpen) {
      documentViewModalData = {
        isOpen: true,
        externalUrl: doc.url,
        modalHeading: doc.title,
        fileType: doc.docType,
      };
    } else {
      documentViewModalData = {
        isOpen: false,
        externalUrl: null,
        modalHeading: null,
        fileType: null,
      };
    }
    this.setState({ documentViewModalData });
  };

  _documentDataFormat = (cell, row, header) => {
    let { closingDetails } = deepClone(this.state);

    switch (header) {
      case "title": {
        return row ? (
          <>
            {row.docType === "pdf" ? (
              <i className="fa fa-file-pdf-o mr-1" />
            ) : (
              <i className="fa fa-file-text-o mr-1" />
            )}
            {row.title}
          </>
        ) : (
          "N/A"
        );
      }
      case "createdAt": {
        return cell ? (
          <>
            {formatDateHideYearIfSame(cell)} <br /> {formatTime(cell)}
          </>
        ) : (
          "N/A"
        );
      }
      case "action": {
        return row ? (
          <>
            <Button
              color="link"
              className="actionBtn"
              onClick={() => this._onToggleDocumentViewModal(row, true)}
            >
              <i className="fa fa-eye" />
            </Button>
            <Button
              color="link"
              className="actionBtn"
              onClick={() => this._downloadDocument(row)}
            >
              <i className="fa fa-download" />
            </Button>

            {!row.isPrivate &&
              !closingDetails.isDocumentCompletedByAgent &&
              !row?.isCreatedForAgent ? (
              <Button
                color="link"
                className="actionBtn"
                onClick={() => this._deleteDocumentClosing(row)}
              >
                <i className="fa fa-trash-o text-danger" />
              </Button>
            ) : null}
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };
  _downloadDocument = (row) => {
    let { closingDetails } = deepClone(this.state);
    let documentPayload = {
      document: { isDownloadedByAgent: true, _id: row._id },
    };
    if (!closingDetails?.isDownloadedByAgent) {
      this._updateClosingDetailsByIdStatus(closingDetails, documentPayload);
    }
    let payload = {
      closingId: closingDetails._id,
      documentId: row._id,
    };
    agentDownloadedDocumentClosing(payload)
      .then((res) => { })
      .catch((error) => {
        errorHandler(error);
      });
    openUrlOnNewTab(row.url);
  };

  _deleteDocumentClosing = (row) => {
    const { closingDetails } = deepClone(this.state);
    let payload = { documentId: row._id };
    this.props.showLoader("Deleting Document...");

    deleteDocumentForClosing(closingDetails._id, payload)
      .then((res) => {
        this._getClosingDetailsById();
        showToast("Document Deleted Successfully", "success");
        this.props.hideLoader();
      })
      .catch((error) => {
        errorHandler(error);
        this.props.hideLoader();
      });
  };

  _expenseDataFormat = (cell, row, header) => {
    switch (header) {
      case "item": {
        return cell ? capitalize(cell) : "N/A";
      }
      case "amount": {
        return cell ? formatCurrencyValue(cell) : "N/A";
      }
      case "receipt": {
        return cell ? (
          <Button
            color="link"
            className="actionBtn"
            onClick={() => openUrlOnNewTab(cell)}
          >
            <i className="fa fa-download" />
          </Button>
        ) : (
          "N/A"
        );
      }
      case "action": {
        return row ? (
          <Button
            color="link"
            className="actionBtn"
            title="Delete Expense"
            disabled={this.state.loading.deleteExpensesLoading}
            onClick={() => this._deleteAgentClosingExpense(row._id)}
          >
            <i className="fa fa-trash-o text-danger" />
          </Button>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _resetFormFields = () => {
    this.setState({
      formFields: {
        name: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        email: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        phoneHome: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        phoneWork: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        phoneMobile: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        borrower: [
          {
            _id: {
              value: "",
            },
            borrowerName: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            email: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            workPhone: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            isForeignNational: {
              value: false,
              isDirty: false,
              isValidate: true,
            },
            language: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            languageTypeOther: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            timeZone: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
          },
        ],
        fileNumber: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        clientName: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        clientEmail: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        clientId: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        borrowerAddress: {
          value: "",
          placeId: null,
          error: null,
          isDirty: false,
          isValidate: true,
        },
        lender: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        trackingInfo: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        personOfContactName: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        personOfContactEmail: {
          value: [],
          error: null,
          isDirty: false,
          isValidate: true,
        },
        signingType: {
          value: "Mobile",
          isDirty: false,
          isValidate: true,
        },
        witnessCount: {
          value: 0,
          isDirty: false,
          isValidate: true,
        },
        loanOfficer: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        titleCompany: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        agentFee: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        closingAddress: {
          value: "",
          placeId: null,
          error: null,
          isDirty: false,
          isValidate: true,
        },
        agentNotes: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        personalNotes: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        instructions: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        miles: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        loanType: {
          value: [],
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        loanTypeOther: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
      },
      googleAddressResult: [],
    });
  };

  _setFormFields = async () => {
    const { formFields, closingDetails } = deepClone(this.state);
    if (closingDetails) {
      let borrower = [];

      closingDetails._borrower.forEach((each) => {
        borrower.push({
          _id: {
            value: each._id,
          },
          borrowerName: {
            value: each.name.full || "",
            isDirty: false,
            isValidate: true,
          },
          email: {
            value: each.email || "",
            isDirty: false,
            isValidate: true,
          },
          workPhone: {
            value: getPhoneNumberFromBrackets(each?.phone?.work) || "",
            isDirty: false,
            isValidate: true,
          },
          isForeignNational: {
            value: each.isForeignNational,
            isDirty: false,
            isValidate: true,
          },
          language: {
            value: each.language || "English",
            isDirty: false,
            isValidate: true,
          },
          languageTypeOther: {
            value: each.languageTypeOther || "",
            isDirty: false,
            isValidate: true,
          },
          timeZone: {
            value: each.timeZone || "",
            isDirty: false,
            isValidate: true,
          },
        });
      });
      formFields["borrower"] = borrower;
      formFields["fileNumber"].value = closingDetails.fileNumber
        ? closingDetails.fileNumber
        : "";
      formFields["lender"].value = closingDetails.lender
        ? closingDetails.lender
        : "";
      formFields["loanOfficer"].value = closingDetails.loanOfficer
        ? closingDetails.loanOfficer
        : "";
      formFields["titleCompany"].value = closingDetails.titleCompany
        ? closingDetails.titleCompany
        : "";
      formFields["agentFee"].value = closingDetails.agentFee
        ? closingDetails.agentFee.toString()
        : "";

      formFields["loanType"].value = closingDetails?.loanType.map((each) => ({
        label: each,
        value: each,
      }));
      formFields["loanTypeOther"].value = closingDetails?.loanTypeOther || "";

      if (closingDetails._client) {
        formFields["clientName"].value = closingDetails._client.name.full
          ? closingDetails._client.name.full
          : "";
        formFields["clientEmail"].value = closingDetails._client.email
          ? closingDetails._client.email
          : "";
        formFields["clientId"].value = closingDetails._client.id
          ? closingDetails._client.id
          : "";
      }

      formFields["trackingInfo"].value = closingDetails.trackingInfo
        ? closingDetails.trackingInfo
        : "";

      if (closingDetails.personOfContact) {
        formFields["personOfContactName"].value = closingDetails.personOfContact
          .name
          ? closingDetails.personOfContact.name
          : "";
        formFields["personOfContactEmail"].value = closingDetails
          .personOfContact.email
          ? closingDetails?.personOfContact?.email?.filter((e) => e !== "")
          : [];
      }

      if (closingDetails._borrower.address) {
        formFields["borrowerAddress"].value = formatAddressInSingleText(
          closingDetails._borrower.address
        );
        formFields["borrowerAddress"].placeId = "initialValue";
      }

      if (closingDetails.closingAddress) {
        formFields["closingAddress"].value = formatAddressInSingleText(
          closingDetails.closingAddress
        );
        formFields["closingAddress"].placeId = "initialValue";
      }

      formFields["agentNotes"].value = closingDetails.agentNotes || "";
      formFields["personalNotes"].value = closingDetails.personalNotes || "";
      formFields["instructions"].value = closingDetails.instructions
        ? closingDetails.instructions
        : "";

      formFields["miles"].value = closingDetails._mileage?.distance
        ? closingDetails._mileage.distance.toString()
        : "0";
    }
    this.setState({ formFields });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields, editMode } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (
          key === "borrower" ||
          (formFields[key].isDirty && formFields[key].isValidate)
        ) {
          if (editMode === "appointment") {
            switch (key) {
              case "borrower": {
                formFields.borrower.forEach((each, index) => {
                  if (each.borrowerName?.value.trim().length) {
                    formFields[key][index]["borrowerName"].isDirty = false;
                    formFields[key][index]["borrowerName"].error = null;
                  } else {
                    formFields[key][index]["borrowerName"].isDirty = true;
                    formFields[key][index]["borrowerName"].error = "*Required";
                    isFormValid = false;
                  }

                  if (
                    formFields[key][index]["email"].value &&
                    formFields[key][index]["email"].value.trim().length
                  ) {
                    if (
                      RegexConfig.email.test(
                        String(
                          formFields[key][index]["email"].value
                        ).toLowerCase()
                      )
                    ) {
                      formFields[key][index]["email"].isDirty = false;
                      formFields[key][index]["email"].error = null;
                    } else {
                      formFields[key][index]["email"].isDirty = true;
                      formFields[key][index]["email"].error = "*Invalid Email";
                      isFormValid = false;
                    }
                  } else {
                    formFields[key][index]["email"].isDirty = false;
                    formFields[key][index]["email"].error = null;
                  }

                  if (
                    formFields[key][index]["workPhone"].value &&
                    formFields[key][index]["workPhone"].value.length
                  ) {
                    if (
                      RegexConfig.phone.test(
                        String(
                          formFields[key][index]["workPhone"].value
                        ).toLowerCase()
                      )
                    ) {
                      formFields[key][index]["workPhone"].isDirty = false;
                      formFields[key][index]["workPhone"].error = null;
                    } else {
                      isFormValid = false;
                      formFields[key][index]["workPhone"].isDirty = true;
                      formFields[key][index]["workPhone"].error =
                        "*Invalid Phone Number";
                    }
                  } else {
                    formFields[key][index]["workPhone"].isDirty = false;
                    formFields[key][index]["workPhone"].error = null;
                  }
                });
                break;
              }
              case "closingAddress": {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  if (formFields[key].placeId) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Invalid Address";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
                break;
              }
              default:
            }
          } else if (editMode === "orderDetails") {
            switch (key) {
              case "clientId":
              case "fileNumber":
              case "personOfContactName":
              case "lender": {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }

                break;
              }
              case "agentFee": {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  if (
                    isNaN(formFields[key].value.trim()) ||
                    Number(formFields[key].value.trim()) < 0
                  ) {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Fee must be a positive number";
                    isFormValid = false;
                  } else {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  }
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "loanTypeOther": {
                if (formFields.loanType.value === "Other") {
                  if (
                    formFields[key].value &&
                    formFields[key].value.trim().length
                  ) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Required";
                    isFormValid = false;
                  }
                }
                break;
              }
              // case "clientEmail":
              case "loanType":
              case "personOfContactEmail": {
                if (formFields[key].value.length) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
                break;
              }
              default:
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _isDisabledCheckStatusDropdown = (itemData) => {
    let result = enableTimeBetweenStartTimeAndEndTime(
      itemData.appointmentDate,
      itemData.closingAddress?.timeZone
    );
    return !result;
  };

  _onSelectAddress = (fieldName, addr) => {
    const { formFields } = deepClone(this.state);
    formFields[fieldName].value = addr.description;
    formFields[fieldName].placeId = addr.place_id;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields, isAddressListShow: false }, () => {
      this._validateForm();
    });
  };

  _googlePlaceSearch = async (searchValue) => {
    if (!this.state.loading.cardUpdateLoading) {
      const googleAddressResult = await googlePlaceSearch(searchValue);
      this.setState({ googleAddressResult, isAddressListShow: true });
    }
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields } = deepClone(this.state);

    if (fieldName === "agentFee" || fieldName === "miles") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }
    if (fieldName === "borrowerAddress" || fieldName === "closingAddress") {
      this._googlePlaceSearch(value);
      formFields[fieldName].placeId = null;
    }
    formFields[fieldName].value = value;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // validation
      this._validateForm();
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        if (formFields[e].isDirty !== undefined) formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _onSaveClosing = async (cardName) => {
    try {
      await this._markAllFieldDirty();

      const isFormValid = await this._validateForm();

      if (isFormValid) {
        this._manageLoading("cardUpdateLoading", true);

        const { formFields, closingDetails, clients } = this.state;

        let payload = {};

        switch (cardName) {
          case "appointment": {
            payload = {
              borrower: [],

              loanTypeOther: formFields.loanTypeOther.value
                ? formFields.loanTypeOther.value
                : undefined,
            };
            let borrower = await formFields.borrower.map(
              ({
                _id,
                borrowerName,
                email,
                workPhone,
                isForeignNational,
                language,
                languageTypeOther,
                timeZone,
              }) => {
                const splitBorrowerName = borrowerName.value.trim().split(" ");

                return {
                  name: {
                    first: splitBorrowerName.slice(0, 1).join(" "),
                    last: splitBorrowerName[1]
                      ? splitBorrowerName.slice(1).join(" ")
                      : "",
                  },
                  _id: _id.value,
                  email: email.value ? email.value : undefined,
                  phone: {
                    work: workPhone.value ? workPhone.value : undefined,
                  },
                  isForeignNational: isForeignNational.value,
                  language: language.value ? language.value : undefined,
                  languageTypeOther: languageTypeOther.value
                    ? languageTypeOther.value
                    : undefined,
                  timeZone: timeZone.value ? timeZone.value : undefined,
                  address: {},
                };
              }
            );
            payload["borrower"] = borrower;
            if (formFields.loanType.value) {
              payload["loanType"] = await formFields.loanType.value.map(
                (item) => item.value
              );
            }
            if (
              formFields.borrowerAddress.placeId &&
              formFields.borrowerAddress.placeId !== "initialValue"
            ) {
              const addressObj = await googlePlaceDetails(
                formFields.borrowerAddress.placeId
              );

              let abbr = usaStates.find(
                (item) => item.name === addressObj.state
              ).abbreviation;

              borrower.map(
                (item) => (
                  // eslint-disable-next-line no-sequences
                  (item.address["line1"] = addressObj.address),
                  (item.address["city"] = addressObj.city),
                  (item.address["county"] = addressObj.county),
                  (item.address["state"] =
                    addressObj.state.length > 2 ? abbr : addressObj.state),
                  (item.address["zip"] = addressObj.postal),
                  (item.address["lat"] = addressObj.lat),
                  (item.address["lng"] = addressObj.lng)
                )
              );
            } else {
              borrower.map(
                (item) => (
                  // eslint-disable-next-line no-sequences
                  (item.address["line1"] = ""),
                  (item.address["city"] = ""),
                  (item.address["county"] = ""),
                  (item.address["state"] = ""),
                  (item.address["zip"] = ""),
                  (item.address["lat"] = ""),
                  (item.address["lng"] = "")
                )
              );
            }

            if (
              formFields.closingAddress.placeId &&
              formFields.closingAddress.placeId !== "initialValue"
            ) {
              const addressObj = await googlePlaceDetails(
                formFields.closingAddress.placeId
              );
              payload.closingAddress["line1"] = addressObj.address;
              payload.closingAddress["city"] = addressObj.city;
              payload.closingAddress["county"] = addressObj.county;
              payload.closingAddress["state"] = addressObj.state;
              payload.closingAddress["zip"] = addressObj.postal;
              payload.closingAddress["lat"] = addressObj.lat;
              payload.closingAddress["lng"] = addressObj.lng;
            } else {
              delete payload.closingAddress;
            }

            payload["agentNotes"] = formFields.agentNotes.value?.trim().length
              ? formFields.agentNotes.value !== closingDetails.agentNotes
                ? formFields.agentNotes.value.trim()
                : undefined
              : "";

            payload["personalNotes"] = formFields.personalNotes.value?.trim()
              .length
              ? formFields.personalNotes.value.trim()
              : "";
            break;
          }
          case "orderDetails": {
            payload = {
              fileNumber: formFields.fileNumber.value.trim(),
              lender: formFields.lender.value
                ? formFields.lender.value.trim()
                : "",
              // clientId: formFields.clientId.value,
              // loanOfficer: formFields.loanOfficer.value
              //   ? formFields.loanOfficer.value.trim()
              //   : "",
              agentFee: formFields.agentFee.value
                ? formFields.agentFee.value.trim()
                : "",
              // client: {
              //   name: formFields.clientName.value.trim(),
              //   email: formFields.clientEmail.value.trim(),
              // },
              personOfContact: {
                name: formFields.personOfContactName.value.trim(),
                email:
                  formFields.personOfContactEmail.value.length > 0
                    ? formFields.personOfContactEmail.value.toString()
                    : [],
              },

              loanTypeOther: formFields.loanTypeOther.value,
            };

            if (formFields.loanType.value) {
              payload["loanType"] = formFields.loanType.value.map(
                (item) => item.value
              );
            }
            if (
              formFields.witnessCount.value &&
              formFields.witnessCount.value.trim().length
            ) {
              payload["witnessCount"] = Number(
                formFields.witnessCount.value.trim()
              );
            }
            if (
              formFields.clientId.value &&
              formFields.clientId.value.trim().length
            ) {
              clients.find((each) => {
                if (each._id === formFields.clientId.value) {
                  if (each.hasOwnProperty("_client") && each._client !== "") {
                    payload["clientId"] = each._client;
                  } else if (
                    each.hasOwnProperty("_signingCompany") &&
                    each._signingCompany !== ""
                  ) {
                    payload["signingCompanyId"] = each._signingCompany;
                  } else {
                    payload["assistantId"] = each._assistant;
                  }
                }
              });
            }

            break;
          }

          case "instructions": {
            payload = {
              instructions: formFields.instructions.value
                ? formFields.instructions.value.trim()
                : "",
            };
            break;
          }
          default:
        }
        if (payload && Object.keys(payload) && Object.keys(payload).length) {
          await this._updateClosingDetailsById(closingDetails.id, payload);
          await this._getClosingDetailsById(closingDetails.id);
          showToast("Closing Details Updated", "success");
          this._manageLoading("cardUpdateLoading", false);
          this._onToggleEditMode();
        } else {
          this._manageLoading("cardUpdateLoading", false);
          this._onToggleEditMode();
        }
      }
    } catch (error) {
      errorHandler(error);
      this._manageLoading("cardUpdateLoading", false);
    }
  };

  _onCancelEditMode = (event) => {
    const { editMode } = deepClone(this.state);
    switch (editMode) {
      case "borrower": {
        if (
          this.borrowerCardRef &&
          !this.borrowerCardRef.contains(event.target)
        ) {
          this._onToggleEditMode();
        }
        break;
      }
      case "orderDetails": {
        if (
          this.orderDetailsCardRef &&
          !this.orderDetailsCardRef.contains(event.target)
        ) {
          this._onToggleEditMode();
        }
        break;
      }
      case "appointment": {
        if (
          this.appointmentCardRef &&
          !this.appointmentCardRef.contains(event.target)
        ) {
          this._onToggleEditMode();
        }
        break;
      }
      case "instructions": {
        if (
          this.instructionsCardRef &&
          !this.instructionsCardRef.contains(event.target)
        ) {
          this._onToggleEditMode();
        }
        break;
      }
      default: {
      }
    }
  };

  _onToggleEditMode = (editMode = null) => {
    if (
      this.state.closingDetails &&
      !this.state.closingDetails?.isCreatedForAgent &&
      editMode &&
      editMode !== "appointment"
    ) {
      showToast("Server error. Try again after sometime.", "error");
      return;
    }
    if (!this.state.loading.cardUpdateLoading) {
      if (editMode) {
        this._setFormFields();
        document.addEventListener("mousedown", this._onCancelEditMode);
      } else {
        this._resetFormFields();
        document.removeEventListener("mousedown", this._onCancelEditMode);
      }
      this.setState({ editMode });
    }
  };

  _toggleActualMilesEdit = async () => {
    try {
      const { isActualMilesEdit, formFields, closingDetails } = this.state;

      if (isActualMilesEdit && closingDetails?._mileage?.id) {
        this._manageLoading("mileageUpdateLoading", true);
        let payload = {
          distance:
            formFields.miles.value?.trim() &&
              !isNaN(formFields.miles.value.trim())
              ? Number(formFields.miles.value.trim())
              : 0,
          closingId: closingDetails.id,
        };
        await agentUpdateClosingMileage(closingDetails._mileage.id, payload);
        await this._getClosingDetailsById(closingDetails.id);
        this._resetFormFields();
        showToast("Miles Updated", "success");
        this._manageLoading("mileageUpdateLoading", false);
      }

      if (!isActualMilesEdit) {
        this._setFormFields();
      }

      this.setState({
        isActualMilesEdit: !isActualMilesEdit,
      });
    } catch (error) {
      errorHandler(error);
      this._manageLoading("mileageUpdateLoading", false);
    }
  };

  _closingDashboard = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    let restLink = "";
    if (userData.type === "agent") {
      restLink = "dashboard";
    } else if (userData.type === "company") {
      restLink = "closing-dashboard";
    }
    this.props.history.push(`/${userData.type}/${restLink}`);
  };

  _handleActivityLogPagination = (pageNo = 1) => {
    const { activityLogPage } = deepClone(this.state);

    activityLogPage["skip"] = (pageNo - 1) * activityLogPage.limit;

    this.setState({ activityLogPage }, () => {
      this._activityLogClosing();
    });
  };

  _activityLog = () => {
    const { activityLog, closingDetails } = deepClone(this.state);

    return (
      activityLog?.length > 0 &&
      activityLog.map((item, key) => {
        return (
          <ListGroupItem
            key={key}
            style={{
              justifyContent: "flex-start",
              flexWrap: "wrap",
              fontWeight: 400,
              color: "#767676",
            }}
          >
            {item.activityType !== "ClosingAssigned"
              ? item.isDocumentCompleted
                ? null
                : item._user?.name?.full
              : "New Order"}{" "}
            {item.activityType === "Documents"
              ? item.isDocumentCompletedByAgent
                ? "Marked Documents"
                : item.isDocumentCompleted
                  ? "Documents upload completed"
                  : item.isDocumentVerified
                    ? "has Verified"
                    : "Uploaded"
              : item.activityType === "AppointmentStatus" ||
                item.activityType === "AppointmentDate" ||
                (item.activityType === "ClosingStatus" &&
                  item.to !== "Arrived") ||
                item.activityType === "LeftMessage"
                ? "Changed"
                : item.activityType === "ClosingStatus" && item.to === "Arrived"
                  ? "Arrived at the location for the appointment"
                  : item.activityType === "ClosingCreated"
                    ? "Created the Closing"
                    : "Updated"}{" "}
            {item.activityType === "Notes"
              ? item.notesType.replace(/([A-Z])/g, " $1").trim()
              : item.activityType === "Documents"
                ? item.isDocumentCompleted
                  ? "by"
                  : item.isDocumentCompletedByAgent
                    ? "as Scanback Upload Complete"
                    : item.isDocumentVerified
                      ? "Agent Document"
                      : "a New File"
                : item.activityType === "ClosingCreated"
                  ? ""
                  : item.activityType === "LeftMessage"
                    ? "Appointment Status"
                    : item.activityType === "ClosingAssigned" ||
                      (item.activityType === "ClosingStatus" && item.to === "Arrived")
                      ? null
                      : item.activityType.replace(/([A-Z])/g, " $1").trim()}{" "}
            {item.activityType === "Documents"
              ? item.documents.map((each) => (
                <Button color="link" className="showMap">
                  {each.title}
                </Button>
              ))
              : null}{" "}
            {item.activityType === "ClosingStatus" && item.to === "Arrived"
              ? null
              : item.to
                ? `To ${item.activityType === "AppointmentDate"
                  ? formatDateAsPerTimeZOne(
                    item.to,
                    closingDetails.closingAddress?.timeZone
                  )
                  : item.to
                }`
                : null}{" "}
            {item.activityType === "ClosingAssigned" ? (
              <>To {item._agent?.name?.full}</>
            ) : null}
            {item.activityType === "Documents" ? (
              <>{item._user?.name?.full}</>
            ) : null}
            {item.activityType === "LeftMessage" ? <>To Left Message </> : null}
            {item.text ? (
              item.activityType === "Notes" ? (
                <>
                  {" "}
                  To{" "}
                  <span
                    style={{
                      fontStyle: "italic",
                      marginLeft: 3,
                      marginRight: 5,
                    }}
                  >
                    "{item.text}"{" "}
                  </span>{" "}
                </>
              ) : null
            ) : null}{" "}
            | {formatDateHideYearIfSame(item.timeStamp)},{" "}
            {formatTime(item.timeStamp)}
          </ListGroupItem>
        );
      })
    );
  };

  _rowStyleFormat = (row, rowIdx) => {
    if (row?.isCreatedForAgent) {
      return { backgroundColor: "#eee" };
    } else if (row?._agent) {
      return { backgroundColor: "#cfe2ff" };
    }
    return null;
  };

  _onChipSelect = (chips, arrName = "chips") => {
    let isValid = chips.length
      ? this._isValidEmail(chips[chips.length - 1])
      : true;
    if (isValid) {
      this.setState({
        formFields: {
          ...this.state.formFields,
          personOfContactEmail: {
            ...this.state.formFields.personOfContactEmail,
            value: chips,
          },
        },
      });
    }
  };

  _isValidEmail = (value) => {
    if (RegexConfig.email.test(String(value).toLowerCase())) {
      return true;
    } else {
      showToast("Invalid Email", "error");

      return false;
    }
  };

  _onChangeFormFieldBorrower = (fieldName, value, subField, index) => {
    const { formFields } = deepClone(this.state);
    if (fieldName === "borrower") {
      // formFields[fieldName][index][subField].value = value;
      if (subField === "workPhone" || subField === "alternatePhone") {
        formFields[fieldName][index][subField].value = value.replace(/\D/g, "");
      } else {
        formFields[fieldName][index][subField].value = value;
      }
    }
    this.setState({ formFields });
  };

  _toggleEditOrderDetailsModal = (isOpen = false, data = null) => {
    this.setState({
      editOrderDetailsModal: {
        isOpen,
        data,
      },
    });
  };

  _isNumber = (e) => {
    var charCode = e.which ? e.which : e.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return e.preventDefault();
    return;
  };

  _renderAgentShare = (data) => {
    return (
      <>
        <span>
          {data?.agentFeeSharingAmount
            ? formatCurrencyValue(data?.agentFeeSharingAmount)
            : formatCurrencyValue(0)}
        </span>{" "}
        {/* <span id={`agentSharePopOver_${data?._id}`} className="signerName">
          <i
            className="fa fa-info-circle"
            style={{ fontSize: "14px", color: "blue", cursor: "pointer" }}
          />
        </span> */}
        {/* <UncontrolledPopover
          trigger="legacy"
          placement="bottom"
          target={`agentSharePopOver_${data?._id}`}
          className="signerPopover"
        >
          <PopoverBody>
            <Table className="responsive-sm">
              <thead>
                <tr>
                  <th>Agent Fee</th>
                  <th>Processing Fee</th>
                  <th>My Share</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    {data?.agentFee
                      ? formatCurrencyValue(data?.agentFee)
                      : formatCurrencyValue(0)}
                  </td>
                  <td>
                    {data?.agentFee && data?.agentFeeSharingAmount
                      ? formatCurrencyValue(
                          data?.agentFee - data?.agentFeeSharingAmount
                        )
                      : formatCurrencyValue(0)}
                  </td>
                  <td>
                    {data?.agentFeeSharingAmount
                      ? formatCurrencyValue(data?.agentFeeSharingAmount)
                      : formatCurrencyValue(0)}
                  </td>
                </tr>
              </tbody>
            </Table>
          </PopoverBody>
        </UncontrolledPopover> */}
      </>
    );

    // return str;
  };

  render() {
    const {
      closingDetails,
      loading,
      signerAvailabilityModal,
      signingStatusModal,
      documentViewModalData,
      editMode,
      formFields,
      closingExpenses,
      closingExpensesTotalAmount,
      clients,
      editOrderDetailsModal,
      seed,
      downloadInvoiceModal,
    } = this.state;

    console.log("closingDetails >>", closingDetails);

    return (
      <div id="scroll">
        <div className="content">
          {closingDetails && (
            <>
              <div className="responsiveTitle">
                <div className="d-flex align-items-center">
                  <Button
                    color="link"
                    className="backBtn"
                    onClick={() => this.props.history.push("/agent/dashboard")}
                  >
                    <img
                      src={require("../../../assets/img/arrowLeft.svg").default}
                      alt="backbutton"
                      height={14}
                    />
                  </Button>
                  <h2>
                    Order Details
                    <span>
                      Order No #{closingDetails.closingNumber || "N/A"}
                    </span>
                  </h2>
                </div>

                <div className="rightSide">
                  <Button
                    color="primary"
                    onClick={() =>
                      HeaderEventEmitter.dispatch("download-invoice")
                    }
                    disabled={
                      closingDetails?.status === "Pending" ||
                      closingDetails?.status === "Cancelled"
                    }
                  >
                    Invoice
                  </Button>
                  {this.props.userData.user.id ===
                    closingDetails?._createdBy?.id ? (
                    <Button
                      color="primary"
                      onClick={() =>
                        this.props.history.push(
                          `/agent/create-closing?${closingDetails.id}`
                        )
                      }
                    >
                      Clone
                    </Button>
                  ) : null}
                </div>
              </div>

              <div className="CardWrapper">
                <div className="CardTitle">
                  <h2 className="sectionTtile">
                    {" "}
                    Appointment{" "}
                    {closingDetails?.status !== "Closed" &&
                      closingDetails?.status !== "DNC" ? (
                      // only open if closing is create by Agent
                      closingDetails?._createdBy?._id ===
                        this.props.userData.user.id ||
                        // only open if closing is created for Agent
                        closingDetails?.isCreatedForAgent ? (
                        <>
                          <Button
                            color="link"
                            onClick={() =>
                              this._toggleEditOrderDetailsModal(
                                true,
                                closingDetails
                              )
                            }
                          >
                            <img
                              src={
                                require("../../../assets/img/pencil.svg")
                                  .default
                              }
                              alt="edit"
                            />
                          </Button>
                        </>
                      ) : null
                    ) : null}
                  </h2>
                </div>
              </div>

              <div className="CustomTableWrapper withAccordian">
                <Table striped responsive className="hideMobile">
                  <thead>
                    <tr>
                      <th>Signer</th>
                      <th>File#</th>
                      <th>Product Type</th>
                      <th>Signing Type</th>
                      <th>
                        {closingDetails?.isAgentWebsiteClosing &&
                          closingDetails?.isCreatedForAgent
                          ? closingDetails?._mainForwardAgent
                            ? "Agent Share" // agentFeeSharingAmount
                            : "My Share" // add i btn -> show agentFeeSharingAmount, agentFee - agentFeeSharingAmount
                          : "Agent Fee"}
                      </th>
                      <th>Location</th>
                      {/* <th>Order on behalf of</th> */}
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <>
                          <div className="tableUserInfo">
                            {/* <div className="userImage">
                              <img
                                className="clientImg"
                                src={
                                  closingDetails?._signingCompany?.logo
                                    ? closingDetails._signingCompany.logo
                                    : closingDetails?._client?.logo
                                    ? closingDetails?._client?.logo
                                    : require("../../../assets/img/default_user_icon.svg")
                                        .default
                                }
                                loading="lazy"
                                alt="avatar"
                              />
                            </div> */}
                            <div className="userContent">
                              <span
                                id={`borrower_table_agent_closing_details_${closingDetails?._id}`}
                                className="signerName"
                              >
                                {closingDetails?._borrower[0]?.name?.full
                                  ? closingDetails?._borrower[0]?.name?.full
                                  : "N/A"}
                                {closingDetails?._borrower?.length > 1
                                  ? `+${closingDetails?._borrower?.length - 1}`
                                  : null}
                              </span>
                              {closingDetails?._borrower?.length ? (
                                <>
                                  <UncontrolledPopover
                                    trigger="legacy"
                                    placement="bottom"
                                    target={`borrower_table_agent_closing_details_${closingDetails?._id}`}
                                    className="signerPopover"
                                  >
                                    <PopoverHeader>Signers</PopoverHeader>
                                    <PopoverBody>
                                      <Table>
                                        <thead>
                                          <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Language</th>
                                            <th>Time Zone</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {closingDetails?._borrower?.map(
                                            (each) => (
                                              <tr key={each._id}>
                                                <td>
                                                  {each.name?.full || "N/A"}
                                                </td>
                                                <td>{each.email || "N/A"}</td>
                                                <td>
                                                  {each?.phone?.home ||
                                                    each?.phone?.work ||
                                                    "N/A"}
                                                </td>
                                                <td>
                                                  {each.language || "N/A"}
                                                </td>
                                                <td>
                                                  {each.timeZone || "N/A"}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </Table>
                                    </PopoverBody>
                                  </UncontrolledPopover>
                                </>
                              ) : null}
                              {/* <span className="dateTimeFormat">
                                {formatDateAsPerTimeZOne(
                                  closingDetails?.appointmentDate,
                                  closingDetails?.closingAddress?.timeZone
                                )}
                                ,
                                <span>
                                  {getTimeZoneAbbr(
                                    closingDetails?.appointmentDate,
                                    closingDetails?.closingAddress?.timeZone
                                  )}
                                </span>
                              </span>{" "} */}
                              <DateTimeShowWithClosingDetails
                                closingDetails={closingDetails}
                              />
                            </div>
                          </div>
                        </>
                      </td>
                      <td>
                        <div style={{ width: 90 }}>
                          {closingDetails?.fileNumber || "N/A"}
                        </div>
                      </td>
                      <td>
                        <div style={{ width: 110 }}>
                          <span
                            style={{
                              marginRight: 6,
                            }}
                          >
                            {closingDetails?.loanType &&
                              closingDetails?.loanType?.length > 0
                              ? closingDetails?.loanType?.indexOf("Other") > -1
                                ? closingDetails?.loanTypeOther
                                : null
                              : null}

                            {closingDetails?.loanType
                              ?.filter((each) => each !== "Other")
                              ?.map((item) => (
                                <>
                                  {item?.replace(/([a-z])([A-Z])/g, "$1 $2")},{" "}
                                </>
                              ))}
                          </span>

                          {/* {closingDetails?.length ? (
                          closingDetails.indexOf("Other") > -1 ? (
                            <span
                              style={{
                                marginRight: 6,
                              }}
                            >
                              {closingDetails?.loanTypeOther}
                            </span>
                          ) : null
                        ) : null}

                        {React.Children.toArray(
                          closingDetails?.loanType
                            ?.filter((each) => each !== "Other")
                            .map((item) => (
                              <span
                                key={item._id}
                                style={{
                                  marginRight: 6,
                                }}
                              >
                                {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                              </span>
                            ))
                        )} */}
                        </div>
                      </td>
                      <td>
                        <div style={{ width: 110 }}>
                          <span
                            style={{
                              marginRight: 6,
                            }}
                          >
                            {closingDetails?.signingType}
                          </span>

                          {/* {closingDetails?.length ? (
                            closingDetails.indexOf("Other") > -1 ? (
                              <span
                                style={{
                                  marginRight: 6,
                                }}
                              >
                                {closingDetails?.loanTypeOther}
                              </span>
                            ) : null
                          ) : null}

                          {React.Children.toArray(
                            closingDetails?.loanType
                              ?.filter((each) => each !== "Other")
                              .map((item) => (
                                <span
                                  key={item._id}
                                  style={{
                                    marginRight: 6,
                                  }}
                                >
                                  {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                                </span>
                              ))
                          )} */}
                        </div>
                      </td>
                      <td>
                        <span style={{ width: 60 }}>
                          {closingDetails?.isAgentWebsiteClosing &&
                            closingDetails?.isCreatedForAgent
                            ? closingDetails?._mainForwardAgent
                              ? closingDetails?.agentFeeSharingAmount
                                ? formatCurrencyValue(
                                  closingDetails?.agentFeeSharingAmount
                                )
                                : formatCurrencyValue(0) // agentFeeSharingAmount
                              : this._renderAgentShare(closingDetails) // add i btn -> show agentFeeSharingAmount, agentFee - agentFeeSharingAmount
                            : closingDetails?.agentFee
                              ? formatCurrencyValue(closingDetails?.agentFee)
                              : formatCurrencyValue(0)}
                        </span>
                      </td>
                      <td>
                        <div
                          style={{
                            width: 150,
                          }}
                        >
                          {formatAddressInSingleText(
                            closingDetails?.closingAddress
                          ) || "N/A"}
                        </div>
                        <Button
                          color="link"
                          className="p-0"
                          title={formatAddressInSingleText(
                            closingDetails?.closingAddress
                          )}
                          onClick={() =>
                            openGoogleMapOnNewTab(
                              closingDetails?.closingAddress
                            )
                          }
                        >
                          <img
                            src={
                              require("../../../assets/img/map-marker.svg")
                                .default
                            }
                            alt="map icon"
                            className="mr-1"
                          />
                          Map
                        </Button>
                      </td>
                      {/* <td>
                        <div style={{ width: 100 }}>
                          {closingDetails.lender
                            ? capitalize(closingDetails.lender)
                            : "N/A"}{" "}
                        </div>{" "}
                      </td> */}
                      <td>
                        <div
                          className="customSelectWrap"
                          style={{ width: 118 }}
                        >
                          <Input
                            type="select"
                            name="status"
                            className={`status ${getDropdownColor(
                              closingDetails.status
                            )}`}
                            disabled={
                              loading.statusChangeLoading ? true : false
                            }
                            value={closingDetails.status}
                            onChange={(event) =>
                              this._updateClosingDetailsByIdStatus(
                                closingDetails,
                                {
                                  status: event.target.value,
                                }
                              )
                            }
                          >
                            <option key="CCA" value="CCA">
                              Can't Complete
                            </option>
                            <option
                              key="Pending"
                              value="Pending"
                              // disabled={
                              //   closingDetails._signingCompany !== undefined
                              // }
                              disabled={!closingDetails.isCreatedForAgent}
                            >
                              Pending
                            </option>
                            <option
                              key="Closed"
                              value="Closed"
                              disabled={
                                agentTodayOrFutureDate(closingDetails) &&
                                closingDetails?.status !== "Arrived"
                              }
                            >
                              Closed
                            </option>
                            <option
                              key="Cancelled"
                              value="Cancelled"
                              // disabled={
                              //   closingDetails._signingCompany !== undefined
                              // }
                              disabled={!closingDetails.isCreatedForAgent}
                            >
                              Cancelled
                            </option>
                            <option
                              key="DNC"
                              value="DNC"
                              disabled={
                                agentTodayOrFutureDate(closingDetails) &&
                                closingDetails?.status !== "Arrived"
                              }
                            >
                              Did not close
                            </option>
                            <option
                              key="Arrived"
                              value="Arrived"
                              disabled={
                                this._isDisabledCheckStatusDropdown(
                                  closingDetails
                                ) || closingDetails.status === "Closed"
                              }
                            >
                              Arrived
                            </option>
                          </Input>
                          <div className="downArrow">
                            <i className="fa fa-chevron-down"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <Card className="responsiveTableCard hideDesktop">
                  <CardHeader>
                    <div className="tableUserInfo">
                      {/* <div className="userImage">
                        <img
                          className="clientImg"
                          src={
                            closingDetails?._signingCompany?.logo
                              ? closingDetails._signingCompany.logo
                              : closingDetails?._client?.logo
                              ? closingDetails?._client?.logo
                              : require("../../../assets/img/default_user_icon.svg")
                                  .default
                          }
                          loading="lazy"
                          alt="avatar"
                        />
                      </div> */}
                      <div className="userContent">
                        <span
                          id="borrower_table_agent_dashboard_test"
                          className="signerName"
                        >
                          {closingDetails?._borrower[0]?.name?.full
                            ? closingDetails?._borrower[0]?.name?.full
                            : "N/A"}
                          {closingDetails?._borrower?.length > 1
                            ? `+${closingDetails?._borrower?.length - 1}`
                            : null}
                        </span>

                        {closingDetails?._borrower?.length ? (
                          <>
                            <UncontrolledPopover
                              trigger="legacy"
                              placement="bottom"
                              target={`borrower_table_agent_dashboard_test`}
                              className="signerPopover"
                            >
                              <PopoverHeader>Signers</PopoverHeader>
                              <PopoverBody>
                                {closingDetails?._borrower?.map((each) => (
                                  <ul key={each._id} className="dataList">
                                    <li>
                                      <Label>Name</Label>
                                      <div>{each.name?.full || "N/A"}</div>
                                    </li>
                                    <li>
                                      <Label>Email</Label>
                                      <div>{each.email || "N/A"}</div>
                                    </li>
                                    <li>
                                      <Label>Phone</Label>
                                      <div>
                                        {each?.phone?.home ||
                                          each?.phone?.work ||
                                          "N/A"}
                                        {/* {each?.phone?.home
                                          ? each?.phone?.home
                                          : each?.phone?.work || "N/A"} */}
                                      </div>
                                    </li>
                                    <li>
                                      <Label>Language</Label>
                                      <div>{each.language || "N/A"}</div>
                                    </li>
                                    <li>
                                      <Label>Time Zone</Label>
                                      {each.timeZone || "N/A"}
                                    </li>
                                  </ul>
                                ))}
                              </PopoverBody>
                            </UncontrolledPopover>
                          </>
                        ) : null}

                        {/* <span className="dateTimeFormat">
                          {formatDateAsPerTimeZOne(
                            closingDetails?.appointmentDate,
                            closingDetails?.closingAddress?.timeZone
                          )}
                          ,
                          <span>
                            {getTimeZoneAbbr(
                              closingDetails?.appointmentDate,
                              closingDetails?.closingAddress?.timeZone
                            )}
                          </span>
                        </span> */}
                        <DateTimeShowWithClosingDetails
                          closingDetails={closingDetails}
                        />
                      </div>
                    </div>
                  </CardHeader>
                  <ul className="cardInfo">
                    <li>
                      <div className="dataWrap">
                        <Label>File#: </Label>
                        <div style={{ fontWeight: 600 }}>
                          {closingDetails?.fileNumber || "N/A"}
                        </div>
                      </div>
                      <div className="dataWrap">
                        <Label>
                          {closingDetails?.isAgentWebsiteClosing &&
                            closingDetails?.isCreatedForAgent
                            ? closingDetails?._mainForwardAgent
                              ? "Agent Share" // agentFeeSharingAmount
                              : "My Share" // add i btn -> show agentFeeSharingAmount, agentFee - agentFeeSharingAmount
                            : "Fee: "}{" "}
                        </Label>
                        <div style={{ fontWeight: 600 }}>
                          {/* {closingDetails?.agentFee
                            ? formatCurrencyValue(closingDetails?.agentFee)
                            : formatCurrencyValue(0)} */}
                          {closingDetails?.isAgentWebsiteClosing &&
                            closingDetails?.isCreatedForAgent
                            ? closingDetails?._mainForwardAgent
                              ? closingDetails?.agentFeeSharingAmount
                                ? formatCurrencyValue(
                                  closingDetails?.agentFeeSharingAmount
                                )
                                : formatCurrencyValue(0) // agentFeeSharingAmount
                              : this._renderAgentShare(closingDetails) // add i btn -> show agentFeeSharingAmount, agentFee - agentFeeSharingAmount
                            : closingDetails?.agentFee
                              ? formatCurrencyValue(closingDetails?.agentFee)
                              : formatCurrencyValue(0)}
                        </div>
                      </div>
                      {/* <div className="dataWrap">
                        <Label>Order On Behalf Of: </Label>
                        <div style={{ fontWeight: 600 }}>
                          {" "}
                          {closingDetails.lender
                            ? capitalize(closingDetails.lender)
                            : "N/A"}{" "}
                        </div>
                      </div> */}
                    </li>
                    <li>
                      <div className="dataWrap">
                        <Label>Product Type: </Label>
                        <div style={{ fontWeight: 600 }}>
                          <span
                            style={{
                              marginRight: 6,
                            }}
                          >
                            {closingDetails?.loanType &&
                              closingDetails?.loanType?.length > 0
                              ? closingDetails?.loanType?.indexOf("Other") > -1
                                ? closingDetails?.loanTypeOther
                                : null
                              : null}
                            <br />
                            {closingDetails?.loanType
                              ?.filter((each) => each !== "Other")
                              ?.map((item) => (
                                <>
                                  {item?.replace(/([a-z])([A-Z])/g, "$1 $2") +
                                    ", "}
                                  <br />
                                </>
                              ))}
                          </span>

                          {/* {closingDetails?.length ? (
                          closingDetails.indexOf("Other") > -1 ? (
                            <span
                              style={{
                                marginRight: 6,
                              }}
                            >
                              {closingDetails?.loanTypeOther}
                            </span>
                          ) : null
                        ) : null}

                        {React.Children.toArray(
                          closingDetails?.loanType
                            ?.filter((each) => each !== "Other")
                            .map((item) => (
                              <span
                                key={item._id}
                                style={{
                                  marginRight: 6,
                                }}
                              >
                                {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                              </span>
                            ))
                        )} */}
                        </div>
                      </div>

                      <div className="dataWrap">
                        <Label>Signing Type: </Label>

                        <div style={{ fontWeight: 600 }}>
                          <span
                            style={{
                              marginRight: 6,
                            }}
                          >
                            {closingDetails?.signingType}
                          </span>

                          {/* {closingDetails?.length ? (
                            closingDetails?.indexOf("Other") > -1 ? (
                              <span
                                style={{
                                  marginRight: 6,
                                }}
                              >
                                {closingDetails?.loanTypeOther}
                              </span>
                            ) : null
                          ) : null}

                          {React.Children.toArray(
                            closingDetails?.loanType
                              ?.filter((each) => each !== "Other")
                              .map((item) => (
                                <span
                                  key={item._id}
                                  style={{
                                    marginRight: 6,
                                  }}
                                >
                                  {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                                </span>
                              ))
                          )} */}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dataWrap">
                        <Label>Location </Label>
                        <div className="d-flex justify-content-between">
                          <div
                            style={{
                              flex: 1,
                              marginRight: 10,
                              fontWeight: 600,
                            }}
                          >
                            {/* 248 MARINA SHORES COURT SUMMERVILLE SC 29486 */}
                            {formatAddressInSingleText(
                              closingDetails.closingAddress
                            )}
                          </div>
                          <div className="d-flex">
                            <Button
                              color="link"
                              className="p-0"
                              // title={formatAddressInSingleText(row.closingAddress)}
                              onClick={() =>
                                openGoogleMapOnNewTab(
                                  closingDetails?.closingAddress
                                )
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/Google_Maps_Logo_2020.svg")
                                    .default
                                }
                                alt="map icon"
                                className="mr-3"
                              />
                            </Button>
                            <Button
                              color="link"
                              className="p-0"
                              // title={formatAddressInSingleText(row.closingAddress)}
                              onClick={() =>
                                openAppleMapOnNewTab(
                                  closingDetails?.closingAddress
                                )
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/Apple_Maps_(WatchOS).svg")
                                    .default
                                }
                                alt="map icon"
                              />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dataWrap d-flex align-items-center justify-content-between">
                        <Label className="mb-0">Status </Label>
                        <div className="customSelectWrap">
                          <Input
                            type="select"
                            name="status"
                            className={`status ${getDropdownColor("pending")}`}
                            // value={row.status}
                            // disabled={this.state.loading.statusChangeLoading ? true : false}
                            // onChange={(event) =>
                            //   this._updateClosingDetailsByIdStatus(row, event.target.value)
                            // }
                            disabled={
                              loading.statusChangeLoading ? true : false
                            }
                            value={closingDetails.status}
                            onChange={(event) =>
                              this._updateClosingDetailsByIdStatus(
                                closingDetails,
                                {
                                  status: event.target.value,
                                }
                              )
                            }
                          >
                            <option key="CCA" value="CCA">
                              Can't Complete
                            </option>
                            <option
                              selected
                              key="Pending"
                              value="Pending"
                              // disabled={row._signingCompany !== undefined}
                              // disabled={!row.isCreatedForAgent}
                              disabled={!closingDetails.isCreatedForAgent}
                            >
                              Pending
                            </option>
                            <option
                              key="Closed"
                              value="Closed"
                              // disabled={
                              //   agentTodayOrFutureDate(row) && row.status !== "Arrived"
                              // }
                              disabled={
                                agentTodayOrFutureDate(closingDetails) &&
                                closingDetails?.status !== "Arrived"
                              }
                            >
                              Closed
                            </option>
                            <option
                              key="Cancelled"
                              value="Cancelled"
                              // disabled={!row.isCreatedForAgent}
                              disabled={!closingDetails.isCreatedForAgent}
                            >
                              Cancelled
                            </option>
                            <option
                              key="DNC"
                              value="DNC"
                              // disabled={
                              //   agentTodayOrFutureDate(row) && row.status !== "Arrived"
                              // }
                              disabled={
                                agentTodayOrFutureDate(closingDetails) &&
                                closingDetails?.status !== "Arrived"
                              }
                            >
                              Did not close
                            </option>
                            <option
                              key="Arrived"
                              value="Arrived"
                              // disabled={
                              //   this._isDisabledCheckStatusDropdown(row) ||
                              //   row.status === "Closed"
                              // }
                              disabled={
                                this._isDisabledCheckStatusDropdown(
                                  closingDetails
                                ) || closingDetails.status === "Closed"
                              }
                            >
                              Arrived
                            </option>
                          </Input>
                          <div className="downArrow">
                            <i className="fa fa-chevron-down"></i>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Card>
                <Collapse
                  className="accordianCollapse"
                  isOpen={this.state.isOpenCollapse}
                >
                  <ul className="clientDetails">
                    <li>
                      <div className="userInfo">
                        <div className="userImage">
                          <img
                            // src={require("../../../assets/img/multi.png").default}
                            src={
                              closingDetails?._signingCompany?.logo
                                ? closingDetails._signingCompany.logo
                                : closingDetails?._client?.logo
                                  ? closingDetails?._client?.logo
                                  : require("../../../assets/img/default_user_icon.svg")
                                    .default
                            }
                            alt="logo"
                          />
                        </div>
                        <div className="userContent">
                          <span>Client</span>
                          <h5>
                            {closingDetails?._signingCompany?.companyName ||
                              closingDetails?._client?.name?.full ||
                              closingDetails?._assistant?.name?.full ||
                              "N/A"}
                          </h5>
                        </div>
                      </div>
                    </li>
                    <li>
                      <span>POC Name</span>
                      <h5>
                        {closingDetails?.isAgentWebsiteClosing
                          ? closingDetails?._client?.name?.full
                            ? capitalize(closingDetails?._client?.name?.full)
                            : "N/A"
                          : closingDetails?.personOfContact?.name
                            ? capitalize(closingDetails?.personOfContact?.name)
                            : "N/A"}
                      </h5>
                    </li>
                    <li>
                      <span>POC Email</span>
                      <h5>
                        {closingDetails?.isAgentWebsiteClosing
                          ? closingDetails?._client?.email
                            ? closingDetails?._client?.email
                            : "N/A"
                          : closingDetails?.personOfContact?.email.length > 0 &&
                            closingDetails?.personOfContact?.email[0] !== ""
                            ? closingDetails?.personOfContact?.email?.join(", ")
                            : "N/A"}
                      </h5>
                    </li>
                    <li>
                      <span>Signing Type</span>{" "}
                      <h5>{closingDetails?.signingType || "N/A"}</h5>
                    </li>
                    <li>
                      <span>Witness Number</span>
                      <h5>{closingDetails?.witnessCount || "N/A"}</h5>
                    </li>
                    {closingDetails?.signingType === "RON" ? (
                      <li>
                        <span>RON Platform Types</span>{" "}
                        <h5>
                          {closingDetails?.ronPlatformTypes?.length
                            ? closingDetails?.ronPlatformTypes?.map(
                              (each) => each + ", "
                            )
                            : "N/A"}
                        </h5>
                      </li>
                    ) : null}
                  </ul>
                  <ul className="questionPoint">
                    {closingDetails?.questions.length > 0 &&
                      closingDetails?.questions.map((question) => (
                        <li className={question.reply ? "yes" : "No"}>
                          {question.query}
                          <div className="answer">
                            {" "}
                            {question.reply ? (
                              <img
                                src={
                                  require("../../../assets/img/checkIcon.svg")
                                    .default
                                }
                                alt="yes check"
                              />
                            ) : (
                              <img
                                src={
                                  require("../../../assets/img/cross.png")
                                    .default
                                }
                                alt="No cross"
                              />
                            )}{" "}
                            {question.reply ? "Yes" : "No"}
                          </div>
                        </li>
                      ))}
                  </ul>
                </Collapse>

                <div className="text-center">
                  <Button
                    color="link"
                    className={`accordianButton ${this.state.isOpenCollapse ? "" : "show"
                      }`}
                    onClick={() =>
                      this.setState({
                        isOpenCollapse: !this.state.isOpenCollapse,
                      })
                    }
                  >
                    {this.state.isOpenCollapse ? "Hide" : "Show"} Other Details
                    <img
                      src={
                        require("../../../assets/img/arrow-blue.svg").default
                      }
                      alt="details"
                    />
                  </Button>
                </div>
              </div>

              <Row>
                <Col md={6} className="mb-4 mb-lg-0"></Col>
              </Row>

              <Row className="mt-4 mb-5">
                <Col xl={6} lg={6}>
                  <div className="CardWrapper h-100">
                    <div className="CardTitle">
                      <h2 className="sectionTtile"> Expense Details </h2>
                    </div>
                    <Card className="h-100 mb-0">
                      <MilageExpanceTabs
                        key={seed}
                        closingDetails={closingDetails}
                        closingExpenses={closingExpenses}
                        closingExpensesTotalAmount={closingExpensesTotalAmount}
                        feesOffered={formFields?.agentFee?.value}
                        addAgentExpenses={this._addAgentExpenses}
                        getAllAgentExpensesForClosing={
                          this._getAllAgentExpensesForClosing
                        }
                        getClosingDetailsById={this._getClosingDetailsById}
                      />
                    </Card>
                  </div>
                </Col>
                <Col xl={6} lg={6} className="mb-5 mb-lg-0">
                  <div className="CardWrapper h-100 mt-5 mt-lg-0">
                    <div className="CardTitle">
                      <h2 className="sectionTtile">
                        Secure Documents{" "}
                        {/* {loading ? <Spinner size="sm" /> : null}{" "} */}
                      </h2>
                    </div>
                    <SecureDocumentCardComponent
                      closingDetails={closingDetails}
                      updateClosingState={(closingDetails) =>
                        this._updateClosingState(closingDetails)
                      }
                    />
                  </div>
                </Col>
              </Row>

              {/* chat log */}
              <ChatLogCardComponent
                ref={this.child}
                closingDetails={closingDetails}
              />

              {/* instructions */}
              <div className="CardWrapper">
                <div className="CardTitle">
                  <h2 className="sectionTtile">
                    Instructions
                    {closingDetails.isCreatedForAgent ? (
                      <div>
                        {editMode === "instructions" ? (
                          <>
                            <Button
                              color="link"
                              disabled={loading.cardUpdateLoading}
                              onClick={() =>
                                this._onSaveClosing("instructions")
                              }
                            >
                              {loading.cardUpdateLoading ? (
                                <i className="fa fa-spinner fa-spin" />
                              ) : null}{" "}
                              <img
                                src={
                                  require("../../../assets/img/checkBlueIcon.svg")
                                    .default
                                }
                                alt="save"
                              />
                            </Button>
                            <Button
                              color="link"
                              onClick={() => this._onToggleEditMode()}
                            >
                              <img
                                src={
                                  require("../../../assets/img/close.svg")
                                    .default
                                }
                                height={14}
                                alt="save"
                              />
                            </Button>
                          </>
                        ) : (
                          <Button
                            color="link"
                            onClick={() =>
                              this._onToggleEditMode("instructions")
                            }
                          >
                            <img
                              src={
                                require("../../../assets/img/pencil.svg")
                                  .default
                              }
                              alt="pencil"
                            />
                          </Button>
                        )}
                      </div>
                    ) : null}
                  </h2>
                </div>
                <Card
                  ref={(ref) => (this.instructionsCardRef = ref)}
                  className=""
                >
                  <CardBody>
                    <CustomInput
                      type="checkbox"
                      id="isScanBackNeeded"
                      label="Scan Backs Needed"
                      checked={closingDetails.isScanBackNeeded}
                      disabled={true}
                      className="mb-2"
                    />
                    {editMode === "instructions" ? (
                      <>
                        <TextEditor
                          disabled
                          content={
                            // closingDetails.instructions
                            //   ? closingDetails.instructions
                            //   :
                            formFields.instructions.value
                          }
                          onChange={(event) =>
                            this._onChangeFormField("instructions", event)
                          }
                        ></TextEditor>
                        {formFields.instructions.error && (
                          <div className="validation-error">
                            {formFields.instructions.error}
                          </div>
                        )}
                      </>
                    ) : closingDetails.instructions ? (
                      <TextEditor
                        enabled={true}
                        content={closingDetails.instructions}
                        onChange={(event) =>
                          this._onChangeFormField("instructions", event)
                        }
                      ></TextEditor>
                    ) : (
                      // "N/A"
                      <div className="nodata">
                        <img
                          src={
                            require("../../../assets/img/noData.svg").default
                          }
                          alt="no data"
                        />
                        <p>There is no data to display</p>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </div>

              {/* paymentInstructions */}
              <div className="CardWrapper">
                <div className="CardTitle">
                  <h2 className="sectionTtile">Payment Instructions</h2>
                </div>
                <Card className="">
                  <CardBody>
                    {closingDetails?.paymentInstructions ? (
                      <TextEditor
                        enabled={true}
                        content={closingDetails?.paymentInstructions}
                        onChange={
                          (event) => {}
                        }
                      ></TextEditor>
                    ) : (
                      // "N/A"
                      <div className="nodata">
                        <img
                          src={
                            require("../../../assets/img/noData.svg").default
                          }
                          alt="no data"
                        />
                        <p>There is no data to display</p>
                      </div>
                    )}
                  </CardBody>
                </Card>
              </div>
            </>
          )}

          <DocumentViewModal
            isOpen={documentViewModalData.isOpen}
            externalUrl={documentViewModalData.externalUrl}
            fileType={documentViewModalData.fileType}
            modalHeading={documentViewModalData.modalHeading}
            toggle={this._onToggleDocumentViewModal}
          />

          {signingStatusModal.isOpen ? (
            <SigningStatusModal
              isOpen={signingStatusModal.isOpen}
              data={signingStatusModal.data}
              status={signingStatusModal.status}
              updateClosingDetailsById={this._updateClosingDetailsById}
              resetDetails={this._getClosingDetailsById}
              toggle={this._onToggleSigningStatusModal}
            />
          ) : null}

          {/* <SignerAvailabilityModal
            isOpen={signerAvailabilityModal.isOpen}
            data={signerAvailabilityModal.data}
            updateClosingDetailsById={this._updateClosingDetailsById}
            resetDetails={this._getClosingDetailsById}
            toggle={this._onToggleSignerAvailabilityModal}
            refeshChatLog={() =>
              this.child.current._getAllComments({
                closingId: closingDetails._id,
              })
            }
          /> */}

          <EditOrderDetailsModal
            isOpen={editOrderDetailsModal.isOpen}
            data={editOrderDetailsModal.data}
            toggle={() => this._toggleEditOrderDetailsModal()}
            updateClosingDetailsById={this._updateClosingDetailsById}
            resetDetails={this._getClosingDetailsById}
            clients={clients}
            loanType={loanTypeConfig}
            forAgent={true}
          />

          <DownloadInvoiceModal
            isOpen={downloadInvoiceModal?.isOpen}
            data={downloadInvoiceModal?.data}
            toggle={this._onToggleDownloadInvoiceModal}
            type="agent"
            onSuccess={this._getClosingDetailsById}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(ClosingDetails);
