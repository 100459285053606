/* eslint-disable no-duplicate-case */
import React from "react";
import {
  Button,
  Input,
  Label,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Badge,
  FormGroup,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import AddClientAgentModal from "../components/Modals/add-client-modal-agent";
import {
  deleteAgentClientById,
  getAgentClientTagsList,
  getAllAgentClients,
  updateAgentClient,
} from "../../../http/http-calls";
import {
  deepClone,
  formatPhoneNumber,
  showToast,
  checkPermission,
  getDropdownColor,
  formatAddressInSingleText,
  errorHandler,
  refreshFunc,
  isProPlusUser,
} from "../../../helper-methods";
import { capitalize } from "@material-ui/core";
import CustomTable from "../components/CustomTable";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomCard from "../components/CustomCard";
import { Link } from "react-router-dom";
import CreatableSelect from "react-select";
import AgentBulkActivityModal from "../components/agent-bulk-activity-modal";
// import UpgradeAccountModal from "../components/Modals/upgradeAccountModal";
import Swal from "sweetalert2";
import UpgradeProfessionalAccountModal from "../components/Modals/upgradeProfessionalAccountModal";
class Clients extends React.Component {
  state = {
    clientsList: [],
    tagsList: [],
    clientsTotalCount: 0,
    clientsHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      { id: "name", label: "Name", noSort: true },
      { id: "email", label: "Email", noSort: true },
      { id: "clientId", label: "Client Id", noSort: true },
      { id: "phone", label: "Phone", noSort: true },
      { id: "address", label: "Location", noSort: true },
      { id: "tags", label: "Tags", noSort: true },
      { id: "pointOfContact", label: "Point Of Contact", noSort: true },
      { id: "isActive", label: "Status", noSort: true },
      {
        id: "action",
        label: "Action",
        noSort: true,
        // style: { width: 150, textAlign: "center" },
      },
    ],
    clientsCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    addClientModalData: {
      isOpen: false,
      data: null,
    },
    bulkActivityModal: {
      isOpen: false,
      data: null,
      type: "",
    },
    loading: {
      statusChangeLoading: false,
      showTableLoading: false,
      clientDeleteLoading: false,
    },
    filters: {
      isActive: "",
      tags: [],
      search: "",
    },
    clientsSelectedRows: [],
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
      sort: {
        sortBy: "createdAt",
        sortOrder: "desc",
      },
    },
    isClassAdded: false,
    upgradeProAccountModal: {
      isOpen: false,
      data: null,
    },
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _setSelectedRows = (clientsSelectedRows = []) => {
    // console.log("clientsSelectedRows: ", clientsSelectedRows);
    this.setState({
      clientsSelectedRows,
    });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  _toggleAddClientModal = (isOpen = false, data = null) => {
    this.setState({
      addClientModalData: {
        isOpen,
        data,
      },
    });
  };

  _toggleBulkActivityModal = (isOpen = false, type = "", data = null) => {
    const { clientsSelectedRows } = deepClone(this.state);

    if (!clientsSelectedRows?.length) {
      errorHandler({ reason: "Please select at least one client" });
      return;
    }

    this.setState({
      bulkActivityModal: {
        isOpen,
        type,
        data,
      },
    });
  };

  _filterOnChange = (type, value) => {
    // console.log(type, value);
    const { filters } = this.state;

    filters[type] = value;
    this.setState({ filters }, () => {
      console.log("filters: ", this.state.filters);
      this._paginate();
    });
  };

  _searchOnChange = (searchTerm) => {
    // console.log({ searchTerm });
    clearTimeout(this.searchTimer);

    const { filters } = this.state;
    filters["search"] = searchTerm;

    this.setState({ filters }, () => {
      console.log({ filters });
    });

    this.searchTimer = setTimeout(() => {
      this._paginate();
    }, 1000);
  };

  _resetTable = () => {
    this._getAllAgentClients();
    this._getAllTagsList();
  };
  _getAllAgentClients = () => {
    this._manageLoading("showTableLoading", true);

    const { tableConfig, filters } = this.state;

    let data = {
      skip: tableConfig.skip,
      limit: tableConfig.limit,

      // isActive: filters?.isActive,
      // tags: filters?.tags?.length
      //   ? JSON.stringify([...filters?.tags?.map((each) => each?.value)])
      //   : [],
    };

    if (String(filters?.isActive)?.length) {
      data["isActive"] = filters?.isActive;
    }
    if (filters?.tags?.length) {
      data["tags"] = JSON.stringify([
        ...filters?.tags?.map((each) => each?.value),
      ]);
    }
    if (filters?.search?.length) {
      data["search"] = filters?.search || "";
    }

    // let data = {
    //   skip: tableConfig.skip,
    //   limit: tableConfig.limit,
    // };
    getAllAgentClients(data)
      .then((res) => {
        this._manageLoading("showTableLoading", false);
        this.setState({
          clientsList: res?.clients || [],
          clientsTotalCount: res?.totalCount || 0,
        });
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _getAllTagsList = async () => {
    try {
      const res = await getAgentClientTagsList();
      console.log("res ", res);

      const tagsListOptions =
        res?.tags?.map((each) => ({
          label: each,
          value: each,
        })) || [];

      this.setState({ tagsList: tagsListOptions });
    } catch (err) {
      errorHandler(err);
    }
  };

  componentDidMount = () => {
    // this._getAllAgentClients();
    this._setFilterDataFromLocalStorage();
    HeaderEventEmitter.subscribe("add-client", () => {
      console.log("add client");
      this._toggleAddClientModal(true);
    });
    HeaderEventEmitter.subscribe("reset-filter", () => {
      console.log("filter reset hit");
      this._resetFilter();
      refreshFunc("agent-clients");
      this._getAllAgentClients();
    });

    this.searchTimer = null;

    this._getAllTagsList();
  };

  _resetFilter = () => {
    const { filters } = this.state;
    filters["isActive"] = "";
    filters["tags"] = [];
    filters["search"] = "";

    this.setState({ filters }, () => {
      this._paginate();
      refreshFunc("agent-clients-responsive");
    });
  };

  _clientStatusUpdate = (data, value) => {
    this._manageLoading("statusChangeLoading", true);

    const payload = {
      isActive: value === "active" ? true : false,
    };

    updateAgentClient(data.id, payload)
      .then((res) => {
        showToast("Status Updated Successfully", "success");
        const { clientsList } = deepClone(this.state);
        const client = clientsList.find((client) => client.id === data.id);
        client["isActive"] = res?.client?.isActive || false;
        this.setState({ clientsList });
        this._manageLoading("statusChangeLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("statusChangeLoading", false);
      });
  };

  _handleOnDeleteClientAlert = (clientId) => {
    if (!clientId) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this client.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this._handleOnDeleteClient(clientId);
      }
    });
  };

  _handleOnDeleteClient = async (clientId) => {
    try {
      this._manageLoading("clientDeleteLoading", true);

      await deleteAgentClientById(clientId);

      this._getAllAgentClients();
      this._manageLoading("clientDeleteLoading", false);
    } catch (err) {
      errorHandler(err);
      this._manageLoading("clientDeleteLoading", false);
    }
  };

  _dataFormat = (cell, row, header) => {
    // console.log(row);
    // console.log("q2we ", cell, row);
    switch (header) {
      case "name": {
        return (
          <div className="tableUserInfo">
            {/* {row.logo && ( */}
            <div className="userImage">
              <img
                className="clientImg"
                src={
                  row.logo
                    ? row.logo
                    : require("../../../assets/img/default_user_icon.svg")
                        .default
                }
                loading="lazy"
                alt="avatar"
              />
            </div>
            {/* )} */}
            <div className="userContent">
              <span>
                {capitalize(
                  row?._signingCompany
                    ? row.companyName
                    : row?._client
                    ? row.name?.full
                    : row?._assistant
                    ? // : row?._clientAssistant
                      // ? row.name?.full
                      // : row?._companyAssistant
                      row.name?.full
                    : "N/A"
                )}
              </span>
            </div>
          </div>
        );
        // return row?._signingCompany
        //   ? row.companyName
        //   : row?._client
        //   ? row.name?.full
        //   : "N/A";
        // return cell && cell.full ? (
        //   <div className="d-flex align-items-center">
        //     {row.logo && (
        //       <img
        //         className="clientImg"
        //         src={row.logo}
        //         loading="lazy"
        //         alt="avatar"
        //       />
        //     )}
        //     <span>{capitalize(cell.full)}</span>
        //   </div>
        // ) : (
        //   "N/A"
        // );
      }
      case "email": {
        return (
          <>
            <div style={{ width: 90 }}>{cell ? cell : "N/A"}</div>
          </>
        );
      }
      case "clientId": {
        return (
          <>
            <div style={{ width: 90 }}>{cell ? cell : "N/A"}</div>
          </>
        );
      }
      // case "tags": {
      //   return row?.noOftags ? row?.noOftags : "N/A";
      // }
      case "phone": {
        return (
          <>
            <div style={{ width: 90 }}>
              {cell ? formatPhoneNumber(cell) : "N/A"}
            </div>
          </>
        );
      }
      case "address": {
        return (
          <>
            <div style={{ width: 90 }}>
              {cell ? <>{formatAddressInSingleText(cell)}</> : "N/A"}
            </div>
          </>
        );
      }
      case "tags": {
        return (
          <>
            {row?.noOftags || row?.noOftags === 0 ? (
              <>
                <Button
                  color="link"
                  id={`agent_client_tags${row?._id}`}
                  type="button"
                >
                  {row?.noOftags}
                </Button>

                {row?.tags?.length > 0 && (
                  <>
                    <UncontrolledPopover
                      trigger="legacy"
                      placement="bottom"
                      target={`agent_client_tags${row?._id}`}
                      className="signerPopover"
                      style={{ maxWidth: 250 }}
                    >
                      <PopoverHeader>Tags</PopoverHeader>
                      <PopoverBody>
                        {row?.tags?.map((each) => (
                          <Badge color="primary" pill>
                            {each}
                          </Badge>
                        ))}
                      </PopoverBody>
                    </UncontrolledPopover>
                  </>
                )}
              </>
            ) : (
              "N/A"
            )}
          </>
        );
      }
      case "pointOfContact": {
        return cell ? cell : "N/A";
      }
      case "isActive": {
        return row ? (
          <>
            <div className="customSelectWrap" style={{ width: 90 }}>
              <Input
                type="select"
                className={`status ${getDropdownColor(
                  row.isActive ? "active" : "inactive"
                )}`}
                name="status"
                disabled={
                  this.state.loading.statusChangeLoading ||
                  !checkPermission("clients", "canUpdate")
                }
                value={row.isActive ? "active" : "inactive"}
                onChange={(event) =>
                  this._clientStatusUpdate(row, event.target.value)
                }
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Input>
              <div className="downArrow">
                <i className="fa fa-chevron-down"></i>
              </div>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "action": {
        return row ? (
          <div style={{ width: 80 }}>
            <Button
              title="Edit"
              color="link"
              className="actionBtn"
              onClick={() => this._toggleAddClientModal(true, row)}
              disabled={
                !checkPermission("clients", "canUpdate") || row?._signingCompany
              }
            >
              <img
                src={require("../../../assets/img/pencil.svg").default}
                alt="edit"
              />
            </Button>
            <Button
              color="link"
              onClick={() => this._handleOnDeleteClientAlert(row?._client)}
              disabled={
                row?._signingCompany || this.state.loading?.clientDeleteLoading
              }
            >
              <img
                src={require("../../../assets/img/deleteIcon.svg").default}
                alt="delete"
              />
            </Button>
          </div>
        ) : (
          // <Button
          //   title="Edit"
          //   color="link"
          //   className="actionBtn"
          //   onClick={() => this._toggleAddClientModal(true, row)}
          //   disabled={
          //     !checkPermission("clients", "canUpdate") || row?._signingCompany
          //   }
          // >
          //   <i className="fa fa-pencil" />
          // </Button>
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;

    this.setState({ tableConfig }, () => {
      // console.log("tableConfig ", tableConfig);
      this._persistFilter();
      this._getAllAgentClients();
    });
  };

  _onSortChange = (sortName, sortOrder) => {
    this._paginate(1, 10);
  };

  _cardHeaderFormat = (cell, row, header) => {
    console.log("weerer", cell, header);
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="tableUserInfo">
              <div className="userImage">
                <img
                  className="clientImg"
                  src={
                    row.logo
                      ? row.logo
                      : require("../../../assets/img/default_user_icon.svg")
                          .default
                  }
                  loading="lazy"
                  alt="avatar"
                />
              </div>
              <div className="userContent">
                <span className="signerName text-black">
                  {capitalize(
                    row?._signingCompany
                      ? row.companyName
                      : row?._client
                      ? row.name?.full
                      : row?._assistant
                      ? // : row?._clientAssistant
                        // ? row.name?.full
                        // : row?._companyAssistant
                        row.name?.full
                      : "N/A"
                  )}
                </span>
              </div>
            </div>

            <Button
              title="Edit"
              color="link"
              className="actionBtn"
              onClick={() => this._toggleAddClientModal(true, row)}
              disabled={
                !checkPermission("clients", "canUpdate") || row?._signingCompany
              }
            >
              <img
                src={require("../../../assets/img/pencil.svg").default}
                alt="edit"
              />
            </Button>
            <Button
              color="link"
              onClick={() => this._handleOnDeleteClientAlert(row?._client)}
              disabled={
                row?._signingCompany || this.state.loading?.clientDeleteLoading
              }
            >
              <img
                src={require("../../../assets/img/deleteIcon.svg").default}
                alt="delete"
              />
            </Button>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Email</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row.email ? row.email : "N/A"}
              </span>
            </div>
          </div>
          <div className="dataWrap">
            <Label>Phone</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row.phone ? formatPhoneNumber(row.phone) : "N/A"}
              </span>
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap">
            <Label>Location</Label>
            <div className="d-flex justify-content-between">
              <div style={{ fontWeight: 600, flex: 1, marginRight: 10 }}>
                {formatAddressInSingleText(row.closingAddress) || "N/A"}
              </div>
              {formatAddressInSingleText(row.closingAddress) ? (
                <div className="d-flex">
                  <Button
                    color="link"
                    className="p-0"
                    title={formatAddressInSingleText(row.closingAddress)}
                    // onClick={() => openGoogleMapOnNewTab(row.closingAddress)}
                  >
                    <img
                      src={
                        require("../../../assets/img/Google_Maps_Logo_2020.svg")
                          .default
                      }
                      alt="map icon"
                      className="mr-3"
                    />
                  </Button>
                  <Button
                    color="link"
                    className="p-0"
                    title={formatAddressInSingleText(row.closingAddress)}
                    // onClick={() => openAppleMapOnNewTab(row.closingAddress)}
                  >
                    <img
                      src={
                        require("../../../assets/img/Apple_Maps_(WatchOS).svg")
                          .default
                      }
                      alt="map icon"
                    />
                  </Button>
                </div>
              ) : null}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Client Id</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row?.clientId ? row?.clientId : "N/A"}
              </span>
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Tags</Label>
            <div>
              {row?.noOftags || row?.noOftags === 0 ? (
                <>
                  <Button
                    color="link"
                    id={`agent_client_tags${row?._id}`}
                    type="button"
                  >
                    {row?.noOftags}
                  </Button>

                  {row?.noOftags?.length > 0 && (
                    <>
                      <UncontrolledPopover
                        trigger="legacy"
                        placement="bottom"
                        target={`agent_client_tags${row?._id}`}
                        className="signerPopover"
                        style={{ maxWidth: 250 }}
                      >
                        <PopoverHeader>Tags</PopoverHeader>
                        <PopoverBody>
                          {row?.tags?.map((each) => (
                            <Badge color="primary" pill>
                              {each}
                            </Badge>
                          ))}
                        </PopoverBody>
                      </UncontrolledPopover>
                    </>
                  )}
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Point Of Contact</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row.pointOfContact ? row.pointOfContact : "N/A"}
              </span>
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap d-flex align-items-center justify-content-between">
            <Label className="mb-0">Status</Label>
            <div className="customSelectWrap">
              <Input
                type="select"
                className={`status ${getDropdownColor(
                  row.isActive ? "active" : "inactive"
                )}`}
                name="status"
                disabled={
                  this.state.loading.statusChangeLoading ||
                  !checkPermission("clients", "canUpdate")
                }
                value={row.isActive ? "active" : "inactive"}
                onChange={(event) =>
                  this._clientStatusUpdate(row, event.target.value)
                }
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Input>
              <div className="downArrow">
                <i className="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </li>
      </ul>
    );
  };

  _persistFilter = () => {
    const { filters, tableConfig } = this.state;
    if (
      (filters &&
        (filters?.isActive?.length ||
          filters?.search?.length ||
          filters?.tags?.length)) ||
      tableConfig.pageNumber !== 1
    ) {
      let data = { filters, tableConfig };
      console.log({ data });
      localStorage.agentClientPage = JSON.stringify(data);
    } else {
      delete localStorage.agentClientPage;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.agentClientPage) {
      try {
        const filter = JSON.parse(localStorage.agentClientPage);

        this.setState(
          { tableConfig: filter?.tableConfig, filters: filter?.filters },
          () => {
            this._getAllAgentClients();
          }
        );
      } catch (e) {
        this._getAllAgentClients();
      }
    } else {
      this._getAllAgentClients();
    }
  };

  _toggleProfessionalUpgradeAccountModal = (isOpen = false, data = null) => {
    this.setState({
      upgradeProAccountModal: {
        isOpen,
        data,
      },
    });
  };

  _checkProPlusUser = (type) => {
    if (!isProPlusUser()) {
      // this._toggleUpgradeAccountModal(true);
      this._toggleProfessionalUpgradeAccountModal(true);
      return;
    }

    if (type === "Email") {
      this._toggleBulkActivityModal(true, "Email");
    } else if (type === "Message") {
      this._toggleBulkActivityModal(true, "Message");
    }
  };

  render() {
    const {
      addClientModalData,
      clientsList,
      clientsCardHeaderKeys,
      clientsHeaderKeys,
      loading,
      clientsTotalCount,
      tableConfig,
      isClassAdded,
      bulkActivityModal,
      clientsSelectedRows,
      tagsList,
      filters,
      upgradeProAccountModal,
    } = this.state;

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>Clients</h2>

            <div className="rightSide">
              <Button
                color="primary"
                className="floatingButton"
                onClick={() => this._toggleAddClientModal(true)}
              >
                Add CLIENT
              </Button>
              <Link to={`/agent/bulk-upload?agentClient`} className="mr-3">
                Bulk upload
              </Link>

              <Button color="link" onClick={this._resetFilter}>
                <img
                  id="agent-clients-responsive"
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                />
              </Button>
              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="Filter"
                />
              </Button>
            </div>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.filterShowMobile}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer  responsiveFilter ${
              isClassAdded ? "show" : ""
            }`}
          >
            <div className="filterIcon">
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
              />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>
            <div className="filterWrapper">
              <FormGroup>
                <Label>Status</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="status"
                    value={filters?.isActive}
                    onChange={(e) =>
                      this._filterOnChange("isActive", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </Input>
                </div>
              </FormGroup>
              <FormGroup className={`withChips`}>
                <Label>Tags</Label>
                <CreatableSelect
                  isClearable
                  placeholder="select"
                  className="customMultiSelect"
                  isMulti
                  value={filters?.tags}
                  options={tagsList}
                  onChange={(value) => this._filterOnChange("tags", value)}
                  blur={() => {
                    this._validateForm();
                  }}
                />
              </FormGroup>
              <FormGroup className={`searchTable d-block`}>
                <Label>Search</Label>
                <InputGroup>
                  <InputGroupText addonType="prepend">
                    <img
                      src={
                        require("../../../assets/img/searchIcon.svg").default
                      }
                      alt="searchIcon"
                    />
                  </InputGroupText>
                  <Input
                    type="text"
                    placeholder="Client"
                    value={filters?.search}
                    onChange={(e) => this._searchOnChange(e.target.value)}
                  />
                </InputGroup>
              </FormGroup>

              <UncontrolledButtonDropdown className="dropdownList hideMobile">
                <DropdownToggle caret>Bulk Activity</DropdownToggle>
                <DropdownMenu>
                  <DropdownItem
                    // onClick={() => {
                    //   this._toggleBulkActivityModal(true, "Email");
                    // }}
                    onClick={() => this._checkProPlusUser("Email")}
                  >
                    Email Template
                  </DropdownItem>

                  <DropdownItem
                    // onClick={() =>
                    //   this._toggleBulkActivityModal(true, "Message")
                    // }
                    onClick={() => this._checkProPlusUser("Message")}
                  >
                    Message Template
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledButtonDropdown>
            </div>

            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
              {/* </Link> */}
            </div>
          </div>
          <div className="hideDesktop mb-4 text-right">
            <UncontrolledButtonDropdown className="dropdownList">
              <DropdownToggle caret>Bulk Activity</DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  // onClick={() => this._toggleBulkActivityModal(true, "Email")}
                  onClick={() => this._checkProPlusUser("Email")}
                >
                  Email Template
                </DropdownItem>

                <DropdownItem
                  // onClick={() => this._toggleBulkActivityModal(true, "Message")}
                  onClick={() => this._checkProPlusUser("Message")}
                >
                  Message Template
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledButtonDropdown>
          </div>

          <div className="hideDesktop">
            <CustomCard
              isPageStartFromOne={true}
              pageNumber={tableConfig.pageNumber}
              tableData={clientsList}
              headerKeys={clientsCardHeaderKeys}
              // bodyKeys={agentClosingCardBodyKeys}
              dataFormat={this._dataFormat}
              totalCount={clientsTotalCount}
              rowSelection={true}
              selectedRows={clientsSelectedRows}
              setSelectedRows={(selectedRows) =>
                this._setSelectedRows(selectedRows)
              }
              onPaginate={(pageNumber, pageSize) =>
                this._paginate(pageNumber, pageSize)
              }
              // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
              showTableLoading={loading.showTableLoading}
              cardHeaderFormat={this._cardHeaderFormat}
              cardDataFormat={this._cardDataFormat}
            />
          </div>
          <div className="hideMobile">
            {clientsHeaderKeys && clientsHeaderKeys.length && (
              <CustomTable
                striped
                isPageStartFromOne={true}
                pageNumber={tableConfig.pageNumber}
                tableData={clientsList}
                headerKeys={clientsHeaderKeys}
                dataFormat={this._dataFormat}
                totalCount={clientsTotalCount}
                rowSelection={true}
                selectedRows={clientsSelectedRows}
                setSelectedRows={(selectedRows) =>
                  this._setSelectedRows(selectedRows)
                }
                onPaginate={(pageNumber, pageSize) =>
                  this._paginate(pageNumber, pageSize)
                }
                onSortChange={(sortName, sortOrder) =>
                  this._onSortChange(sortName, sortOrder)
                }
                showTableLoading={loading.showTableLoading}
              />
            )}
          </div>
        </div>

        <AddClientAgentModal
          isOpen={addClientModalData.isOpen}
          data={addClientModalData.data}
          resetTable={this._resetTable}
          toggle={this._toggleAddClientModal}
        />

        <AgentBulkActivityModal
          isOpen={bulkActivityModal.isOpen}
          data={bulkActivityModal.data}
          bulkActivityType={bulkActivityModal.type}
          // resetTable={this._getAllAgentClients}
          toggle={this._toggleBulkActivityModal}
          clientsSelectedRows={clientsSelectedRows}
        />

        {/* <UpgradeAccountModal
          {...this.props}
          isOpen={upgradeAccountModal.isOpen}
          toggle={this._toggleUpgradeAccountModal}
        /> */}
        <UpgradeProfessionalAccountModal
          {...this.props}
          isOpen={upgradeProAccountModal.isOpen}
          toggle={this._toggleProfessionalUpgradeAccountModal}
        />
      </>
    );
  }
}

export default Clients;
