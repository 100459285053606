import React, { Component } from "react";
import {
  Button,
  Col,
  Input,
  PopoverBody,
  PopoverHeader,
  Row,
  UncontrolledPopover,
  FormGroup,
  Card,
  CardBody,
  Label,
} from "reactstrap";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {
  deepClone,
  errorHandler,
  formatCurrencyValue,
  formatDateOnlyAsPerTimeZone,
  formatOnlyDateMoment,
  getLoggedInUserId,
  getTimeZoneAbbr,
  openUrlOnNewTab,
  refreshFunc,
  showToast,
  structureQueryParams,
} from "../../../helper-methods";
import {
  deleteAgentNotarialLog,
  fetchAllNotarialLog,
} from "../../../http/http-calls";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import { capitalize } from "@material-ui/core";
import { BASE_URL } from "../../../config";
import CustomTable from "../components/CustomTable";
import CustomCard from "../components/CustomCard";
import { Link } from "react-router-dom";
import AddNotarialModalAgent from "../components/add-notarial";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import Swal from "sweetalert2";

class NotarialLog extends Component {
  state = {
    notarialLog: [],
    notarialLogTotalCount: 0,
    totalAgentFee: 0,
    totalAmount: 0,
    totalNotarialActs: 0,
    notarialLogHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      { id: "_borrower", label: "NAME", noSort: true },
      { id: "fileNumber", label: "FILE NUMBER", noSort: true },
      { id: "_client", label: "CLIENT", noSort: true },
      { id: "closingAppointmentDate", label: "DATE", noSort: true },
      { id: "notarialActs", label: "NOTARIAL ACTS", noSort: true },
      { id: "amountPerAct", label: "AMOUNT PER ACT", noSort: true },
      { id: "totalPotential", label: "TOTAL POTENTIAL", noSort: true },
      {
        id: "plTotal",
        label: `TOTAL (NOT TO EXCEED ORDER TOTAL)`,
        noSort: true,
        // style:{whiteSpace: "normal"}
      },
      { id: "action", label: "Action", noSort: true },
    ],
    notarialHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
      // sort: {
      //   sortBy: "createdAt",
      //   sortOrder: "desc",
      // },
    },
    loading: {
      statusChangeLoading: false,
      showTableLoading: false,
    },
    dateRangeValue: null,
    filters: {
      status: "",
    },
    addNotarialModalAgent: {
      isOpen: false,
      data: null,
    },
    isClassAdded: false,
  };
  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => {
      this._paginate();
    });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  _filterOnChange = (type, value, isDelay = false) => {
    if (isDelay) clearTimeout(this.searchTimer);

    const { filters } = deepClone(this.state);
    filters[type] = value;

    this.setState({ filters }, () => {
      if (isDelay) {
        this.searchTimer = setTimeout(() => {
          this._paginate();
        }, 1000);
      } else {
        this._paginate();
      }
    });
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._fetchAllNotarialLog();
    });
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  componentDidMount = async () => {
    try {
      this.props.showLoader("Fetching Notarial Log...");
      this._setFilterDataFromLocalStorage();
      // await this._fetchAllNotarialLog();
      this.props.hideLoader();
    } catch (error) {
      errorHandler(error);
      this.props.hideLoader();
    }
    HeaderEventEmitter.subscribe("add-notarial", () => {
      console.log("add notarial");
      this._onToggleAddNotarialModalAgent(true);
    });
  };

  _fetchAllNotarialLog = () => {
    this._manageLoading("showTableLoading", true);
    const { tableConfig, filters, dateRangeValue } = deepClone(this.state);

    return new Promise((resolve, reject) => {
      let payload = {
        ...tableConfig,
        filters: {},
      };
      if (dateRangeValue && dateRangeValue.length) {
        payload.filters["startDate"] = dateRangeValue[0];
        payload.filters["endDate"] = dateRangeValue[1];
      }

      if (filters.status)
        payload.filters["status"] =
          filters.status !== "" ? filters.status : undefined;

      fetchAllNotarialLog(payload)
        .then((res) => {
          this.setState(
            {
              notarialLog: res.notarialLogs,
              notarialLogTotalCount: res.totalCount,
              totalAgentFee: res.totalAgentFee,
              totalAmount: res.totalAmount,
              totalNotarialActs: res.totalNotarialActs,
            },
            () => {
              this._manageLoading("showTableLoading", false);
            }
          );
          resolve(res);
        })
        .catch((error) => {
          this._manageLoading("showTableLoading", false);
          reject(error);
        });
    });
  };

  multiLineText = (str) => {
    return str.split(" ").join("\n");
  };

  _dataFormat = (cell, row, header) => {
    // console.log("2334 ", cell, row);
    switch (header) {
      case "_borrower": {
        return (
          <>
            {/* show the below span only when there are more than 1 signers  */}
            <div style={{ width: 120 }}>
              {row?.isManuallyCreatedByAgent
                ? (row?._borrower &&
                    row?._borrower.length > 0 &&
                    capitalize(row?._borrower[0]?.name?.full)) ||
                  "N/A"
                : (row?._closing?._borrower &&
                    capitalize(row?._closing?._borrower[0]?.name?.full)) ||
                  "N/A"}{" "}
              {row?.isManuallyCreatedByAgent
                ? row?._borrower &&
                  row?._borrower?.length > 1 && (
                    <>
                      <span
                        id={`PopoverLegacy${row._id}`}
                        className="themeColor cursorPointer"
                        style={{ fontWeight: "600" }}
                      >
                        +{row?._borrower?.length - 1}
                      </span>
                      <UncontrolledPopover
                        trigger="legacy"
                        placement="bottom"
                        target={`PopoverLegacy${row._id}`}
                        className="signerPopover"
                      >
                        <PopoverHeader>Signers</PopoverHeader>
                        <PopoverBody>
                          {row?._borrower.map((item) => (
                            <p>{capitalize(item?.name?.full)}</p>
                          ))}
                        </PopoverBody>
                      </UncontrolledPopover>
                    </>
                  )
                : row?._closing?._borrower &&
                  row?._closing?._borrower?.length > 1 && (
                    <>
                      <span
                        id={`PopoverLegacy${row._id}`}
                        className="themeColor cursorPointer"
                        style={{ fontWeight: "600" }}
                      >
                        +{row?._closing?._borrower?.length - 1}
                      </span>
                      <UncontrolledPopover
                        trigger="legacy"
                        placement="bottom"
                        target={`PopoverLegacy${row._id}`}
                        className="signerPopover"
                      >
                        <PopoverHeader>Signers</PopoverHeader>
                        <PopoverBody>
                          {row?._closing?._borrower.map((item) => (
                            <p>{capitalize(item?.name?.full)}</p>
                          ))}
                        </PopoverBody>
                      </UncontrolledPopover>
                    </>
                  )}
            </div>
          </>
        );
      }
      case "fileNumber": {
        return (
          <>
            <div style={{ width: 120 }}>
              {/* {row ? <>{row?._closing?.fileNumber || "N/A"}</> : "N/A"} */}
              {row && row?.isManuallyCreatedByAgent ? (
                row?.purpose
              ) : (
                <>{row?._closing?.fileNumber || "N/A"}</>
              )}
            </div>
          </>
        );
      }

      case "_client": {
        return (
          <>
            <div style={{ width: 150 }}>
              {/* {row && row?._closing?._client
                ? row?._closing?._client?.name?.full !== ""
                  ? capitalize(row?._closing?._client?.name?.full)
                  : "N/A"
                : "N/A"} */}
              {/* {row && row?.isManuallyCreatedByAgent
                ? row?._signingCompany
                  ? capitalize(row?._signingCompany?.companyName)
                  : row?._client?._agentClients.length > 0 &&
                  capitalize(
                    row?._client?._agentClients[0]?._agentClient?.name?.full
                  )
                : row && row?._closing?._client
                  ? row?._closing?._client?.name?.full !== ""
                    ? capitalize(row?._closing?._client?.name?.full)
                    : "N/A"
                : "N/A"} */}
              {this._clientName(row)}
            </div>
          </>
        );
      }
      case "loanType": {
        return cell
          ? (cell && cell.length > 0
              ? cell.indexOf("Other") > -1
                ? row?.loanTypeOther
                : null
              : null,
            cell
              .filter((each) => each !== "Other")
              .map((item) => (
                <>
                  {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                  <br />
                </>
              )))
          : "N/A";
      }
      case "lender": {
        return cell ? capitalize(cell) : "N/A";
      }

      case "closingAppointmentDate": {
        return row ? (
          <>
            {row ? (
              <>
                <div
                  className="d-flex align-items-center"
                  style={{ whiteSpace: "nowrap", fontSize: 13 }}
                >
                  {formatDateOnlyAsPerTimeZone(
                    cell,
                    row?._closing?.closingAddress?.timeZone
                  )}
                </div>{" "}
              </>
            ) : (
              ""
            )}
          </>
        ) : (
          "N/A"
        );
      }
      case "totalPotential": {
        // return row
        //   ? row?.isManuallyCreatedByAgent
        //     ? formatCurrencyValue(row?.agentFee)
        //       ? formatCurrencyValue(row?.agentFee)
        //       : formatCurrencyValue(row?.plTotal)
        //     : row?._closing?.agentFee > row?.plTotal
        //     ? formatCurrencyValue(row?._closing?.agentFee)
        //     : formatCurrencyValue(row?.plTotal)
        //   : "N/A";
        return row
          ? row?.isManuallyCreatedByAgent
            ? row?.agentFee
              ? row?.notarialFees
                ? row?.notarialFees > row?.agentFee
                  ? formatCurrencyValue(row?.notarialFees)
                  : formatCurrencyValue(row?.agentFee)
                : row?.agentFee > row?.plTotal
                ? formatCurrencyValue(row?.agentFee)
                : formatCurrencyValue(row?.plTotal)
              : formatCurrencyValue(row?.plTotal)
            : row?._closing?.agentFee > row?.plTotal
            ? formatCurrencyValue(row?._closing?.agentFee)
            : formatCurrencyValue(row?.plTotal)
          : "N/A";
      }
      case "plTotal": {
        return row
          ? row?.isManuallyCreatedByAgent
            ? formatCurrencyValue(row?.plTotal)
            : row?._closing?.agentFee > row?.plTotal
            ? formatCurrencyValue(row?.plTotal)
            : formatCurrencyValue(row?._closing?.agentFee)
          : "N/A";
        // return cell ? formatCurrencyValue(cell) : "N/A";
      }
      case "amountPerAct": {
        return cell ? formatCurrencyValue(cell) : "N/A";
      }
      case "notarialActs": {
        return row?.notarialActs ? row?.notarialActs : "N/A";
      }

      case "action": {
        return (
          // <>
          //   {row?.isManuallyCreatedByAgent ? (
          //     <>
          //       <div style={{ width: 60 }}>
          //       {row?._closing?.isCreatedForAgent&&  <Button
          //           color="link"
          //           className="actionBtn"
          //           onClick={() =>
          //             this._onToggleAddNotarialModalAgent(true, row)
          //           }
          //         >
          //           <img
          //             src={require("../../../assets/img/pencil.svg").default}
          //             alt="edit"
          //           />
          //         </Button>}
          //         <Button
          //           color="link"
          //           className="remove"
          //           onClick={() => this._deleteNotarialLog(row.id)}
          //           disabled={
          //             this.state.loading.statusChangeLoading ? true : false
          //           }
          //         >
          //           <img
          //             src={
          //               require("../../../assets/img/deleteIcon.svg").default
          //             }
          //             alt="delete"
          //           />
          //         </Button>
          //       </div>
          //     </>
          //   ) : (
          //     "-"
          //   )}
          // </>
          <>
            {row?.isManuallyCreatedByAgent ? (
              <div style={{ width: 60 }}>
                <Button
                  color="link"
                  className="actionBtn"
                  onClick={() => this._onToggleAddNotarialModalAgent(true, row)}
                >
                  <img
                    src={require("../../../assets/img/pencil.svg").default}
                    alt="edit"
                  />
                </Button>

                <Button
                  color="link"
                  className="remove"
                  onClick={() => this._deleteNotarialLog(row.id)}
                  disabled={
                    this.state.loading.statusChangeLoading ? true : false
                  }
                >
                  <img
                    src={require("../../../assets/img/deleteIcon.svg").default}
                    alt="delete"
                  />
                </Button>
              </div>
            ) : (
              !row?.isManuallyCreatedByAgent &&
              row?._closing?.isCreatedForAgent && (
                <Button
                  color="link"
                  className="actionBtn"
                  onClick={() => this._onToggleAddNotarialModalAgent(true, row)}
                >
                  <img
                    src={require("../../../assets/img/pencil.svg").default}
                    alt="edit"
                  />
                </Button>
              )
            )}
            {!row?.isManuallyCreatedByAgent &&
              !row?._closing?.isCreatedForAgent && <span>-</span>}
          </>
        );
      }

      default: {
        return cell;
      }
    }
  };
  _resetFilter = () => {
    this.setState(
      {
        dateRangeValue: null,
        filters: {
          status: "",
        },
      },
      () => {
        this._paginate();
        refreshFunc("agent-notarial-log-responsive");
      }
    );
  };

  _deleteNotarialLog = (id) => {
    if (!id) {
      showToast("Notarial not found", "error");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this._deleteAgentNotarialLog(id);
      }
    });
  };

  _deleteAgentNotarialLog = (id) => {
    this._manageLoading("showTableLoading", true);

    deleteAgentNotarialLog(id)
      .then(async (res) => {
        Swal.fire("Deleted!", "Entry has been deleted.", "success");

        this._manageLoading("showTableLoading", false);
        this._paginate();
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _downloadData = (action = "download") => {
    const { notarialLog, filters, dateRangeValue } = this.state;

    if (!notarialLog.length) {
      showToast(`No data for ${action}`, "error");
      return;
    }

    const payload = {
      userId: getLoggedInUserId(),
    };

    if (dateRangeValue) {
      // payload["startDate"] = dateRangeValue[0];
      // payload["endDate"] = dateRangeValue[1];
      payload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      payload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
    }

    if (filters.status) payload["status"] = filters.status;

    const queryParams = structureQueryParams(payload);

    if (action === "download")
      openUrlOnNewTab(`${BASE_URL}/agent/download/notarial-logs${queryParams}`);
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Signer </Label>
            <div className="text-primary " style={{ fontWeight: 600 }}>
              {row?.isManuallyCreatedByAgent
                ? (row?._borrower &&
                    row?._borrower.length > 0 &&
                    capitalize(row?._borrower[0]?.name?.full)) ||
                  "N/A"
                : (row?._closing?._borrower &&
                    capitalize(row?._closing?._borrower[0]?.name?.full)) ||
                  "N/A"}{" "}
              {row?.isManuallyCreatedByAgent
                ? row?._borrower &&
                  row?._borrower?.length > 1 && (
                    <>
                      <span
                        id={`PopoverLegacy${row._id}`}
                        className="themeColor cursorPointer"
                        style={{ fontWeight: "600" }}
                      >
                        +{row?._borrower?.length - 1}
                      </span>
                      <UncontrolledPopover
                        trigger="legacy"
                        placement="bottom"
                        target={`PopoverLegacy${row._id}`}
                        className="signerPopover"
                      >
                        <PopoverHeader>Signers</PopoverHeader>
                        <PopoverBody>
                          {row?._borrower.map((item) => (
                            <p>{capitalize(item?.name?.full)}</p>
                          ))}
                        </PopoverBody>
                      </UncontrolledPopover>
                    </>
                  )
                : row?._closing?._borrower &&
                  row?._closing?._borrower?.length > 1 && (
                    <>
                      <span
                        id={`PopoverLegacy${row._id}`}
                        className="themeColor cursorPointer"
                        style={{ fontWeight: "600" }}
                      >
                        +{row?._closing?._borrower?.length - 1}
                      </span>
                      <UncontrolledPopover
                        trigger="legacy"
                        placement="bottom"
                        target={`PopoverLegacy${row._id}`}
                        className="signerPopover"
                      >
                        <PopoverHeader>Signers</PopoverHeader>
                        <PopoverBody>
                          {row?._closing?._borrower.map((item) => (
                            <p>{capitalize(item?.name?.full)}</p>
                          ))}
                        </PopoverBody>
                      </UncontrolledPopover>
                    </>
                  )}
            </div>
          </div>
          <div className="dataWrap">
            <Label>File Number </Label>
            <div style={{ fontWeight: 600 }}>
              {row && row?.isManuallyCreatedByAgent ? (
                row?.purpose
              ) : (
                <>{row?._closing?.fileNumber || "N/A"}</>
              )}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Client </Label>
            <div style={{ fontWeight: 600 }}>
              {/* {row?._closing?._client?.name?.full
                ? row?._closing?._client?.name?.full
                : "N/A"} */}
              {this._clientName(row)}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Date </Label>
            <div style={{ fontWeight: 600 }}>
              {/* {row?.createdAt
                ? formatDateOnlyAsPerTimeZone(
                    row?.createdAt,
                    row?._closing?.closingAddress?.timeZone
                  )
                : "N/A"} */}
              {row?.closingAppointmentDate
                ? formatDateOnlyAsPerTimeZone(
                    row?.closingAppointmentDate,
                    row?._closing?.closingAddress?.timeZone
                  )
                : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>NOTARIAL ACTS </Label>
            <div style={{ fontWeight: 600 }}>
              {row?.notarialActs || row?.notarialActs === 0
                ? row?.notarialActs
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>AMOUNT PER ACT </Label>
            <div style={{ fontWeight: 600 }}>
              {row?.amountPerAct || row?.amountPerAct === 0
                ? formatCurrencyValue(row?.amountPerAct)
                : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>TOTAL POTENTIAL </Label>
            <div style={{ fontWeight: 600 }}>
              {/* {(row?._closing?.agentFee || row?._closing?.agentFee === 0) &&
              (row?.plTotal || row?.plTotal === 0)
                ? row?._closing?.agentFee > row?.plTotal
                  ? formatCurrencyValue(row?._closing?.agentFee)
                  : formatCurrencyValue(row?.plTotal)
                : "N/A"} */}
              {row
                ? row?.isManuallyCreatedByAgent
                  ? row?.agentFee
                    ? row?.notarialFees
                      ? row?.notarialFees > row?.agentFee
                        ? formatCurrencyValue(row?.notarialFees)
                        : formatCurrencyValue(row?.agentFee)
                      : row?.agentFee > row?.plTotal
                      ? formatCurrencyValue(row?.agentFee)
                      : formatCurrencyValue(row?.plTotal)
                    : formatCurrencyValue(row?.plTotal)
                  : row?._closing?.agentFee > row?.plTotal
                  ? formatCurrencyValue(row?._closing?.agentFee)
                  : formatCurrencyValue(row?.plTotal)
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>
              TOTAL
              <span style={{ fontSize: 10 }}>
                (NOT TO EXCEED ORDER TOTAL)
              </span>{" "}
            </Label>
            <div style={{ fontWeight: 600 }}>
              {/* {(row?._closing?.agentFee || row?._closing?.agentFee === 0) &&
              (row?.plTotal || row?.plTotal === 0)
                ? row?._closing?.agentFee > row?.plTotal
                  ? formatCurrencyValue(row?.plTotal)
                  : formatCurrencyValue(row?._closing?.agentFee)
                : "N/A"} */}
              {row
                ? row?.isManuallyCreatedByAgent
                  ? formatCurrencyValue(row?.plTotal)
                  : row?._closing?.agentFee > row?.plTotal
                  ? formatCurrencyValue(row?.plTotal)
                  : formatCurrencyValue(row?._closing?.agentFee)
                : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <Label>Action</Label>
          <div style={{ fontWeight: 600 }}>
            {row?.isManuallyCreatedByAgent ? (
              <div style={{ width: 60 }}>
                <Button
                  color="link"
                  className="actionBtn"
                  onClick={() => this._onToggleAddNotarialModalAgent(true, row)}
                >
                  <img
                    src={require("../../../assets/img/pencil.svg").default}
                    alt="edit"
                  />
                </Button>

                <Button
                  color="link"
                  className="remove"
                  onClick={() => this._deleteNotarialLog(row.id)}
                  disabled={
                    this.state.loading.statusChangeLoading ? true : false
                  }
                >
                  <img
                    src={require("../../../assets/img/deleteIcon.svg").default}
                    alt="delete"
                  />
                </Button>
              </div>
            ) : (
              !row?.isManuallyCreatedByAgent &&
              row?._closing?.isCreatedForAgent && (
                <Button
                  color="link"
                  className="actionBtn"
                  onClick={() => this._onToggleAddNotarialModalAgent(true, row)}
                >
                  <img
                    src={require("../../../assets/img/pencil.svg").default}
                    alt="edit"
                  />
                </Button>
              )
            )}
            {!row?.isManuallyCreatedByAgent &&
              !row?._closing?.isCreatedForAgent && <span>-</span>}
          </div>
        </li>
      </ul>
    );
  };

  _onToggleAddNotarialModalAgent = (isOpen = false, data = null) => {
    this.setState({
      addNotarialModalAgent: {
        isOpen,
        data,
      },
    });
  };

  // _clientName = (row) => {
  //   if (row && row?.isManuallyCreatedByAgent) {
  //     if (row?._signingCompany) {
  //       return capitalize(row?._signingCompany?.companyName);
  //     } else if (row?._client?._agentClients?.length > 0) {
  //       return capitalize(
  //         row?._client?._agentClients[0]?._agentClient?.name?.full
  //       );
  //     }
  //   } else {
  //     if (row?._closing) {
  //       if ((row, row?._closing?._client?.name?.full)) {
  //         return capitalize(row?._closing?._client?.name?.full);
  //       } else if (row?._closing?._signingCompany) {
  //         return capitalize(row?._closing?._signingCompany?.companyName);
  //       }
  //     }
  //   }
  //   return "N/A";
  // };

  _clientName = (row) => {
    if (row && row?.isManuallyCreatedByAgent) {
      if (row?._signingCompany) {
        return capitalize(row?._signingCompany?.companyName);
      } else if (row?._client && row?._client !== "") {
        return row?._client?.name
          ? capitalize(row?._client?.name?.full)
          : "N/A";
      } else {
        return row?._assistant?.name?.full
          ? capitalize(row?._assistant?.name?.full)
          : "N/A";
      }
    } else {
      if (row?._closing) {
        if (
          row?._closing?.isCreatedForAgent !== undefined &&
          row?._closing?.isCreatedForAgent === true &&
          row?._closing?._client?.name?.full
        ) {
          return capitalize(row?._closing?._client?.name?.full);
        } else if (row?._closing?._signingCompany) {
          return capitalize(row?._closing?._signingCompany?.companyName);
        } else {
          return row?._closing?._assistant?.name?.full
            ? capitalize(row?._closing?._assistant?.name?.full)
            : "N/A";
        }
      }
    }
    return "N/A";
  };

  _persistFilter = () => {
    const { tableConfig } = this.state;

    if (tableConfig.pageNumber !== 1) {
      let data = { tableConfig };
      localStorage.agentNotarialLogPage = JSON.stringify(data);
    } else {
      delete localStorage.agentNotarialLogPage;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.agentNotarialLogPage) {
      try {
        const filters = JSON.parse(localStorage.agentNotarialLogPage);

        this.setState({ tableConfig: filters.tableConfig }, () => {
          this._fetchAllNotarialLog();
        });
      } catch (e) {
        console.log("error", e);
        this._fetchAllNotarialLog();
      }
    } else {
      this._fetchAllNotarialLog();
    }
  };

  render() {
    const {
      notarialLogHeaderKeys,
      tableConfig,
      notarialLog,
      notarialLogTotalCount,
      loading,
      dateRangeValue,
      filters,
      totalAgentFee,
      totalAmount,
      totalNotarialActs,
      isClassAdded,
      notarialHeaderKeys,
      addNotarialModalAgent,
    } = deepClone(this.state);
    return (
      <div className="content">
        <div className="responsiveTitle">
          <h2>Notarial Log</h2>

          <div className="rightSide">
            <Button color="link" onClick={this._resetFilter}>
              <img
                id="agent-notarial-log-responsive"
                src={require("../../../assets/img/refresh.svg").default}
                alt="refresh"
              />
            </Button>
            <Link to={`/agent/bulk-upload?notarialLog`} className="ml-2">
              Bulk upload
            </Link>
            <Button
              className="floatingButton"
              color="primary"
              // onClick={this.filterShowMobile}
            >
              Add Notarial
            </Button>
            <Button
              className="filterBarIcon"
              color="link"
              onClick={this.filterShowMobile}
            >
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="Filter"
              />
            </Button>
          </div>
        </div>

        {/* filter for tablet and web */}
        <div
          onClick={this.handleButtonClick}
          className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
        ></div>

        <div
          className={`filterContainer responsiveFilter ${
            isClassAdded ? "show" : ""
          }`}
        >
          <div className="filterIcon">
            <img
              src={require("../../../assets/img/filter_icon.svg").default}
              alt="filter icon"
            />
            Filter by
          </div>
          <div className="mobileTitle">
            <h2>Filter by</h2>
            <Button
              color="link"
              className="closeButton"
              onClick={this.filterShowMobile}
            >
              <img
                src={require("../../../assets/img/close_grey.svg").default}
                alt="close"
                height={12}
              />
            </Button>
          </div>
          <div className="filterWrapper">
            <FormGroup className="dateRange">
              <Label>Date Range</Label>
              <DateRangePicker
                className="dateRange"
                format="MM-dd-y"
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                onChange={(dateRangeValue) =>
                  this._onChangeDatePicker(dateRangeValue)
                }
                value={dateRangeValue}
              />
            </FormGroup>
            {/* <FormGroup>
              <Label>Status</Label>
              <div className="custom-select-wrapper">
                <Input
                  type="select"
                  value={filters.status}
                  name="agent"
                  onChange={(e) =>
                    this._filterOnChange("status", e.target.value)
                  }
                >
                  <option value="">All</option>
                  <option value="Pending">Pending</option>
                  <option value="CCA">Cannot Complete Assignment</option>
                  <option value="Closed">Closed</option>
                  <option value="Cancelled">Cancelled</option>
                  <option value="DNC">Did not close</option>
                  <option value="Arrived">Arrived</option>
                </Input>
              </div>
            </FormGroup> */}

            <Button
              color="link"
              outline
              className="downloadButton ml-auto"
              onClick={() => this._downloadData()}
            >
              Download
              <img
                src={require(`../../../assets/img/download_blue.png`).default}
                alt="download"
              />
            </Button>
          </div>
          <div className="clearButton">
            <Button size="md" color="primary" onClick={this.filterShowMobile}>
              Close
            </Button>
          </div>
        </div>

        <div className="downloadButtonPos">
          <Button color="link" outline onClick={() => this._downloadData()}>
            Download
            <img
              src={require(`../../../assets/img/download_blue.png`).default}
              alt="download"
            />
          </Button>
        </div>

        <Row className="justify-content-center justify-content-xl-start">
          <Col xl={3} lg={6} md={4} sm={6} xs={6}>
            <Card className="infoCard">
              <CardBody>
                <div className="">
                  <span>Notarial Acts</span>
                  <h4>
                    {" "}
                    {totalNotarialActs !== undefined
                      ? totalNotarialActs
                      : "N/A"}
                  </h4>
                </div>
                <div className="icon">
                  <img
                    src={require("../../../assets/img/notaryActs.png").default}
                    alt="notaryActs"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3} lg={6} md={4} sm={6} xs={6}>
            <Card className="infoCard">
              <CardBody>
                <div className="">
                  <span>Total Potential</span>
                  <h4>
                    {totalAmount !== undefined
                      ? formatCurrencyValue(totalAgentFee)
                      : "N/A"}
                  </h4>
                </div>
                <div className="icon">
                  <img
                    src={require("../../../assets/img/potential.png").default}
                    alt="notaryActs"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col xl={3} lg={6} md={4} sm={12} xs={12}>
            <Card className="infoCard">
              <CardBody>
                <div className="">
                  <span>Total(Not to exceed order total)</span>
                  <h4>
                    {" "}
                    {totalAmount !== undefined
                      ? formatCurrencyValue(totalAmount)
                      : "N/A"}
                  </h4>
                </div>
                <div className="icon">
                  <img
                    src={require("../../../assets/img/totalAmount.png").default}
                    alt="notaryActs"
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>

        <div className="hideDesktop">
          <CustomCard
            isPageStartFromOne={true}
            pageNumber={tableConfig.pageNumber}
            tableData={notarialLog}
            // headerKeys={notarialHeaderKeys}
            // bodyKeys={agentClosingCardBodyKeys}
            dataFormat={this._dataFormat}
            totalCount={notarialLogTotalCount}
            onPaginate={(pageNumber, pageSize) =>
              this._paginate(pageNumber, pageSize)
            }
            rowSelection={false}
            // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
            showTableLoading={loading.showTableLoading}
            cardHeaderFormat={this._cardHeaderFormat}
            cardDataFormat={this._cardDataFormat}
          />
        </div>

        <div className="hideMobile">
          {notarialLogHeaderKeys && notarialLogHeaderKeys.length && (
            <CustomTable
              striped
              isPageStartFromOne={true}
              pageNumber={tableConfig.pageNumber}
              tableData={notarialLog}
              headerKeys={notarialLogHeaderKeys}
              dataFormat={this._dataFormat}
              totalCount={notarialLogTotalCount}
              rowSelection={false}
              pageSize={tableConfig.limit}
              onPaginate={(pageNumber, pageSize) =>
                this._paginate(pageNumber, pageSize)
              }
              onSortChange={(sortName, sortOrder) =>
                this._onSortChange(sortName, sortOrder)
              }
              showTableLoading={loading.showTableLoading}
            />
          )}
        </div>

        <AddNotarialModalAgent
          isOpen={addNotarialModalAgent.isOpen}
          data={addNotarialModalAgent.data}
          // signingCompany={filterDropdownValues.signingCompany}
          // unregisterClientList={filterDropdownValues.unregisterClientList}
          toggle={this._onToggleAddNotarialModalAgent}
          resetDetails={() => this._paginate()}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(NotarialLog);
