import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  CustomInput,
  Spinner,
} from "reactstrap";
import TextEditor from "../../TextEdittor";
import CreatableSelect from "react-select";
import ReactDatetime from "react-datetime";
import {
  errorHandler,
  formatDateAndTime,
  getFileExtension,
  getFileType,
  getFileTypeFromName,
  showToast,
  sleepTime,
} from "../../../../../helper-methods";
import { createSocialMediaPost } from "../../../../../http/http-calls";
import { PostManager } from "../../../../../post-manager";
import { UploadQueueManager } from "../../../../../upload-queue-manager";
import { SocialPostMediaBucketName } from "../../../../../config";

const initialValues = {
  topic: "",
  text: "",
  scheduledAt: "",
  postedOn: [],
  attatchments: [],
};

let initialIsDirty = {
  topic: false,
  text: false,
  scheduledAt: false,
  postedOn: false,
  attatchments: false,
};

// let socialMedia = [
//   {
//     label: "Instagram",
//     value: "Instagram",
//   },
//   {
//     label: "Facebook",
//     value: "Facebook",
//   },
//   {
//     label: "Linkedin",
//     value: "Linkedin",
//   },
// ];
// let bucketName="closewise-social-post-media-storage-bucket";

const AddPostModal = ({ isOpen, data, toggle, socialMediaData, onSuccess }) => {
  const [isChecked, setIsChecked] = useState(false);
  const [formFields, setFormFields] = useState(initialValues);
  const [isDirty, setIsDirty] = useState(initialIsDirty);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [socialMedia, setSocialMedia] = useState([]);

  const _closeModal = () => {
    setFormFields(initialValues);
    setErrors({});
    setIsDirty(initialIsDirty);
    setIsChecked(false);
    toggle();
    setLoading(false);
  };
  const _onChange = (key, value) => {
    const updatedObj = { ...formFields };
    const newIsDirty = { ...isDirty };

    updatedObj[key] = value;

    setFormFields(updatedObj);
    setIsDirty(newIsDirty);
    _validateForm(updatedObj, newIsDirty);
  };

  const _validateForm = (newFormFields, isDirty) => {
    // console.log({ newFormFields }, { isDirty });
    return new Promise((resolve, reject) => {
      const error = { ...errors };
      let isFormValid = true;

      Object.keys(newFormFields).forEach((fieldName) => {
        switch (fieldName) {
          // case "topic": {
          //   if (
          //     newFormFields[fieldName] &&
          //     newFormFields[fieldName].trim().length > 0
          //   ) {
          //     error[fieldName] = null;
          //     isDirty[fieldName] = false;
          //   } else {
          //     error[fieldName] = "Topic is Required";
          //     isFormValid = false;
          //   }
          //   break;
          // }

          case "topic": {
            if (
              newFormFields[fieldName] &&
              newFormFields[fieldName].trim().length >= 2 &&
              newFormFields[fieldName].trim().length <= 200
            ) {
              error[fieldName] = null;
              isDirty[fieldName] = false;
            } else {
              error[fieldName] =
                "*Topic must contain between 2 to 200 characters";
              isFormValid = false;
            }
            break;
          }

          case "text": {
            if (
              newFormFields[fieldName] &&
              newFormFields[fieldName].trim().length >= 2 &&
              newFormFields[fieldName].trim().length <= 2000
            ) {
              error[fieldName] = null;
              isDirty[fieldName] = false;
            } else {
              error[fieldName] =
                "*Body must contain between 2 to 2000 characters";
              isFormValid = false;
            }
            break;
          }

          // case "attatchments": {
          //   if (
          //     newFormFields[fieldName] &&
          //     newFormFields[fieldName].length > 0
          //   ) {
          //     error[fieldName] = null;
          //     isDirty[fieldName] = false;
          //   } else {
          //     error[fieldName] = "*Required";
          //     isFormValid = false;
          //   }
          //   break;
          // }

          case "attatchments": {
            if (
              newFormFields[fieldName] &&
              newFormFields[fieldName].length > 0
            ) {
              // error[fieldName] = null;
              // isDirty[fieldName] = false;
              const hasImage = newFormFields[fieldName].some((file) =>
                file.uploadData.type.startsWith("image/")
              );
              const hasVideo = newFormFields[fieldName].some((file) =>
                file.uploadData.type.startsWith("video/")
              );

              if ((hasImage && hasVideo) || (!hasImage && !hasVideo)) {
                error[fieldName] =
                  "*Please select either an image or a video, not both.";
                isFormValid = false;
              } else if (hasVideo && newFormFields[fieldName].length > 1) {
                error[fieldName] = "*Only One Video can be Selected.";
                isFormValid = false;
              } else {
                error[fieldName] = null;
                isDirty[fieldName] = false;
              }
            } else {
              error[fieldName] = "*Required";
              isFormValid = false;
            }
            break;
          }

          case "postedOn": {
            if (newFormFields[fieldName]?.length > 0) {
              error[fieldName] = null;
              isDirty[fieldName] = false;
            } else {
              error[fieldName] = "*Required";
              isFormValid = false;
            }
            break;
          }

          case "scheduledAt": {
            // console.log("11", newFormFields?.scheduledAt);
            if (isChecked) {
              if (newFormFields?.scheduledAt) {
                error[fieldName] = null;
                isDirty[fieldName] = false;
              } else {
                error[fieldName] = "*Required";
                isFormValid = false;
              }
            }

            break;
          }

          default: {
          }
        }
      });

      setIsDirty(isDirty);
      setErrors(error);
      resolve(isFormValid);
    });
  };

  const _editOrCreatePost = async () => {
    const newIsDirty = { ...isDirty };
    Object.keys(isDirty).forEach((each) => (newIsDirty[each] = true));
    // console.log({ newIsDirty });

    const error = await _validateForm(formFields, newIsDirty);
    console.log("dfg", error);

    let payload = {
      topic: formFields.topic,
      text: formFields.text,
      // scheduledAt: formFields.scheduledAt ? formFields.scheduledAt : undefined,
      postedOn:
        formFields.postedOn.length > 0
          ? formFields.postedOn.map((item) => item.value)
          : undefined,
      attachments: [],
    };

    if (isChecked) {
      payload["scheduledAt"] = formFields.scheduledAt
        ? formFields.scheduledAt
        : undefined;
    }

    if (error) {
      // if (isOpen && data) {
      //   _updatePost(payload);
      // } else {
      setLoading(true);

      if (formFields.attatchments && formFields.attatchments.length) {
        try {
          payload["attachments"] = [];

          let postID = PostManager.addMediaFilesCount(
            formFields.attatchments.length
          );

          PostManager.onAllMediaFilesUploadCompleted(postID, async (id) => {
            if (id.postID === postID) {
              console.log("payload :>> ", id);
              await sleepTime(500);

              _createPost(payload);

              PostManager.deletePostID(postID);
            } else {
              console.log("into else");
              return;
            }
          });

          formFields.attatchments.forEach((uploadFile) => {
            // for logo images
            let mediaData = {
              file: uploadFile.uploadData,
              blobObject: uploadFile.previewBlob,
            };
            const uploadId = UploadQueueManager.addMediaToQueue(
              mediaData,
              getFileTypeFromName(uploadFile.uploadData.name),
              SocialPostMediaBucketName
            );

            // Listen for upload complete
            UploadQueueManager.onUploadComplete(
              uploadId,
              async (mediaResponse) => {
                PostManager.onSingleMediaFileUploadCompleted(postID);
                // Upload complete
                // Get content id from backend
                payload["attachments"].push({
                  url: mediaResponse.fileUrl,
                  contentType: mediaResponse.data.mediaType,
                });
              }
            );

            UploadQueueManager.onUploadFailed(uploadId, async (error) => {
              console.log("response", error);
              setLoading(false);
              // this.props.hideLoader();
              errorHandler(error);
            });
          });
        } catch (error) {
          console.log("err", error);
          // this.props.hideLoader();
          setLoading(false);
          errorHandler(error);
        }
      } else {
        _createPost(payload);
      }
    }
  };

  const _createPost = async (payload) => {
    setLoading(true);
    try {
      console.log("dfg", payload);
      await createSocialMediaPost(payload);
      showToast("Created Successfully", "success");
      setLoading(false);
      onSuccess();
      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  // const _updatePost = (payload) => {};

  const _updateFile = (fieldName, e) => {
    const updatedObj = { ...formFields };
    const newIsDirty = { ...isDirty };

    let fileList = Array.from(e.target.files);
    let files = [...formFields[fieldName]];
    for (let i = 0; i < fileList.length; i++) {
      // let image = fileList[i];

      files.unshift({
        previewBlob: URL.createObjectURL(fileList[i]),
        uploadData: fileList[i],
      });
    }

    updatedObj[fieldName] = files;

    setFormFields(updatedObj);
    setIsDirty(newIsDirty);
    _validateForm(updatedObj, newIsDirty);
  };
  const _renderFileOnType = (type, item) => {
    console.log(type, item);
    switch (type) {
      case "pdf":
        return <i className="fa fa-file-pdf-o docIcon" />;
        break;

      case "docx":
      case "doc":
        return <i className="fa fa-file-text-o docIcon" />;
        break;

      case "jpg":
      case "png":
        return (
          <img src={item.previewBlob ? item.previewBlob : item} alt="Img" />
        );
      default:
    }
  };

  const _deleteContent = (index) => {
    const updatedObj = { ...formFields };
    const newIsDirty = { ...isDirty };

    updatedObj.attatchments.splice(index, 1);
    setFormFields(updatedObj);
    _validateForm(updatedObj, newIsDirty);
  };

  useEffect(() => {
    let socialMedia = [];

    // socialMedia.forEach((each) => {
    if (socialMediaData?.isAuthorizedFromFacebook) {
      socialMedia.push({
        label: "Facebook",
        value: "facebook",
      });
    }
    if (socialMediaData?.isAuthorizedFromInstagram) {
      socialMedia.push({
        label: "Instagram",
        value: "instagram",
      });
    }
    if (socialMediaData?.isAuthorizedFromLinkedIn) {
      socialMedia.push({
        label: "Linkedin",
        value: "linkedin",
      });
    }
    setSocialMedia(socialMedia);
    // });
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} centered scrollable>
      <ModalHeader toggle={() => _closeModal()}>Create New Post</ModalHeader>

      <ModalBody>
        <FormGroup className="floatingLabel">
          <Input
            placeholder=" "
            value={formFields.topic}
            onChange={(e) => _onChange("topic", e.target.value)}
          />
          <Label>Topic</Label>
          {errors[`topic`] ? (
            <div className="validation-error">{errors[`topic`]}</div>
          ) : null}
        </FormGroup>
        {/* {console.log("Dd",socialMediaData, socialMedia)} */}

        <FormGroup className="floatingLabel">
          {/* <Input type="textarea" row={10} placeholder=" " /> */}
          {/* <TextEditor
            content={formFields.text}
            placeholder=" "
            onChange={(e) => _onChange("text", e)}
          /> */}
          <Input
            type="textarea"
            placeholder=" "
            value={formFields.text}
            onChange={(e) => _onChange("text", e.target.value)}
          />
          <Label>Body</Label>
          {errors[`text`] ? (
            <div className="validation-error">{errors[`text`]}</div>
          ) : null}
        </FormGroup>

        <div className="attachementWrap">
          <div className="uploadItem">
            <Label>
              <Input
                type="file"
                multiple
                value=""
                accept=".mp4, .mov, .jpg, .jpeg, .png"
                onChange={(e) => _updateFile("attatchments", e)}
              />
              <img
                src={require("../../../../../assets/img/addItem.svg").default}
                alt="icon"
              />
            </Label>
          </div>
          {formFields.attatchments && formFields.attatchments.length
            ? formFields.attatchments.map((media, index) => (
                <div className="uploadItem" key={index}>
                  <div className="uploadItem">
                    <div
                      className="removeIcon"
                      onClick={() => _deleteContent(index)}
                    >
                      <img
                        src={
                          require("../../../../../assets/img/close.png").default
                        }
                        alt="close"
                      />
                    </div>
                    {!media.previewBlob &&
                      _renderFileOnType(getFileExtension(media), media)}

                    {media.previewBlob &&
                      _renderFileOnType(
                        getFileExtension(media?.uploadData?.name),
                        media
                      )}
                  </div>

                  {/* <span>
                    {media.uploadData && media.uploadData.name
                      ? media.uploadData.name
                      : null}
                  </span> */}
                  {/* {!media?.isPrivate ? (
                    <Button
                      color="link"
                      onClick={() => _deleteContent(index)}
                      className="deletePreview"
                    >
                      <img
                        src={
                          require("../../../../../assets/img/close.png").default
                        }
                        alt="close"
                      />
                      {/* <img
                        src={
                          require("../../../assets/img/close_grey.svg").default
                        }
                        height={15}
                        alt="delete"
                      /> 
                    </Button>
                  ) : null} */}
                </div>
              ))
            : null}
          {errors[`attatchments`] ? (
            <div className="validation-error">{errors[`attatchments`]}</div>
          ) : null}
          {/* <div className="uploadItem">
            <div className="removeIcon">
              <img
                src={require("../../../../../assets/img/close.png").default}
                alt="close"
              />
            </div>
            <img
              src={require("../../../../../assets/img/banner1.png").default}
              value
            />
          </div> */}
        </div>
        <FormGroup className={`withChips mt-3`}>
          <p className="mb-1 fs-12">Social Media to post on</p>
          <CreatableSelect
            isClearable
            placeholder="select"
            className="customMultiSelect"
            isMulti
            options={socialMedia}
            value={formFields.postedOn}
            // options={"sdfasd"}
            onChange={(event) => _onChange("postedOn", event)}
            blur={() => {
              _validateForm();
            }}
          />
          {errors[`postedOn`] ? (
            <div className="validation-error">{errors[`postedOn`]}</div>
          ) : null}
        </FormGroup>
        <CustomInput
          type="checkbox"
          checked={isChecked}
          onChange={() => setIsChecked(!isChecked)}
          id="exampleCustomCheckbox"
          label="Schedule Post"
        />

        {isChecked && (
          <FormGroup className="mt-2">
            <ReactDatetime
              inputProps={{
                className: "form-control",
                placeholder: "Schedule At",
                value: formatDateAndTime(formFields?.scheduledAt),
              }}
              onChange={(e) => _onChange("scheduledAt", e._d)}
              // onOpen={() =>
              //   this.setState({ isOpenReactDatetime: true })
              // }
              // onClose={() =>
              //   this.setState({ isOpenReactDatetime: false })
              // }
              value={
                formFields?.scheduledAt ? new Date(formFields?.scheduledAt) : ""
              }
              timeFormat={true}
              timeConstraints={{ minutes: { step: 15 } }}
            />
            {errors[`scheduledAt`] ? (
              <div className="validation-error">{errors[`scheduledAt`]}</div>
            ) : null}
          </FormGroup>
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="lg" outline onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          color="primary"
          size="lg"
          onClick={() => _editOrCreatePost()}
          disabled={loading}
        >
          {loading && <Spinner />}
          Post
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddPostModal;
