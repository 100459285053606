import React, { useState } from "react";
import {
  Card,
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import AgentSettingsInvoicing from "./agentsettingsInvoicing";
import { getAgentSettings } from "../../../http/http-calls";

const AgentAccountingAutomation = ({ agentSettings }) => {
  const [activeTab, setActiveTab] = useState("1");
  const _toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };
  return (
    <Card className="innerTabs">
        <Nav tabs className="greyBg">
          <NavItem>
            <NavLink
              className={activeTab === "1" ? "active" : ""}
              onClick={() => _toggleTab("1")}
            >
              Invoicing Automation
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <AgentSettingsInvoicing
              agentSettings={agentSettings}
              getAgentSettings={() => getAgentSettings()}
            />
          </TabPane>
        </TabContent>
    </Card>
  );
};

export default AgentAccountingAutomation;
