import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import StarRatings from "react-star-ratings";
import { createNewRating, editRating } from "../../../http/http-calls";
import { showToast, deepClone, errorHandler } from "../../../helper-methods";

class AddRatingModal extends Component {
  state = { rating: 5, comment: "", loading: false };

  _closeModal = (action) => {
    if (!this.state.loading) {
      this.props.toggle();
      this.props.ratingDetail();
    }
  };

  componentDidUpdate(previousProps, previousState) {
    const { isOpen, data } = this.props;

    if (isOpen && data && previousProps.isOpen !== isOpen) {
      this.setState({
        rating: data.previousRating !== null ? data.previousRating.rating : 5,
        comment:
          data.previousRating !== null ? data.previousRating.comments : "",
      });
    }
  }

  changeRating = (newRating, name) => {
    this.setState({
      rating: newRating,
    });
  };

  handleOnChange = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  _callAddOrEditApi = (data) => {
    if (this.props.mode === "edit") {
      this._editRating(data);
    } else {
      this._createNewRating(data);
    }
  };

  _createNewRating = (isShare) => {
    this.setState({ loading: true });

    const { rating, comment } = deepClone(this.state);
    let data = {
      rating: rating,
      comments: comment,
      closingId: this.props.data.id,
      isShare
    };
    createNewRating(data)
      .then((res) => {
        showToast("Successfully Created", "success");
        this.setState({ loading: false }, () => {
          this._closeModal();
          this.props.resetDetails();
        });
      })
      .catch((error) => {
        errorHandler(error);
        this.setState({ loading: false });
      });
  };

  _editRating = (isShare) => {
    this.setState({ loading: true });

    const { rating, comment } = deepClone(this.state);
    let data = {
      rating: rating,
      comments: comment,
      closingId: this.props.data.id,
      isShare
    };
    editRating(data)
      .then((res) => {
        showToast("Successfully Updated", "success");
        this.setState({ loading: false }, () => {
          this._closeModal();
          this.props.resetDetails();
        });
      })
      .catch((error) => {
        errorHandler(error);
        this.setState({ loading: false });
      });
  };

  render() {
    const { loading } = this.state;

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle(null)}
        className="modal-dialog-centered"
        size="lg"
      >
        <ModalHeader toggle={() => this.props.toggle(null)}>
          Rate This Closing
        </ModalHeader>
        <ModalBody>
          <div className="rating-stars mb-4 mt-1">
            <StarRatings
              rating={this.state.rating}
              starRatedColor="#FED82F"
              changeRating={this.changeRating}
              starHoverColor="#FED82F"
              numberOfStars={5}
              name="rating"
            />
          </div>

          <FormGroup className="floatingLabel">
            <Input
              type="textarea"
              rows="6"
              placeholder=" "
              value={this.state.comment}
              onChange={this.handleOnChange}
            />
            <Label>Comments</Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={() => this.props.toggle()}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={loading ? true : false}
            onClick={() => this._callAddOrEditApi()}
          >
            {this.props.mode === "edit" ? "Save" : "Add"}
          </Button>
          <Button
            color="primary"
            disabled={loading ? true : false}
            onClick={() => this._callAddOrEditApi(true)}
          >
            Save &amp; Share
          </Button>
        </ModalFooter>
        {loading && (
          <div className="table-overlay">
            <div>
              <i className="fa fa-spinner fa-spin" />
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

export default AddRatingModal;
