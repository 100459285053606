import React, { Component } from "react";
import {
  Button,
  Col,
  CustomInput,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { RegexConfig } from "../../../config/RegexConfig";
import { countryCodes } from "../../../config/country-codes";
import {
  deepClone,
  errorHandler,
  getPhoneNumberFromBrackets,
  showToast,
} from "../../../helper-methods";
import {
  createAgentInvite,
  enquiryAgentDetailsByEmail,
} from "../../../http/http-calls";
import {
  googlePlaceDetails,
  googlePlaceSearch,
} from "../../../helper-methods/googleService";
import usaStates from "../../../config/usa_states_titlecase";

const initialAddressState = {
  address: {
    value: "",
    placeId: null,
    error: null,
    isDirty: false,
  },
  line1: {
    value: "",
    error: null,
    isDirty: false,
  },
  city: {
    value: "",
    error: null,
    isDirty: false,
  },
  state: {
    value: "",
    error: null,
    isDirty: false,
  },
  zip: {
    value: "",
    error: null,
    isDirty: false,
  },
  country: {
    value: "",
    error: null,
    isDirty: false,
  },
  lat: {
    value: "",
    error: null,
    isDirty: false,
  },
  lng: {
    value: "",
    error: null,
    isDirty: false,
  },
};
class NewAgentModal extends Component {
  state = {
    formFields: {
      firstname: {
        value: "",
        error: null,
        isDirty: false,
      },
      lastname: {
        value: "",
        error: null,
        isDirty: false,
      },
      phone: {
        value: "",
        error: null,
        isDirty: false,
      },
      phoneCountry: {
        value: "+1",
        error: null,
        isDirty: false,
      },
      email: {
        value: "",
        error: null,
        isDirty: false,
      },
      type: {
        value: "",
        error: null,
        isDirty: false,
      },
      isManuallyCreatedGuestAgent: {
        value: false,
        error: null,
        isDirty: false,
      },
      ...initialAddressState,
    },
    googleAddressResult: [],
    isAddressListShow: false, // boolean,
    loading: false,
    enquiryLoading: false,
  };

  _resetModalState = () => {
    this.setState({
      formFields: {
        firstname: {
          value: "",
          error: null,
          isDirty: false,
        },
        lastname: {
          value: "",
          error: null,
          isDirty: false,
        },
        phone: {
          value: "",
          error: null,
          isDirty: false,
        },
        phoneCountry: {
          value: "+1",
          error: null,
          isDirty: false,
        },
        email: {
          value: "",
          error: null,
          isDirty: false,
        },
        type: {
          value: "",
          error: null,
          isDirty: false,
        },
        isManuallyCreatedGuestAgent: {
          value: false,
          error: null,
          isDirty: false,
        },
        ...initialAddressState,
      },
      googleAddressResult: [],
      isAddressListShow: false, // boolean
      loading: false,
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty) {
          switch (key) {
            case "firstname": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "lastname": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "type": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "email": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                if (
                  RegexConfig.email.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  this._enquiryAgentDetailsByEmail();
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "phone": {
              if (formFields[key].value.trim().length) {
                if (
                  RegexConfig.phone.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  isFormValid = false;
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Phone Number";
                }
              } else {
                // formFields[key].isDirty = false;
                // formFields[key].error = null;
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "address": {
              if (formFields?.isManuallyCreatedGuestAgent?.value) {
                if (formFields?.[key]?.placeId?.trim()?.length) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            default: {
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _googlePlaceSearch = (searchValue) => {
    clearTimeout(this.searchTimer);

    this.searchTimer = setTimeout(async () => {
      const googleAddressResult = await googlePlaceSearch(searchValue);
      this.setState({ googleAddressResult, isAddressListShow: true });
    }, 1000);
  };

  _getPlaceDetail = async (pid) => {
    const { formFields } = deepClone(this.state);

    let detail = await googlePlaceDetails(pid?.place_id);

    if (!detail) {
      errorHandler({ reason: "Invalid Address" });
      return;
    }

    if (detail?.postal) {
      const { address, city, state, postal, country, lat, lng } = detail;
      const stateAbbreviation = usaStates?.find(
        (s) => s?.name === state
      )?.abbreviation;

      formFields["address"].value = `${address},${city},${postal},${country}`;
      formFields["line1"].value = address;
      formFields["city"].value = city;
      formFields["state"].value = stateAbbreviation;
      formFields["zip"].value = postal;
      formFields["country"].value = country;
      formFields["lat"].value = lat;
      formFields["lng"].value = lng;
      formFields["address"].placeId = pid?.place_id;
    } else {
      errorHandler({ reason: "Invalid Address" });
      formFields["address"].placeId = null;
    }

    this.setState({
      formFields,
      isAddressListShow: false,
      googleAddressResult: [],
    });
  };

  _onChangeFormField = (fieldName, value) => {
    let { formFields } = deepClone(this.state);

    formFields[fieldName].value = value;
    formFields[fieldName].isDirty = true;

    if (fieldName === "isManuallyCreatedGuestAgent") {
      formFields = { ...formFields, ...initialAddressState };
      this.setState({ isAddressListShow: false, googleAddressResult: [] });
    }

    if (fieldName === "address") {
      this._googlePlaceSearch(value);
      formFields[fieldName].placeId = null;
    }

    this.setState({ formFields });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _inviteNewgent = async () => {
    try {
      await this._markAllFieldDirty();

      const isFormValid = await this._validateForm();

      if (!isFormValid) {
        return;
      }

      this.setState({ loading: true });
      const { formFields } = deepClone(this.state);

      const countryDialCode = countryCodes?.find(
        (obj) => obj?.dial_code === formFields?.phoneCountry?.value
      )?.code;

      let data = {
        name: {
          first: formFields?.firstname?.value,
          last: formFields?.lastname?.value,
        },
        email: formFields?.email?.value,
        phone: `(${formFields?.phoneCountry?.value})${formFields?.phone?.value}`,
        phoneCountry: countryDialCode,
        workType: formFields?.type?.value,
        isManuallyCreatedGuestAgent:
          formFields?.isManuallyCreatedGuestAgent?.value || false,
      };

      if (
        formFields?.isManuallyCreatedGuestAgent?.value &&
        formFields?.zip?.value &&
        formFields?.address?.placeId
      ) {
        data["address"] = {
          street:
            formFields?.line1?.value?.trim()?.length > 0
              ? formFields?.line1?.value?.trim()
              : undefined,
          city:
            formFields?.city?.value?.trim()?.length > 0
              ? formFields?.city?.value?.trim()
              : undefined,
          state:
            formFields?.state?.value?.trim()?.length > 0
              ? formFields?.state?.value?.trim()
              : undefined,
          zip:
            formFields?.zip?.value?.trim()?.length > 0
              ? formFields?.zip?.value?.trim()
              : undefined,
          country:
            formFields?.country?.value?.trim()?.length > 0
              ? formFields?.country?.value?.trim()
              : undefined,
          lat: formFields?.lat?.value ? formFields?.lat?.value : undefined,
          lng: formFields?.lng?.value ? formFields?.lng?.value : undefined,
        };
      }

      await createAgentInvite(data);
      showToast("Agent Invitation Sent Successfully", "success");
      this._closeModal();
    } catch (err) {
      errorHandler(err);
      this.setState({ loading: false });
    }
  };

  _closeModal = (action) => {
    this._resetModalState();
    this.props.toggle();
  };

  _setModalState = (data) => {
    this.setState({
      formFields: {
        ...this.state.formFields,
        firstname: {
          value: data?.name?.first,
          error: null,
          isDirty: false,
        },
        lastname: {
          value: data?.name?.last,
          error: null,
          isDirty: false,
        },
        phone: {
          value: data?.phone ? getPhoneNumberFromBrackets(data?.phone) : "",
          error: null,
          isDirty: false,
        },
        phoneCountry: {
          // value: data?.phoneCountry || "+1",
          value: "+1",
          error: null,
          isDirty: false,
        },
        email: {
          value: data?.email,
          error: null,
          isDirty: false,
        },
        type: {
          value: data?.workType,
          error: null,
          isDirty: false,
        },
      },
    });
  };

  _onBlurFormFields = (fieldName) => {
    const { formFields } = deepClone(this.state);
    formFields[fieldName].isDirty = true;

    this.setState({ formFields }, () => {
      // Validation
      this._validateForm();
    });
  };

  _enquiryAgentDetailsByEmail = async () => {
    try {
      const { formFields } = this.state;

      this.setState({ enquiryLoading: true });
      const payload = {
        email: formFields?.email?.value,
      };
      const { agent } = await enquiryAgentDetailsByEmail(payload);

      this._setModalState(agent);
      this.setState({ enquiryLoading: false });
    } catch (err) {
      this.setState({ enquiryLoading: false });
      errorHandler(err);
    }
  };

  render() {
    const {
      formFields,
      loading,
      enquiryLoading,
      isAddressListShow,
      googleAddressResult,
    } = deepClone(this.state);

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._closeModal()}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={() => this._closeModal()}>
          Invite New Agent {enquiryLoading ? <Spinner size="sm" /> : null}
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row form>
              <Col sm="6">
                <FormGroup className="floatingLabel">
                  <Input
                    type="text"
                    placeholder=" "
                    name="firstname"
                    value={formFields?.firstname?.value}
                    onChange={(e) =>
                      this._onChangeFormField("firstname", e.target.value)
                    }
                    onBlur={() => this._onBlurFormFields("firstname")}
                  />
                  <Label>First Name</Label>
                  {formFields?.firstname?.error && (
                    <div className="validation-error">
                      {formFields?.firstname?.error}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col sm="6">
                <FormGroup className="floatingLabel">
                  <Input
                    type="text"
                    placeholder=" "
                    name="lastname"
                    value={formFields?.lastname?.value}
                    onChange={(e) =>
                      this._onChangeFormField("lastname", e.target.value)
                    }
                    onBlur={() => this._onBlurFormFields("lastname")}
                  />
                  <Label>Last Name</Label>
                  {formFields?.lastname?.error && (
                    <div className="validation-error">
                      {formFields?.lastname?.error}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col sm="12">
                <FormGroup className="floatingLabel">
                  <Input
                    type="email"
                    placeholder=" "
                    name="email"
                    value={formFields?.email?.value}
                    onChange={(e) =>
                      this._onChangeFormField("email", e.target.value)
                    }
                    onBlur={() => this._onBlurFormFields("email")}
                  />
                  <Label>Email</Label>

                  {formFields?.email?.error && (
                    <div className="validation-error">
                      {formFields?.email?.error}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col sm="12">
                <FormGroup className="floatingLabel withInputGroup">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>+1</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      placeholder=" "
                      value={formFields?.phone?.value}
                      onChange={(e) =>
                        this._onChangeFormField("phone", e.target.value)
                      }
                      onBlur={() => this._onBlurFormFields("phone")}
                    />
                    <Label>Phone</Label>
                  </InputGroup>
                  {formFields?.phone?.error && (
                    <div className="validation-error">
                      {formFields?.phone?.error}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col sm="12">
                <FormGroup className="floatingLabel custom-select-wrapper">
                  <Input
                    type="select"
                    placeholder=" "
                    name="type"
                    value={formFields?.type?.value}
                    onChange={(e) =>
                      this._onChangeFormField("type", e.target.value)
                    }
                    onBlur={() => this._onBlurFormFields("type")}
                  >
                    <option>Select</option>
                    <option>Notary</option>
                    <option>Attorney</option>
                  </Input>
                  <Label>Type</Label>
                  {formFields?.type?.error && (
                    <div className="validation-error">
                      {formFields?.type?.error}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col sm="12">
                <CustomInput
                  id="isManuallyCreatedGuestAgent"
                  type="checkbox"
                  name="isManuallyCreatedGuestAgent"
                  className="mb-2"
                  checked={formFields?.isManuallyCreatedGuestAgent?.value}
                  onChange={(e) =>
                    this._onChangeFormField(
                      "isManuallyCreatedGuestAgent",
                      e.target.checked
                    )
                  }
                  label="Add as a guest"
                />

                {formFields?.isManuallyCreatedGuestAgent?.value ? (
                  <Col sm="12">
                    <FormGroup className="floatingLabel withInputGroup">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <img
                              src={
                                require("../../../assets/img/map-marker.svg")
                                  .default
                              }
                              alt="map icon"
                              height={14}
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder=" "
                          value={formFields?.address?.value}
                          name="address"
                          autoComplete="off"
                          onChange={(event) =>
                            this._onChangeFormField(
                              "address",
                              event.target.value
                            )
                          }
                          onBlur={() => this._onBlurFormFields("address")}
                        />
                        <Label>Location</Label>

                        {googleAddressResult &&
                        googleAddressResult?.length &&
                        isAddressListShow ? (
                          <ListGroup flush className="customSearchOptions">
                            {googleAddressResult?.map((addr, index) => {
                              return (
                                <ListGroupItem
                                  key={addr?.place_id || index}
                                  className="cursorPointer"
                                  onClick={() => this._getPlaceDetail(addr)}
                                >
                                  {addr?.description}
                                </ListGroupItem>
                              );
                            })}
                          </ListGroup>
                        ) : null}
                      </InputGroup>
                      {formFields?.address?.error && (
                        <div className="validation-error">
                          {formFields?.address?.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                ) : null}
              </Col>
            </Row>
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={() => this._closeModal()}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => this._inviteNewgent()}
            disabled={loading}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
            Invite
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default NewAgentModal;
