/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  CustomInput,
} from "reactstrap";
import TextEditor from "./TextEdittor";
import {
  deepClone,
  errorHandler,
  formatDateAndTime,
  getFileExtension,
  removeHTMLTags,
  showToast,
  uploadFileOnServer,
} from "../../../helper-methods";
import {
  TemplateCustomizationBodyVariable,
  TemplateCustomizationSubjectVariable,
} from "../../../config";
import ReactDatetime from "react-datetime";
import Select from "react-select";
import {
  agentFetchBulkEmailMessageTemplatesList,
  agentSendEmailOrMessageToClient,
} from "../../../http/http-calls";

let initialFields = {
  emailTo: [],
  templateId: "",
  subject: "",
  body: "",
  scheduleDateAndTime: "",
};

let initialIsDirty = {
  emailTo: false,
  templateId: false,
  subject: false,
  body: false,
  scheduleDateAndTime: false,
};

const AgentBulkActivityModal = ({
  isOpen,
  data,
  bulkActivityType,
  toggle,
  clientsSelectedRows,
}) => {
  const [formFields, setFormFields] = useState(deepClone(initialFields));
  const [emailToOptions, setEmailToOptions] = useState([]);
  const [templateIdOptions, setTemplateIdOptions] = useState([]);
  const [templateOptions, setTemplateOptions] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState(deepClone(initialIsDirty));
  const [isLoading, setIsLoading] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [isFocusTextEditor, setIsFocusTextEditor] = useState(false);
  const [isOpenReactDatetime, setIsOpenReactDatetime] = useState(false);

  const _resetFields = () => {
    setFormFields(deepClone(initialFields));
    setIsDirty(deepClone(initialIsDirty));
    setErrors({});
    setEmailToOptions([]);
    setTemplateIdOptions([]);
    setTemplateOptions([]);
    setSelectedFiles([]);
    setIsChecked(false);
    setIsLoading(false);
    // console.log("reset",emailFields);
  };

  const _closeModal = () => {
    _resetFields();
    toggle();
  };

  const _onChangeFormFields = (fieldName, value) => {
    // console.log("dd", fieldName, value);
    const newFormFields = { ...formFields };
    const newIsDirty = { ...isDirty };

    // if (fieldName === "emailTo") {
    //   newFormFields[fieldName] = value;
    // } else {
    //   newFormFields[fieldName] = value;
    // }
    newFormFields[fieldName] = value;
    newIsDirty[fieldName] = true;

    setFormFields(newFormFields);
    setIsDirty(newIsDirty);

    _validateForm({ newFormFields, newIsDirty });
  };

  const _validateForm = ({ newFormFields, newIsDirty }) => {
    // console.log("123", newFormFields, newIsDirty);
    return new Promise((resolve) => {
      let isFormValid = true;
      const newErrors = { ...errors };

      Object.keys(newFormFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "emailTo": {
              // console.log("11", newFormFields?.emailTo);
              if (newFormFields?.emailTo?.length) {
                newIsDirty[key] = false;
                delete newErrors[key];
              } else {
                isFormValid = false;
                newErrors[key] = "*Required";
              }

              break;
            }

            // case "templateId": {
            //   console.log("11", newFormFields?.templateId);
            //   if (bulkActivityType === "Email") {
            //     if (
            //       newFormFields?.templateId &&
            //       newFormFields?.templateId?.value?.length
            //     ) {
            //       newIsDirty[key] = false;
            //       delete newErrors[key];
            //     } else {
            //       isFormValid = false;
            //       newErrors[key] = "*Required";
            //     }
            //   } else {
            //     newIsDirty[key] = false;
            //     delete newErrors[key];
            //   }

            //   break;
            // }

            case "subject": {
              // console.log("11", newFormFields?.subject);
              if (bulkActivityType === "Email") {
                if (
                  newFormFields?.subject &&
                  newFormFields?.subject?.trim()?.length
                )
                  if (
                    newFormFields?.subject?.trim()?.length < 2 ||
                    newFormFields?.subject?.trim()?.length > 500
                  ) {
                    isFormValid = false;
                    newErrors[key] = "*Subject should be 2-500 characters long";
                  } else {
                    newIsDirty[key] = false;
                    delete newErrors[key];
                  }
                else {
                  isFormValid = false;
                  newErrors[key] = "*Required";
                }
              } else {
                newIsDirty[key] = false;
                delete newErrors[key];
              }

              break;
            }

            case "body": {
              // console.log("11", newFormFields?.body);
              if (newFormFields?.body?.replace(/<[^>]+>/g, "")?.trim()?.length)
                if (bulkActivityType === "Email") {
                  if (
                    newFormFields?.body?.replace(/<[^>]+>/g, "")?.trim()
                      ?.length < 2 ||
                    newFormFields?.body?.replace(/<[^>]+>/g, "")?.trim()
                      ?.length > 2000
                  ) {
                    isFormValid = false;
                    newErrors[key] = "*Body should be 2-2000 characters long";
                  } else {
                    newIsDirty[key] = false;
                    delete newErrors[key];
                  }
                } else {
                  if (
                    newFormFields?.body?.replace(/<[^>]+>/g, "")?.trim()
                      ?.length < 2 ||
                    newFormFields?.body?.replace(/<[^>]+>/g, "")?.trim()
                      ?.length > 160
                  ) {
                    isFormValid = false;
                    newErrors[key] = "*Body should be 2-160 characters long";
                  } else {
                    newIsDirty[key] = false;
                    delete newErrors[key];
                  }
                }
              else {
                isFormValid = false;
                newErrors[key] = "*Required";
              }

              break;
            }

            case "scheduleDateAndTime": {
              // console.log("11", newFormFields?.scheduleDateAndTime);
              if (isChecked) {
                if (newFormFields?.scheduleDateAndTime) {
                  newIsDirty[key] = false;
                  delete newErrors[key];
                } else {
                  isFormValid = false;
                  newErrors[key] = "*Required";
                }
              }

              break;
            }

            default: {
            }
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _handleOnCopy = (fieldName, text) => {
    // console.log("45", fieldName, text);
    _onChangeFormFields(fieldName, `${formFields[fieldName]} ${text}`);
  };

  console.log("bulkActivityType: ", bulkActivityType);
  console.log("clientsSelectedRows: ", clientsSelectedRows);

  const _fetchEmailMessageTemplateList = async () => {
    try {
      const payload = {
        type: bulkActivityType,
      };
      const res = await agentFetchBulkEmailMessageTemplatesList(payload);
      console.log("res: ", res);
      setTemplateOptions(res?.bulkEmailMessagetemplateList || []);

      const templateOptionsCofig =
        res?.bulkEmailMessagetemplateList?.map((each) => ({
          value: each?.id,
          label: each?.title || "",
        })) || [];

      setTemplateIdOptions(templateOptionsCofig);
    } catch (err) {
      errorHandler(err);
    }
  };

  const _onFileMultiSelect = (fieldName, e) => {
    // console.log("files", e.target.files);
    let fileList = Array.from(e.target.files);
    let files = [...selectedFiles];
    // console.log("files: ", files);
    console.log("fileList: ", fileList);

    for (let i = 0; i < fileList.length; i++) {
      // let image = fileList[i];
      // console.log("curr file: ", fileList[i]);

      if (
        fileList[i]?.type?.split("/")[1] !== "png" &&
        fileList[i]?.type?.split("/")[1] !== "jpg" &&
        fileList[i]?.type?.split("/")[1] !== "jpeg" &&
        fileList[i]?.type?.split("/")[1] !== "pdf" &&
        fileList[i]?.type?.split("/")[1] !== "doc"
      ) {
        errorHandler({
          reason:
            "Only file with the following extensions are allowed: png, jpg, jpeg, pdf, doc",
        });
        continue;
      }

      files.unshift({
        previewBlob: URL.createObjectURL(fileList[i]),
        uploadData: fileList[i],
        type:
          fileList[i]?.type?.split("/")[0] === "application"
            ? "pdf"
            : fileList[i]?.type?.split("/")[0] || "",
      });
    }

    console.log("files: ", files);
    setSelectedFiles(files);
    // this.setState({ [fieldName]: files });
  };

  const _renderFileOnType = (type, item) => {
    switch (type) {
      case "pdf":
        return (
          <i
            className="fa fa-file-pdf-o docIcon"
            // onClick={() => this._onToggleDocumentViewModal(item, true)}
          />
        );
        break;

      case "docx":
      case "doc":
        return (
          <i
            className="fa fa-file-text-o docIcon"
            // onClick={() => this._onToggleDocumentViewModal(item, true)}
          />
        );
        break;

      case "jpg":
      case "png":
        return (
          <img
            src={item.previewBlob ? item.previewBlob : item}
            alt="Img"
            // onClick={() => this._onToggleDocumentViewModal(item, true)}
          />
        );
      default:
    }
  };
  const _deleteImage = (arrayName, previewUrl) => {
    console.log(arrayName, previewUrl);

    if (arrayName === "selectedFiles") {
      let newArray;

      if (previewUrl.previewBlob) {
        newArray = selectedFiles.filter(
          (item) => item.previewBlob !== previewUrl.previewBlob
        );
      } else {
        newArray = selectedFiles.filter((item) => item !== previewUrl);
      }

      setSelectedFiles(newArray);
    }
  };

  const _onSubmit = async () => {
    console.log("submit func called...");
    try {
      setIsLoading(true);
      const newFormFields = { ...formFields };

      const newIsDirty = {
        emailTo: true,
        templateId: true,
        subject: true,
        body: true,
        scheduleDateAndTime: isChecked || false,
      };
      setIsDirty(newIsDirty);

      const isFormValid = await _validateForm({ newFormFields, newIsDirty });

      if (!isFormValid) {
        setIsLoading(false);
        return; // form not valid -> return
      }

      const payload = {};

      payload["agentClientIds"] = formFields?.emailTo
        ? formFields?.emailTo?.map((each) => each?.value)
        : [];
      if (bulkActivityType === "Email") {
        payload["subject"] = formFields?.subject || "";
      }
      payload["body"] =
        bulkActivityType === "Email"
          ? formFields?.body
          : removeHTMLTags(formFields?.body);
      payload["type"] = bulkActivityType || "";

      if (isChecked) {
        payload["scheduleDateAndTime"] = formFields?.scheduleDateAndTime || "";
      }

      if (formFields?.templateId?.value) {
        payload["templateId"] = formFields?.templateId?.value || "";
      }

      if (selectedFiles && selectedFiles?.length) {
        const uploadedFilesRes = await uploadFileOnServer(selectedFiles);
        console.log("uploadRes: ", uploadedFilesRes);

        const files = uploadedFilesRes?.map((each) => ({
          contentType: each?.docType || "",
          title: each?.title || "",
          url: each?.url,
        }));

        payload["files"] = files;
        payload["isAttachment"] = true;
      }

      console.log("payload: ", payload);
      await agentSendEmailOrMessageToClient(payload);

      _closeModal();
      showToast("Sent Successfully", "success");
      setIsLoading(false);
    } catch (err) {
      errorHandler(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!clientsSelectedRows?.length || !bulkActivityType) return;

    const newFormFields = { ...formFields };

    if (bulkActivityType === "Email") {
      console.log({ clientsSelectedRows });
      const newEmailToOptions = clientsSelectedRows?.map((each) => ({
        value: each?._id || each?.id || "",
        // label: each?.name?.full || each?.companyName || "",
        label: each?._signingCompany
          ? each.companyName
          : each?._client || each?._assistant
          ? each.name?.full
          : "N/A",
      }));

      setEmailToOptions(newEmailToOptions);

      newFormFields["emailTo"] = newEmailToOptions;
      setFormFields(newFormFields);
    } else if (bulkActivityType === "Message") {
      const newEmailToOptions = clientsSelectedRows?.map((each) => ({
        value: each?._id || each?.id || "",
        // label: each?.name?.full || each?.companyName || each?.email || "",
        label: each?._signingCompany
          ? each.companyName
          : each?._client || each?._assistant
          ? each.name?.full
          : "N/A",
      }));

      setEmailToOptions(newEmailToOptions);

      newFormFields["emailTo"] = newEmailToOptions;
      setFormFields(newFormFields);
    }

    _fetchEmailMessageTemplateList();
  }, [bulkActivityType]);

  useEffect(() => {
    if (!formFields?.templateId?.value) return;

    const newFormFields = { ...formFields };
    const findTemplate = templateOptions?.find(
      (each) => each?.id === formFields?.templateId?.value
    );

    // console.log("findTemplate: ", findTemplate);

    newFormFields["body"] = findTemplate?.body;

    if (bulkActivityType === "Email")
      newFormFields["subject"] = findTemplate?.subject;

    setFormFields(newFormFields);
  }, [formFields.templateId]);

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} scrollable centered>
      <ModalHeader toggle={() => _closeModal()}>
        Send Bulk {bulkActivityType === "Email" ? "Email" : "Message"}
      </ModalHeader>
      <ModalBody>
        <FormGroup className={`withChips`} style={{ zIndex: 4 }}>
          <Select
            isClearable
            placeholder={`Send ${
              bulkActivityType === "Email" ? "Email" : "Message"
            } To`}
            className="customMultiSelect"
            isMulti
            // components={["dummy text", "demo1"]}
            value={formFields?.emailTo}
            options={emailToOptions?.length ? emailToOptions : []}
            onChange={(value) => _onChangeFormFields("emailTo", value)}
            blur={() => {
              this._validateForm();
            }}
          />
          {errors?.emailTo && (
            <div className="validation-error">{errors?.emailTo}</div>
          )}
        </FormGroup>
        <FormGroup className="floatingLabel" style={{ zIndex: 3 }}>
          <div className="custom-select-wrapper">
            <Select
              options={templateIdOptions}
              value={formFields?.templateId}
              onChange={(event) => _onChangeFormFields("templateId", event)}
              className="customMultiSelect"
              placeholder="Choose Template"
              closeMenuOnSelect={true}
              isClearable
            />
            {/* <Label>Choose Template</Label> */}
            {errors?.templateId && (
              <div className="validation-error">{errors?.templateId}</div>
            )}
          </div>
        </FormGroup>

        {bulkActivityType && bulkActivityType === "Email" ? (
          <div>
            <UncontrolledDropdown className="customDropdown rightSide">
              <DropdownToggle>
                Insert Variables{" "}
                <img
                  src={
                    require("../../../assets/img/arrow-dropdown.svg").default
                  }
                  alt="arrow"
                />
              </DropdownToggle>
              <DropdownMenu right>
                {TemplateCustomizationSubjectVariable?.map((item, index) => (
                  <>
                    <DropdownItem
                      key={index}
                      onClick={() => _handleOnCopy("subject", item)}
                    >
                      {item}
                    </DropdownItem>
                  </>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
            <FormGroup className="floatingLabel">
              <Input
                placeholder=" "
                value={formFields?.subject}
                onChange={(e) => _onChangeFormFields("subject", e.target.value)}
              />
              <Label>Subject</Label>
              {errors?.subject && (
                <div className="validation-error">{errors?.subject}</div>
              )}
            </FormGroup>
          </div>
        ) : null}
        <div>
          <UncontrolledDropdown className="customDropdown rightSide">
            <DropdownToggle>
              Insert Variables{" "}
              <img
                src={require("../../../assets/img/arrow-dropdown.svg").default}
                alt="arrow"
              />
            </DropdownToggle>
            <DropdownMenu right>
              {TemplateCustomizationBodyVariable?.map((item, index) => (
                <>
                  <DropdownItem
                    key={index}
                    onClick={() => _handleOnCopy("body", item)}
                  >
                    {item}
                  </DropdownItem>
                </>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>

          {/* change this text editor to WYSIWYG editor */}
          {/* <FormGroup className="floatingLabel"> */}
          {/* <Input type="textarea" row={10} placeholder=" " /> */}
          <FormGroup
            className={`mb-0 floatingLabel reactQuill ${
              (formFields?.body && formFields?.body !== "<p><br></p>") ||
              isFocusTextEditor
                ? "valueAdded"
                : ""
            }`}
          >
            {bulkActivityType && bulkActivityType === "Email" ? (
              <TextEditor
                content={formFields?.body}
                onChange={(e) => _onChangeFormFields("body", e)}
                onFocus={() => setIsFocusTextEditor(true)}
                onBlur={() => setIsFocusTextEditor(false)}
              />
            ) : (
              <Input
                style={{ height: "150px" }}
                type="textarea"
                row={10}
                placeholder=" "
                value={formFields?.body}
                onChange={(e) => _onChangeFormFields("body", e.target.value)}
              />
            )}
            <Label>Body</Label>
            {errors?.body && (
              <div className="validation-error">{errors?.body}</div>
            )}
          </FormGroup>
          {/* </FormGroup> */}
        </div>

        {/* <div className="my-2">
          <Label className=" uploadBtn px-2">
            <input
              type="file"
              style={{ display: "none" }}
              // accept=".csv"
              accept="application/pdf,.png, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel image/png, image/jpeg"
              onChange={(e) => _onFileMultiSelect("selectedFiles", e)}
              multiple
            />
            Add Attachment
          </Label>

          <div className="d-flex mt-2 overflow-auto">
            {selectedFiles?.length > 0 &&
              selectedFiles?.map((item) => {
                return (
                  <div className="taskAttachment mr-2 my-2">
                    {!item.previewBlob &&
                      _renderFileOnType(getFileExtension(item), item)}
                    {item.previewBlob &&
                      _renderFileOnType(
                        getFileExtension(item?.uploadData?.name),
                        item
                      )}{" "}
                    {item?.uploadData?.name ? item?.uploadData?.name : null}
                    <Button
                      className="removeAttachment"
                      onClick={() => _deleteImage("selectedFiles", item)}
                    >
                      <i className="fa fa-times" />
                    </Button>
                  </div>
                );
              })}
          </div>
        </div> */}

        {bulkActivityType === "Email" ? (
          <div className="attachementWrap mb-2">
            <div className="uploadItem">
              <Label className="">
                <input
                  type="file"
                  style={{ display: "none" }}
                  // accept=".csv"
                  accept="application/pdf,.png, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel image/png, image/jpeg"
                  onChange={(e) => _onFileMultiSelect("selectedFiles", e)}
                  multiple
                />
                <img
                  src={require("../../../assets/img/addItem.svg").default}
                  value
                  alt="add"
                />
              </Label>
            </div>

            <div className="d-flex flex-wrap flex-1">
              {selectedFiles?.length > 0 &&
                selectedFiles?.map((item) => {
                  return (
                    <div className="uploadItem mb-2">
                      {!item.previewBlob &&
                        _renderFileOnType(getFileExtension(item), item)}
                      {item.previewBlob &&
                        _renderFileOnType(
                          getFileExtension(item?.uploadData?.name),
                          item
                        )}{" "}
                      <div
                        className="removeIcon"
                        onClick={() => _deleteImage("selectedFiles", item)}
                      >
                        <img
                          src={require("../../../assets/img/close.png").default}
                          alt="close"
                        />
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        ) : null}

        <div style={{ zIndex: 0, marginTop: 20 }}>
          <CustomInput
            type="checkbox"
            checked={isChecked}
            onChange={() => setIsChecked(!isChecked)}
            id="exampleCustomCheckbox"
            label="Schedule"
          />

          {isChecked && (
            <FormGroup
              className={`floatingLabel mt-3 ${
                formFields?.scheduleDateAndTime
                  ? //  || this.state.isOpenReactDatetime
                    "valueAdded"
                  : ""
              }`}
            >
              <ReactDatetime
                inputProps={{
                  className: "form-control customTimeWrap",
                  placeholder: " ",
                  value: formatDateAndTime(formFields?.scheduleDateAndTime),
                }}
                onChange={(e) =>
                  _onChangeFormFields(
                    "scheduleDateAndTime",
                    e?._d ? new Date(e?._d)?.toISOString() : ""
                  )
                }
                onOpen={() => setIsOpenReactDatetime(true)}
                onClose={() => setIsOpenReactDatetime(false)}
                // isValidDate={(current) =>
                //   current.isAfter(new Date().setDate(new Date().getDate() - 1))
                // }
                isValidDate={(current) => current.isAfter(new Date())}
                timeFormat={true}
                timeConstraints={{ minutes: { step: 15 } }}
                isClearable
              />
              <Label>Select Time</Label>
              {errors?.scheduleDateAndTime && (
                <div className="validation-error">
                  {errors?.scheduleDateAndTime}
                </div>
              )}
            </FormGroup>
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="lg" outline onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          color="primary"
          size="lg"
          disabled={isLoading}
          onClick={() => _onSubmit()}
        >
          {isLoading ? <i className="fa fa-spinner fa-spin mr-1" /> : null} Send
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AgentBulkActivityModal;
