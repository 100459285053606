import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
} from "reactstrap";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {
  capitalize,
  errorHandler,
  formatOnlyDateMoment,
  openUrlOnNewTab,
  structureQueryParams,
} from "../../../../../helper-methods";
import { BASE_URL } from "../../../../../config/index";
import { useSelector } from "react-redux";

const OrderDashboardExport = ({ isOpen, data, toggle }) => {
  const userData = useSelector((state) => state?.userData);

  const [dateRangeValue, setDateRangeValue] = useState(null);

  const _onChangeDatePicker = (dateRangeValue) => {
    setDateRangeValue(dateRangeValue);
  };

  const _resetFilter = () => {
    setDateRangeValue(null);
  };
  const _onClose = () => {
    _resetFilter();
    toggle();
  };

  const _onSubmit = async () => {
    try {
      const params = {
        token: userData?.token,
        startDate:
          dateRangeValue !== null
            ? formatOnlyDateMoment(dateRangeValue[0])
            : undefined,
        endDate:
          dateRangeValue !== null
            ? formatOnlyDateMoment(dateRangeValue[1])
            : undefined,
      };

      if (params.startDate === undefined) {
        delete params.startDate;
      }
      if (params.endDate === undefined) {
        delete params.endDate;
      }

      const queryString = structureQueryParams(params);
      if (data?.type === "invoice") {
        openUrlOnNewTab(
          `${BASE_URL}/signingcompany/export/incomes/${queryString}`
        );
      } else if (data?.type === "bill") {
        openUrlOnNewTab(
          `${BASE_URL}/signingcompany/export/pay-period/${queryString}`
        );
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={_onClose}>
        <ModalHeader toggle={_onClose}>
          Export {capitalize(data?.type)}
        </ModalHeader>
        <ModalBody>
          <FormGroup>
            <Label className="d-block">Date Range</Label>

            <DateRangePicker
              className="dateRange"
              format="MM-dd-y"
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
              onChange={(dateRangeValue) => _onChangeDatePicker(dateRangeValue)}
              value={dateRangeValue}
            />
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={_onClose}>
            Cancel
          </Button>
          <Button color="primary" onClick={() => _onSubmit()}>
            Export
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default OrderDashboardExport;
