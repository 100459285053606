/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { NavLink } from "react-router-dom";
import {
  Nav,
  NavItem,
  Collapse,
  Button,
  NavLink as ReactstrapLink,
} from "reactstrap";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { store as REDUX_STORE } from "../../../redux/store";

import {
  convertHexToRgbColor,
  errorHandler,
  isProPlusUser,
  isRegularUser,
  logout,
  setThemeColor,
  showToast,
  updateCompanyName,
  updateFavIcon,
} from "../../../helper-methods/index";
import { checkPermission } from "../../../helper-methods";
import UpgradeAccountModal from "../components/Modals/upgradeAccountModal";
import {
  app_logo,
  //  DEFAULT_PROFILE_PICTURE
} from "../../../config";
import { Link } from "react-router-dom";
import {
  getAgentProfileDetail,
  getClientProfileDetail,
  removeDeviceId,
  signingCompanyDetail,
} from "../../../http/http-calls";
import { disconnectToSocket } from "../../../socket-io";
import { updateUserObj } from "../../../redux/actions/user-data";
import { connect } from "react-redux";
import SvgIcons from "./SvgIcons";
import UpgradeProfessionalAccountModal from "./Modals/upgradeProfessionalAccountModal";

var ps;

class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      route: this.getCollapseStates(props.routes),
      upgradeAccountModal: {
        isOpen: false,
        data: null,
      },
      upgradeProAccountModal: {
        isOpen: false,
        data: null,
      },
    };
  }
  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates = (routes) => {
    let initialState = {};
    routes.map((prop, key) => {
      if (prop.collapse) {
        initialState = {
          [prop.state]: this.getCollapseInitialState(prop.views),
          ...this.getCollapseStates(prop.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  };
  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      } else if (window.location.pathname.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  _closeCollapseBar = (routeName) => {
    const { route } = this.state;

    Object.keys(route).forEach((each) => {
      if (routeName.includes(each.split("Collapse")[0])) {
        route[each] = true;
      } else {
        route[each] = false;
      }
    });

    this.setState({ route });
  };

  _hideCollapsible = (prop) => {
    // console.log("isTitleCompany", userData?.user?.isTitleCompany);
    if (prop.name === "Accounting") {
      return !this.props.userData?.user?.isTitleCompany;
    } else {
      return true;
    }
  };

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks = (routes) => {
    const { userData } = this.props;
    return routes.map((prop, key) => {
      if (prop.redirect) {
        return null;
      }
      if (prop.collapse) {
        var st = {};
        st[prop["state"]] = !this.state.route[prop.state];
        return (
          this._hideCollapsible(prop) && (
            <li
              className={
                this.getCollapseInitialState(prop.views) ? "active" : ""
              }
              key={key}
            >
              <a
                href="#pablo"
                data-toggle="collapse"
                aria-expanded={this.state.route[prop.state]}
                onClick={(e) => {
                  e.preventDefault();
                  this.setState({ route: st });
                }}
              >
                {prop.imgIcon ? (
                  <>
                    {/* <img src={prop.imgIcon} alt="icon" /> */}
                    <SvgIcons type={prop.svgIcon} />
                    <span>{prop.name}</span>

                    <img
                      src={require("../../../assets/img/arrow.png").default}
                      alt="icon"
                      className="arrowIcon"
                    />
                  </>
                ) : (
                  <>
                    <span>{prop.name}</span>
                    <img
                      src={require("../../../assets/img/arrow.png").default}
                      alt="icon"
                      className="arrowIcon"
                    />
                  </>
                )}
              </a>

              <Collapse
                className="innerMenu"
                isOpen={this.state.route[prop.state]}
              >
                <ul className="nav">{this.createLinks(prop.views)}</ul>
              </Collapse>
            </li>
          )
        );
      }
      return (
        this._showInSideBarOrNot(prop.name) && (
          <li
            className={this.activeRoute(prop.path)}
            key={key}
            onClick={(e) => this._navigatePage(prop, e)}
          >
            <NavLink
              to={prop.path}
              activeClassName=""
              className="customSidebarList"
              onClick={() => this._closeCollapseBar(prop.path)}
            >
              {prop.imgIcon ? (
                <>
                  {/* <img src={prop.imgIcon} alt="icon" /> */}
                  <SvgIcons type={prop.svgIcon} />
                  <span>
                    {userData.user?.isAssistant &&
                    prop.name === "Company Profile"
                      ? "Profile"
                      : prop.name}
                  </span>
                </>
              ) : (
                <>
                  <span>{prop.name}</span>
                </>
              )}
            </NavLink>
          </li>
        )
      );
    });
  };

  _navigatePage = (prop, e) => {
    const checkAndPrevent = (modalToggleFunc, condition) => {
      if (condition) {
        modalToggleFunc(true);
        e.preventDefault();
        e.stopPropagation();
        return true;
      }
      return false;
    };

    if (
      (prop.path === "/agent/reporting/status-report" ||
        prop.path === "/agent/reporting/summaries") &&
      !isRegularUser()
    ) {
      return checkAndPrevent(this._toggleUpgradeAccountModal, true);
    } else if (
      (prop.path === "/agent/marketing/personal-website-management" ||
        prop.path === "/agent/marketing/templates" ||
        prop.path === "/agent/marketing/social-media-management" ||
        prop.path === "/agent/query-center") &&
      !isProPlusUser()
    ) {
      return checkAndPrevent(this._toggleProfessionalUpgradeAccountModal, true);
    }

    return null;
  };

  // check this link should be shown in sidebar
  _showInSideBarOrNot = (pageName) => {
    const userType = this.props.userData?.user?.type?.toLowerCase();
    const userData = this.props.userData;
    switch (userType) {
      case "agent": {
        if (pageName === "Terms") {
          return false;
        }
        if (pageName === "search console") {
          if (
            !userData?.user?.isDnsVerifiedForSearchConsole &&
            !isProPlusUser()
          ) {
            console.log("search console not show");
            return false;
          }
        }
        return true;
      }
      case "signingcompany": {
        switch (pageName) {
          case "Closing Master Schedule":
            return checkPermission("masterSchedule", "canView");

          case "Invoicing":
            return (
              !userData.user.isTitleCompany &&
              checkPermission("accounting", "invoicing")
            );

          case "Income":
            return (
              !userData.user.isTitleCompany &&
              checkPermission("accounting", "income")
            );

          case "Expenses":
            return (
              !userData.user.isTitleCompany &&
              checkPermission("accounting", "expense")
            );

          case "Agent Pay Breakdown":
            return checkPermission("accounting", "agentPayPeriod");

          case "Agent Schedule":
            return checkPermission("reporting", "agentSchedule");

          case "Status Report":
            return checkPermission("reporting", "statusReport");

          case "Profit & Loss":
            return (
              !userData.user.isTitleCompany &&
              checkPermission("reporting", "profitLoss")
            );

          case "Summaries":
            return checkPermission("reporting", "summaries");

          case "Team Members":
            return (
              checkPermission("users", "canView") ||
              checkPermission("users", "canAdd")
            );

          case "Agents":
            return (
              checkPermission("agents", "canAdd") ||
              checkPermission("agents", "canUpdate")
            );

          case "Clients":
            return (
              checkPermission("clients", "canAdd") ||
              checkPermission("clients", "canUpdate")
            );
          case "Order Client":
            return checkPermission("lenderClient", "canView");
          case "Signing Companies":
            return checkPermission("connectCompanies", "canView");
          case "Client Payment":
            return checkPermission("payment", "canView");

          case "Subscription":
            return userData.user?.isAssistant ? false : true;

          case "Settings":
            return userData.user?.isAssistant ? false : true;

          case "Terms":
            return false;

          case "Ach Settings":
            return userData.user?.isAssistant ? false : true;

          default:
            return true;
        }
      }
      case "client": {
        switch (pageName) {
          case "Order Client":
            return checkPermission("lenderClient", "canView");
          case "Connected Companies":
            return userData?.user?.whiteLabelSetting
              ? false
              : checkPermission("connectCompanies", "canView");
          case "Connect Pay":
            return checkPermission("payment", "canView");
          case "Clients Management":
            return false;
          // return checkPermission("lenderClient", "canView");
          case "Team Members":
            return userData.user?.isAssistant ? false : true;
          case "Client Payment":
            return checkPermission("payment", "canView");
          case "Settings":
            return userData.user?.isAssistant ? false : true;
          case "Terms":
            return false;
          default:
            return true;
        }
      }
      default:
        return true;
    }
  };

  // verifies if routeName is the one active (in browser input)
  activeRoute = (routeName) => {
    return this.props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  componentDidMount() {
    // if you are using a Windows Machine, the scrollbars will have a Mac look
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(this.refs.sidebar, {
        suppressScrollX: true,
        suppressScrollY: false,
      });
    }
    if (
      (this.props.userData.user.type.toLowerCase() === "signingcompany" ||
        this.props.userData.user.type.toLowerCase() === "client") &&
      this.props.userData.user?.whiteLabelSetting
      // this.props.userData.user?.siteOwnerDetail?.whiteLabelSetting
    ) {
      // const element = document.getElementById("selectingHtml");

      // element.classList.add(
      //   this.props.userData.user?.siteOwnerDetail?.whiteLabelSetting
      //     .customizedUiSetting.primary
      // );

      // localStorage.setItem(
      //   "UIColor",
      //   this.props.userData.user?.siteOwnerDetail?.whiteLabelSetting
      //     .customizedUiSetting.primary
      // );
      // this._setThemeColor()
      if (!this.props.userData.user?.whiteLabelSetting?.isDefaultUiSelected) {
        let whiteLabelSetting =
          this.props.userData.user?.whiteLabelSetting?.customizedUiSetting;

        setThemeColor(whiteLabelSetting);
      } else {
        let whiteLabelSetting =
          this.props.userData.user?.whiteLabelSetting?.defaultUiSetting;

        // let matchingColorScheme = _findMatchingColorScheme(
        //   whiteLabelSetting,
        //   colorsUI
        // );
        setThemeColor(whiteLabelSetting);
      }
      if (this.props.userData?.user?.isWhiteLabelUser) {
        updateCompanyName(this.props.userData.user);
        updateFavIcon(this.props.userData.user?.whiteLabelSetting);
      }
    }
  }

  _findMatchingColorScheme = (targetObject, colorsUI) => {
    console.log("wer", targetObject, colorsUI);

    let foundKey = null;

    for (const key in colorsUI) {
      if (JSON.stringify(colorsUI[key]) === JSON.stringify(targetObject)) {
        foundKey = key;
        break;
      }
    }
    return foundKey;
  };

  componentWillUnmount() {
    // we need to destroy the false scrollbar when we navigate
    // to a page that doesn't have this component rendered
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    // console.log(
    //   "cdu",
    //   this.props.userData.user?.siteOwnerDetail?.whiteLabelSetting
    //     ?.customizedUiSetting?.primary !==
    //     prevProps.userData.user?.siteOwnerDetail?.whiteLabelSetting
    //       ?.customizedUiSetting?.primary
    // );

    if (
      this.props.userData.user?.whiteLabelSetting?.customizedUiSetting
        ?.primary !==
      prevProps?.userData.user?.whiteLabelSetting?.customizedUiSetting?.primary
    ) {
      // Perform some action when `someValue` prop changes

      const element = document.getElementById("selectingHtml");

      element.classList.add(
        this.props.userData.user?.whiteLabelSetting.customizedUiSetting.primary
      );
      localStorage.setItem(
        "UIColor",
        this.props.userData.user?.whiteLabelSetting.customizedUiSetting.primary
      );

      // updateCompanyNameAndFavIcon(this.props.userData.user?.whiteLabelSetting);
    }
  }
  _truncate = (str) => {
    let word = str.length > 20 ? str.substring(0, 20) + "..." : str;
    return word;
  };

  _toggleUpgradeAccountModal = (isOpen = false, data = null) => {
    // if (this.props.location.pathname !== "/agent/subscription") {
    this.setState({
      upgradeAccountModal: {
        isOpen,
        data,
      },
    });
    // }
  };
  _toggleProfessionalUpgradeAccountModal = (isOpen = false, data = null) => {
    this.setState({
      upgradeProAccountModal: {
        isOpen,
        data,
      },
    });
  };

  _redirectToHelpPage = () => {
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/help`);
  };

  _redirectToGleapHelpPage = () => {
    const { userData } = this.props;

    switch (userData?.type) {
      case "agent": {
        window.open("https://notarysupport.closewise.com/en");
        break;
      }
      case "signingcompany": {
        window.open("https://companysupport.closewise.com/en");
        break;
      }
      case "client": {
        window.open("https://clientsupport.closewise.com/en");
        break;
      }

      default:
    }
  };

  _onLogout = async () => {
    try {
      await removeDeviceId({ deviceId: localStorage.getItem("deviceId") });
    } catch (error) {
      errorHandler(error);
    }
    this._disconnectToSocket();
    logout(this.props.history);
  };

  _disconnectToSocket = () => {
    disconnectToSocket()
      .then((flag) => {
        console.log("disconnected>>", flag);
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getlogo = () => {
    const userType = this.props.userData?.user?.type?.toLowerCase();

    console.log("wd", userType);
    switch (userType) {
      case "agent":
        this._getAgentProfileDetail();

        break;
      case "signingcompany":
        this._getSigningCompanyDetail();

        break;
      case "client":
        this._getClientProfileDetail();

        break;
      default:
        return true;
    }
  };

  _getSigningCompanyDetail = () => {
    const { userData } = this.props;

    return new Promise((resolve, reject) => {
      signingCompanyDetail()
        .then((res) => {
          // this.setState({ companyProfileDetail: res.signingCompany });
          // this._setFormData(res.signingCompany);
          if (!userData?.user.isAssistant) {
            this.props.updateUserObj(res.signingCompany);
          }
          // this.props.hideLoader();
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _getAgentProfileDetail = () => {
    return new Promise((resolve, reject) => {
      getAgentProfileDetail()
        .then((res) => {
          this.props.updateUserObj(res.agent);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _getClientProfileDetail = () => {
    return new Promise((resolve, reject) => {
      const { userData } = this.props;
      console.log("g5eyhrtnryjtyjutyjuntyu");
      getClientProfileDetail()
        .then((res) => {
          if (!userData?.user.isAssistant) {
            this.props.updateUserObj(res.client);
          }
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _redirectToAgentQueryPage = () => {
    if (!isRegularUser()) {
      this._toggleUpgradeAccountModal(true);
    } else {
      this.props.history.push(`/agent/query-center`);
    }
  };

  render() {
    const { upgradeAccountModal, upgradeProAccountModal } = this.state;
    const { userData } = this.props;

    return (
      <div
        className="sidebar"
        data-color={this.props.bgColor}
        data-active-color={this.props.activeColor}
      >
        <div className="logo">
          <Link>
            <img
              src={userData?.user?.logo ? userData?.user?.logo : app_logo}
              alt={
                userData?.type !== "agent" && userData?.user?.companyName
                  ? userData?.user?.companyName
                  : userData?.type === "agent"
                  ? "Closewise Logo"
                  : "Company Logo"
              }
              onError={() => this._getlogo()}
            />
          </Link>
        </div>
        {}
        <div className="sidebar-wrapper" ref="sidebar">
          {/* <div className="user">
            <div className="photo">
              <img
                src={
                  userData.user?.isAssistant
                    ? userData.user?._assistant?.profilePicture ||
                    DEFAULT_PROFILE_PICTURE
                    : userData?.user?.profilePicture || DEFAULT_PROFILE_PICTURE
                }
                onError={(e) => (e.target.value = DEFAULT_PROFILE_PICTURE)}
                alt="Avatar"
              />
            </div>
            <div className="info">
              <a href="# " style={{ pointerEvents: "none" }}>
                <span style={{ fontSize: 12, fontWeight: 600 }}>
                  {userData.user?.isAssistant
                    ? this._truncate(
                      capitalize(userData.user?._assistant?.name?.full)
                    )
                    : userData?.user?.name?.full
                      ? this._truncate(capitalize(userData.user.name.full))
                      : this._truncate(capitalize(userData.user?.fullName)) || ""}
                </span>
              </a>
            </div>
          </div> */}
          <Nav className="mainNav">{this.createLinks(this.props.routes)}</Nav>
          <Nav className="mainNav hideDesktop">
            <li>
              <NavLink
                className="customSidebarList"
                // onClick={this._redirectToSettingsPage}
                to={`/${userData.type}/settings`}
              >
                <SvgIcons type="setting" />
                <span>Setting</span>
              </NavLink>
            </li>
            <li>
              <NavLink
                className="customSidebarList"
                // onClick={this._redirectToNotificationPage}
                to={`/${this.props.userData.type}/notifications`}
              >
                <SvgIcons type="notification" />
                <span>Notification</span>

                {/* {!this.props.userData?.user?.unreadnotificationsCount > 0 ? (
                  <Badge color="primary" className="notificationCount">
                    {this.props.userData?.user?.unreadnotificationsCount < 9
                      ? this.props?.userData?.user?.unreadnotificationsCount
                      : "9+"}
                  </Badge>
                ) : null} */}
              </NavLink>
            </li>
            {this.props.userData?.user?.type?.toLowerCase() === "agent" ? (
              <li>
                <ReactstrapLink
                  className="customSidebarList"
                  onClick={this._redirectToAgentQueryPage}
                >
                  <SvgIcons type="agentQuery" />
                  <span>Agent Query Center</span>
                </ReactstrapLink>
              </li>
            ) : null}
            {/* <li>
              <NavLink
                className="customSidebarList"
                onClick={this._onLogout}
                to=""
              >
                <img
                  src={
                    require("../../../../src/assets/img/header/logout.svg")
                      .default
                  }
                  alt="logout"
                />
                <span>Logout</span>
              </NavLink>
            </li> */}
          </Nav>
        </div>
        <div className="sidebarScheduleBox">
          <Button
            color="primary"
            // onClick={() => this._redirectToHelpPage()}
            onClick={() => this._redirectToGleapHelpPage()}
          >
            Need Help
          </Button>
        </div>
        <UpgradeAccountModal
          {...this.props}
          isOpen={upgradeAccountModal.isOpen}
          toggle={this._toggleUpgradeAccountModal}
        />
        <UpgradeProfessionalAccountModal
          {...this.props}
          isOpen={upgradeProAccountModal.isOpen}
          toggle={this._toggleProfessionalUpgradeAccountModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserObj: (userData) => dispatch(updateUserObj(userData)),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);

// export default Sidebar;
