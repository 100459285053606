import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Spinner,
  CustomInput,
} from "reactstrap";
import { deepClone, errorHandler, showToast } from "../../../helper-methods";
import {
  addUserBank,
  addBankAccount,
  getUserKycLink,
  makeCardDefault,
  fetchAllCards,
} from "../../../http/http-calls";
import { injectStripe } from "react-stripe-elements";

class AccountComponentModal extends Component {
  state = {
    cards: [],
    accounts: [],
    preferredType: "exisiting", // 'new'
    defaultAccount: null,
    selectedAccount: null,
    error: null,
    routingNo: "",
    accountNo: "",
    amount1: "",
    amount2: "",
    loading: false,
  };

  _onTypeChange = (preferredType = "new", selectedAccount = null) => {
    this.setState({
      preferredType,
      selectedAccount,
    });
  };

  componentDidUpdate = async (previousProps, previousState) => {
    // eslint-disable-next-line no-unused-vars
    const { isOpen, data } = this.props;

    if (isOpen && previousProps.isOpen !== isOpen) {
      try {
        await this._getDefaultCard();
      } catch (error) {
        this.setState({ error });
        errorHandler(error);
      }
    }
  };

  _getDefaultCard = () => {
    return new Promise(async (resolve, reject) => {
      this._showLoader();

      try {
        let res = await fetchAllCards();
        let accounts =
          res?.cards?.length &&
          res?.cards.filter((item) => item?.object === "bank_account");
        if (accounts?.length) {
          this.setState({ accounts });
          await this._setDefaultCard(accounts);
          this._hideLoader();
          resolve();
        } else {
          // No cards available
          this.setState({ preferredType: "new", defaultCard: null }, () => {
            this._hideLoader();
            resolve();
          });
        }
      } catch (error) {
        this._hideLoader();
        reject(error);
      }
    });
  };

  _setDefaultCard = (accounts) => {
    return new Promise((resolve, reject) => {
      let defaultAccount = accounts?.length
        ? accounts[0]
        : accounts.find((acc) => acc.isDefault);

      this.setState({ defaultAccount, selectedAccount: defaultAccount }, () => {
        resolve();
      });
    });
  };

  _onChangeFormField = (fieldName, value) => {
    if (fieldName === "routingNo") {
      if (value.length <= 9) {
        this.setState({ [fieldName]: value });
      }
    } else if (fieldName === "accountNo") {
      if (value.length <= 12) {
        this.setState({ [fieldName]: value });
      }
    } else {
      this.setState({ [fieldName]: value });
    }
  };

  _addBank = async (e = null) => {
    if (e) e.preventDefault();

    if (this.state.routingNo && this.state.accountNo) {
      if (this.props.stripe) {
        this._showLoader();
        this.props.stripe
          .createToken("bank_account", {
            country: "US",
            currency: "usd",
            routing_number: this.state.routingNo,
            account_number: this.state.accountNo,
            account_holder_name: this.props?.userData?.user?.name?.full,
            account_holder_type: "company",
            // account_type: 'checking',
          })
          .then((payload) => {
            if (payload?.error && payload.error.message) {
              errorHandler(payload.error);
            } else {
              this._onAccountAdded();
              this._userAddBankAccount(payload.token.id);
            }
            this._hideLoader();
          })
          .catch((error) => {
            this._hideLoader();
            errorHandler(error);
          });
      } else {
        console.log("Stripe.js hasn't loaded yet.");
      }
    } else {
      showToast("Account Number and Routing Number Cannot be Empty", "error");
    }
  };

  _onAccountAdded = async () => {
    this._showLoader();
    try {
      let payload = {
        routingNo: this.state.routingNo,
        accountNo: this.state.accountNo,
      };

      await addUserBank(payload);
      this.setState({ routingNo: "", accountNo: "", addBank: false });
      await getUserKycLink();

      this._hideLoader();
      this._onDismiss();
    } catch (error) {
      errorHandler(error);
      this._hideLoader();
    }
  };

  _userAddBankAccount = (token) => {
    addBankAccount({
      token,
    })
      .then((response) => {
        console.log(response);
        this._hideLoader();
      })
      .catch((error) => {
        errorHandler(error);
        this._hideLoader();
      });
  };
  _onDismiss = () => {
    // Reset state
    this.setState({
      preferredType: "exisiting",
    });
    this.props.toggle();
  };

  _showLoader = () => {
    if (this.props.loadingModal) {
      this.props.manageLoading(true);
    }
    this.setState({ loading: true });
  };

  _hideLoader = () => {
    if (this.props.loadingModal) {
      this.props.manageLoading(false);
    }
    this.setState({ loading: false });
  };

  componentWillUnmount = () => {
    this._hideLoader();
  };

  _processPayment = async () => {
    const { preferredType, selectedAccount } = this.state;

    if (preferredType === "new") {
      // Try to add account
      this._addBank();
    } else {
      if (!selectedAccount) {
        this._hideLoader();
        return;
      }
      if (selectedAccount.status !== "verified") {
        showToast("Please Verify Your Account", "error");
        this._hideLoader();
        return;
      }
      try {
        this._showLoader();
        await this._makeCardAsDefault();
        this._hideLoader();
      } catch (error) {
        this._hideLoader();
      }

      this.props.processPayment();
    }
  };

  _makeCardAsDefault = () => {
    const { selectedAccount } = this.state;

    const { type: userType } = this.props.userData;

    return new Promise((resolve, reject) => {
      makeCardDefault(selectedAccount.id, userType)
        .then((res) => {
          if (!res.error) {
            resolve();
          }
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  };

  render() {
    const { preferredType, accounts, selectedAccount, loading } = deepClone(
      this.state
    );

    const { loadingModal } = this.props;

    return (
      <Modal isOpen={this.props.isOpen} toggle={() => this.props.toggle(null)}>
        <ModalHeader toggle={() => this.props.toggle(null)}>
          Select A Payment Method
        </ModalHeader>
        <ModalBody>
          {accounts?.length ? (
            accounts
              .filter((item) => item.object === "bank_account")
              .map((acc) => (
                <>
                  <CustomInput
                    type="radio"
                    name="cardType"
                    value="exisiting"
                    checked={
                      preferredType === "exisiting" &&
                      selectedAccount?.id === acc?.id
                        ? true
                        : false
                    }
                    onChange={() => this._onTypeChange("exisiting", acc)}
                    id={`cards_data_${acc.id}`}
                    label={
                      acc.isDefault
                        ? `Primary Account Number ending with ${acc.last4} (${acc.bank_name})`
                        : `Account Number ending with ${acc.last4} (${acc.bank_name})`
                    }
                    htmlFor={`cards_data_${acc.id}`}
                    key={acc?.id}
                  />

                  {/* <div className="form-check-radio mb-3" key={acc?.id}>
                    <Label
                      check
                      className="cursorPointer"
                      htmlFor={`cards_data_${acc.id}`}
                    >
                      <Input
                        type="radio"
                        name="cardType"
                        value="exisiting"
                        checked={
                          preferredType === "exisiting" &&
                          selectedAccount?.id === acc?.id
                            ? true
                            : false
                        }
                        onChange={() => this._onTypeChange("exisiting", acc)}
                        id={`cards_data_${acc.id}`}
                      />

                      {acc.isDefault
                        ? `Primary Account Number ending with ${acc.last4} (${acc.bank_name})`
                        : `Account Number ending with ${acc.last4} (${acc.bank_name})`}

                      <span className="form-check-sign" />
                    </Label>
                  </div> */}
                </>
              ))
          ) : (
            <div className="text-center">
              {loading || loadingModal ? (
                <Spinner />
              ) : (
                "Please add bank information on the Profile page."
              )}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          {
            <Button
              color="primary"
              outline
              onClick={() => this.props.toggle(null)}
            >
              Cancel
            </Button>
          }

          <Button
            color="primary"
            disabled={
              loading || loadingModal || !accounts?.length ? true : false
            }
            onClick={() => this._processPayment()}
          >
            {loading || loadingModal ? (
              <i className="fa fa-spinner fa-spin mr-2" />
            ) : null}{" "}
            Save & Pay Now
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(
  mapStateToProps,
  null
)(injectStripe(AccountComponentModal));
