import React, { Component, createRef } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  getClosingDetailsById,
  updateClosingDetailsById,
  sendConfirmationEmailToAgent,
  editDraft,
} from "../../../http/http-calls";
import {
  capitalize,
  deepClone,
  formatDateAndTime,
  formatPhoneNumber,
  // getYesterdayDate,
  openGoogleMapOnNewTab,
  openAppleMapOnNewTab,
  showToast,
  formatDateMoment,
  formatDateAsPerTimeZOne,
  formatDateAsPerTimeZoneWithDateAndTime,
  errorHandler,
  formatDateOnlyAsPerTimeZone,
  formatDate,
  getTimeZoneAbbr,
} from "../../../helper-methods";
import { connect } from "react-redux";
import CustomInput from "reactstrap/lib/CustomInput";
import { Link } from "react-router-dom";
import AppointmentScheduler from "./AppointmentScheduler";
import RegularAppointmentScheduler from "./RegularAppointmentScheduler";
import { DateTimeShowWithClosingDetails } from "./Date-time-show";
class SignerAvailabilityModal extends Component {
  constructor(props) {
    super(props);
    this.appointmentSchedulerRef = createRef();
    this.regularSchedulerRef = createRef();
  }

  state = {
    closingDetails: null,
    mapType: "google",
    appointmentStatus: "Confirmed",
    time: {
      value: "",
      error: null,
      isDirty: false,
    },
    loading: false,
    tBD: false,
    appointmentSchedulerData: {
      appointmentDate: null,
      endAppointmentDate: null,
      tBD: false,
      isRangeDated: false,
    },
  };

  _resetModalState = () => {
    this.setState({
      closingDetails: null,
      mapType: "google",
      appointmentStatus: "Confirmed", // Rescheduled
      time: {
        value: "",
        error: null,
        isDirty: false,
      },
      loading: false,
    });
  };

  _closeModal = () => {
    this._resetModalState();
    this.props.toggle();
  };

  _getClosingDetailsById = (id) => {
    this.setState({ loading: true });

    getClosingDetailsById(id)
      .then((res) => {
        // console.log(res);
        this.setState({
          closingDetails: res?.closing,
          loading: false,
          time: {
            value: res?.closing?.appointmentDate
              ? formatDateAsPerTimeZoneWithDateAndTime(
                  res?.closing?.appointmentDate,
                  res?.closing?.closingAddress?.timeZone
                )
              : "",
            error: null,
            isDirty: false,
          },
          tBD: res?.closing?.tBD,
          appointmentSchedulerData: {
            appointmentDate: res?.closing?.appointmentDate,
            endAppointmentDate: null,
            tBD: res?.closing?.tBD,
            isRangeDated: res?.closing?.isRangeDated,
          },
          appointmentStatus: res?.closing?.appointmentStatus
            ? res?.closing?.appointmentStatus
            : "Confirmed", // Rescheduled
        });
      })
      .catch((error) => {
        errorHandler(error);
        this._closeModal();
      });
  };

  _sendConfirmationEmailToAgent = () => {
    this.setState({ loading: true });

    sendConfirmationEmailToAgent(this.props.data.id)
      .then((res) => {
        // console.log(res);
        showToast("Reminder Sent To Agent", "success");
        this.props.resetDetails();
        this._closeModal();
      })
      .catch((error) => {
        errorHandler(error);
        this._closeModal();
      });
  };

  // componentDidUpdate = (prevProps, prevState) => {
  //   const { isOpen, data } = this.props;
  //   if (isOpen && data && prevProps.isOpen !== isOpen) {
  //     this._getClosingDetailsById(data.id);
  //   }
  // };

  componentDidMount() {
    const { data } = this.props;
    console.log("data >>", data);
    if (data?.id) {
      this._getClosingDetailsById(data.id);
    }
  }

  _updateClosingDetailsById = async (id, payload) => {
    const { closingDetails } = this.state;

    try {
      this.setState({ loading: true });

      // await updateClosingDetailsById({ id, payload });
      closingDetails.isSavedAsDraft
        ? await editDraft(closingDetails.id, payload)
        : await updateClosingDetailsById({
            id,
            payload,
          });
      showToast("Signing Status Updated", "success");

      this.props.resetDetails();

      this._closeModal();
    } catch (error) {
      errorHandler(error);
      this.setState({ loading: false });
    }
  };

  _onChangeAppointmentStatus = (appointmentStatus = "Confirmed") => {
    let { appointmentSchedulerData, closingDetails } = deepClone(this.state);
    // pass data to AppointmentScheduler component
    if (appointmentStatus === "Rescheduled") {
      appointmentSchedulerData = {
        appointmentDate: closingDetails?.appointmentDate,
        endAppointmentDate:
          closingDetails && closingDetails?.endAppointmentDate !== undefined
            ? closingDetails?.endAppointmentDate
            : null,
        tBD: closingDetails?.tBD,
        isRangeDated:
          closingDetails && closingDetails?.endAppointmentDate !== undefined
            ? true
            : false,
      };
    }
    this.setState({ appointmentStatus, appointmentSchedulerData });
  };

  _onChangeAppointmentTime = (value = "") => {
    const { time } = deepClone(this.state);
    time["value"] = value ? new Date(value).toISOString() : "";
    // time["value"] = value?formatDateAsPerTimeZOne(value,this.state.closingDetails.closingAddress.timeZone) :"";
    time["isDirty"] = true;
    this.setState({ time });
  };

  _confirmStatus = async () => {
    const { tBD, closingDetails, appointmentStatus, time } = deepClone(
      this.state
    );

    if (
      this.props?.userData?.user?.type?.toLowerCase() === "client" &&
      appointmentStatus !== "Rescheduled"
    ) {
      showToast("Please select availability");
      return;
    }

    let payload = {};

    if (appointmentStatus) {
      if (appointmentStatus === "Confirmed") {
        if (closingDetails?.isRangeDated || closingDetails?.tBD) {
          const regularSchedulerResponse =
            await this.regularSchedulerRef.current.getAppointmentSchedulerData();
          if (!regularSchedulerResponse?.isValid) {
            return;
          }
          payload = {
            ...regularSchedulerResponse.appointmentSchedulerData,
            appointmentStatus: "Confirmed",
          };
        } else {
          payload = {
            appointmentStatus: "Confirmed",
          };
        }
      } else if (appointmentStatus === "Rescheduled") {
        const appointmentSchedulerResponse =
          await this.appointmentSchedulerRef.current.getAppointmentSchedulerData();
        if (!appointmentSchedulerResponse?.isValid) {
          return;
        }
        payload = {
          ...appointmentSchedulerResponse.appointmentSchedulerData,
        };
        payload["appointmentStatus"] = "Rescheduled";
      } else if (appointmentStatus === "Cancelled") {
        payload["appointmentStatus"] = "Cancelled";
      }

      console.log("payload >>", payload);

      this._updateClosingDetailsById(closingDetails.id, payload);
    }
  };

  // _onChangeTBD = (tBD) => {
  //   if (tBD) {
  //     const { time } = this.state;
  //     time.value = "";
  //     time.isDirty = false;
  //     time.error = null;

  //     // formFields.signingCompanyId.value = "";
  //     // formFields.signingCompanyId.isDirty = false;
  //     // formFields.signingCompanyId.error = null;
  //     this.setState({ time });
  //   }
  //   this.setState({ tBD });
  // };

  render() {
    const { tBD, closingDetails, time, appointmentStatus, loading, mapType } =
      this.state;

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this._closeModal}
        centered
        scrollable
      >
        <ModalHeader toggle={this._closeModal}>Signer Availability</ModalHeader>
        <ModalBody>
          <div className="signingWrapper">
            {closingDetails?._borrower?.map((each) => (
              <div className="signingUser">
                <div className="phoneIcon">
                  <i className="fa fa-phone" />
                </div>
                <div className="content">
                  <span>{capitalize(each?.name?.full)}</span>
                  {each?.phone?.work && each?.phone?.alternate ? (
                    <>
                      <a
                        href={`tel:${formatPhoneNumber(each?.phone?.work)}`}
                        title="Work phone"
                      >
                        {" "}
                        {formatPhoneNumber(each?.phone?.work)}
                      </a>
                      <a
                        href={`tel:${formatPhoneNumber(
                          each?.phone?.alternate
                        )}`}
                        title="Alternate phone"
                      >
                        {" "}
                        {formatPhoneNumber(each?.phone?.alternate)}
                      </a>
                    </>
                  ) : each?.phone?.work && !each?.phone?.alternate ? (
                    <a
                      href={`tel:${formatPhoneNumber(each?.phone?.work)}`}
                      title="Work phone"
                    >
                      {" "}
                      {formatPhoneNumber(each?.phone?.work)}
                    </a>
                  ) : !each?.phone?.work && each?.phone?.alternate ? (
                    <a
                      href={`tel:${formatPhoneNumber(each?.phone?.alternate)}`}
                      title="Alternate phone"
                    >
                      {" "}
                      {formatPhoneNumber(each?.phone?.alternate)}
                    </a>
                  ) : (
                    <p>N/A</p>
                  )}
                </div>
              </div>
            ))}
          </div>
          {closingDetails ? (
            <>
              <div className="SigningAvailability">
                <p>
                  Please call to confirm their availability for this signing
                  appointment and location:{" "}
                  <strong>
                    {closingDetails.closingAddress &&
                    closingDetails.closingAddress ? (
                      <>
                        {closingDetails.closingAddress.line1
                          ? `${closingDetails.closingAddress.line1}, `
                          : ""}
                        {closingDetails.closingAddress.line2
                          ? `${closingDetails.closingAddress.line2}, `
                          : ""}
                        {closingDetails.closingAddress.city
                          ? `${closingDetails.closingAddress.city}, `
                          : ""}
                        {closingDetails.closingAddress.state
                          ? `${closingDetails.closingAddress.state}, `
                          : ""}
                        {closingDetails.closingAddress.zip
                          ? `${closingDetails.closingAddress.zip}`
                          : ""}
                      </>
                    ) : (
                      "N/A"
                    )}
                  </strong>
                </p>
              </div>
              <div className="scheduledTime">
                <Label>Scheduled Appointment:</Label>
                <h6>
                  {/* {closingDetails.appointmentDate
                    ? !closingDetails.tBD
                      ? formatDateAsPerTimeZOne(
                          closingDetails.appointmentDate,
                          closingDetails.closingAddress.timeZone
                        )
                      : formatDateOnlyAsPerTimeZone(
                          closingDetails.appointmentDate,
                          closingDetails.closingAddress.timeZone
                        )
                    : "N/A"} */}
                  <DateTimeShowWithClosingDetails
                    closingDetails={closingDetails}
                  />
                  {getTimeZoneAbbr(
                    closingDetails?.appointmentDate,
                    closingDetails?.closingAddress?.timeZone
                  )}
                </h6>
              </div>

              {/* {closingDetails?._createdByClient !== undefined && (
                <CustomInput
                  type="checkbox"
                  id="appointmentDatetime_tBD"
                  label="Time T.B.D"
                  name="tBD"
                  checked={tBD}
                  onChange={(e) => this._onChangeTBD(e.target.checked)}
                />
              )} */}

              <div className="signingAppointment">
                {this.props?.userData?.user?.type?.toLowerCase() ===
                "client" ? (
                  <>
                    {/* <div className="d-flex flex-wrap">
                      <div className="form-check-radio mr-3">
                        <Label check className="cursorPointer">
                          <Input
                            type="radio"
                            name="appointmentStatus"
                            value="Rescheduled"
                            checked={appointmentStatus === "Rescheduled"}
                            onChange={(event) =>
                              this._onChangeAppointmentStatus("Rescheduled")
                            }
                          />
                          Change Appointment Date &amp; Time{" "}
                          <span className="form-check-sign" />
                        </Label>
                      </div>
                    </div> */}
                    <CustomInput
                      id="client_appointmentStatus"
                      label={`Change Appointment Date & Time`}
                      type="radio"
                      className="mr-2 mb-2"
                      name="appointmentStatus"
                      value="Rescheduled"
                      checked={appointmentStatus === "Rescheduled"}
                      onChange={(event) =>
                        this._onChangeAppointmentStatus("Rescheduled")
                      }
                    />
                  </>
                ) : (
                  <>
                    {/* <div className="form-check-radio mr-3">
                      <Label check className="cursorPointer">
                        <Input
                          type="radio"
                          name="appointmentStatus"
                          value="Confirmed"
                          checked={appointmentStatus === "Confirmed"}
                          onChange={(event) =>
                            this._onChangeAppointmentStatus("Confirmed")
                          }
                          disabled={closingDetails?.tBD ? true : false}
                        />
                        Confirm Appointment <span className="form-check-sign" />
                      </Label>
                    </div> */}
                    <CustomInput
                      id="signing_ConfirmAppointment"
                      label={`Confirm Appointment`}
                      type="radio"
                      name="appointmentStatus"
                      value="Confirmed"
                      checked={appointmentStatus === "Confirmed"}
                      onChange={(event) =>
                        this._onChangeAppointmentStatus("Confirmed")
                      }
                    />

                    {/* <div className="form-check-radio mr-3">
                      <Label check className="cursorPointer">
                        <Input
                          type="radio"
                          name="appointmentStatus"
                          value="Rescheduled"
                          checked={appointmentStatus === "Rescheduled"}
                          onChange={(event) =>
                            this._onChangeAppointmentStatus("Rescheduled")
                          }
                        />
                        Change Appointment Date &amp; Time{" "}
                        <span className="form-check-sign" />
                      </Label>
                    </div> */}
                    <CustomInput
                      id="signing_ChangeAppointment"
                      label={` Change Appointment Date & Time`}
                      type="radio"
                      name="appointmentStatus"
                      value="Rescheduled"
                      checked={appointmentStatus === "Rescheduled"}
                      onChange={(event) =>
                        this._onChangeAppointmentStatus("Rescheduled")
                      }
                    />
                    {/* <div className="form-check-radio mt-2 mb-0">
                    <Label check className="cursorPointer">
                      <Input
                        type="radio"
                        name="appointmentStatus"
                        value="Cancelled"
                        checked={appointmentStatus === "Cancelled"}
                        onChange={(event) =>
                          this._onChangeAppointmentStatus("Cancelled")
                        }
                      />
                      Cancel Appointment <span className="form-check-sign" />
                    </Label>
                  </div> */}
                    <CustomInput
                      id="signing_CancelAppointment"
                      label={` Cancel Appointment`}
                      type="radio"
                      name="appointmentStatus"
                      value="Cancelled"
                      checked={appointmentStatus === "Cancelled"}
                      onChange={(event) =>
                        this._onChangeAppointmentStatus("Cancelled")
                      }
                    />

                    {closingDetails._agent && (
                      <Button
                        color="link"
                        style={{
                          textTransform: "capitalize",
                          fontSize: "14px",
                        }}
                        onClick={() => this._sendConfirmationEmailToAgent()}
                      >
                        Send Reminder
                      </Button>
                    )}
                  </>
                )}
              </div>

              {/* Show the below div only when user selects the "Change Appointment Time" option above */}
              {/* {appointmentStatus === "Rescheduled" &&
                (!tBD ? (
                  <FormGroup
                    className={`floatingLabel ${
                      time.value || this.state.isOpenReactDatetime
                        ? "valueAdded"
                        : ""
                    }`}
                  >
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: " ",
                        value: formatDateAndTime(time.value),
                      }}
                      onOpen={() =>
                        this.setState({ isOpenReactDatetime: true })
                      }
                      onClose={() =>
                        this.setState({ isOpenReactDatetime: false })
                      }
                      value={time.value ? new Date(time.value) : ""}
                      onChange={(e) => this._onChangeAppointmentTime(e._d)}
                      // isValidDate={(current) =>
                      //   current.isAfter(getYesterdayDate())
                      // }
                      timeConstraints={{
                        minutes: {
                          step: 15,
                        },
                      }}
                      dateFormat={true}
                      timeFormat={true}
                    />
                    <Label>Revised Date &amp; Time</Label>
                  </FormGroup>
                ) : (
                  <FormGroup
                    className={`floatingLabel ${
                      time.value || this.state.isOpenReactDatetime
                        ? "valueAdded"
                        : ""
                    }`}
                  >
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: " ",
                        value: formatDate(time.value),
                      }}
                      onOpen={() =>
                        this.setState({ isOpenReactDatetime: true })
                      }
                      onClose={() =>
                        this.setState({ isOpenReactDatetime: false })
                      }
                      onChange={(e) => this._onChangeAppointmentTime(e._d)}
                      dateFormat={true}
                      timeFormat={false}
                    />
                    <Label>Appointment Date</Label>
                  </FormGroup>
                ))} */}

              {appointmentStatus === "Confirmed" &&
              (closingDetails?.isRangeDated || closingDetails?.tBD) ? (
                <>
                  <RegularAppointmentScheduler
                    ref={this.regularSchedulerRef}
                    // data={this.state.appointmentSchedulerData}
                  />
                </>
              ) : null}

              {appointmentStatus === "Rescheduled" && (
                <>
                  <AppointmentScheduler
                    ref={this.appointmentSchedulerRef}
                    AppointmentSchedulerConfig={
                      this.props.AppointmentSchedulerConfig
                    }
                    data={this.state.appointmentSchedulerData}
                    timezone={closingDetails?.closingAddress?.timeZone}
                  />
                </>
              )}

              {/* <div className="signerLocation"> */}
              {closingDetails.closingAddress ? (
                <>
                  <div className="locationDetails">
                    <div className="d-flex justify-content-between">
                      <Label>Location</Label>
                      <div className="locationLinks">
                        <div className="form-check-radio">
                          <Label check>
                            <Input
                              type="radio"
                              name="mapType"
                              value="google"
                              checked={mapType === "google"}
                              onChange={() =>
                                this.setState({ mapType: "google" })
                              }
                            />
                            <div className="maps">
                              <img
                                src={
                                  require("../../../assets/img/Google_Maps_Logo_2020.svg")
                                    .default
                                }
                                alt="google map"
                              />
                            </div>
                          </Label>
                        </div>
                        <div className="form-check-radio">
                          <Label check>
                            <Input
                              type="radio"
                              name="mapType"
                              value="apple"
                              checked={mapType === "apple"}
                              onChange={() =>
                                this.setState({ mapType: "apple" })
                              }
                            />
                            <div className="maps">
                              <img
                                src={
                                  require("../../../assets/img/Apple_Maps_(WatchOS).svg")
                                    .default
                                }
                                alt="apple map"
                              />
                            </div>
                          </Label>
                        </div>
                      </div>
                    </div>
                    <h6
                      onClick={() =>
                        mapType === "apple"
                          ? openAppleMapOnNewTab(closingDetails.closingAddress)
                          : openGoogleMapOnNewTab(closingDetails.closingAddress)
                      }
                    >
                      <img
                        src={
                          require("../../../assets/img/map-marker.svg").default
                        }
                        height={13}
                        alt="location icon"
                      />{" "}
                      {closingDetails.closingAddress.line1
                        ? `${closingDetails.closingAddress.line1}, `
                        : ""}
                      {closingDetails.closingAddress.line2
                        ? `${closingDetails.closingAddress.line2}, `
                        : ""}
                      {closingDetails.closingAddress.city
                        ? `${closingDetails.closingAddress.city}, `
                        : ""}
                      {closingDetails.closingAddress.state
                        ? `${closingDetails.closingAddress.state}, `
                        : ""}
                      {closingDetails.closingAddress.zip
                        ? `${closingDetails.closingAddress.zip}`
                        : ""}
                    </h6>
                  </div>
                </>
              ) : (
                "N/A"
              )}
              {/* </div> */}

              {/* <div className="mt-3">
                {closingDetails.closingAddress ? (
                  <>
                    {closingDetails.closingAddress.line1
                      ? `${closingDetails.closingAddress.line1}, `
                      : ""}
                    {closingDetails.closingAddress.line2
                      ? `${closingDetails.closingAddress.line2}, `
                      : ""}
                    {closingDetails.closingAddress.city
                      ? `${closingDetails.closingAddress.city}, `
                      : ""}
                    {closingDetails.closingAddress.state
                      ? `${closingDetails.closingAddress.state}, `
                      : ""}
                    {closingDetails.closingAddress.zip
                      ? `${closingDetails.closingAddress.zip}`
                      : ""}
                    <Button
                      color="link"
                      style={{
                        padding: "0 6px",
                        fontSize: 16,
                        verticalAlign: 0,
                      }}
                      onClick={() =>
                        mapType === "apple"
                          ? openAppleMapOnNewTab(closingDetails.closingAddress)
                          : openGoogleMapOnNewTab(closingDetails.closingAddress)
                      }
                    >
                      <i className="fa fa-map-marker" />
                    </Button>
                    <div className="d-flex flex-wrap mt-3">
                      <div className="form-check-radio mr-3">
                        <Label check className="cursorPointer">
                          <Input
                            type="radio"
                            name="mapType"
                            value="google"
                            checked={mapType === "google"}
                            onChange={() =>
                              this.setState({ mapType: "google" })
                            }
                          />
                          Google Map <span className="form-check-sign" />
                        </Label>
                      </div>
                      <div className="form-check-radio">
                        <Label check className="cursorPointer">
                          <Input
                            type="radio"
                            name="mapType"
                            value="apple"
                            checked={mapType === "apple"}
                            onChange={() => this.setState({ mapType: "apple" })}
                          />
                          Apple Map
                          <span className="form-check-sign" />
                        </Label>
                      </div>
                    </div>
                  </>
                ) : (
                  "N/A"
                )}
              </div> */}
            </>
          ) : (
            <div
              className="text-center  mb-3"
              style={{ fontSize: "16px", fontWeight: 500 }}
            >
              {loading ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                "Closing Not found"
              )}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={this._closeModal}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={loading}
            onClick={this._confirmStatus}
          >
            {loading ? <i className="fa fa-spinner fa-spin" /> : null} Confirm
            Status
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

export default connect(mapStateToProps, null)(SignerAvailabilityModal);
