import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Button,
  CardHeader,
  CardTitle,
  CustomInput,
  Row,
  Col,
} from "reactstrap";
import { errorHandler, showToast } from "../../../helper-methods";
import {
  createCompanySetting,
  deleteScanBackDocumentId,
  updateSigningCompanySetting,
} from "../../../http/http-calls";
import AddInstructionsModal from "./add-instructions-modal";
import ScanBackDocumentModal from "./scanback-document-modal";
import Swal from "sweetalert2";
import ReadMore from "./readMore";

let initialModalData = {
  isOpen: false,
  data: null,
};
const JobsSettings = ({
  getAllCompanySettings,
  companySettings,
  changeToTab,
  client,
  loanType,
}) => {
  const [isOpenAddInstruction, setIsOpenAddInstruction] =
    useState(initialModalData);
  const [isOpenScanBackDocument, setIsOpenScanBackDocument] = useState(false);
  const [loading, setLoading] = useState(false);
  const [scanbackIsRequired, setScanbackIsRequired] = useState(true);
  const [signingInstruction, setSigningInstruction] = useState(false);
  const toggle = () => setSigningInstruction(!signingInstruction);
  const _scanBackPayload = (data) => {
    let payload = {};
    payload = data;
    payload.scanBackDocument["isAlwaysRequired"] = scanbackIsRequired;

    _createCompanySettings(payload);
  };

  const _createCompanySettings = async (data) => {
    setLoading(true);
    try {
      await createCompanySetting(data);

      showToast("Settings Updated Successfully", "success");
      setLoading(false);
      setIsOpenScanBackDocument(false);
      getAllCompanySettings();
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _deleteScanBackDocument = (id) => {
    return new Promise(async (resolve) => {
      try {
        await deleteScanBackDocumentId(id);

        // setCompanySettings(response.companySetting);
        showToast("Successfully Deleted!", "success");
        getAllCompanySettings();
        resolve(true);
      } catch (error) {
        errorHandler(error);
      }
    });
  };

  useEffect(() => {
    setScanbackIsRequired(companySettings?.scanBackDocument?.isAlwaysRequired);
  }, [companySettings]);

  const _handleOnClickOfIsRequired = (value) => {
    let payload = {
      scanBackDocument: { isAlwaysRequired: value },
    };
    setScanbackIsRequired(value);

    _createCompanySettings(payload);
  };

  const _rawHtml = (htmlreceived) => {
    var rawMarkup = htmlreceived;
    // console.log("raw markup received:", rawMarkup)
    return { __html: rawMarkup };
  };

  const _handleOnDelete = async (id) => {
    console.log(id);
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this Instruction.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        // _deleteDocument(document._id);
        try {
          if (companySettings) {
            setLoading(true);
            // client
            let removedArray = companySettings.signingInstruction.filter(
              (obj) => obj._id !== id
            );
            // console.log("ssss", removedArray);

            // await updateClientSettings({
            //   signingInstruction: removedArray,
            // });

            // company
            await updateSigningCompanySetting({
              signingInstruction: removedArray,
            });
            setLoading(false);
          }

          showToast("Instruction has been updated", "success");
          getAllCompanySettings();
          // onSuccess();
        } catch (error) {
          setLoading(false);
          errorHandler(error);
        }
      }
    });
  };

  const _handleOnDefault = async (id) => {
    console.log(id);
    try {
      if (companySettings) {
        setLoading(true);

        // client
        let updatedArray = companySettings.signingInstruction.map((obj) =>
          obj._id === id
            ? { ...obj, isDefault: true }
            : { ...obj, isDefault: false }
        );

        // await updateClientSettings({
        //   signingInstruction: updatedArray,
        // });
        // } else {
        // company
        await updateSigningCompanySetting({ signingInstruction: updatedArray });
        setLoading(false);
      }
      showToast("Instruction has been updated", "success");
      getAllCompanySettings();
      // onSuccess();
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _handleOnEdit = (id) => {
    let getObjectToUpdate = companySettings?.signingInstruction.filter(
      (item) => item._id === id
    );
    console.log("Dd", getObjectToUpdate);
    setIsOpenAddInstruction({
      isOpen: true,
      data: getObjectToUpdate,
    });
  };
  return (
    <>
      <Card className="jobsCard">
        <CardHeader>
          <div>
            <CardTitle>Signing Instruction</CardTitle>
            <p> Instructions for Closing types & Specific Clients</p>
          </div>
          <Button
            color="link"
            className="text-capitalize"
            onClick={() =>
              setIsOpenAddInstruction({
                isOpen: true,
                data: null,
              })
            }
          >
            Add New Instruction
          </Button>
        </CardHeader>
        <CardBody>
          {/* <div className="JobWrapper mb-3" onClick={toggle}>
            <span>{companySettings?.noOfSigningInstruction} Saved</span>
          </div> */}
          {/* {console.log("dsdd", companySettings)} */}
          <Row>
            {companySettings?.signingInstruction?.length > 0 &&
              companySettings?.signingInstruction?.map((each, index) => (
                <>
                  <Col xl={4} lg={6}>
                    <div className="instructionWrap">
                      <div className="instructionHeader">
                        <CustomInput
                          type="radio"
                          name="job"
                          checked={each.isDefault}
                          label={each.isDefault ? "Default" : "Make Default"}
                          id={`${each._id}-job`}
                          onClick={() => _handleOnDefault(each._id)}
                          disable={loading}
                        />
                        <div>
                          <Button
                            color="link"
                            onClick={() => _handleOnEdit(each._id)}
                          >
                            <img
                              src={
                                require("../../../assets/img/pencil.svg")
                                  .default
                              }
                              alt="pencil"
                            />
                          </Button>
                          {/* delete button will hide when make default selected */}
                          {!each.isDefault && (
                            <Button
                              color="link"
                              onClick={() => _handleOnDelete(each._id)}
                              disable={loading}
                            >
                              <img
                                src={
                                  require("../../../assets/img/deleteIcon.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button>
                          )}
                        </div>
                      </div>
                      <div className="instructionContent">
                        <span>{each.name}</span>
                        {/* <p
                          dangerouslySetInnerHTML={_rawHtml(each?.description)}
                        ></p> */}
                          <ReadMore text={each?.description} maxLength={100} />
                      </div>
                    </div>
                  </Col>
                </>
              ))}
            {/* <Col xl={4}>
              <div className="instructionWrap">
                <div className="instructionHeader">
                  <CustomInput
                    type="radio"
                    name="job"
                    checked
                    label="Default"
                    id="job"
                  />
                  <div>
                    <Button color="link">
                      <img
                        src={require("../../../assets/img/pencil.svg").default}
                        alt="pencil"
                      />
                    </Button>
                    {/* delete button will hide when make default selected */}
            {/* <Button color="link">
                      <img
                        src={
                          require("../../../assets/img/deleteIcon.svg").default
                        }
                        alt="delete"
                      />
                    </Button> *
                  </div>
                </div>
                <div className="instructionContent">
                  <span>Title</span>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Dicta laboriosam facere officiis quos corrupti alias
                  </p>
                </div>
              </div>
            </Col>

            <Col xl={4}>
              <div className="instructionWrap">
                <div className="instructionHeader">
                  <CustomInput
                    type="radio"
                    name="job"
                    label="Make Default"
                    id="job"
                  />
                  <div>
                    <Button color="link">
                      <img
                        src={require("../../../assets/img/pencil.svg").default}
                        alt="pencil"
                      />
                    </Button>
                    <Button color="link">
                      <img
                        src={
                          require("../../../assets/img/deleteIcon.svg").default
                        }
                        alt="delete"
                      />
                    </Button>
                  </div>
                </div>
                <div className="instructionContent">
                  <div className="">
                    <span>Title</span>
                    <p>
                      Lorem ipsum dolor sit amet consectetur adipisicing elit.
                      Dicta laboriosam facere officiis quos corrupti alias
                    </p>
                  </div>
                </div>
              </div>
            </Col> */}
          </Row>
          {/* <Collapse isOpen={signingInstruction}></Collapse> */}
        </CardBody>
      </Card>
      <p className="fs-12 text-muted mb-4">
        *Set specific instructions sent to agents based on closing type and
        client. Once created they will auto generate in the order instructions
        sent to the agent assigned to the order.
      </p>

      <Card className="jobsCard">
        <CardHeader>
          <div>
            <CardTitle>Standard Fee Rates</CardTitle>
            <p>Set standard rates based on location & product types</p>
          </div>
          <Button
            color="link"
            className="text-capitalize"
            onClick={() => changeToTab("3")}
          >
            Manage Instruction
          </Button>
        </CardHeader>
        <CardBody>
          <p> {companySettings?.noOfStandardFeeRates} Saved</p>
        </CardBody>
      </Card>
      <p className="fs-12 text-muted mb-4">
        *Auto populate rates for closing agents based on State, County, and
        Signing types. These rates will show in the bigging system automatically
        for your schedulers.
      </p>

      <Card className="jobsCard">
        <CardHeader>
          <div>
            <CardTitle>Scan Back Documents</CardTitle>
            <p>Auto Set when required</p>
          </div>
          <Button
            color="link"
            className="text-capitalize"
            onClick={() => setIsOpenScanBackDocument(true)}
          >
            Manage Instruction
          </Button>
        </CardHeader>
        <CardBody>
          <CustomInput
            id="Scan Back Documents"
            type="checkbox"
            checked={scanbackIsRequired}
            name="isAlwaysRequired"
            onClick={(event) =>
              _handleOnClickOfIsRequired(event.target.checked)
            }
            label="Always Required"
          />

          <p className="mt-2">{companySettings?.noOfScanBackDocument} Saved</p>
        </CardBody>
      </Card>
      <p className="fs-12 text-muted">
        *Auto set when order will have scanbacks required auto selected based on
        Client and product type.
      </p>

      <AddInstructionsModal
        isOpen={isOpenAddInstruction.isOpen}
        toggle={() => setIsOpenAddInstruction(false)}
        companySettings={companySettings}
        companySettingsData={isOpenAddInstruction.data}
        onSuccess={getAllCompanySettings}
      />
      <ScanBackDocumentModal
        isOpen={isOpenScanBackDocument}
        toggle={() => setIsOpenScanBackDocument(false)}
        client={client}
        loading={loading}
        handleSubmit={_scanBackPayload}
        loanType={loanType}
        companySettings={companySettings}
        deleteScanBackDocument={_deleteScanBackDocument}
      />
    </>
  );
};

export default JobsSettings;
