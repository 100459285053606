import React from "react";
import { Row, Col } from "reactstrap";
import { connect } from "react-redux";

class Terms extends React.Component {
  _redirectToContact = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/contact`);
  };

  render() {
    return (
      <>
        <div className="content">
          <Row>
            <Col md="12">
              <div className="pgTitleWrap">
                <h4> Terms of Service</h4>
              </div>
              <span className="text-bold">These Terms of Service </span>
              <b>("TERMS")</b>
              <span className="text-bold">
                constitute the legally binding agreement between CloseWise,
                Ilc., its affiliates, and agents (collectively, "
              </span>
              <b>CloseWise</b>
              <span className="text-bold">
                ", "we", "us", or "our") and users of our Services ("
              </span>
              <b>user</b>
              <span className="text-bold">
                ", "you", or "your", except in Sections of the TERMS that state
                otherwise). These TERMS govern your use of our website (
              </span>
              <a
                target="_blank"
                href="https://www.snapdocs.com/"
                rel="noreferrer"
              >
                <span className="text-bold">www.CloseWise.com</span>
              </a>
              <span className="text-bold">
                ) or any other websites of CloseWise (collectively, the "
              </span>
              <b>Website</b>
              <span className="text-bold">
                ") in any way, including using services or resources available
                or enabled via, or that interact with, the Website
                (collectively, the "
              </span>
              <b>Services</b>
              <span className="text-bold">").</span>
              <span className="text-bold"></span>
              <span className="text-bold"></span>
              <span className="text-bold">
                By clicking an "I accept" or similar button, completing the
                registration process, and/or browsing the Website you represent
                that (1) you have read, understand, and agree to be bound by the
                TERMS, (2) you are of legal age to form a binding contract with
                CloseWise, and (3) you have the authority to enter into the
                TERMS personally (or on behalf of the entity you named as the
                user, and to bind that company to the TERMS). The term "you"
                refers to the individual or entity, as applicable, identified as
                the User when you registered on the Website.
              </span>
              <span className="text-bold"></span>
              <span className="text-bold"></span>
              <span className="text-bold">
                IF YOU DO NOT AGREE TO THESE TERMS, YOU MUST CEASE USING THE
                SERVICES AT ONCE.
              </span>
              <span className="text-bold"></span>
              <span className="text-bold"></span>
              <b>
                THESE TERMS INCLUDE A CLASS ACTION WAIVER AND A WAIVER OF JURY
                TRIALS, AND REQUIRE BINDING ARBITRATION ON AN INDIVIDUAL BASIS
                TO RESOLVE DISPUTES RELATING IN ANY WAY TO YOUR USE OF THE
                SERVICES. THE TERMS LIMIT THE REMEDIES THAT MAY BE AVAILABLE TO
                YOU IN THE EVENT OF A DISPUTE. BY ENTERING INTO THE TERMS, YOU
                ARE WAIVING YOUR RIGHT TO SEEK RELIEF IN A COURT OF LAW. PLEASE
                SEE SECTION 15 BELOW FOR MORE INFORMATION REGARDING ARBITRATION
                AND HOW IT DIFFERS FROM A PROCEEDING IN A COURT OF LAW.
              </b>
              <span className="text-bold"></span>
              <span className="text-bold"></span>
              <span className="text-bold">
                ANY DISPUTE OR CLAIM RELATING IN ANY WAY TO YOUR USE OF THE
                SERVICES WILL BE GOVERNED AND INTERPRETED BY AND UNDER THE LAWS
                OF THE STATE OF CALIFORNIA, CONSISTENT WITH THE FEDERAL
                ARBITRATION ACT, WITHOUT GIVING EFFECT TO ANY PRINCIPLES THAT
                PROVIDE FOR THE APPLICATION OF THE LAW OF ANOTHER JURISDICTION.
                THE UNITED NATIONS CONVENTION ON CONTRACTS FOR THE INTERNATIONAL
                SALE OF GOODS DOES NOT APPLY TO THESE TERMS.
              </span>
              <span className="text-bold"></span>
              <span className="text-bold"></span>
              <span className="text-bold">
                These TERMS are subject to occasional revision. We will notify
                you of changes to the TERMS by posting a new version of the
                TERMS at 
              </span>
              <a
                target="_blank"
                href="https://www.closewise.com/terms-and-conditions/"
                rel="noreferrer"
              >
                <span className="text-bold">www.CloseWise.com/tos</span>
              </a>
              <span className="text-bold">
                , and we will change the "Last Updated" date above. You should
                consult these TERMS regularly for any changes. For current users
                of our Services, any changes to these TERMS will be effective
                thirty (30) calendar days following our posting of the new
                version. For new users of our Services, the TERMS will be
                effective immediately. Continued use of our Services following
                notice of such changes shall indicate your acknowledgement of
                such changes and agreement to be bound by the terms and
                conditions of such changes.
              </span>
              <span className="text-bold"> </span>
              <ol>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">AGE LIMITATION.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    THE SERVICES ARE NOT INTENDED FOR INDIVIDUALS UNDER THE AGE
                    OF 18.  IF YOU ARE UNDER 18, YOU MAY NOT USE THE SERVICES.
                  </span>
                </li>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">Services.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    CloseWise provides an online platform and notary database to
                    bring together:
                  </span>
                  <ul>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">
                        Individuals and entities who are taking out a loan
                      </span>
                      <b>("Borrowers")</b>
                      <span className="text-bold">
                        and working with title and escrow companies
                      </span>
                      <b>("Companies")</b>
                      <span className="text-bold">to close their loans;</span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">
                        Companies seeking notary services to help their
                        Borrower’s sign documents for loan closings;
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">
                        Polished notary signing agents
                      </span>
                      <b>("Notaries")</b>
                      <span className="text-bold">
                        seeking to advertise and provide notary signing
                        services; and
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">
                        Other parties related to a particular closing (e.g.
                        real-estate agents and banks) seeking to monitor the
                        signing of documents by the Borrowers
                      </span>
                      <b>("Participants")</b>
                      <span className="text-bold">.</span>
                    </li>
                  </ul>
                </li>
              </ol>
              <span className="text-bold">
                CloseWise’s responsibilities are limited to providing the
                Services as summarized in this Section 2, and more fully
                described in these TERMS. Certain provisions of these TERMS
                apply only to Companies, Borrowers, Notaries, or Participants as
                described more fully below.
              </span>
              <ol>
                <li className="text-bold" aria-level="2">
                  <span className="text-bold">
                    Our Services enable and assist:
                  </span>
                  <ul>
                    <li className="text-bold" aria-level="3">
                      <span className="text-bold">
                        Companies to (i) upload documents that Borrowers needs
                        to sign with a Notary, (ii) easily browse through, find,
                        and select a Notary that is best equipped to help a
                        Borrower with notary signing services, (iii) create a "
                      </span>
                      <b>Signing Request</b>
                      <span className="text-bold">
                        ," which means to request that a Notary listed on the
                        Services perform the notary signing services described
                        in the request
                      </span>
                      <b>("Notary Assignment")</b>
                      <span className="text-bold">
                        , for instance, assisting a Borrower in signing
                        documents that Company uploaded onto the CloseWise
                        platform, (iv) monitor the Borrower’s signing process to
                        ensure the Borrower signed the documents that Company
                        uploaded onto CloseWise, (v) manage Notary Assignments
                        and mark them as complete, (vi) place Notary Feedback
                        (defined below), (vii) add and grant permissions for
                        Borrowers to interact with the Services as listed in
                        this Section, and (viii) add Participants to monitor the
                        signing of documents for a particular Borrower; and
                      </span>
                    </li>
                    <li className="text-bold" aria-level="3">
                      <span className="text-bold">
                        Notaries to (i) offer and advertise their notary signing
                        services, (ii) receive documents to be signed, and (iii)
                        manage orders from Companies that require their
                        assistance, and (iv) manage Notary Assignments and mark
                        them as complete;
                      </span>
                    </li>
                  </ul>
                </li>
                <li className="text-bold" aria-level="2">
                  <span className="text-bold">Limited Access.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    Borrowers and Participants have limited access to the
                    Services. They can only use the Services if the Company they
                    are working with requests for them to be invited to the
                    Services, and even then, they may only view and interact
                    with the Services in the following ways:
                  </span>
                  <ul>
                    <li className="text-bold" aria-level="3">
                      <span className="text-bold">Borrowers</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        may: (i) view, modify the date and time of, accept, and
                        reject meetings with a Notary, (ii) comment on Notary
                        meetings, and (iii) place feedback either via email or
                        on the Website if the applicable Company requests that
                        CloseWise solicit Borrower feedback.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="3">
                      <span className="text-bold">Participants</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        may only view and track the signing of documents for
                        that Signing Request.
                      </span>
                    </li>
                  </ul>
                </li>
                <li className="text-bold" aria-level="2">
                  <span className="text-bold">If you are a Company.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    As a Company, you represent, warrant and covenant that: (i)
                    the Signing Requests you submit are true and accurate, and
                    (ii) you will pay the agreed upon price for the Notary
                    Assignment that is listed on the Services (payment for the
                    Notary Assignment shall be as set forth in Section 9 of
                    these TERMS). You are solely responsible for accuracy and
                    completeness of the Signing Request you submit and for all
                    content, including, the documents you upload and Notary
                    Assignment, contained in a Signing Request. Submission of a
                    Signing Request does not obligate CloseWise to ensure the
                    Notary is available to fulfill your Signing Request and
                    CloseWise may remove the Signing Request with or without
                    notice to you at any time for any reason. You agree that
                    CloseWise will not be liable to you for any removal of a
                    Signing Request or any part thereof. Additionally, you
                    acknowledge and agree that your use of the Services will
                    include (if and when available) CloseWise’ auto-close logic
                    functionality.  Such functionality shall automatically close
                    transactions submitted to the Services after a 30 calendar
                    day period of inactivity (except as otherwise notified in
                    writing or made available to Customer), unless such
                    transaction is earlier closed or rightfully cancelled.
                  </span>
                </li>
                <li className="text-bold" aria-level="2">
                  <span className="text-bold">If you are a Notary.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    By participating on CloseWise as a Notary, you represent,
                    warrant, and covenant that: (i) the information you submit
                    to us or post on the Services, whether in your profile,
                    invoice, or otherwise, is true and accurate; (ii) you will
                    perform the Notary Assignments in accordance with the
                    applicable laws of your jurisdiction and in accordance with
                    the information provided to you by the Company that
                    submitted the Signing Request; (iii) that you have obtained
                    and will maintain all certifications, licenses and legal
                    requirements to perform the applicable Notary Assignments
                    and that you comply with all applicable laws and industry
                    standards (including business licenses and, to the extent
                    applicable, the collection and remittance of sales taxes);
                    (iv) that you maintain valid workers compensation and
                    professional liability insurance policies in amounts
                    sufficient to meet your statutory obligations and to cover
                    any damages that might occur during or as a result of
                    performing each Notary Assignment; (v) that you will provide
                    the services in a Notary Assignment without imposing
                    restrictions or policies not fully described to Companies in
                    writing prior to such Companies engaging you to perform a
                    Notary Assignment; and (vi) that you will not solicit
                    Companies to pay for your services outside of the Services
                    (payment provisions are set out below) or otherwise
                    circumvent any fees that are paid or would be payable to
                    CloseWise, to the extent applicable. You agree that unless
                    you obtain the consent of any Company, you will use the
                    personal information of Borrowers and Companies provided to
                    you by CloseWise or a Company only to communicate with and
                    provide the services in a Notary Assignment to that Company
                    and that Borrower (or Borrowers), and for no other purpose.
                    You are solely responsible for the accuracy and completeness
                    of the notary signing services you post, or invoice you
                    submit and for all content contained in such submissions.
                    Your submission of a post does not obligate CloseWise to
                    make your posting or any of its contents available on the
                    Services. CloseWise may remove your posting with or without
                    notice to you at any time for any reason. You agree that
                    CloseWise will not be liable to you for any removal of a
                    notary signing services posting or any part thereof. You
                    acknowledge and agree that CloseWise may screen potential
                    Notaries and that CloseWise may publish feedback and
                    recommendations of Notaries by Companies and Borrowers ("
                  </span>
                  <b>Notary Feedback</b>
                  <span className="text-bold">
                    "), which may include reviews of you. You further
                    acknowledge and agree that CloseWise may post Notary
                    Feedback without permitting you to view the Notary Feedback
                    or any information related to the Notary Feedback.
                  </span>
                </li>
                <li className="text-bold" aria-level="2">
                  <span className="text-bold">
                    Disclaimer Regarding Notaries.
                  </span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    CloseWise makes no representation to Borrowers or to
                    Companies, whether through Notary Feedback or otherwise,
                    regarding Notaries, including that such Notaries are
                    qualified to perform the applicable Signing Requests. You
                    acknowledge and agree that the inclusion of a particular
                    Notary on a list of Notaries does not operate as a
                    recommendation, validation, or approval of such Notary by
                    CloseWise. The list of Notaries available through the
                    Services may not be a complete list of all potentially
                    qualified Notaries in any particular area. Each Notary
                    indicates in the personal profile of that Notary (available
                    to you via the Services) certain information and
                    qualifications that may assist you in comparing Notaries,
                    and in selecting the one most suitable to perform your
                    Notary Assignment. In addition, we may publish through the
                    Services Notary Feedback, which are based in part on
                    aggregate Company and Borrower feedback, and which may
                    assist your decision. However, You are solely responsible
                    for selecting a Notary. The Company and Notary are entering
                    into an agreement directly with each other for the Notary to
                    provide the Notary Assignment. Other than with respect to
                    the payment procedures described in these TERMS, Companies
                    and Notaries (and not CloseWise) are solely responsible for
                    any interaction between them. For purposes of clarity,
                    CloseWise has no liability in the performance of a Notary
                    Assignment.
                  </span>
                </li>
                <li className="text-bold" aria-level="2">
                  <span className="text-bold">Only a Venue.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    CloseWise does not control the quality, safety, or legality
                    of any aspect of the Notary Assignment, the ability of
                    Notaries to provide the notary signing services, or the
                    ability of Companies to pay Notaries. The relationship
                    between (i) the Companies and Borrowers and (ii) Notaries
                    are governed by the provisions of these TERMS as follows: if
                    you are a Company, you provide to each Notary the covenants,
                    representations, and warranties provided to CloseWise in
                    Section 2.2. If you are a Notary, you provide to each
                    Company and Borrower the covenants, representations, and
                    warranties provided to CloseWise in Section 2.3. Companies,
                    Borrowers and Notaries each acknowledge that the other is a
                    third-party beneficiary of these TERMS, but solely with
                    respect to Section 2.2 and Section 2.3.
                  </span>
                </li>
                <li className="text-bold" aria-level="2">
                  <span className="text-bold">E-signing Documents.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    CloseWise provides users with an electronic signature
                    solution for U.S. notarized contracts ("
                  </span>
                  <b>E-Sign Solution</b>
                  <span className="text-bold">
                    "). CloseWise complies with the requirements of the U.S.
                    Electronic Signature in Global and National Commerce Act of
                    2000 ("
                  </span>
                  <b>ESIGN</b>
                  <span className="text-bold">
                    ") and Uniform Electronic Transactions Act ("
                  </span>
                  <b>UETA</b>
                  <span className="text-bold">
                    "), which should make your electronic signature on notary
                    contracts legally binding. By using the E-Sign Solution, you
                    agree to transact business using electronic communications,
                    electronic records, and electronic signatures rather than
                    using paper documents to communicate, keep records, and
                    sign. For avoidance of doubt, by using the E-Sign Solution,
                    you agree to use an electronic signature in lieu of a
                    hand-written signature on paper. You acknowledge and agree
                    that legislation such as ESIGN and UETA have provided
                    electronic signatures the legal equivalence of handwritten
                    signatures on paper, and thus should make your e-signature
                    legally binding in the United States and analogues where
                    statutes have been adopted. However, CloseWise does not make
                    any warranties or guarantees as to the enforceability of
                    ESIGN and UETA for transactions and documents. You agree not
                    to electronically sign a document without first reading and
                    understanding it, thus demonstrating that you are able to
                    access the electronic information contained within the
                    document before signing. You acknowledge and agree that per
                    ESIGN you are not required to use our E-Sign Solution, or
                    electronically sign documents in another way, if you prefer
                    not to do so.
                  </span>
                </li>
                <li className="text-bold" aria-level="2">
                  <span className="text-bold">Signature Verification.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    CloseWise verifies that the signature belongs to a
                    particular user by using your personal information, for
                    example your Account information, login credentials, email
                    verification, IP address capture when a user signs a
                    document, and group password mechanisms that CloseWise may
                    provide. Any user using an electronic signature on CloseWise
                    must have an Account. CloseWise uses commercially reasonable
                    standards governed by our internal policies to ensure user
                    information is secure. However, CloseWise does not guarantee
                    the identity of any user who signs a document and makes no
                    warranties or guarantees as to the identity of users using
                    the Services.
                  </span>
                </li>
                <li className="text-bold" aria-level="2">
                  <span className="text-bold">Audit Trails.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    You acknowledge and agree that CloseWise may create an audit
                    trail on your transactions to provide you with a
                    transactions history. We track and timestamp various
                    information from the time a document is uploaded by a
                    Company for a Borrower’s signature to when it is signed by a
                    Borrower. You acknowledge and agree that any audit trails
                    created by CloseWise may not be admissible in a court of
                    law. We do not warrant or guarantee that any audit trails
                    are legally binding.
                  </span>
                </li>
                <li className="text-bold" aria-level="2">
                  <span className="text-bold">Necessary Equipment.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    You must provide all equipment and software necessary to
                    connect to the Services, including but not limited to, a
                    computer that is suitable to connect with and use the
                    Services. You are solely responsible for any fees, including
                    Internet connection or mobile fees, that you incur when
                    accessing the Services.
                  </span>
                </li>
              </ol>
              <ol>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">Disclaimers; Release.</span>
                  <ol>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">Compliance with Laws.</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        As noted above, Notaries are solely responsible for
                        compliance with all applicable laws, rules, regulations
                        and local ordinances, including without limitation,
                        necessary permits, certifications, insurance coverage,
                        surety bond requirements, or other taxes and payments
                        which may be applicable to the performance of their
                        notary signing services.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">
                        Limited Identity Verification.
                      </span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        You authorize us, directly or through third parties, to
                        make any inquiries we consider necessary to help verify
                        or check your identity or prevent fraud, including with
                        respect to our handling of payments through the Services
                        as described below. This may include asking you to
                        provide a form of government identification (e.g.
                        driver’s license or passport), your date of birth, and
                        other information requiring you to take steps to confirm
                        ownership of your email address and/or Payment Provider
                        Account (as defined below); or attempting to screen your
                        information against third party databases. Nevertheless,
                        you acknowledge that CloseWise is not required to
                        confirm or attempt to confirm any particular user’s
                        purported identity, and does not warrant that our
                        efforts will be successful. We do not endorse any
                        persons or entities who use or register for the
                        Services. You are finally responsible, whether as a
                        Notary, Company, or Borrower for determining the
                        identity and suitability of others whom you may contact
                        or who may contact you by means of the Services. You
                        agree to exercise caution and good judgment in all
                        interactions with other users. CloseWise encourages you
                        to communicate directly with others through the tools
                        available via the Services and to conduct your own
                        investigation of the other users with whom you interact.
                        You may also wish to consider using a third-party
                        service that provides additional user verification.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">If you are a Notary,</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        you further authorize CloseWise to verify your insurance
                        coverage, notary certification, proof that you have
                        conducted a background check, and proof that you have a
                        surety bond (if laws applicable to you require such a
                        bond). You acknowledge that CloseWise uses commercially
                        reasonable efforts governed by our then existing
                        internal policies to verify Notaries.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">RELEASE.</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <b>
                        CloseWise makes no warranties regarding users or the
                        Services the content made available through the
                        Services, or the interactions (if any) between the users
                        within or outside of the Services, and each of the
                        foregoing is provided by us "as is."
                      </b>
                      <span className="text-bold">
                         Your interactions with other users are solely between
                        you and such user and CloseWise shall not be responsible
                        for any loss or damage incurred as the result of any
                        such interactions. Without limiting the foregoing, as a
                        Company, you should conduct your own investigation prior
                        to hiring a Notary to perform a Notary Assignment. If
                        there is a dispute between you and any other user,
                        CloseWise is under no obligation to become involved, but
                        reserves the right to do so. The foregoing disclaimers
                        shall not limit the more general disclaimers in these
                        TERMS. To the extent permitted under applicable laws,
                        you hereby release and forever discharge us (and our
                        officers, employees, agents, successors, and assigns)
                        from, and hereby waive and release, each and every past,
                        present and future dispute, claim, controversy, demand,
                        right, obligation, liability, action and cause of action
                        of every kind and nature (including personal injuries,
                        death, and property damage), that has arisen or arises
                        directly or indirectly out of, or relates directly or
                        indirectly to: (i) any incorrect or inaccurate content
                        posted or uploaded on the Services, whether caused by us
                        or any user of the Services, or by any of the equipment
                        or programming associated with or utilized in the
                        Services; (ii) the conduct, whether online or offline,
                        of any Company, Notary, Borrower, or Participant; (iii)
                        any injury, loss or damage caused by another user, a
                        Signing Request, Notary Assignment, or content posted on
                        the Services, whether online or offline; and (iv) any
                        error, omission, interruption, deletion, defect, delay
                        in operation or transmission, communications line
                        failure, theft or destruction or unauthorized access to,
                        or alteration of, the Services’ users’ communications.
                        If you are a California resident, you hereby waive
                        California Civil Code Section 1542, which states: 
                      </span>
                      <b>
                        "A general release does not extend to claims which the
                        creditor does not know or suspect to exist in his favor
                        at the time of executing the release, which, if known by
                        him must have materially affected his settlement with
                        the debtor."
                      </b>
                      <span className="text-bold">
                         The foregoing release does not apply to any claims,
                        demands, or any losses, damages, rights and actions of
                        any kind, including personal injuries, death or property
                        damage for any unconscionable commercial practice by
                        CloseWise or for CloseWise’ fraud, deception, false,
                        promise, misrepresentation or concealment, suppression
                        or omission of any material fact in connection with the
                        Services.
                      </span>
                    </li>
                  </ol>
                </li>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">Access to the Services.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    Subject to these TERMS, we hereby grant you a limited,
                    non-transferable, non-sub-licensable, non-exclusive license
                    to use the Services on any applicable devices that are under
                    your control, and to use the Services solely as applicable
                    herein. The Services may not be used for any other purposes
                    without our prior written consent. You may not use the
                    Services if you are not the owner or approved administrator
                    of the device on which you activated or accessed the
                    Services.
                  </span>
                </li>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">
                    User Generated Content; Acceptable Use Policy.
                  </span>
                  <ol>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">
                        "User Generated Content"
                      </span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        means any and all information and content that a user
                        submits to, or uses with, the Services, such as Signing
                        Requests, Notary Assignments, information regarding your
                        services if you are a Notary, documents uploaded if you
                        are a Company or Borrower, Statements, invoices,
                        messages, calls, etc. You are solely responsible for
                        your User Generated Content. You assume all risks
                        associated with use of your User Generated Content,
                        including any reliance on its accuracy, completeness, or
                        usefulness by others, or any disclosure of your User
                        Generated Content that makes you or any third party
                        personally identifiable. You hereby represent and
                        warrant that your User Generated Content does not
                        violate the Acceptable Use Policy as defined in Section
                        5.4. You may not state or imply that your User Generated
                        Content is in any way provided, sponsored, or endorsed
                        by CloseWise. Because you alone (and not CloseWise) are
                        responsible for your User Generated Content, you may
                        expose yourself to liability if, for example, your User
                        Generated Content violates the Acceptable Use Policy.
                        CloseWise is not obligated to backup any User Generated
                        Content and User Generated Content may be deleted at any
                        time. You are solely responsible for creating backup
                        copies of your User Generated Content if you desire.
                        CloseWise has no responsibility or liability for the
                        deletion or accuracy of any User Generated Content; the
                        failure to store, transmit, or receive transmission of
                        User Generated Content; or the security, privacy,
                        storage, or transmission of other communications
                        originating with or involving use of the Services.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">
                        Third Party Consent to Share Information.
                      </span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        YOU REPRESENT, WARRANT, AND CONVENANT TO ONLY PROVIDE
                        INFORMATION TO THE SERVICES THAT YOU ARE ALLOWED TO
                        PROVIDE WITHOUT VIOLATING ANY OBLIGATIONS YOU MIGHT HAVE
                        TOWARDS A THIRD PARTY, INCLUDING ANY CONFIDENTIALITY
                        OBLIGATIONS. PLEASE DO NOT PROVIDE ANY INFORMATION THAT
                        YOU ARE NOT ALLOWED TO SHARE WITH OTHERS, INCLUDING BY
                        CONTRACT OR LAW; PLEASE NOTE THAT ANY INFORMATION YOU
                        PROVIDE MAY BE ACCESSIBLE BY USERS OF THE SERVICES.
                        NOTWITHSTANDING THE FORGOING, IF YOU ARE A COMPANY, YOU
                        REPRESENT, WARRANT, AND COVENANT THAT YOU HAVE OBTAINED
                        FROM EACH BORROWER WHOSE INFORMATION YOU MAKE AVAILABLE
                        ON OUR SERVICES OR WEBSITE, INCLUDING WITHOUT LIMITATION
                        PERSONALLY IDENTIFIABLE INFORMATION AND SENSITIVE
                        INFORMATION (COLLECTIVELY, "
                      </span>
                      <b>BORROWER INFORMATION</b>
                      <span className="text-bold">
                        ") ALL CONSENTS REQUIRED TO SHARE SUCH BORROWER
                        INFORMATION WITH CLOSEWISE AND FOR CLOSEWISE TO USE AND
                        DISCLOSE SUCH BORROWER INFORMATION IN ACCORDANCE WITH
                        OUR PRIVACY POLICY.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">
                        Rights and Sharing User Generated Content.
                      </span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        By making available your User Generated Content on or in
                        the Services, you represent that you own or have all
                        rights necessary to make available your User Generated
                        Content. When you submit certain User Generated Content,
                        you acknowledge and agree that such User Generated
                        Content may be shared with other users in accordance
                        with the TERMS.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">
                        Our Acceptable Use Policy.
                      </span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        The following sets forth our "
                      </span>
                      <b>Acceptable Use Policy</b>
                      <span className="text-bold">
                        ": as a condition of use, you agree not to use the
                        Services for any purpose that is prohibited by the TERMS
                        or by applicable law. You will not (and will not permit
                        any third party) either (i) take any action or (ii) make
                        available any User Generated Content on or through the
                        Services that:
                      </span>
                      <ul>
                        <li className="text-bold" aria-level="3">
                          <span className="text-bold">
                            violates any third-party right, including any
                            copyright, trademark, patent, trade secret, moral
                            right, privacy right, right of publicity, or any
                            other intellectual property or proprietary right;
                          </span>
                        </li>
                        <li className="text-bold" aria-level="3">
                          <span className="text-bold">
                            is unlawful, threatening, abusive, harassing,
                            harmful, defamatory, trade libelous, deceptive,
                            fraudulent, false, intentionally misleading,
                            pornographic, invasive of another’s privacy,
                            tortious, obscene, patently offensive (e.g.,
                            material that promotes racism, bigotry, hatred, or
                            physical harm of any kind against any group or
                            individual), vulgar, profane or otherwise
                            objectionable material of any kind or nature or
                            which is harmful to minors in any way or which may
                            create risk of harm, loss, physical or mental
                            injury, emotional distress, death, disability,
                            disfigurement, or physical or mental illness to you
                            or to any other person or may constitute a crime or
                            tort;
                          </span>
                        </li>
                        <li className="text-bold" aria-level="3">
                          <span className="text-bold">
                            contains any information or content that is not
                            correct and current;
                          </span>
                        </li>
                        <li className="text-bold" aria-level="3">
                          <span className="text-bold">
                            in violation of any laws, or obligations or
                            restrictions imposed by any third party;
                          </span>
                        </li>
                        <li className="text-bold" aria-level="3">
                          <span className="text-bold">
                            constitutes unauthorized or unsolicited advertising,
                            promotional materials, junk mail, spam, chain
                            letters, pyramid schemes, or any other form of
                            duplicative or unsolicited messages, whether
                            commercial or otherwise;
                          </span>
                        </li>
                        <li className="text-bold" aria-level="3">
                          <span className="text-bold">
                            involves commercial activities and/or sales without
                            CloseWise prior written consent, such as contests,
                            sweepstakes, barter, advertising, or pyramid
                            schemes;
                          </span>
                        </li>
                        <li className="text-bold" aria-level="3">
                          <span className="text-bold">
                            impersonates any person or entity, including any
                            employee or representative of CloseWise, or
                            falsifies or misrepresents yourself, your
                            credentials, or your affiliation with any person or
                            entity;
                          </span>
                        </li>
                        <li className="text-bold" aria-level="3">
                          <span className="text-bold">
                            interferes with or attempt to interfere with the
                            proper functioning of the Services or uses the
                            Services in any way not expressly permitted by the
                            TERMS;
                          </span>
                        </li>
                        <li className="text-bold" aria-level="3">
                          <span className="text-bold">
                            bypasses the measures we may use to prevent or
                            restrict access to the Services, including, but not
                            limited to, registering for the Services with a
                            fraudulent email address;
                          </span>
                        </li>
                        <li className="text-bold" aria-level="3">
                          <span className="text-bold">
                            harasses or interferes with another user’s use and
                            enjoyment of the Services;
                          </span>
                        </li>
                        <li className="text-bold" aria-level="3">
                          <span className="text-bold">
                            harvests, collects, gathers or assembles information
                            or data regarding other users, including e-mail
                            addresses, without their consent;
                          </span>
                        </li>
                        <li className="text-bold" aria-level="3">
                          <span className="text-bold">
                            displays, mirrors, or frames the Services;
                          </span>
                        </li>
                        <li className="text-bold" aria-level="3">
                          <span className="text-bold">
                            attempts to engage in or engage in, any potentially
                            harmful acts that are directed against the Services,
                            including but not limited to violating or attempting
                            to violate any security features of the Services,
                            using manual or automated software or other means to
                            access, "scrape," "crawl" or "spider" any pages
                            contained in the Services, uploading invalid data,
                            introducing viruses, worms, or any software intended
                            to damage or alter a computer system or data,
                            interfering with, disrupting, or creating an undue
                            burden on servers or networks connected to the
                            Services or violate the regulations, policies or
                            procedures of such networks, attempting to gain
                            unauthorized access to the Services, other computer
                            systems or networks connected to or used together
                            with the Services, through password mining or other
                            means;
                          </span>
                        </li>
                        <li className="text-bold" aria-level="3">
                          <span className="text-bold">
                            violates the additional usage requirements in
                            Section 9 below with respect to our payment
                            services, as they are a part of the Acceptable Use
                            Policy.
                          </span>
                        </li>
                      </ul>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">
                        No Obligation to Pre-Screen Content.
                      </span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        The Services may contain User Generated Content provided
                        by other users or third parties. You acknowledge that
                        CloseWise has no obligation to pre-screen User Generated
                        Content, although CloseWise reserves the right in its
                        sole discretion to pre-screen, refuse, or remove any
                        User Generated Content at any time for any reason. By
                        entering into these TERMS, you hereby provide your
                        irrevocable consent to such monitoring.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">Investigations.</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        We may, but are not obligated to, monitor or review the
                        Services at any time, investigate, and/or take
                        appropriate action against you in our sole discretion
                        (including removing or modifying your User Generated
                        Content, terminating your Account, and/or reporting you
                        to law enforcement authorities) if you violate any
                        provision of these TERMS, any applicable law, or
                        otherwise create liability or us or any other person.
                      </span>
                    </li>
                  </ol>
                </li>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">Using the Services.</span>
                  <ol>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">Registration.</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        To use the Services, you may have to register for an
                        account with us ("
                      </span>
                      <b>Account</b>
                      <span className="text-bold">
                        "). To create an Account, you must provide certain
                        information about yourself as prompted by the
                        registration process. You acknowledge and agree to
                        submit varying types of information depending on whether
                        you login as a Notary, Company, Borrower, or
                        Participant. You represent and warrant that: (i) all
                        required registration information you submit is truthful
                        and accurate; and (ii) you will maintain the accuracy of
                        such information. You may deactivate your Account at any
                        time, for any reason, by following the instructions on
                        the Website. You are responsible for maintaining the
                        confidentiality of your Account login information and
                        are fully responsible for all activities that occur
                        under your Account, including payment authorizations.
                        You agree to immediately notify CloseWise of any
                        unauthorized use, or suspected unauthorized use of your
                        Account or any other breach of security. CloseWise
                        cannot and will not be liable for any loss or damage
                        arising from your failure to comply with the above
                        requirements. You agree not to create an Account or use
                        the Services if you have been previously removed by us
                        or banned from any of the Services. CloseWise reserves
                        the right in its sole discretion to suspend or terminate
                        your Account and refuse any and all current or future
                        use of the Services (or any portion thereof) at any time
                        for any reason. You agree that CloseWise will not be
                        liable for any suspension or termination of your Account
                        or any refusal of any use of the Services (or any
                        portion thereof). CloseWise reserves the right in its
                        sole discretion to remove any content you provide or
                        upload at any time for any reason. You agree that
                        CloseWise will not be liable for such removal.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">User Restrictions.</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        The rights granted to you in these TERMS are subject to
                        the following restrictions: you shall not: (i) permit
                        any third party to use the Services (unless you are a
                        Company or Borrower permitting a Friend to use the
                        Services); (ii) circumvent, disable or otherwise
                        interfere with security-related features of the
                        Services; (iii) modify, create a derivative work of,
                        reverse engineer, decompile or disassemble the Services
                        except to the extent permitted by applicable law and, in
                        such an event, provided you first give us written notice
                        thereof; (iv) remove, deface, obscure, or alter the
                        Services, or any third parties, copyright notices,
                        trademarks, or other proprietary rights notices affixed
                        to or provided as part of the Services; (v) use the
                        Services for benchmarking purposes or to develop any
                        software, product or service that is the same as,
                        substantially similar to, or competitive with the
                        Services; (vi) use the Services to promote, conduct, or
                        contribute to fraudulent, obscene, pornographic, illegal
                        or otherwise inappropriate activities, including without
                        limitation, deceptive impersonation in connection with
                        contests, pyramid schemes, surveys, chain letters, junk
                        e-mail, spamming or any duplicative or unsolicited
                        messages; (vii) use any robot, spider, other automated
                        device or any toolbar, web-bar, other web-client,
                        device, software, routine or manual process to monitor
                        or scrape information from the Services; (viii)
                        otherwise use the Services in any unlawful manner or in
                        breach of these TERMS; or (ix) share User Generated
                        Content (other than your own User Generated Content)
                        outside the Services without the applicable User’s
                        permission.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">Other Restrictions.</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        Unless otherwise indicated, any future release, update,
                        or other addition to functionality of the Services shall
                        be subject to these TERMS. You shall not agree to, nor
                        shall you authorize or encourage any third party to: (i)
                        prevent others from using the Services; (ii) use the
                        Services for any fraudulent or inappropriate purpose; or
                        (iii) act in any way that violates the TERMS and
                        Services’ policies as may be revised from time to time.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">Disclaimer.</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        We make no representation or warranties that the
                        Services are appropriate for use in locations other than
                        the United States or that the Services are or will be
                        available for use in any particular location or at
                        specific times. We may change, modify, suspend, or
                        discontinue any aspect of the Services (including the
                        payment services) at any time and without prior notice.
                        You agree that we will not be liable for any change,
                        modification, suspension, or discontinuation of the
                        Services or any part thereof.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">Updates.</span>
                    </li>
                  </ol>
                </li>
              </ol>
              <ol>
                <li className="text-bold" aria-level="3">
                  <span className="text-bold">Update to the Services.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    We may update the Services periodically but you agree that
                    we are under no obligation to do so or to provide technical
                    support or maintenance. If we do update the Services, it
                    will be done automatically without notice to you if these
                    updates are designed to improve, enhance or fix bugs in the
                    then current version of the Services. By accepting these
                    TERMS, you agree to such updates.
                  </span>
                </li>
                <li className="text-bold" aria-level="3">
                  <span className="text-bold">Update to these TERMS.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    If we modify these TERMS, we will either post the
                    modification on the Site or otherwise provide you with
                    notice of the modification. We will also update the "Posted"
                    date at the top of these TERMS. By continuing to use the
                    Services (including the payment services) after we have
                    posted a modification or have provided you with notice of a
                    modification, you are indicating that you agree to be bound
                    by the modified TERMS. If the modified TERMS are not
                    acceptable to you, your only recourse is to cease using the
                    Services.
                  </span>
                </li>
              </ol>
              <ol>
                <li className="text-bold" aria-level="2">
                  <span className="text-bold">
                    Your access and use of the Services is at your own risk and
                    responsibility and you shall comply with all laws, rules and
                    regulations applicable to you. As a part of the Services,
                    you may have access to materials that are hosted by another
                    party. You agree that it is impossible for CloseWise to
                    monitor such materials and that you access these materials
                    at your own risk.
                  </span>
                </li>
              </ol>
              <ol>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">
                    Intellectual Property Rights.
                  </span>
                  <ol>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">CloseWise Ownership.</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        The Services' features, design and content, including
                        without limitation, all text, documents, products,
                        software, scripts, graphics, and services provided
                        therein and the trademarks, service marks and logos
                        contained therein ("
                      </span>
                      <b>Content and Marks</b>
                      <span className="text-bold">
                        ") are either owned by us or owned by third parties and
                        licensed to us. The Content and Marks are protected
                        under United States and foreign copyright and
                        intellectual property laws and treaties. The Content and
                        Marks are provided to you "AS IS" for your information
                        and personal use only and are subject to the terms and
                        conditions of these TERMS. You agree not to use, copy,
                        or distribute the Services or any part thereof other
                        than as expressly permitted herein, or to interfere with
                        security-related features of the Services. Nothing in
                        these TERMS constitutes a waiver of our intellectual
                        property rights under any law. Our name, logo, and the
                        product names associated with the Services belong to us
                        or our licensors, and no right or license is granted to
                        use them by implication, estoppel or otherwise. Neither
                        these TERMS nor your access to the Services transfers to
                        you or any third party any rights, title or interest in
                        or to such intellectual property rights, except for the
                        limited usage and access rights expressly set forth in
                        Section 4. CloseWise and its licensors reserve all
                        rights not granted in these TERMS. CloseWise does not
                        grant any implied licenses under these TERMS.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">User Ownership.</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        Each user owns its own User Generated Content.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">License to CloseWise.</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        You hereby grant, and you represent and warrant that you
                        have the right to grant, to CloseWise an irrevocable,
                        perpetual, nonexclusive, royalty-free, and fully paid,
                        worldwide license to license, reproduce, distribute,
                        modify, adapt, publicly display and perform, prepare
                        derivative works of, incorporate into other works, and
                        otherwise use your User Generated Content (in whole or
                        in part), and to grant sublicenses of the foregoing,
                        solely for the purposes of including your User Generated
                        Content in the Services and operating and providing the
                        Site and Services. You agree to irrevocably waive (and
                        cause to be waived) any claims and assertions of moral
                        rights or attribution with respect to your User
                        Generated Content.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">User Feedback.</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        You agree that submission of any ideas, suggestions,
                        and/or proposals to us ("
                      </span>
                      <b>Feedback</b>
                      <span className="text-bold">
                        ") is at your own risk and that CloseWise has no
                        obligations (including without limitation obligations of
                        confidentiality) with respect to such Feedback. You
                        represent and warrant that you have all rights necessary
                        to submit the Feedback. You hereby grant to CloseWise a
                        fully paid, royalty-free, perpetual, irrevocable,
                        worldwide, non-exclusive, and fully sublicensable right
                        and license to use, reproduce, perform, display,
                        distribute, adapt, modify, re-format, create derivative
                        works of, and otherwise commercially or non-commercially
                        exploit in any manner it deems appropriate, any and all
                        Feedback, and to sublicense the foregoing rights. We
                        will treat any Feedback you provide to us as
                        non-confidential and non-proprietary. You agree that you
                        will not submit to us any information or ideas that you
                        consider to be confidential or proprietary. 
                      </span>
                      <b>If you are a Notary</b>
                      <span className="text-bold">
                        , you acknowledge and agree that feedback about you may
                        not be accessible, viewable, or editable by you, and may
                        be visible to other users without your knowledge or
                        consent.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">
                        Copyright Infringement Claim.
                      </span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        It is CloseWise’ policy to respect the legitimate rights
                        of copyright and other intellectual property owners, and
                        we have adopted and implemented a policy respecting
                        copyright law that provides for the removal of any
                        infringing materials and for the termination, in
                        appropriate circumstances, of users of our Services who
                        are repeat infringers of intellectual property rights,
                        including copyrights. Should you believe that content
                        available via the Services unlawfully infringes one or
                        more of your copyrights and you wish to have the
                        infringing material removed, the following information
                        in the form of a written notification (pursuant to 17
                        U.S.C. § 512(c)) must be provided to our designated
                        Copyright Agent:
                      </span>
                    </li>
                  </ol>
                </li>
              </ol>
              <ul>
                <li className="text-bold" aria-level="3">
                  <span className="text-bold">
                    A physical or electronic signature of the copyright owner or
                    a person authorized to act on their behalf;
                  </span>
                </li>
                <li className="text-bold" aria-level="3">
                  <span className="text-bold">
                    identification of the copyrighted work claimed to have been
                    infringed;
                  </span>
                </li>
                <li className="text-bold" aria-level="3">
                  <span className="text-bold">
                    a description of the nature and exact location of the
                    content that you claim to infringe your copyright, in
                    sufficient detail so to allow CloseWise to locate and
                    identify such content;
                  </span>
                </li>
                <li className="text-bold" aria-level="3">
                  <span className="text-bold">
                    your name, address, telephone number and email address;
                  </span>
                </li>
                <li className="text-bold" aria-level="3">
                  <span className="text-bold">
                    a statement that you have a good faith belief that the use
                    of the material in the manner described in your Complaint is
                    not authorized by the copyright owner, its agent, or the
                    law; and
                  </span>
                </li>
                <li className="text-bold" aria-level="3">
                  <span className="text-bold">
                    a statement that the information in the Complaint is
                    accurate, and that you are either the copyright owner or a
                    person authorized to act on their behalf
                  </span>
                </li>
              </ul>
              <b>CloseWise designated Copyright Agent is: </b>
              <a
                target="_blank"
                href="mailto:support@snapdocs.com"
                rel="noreferrer"
              >
                <b>support@CloseWise.com</b>
                <span className="text-bold"></span>
                <span className="text-bold"></span>
              </a>
              <span className="text-bold">
                You acknowledge that for us to be authorized to takedown any
                content, your DMCA takedown notice must comply with all the
                requirements of this Section 7.5. Please note that a copy of
                your complaint, including any contact information you provide
                (address, telephone number, and email address), will be
                forwarded to the person or entity whose content you claim
                infringes your right.
              </span>
              <ol>
                <li className="text-bold" aria-level="2">
                  <span className="text-bold">
                    Please note that, pursuant to 17 U.S.C. § 512(f), any
                    misrepresentation of material fact (falsities) in a written
                    notification automatically subjects the complaining party to
                    liability for any damages, costs and attorney’s fees
                    incurred by us in connection with the written notification
                    and allegation of copyright infringement.
                  </span>
                </li>
              </ol>
              <ol>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">Privacy.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    Our Privacy Policy, located here: 
                  </span>
                  <a
                    target="_blank"
                    href="https://www.snapdocs.com/privacy_policy"
                    rel="noreferrer"
                  >
                    <span className="text-bold">
                      https://www.CloseWise.com/privacy_policy
                    </span>
                  </a>
                  <span className="text-bold">
                     describes our practices regarding your personal
                    information.
                  </span>
                </li>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">Fees and Financial Terms.</span>
                  <ol>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">Types of Fees.</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        Upon completion of a Notary Assignment, the Company that
                        made the Signing Request for such Notary Assignment will
                        be charged: (1) a fee that in exchange for that Notary’s
                        performance of the Notary Assignment ("
                      </span>
                      <b>Notary Fee</b>
                      <span className="text-bold">
                        "); and (2) a fee for use of the Services ("
                      </span>
                      <b>CloseWise Fee</b>
                      <span className="text-bold">
                        "). Collectively, the Notary Fee and CloseWise Fee are
                        the "
                      </span>
                      <b>Fees</b>
                      <span className="text-bold">
                        ." The Company may elect to pay the Fees via one of the
                        two ways described in Section 9.2.C (Payment Options).
                        All Fees listed on the Services are in US dollars unless
                        otherwise specified herein or on the Services. Company
                        is responsible for paying the Fees once that Company or
                        the applicable Notary marks the Notary Assignment
                        complete on our Services. For clarity, the Fees are
                        charged for each Notary Assignment marked as complete by
                        either the applicable Notary or Company (i.e. the Fees
                        are charged on a per transaction basis).
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">
                        Company Financial Terms.
                      </span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        The terms in this Section 9.2 only apply to users who
                        are Companies. For purposes of this Section 9.2, "you"
                        refers only to users that are Companies (and no other
                        users).
                      </span>
                    </li>
                  </ol>
                </li>
              </ol>
              <ol>
                <li className="text-bold" aria-level="3">
                  <span className="text-bold">Payment Methods.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    When you create an Account, you must provide us with valid
                    credit card information (Visa, Mastercard, or any other
                    issuer accepted by us) or automated clearing house account
                    information ("
                  </span>
                  <b>Payment Provider</b>
                  <span className="text-bold">" and "</span>
                  <b>Payment Provider Account</b>
                  <span className="text-bold">
                    "). We may, in our sole discretion, waive the requirement in
                    the previous sentence, in which case you may pay the Fees
                    via a commercial check, cashiers check, or another format
                    that we mutually agree upon. Your Payment Provider agreement
                    governs your use of the designated Payment Provider Account,
                    and you must refer to that agreement and not the TERMS to
                    determine your rights and liabilities. By providing us with
                    your Payment Provider Account, you agree we are authorized
                    to immediately charge your Payment Provider Account for all
                    charges due and payable to us (and to the Notary depending
                    on the payment option you select from Section 9.2.C (Payment
                    Options)) in connection with your Account and that no
                    additional notice or consent is required. By submitting a
                    Signing Request, you represent that you are an authorized
                    user of the Payment Provider Account provided. Please note
                    that we cannot control, and disclaim all liability
                    associated with, any fees that may be charged by your bank
                    in relation to our collection of the Fees.
                  </span>
                </li>
                <li className="text-bold" aria-level="3">
                  <span className="text-bold">Amount of Fees.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    In connection with the creation of your Account, you and us
                    will agree upon our CloseWise Fee. The amount of the Notary
                    Fees will vary depending on the Notary Assignment (e.g. the
                    Notary Fee is different for a Notary Assignment that is for
                    a refinancing Notary Assignment versus a Notary Assignment
                    for a new loan) and is at your discretion. The amount of the
                    CloseWise Fee may vary from Company to Company. After you
                    login to your Account, and while you create a Signing
                    Request, the Services will display the Notary Fee you would
                    owe (next to a description of the Notary Assignment) should
                    you submit the Signing Request. For clarity, the Services
                    will inform you of the total Notary Fees you would owe
                    before you submit the Signing Request. CloseWise may enable
                    features in your Account that permit you submit the least
                    and greatest amount of Notary Fees you be willing to pay for
                    each Notary Assignment. By submitting a Signing Request, you
                    agree to pay all the Fees for that Signing Request in
                    accordance with the fees, charges, and billing terms in
                    effect at the time the charge is due and payable. A list of
                    all the Fees you owe are available to view in your Account
                    and we will send you periodic invoices for the Fees you owe
                    depending on the payment option you choose in Section 9.2.C
                    (Payment Options). If you have questions or concerns about
                    our billing practices, please contact us at the contact
                    information listed in Section 20 (Questions and Concerns).
                  </span>
                </li>
                <li className="text-bold" aria-level="3">
                  <span className="text-bold">Payment Options.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    We offer you two payment options, and your Account will be
                    subject to the option you and we mutually agree upon when
                    you create your Account.
                  </span>
                  <ul>
                    <li className="text-bold" aria-level="4">
                      <span className="text-bold">
                        Under the first payment option, you must pay each Notary
                        Fee directly to each Notary on your own and outside of
                        the Services, and your Payment Provider Account will be
                        automatically charged each month for the CloseWise Fees
                        you owe for that month ("
                      </span>
                      <b>Classical Invoice</b>
                      <span className="text-bold">").</span>
                    </li>
                    <li className="text-bold" aria-level="4">
                      <span className="text-bold">
                        Under the second payment option, you must pay each
                        notary fee through the agent pay breakdown service
                        inside the CloseWise service, and your Payment Provider
                        Account will be automatically charged each month for the
                        CloseWise Fees you owe for that month.
                      </span>
                    </li>
                  </ul>
                </li>
              </ol>
              &nbsp;
              <ol>
                <li className="text-bold" aria-level="3">
                  <span className="text-bold">Payment Changes.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    You agree to immediately notify us of any change in your
                    Payment Provider Account, including your billing address,
                    used for payment hereunder. We reserve the right, at any
                    time, to change our prices and billing methods, either
                    immediately or upon posting on our Services or by email
                    delivery to you.
                  </span>
                </li>
                <li className="text-bold" aria-level="3">
                  <span className="text-bold">Only a Venue.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    You agree that only the Notary, and not CloseWise, is
                    responsible for performing each Notary Assignment, and
                    accounting for damages resulting from performance of such
                    Notary Assignment. You acknowledge and agree that by
                    submitting each Signing Request, you enter into an agreement
                    with the Notary who accepted your Signing Request, and that
                    we, CloseWise, are not a party to such agreements. You
                    further acknowledge and agree that with the exception of
                    CloseWise’ obligations to process payments pursuant to
                    these TERMS, we disclaim all liability arising from or
                    related to any such agreements between you and Notaries.
                  </span>
                </li>
              </ol>
              <ol>
                <li className="text-bold" aria-level="2">
                  <span className="text-bold">Notary Financial Terms.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    The terms in this Section 9.3 only apply to users who are
                    Notaries. For purposes of this Section 9.3, "you" refers to
                    users who are Notaries (and no other users).
                  </span>
                  <ol>
                    <li className="text-bold" aria-level="3">
                      <span className="text-bold">Limited Agent.</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        Subject to these TERMS, each Notary hereby appoints us
                        as its limited agent for the sole purpose of accepting
                        and collecting Notary Fees from Companies who choose
                        VendorPay. You acknowledge and agree that payments made
                        to us by a Company using VendorPay shall be considered
                        the same as a payment made directly to you. You agree to
                        indemnify us in the event a Company triggers a
                        chargeback of any Notary Fees that are paid to you.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="3">
                      <span className="text-bold">Disclaimer.</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        If we are unable to collect any Fees, we will inform you
                        in writing. However, we have no obligation to impose
                        collection procedures on Companies and we do not
                        guarantee payment from Companies.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="3">
                      <span className="text-bold">Taxes.</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        Tax regulations may require us to collect appropriate
                        tax information from you or to withhold taxes from
                        payouts to you, or both. For instance, IRS regulations
                        stipulate that we must collect an IRS Form W-9 from
                        certain US Notaries. You are solely responsible for
                        keeping information in your tax forms current, complete,
                        and accurate. If you fail to provide us with
                        documentation that we determine to be sufficient to
                        alleviate our obligation (if any) to withhold taxes from
                        payments to you, we reserve the right in our sole
                        discretion to freeze all payments of Notary Fees to you
                        until resolution, to withhold such amounts as required
                        by law, or to do both. You understand and agree that you
                        are solely responsible for determining your applicable
                        tax reporting requirements and for remitting to the
                        relevant authority any taxes included or received by you
                        through the Services. We cannot and do not offer tax
                        advice to our users.
                      </span>
                    </li>
                  </ol>
                </li>
                <li className="text-bold" aria-level="2">
                  <span className="text-bold">
                    Our Third Party Payment Processor.
                  </span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    In connection with your use of the Services, you will be
                    asked to provide billing information such as your name,
                    billing address and Payment Provider Account either to us or
                    to our third-party payment processor, Stripe. You hereby
                    authorize us and Stripe to store your Payment Provider
                    Account and charge your Payment Provider Account as outlined
                    herein. If your Payment Provider Account contains errors,
                    you agree to allow us to acquire the correct information and
                    update the Payment Provider Account that is on file in your
                    Account. By paying or receiving funds through the Services,
                    you agree to be bound by Stripe’s US Terms of Service
                    available at https://stripe.com/us/terms and Privacy Policy
                    available at https://stripe.com/us/privacy. You hereby
                    consent to provide and authorize CloseWise and Stripe to
                    share any information and payment instructions you provide
                    to the extent required to complete the payment transactions
                    in connection with providing the Services, including
                    personal, financial, credit card payment, and transaction
                    information. 
                  </span>
                  <b>
                    Stripe may impose its own additional charges for the use of
                    their services, including by deducting their charges from
                    the Notary Fees.
                  </b>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    YOU ACKNOWLEDGE AND AGREE THAT CLOSEWISE IS NOT LIABLE, YOU
                    AGREE NOT TO SEEK TO HOLD CLOSEWISE LIABLE, FOR THE CONDUCT
                    OF THIRD PARTIES, INCLUDING THOSE THAT YOU OR WE UTILIZE IN
                    CONNECTION WITH THE SERVICES (SUCH AS STRIPE), AND OPERATORS
                    OF EXTERNAL SITES, AND THAT THE RISK OF INJURY FROM SUCH
                    THIRD PARTIES RESTS ENTIRELY WITH YOU.
                  </span>
                </li>
                <li className="text-bold" aria-level="2">
                  <span className="text-bold">Refunds.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    CloseWise has no obligation to provide refunds or credits,
                    but may grant them in extenuating circumstances, for
                    instance to correct any errors made by CloseWise, in each
                    case at CloseWise’s sole discretion. We do our best to
                    rectify errors we made and that we become aware of. If you
                    believe CloseWise made an error in processing your payments,
                    please contact us at the contact information listed in
                    Section 20 (Questions or Concerns).
                  </span>
                </li>
              </ol>
              <ol>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">Disclaimer of Warranties.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    YOU ACKNOWLEDGE AND AGREE THAT THE SERVICES ARE PROVIDED ON
                    AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTIES OF
                    ANY KIND, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION,
                    REPRESENTATIONS, WARRANTIES AND CONDITIONS OF
                    MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
                    NON-INFRINGEMENT, AND THOSE ARISING BY STATUTE OR FROM A
                    COURSE OF DEALING OR USAGE OF TRADE. CLOSEWISE DOES NOT
                    WARRANT THAT THE SERVICES WILL OPERATE ERROR-FREE, OR THAT
                    THE SERVICES ARE FREE OF VIRUSES OR OTHER HARMFUL CODE.
                    CLOSEWISE DOES NOT WARRANT OR MAKE ANY REPRESENTATIONS
                    REGARDING ANY SERVICES PROVIDED BY A NOTARY, ANY FAILURE TO
                    PROVIDE SUCH SERVICES, OR THE CONDUCT OF ANY NOTARY WITH
                    WHOM YOU INTERACT, AND THE FOREGOING ARE AT YOUR OWN RISK.
                    NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED
                    FROM CLOSEWISE OR THROUGH THE SERVICES OR COLLECTIVE
                    CONTENT, WILL CREATE ANY WARRANTY NOT EXPRESSLY MADE HEREIN.
                  </span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    YOU ACKNOWLEDGE THAT CLOSEWISE IS NOT ABLE TO CONTROL OR
                    SUPERVISE YOUR INTERACTION OR TRANSACTIONS WITH OTHER USERS
                    OF THE SERVICE AND YOU AGREE THAT YOU ARE SOLELY RESPONSIBLE
                    FOR SUCH INTERACTION OR TRANSACTIONS. CLOSEWISE RESERVES THE
                    RIGHT, BUT HAS NO OBLIGATION, TO (I) MONITOR ANY SUCH
                    DISPUTE OR (II) UPON YOUR REQUEST, INTERVENE IN SUCH DISPUTE
                    FOR THE PURPOSE OF RESOLVING THE DISPUTE, PROVIDED THAT
                    CLOSEWISE WILL NOT BE RESPONSIBLE FOR ANY DAMAGES WHATSOEVER
                    ARISING FROM ANY MONITORING OR INTERVENTION ACTIVITIES THAT
                    WE PERFORM.
                  </span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    FROM TIME TO TIME, CLOSEWISE MAY OFFER NEW "BETA" FEATURES
                    OR TOOLS WITH WHICH YOU MAY EXPERIMENT. SUCH FEATURES OR
                    TOOLS ARE OFFERED SOLELY FOR EXPERIMENTAL PURPOSES AND
                    WITHOUT ANY WARRANTY OF ANY KIND, AND MAY BE MODIFIED OR
                    DISCONTINUED AT CLOSEWISE’ SOLE DISCRETION. THE PROVISIONS
                    OF THIS SECTION 10 APPLY WITH FULL FORCE TO SUCH FEATURES
                    AND TOOLS.
                  </span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    WITHOUT LIMITING THE GENERALITY OF THE FOREGOING, AND
                    NOTWITHSTANDING CLOSEWISE’ APPOINTMENT AS THE LIMITED
                    PAYMENT COLLECTION AGENT OF THE NOTARIES FOR THE PURPOSE OF
                    ACCEPTING PAYMENTS FROM COMPANIES ON BEHALF OF THE NOTARIES,
                    CLOSEWISE EXPLICITLY DISCLAIMS ALL LIABILITY FOR ANY ACT OR
                    OMISSION OF ANY USER OR OTHER THIRD PARTY.
                  </span>
                </li>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">Limitation of Liability.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    YOU ACKNOWLEDGE AND AGREE THAT, TO THE MAXIMUM EXTENT
                    PERMITTED BY LAW, THE ENTIRE RISK ARISING OUT OF THE USE OF
                    OUR SERVICES, INCLUDING OUR PROVISION OF PAYMENT SERVICES TO
                    YOU HEREUNDER, REMAINS WITH YOU. IF YOU PERMIT OR AUTHORIZE
                    ANOTHER PERSON TO USE YOUR CLOSEWISE ACCOUNT IN ANY WAY, YOU
                    ARE RESPONSIBLE FOR THE ACTIONS TAKEN BY THAT PERSON.
                    NEITHER CLOSEWISE NOR ANY OTHER PARTY INVOLVED IN PROVIDING
                    THE SERVICES (OR IN CARRYING OUT PAYMENT SERVICES HEREUNDER)
                    WILL BE LIABLE FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY OR
                    CONSEQUENTIAL DAMAGES, INCLUDING LOST PROFITS, LOSS OF DATA
                    OR LOSS OF GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE
                    OR SYSTEM FAILURE OR THE COST OF SUBSTITUTE PRODUCTS OR
                    SERVICES, OR FOR ANY DAMAGES FOR PERSONAL OR BODILY INJURY
                    OR EMOTIONAL DISTRESS ARISING OUT OF OR IN CONNECTION WITH
                    THESE TERMS, FROM THE USE OF OR INABILITY TO USE THE PAYMENT
                    SERVICES, FROM ANY COMMUNICATIONS, INTERACTIONS OR MEETINGS
                    WITH OTHER PERSONS WITH WHOM YOU COMMUNICATE OR INTERACT AS
                    A RESULT OF YOUR USE OF THE SERVICES (INCLUDING THE PAYMENT
                    SERVICES), WHETHER BASED ON WARRANTY, CONTRACT, TORT
                    (INCLUDING NEGLIGENCE), PRODUCT LIABILITY OR ANY OTHER LEGAL
                    THEORY, AND WHETHER OR NOT CLOSEWISE HAS BEEN INFORMED OF
                    THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY SET
                    FORTH HEREIN IS FOUND TO HAVE FAILED OF ITS ESSENTIAL
                    PURPOSE.
                  </span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    EXCEPT FOR OUR OBLIGATIONS TO PAY AMOUNTS TO APPLICABLE
                    NOTARIES PURSUANT TO THE PAYMENT PROVISIONS OF THESE TERMS,
                    IN NO EVENT WILL CLOSEWISE’ AGGREGATE LIABILITY ARISING OUT
                    OF OR IN CONNECTION WITH THESE TERMS AND YOUR USE OF THE
                    SERVICES AND OUR PAYMENT SERVICES HEREUNDER INCLUDING, BUT
                    NOT LIMITED TO, FROM THE USE OF OR INABILITY TO USE OUR
                    PAYMENT SERVICES, EXCEED THE AMOUNTS YOU HAVE PAID OR OWE
                    FOR SERVICES VIA THE CLOSEWISE PLATFORM AS A COMPANY (OR
                    BORROWER) IN THE SIX (6) MONTH PERIOD PRIOR TO THE EVENT
                    GIVING RISE TO THE LIABILITY, OR IF YOU ARE A NOTARY, THE
                    AMOUNTS PAID BY CLOSEWISE TO YOU IN THE SIX (6) MONTH PERIOD
                    PRIOR TO THE EVENT GIVING RISE TO THE LIABILITY, OR ONE
                    HUNDRED U.S. DOLLARS ($100), IF NO SUCH PAYMENTS HAVE BEEN
                    MADE, AS APPLICABLE. THE LIMITATIONS OF DAMAGES SET FORTH
                    ABOVE ARE FUNDAMENTAL ELEMENTS OF THE BASIS OF THE BARGAIN
                    BETWEEN CLOSEWISE AND YOU. SOME JURISDICTIONS DO NOT ALLOW
                    THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
                    OR INCIDENTAL DAMAGES, SO THE ABOVE LIMITATION MAY NOT APPLY
                    TO YOU.
                  </span>
                </li>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">Indemnification.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    You agree to defend, indemnify and hold harmless CloseWise,
                    and respective suppliers, licensors, officers, directors,
                    employees and agents from any losses, costs, liabilities and
                    expenses (including reasonable attorneys’ fees) relating to
                    or arising out of (i) your use of the Service; and (ii) your
                    breach of these TERMS and/or applicable law. CloseWise
                    reserves the right, at your expense, to assume the exclusive
                    defense and control of any matter for which you are required
                    to indemnify CloseWise, and you agree to cooperate with the
                    defense of these claims. You agree not to settle any matter
                    without the prior written consent of CloseWise. CloseWise
                    will use reasonable efforts to notify you of any such claim,
                    action or proceeding upon becoming aware of it. This
                    provision does not require you to indemnify CloseWise, and
                    respective suppliers, licensors, officers, directors,
                    employees or agents for any unconscionable commercial
                    practice by such party or for such party’s fraud, deception,
                    false promise, misrepresentation or concealment, suppression
                    or omission of any material fact in connection with the
                    Services.
                  </span>
                </li>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">Term and Termination.</span>
                  <ol>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">Term.</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        These TERMS shall become effective on the date that you
                        first register, access, or use the Services. These TERMS
                        shall continue in full effect until terminated, as set
                        forth herein.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">Termination.</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        You may terminate your relationship with us at any time
                        by unregistering your Account or discontinuing your use
                        of the Services. We may suspend or terminate your rights
                        to use the Services (including your Account) at any time
                        for any reason at our sole discretion, including for any
                        use of the Services in violation of these TERMS.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <span className="text-bold">Effect of Termination.</span>
                      <span className="text-bold"></span>
                      <span className="text-bold"></span>
                      <span className="text-bold">
                        Upon termination of these TERMS: (i) the license granted
                        to you hereunder will automatically expire; (ii) your
                        Account and right to access and use the Services will
                        terminate immediately you will cease any and all use of
                        the Services. You understand that any termination of
                        your Account may involve deletion of any data associated
                        with your Account from our live databases. You also
                        understand that CloseWise may deactivate your Account
                        upon termination, but may retain all data associated
                        with your Account and use it in accordance with these
                        TERMS. CloseWise will not have any liability whatsoever
                        to you for any termination of your rights under these
                        TERMS, including for termination of your Account or
                        deletion or preservation of such data. Even after your
                        rights under these TERMS are terminated, all provisions
                        of these TERMS which by their nature should survive,
                        will survive, including, without limitation, ownership
                        provisions, warranty disclaimers, and limitations of
                        liability.
                      </span>
                    </li>
                  </ol>
                </li>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">
                    Third Party Software and Licenses.
                  </span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    Portions of the Services may include software that we
                    license from third parties
                  </span>
                  <b>("Third Party Software")</b>
                  <span className="text-bold">
                    , which may be subject to various "open source" or
                    commercial licenses. Third Party Software is subject to the
                    terms and conditions imposed by the licensors of that Third
                    Party Software ("Third Party Terms"). You agree that your
                    use of Third Party Software is subject to and governed by
                    the related Third Party Terms.  To the extent of any
                    conflict between any Third Party Terms and the terms of
                    these TERMS, the Third Party Terms shall prevail in
                    connection with the related Third Party Software. 
                    Notwithstanding anything to the contrary herein, we do not
                    make any warranty with respect to Third Party Software.
                  </span>
                </li>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">
                    Arbitration Agreement; Class Waiver; Waiver of Trial by
                    Jury.
                  </span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <b>
                    Please read this Section 15 ("Arbitration Agreement")
                    carefully. It is part of your contract with CloseWise and
                    affects your rights. It contains procedures for 
                  </b>
                  <b>MANDATORY BINDING ARBITRATION AND A CLASS ACTION WAIVER</b>
                  <b>
                    . By agreeing to arbitration, you are waiving your right to
                    seek relief in a court of law.
                  </b>
                  <ol>
                    <li className="text-bold" aria-level="2">
                      <i>
                        <span className="text-bold">
                          Applicability of Arbitration Agreement.   
                        </span>
                      </i>
                      <b>
                        You acknowledge and agree that any dispute or claim
                        relating in any way to these TERMS, your use of the
                        Services, or to any products or services sold or
                        distributed by CloseWise, will be resolved by binding
                        arbitration, rather than in court, 
                      </b>
                      <span className="text-bold">
                        except that you may assert claims in small claims court
                        if your claims qualify. Notwithstanding the foregoing,
                        you agree that you will not bring any class action
                        claims in small claims court. The Federal Arbitration
                        Act and federal arbitration law apply to this Agreement.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <i>
                        <span className="text-bold">
                          Waiver of jury trial.   
                        </span>
                      </i>
                      <span className="text-bold">
                        There is no judge or jury in arbitration, and court
                        review of an arbitration award is limited. However, an
                        arbitrator can award on an individual basis the same
                        damages and relief as a court (including injunctive and
                        declaratory relief or statutory damages), and must
                        follow these TERMS as a court would.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <i>
                        <span className="text-bold">
                          Commencement of Arbitration Proceeding.   
                        </span>
                      </i>
                      <span className="text-bold">
                        To begin an arbitration proceeding, you must send a
                        letter requesting arbitration and describing your claim
                        to CloseWise at 
                      </span>
                      <a
                        target="_blank"
                        href="mailto:support@snapdocs.com"
                        rel="noreferrer"
                      >
                        <span className="text-bold">support@CloseWise.com</span>
                      </a>
                      <span className="text-bold">
                         The arbitration will be conducted by JAMS , an
                        established alternative dispute resolution provider.
                        Disputes involving claims and counterclaims under
                        $250,000, not inclusive of attorneys’ fees and interest,
                        shall be subject to JAMS’s most current version of the
                        Streamlined Arbitration Rules and procedures available
                        at 
                      </span>
                      <a
                        target="_blank"
                        href="http://www.jamsadr.com/rules-streamlined-arbitration/"
                        rel="noreferrer"
                      >
                        <span className="text-bold">
                          http://www.jamsadr.com/rules-streamlined-arbitration/
                        </span>
                      </a>
                      <span className="text-bold">
                        ; all other claims shall be subject to JAMS’s most
                        current version of the Comprehensive Arbitration Rules
                        and Procedures, available at 
                      </span>
                      <a
                        target="_blank"
                        href="http://www.jamsadr.com/rules-comprehensive-arbitration/"
                        rel="noreferrer"
                      >
                        <span className="text-bold">
                          http://www.jamsadr.com/rules-comprehensive-arbitration/
                        </span>
                      </a>
                      <span className="text-bold">
                        . JAMS’s rules are also available at 
                      </span>
                      <a
                        target="_blank"
                        href="https://www.jamsadr.com/"
                        rel="noreferrer"
                      >
                        <span className="text-bold">www.jamsadr.com</span>
                      </a>
                      <span className="text-bold">
                         or by calling JAMS at 800-352-5267. If JAMS is not
                        available to arbitrate, the parties will select an
                        alternative arbitral forum. If the arbitrator finds that
                        you cannot afford to pay JAMS’s filing, administrative,
                        hearing and/or other fees and cannot obtain a waiver
                        from JAMS, CloseWise will pay them for you. In addition,
                        CloseWise will reimburse all such JAMS’s filing,
                        administrative, hearing and/or other fees for claims
                        totaling less than $10,000 unless the arbitrator
                        determines the claims are frivolous. Likewise, CloseWise
                        will not seek attorneys’ fees and costs in arbitration
                        unless the arbitrator determines the claims are
                        frivolous. You may choose to have the arbitration
                        conducted by telephone, based on written submissions, or
                        in person in the country where you live or at another
                        mutually agreed location. Any judgment on the award
                        rendered by the arbitrator may be entered in any court
                        of competent jurisdiction.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <i>
                        <span className="text-bold">No Class Actions.   </span>
                      </i>
                      <b>
                        We each agree that any dispute resolution proceedings
                        will be conducted only on an individual basis and not in
                        a class, consolidated or representative action. 
                      </b>
                      <span className="text-bold">
                        If for any reason a claim proceeds in court rather than
                        in arbitration, 
                      </span>
                      <b>we each waive any right to a jury trial</b>
                      <span className="text-bold">
                        . We also both agree that you or we may bring suit in
                        court to enjoin infringement or other misuse of
                        intellectual property rights.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <i>
                        <span className="text-bold">
                          Thirty-Day Right to Opt Out.   
                        </span>
                      </i>
                      <span className="text-bold">
                        You have the right to opt out of the provisions of this
                        Arbitration Agreement by sending written notice of your
                        decision to opt out to the address listed in Section 20
                        (Questions or Concerns) within thirty (30) days after
                        first becoming subject to this Arbitration Agreement.
                        Your notice must include your name and address, and the
                        email address you used to set up your Account, and an
                        unequivocal statement that you want to opt out of this
                        Arbitration Agreement. If you opt out of this
                        Arbitration Agreement, all other parts of this TOS
                        Agreement will continue to apply to you. Opting out of
                        this Arbitration Agreement has no effect on any other
                        arbitration agreements that you may currently have, or
                        may enter in the future, with us.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <i>
                        <span className="text-bold">Survival.   </span>
                      </i>
                      <span className="text-bold">
                        This Arbitration Agreement will survive the termination
                        of your relationship with CloseWise.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <i>
                        <span className="text-bold">
                          Emergency Equitable Relief.   
                        </span>
                      </i>
                      <span className="text-bold">
                        Notwithstanding the foregoing, either party may seek
                        emergency equitable relief before a state or federal
                        court in order to maintain the status quo pending
                        arbitration. A request for interim measures shall not be
                        deemed a waiver of any other rights or obligations under
                        this Arbitration Agreement.
                      </span>
                    </li>
                    <li className="text-bold" aria-level="2">
                      <i>
                        <span className="text-bold">Courts.   </span>
                      </i>
                      <span className="text-bold">
                        In any circumstances where the parties are permitted to
                        litigate in court, the parties hereby agree to submit to
                        the personal jurisdiction and exclusive venue of the
                        state courts in San Francisco County, California and
                        federal courts located in the Northern District of
                        California for such purpose.
                      </span>
                    </li>
                  </ol>
                </li>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">Export Controls.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    The Services may be subject to U.S. export control laws and
                    may be subject to export or import regulations in other
                    countries and you will comply therewith. You agree not to
                    export, re-export, or transfer, directly or indirectly, any
                    U.S. technical data acquired from CloseWise, or any products
                    utilizing such data, in violation of the United States
                    export laws or regulations. You will defend, indemnify, and
                    hold harmless CloseWise from and against any violation of
                    such laws by you or any of your agents, officers, directors,
                    or employees.
                  </span>
                </li>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">Disclosures.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    CloseWise is located at the address set forth below. If you
                    are a California resident, you may report complaints to the
                    Complaint Assistance Unit of the Division of Consumer
                    Product of the California Department of Consumer Affairs by
                    contacting them in writing at 400 R Street, Sacramento, CA
                    95814, or by telephone at (800) 952-5210.
                  </span>
                </li>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">Electronic Communications.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    The communications between you and CloseWise use electronic
                    means, whether you use the Services or send us emails, or
                    whether CloseWise posts notices on the Services or
                    communicates with you via email. For contractual purposes,
                    you (a) consent to receive communications, including
                    notifications, from CloseWise in an electronic form; and (b)
                    agree that all terms and conditions, agreements, notices,
                    disclosures, and other communications that CloseWise
                    provides to you electronically satisfy any legal requirement
                    that such communications would satisfy if it were in a
                    hardcopy writing. The foregoing does not affect your
                    non-waivable rights.
                  </span>
                </li>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">Miscellaneous.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    These TERMS constitute the entire understanding between the
                    parties with respect to the matters referred to herein. If
                    any provision of these TERMS is held to be unenforceable,
                    such provision shall be reformed only to the extent
                    necessary to make it enforceable and such decision shall not
                    affect the enforceability of such provision under other
                    circumstances, or of the remaining provisions hereof under
                    all circumstances. As used herein, "including" means
                    "including without limitation". Failure of CloseWise to
                    enforce any rights or to take action against you in the
                    event of any breach hereunder shall not be deemed a waiver
                    of such rights or of subsequent actions in the event of
                    future breaches. These TERMS and any right granted herein
                    may not be assigned by you without the prior written consent
                    of CloseWise. The terms and conditions set forth in these
                    TERMS shall inure to the benefit of and be binding upon
                    permitted assignees.
                  </span>
                </li>
                <li className="text-bold" aria-level="1">
                  <span className="text-bold">Questions or Concerns.</span>
                  <span className="text-bold"></span>
                  <span className="text-bold"></span>
                  <span className="text-bold">
                    If you have any questions or concerns regarding these TERMS,
                    please contact us at: 
                  </span>
                  <a
                    target="_blank"
                    href="mailto:support@snapdocs.com"
                    rel="noreferrer"
                  >
                    <span className="text-bold">support@CloseWise.com</span>
                  </a>
                  <span className="text-bold"> </span>
                  <span className="text-bold"> </span>
                </li>
              </ol>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

export default connect(mapStateToProps, null)(Terms);
