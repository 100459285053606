import React, { useState, useEffect } from "react";
import {
  CardBody,
  Card,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Col,
  Row,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

import { errorHandler, showToast } from "../../../../../helper-methods";

import {
  updateCustomProductTypes,
  companyGetAllProductTypes,
} from "../../../../../http/http-calls";
import cuid from "cuid";
import { loanTypeConfig } from "../../../../../config";

const CustomProductType = ({
  customProductTypes,
  companyGetAllProductTypes,
}) => {
  const [activeTab, setActiveTab] = useState(0);
  const [key, setKey] = useState("RealEstateDocuments");
  const [productType, setProductType] = useState([]);
  const [loading, setLoading] = useState(false);

  const _toggleTab = (tab, index) => {
    if (activeTab !== tab) {
      setActiveTab(index);
      setKey(tab);
    }
  };

  const _addProductType = () => {
    const newObject = {
      id: cuid(),
      productCategory: key,
      productType: "",
      isCustomAdded: true,
    };

    setProductType((prevArray) => [...prevArray, newObject]);
  };

  const _removeProductType = (id) => {
    const updatedArray = productType.filter(
      (item) => (item.id || item._id) !== id
    );

    setProductType(updatedArray);
  };

  const _handleInputChange = (field, value, id) => {
    const index = productType.findIndex((item) => (item.id || item._id) === id);

    if (index !== -1) {
      const updatedProductType = [...productType];
      updatedProductType[index][field] = value;
      setProductType(updatedProductType);
    } else {
      console.error(`Object with id ${id} not found`);
    }
  };

  const _onSaveProductType = async () => {
    let newProductType = productType
      .filter((item) => item.productType !== "")
      .map((item) => ({
        _id: item._id,
        productCategory: item.productCategory,
        productType: item.productType,
        isCustomAdded: true,
      }));
    if (newProductType.length > 0) {
      let payload = {
        products: newProductType,
      };
      try {
        setLoading(true);
        await updateCustomProductTypes(payload);
        _companyGetAllProductTypes();
        showToast("Product Types are Set!", "success");
        setLoading(false);
      } catch (error) {
        errorHandler(error);
        setLoading(false);
      }
    } else {
      showToast("No Product Type Added Or its invalid Input!", "error");
    }
  };

  const _companyGetAllProductTypes = async () => {
    try {
      const res = await companyGetAllProductTypes();
      console.log({ res });
      if (res?.customProductTypes) {
        _setData(res?.customProductTypes);
      }
      // showToast("Settings are", "success");
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _setData = (data) => {
    // console.log("data",data)
    let products =
      (data?.products?.length > 0 &&
        data?.products?.filter((each) => each.isCustomAdded)) ||
      [];
    setProductType(products);
  };

  useEffect(() => {
    //   // companyGetAllProductTypes();
      console.log("ss",customProductTypes)
    if (customProductTypes?.length > 0) {
      //     _setData(customProductTypes);
      setProductType(customProductTypes);
    }
  }, [customProductTypes]);

  return (
    <Card className="customProductType">
      <CardBody>
        <h2 style={{ fontSize: 18, fontWeight: "600", marginBottom: 12 }}>
          Manage Your Product Type
        </h2>

        <Nav tabs>
          {Object.entries(loanTypeConfig).map(([category, types], index) => {
            return (
              <>
                <NavItem>
                  <NavLink
                    className={activeTab === index ? "active" : ""}
                    onClick={() => {
                      _toggleTab(category, index);
                    }}
                  >
                    {category}
                  </NavLink>
                </NavItem>
              </>
            );
          })}
        </Nav>
        <TabContent activeTab={activeTab}>
          {/* basic detail page */}
          <TabPane tabId={activeTab} className="pt-4">
            <div className="text-right mb-4">
              <Button
                color="primary"
                className="h-auto readMore"
                onClick={() => _addProductType()}
              >
                Add New
              </Button>
            </div>
            <Row>
              {loanTypeConfig[key].map((loan, typeIndex) => (
                <Col md={4}>
                  <FormGroup className="floatingLabel">
                    <Input placeholder=" " disabled />
                    <Label>{loan}</Label>
                  </FormGroup>
                </Col>
              ))}

              {productType
                .filter((item) => item.productCategory === key)
                .map((product, typeIndex) => (
                  <Col md={4}>
                    <div className="d-flex align-items-start">
                      <FormGroup className="floatingLabel w-100">
                        <Input
                          placeholder=" "
                          value={product.productType}
                          onChange={(e) =>
                            _handleInputChange(
                              "productType",
                              e.target.value,
                              product.id ? product.id : product._id
                            )
                          }
                        />
                        {/* <Label>{product.productType}</Label> */}
                      </FormGroup>
                      <Button
                        color="link"
                        className="pl-3 mt-3"
                        onClick={() =>
                          _removeProductType(
                            product.id ? product.id : product._id
                          )
                        }
                      >
                        <img
                          src={
                            require("../../../../../assets/img/deleteIcon.svg")
                              .default
                          }
                          height={20}
                          alt="delete"
                        />
                      </Button>
                    </div>
                  </Col>
                ))}
            </Row>
            <div className="text-center">
            <Button
              color="primary"
              className="pl-3 mt-3"
              onClick={() => _onSaveProductType()}
              disabled={loading ? true : false}
            >
              Save
            </Button>
            </div>
          </TabPane>
        </TabContent>
      </CardBody>
    </Card>
  );
};

export default CustomProductType;
