import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import {
  capitalize,
  errorHandler,
  formatDateAsPerTimeZOne,
  formatDateOnlyAsPerTimeZone,
  getTimeZoneAbbr,
  showToast,
} from "../../../helper-methods";
import {
  getClosingDetailsById,
  updateClosingDetailsById,
} from "../../../http/http-calls";

const ClientAssignOrder = ({
  isOpen,
  signingCompanies,
  data,
  toggle,
  resetDetails,
}) => {
  const [closingDetails, setClosingDetails] = useState(null);
  const [signingCompanyId, setSigningCompanyId] = useState("");
  const [offeredFee, setOfferedFee] = useState("");
  const [loading, setLoading] = useState(false);

  const _setModalState = (data = null) => {
    setClosingDetails(data || null);
    setSigningCompanyId(data?._signingCompany?._id || "");
    setOfferedFee(
      data?.signingCompanyFee ? data.signingCompanyFee.toString() : ""
    );
    setLoading(false);
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  const _onChangeOfferedFee = (value) => {
    if (
      isNaN(value) ||
      (value.includes(".") && value.split(".")[1].length > 2)
    ) {
      return;
    }

    setOfferedFee(value);
  };

  const _getClosingDetailsById = (id) => {
    setLoading(true);

    getClosingDetailsById(id)
      .then((res) => {
        _setModalState(res?.closing);
        setLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        setLoading(false);

        _closeModal();
      });
  };

  useEffect(() => {
    if (isOpen && data?._id) {
      _getClosingDetailsById(data._id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, data]);

  const _onSave = async () => {
    try {
      const payload = {};

      payload.signingCompanyId = signingCompanyId || undefined;

      if (
        isNaN(offeredFee) ||
        (offeredFee.includes(".") && offeredFee.split(".")[1].length > 2) ||
        Number(offeredFee) < 1
      ) {
        showToast("Offered fee should be greater than $1", "error");
        return;
      }

      payload.signingCompanyFee = offeredFee ? Number(offeredFee) : undefined;

      setLoading(true);

      if (Object.keys(payload)?.length) {
        await updateClosingDetailsById({
          id: data?._id,
          payload,
        });
      }

      resetDetails();
      _closeModal();
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} centered scrollable>
      <ModalHeader toggle={() => _closeModal()}>Order Assignment</ModalHeader>
      <ModalBody>
        <Row>
          {!closingDetails && loading ? (
            <Col className="text-center">
              <Spinner />
            </Col>
          ) : (
            <>
              <Col sm="12">
                <Label className="d-block mb-3">
                  <span style={{ fontWeight: 600, fontSize: 12 }}>
                    {" "}
                    Appointment Time:{" "}
                  </span>
                  {closingDetails?.appointmentDate ? (
                    <>
                      <span style={{ fontWeight: 600, fontSize: 12 }}>
                        {!closingDetails.tBD
                          ? formatDateAsPerTimeZOne(
                              closingDetails?.appointmentDate,
                              closingDetails?.closingAddress?.timeZone
                            )
                          : formatDateOnlyAsPerTimeZone(
                              closingDetails?.appointmentDate,
                              closingDetails?.closingAddress?.timeZone
                            )}{" "}
                      </span>
                      <span style={{ fontWeight: 400, fontSize: 12 }}>
                        {getTimeZoneAbbr(
                          closingDetails?.appointmentDate,
                          closingDetails?.closingAddress?.timeZone
                        )}
                      </span>
                    </>
                  ) : (
                    "N/A"
                  )}
                </Label>
              </Col>

              <Col sm="12">
                <FormGroup className="floatingLabel custom-select-wrapper">
                  <Input
                    type="select"
                    name="signingCompanyId"
                    value={signingCompanyId}
                    onChange={(e) => setSigningCompanyId(e.target.value)}
                  >
                    <option hidden value="">
                      Select Company
                    </option>
                    {signingCompanies.map((each) => (
                      <option key={each._id} value={each._id}>
                        {capitalize(each.companyName)}
                      </option>
                    ))}
                  </Input>
                  <Label>Assign Company</Label>
                </FormGroup>
              </Col>

              <Col sm="12">
                <FormGroup className="floatingLabel withInputGroup">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <img
                          src={
                            require("../../../assets/img/dollar.svg").default
                          }
                          alt="doller"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="offeredFee"
                      value={offeredFee}
                      onChange={(e) => _onChangeOfferedFee(e.target.value)}
                    />
                    <Label>Offered Fee</Label>
                  </InputGroup>
                </FormGroup>
              </Col>
            </>
          )}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="lg" outline onClick={() => _closeModal()}>
          Close
        </Button>
        <Button
          color="primary"
          size="lg"
          onClick={() => _onSave()}
          disabled={loading}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null} Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ClientAssignOrder;
