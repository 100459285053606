import React from "react";
import { Row, Col } from "reactstrap";
import Miscellaneous from "../../components/HelpModule/Miscellaneous";
import Categories from "../../components/HelpModule/Categories";

const Help = () => {
  return (
    <>
      <div className="content">
        <div className="responsiveTitle">
          <h2>Help</h2>
        </div>

        <Categories />

        <Miscellaneous />
        
      </div>
    </>
  );
};

export default Help;
