import React from "react";
import {
  Button,
  Badge,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// import { Link } from "react-router-dom";
import InviteClient from "../components/InviteClient";
import {
  deleteCompanyClientById,
  deleteRequestConnectWithClient,
  getAllClients,
  resendClientInvitation,
  updateClient,
  uploadClientCSV,
} from "../../../http/http-calls";
import {
  deepClone,
  formatCurrencyValue,
  formatPhoneNumber,
  showToast,
  checkPermission,
  errorHandler,
  formatAddressInSingleText,
  openGoogleMapOnNewTab,
  getDropdownColor,
} from "../../../helper-methods";
import { FormGroup, capitalize } from "@material-ui/core";
import { DEFAULT_COVER_PICTURE } from "../../../config";
import { connect } from "react-redux";
import BulkUploadInfo from "../components/BulkUploadInfo";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomTable from "../components/CustomTable";
import CustomCard from "../components/CustomCard";
import InviteClientNewModal from "../components/InviteClientNewModal";
import Swal from "sweetalert2";
import { ClientMembers } from "../components/Modals/Company/ClientMembers";
import { ClientTeams } from "../components/Modals/Company/ClientTeams";

class Clients extends React.Component {
  state = {
    clientsList: [],
    clientsTotalCount: 0,
    clientsHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      { id: "name", label: "Client Name", noSort: true },
      // { id: "email", label: "Email", noSort: true },
      // { id: "phone", label: "Phone", noSort: true },
      { id: "clientmembers", label: "Clients Member", noSort: true },
      { id: "clientteams", label: "Client Teams", noSort: true },
      { id: "address", label: "Location", noSort: true },
      // { id: "rates", label: "Standard rates", noSort: true },
      // { id: "connectStatus", label: "Connect Status", noSort: true },
      // { id: "status", label: "Status", noSort: true },
      { id: "action", label: "Action", noSort: true },
    ],
    clientsCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    addClientModalData: {
      isOpen: false,
      data: null,
      type: null,
    },
    bulkUploadResponse: {
      isOpen: false,
      data: null,
      success: null,
    },
    clientMemberModal: {
      isOpen: false,
      data: null,
      success: null,
    },
    clientTeamsModal: {
      isOpen: false,
      data: null,
      success: null,
    },
    loading: {
      statusChangeLoading: false,
      showTableLoading: false,
      bulkFile: false,
      clientDeleteLoading: false,
    },
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
      sort: {
        sortBy: "createdAt",
        sortOrder: "desc",
      },
    },
    filters: {
      search: "",
    },
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _toggleAddClientModal = (isOpen = false, type = null, data = null) => {
    this.setState({
      addClientModalData: {
        isOpen,
        data,
        type,
      },
    });
  };

  _onToggleBulkInfoModal = () => {
    let { bulkUploadResponse } = deepClone(this.state);
    bulkUploadResponse.isOpen = !bulkUploadResponse.isOpen;
    bulkUploadResponse.data = null;
    bulkUploadResponse.success = null;
    this.setState({
      bulkUploadResponse,
      selectedFile: {},
    });
  };

  _onToggleClientMembers = (isOpen = false, data = null) => {
    this.setState({
      clientMemberModal: {
        isOpen,
        data,
      },
    });
  };

  _onToggleClientTeams = (isOpen = false, data = null) => {
    this.setState({
      clientTeamsModal: {
        isOpen,
        data,
      },
    });
  };

  _getAllClients = async () => {
    try {
      this._manageLoading("showTableLoading", true);

      const { tableConfig, filters } = this.state;

      let data = {
        skip: tableConfig.skip,
        limit: tableConfig.limit,
        // search: filters.search.trim().length > 0 ? filters.search : undefined,
      };
      if (filters?.search && filters?.search?.trim()?.length)
        Object.assign(data, { search: filters?.search?.trim() });

      const res = await getAllClients(data);

      this.setState({
        clientsList: res?.clients || [],
        clientsTotalCount: res?.totalCount || 0,
      });
      this._manageLoading("showTableLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("showTableLoading", false);
    }
  };

  componentDidMount = () => {
    this._setFilterDataFromLocalStorage();
    // this._getAllClients();
    HeaderEventEmitter.subscribe("add-client", () => {
      this._toggleAddClientModal(true, "add");
    });

    document.querySelector("#scroll").scrollTo(0, 0);
  };

  _clientStatusUpdate = (data, value) => {
    this._manageLoading("statusChangeLoading", true);

    const payload = {
      isActive: value === "active" ? true : false,
    };

    updateClient(data.id, payload)
      .then((res) => {
        showToast("Status Updated Successfully", "success");
        const { clientsList } = deepClone(this.state);
        const client = clientsList.find((client) => client.id === data.id);
        client["isActive"] = res?.client?.isActive || false;
        this.setState({ clientsList });
        this._manageLoading("statusChangeLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("statusChangeLoading", false);
      });
  };

  _resendClientInvitation = async (client) => {
    try {
      const payload = { clientId: client?.id };

      await resendClientInvitation(payload);
      showToast("Invitation sent successfully", "success");
    } catch (err) {
      errorHandler(err);
    }
  };

  _handleOnDeleteClientAlert = (clientId, index) => {
    if (!clientId) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this client.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        this._handleOnDeleteClient(clientId, index);
      }
    });
  };

  _handleOnDeleteClient = async (clientId, index) => {
    try {
      this._manageLoading("clientDeleteLoading", true);

      await deleteCompanyClientById(clientId);

      await this._getAllClients();
      this._manageLoading("clientDeleteLoading", false);
    } catch (err) {
      errorHandler(err);
      this._manageLoading("clientDeleteLoading", false);
    }
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return row?.companyName ? (
          <>
            <div
              className={`tableUserInfo ${
                !row?._signingCompany ? "cursorPointer" : ""
              }`}
              onClick={() =>
                this.props.history.push(
                  `/signingcompany/client-details/${row._id}`
                )
              }
            >
              <div className="userImage">
                <img
                  className="clientImg"
                  src={row?.logo || DEFAULT_COVER_PICTURE}
                  loading="lazy"
                  alt="avatar"
                />
              </div>
              <div className="userContent">
                {row?.companyName && (
                  <span className="signerName">
                    {capitalize(row?.companyName)}
                  </span>
                )}
              </div>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "email": {
        return (
          <>
            <div style={{ width: 150 }}>
              {cell ? <a href={`mailto:${cell}`}>{cell}</a> : "N/A"}
            </div>
          </>
        );
      }
      case "phone": {
        return (
          <>
            <div style={{ width: 100 }}>
              {cell ? formatPhoneNumber(cell) : "N/A"}
            </div>
          </>
        );
      }

      case "clientmembers": {
        return (
          <>
            <div style={{ width: 100 }}>
              <Button
                color="link"
                onClick={() =>
                  row?.totalClientMemberCount
                    ? this._onToggleClientMembers(true, row)
                    : null
                }
              >
                {row?.totalClientMemberCount ? row?.totalClientMemberCount : 0}
              </Button>
            </div>
          </>
        );
      }
      case "clientteams": {
        return (
          <>
            <div style={{ width: 100 }}>
              <Button
                color="link"
                onClick={() =>
                  row?.totalClientTeamCount
                    ? this._onToggleClientTeams(true, row)
                    : null
                }
              >
                {row?.totalClientTeamCount ? row?.totalClientTeamCount : 0}
              </Button>
            </div>
          </>
        );
      }

      case "action": {
        return (
          <div className="d-flex flex-wrap">
            {/* {!row?.isSignedUp && ( */}
            <Button
              color="link"
              className="actionBtn"
              onClick={() => this._toggleAddClientModal(true, "edit", row)}
              disabled={this.state.loading?.clientDeleteLoading}
            >
              <img
                src={require("../../../assets/img/pencil.svg").default}
                alt="edit"
              />
            </Button>
            {/* )} */}

            {/* {!row?.isSignedUp ? (
              <Button
                color="link"
                className="remove"
                onClick={() => this._resendClientInvitation(row)}
                disabled={this.state.loading?.clientDeleteLoading}
              >
                Resend Invitation
              </Button>
            ) : null} */}

            {/* {row?.connectStatus === "accepted" &&
              (row?.isSignedUp && row?.isEditable ? (
                <>
                  <Button
                    color="link"
                    className="actionBtn"
                    onClick={() =>
                      this._toggleAddClientModal(true, "edit", row)
                    }
                    disabled={this.state.loading?.clientDeleteLoading}
                  >
                    <img
                      src={require("../../../assets/img/pencil.svg").default}
                      alt="edit"
                    />
                  </Button>
                  <Button
                    color="link"
                    className="remove"
                    style={{ whiteSpace: "nowrap" }}
                    onClick={() =>
                      this._companyDeleteRequestConnectWithClient(row.id)
                    }
                    disabled={
                      this.state.loading.statusChangeLoading
                        ? true
                        : false || this.state.loading?.clientDeleteLoading
                    }
                  >
                    Remove Connection
                  </Button>
                </>
              ) : (
                <Button
                  color="link"
                  className="remove"
                  onClick={() =>
                    this._companyDeleteRequestConnectWithClient(row.id)
                  }
                  disabled={
                    this.state.loading.statusChangeLoading
                      ? true
                      : false || this.state.loading?.clientDeleteLoading
                  }
                >
                  Remove Connection
                </Button>
              ))} */}

            <Button
              color="link"
              onClick={() => this._handleOnDeleteClientAlert(row?._id)}
              disabled={
                row?.connectStatus === "accepted" ||
                this.state.loading?.clientDeleteLoading
              }
            >
              <img
                src={require("../../../assets/img/deleteIcon.svg").default}
                alt="delete"
              />
            </Button>
          </div>
        );
      }
      case "location": {
        return cell || row.address?.zip ? (
          <>
            <span>{row.address?.zip || "N/A"}</span>
            {/* <Button
              color="link"
              className="p-0"
              title={row.address?.zip}
            >
              <img
                src={require("../../../assets/img/map-marker.svg").default}
                alt="map icon"
                className="mr-1"
              />
              Map
            </Button> */}
          </>
        ) : (
          "N/A"
        );
      }
      case "address": {
        return cell ? (
          <>
            <div style={{ width: 150 }}>
              {formatAddressInSingleText(cell) || "N/A"}
            </div>
            {cell?.zip ? (
              <Button
                color="link"
                className="p-0"
                title={formatAddressInSingleText(cell)}
                onClick={() => openGoogleMapOnNewTab(cell)}
              >
                <img
                  src={require("../../../assets/img/map-marker.svg").default}
                  alt="map icon"
                  className="mr-1"
                />
                Map
              </Button>
            ) : null}
          </>
        ) : (
          "N/A"
        );
      }
      case "rates": {
        return (
          <>
            <div style={{ width: 50 }}>
              {cell ? formatCurrencyValue(cell) : "N/A"}
            </div>
          </>
        );
      }
      case "connectStatus": {
        return cell ? (
          cell === "requested" ? (
            <Badge color="success" className="outline" pill>
              Request{" "}
              {`
              ${
                row.connectResquestBy === this.props.userData?.user?.type
                  ? "Sent"
                  : "Received"
              }
                `}
            </Badge>
          ) : cell === "accepted" ? (
            <Badge color="success" className="outline" pill>
              Connected
            </Badge>
          ) : (
            <Badge color="warning" className="outline" pill>
              {capitalize(cell)}
            </Badge>
          )
        ) : (
          <Badge color="warning" className="outline" pill>
            Pending
          </Badge>
        );
      }
      case "status": {
        return (
          <>
            {/* // <>
          //   {row?.isActive ? (
          //     <Badge color="success" className="outline" pill>
          //       Active
          //     </Badge>
          //   ) : (
          //     <Badge color="warning" className="outline" pill>
          //       Inactive
          //     </Badge>
          //   )}
          // </> */}

            <div className="customSelectWrap" style={{ width: 95 }}>
              <Input
                type="select"
                name="status"
                style={{ textAlign: "center" }}
                className={`status ${getDropdownColor(
                  row.isActive ? "active" : "inactive"
                )}`}
                disabled={true}
                value={row.isActive ? "active" : "inactive"}
                onChange={(event) => this._agentStatusUpdate(row)}
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Input>
              <div className="downArrow">
                <i className="fa fa-chevron-down"></i>
              </div>
            </div>
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  _companyDeleteRequestConnectWithClient = async (id) => {
    try {
      this._manageLoading("statusChangeLoading", "Delete");

      await deleteRequestConnectWithClient(id);
      await this._getAllClients();
      showToast("Connection has been Deleted Successfully", "success");

      this._manageLoading("statusChangeLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("statusChangeLoading", false);
    }
  };

  _paginate = (pageNumber, pageSize) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllClients();
    });
  };

  _onSortChange = (sortName, sortOrder) => {
    this._paginate(1, 10);
  };

  _filterOnChange = (type, value, isDelay = false) => {
    try {
      if (isDelay) clearTimeout(this.searchTimer);

      const { filters } = this.state;
      filters[type] = value;

      this.setState({ filters }, () => {
        if (isDelay) {
          this.searchTimer = setTimeout(() => {
            this._paginate(1, 10);
          }, 1000);
        } else {
          this._paginate(1, 10);
        }
      });
    } catch (err) {
      errorHandler(err);
    }
  };

  _onFileSelect = (fieldName, e) => {
    if (e.target.files?.[0]) {
      this.setState(
        {
          isFormDirty: true,
          [fieldName]: {
            previewBlob: URL.createObjectURL(e.target.files[0]),
            uploadData: e.target.files[0],
          },
        },
        () => this._handleSubmit()
      );
    }
  };

  _handleSubmit = async () => {
    this._manageLoading("bulkFile", true);

    try {
      const formData = new FormData();
      formData.append("file", this.state.selectedFile?.uploadData);
      formData.append("companyName", "companyName");
      formData.append("phone", "phone");
      formData.append("generalEmail", "generalEmail");
      formData.append("schedulingEmail", "schedulingEmail");
      formData.append("accountingEmail", "accountingEmail");
      formData.append("personOfContact", "personOfContact");

      let response = await uploadClientCSV(formData);
      this._manageLoading("bulkFile", false);
      if (typeof response.error === "string") {
        errorHandler(response.error);
      } else {
        !response.error && showToast("Uploaded Successfully", "success");
        this.setState({
          bulkUploadResponse: {
            isOpen: true,
            data: response?.reason,
            success: response?.dataUploadedCount,
          },
        });
      }
    } catch (error) {
      this._manageLoading("bulkFile", false);
      this.setState({
        bulkUploadResponse: {
          isOpen: true,
          data: error.reason,
          success: error.dataUploadedCount,
        },
      });
    }
  };

  _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return (
          <>
            {row?.companyName ? (
              <>
                <div
                  className={`tableUserInfo ${
                    !row?._signingCompany ? "cursorPointer" : ""
                  }`}
                  onClick={() =>
                    this.props.history.push(
                      `/signingcompany/client-details/${row._id}`
                    )
                  }
                >
                  <div className="userImage">
                    <img
                      className="clientImg"
                      src={row?.logo || DEFAULT_COVER_PICTURE}
                      loading="lazy"
                      alt="avatar"
                    />
                  </div>
                  <div className="userContent">
                    {row?.companyName && (
                      <span className="signerName">
                        {capitalize(row?.companyName)}
                      </span>
                    )}
                  </div>
                </div>
              </>
            ) : (
              "N/A"
            )}
            {/* <>
              {!row?.isSignedUp && (
                <Button
                  color="link"
                  className="actionBtn"
                  onClick={() => this._toggleAddClientModal(true, row)}
                >
                  <img
                    src={require("../../../assets/img/pencil.svg").default}
                    alt="edit"
                  />
                </Button>
              )}

              {row?.connectStatus === "accepted" &&
                (row?.isSignedUp && row?.isEditable ? (
                  <>
                    <div style={{ width: 165 }}>
                      <Button
                        color="link"
                        className="actionBtn"
                        onClick={() => this._toggleAddClientModal(true, row)}
                      >
                        <img
                          src={
                            require("../../../assets/img/pencil.svg").default
                          }
                          alt="edit"
                        />
                      </Button>
                      <Button
                        color="link"
                        className="remove"
                        style={{ whiteSpace: "nowrap" }}
                        onClick={() =>
                          this._companyDeleteRequestConnectWithClient(row.id)
                        }
                        disabled={
                          this.state.loading.statusChangeLoading ? true : false
                        }
                      >
                        Remove Connection
                      </Button>
                    </div>
                  </>
                ) : (
                  <Button
                    color="link"
                    className="fs-12 remove"
                    onClick={() =>
                      this._companyDeleteRequestConnectWithClient(row.id)
                    }
                    disabled={
                      this.state.loading.statusChangeLoading ? true : false
                    }
                  >
                    Remove Connection
                  </Button>
                ))}
            </> */}
            {/* <>
              {!row?.isSignedUp && (
                <Button
                  color="link"

                  className="actionBtn"
                  onClick={() => this._toggleAddClientModal(true, row)}
                >
                  <img
                    src={require("../../../assets/img/pencil.svg").default}
                    alt="edit"
                  />
                </Button>
              )}
              {row?.connectStatus === "accepted" && (
                <Button
                  color="link"
                  className="fs-12 remove"
                  onClick={() =>
                    this._companyDeleteRequestConnectWithClient(row.id)
                  }
                  disabled={
                    this.state.loading.statusChangeLoading ? true : false
                  }
                >
                  Remove Connection
                </Button>
              )}
            </> */}
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.companyClientsPage) {
      try {
        const filters = JSON.parse(localStorage.companyClientsPage);
        this.setState(
          { tableConfig: filters.tableConfig, filters: filters.filters },
          () => {
            this._getAllClients();
          }
        );
      } catch (e) {
        console.log("error", e);
        this._getAllClients();
      }
    } else {
      this._getAllClients();
    }
  };

  _persistFilter = () => {
    try {
      const { tableConfig, filters } = this.state;

      if (tableConfig.pageNumber !== 1) {
        let data = { tableConfig, filters };
        localStorage.companyClientsPage = JSON.stringify(data);
      } else {
        delete localStorage.companyClientsPage;
      }
    } catch (err) {
      errorHandler(err);
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Client Members</Label>
            <div>
              <Button
                color="link"
                onClick={() =>
                  row?.totalClientMemberCount
                    ? this._onToggleClientMembers(true, row)
                    : null
                }
              >
                {row?.totalClientMemberCount ? row?.totalClientMemberCount : 0}
              </Button>
            </div>
          </div>

          <div className="dataWrap">
            <Label>Client Teams</Label>
            <div>
              <Button
                color="link"
                onClick={() =>
                  row?.totalClientTeamCount
                    ? this._onToggleClientTeams(true, row)
                    : null
                }
              >
                {row?.totalClientTeamCount ? row?.totalClientTeamCount : 0}
              </Button>
            </div>
          </div>
          {/* <div className="dataWrap">
            <Label>Email </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.email ? (
                <a href={`mailto:${row?.email}`}>{row?.email}</a>
              ) : (
                "N/A"
              )}
            </div>
          </div> */}

          {/* <div className="dataWrap">
            <Label>Phone </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.phone ? row?.phone : "N/A"}
            </div>
          </div> */}
        </li>

        <li>
          <div className="dataWrap">
            <div className="d-flex align-items-center justify-content-between">
              <Label>Location </Label>
              {row?.address?.zip ? (
                <Button
                  color="link"
                  size="md"
                  className="p-0"
                  title={formatAddressInSingleText(row?.address)}
                  onClick={() => openGoogleMapOnNewTab(row?.address)}
                >
                  <img
                    src={require("../../../assets/img/map-marker.svg").default}
                    alt="map icon"
                    className="mr-1"
                  />
                  Map
                </Button>
              ) : null}
            </div>

            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.address ? (
                <>{formatAddressInSingleText(row?.address) || "N/A"}</>
              ) : (
                "N/A"
              )}

              {row?.address?.zip ? (
                <Button
                  color="link"
                  className="p-0"
                  title={formatAddressInSingleText(row?.address)}
                  onClick={() => openGoogleMapOnNewTab(row?.address)}
                  style={{ fontSize: "14px" }}
                >
                  <img
                    src={require("../../../assets/img/map-marker.svg").default}
                    alt="map icon"
                    className="mr-1"
                  />
                  Map
                </Button>
              ) : null}
            </div>
          </div>
        </li>

        {/* <li>
          <div className="dataWrap">
            <Label>Standard Rates </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.rates ? formatCurrencyValue(row?.rates) : "N/A"}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Connect Status </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.connectStatus ? (
                row?.connectStatus === "requested" ? (
                  <Badge color="success" className="outline" pill>
                    Request{" "}
                    {`
              ${
                row.connectResquestBy === this.props.userData?.user?.type
                  ? "Sent"
                  : "Received"
              }
                `}
                  </Badge>
                ) : row?.connectStatus === "accepted" ? (
                  <Badge color="success" className="outline" pill>
                    Connected
                  </Badge>
                ) : (
                  <Badge color="warning" className="outline" pill>
                    {capitalize(row?.connectStatus)}
                  </Badge>
                )
              ) : (
                <Badge color="warning" className="outline" pill>
                  Pending
                </Badge>
              )}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap d-flex align-items-center justify-content-between">
            <Label>Status </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.isActive ? (
                <Badge color="success" className="outline" pill>
                  Active
                </Badge>
              ) : (
                <Badge color="warning" className="outline" pill>
                  Inactive
                </Badge>
              )}
            </div>
          </div>
        </li> */}
        <li>
          <div className="dataWrap d-flex align-items-center justify-content-center">
            {/* {!row?.isSignedUp && ( */}
            <Button
              color="primary"
              className="actionBtn"
              size="md"
              onClick={() => this._toggleAddClientModal(true, "edit", row)}
              disabled={this.state.loading?.clientDeleteLoading}
            >
              {/* <img
                  src={require("../../../assets/img/pencil.svg").default}
                  alt="edit"
                /> */}
              Edit
            </Button>
            {/* )} */}

            {/* {!row?.isSignedUp ? (
              <Button
                color="danger"
                size="md"
                // className="remove"
                onClick={() => this._resendClientInvitation(row)}
                disabled={this.state.loading?.clientDeleteLoading}
              >
                Resend Invitation
              </Button>
            ) : null} */}

            {/* {row?.connectStatus === "accepted" &&
              (row?.isSignedUp && row?.isEditable ? (
                <>
                  <Button
                    color="primary"
                    className="actionBtn"
                    size="md"
                    onClick={() =>
                      this._toggleAddClientModal(true, "edit", row)
                    }
                    disabled={this.state.loading?.clientDeleteLoading}
                  >
                    {/* <img
                      src={require("../../../assets/img/pencil.svg").default}
                      alt="edit"
                    /> *
                    Edit
                  </Button>
                  <Button
                    color="danger"
                    // className="remove"
                    size="md"
                    style={{ whiteSpace: "nowrap" }}
                    onClick={() =>
                      this._companyDeleteRequestConnectWithClient(row.id)
                    }
                    disabled={
                      this.state.loading.statusChangeLoading
                        ? true
                        : false || this.state.loading?.clientDeleteLoading
                    }
                  >
                    Remove Connection
                  </Button>
                </>
              ) : (
              ))} */}
            {/* <Button
              color="danger"
              size="md"
              // className="fs-12 remove"
              onClick={() =>
                this._companyDeleteRequestConnectWithClient(row.id)
              }
              disabled={
                this.state.loading.statusChangeLoading
                  ? true
                  : false || this.state.loading?.clientDeleteLoading
              }
            >
              Remove Connection
            </Button> */}

            <Button
              color="link"
              onClick={() => this._handleOnDeleteClientAlert(row?._id)}
              disabled={
                row?.connectStatus === "accepted" ||
                this.state.loading?.clientDeleteLoading
              }
            >
              <img
                src={require("../../../assets/img/deleteIcon.svg").default}
                alt="delete"
              />
            </Button>
          </div>
        </li>
      </ul>
    );
  };

  render() {
    const {
      addClientModalData,
      clientsList,
      clientsHeaderKeys,
      loading,
      clientsTotalCount,
      tableConfig,
      bulkUploadResponse,
      clientsCardHeaderKeys,
      filters,
      clientMemberModal,
      clientTeamsModal,
    } = this.state;

    return (
      <>
        <div id="scroll">
          <div className="content">
            <div className="responsiveTitle">
              <h2>Clients</h2>

              <div className="rightSide">
                {checkPermission("clients", "canAdd") && (
                  <>
                    <Label className="uploadLink">
                      <Input
                        type="file"
                        hidden
                        accept=".csv"
                        value=""
                        disabled={loading.bulkFile}
                        onChange={(e) => this._onFileSelect("selectedFile", e)}
                      />
                      {loading.bulkFile ? (
                        <i className="fa fa-spinner fa-spin" />
                      ) : (
                        <>
                          <img
                            src={
                              require("../../../assets/img/uploadIconBlue.svg")
                                .default
                            }
                            className="mr-2"
                            alt="upload"
                          />
                          Upload
                        </>
                      )}
                    </Label>

                    {/* <Link
                      to={`/signingcompany/bulk-upload?companyClient`}
                      className="mr-3"
                    >
                      Bulk upload
                    </Link> */}

                    <Button
                      className="floatingButton"
                      color="primary"
                      onClick={() => this._toggleAddClientModal(true, "add")}
                    >
                      Add Client
                    </Button>
                  </>
                )}

                {/* <Button color="link" onClick={this._resetFilter}>
                <img
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                  height={14}
                />
              </Button> */}
              </div>
            </div>

            {/* search filed for responsive will show below 767px */}
            <div className="mobileSearchFiled">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <img
                      src={
                        require("../../../assets/img/searchIcon.svg").default
                      }
                      alt="searchIcon"
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  name="search"
                  value={filters?.search}
                  onChange={(e) =>
                    this._filterOnChange("search", e.target.value, true)
                  }
                  placeholder="Company Name"
                />
              </InputGroup>
            </div>

            <div className="filterContainer responsiveFilter">
              <div className="filterIcon">
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="filter icon"
                />
                Filter by
              </div>
              <div className="filterWrapper">
                <FormGroup className="searchTable">
                  <Label>Search</Label>
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <img
                          src={
                            require("../../../assets/img/searchIcon.svg")
                              .default
                          }
                          alt="searchIcon"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      placeholder="Company Name"
                      value={filters?.search}
                      onChange={(e) =>
                        this._filterOnChange("search", e.target.value, true)
                      }
                    />
                  </InputGroup>
                </FormGroup>
              </div>
            </div>

            <div className="hideDesktop">
              <CustomCard
                isPageStartFromOne={true}
                pageNumber={tableConfig.pageNumber}
                tableData={clientsList}
                headerKeys={clientsCardHeaderKeys}
                // bodyKeys={agentClosingCardBodyKeys}
                dataFormat={this._dataFormat}
                totalCount={clientsTotalCount}
                onPaginate={(pageNumber, pageSize) =>
                  this._paginate(pageNumber, pageSize)
                }
                rowSelection={false}
                // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
                showTableLoading={loading.showTableLoading}
                cardHeaderFormat={this._cardHeaderFormat}
                cardDataFormat={this._cardDataFormat}
              />
            </div>

            <div className="hideMobile">
              {clientsHeaderKeys && clientsHeaderKeys.length && (
                <CustomTable
                  striped
                  isPageStartFromOne={true}
                  pageNumber={tableConfig.pageNumber}
                  tableData={clientsList}
                  headerKeys={clientsHeaderKeys}
                  dataFormat={this._dataFormat}
                  totalCount={clientsTotalCount}
                  rowSelection={false}
                  onPaginate={(pageNumber, pageSize) =>
                    this._paginate(pageNumber, pageSize)
                  }
                  onSortChange={(sortName, sortOrder) =>
                    this._onSortChange(sortName, sortOrder)
                  }
                  showTableLoading={loading.showTableLoading}
                />
              )}
            </div>

            {addClientModalData?.data?.isSignedUp &&
            addClientModalData?.data?.isEditable ? (
              <InviteClientNewModal
                isOpen={addClientModalData.isOpen}
                data={addClientModalData.data}
                onSuccess={() => this._getAllClients()}
                toggle={this._toggleAddClientModal}
              />
            ) : (
              <InviteClient
                isOpen={addClientModalData.isOpen}
                data={addClientModalData.data}
                type={addClientModalData.type}
                onSuccess={() => this._getAllClients()}
                toggle={this._toggleAddClientModal}
              />
            )}
            <BulkUploadInfo
              isOpen={bulkUploadResponse.isOpen}
              toggle={() => this._onToggleBulkInfoModal()}
              data={bulkUploadResponse.data}
              success={bulkUploadResponse.success}
              type={"Clients"}
            />

            {clientMemberModal?.isOpen ? (
              <ClientMembers
                isOpen={clientMemberModal?.isOpen}
                data={clientMemberModal?.data}
                toggle={() => this._onToggleClientMembers()}
              />
            ) : null}
            {clientTeamsModal?.isOpen ? (
              <ClientTeams
                isOpen={clientTeamsModal?.isOpen}
                data={clientTeamsModal?.data}
                toggle={() => this._onToggleClientTeams()}
              />
            ) : null}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

export default connect(mapStateToProps, null)(Clients);
