import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { RegexConfig } from "../../../../../config/RegexConfig";
import { errorHandler } from "../../../../../helper-methods";
import UIColorChangePreview from "../../../pages/Company/UIColorChangePreview";

const initialValues = {
  primary: "",
  secondary: "",
  tertiary: "",
  secondaryText: "",
};

const WhiteLabelUIColor = forwardRef(
  (
    {
      isOpen,
      toggle,
      onSave,
      customizedUIData = null,
      hideFromAgentWebsite = false, // true -> from agent website side, used to hide stuff that shouuld not be displayed there
    },
    ref
  ) => {
    const [showPreview, setShowPreview] = useState(false);
    const [colors, setColors] = useState(initialValues);
    const showtoggle = () => setShowPreview(!showPreview);

    const _onChangeFormFields = (key, value) => {
      const updatedObj = { ...colors };

      updatedObj[key] = value;

      setColors(updatedObj);
    };

    const _closeModal = () => {
      toggle();
    };

    useImperativeHandle(ref, () => {
      return {
        _closePreviewModal,
      };
    });

    const _closePreviewModal = () => {
      setShowPreview(false);
    };

    const _onSaveColor = async () => {
      if (
        !colors?.primary ||
        !colors?.secondary ||
        (!hideFromAgentWebsite && (!colors?.tertiary || !colors?.secondaryText))
      ) {
        errorHandler("All fields are required");
        return;
      }
      if (
        !RegexConfig.hexCode.test(colors?.primary) ||
        !RegexConfig.hexCode.test(colors?.secondary) ||
        (!hideFromAgentWebsite &&
          (!RegexConfig.hexCode.test(colors?.tertiary) ||
            !RegexConfig.hexCode.test(colors?.secondaryText)))
      ) {
        errorHandler("Please enter valid color codes only");
        return;
      }

      onSave(colors);
      _closeModal();
    };

    const _setForm = () => {
      setColors(customizedUIData);
    };

    useEffect(() => {
      if (customizedUIData) {
        _setForm();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customizedUIData]);

    return (
      <>
        <Modal isOpen={isOpen} toggle={() => _closeModal()} centered>
          <ModalHeader toggle={() => _closeModal()}>
            Select UI Color
          </ModalHeader>
          <ModalBody>
            <FormGroup className="floatingLabel withInputGroup withColorInput">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <Input
                      type="color"
                      value={colors?.primary}
                      onChange={(e) =>
                        _onChangeFormFields("primary", e.target.value)
                      }
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder=" "
                  value={colors?.primary}
                  onChange={(e) =>
                    _onChangeFormFields("primary", e.target.value)
                  }
                />
                <Label>Primary Color </Label>
              </InputGroup>
              <small className="text-muted">
                Button and anchor text use the primary colour.
              </small>
            </FormGroup>

            <FormGroup className="floatingLabel withInputGroup withColorInput">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <Input
                      type="color"
                      value={colors?.secondary}
                      onChange={(e) =>
                        _onChangeFormFields("secondary", e.target.value)
                      }
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder=" "
                  value={colors?.secondary}
                  onChange={(e) =>
                    _onChangeFormFields("secondary", e.target.value)
                  }
                />
                <Label>Secondary Color</Label>
              </InputGroup>
              <small className="text-muted">
                {hideFromAgentWebsite
                  ? "The card header uses the secondary colour."
                  : "Sidebar use the secondary colour."}
              </small>
            </FormGroup>

            {!hideFromAgentWebsite ? (
              <FormGroup className="floatingLabel withInputGroup withColorInput">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <Input
                        type="color"
                        value={colors?.secondaryText}
                        onChange={(e) =>
                          _onChangeFormFields("secondaryText", e.target.value)
                        }
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder=" "
                    value={colors?.secondaryText}
                    onChange={(e) =>
                      _onChangeFormFields("secondaryText", e.target.value)
                    }
                  />
                  <Label>Secondary Text Color</Label>
                </InputGroup>
                <small className="text-muted">
                  Sidebar text use the secondary text colour.
                </small>
              </FormGroup>
            ) : null}

            {!hideFromAgentWebsite ? (
              <FormGroup className="floatingLabel withInputGroup withColorInput">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <Input
                        type="color"
                        value={colors?.tertiary}
                        onChange={(e) =>
                          _onChangeFormFields("tertiary", e.target.value)
                        }
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    placeholder=" "
                    value={colors?.tertiary}
                    onChange={(e) =>
                      _onChangeFormFields("tertiary", e.target.value)
                    }
                  />
                  <Label>Tertiary Color</Label>
                </InputGroup>
                <small className="text-muted">
                  Sidebar menu active use the tertiary colour.
                </small>
              </FormGroup>
            ) : null}
          </ModalBody>
          <ModalFooter>
            <Button color="primary" outline onClick={() => _closeModal()}>
              Cancel
            </Button>
            <Button color="primary" onClick={() => _onSaveColor()}>
              Save
            </Button>
          </ModalFooter>
        </Modal>

        <UIColorChangePreview
          isOpen1={showPreview}
          toggle={showtoggle}
          onSaveData={_onSaveColor}
        />
      </>
    );
  }
);

export default WhiteLabelUIColor;
