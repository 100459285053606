import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Card, CardBody } from "reactstrap";
import PersonalWebsiteAddAgents from "./PersonalWebsiteAddAgents";
import { errorHandler } from "../../../../helper-methods";
import {
  getAgentWebsiteDetail,
  updateAgentWebsiteDetail,
  saveDomainDetailForAgentWebsite,
} from "../../../../http/http-calls";
import WebsiteLaunchedModal from "./WebsiteLaunchedModal";

const PersonalWebsiteSetp3 = (
  { manageLoadingState, setIsFormSubmitted },
  ref
) => {
  const [agentWebsiteDetails, setAgentWebsiteDetails] = useState({});
  const [selectedValue, setSelectedValue] = useState("ClosewiseverifiedNna");

  const [websiteLaunchedModal, setWebsiteLaunchedModal] = useState({
    isOpen: false,
    data: null,
  });

  const _togglewebsiteLaunchedModal = (isOpen = false, data = null) => {
    setWebsiteLaunchedModal({ isOpen, data });
  };

  const _onSubmit = async () => {
    try {
      manageLoadingState("stepLoading", true);
      const payload = {
        showJobForwardingSelectedAgents:
          selectedValue === "InvitesAgentsOnly" ? true : false,
      };
      await updateAgentWebsiteDetail(payload);
      manageLoadingState("stepLoading", false);

      // for auto deployment
      if (
        agentWebsiteDetails?.isAutoGeneratedAwsRoute53 &&
        agentWebsiteDetails?.websiteDeployementProcessStatus === "Pending"
      ) {
        // email not verified yet
        if (!agentWebsiteDetails?.isEmailVerified) {
          errorHandler(
            "Email not verfied, please verify your email from step 2 to launch website."
          );
          manageLoadingState("stepLoading", false);
          setIsFormSubmitted(true);
          return;
        }

        const clientPayload = {
          domainName: agentWebsiteDetails?.domainName,
          domainProvider: agentWebsiteDetails?.domainProvider,
          isAutoGeneratedAwsRoute53:
            agentWebsiteDetails?.isAutoGeneratedAwsRoute53,
        };

        // save api call
        await saveDomainDetailForAgentWebsite(clientPayload);
      }

      manageLoadingState("stepLoading", false);
      setIsFormSubmitted(true);
      _togglewebsiteLaunchedModal(true);
    } catch (err) {
      errorHandler(err);
      manageLoadingState("stepLoading", false);
    }
  };

  useImperativeHandle(ref, () => ({
    _onSubmit,
    isWebsiteLaunched: agentWebsiteDetails?.isWebsiteLaunched,
  }));

  const _getAgentWebsite = async () => {
    try {
      const res = await getAgentWebsiteDetail();
      setAgentWebsiteDetails(res?.agentWebsite);

      const newSelectedValue =
        res?.agentWebsite?.showJobForwardingSelectedAgents === true
          ? "InvitesAgentsOnly"
          : "ClosewiseverifiedNna";

      setSelectedValue(newSelectedValue);
    } catch (err) {
      errorHandler(err);
    }
  };

  useEffect(() => {
    _getAgentWebsite();
  }, []);

  return (
    <>
      <Card>
        <CardBody>
          <div className="d-flex align-items-center justify-content-center flex-wrap mt-4 mb-4">
            <p style={{ fontSize: 18, fontWeight: 600, marginBottom: 5 }}>
              Invite other notaries to join you co-op
            </p>
          </div>
          <PersonalWebsiteAddAgents />
        </CardBody>
      </Card>
      <WebsiteLaunchedModal
        isOpen={websiteLaunchedModal?.isOpen}
        toggle={_togglewebsiteLaunchedModal}
        agentWebsiteDetails={agentWebsiteDetails}
      />
    </>
  );
};

export default forwardRef(PersonalWebsiteSetp3);
