import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import {
  FacebookShareButton,
  WhatsappShareButton,
  FacebookIcon,
  WhatsappIcon,
  EmailShareButton,
  EmailIcon,
  LinkedinShareButton,
  LinkedinIcon,
  TelegramShareButton,
  TelegramIcon,
} from "react-share";

const ShareLinkModal = ({ isOpen, toggle, shareableLink }) => {

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => toggle(null)}
      className="modal-dialog-centered"
    >
      <ModalHeader toggle={() => toggle(null)}>Share Link</ModalHeader>
      <ModalBody className="modalContent text-center">
        <div className="mt-1 d-flex justify-content-around align-items-center">
          <FacebookShareButton
            url={shareableLink}
            title="checkout this"
            className="Demo__some-network__share-button"
            hashtag="#closwise"
          >
            <FacebookIcon size={60} round />
            <p className="mt-2 mb-0">Facebook</p>
          </FacebookShareButton>
          <WhatsappShareButton
            url={shareableLink}
            title=""
            className="Demo__some-network__share-button"
          >
            <WhatsappIcon size={60} round />
            <p className="mt-2 mb-0">WhatsApp</p>
          </WhatsappShareButton>
          <LinkedinShareButton
            url={shareableLink}
            title=""
            className="Demo__some-network__share-button"
          >
            <LinkedinIcon size={60} round />
            <p className="mt-2 mb-0">LinkedIn</p>
          </LinkedinShareButton>
          {/* <EmailShareButton
            url={shareableLink}
            title=""
            className="Demo__some-network__share-button"
          >
            <EmailIcon size={60} round />
            <p className="mt-2 mb-0">Email</p>
          </EmailShareButton> */}

          <EmailShareButton
            url={shareableLink}
            subject="Check this out!"
            body={`Here is something interesting:${shareableLink}`}
            className="Demo__some-network__share-button"
          >
            <EmailIcon size={60} round />
            <p className="mt-2 mb-0">Email</p>
          </EmailShareButton>
          <TelegramShareButton
            url={shareableLink}
            title=""
            className="Demo__some-network__share-button"
          >
            <TelegramIcon size={60} round />
            <p className="mt-2 mb-0">Telegram</p>
          </TelegramShareButton>
        </div>
        <hr />
      </ModalBody>
      <ModalFooter>
        <Button className="modalBtnCancel" onClick={() => toggle(null)}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ShareLinkModal;
