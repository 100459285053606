import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import NotificationSettings from "../components/NotificationSettings";
import OrderAutomationSettings from "../components/OrderAutomationSettings";
import {
  checkPermission,
  errorHandler,
  showToast,
} from "../../../helper-methods";
import {
  companyGetAllProductTypes,
  getAllCompanySettings,
  updateSigningCompanySetting,
} from "../../../http/http-calls";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { updateUserObj } from "../../../redux/actions/user-data";
import JobsSettings from "../components/jobsSettings";
import FeesSettings from "../components/feesSettings";
import EmailCustomization from "../components/emailCustomization";
import AccountingAutomation from "./accountingAutomation";
import Quickbooksetting from "./quickbooksetting";
import CustomProductType from "../components/Modals/Company/CustomProductType";

const Settings = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState([]);
  const [loanType, setLoanType] = useState([]);
  const [productTypes, setproductTypes] = useState({});
  const [customProductTypes, setCustomProductTypes] = useState([]);
  const [companySettings, setCompanySettings] = useState({});

  const signingCompany = useSelector((state) => state);
  const dispatch = useDispatch();
  const _toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  useEffect(() => {
    _getAllCompanySettings();
    _companyGetAllProductTypes();
    setClient(signingCompany.list.clients);

    loanTypes();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // console.log(signingCompany);
    if (
      signingCompany?.userData?.type === "signingcompany" &&
      signingCompany?.userData?.user?.isAssistant
    ) {
      setActiveTab("6");
    }
  }, []);

  const loanTypes = () => {
    let LoanType = [
      "Refinance",
      "HomeEquity",
      "AttorneyPhone",
      "Purchase",
      "LoanMod",
      "ReverseMtg",
      "HybridESign",
      "DeedInLieu",
      "SingleDoc",
      "LoanApplication",
    ];

    let options = LoanType.map((item) => ({
      value: item,
      label: item.replace(/([A-Z])/g, " $1").trim(),
    }));
    options.splice(0, 0, { value: "", label: "Select" });
    setLoanType(options);
  };
  const _handleOnSubmit = async (payload) => {
    try {
      setLoading(true);
      let response = await updateSigningCompanySetting(payload);
      _getAllCompanySettings();
      console.log("response", response);
      dispatch(updateUserObj(response?.signingCompany));
      showToast("Updated Successfully", "success");
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _getAllCompanySettings = async (payload) => {
    if (payload) {
      try {
        setLoading(true);
        let response = await getAllCompanySettings(payload);

        setCompanySettings(response?.companySetting);

        setLoading(false);
      } catch (error) {
        errorHandler(error);
        setLoading(false);
      }
    } else {
      try {
        setLoading(true);
        let response = await getAllCompanySettings();

        setCompanySettings(response?.companySetting);

        setLoading(false);
      } catch (error) {
        errorHandler(error);
        setLoading(false);
      }
    }
  };

  const _companyGetAllProductTypes = async () => {
    try {
      const res = await companyGetAllProductTypes();
      // console.log({ res });
      if (res?.customProductTypes) {
        setCustomProductTypes(res?.customProductTypes);
        _setData(res?.customProductTypes);
      }
      // showToast("Settings are", "success");
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _setData = (data) => {
    // console.log("data",data)
    let products =
      (data?.products?.length > 0 &&
        data?.products?.filter((each) => each.isCustomAdded)) ||
      [];
    setproductTypes(products);
  };

  return (
    <div className="content">
      <div className="responsiveTitle">
        <h2>Settings</h2>
      </div>
      <div className="verticalTabs">
        <Nav tabs>
          {checkPermission("settings", "canViewOrEditOrderAutomation") ? (
            <NavItem>
              <NavLink
                className={activeTab === "1" ? "active" : ""}
                onClick={() => _toggleTab("1")}
              >
                Order Automations
              </NavLink>
            </NavItem>
          ) : null}
          {checkPermission("settings", "canViewOrEditJobsAndFees") ? (
            <NavItem>
              <NavLink
                className={activeTab === "2" ? "active" : ""}
                onClick={() => _toggleTab("2")}
              >
                Jobs
              </NavLink>
            </NavItem>
          ) : null}
          {checkPermission("settings", "canViewOrEditJobsAndFees") ? (
            <NavItem>
              <NavLink
                className={activeTab === "3" ? "active" : ""}
                onClick={() => _toggleTab("3")}
              >
                Fees
              </NavLink>
            </NavItem>
          ) : null}
          <NavItem>
            <NavLink
              className={activeTab === "4" ? "active" : ""}
              onClick={() => _toggleTab("4")}
            >
              Custom Product Type
            </NavLink>
          </NavItem>
          {!signingCompany?.userData?.user?.isTitleCompany && (
            <NavItem>
              <NavLink
                className={activeTab === "5" ? "active" : ""}
                onClick={() => _toggleTab("5")}
              >
                Quickbooks Settings
              </NavLink>
            </NavItem>
          )}
          {checkPermission("settings", "canViewOrEditEmailCustomization") ? (
            <NavItem>
              <NavLink
                className={activeTab === "6" ? "active" : ""}
                onClick={() => _toggleTab("6")}
              >
                Email Customization
              </NavLink>
            </NavItem>
          ) : null}
          {/* {console.log("azaxs", signingCompany)} */}
          {!signingCompany?.userData?.user?.isTitleCompany &&
          checkPermission("settings", "canViewOrEditAccountingAutomation") ? (
            <NavItem>
              <NavLink
                className={activeTab === "7" ? "active" : ""}
                onClick={() => _toggleTab("7")}
              >
                Accounting Automation
              </NavLink>
            </NavItem>
          ) : null}
          <NavItem>
            <NavLink
              className={activeTab === "8" ? "active" : ""}
              onClick={() => _toggleTab("8")}
            >
              Notifications
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <OrderAutomationSettings
              bidAutomationSetting={companySettings?.bidAutomationSetting}
              handleOnSubmit={_handleOnSubmit}
              loading={loading}
            />
          </TabPane>
          <TabPane tabId="2">
            <JobsSettings
              client={client}
              loanType={loanType}
              getAllCompanySettings={_getAllCompanySettings}
              changeToTab={() => _toggleTab("3")}
              companySettings={companySettings}
            />
          </TabPane>
          <TabPane tabId="3">
            <FeesSettings
              // loanType={customProductTypes}
              loading={loading}
              companySettings={companySettings}
              getAllCompanySettings={_getAllCompanySettings}
              customProductTypes={customProductTypes}
            />
          </TabPane>
          {/* {console.log("object", customProductTypes)} */}
          <TabPane tabId="4">
            <CustomProductType
              companyGetAllProductTypes={_companyGetAllProductTypes}
              customProductTypes={productTypes}
            />
          </TabPane>
          {!signingCompany?.userData?.user?.isTitleCompany && (
            <TabPane tabId="5">
              <Quickbooksetting />
            </TabPane>
          )}
          <TabPane tabId="6">
            <EmailCustomization />
          </TabPane>
          <TabPane tabId="7">
            <AccountingAutomation
              companySettings={companySettings}
              getAllCompanySettings={_getAllCompanySettings}
            />
          </TabPane>
          <TabPane tabId="8">
            <NotificationSettings />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default Settings;
