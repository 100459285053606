import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import {
  Button,
  Card,
  CardBody,
  CustomInput,
  FormGroup,
  Input,
  Label,
} from "reactstrap";
import SvgIcons from "../SvgIcons";
import TeamMemberPermissions from "../TeamMemberPermissions";
import {
  comapanyGetAllClientTeams,
  companyAddAssistantTeams,
  companyEditAssistantTeams,
  getClientTeamMembers,
} from "../../../../http/http-calls";
import { errorHandler, showToast } from "../../../../helper-methods";

const initialMembers = [
  {
    id: "",
    memberName: "",
    memberTeams: [
      {
        teamName: "Primary",
        teamId: "",
        _user: "",
        permissions: {
          noEmails: false,
          allEmails: false,
          chatLogEmails: false,
          statusUpdates: false,
          invoiceEmails: false,
          appointmentConfirmation: false,
          agentAssigned: false,
        },
        loginCredential: false,
        isDirty: {
          _user: false,
          permissions: false,
        },
        errors: {},
      },
    ],
  },
];

const initialIsDirty = {
  teamName: false,
};

const AddToOtherTeam = forwardRef(
  (
    { data, manageLoading, type, newClientData, onSuccess, stopTeamMemberCall },
    ref
  ) => {
    const [teams, setTeams] = useState([]);
    // const [teamMember, setTeamMember] = useState([1]);
    const [allMembers, setAllMembers] = useState(initialMembers);
    const [isDirty, setIsDirty] = useState(initialIsDirty);
    const [errors, setErrors] = useState({});
    const [isFormDirty, setIsFormDirty] = useState(false);

    const _resetData = () => {
      setTeams([]);
      setAllMembers(initialMembers);
    };

    useEffect(() => {
      async function fetchData() {
        try {
          manageLoading(true);
          _resetData();
          const Id = data?.id ? data?.id : newClientData.id;
          let response = await comapanyGetAllClientTeams({ clientId: Id });
          setTeams(response.assistantTeams);
          _setToAllMember(response.assistantTeams);
          console.log("sss", stopTeamMemberCall);
          if (!stopTeamMemberCall) {
            let resp = await getClientTeamMembers({ clientId: Id });

            // console.log("dd",data)
            console.log("as", response.assistantTeams); // Moved the console.log here to access response.assistantTeams
            // }
            if (resp) {
              let teamMember = resp.users.map((item, index) => ({
                id: item.id,
                memberName: item?.name?.full || "N/A",
                memberTeams: [
                  {
                    teamName: item.teamName || "Primary Team",
                    _user: item.id,
                    email: item.email,
                    permissions: {
                      noEmails: false,
                      allEmails: false,
                      chatLogEmails: false,
                      statusUpdates: false,
                      invoiceEmails: false,
                      appointmentConfirmation: false,
                      agentAssigned: false,
                    },
                    loginCredential: false,
                    isDirty: {
                      _user: false,
                      permissions: false,
                    },
                    errors: {},
                  },
                ],
              }));
              setAllMembers(teamMember);
            }
            manageLoading(false);
          }
        } catch (error) {
          errorHandler(error);
          manageLoading(false);
        }
      }
      fetchData();

      // // const Id = newClientData?.id ? newClientData?.id : data?.id;
      // const Id = "660f91a29f1a37235cc55010";
      // try {
      //   let response = await getClientTeamMembers({ clientId: Id });
      //   // Handle response data as needed
      //   // _setFields(response);
      // } catch (error) {
      //   errorHandler(error);
      // }
      // };
    }, []);

    const _setToAllMember = (teamData) => {
      console.log("team", teamData);
      const newAllMembers = [...allMembers];

      newAllMembers[0]["memberTeams"][0]["teamId"] = teamData[0]._id;

      newAllMembers[0]["memberTeams"][0]["teamName"] = teamData[0].teamName;
      setAllMembers(newAllMembers);
    };

    const _addNewTeamToMember = (index, data) => {
      console.log("first", data);
      const newAllMembers = [...allMembers];
      newAllMembers[index]["memberTeams"].push({
        teamName: "Test team one",
        _user: data?.memberTeams[0]?._user,
        email: data?.memberTeams[0]?.email,
        permissions: {
          noEmails: false,
          allEmails: false,
          chatLogEmails: false,
          statusUpdates: false,
          invoiceEmails: false,
          appointmentConfirmation: false,
          agentAssigned: false,
        },
        isDirty: {
          _user: false,
          permissions: false,
        },
        errors: {},
      });

      setAllMembers(newAllMembers);
    };

    const _deleteMemberTeam = (memberIndex, teamIndex) => {
      const newAllMembers = [...allMembers];
      newAllMembers[memberIndex]["memberTeams"].splice(teamIndex, 1);

      setAllMembers(newAllMembers);
    };

    const _onChangeTeam = (field, value, index, subIndex) => {
      console.log({ field }, { value }, { index }, { subIndex });
      // here subIndex is coming 0 everytime, need to check that

      const newAllMembers = [...allMembers];

      newAllMembers[index]["memberTeams"][subIndex][field] = value;
      if (field === "teamId") {
        newAllMembers[index]["memberTeams"][subIndex]["teamName"] =
          findTeamNameByMemberId(value);
      }

      console.log({ newAllMembers });
      setAllMembers(newAllMembers);
    };

    const _onChangeMemberTeam = (field, value, index, subKey, subIndex) => {
      console.log({ field }, { value }, { index }, { subKey }, { subIndex });
      // here subIndex is coming 0 everytime, need to check that

      const newAllMembers = [...allMembers];

      newAllMembers[index]["memberTeams"][subIndex][subKey][field] = value;

      console.log({ newAllMembers });
      setAllMembers(newAllMembers);
    };

    const _createPayload = (payloadData) => {
      // @apiParamExample  {json} Request-Example:
      // * {
      // *     "clientId" : "00000000000",
      // *     "teamName" : "Doe-team",
      // *     "teamMembers"  :[{
      // *          "_user":"00000000000",
      // *          "email" :"joe@gmail.com",
      // *          "name" :"Doe",
      // *          "roles" :[1,2]
      // *      }],
      // * }
      // *

      // "assistantTeams": [{
      //   _id: "87654321"
      //   _client: "12345678901234567890",
      //   _signingcompany: "123456",
      //   teamName: "Primary Team"
      //   teamMembers: [{
      //     _user: {
      //       name: {
      //         first: "sanjay",
      //         last: "kumar"
      //       }
      //     },
      //     roles: [1]
      //   }]
      // }]

      console.log("data", data, payloadData, newClientData);
      return new Promise((resolve) => {
        const payload = {
          clientId: newClientData?.id ? newClientData?.id : data?.id,
          // clientId: "660f91a29f1a37235cc55010",
          // teamName: data?.teamName,
          teamMembers: [],
        };

        const teamMembers = [];
        payloadData.forEach((team) =>
          team?.memberTeams?.forEach((each) => {
            console.log("first", team);
            const member = {};
            // payload["clientId"] = each?.clientId;
            payload["teamName"] = each.teamName;
            payload["teamId"] = each?.teamId;

            const permissionArr = [];
            Object.keys(each?.permissions)?.forEach((subEach) =>
              subEach === "noEmails" && each?.permissions[subEach]
                ? permissionArr.push(1)
                : subEach === "allEmails" && each?.permissions[subEach]
                ? permissionArr.push(2)
                : subEach === "chatLogEmails" && each?.permissions[subEach]
                ? permissionArr.push(3)
                : subEach === "statusUpdates" && each?.permissions[subEach]
                ? permissionArr.push(4)
                : subEach === "invoiceEmails" && each?.permissions[subEach]
                ? permissionArr.push(5)
                : subEach === "appointmentConfirmation" &&
                  each?.permissions[subEach]
                ? permissionArr.push(6)
                : subEach === "agentAssigned" && each?.permissions[subEach]
                ? permissionArr.push(7)
                : null
            );

            member["roles"] = permissionArr;
            member["name"] = team.memberName;
            member["email"] = each.email;
            member["_user"] = each._user;
            member["loginCredential"] = each.loginCredential;

            teamMembers.push(member);
          })
        );
        payload["teamMembers"] = teamMembers;

        resolve(payload);
      });
    };

    const _onSubmit = async () => {
      try {
        // if (!isFormDirty) {
        //   _closeModal();
        //   return;
        // }

        manageLoading(true);
        const newTeam = [...allMembers];
        const newIsDirty = { ...isDirty };
        newIsDirty["teamName"] = true;

        newTeam.forEach((member) => {
          member.memberTeams.forEach((team) => {
            // team.isDirty._user = true;
            team.isDirty.teamName = true;
            team.isDirty.permissions = true;
          });
        });

        // const isFormValid = await _validateFormfields({ newTeam, newIsDirty });
        const isMembersFormValid = await _validateMembers({ newTeam });

        if (!isMembersFormValid) {
          errorHandler({ reason: "Please enter required fields properly" });
          manageLoading(false);
          return;
        }

        const payload = await _createPayload(newTeam);
        console.log("pay", teams[0]);
        // if (!data) {
        //   await companyAddAssistantTeams(payload);
        // } else {
        const Id = teams[0]?._id ? teams[0]?._id : teams[0]?.id;
        await companyEditAssistantTeams(Id, payload);
        // }

        manageLoading(false);
        _resetData();
        onSuccess();
        showToast(`Team ${data ? "updated" : "added"} successfully`, "success");
        return true;
      } catch (err) {
        errorHandler(err);
        manageLoading(false);
        return false;
      }
    };

    useImperativeHandle(ref, () => ({
      _onSubmit,
      _sendData,
      _resetData,
    }));

    const _validateMembers = ({ newTeam }) => {
      return new Promise((resolve) => {
        newTeam.forEach((member) => {
          member?.memberTeams?.forEach((each) => {
            if (each?.isDirty?.permissions) {
              if (
                Object.keys(each?.permissions)?.filter(
                  (subEach) => each?.permissions[subEach] === true
                )?.length < 1
              ) {
                each.errors.permissions =
                  "*At least 1 email permission is Required";
              } else {
                delete each.errors.permissions;
                each.isDirty.permissions = false;
              }
            }
            if (each?.isDirty?.teamName) {
              if (!each.teamName) {
                each.errors.teamName = "Team is required";
              } else {
                delete each.errors.teamName;
                each.isDirty.teamName = false;
              }
            }
          });
        });

        setAllMembers(newTeam);

        // let membersWithErrors = newTeam?.members?.filter((each) => {
        //   return Object.keys(each?.errors)?.length;
        // });
        let membersWithErrors = newTeam.flatMap((team) =>
          team.memberTeams.filter(
            (member) => Object.keys(member?.errors)?.length
          )
        );
        console.log("qaz", membersWithErrors);
        resolve(membersWithErrors?.length ? false : true);
      });
    };

    const findTeamNameByMemberId = (memberId) => {
      const foundTeam = teams.find((team) => {
        return team._id === memberId;
      });
      // console.log("rere", foundTeam);
      return foundTeam ? foundTeam.teamName : null;
    };

    const _sendData = async () => {
      manageLoading(true);
      const newTeam = [...allMembers];
      const newIsDirty = { ...isDirty };
      newIsDirty["teamName"] = true;

      newTeam.forEach((member) => {
        member.memberTeams.forEach((team) => {
          // team.isDirty._user = true;
          team.isDirty.teamName = true;
          team.isDirty.permissions = true;
        });
      });

      // const isFormValid = await _validateFormfields({ newTeam, newIsDirty });
      const isMembersFormValid = await _validateMembers({ newTeam });

      if (!isMembersFormValid) {
        errorHandler({ reason: "Please enter required fields properly" });
        manageLoading(false);
        return;
      }

      // const payload = {};
      let teamIds = [];
      console.log("mhgfyhfhf", newTeam[0]);
      newTeam[0].memberTeams.forEach((each) => {
        const member = {};

        // payload["teamName"] = each.teamName;
        member["teamId"] = each?.teamId;

        const permissionArr = [];
        Object.keys(each?.permissions)?.forEach((subEach) =>
          subEach === "noEmails" && each?.permissions[subEach]
            ? permissionArr.push(1)
            : subEach === "allEmails" && each?.permissions[subEach]
            ? permissionArr.push(2)
            : subEach === "chatLogEmails" && each?.permissions[subEach]
            ? permissionArr.push(3)
            : subEach === "statusUpdates" && each?.permissions[subEach]
            ? permissionArr.push(4)
            : subEach === "invoiceEmails" && each?.permissions[subEach]
            ? permissionArr.push(5)
            : subEach === "appointmentConfirmation" &&
              each?.permissions[subEach]
            ? permissionArr.push(6)
            : subEach === "agentAssigned" && each?.permissions[subEach]
            ? permissionArr.push(7)
            : null
        );

        member["roles"] = permissionArr;
        teamIds.push(member);
      });
      return teamIds;
    };

    return (
      <>
        {console.log("qw", data, newClientData, teams, allMembers)}
        <Card className="mt-3">
          {allMembers?.length > 0 &&
            allMembers?.map((each, index) => (
              <>
                <CardBody key={each?.id + index}>
                  <div className="mb-2 d-flex align-items-center justify-content-between">
                    <Label className="mb-0">
                      {each?.memberName ? each?.memberName : data?.value}
                    </Label>
                    {teams.length > 0 && (
                      <Button
                        className="readMore"
                        color="link"
                        onClick={() => _addNewTeamToMember(index, each)}
                      >
                        Add to other team
                      </Button>
                    )}
                  </div>

                  {each?.memberTeams?.length
                    ? each?.memberTeams?.map((subEach, subIndex) => (
                        <div key={subEach?.teamName + subIndex}>
                          {console.log({ subIndex })}
                          <div className="text-right mb-3">
                            {each?.memberTeams?.length > 1 && (
                              <Button
                                className="remove pb-2"
                                color="link"
                                onClick={() =>
                                  _deleteMemberTeam(index, subIndex)
                                }
                              >
                                <SvgIcons type="remove" />
                              </Button>
                            )}
                          </div>
                          <FormGroup className="floatingLabel">
                            <Input
                              type="select"
                              // value={subEach?.teamName}
                              value={subEach?.teamId}
                              onChange={(e) =>
                                _onChangeTeam(
                                  // "teamName",
                                  "teamId",
                                  e.target.value,
                                  index,
                                  subIndex
                                )
                              }
                            >
                              {teams?.length > 0 &&
                                teams?.map((item) => (
                                  <option value={item?._id}>
                                    {item?.teamName}
                                  </option>
                                ))}
                            </Input>
                            <Label>Teams</Label>
                            {subEach?.errors?.teamName ? (
                              <p className="validation-error">
                                {subEach?.errors?.teamName}
                              </p>
                            ) : null}
                          </FormGroup>

                          <TeamMemberPermissions
                            type="members"
                            headingText="Email Permissions"
                            each={subEach}
                            index={index}
                            subIndex={subIndex}
                            onChangeTeamMemberOrCCMember={_onChangeMemberTeam}
                            // onBlurFormFields={_onBlurFormFields}
                          />
                          <CustomInput
                            key={"loginCredential" + index}
                            type="checkbox"
                            id={`memberPermissionCheckbox_${"loginCredential"}_${index}${
                              subIndex ? "_" + subIndex : ""
                            }`}
                            label={"Send Login Credential"}
                            inline
                            checked={each.loginCredential}
                            onChange={(e) =>
                              _onChangeTeam(
                                "loginCredential",
                                e.target.checked,
                                // each?.type,
                                index,
                                subIndex
                              )
                            }
                          />
                        </div>
                      ))
                    : null}
                </CardBody>
                <hr />
              </>
            ))}
        </Card>
      </>
    );
  }
);

export default AddToOtherTeam;
