import React, { useState } from "react";
import PublicTextCarousel from "./PublicTextCarousel";
import PublicImageCarousel from "./PublicImageCarousel";

const PublicSideCarousel = ({ className, companyDetail }) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const next = () => {
    const nextIndex = activeIndex === 3 - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    const nextIndex = activeIndex === 0 ? 3 - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    setActiveIndex(newIndex);
  };

  return (
    <>
      <img
        src={
          companyDetail?.logo
            ? companyDetail?.logo
            : require("../../../../assets/img/closewise-white-logo.png").default
        }
        alt="Logo"
        className="projectLogo"
      />
      <div className="carouselContent">
        <PublicTextCarousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          goToIndex={goToIndex}
        />
      </div>
      <div className="loginDashboard">
        <PublicImageCarousel
          activeIndex={activeIndex}
          next={next}
          previous={previous}
          goToIndex={goToIndex}
        />
      </div>
    </>
  );
};

export default PublicSideCarousel;
