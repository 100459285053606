import React, { useState } from "react";
import { useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Spinner,
} from "reactstrap";
import { errorHandler } from "../../../helper-methods";
import { getClientSettings } from "../../../http/http-calls";
import ClientJobSettingTab from "../components/tabs/ClientJobSettingTab";
import ClientNotificationSettingTab from "../components/tabs/ClientNotificationSettingTab";
import EmailCustomization from "../components/emailCustomization";
const ClientSettings = () => {
  const [activeTab, setActiveTab] = useState("1");
  const [clientSetting, setClientSetting] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(false);

  const _toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const _getClientSettings = () => {
    setLoading(true);
    getClientSettings()
      .then((res) => {
        setClientSetting(res?.clientSetting || {});
        setLoading(false);
      })
      .catch((error) => {
        errorHandler(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    _getClientSettings();
  }, []);

  return (
    <div className="content">
      {/* <div className="pgTitleWrap">
        <h4>Settings {loading ? <Spinner /> : null}</h4>
      </div> */}

      <div className="responsiveTitle">
        <h2>Settings {loading ? <Spinner /> : null}</h2>
      </div>

      <div className="verticalTabs">
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === "1" ? "active" : ""}
              onClick={() => _toggleTab("1")}
            >
              Jobs
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "2" ? "active" : ""}
              onClick={() => _toggleTab("2")}
            >
              Notifications
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={activeTab === "3" ? "active" : ""}
              onClick={() => _toggleTab("3")}
            >
              Email Customization
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
            <ClientJobSettingTab
              clientSetting={clientSetting}
              onSuccess={_getClientSettings}
            />
          </TabPane>
          <TabPane tabId="2">
            <ClientNotificationSettingTab
              clientSetting={clientSetting}
              activeTab={activeTab}
            />
          </TabPane>
          <TabPane tabId="3">
            <EmailCustomization />
          </TabPane>
        </TabContent>
      </div>
    </div>
  );
};

export default ClientSettings;
