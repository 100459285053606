import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
  CustomInput,
} from "reactstrap";
import TextEditor from "../../TextEdittor";
import CreatableSelect from "react-select";
import ReactDatetime from "react-datetime";
import {
  errorHandler,
  formatDateAndTime,
  getFileExtension,
  showToast,
  sleepTime,
} from "../../../../../helper-methods";
import {
  createSocialMediaPost,
  getFacebookPagesList,
  saveFacebookPageId,
} from "../../../../../http/http-calls";
import { PostManager } from "../../../../../post-manager";
import { UploadQueueManager } from "../../../../../upload-queue-manager";
import { useEffect } from "react";

const initialValues = {
  topic: "",
  text: "",
  scheduleAt: "",
  postedOn: [],
  attatchments: [],
};

let initialIsDirty = {
  topic: false,
};

const PagesModal = ({ isOpen, data, toggle, onSuccess }) => {
  const [facebookPages, setFacebookPages] = useState([]);
  const [formFields, setFormFields] = useState(initialValues);
  const [isDirty, setIsDirty] = useState(initialIsDirty);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const _closeModal = () => {
    setFormFields(initialValues);
    toggle();
  };
  const _onChange = (key, value) => {
    const updatedObj = { ...formFields };
    const newIsDirty = { ...isDirty };

    updatedObj[key] = value;

    setFormFields(updatedObj);
    setIsDirty(newIsDirty);
    _validateForm(updatedObj, newIsDirty);
  };

  const _validateForm = (newFormFields, isDirty) => {
    return new Promise((resolve, reject) => {
      const error = { ...errors };
      let isFormValid = true;

      Object.keys(newFormFields).forEach((fieldName) => {
        switch (fieldName) {
          case "topic": {
            if (
              newFormFields[fieldName] &&
              newFormFields[fieldName].trim().length > 0
            ) {
              error[fieldName] = null;
              isDirty[fieldName] = false;
            } else {
              error[fieldName] = "Topic is Required";
              isFormValid = false;
            }
            break;
          }

          default: {
          }
        }
      });

      setIsDirty(isDirty);
      setErrors(error);
      resolve(isFormValid);
    });
  };

  // const _createPost = async (payload) => {
  //   setLoading(true);
  //   try {
  //     console.log("dfg", payload);
  //     await createSocialMediaPost(payload);
  //     showToast("Created Successfully", "success");
  //     setLoading(false);
  //     _closeModal();
  //   } catch (error) {
  //     showToast(
  //       error && error.reason && error.reason.length
  //         ? error.reason
  //         : "Server error. Try again after sometime.",
  //       "error"
  //     );
  //     setLoading(false);
  //   }
  // };

  // const _updatePost = (payload) => {};

  useEffect(() => {
    if (isOpen) {
      _getFacebookPagesList();
    }
  }, [isOpen]);

  const _getFacebookPagesList = async () => {
    setLoading(true);
    try {
      let facebookPages = await getFacebookPagesList();
      setFacebookPages(facebookPages?.pages?.data || []);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _handlePageSelect = async (pageData) => {
    setLoading(true);
    try {
      let payload = {
        pageId: pageData?.id,
        pageAccessToken: pageData?.access_token,
      };
      let response = await saveFacebookPageId(payload);
      if (!response.error) {
        onSuccess();
        _closeModal();
      }
      // setFacebookPages(facebookPages.pages.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} centered scrollable>
      <ModalHeader toggle={() => _closeModal()}>Select Page</ModalHeader>
      {console.log("fff", facebookPages)}
      <ModalBody>
        {facebookPages.length > 0 &&
          facebookPages.map((item, index) => (
            <ul
              key={index}
              value={item}
              className="bg-primary"
              onClick={() => _handlePageSelect(item)}
            >
              <li>{item?.name}</li>
            </ul>
          ))}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="lg" outline onClick={() => _closeModal()}>
          Cancel
        </Button>
        {/* <Button color="primary" size="lg" onClick={() => _editOrCreatePost()}>
          Post
        </Button> */}
      </ModalFooter>
    </Modal>
  );
};

export default PagesModal;
