import React from "react";
import {
  Card,
  Table,
  Row,
  Col,
  Input,
  Button,
  FormGroup,
  Spinner,
  Badge,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  CardBody,
  Label,
} from "reactstrap";
import {
  showToast,
  deepClone,
  formatDateAsPerTimeZoneWithYear,
  openUrlOnNewTab,
  structureQueryParams,
  getLoggedInUserId,
  getTimeZoneAbbr,
  isRegularUser,
  formatOnlyDateMoment,
  convertIsoToUtc,
  capitalize,
  formatCurrencyValue,
  errorHandler,
  getDropdownColor,
  refreshFunc,
} from "../../../helper-methods";
// import usaStates from "../../../config/usa_states_titlecase";
import { agentFetchAllStatusReport } from "../../../http/http-calls";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { agentClosingStatus, BASE_URL } from "../../../config";
import CustomPagination from "../components/CustomPagination";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomCard from "../components/CustomCard";
import SvgIcons from "../components/SvgIcons";

class StatusReportAgent extends React.Component {
  state = {
    closings: {},
    dateRangeValue: null,
    filters: {
      states: "",
      status: "",
    },
    staatusReportCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    activeTab: "Pending",
    loading: false,
    pageNumber: 1,
    isClassAdded: false,
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  _toggleTab = async (tab) => {
    const { activeTab } = deepClone(this.state);

    if (tab === activeTab) {
      return;
    }

    try {
      this.setState({ activeTab: tab, pageNumber: 1 });
    } catch (error) {
      errorHandler(error);
      this.setState({ loading: false, activeTab: "Pending", pageNumber: 1 });
    }
  };

  componentDidMount() {
    if (isRegularUser()) {
      this.setState({ loading: true });
      // this._getAllStatusReport();
      this._setFilterDataFromLocalStorage();
      HeaderEventEmitter.subscribe("reset-filter", () => {
        console.log("filter reset hit");
        this._resetFilter();
        refreshFunc("agent-reporting-status-report");
      });
    } else {
      this.props.history.goBack();
    }
  }

  _getAllStatusReport = async () => {
    try {
      this.setState({ loading: true });

      const { filters, dateRangeValue } = deepClone(this.state);

      const filterPayload = {};

      if (filters.states && filters.states.length)
        filterPayload["states"] = filters.states;

      if (filters.status && filters.status.trim().length)
        filterPayload["status"] =
          filters.status !== "" ? filters.status : undefined;

      if (dateRangeValue) {
        filterPayload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
        filterPayload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
      }

      let payload = {
        filters: filterPayload,
      };

      let response = await agentFetchAllStatusReport(payload);

      this.setState({ closings: response?.closings, loading: false });
    } catch (error) {
      this.setState({ loading: false });
      errorHandler(error);
    }
  };

  _downloadData = (action = "download") => {
    const { closings, filters, dateRangeValue } = this.state;

    if (!Object.keys(closings).length) {
      showToast(`No data for ${action}`, "error");
      return;
    }

    const payload = {
      userId: getLoggedInUserId(),
    };

    if (filters.states && filters.states.length)
      payload["states"] = filters.states;

    if (filters.status && filters.status.trim().length)
      payload["status"] = filters.status.trim();

    if (dateRangeValue) {
      payload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      payload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
    }

    const queryParams = structureQueryParams(payload);

    if (action === "download")
      openUrlOnNewTab(`${BASE_URL}/agent/download/status-report${queryParams}`);
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue, loading: true }, () => {
      this._getAllStatusReport();
      this._persistFilter();
    });
  };

  _filterOnChange = (type, value, isDelay = false) => {
    if (isDelay) clearTimeout(this.searchTimer);

    const { filters } = deepClone(this.state);
    filters[type] = value;

    this.setState({ filters }, () => {
      if (isDelay) {
        this.searchTimer = setTimeout(() => {
          this._getAllStatusReport();
          this._persistFilter();
        }, 1000);
      } else {
        this._getAllStatusReport();
        this._persistFilter();
      }
    });
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          states: "",
          status: "",
        },
        dateRangeValue: null,
      },
      () => {
        this._getAllStatusReport();
        refreshFunc("agent-reporting-status-report-responsive");
      }
    );
  };

  _tableFormatWithArray = (data) => {
    return (
      <>
        <div className="CustomTableWrapper hideMobile">
          <Table responsive striped>
            <thead>
              <tr>
                <th>Status</th>
                <th>Date</th>
                <th>Service</th>
                <th>Company</th>
                <th>Order</th>
                <th>Reference</th>
                <th>Agent Fee</th>
              </tr>
            </thead>
            <tbody>
              {data.length !== 0 &&
                data.map((item, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <Badge
                          className="outline"
                          color={`${getDropdownColor(item.status)}`}
                        >
                          {item.status}
                        </Badge>
                        {console.log("asfasdf " + item.status)}
                      </td>

                      <td>
                        <div style={{ width: 120 }}>
                          {formatDateAsPerTimeZoneWithYear(
                            item.appointmentDate,
                            item?.closingAddress?.timeZone
                          )}
                          {" | "}
                          {getTimeZoneAbbr(
                            item.appointmentDate,
                            item?.closingAddress?.timeZone
                          )}
                        </div>
                      </td>
                      <td>
                        <>
                          <div style={{ width: 150 }}>
                            <span
                              style={{
                                marginRight: 6,
                                display: "inline-block",
                              }}
                            >
                              {item.signingType}
                              {","}
                            </span>

                            {item.loanType && item.loanType.length > 0 ? (
                              item.loanType.indexOf("Other") > -1 ? (
                                <>
                                  <span
                                    style={{
                                      marginRight: 6,
                                      display: "inline-block",
                                    }}
                                  >
                                    {" "}
                                    {item?.loanTypeOther} {","}
                                  </span>
                                </>
                              ) : null
                            ) : null}
                            {item.loanType
                              .filter((each) => each !== "Other")
                              .map((item, index) => (
                                <span
                                  key={`loanType_${index}`}
                                  style={{
                                    marginRight: 6,
                                    display: "inline-block",
                                  }}
                                >
                                  {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                                  {","}
                                </span>
                              ))}
                          </div>
                        </>
                      </td>
                      <td>
                        <div style={{ width: 120 }}>
                          {item?._signingCompany?.companyName ||
                            item?._client?.name?.full ||
                            item?._assistant?.name?.full ||
                            "N/A"}
                        </div>
                      </td>
                      <td>
                        <div style={{ width: 120 }}>
                          {/* show the below span only when there are more than 1 signers  */}
                          {capitalize(item?._borrower[0]?.name?.full) || "N/A"}{" "}
                          {item?._borrower?.length > 1 && (
                            <>
                              <span
                                id={`PopoverLegacy${item._id}`}
                                className="themeColor cursorPointer"
                                style={{ fontWeight: "600" }}
                              >
                                +{item?._borrower?.length - 1}
                              </span>
                              <UncontrolledPopover
                                trigger="legacy"
                                placement="bottom"
                                target={`PopoverLegacy${item?._id}`}
                                className="signerPopover"
                              >
                                <PopoverHeader>Signers</PopoverHeader>
                                <PopoverBody>
                                  {item._borrower.map((item, itemIndex) => (
                                    <p key={`borrower_name_${itemIndex}`}>
                                      {capitalize(item?.name?.full)}
                                    </p>
                                  ))}
                                </PopoverBody>
                              </UncontrolledPopover>
                            </>
                          )}
                        </div>
                      </td>

                      <td>
                        {" "}
                        <div style={{ width: 120 }}>{item.fileNumber}</div>
                      </td>
                      <td>
                        <div style={{ width: 120 }}>
                          {item.agentFee ? (
                            <>{formatCurrencyValue(item.agentFee)}</>
                          ) : (
                            "N/A"
                          )}
                        </div>
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </>
    );
  };

  _calculateTotalFee = (array) => {
    let arr = array.map((item) =>
      item.signingCompanyFee !== undefined ? item.signingCompanyFee : 0
    );
    return arr.reduce((a, b) => a + b, 0);
  };

  _calculateAgentTotalFee = (array) => {
    let arr = array.map((item) =>
      item.agentFee !== undefined ? item.agentFee : 0
    );

    return arr.reduce((a, b) => a + b, 0);
  };

  _closingStatus = (array) => {
    let arrayClosingStatus = array.map((item) => item.status)[0];
    return (
      <div className="tableHeadStatusReport">
        <h6>{arrayClosingStatus}</h6>

        <h6>
          {arrayClosingStatus}: <span>{array.length}</span>
        </h6>
      </div>
    );
  };

  _persistFilter = () => {
    const { filters, dateRangeValue, pageNumber } = this.state;
    if (
      (filters && filters.status && filters.status !== "") ||
      dateRangeValue !== null ||
      pageNumber !== 1
    ) {
      let data = { filters, dateRangeValue, pageNumber };
      localStorage.agentstatusreport = JSON.stringify(data);
    } else {
      delete localStorage.agentstatusreport;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    // const { filters, tableConfig } = deepClone(this.state);

    if (localStorage && localStorage.agentstatusreport) {
      try {
        const filters = JSON.parse(localStorage.agentstatusreport);
        let dateRange = null;
        if (filters.dateRangeValue) {
          dateRange = [
            new Date(convertIsoToUtc(filters.dateRangeValue[0])),
            new Date(convertIsoToUtc(filters.dateRangeValue[1])),
          ];
        }
        this.setState(
          {
            filters: filters.filters,
            dateRangeValue: dateRange,
            pageNumber: filters?.pageNumber,
          },
          () => {
            this._getAllStatusReport();
          }
        );
      } catch (e) {
        this._getAllStatusReport();
      }
    } else {
      this._getAllStatusReport();
    }
  };

  // _cardHeaderFormat = (cell, row, header) => {
  //   console.log("23456", row, cell, header);
  //   switch (header) {
  //     case "name": {
  //       return row?.status ? (
  //         <div className="tableUserInfo">
  //           <Badge
  //             className="outline"
  //             color={`${getDropdownColor(row?.status)}`}
  //           >
  //             {row?.status}
  //           </Badge>
  //         </div>
  //       ) : null;
  //     }
  //     default: {
  //       return cell;
  //     }
  //   }
  // };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Date </Label>
            <div>
              {row?.appointmentDate
                ? formatDateAsPerTimeZoneWithYear(
                    row?.appointmentDate,
                    row?.closingAddress?.timeZone
                  ) +
                  " | " +
                  getTimeZoneAbbr(
                    row?.appointmentDate,
                    row?.closingAddress?.timeZone
                  )
                : "N/A"}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Service </Label>
            <div>
              <>
                <span
                  style={{
                    marginRight: 6,
                    display: "inline-block",
                  }}
                >
                  {row?.signingType}
                  {","}
                </span>

                {row?.loanType && row?.loanType.length > 0 ? (
                  row?.loanType.indexOf("Other") > -1 ? (
                    <>
                      <span
                        style={{
                          marginRight: 6,
                          display: "inline-block",
                        }}
                      >
                        {" "}
                        {row?.loanTypeOther} {","}
                      </span>
                    </>
                  ) : null
                ) : null}
                {row?.loanType
                  .filter((each) => each !== "Other")
                  .map((item, index) => (
                    <span
                      key={`loanType_${index}`}
                      style={{
                        marginRight: 6,
                        display: "inline-block",
                      }}
                    >
                      {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                      {","}
                    </span>
                  ))}
              </>
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Company </Label>
            <div>
              {row?._signingCompany?.companyName
                ? row?._signingCompany?.companyName
                : row?._assistant?.name?.full
                ? row?._client?.name?.full
                  ? row?._client?.name?.full
                  : row?._assistant?.name?.full
                : "N/A"}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Order </Label>
            <div>
              <>
                {row?._borrower[0]?.name?.full
                  ? capitalize(row?._borrower[0]?.name?.full)
                  : "N/A"}
                {row?._borrower?.length > 1 && (
                  <>
                    <span
                      id={`PopoverLegacy${row._id}`}
                      className="themeColor cursorPointer"
                      style={{ fontWeight: "600" }}
                    >
                      +{row?._borrower?.length - 1}
                    </span>
                    <UncontrolledPopover
                      trigger="legacy"
                      placement="bottom"
                      target={`PopoverLegacy${row?._id}`}
                      className="signerPopover"
                    >
                      <PopoverHeader>Signers</PopoverHeader>
                      <PopoverBody>
                        {row?._borrower.map((item, itemIndex) => (
                          <p key={`borrower_name_${itemIndex}`}>
                            {capitalize(item?.name?.full)}
                          </p>
                        ))}
                      </PopoverBody>
                    </UncontrolledPopover>
                  </>
                )}
              </>
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Reference </Label>
            <div>{row?.fileNumber ? row?.fileNumber : "N/A"}</div>
          </div>

          <div className="dataWrap">
            <Label>Agent Fee: </Label>
            <div>
              {row?.agentFee ? formatCurrencyValue(row?.agentFee) : "N/A"}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap d-flex align-items-center justify-content-between">
            <Label>Status</Label>
            <Badge
              className="outline"
              color={`${getDropdownColor(row?.status)}`}
            >
              {row?.status}
            </Badge>
          </div>
        </li>
      </ul>
    );
  };

  render() {
    const {
      dateRangeValue,
      filters,
      closings,
      loading,
      activeTab,
      pageNumber,
      isClassAdded,
      staatusReportCardHeaderKeys,
    } = this.state;
    console.log("lol", this.state);
    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>Status Report</h2>

            <div className="rightSide">
              <Button color="link" onClick={this._resetFilter}>
                <img
                  id="agent-reporting-status-report-responsive"
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                />
              </Button>

              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="Filter"
                />
              </Button>
            </div>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.handleButtonClick}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer responsiveFilter ${
              isClassAdded ? "show" : ""
            }`}
          >
            <div className="filterIcon">
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
              />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>
            <div className="filterWrapper">
              <FormGroup className="dateRange">
                <Label>Date Range</Label>
                <DateRangePicker
                  className="dateRange"
                  format="MM-dd-y"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  onChange={(dateRangeValue) =>
                    this._onChangeDatePicker(dateRangeValue)
                  }
                  value={dateRangeValue}
                />
              </FormGroup>
              {/* <FormGroup>
                <Label>Status</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    value={filters.status}
                    name="status"
                    onChange={(e) =>
                      this._filterOnChange("status", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {agentClosingStatus.map((each) => (
                      <option key={each.key} value={each.key}>
                        {each.label}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup> */}

              <Button
                color="link"
                outline
                className="downloadButton ml-auto"
                onClick={() => this._downloadData()}
              >
                Download
                <img
                  src={require(`../../../assets/img/download_blue.png`).default}
                  alt="download"
                />
              </Button>
            </div>
            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>

          <div className="downloadButtonPos">
            <Button color="link" outline onClick={() => this._downloadData()}>
              Download
              <img
                src={require(`../../../assets/img/download_blue.png`).default}
                alt="download"
              />
            </Button>
          </div>

          <Row>
            <Col md="12">
              <div className="verticalTabs">
                <Nav tabs>
                  {agentClosingStatus.map((each) => (
                    <NavItem key={`NavItem_${each.value}`}>
                      <NavLink
                        className={activeTab === each.value ? "active" : ""}
                        onClick={() => {
                          this._toggleTab(each.value);
                        }}
                      >
                        <img
                          src={
                            require(`../../../assets/img/${
                              activeTab === each.value
                                ? each.imageBlueName
                                : each.imageBlackName
                            }.png`).default
                          }
                          alt={each.label}
                        />
                        {each.label}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>

                <TabContent>
                  <TabPane>
                    <Row>
                      <Col xl={4} lg={6} xs={6}>
                        <Card className="infoCard">
                          <CardBody>
                            <div>
                              <span>{activeTab}</span>
                              <h4 className={`${getDropdownColor(activeTab)}`}>
                                {closings?.[activeTab]?.length ? (
                                  closings[activeTab].length
                                ) : loading ? (
                                  <Spinner />
                                ) : (
                                  "0"
                                )}
                              </h4>
                            </div>

                            <div className="icon">
                              <SvgIcons type={"invoicepending"} />
                              {/* <img
                                src={
                                  require("../../../assets/img/invoicepending.svg")
                                    .default
                                }
                                alt="notaryActs"
                              /> */}
                            </div>
                          </CardBody>
                        </Card>
                      </Col>
                      <Col xl={4} lg={6} xs={6}>
                        <Card className="infoCard">
                          <CardBody>
                            <div className="infoContent">
                              <span>Agent Fee</span>
                              <h4>
                                {closings?.[activeTab]?.length ? (
                                  formatCurrencyValue(
                                    this._calculateAgentTotalFee(
                                      closings[activeTab]
                                    )
                                  )
                                ) : loading ? (
                                  <Spinner />
                                ) : (
                                  formatCurrencyValue(0)
                                )}
                              </h4>
                            </div>
                            <div className="icon">
                              <SvgIcons type={"invoicePaid"} />
                            </div>
                          </CardBody>
                        </Card>
                      </Col>

                      <Col md={12}>
                        {closings?.[activeTab]?.length ? (
                          <>
                            {this._tableFormatWithArray(
                              closings[activeTab].slice(
                                (pageNumber - 1) * 5,
                                pageNumber * 5
                              )
                            )}

                            <div className="hideDesktop">
                              <CustomCard
                                isPageStartFromOne={true}
                                pageNumber={pageNumber}
                                tableData={closings[activeTab].slice(
                                  (pageNumber - 1) * 5,
                                  pageNumber * 5 + 5
                                )}
                                // tableData={closings[activeTab].slice(
                                //   pageNumber,
                                //   pageNumber + 5
                                // )}
                                isPagination={false}
                                // headerKeys={staatusReportCardHeaderKeys}
                                // bodyKeys={agentClosingCardBodyKeys}
                                dataFormat={this._dataFormat}
                                totalCount={closings[activeTab]?.length}
                                onPaginate={(pageNumber, pageSize) =>
                                  this._paginate(pageNumber, pageSize)
                                }
                                rowSelection={false}
                                // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
                                showTableLoading={loading.showTableLoading}
                                cardHeaderFormat={this._cardHeaderFormat}
                                cardDataFormat={this._cardDataFormat}
                              />
                            </div>

                            <CustomPagination
                              // data={closings[activeTab].slice(
                              //   pageNumber * 5,
                              //   pageNumber * 5 + 5
                              // )}
                              data={closings[activeTab].slice(
                                pageNumber,
                                pageNumber + 5
                              )}
                              dataCount={closings[activeTab]?.length}
                              pageNumber={pageNumber}
                              pageSize={5}
                              onPageChange={(pageNumber) =>
                                this.setState({ pageNumber }, () => {
                                  this._persistFilter();
                                })
                              }
                              isPageStartFromOne={true}
                              isHideForLessData={true}
                            />
                          </>
                        ) : (
                          <Card>
                            <div className="nodata">
                              {loading ? (
                                <Spinner />
                              ) : (
                                <>
                                  <img
                                    src={
                                      require("../../../assets/img/noData.svg")
                                        .default
                                    }
                                    alt="no data"
                                  />
                                  <p>There is no data to display</p>
                                </>
                              )}
                            </div>
                          </Card>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default StatusReportAgent;
