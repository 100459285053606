import React, { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { errorHandler, showToast } from "../../../../helper-methods";
import { createAgentInvitationByLoginAgent } from "../../../../http/http-calls";

const WebsiteInviteAgentModal = ({ isOpen, data, toggle }) => {
  const [loading, setLoading] = useState(false);

  const _closeModal = () => {
    toggle();
  };

  const _inviteAgent = async () => {
    try {
      setLoading(true);
      const payload = {
        email: data?.email,
      };

      await createAgentInvitationByLoginAgent(payload);
      showToast("Invitation sent successfully", "success");
      setLoading(false);
      _closeModal();
    } catch (err) {
      errorHandler(err);
      setLoading(false);
    }
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => _closeModal()}
        size="lg"
        centered
        scrollable
        className="checkOutModal"
      >
        <ModalHeader toggle={_closeModal}>Invite Agent</ModalHeader>
        <ModalBody>
          <div className="text-center p-4">
            <p>
              The user with the email{" "}
              <span style={{ fontWeight: 500 }}>"{data?.email}"</span> is not
              registered on the Closewise platform.
            </p>
            <p>Do you want to invite the user?</p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={_closeModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={_inviteAgent} disabled={loading}>
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
            Invite
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default WebsiteInviteAgentModal;
