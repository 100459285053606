import React from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Label,
  FormGroup,
  Badge,
  Card,
  CardBody,
  UncontrolledTooltip,
} from "reactstrap";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {
  showToast,
  deepClone,
  capitalize,
  getLoggedInUserId,
  structureQueryParams,
  openUrlOnNewTab,
  formatCurrencyValue,
  getTimeZoneAbbr,
  formatDateAsPerTimeZOne,
  formatDateAndTimeForNotificationPage,
  convertIsoToUtc,
  refreshFunc,
  formatOnlyDateMoment,
  deleteLocalStorageData,
  errorHandler,
} from "../../../helper-methods";

import {
  signingCompanyFetchAllInvoices,
  updateSigningcompanyInvoiceStatus,
  // getAllFiltersData,
  updateSigningcompanyAgentInvoice,
  updateCompanyInvoiceForRecordPayment,
  getClientListForCompanyInvoice,
  companyListDownloadInvoiceClient,
  companyGetListTeamsOfClient,
} from "../../../http/http-calls";
import JobsIncludedModal from "../components/jobs-included-invoicing-agent-modal";
import CreateInvoiceModal from "../components/create-invoice-company-modal";
import { connect } from "react-redux";
import { updateUserObj } from "../../../redux/actions/user-data";
import { BASE_URL } from "../../../config";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomTable from "../components/CustomTable";
import CustomCard from "../components/CustomCard";

class InvoicingCompany extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      kyc: {
        paymentEnabled: true,
        payoutEnabled: true,
        lastKycUpdatedAt: null,
      },
      paidAmount: 0,
      pendingAmount: 0,
      companyInvoiceList: [],
      companyInvoiceTotalCount: 0,
      companyInvoiceHeaderKeys: [
        { id: "id", label: "id", noSort: true },
        { id: "client", label: "Client", noSort: true },
        { id: "createdAt", label: "Date Created", noSort: true },
        { id: "paymentDate", label: "payment Date", noSort: true },
        { id: "_closing", label: "Jobs", noSort: true },
        {
          id: "amount",
          label: "Amount",
          noSort: true,
          tooltip:
            "The amount displayed is the pending amount at the time of invoice creation.",
        },
        { id: "status", label: "Status", noSort: true },
        {
          id: "action",
          label: "Action",
          noSort: true,
          tooltip: "The amounts displayed in the PDF reflect real-time data.",
        },
      ],
      companyCardInvoiceHeaderKeys: [
        { id: "id", label: "id" },
        { id: "name", label: "Name" },
      ],
      filterDropdownValues: {
        clients: [],
        downloadClients: [],
        clientTeamsList: [],
      },
      filters: {
        client: "",
        clientTeam: "",
        status: "",
        search: "",
      },
      jobsIncludedModal: {
        isOpen: false,
        data: null,
      },
      dateRangeValue: null,
      loading: {
        showDataLoading: false,
        markPaidLoading: false,
      },
      tableConfig: {
        skip: 0,
        limit: 10,
        pageNumber: 1,
      },
      selectedRows: [],
      amount: "",
      incomeObj: [],
      referenceNumber: "",
      checkDate: "",
      createInvoiceModal: {
        isOpen: false,
        data: null,
      },
      isClassAdded: false,
    };
    this.tableRef = React.createRef();
  }
  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => {
      this._paginate();
      this._persistFilter();
    });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  _filterOnChange = (type, value) => {
    const { filters, filterDropdownValues } = this.state;
    filters[type] = value;

    if (type === "client") {
      filters["clientTeam"] = "";
      if (value?.length) {
        this._getCompanyListTeamsOfClient(value);
      } else {
        filterDropdownValues["clientTeamsList"] = [];
      }
    }

    this.setState({ filters, filterDropdownValues }, () => {
      this._paginate();
      this._persistFilter();
    });
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          client: "",
          clientTeam: "",
          status: "",
          search: "",
        },
        dateRangeValue: null,
      },
      () => {
        // this._getAllInvoice();
        this._paginate();
        refreshFunc("company-accounting-invoicing-responsive");
        deleteLocalStorageData("companyinvoice");
      }
    );
  };

  componentDidMount() {
    // this._getAllInvoice();
    this._getClientListForCompanyInvoice();
    this._setFilterDataFromLocalStorage();
    this._companyListDownloadInvoiceClient();
    HeaderEventEmitter.subscribe("reset-filter", () => {
      console.log("filter reset hit");
      this._resetFilter();
      refreshFunc("company-accounting-invoicing");
    });
    HeaderEventEmitter.subscribe("add-invoice", () => {
      console.log("filter reset hit");
      this._onToggleCreateInvoiceModal(true);
    });
  }

  // _getAllFiltersData = () => {
  //   getAllFiltersData()
  //     .then((res) => {
  //       const { filterDropdownValues } = deepClone(this.state);
  //       filterDropdownValues["clients"] = res.clients;
  //       this.setState({ filterDropdownValues });
  //     })
  //     .catch((error) => {
  //       showToast(
  //         error && error.reason && error.reason.length
  //           ? error.reason
  //           : "Server error. Try again after sometime.",
  //         "error"
  //       );
  //     });
  // };

  _companyListDownloadInvoiceClient = () => {
    companyListDownloadInvoiceClient()
      .then((res) => {
        const { filterDropdownValues } = deepClone(this.state);
        filterDropdownValues["downloadClients"] = res?.list || [];
        this.setState({ filterDropdownValues });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getClientListForCompanyInvoice = () => {
    getClientListForCompanyInvoice()
      .then((res) => {
        const { filterDropdownValues } = deepClone(this.state);
        filterDropdownValues["clients"] = res?.list || [];
        this.setState({ filterDropdownValues });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getAllInvoice = () => {
    this._manageLoading("showDataLoading", true);

    const { tableConfig, filters, dateRangeValue } = deepClone(this.state);

    const payload = {
      ...tableConfig,
      filters: {},
    };

    if (dateRangeValue && dateRangeValue.length) {
      payload.filters["startDate"] = dateRangeValue[0];
      payload.filters["endDate"] = dateRangeValue[1];
    }

    if (filters.client) payload.filters["clientId"] = filters.client;
    if (filters?.client && filters?.clientTeam)
      payload.filters["clientAssistantTeam"] = filters?.clientTeam;

    if (filters.status)
      payload.filters["status"] =
        filters.status !== "" ? filters.status : undefined;

    signingCompanyFetchAllInvoices(payload)
      .then((res) => {
        this.setState(
          {
            kyc: res?.kyc,
            paidAmount: res?.paidAmount,
            pendingAmount: res?.pendingAmount,
            companyInvoiceList: res?.invoices,
            companyInvoiceTotalCount: res?.totalCount,
          },
          () => {
            this._manageLoading("showDataLoading", false);
          }
        );
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showDataLoading", false);
      });
  };

  _UpdateInvoice = (row, data) => {
    this._manageLoading("showDataLoading", true);

    let payload = {
      status: data,
    };

    if (row?._client) {
      updateSigningcompanyInvoiceStatus(row._id, payload)
        .then((res) => {
          this._getAllInvoice();

          this._manageLoading("showDataLoading", false);
        })
        .catch((error) => {
          errorHandler(error);
          this._manageLoading("showDataLoading", false);
        });
    } else {
      updateSigningcompanyAgentInvoice(row._id, payload)
        .then((res) => {
          this._getAllInvoice();

          this._manageLoading("showDataLoading", false);
        })
        .catch((error) => {
          errorHandler(error);
          this._manageLoading("showDataLoading", false);
        });
    }
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _setSelectedRows = (selectedRows = []) => {
    let obj = selectedRows.map((each) =>
      this.state.companyInvoiceList.find((item) => item.id === each)
    );
    // console.log("obj", obj);
    let isObjectPaymentDue = obj.find(
      (each) => each.duePayment === 0 || each.duePayment === undefined
    );
    // console.log("isObjectPaymentDue", isObjectPaymentDue);
    if (isObjectPaymentDue)
      this.tableRef.current.onDeRowSelect(isObjectPaymentDue);

    this.setState({
      selectedRows,
      incomeObj: obj,
    });
  };

  _calTotalCheckAmount = () => {
    const { incomeObj } = this.state;

    return incomeObj?.length
      ? incomeObj.reduce((acc, each) => {
          acc = (+acc || 0) + (+each?.paymentAmount || 0);
          return acc;
        }, 0)
      : null;
  };

  _onFieldChange = (fieldName, value) => {
    this.setState({
      [fieldName]: value,
    });
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "client": {
        return row?._client?.companyName
          ? `${capitalize(row?._client?.companyName)}${
              row?._clientAssistantTeam?.teamName
                ? `-${capitalize(row?._clientAssistantTeam?.teamName)}`
                : ""
            }`
          : "N/A";
      }

      case "paymentDate": {
        return cell ? (
          <>
            {formatDateAsPerTimeZOne(
              cell,
              row?.closings[0]?._closing?.closingAddress?.timeZone
            )}{" "}
            {"|"}
            <span className="mr-2">
              {getTimeZoneAbbr(
                cell,
                row?.closings[0]?._closing?.closingAddress?.timeZone
              )}
            </span>
          </>
        ) : (
          "N/A"
        );
      }

      case "createdAt": {
        return cell ? formatDateAndTimeForNotificationPage(cell) : "N/A";
      }

      case "fileNumber": {
        return row?.closings[0]?._closing?.fileNumber
          ? row?.closings[0]?._closing?.fileNumber
          : "N/A";
      }

      case "_closing": {
        return cell && cell.length ? (
          <>
            <span
              className="text-primary cursorPointer"
              onClick={() => this._onToggleJobsIncludedModal(true, row)}
            >
              {cell.length}
            </span>
          </>
        ) : (
          "0"
        );
      }

      case "status": {
        if (cell) {
          if (row?._signingCompany || cell.toLowerCase() === "paid") {
            if (cell.toLowerCase() === "pending") {
              return (
                <Badge color="warning" className="outline" pill>
                  {cell}
                </Badge>
              );
            } else if (cell.toLowerCase() === "paid") {
              return (
                <Badge color="success" className="outline" pill>
                  {cell}
                </Badge>
              );
            } else if (cell.toLowerCase() === "cancelled") {
              return (
                <Badge color="danger" className="outline" pill>
                  {cell}
                </Badge>
              );
            } else {
              return capitalize(cell);
            }
          } else {
            return cell === "Pending" ? (
              <Badge color="warning" className="outline" pill>
                {cell}
              </Badge>
            ) : (
              <Badge color="success" className="outline" pill>
                {cell}
              </Badge>
            );
          }
        } else {
          return "N/A";
        }
      }

      case "action": {
        return (
          <Button
            color="link"
            outline
            onClick={() => this._downloadInvoiceData(row.id)}
          >
            Download
            <img
              src={require("../../../assets/img/download_blue.png").default}
              alt="download"
            />
          </Button>
        );
      }

      case "amount": {
        return cell || cell === 0 ? "$" + cell : "N/A";
      }

      case "orderid": {
        return cell ? cell : "N/A";
      }

      default: {
        return cell;
      }
    }
  };

  _downloadInvoiceData = (invoiceId) => {
    // console.log(invoiceId)
    this._manageLoading("showTableLoading", true);
    openUrlOnNewTab(
      `${BASE_URL}/signingcompany/download/invoice/${invoiceId}?userId=${getLoggedInUserId()}`
    );
    this._manageLoading("showTableLoading", false);
  };

  _onChangeFormField = (row, value) => {
    const { incomeObj } = deepClone(this.state);

    this.state.incomeObj.forEach((item) => {
      // if (item.id === row.id) {
      if (value <= row.duePayment) {
        const objIndex = incomeObj.findIndex((obj) => obj.id === row.id);
        const updatedObj = {
          ...incomeObj[objIndex],
          paymentAmount: Number(value),
        };
        const updatedProjects = [
          ...incomeObj.slice(0, objIndex),
          updatedObj,
          ...incomeObj.slice(objIndex + 1),
        ];
        this.setState({
          incomeObj: updatedProjects,
        });
        // }
        var regex = /^\d+(\.\d{0,2})?$/g;
        if (!regex.test(value)) {
          showToast("Invalid Input", "error");
        }
      } else {
        showToast("Entered Value is greater than Due Payment", "error");
      }
      // }
    });
  };

  _onToggleCreateInvoiceModal = (isOpen = false, data = null) => {
    this.setState({
      createInvoiceModal: {
        isOpen,
        data,
      },
    });
  };

  _onToggleJobsIncludedModal = (isOpen = false, data = null) => {
    this.setState({
      jobsIncludedModal: {
        isOpen,
        data,
      },
    });
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllInvoice();
    });
  };

  _onSortChange = (sortName, sortOrder) => {
    this._paginate();
  };

  _downloadData = (action = "download") => {
    const { companyInvoiceList, dateRangeValue, filters } = deepClone(
      this.state
    );

    if (!companyInvoiceList.length) {
      showToast(`No data for ${action}`, "error");
      return;
    }

    const payload = {
      userId: getLoggedInUserId(),
      action,
    };

    if (filters.client) payload["clientId"] = filters.client;
    else {
      showToast("Please select client from filter", "error");
      return;
    }
    if (filters.status) payload["status"] = filters.status;

    if (dateRangeValue && dateRangeValue.length) {
      // payload["startDate"] = dateRangeValue[0];
      // payload["endDate"] = dateRangeValue[1];
      payload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      payload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
    }

    const queryParams = structureQueryParams(payload);

    const apiUrl = `${BASE_URL}/signingcompany/mail/invoices${queryParams}`;

    if (action === "download") {
      openUrlOnNewTab(apiUrl);
    }
  };

  _updateIncomeForRecordPayment = () => {
    const { incomeObj, referenceNumber, checkDate, selectedRows } = this.state;

    let commanArray = selectedRows.reduce((arr, item1) => {
      const match = incomeObj.find((item2) => item1 === item2.id);
      match && arr.push(match);
      return arr;
    }, []);

    let invoiceList = commanArray.map((item) => ({
      id: item.id,
      paymentAmount: item.paymentAmount,
    }));

    let noPaymentAmount = invoiceList.find(
      (item) => item.paymentAmount === undefined
    );

    if (noPaymentAmount) {
      showToast("Payment Amount Should Not be Empty", "error");
    } else {
      if (incomeObj?.length && checkDate && referenceNumber) {
        this._manageLoading("markPaidLoading", true);

        const payload = {
          invoiceList,
          checkDate,
          referenceNumber,
        };

        const totalCheckAmount = this._calTotalCheckAmount();

        if (totalCheckAmount) payload["totalCheckAmount"] = totalCheckAmount;

        updateCompanyInvoiceForRecordPayment(payload)
          .then((res) => {
            this.setState({ checkDate: "", referenceNumber: "" });
            this.tableRef.current.onDeSelectAll();
            this._getAllInvoice();
            this._manageLoading("markPaidLoading", false);
            showToast("Recorded Successfully", "success");
          })
          .catch((error) => {
            this._setDateRangeValue();
            errorHandler(error);
            this._manageLoading("markPaidLoading", false);
          });
      }
    }
  };

  _persistFilter = () => {
    const { filters, dateRangeValue, tableConfig } = this.state;
    if (
      (filters &&
        ((filters.search && filters.search.length) ||
          filters.client ||
          filters.status)) ||
      dateRangeValue !== null ||
      tableConfig.pageNumber !== 1
    ) {
      let data = { filters, dateRangeValue, tableConfig };
      localStorage.companyinvoice = JSON.stringify(data);
    } else {
      delete localStorage.companyinvoice;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    // const { filters, tableConfig } = deepClone(this.state);
    if (localStorage && localStorage.companyinvoice) {
      try {
        const filters = JSON.parse(localStorage.companyinvoice);

        let dateRange = null;
        if (filters.dateRangeValue !== null) {
          dateRange = [
            new Date(convertIsoToUtc(filters.dateRangeValue[0])),
            new Date(convertIsoToUtc(filters.dateRangeValue[1])),
          ];
          // this.setState(
          //   { dateRangeValue: dateRange, tableConfig: filters?.tableConfig },
          //   () => {
          //     this._getAllInvoice();
          //   }
          // );
        }
        this.setState(
          {
            filters: filters?.filters,
            dateRangeValue: dateRange,
            tableConfig: filters?.tableConfig,
          },
          () => {
            this._getAllInvoice();

            if (this.state.filters?.client) {
              this._getCompanyListTeamsOfClient(this.state.filters?.client);
            }
          }
        );
        // this.setState(
        //   {
        //     filters: filters.filters,
        //   },
        //   () => {
        //     this._getAllInvoice();
        //   }
        // );
      } catch (e) {
        // this._companyClosingMasterSchedule();
        this._getAllInvoice();
      }
    } else {
      // this._companyClosingMasterSchedule();
      this._getAllInvoice();
    }
  };

  _getCompanyListTeamsOfClient = async (clientId) => {
    try {
      const res = await companyGetListTeamsOfClient(clientId);
      const { filterDropdownValues } = deepClone(this.state);

      filterDropdownValues["clientTeamsList"] = res?.teams;

      this.setState({ filterDropdownValues });
    } catch (err) {
      errorHandler(err);
    }
  };

  _prepareFilterData = () => {
    const { filters } = deepClone(this.state);

    if (!filters.client) delete filters.client;
    if (!filters.status) delete filters.status;
    if (!filters.search?.trim()?.length) delete filters.search;

    return filters;
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Client </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?._client?.companyName
                ? `${capitalize(row?._client?.companyName)}${
                    row?._clientAssistantTeam?.teamName
                      ? `-${capitalize(row?._clientAssistantTeam?.teamName)}`
                      : ""
                  }`
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Date Created </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.createdAt
                ? formatDateAndTimeForNotificationPage(row?.createdAt)
                : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Payment Date </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.paymentDate ? (
                <>
                  {formatDateAsPerTimeZOne(
                    row?.paymentDate,
                    row?.closings[0]?._closing?.closingAddress?.timeZone
                  )}{" "}
                  {"|"}
                  <span className="mr-2">
                    {getTimeZoneAbbr(
                      row?.paymentDate,
                      row?.closings[0]?._closing?.closingAddress?.timeZone
                    )}
                  </span>
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Jobs </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?._closing && row?._closing.length ? (
                <>
                  <span
                    className="text-primary cursorPointer"
                    onClick={() => this._onToggleJobsIncludedModal(true, row)}
                  >
                    {row?._closing.length}
                  </span>
                </>
              ) : (
                "0"
              )}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Amount </Label>
            <i
              id={`amountTooltip_${row?._id}`}
              className="fa fa-info-circle ml-2"
              style={{
                fontSize: "14px",
                color: "blue",
                cursor: "pointer",
              }}
            />
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.amount || row?.amount === 0 ? "$" + row?.amount : "N/A"}
              <UncontrolledTooltip
                placement="bottom"
                target={`amountTooltip_${row?._id}`}
              >
                The amount displayed is the pending amount at the time of
                invoice creation.
              </UncontrolledTooltip>
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap d-flex align-items-center justify-content-between">
            <Label>Status </Label>

            {row?.status ? (
              row?._signingCompany || row?.status?.toLowerCase() === "paid" ? (
                row?.status?.toLowerCase() === "pending" ? (
                  <Badge color="warning" className="outline" pill>
                    {row?.status}
                  </Badge>
                ) : row?.status?.toLowerCase() === "paid" ? (
                  <Badge color="success" className="outline" pill>
                    {row?.status}
                  </Badge>
                ) : row?.status?.toLowerCase() === "cancelled" ? (
                  <Badge color="danger" className="outline" pill>
                    {row?.status}
                  </Badge>
                ) : (
                  capitalize(row?.status)
                )
              ) : row?.status === "Pending" ? (
                <Badge color="warning" className="outline" pill>
                  {row?.status}
                </Badge>
              ) : (
                <Badge color="success" className="outline" pill>
                  {row?.status}
                </Badge>
              )
            ) : (
              "N/A"
            )}
          </div>
        </li>

        <li>
          <div className="dataWrap d-flex justify-content-center">
            <Button
              id={`downloadTooltip_${row?._id}`}
              color="link"
              outline
              onClick={() => this._downloadInvoiceData(row.id)}
            >
              Download
              <img
                src={require("../../../assets/img/download_blue.png").default}
                alt="download"
              />
              <UncontrolledTooltip
                placement="bottom"
                target={`downloadTooltip_${row?._id}`}
              >
                The amounts displayed in the PDF reflect real-time data.
              </UncontrolledTooltip>
            </Button>
          </div>
        </li>
      </ul>
    );
  };

  render() {
    const {
      // kyc,
      dateRangeValue,
      filters,
      filterDropdownValues,
      createInvoiceModal,
      loading,
      companyInvoiceList,
      companyInvoiceTotalCount,
      tableConfig,
      companyInvoiceHeaderKeys,
      selectedRows,
      jobsIncludedModal,
      paidAmount,
      pendingAmount,
      isClassAdded,
      // companyCardInvoiceHeaderKeys,
    } = deepClone(this.state);
    // const {kyc}=this.props?.userData?.user
    // console.log("78", this.state);
    return (
      <div className="content">
        <div className="responsiveTitle">
          <h2>Invoicing</h2>

          <div className="rightSide">
            <Button
              className="floatingButton"
              color="primary"
              // onClick={() => this._onToggleAddIncomeModalCompany(true)}
              onClick={() => this._onToggleCreateInvoiceModal(true)}
            >
              New Invoice
            </Button>

            <Button color="link" onClick={this._resetFilter}>
              <img
                id="company-accounting-invoicing-responsive"
                src={require("../../../assets/img/refresh.svg").default}
                alt="refresh"
                height={14}
              />
            </Button>
            <Button
              className="filterBarIcon"
              color="link"
              onClick={this.filterShowMobile}
            >
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="Filter"
                height={14}
              />
            </Button>
          </div>
        </div>

        {/* filter for tablet and web */}
        <div
          onClick={this.filterShowMobile}
          className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
        ></div>
        <div
          className={`filterContainer  responsiveFilter ${
            isClassAdded ? "show" : ""
          }`}
        >
          <div className="filterIcon">
            <img
              src={require("../../../assets/img/filter_icon.svg").default}
              alt="filter icon"
            />
            Filter by
          </div>
          <div className="mobileTitle">
            <h2>Filter by</h2>
            <Button
              color="link"
              className="closeButton"
              onClick={this.filterShowMobile}
            >
              <img
                src={require("../../../assets/img/close_grey.svg").default}
                alt="close"
                height={12}
              />
            </Button>
          </div>
          <div className="filterWrapper">
            <FormGroup className="dateRange">
              <Label>Date Range</Label>
              <DateRangePicker
                className="dateRange"
                format="MM-dd-y"
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                onChange={(dateRangeValue) =>
                  this._onChangeDatePicker(dateRangeValue)
                }
                value={dateRangeValue}
              />
            </FormGroup>
            <FormGroup>
              <Label>Client</Label>
              <div className="custom-select-wrapper">
                <Input
                  type="select"
                  name="client"
                  value={filters.client}
                  onChange={(e) =>
                    this._filterOnChange("client", e.target.value)
                  }
                >
                  <option value="">All</option>
                  {filterDropdownValues.downloadClients.map((client) => (
                    <option key={client.id} value={client.id}>
                      {/* {(client.name &&
                        client.name.full &&
                        capitalize(client.name.full)) ||
                        "N/A Company"} */}
                      {(client?.companyName
                        ? capitalize(client.companyName)
                        : capitalize(client.name.full)) || "N/A Client"}
                    </option>
                  ))}
                </Input>
              </div>
            </FormGroup>
            <FormGroup style={{ minWidth: "150px" }}>
              <Label>Client Teams</Label>
              <div
                className={`custom-select-wrapper ${
                  filters?.client === "" ? "disabled" : ""
                }`}
              >
                <Input
                  type="select"
                  placeholder=" "
                  value={filters?.clientTeam}
                  onChange={(event) =>
                    this._filterOnChange("clientTeam", event.target.value)
                  }
                  disabled={filters?.client === ""}
                  name="clientTeamsId"
                >
                  <option value="">All Teams</option>
                  {filterDropdownValues?.clientTeamsList?.map((team) => (
                    <option key={team?._id} value={team?._id}>
                      {team?.teamName ? capitalize(team?.teamName) : ""}
                    </option>
                  ))}
                </Input>
              </div>
            </FormGroup>

            <FormGroup>
              <Label>Status</Label>
              <div className="custom-select-wrapper">
                <Input
                  type="select"
                  name="status"
                  value={filters.status}
                  onChange={(e) =>
                    this._filterOnChange("status", e.target.value)
                  }
                >
                  <option value="">All</option>
                  <option value="Pending">Pending</option>
                  <option value="Paid">Paid</option>
                </Input>
              </div>
            </FormGroup>
            <Button
              color="link"
              outline
              className="downloadButton ml-lg-auto mt-3"
              onClick={() => this._downloadData()}
            >
              Download All
              <img
                src={require("../../../assets/img/download_blue.png").default}
                alt="download"
              />
            </Button>
          </div>
          <div className="clearButton">
            <Button size="md" color="primary" onClick={this.filterShowMobile}>
              Close
            </Button>
          </div>
        </div>

        <div className="downloadButtonPos">
          <Button color="link" outline onClick={() => this._downloadData()}>
            Download All
            <img
              src={require(`../../../assets/img/download_blue.png`).default}
              alt="download"
            />
          </Button>
        </div>

        {/* {kyc && !kyc.paymentEnabled && !kyc.payoutEnabled ? (
          // kyc.lastKycUpdatedAt ? (
          //   moment(kyc.lastKycUpdatedAt).add(30, "minutes")._d < new Date() ? (
          //     <KYCAlertCard onSuccess={this._paginate} />
          //   ) : null
          // ) : (
            <KYCAlertCard onSuccess={this._paginate} />
          // )
        ) : null} */}

        <div
          className="d-flex align-items-center justify-content-between"
          style={{ marginBottom: 25 }}
        >
          {/* when the checkboxes in the below table are selected/checked then below text is to be shown */}
          {selectedRows.length ? (
            <div className="fs-16" style={{ color: "#767676" }}>
              <span className="font-weight-bold themeColor">
                {selectedRows.length}
              </span>{" "}
              {selectedRows.length > 1 ? "Records" : "Record"} are selected
            </div>
          ) : (
            <div />
          )}
          {/* <div>
                <Button
                  className="my-0 ml-1 themeBtn"
                  onClick={() => this._onToggleCreateInvoiceModal(true)}
                >
                  Invoice
                </Button>
              </div> */}
        </div>
        {paidAmount || pendingAmount ? (
          <>
            <Row>
              <Col xl={4} xs={6}>
                <Card className="infoCard">
                  <CardBody>
                    <div className="">
                      <span>Paid</span>
                      <h4>
                        {paidAmount ? formatCurrencyValue(paidAmount) : "$0"}
                      </h4>
                    </div>
                    <div className="icon">
                      <img
                        src={
                          require("../../../assets/img/invoicePaid.svg").default
                        }
                        alt="notaryActs"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={4} xs={6}>
                <Card className="infoCard">
                  <CardBody>
                    <div className="">
                      <span>Pending</span>
                      <h4 className="text-danger">
                        {pendingAmount
                          ? formatCurrencyValue(pendingAmount)
                          : "$0"}
                      </h4>
                    </div>
                    <div className="icon">
                      <img
                        src={
                          require("../../../assets/img/invoicepending.svg")
                            .default
                        }
                        alt="notaryActs"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        ) : null}

        <div className="hideDesktop">
          <CustomCard
            isPageStartFromOne={true}
            pageNumber={tableConfig.pageNumber}
            tableData={companyInvoiceList}
            // headerKeys={companyCardInvoiceHeaderKeys}
            // bodyKeys={agentClosingCardBodyKeys}
            dataFormat={this._dataFormat}
            totalCount={companyInvoiceTotalCount}
            onPaginate={(pageNumber, pageSize) =>
              this._paginate(pageNumber, pageSize)
            }
            rowSelection={false}
            // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
            showTableLoading={loading.showDataLoading}
            cardHeaderFormat={this._cardHeaderFormat}
            cardDataFormat={this._cardDataFormat}
          />
        </div>

        <div className="hideMobile">
          {companyInvoiceHeaderKeys && companyInvoiceHeaderKeys.length && (
            <CustomTable
              striped
              isPageStartFromOne={true}
              pageNumber={tableConfig.pageNumber}
              tableData={companyInvoiceList}
              headerKeys={companyInvoiceHeaderKeys}
              dataFormat={this._dataFormat}
              totalCount={companyInvoiceTotalCount}
              rowSelection={false}
              onPaginate={(pageNumber, pageSize) =>
                this._paginate(pageNumber, pageSize)
              }
              onSortChange={(sortName, sortOrder) =>
                this._onSortChange(sortName, sortOrder)
              }
              showTableLoading={loading.showDataLoading}
              // setSelectedRows={(data) => this._setSelectedRows(data)}
              ref={this.tableRef}
            />
          )}
        </div>

        <JobsIncludedModal
          isOpen={jobsIncludedModal.isOpen}
          data={jobsIncludedModal.data}
          toggle={() => this._onToggleJobsIncludedModal()}
          userData={this.props.userData}
        />

        <CreateInvoiceModal
          isOpen={createInvoiceModal.isOpen}
          creatorData={createInvoiceModal.data}
          clients={filterDropdownValues.clients}
          toggle={() => this._onToggleCreateInvoiceModal()}
          resetDetails={() => this._paginate()}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserObj: (userData) => dispatch(updateUserObj(userData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicingCompany);

// export default InvoicingCompany;
