import React, { useState } from "react";
import {
  Card,
  CardBody,
  Button,
  CardHeader,
  CardTitle,
  CustomInput,
  Col,
  Row,
  Spinner,
} from "reactstrap";
import { errorHandler, showToast } from "../../../../helper-methods";
import { updateClientSettings } from "../../../../http/http-calls";
import AddInstructionsModal from "../add-instructions-modal";
import Swal from "sweetalert2";

let initialModalData = {
  isOpen: false,
  data: null,
};
const ClientJobSettingTab = ({ clientSetting, onSuccess }) => {
  const [isOpenAddInstruction, setIsOpenAddInstruction] =
    useState(initialModalData);
  const [loading, setLoading] = useState(false);

  const _handleOnEdit = (id) => {
    let getObjectToUpdate = clientSetting?.signingInstruction.filter(
      (item) => item._id === id
    );

    setIsOpenAddInstruction({
      isOpen: true,
      data: getObjectToUpdate,
    });
  };

  const _rawHtml = (htmlreceived) => {
    var rawMarkup = htmlreceived;
    return { __html: rawMarkup };
  };

  const _handleOnDelete = async (id) => {
    
    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this Instruction.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        
        try {
          if (clientSetting) {
            setLoading(true);
            // client
            let removedArray = clientSetting.signingInstruction.filter(
              (obj) => obj._id !== id
            );
            

            await updateClientSettings({
              signingInstruction: removedArray,
            });
            setLoading(false);
          } else {
            // company
            // await updateSigningCompanySetting({ signingInstruction });
          }

          showToast("Instruction has been updated", "success");
          onSuccess();
        } catch (error) {
          setLoading(false);
          errorHandler(error);
        }
      }
    });
  };

  const _handleOnDefault = async (id) => {
    
    try {
      if (clientSetting) {
        setLoading(true);

        // client
        let updatedArray = clientSetting.signingInstruction.map((obj) =>
          obj._id === id
            ? { ...obj, isDefault: true }
            : { ...obj, isDefault: false }
        );

        await updateClientSettings({
          signingInstruction: updatedArray,
        });
        setLoading(false);
      } else {
        // company
        // await updateSigningCompanySetting({ signingInstruction });
      }
      showToast("Instruction has been updated", "success");
      onSuccess();
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  return (
    <>
      <Card className="mb-2 instructionCard">
        <CardHeader>
          <div>
            <CardTitle> Signing Instruction {loading && <Spinner />}</CardTitle>
            <p>Instructions for Closing types &amp; Specific Clients</p>
          </div>
          <Button
            color="link"
            onClick={() =>
              setIsOpenAddInstruction({
                isOpen: true,
                data: null,
              })
            }
          >
            Add New Instruction
          </Button>
        </CardHeader>
        <CardBody>
          <Row>
            {clientSetting?.signingInstruction.length > 0 ? (
              clientSetting?.signingInstruction.map((each, index) => (
                <>
                  <Col xl={6} className="mb-3 mb-xl-0">
                    <div className="instructionWrap">
                      <div className="instructionHeader">
                        <CustomInput
                          type="radio"
                          name="job"
                          checked={each.isDefault}
                          label={each?.isDefault ? "Default" : "Make Default"}
                          id={`${each._id}-job`}
                          onClick={() => _handleOnDefault(each._id)}
                          disable={loading}
                        />
                        <div>
                          <Button
                            color="link"
                            onClick={() => _handleOnEdit(each._id)}
                          >
                            <img
                              src={
                                require("../../../../assets/img/pencil.svg")
                                  .default
                              }
                              alt="pencil"
                            />
                          </Button>
                          {/* delete button will hide when make default selected */}
                          {!each.isDefault && (
                            <Button
                              color="link"
                              onClick={() => _handleOnDelete(each._id)}
                              disable={loading}
                            >
                              <img
                                src={
                                  require("../../../../assets/img/deleteIcon.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button>
                          )}
                        </div>
                      </div>
                      <div className="instructionContent">
                        <span>{each.name}</span>
                        <div className="intruContent">
                          <p
                            dangerouslySetInnerHTML={_rawHtml(each?.description)}
                          ></p>
                        </div>
                      </div>
                    </div>
                  </Col>
                </>
              ))
            ) : (
              <p className="text-center">No Signing Instruction Found!</p>
            )}
          </Row>
        </CardBody>
      </Card>
      <p style={{ fontSize: 12, color: "grey" }}>
        *Set specific instructions sent to agents based on closing type and
        client. Once created they will auto generate in the order instructions
        sent to the agent assigned to the order.
      </p>
      <AddInstructionsModal
        isOpen={isOpenAddInstruction.isOpen}
        toggle={() => setIsOpenAddInstruction(false)}
        clientSettingData={isOpenAddInstruction.data}
        clientSetting={clientSetting}
        onSuccess={onSuccess}
      />
    </>
  );
};

export default ClientJobSettingTab;
