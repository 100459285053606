import React, { Component, createRef } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  CustomInput,
} from "reactstrap";
import {
  capitalize,
  deepClone,
  errorHandler,
  showToast,
} from "../../../helper-methods";
import {
  createChatLog,
  createNotarialLogForClosing,
  getClosingDetailsById,
  sendEmailOnStatusChange,
  updateNotarialLog,
} from "../../../http/http-calls";
import { RegexConfig } from "../../../config/RegexConfig";
import Chips from "react-chips";
import { agentClosingStatus } from "../../../config";
import RegularAppointmentScheduler from "./RegularAppointmentScheduler";
// import { Link } from "react-router-dom";
//for Agent
class SigningStatusModal extends Component {
  constructor(props) {
    super(props);
    this.regularSchedulerRef = createRef();
  }
  state = {
    formField: {
      agentNotes: "",
      shippingCompany: "",
      trackingInfo: "",
      agentDncFee: "0",
      selectReason: "",
      chips: [],
      notarialActs: "",
      amountPerAct: "",
    },
    agentFundsCollected: false,
    closingDetails: null,
    loading: false,
    notarialLog: null,
    appointmentSchedulerData: {
      appointmentDate: null,
      endAppointmentDate: null,
      tBD: false,
      isRangeDated: false,
    },
  };

  _resetModalState = () => {
    this.setState({
      formField: {
        agentNotes: "",
        shippingCompany: "",
        trackingInfo: "",
        agentDncFee: "0",
        selectReason: "",
        chips: [],
        notarialActs: "",
        amountPerAct: "",
      },
      agentFundsCollected: true,
      closingDetails: null,
      loading: false,
      notarialLog: null,
    });
  };

  _closeModal = () => {
    this._resetModalState();
    this.props.toggle();
  };

  _updateState = (closing) => {
    const { formField } = deepClone(this.state);

    formField["agentNotes"] = closing.agentNotes ? closing.agentNotes : "";
    formField["shippingCompany"] = closing.shippingCompany
      ? closing.shippingCompany
      : "";
    formField["trackingInfo"] = closing.trackingInfo
      ? closing.trackingInfo
      : "";
    if (
      this.props.status === "DNC" ||
      this.props.status === "Closed" ||
      this.props.status === "CCA"
    ) {
      // if (this.props.userData?.handle)
      if (!closing._signingCompany) {
        //     formField["chips"] = [this.props.userData.handle];
        formField["chips"] = [
          // closing?.personOfContact?.email,
          closing.otherRecipients,
        ]
          .flat()
          .filter((item) => item !== "" && item !== undefined);
      } else {
        formField["chips"] = [
          // closing?._signingCompany?.schedulingEmail
          //   ? closing?._signingCompany?.schedulingEmail
          //   : closing?._signingCompany?.email,
          closing.otherRecipients,
        ]
          .flat()
          .filter((item) => item !== "" && item !== undefined);
      }
    }
    this.setState({
      closingDetails: closing,
      loading: false,
      formField,
      appointmentSchedulerData: {
        appointmentDate: closing?.appointmentDate,
        endAppointmentDate: null,
        tBD: closing?.tBD,
        isRangeDated: closing?.isRangeDated,
      },
    });
  };

  _updateNotarialState = (notarialData) => {
    // console.log("1111", notarialData);
    const { formField } = deepClone(this.state);
    formField["notarialActs"] = notarialData.notarialActs
      ? notarialData.notarialActs.toString()
      : "";
    formField["amountPerAct"] = notarialData.amountPerAct
      ? notarialData.amountPerAct.toString()
      : "";
    this.setState({
      formField,
      notarialLog: notarialData,
    });
  };

  _getClosingDetailsById = (id) => {
    this.setState({ loading: true });

    getClosingDetailsById(id)
      .then((res) => {
        this._updateState(res?.closing);
        res?.notarialLog !== null &&
          this._updateNotarialState(res?.notarialLog);

        if (res?.notarialLog === null) {
          localStorage.amountPerAct && this._getAmountPerActFromLocalStorage();
        }
      })
      .catch((error) => {
        errorHandler(error);
        this._closeModal();
      });
  };

  // componentDidUpdate = (prevProps, prevState) => {
  //   const { isOpen, data } = this.props;
  //   if (isOpen && data && prevProps.isOpen !== isOpen) {
  //     this._getClosingDetailsById(data.id);
  //   }
  // };

  componentDidMount() {
    const { isOpen, data } = this.props;
    if (isOpen && data) {
      this._getClosingDetailsById(data.id);
    }
  }

  _getAmountPerActFromLocalStorage = () => {
    const { formField } = this.state;

    const amount = JSON.parse(localStorage.amountPerAct);
    formField["amountPerAct"] = amount;
    this.setState({ formField });
  };
  _onChangeFormField = (type, value) => {
    if (type === "agentDncFee") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      )
        return;
    }
    const { formField } = this.state;
    formField[type] = value;
    this.setState({ formField });
  };

  _createChatLog = async () => {
    const { closingDetails, formField } = deepClone(this.state);
    const { status } = this.props;
    // console.log({ closingDetails });

    try {
      let payload = {
        closingId: closingDetails.id,
        isVisibleAgent: true,
        isVisibleClient: false,
        // text: this.state.formField.agentNotes,
      };

      if (status === "DNC") {
        payload["text"] = `${
          closingDetails?._agent?.name?.full
        } Updated the status to DNC. 
       ${
         String(formField?.selectReason)?.length
           ? `Reason: ${formField?.selectReason}`
           : ""
       }
        ${
          this.state.formField.agentNotes
            ? `comments: ${this.state.formField.agentNotes}`
            : ""
        }`;
      } else {
        payload["text"] = this.state.formField.agentNotes;
      }
      await createChatLog(payload);
    } catch (error) {
      errorHandler(error);
    }
  };

  _updateClosingDetailsById = async (id, data) => {
    const { formField } = this.state;
    const { status } = this.props;
    try {
      this.setState({ loading: true });

      await this.props.updateClosingDetailsById(id, data);
      if (
        status === "DNC" &&
        (this.state.formField.agentNotes || formField?.selectReason)
      ) {
        await this._createChatLog();
      } else {
        if (this.state.formField.agentNotes) {
          await this._createChatLog();
        }
      }
      if (
        status === "Closed" &&
        formField.notarialActs.trim().length &&
        formField.amountPerAct.trim().length
      ) {
        if (this.state.notarialLog !== null) {
          await this._updateNotarialLog();
        } else {
          await this._addNotarialLog();
        }
      }
      showToast("Signing Status Updated", "success");
      this._sendEmailOnStatusChange();
      this.props.resetDetails();
      this._saveStatePerAct();
      this._closeModal();
    } catch (error) {
      errorHandler(error);
      this.setState({ loading: false });
    }
  };
  _saveStatePerAct = () => {
    const { formField } = this.state;

    // console.log(data);
    if (formField.amountPerAct.trim().length) {
      localStorage.amountPerAct = JSON.stringify(formField.amountPerAct);
    } else {
      delete localStorage.amountPerAct;
    }
  };
  _addNotarialLog = async () => {
    let { closingDetails, formField } = deepClone(this.state);

    let data = {
      notarialActs: Number(formField.notarialActs),
      amountPerAct: Number(formField.amountPerAct),
    };
    try {
      this.setState({ loading: true });
      await createNotarialLogForClosing(closingDetails.id, data);
      this.setState({ loading: false });
    } catch (error) {
      errorHandler(error);

      this.setState({ loading: false });
    }
  };

  _updateNotarialLog = async () => {
    let { closingDetails, formField } = deepClone(this.state);

    let data = {
      notarialActs: Number(formField.notarialActs),
      amountPerAct: Number(formField.amountPerAct),
    };
    try {
      this.setState({ loading: true });
      await updateNotarialLog(closingDetails.id, data);
      this.setState({ loading: false });
    } catch (error) {
      errorHandler(error);
      this.setState({ loading: false });
    }
  };

  _confirmStatus = async () => {
    const { closingDetails, formField } = deepClone(this.state);

    let payload = {};

    // status = "Closed" or "DNC"

    const { status } = this.props;

    if (status === "Closed") {
      if (closingDetails?.isRangeDated || closingDetails?.tBD) {
        const regularSchedulerResponse =
          await this.regularSchedulerRef.current.getAppointmentSchedulerData();
        if (!regularSchedulerResponse?.isValid) {
          return;
        }
        payload = {
          ...regularSchedulerResponse.appointmentSchedulerData,
          status: "Closed",
          signingStatus: true,
        };
      } else {
        payload = {
          status: "Closed",
          signingStatus: true,
        };
      }

      if (formField.shippingCompany && formField.shippingCompany.trim().length)
        payload["shippingCompany"] = formField.shippingCompany.trim();

      if (formField.trackingInfo && formField.trackingInfo.trim().length)
        payload["trackingInfo"] = formField.trackingInfo.trim();

      payload["agentNotes"] = formField.agentNotes?.trim().length
        ? formField.agentNotes.trim()
        : "";
      payload["agentFundsCollected"] = this.state.agentFundsCollected;
    } else if (status === "CCA") {
      payload["status"] = "CCA";
      // payload["agentCcaReason"] = formField.selectReason;

      if (formField.selectReason?.trim()) {
        payload["agentCcaReason"] = formField.selectReason;
      } else {
        showToast("Select a Reason", "error");
        return;
      }

      payload["agentCcaComment"] = formField.agentNotes?.trim().length
        ? formField.agentNotes.trim()
        : "";
    } else if (status === "Cancelled") {
      payload["status"] = "Cancelled";
    } else {
      if (closingDetails?.isRangeDated || closingDetails?.tBD) {
        const regularSchedulerResponse =
          await this.regularSchedulerRef.current.getAppointmentSchedulerData();
        if (!regularSchedulerResponse?.isValid) {
          return;
        }
        payload = {
          ...regularSchedulerResponse.appointmentSchedulerData,
          status: "DNC",
          signingStatus: false,
        };
      } else {
        payload = {
          status: "DNC",
          signingStatus: false,
        };
      }

      console.log("payload >>", payload);
      if (
        formField.agentDncFee?.trim() &&
        !isNaN(formField.agentDncFee.trim())
      ) {
        payload["agentDncFee"] = Number(formField.agentDncFee.trim());
      } else {
        showToast("Fee is Required", "error");
        return;
      }

      payload["agentDncReason"] = formField.selectReason;

      payload["agentDncComment"] = formField.agentNotes?.trim().length
        ? formField.agentNotes.trim()
        : "";
    }

    if (
      status === "DNC" ||
      status === "Closed" ||
      status === "Cancelled" ||
      (status === "CCA" && formField.selectReason.trim().length)
    ) {
      // console.log("payload", payload);
      this._updateClosingDetailsById(closingDetails.id, payload);
    }
  };

  _onChipSelect = (chips, arrName = "chips") => {
    let isValid = chips.length
      ? this._isValidEmail(chips[chips.length - 1])
      : true;
    if (isValid) {
      this.setState({
        formField: {
          ...this.state.formField,
          chips: chips,
        },
      });
    }
  };

  _isValidEmail = (value) => {
    if (RegexConfig.email.test(String(value).toLowerCase())) {
      return true;
    } else {
      showToast("Invalid Email", "error");
      return false;
    }
  };

  _sendEmailOnStatusChange = async () => {
    let { closingDetails, formField } = deepClone(this.state);
    // let otherRecipient = formField?.chips?.filter(
    //   (item) =>
    //     !this.props.data.personOfContact.email.includes(item) &&
    //     item !== closingDetails?._signingCompany?.email
    // );
    let otherRecipient = formField?.chips;

    let data = {
      // recipients: formField?.chips,
      recipients: otherRecipient,
    };
    try {
      this.setState({ loading: true });

      await sendEmailOnStatusChange(closingDetails.id, data);
      // showToast("Email Sent", "success");
      this.props.resetDetails();
      this._closeModal();
    } catch (error) {
      errorHandler(error);
      this.setState({ loading: false });
    }
  };

  _isNumber = (e) => {
    var charCode = e.which ? e.which : e.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return e.preventDefault();
    return;
  };

  render() {
    const { closingDetails, formField, loading } = this.state;

    const { status } = this.props;
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this._closeModal}
        scrollable
        centered
      >
        <ModalHeader toggle={this._closeModal}>
          Change Status:{" "}
          {agentClosingStatus?.find((each) => each.value === status)?.label}
        </ModalHeader>

        <ModalBody>
          {closingDetails ? (
            <>
              <div className="signingCompanyInfo">
                {status !== "Cancelled" ? (
                  <p>
                    Shared with{" "}
                    {closingDetails._signingCompany?.companyName
                      ? capitalize(closingDetails._signingCompany.companyName)
                      : closingDetails.client?.name
                      ? capitalize(closingDetails.client.name)
                      : ""}
                  </p>
                ) : null}
                <p>
                  Signer Name :{" "}
                  {closingDetails?._borrower &&
                  closingDetails?._borrower?.length > 0
                    ? closingDetails?._borrower
                        ?.map((item) => capitalize(item?.name?.full))
                        .join(", ")
                    : "N/A"}
                  ,{" "}
                </p>

                <p>
                  File Number :{" "}
                  {closingDetails.fileNumber
                    ? capitalize(closingDetails.fileNumber)
                    : ""}
                </p>

                {/* {status === "Cancelled" ? (
                  <h4>Do You Want To Cancel This Closing?</h4>
                ) : null} */}
              </div>

              {status === "Cancelled" ? (
                <FormGroup className="floatingLabel">
                  <Input
                    type="textarea"
                    rows="3"
                    style={{ maxHeight: "unset" }}
                    placeholder=" "
                    name="agentNotes"
                    value={formField.agentNotes}
                    onChange={(e) =>
                      this._onChangeFormField("agentNotes", e.target.value)
                    }
                  />
                  <Label>Comments</Label>
                </FormGroup>
              ) : null}

              {status === "CCA" ? (
                <>
                  <FormGroup className="floatingLabel">
                    <div className="custom-select-wrapper">
                      <Input
                        type="select"
                        name="shippingCompany"
                        value={formField.selectReason}
                        onChange={(e) =>
                          this._onChangeFormField(
                            "selectReason",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select</option>
                        <option value="Another appointment already booked">
                          Another appointment already booked
                        </option>
                        <option value="Fee is incorrect">
                          Fee is incorrect
                        </option>
                        <option value="Forgot to block calendar">
                          Forgot to block calendar
                        </option>
                        <option value="No Documents Received">
                          No Documents Received
                        </option>
                        <option value="Out of my area">Out of my area</option>
                        <option value="Outside of my working areas">
                          Outside of my working areas
                        </option>
                        <option value="Personal event not showing in CloseWise">
                          Personal event not showing in CloseWise
                        </option>
                        <option value="Travel too far between appointments ">
                          Travel too far between appointments{" "}
                        </option>
                        <option value="Emergency / Unforeseen Circumstances">
                          Emergency / Unforeseen Circumstances
                        </option>
                      </Input>
                      <Label>Reason</Label>
                    </div>
                  </FormGroup>
                </>
              ) : null}

              {status !== "DNC" && status !== "Cancelled" ? (
                <FormGroup className="floatingLabel">
                  <Input
                    type="textarea"
                    rows="3"
                    style={{ maxHeight: "unset" }}
                    placeholder=" "
                    // placeholder={
                    //   status === "Closed"
                    //     ? "Did you collect funds? When will the return package be shipped? (Questions regarding payment can be asked on the next page.)"
                    //     : "Type Comments"
                    // }
                    name="agentNotes"
                    value={formField.agentNotes}
                    onChange={(e) =>
                      this._onChangeFormField("agentNotes", e.target.value)
                    }
                  />
                  <Label>
                    {status === "Closed"
                      ? "How was this order? Any Updates?"
                      : "Comments"}
                  </Label>
                  {/* <small className="text-muted">Did you collect funds? When will the return package be shipped? (Questions regarding payment can be asked on the next page.)</small> */}
                </FormGroup>
              ) : null}

              {status === "Closed" && (
                <div className="d-flex flex-wrap mb-3">
                  <Label className="mr-3">Funds Collected</Label>
                  <CustomInput
                    type="radio"
                    name="agentFundsCollected"
                    value="true"
                    checked={this.state.agentFundsCollected}
                    onChange={() =>
                      this.setState({ agentFundsCollected: true })
                    }
                    label="Yes"
                    id="fundsCollectedYes"
                    inline
                  />
                  <CustomInput
                    type="radio"
                    name="agentFundsCollected"
                    value="false"
                    checked={!this.state.agentFundsCollected}
                    onChange={() =>
                      this.setState({ agentFundsCollected: false })
                    }
                    label="no"
                    id="fundsCollectedNo"
                    inline
                  />
                  {/* <div className="form-check-radio mr-3 ml-2">
                    <Label check className="cursorPointer">
                      <Input
                        type="radio"
                        name="agentFundsCollected"
                        value="true"
                        checked={this.state.agentFundsCollected}
                        onChange={() =>
                          this.setState({ agentFundsCollected: true })
                        }
                      />
                      Yes
                      <span className="form-check-sign" />
                    </Label>
                  </div> */}
                  {/* <div className="form-check-radio">
                    <Label check className="cursorPointer">
                      <Input
                        type="radio"
                        name="agentFundsCollected"
                        value="false"
                        checked={!this.state.agentFundsCollected}
                        onChange={() =>
                          this.setState({ agentFundsCollected: false })
                        }
                      />
                      No
                      <span className="form-check-sign" />
                    </Label>
                  </div> */}
                </div>
              )}
              {status === "DNC" ? (
                <>
                  <FormGroup className="floatingLabel">
                    <div className="custom-select-wrapper">
                      <Input
                        type="select"
                        name="shippingCompany"
                        placeholder=" "
                        value={formField.selectReason}
                        onChange={(e) =>
                          this._onChangeFormField(
                            "selectReason",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select</option>
                        <option value="Borrower did not show up">
                          Borrower did not show up
                        </option>
                        <option value="Borrower did not want to sign">
                          Borrower did not want to sign
                        </option>
                        <option value="Borrower ID Issue">
                          Borrower ID Issue
                        </option>
                        {/* <option value="Cancelled prior to appointment">Cancelled prior to appointment</option> */}
                        <option value="Other">Other</option>
                      </Input>
                      <Label>Reason</Label>
                    </div>
                  </FormGroup>

                  {status === "DNC" ? (
                    <FormGroup className="floatingLabel ">
                      <Input
                        type="textarea"
                        rows="3"
                        style={{ maxHeight: "unset" }}
                        placeholder=" "
                        name="agentNotes"
                        value={formField.agentNotes}
                        onChange={(e) =>
                          this._onChangeFormField("agentNotes", e.target.value)
                        }
                      />
                      <Label>Comments</Label>
                    </FormGroup>
                  ) : null}

                  <FormGroup className="floatingLabel withInputGroup">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <img
                            src={
                              require("../../../assets/img/dollar.svg").default
                            }
                            height={14}
                            alt="doller"
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="agentDncFee"
                        min={"0"}
                        placeholder=" "
                        disabled={formField.companyAcceptDncFee}
                        value={formField.agentDncFee}
                        onChange={(e) =>
                          this._onChangeFormField("agentDncFee", e.target.value)
                        }
                      />
                      <Label>Request Fee for Approval</Label>
                    </InputGroup>
                  </FormGroup>
                </>
              ) : null}

              {status === "Closed" ? (
                <>
                  <Row>
                    <Col md={6}>
                      <FormGroup className="floatingLabel">
                        <div className="custom-select-wrapper">
                          <Input
                            type="select"
                            name="shippingCompany"
                            value={formField.shippingCompany}
                            placeholder=" "
                            onChange={(e) =>
                              this._onChangeFormField(
                                "shippingCompany",
                                e.target.value
                              )
                            }
                          >
                            <option value="">Select</option>
                            <option value="USPS">USPS</option>
                            <option value="UPS">UPS</option>
                            <option value="FedEx">FedEx</option>
                            <option value="DHL">DHL</option>
                          </Input>
                          <Label>Shipping Company</Label>
                        </div>

                        <span
                          style={{
                            color: "#767676",
                            fontSize: 12,
                            marginTop: 7,
                            display: "block",
                          }}
                        >
                          Optional (You can add shipping information later)
                        </span>
                      </FormGroup>
                      {/* Note: Hide Everything Below if Signing is Unsuccessful */}
                    </Col>
                    <Col md={6}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          name="trackingInfo"
                          placeholder=" "
                          value={formField.trackingInfo}
                          onChange={(e) =>
                            this._onChangeFormField(
                              "trackingInfo",
                              e.target.value
                            )
                          }
                        />
                        <Label>Tracking #</Label>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="floatingLabel withInputGroup">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>#</InputGroupText>
                          </InputGroupAddon>
                          <Input
                            name="notarialActs"
                            placeholder=" "
                            value={formField.notarialActs}
                            onChange={(e) =>
                              this._onChangeFormField(
                                "notarialActs",
                                e.target.value
                              )
                            }
                            onKeyPress={(e) => this._isNumber(e)}
                          />
                          <Label>Notarial Acts</Label>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                    <Col md={6}>
                      <FormGroup className="floatingLabel withInputGroup">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <img
                                src={
                                  require("../../../assets/img/dollar.svg")
                                    .default
                                }
                                height={14}
                                alt="doller"
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder=" "
                            name="amountPerAct"
                            value={formField.amountPerAct}
                            onChange={(e) =>
                              this._onChangeFormField(
                                "amountPerAct",
                                e.target.value
                              )
                            }
                            onKeyPress={(e) => this._isNumber(e)}
                          />
                          <Label>State Rate Per</Label>
                        </InputGroup>
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="text-right mt-2 mb-4">
                    <a
                      href="https://www.closewise.com/income-tax-tips-for-notaries-signing-agents/"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Why? Read More
                    </a>
                  </div>

                  {/* Note: Hide Everything Above if Signing is Unsuccessful */}
                </>
              ) : null}
              {status === "Closed" ||
              status === "DNC" ||
              status === "CCA" ||
              status === "Cancelled" ? (
                <FormGroup className="ChipsWrap floatingLabel valueAdded">
                  <Chips
                    value={formField.chips}
                    onChange={(arr) => this._onChipSelect(arr, "chips")}
                    getChipValue={(e) => console.log(e)}
                    createChipKeys={[9, 13]} //  Key codes to add chips
                    placeholder={"+Add Email"}
                  />

                  <Label> Send Email To</Label>
                </FormGroup>
              ) : null}

              {(status === "Closed" || status === "DNC") &&
              // Need to show only closingDetails is not TBD and DateRange
              (closingDetails?.isRangeDated || closingDetails?.tBD) ? (
                <RegularAppointmentScheduler
                  ref={this.regularSchedulerRef}
                  data={this.state.appointmentSchedulerData}
                  isDateFormat={false}
                  initialViewMode={"time"}
                  timezone={closingDetails?.closingAddress?.timeZone}
                />
              ) : null}
            </>
          ) : (
            <div className="text-center" style={{ fontSize: "20px" }}>
              <i className="fa fa-spinner fa-spin" />
            </div>
          )}
        </ModalBody>

        <ModalFooter>
          <Button size="lg" color="primary" outline onClick={this._closeModal}>
            Cancel
          </Button>

          <Button
            color="primary"
            size="lg"
            disabled={loading}
            onClick={this._confirmStatus}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null} Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default SigningStatusModal;
