import React from "react";
import {
  Row,
  Col,
  Input,
  Button,
  FormGroup,
  Badge,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Card,
  CardBody,
  Label,
} from "reactstrap";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import AddNotesModal from "../components/add-notes-income-list-modal";
import {
  capitalize,
  deepClone,
  formatCurrencyValue,
  errorHandler,
  showToast,
  checkPermission,
  // formatDate,
  formatDateOnlyAsPerTimeZone,
  formatOnlyDateMoment,
  refreshFunc,
} from "../../../helper-methods";

import {
  getAllClientPayment,
  updateClientPayment,
  getAllCompanyForPayment,
  clientPayCompany,
} from "../../../http/http-calls";
import ClientMakePaymentModal from "../components/ClientMakePaymentModal";
import { Elements } from "react-stripe-elements";
import AccountComponentModal from "../components/AccountComponentModal";
import CustomTable from "../components/CustomTable";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomCard from "../components/CustomCard";
// import { loanTypeConfig } from "../../../config";

class ClientPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      dataTotalCount: 0,
      totalPayment: 0,
      totalPaid: 0,
      totalDue: 0,
      dataHeaderKeys: [
        { id: "id", label: "id", noSort: true },
        { id: "fileNumber", label: "File Number", noSort: true },
        { id: "appointmentDate", label: "completion Date", noSort: true },
        { id: "payPeriodPaymentNotesClient", label: "Notes", noSort: true },
        { id: "loanType", label: "Product Type", noSort: true },
        { id: "_signingCompany", label: "Company", noSort: true },
        { id: "payStatusClient", label: "Status", noSort: true },
        { id: "signingCompanyFee", label: "Balance", noSort: true },
      ],
      dataCardHeaderKeys: [
        { id: "id", label: "id" },
        { id: "name", label: "Name" },
      ],
      tableConfig: {
        skip: 0,
        limit: 50,
        pageNumber: 1,
      },
      addNotesModal: {
        isOpen: false,
        data: null,
      },
      signingCompanys: [],
      clientMakePayment: {
        isOpen: false,
        data: null,
      },
      filters: {
        signingCompanyId: "",
        payStatusClient: "",
        search: "",
      },
      dateRangeValue: null,
      loading: {
        showTableLoading: false,
        markPaidLoading: false,
      },
      selectedRows: [],
      selectedArray: [],
      payAchModal: {
        isOpen: false,
        data: null,
      },
      isClassAdded: false,
    };
    this.tableRef = React.createRef();
  }

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  _getPaymentAndCompanyData = async () => {
    const companies = await this._getAllCompanyForPayment();

    this.setState(
      {
        filters: {
          signingCompanyId: companies?.[0]?.id,
        },
      },
      () => {
        this._setFilterDataFromLocalStorage();
      }
    );
  };

  componentDidMount = () => {
    // this._getAllClientPayment();
    this._getPaymentAndCompanyData();
    HeaderEventEmitter.subscribe("reset-filter", () => {
      this._resetFilter();
      // this._paginate();
      refreshFunc("client-client-payment");
    });
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          signingCompanyId: "",
          payStatusClient: "",
          search: "",
        },
      },
      () => this._paginate()
    );
  };

  _getAllClientPayment = () => {
    this._manageLoading("showTableLoading", true);

    const { filters, tableConfig, dateRangeValue } = deepClone(this.state);

    const filterPayload = {};

    if (filters?.signingCompanyId?.length)
      filterPayload["signingCompanyId"] = filters.signingCompanyId;
    if (filters?.payStatusClient?.length)
      filterPayload["payStatusClient"] = filters.payStatusClient;
    // if (dateRangeValue) {
    //   filterPayload["startDate"] = dateRangeValue[0].toISOString();
    //   filterPayload["endDate"] = dateRangeValue[1].toISOString();
    // }

    if (dateRangeValue && dateRangeValue[0])
      Object.assign(filterPayload, {
        startDate: formatOnlyDateMoment(dateRangeValue[0]),
      });

    if (dateRangeValue && dateRangeValue[1])
      Object.assign(filterPayload, {
        endDate: formatOnlyDateMoment(dateRangeValue[1]),
      });

    if (filters.search?.length) tableConfig["search"] = filters.search;

    tableConfig["filters"] = { ...filterPayload };

    getAllClientPayment(tableConfig)
      .then((res) => {
        this.setState(
          {
            data: res?.closings || [],
            dataTotalCount: res?.totalCount || 0,
            totalPayment: res?.totalPayment || 0,
            totalDue: res?.totalDue || 0,
            totalPaid: res?.totalPaid || 0,
          },
          () => {
            this._manageLoading("showTableLoading", false);
          }
        );
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _getAllCompanyForPayment = () => {
    return new Promise(async (resolve) => {
      try {
        const { filters, dateRangeValue } = deepClone(this.state);

        const filterPayload = {};

        if (filters?.payStatusClient?.length)
          filterPayload["payStatusClient"] = filters.payStatusClient;
        if (dateRangeValue) {
          filterPayload["startDate"] = dateRangeValue[0];
          filterPayload["endDate"] = dateRangeValue[1];
        }
        if (filters.search && filters.search.length)
          filterPayload["search"] = filters.search;

        const res = await getAllCompanyForPayment(filterPayload);

        this.setState(
          {
            signingCompanys: res?.signingCompanys?.length
              ? res.signingCompanys
              : [],
          },
          () => {
            this._setSelectedRows();
            resolve(res?.signingCompanys);
          }
        );
      } catch (err) {
        resolve([]);
        errorHandler(err);
      }
    });
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => this._paginate());
  };

  _filterOnChange = (type, value, isDelay = false) => {
    if (isDelay) clearTimeout(this.searchTimer);

    const { filters } = this.state;
    filters[type] = value;
    this.setState({ filters }, () => {
      if (isDelay) {
        this.searchTimer = setTimeout(() => {
          this._paginate();
        }, 1000);
      } else {
        this._paginate();
      }
    });
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          signingCompanyId: "",
          payStatusClient: "",
          search: "",
        },
        dateRangeValue: null,
      },
      () => this._paginate()
    );
  };

  _paginate = (pageNumber = 1, pageSize = 50) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllClientPayment();
      // this._getAllCompanyForPayment();
    });
  };

  _onTogglePayAchModal = (isOpen = false, note = null) => {
    this.setState({
      payAchModal: {
        isOpen,
        note,
      },
    });
  };

  _onToggleClientMakePayment = (isOpen = false, data = null) => {
    this.setState({
      clientMakePayment: {
        isOpen,
        data,
      },
    });
  };

  _onToggleAddNotesModal = (isOpen = false, data = null) => {
    this.setState({
      addNotesModal: {
        isOpen,
        data,
      },
    });
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "fileNumber": {
        return cell || "N/A";
      }
      case "appointmentDate": {
        return cell
          ? formatDateOnlyAsPerTimeZone(cell, row?.closingAddress?.timeZone)
          : "N/A";
        // return cell ? formatDate(cell) : "N/A";
      }
      case "payPeriodPaymentNotesClient": {
        return (
          <Button
            color="link"
            className="actionBtn"
            onClick={() => this._onToggleAddNotesModal(true, row)}
          >
            <img
              src={require("../../../assets/img/nodes.svg").default}
              alt="notes"
            />
          </Button>
        );
      }
      // case "loanType": {
      //   return cell
      //     ? cell === "Other"
      //       ? row.loanTypeOther || "N/A"
      //       : loanTypeConfig.find((obj) => obj.value === cell)?.label || "N/A"
      //     : "N/A";
      // }
      case "loanType": {
        return cell
          ? cell[0] === "Other"
            ? row?.loanTypeOther
            : cell.map((item) => item.replace(/([a-z])([A-Z])/g, "$1,$2"))
          : "N/A";
      }
      case "_signingCompany": {
        return cell.companyName ? capitalize(cell.companyName) : "N/A";
      }
      case "signingCompanyFee": {
        return cell ? formatCurrencyValue(cell) : "N/A";
      }
      case "payStatusClient": {
        return cell === "Pending" ? (
          <Badge color="warning" className="outline" pill>
            Pending
          </Badge>
        ) : cell === "Paid" ? (
          <Badge color="success" className="outline" pill>
            Paid
          </Badge>
        ) : (
          <Badge color="primary" className="outline" pill>
            {cell || "-"}
          </Badge>
        );
      }
      default: {
        return cell;
      }
    }
  };

  _recordPayment = async (action, note) => {
    try {
      if (action !== "pay") {
        showToast();
        return;
      }

      this._manageLoading("showTableLoading", true);

      const { filters, selectedRows } = this.state;

      const payload = {
        action,
        payPeriodPaymentNotesClient: note?.trim() ? note.trim() : "",
      };
      const closingId = selectedRows?.map((each) => each?.id);

      if (filters.signingCompanyId)
        payload["signingCompanyId"] = filters.signingCompanyId;
      if (selectedRows?.length) payload["closingId"] = closingId;

      await updateClientPayment(payload);

      showToast("Record has been updated", "success");

      this._getAllClientPayment();
      this._setSelectedRows();
      this._manageLoading("showTableLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("showTableLoading", false);
    }
  };

  _onSaveNotes = (id, note) => {
    return new Promise((resolve, reject) => {
      const { filters } = this.state;

      const payload = {
        closingId: [id],
        payPeriodPaymentNotesClient: note?.trim() ? note.trim() : "",
      };

      if (filters.signingCompanyId)
        payload["signingCompanyId"] = filters.signingCompanyId;

      updateClientPayment(payload)
        .then((res) => {
          this._getAllClientPayment();
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _nextCompany = () => {
    if (this.searchTimer) clearTimeout(this.searchTimer);

    const { signingCompanys, filters } = deepClone(this.state);

    const index = signingCompanys.findIndex(
      (each) => filters.signingCompanyId === each._id
    );

    if (index !== signingCompanys.length - 1) {
      const nextCompany = signingCompanys[index + 1];

      filters["signingCompanyId"] = nextCompany?._id || "";

      this.setState({ filters }, () => {
        this.searchTimer = setTimeout(() => {
          this._paginate();
        }, 1000);
      });
    }
  };

  _prevCompany = () => {
    if (this.searchTimer) clearTimeout(this.searchTimer);

    const { signingCompanys, filters } = deepClone(this.state);

    const index = signingCompanys.findIndex(
      (each) => filters.signingCompanyId === each._id
    );

    if (index > 0) {
      const prevCompany = signingCompanys[index - 1];

      filters["signingCompanyId"] = prevCompany?._id || "";

      this.setState({ filters }, () => {
        this.searchTimer = setTimeout(() => {
          this._paginate();
        }, 1000);
      });
    }
  };

  _setSelectedRows = (selectedRows = []) => {
    const { data } = deepClone(this.state);

    selectedRows = selectedRows?.filter(
      (each) => each?.payStatusClient !== "Paid"
    );

    const results = data.filter((item) =>
      selectedRows.some((each) => each.id === item.id)
    );

    this.setState({
      selectedRows,
      selectedArray: results,
    });
  };

  _calculateCompanyTotalFee = () => {
    const { selectedArray } = deepClone(this.state);
    let arr = selectedArray.map((item) => item.signingCompanyFee || 0);
    return arr.reduce((a, b) => a + b, 0);
  };

  _processPayment = async () => {
    try {
      let { filters, selectedRows, payAchModal } = deepClone(this.state);

      if (!selectedRows?.length) {
        showToast("Please Select Closings", "error");
        return;
      }

      this.setState({ loadingModal: true });

      let payload = {
        signingCompanyId: filters.signingCompanyId,
        amount: this._calculateCompanyTotalFee(),
        payPeriodPaymentNotes: payAchModal.note,
        closingId: selectedRows,
        // action: action,
      };

      await clientPayCompany(payload);

      this._onTogglePayAchModal();
      this._getAllClientPayment();
      this._setSelectedRows();

      showToast(`Payment has been completed`, "success");

      this.setState({ loadingModal: false });
    } catch (error) {
      errorHandler(error);

      this.setState({ loadingModal: false });
    }
  };

  _cardHeaderFormat = (cell, row, header) => {
    const { loading } = deepClone(this.state);

    switch (header) {
      case "name": {
        return row ? (
          <>
            {/* <div
              className="tableUserInfo"
              onClick={() =>
                row?._company?._id
                  ? this.props.history.push(
                      `/client/signing-company-details/${row._company._id}`
                    )
                  : {}
              }
            >
              <div className="userImage">
                <img
                  className="clientImg"
                  src={
                    row?._company?.logo
                      ? row?._company?.logo
                      : require("../../../assets/img/default_user_icon.svg")
                          .default
                  }
                  loading="lazy"
                  alt="avatar"
                />
              </div>
            </div> */}
            {/* <Button
              color={`${
                row?.appointmentStatus === "Confirmed" && !row.leftMessage
                  ? "success "
                  : row?.appointmentStatus === "Rescheduled" && !row.leftMessage
                  ? "danger "
                  : row.leftMessage
                  ? "warning"
                  : "danger "
              }`}
              className={`confirmButton btnConfirm`}
              onClick={() => this._onToggleSignerAvailabilityModal(true, row)}
            >
              {row?.appointmentStatus === "Confirmed" ? "Confirmed" : "Confirm"}
            </Button> */}
            {row?.payStatusClient === "Pending" ? (
              <Badge color="warning" className="outline" pill>
                Pending
              </Badge>
            ) : row?.payStatusClient === "Paid" ? (
              <Badge color="success" className="outline" pill>
                Paid
              </Badge>
            ) : (
              <Badge color="primary" className="outline" pill>
                {row?.payStatusClient || "-"}
              </Badge>
            )}
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>File Number: </Label>

            <span
              style={{
                marginLeft: 5,
                fontWeight: 600,
              }}
            >
              {row?.fileNumber ? row?.fileNumber : "N/A"}
            </span>
          </div>

          <div className="dataWrap">
            <Label>Completion Date: </Label>

            <span
              style={{
                marginLeft: 5,
                fontWeight: 600,
              }}
            >
              {row?.appointmentDate
                ? formatDateOnlyAsPerTimeZone(
                    row?.appointmentDate,
                    row?.closingAddress?.timeZone
                  )
                : "N/A"}
            </span>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Notes: </Label>

            {row ? (
              <Button
                color="link"
                className="actionBtn"
                onClick={() => this._onToggleAddNotesModal(true, row)}
              >
                <img
                  src={require("../../../assets/img/nodes.svg").default}
                  alt="notes"
                />
              </Button>
            ) : (
              "N/A"
            )}
          </div>
          <div className="dataWrap">
            <Label>Product Type: </Label>

            <span
              style={{
                marginLeft: 5,
                fontWeight: 600,
              }}
            >
              {row?.loanType
                ? row?.loanType[0] === "Other"
                  ? row?.loanTypeOther
                  : row?.loanType.map((item) =>
                      item.replace(/([a-z])([A-Z])/g, "$1 $2")
                    )
                : "N/A"}
            </span>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Company: </Label>

            <span
              style={{
                marginLeft: 5,
                fontWeight: 600,
              }}
            >
              {row?._signingCompany?.companyName
                ? capitalize(row?._signingCompany?.companyName)
                : "N/A"}
            </span>
          </div>

          <div className="dataWrap">
            <Label>Balance: </Label>

            <span
              style={{
                marginLeft: 5,
                fontWeight: 600,
              }}
            >
              {row?.signingCompanyFee
                ? formatCurrencyValue(row?.signingCompanyFee)
                : "N/A"}
            </span>
          </div>
        </li>
      </ul>
    );
  };

  _persistFilter = () => {
    const { tableConfig } = this.state;

    if (tableConfig.pageNumber !== 1) {
      let data = { tableConfig };
      localStorage.clientConnectPayPage = JSON.stringify(data);
    } else {
      delete localStorage.clientConnectPayPage;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.clientConnectPayPage) {
      try {
        const filters = JSON.parse(localStorage.clientConnectPayPage);

        this.setState({ tableConfig: filters?.tableConfig }, () => {
          this._getAllClientPayment();
        });
      } catch (e) {
        console.log("error", e);
        this._getAllClientPayment();
      }
    } else {
      this._getAllClientPayment();
    }
  };

  render() {
    const {
      dateRangeValue,
      filters,
      data,
      dataTotalCount,
      dataHeaderKeys,
      tableConfig,
      loading,
      clientMakePayment,
      totalPayment,
      totalPaid,
      totalDue,
      signingCompanys,
      selectedRows,
      selectedArray,
      addNotesModal,
      loadingModal,
      payAchModal,
      dataCardHeaderKeys,
      isClassAdded,
    } = this.state;
    // console.log("first", this.state);
    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>Connect Pay</h2>

            <div className="rightSide">
              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="Filter"
                />
              </Button>
            </div>
          </div>

          {/* search filed for responsive will show below 767px */}
          <div className="mobileSearchFiled">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img
                    src={require("../../../assets/img/searchIcon.svg").default}
                    alt="searchIcon"
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="File Number"
                value={filters.search}
                onChange={(e) =>
                  this._filterOnChange("search", e.target.value, true)
                }
              />
            </InputGroup>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.filterShowMobile}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer  responsiveFilter ${
              isClassAdded ? "show" : ""
            }`}
          >
            <div className="filterIcon">
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
              />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>
            <div className="filterWrapper">
              <FormGroup className="searchTable">
                <Label>Search</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <img
                        src={
                          require("../../../assets/img/searchIcon.svg").default
                        }
                        alt="searchIcon"
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    placeholder="File Number"
                    value={filters.search}
                    onChange={(e) =>
                      this._filterOnChange("search", e.target.value, true)
                    }
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="dateRange">
                <Label>Date Range</Label>
                <DateRangePicker
                  className="dateRange"
                  format="MM-dd-y"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  onChange={(dateRangeValue) =>
                    this._onChangeDatePicker(dateRangeValue)
                  }
                  value={dateRangeValue}
                />
              </FormGroup>
              <FormGroup>
                <Label>Status</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    value={filters.payStatusClient}
                    name="payStatusClient"
                    onChange={(e) =>
                      this._filterOnChange("payStatusClient", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    <option value="Pending">Pending</option>
                    <option value="Paid">Paid</option>
                  </Input>
                </div>
              </FormGroup>
              {/* <FormGroup>
                <Label>Company</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    value={filters.signingCompanyId}
                    name="signingCompanyId"
                    onChange={(e) =>
                      this._filterOnChange("signingCompanyId", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {signingCompanys.map((each) => (
                      <option key={each._id} value={each._id}>
                        {capitalize(each?.companyName)}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup> */}
            </div>
            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>

          <Row>
            <Col md="12">
              <Row className="mt-4">
                <Col xl={3} lg={6} xs={6}>
                  <Card className="infoCard">
                    <CardBody>
                      <div className="">
                        <span>Total Orders</span>
                        <h4> {dataTotalCount || "0"}</h4>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            require("../../../assets/img/TotalOrders.svg")
                              .default
                          }
                          alt="notaryActs"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} lg={6} xs={6}>
                  <Card className="infoCard">
                    <CardBody>
                      <div className="">
                        <span>Total Payments</span>
                        <h4>
                          {totalPayment
                            ? formatCurrencyValue(totalPayment)
                            : "$0"}
                        </h4>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            require("../../../assets/img/TotalIncome.svg")
                              .default
                          }
                          alt="notaryActs"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} lg={6} xs={6}>
                  <Card className="infoCard">
                    <CardBody>
                      <div className="">
                        <span>Paid</span>
                        <h4> {formatCurrencyValue(totalPaid)}</h4>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            require("../../../assets/img/Expenses.svg").default
                          }
                          alt="notaryActs"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} lg={6} xs={6}>
                  <Card className="infoCard">
                    <CardBody>
                      <div className={``}>
                        <span>Pending</span>
                        <h4 className="text-danger">
                          {" "}
                          {formatCurrencyValue(totalDue)}
                        </h4>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            require("../../../assets/img/TotalIncome.svg")
                              .default
                          }
                          alt="notaryActs"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {checkPermission("payment", "canPay") ? (
                selectedRows?.length ? (
                  <div
                    className="d-flex flex-column flex-sm-row align-items-center justify-content-between"
                    style={{ marginBottom: 20, marginTop: 15 }}
                  >
                    <div
                      className="fs-14"
                      style={{ color: "#1B2430", fontWeight: 600 }}
                    >
                      <span>{selectedRows.length}</span>{" "}
                      {selectedRows.length > 1 ? "Records are" : "Record is"}{" "}
                      Selected
                    </div>
                    {selectedRows.filter((item) => item?.payStatusClient)
                      ?.length > 0 && (
                      <div>
                        <Button
                          color="primary"
                          size="lg"
                          onClick={() => this._onToggleClientMakePayment(true)}
                        >
                          Make Payment - ${this._calculateCompanyTotalFee()}
                        </Button>
                      </div>
                    )}
                  </div>
                ) : null
              ) : null}

              <div className="hideDesktop">
                <CustomCard
                  isPageStartFromOne={true}
                  pageNumber={tableConfig.pageNumber}
                  tableData={data}
                  headerKeys={dataCardHeaderKeys}
                  // bodyKeys={agentClosingCardBodyKeys}
                  dataFormat={this._dataFormat}
                  totalCount={dataTotalCount}
                  onPaginate={(pageNumber, pageSize) =>
                    this._paginate(pageNumber, pageSize)
                  }
                  rowSelection={true}
                  selectedRows={selectedRows}
                  setSelectedRows={(selectedRows) =>
                    this._setSelectedRows(selectedRows)
                  }
                  // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
                  showTableLoading={loading.showTableLoading}
                  cardHeaderFormat={this._cardHeaderFormat}
                  cardDataFormat={this._cardDataFormat}
                />
              </div>

              <div className="hideMobile">
                {dataHeaderKeys && dataHeaderKeys.length && (
                  <CustomTable
                    striped
                    isPageStartFromOne={true}
                    pageNumber={tableConfig.pageNumber}
                    tableData={data}
                    headerKeys={dataHeaderKeys}
                    dataFormat={this._dataFormat}
                    totalCount={dataTotalCount}
                    rowSelection={
                      checkPermission("payment", "canPay") ? true : false
                    }
                    onPaginate={(pageNumber, pageSize) =>
                      this._paginate(pageNumber, pageSize)
                    }
                    // onSortChange={(sortName, sortOrder) =>
                    //   // sort function is disabled
                    //   this._paginate()
                    // }
                    setSelectedRows={(selectedRows) =>
                      this._setSelectedRows(selectedRows)
                    }
                    selectedRows={selectedRows}
                    isRowSelectedCondition={true}
                    rowSelectedCondition={(row) => {
                      return row.payStatusClient !== "Paid";
                    }}
                    showTableLoading={loading.showTableLoading}
                    ref={this.tableRef}
                    // sizePerPage={50}
                    pageSize={50}
                  />
                )}
              </div>
            </Col>
          </Row>

          <AddNotesModal
            isOpen={addNotesModal.isOpen}
            data={addNotesModal.data}
            toggle={() => this._onToggleAddNotesModal()}
            onSaveNotes={(id, note) => this._onSaveNotes(id, note)}
          />

          <ClientMakePaymentModal
            isOpen={clientMakePayment.isOpen}
            data={selectedArray}
            toggle={() => this._onToggleClientMakePayment()}
            openAchModal={(note) => this._onTogglePayAchModal(true, note)}
            recordPayment={(note) => this._recordPayment("pay", note)}
          />

          <Elements>
            <AccountComponentModal
              loadingModal={loadingModal}
              isOpen={payAchModal.isOpen}
              toggle={() => this._onTogglePayAchModal()}
              processPayment={() => this._processPayment()}
              manageLoading={(value) => this.setState({ loadingModal: value })}
            />
          </Elements>
        </div>
      </>
    );
  }
}

export default ClientPayment;
