import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  CardTitle,
  CardHeader,
} from "reactstrap";
import {
  capitalize,
  deepClone,
  errorHandler,
  showToast,
} from "../../../helper-methods";
import {
  fetchAllCards,
  deleteCard,
  getPlaidLinkToken,
  exchangePublicToken,
} from "../../../http/http-calls";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import AddAccountModal from "../components/add-account-modal";
import { Elements } from "react-stripe-elements";
import KYCAlertCard from "../components/kyc-alert";
import { FRONTEND_BASE_URL } from "../../../config";
import ReKYCCard from "../components/re-kycalert";

class AchSettings extends Component {
  state = {
    accounts: [],
    addAccountModalData: {
      isOpen: false,
      data: null,
    },
  };

  componentDidMount = async () => {
    this.props.showLoader();

    try {
      await this._getDefaultCard();
      this.props.hideLoader();
    } catch (error) {
      this.setState({ error });
      errorHandler(error);
      this.props.hideLoader();
    }
  };

  _getDefaultCard = () => {
    return new Promise(async (resolve, reject) => {
      this.props.showLoader();
      try {
        let res = await fetchAllCards();
        let accounts =
          (res?.cards?.length &&
            res?.cards?.filter((item) => item.object === "bank_account")) ||
          [];
        this.setState({ accounts });
        this.props.hideLoader();
      } catch (error) {
        this.props.hideLoader();
        reject(error);
      }
    });
  };

  _setDefaultCard = (accounts) => {
    return new Promise((resolve, reject) => {
      let defaultAccount = accounts?.length
        ? accounts[0]
        : accounts.find((acc) => acc.isDefault);

      this.setState({ defaultAccount, selectedAccount: defaultAccount }, () => {
        resolve();
      });
    });
  };

  _toggleAddAccountModal = (isOpen = false, data = null) => {
    this.setState({
      addAccountModalData: {
        isOpen,
        data,
      },
    });
  };

  _deleteAccount = (cardId) => {
    this.props.showLoader();

    deleteCard(cardId)
      .then(async (res) => {
        this._toggleAddAccountModal();
        await this._getDefaultCard();
        this.props.hideLoader();
        showToast("Account has been removed", "success");
      })
      .catch((error) => {
        errorHandler(error);
        this.props.hideLoader();
      });
  };

  _openPlaidSandbox = async () => {
    try {
      if (!window?.Plaid?.create)
        throw new Error("Plaid extension is not supported");

      const { data } = await getPlaidLinkToken({
        redirectUrl: `${FRONTEND_BASE_URL}/signingcompany/profile`,
      });

      var linkHandler = window.Plaid.create({
        // Make a request to your server to fetch a new link_token.
        token: data.link_token,
        onLoad: function () {
          // The Link module finished loading.
          console.log("The Link module finished loading");
        },
        onSuccess: async function (publicToken, metadata) {
          // The onSuccess function is called when the user has
          // successfully authenticated and selected an account to
          // use.
          //
          // When called, you will send the public_token
          // and the selected account ID, metadata.account_id,
          // to your backend app server.
          //
          // sendDataToBackendServer({
          //   public_token: public_token,
          //   account_id: metadata.account_id
          // });

          try {
            await exchangePublicToken({
              publicToken,
              accountId: metadata.account_id,
            });

            this._getDefaultCard();
          } catch (error) {
            console.log("error>>", error);
            errorHandler(error);
          }
        },
        onExit: function (error, metadata) {
          console.log("error>>", error, metadata);
          // The user exited the Link flow.
          // The user encountered a Plaid API error
          // prior to exiting.
          showToast("Plaid API error, Try again after sometime.", "error");
          // metadata contains information about the institution
          // that the user selected and the most recent
          // API request IDs.
          // Storing this information can be helpful for support.
        },
      });

      linkHandler.open();
    } catch (error) {
      errorHandler(error);
    }
  };

  render() {
    const { addAccountModalData, accounts } = deepClone(this.state);
    const { kyc } = this.props?.userData?.user;

    return (
      <>
        <Card>
          {kyc && (!kyc.paymentEnabled ||!kyc.payoutEnabled) ? (
            <KYCAlertCard />
          ) : null}
          {kyc?.isReKycNeeded && !kyc?.showKycBanner && (
            <ReKYCCard hideCancelButton={true} hideDoNotShowButton={true} />
          )}
          <div className="BankSettingCard">
            <div className="CardTitle">
              <h2 className="sectionTtile"> ACH Settings </h2>
            </div>
            <Row>
              {accounts?.length ? (
                accounts.map((item) => {
                  return (
                    <Col xl={4} key={item.id}>
                      <Card className="addedBankAccount">
                        <CardHeader>
                          <CardTitle>
                            {capitalize(item.bank_name.toLowerCase())}
                          </CardTitle>
                          {item.status !== "verified" ? (
                            <Button
                              color="success"
                              className="btnSuccess"
                              onClick={() =>
                                this._toggleAddAccountModal(true, {
                                  type: "verify",
                                  item,
                                })
                              }
                            >
                              <i className="fa fa-check" />
                            </Button>
                          ) : (
                            <Button
                              color="link"
                              className="ml-auto"
                              onClick={() =>
                                this._toggleAddAccountModal(true, {
                                  type: "delete",
                                  item,
                                })
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/deleteIcon.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button>
                          )}
                        </CardHeader>
                        <CardBody>
                          <p>{item.account_holder_name}</p>
                          <p>
                            <span>XXX</span>
                            <span>XXXX</span>
                            {item.last4}
                          </p>
                        </CardBody>
                      </Card>
                    </Col>
                  );
                })
              ) : (
                <Col md={12}>
                  <h5 className="noAccount">No Accounts Added!</h5>
                </Col>
              )}
            </Row>
            <div className="text-center my-3">
              <Button
                color="primary"
                outline
                onClick={() =>
                  this._toggleAddAccountModal(true, { type: "add" })
                }
              >
                Add Account
              </Button>
            </div>
          </div>
        </Card>

        <Elements>
          <AddAccountModal
            isOpen={addAccountModalData.isOpen}
            data={addAccountModalData.data}
            resetTable={() => this._getDefaultCard()}
            toggle={() => this._toggleAddAccountModal()}
            deleteAccount={(item) => this._deleteAccount(item.id)}
          />
        </Elements>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AchSettings);
