import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  CustomInput,
} from "reactstrap";
import { errorHandler } from "../../../../../helper-methods";
import { agentLogoutFromSocialMedia } from "../../../../../http/http-calls";

const SocialMediaLogout = ({ isOpen, toggle, onSuccess }) => {
  const [socialMediaLogoutCheckbox, setSocialMediaLogoutCheckbox] = useState({
    isFacebookLogOut: false,
    isLinkedinLogOut: false,
  });

  const _onChangeFormField = (key, value) => {
    const newSocialMediaCheckbox = { ...socialMediaLogoutCheckbox };

    newSocialMediaCheckbox[key] = value;

    setSocialMediaLogoutCheckbox(newSocialMediaCheckbox);
  };

  const [loading, setLoading] = useState(false);

  const _resetFormFields = () => {
    setSocialMediaLogoutCheckbox({
      facebookInstagram: false,
      linkedIn: false,
    });
  };

  const _closeModal = () => {
    _resetFormFields();
    toggle();
  };

  const _onSubmit = async () => {
    try {
      setLoading(true);

      const payload = {
        isFacebookLogOut: socialMediaLogoutCheckbox?.isFacebookLogOut,
        isLinkedinLogOut: socialMediaLogoutCheckbox?.isLinkedinLogOut,
      };

      console.log({ payload });
      await agentLogoutFromSocialMedia(payload);

      onSuccess();
      _closeModal();
      setLoading(false);
    } catch (err) {
      setLoading(false);
      errorHandler(err);
    }
  };

  return (
    <div>
      <Modal isOpen={isOpen} toggle={() => _closeModal()} centered scrollable>
        <ModalHeader toggle={() => _closeModal()}>
          Social Media Logout
        </ModalHeader>
        <ModalBody className="text-center mt-2">
          <h1 style={{ fontSize: 19, paddingBottom: 10, paddingTop: 8 }}>
            Do you want to logout from
          </h1>
          <CustomInput
            id="social1"
            type="checkbox"
            checked={socialMediaLogoutCheckbox?.isFacebookLogOut}
            label="Facebook/Instagram"
            onChange={(e) =>
              _onChangeFormField("isFacebookLogOut", e.target.checked)
            }
            inline
          />
          <CustomInput
            id="social2"
            type="checkbox"
            checked={socialMediaLogoutCheckbox?.isLinkedinLogOut}
            label="LinkedIn"
            onChange={(e) =>
              _onChangeFormField("isLinkedinLogOut", e.target.checked)
            }
            inline
          />
        </ModalBody>
        <ModalFooter className="mt-2">
          <Button color="primary" outline onClick={() => _closeModal()}>
            Cancel
          </Button>
          <Button color="danger" disabled={loading} onClick={() => _onSubmit()}>
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
            Logout
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SocialMediaLogout;
