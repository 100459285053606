import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Input,
  FormGroup,
  Form,
  Col,
  Row,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import { DEFAULT_COVER_PICTURE } from "../../../config";
import { RegexConfig } from "../../../config/RegexConfig";
import {
  errorHandler,
  showToast,
  splitName,
  uploadFileOnServer,
} from "../../../helper-methods";
import {
  getOrderClientById,
  createLenderClient,
  updateLenderClient,
} from "../../../http/http-calls";

class AddOrderClient extends Component {
  state = {
    formFields: {
      name: "",
      picUrl: {
        uploadData: null,
        previewBlob: null,
        type: "image",
        uploadUrl: null,
      },
      contactInfo: [
        {
          pointOfContact: "",
          contactNumber: "",
          contactEmail: "",
        },
      ],
    },
    errors: {},
    isDirty: {},
    loading: false,
  };

  _setModelState = (data = null) => {
    let contactInfo = [];

    if (data?.contactInfo?.length) {
      data.contactInfo.forEach((each) => {
        contactInfo.push({
          contactNumber: each.contactNumber || "",
          pointOfContact: each.pointOfContact || "",
          contactEmail: each.contactEmail || "",
        });
      });
    }

    if (!contactInfo?.length) {
      contactInfo = [
        {
          pointOfContact: "",
          contactNumber: "",
          contactEmail: "",
        },
      ];
    }

    this.setState({
      formFields: {
        name: data?.name?.full?.trim() || "",
        picUrl: {
          uploadData: null,
          previewBlob: null,
          type: "image",
          uploadUrl: data?.picUrl || null,
        },
        contactInfo,
      },
      errors: {},
      isDirty: {},
      loading: false,
    });
  };

  _closeModal = () => {
    this._setModelState();
    this.props.toggle();
  };

  componentDidUpdate(previousProps, previousState) {
    const { isOpen, data } = this.props;

    if (isOpen && data && previousProps.isOpen !== isOpen) {
      this._getOrderClientById(data.id);
    }
  }

  _getOrderClientById = (id) => {
    this.setState({ loading: true });

    getOrderClientById(id)
      .then((res) => {
        this._setModelState(res?.lenderClient || {});
      })
      .catch((error) => {
        errorHandler(error);
        this._closeModal();
      });
  };

  _validateFormFields = ({ isValidateAll = false }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      const { formFields, isDirty } = this.state;
      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (isDirty[key] || isValidateAll) {
          switch (key) {
            case "name": {
              if (formFields[key]?.trim().length) {
                if (
                  formFields[key]?.trim().length > 1 &&
                  /^[0-9!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]*$/.test(
                    String(formFields[key]).toLowerCase()
                  ) //eslint-disable-line
                ) {
                  newErrors[key] =
                    "*Name containing only number and special character are not allowed";
                  isFormValid = false;
                } else {
                  newErrors[key] = null;
                  isDirty[key] = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      formFields.contactInfo.forEach((contact, index) => {
        if (isDirty[`pointOfContact_${index}`] || isValidateAll) {
          if (contact.pointOfContact?.trim().length) {
            if (
              RegexConfig.name.test(
                String(contact.pointOfContact).toLowerCase()
              )
            ) {
              newErrors[`pointOfContact_${index}`] = null;
              isDirty[`pointOfContact_${index}`] = false;
            } else {
              newErrors[`pointOfContact_${index}`] =
                "*Minimum 2 characters & Maximum 25 characters, number and special character not allowed except '";
              isFormValid = false;
            }
          } else {
            newErrors[`pointOfContact_${index}`] = null;
            isDirty[`pointOfContact_${index}`] = false;
          }
        }
        if (isDirty[`contactNumber_${index}`] || isValidateAll) {
          if (contact.contactNumber?.trim().length) {
            if (
              RegexConfig.phone.test(
                String(contact.contactNumber).toLowerCase()
              )
            ) {
              newErrors[`contactNumber_${index}`] = null;
              isDirty[`contactNumber_${index}`] = false;
            } else {
              newErrors[`contactNumber_${index}`] = "*Invalid phone number";
              isFormValid = false;
            }
          } else {
            newErrors[`contactNumber_${index}`] = "*Required";
            isFormValid = false;
          }
        }
        if (isDirty[`contactEmail_${index}`] || isValidateAll) {
          if (contact.contactEmail?.trim().length) {
            if (
              RegexConfig.email.test(String(contact.contactEmail).toLowerCase())
            ) {
              newErrors[`contactEmail_${index}`] = null;
              isDirty[`contactEmail_${index}`] = false;
            } else {
              newErrors[`contactEmail_${index}`] = "*Invalid email";
              isFormValid = false;
            }
            let ind = formFields.contactInfo.findIndex(
              (item) => item.contactEmail === contact.contactEmail
            );
            if (ind !== index) {
              newErrors[`contactEmail_${index}`] = `*Dupliate Email found at ${
                ind + 1
              }`;
              isFormValid = false;
            }
          } else {
            newErrors[`contactEmail_${index}`] = "*Required";
            isFormValid = false;
          }
        }
      });

      this.setState({ isDirty, errors: newErrors }, () => {
        resolve(isFormValid);
      });
    });
  };

  _addContactInfo = () => {
    const { formFields } = this.state;
    formFields.contactInfo.push({
      pointOfContact: "",
      contactNumber: "",
      contactEmail: "",
    });
    this.setState({ formFields });
  };

  _removeContactInfo = (index) => {
    const { formFields } = this.state;
    formFields.contactInfo.splice(index, 1);
    this.setState({ formFields });
  };

  _onChangeFormFields = (key, value, subKey, index) => {
    const { formFields } = this.state;
    if (key === "contactInfo") {
      formFields[key][index][subKey] = value;
    } else {
      formFields[key] = value;
    }
    this.setState({ formFields });
  };

  _onBlurFormFields = (key) => {
    const { isDirty } = this.state;
    isDirty[key] = true;
    this.setState({ isDirty }, () => {
      this._validateFormFields({ isValidateAll: false });
    });
  };

  _onChangeAvatar = (e) => {
    try {
      if (e?.target?.files?.length) {
        const { formFields } = this.state;

        const file = e.target.files[0];

        const fileType = file.type.split("/")[0];

        if (fileType === "image") {
          const previewBlob = URL.createObjectURL(file);

          formFields.picUrl["uploadData"] = file;
          formFields.picUrl["previewBlob"] = previewBlob;
          formFields.picUrl["type"] = fileType;
          formFields.picUrl["uploadUrl"] = null;
        } else {
          showToast("Only image file is allowed", "error");
          return;
        }

        this.setState({ formFields });
      }
    } catch (error) {
      errorHandler();
    }
  };

  _onSubmit = async () => {
    try {
      const { formFields } = this.state;

      const isFormValid = await this._validateFormFields({
        isValidateAll: true,
      });

      if (!isFormValid) return;

      this.setState({ loading: true });

      const { first, last } = splitName(formFields.name.trim());

      const payload = {
        name: {
          first,
          last,
        },
        picUrl: "",
        contactInfo: [...formFields.contactInfo],
      };

      if (formFields.picUrl.uploadData) {
        const response = await uploadFileOnServer([{ ...formFields.picUrl }]);

        payload["picUrl"] = response[0].url;

        formFields.picUrl["uploadUrl"] = response[0].url;
        formFields.picUrl["uploadData"] = null;

        this.setState({ formFields });
      } else if (formFields.picUrl.uploadUrl) {
        payload["picUrl"] = formFields.picUrl.uploadUrl;
      }

      if (this.props.data?.id) {
        await updateLenderClient({ id: this.props.data?.id, payload });
        showToast("Client has been updated", "success");
      } else {
        await createLenderClient(payload);
        showToast("Client has been created", "success");
      }

      if (this.props.onSuccess) this.props.onSuccess();

      this._closeModal();
    } catch (error) {
      this.setState({ loading: false });
      errorHandler(error);
    }
  };

  render() {
    const { formFields, errors, loading } = this.state;
    const { isOpen } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={() => this._closeModal()}
        className="modal-dialog-centered"
        scrollable
      >
        <ModalHeader toggle={() => this._closeModal()}>
          {this.props.data ? "Edit" : "Add"} Client
        </ModalHeader>
        <ModalBody>
          <Form>
            <Label className="uploadProfile">
              <Input
                type="file"
                style={{ display: "none" }}
                accept="image/x-png,image/gif,image/jpeg"
                value=""
                onChange={(e) => this._onChangeAvatar(e)}
                disabled={loading}
              />
              <img
                src={
                  formFields.picUrl.previewBlob ||
                  formFields.picUrl.uploadUrl ||
                  DEFAULT_COVER_PICTURE
                }
                alt="Profile Img"
              />
              <i className="fa fa-pencil" />
            </Label>

            <FormGroup className="floatingLabel">
              <Input
                type="text"
                placeholder=" "
                name="name"
                value={formFields.name}
                onChange={(e) =>
                  this._onChangeFormFields("name", e.target.value)
                }
                onBlur={() => this._onBlurFormFields("name")}
                disabled={loading}
              />
              <Label>Client Name</Label>
              {errors["name"] ? (
                <div className="validation-error">{errors["name"]}</div>
              ) : null}
            </FormGroup>

            {formFields.contactInfo.map((contact, index) => (
              <>
                <div className="clientAdditionalContacts" key={index}>
                  <FormGroup className="floatingLabel">
                    <Input
                      type="text"
                      placeholder=" "
                      name="name"
                      value={contact.pointOfContact}
                      onChange={(e) =>
                        this._onChangeFormFields(
                          "contactInfo",
                          e.target.value,
                          "pointOfContact",
                          index
                        )
                      }
                      onBlur={() =>
                        this._onBlurFormFields(`pointOfContact_${index}`)
                      }
                      disabled={loading}
                    />
                    <Label>Point of contact</Label>
                    {errors[`pointOfContact_${index}`] ? (
                      <div className="validation-error">
                        {errors[`pointOfContact_${index}`]}
                      </div>
                    ) : null}
                  </FormGroup>

                  <FormGroup className="floatingLabel withInputGroup">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>+1</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder=" "
                        name="contactNumber"
                        value={contact.contactNumber}
                        onChange={(e) =>
                          this._onChangeFormFields(
                            "contactInfo",
                            e.target.value,
                            "contactNumber",
                            index
                          )
                        }
                        onBlur={() =>
                          this._onBlurFormFields(`contactNumber_${index}`)
                        }
                        disabled={loading}
                      />
                      <Label>Contact Number</Label>
                    </InputGroup>
                    {errors[`contactNumber_${index}`] ? (
                      <div className="validation-error">
                        {errors[`contactNumber_${index}`]}
                      </div>
                    ) : null}
                  </FormGroup>

                  <FormGroup className="floatingLabel">
                    <Input
                      type="text"
                      placeholder=" "
                      name="email"
                      value={contact.contactEmail}
                      onChange={(e) =>
                        this._onChangeFormFields(
                          "contactInfo",
                          e.target.value,
                          "contactEmail",
                          index
                        )
                      }
                      onBlur={() =>
                        this._onBlurFormFields(`contactEmail_${index}`)
                      }
                      disabled={loading}
                    />
                    <Label>Contact Email</Label>
                    {errors[`contactEmail_${index}`] ? (
                      <div className="validation-error">
                        {errors[`contactEmail_${index}`]}
                      </div>
                    ) : null}
                  </FormGroup>
                  {formFields.contactInfo.length > 1 ? (
                    <Button
                      color="danger"
                      className="btn-round"
                      outline
                      onClick={() => this._removeContactInfo(index)}
                    >
                      Delete
                    </Button>
                  ) : null}
                </div>
                {formFields.contactInfo.length - 1 === index ? (
                  <div className="text-center mb-2 mt-2">
                    <Button
                      color="link"
                      className="fs-12"
                      onClick={() => this._addContactInfo()}
                    >
                      +Add Additional Contacts
                    </Button>
                  </div>
                ) : null}
              </>
            ))}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            size="lg"
            outline
            onClick={() => this._closeModal()}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => this._onSubmit()}
            disabled={loading}
            size="lg"
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
            {this.props.data ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddOrderClient;
