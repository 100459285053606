import React, { useEffect, useState } from "react";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { getPhoneNumberFromBrackets } from "../../../../../helper-methods";
import CustomCard from "../../CustomCard";
import CustomTable from "../../CustomTable";

const teamMembersHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "name", label: "Name", noSort: true },
  { id: "email", label: "Email", noSort: true },
  { id: "phone", label: "Phone", noSort: true },
];

const teamMembersCardHeaderKeys = [
  { id: "id", label: "id" },
  { id: "name", label: "Name" },
];

export const TeamMembers = ({ isOpen, data, toggle, clientTeamMembers }) => {
  console.log({ data });

  const [teamMembers, setTeamMembers] = useState({
    data: [],
    totalCount: null,
  });
  const [tableConfig, setTableConfig] = useState({
    skip: 0,
    limit: 10,
    pageNumber: 1,
  });

  const _closeModal = () => {
    // this._setModalState();
    toggle();
  };

  const _paginate = (pageNumber = 1, pageSize = 10) => {
    const newTableConfig = { ...tableConfig };

    newTableConfig["skip"] = (pageNumber - 1) * pageSize;
    newTableConfig["limit"] = pageSize;
    newTableConfig["pageNumber"] = pageNumber;

    let newStatsList = clientTeamMembers?.slice(
      (pageNumber - 1) * pageSize,
      newTableConfig.skip + pageSize
    );
    console.log("new", newStatsList);
    setTeamMembers({
      ...teamMembers,
      data: newStatsList,
    });

    setTableConfig(newTableConfig);
  };

  useEffect(() => {
    setTeamMembers({
      data: clientTeamMembers ? clientTeamMembers.slice(0, 10) : [],
      totalCount: clientTeamMembers?.length,
    });
  }, [clientTeamMembers]);

  const _dataFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return row?._user?.name?.full ? (
          <p>{row?._user?.name?.full}</p>
        ) : row?.name ? (
          <p>{row?.name?.full || row?.name?.first + " " + row?.name?.last}</p>
        ) : (
          "N/A"
        );
      }

      case "email": {
        return row?.email ? <p>{row?.email}</p> : "N/A";
      }

      case "phone": {
        return row?._user?.phone ? <p>{row?._user?.phone}</p> : "N/A";
      }

      default: {
        return cell;
      }
    }
  };

  const _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return row ? <span>{row?._user?.name?.full || "N/A"}</span> : "N/A";
      }

      default: {
        return cell;
      }
    }
  };

  const _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Email</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row?.email ? (
                  <>
                    <div
                      style={{
                        minWidth: 80,
                        whiteSpace: "normal",
                      }}
                    >
                      {row?.email || "N/A"}
                    </div>
                  </>
                ) : (
                  "N/A"
                )}
              </span>
            </div>
          </div>

          <div className="dataWrap">
            <Label>Phone</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row?._user?.phone ? (
                  <>
                    <div
                      style={{
                        minWidth: 80,
                        whiteSpace: "normal",
                      }}
                    >
                      {row?._user?.phone || "N/A"}
                    </div>
                  </>
                ) : (
                  "N/A"
                )}
              </span>
            </div>
          </div>
        </li>
      </ul>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalHeader toggle={() => _closeModal()}>Client Members</ModalHeader>
      <ModalBody>
        {console.log("team", teamMembers, tableConfig)}
        <div className="hideMobile">
          {teamMembersHeaderKeys && teamMembersHeaderKeys?.length && (
            <CustomTable
              // striped
              isPagination={true}
              isPageStartFromOne={true}
              pageNumber={tableConfig?.pageNumber}
              pageSize={tableConfig?.limit}
              tableData={teamMembers?.data}
              headerKeys={teamMembersHeaderKeys}
              dataFormat={_dataFormat}
              totalCount={teamMembers?.totalCount}
              rowSelection={false}
              onPaginate={(pageNumber, pageSize) =>
                _paginate(pageNumber, pageSize)
              }
            // showTableLoading={loading?.dataLoading}
            />
          )}
        </div>

        <div className="hideDesktop">
          <CustomCard
            columnSizeMD={12}
            isPagination={true}
            isPageStartFromOne={true}
            pageNumber={tableConfig?.pageNumber}
            pageSize={tableConfig?.limit}
            tableData={teamMembers?.data}
            dataFormat={_dataFormat}
            headerKeys={teamMembersCardHeaderKeys}
            totalCount={teamMembers?.totalCount}
            onPaginate={(pageNumber, pageSize) =>
              _paginate(pageNumber, pageSize)
            }
            // showTableLoading={loading?.dataLoading}
            cardHeaderFormat={_cardHeaderFormat}
            cardDataFormat={_cardDataFormat}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline onClick={() => _closeModal()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
