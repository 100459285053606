import React, { useEffect } from "react";
import { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Input,
  FormGroup,
  Card,
  CardBody,
} from "reactstrap";
import { errorHandler, showToast } from "../../../helper-methods";
import {
  updateClientSettings,
  updateSigningCompanySetting,
} from "../../../http/http-calls";
import TextEditor from "./TextEdittor";

const AddInstructionsModal = ({
  isOpen,
  toggle,
  clientSetting,
  clientSettingData,
  companySettings,
  companySettingsData,
  onSuccess,
}) => {
  const [signingInstruction, setSigningInstruction] = useState([
    {
      tempId: Date.now(),
      name: "",
      description: "",
      isDefault: true,
    },
  ]);
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const _setInstructionSetting = (data) => {
    console.log("Dfdf", data);
    if (data?.length) {
      setSigningInstruction([...data]);
    } else {
      setSigningInstruction([
        {
          tempId: Date.now(),
          name: "",
          description: "",
          isDefault: false,
        },
      ]);
    }
    setLoading(false);
  };

  const _closeModal = () => {
    toggle();
  };

  const _validateInstruction = ({
    newSigningInstruction,
    newIsDirty = {},
    isValidateAll = false,
  }) => {
    return new Promise((resolve) => {
      const newErrors = {};
      let isFormValid = true;

      newSigningInstruction.forEach((each, index) => {
        if (isValidateAll || newIsDirty[`name_${index}`]) {
          if (each.name?.trim().length) {
            newErrors[`name_${index}`] = null;
            newIsDirty[`name_${index}`] = false;
          } else {
            newErrors[`name_${index}`] = "*Required";
            isFormValid = false;
          }
        }
        if (isValidateAll || newIsDirty[`description_${index}`]) {
          if (each.description?.trim().length) {
            newErrors[`description_${index}`] = null;
            newIsDirty[`description_${index}`] = false;
          } else {
            newErrors[`description_${index}`] = "*Required";
            isFormValid = false;
          }
        }
      });

      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));

      resolve(isFormValid);
    });
  };

  const _onBlurInstruction = (key) => {
    const newIsDirty = { ...isDirty };
    newIsDirty[key] = true;
    setIsDirty(newIsDirty);
    const newSigningInstruction = [...signingInstruction];
    _validateInstruction({ newIsDirty, newSigningInstruction });
  };

  const _onChangeInstruction = ({ key, value, index }) => {
    const newSigningInstruction = [...signingInstruction];
    newSigningInstruction[index][key] = value;
    setSigningInstruction(newSigningInstruction);
  };

  const _addInstruction = () => {
    const newSigningInstruction = [...signingInstruction];
    newSigningInstruction.unshift({
      tempId: Date.now(),
      name: "",
      description: "",
      isDefault: false,
    });
    setSigningInstruction(newSigningInstruction);
  };

  const _removeInstruction = (index) => {
    const newSigningInstruction = [...signingInstruction];
    newSigningInstruction.splice(index, 1);
    setSigningInstruction(newSigningInstruction);
  };

  // eslint-disable-next-line no-unused-vars
  const _makeDefaultInstruction = (index) => {
    const newSigningInstruction = [...signingInstruction];
    newSigningInstruction.forEach((each) => (each.isDefault = false));
    newSigningInstruction[index].isDefault = true;
    setSigningInstruction(newSigningInstruction);
  };

  const _onSave = async () => {
    try {
      const newSigningInstruction = [...signingInstruction];

      const isFormValid = await _validateInstruction({
        newSigningInstruction,
        isValidateAll: true,
      });

      if (!isFormValid) {
        showToast("Please fill all form", "error");
        return;
      }

      setLoading(true);

      if (clientSetting) {
        // client

        signingInstruction.forEach((newObj) => {
          const existingIndex = clientSetting.signingInstruction.findIndex(
            (obj) => obj._id === newObj._id
          );
          if (existingIndex !== -1) {
            // If an object with the id exists, update its properties
            clientSetting.signingInstruction[existingIndex] = {
              ...clientSetting.signingInstruction[existingIndex],
              ...newObj,
            };
          } else {
            // If no object with the id exists, add a new object to the first array
            clientSetting.signingInstruction.push(newObj);
          }
        });

        const defaultObject = clientSetting.signingInstruction.find(
          (obj) => obj.tempId && obj.isDefault === true
        );

        if (defaultObject) {
          // Set isDefault to false for all other objects
          clientSetting.signingInstruction.forEach((obj) => {
            if (!obj.tempId) {
              obj.isDefault = false;
            }
          });
        }

        // console.log("cc", payload);
        await updateClientSettings({
          signingInstruction: clientSetting.signingInstruction,
        });
      } else {
        // company
        signingInstruction.forEach((newObj) => {
          const existingIndex = companySettings.signingInstruction.findIndex(
            (obj) => obj._id === newObj._id
          );
          if (existingIndex !== -1) {
            // If an object with the id exists, update its properties
            companySettings.signingInstruction[existingIndex] = {
              ...companySettings.signingInstruction[existingIndex],
              ...newObj,
            };
          } else {
            // If no object with the id exists, add a new object to the first array
            companySettings.signingInstruction.push(newObj);
          }
        });

        const defaultObject = companySettings.signingInstruction.find(
          (obj) => obj.tempId && obj.isDefault === true
        );

        if (defaultObject) {
          // Set isDefault to false for all other objects
          companySettings.signingInstruction.forEach((obj) => {
            if (!obj.tempId) {
              obj.isDefault = false;
            }
          });
        }
        await updateSigningCompanySetting({
          signingInstruction: companySettings.signingInstruction,
        });
      }

      showToast("Instruction has been updated", "success");

      onSuccess();
      _closeModal();
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (clientSetting) {
        // client
        _setInstructionSetting(clientSettingData);
      } else if (companySettings) {
        // company
        _setInstructionSetting(companySettingsData);
      }
    }
  }, [isOpen, clientSetting, companySettings]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered"
      scrollable
      size="lg"
    >
      <ModalHeader toggle={() => _closeModal()}>
        Manage Instructions
      </ModalHeader>
      <ModalBody>
        {signingInstruction.map((each, index) => (
          <Card key={each.tempId || each._id + index}>
           
            <CardBody>
              <FormGroup className="floatingLabel">
                <Input
                  type="text"
                  placeholder=" "
                  value={each.name}
                  onChange={(e) =>
                    _onChangeInstruction({
                      key: "name",
                      value: e.target.value,
                      index,
                    })
                  }
                  onBlur={() => _onBlurInstruction(`name_${index}`)}
                />
                <Label>Name of Instruction</Label>
                {errors[`name_${index}`] ? (
                  <div className="validation-error">
                    {errors[`name_${index}`]}
                  </div>
                ) : null}
              </FormGroup>

              <FormGroup className="floatingLabel">
                <TextEditor
                  content={each.description}
                  placeholder=" "
                  onChange={(e) =>
                    _onChangeInstruction({
                      key: "description",
                      value: e,
                      index,
                    })
                  }
                  onBlur={(e) => _onBlurInstruction(`description_${index}`)}
                />

                {errors[`description_${index}`] ? (
                  <div className="validation-error">
                    {errors[`description_${index}`]}
                  </div>
                ) : null}
              </FormGroup>
            </CardBody>
          </Card>
        ))}
        {/* <Row>
          <Col sm="12">
            {signingInstruction.map((each, index) => (
              <div
                className="manageInstructionWrap"
                key={each.tempId || each._id + index}
              >
                <div className="d-flex justify-content-end">
                  {each.isDefault ? (
                    <Button color="link" className="px-2">
                      <span style={{ color: "#2c4199" }}>Default</span>
                    </Button>
                  ) : (
                    <>
                      <Button
                        color="link"
                        className="px-2"
                        onClick={() => _makeDefaultInstruction(index)}
                      >
                        <span style={{ color: "#2c4199" }}>Make Default</span>
                      </Button>
                      <Button
                        color="link"
                        className="px-2 ml-2"
                        onClick={() => _removeInstruction(index)}
                      >
                        <i className="fa fa-trash-o text-danger" />
                      </Button>
                    </>
                  )}
                </div>
                <FormGroup>
                  <Label>Name of Instruction</Label>
                  <Input
                    type="text"
                    placeholder="Enter the name"
                    value={each.name}
                    onChange={(e) =>
                      _onChangeInstruction({
                        key: "name",
                        value: e.target.value,
                        index,
                      })
                    }
                    onBlur={() => _onBlurInstruction(`name_${index}`)}
                  />
                  {errors[`name_${index}`] ? (
                    <div className="validation-error">
                      {errors[`name_${index}`]}
                    </div>
                  ) : null}
                </FormGroup>

                <FormGroup>
                  <Label>Add Instructions</Label>

                  <TextEditor
                    content={each.description}
                    onChange={(e) =>
                      _onChangeInstruction({
                        key: "description",
                        value: e,
                        index,
                      })
                    }
                    onBlur={(e) => _onBlurInstruction(`description_${index}`)}
                  />
                  {errors[`description_${index}`] ? (
                    <div className="validation-error">
                      {errors[`description_${index}`]}
                    </div>
                  ) : null}
                </FormGroup>
              </div>
            ))}
          </Col>
        </Row> */}

        {/* <Button style={{textTransform:'capitalize'}} color="link" onClick={() => _addInstruction()}>
          <i className="fa fa-plus" style={{fontSize:12}}></i>
          New Instruction
        </Button> */}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="lg" outline onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          color="primary"
          size="lg"
          onClick={() => _onSave()}
          disabled={loading}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null} Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddInstructionsModal;
