import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Input,
  FormGroup,
  Form,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
} from "reactstrap";
import usaStates from "../../../config/usa_states_titlecase";
import { deepClone } from "../../../helper-methods";
import ProductType from "./ProductType";
import { getAllConnectedListforCompany } from "../../../http/http-calls";
import { loanTypeConfig } from "../../../config";

class FeeRateModal extends Component {
  state = {
    loading: false,
    formFields: {
      client: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      county: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      offerRate: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      state: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      signingType: {
        value: "Mobile",
        isDirty: false,
        isValidate: true,
      },
      productType: {
        value: {},
        isDirty: false,
        isValidate: true,
      },
      loanCategories: {
        value: [],
        error: null,
        isDirty: false,
        isValidate: false,
      },
    },
  };

  _closeModal = (action) => {
    // this._resetModalState();
    this.props.toggle();
  };

  _handleOnChange = (fieldName, value) => {
    // console.log("qwqwq ", fieldName, value);
    const { formFields } = deepClone(this.state);

    formFields[fieldName].value = value;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields });
  };

  _standardFeeRates = async () => {
    const { formFields } = deepClone(this.state);
    await this._markAllFieldDirty();
    let payload = {
      standardFeeRates: {
        _client:
          formFields.client.value !== "" ? formFields.client.value : undefined,
        isForClient: this.props.activeTab === "2" ? true : false,
        state: formFields.state.value,
        county: formFields.county.value,
        signingType: formFields.signingType.value,
        // productType: formFields.productType.value,
        offerRate: formFields.offerRate.value,
        productCategories: formFields?.productType?.value?.category,
        productType: formFields?.productType?.value?.value,
      },
    };
    let isFormValid = await this._validateForm();
    if (isFormValid) {
      if (this.props.data !== null) {
        this.props.updateStandardFeeRate(payload.standardFeeRates);
      } else {
        this.props.handleSubmit(payload);
      }
    }
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty && formFields[key].isValidate) {
          switch (key) {
            // case "state": {
            //   if (
            //     formFields[key].value &&
            //     formFields[key].value.trim().length
            //   ) {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   } else {
            //     formFields[key].isDirty = true;
            //     formFields[key].error = "*Required";
            //     isFormValid = false;
            //   }
            //   break;
            // }
            // case "county": {
            //   if (
            //     formFields[key].value &&
            //     formFields[key].value.trim().length
            //   ) {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   } else {
            //     formFields[key].isDirty = true;
            //     formFields[key].error = "*Required";
            //     isFormValid = false;
            //   }
            //   break;
            // }
            case "productType": {
              if (
                formFields[key]?.value?.value &&
                formFields[key]?.value?.value?.trim()?.length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "signingType": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "offerRate": {
              if (formFields[key].value && formFields[key].value.length) {
                if (
                  isNaN(formFields[key].value) ||
                  Number(formFields[key].value) < 0
                ) {
                  formFields[key].isDirty = true;
                  formFields[key].error =
                    "*OfferRate must be a positive number";
                  isFormValid = false;
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            default: {
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  componentDidUpdate = async (previousProps, previousState) => {
    const { isOpen, data } = this.props;
    if (isOpen && previousProps.isOpen !== isOpen) {
      // this.setState({ type: data.type });
      if (data !== null) {
        console.log("qas", data);
        const { formFields } = deepClone(this.state);
        formFields["client"].value = data?._client?._id;
        formFields["county"].value = data.county;
        formFields["offerRate"].value = data.offerRate.toString();
        formFields["state"].value = data.state;
        formFields["signingType"].value = data.signingType;
        // formFields["productType"].value = data.productType;
        formFields["productType"].value = {
          value: data?.productType,
          label: data?.productType,
          category: data?.productCategories || "",
        };
        this.setState(
          {
            formFields,
          },
          () => {
            console.log("formfield", formFields);
          }
        );
      } else {
        this._reset();
      }
    }
  };

  _reset = () => {
    this.setState({
      formFields: {
        client: {
          value: "",
          isDirty: false,
          isValidate: false,
        },
        county: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
        offerRate: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
        state: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
        signingType: {
          value: "Mobile",
          isDirty: false,
          isValidate: true,
        },
        productType: {
          value: {},
          isDirty: false,
          isValidate: true,
        },
        loanCategories: {
          value: [],
          error: null,
          isDirty: false,
          isValidate: false,
        },
      },
    });
  };

  render() {
    const { formFields } = deepClone(this.state);
    const { loading, data, loanType } = this.props;
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._closeModal()}
        className="modal-dialog-centered"
      >
        {console.log("qa", this.props)}
        <ModalHeader toggle={() => this._closeModal()}>
          {data ? "Edit" : "Add"} Rate
        </ModalHeader>
        <ModalBody>
          <Form>
            {this.props.activeTab === "2" ? (
              <FormGroup className="floatingLabel">
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    value={formFields.client.value}
                    name="client"
                    onChange={(event) =>
                      this._handleOnChange("client", event.target.value)
                    }
                    onBlur={() => this._validateForm()}
                  >
                    <option value="">Select</option>
                    {this.props.clientList &&
                      this.props.clientList.map((type, index) => (
                        <option key={index} value={type.value}>
                          {type.label}
                        </option>
                      ))}
                  </Input>
                  <Label>Client</Label>
                </div>
              </FormGroup>
            ) : null}
            <FormGroup className="floatingLabel">
              <div className="custom-select-wrapper">
                <Input
                  type="select"
                  value={formFields.state.value}
                  name="state"
                  onChange={(event) =>
                    this._handleOnChange("state", event.target.value)
                  }
                  onBlur={() => this._validateForm()}
                >
                  <option value="">Select</option>
                  {usaStates.map((state) => (
                    <option key={state.abbreviation} value={state.abbreviation}>
                      {state.name}
                    </option>
                  ))}
                </Input>
                <Label>State</Label>
              </div>
              {formFields.state.error && (
                <div className="validation-error">{formFields.state.error}</div>
              )}
            </FormGroup>
            <FormGroup className="floatingLabel">
              <Input
                type="text"
                placeholder=" "
                value={formFields.county.value}
                name="county"
                onChange={(event) =>
                  this._handleOnChange("county", event.target.value)
                }
                onBlur={() => this._validateForm()}
              />
              <Label>City</Label>
              {formFields.county.error && (
                <div className="validation-error">
                  {formFields.county.error}
                </div>
              )}
            </FormGroup>
            <FormGroup className="floatingLabel withInputGroup">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  placeholder=" "
                  value={formFields.offerRate.value}
                  name="offerRate"
                  onChange={(event) =>
                    this._handleOnChange("offerRate", event.target.value)
                  }
                  onBlur={() => this._validateForm()}
                />
                <Label>Offer Rate</Label>
              </InputGroup>
              {formFields.offerRate.error && (
                <div className="validation-error">
                  {formFields.offerRate.error}
                </div>
              )}
            </FormGroup>
            <FormGroup className="floatingLabel">
              <div className="custom-select-wrapper">
                <Input
                  type="select"
                  name="signingType"
                  value={formFields.signingType.value}
                  onChange={(event) =>
                    this._handleOnChange("signingType", event.target.value)
                  }
                >
                  <option value="Mobile">Mobile Signing</option>
                  <option value="RON">RON</option>
                </Input>
                <Label>Signing Type</Label>
              </div>
              {formFields.signingType.error && (
                <div className="validation-error">
                  {formFields.signingType.error}
                </div>
              )}
            </FormGroup>
            {/*<FormGroup className="floatingLabel">
              <div className="custom-select-wrapper">
                <Input
                  type="select"
                  value={formFields.productType.value}
                  name="productType"
                  onChange={(event) =>
                    this._handleOnChange("productType", event.target.value)
                  }
                  onBlur={() => this._validateForm()}
                >
                  {this.props.loanType &&
                    this.props.loanType.map((type, index) => (
                      <option key={index} value={type.value}>
                        {type.label}
                      </option>
                    ))}
                </Input>
                <Label>Product Type</Label>
              </div>
              {formFields.productType.error && (
                <div className="validation-error">
                  {formFields.productType.error}
                </div>
              )}
            </FormGroup> */}
            <ProductType
              isMulti={false}
              value={
                Object.keys(formFields?.productType?.value)?.length
                  ? [formFields?.productType?.value]
                  : []
              }
              productTypeField={"productType"}
              selectedCategoriesField={"loanCategories"}
              onChange={(type, event) =>
                this._handleOnChange(type, event)
              }
              // blur={() => {
              //   console.log("blur function called");
              //   // this._validateForm();
              // }}
              error={formFields?.productType?.error}
              onClickOutside={() => console.log("blur...")}
              // isCompanyFeeSettings={true}
              clientCreateOrderOptions={loanType}
            />
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            size="lg"
            outline
            onClick={() => this._closeModal()}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="lg"
            onClick={() => this._standardFeeRates()}
            disabled={loading}
          >
            {loading ? <i className="fa fa-spinner fa-spin" /> : null}{" "}
            {data ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default FeeRateModal;
