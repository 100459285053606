import React from "react";
import PerfectScrollbar from "perfect-scrollbar";
import { Redirect, Route, Switch } from "react-router-dom";

import AdminNavbar from "../components/AdminNavbar";
import Footer from "../components/Footer.js";
import Sidebar from "../components/Sidebar.js";

import { clientRoutes } from "../../../routes";
import Notifications from "../pages/notifications";
import ContactUs from "../pages/contact-us";
import FAQCOMPANY from "../pages/faq-company";
import ClientSettings from "../pages/ClientSettings";
import ProtectedRoute from "../../general/components/protected-route";
import { connect } from "react-redux";
import MediaQueueViewer from "../containers/media-queue-viewer";
import OrderDetails from "../pages/OrderDetails";
import CreateOrder from "../pages/CreateOrder";
import SigningCompanyDetails from "../pages/SigningCompanyDetails";
import Help from "../pages/HelpModule/Help";
import TopicList from "../pages/HelpModule/TopicList";
import TopicDetails from "../pages/HelpModule/TopicDetails";
import Gleap from "gleap";
import { GLEAP_CLIENT_ID } from "../../../config/index.js";
import SystemGeneratedPasswordModal from "../components/SystemGeneratedPasswordModal.jsx";

var ps;

class clientLayout extends React.Component {
  state = {
    backgroundColor: "black",
    activeColor: "info",
    sidebarMini: false,
    systemGeneratedPasswordModal: false,
  };

  componentDidMount() {
    Gleap.initialize(GLEAP_CLIENT_ID);
    if (navigator.platform.indexOf("Win") > -1) {
      document.documentElement.className += " perfect-scrollbar-on";
      document.documentElement.classList.remove("perfect-scrollbar-off");
      ps = new PerfectScrollbar(this.refs.mainPanel);
    }

    // Open Modal for Change System Generated Password
    if (this?.props?.userData?.user?.isSystemGeneratedPassword) {
      this.setState({ systemGeneratedPasswordModal: true });
    }
  }

  componentWillUnmount() {
    if (navigator.platform.indexOf("Win") > -1) {
      ps.destroy();
      document.documentElement.className += " perfect-scrollbar-off";
      document.documentElement.classList.remove("perfect-scrollbar-on");
    }
  }

  componentDidUpdate(e) {
    if (e.history.action === "PUSH") {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainPanel.scrollTop = 0;
    }
  }

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      // if (prop.layout === "/admin") {
      return (
        <ProtectedRoute
          path={prop.path}
          component={prop.component}
          key={key}
          redirectRoute={"/login"}
        />
      );
      // } else {
      //   return null;
      // }
    });
  };

  handleActiveClick = (color) => {
    this.setState({ activeColor: color });
  };

  handleBgClick = (color) => {
    this.setState({ backgroundColor: color });
  };

  handleMiniClick = () => {
    if (document.body.classList.contains("sidebar-mini")) {
      this.setState({ sidebarMini: false });
    } else {
      this.setState({ sidebarMini: true });
    }
    document.body.classList.toggle("sidebar-mini");
  };

  render() {
    const { userData } = this.props;

    return (
      <div className="wrapper">
        <Sidebar
          {...this.props}
          routes={clientRoutes}
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
        />
        <div className="main-panel" ref="mainPanel">
          <AdminNavbar
            {...this.props}
            userData={userData}
            handleMiniClick={this.handleMiniClick}
          />

          <Switch>
            {this.getRoutes(clientRoutes)}

            <ProtectedRoute
              path={`${this.props.match.path}/order-details/:id`}
              component={OrderDetails}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/create-order`}
              component={CreateOrder}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/notifications`}
              component={Notifications}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/contact`}
              component={ContactUs}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/faq`}
              component={FAQCOMPANY}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/settings`}
              component={ClientSettings}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/signing-company-details/:id`}
              component={SigningCompanyDetails}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/help`}
              component={Help}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/topic-list/:id`}
              component={TopicList}
              redirectRoute={"/login"}
            />

            <ProtectedRoute
              path={`${this.props.match.path}/topic/:id`}
              component={TopicDetails}
              redirectRoute={"/login"}
            />
            {/* <Redirect from="/" to="/login" /> */}
            <Route
              path="/"
              render={() => <Redirect to="/client/order-dashboard" />}
            />
          </Switch>
          <MediaQueueViewer />
          {
            // we don't want the Footer to be rendered on full screen maps page
            this.props.location.pathname.indexOf("full-screen-map") !==
            -1 ? null : (
              <Footer fluid />
            )
          }
          {this.state.systemGeneratedPasswordModal ? (
            <SystemGeneratedPasswordModal
              isOpen={this.state.systemGeneratedPasswordModal}
              toggle={() =>
                this.setState({ systemGeneratedPasswordModal: false })
              }
            />
          ) : null}
        </div>
        {/* <FixedPlugin
          bgColor={this.state.backgroundColor}
          activeColor={this.state.activeColor}
          sidebarMini={this.state.sidebarMini}
          handleActiveClick={this.handleActiveClick}
          handleBgClick={this.handleBgClick}
          handleMiniClick={this.handleMiniClick}
        /> */}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps, null)(clientLayout);
