import React, { useEffect } from "react";
import { useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { itemListOptionsConfig } from "../../../../config";
import {
  errorHandler,
  formatDate,
  showToast,
  uploadFileOnServer,
} from "../../../../helper-methods";
import {
  addAgentExpenses,
  getAgentExpenses,
} from "../../../../http/http-calls";
import CreatableSelect from "react-select/creatable";
import ReactDatetime from "react-datetime";

const AddExpenseModal = ({
  isOpen,
  data,
  toggle,
  getAllAgentExpensesForClosing,
  closingId,
}) => {
  const [formFields, setFormFields] = useState({
    item: {
      value: "",
      error: null,
      isDirty: false,
    },
    date: {
      value: "",
      error: null,
      isDirty: false,
    },
    amount: {
      value: "",
      error: null,
      isDirty: false,
    },
    receipt: {
      file: {
        previewBlob: null,
        uploadData: null,
        type: null,
      },
      error: null,
    },
  });
  const [loading, setLoading] = useState(false);
  const [isOpenReactDateTime, setIsOpenReactDateTime] = useState(false);
  const [seed, setSeed] = useState(0); // used to refresh the component, can be given any value

  const [expensesList, setExpensesList] = useState([]);

  const _getAgentExpenses = async () => {
    try {
      const res = await getAgentExpenses(closingId);
      setExpensesList(res?.expenseTypes);
      // console.log("qwerty", { res });
    } catch (err) {
      console.log({ err });
    }
  };

  useEffect(() => {
    if (isOpen) {
      _getAgentExpenses();
    }
  }, [isOpen]);

  const _setModalState = () => {
    setFormFields({
      item: {
        value: "",
        error: null,
      },
      date: {
        value: "",
        error: null,
      },
      amount: {
        value: "",
        error: null,
      },
      receipt: {
        file: {
          previewBlob: null,
          uploadData: null,
          type: null,
        },
        error: null,
      },
    });
    setLoading(false);
  };

  const _closeModal = () => {
    _setModalState();
    toggle();
  };

  const _onChangeExpenseForm = (fieldName, value) => {
    const newFormFields = { ...formFields };

    if (fieldName === "amount") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }

    newFormFields[fieldName].value = value;
    newFormFields[fieldName].isDirty = true;
    setFormFields(newFormFields);

    _validateAgentExpenseForm({ newFormFields });
  };

  const _updateFile = async (event) => {
    const newFormFields = { ...formFields };

    let objFile, objFileType;

    if (event?.target?.files?.length) {
      objFile = event.target.files[0];
      objFileType = objFile.type.split("/")[0];

      if (objFileType !== "image" && !objFile.type.includes("pdf")) {
        showToast("Only Image or PDF file is allowed", "error");
        return;
      }

      newFormFields.receipt.file = {
        previewBlob: URL.createObjectURL(objFile),
        uploadData: objFile,
        type: objFileType === "application" ? "pdf" : objFileType,
      };

      setFormFields(newFormFields);
    }
  };

  const _validateAgentExpenseForm = ({ newFormFields }) => {
    return new Promise((resolve) => {
      let isFormValid = true;

      Object.keys(newFormFields).forEach((key) => {
        switch (key) {
          case "item": {
            if (newFormFields[key].isDirty) {
              if (
                newFormFields[key].value &&
                newFormFields[key].value.trim().length
              ) {
                newFormFields[key].error = null;
                newFormFields[key].isDirty = false;
              } else {
                newFormFields[key].error = "*Required";
                isFormValid = false;
              }
            }
            break;
          }
          case "amount": {
            if (newFormFields[key].isDirty) {
              if (
                newFormFields[key].value &&
                newFormFields[key].value.trim().length
              ) {
                if (
                  isNaN(newFormFields[key].value) ||
                  Number(newFormFields[key].value) <= 0
                ) {
                  newFormFields[key].error =
                    "*Amount must be a positive number & greater than 0";
                  isFormValid = false;
                } else {
                  newFormFields[key].error = null;
                  newFormFields[key].isDirty = false;
                }
              } else {
                newFormFields[key].error = "*Required";
                isFormValid = false;
              }
            }
            break;
          }
          case "date": {
            if (newFormFields[key]?.isDirty) {
              if (String(newFormFields[key]?.value)?.length) {
                newFormFields[key].isDirty = false;
                newFormFields[key].error = null;
              } else {
                newFormFields[key].isDirty = true;
                newFormFields[key].error = "*Required";
                isFormValid = false;
              }
            }
            break;
          }
          default:
        }
      });

      setSeed(Math.random());
      setFormFields(newFormFields);
      resolve(isFormValid);
    });
  };

  const _markAllFieldDirty = () => {
    return new Promise((resolve) => {
      const newFormFields = { ...formFields };

      Object.keys(newFormFields).forEach((key) => {
        newFormFields[key]["isDirty"] = true;
      });

      setFormFields(newFormFields);
      resolve(newFormFields);
    });
  };
  const _deleteExpenseFile = () => {
    const newFormFields = { ...formFields };

    newFormFields["receipt"] = {
      file: {
        previewBlob: null,
        uploadData: null,
        type: null,
      },
      error: null,
    };

    setFormFields(newFormFields);
  };

  const _addAgentExpenses = async (id) => {
    const newFormFields = await _markAllFieldDirty();

    const isFormValid = await _validateAgentExpenseForm({ newFormFields });

    if (!isFormValid) {
      return;
    }

    setLoading(true);

    const payload = {
      expenses: {
        item: newFormFields.item.value.trim(),
        amount: +newFormFields.amount.value,
        date: newFormFields?.date?.value,
      },
    };

    if (newFormFields?.receipt?.file?.uploadData) {
      const uploadedFilesRes = await uploadFileOnServer([
        newFormFields.receipt.file,
      ]);
      // console.log(uploadedFilesRes[0]);
      payload.expenses["receipt"] = uploadedFilesRes[0].url;
      payload.expenses["docType"] = uploadedFilesRes[0].docType;
      payload.expenses["receiptName"] = uploadedFilesRes[0].title;
    }

    addAgentExpenses(id, payload)
      .then(async (res) => {
        await getAllAgentExpensesForClosing();
        showToast("Expense Updated", "success");
        _closeModal();
      })
      .catch((error) => {
        errorHandler(error);
        setLoading(false);
      });
  };
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: 'unset', // unset the minimum height
    }),
    menu: (provided, state) => ({
      ...provided,
      maxHeight: '200px',
      overflow:200
    }),
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={_closeModal} centered scrollable>
        <ModalHeader toggle={_closeModal}>Add Expense</ModalHeader>
        <ModalBody>
          <FormGroup className="withChips floatingLabel valueAdded">
            <CreatableSelect
              isClearable
              placeholder="Select"
              styles={customStyles}
              className="customMultiSelect"
              value={{
                label: formFields?.item?.value,
                value: formFields?.item?.value,
              }}
              onChange={(event) => _onChangeExpenseForm("item", event.value)}
              onBlur={() =>
                _validateAgentExpenseForm({ newFormFields: { ...formFields } })
              }
              options={expensesList?.map((each) => ({
                label: each,
                value: each,
              }))}

            />
            <Label style={{ zIndex: 3, }}>Item</Label>
            {/* {formFields?.item?.value ? null : <Label>Item</Label>} */}

          </FormGroup>
          {formFields.item.error && (
            <div className="validation-error" key={seed}>
              {formFields.item.error}
            </div>
          )}

          <FormGroup
            className={`floatingLabel ${formFields.date.value || isOpenReactDateTime ? "valueAdded" : ""
              }`}
          >
            <ReactDatetime
              inputProps={{
                className: "form-control",
                placeholder: " ",
                value: formatDate(formFields.date.value),
              }}
              onChange={(e) => _onChangeExpenseForm("date", e._d)}
              closeOnSelect={true}
              isValidDate={(current) => current.isBefore(new Date())}
              timeFormat={false}
              onOpen={() => setIsOpenReactDateTime(true)}
              onClose={() => setIsOpenReactDateTime(false)}
            />
            <Label>Date</Label>
            {formFields.date.error && (
              <div className="validation-error">{formFields.date.error}</div>
            )}
          </FormGroup>

          <FormGroup className="floatingLabel withInputGroup">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i class="fa fa-usd" aria-hidden="true"></i>
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder=" "
                value={formFields.amount.value}
                onChange={(event) =>
                  _onChangeExpenseForm("amount", event.target.value)
                }
                onBlur={() =>
                  _validateAgentExpenseForm({
                    newFormFields: { ...formFields },
                  })
                }
              />
              <Label>Amount</Label>
            </InputGroup>
            {formFields.amount.error && (
              <div className="validation-error" key={seed}>
                {formFields.amount.error}
              </div>
            )}
          </FormGroup>

          <FormGroup>
            <Label
              className="uploadDocument"
              for="uploadFile_add_expense_modal"
            >
              <Input
                type="file"
                id="uploadFile_add_expense_modal"
                accept="image/x-png,image/gif,image/jpeg,.pdf"
                value=""
                disabled={loading}
                onChange={(event) => _updateFile(event)}
              />
              <img
                src={require("../../../../assets/img/uploadIcon.png").default}
                alt="file upload"
              />

              <div className="uploadLabel">
                <h4>Upload Receipt</h4>
                <p>File size must be less than 5mb</p>
              </div>
            </Label>
          </FormGroup>
          {!loading && formFields.receipt.file.previewBlob && (
            <div className="uploadPreview">
              <div className="uploadedImg">
                <img
                  src={
                    formFields.receipt.file.type === "image"
                      ? formFields.receipt.file.previewBlob
                      : require("../../../../assets/img/uploadIcon.png").default
                  }
                  alt="file upload"
                />
              </div>
              <span>2023-01-10.png</span>

              <Button
                color="danger"
                className="deletePreview"
                onClick={_deleteExpenseFile}
              >
                <img
                  src={require("../../../../assets/img/close.png").default}
                  alt="deletePreview"
                />
              </Button>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={() => _closeModal()}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={loading}
            onClick={() => _addAgentExpenses(data?._id)}
          >
            {loading ? <i className="fa fa-spinner fa-spin" /> : null} Add
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AddExpenseModal;
