import React from "react";

import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";
import {
  deepClone,
  formatCurrencyValue,
  formatDate,
  openUrlOnNewTab,
  showToast,
  errorHandler,
} from "../../../helper-methods";
import { capitalize } from "@material-ui/core";
import {
  deleteAgentClosingExpense,
  getNotarialLogByClosingId,
  // agentUpdateClosingMileage,
  updateNotarialLog,
  editMileageAgentClosingDetails,
  createNotarialLogForClosing,
} from "../../../http/http-calls";
import AddExpenseModal from "./Modals/AddExpenseModal";
import CustomPagination from "./CustomPagination";

class MilageExpanceTabs extends React.Component {
  state = {
    activeTab: "Mileage",
    isActualMilesEdit: false,
    show: false,
    NotarialLogFormData: {
      notarialActs: {
        value: "",
        error: null,
      },
      amountPerAct: {
        value: "",
        error: null,
      },
    },
    formFields: {
      miles: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
    },
    loading: {
      statusChangeLoading: false,
      fileUploadLoading: false,
      expensesLoading: false,
      deleteExpensesLoading: false,
      mileageUpdateLoading: false,
      NotarialLogLoading: false,
    },
    notarialLog: null,
    addExpenseModal: {
      isOpen: false,
      data: null,
    },
    closingExpensesPageNumber: 0,
  };

  _toggle = (tab) => {
    const { activeTab } = deepClone(this.state);

    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  _toggleAddExpenseModal = (isOpen = false, data = null) => {
    this.setState({ addExpenseModal: { isOpen, data } });
  };

  componentDidMount = async () => {
    await this._getNotarialLogByClosingId();
  };

  _getNotarialLogByClosingId = (id = null) => {
    if (!id && this.props.closingDetails && this.props.closingDetails.id) {
      id = this.props.closingDetails.id;
    }
    const { NotarialLogFormData } = deepClone(this.state);

    return new Promise((resolve, reject) => {
      getNotarialLogByClosingId(id)
        .then((res) => {
          NotarialLogFormData.notarialActs.value =
            res.notarialLog !== null ? res.notarialLog.notarialActs : "";
          NotarialLogFormData.amountPerAct.value =
            res.notarialLog !== null ? res.notarialLog.amountPerAct : "";
          this.setState({
            notarialLog: res.notarialLog,
            NotarialLogFormData,
          });
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _expenseDataFormat = (cell, row, header) => {
    switch (header) {
      case "item": {
        return cell ? capitalize(cell) : "N/A";
      }
      case "amount": {
        return cell ? formatCurrencyValue(cell) : "N/A";
      }
      case "receipt": {
        return cell ? (
          <Button
            color="link"
            className="actionBtn"
            onClick={() => openUrlOnNewTab(cell)}
          >
            <i className="fa fa-download" />
          </Button>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _onChangeNotarialLogForm = (fieldName, value) => {
    const { NotarialLogFormData } = deepClone(this.state);
    NotarialLogFormData[fieldName].value = value;
    this.setState({ NotarialLogFormData });
  };

  _resetNotarialLogFormData = () => {
    this.setState(
      {
        NotarialLogFormData: {
          notarialActs: {
            value: "",
            error: null,
          },
          amountPerAct: {
            value: "",
            error: null,
          },
        },
      },
      () => {
        this._manageLoading("NotarialLogLoading", false);
      }
    );
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _deleteAgentClosingExpense = (expensesId) => {
    const { closingDetails } = deepClone(this.props);
    this._manageLoading("deleteExpensesLoading", true);

    deleteAgentClosingExpense(closingDetails.id, expensesId)
      .then(async (res) => {
        await this.props.getAllAgentExpensesForClosing(closingDetails.id);
        showToast("Expense Deleted", "success");
        this._manageLoading("deleteExpensesLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("deleteExpensesLoading", false);
      });
  };

  _addNotarialLog = async () => {
    let { NotarialLogFormData } = deepClone(this.state);
    let { closingDetails } = this.props;
    let data = {
      notarialActs: Number(NotarialLogFormData.notarialActs.value),
      amountPerAct: Number(NotarialLogFormData.amountPerAct.value),
    };
    try {
      this._manageLoading("NotarialLogLoading", true);

      await createNotarialLogForClosing(closingDetails.id, data);
      this._resetNotarialLogFormData();
      await this._getNotarialLogByClosingId();
    } catch (error) {
      errorHandler(error);

      this._manageLoading("NotarialLogLoading", false);
    }
  };

  _toggleActualMilesEdit = async () => {
    try {
      const { isActualMilesEdit, formFields } = this.state;
      const { closingDetails } = this.props;
      if (isActualMilesEdit && closingDetails?._mileage?.id) {
        this._manageLoading("mileageUpdateLoading", true);
        let payload = {
          distance:
            formFields.miles.value?.trim() &&
            !isNaN(formFields.miles.value.trim())
              ? Number(formFields.miles.value.trim())
              : 0,
          closingId: closingDetails.id,
        };
        await editMileageAgentClosingDetails(
          closingDetails?._mileage?.id,
          payload
        );
        await this.props.getClosingDetailsById(closingDetails.id);
        this.setState({
          formFields: {
            miles: {
              value: "",
              error: null,
              isDirty: false,
              isValidate: false,
            },
          },
        });
        showToast("Miles Updated", "success");
        this._manageLoading("mileageUpdateLoading", false);
      }

      if (!isActualMilesEdit) {
        formFields["miles"].value = closingDetails?._mileage?.distance
          ? closingDetails?._mileage?.distance?.toString()
          : "0";

        this.setState({
          formFields,
        });
      }

      this.setState({
        isActualMilesEdit: !isActualMilesEdit,
      });
    } catch (error) {
      errorHandler(error);
      this._manageLoading("mileageUpdateLoading", false);
    }
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields } = deepClone(this.state);

    if (fieldName === "agentFee" || fieldName === "miles") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }
    formFields[fieldName].value = value;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields });
  };

  _editNotarialLog = async () => {
    let { NotarialLogFormData } = deepClone(this.state);
    let { closingDetails } = this.props;
    let data = {
      notarialActs: Number(NotarialLogFormData.notarialActs.value),
      amountPerAct: Number(NotarialLogFormData.amountPerAct.value),
    };
    try {
      this._manageLoading("NotarialLogLoading", true);

      await updateNotarialLog(closingDetails.id, data);
      this._resetNotarialLogFormData();
      await this._getNotarialLogByClosingId();
    } catch (error) {
      errorHandler(error);

      this._manageLoading("NotarialLogLoading", false);
    }
  };

  _isNumber = (e) => {
    var charCode = e.which ? e.which : e.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return e.preventDefault();
    return;
  };

  render() {
    const {
      NotarialLogFormData,
      activeTab,
      loading,
      notarialLog,
      isActualMilesEdit,
      formFields,
      addExpenseModal,
      closingExpensesPageNumber,
    } = deepClone(this.state);

    const { closingDetails, closingExpenses } = this.props;

    return (
      <>
        <Nav tabs className="greyBg">
          <NavItem>
            <NavLink
              className={`${activeTab === "Mileage" ? "active" : ""}`}
              onClick={() => {
                this._toggle("Mileage");
              }}
            >
              Mileage
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              onClick={() => {
                this._toggle("Expenses");
              }}
              className={`${activeTab === "Expenses" ? "active" : ""}`}
            >
              Expenses
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              onClick={() => {
                this._toggle("Notarial");
              }}
              className={`${activeTab === "Notarial" ? "active" : ""}`}
            >
              Notarial Acts
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="Mileage">
            <ul className="expensesList">
              <li>
                <div className="ExpenseItem">
                  <span>Estimate</span>
                  <h6>{closingDetails._mileage?.estDistance || 0}</h6>
                </div>
                <div className="ExpenseItem">
                  <span>
                    {" "}
                    Actual Miles
                    <Button
                      color="link"
                      onClick={() => this._toggleActualMilesEdit()}
                      disabled={loading.mileageUpdateLoading}
                    >
                      {isActualMilesEdit ? (
                        <img
                          src={
                            require("../../../assets/img/checkBlueIcon.svg")
                              .default
                          }
                          alt="actualMiles"
                        />
                      ) : (
                        <img
                          src={
                            require("../../../assets/img/pencil.svg").default
                          }
                          alt="actualMiles"
                        />
                      )}
                    </Button>
                  </span>
                  <div className="amount">
                    {isActualMilesEdit ? (
                      <Input
                        type="text"
                        value={formFields.miles.value}
                        name="miles"
                        style={{
                          width: 90,
                          height: 30,
                          marginTop: 5,
                          textAlign: "center",
                        }}
                        disabled={loading.mileageUpdateLoading}
                        placeholder="Enter Miles"
                        onChange={(event) =>
                          this._onChangeFormField("miles", event.target.value)
                        }
                      />
                    ) : (
                      <h6>{closingDetails._mileage?.distance || 0}</h6>
                    )}
                  </div>
                </div>
                <div className="ExpenseItem">
                  <span>Deduction</span>
                  <h6>
                    <div className="amount">
                      {closingDetails._mileage?.amount
                        ? formatCurrencyValue(closingDetails._mileage.amount)
                        : "$0"}
                    </div>
                  </h6>
                </div>
              </li>
            </ul>
          </TabPane>
          <TabPane tabId="Expenses">
            {/* expense list */}
            <ul className="expensesList">
              {closingExpenses?.length ? (
                <>
                  {closingExpenses
                    .slice(
                      closingExpensesPageNumber * 5,
                      closingExpensesPageNumber * 5 + 5
                    )
                    .map((each) => (
                      <li key={each._id}>
                        <div className="ExpenseItem">
                          <span>Item</span>
                          <h6>{each.item}</h6>
                        </div>
                        <div className="ExpenseItem">
                          <span>Added on</span>
                          {/* <h6>Aug 02, 02:01 PM</h6> */}
                          <h6>{each?.date ? formatDate(each?.date) : "N/A"}</h6>
                        </div>
                        <div className="ExpenseItem">
                          <span>Receipt</span>
                          <h6>
                            {each.receipt ? (
                              <>
                                <span
                                  className="text-primary cursorPointer"
                                  onClick={() => openUrlOnNewTab(each.receipt)}
                                >
                                  <img
                                    src={
                                      require("../../../assets/img/pdf.svg")
                                        .default
                                    }
                                    alt="pdf"
                                  />
                                </span>
                                {/* <Button
                                color="link"
                                className="pl-0"
                                onClick={() => openUrlOnNewTab(each.receipt)}>
                              </Button> */}
                              </>
                            ) : (
                              "N/A"
                            )}
                          </h6>
                        </div>
                        <div className="deleteIcon">
                          <Button
                            color="link"
                            title="Delete Expense"
                            disabled={this.state.loading.deleteExpensesLoading}
                            onClick={() =>
                              this._deleteAgentClosingExpense(each._id)
                            }
                          >
                            <img
                              src={
                                require("../../../assets/img/deleteIcon.svg")
                                  .default
                              }
                              alt=""
                            />
                          </Button>
                        </div>
                      </li>
                    ))}
                </>
              ) : (
                <li>
                  <div className="nodata">
                    <img
                      src={require("../../../assets/img/noData.svg").default}
                      alt="no data"
                    />
                    <p>There is no data to display</p>
                  </div>
                </li>
              )}
            </ul>
            <CustomPagination
              data={closingExpenses?.slice(
                closingExpensesPageNumber * 5,
                closingExpensesPageNumber * 5 + 5
              )}
              dataCount={closingExpenses?.length}
              pageNumber={closingExpensesPageNumber}
              pageSize={5}
              onPageChange={(pageNumber = 0) =>
                this.setState({
                  closingExpensesPageNumber: pageNumber,
                })
              }
              isHideForLessData={true}
            />
            <div className="text-center mt-3 mb-3">
              <Button
                size="md"
                color="primary"
                onClick={() =>
                  this._toggleAddExpenseModal(true, closingDetails)
                }
              >
                ADD EXPENSES
              </Button>
            </div>
          </TabPane>
          <TabPane tabId="Notarial">
            <Row>
              <Col xl={6} lg={12}>
                <FormGroup className="floatingLabel">
                  <Input
                    type="Number"
                    placeholder=" "
                    value={NotarialLogFormData.notarialActs.value}
                    onChange={(event) =>
                      this._onChangeNotarialLogForm(
                        "notarialActs",
                        event.target.value
                      )
                    }
                    onKeyPress={(e) => this._isNumber(e)}
                  />
                  <Label>Notarial Acts</Label>
                </FormGroup>
              </Col>
              <Col xl={6} lg={12}>
                <FormGroup className="floatingLabel withInputGroup">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i class="fa fa-usd" aria-hidden="true"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="Number"
                      placeholder=" "
                      value={NotarialLogFormData.amountPerAct.value}
                      onChange={(event) =>
                        this._onChangeNotarialLogForm(
                          "amountPerAct",
                          event.target.value
                        )
                      }
                      onKeyPress={(e) => this._isNumber(e)}
                    />
                    <Label>Amount Per Act</Label>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xl={6} xs={6}>
                <div className="notarialogWrap">
                  <span>Total Acts</span>
                  <h6>{notarialLog?.total}</h6>
                </div>
              </Col>
              <Col xl={6} xs={6}>
                <div className="notarialogWrap">
                  <span>
                    P&L Total
                    <img
                      id="PLTotal"
                      className="ml-1"
                      src={require("../../../assets/img/info.svg").default}
                      alt="info"
                    />
                  </span>
                  <h6>{notarialLog?.plTotal}</h6>
                </div>
                <UncontrolledTooltip placement="top" target="PLTotal">
                  Can not exceed signing fee
                </UncontrolledTooltip>
              </Col>
              <Col md="12">
                <div className="text-center mt-4">
                  {notarialLog === null ? (
                    <Button
                      size="md"
                      color="primary"
                      disabled={loading.NotarialLogLoading}
                      onClick={() => this._addNotarialLog()}
                    >
                      {loading.NotarialLogLoading ? (
                        <i className="fa fa-spinner fa-spin" />
                      ) : null}{" "}
                      Add
                    </Button>
                  ) : (
                    <Button
                      color="primary"
                      size="md"
                      disabled={loading.NotarialLogLoading}
                      onClick={() => this._editNotarialLog()}
                    >
                      {loading.NotarialLogLoading ? (
                        <i className="fa fa-spinner fa-spin" />
                      ) : null}{" "}
                      Save
                    </Button>
                  )}
                </div>
              </Col>
            </Row>
          </TabPane>
        </TabContent>

        <AddExpenseModal
          isOpen={addExpenseModal.isOpen}
          data={addExpenseModal.data}
          toggle={() => this._toggleAddExpenseModal()}
          closingId={closingDetails?.id}
          getAllAgentExpensesForClosing={() =>
            this.props.getAllAgentExpensesForClosing(closingDetails?._id)
          }
        />
      </>
    );
  }
}

export default MilageExpanceTabs;
