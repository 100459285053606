import React, { Component } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {
  Row,
  Col,
  Input,
  FormGroup,
  Spinner,
  Card,
  CardBody,
  Button,
  Label,
} from "reactstrap";
import {
  capitalize,
  deepClone,
  formatCurrencyValue,
  // getDateRangeValue,
  showToast,
  formatOnlyDateMoment,
  getDateRangeValueSummarie,
  refreshFunc,
  errorHandler,
} from "../../../helper-methods";
import {
  signingCompanySummary,
  getAllFiltersData,
  companyGetListTeamsOfClient,
} from "../../../http/http-calls";
// import GraphComponent from "../components/graphComponent";
import { customDateRangeConfig, monthShortNames } from "../../../config";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import LineGraph from "../components/graph/LineGraph";
import BarGraph from "../components/graph/BarGraph";
import { connect } from "react-redux";
import { updateUserObj } from "../../../redux/actions/user-data";

class SummariesReportingCompany extends Component {
  state = {
    cardList: {
      incomes: 0,
      closings: 0,
      expenses: 0,
      net: 0,
    },
    closingsGraphConfig: {
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          y: {
            ticks: {
              stepSize: 1,
            },
          },
        },
      },
      type: "bar",
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Count",
            backgroundColor: "rgba(30, 41, 92, 0.3)",
            borderColor: "rgba(30, 41, 92, 1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(30, 41, 92, 0.5)",
            hoverBorderColor: "rgba(30, 41, 92, 1)",
            data: [],
          },
        ],
      },
    },
    incomeGraphConfig: {
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                var dataFor = context.dataset.label || ""; // constant
                if (dataFor) {
                  dataFor += ": ";
                }
                if (context.parsed.y !== null) {
                  dataFor += formatCurrencyValue(context.parsed.y);
                }
                return dataFor;
              },
            },
          },
        },
      },
      type: "bar",
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Income",
            backgroundColor: "rgba(30, 41, 92, 0.3)",
            borderColor: "rgba(30, 41, 92, 1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(30, 41, 92, 0.5)",
            hoverBorderColor: "rgba(30, 41, 92, 1)",
            data: [],
          },
        ],
      },
    },
    expenseGraphConfig: {
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                var dataFor = context.dataset.label || ""; // constant
                if (dataFor) {
                  dataFor += ": ";
                }
                if (context.parsed.y !== null) {
                  dataFor += formatCurrencyValue(context.parsed.y);
                }
                return dataFor;
              },
            },
          },
        },
      },
      type: "bar",
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Expenses",
            backgroundColor: "rgba(30, 41, 92, 0.3)",
            borderColor: "rgba(30, 41, 92, 1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(30, 41, 92, 0.5)",
            hoverBorderColor: "rgba(30, 41, 92, 1)",
            data: [],
          },
        ],
      },
    },
    agentPayPeriodGraphConfig: {
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                var dataFor = context.dataset.label || ""; // constant
                if (dataFor) {
                  dataFor += ": ";
                }
                if (context.parsed.y !== null) {
                  dataFor += formatCurrencyValue(context.parsed.y);
                }
                return dataFor;
              },
            },
          },
        },
      },
      type: "bar",
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Agent Pay Period",
            backgroundColor: "rgba(30, 41, 92, 0.3)",
            borderColor: "rgba(30, 41, 92, 1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(30, 41, 92, 0.5)",
            hoverBorderColor: "rgba(30, 41, 92, 1)",
            data: [],
          },
        ],
      },
    },
    netGraphConfig: {
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                var dataFor = context.dataset.label || ""; // constant
                if (dataFor) {
                  dataFor += ": ";
                }
                if (context.parsed.y !== null) {
                  dataFor += formatCurrencyValue(context.parsed.y);
                }
                return dataFor;
              },
            },
          },
        },
      },
      type: "bar",
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Net",
            backgroundColor: "rgba(30, 41, 92, 0.3)",
            borderColor: "rgba(30, 41, 92, 1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(30, 41, 92, 0.5)",
            hoverBorderColor: "rgba(30, 41, 92, 1)",
            data: [],
          },
        ],
      },
    },

    orderGraphData: {
      colors: ["#07B8E7"],
      tooltipName1: "Order #",
      series1: [],
    },
    incomeGraphData: {
      colors: ["#4C96CE"],
      tooltipName1: "Income",
      series1: [],
      formatter1: (value) => formatCurrencyValue(value || "0"),
    },
    expenseGraphData: {
      colors: ["#4C96CE"],
      tooltipName1: "Expense",
      series1: [],
      formatter1: (value) => formatCurrencyValue(value || "0"),
    },
    agentPayPeriodGraphData: {
      colors: ["#4C96CE"],
      tooltipName1: "Agent Pay Period",
      series1: [],
      formatter1: (value) => formatCurrencyValue(value || "0"),
    },
    expenseAndNetGraphData: {
      colors: ["#07B8E7", "#F0B634"],
      tooltipName1: "Expenses",
      series1: [],
      formatter1: (value) => formatCurrencyValue(value || "0"),
      tooltipName2: "Net",
      series2: [],
      formatter2: (value) => formatCurrencyValue(value || "0"),
    },

    filterDropdownValues: {
      clients: [],
      clientTeamsList: [],
    },
    filters: {
      dateRangeValue: null,
      customDateRangeValue: "month",
      client: "",
      clientTeam: "",
    },
    loading: {
      showTableLoading: false,
    },
    isClassAdded: false,
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  // _setGraphData = (
  //   closingsGraph = [],
  //   incomeGraph = [],
  //   expenseGraph = [],
  //   agentPayPeriodGraph = []
  // ) => {
  //   try {
  //     const {
  //       closingsGraphConfig,
  //       incomeGraphConfig,
  //       expenseGraphConfig,
  //       netGraphConfig,
  //       agentPayPeriodGraphConfig,
  //       filters,
  //     } = deepClone(this.state);

  //     // closingsGraph
  //     closingsGraphConfig.chartData.labels = closingsGraph?.length
  //       ? closingsGraph.map((obj) => {
  //           if (
  //             filters.customDateRangeValue === "month" ||
  //             filters.customDateRangeValue === "day" ||
  //             filters.customDateRangeValue === "week"
  //           ) {
  //             return `${obj._id.day} ${monthShortNames[obj._id.month - 1]}`;
  //           } else if (filters.customDateRangeValue === "allTime") {
  //             return `${obj._id.year}`;
  //           }

  //           return `${monthShortNames[obj._id.month - 1]} ${obj._id.year}`;
  //         })
  //       : [];
  //     closingsGraphConfig.chartData.datasets[0].data = closingsGraph?.length
  //       ? closingsGraph.map((obj) => obj.count)
  //       : [];

  //     // incomeGraph
  //     incomeGraphConfig.chartData.labels = incomeGraph?.length
  //       ? incomeGraph.map((obj) => {
  //           if (
  //             filters.customDateRangeValue === "month" ||
  //             filters.customDateRangeValue === "day" ||
  //             filters.customDateRangeValue === "week"
  //           ) {
  //             return `${obj._id.day} ${monthShortNames[obj._id.month - 1]}`;
  //           } else if (filters.customDateRangeValue === "allTime") {
  //             return `${obj._id.year}`;
  //           }

  //           return `${monthShortNames[obj._id.month - 1]} ${obj._id.year}`;
  //         })
  //       : [];
  //     incomeGraphConfig.chartData.datasets[0].data = incomeGraph?.length
  //       ? incomeGraph.map((obj) => obj.amount)
  //       : [];

  //     // expenseGraph
  //     expenseGraphConfig.chartData.labels = expenseGraph?.length
  //       ? expenseGraph.map((obj) => {
  //           if (
  //             filters.customDateRangeValue === "month" ||
  //             filters.customDateRangeValue === "day" ||
  //             filters.customDateRangeValue === "week"
  //           ) {
  //             return `${obj._id.day} ${monthShortNames[obj._id.month - 1]}`;
  //           } else if (filters.customDateRangeValue === "allTime") {
  //             return `${obj._id.year}`;
  //           }

  //           return `${monthShortNames[obj._id.month - 1]} ${obj._id.year}`;
  //         })
  //       : [];
  //     expenseGraphConfig.chartData.datasets[0].data = expenseGraph?.length
  //       ? expenseGraph.map((obj) => obj.amount)
  //       : [];

  //     //agentPayPeriodGraphConfig
  //     agentPayPeriodGraphConfig.chartData.labels = agentPayPeriodGraph?.length
  //       ? agentPayPeriodGraph.map((obj) => {
  //           if (
  //             filters.customDateRangeValue === "month" ||
  //             filters.customDateRangeValue === "day" ||
  //             filters.customDateRangeValue === "week"
  //           ) {
  //             return `${obj._id.day} ${monthShortNames[obj._id.month - 1]}`;
  //           } else if (filters.customDateRangeValue === "allTime") {
  //             return `${obj._id.year}`;
  //           }

  //           return `${monthShortNames[obj._id.month - 1]} ${obj._id.year}`;
  //         })
  //       : [];
  //     agentPayPeriodGraphConfig.chartData.datasets[0].data =
  //       agentPayPeriodGraph?.length
  //         ? agentPayPeriodGraph.map((obj) => obj.amount)
  //         : [];

  //     // netGraphConfig = (incomeGraph - expenseGraph)
  //     if (incomeGraph?.length && expenseGraph?.length) {
  //       let netPofiteGraph = [];
  //       let incomeArray = incomeGraph;
  //       let expenseArray = expenseGraph;
  //       let agentPayPeriodArray = agentPayPeriodGraph;

  //       netPofiteGraph = incomeArray.map((income) => {
  //         const incomeDateObjString = JSON.stringify(income._id);
  //         const expenseIndexOfSameDate = expenseArray.findIndex(
  //           (exp) => JSON.stringify(exp._id) === incomeDateObjString
  //         );

  //         const agentPayPeriodIndexOfSameDate = agentPayPeriodArray.findIndex(
  //           (exp) => JSON.stringify(exp._id) === incomeDateObjString
  //         );

  //         // if (expenseIndexOfSameDate > -1) {
  //         //   let expenseObj = expenseArray.splice(expenseIndexOfSameDate, 1);
  //         //   return {
  //         //     amount: income.amount - expenseObj[0].amount,
  //         //     _id: income._id,
  //         //   };
  //         // }

  //         if (
  //           agentPayPeriodIndexOfSameDate > -1 &&
  //           expenseIndexOfSameDate === -1
  //         ) {
  //           let agentPayPeriodObj = agentPayPeriodArray.splice(
  //             agentPayPeriodIndexOfSameDate,
  //             1
  //           );
  //           return {
  //             amount: income.amount - agentPayPeriodObj[0].amount,
  //             _id: income._id,
  //           };
  //         }

  //         if (
  //           agentPayPeriodIndexOfSameDate === -1 &&
  //           expenseIndexOfSameDate > -1
  //         ) {
  //           let expenseObj = expenseArray.splice(expenseIndexOfSameDate, 1);
  //           return {
  //             amount: income.amount - expenseObj[0].amount,
  //             _id: income._id,
  //           };
  //         }

  //         if (
  //           agentPayPeriodIndexOfSameDate > -1 &&
  //           expenseIndexOfSameDate > -1
  //         ) {
  //           let expenseObj = expenseArray.splice(expenseIndexOfSameDate, 1);
  //           let agentPayPeriodObj = agentPayPeriodArray.splice(
  //             agentPayPeriodIndexOfSameDate,
  //             1
  //           );
  //           return {
  //             amount:
  //               income.amount -
  //               (expenseObj[0].amount + agentPayPeriodObj[0].amount),
  //             _id: income._id,
  //           };
  //         }

  //         return income;
  //       });

  //       if (expenseArray?.length) {
  //         expenseArray = expenseArray.map((expense) => {
  //           return {
  //             amount: -expense.amount,
  //             _id: expense._id,
  //           };
  //         });
  //         netPofiteGraph = [...netPofiteGraph, ...expenseArray];
  //         netPofiteGraph.sort((a, b) => {
  //           if (a._id?.day > b._id?.day) return 1;
  //           if (a._id?.day < b._id?.day) return -1;
  //           if (a._id?.month > b._id?.month) return 1;
  //           if (a._id?.month < b._id?.month) return -1;
  //           return 0;
  //         });
  //       }

  //       if (agentPayPeriodArray?.length) {
  //         agentPayPeriodArray = agentPayPeriodArray.map((agentPay) => {
  //           console.log("aa", agentPay);
  //           return {
  //             amount: -agentPay.amount,
  //             _id: agentPay._id,
  //           };
  //         });

  //         netPofiteGraph = [...netPofiteGraph, ...agentPayPeriodArray];
  //         netPofiteGraph.sort((a, b) => {
  //           if (a._id?.day > b._id?.day) return 1;
  //           if (a._id?.day < b._id?.day) return -1;
  //           if (a._id?.month > b._id?.month) return 1;
  //           if (a._id?.month < b._id?.month) return -1;
  //           return 0;
  //         });
  //       }

  //       netGraphConfig.chartData.labels = netPofiteGraph.map((obj) => {
  //         if (
  //           filters.customDateRangeValue === "month" ||
  //           filters.customDateRangeValue === "day" ||
  //           filters.customDateRangeValue === "week"
  //         ) {
  //           return `${obj._id.day} ${monthShortNames[obj._id.month - 1]}`;
  //         } else if (filters.customDateRangeValue === "allTime") {
  //           return `${obj._id.year}`;
  //         }

  //         return `${monthShortNames[obj._id.month - 1]} ${obj._id.year}`;
  //       });
  //       netGraphConfig.chartData.datasets[0].data = netPofiteGraph.map(
  //         (obj) => obj.amount
  //       );
  //     } else if (incomeGraph?.length && !expenseGraph?.length) {
  //       netGraphConfig.chartData.labels = incomeGraph.map((obj) => {
  //         if (
  //           filters.customDateRangeValue === "month" ||
  //           filters.customDateRangeValue === "day" ||
  //           filters.customDateRangeValue === "week"
  //         ) {
  //           return `${obj._id.day} ${monthShortNames[obj._id.month - 1]}`;
  //         } else if (filters.customDateRangeValue === "allTime") {
  //           return `${obj._id.year}`;
  //         }

  //         return `${monthShortNames[obj._id.month - 1]} ${obj._id.year}`;
  //       });
  //       netGraphConfig.chartData.datasets[0].data = incomeGraph.map(
  //         (obj) => obj.amount
  //       );
  //     } else if (!incomeGraph?.length && expenseGraph?.length) {
  //       netGraphConfig.chartData.labels = expenseGraph.map((obj) => {
  //         if (
  //           filters.customDateRangeValue === "month" ||
  //           filters.customDateRangeValue === "day" ||
  //           filters.customDateRangeValue === "week"
  //         ) {
  //           return `${obj._id.day} ${monthShortNames[obj._id.month - 1]}`;
  //         } else if (filters.customDateRangeValue === "allTime") {
  //           return `${obj._id.year}`;
  //         }

  //         return `${monthShortNames[obj._id.month - 1]} ${obj._id.year}`;
  //       });
  //       netGraphConfig.chartData.datasets[0].data = expenseGraph.map(
  //         (obj) => -obj.amount
  //       );
  //     } else {
  //       netGraphConfig.chartData.labels = [];
  //       netGraphConfig.chartData.datasets[0].data = [];
  //     }

  //     this.setState({
  //       closingsGraphConfig,
  //       incomeGraphConfig,
  //       expenseGraphConfig,
  //       netGraphConfig,
  //       agentPayPeriodGraphConfig,
  //     });
  //   } catch (error) {
  //     console.log("error >> ", error);
  //     showToast("Somthing went wrong. Try again after sometime.", "error");
  //   }
  // };

  _setGraphData = (
    closingsGraph = [],
    incomeGraph = [],
    expenseGraph = [],
    agentPayPeriodGraph = []
  ) => {
    try {
      const {
        orderGraphData,
        incomeGraphData,
        expenseGraphData,
        expenseAndNetGraphData,
        agentPayPeriodGraphData,
        filters,
      } = deepClone(this.state);

      // closingsGraph
      orderGraphData.series1 = closingsGraph?.length
        ? closingsGraph.map((each) => {
            let label = "";

            if (
              filters.customDateRangeValue === "month" ||
              filters.customDateRangeValue === "day" ||
              filters.customDateRangeValue === "week"
            ) {
              label = `${each._id.day} ${monthShortNames[each._id.month - 1]}`;
            } else if (filters.customDateRangeValue === "allTime") {
              label = `${each._id.year}`;
            } else {
              label = `${monthShortNames[each._id.month - 1]} ${each._id.year}`;
            }

            return { label, value: each.count };
          })
        : [];

      // incomeGraph
      incomeGraphData.series1 = incomeGraph?.length
        ? incomeGraph.map((each) => {
            let label = "";

            if (
              filters.customDateRangeValue === "month" ||
              filters.customDateRangeValue === "day" ||
              filters.customDateRangeValue === "week"
            ) {
              label = `${each._id.day} ${monthShortNames[each._id.month - 1]}`;
            } else if (filters.customDateRangeValue === "allTime") {
              label = `${each._id.year}`;
            } else {
              label = `${monthShortNames[each._id.month - 1]} ${each._id.year}`;
            }

            return { label, value: each.amount };
          })
        : null;

      // expenseGraph
      expenseGraphData.series1 = expenseGraph?.length
        ? expenseGraph.map((each) => {
            let label = "";

            if (
              filters.customDateRangeValue === "month" ||
              filters.customDateRangeValue === "day" ||
              filters.customDateRangeValue === "week"
            ) {
              label = `${each._id.day} ${monthShortNames[each._id.month - 1]}`;
            } else if (filters.customDateRangeValue === "allTime") {
              label = `${each._id.year}`;
            } else {
              label = `${monthShortNames[each._id.month - 1]} ${each._id.year}`;
            }

            return { label, value: each.amount };
          })
        : null;

      //agentPayPeriodGraph
      agentPayPeriodGraphData.series1 = agentPayPeriodGraph?.length
        ? agentPayPeriodGraph.map((each) => {
            let label = "";

            if (
              filters.customDateRangeValue === "month" ||
              filters.customDateRangeValue === "day" ||
              filters.customDateRangeValue === "week"
            ) {
              label = `${each._id.day} ${monthShortNames[each._id.month - 1]}`;
            } else if (filters.customDateRangeValue === "allTime") {
              label = `${each._id.year}`;
            } else {
              label = `${monthShortNames[each._id.month - 1]} ${each._id.year}`;
            }

            return { label, value: each.amount };
          })
        : [];

      // expenseGraph
      expenseAndNetGraphData.series1 = expenseGraph?.length
        ? expenseGraph.map((each) => {
            let label = "";

            if (
              filters.customDateRangeValue === "month" ||
              filters.customDateRangeValue === "day" ||
              filters.customDateRangeValue === "week"
            ) {
              label = `${each._id.day} ${monthShortNames[each._id.month - 1]}`;
            } else if (filters.customDateRangeValue === "allTime") {
              label = `${each._id.year}`;
            } else {
              label = `${monthShortNames[each._id.month - 1]} ${each._id.year}`;
            }

            return { label, value: each.amount };
          })
        : [];
      // netGraphConfig = (incomeGraph - expenseGraph)
      if (incomeGraph?.length && expenseGraph?.length) {
        let netPofiteGraph = [];
        let incomeArray = incomeGraph;
        let expenseArray = expenseGraph;
        let agentPayPeriodArray = agentPayPeriodGraph;

        netPofiteGraph = incomeArray.map((income) => {
          const incomeDateObjString = JSON.stringify(income._id);
          const expenseIndexOfSameDate = expenseArray.findIndex(
            (exp) => JSON.stringify(exp._id) === incomeDateObjString
          );
          const agentPayPeriodIndexOfSameDate = agentPayPeriodArray.findIndex(
            (agentPayPeriod) =>
              JSON.stringify(agentPayPeriod._id) === incomeDateObjString
          );
          // if (expenseIndexOfSameDate > -1) {
          //   let expenseObj = expenseArray.splice(expenseIndexOfSameDate, 1);

          //   return {
          //     amount: income.amount - expenseObj[0].amount,
          //     _id: income._id,
          //   };
          // }
          if (
            agentPayPeriodIndexOfSameDate > -1 &&
            expenseIndexOfSameDate === -1
          ) {
            let agentPayPeriodObj = agentPayPeriodArray.splice(
              agentPayPeriodIndexOfSameDate,
              1
            );

            return {
              amount: income.amount - agentPayPeriodObj[0].amount,
              _id: income._id,
            };
          }

          if (
            agentPayPeriodIndexOfSameDate === -1 &&
            expenseIndexOfSameDate > -1
          ) {
            let expenseObj = expenseArray.splice(expenseIndexOfSameDate, 1);
            return {
              amount: income.amount - expenseObj[0].amount,
              _id: income._id,
            };
          }

          if (
            agentPayPeriodIndexOfSameDate > -1 &&
            expenseIndexOfSameDate > -1
          ) {
            let expenseObj = expenseArray.splice(expenseIndexOfSameDate, 1);
            let agentPayPeriodObj = agentPayPeriodArray.splice(
              agentPayPeriodIndexOfSameDate,
              1
            );

            return {
              amount:
                income.amount -
                (expenseObj[0].amount + agentPayPeriodObj[0].amount),
              _id: income._id,
            };
          }
          return income;
        });

        if (expenseArray?.length) {
          expenseArray = expenseArray.map((expense) => {
            return {
              amount: -expense.amount,
              _id: expense._id,
            };
          });
          netPofiteGraph = [...netPofiteGraph, ...expenseArray];
          netPofiteGraph.sort((a, b) => {
            if (a._id?.day > b._id?.day) return 1;
            if (a._id?.day < b._id?.day) return -1;
            if (a._id?.month > b._id?.month) return 1;
            if (a._id?.month < b._id?.month) return -1;
            return 0;
          });
        }

        expenseAndNetGraphData.series2 = netPofiteGraph.map((each, index) => {
          let label = "";

          if (
            filters.customDateRangeValue === "month" ||
            filters.customDateRangeValue === "day" ||
            filters.customDateRangeValue === "week"
          ) {
            label = `${each._id.day} ${monthShortNames[each._id.month - 1]}`;
          } else if (filters.customDateRangeValue === "allTime") {
            label = `${each._id.year}`;
          } else {
            label = `${monthShortNames[each._id.month - 1]} ${each._id.year}`;
          }

          const findInExpense = expenseAndNetGraphData.series1.find(
            (each) => each.label === label
          );
          if (!findInExpense) {
            expenseAndNetGraphData.series1.splice(index, 0, {
              label,
              value: 0,
            });
          }

          return { label, value: each.amount };
        });
      } else if (incomeGraph?.length && !expenseGraph?.length) {
        expenseAndNetGraphData.series2 = incomeGraph.map((each, index) => {
          let label = "";

          if (
            filters.customDateRangeValue === "month" ||
            filters.customDateRangeValue === "day" ||
            filters.customDateRangeValue === "week"
          ) {
            label = `${each._id.day} ${monthShortNames[each._id.month - 1]}`;
          } else if (filters.customDateRangeValue === "allTime") {
            label = `${each._id.year}`;
          } else {
            label = `${monthShortNames[each._id.month - 1]} ${each._id.year}`;
          }

          const findInExpense = expenseAndNetGraphData.series1.find(
            (each) => each.label === label
          );
          if (!findInExpense) {
            expenseAndNetGraphData.series1.splice(index, 0, {
              label,
              value: 0,
            });
          }

          return { label, value: each.amount };
        });
      } else if (!incomeGraph?.length && expenseGraph?.length) {
        expenseAndNetGraphData.series2 = expenseGraph.map((each, index) => {
          let label = "";

          if (
            filters.customDateRangeValue === "month" ||
            filters.customDateRangeValue === "day" ||
            filters.customDateRangeValue === "week"
          ) {
            label = `${each._id.day} ${monthShortNames[each._id.month - 1]}`;
          } else if (filters.customDateRangeValue === "allTime") {
            label = `${each._id.year}`;
          } else {
            label = `${monthShortNames[each._id.month - 1]} ${each._id.year}`;
          }

          const findInExpense = expenseAndNetGraphData.series1.find(
            (each) => each.label === label
          );
          if (!findInExpense) {
            expenseAndNetGraphData.series1.splice(index, 0, {
              label,
              value: 0,
            });
          }

          return { label, value: -each.amount };
        });
      } else {
        expenseAndNetGraphData.series2 = [];
      }

      this.setState({
        orderGraphData,
        incomeGraphData,
        expenseGraphData,
        expenseAndNetGraphData,
        agentPayPeriodGraphData,
      });
    } catch (error) {
      console.log("error >> ", error);
      showToast("Somthing went wrong. Try again after sometime.", "error");
    }
  };

  _signingComapanyGetReportingSummaries = () => {
    this._manageLoading("showTableLoading", true);

    const { filters } = deepClone(this.state);

    const payload = {
      filters: {},
    };

    if (filters.client && filters.client.length)
      payload.filters["clientId"] = filters.client;

    if (filters?.client && filters?.clientTeam && filters?.clientTeam?.length)
      payload.filters["clientAssistantTeam"] = filters?.clientTeam;

    if (filters.dateRangeValue) {
      payload.filters["startDate"] = formatOnlyDateMoment(
        filters.dateRangeValue[0]
      );
      payload.filters["endDate"] = formatOnlyDateMoment(
        filters.dateRangeValue[1]
      );
    }
    if (filters.customDateRangeValue) {
      // AgentExpensesSummaries -
      // {String} [groupByUnit] `enum = ["month", "day", "year"]
      if (
        filters.customDateRangeValue === "month" ||
        filters.customDateRangeValue === "day" ||
        filters.customDateRangeValue === "week"
      ) {
        payload.filters["groupByUnit"] = "day";
      } else if (filters.customDateRangeValue === "allTime") {
        payload.filters["groupByUnit"] = "year";
      }
    }

    signingCompanySummary(payload)
      .then((res) => {
        this._setGraphData(
          res?.closingsGraph,
          res?.incomeGraph,
          res?.expenseGraph,
          res?.agentPayPeriodGraph
        );

        this.setState(
          {
            cardList: {
              incomes: res?.incomes,
              closings: res?.closings,
              expenses: res?.expenses,
              net: res?.net,
            },
          },
          () => this._manageLoading("showTableLoading", false)
        );
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _setDateRangeValue = (value = "month") => {
    const { filters } = this.state;

    switch (value) {
      case "day":
      case "week":
      case "month":
      case "year": {
        filters["dateRangeValue"] = getDateRangeValueSummarie(value);
        break;
      }
      case "allTime": {
        filters["dateRangeValue"] = null;
        break;
      }
      case "customDateRange": {
        filters["dateRangeValue"] = null;
        filters["customDateRangeValue"] = value;
        this.setState({ filters });
        return;
      }
      default: {
        showToast("Something went wrong. Try again after sometime.", "error");
        return;
      }
    }

    filters["customDateRangeValue"] = value;
    this.setState({ filters }, () => {
      this._signingComapanyGetReportingSummaries();
      this._persistFilter();
    });
  };

  _filterOnChange = (type, value) => {
    const { filters, filterDropdownValues } = this.state;
    if (type === "dateRangeValue") {
      if (value) {
        filters["customDateRangeValue"] = "customDateRange";
        filters[type] = value;
        this.setState({ filters }, () => {
          this._signingComapanyGetReportingSummaries();
          this._persistFilter();
        });
      } else {
        this._setDateRangeValue();
      }
    } else {
      filters[type] = value;

      if (type === "client") {
        filters["clientTeam"] = "";
        if (value?.length) {
          this._getCompanyListTeamsOfClient(value);
        } else {
          filterDropdownValues["clientTeamsList"] = [];
        }
      }

      this.setState({ filters }, () => {
        this._signingComapanyGetReportingSummaries();
        this._persistFilter();
      });
    }
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          dateRangeValue: null,
          customDateRangeValue: "month",
          client: "",
          clientTeam: "",
        },
      },
      () => {
        this._setDateRangeValue();
        refreshFunc("company-reporting-expense-summaries-responsive");
      }
    );
  };

  _getAllFiltersData = () => {
    getAllFiltersData()
      .then((res) => {
        const { filterDropdownValues } = deepClone(this.state);
        filterDropdownValues["clients"] = res?.clients;
        this.setState({ filterDropdownValues });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  componentDidMount = () => {
    this._getAllFiltersData();
    this._setFilterDataFromLocalStorage();
    // call function after set date range -> _signingComapanyGetReportingSummaries();
    // this._setDateRangeValue();
    HeaderEventEmitter.subscribe("reset-filter", () => {
      console.log("filter reset hit");
      this._resetFilter();
      refreshFunc("company-reporting-expense-summaries");
    });
  };

  _persistFilter = () => {
    const { filters } = this.state;
    if (
      filters &&
      (filters.client ||
        (filters.customDateRangeValue &&
          filters.customDateRangeValue !== "month"))
    ) {
      localStorage.companyexpensesummary = JSON.stringify(filters);
    } else {
      delete localStorage.companyexpensesummary;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    // const { filters, tableConfig } = deepClone(this.state);

    if (localStorage && localStorage.companyexpensesummary) {
      try {
        const filters = JSON.parse(localStorage.companyexpensesummary);
        this.setState({ filters }, () => {
          // this._getAllStatusReport();
          this._setDateRangeValue();
          if (this.state.filters?.client) {
            this._getCompanyListTeamsOfClient(this.state.filters?.client);
          }
        });
      } catch (e) {
        // this._getAllStatusReport();
        this._setDateRangeValue();
      }
    } else {
      // this._getAllStatusReport();
      this._setDateRangeValue();
    }
  };

  _getCompanyListTeamsOfClient = async (clientId) => {
    try {
      const res = await companyGetListTeamsOfClient(clientId);
      const { filterDropdownValues } = deepClone(this.state);

      filterDropdownValues["clientTeamsList"] = res?.teams;

      this.setState({ filterDropdownValues });
    } catch (err) {
      errorHandler(err);
    }
  };

  _prepareFilterData = () => {
    const { filters } = deepClone(this.state);

    if (!filters.states) delete filters.agent;
    if (!filters.status) delete filters.status;

    return filters;
  };
  render() {
    const {
      incomeGraphData,
      orderGraphData,
      expenseAndNetGraphData,
      agentPayPeriodGraphData,
      filters,
      filterDropdownValues,
      cardList,
      isClassAdded,
      loading,
    } = this.state;
    return (
      <div className="content">
        <div className="responsiveTitle">
          <h2>Summaries</h2>

          <div className="rightSide">
            <Button color="link" onClick={this._resetFilter}>
              <img
                id="company-reporting-expense-summaries-responsive"
                src={require("../../../assets/img/refresh.svg").default}
                alt="refresh"
              />
            </Button>
            <Button
              className="filterBarIcon"
              color="link"
              onClick={this.filterShowMobile}
            >
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="Filter"
              />
            </Button>
          </div>
        </div>

        {/* filter for tablet and web */}
        <div
          onClick={this.filterShowMobile}
          className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
        ></div>
        <div
          className={`filterContainer  responsiveFilter ${
            isClassAdded ? "show" : ""
          }`}
        >
          <div className="filterIcon">
            <img
              src={require("../../../assets/img/filter_icon.svg").default}
              alt="filter icon"
            />
            Filter by
          </div>
          <div className="mobileTitle">
            <h2>Filter by</h2>
            <Button
              color="link"
              className="closeButton"
              onClick={this.filterShowMobile}
            >
              <img
                src={require("../../../assets/img/close_grey.svg").default}
                alt="close"
                height={12}
              />
            </Button>
          </div>
          <div className="filterWrapper">
            <FormGroup className="dateRange">
              <Label>Date Range</Label>
              <DateRangePicker
                isOpen={
                  !filters.dateRangeValue &&
                  filters.customDateRangeValue === "customDateRange"
                    ? true
                    : false
                }
                clearIcon={null}
                className="dateRange"
                format="MM-dd-y"
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                onChange={(dateRangeValue) =>
                  this._filterOnChange("dateRangeValue", dateRangeValue)
                }
                // maxDate={new Date()}
                value={filters.dateRangeValue}
              />
            </FormGroup>
            <FormGroup>
              <Label>Date Added</Label>
              <div className="custom-select-wrapper">
                <Input
                  type="select"
                  value={filters.customDateRangeValue}
                  name="customDateRangeValue"
                  onChange={(e) => this._setDateRangeValue(e.target.value)}
                >
                  {customDateRangeConfig.map((obj) => (
                    <option key={obj.value} value={obj.value}>
                      {obj.label}
                    </option>
                  ))}
                </Input>
              </div>
            </FormGroup>
            <FormGroup>
              <Label>Client</Label>
              <div className="custom-select-wrapper">
                <Input
                  type="select"
                  value={filters.client}
                  name="client"
                  onChange={(e) =>
                    this._filterOnChange("client", e.target.value)
                  }
                >
                  <option value="">All</option>
                  {filterDropdownValues.clients.map((obj) => (
                    <option key={obj.id} value={obj.id}>
                      {/* {(obj.name.full && capitalize(obj.name.full)) ||
                          "N/A Company"} */}
                      {(obj?.companyName
                        ? capitalize(obj.companyName)
                        : capitalize(obj.name.full)) || "N/A Client"}
                    </option>
                  ))}
                </Input>
              </div>
            </FormGroup>
            <FormGroup style={{ minWidth: "150px" }}>
              <Label>Client Teams</Label>
              <div
                className={`custom-select-wrapper ${
                  filters?.client === "" ? "disabled" : ""
                }`}
              >
                <Input
                  type="select"
                  placeholder=" "
                  value={filters?.clientTeam}
                  onChange={(event) =>
                    this._filterOnChange("clientTeam", event.target.value)
                  }
                  disabled={filters?.client === ""}
                  name="clientTeamsId"
                >
                  <option value="">All Teams</option>
                  {filterDropdownValues?.clientTeamsList?.map((team) => (
                    <option key={team?._id} value={team?._id}>
                      {team?.teamName ? capitalize(team?.teamName) : ""}
                    </option>
                  ))}
                </Input>
              </div>
            </FormGroup>
          </div>
          <div className="clearButton">
            <Button size="md" color="primary" onClick={this.filterShowMobile}>
              Close
            </Button>
          </div>
        </div>

        {loading.showTableLoading ? (
          <div className="text-center mb-4 mt-2">
            <Spinner />
          </div>
        ) : (
          <>
            <Row className="mt-2">
              <Col xl={3} xs={6}>
                <Card className="infoCard">
                  <CardBody>
                    <div className="">
                      <span>Orders</span>
                      <h4>{cardList.closings || 0}</h4>
                    </div>
                    <div className="icon">
                      <img
                        src={
                          require("../../../assets/img/TotalOrders.svg").default
                        }
                        alt="notaryActs"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              {!this.props.userData?.user?.isTitleCompany && (
                <Col xl={3} xs={6}>
                  <Card className="infoCard">
                    <CardBody>
                      <div className="">
                        <span>Income</span>
                        <h4>
                          {cardList.incomes
                            ? formatCurrencyValue(cardList.incomes)
                            : "$0"}
                        </h4>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            require("../../../assets/img/Income.png").default
                          }
                          alt="notaryActs"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              {!this.props.userData?.user?.isTitleCompany && (
                <Col xl={3} xs={6}>
                  <Card className="infoCard">
                    <CardBody>
                      <div className="">
                        <span>Expenses</span>
                        <h4>
                          {cardList.expenses
                            ? formatCurrencyValue(cardList.expenses)
                            : "$0"}
                        </h4>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            require("../../../assets/img/Expenses.svg").default
                          }
                          alt="notaryActs"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              )}
              <Col xl={3} xs={6}>
                <Card className="infoCard">
                  <CardBody>
                    <div>
                      <span>Net</span>
                      <h4
                        className={
                          cardList.net <= 0 ? "text-danger" : "text-success"
                        }
                      >
                        {cardList.net
                          ? formatCurrencyValue(cardList.net)
                          : "$0"}
                      </h4>
                    </div>
                    <div className="icon">
                      <img
                        src={
                          require("../../../assets/img/TotalIncome.svg").default
                        }
                        alt="notaryActs"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}

        {!loading.showTableLoading && (
          <Row>
            <Col md="6" className="mb-4">
              {/* closing graph */}
              {orderGraphData?.series1?.length ? (
                <div className="graphWrap h-100">
                  <h1>Order</h1>
                  <BarGraph data={orderGraphData} height={400} />
                </div>
              ) : (
                <div className="graphWrap noData h-100">
                  <img
                    src={require("../../../assets/img/noData.svg").default}
                    alt="no data"
                  />

                  <p>There is no closing data to display on chart.</p>
                </div>
              )}
            </Col>

            {!this.props.userData?.user?.isTitleCompany && (
              <Col md="6" className="mb-4">
                {/* income graph */}
                {incomeGraphData?.series1?.length ? (
                  <div className="graphWrap h-100">
                    <h1>Income</h1>
                    <BarGraph data={incomeGraphData} height={400} />
                  </div>
                ) : (
                  <div className="graphWrap noData h-100">
                    <img
                      src={require("../../../assets/img/noData.svg").default}
                      alt="no data"
                    />

                    <p>There is no income data to display on chart.</p>
                  </div>
                )}
              </Col>
            )}

            {/* <Col md="6" className="mb-4">
                  // {/* expense graph 
                  {expenseGraphData?.series1?.length ? (
                    <div className="graphWrap h-100">
                      <h1>Expense</h1>
                      <BarGraph data={expenseGraphData} height={400} />
                    </div>
                  ) : (
                    <div className="graphWrap noData h-100">
                      <img
                        src={require("../../../assets/img/noData.svg").default}
                        alt="no data"
                      />

                      <p>There is no expense data to display on chart.</p>
                    </div>
                  )}
                </Col> */}

            <Col md="6" className="mb-4">
              {/* agent pay period graph */}
              {agentPayPeriodGraphData?.series1?.length ? (
                <div className="graphWrap h-100">
                  <h1>Agent Pay Period Graph</h1>
                  <BarGraph data={agentPayPeriodGraphData} height={400} />
                </div>
              ) : (
                <div className="graphWrap noData h-100">
                  <img
                    src={require("../../../assets/img/noData.svg").default}
                    alt="no data"
                  />

                  <p>There is no Agent Pay Period data to display on chart.</p>
                </div>
              )}
            </Col>

            <Col md="12">
              {/* expense & net graph */}
              {expenseAndNetGraphData?.series1?.length ||
              expenseAndNetGraphData?.series2?.length ? (
                <div className="graphWrap">
                  <h1>Expenses and Net Profit</h1>
                  <LineGraph data={expenseAndNetGraphData} height={400} />
                </div>
              ) : (
                <div className="graphWrap noData h-100">
                  <img
                    src={require("../../../assets/img/noData.svg").default}
                    alt="no data"
                  />

                  <p>There is no expense data to display on chart.</p>
                </div>
              )}
            </Col>
          </Row>
        )}

        <p className="mt-3">
          Note: *income reflects total amount paid and pending
        </p>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

// export default SummariesReportingCompany;
export default connect(mapStateToProps, null)(SummariesReportingCompany);
