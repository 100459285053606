import React from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { connect } from "react-redux";
import {
  deepClone,
  getCountryDialCodeFromCountryCode,
  showToast,
  uploadFileOnServer,
  errorHandler,
} from "../../../helper-methods";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import {
  addAgentProfileDetail,
  getAgentProfileDetail,
  updateAgentProfileDetail,
  editAgentProfileDetail,
  getRonPlatformTypes,
} from "../../../http/http-calls";
import { updateUserObj } from "../../../redux/actions/user-data";
import AgentProfileCredentials from "../components/agent-profile-credentials";
import AgentProfileDetails from "../components/agent-profile-details";
import AgentProfileSchedule from "../components/agent-profile-schedule";
import { googlePlaceDetails } from "../../../helper-methods/googleService";
import usaStates from "../../../config/usa_states_titlecase";
import AgentBankInfo from "../components/AgentBankInfo";
import ManageReviewLinks from "../components/ManageReviewLinks";
import SvgIcons from "../components/SvgIcons";

class AgentProfile extends React.Component {
  state = {
    agentDetails: null,
    activeTab: "1",
    loading: false,
    ronPlatformOptions: [],
  };
  componentDidMount = async () => {
    try {
      let tabId = this.props.location.search.replace(/[^\w\s]/gi, "");
      if (tabId) {
        this.setState({ activeTab: tabId });
      }

      this.props.showLoader("Fetching Details...");
      await this._getAgentProfileDetail();
      this._getRonPlatformType();
      this.props.hideLoader();
    } catch (error) {
      errorHandler(error);
      this.props.hideLoader();
    }
  };

  _getRonPlatformType = async () => {
    try {
      const response = await getRonPlatformTypes();

      if (!response?.error) {
        const convertedArray =
          (response?.ronPlatformTypes &&
            Object.entries(response?.ronPlatformTypes).map(
              ([label, value]) => ({ label, value })
            )) ||
          [];
        this.setState({ ronPlatformOptions: convertedArray });
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };

  _getAgentProfileDetail = () => {
    return new Promise((resolve, reject) => {
      getAgentProfileDetail()
        .then((res) => {
          this.setState({ agentDetails: res?.agent });
          this.props.updateUserObj(res?.agent);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _updateOrAddAgentProfileDetail = (data, toastSuccessMsg = null) => {
    return new Promise((resolve, reject) => {
      this.setState({ loading: true });

      const { agentDetails } = deepClone(this.state);
      // FOR EDIT
      updateAgentProfileDetail(agentDetails._id, data)
        .then(async (res) => {
          await this._getAgentProfileDetail();
          showToast(
            toastSuccessMsg ? toastSuccessMsg : "Agent Details Updated",
            "success"
          );
          this.setState({ loading: false });
          resolve(true);
        })
        .catch((error) => {
          errorHandler(error);
          this.setState({ loading: false });
          resolve(false);
        });
    });
  };

  _addAgentLicense = (data, toastSuccessMsg = null) => {
    return new Promise((resolve, reject) => {
      this.setState({ loading: true });

      const { agentDetails } = deepClone(this.state);
      addAgentProfileDetail(agentDetails._id, data)
        .then(async (res) => {
          await this._getAgentProfileDetail();
          showToast(
            toastSuccessMsg ? toastSuccessMsg : "Add Agent Detail Successfully",
            "success"
          );
          this.setState({ loading: false });
          resolve(true);
        })
        .catch((error) => {
          errorHandler(error);
          this.setState({ loading: false });
          resolve(false);
        });
    });
  };

  _editAgentLicense = (data, toastSuccessMsg = null) => {
    return new Promise((resolve, reject) => {
      this.setState({ loading: true });

      const { agentDetails } = deepClone(this.state);
      editAgentProfileDetail(agentDetails._id, data)
        .then(async (res) => {
          await this._getAgentProfileDetail();
          showToast(
            toastSuccessMsg ? toastSuccessMsg : "Add Agent Detail Successfully",
            "success"
          );
          this.setState({ loading: false });
          resolve(true);
        })
        .catch((error) => {
          errorHandler(error);
          this.setState({ loading: false });
          resolve(false);
        });
    });
  };

  _toggleTab = async (tab) => {
    const { loading, activeTab } = deepClone(this.state);
    if (!loading && tab !== activeTab) {
      try {
        this.setState({ loading: true, activeTab: tab });
        if (tab !== "4") {
          await this._getAgentProfileDetail();
        }
        this.setState({ loading: false });
      } catch (error) {
        errorHandler(error);
        this.setState({ loading: false, activeTab: "" });
      }
    }
  };

  _onSubmitBasicDetail = async (
    basicDetails,
    manuallyTypeAddress,
    manuallyTypeStoreAddress
  ) => {
    try {
      const { agentDetails } = deepClone(this.state);

      this.setState({ loading: true });

      const countryDialCode = await getCountryDialCodeFromCountryCode(
        basicDetails.phoneCountry.value
      );

      let splitName = basicDetails.name.value.trim().split(" ");

      if (splitName?.length) {
        splitName = {
          first:
            splitName.length > 1
              ? splitName.slice(0, -1).join(" ")
              : splitName[0],
          last: splitName.length > 1 ? splitName[splitName.length - 1] : "",
        };
      }

      const payload = {
        name: {
          first: splitName.first?.trim() ? splitName.first.trim() : "",
          last: splitName.last?.trim() ? splitName.last.trim() : "",
        },
        phoneCountry: basicDetails.phoneCountry.value,
        address: {},
        storeAddress: {},
        workingStates: basicDetails?.workingStates?.value,
        languagesKnown: basicDetails?.languagesKnown?.value,
        ronPlatformTypes: basicDetails.ronPlatformTypes.value.map(
          (item) => item.value
        ),
        isProvideMobileServices:
          basicDetails?.isProvideMobileServices?.value || false,
        isProvideInStoreServices:
          basicDetails?.isProvideInStoreServices?.value || false,
        userName: basicDetails?.userName?.value,
      };

      if (agentDetails?.email !== basicDetails?.email?.value) {
        payload["email"] = basicDetails?.email?.value || "";
      }

      if (manuallyTypeAddress) {
        payload.address["street"] = basicDetails.address.value.trim();
        payload.address["city"] = basicDetails.city.value.trim();
        payload.address["state"] = basicDetails.state.value.trim();
        payload.address["zip"] = basicDetails.zip.value.trim();
        payload.address["country"] = basicDetails.country.value.trim();
        payload.address["lat"] = undefined;
        payload.address["lng"] = undefined;
      } else {
        if (
          basicDetails?.agentAddress?.placeId &&
          basicDetails?.agentAddress?.placeId !== "initialValue"
        ) {
          const addressObj = await googlePlaceDetails(
            basicDetails?.agentAddress?.placeId
          );
          payload.address["street"] = addressObj?.address?.trim();
          payload.address["city"] = addressObj?.city;
          const findStateObj = usaStates?.find(
            (state) =>
              state.name === addressObj?.state ||
              state.abbreviation === addressObj?.state
          );
          payload.address["state"] = findStateObj
            ? findStateObj?.abbreviation
            : addressObj?.state;
          payload.address["zip"] = addressObj?.postal;
          payload.address["country"] = addressObj?.country;
          payload.address["lat"] = addressObj?.lat;
          payload.address["lng"] = addressObj?.lng;
        } else {
          delete payload.address;
        }
      }

      if (basicDetails?.isProvideInStoreServices?.value) {
        if (manuallyTypeStoreAddress) {
          payload.storeAddress["street"] =
            basicDetails?.storeAddress?.value?.trim() || "";
          payload.storeAddress["city"] =
            basicDetails?.storeCity?.value?.trim() || "";
          payload.storeAddress["state"] =
            basicDetails?.storeState?.value?.trim() || "";
          payload.storeAddress["zip"] =
            basicDetails?.storeZip?.value?.trim() || "";
          payload.storeAddress["country"] =
            basicDetails?.storeCountry?.value?.trim() || "";
          payload.storeAddress["lat"] = undefined;
          payload.storeAddress["lng"] = undefined;
        } else {
          if (
            basicDetails?.storeGoogleAddress?.placeId &&
            basicDetails?.storeGoogleAddress?.placeId !== "initialValue"
          ) {
            const storeAddressObj = await googlePlaceDetails(
              basicDetails?.storeGoogleAddress?.placeId
            );

            payload.storeAddress["street"] =
              storeAddressObj?.address?.trim() || "";
            payload.storeAddress["city"] = storeAddressObj?.city || "";
            const findStateObj = usaStates?.find(
              (state) =>
                state.name === storeAddressObj?.state ||
                state.abbreviation === storeAddressObj?.state
            );
            payload.storeAddress["state"] = findStateObj
              ? findStateObj?.abbreviation
              : storeAddressObj?.state;
            payload.storeAddress["zip"] = storeAddressObj?.postal || "";
            payload.storeAddress["country"] = storeAddressObj?.country || "";
            payload.storeAddress["lat"] = storeAddressObj?.lat;
            payload.storeAddress["lng"] = storeAddressObj?.lng;
          } else {
            delete payload.storeAddress;
          }
        }
      }

      if (countryDialCode && basicDetails.phone.value) {
        payload["phone"] = `(${countryDialCode})${basicDetails.phone.value}`;
      }

      if (
        basicDetails?.profilePicture?.file &&
        basicDetails?.profilePicture?.file?.uploadData
      ) {
        const uploadedFilesRes = await uploadFileOnServer([
          basicDetails?.profilePicture?.file,
        ]);
        payload["profilePicture"] = uploadedFilesRes[0].url;
      }
      //  else {
      //   payload["profilePicture"] = "";
      // }
      if (
        basicDetails?.coverPhoto?.file &&
        basicDetails?.coverPhoto?.file?.uploadData
      ) {
        const uploadedFilesRes = await uploadFileOnServer([
          basicDetails?.coverPhoto?.file,
        ]);
        payload["coverPhoto"] = uploadedFilesRes[0].url;
      }
      if (basicDetails.password.value) {
        payload["password"] = `${basicDetails?.password?.value}`;
      }

      payload["nnaProfile"] = basicDetails?.nnaProfile?.value
        ? basicDetails?.nnaProfile?.value
        : "";
      payload["websiteUrl"] = basicDetails?.websiteUrl?.value
        ? basicDetails?.websiteUrl?.value
        : "";
      payload["bio"] = basicDetails?.bio?.value ? basicDetails?.bio?.value : "";
      payload["linkedInUrl"] = basicDetails?.linkedInUrl?.value
        ? basicDetails?.linkedInUrl?.value
        : "";

      payload["businessName"] = basicDetails?.businessName?.value?.trim()
        ? basicDetails?.businessName?.value
        : "";

      if (basicDetails?.businessName?.value?.trim()) {
        payload["isBusinessNameUsed"] =
          basicDetails?.isBusinessNameUsed?.value || false;
      } else {
        payload["isBusinessNameUsed"] = false;
      }
      // console.log({ basicDetails });

      payload["ronPlatformTypes"] = basicDetails?.ronPlatformTypes?.value
        ?.length
        ? basicDetails?.ronPlatformTypes?.value?.map((each) => each?.value)
        : [];

      this._updateOrAddAgentProfileDetail(payload, "Agent Profile Updated");
    } catch (error) {
      errorHandler(error);
      this.setState({ loading: false });
    }
  };

  _onSubmitCredentialDetail = (credentialName, credentialDetail) => {
    return new Promise(async (resolve, reject) => {
      try {
        this.setState({ loading: true });

        const payload = {};

        if (
          credentialName === "notaryLicense" ||
          credentialName === "barLicense"
        ) {
          payload["_id"] = credentialDetail?._id?.value
            ? credentialDetail?._id?.value
            : undefined;
          payload["licenseNumber"] =
            credentialDetail.licenseNumber.value.trim();
          payload["state"] = credentialDetail.state.value.trim();
          payload["isChanged"] = credentialDetail.isChanged;
        }

        if (credentialName === "ronCredentials") {
          payload["_id"] = credentialDetail?._id?.value
            ? credentialDetail?._id?.value
            : undefined;
          payload["state"] = credentialDetail.state.value.trim();
        }

        if (credentialName === "eoInsurance") {
          payload["companyName"] = credentialDetail.companyName.value.trim();
          payload["policyNumber"] = credentialDetail.policyNumber.value.trim();
          payload["amount"] = credentialDetail.amount.value.trim();
        }

        if (credentialName === "backgroundCheckReport")
          payload["nameOfCompany"] =
            credentialDetail.nameOfCompany.value.trim();

        if (
          credentialName === "notaryLicense" ||
          credentialName === "eoInsurance" ||
          credentialName === "backgroundCheckReport" ||
          credentialName === "barLicense"
        ) {
          payload["title"] = credentialDetail?.title.value.trim()
            ? credentialDetail.title.value
            : undefined;
          payload["url"] = credentialDetail?.document.file.previewBlob
            ? credentialDetail.document.file.previewBlob
            : undefined;
          payload["docType"] = credentialDetail?.docType.value
            ? credentialDetail.docType.value
            : undefined;
        }

        if (credentialName === "w9Credentials")
          payload["certficationYear"] = credentialDetail.certficationYear.value;

        if (
          credentialName === "notaryLicense" ||
          credentialName === "eoInsurance" ||
          credentialName === "backgroundCheckReport"
        )
          payload["expiredOn"] = credentialDetail.expiredOn.value;

        if (
          credentialDetail.document.file &&
          credentialDetail.document.file.uploadData
        ) {
          const uploadedFilesRes = await uploadFileOnServer([
            credentialDetail.document.file,
          ]);
          payload["url"] = uploadedFilesRes[0].url;
          payload["docType"] = uploadedFilesRes[0].docType;
          payload["title"] = uploadedFilesRes[0].title;
        }

        let isSuccess;

        if (payload && Object.keys(payload).length) {
          if (
            credentialName === "notaryLicense" ||
            credentialName === "barLicense" ||
            credentialName === "ronCredentials"
          ) {
            isSuccess = this._agentLicense(
              { [credentialName]: payload },
              credentialName,
              "Agent Credential Updated"
            );
          } else {
            isSuccess = await this._updateOrAddAgentProfileDetail(
              { [credentialName]: payload },
              "Agent Credential Updated"
            );
          }
        } else {
          isSuccess = true;
          this.setState({ loading: false });
        }
        this._getAgentProfileDetail();
        resolve(isSuccess);
      } catch (error) {
        errorHandler(error);
        this.setState({ loading: false });
        resolve(false);
      }
    });
  };

  _agentLicense = (payload, credentialName, toastSuccessMsg) => {
    if (payload[credentialName].isChanged && payload[credentialName]._id) {
      payload.type = "edit";
      delete payload[credentialName].isChanged;
      this._editAgentLicense(payload, toastSuccessMsg);
    } else {
      if (payload[credentialName]._id === undefined) {
        delete payload[credentialName].isChanged;
        this._addAgentLicense(payload, toastSuccessMsg);
      }
    }
  };

  render() {
    const { activeTab, agentDetails, loading } = this.state;
    // percentage is upload file percentage value
    const { percentage } = this.props;

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>Profile</h2>
          </div>

          <div className="verticalTabs">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => {
                    this._toggleTab("1");
                  }}
                >
                  <SvgIcons type={"basicDetails"} />
                  Basic Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => {
                    this._toggleTab("2");
                  }}
                >
                  <SvgIcons type={"myCredentials"} />
                  My Credentials
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "3" ? "active" : ""}
                  onClick={() => {
                    this._toggleTab("3");
                  }}
                >
                  <SvgIcons type={"mySchedule"} />
                  My Schedule
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "4" ? "active" : ""}
                  onClick={() => {
                    this._toggleTab("4");
                  }}
                >
                  <SvgIcons type={"bankInfo"} />
                  Bank Info
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "5" ? "active" : ""}
                  onClick={() => {
                    this._toggleTab("5");
                  }}
                >
                  <SvgIcons type={"manageReview"} />
                  {/* Manage Review Links */}
                  Social/ Business Links
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              {/* basic detail page */}
              <TabPane tabId="1" className="BasicDetailTab">
                <AgentProfileDetails
                  {...this.props}
                  activeTab={activeTab}
                  agentDetails={agentDetails}
                  percentage={percentage}
                  loading={loading}
                  ronPlatformOptions={this.state.ronPlatformOptions}
                  onSubmitBasicDetail={this._onSubmitBasicDetail}
                />
              </TabPane>

              {/* My Credentials form start */}
              <TabPane tabId="2">
                <AgentProfileCredentials
                  {...this.props}
                  activeTab={activeTab}
                  agentDetails={agentDetails}
                  percentage={percentage}
                  loading={loading}
                  getAgentProfileDetail={() => this._getAgentProfileDetail()}
                  // onSubmitCredentialDetail={this._onSubmitCredentialDetail}
                  deleteLicense={this._editAgentLicense}
                />
              </TabPane>

              {/* My Schedule form start */}
              <TabPane tabId="3">
                <AgentProfileSchedule
                  activeTab={activeTab}
                  agentDetails={agentDetails}
                  onSubmitSchedule={this._updateOrAddAgentProfileDetail}
                />
              </TabPane>

              <TabPane tabId="4">
                <AgentBankInfo
                  activeTab={activeTab}
                  agentDetails={agentDetails}
                  loading={loading}
                  getAgentProfileDetail={() => this._getAgentProfileDetail()}
                />
              </TabPane>
              <TabPane tabId="5">
                {agentDetails && (
                  <ManageReviewLinks agentDetails={agentDetails} />
                )}
              </TabPane>
            </TabContent>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    percentage:
      state.uploadFilePercentage && state.uploadFilePercentage.percentage
        ? state.uploadFilePercentage.percentage
        : "0",
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserObj: (user) => dispatch(updateUserObj(user)),
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentProfile);
