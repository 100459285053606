import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { APP_URL } from "../../../config";

const AppleLoginComponent = ({ className, btnText, onSuccess }) => {
  const _responseApple = async (response) => {
    try {
      if (response?.authorization && response?.authorization?.id_token) {
        const payload = {
          accessToken: response.authorization.id_token,
        };
        if (response.user?.name) payload.name = response.user.name;
        onSuccess(payload);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const _onClick = async () => {
    try {
      const data = await window.AppleID.auth.signIn();
      if (data) {
        _responseApple(data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    window.AppleID.auth.init({
      clientId: "com.ls.Closewise.web",
      scope: "name email",
      redirectURI: APP_URL,
      state: "state",
      nonce: "nonce",
      usePopup: true,
    });
  }, []);

  return (
    <Button className="btnIcon" onClick={_onClick}>
      {btnText ? (
        btnText
      ) : (
        <>
          <img
            src={require("../../../assets/img/apple-logo.png").default}
            alt="apple"
          />
        </>
      )}
    </Button>
  );
};

export default AppleLoginComponent;
