import React, { Component } from "react";
import { Modal, ModalBody, Button, ModalFooter } from "reactstrap";
class FileChecked extends Component {
  state = {
    fileNumber: '',
  }

  _closeModal = (action) => {
    console.log("in _closeModal");
    this.props.toggle();
  };

  componentDidUpdate = (prevProps) => {
    const { isOpen } = this.props;
    if (isOpen && isOpen !== prevProps.isOpen) {
      this.fileNumberGenerator()
    }
  };

  _getRandomInt = function (min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  };

  fileNumberGenerator = () => {
    let length = 8;
    let timestamp = +new Date();

    let ts = timestamp.toString();
    let parts = ts.split("").reverse();
    let id = "";

    for (let i = 0; i < length; ++i) {
      let index = this._getRandomInt(0, parts.length - 1);
      id += parts[index];
    }
    console.log(`%c ${id}`, "color:red");
    this.setState({ fileNumber: id })
  };
  _approvedFileNumber = () => {
    this.props.approved({ FileNumber: this.state.fileNumber })
  }
  render() {

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._closeModal()}
        className="modal-dialog-scrollable"
        centered
      >
        <ModalBody className="text-center">
          <h4 style={{ fontWeight: 600, fontSize: 16, marginTop: 10, marginBottom: 0 }}>
            A file number has not been added and will be filled with CW-
            {this.state.fileNumber} for reference
          </h4>
        </ModalBody>
        <ModalFooter>
          <Button
            outline
            color="primary"
            size="lg"
            onClick={() => this._approvedFileNumber()}
          >
            Approve
          </Button>
          <Button
            color="primary"
            size="lg"
            onClick={() => this._closeModal()}
          >
            Add my own
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default FileChecked;
