import React from "react";
import {
  // Table,
  Row,
  Col,
  Input,
  Button,
  Label,
  FormGroup,
  Card,
  CardBody,
} from "reactstrap";
import ViewReceiptModal from "../components/view-receipt-modal";
import AddExpenseAccountingCompany from "../components/add-expense-accounting-company-modal";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";

import {
  deleteCompanyExpense,
  fetchExpensesOfSigningCompany,
  terminateExpenseRecurring,
  updateExpenseSigningCompany,
} from "../../../http/http-calls";
import {
  deepClone,
  formatCurrencyValue,
  formatDateHideYearIfSame,
  showToast,
  openUrlOnNewTab,
  uploadFileOnServer,
  getLoggedInUserId,
  structureQueryParams,
  convertIsoToUtc,
  refreshFunc,
  formatOnlyDateMoment,
  errorHandler,
} from "../../../helper-methods";
import AddNotesModal from "../components/add-notes-income-list-modal";
import DocumentViewModal from "../components/documentViewModal";
import { connect } from "react-redux";
import {
  BASE_URL,
  //  itemListOptionsConfig
} from "../../../config";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomTable from "../components/CustomTable";
import CustomCard from "../components/CustomCard";

class ExpensesAccountingCompany extends React.Component {
  state = {
    expensesList: [],
    expenseType: [],
    expensesTotalCount: 0,
    expensesTotalAmount: 0,
    expenseSelectedRows: [],
    expensesHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      { id: "date", label: "Date", noSort: true },
      { id: "amount", label: "Amount", noSort: true },
      { id: "item", label: "Type", noSort: true },
      { id: "notes", label: "Notes", noSort: true },
      { id: "receipt", label: "Receipt", noSort: true },
      { id: "action", label: "Action", noSort: true },
    ],
    expensesCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    },
    addExpenseAccountingCompany: {
      isOpen: false,
      data: null,
    },
    addNotesModal: {
      isOpen: false,
      data: null,
    },
    viewReceiptModal: {
      isOpen: false,
      data: null,
    },
    documentViewModalData: {
      isOpen: false,
      externalUrl: null,
      modalHeading: null,
      fileType: null,
    },
    receipt: {
      file: {
        previewBlob: null,
        uploadData: null,
        type: null,
      },
      error: null,
      isValidate: false,
    },
    dateRangeValue: null,
    filters: {
      item: "",
      search: "",
    },
    loading: {
      showTableLoading: false,
      terminateExpenseLoading: false,
    },
    isClassAdded: false,
  };

  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => {
      this._paginate();
      this._persistFilter();
    });
  };

  _onToggleAddExpenseAccountingCompany = (isOpen = false, data = null) => {
    let { addExpenseAccountingCompany } = JSON.parse(
      JSON.stringify(this.state)
    );
    addExpenseAccountingCompany.isOpen = isOpen;
    addExpenseAccountingCompany.data = data;
    this.setState({
      addExpenseAccountingCompany,
    });
  };

  _onToggleViewReceiptModal = (isOpen = false, data = null) => {
    this.setState({
      viewReceiptModal: {
        isOpen,
        data,
      },
    });
  };

  _onToggleAddNotesModal = (isOpen = false, data = null) => {
    this.setState({
      addNotesModal: {
        isOpen,
        data,
      },
    });
  };

  _onToggleDocumentViewModal = (isOpen = false, expense = null) => {
    let { documentViewModalData } = deepClone(this.state);
    if (expense && isOpen) {
      documentViewModalData = {
        isOpen: true,
        externalUrl: expense.receipt,
        modalHeading: "View Receipt",
        fileType: expense.docType,
      };
    } else {
      documentViewModalData = {
        isOpen: false,
        externalUrl: null,
        modalHeading: null,
        fileType: null,
      };
    }
    this.setState({ documentViewModalData });
  };

  _filterOnChange = (type, value, isDelay = false) => {
    if (isDelay) clearTimeout(this.searchTimer);

    const { filters } = deepClone(this.state);
    filters[type] = value;

    this.setState({ filters }, () => {
      if (isDelay) {
        this.searchTimer = setTimeout(() => {
          this._paginate();
          this._persistFilter();
        }, 1000);
      } else {
        this._paginate();
        this._persistFilter();
      }
    });
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          type: "",
          search: "",
          item: "",
        },
        dateRangeValue: null,
      },
      () => {
        this._paginate();
        refreshFunc("company-accounting-expenses-responsive");
      }
    );
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._FetchExpensesSigningCompany();
    });
  };

  _terminateExpenseRecurring = (expenseId) => {
    this._manageLoading("terminateExpenseLoading", expenseId);
    terminateExpenseRecurring(expenseId)
      .then((res) => {
        const { expensesList } = deepClone(this.state);
        const expense = expensesList.find((exp) => exp._id === expenseId);
        if (expense) {
          expense["recurring"] = false;
          this.setState({ expensesList });
        }
        showToast("Expense Recurring Terminated.", "success");
        this._manageLoading("terminateExpenseLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("terminateExpenseLoading", false);
      });
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "amount": {
        // return cell ? formatCurrencyValue(cell) : "N/A";
        return cell || cell === 0 ? formatCurrencyValue(cell) : "N/A";
      }
      case "date": {
        return cell ? formatDateHideYearIfSame(cell) : "N/A";
      }
      case "notes": {
        return row ? (
          <Button
            color="link"
            className="actionBtn"
            onClick={() => this._onToggleAddNotesModal(true, row)}
          >
            <img
              src={require("../../../assets/img/nodes.svg").default}
              alt="document"
              style={{ height: 16 }}
            />
          </Button>
        ) : (
          "-"
        );
      }

      case "receipt": {
        return row && row.receipt && row.docType ? (
          <>
            <Button
              color="link"
              className="pl-0 text-left"
              title="View Receipt"
              onClick={() => this._onToggleDocumentViewModal(true, row)}
            >
              <img
                src={require("../../../assets/img/pdf.svg").default}
                style={{ height: 14, marginRight: "6px" }}
                alt="uploaded items"
              />
              {row?.receiptName ? row.receiptName : "View Receipt"}
            </Button>
            {/* <Button
              color="link"
              className="actionBtn"
              onClick={() => openUrlOnNewTab(row.receipt)}
            >
              <i className="fa fa-download" />
            </Button> */}
          </>
        ) : (
          <>
            {/* // "N/A" */}
            <Label className="uploadLink">
              <Input
                type="file"
                style={{ display: "none" }}
                accept="image/x-png,image/gif,image/jpeg,.pdf"
                value=""
                // disabled={this.state.loading}
                onChange={(event) => this._updateFile(event, row)}
              />
              <img
                style={{ height: 14, marginRight: "6px" }}
                src={require("../../../assets/img/uploadIconBlue.svg").default}
                alt="upload"
              />
              Upload Receipt
            </Label>
          </>
        );
      }
      // case "action": {
      //   return (
      //     row.recurring ? (
      //       <Button
      //         className="my-0 themeBtn px-2"
      //         style={{ fontWeight: 400, height: 38, lineHeight: "38px" }}
      //         disabled={this.state.loading.terminateExpenseLoading === row._id}
      //         onClick={() => this._terminateExpenseRecurring(row._id)}
      //       >
      //         {this.state.loading.terminateExpenseLoading === row._id ? (
      //           <i className="fa fa-spinner fa-spin mr-1" />
      //         ) : null}
      //         Terminate Recurring
      //       </Button>
      //     ) : (
      //       "-"
      //     ),
      //     (
      //       <Button
      //         color="link"
      //         className="px-2 ml-2"
      //         // onClick={() => _removeInstruction(index)}
      //         onClick={() => this._companyDeleteExpense(row?._id)}
      //         disabled={this.state.loading.showTableLoading ? true : false}
      //       >
      //         <i className="fa fa-trash-o text-danger" />
      //       </Button>
      //     )
      //   );
      // }
      case "action": {
        return (
          <>
            <Button
              color="link"
              onClick={() =>
                this._onToggleAddExpenseAccountingCompany(true, row)
              }
              disabled={this.state.loading.showTableLoading ? true : false}
            >
              <img
                src={require("../../../assets/img/pencil.svg").default}
                alt="edit"
              />
            </Button>
            <Button
              color="link"
              className="pl-0"
              onClick={() => this._companyDeleteExpense(row?._id)}
              disabled={this.state.loading.showTableLoading ? true : false}
            >
              <img
                src={require("../../../assets/img/deleteIcon.svg").default}
                alt="delete"
              />
            </Button>
            {row.recurring && (
              <Button
                color="link"
                disabled={
                  this.state.loading.terminateExpenseLoading === row._id
                }
                onClick={() => this._terminateExpenseRecurring(row._id)}
              >
                {this.state.loading.terminateExpenseLoading === row._id ? (
                  <i className="fa fa-spinner fa-spin mr-1" />
                ) : null}
                Terminate Recurring
              </Button>
            )}
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  _companyDeleteExpense = (id) => {
    this._manageLoading("showTableLoading", true);

    deleteCompanyExpense(id)
      .then((res) => {
        this._manageLoading("showTableLoading", false);
        showToast("Deleted Successfully!", "success");
        this._FetchExpensesSigningCompany();
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _updateFile = async (event, cell) => {
    const { receipt, loading } = deepClone(this.state);

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      const objFile = event.target.files[0];
      const objFileType = objFile.type.split("/")[0];
      if (objFileType === "image" || objFile.type.includes("pdf")) {
        receipt.file = {
          previewBlob: URL.createObjectURL(objFile),
          uploadData: objFile,
          type: objFileType === "application" ? "pdf" : objFileType,
        };
        loading["receiptLoading"] = true;
        this.setState({ receipt, loading }, () => {
          this._onSaveNotes(cell._id);
        });
      } else {
        showToast("Only Image or PDF file is allowed", "error");
      }
    }
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  componentDidMount = () => {
    // this._FetchExpensesSigningCompany();
    this._setFilterDataFromLocalStorage();
    // this._getAllFiltersData();
    HeaderEventEmitter.subscribe("reset-filter", () => {
      console.log("filter reset hit");
      this._resetFilter();
      refreshFunc("company-accounting-expenses");
    });
    HeaderEventEmitter.subscribe("add-expense", () => {
      console.log("add expense");
      this._onToggleAddExpenseAccountingCompany(true);
    });
    HeaderEventEmitter.subscribe("download-expense", () => {
      console.log("add expense");
      this._downloadData();
    });
  };

  _FetchExpensesSigningCompany = () => {
    this._manageLoading("showTableLoading", true);

    const { filters, tableConfig, dateRangeValue } = deepClone(this.state);

    const filterPayload = {};

    if (filters.item && filters.item.length)
      filterPayload["item"] = filters.item;

    if (filters.search && filters.search.trim().length)
      filterPayload["search"] = filters.search.trim();

    if (dateRangeValue) {
      filterPayload["startDate"] = dateRangeValue[0];
      filterPayload["endDate"] = dateRangeValue[1];
    }
    tableConfig["filters"] = { ...filterPayload };

    fetchExpensesOfSigningCompany(tableConfig)
      .then((res) => {
        this.setState(
          {
            expensesList: res?.expenses,
            expenseType:
              res?.expenseTypes?.map((item) => ({
                label: item,
                value: item,
              })) || [],
            expensesTotalCount: res?.totalCount,
            expensesTotalAmount: res?.totalAmount,
          },
          () => {
            this._manageLoading("showTableLoading", false);
          }
        );
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _onSaveNotes = async (id, notes) => {
    const { receipt } = deepClone(this.state);
    let payload = {
      notes: notes?.trim() ? notes.trim() : "",
    };
    if (receipt && receipt.file && receipt.file.uploadData) {
      const uploadedFilesRes = await uploadFileOnServer([receipt.file]);
      payload["receipt"] = uploadedFilesRes[0].url;
      payload["docType"] = uploadedFilesRes[0].docType;
      payload["receiptName"] = uploadedFilesRes[0].title;
    }

    return new Promise((resolve, reject) => {
      updateExpenseSigningCompany(id, payload)
        .then((res) => {
          this._FetchExpensesSigningCompany();
          this.setState({
            receipt: {
              file: {
                previewBlob: null,
                uploadData: null,
                type: null,
              },
              error: null,
              isValidate: false,
            },
          });
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _setSelectedRows = (expenseSelectedRows = []) => {
    // let obj = expenseSelectedRows.map((each) =>
    //   this.state.expensesList.find((item) => item.id === each)
    // );

    // let isObjectReceipt = obj.find(
    //   (each) => each.receipt === undefined || each.receipt === null
    // );

    // if (isObjectReceipt) {
    //   this.tableRef.current.onDeRowSelect(isObjectReceipt);
    // }

    // this.setState({
    //   expenseSelectedRows,
    // });

    try {
      expenseSelectedRows = expenseSelectedRows.filter(
        (each) => each.receipt !== undefined || each.receipt !== null
      );

      this.setState({ expenseSelectedRows });
    } catch (error) {
      console.log({ error });
    }
  };

  _downloadDataAsZip = () => {
    const { expenseSelectedRows } = deepClone(this.state);
    if (expenseSelectedRows.length) {
      let expenseSelectedRowsIds = expenseSelectedRows.map((item) => item._id);
      this._manageLoading("showTableLoading", true);
      openUrlOnNewTab(
        `${BASE_URL}/expense/download-receipts?expenseids=${JSON.stringify(
          expenseSelectedRowsIds
        )}&token=${this.props.userData.token}`
      );
      this._setSelectedRows();
      this._manageLoading("showTableLoading", false);
    } else {
      showToast("Please select expense", "error");
    }
  };

  _downloadData = (action = "download") => {
    const { expensesList, filters, dateRangeValue } = this.state;

    if (!expensesList.length) {
      showToast(`No data for ${action}`, "error");
      return;
    }

    const payload = {
      userId: getLoggedInUserId(),
    };

    if (dateRangeValue) {
      // payload["startDate"] = dateRangeValue[0];
      // payload["endDate"] = dateRangeValue[1];
      payload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      payload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
    }

    if (filters.item) payload["item"] = filters.item;

    const queryParams = structureQueryParams(payload);

    if (action === "download")
      openUrlOnNewTab(
        `${BASE_URL}/signingcompany/download/expenses${queryParams}`
      );
  };

  _persistFilter = () => {
    const { filters, dateRangeValue, tableConfig } = this.state;
    if (
      (filters &&
        ((filters.search && filters.search.length) || filters.item)) ||
      dateRangeValue !== null ||
      tableConfig.pageNumber !== 1
    ) {
      let data = { filters, dateRangeValue, tableConfig };
      localStorage.companyexpense = JSON.stringify(data);
    } else {
      delete localStorage.companyexpense;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    // const { filters, tableConfig } = deepClone(this.state);

    if (localStorage && localStorage.companyexpense) {
      try {
        const filters = JSON.parse(localStorage.companyexpense);
        let dateRange = null;
        if (filters?.dateRangeValue) {
          dateRange = [
            new Date(convertIsoToUtc(filters?.dateRangeValue[0])),
            new Date(convertIsoToUtc(filters?.dateRangeValue[1])),
          ];
        }

        this.setState(
          {
            filters: filters.filters,
            dateRangeValue: dateRange,
            tableConfig: filters.tableConfig,
          },
          () => {
            console.log("asdf ", this.state);
            this._FetchExpensesSigningCompany();
          }
        );
      } catch (e) {
        this._FetchExpensesSigningCompany();
      }
    } else {
      this._FetchExpensesSigningCompany();
    }
  };

  _prepareFilterData = () => {
    const { filters } = deepClone(this.state);

    if (!filters.client) delete filters.client;
    if (!filters.status) delete filters.status;
    if (!filters.search?.trim()?.length) delete filters.search;

    return filters;
  };

  _cardHeaderFormat = (cell, row, header) => {
    console.log("23456", row, cell, header);
    switch (header) {
      case "name": {
        return (
          <>
            <div className="cardTableTitle">
              <Label>Type</Label>
              <span>{row?.item ? row?.item : "N/A"}</span>
            </div>

            <>
              <Button
                color="link"
                onClick={() =>
                  this._onToggleAddExpenseAccountingCompany(true, row)
                }
                disabled={this.state.loading.showTableLoading ? true : false}
              >
                <img
                  src={require("../../../assets/img/pencil.svg").default}
                  alt="edit"
                />
              </Button>
              <Button
                color="link"
                className="pl-0"
                onClick={() => this._companyDeleteExpense(row?._id)}
                disabled={this.state.loading.showTableLoading ? true : false}
              >
                <img
                  src={require("../../../assets/img/deleteIcon.svg").default}
                  alt="delete"
                />
              </Button>
            </>
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Date </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.date ? formatDateHideYearIfSame(row?.date) : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Amount </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.amount || row?.amount === 0
                ? formatCurrencyValue(row?.amount)
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Notes </Label>
            <div>
              {row ? (
                <Button
                  color="link"
                  className="actionBtn"
                  onClick={() => this._onToggleAddNotesModal(true, row)}
                >
                  <img
                    src={require("../../../assets/img/nodes.svg").default}
                    alt="document"
                    style={{ height: 16 }}
                  />
                </Button>
              ) : (
                "-"
              )}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Receipt</Label>
            <div>
              {row && row?.receipt && row?.docType ? (
                <>
                  <Button
                    color="link"
                    className="p-0 text-left fs-12"
                    title="View Receipt"
                    onClick={() => this._onToggleDocumentViewModal(true, row)}
                  >
                    <img
                      src={require("../../../assets/img/pdf.svg").default}
                      style={{ height: 14, marginRight: "6px" }}
                      alt="uploaded items"
                    />
                    {row?.receiptName ? row?.receiptName : "View Receipt"}
                  </Button>
                  {/* <Button
              color="link"
              className="actionBtn"
              onClick={() => openUrlOnNewTab(row.receipt)}
            >
              <i className="fa fa-download" />
            </Button> */}
                </>
              ) : (
                <>
                  {/* // "N/A" */}
                  <Label className="uploadLink">
                    <Input
                      type="file"
                      style={{ display: "none" }}
                      accept="image/x-png,image/gif,image/jpeg,.pdf"
                      value=""
                      // disabled={this.state.loading}
                      onChange={(event) => this._updateFile(event, row)}
                    />
                    <img
                      style={{ height: 14, marginRight: "6px" }}
                      src={
                        require("../../../assets/img/uploadIconBlue.svg")
                          .default
                      }
                      alt="upload"
                    />
                    Upload Receipt
                  </Label>
                </>
              )}
            </div>
          </div>
        </li>
        {row?.recurring && (
          <li>
            <div className="dataWrap">
              <Button
                color="link"
                className="fs-12 remove d-block w-100"
                disabled={
                  this.state.loading.terminateExpenseLoading === row._id
                }
                onClick={() => this._terminateExpenseRecurring(row._id)}
              >
                {this.state.loading.terminateExpenseLoading === row._id ? (
                  <i className="fa fa-spinner fa-spin mr-1" />
                ) : null}
                Terminate Recurring
              </Button>
            </div>
          </li>
        )}
      </ul>
    );
  };

  render() {
    const {
      dateRangeValue,
      filters,
      expensesList,
      expenseType,
      expensesTotalCount,
      expensesTotalAmount,
      expensesHeaderKeys,
      tableConfig,
      loading,
      addNotesModal,
      viewReceiptModal,
      expenseSelectedRows,
      isClassAdded,
      documentViewModalData,
      expensesCardHeaderKeys,
    } = this.state;
    console.log(this.state);
    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>Expenses</h2>

            <div className="rightSide">
              <Button
                className="floatingButton"
                color="primary"
                onClick={() => this._onToggleAddExpenseAccountingCompany(true)}
              >
                Add Expenses
              </Button>

              <Button color="link" onClick={this._resetFilter}>
                <img
                  id="company-accounting-expenses-responsive"
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                />
              </Button>

              <Button
                className="filterBarIcon"
                Mileage
                color="link"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="Filter"
                />
              </Button>
            </div>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.handleButtonClick}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer responsiveFilter ${
              isClassAdded ? "show" : ""
            }`}
          >
            <div className="filterIcon">
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
              />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>
            <div className="filterWrapper">
              <FormGroup className="dateRange">
                <Label>Date Range</Label>
                <DateRangePicker
                  className="dateRange"
                  format="MM-dd-y"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  onChange={(dateRangeValue) =>
                    this._onChangeDatePicker(dateRangeValue)
                  }
                  value={dateRangeValue}
                />
              </FormGroup>
              <FormGroup>
                <Label>Type</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    value={filters?.item}
                    name="type"
                    onChange={(e) =>
                      this._filterOnChange("item", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {/* {itemListOptionsConfig.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))} */}
                    {expenseType.map((item) => (
                      <option key={item.value} value={item.value}>
                        {item.label}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <Button
                color="link"
                outline
                className="downloadButton ml-auto"
                onClick={() => this._downloadData()}
              >
                Download
                <img
                  src={require("../../../assets/img/download_blue.png").default}
                  alt="download"
                />
              </Button>
            </div>
            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>
          <div className="downloadButtonPos">
            <Button color="link" outline onClick={() => this._downloadData()}>
              Download
              <img
                src={require(`../../../assets/img/download_blue.png`).default}
                alt="download"
              />
            </Button>
          </div>

          <Row className="mt-4">
            <Col xl={3} lg={6} xs={6}>
              <Card className="infoCard">
                <CardBody>
                  <div className="">
                    <span>Transactions</span>
                    <h4> {expensesTotalCount}</h4>
                  </div>
                  <div className="icon">
                    <img
                      src={
                        require("../../../assets/img/Transactions.svg").default
                      }
                      alt="notaryActs"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={3} lg={6} xs={6}>
              <Card className="infoCard">
                <CardBody>
                  <div className="">
                    <span>Total Expenses</span>
                    <h4>{expensesTotalAmount}</h4>
                  </div>
                  <div className="icon">
                    <img
                      src={
                        require("../../../assets/img/TotalExpense.svg").default
                      }
                      alt="notaryActs"
                    />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {expenseSelectedRows.length ? (
            <div
              className="d-flex align-items-center justify-content-between"
              style={{ marginBottom: 25 }}
            >
              {/* when the checkboxes in the below table are selected/checked then below text is to be shown */}
              <div style={{ fontWeight: 500, fontSize: 14 }}>
                {expenseSelectedRows.length}
                {expenseSelectedRows.length > 1 ? " Records" : " Record"} are
                selected
              </div>

              <Button
                color="link"
                outline
                className="ml-auto"
                onClick={() => this._downloadDataAsZip()}
              >
                Download Zip
                <img
                  src={require(`../../../assets/img/download_blue.png`).default}
                  alt="download"
                />
              </Button>
            </div>
          ) : null}
          <div className="hideDesktop">
            <CustomCard
              isPageStartFromOne={true}
              pageNumber={tableConfig.pageNumber}
              tableData={expensesList}
              headerKeys={expensesCardHeaderKeys}
              // bodyKeys={agentClosingCardBodyKeys}
              dataFormat={this._dataFormat}
              totalCount={expensesTotalCount}
              onPaginate={(pageNumber, pageSize) =>
                this._paginate(pageNumber, pageSize)
              }
              rowSelection={true}
              selectedRows={expenseSelectedRows}
              setSelectedRows={(selectedRows) =>
                // console.log("selectedRows",selectedRows)
                this._setSelectedRows(selectedRows)
              }
              // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
              showTableLoading={loading.showTableLoading}
              cardHeaderFormat={this._cardHeaderFormat}
              cardDataFormat={this._cardDataFormat}
            />
          </div>

          <div className="hideMobile">
            {expensesHeaderKeys && expensesHeaderKeys.length && (
              <CustomTable
                striped
                isPageStartFromOne={true}
                pageNumber={tableConfig.pageNumber}
                tableData={expensesList}
                headerKeys={expensesHeaderKeys}
                dataFormat={this._dataFormat}
                totalCount={expensesTotalCount}
                rowSelection={true}
                onPaginate={(pageNumber, pageSize) =>
                  this._paginate(pageNumber, pageSize)
                }
                onSortChange={(sortName, sortOrder) =>
                  // sort function is disabled
                  this._paginate()
                }
                selectedRows={expenseSelectedRows}
                setSelectedRows={(selectedRows) =>
                  // console.log("selectedRows",selectedRows)
                  this._setSelectedRows(selectedRows)
                }
                showTableLoading={loading.showTableLoading}
                ref={this.tableRef}
              />
            )}
          </div>
          <AddNotesModal
            isOpen={addNotesModal.isOpen}
            data={addNotesModal.data}
            toggle={() => this._onToggleAddNotesModal()}
            onSaveNotes={(id, notes) => this._onSaveNotes(id, notes)}
          />

          <AddExpenseAccountingCompany
            isOpen={this.state.addExpenseAccountingCompany.isOpen}
            data={this.state.addExpenseAccountingCompany.data}
            toggle={() => this._onToggleAddExpenseAccountingCompany()}
            resetTable={() => this._paginate()}
            expenseType={this.state.expenseType}
          ></AddExpenseAccountingCompany>

          <ViewReceiptModal
            isOpen={viewReceiptModal.isOpen}
            creatorData={viewReceiptModal.data}
            toggle={() => this._onToggleViewReceiptModal()}
          ></ViewReceiptModal>

          {documentViewModalData.isOpen && (
            <DocumentViewModal
              isOpen={documentViewModalData.isOpen}
              externalUrl={documentViewModalData.externalUrl}
              fileType={documentViewModalData.fileType}
              modalHeading={documentViewModalData.modalHeading}
              toggle={this._onToggleDocumentViewModal}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

export default connect(mapStateToProps, null)(ExpensesAccountingCompany);
