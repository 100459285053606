export const customClosingAssigned = `
<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap" rel="stylesheet" />

    <title>Agent Registration</title>
    <style>
        body {
            margin: 0;
            padding: 0;
            font-family: "Nunito", sans-serif;
            font-weight: 400;
            max-width: 680px;
            background-color: #f5f5f5;
        }

        p {
            font-size: 14px;
        }

        .table {
            max-width: 650px;
            margin: auto;
            background-color: #e1c8fe;
            border: 1px solid #8148cc48;
        }

        .socialList {
            margin: 0;
            padding: 0;
            clear: both;
            margin-top: 2px;
        }

        .socialList li {
            display: inline-block;
            margin-right: 10px;
            margin-left: 0px;
        }

        .socialList li a {
            width: 30px;
            height: 30px;
            display: block;
        }

        .socialList li a img {
            width: 100%;
            height: 100%;
        }
    </style>
</head>

<body class="body" style="
      padding: 0 !important;
      margin: 0 !important;
      display: block !important;
      min-width: 100% !important;
      width: 100% !important;
      -webkit-text-size-adjust: none;
    ">
    <table style="
        padding: 15px 8px;
        color: #414141;
        background-color: #f5f5f5;
        border-radius: 10px;" cellpadding="0" cellspacing="0" height="100%" width="100%">
        <tbody>
            <tr>
                <td align="center" valign="top">
                    <table style="
                width: 100%;
                max-width: 500px;
                border-radius: 10px;
                border-spacing: 0px;
                display: block;
                padding:15px 10px 0px;
                overflow: hidden;
                background-color: #ffffff;
              " cellpadding="0" cellspacing="0" width="100%">
                        <tbody style="width: 100%; display: inline-table;">
                            <!-- Logo -->
                           
                            <tr>
                                <td colspan="2" style=" color: #414141; line-height: 26px; font-size: 16px;"
                                    align="left">
                                    <p style="margin-bottom: 0;">Hello name,</strong></p>
                                    <p style="margin-top: 8px; margin-bottom: 0; font-size: 14px; line-height: 1.5;">
                                      companyName has assigned the orderType for date at time at address. If you have any questions or concerns regarding the order, please don't hesitate to reach out via email or in the order chat log. We’d be happy to assist with questions you may have.
                                    </p>
                                    <p style="margin-top: 8px; margin-bottom: 0; font-size: 14px; line-height: 1.5;">
                                      Please review and follow any special instructions added in the order including any scan back requirements.
                                    </p>
                                    <p style="margin-top: 8px; margin-bottom: 0; font-size: 14px; line-height: 1.5;">
                                      Click the button below to review the order details on CloseWise and confirm your signing appointment with the signers.
                                    </p>
                                </td>
                            </tr>
                           
                            <tr>
                                <td colspan="2" style="padding:10px;">  
                                    <p style="margin-bottom: 0;">Best regards,</p>
                                    <p style="margin-top: 8px;">companyName</p>
                                </td>
                            </tr>      
                           
                        </tbody>
                    </table>
                   
                </td>
            </tr>
        </tbody>
    </table>
</body>

</html>






`;
