import React, { useState, useEffect, useMemo } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Button,
  CustomInput,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  UncontrolledCollapse,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  capitalize,
  errorHandler,
  formatDateTimeFlexible,
  formattedStr,
  formatTime24hour,
  getDropdownColor,
  showToast,
} from "../../../helper-methods";
import { dayNames, FrequencyEnum } from "../../../config";
import {
  agentGetClientWiseAutomation,
  agentUpdateClientWiseAutomation,
  updateAgentSettings,
} from "../../../http/http-calls";

const initialValues = {
  frequency: "1stDayOfMonth",
  day: "",
  time: "10:00 AM",
  isActive: false,
};

let initialIsDirty = {
  frequency: false,
  day: false,
};

export default function AgentSettingsInvoicing({
  agentSettings,
  getAgentSettings,
}) {
  const [invoiceAutomationSetting, setInvoiceAutomationSetting] =
    useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [paymentInvoiceOverDue, setPaymentInvoiceOverDue] = useState([]);
  const [isDirty, setIsDirty] = useState(initialIsDirty);
  const [errors, setErrors] = useState({});

  const [clientWiseAutomationSetting, setclientWiseAutomationSetting] =
    useState([]);

  const [isDirtyClient, setIsDirtyClient] = useState([
    initialIsDirty,
    initialIsDirty,
  ]);

  const isDisabledDay = useMemo(() => {
    return (
      invoiceAutomationSetting.frequency === "1stDayOfMonth" ||
      invoiceAutomationSetting.frequency === "lastDayOfMonth" ||
      invoiceAutomationSetting.frequency === "15thOfMonth" ||
      invoiceAutomationSetting.frequency === "biWeekly" ||
      invoiceAutomationSetting.frequency === "eachOrder"
    );
  }, [invoiceAutomationSetting]);

  const _onChangeFormFields = (key, value, index) => {
    const updatedObj = { ...invoiceAutomationSetting };
    const newIsDirty = { ...isDirty };

    updatedObj[key] = value;
    if (
      value === "1stDayOfMonth" ||
      value === "lastDayOfMonth" ||
      value === "15thOfMonth" ||
      value === "biWeekly" ||
      value === "eachOrder"
    ) {
      updatedObj["day"] = "";
    }
    if (value === "eachOrder") {
      updatedObj["time"] = "10:00";
    }
    setInvoiceAutomationSetting(updatedObj);
    setIsDirty(newIsDirty);
    _validateForm(updatedObj, newIsDirty);

    // const newArray = invoiceAutomationSetting.map((item, i) => {
    //   if (index === i) {
    //     return { ...item, [key]: value };
    //   } else {
    //     return item;
    //   }
    // });
    // setInvoiceAutomationSetting(newArray);
  };

  const _onChangeFormFieldsClientWise = (key, value, index) => {
    const updatedObj = [...clientWiseAutomationSetting];
    const newIsDirty = { ...isDirtyClient };
    // console.log("dffd",key,value,index)
    updatedObj[index][key] = value;
    updatedObj[index]["isChanged"] = true;
    if (
      value === "1stDayOfMonth" ||
      value === "lastDayOfMonth" ||
      value === "15thOfMonth" ||
      value === "biWeekly" ||
      value === "eachOrder"
    ) {
      updatedObj[index]["day"] = "";
    }
    if (value === "eachOrder") {
      updatedObj[index]["time"] = "10:00";
    }
    setclientWiseAutomationSetting(updatedObj);

    // const newArray = clientWiseAutomationSetting.map((item, i) => {
    //   if (index === i) {
    //     return { ...item, [key]: value };
    //   } else {
    //     return item;
    //   }
    // });
    // setclientWiseAutomationSetting(newArray);
    setIsDirtyClient(newIsDirty);
    _validateFormClient(updatedObj, newIsDirty);
  };

  // const _addInvoiceAutomationSetting = () => {
  //   const newInvoiceAutomationSetting = [...invoiceAutomationSetting];
  //   // console.log("first", newInvoiceAutomationSetting);
  //   newInvoiceAutomationSetting.push(initialValues);
  //   setInvoiceAutomationSetting(newInvoiceAutomationSetting);
  // };
  // const _deleteInvoiceAutomationSetting = (index) => {
  //   const newInvoiceAutomationSetting = [...invoiceAutomationSetting];
  //   // console.log("first", newInvoiceAutomationSetting);
  //   newInvoiceAutomationSetting.splice(index, 1);
  //   setInvoiceAutomationSetting(newInvoiceAutomationSetting);
  // };
  const _settingInvoicing = async () => {
    const newIsDirty = { ...isDirty };
    Object.keys(isDirty).forEach((each) => (newIsDirty[each] = true));

    const error = await _validateForm(invoiceAutomationSetting, newIsDirty);
    // console.log("error", error);

    const newIsDirtyClient = { ...isDirtyClient };
    Object.keys(isDirtyClient).forEach(
      (each) => (newIsDirtyClient[each] = true)
    );

    const errorClient = await _validateFormClient(
      clientWiseAutomationSetting,
      newIsDirtyClient
    );
    let payload = {
      invoiceAutomationSetting: {
        frequency: invoiceAutomationSetting.frequency,
        day: invoiceAutomationSetting.day.trim().length
          ? invoiceAutomationSetting.day
          : undefined,
        time:
          invoiceAutomationSetting.frequency !== "eachOrder"
            ? invoiceAutomationSetting.time
            : undefined,
        isActive: invoiceAutomationSetting.isActive,
      },
      paymentInvoiceOverDue: paymentInvoiceOverDue
        ? paymentInvoiceOverDue
        : undefined,
    };
    // let clientWisePayload = {};
    if (error && errorClient) {
      setLoading(true);
      try {
        await updateAgentSettings(payload);
        getAgentSettings();
        _updateClientAutomation();
        // await companyUpdateClientWiseAutomation(id,clientWisePayload)
        showToast("Settings are updated", "success");
        setLoading(false);
      } catch (error) {
        errorHandler(error);
        setLoading(false);
      }
    }
  };

  const _updateClientAutomation = () => {
    return new Promise((resolve, reject) => {
      const promises = clientWiseAutomationSetting
        .filter((item) => item?.isChanged)
        .map((element) => {
          // Make an API request and return the Promise
          if (element?.day?.trim()?.length === 0) {
            delete element?.day;
          }
          delete element?.isChanged;
          delete element?.client;
          return agentUpdateClientWiseAutomation(element?._id, element);
        });

      Promise.all(promises)
        .then((response) => {
          _agentGetClientWiseAutomation();
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  useEffect(() => {
    console.log("dvfeafve", agentSettings);
    if (agentSettings?.invoiceAutomationSetting) {
      setInvoiceAutomationSetting({
        frequency: agentSettings?.invoiceAutomationSetting?.frequency || "",
        day: agentSettings?.invoiceAutomationSetting?.day || "",
        time: agentSettings?.invoiceAutomationSetting?.time || "",
        isActive: agentSettings?.invoiceAutomationSetting?.isActive || false,
      });
      setPaymentInvoiceOverDue(agentSettings.paymentInvoiceOverDue || []);
    }
  }, [agentSettings]);

  useEffect(() => {
    _agentGetClientWiseAutomation();
  }, []);

  const _agentGetClientWiseAutomation = async () => {
    try {
      let response = await agentGetClientWiseAutomation();
      if (response?.automationClients?.length > 0) {
        let resArray = [];
        response?.automationClients.forEach((item, index) => {
          resArray.push({
            _id: item?._id,
            client: item?._client || item?._assistant || item?._signingCompany,
            frequency: item?.frequency,
            day: item?.day,
            time: item?.time,
            isActive: item?.isActive,
            company: item?._signingCompany,
          });
        });
        setclientWiseAutomationSetting(resArray);
        // console.log("resArr", resArray);
      }
      // showToast("Settings are updated", "success");
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _validateForm = (newFormFields, isDirty) => {
    return new Promise((resolve, reject) => {
      const error = { ...errors };
      let isFormValid = true;

      Object.keys(newFormFields).forEach((fieldName) => {
        switch (fieldName) {
          case "frequency": {
            if (!newFormFields[fieldName].trim().length && isDirty[fieldName]) {
              error[fieldName] = "*Required";
              isFormValid = false;
            } else {
              delete error[fieldName];
              isDirty[fieldName] = false;
            }
            break;
          }
          case "day": {
            if (
              newFormFields["frequency"] === "weekly" &&
              // ||newFormFields["frequency"] === "biWeekly" ||
              //   newFormFields["frequency"] === "monthly"
              isDirty[fieldName]
            ) {
              if (!newFormFields[fieldName].trim().length) {
                error[fieldName] = "*Required";
                isFormValid = false;
              } else {
                delete error[fieldName];
                isDirty[fieldName] = false;
              }
            } else {
              delete error[fieldName];
              isDirty[fieldName] = false;
            }
            break;
          }

          default: {
          }
        }
      });

      setIsDirty(isDirty);
      setErrors(error);
      resolve(isFormValid);
    });
  };

  const _validateFormClient = (newFormFields, isDirty) => {
    return new Promise((resolve, reject) => {
      const error = { ...errors };
      let isFormValid = true;
      newFormFields.map((item) =>
        Object.keys(item).forEach((fieldName) => {
          switch (fieldName) {
            case "frequency": {
              if (!item[fieldName]?.trim()?.length && isDirty[fieldName]) {
                error[fieldName] = "*Required";
                isFormValid = false;
              } else {
                delete error[fieldName];
                isDirty[fieldName] = false;
              }
              break;
            }
            case "day": {
              if (
                item["frequency"] === "weekly" &&
                // || item["frequency"] === "biWeekly"
                // || item["frequency"] === "monthly"
                isDirty[fieldName]
              ) {
                if (!newFormFields[fieldName]?.trim()?.length) {
                  error[fieldName] = "*Required";
                  isFormValid = false;
                } else {
                  delete error[fieldName];
                  isDirty[fieldName] = false;
                }
              } else {
                delete error[fieldName];
                isDirty[fieldName] = false;
              }
              break;
            }

            default: {
            }
          }
        })
      );

      setIsDirtyClient(isDirty);
      resolve(isFormValid);
    });
  };
  const _handleOnPaymentInvoiceChange = (value) => {
    // setPaymentInvoiceOverDue(paymentInvoiceOverDue ? "" : value);
    let paymentInvoiceOverDueArray = [...paymentInvoiceOverDue];
    console.log(value);
    if (paymentInvoiceOverDueArray.includes(value)) {
      const index = paymentInvoiceOverDueArray.indexOf(value);
      paymentInvoiceOverDueArray.splice(index, 1);
    } else {
      paymentInvoiceOverDueArray.push(value);
    }
    // return paymentInvoiceOverDueArray;
    setPaymentInvoiceOverDue(paymentInvoiceOverDueArray);
  };
  return (
    <>
      <div className="invoicingFrequency">
        <h5>Default Automated Invoicing Frequency</h5>
        <Row>
          <Col md="6" xl="6">
            <FormGroup className="floatingLabel">
              <div className="custom-select-wrapper">
                <Input
                  type="select"
                  value={invoiceAutomationSetting.frequency}
                  onChange={(e) =>
                    _onChangeFormFields("frequency", e.target.value)
                  }
                >
                  {FrequencyEnum.map((item) => (
                    <option value={item}>{formattedStr(item)}</option>
                  ))}
                </Input>
                <Label>Date</Label>
              </div>
            </FormGroup>
          </Col>
          <Col md="6" xl="6">
            <FormGroup className="floatingLabel">
              <div
                className={`custom-select-wrapper ${
                  isDisabledDay ? "disabled" : " "
                }`}
              >
                <Input
                  type="select"
                  value={invoiceAutomationSetting.day}
                  onChange={(e) => _onChangeFormFields("day", e.target.value)}
                  disabled={isDisabledDay}
                >
                  <option value="">Select</option>
                  {dayNames.map((item, index) => (
                    <option value={item.value} key={index}>
                      {capitalize(item.value)}
                    </option>
                  ))}
                </Input>
                <Label>Start Day</Label>
              </div>
              {errors?.day && (
                <div className="validation-error">{errors?.day}</div>
              )}
            </FormGroup>
          </Col>
          <Col md={6} xl={6}>
            <FormGroup>
              <ReactDatetime
                dateFormat={false}
                inputProps={{
                  className: "form-control customTimeWrap",
                  placeholder: " ",
                  value: formatDateTimeFlexible(
                    invoiceAutomationSetting.time,
                    "HH:mm",
                    "hh:mm A"
                  ),
                  disabled: invoiceAutomationSetting.frequency === "eachOrder",
                }}
                onChange={(e) =>
                  e &&
                  _onChangeFormFields(
                    "time",
                    // formatTime(new Date(e._d)?.toISOString())
                    formatTime24hour(new Date(e._d)?.toISOString())
                  )
                }
                timeConstraints={{ minutes: { step: 15 } }}
                timeFormat="HH:mm A"
                closeOnSelect={true}
              />
              {errors?.time && (
                <div className="validation-error">{errors?.time}</div>
              )}
            </FormGroup>
          </Col>
          <Col md={6} xl={6}>
            <CustomInput
              type="switch"
              id="exampleCustomSwitch"
              name="customSwitch"
              className="mt-2 mb-md-0 mb-4"
              checked={invoiceAutomationSetting.isActive}
              onChange={(e) =>
                _onChangeFormFields("isActive", e.target.checked)
              }
            />
            <Label>
              Turn on to activate default invoice automation for new clients
            </Label>
          </Col>
        </Row>
        {/* ))} */}
        <div className="automationAccordian">
          <div id="toggler" className="accordianHead">
            Client wise Automation Setting
            {/* toggle this class -> "toggleIcon" on click */}
            <img
              src={require("../../../assets/img/arrow.png").default}
              alt="arrow"
              height={10}
            />
          </div>
          <UncontrolledCollapse toggler="toggler">
            <ul className="automationList">
              {clientWiseAutomationSetting.length > 0 ? (
                clientWiseAutomationSetting.map((item, index) => (
                  <li>
                    <div className="automationItem">
                      <Label>Client</Label>
                      <span>
                        {item?.client
                          ? capitalize(item?.client?.name?.full)
                          : capitalize(item?.company?.companyName)}
                      </span>
                    </div>

                    <div className="automationItem">
                      <Label>Selected Frequency</Label>
                      <div className="custom-select-wrapper">
                        <Input
                          type="select"
                          value={item.frequency}
                          onChange={(e) =>
                            _onChangeFormFieldsClientWise(
                              "frequency",
                              e.target.value,
                              index
                            )
                          }
                        >
                          <option>Select</option>
                          {FrequencyEnum.map((item) => (
                            <option value={item}>{formattedStr(item)}</option>
                          ))}
                        </Input>
                      </div>
                    </div>
                    <div className="automationItem">
                      <Label>Starting From</Label>
                      <div className="custom-select-wrapper">
                        <Input
                          type="select"
                          value={item.day}
                          onChange={(e) =>
                            _onChangeFormFieldsClientWise(
                              "day",
                              e.target.value,
                              index
                            )
                          }
                          disabled={
                            item.frequency === "1stDayOfMonth" ||
                            item.frequency === "lastDayOfMonth" ||
                            item.frequency === "15thOfMonth" ||
                            item.frequency === "biWeekly" ||
                            item.frequency === "eachOrder"
                          }
                        >
                          <option>Select</option>
                          {dayNames.map((item, index) => (
                            <option value={item.value} key={index}>
                              {capitalize(item.value)}
                            </option>
                          ))}
                        </Input>
                      </div>
                      {errors?.day && (
                        <div className="validation-error">{errors?.day}</div>
                      )}
                    </div>
                    <div className="automationItem">
                      <Label>Time</Label>
                      <ReactDatetime
                        dateFormat={false}
                        inputProps={{
                          className: "form-control customTimeWrap",
                          placeholder: "Select Time",
                          value: formatDateTimeFlexible(
                            item.time,
                            "HH:mm",
                            "hh:mm A"
                          ),
                          disabled: item.frequency === "eachOrder",
                        }}
                        onChange={(e) =>
                          _onChangeFormFieldsClientWise(
                            "time",
                            formatTime24hour(new Date(e._d).toISOString()),
                            index
                          )
                        }
                        timeConstraints={{ minutes: { step: 15 } }}
                        timeFormat="HH:mm A"
                        closeOnSelect={true}
                      />
                      {errors?.time && (
                        <div className="validation-error">{errors?.time}</div>
                      )}
                    </div>
                    <div className="automationItem">
                      {/* <CustomInput
                        type="switch"
                        id={`exampleCustomSwitchClient${index}`}
                        name="customSwitch"
                        label=""
                        className="ml-2"
                        checked={item.isActive}
                        onChange={(e) =>
                          _onChangeFormFieldsClientWise(
                            "isActive",
                            e.target.checked,
                            index
                          )
                        }
                      /> */}
                      <div className="customSelectWrap" style={{ width: 100 }}>
                        <Input
                          type="select"
                          name="status"
                          className={`status ${getDropdownColor(
                            item.isActive ? "active" : "inactive"
                          )}`}
                          value={item.isActive ? "active" : "inactive"}
                          onChange={(e) =>
                            _onChangeFormFieldsClientWise(
                              "isActive",
                              e.target.value === "active" ? true : false,
                              index
                            )
                          }
                        >
                          <option value="active">Active</option>
                          <option value="inactive">Inactive</option>
                        </Input>
                        <div className="downArrow">
                          <i className="fa fa-chevron-down"></i>
                        </div>
                      </div>
                    </div>
                  </li>
                ))
              ) : (
                <li>
                  <div className="nodata">
                    <img
                      src={require("../../../assets/img/noData.svg").default}
                      alt="no data"
                    />
                    <p>No Client Found!</p>
                  </div>
                </li>
              )}
            </ul>
          </UncontrolledCollapse>
        </div>
      </div>

      <Card className="mt-4">
        <CardHeader>Payment Invoice Overdue</CardHeader>
        <CardBody>
          <div className="d-flex mb-1">
            <CustomInput
              type="radio"
              id="60Days"
              name="invoice"
              label="60 Days"
              className="mr-4"
              checked={paymentInvoiceOverDue.includes(60)}
              onChange={(event) => _handleOnPaymentInvoiceChange(60)}
            />
            <CustomInput
              type="radio"
              id="45Days"
              name="invoice"
              label="45 Days"
              className="mr-4"
              checked={paymentInvoiceOverDue.includes(45)}
              onChange={(event) => _handleOnPaymentInvoiceChange(45)}
            />
          </div>
        </CardBody>
      </Card>
      <div className="text-center">
        <Button
          color="primary"
          size="lg"
          disabled={loading}
          onClick={() => _settingInvoicing()}
        >
          Save
        </Button>
      </div>
    </>
  );
}
