import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Progress,
  CustomInput,
} from "reactstrap";
import {
  deepClone,
  showToast,
  uploadFileOnServer,
  getPhoneNumberFromBrackets,
  errorHandler,
} from "../../../helper-methods";
import { RegexConfig } from "../../../config/RegexConfig";
import {
  getUserProfileDetail,
  updateUserProfileDetail,
} from "../../../http/http-calls";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import { DEFAULT_PROFILE_PICTURE } from "../../../config";
import { updateAssistantObj } from "../../../redux/actions/user-data";

export class UserProfile extends Component {
  state = {
    basicDetails: {
      name: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      email: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      phoneCountry: {
        value: "US",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      phone: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      password: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      profilePicture: {
        file: {
          previewBlob: null,
          uploadData: null,
          type: null,
        },
        error: null,
        isDirty: false,
      },
    },
    showPassword: {
      password: true,
    },
    isShowPassword: false,
    userDetails: null,
    changePassword: false,
  };

  _showPasswordToggle = () => {
    this.setState({ isShowPassword: !this.state.isShowPassword });
  };

  componentDidMount() {
    this._getUserProfileDetail();
    setTimeout(() => {
      this.setState({
        showPassword: {
          password: false,
        },
      });
    }, 3000);
  }

  _getUserProfileDetail = () => {
    return new Promise((resolve, reject) => {
      let id = this.props.userData.user._assistant._id;
      let userType = this.props.userData?.user?.type?.toLowerCase();
      // console.log(id)
      getUserProfileDetail({ userType, id })
        .then((res) => {
          if (res.user) {
            console.log(this.props.userData.user);
            this.props.updateAssistantObj(res.user);
          }
          this.setState({ userDetails: res.user });
          this._setFormData(res.user);
          this.props.hideLoader();
          resolve(res);
        })
        .catch((error) => {
          this.props.hideLoader();
          reject(error);
        });
    });
  };

  _updateFileImageBasicDetail = (fieldName, event) => {
    const { basicDetails } = deepClone(this.state);

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      let objFile = event.target.files[0];
      let objFileType = objFile.type.split("/")[0];
      if (objFileType === "image") {
        basicDetails[fieldName].file = {
          previewBlob: URL.createObjectURL(objFile),
          uploadData: objFile,
          type: objFileType === "application" ? "pdf" : objFileType,
        };
        this.setState({ basicDetails });
      } else {
        showToast("Only Image file is allowed", "error");
      }
    }
  };

  _setFormData = (data) => {
    let { basicDetails } = deepClone(this.state);

    basicDetails["name"].value = data.name.full;
    basicDetails["email"].value = data.email;

    basicDetails["phone"].value = data.phone
      ? getPhoneNumberFromBrackets(data.phone)
      : "";

    basicDetails["profilePicture"].file = {
      previewBlob: data.profilePicture ? data.profilePicture : null,
      uploadData: null,
      type: null,
    };

    this.setState({ basicDetails });
  };

  _onChangeBasicDetail = (fieldName, value) => {
    const { basicDetails } = this.state;
    if (fieldName === "email") {
      return;
    }

    if (fieldName === "agentAddress") {
      this._googlePlaceSearch(value);
      basicDetails[fieldName].placeId = null;
    }

    basicDetails[fieldName].value = value;
    basicDetails[fieldName].isDirty = true;
    this.setState({ basicDetails }, () => {
      // Validation
      this._validateBasicDetailForm();
    });
  };

  _validateBasicDetailForm = () => {
    return new Promise((resolve, reject) => {
      const { basicDetails } = this.state;

      let isFormValid = true;

      Object.keys(basicDetails).forEach((key) => {
        if (basicDetails[key].isDirty && basicDetails[key].isValidate) {
          switch (key) {
            case "name": {
              if (
                basicDetails[key].value &&
                basicDetails[key].value.trim().length
              ) {
                basicDetails[key].isDirty = false;
                basicDetails[key].error = null;
              } else {
                basicDetails[key].isDirty = true;
                basicDetails[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "email": {
              if (
                basicDetails[key].value &&
                basicDetails[key].value.trim().length
              ) {
                if (
                  RegexConfig.email.test(
                    String(basicDetails[key].value).toLowerCase()
                  )
                ) {
                  basicDetails[key].isDirty = false;
                  basicDetails[key].error = null;
                } else {
                  basicDetails[key].isDirty = true;
                  basicDetails[key].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                basicDetails[key].isDirty = true;
                basicDetails[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "phone": {
              if (basicDetails[key].value && basicDetails[key].value.length) {
                if (
                  RegexConfig.phone.test(
                    String(basicDetails[key].value).toLowerCase()
                  )
                ) {
                  basicDetails[key].isDirty = false;
                  basicDetails[key].error = null;
                } else {
                  isFormValid = false;
                  basicDetails[key].isDirty = true;
                  basicDetails[key].error = "*Invalid Phone Number";
                }
              } else {
                basicDetails[key].isDirty = false;
                basicDetails[key].error = null;
              }
              break;
            }
            case "password": {
              if (
                basicDetails[key].value &&
                basicDetails[key].value.trim().length
              ) {
                if (!basicDetails[key].value.trim().length) {
                  basicDetails[key].error = "Password is Required";
                } else if (
                  !new RegExp("^(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$").test(
                    basicDetails[key].value
                  )
                ) {
                  basicDetails[key].error =
                    "Min password length should be 8 , Req 1 digit , a special char";
                } else {
                  basicDetails[key].error = null;
                  basicDetails[key].isDirty = false;
                }
              } else {
                basicDetails[key].error = null;
                basicDetails[key].isDirty = false;
              }
              break;
            }
            default: {
            }
          }
        }
      });

      this.setState({ basicDetails }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onSubmitBasicDetail = async () => {
    const { basicDetails } = this.state;

    let isFormValid = Object.values(basicDetails).every((value) => {
      return value.error === null;
    });

    if (!isFormValid) {
      showToast("Please Fill The Required Fields", "error");
    }

    if (isFormValid) {
      this.props.showLoader("Update Company Profile...");

      try {
        this.setState({ loading: true });

        let splitName = basicDetails.name.value.trim().split(" ");

        if (splitName?.length) {
          splitName = {
            first:
              splitName.length > 1
                ? splitName.slice(0, -1).join(" ")
                : splitName[0],
            last: splitName.length > 1 ? splitName[splitName.length - 1] : "",
          };
        }

        const payload = {
          id: this.state.userDetails._id,
          name: {
            first: splitName.first?.trim() ? splitName.first.trim() : "",
            last: splitName.last?.trim() ? splitName.last.trim() : "",
          },
        };

        if (basicDetails.phone.value) {
          payload["phone"] = `${basicDetails.phone.value}`;
        }

        if (basicDetails.password.value) {
          payload["password"] = `${basicDetails.password.value}`;
        }

        if (
          basicDetails.profilePicture.file &&
          basicDetails.profilePicture.file.uploadData
        ) {
          const uploadedFilesRes = await uploadFileOnServer([
            basicDetails.profilePicture.file,
          ]);
          payload["profilePicture"] = uploadedFilesRes[0].url;
        }
        // console.log(payload);
        this._updateUserProfileDetail(payload, "Details have been updated");
      } catch (error) {
        errorHandler(error);
        this.setState({ loading: false });
      }
    }
  };

  _updateUserProfileDetail = (payload, toastSuccessMsg = null) => {
    return new Promise((resolve, reject) => {
      this.setState({ loading: true });

      const { basicDetails } = deepClone(this.state);

      let userType = this.props.userData?.user?.type?.toLowerCase();

      updateUserProfileDetail({ userType, payload })
        .then(async (res) => {
          await this._getUserProfileDetail();

          basicDetails["password"].value = "";
          showToast(
            toastSuccessMsg ? toastSuccessMsg : "Details have been updated",
            "success"
          );
          this.props.hideLoader();
          this.setState({
            basicDetails,
            loading: false,
            showPassword: {
              password: false,
            },
            changePassword: false,
          });
          resolve(true);
        })
        .catch((error) => {
          errorHandler(error);
          this.props.hideLoader();
          this.setState({ loading: false });
          resolve(false);
        });
    });
  };

  render() {
    const { isShowPassword, basicDetails } = this.state;

    const { loading, percentage } = this.props;

    return (
      <>
        <div className="responsiveTitle">
          <h2>My Profile </h2>
        </div>

        <Card className="">
          <CardBody>
            <Row>
              <Col sm="12">
                <Label className="uploadProfile">
                  <Input
                    type="file"
                    style={{ display: "none" }}
                    disabled={loading}
                    accept="image/x-png,image/gif,image/jpeg"
                    value=""
                    onChange={(event) =>
                      this._updateFileImageBasicDetail("profilePicture", event)
                    }
                  />
                  <img
                    src={
                      basicDetails.profilePicture.file &&
                      basicDetails.profilePicture.file.previewBlob
                        ? basicDetails.profilePicture.file.previewBlob
                        : DEFAULT_PROFILE_PICTURE
                    }
                    alt="Profile Img"
                  />
                  <i className="fa fa-pencil"></i>
                </Label>
              </Col>

              <Col xl={4} md={6}>
                <FormGroup className="floatingLabel">
                  <Input
                    type="text"
                    value={basicDetails.name.value}
                    name="name"
                    onChange={(event) =>
                      this._onChangeBasicDetail("name", event.target.value)
                    }
                    placeholder=" "
                  />
                  <Label>Name</Label>
                  {basicDetails.name.error && (
                    <div className="validation-error">
                      {basicDetails.name.error}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col xl={4} md={6}>
                <FormGroup className="floatingLabel">
                  <Input
                    type="email"
                    value={basicDetails.email.value}
                    disabled={true}
                    name="email"
                    placeholder=" "
                    onChange={(event) =>
                      this._onChangeBasicDetail("email", event.target.value)
                    }
                  />
                  <Label>Email</Label>
                  {basicDetails.email.error && (
                    <div className="validation-error">
                      {basicDetails.email.error}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl={4} md={6}>
                <FormGroup className="floatingLabel withInputGroup">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>+1</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="phone"
                      value={basicDetails.phone.value}
                      name="phone"
                      onChange={(event) =>
                        this._onChangeBasicDetail("phone", event.target.value)
                      }
                      placeholder=" "
                    />
                    <Label>Phone</Label>
                  </InputGroup>
                  {basicDetails.phone.error && (
                    <div className="validation-error">
                      {basicDetails.phone.error}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col sm="12">
                <CustomInput
                  id="changePassword"
                  type="checkbox"
                  name="unassigned"
                  checked={this.state.changePassword}
                  onChange={(e) =>
                    this.setState({
                      changePassword: !this.state.changePassword,
                    })
                  }
                  label="Change Password"
                />

                {this.state.changePassword ? (
                  <FormGroup
                    className="floatingLabel mt-2"
                    style={{ maxWidth: 350 }}
                  >
                    <InputGroup>
                      <Input
                        type={isShowPassword ? "text" : "password"}
                        value={basicDetails.password.value}
                        name="password"
                        placeholder=" "
                        onChange={(event) =>
                          this._onChangeBasicDetail(
                            "password",
                            event.target.value
                          )
                        }
                      />
                      <Label>Password</Label>
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          <img
                            src={
                              require(`../../../assets/img/${
                                isShowPassword
                                  ? "eyeIcon.svg"
                                  : "eyeIconSlash.svg"
                              }`).default
                            }
                            onClick={this._showPasswordToggle}
                            alt="eye icon"
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    {basicDetails.password.error && (
                      <div className="validation-error">
                        {basicDetails.password.error}
                      </div>
                    )}
                  </FormGroup>
                ) : null}
              </Col>
            </Row>
            <div className="text-center mt-3">
              <Button
                color="primary"
                disabled={loading}
                onClick={this._onSubmitBasicDetail}
              >
                {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
                Save
              </Button>
            </div>
          </CardBody>

          {loading && Number(percentage) > 0 && (
            <Progress animated color="success" value={percentage} />
          )}
        </Card>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateAssistantObj: (user) => dispatch(updateAssistantObj(user)),
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
