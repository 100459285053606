import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  Spinner,
  FormGroup,
  Input,
  Label,
  NavLink,
  NavItem,
  Nav,
  TabPane,
  TabContent,
} from "reactstrap";
import { errorHandler, showToast } from "../../../helper-methods";
import {
  createCompanySetting,
  deleteCompanySettingStandardFeeRates,
  getAllConnectedListforCompany,
  updateCompanySettingStandardFeeRates,
} from "../../../http/http-calls";
import FeeRateModal from "./fee-rate-modal";
import usaStates from "../../../config/usa_states_titlecase";
// import CustomCard from "./CustomCard";
import FeeSettingTable from "./feeSettingTable";

const FeesSettings = (props) => {
  const [isOpenFeeRate, setIsOpenFeeRate] = useState(false);
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [feeRateData, setFeeRateData] = useState(null);
  const [clientList, setClientList] = useState(null);
  const [productTypes, setproductTypes] = useState({});

  const [filters, setFilters] = useState({});

  useEffect(() => {
    _getAllConnectedListforCompany();
  }, []);

  useEffect(() => {
    if (props.customProductTypes.products) {
      _setProductType(props.customProductTypes.products);
    }
    // _getAllConnectedListforCompany();
  }, [props.customProductTypes]);

  const _setProductType = (products) => {
    console.log("loantype", products);

    const groupedProducts = products.reduce((acc, product) => {
      const { productCategory } = product;

      if (!acc[productCategory]) {
        acc[productCategory] = [];
      }

      acc[productCategory].push(product.productType);

      return acc;
    }, {});
    setproductTypes(groupedProducts);
  };

  const _getAllConnectedListforCompany = async () => {
    try {
      let clientList = await getAllConnectedListforCompany();
      let list =
        clientList?.clients?.map((client, index) => ({
          label: client.companyName,
          value: client._id,
        })) || [];
      setClientList(list);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _standardFeeRatesPayload = (data) => {
    _createCompanySettings(data);
  };

  const _createCompanySettings = async (data) => {
    setLoading(true);
    try {
      await createCompanySetting(data);

      showToast("Settings Updated Successfully", "success");
      setLoading(false);
      setIsOpenFeeRate(false);
      props.getAllCompanySettings();
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _updateStandardFeeRate = async (data) => {
    setLoading(true);
    try {
      // await createCompanySetting(data);
      await updateCompanySettingStandardFeeRates(feeRateData._id, data);

      showToast("Settings Updated Successfully", "success");
      setLoading(false);
      setIsOpenFeeRate(false);
      setFeeRateData(null);
      props.getAllCompanySettings();
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _removeFeeSettings = async (item) => {
    try {
      setLoading(true);
      await deleteCompanySettingStandardFeeRates(item._id);
      showToast("Deleted Successfully", "success");
      props.getAllCompanySettings();
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  const _toggleFeeRateModal = async (isOpen = false, data = null) => {
    setIsOpenFeeRate(isOpen);
    setFeeRateData(data);
  };

  const _toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const _filterOnChange = (field, value) => {
    console.log("field", field, value);
    const newFilterData = { ...filters };
    newFilterData[field] = value;
    setFilters(newFilterData);

    //add api call sned data
    if (value === "") {
      delete newFilterData[field];
    }
    props.getAllCompanySettings(newFilterData);
  };

  // const _cardDataFormat = (row) => {
  //   // console.log("row: ", row);
  //   return (
  //     <ul className="cardInfo">
  //       <li>
  //         <div className="dataWrap">
  //           <Label>State</Label>
  //           <div
  //             style={{
  //               fontWeight: 600,
  //             }}
  //           >
  //             {row?.state ? row?.state : "N/A"}
  //           </div>
  //         </div>
  //         <div className="dataWrap">
  //           <Label>City</Label>
  //           <div
  //             style={{
  //               fontWeight: 600,
  //             }}
  //           >
  //             {row?.county ? row?.county : "N/A"}
  //           </div>
  //         </div>
  //         <div className="dataWrap">
  //           <Label>Signing Type </Label>
  //           <div
  //             style={{
  //               fontWeight: 600,
  //             }}
  //           >
  //             {row?.signingType ? row?.signingType : "N/A"}
  //           </div>
  //         </div>
  //       </li>

  //       <li>
  //         <div className="dataWrap">
  //           <Label>Product Type </Label>
  //           <div
  //             style={{
  //               fontWeight: 600,
  //             }}
  //           >
  //             {row?.productType ? row?.productType : "N/A"}
  //           </div>
  //         </div>
  //         <div className="dataWrap">
  //           <Label>Offer Rate: </Label>
  //           <div
  //             style={{
  //               fontWeight: 600,
  //             }}
  //           >
  //             {row?.offerRate ? `$${row?.offerRate}` : "N/A"}
  //           </div>
  //         </div>
  //       </li>

  //       <li>
  //         <div className="dataWrap text-center">
  //           {
  //             <>
  //               <Button
  //                 color="primary"
  //                 size="md"
  //                 onClick={() => _toggleFeeRateModal(true, row)}
  //                 disabled={loading ? true : false}
  //               >
  //                 Edit
  //               </Button>
  //               <Button
  //                 color="danger"
  //                 size="md"
  //                 className=""
  //                 onClick={() => _removeFeeSettings(row)}
  //                 disabled={loading ? true : false}
  //               >
  //                 Delete
  //               </Button>
  //             </>
  //           }
  //         </div>
  //       </li>
  //     </ul>
  //   );
  // };

  console.log("filters>>>", filters);
  console.log("props.loanType", props.loanType);
  console.log("props.companySettings", props.companySettings);
  return (
    <>
      <div className="">
        <div className="text-right mb-3">
          <Button
            color="primary"
            className="readMore h-auto"
            onClick={() => setIsOpenFeeRate(true)}
          >
            Add Rate
          </Button>
        </div>
        <Nav tabs>
          <NavItem>
            <NavLink
              className={activeTab === "1" ? "active" : ""}
              onClick={() => {
                _toggleTab("1");
              }}
            >
              Agent
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={activeTab === "2" ? "active" : ""}
              onClick={() => {
                _toggleTab("2");
              }}
            >
              Client
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab} className="mt-4">
          {/* basic detail page */}
          <TabPane tabId="1">
            <FeeSettingTable
              companySettings={props.companySettings}
              toggleFeeRateModal={_toggleFeeRateModal}
              removeFeeSettings={_removeFeeSettings}
              loading={loading}
              isForClient={false}
            />
          </TabPane>

          <TabPane tabId="2">
            <FeeSettingTable
              companySettings={props.companySettings}
              toggleFeeRateModal={_toggleFeeRateModal}
              removeFeeSettings={_removeFeeSettings}
              loading={loading}
              isForClient={true}
            />
          </TabPane>
        </TabContent>
        {/* {console.log("Sd", this.props.userData.user)} */}
      </div>
      <div className="filterContainer d-none">
        <div className="filterIcon">
          <img
            src={require("../../../assets/img/filter_icon.svg").default}
            alt="filter icon"
          />
          Filter by
        </div>
        <div className="filterWrapper">
          <FormGroup>
            <Label>State</Label>
            <div className="custom-select-wrapper">
              <Input
                type="select"
                value={filters.state}
                name="state"
                onChange={(e) => _filterOnChange("state", e.target.value)}
              >
                <option value="">Select</option>
                {usaStates.map((state) => (
                  <option key={state.abbreviation} value={state.abbreviation}>
                    {state.name}
                  </option>
                ))}
              </Input>
            </div>
          </FormGroup>
          <FormGroup>
            <Label>Signing Type</Label>
            <div className="custom-select-wrapper">
              <Input
                type="select"
                value={filters.signingType}
                name="signingType"
                onChange={(e) => _filterOnChange("signingType", e.target.value)}
              >
                <option value="">Select</option>
                <option value="Mobile">Mobile Signing</option>
                <option value="RON">RON</option>
              </Input>
            </div>
          </FormGroup>
          {/* <FormGroup>
            <Label>Product Type</Label>
            <div className="custom-select-wrapper">
              <Input
                type="select"
                value={filters.productType}
                name="ProductType"
                onChange={(e) => _filterOnChange("productType", e.target.value)}
              >
                {props.loanType &&
                  props.loanType.map((type, index) => (
                    <option key={index} value={type.value}>
                      {type.label}
                    </option>
                  ))}
              </Input>
            </div>
          </FormGroup> */}
        </div>
      </div>

      <FeeRateModal
        isOpen={isOpenFeeRate}
        data={feeRateData}
        toggle={() => _toggleFeeRateModal()}
        loanType={productTypes}
        handleSubmit={_standardFeeRatesPayload}
        updateStandardFeeRate={_updateStandardFeeRate}
        loading={loading}
        activeTab={activeTab}
        clientList={clientList}
      />
    </>
  );
};

export default FeesSettings;
