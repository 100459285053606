import React, { useCallback, useEffect } from "react";
import CustomPagination from "./CustomPagination";
import {
  Input,
  Button,
  InputGroup,
  FormGroup,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  InputGroupText,
  InputGroupAddon,
  Table,
  UncontrolledCollapse,
  CardHeader,
  Card,
  CardBody,
  Label,
  Row,
  Col,
  Badge,
  Spinner,
  CustomInput,
} from "reactstrap";

const CustomCard = ({
  className = "",
  pageNumber,
  pageSize = 10,
  tableData = [],
  headerKeys,
  dataFormat,
  totalCount,
  onPaginate,
  rowStyleFormat,
  rowClassName,
  showTableLoading = false,
  rowSelection = false,
  selectedRows = [],
  setSelectedRows,
  isPageStartFromOne = false,
  striped = false,
  isPagination = true,
  bodyKeys,
  cardHeaderFormat,
  cardDataFormat,
  columnSizeMD,
  isHideForLessData = true,
}) => {
  const isRowSelected = useCallback(
    (eachRow) => {
      if (!headerKeys?.length || !selectedRows?.length) return false;

      return selectedRows?.find(
        (eachSelect) =>
          eachSelect[headerKeys?.[0]?.id] === eachRow[headerKeys?.[0]?.id]
      )
        ? true
        : false;
    },
    [selectedRows, headerKeys]
  );

  // if (!headerKeys?.length) return <></>;

  const _setSelectedRows = (isChecked, row) => {
    try {
      if (!setSelectedRows) return;

      if (row === "all") {
        if (isChecked) {
          setSelectedRows(tableData);
        } else {
          setSelectedRows();
        }
      } else {
        if (isChecked) {
          const newSelectedRows = [...selectedRows];
          const findRow = newSelectedRows?.find(
            (each) => each[headerKeys?.[0]?.id] === row[headerKeys?.[0]?.id]
          );
          if (!findRow) {
            newSelectedRows.push(row);
            setSelectedRows(newSelectedRows);
          }
        } else {
          const newSelectedRows = [...selectedRows];
          const findRowIndex = newSelectedRows?.findIndex(
            (each) => each[headerKeys?.[0]?.id] === row[headerKeys?.[0]?.id]
          );
          if (findRowIndex >= 0) {
            newSelectedRows.splice(findRowIndex, 1);
            setSelectedRows(newSelectedRows);
          }
        }
      }
    } catch (error) {
      console.log({ error });
    }
  };
  return (
    <>
      <Row>
        {tableData?.length ? (
          tableData.map((eachRow, rowIndex) => (
            <Col md={columnSizeMD ? columnSizeMD : 6} className="mb-4 mb-lg-0">
              <Card
                key={`customCard_${rowIndex}`}
                className="responsiveTableCard"
              >
                {headerKeys?.length > 0 &&
                  headerKeys.map(
                    (eachHeading, headingIndex) =>
                      eachHeading.id !== "id" &&
                      eachHeading.id !== "_id" && (
                        <CardHeader>
                          {rowSelection && (
                            <div className="checkBoxColumn">
                              <CustomInput
                                id={`row_selection_${
                                  eachRow[headerKeys?.[0]?.id] || rowIndex
                                }`}
                                type="checkbox"
                                onChange={(e) =>
                                  _setSelectedRows(e.target.checked, eachRow)
                                }
                                checked={isRowSelected(eachRow)}
                              />
                            </div>
                          )}
                          {cardHeaderFormat
                            ? cardHeaderFormat(
                                eachRow[eachHeading.id],
                                eachRow,
                                eachHeading.id
                              )
                            : eachRow[eachHeading.id]}
                        </CardHeader>
                      )
                  )}
                {cardDataFormat ? cardDataFormat(eachRow) : "N/A"}
              </Card>
            </Col>
          ))
        ) : (
          <Col>
            <Card>
              <div className="nodata">
                <img
                  src={require("../../../assets/img/noData.svg").default}
                  alt="no data"
                />
                <p>There is no data to display</p>
              </div>
            </Card>
          </Col>
        )}
      </Row>
      {/* {isPagination && totalCount > pageSize ? ( */}
      {isPagination ? (
        <CustomPagination
          data={tableData}
          dataCount={totalCount}
          pageNumber={pageNumber}
          pageSize={pageSize}
          onPageChange={(pageNumber) => onPaginate(pageNumber, pageSize)}
          isPageStartFromOne={isPageStartFromOne}
          isHideForLessData={isHideForLessData}
        />
      ) : null}
    </>
  );
};

export default CustomCard;
