import React, { Component } from "react";

import {
  Button,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Input,
  Label,
  FormGroup,
  Spinner,
  Table,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Collapse,
} from "reactstrap";
import {
  capitalize,
  checkPermission,
  deepClone,
  errorHandler,
  formatAddressInSingleText,
  formatCurrencyValue,
  formatDateAsPerTimeZOne,
  formatDateHideYearIfSame,
  // formatDateOnlyAsPerTimeZone,
  formatPhoneNumber,
  formatTime,
  getPhoneNumberFromBrackets,
  // openAppleMapOnNewTab,
  // openGoogleMapOnNewTab,
  showToast,
  // timezoneList,
  getTimeZoneAbbr,
  getDropdownColor,
  agentTodayOrFutureDate,
  openAppleMapOnNewTab,
  openGoogleMapOnNewTab,
} from "../../../helper-methods";
import {
  activityLogClientClosing,
  getAllLenderClients,
  getAllSigningCompanyForClient,
  getClosingDetailsById,
  getClientSettings,
  updateClosingDetailsById,
  ClientGetSigningCompanyCustomOrderFormId,
} from "../../../http/http-calls";
import SecureDocumentCardComponent from "../components/SecureDocumentCardComponent";
import TextEditor from "../components/TextEdittor";
// import Chips from "react-chips";
import // agentClosingStatus,
// LanguageList,
// loanTypeConfig,
"../../../config";
import {
  googlePlaceDetails,
  googlePlaceSearch,
} from "../../../helper-methods/googleService";
import usaStates from "../../../config/usa_states_titlecase";
import { RegexConfig } from "../../../config/RegexConfig";
import SignerAvailabilityModal from "../components/signer-availability-modal";
import ChatLogCardComponent from "../components/ChatLogCardComponent";
// import Pagination from "react-js-pagination";
import CustomInput from "reactstrap/lib/CustomInput";
import makeAnimated from "react-select/animated";
// import CreatableSelect from "react-select";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomPagination from "../components/CustomPagination";
import EditOrderDetailsModal from "../components/Modals/EditOrderDetailsModal";
import StarRatings from "react-star-ratings";
import closingDetails from "./closing-details";
import { agentClosingStatus } from "../../../config";
import { DateTimeShowWithClosingDetails } from "../components/Date-time-show";
// eslint-disable-next-line no-unused-vars
const animatedComponents = makeAnimated();

class OrderDetails extends Component {
  state = {
    closingDetails: null,
    formFields: {
      borrower: [
        {
          _id: {
            value: "",
          },
          borrowerName: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          email: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          workPhone: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          isForeignNational: {
            value: false,
            isDirty: false,
            isValidate: true,
          },
          language: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          languageTypeOther: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          timeZone: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
        },
      ],
      // borrowerName: {
      //   value: "",
      //   isDirty: false,
      //   isValidate: true,
      // },
      additionalSigner: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      // email: {
      //   value: "",
      //   isDirty: false,
      //   isValidate: true,
      // },
      // phoneHomeDialCode: {
      //   value: "+1",
      //   error: null,
      //   isDirty: false,
      //   isValidate: false,
      // },
      // phoneWorkDialCode: {
      //   value: "+1",
      //   error: null,
      //   isDirty: false,
      //   isValidate: false,
      // },
      // phoneMobileDialCode: {
      //   value: "+1",
      //   error: null,
      //   isDirty: false,
      //   isValidate: false,
      // },
      // homePhone: {
      //   value: "",
      //   isDirty: false,
      //   isValidate: true,
      // },
      // workPhone: {
      //   value: "",
      //   isDirty: false,
      //   isValidate: true,
      // },
      // phone: {
      //   value: "",
      //   isDirty: false,
      //   isValidate: true,
      // },
      borrowerSearch: {
        value: "",
        placeId: null,
        isDirty: false,
        isValidate: true,
      },
      closingSearch: {
        value: "",
        placeId: null,
        isDirty: false,
        isValidate: true,
      },
      line1: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      line2: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      city: {
        value: "",
        isValidate: true,
        isDirty: false,
      },
      state: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      zip: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      loanOfficer: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      fileNumber: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      titleCompany: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      lender: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      personOfContactName: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      personOfContactEmail: {
        value: [],
        isDirty: false,
        isValidate: true,
      },
      signingType: {
        value: "Mobile",
        isDirty: false,
        isValidate: true,
      },
      witnessCount: {
        value: 0,
        isDirty: false,
        isValidate: true,
      },
      loanType: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      loanTypeOther: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      signingCompanyId: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      signingCompanyFee: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      lenderClientId: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      appointmentDate: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      closingAddressLine1: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      closingAddressLine2: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      closingAddressCity: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      closingAddressState: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      closingAddressZip: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      files: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      notes: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      instructionId: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      instructions: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      internalNotes: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      companyNotes: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      isScanBackNeeded: {
        value: false,
        isDirty: false,
        isValidate: false,
      },
    },
    editing: {
      appointment: false,
      orderDetail: false,
      instructions: false,
    },
    appointmentMapType: "google",
    lenderClients: [],
    signingCompanies: [],
    signingInstruction: [],
    searchResult: [],
    activityLog: [],
    activityLogPage: {
      skip: 0,
      limit: 5,
      totalCount: 0,
    },
    loading: {
      dataLoading: false,
      statusChangeLoading: false,
      fileUploadLoading: false,
      expensesLoading: false,
      deleteExpensesLoading: false,
      cardUpdateLoading: false,
    },
    show: false,
    signerAvailabilityModal: {
      isOpen: false,
      data: null,
    },
    editOrderDetailsModal: {
      isOpen: false,
      data: null,
    },
    isOpenCollapse: true,
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = this.state;
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _activityLogClientClosing = () => {
    const { activityLogPage } = this.state;

    let payload = `closingId=${this.props.match.params.id}&skip=${activityLogPage.skip}&limit=${activityLogPage.limit}`;

    activityLogClientClosing(payload)
      .then((res) => {
        this.setState({
          activityLog: res?.logs || [],
          activityLogPage: {
            ...this.state.activityLogPage,
            totalCount: res?.totalCount || 0,
          },
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _handleActivityLogPagination = (pageNo = 1) => {
    const { activityLogPage } = this.state;

    activityLogPage["skip"] = (pageNo - 1) * activityLogPage.limit;

    this.setState({ activityLogPage }, () => {
      this._activityLogClientClosing();
    });
  };

  _getAllLenderClients = () => {
    getAllLenderClients()
      .then((res) => {
        this.setState({
          lenderClients: res?.lenderClients?.length ? res?.lenderClients : [],
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _updateClosingState = (closingDetails) => {
    this.setState({ closingDetails });
  };

  _getClosingDetailsById = () => {
    const { formFields } = deepClone(this.state);
    this._manageLoading("dataLoading", true);

    getClosingDetailsById(this.props?.match?.params?.id)
      .then((res) => {
        this._handleActivityLogPagination();

        if (res?.closing && Object.keys(res?.closing).length) {
          formFields.isScanBackNeeded.value = res?.closing?.isScanBackNeeded
            ? res?.closing?.isScanBackNeeded
            : false;

          this.setState({ closingDetails: res?.closing, formFields });
          this._ClientGetSigningCompanyCustomOrderFormId(
            res?.closing?._signingCompany?._id
          );

          HeaderEventEmitter.dispatch("closing-details", {
            closingDetails: res?.closing,
          });
          this._manageLoading("dataLoading", false);
          document.querySelector("#scroll").scrollTo(0, 0);
        }
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("dataLoading", false);
        this.props.history.goBack();
      });
  };

  _getClientSettings = () => {
    getClientSettings()
      .then((res) => {
        this.setState({
          signingInstruction: res?.clientSetting?.signingInstruction?.length
            ? res.clientSetting.signingInstruction
            : [],
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getAllSigningCompanyForClient = () => {
    getAllSigningCompanyForClient()
      .then((res) => {
        this.setState({
          signingCompanies: res?.signingCompany?.length
            ? res.signingCompany
            : [],
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  componentDidMount = () => {
    this._getClosingDetailsById();

    this._getAllSigningCompanyForClient();
    this._getAllLenderClients();
    this._getClientSettings();
    HeaderEventEmitter.subscribe("back-button", () => {
      this.props.history.goBack();
    });
  };

  _orderDetail = () => {
    const { closingDetails, formFields } = this.state;
    formFields["fileNumber"].value = closingDetails.fileNumber
      ? closingDetails.fileNumber
      : "";
    formFields["lenderClientId"].value = closingDetails?._lenderClient
      ? closingDetails?._lenderClient
      : "";
    formFields["signingCompanyId"].value = closingDetails?._signingCompany?._id
      ? closingDetails?._signingCompany?._id
      : "";
    formFields["signingCompanyFee"].value = closingDetails.signingCompanyFee
      ? closingDetails.signingCompanyFee.toString()
      : "";

    formFields["personOfContactName"].value = closingDetails.personOfContact
      .name
      ? closingDetails.personOfContact.name
      : "";
    formFields["personOfContactEmail"].value = closingDetails.personOfContact
      .email
      ? closingDetails.personOfContact.email.filter((x) => x)
      : [];

    // formFields["loanType"].value = closingDetails.loanType
    //   ? closingDetails.loanType
    //   : "";
    formFields["loanTypeOther"].value = closingDetails.loanTypeOther
      ? closingDetails.loanTypeOther
      : "";
    formFields["loanType"].value = closingDetails?.loanType.map((each) => ({
      label: each,
      value: each,
    }));
    formFields["witnessCount"].value = Number(closingDetails.witnessCount);

    this.setState({ formFields });
  };

  _appointmentDetail = () => {
    const { closingDetails, formFields } = this.state;
    let borrower = [];

    closingDetails._borrower.forEach((each) => {
      borrower.push(
        // contactNumber: each.contactNumber || "",
        // pointOfContact: each.pointOfContact || "",
        // contactEmail: each.contactEmail || "",
        {
          _id: {
            value: each._id,
          },
          borrowerName: {
            value: each.name.full || "",
            isDirty: false,
            isValidate: true,
          },
          email: {
            value: each.email || "",
            isDirty: false,
            isValidate: true,
          },
          workPhone: {
            value: getPhoneNumberFromBrackets(each?.phone?.work) || "",
            isDirty: false,
            isValidate: true,
          },
          isForeignNational: {
            value: each.isForeignNational,
            isDirty: false,
            isValidate: true,
          },
          language: {
            value: each.language,
            isDirty: false,
            isValidate: true,
          },
          languageTypeOther: {
            value: each.languageTypeOther || "",
            isDirty: false,
            isValidate: true,
          },
          timeZone: {
            value: each.timeZone || "",
            isDirty: false,
            isValidate: true,
          },
        }
      );
    });
    formFields["borrower"] = borrower;

    if (closingDetails?.closingAddress) {
      formFields["closingSearch"].value = formatAddressInSingleText(
        closingDetails?.closingAddress
      );
      formFields["closingSearch"].placeId = "initialValue";
    }
    if (closingDetails?._borrower[0]?.address) {
      formFields["borrowerSearch"].value = formatAddressInSingleText(
        closingDetails._borrower[0].address
      );
      formFields["borrowerSearch"].placeId = "initialValue";
    }
    // formFields["borrowerName"].value = closingDetails?._borrower.name.full;
    formFields["additionalSigner"].value = closingDetails?.additionalSigner;
    // formFields["email"].value = closingDetails?._borrower.email;
    // formFields["homePhone"].value = getPhoneNumberFromBrackets(
    //   closingDetails?._borrower.phone.home
    // );
    // formFields["workPhone"].value = getPhoneNumberFromBrackets(
    //   closingDetails?._borrower.phone.work
    // );
    // formFields["phone"].value = getPhoneNumberFromBrackets(
    //   closingDetails?._borrower.phone.mobile
    // );

    this.setState({ formFields });
  };

  _instructionsDetail = () => {
    const { closingDetails, formFields } = this.state;
    formFields["instructionId"].value = closingDetails?.instructionId
      ? closingDetails?.instructionId
      : "";
    formFields["instructions"].value = closingDetails?.instructions
      ? closingDetails?.instructions
      : "";
    this.setState({ formFields });
  };

  _editModeOn = (fieldName, value) => {
    const { editing, formFields } = this.state;

    Object.keys(editing).forEach((key) => {
      editing[key] = false;
    });

    editing[fieldName] = value;

    this.setState({
      editing,
    });

    if (fieldName === "appointment") {
      this._appointmentDetail();
    }
    if (fieldName === "orderDetail") {
      this._orderDetail();
    }
    if (fieldName === "instructions") {
      this._instructionsDetail();
    }

    if (value === false) {
      Object.keys(formFields).forEach((key) => {
        delete formFields[key].error;
      });
      this.setState({ formFields });
    }
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields, editing } = this.state;
      // editing: {
      //   appointment: false,
      //   orderDetail: false,
      //   instructions: false,
      // },

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty && formFields[key].isValidate) {
          if (editing.appointment) {
            switch (key) {
              case "borrowerName": {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "email": {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  if (
                    RegexConfig.email.test(
                      String(formFields[key].value).toLowerCase()
                    )
                  ) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Invalid Email";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
                break;
              }
              case "homePhone":
              case "workPhone":
              case "phone": {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  if (
                    RegexConfig.phone.test(
                      String(formFields[key].value).toLowerCase()
                    )
                  ) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    isFormValid = false;
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Invalid Phone Number";
                  }
                } else {
                  if (
                    (formFields["homePhone"].value &&
                      formFields["homePhone"].value.length) ||
                    (formFields["workPhone"].value &&
                      formFields["workPhone"].value.length) ||
                    (formFields["phone"].value &&
                      formFields["phone"].value.length)
                  ) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    isFormValid = false;
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Required";
                  }
                }
                break;
              }
              case "closingSearch": {
                if (
                  this.props.location.search.length === 0 &&
                  this.state.isAppointmentSearch
                ) {
                  if (
                    formFields[key].value &&
                    formFields[key].value.trim().length
                  ) {
                    if (formFields[key].placeId) {
                      formFields[key].isDirty = false;
                      formFields[key].error = null;
                    } else {
                      formFields[key].isDirty = true;
                      formFields[key].error = "*Invalid Address";
                      isFormValid = false;
                    }
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Required";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
                break;
              }
              default:
            }
          } else if (editing.orderDetail) {
            switch (key) {
              case "fileNumber": {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "personOfContactEmail": {
                if (formFields[key].value && formFields[key].value.length) {
                  formFields.personOfContactEmail.value.forEach(
                    (each, index) => {
                      if (RegexConfig.email.test(String(each).toLowerCase())) {
                        formFields[key].isDirty = false;
                        formFields[key].error = null;
                      } else {
                        formFields[key].isDirty = true;
                        formFields[key].error = "*Invalid Email";
                        isFormValid = false;
                      }
                    }
                  );
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
                break;
              }
              case "loanType": {
                if (formFields[key].value && formFields[key].value.length) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "loanTypeOther": {
                if (formFields.loanType.value === "Other") {
                  if (
                    formFields[key].value &&
                    formFields[key].value.trim().length
                  ) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Required";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
                break;
              }
              // case "signingCompanyFee": {
              //   if (formFields[key].value && formFields[key].value.length) {
              //     if (
              //       isNaN(formFields[key].value) ||
              //       Number(formFields[key].value) < 0
              //     ) {
              //       formFields[key].isDirty = true;
              //       formFields[key].error = "*Fee must be a positive number";
              //       isFormValid = false;
              //     } else {
              //       formFields[key].isDirty = false;
              //       formFields[key].error = null;
              //     }
              //   } else {
              //     formFields[key].isDirty = true;
              //     formFields[key].error = "*Required";
              //     isFormValid = false;
              //   }
              //   break;
              // }
              default:
            }
          } else {
            formFields[key].isDirty = false;
            formFields[key].error = null;
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields, signingInstruction } = this.state;

    if (fieldName === "signingCompanyFee" || fieldName === "agentFee") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }
    if (fieldName === "closingSearch" || fieldName === "borrowerSearch") {
      googlePlaceSearch(value)
        .then((res) => {
          this.setState({ searchResult: res, show: fieldName });
        })
        .catch((error) => {
          errorHandler(error);
        });
    }

    if (fieldName === "instructionId" && value) {
      const findInstruction = signingInstruction.find(
        (each) => each._id === value
      );
      formFields.instructions.value = findInstruction?.description || "";
    }

    if (fieldName === "appointmentDate")
      formFields[fieldName].value = value ? new Date(value).toISOString() : "";
    else formFields[fieldName].value = value;

    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // validation
      this._validateForm();
    });
  };

  _getPlaceDetail = async (pid, type) => {
    const { formFields } = this.state;

    let detail = await googlePlaceDetails(pid.place_id);

    if (detail) {
      if (type === "borrowerAddress") {
        formFields["line1"].value = detail.address;
        formFields["city"].value = detail.city;
        formFields["state"].value = detail.state;
        formFields["zip"].value = detail.postal;
        formFields["borrowerSearch"].value =
          detail.address +
          "," +
          detail.city +
          "," +
          detail.postal +
          "," +
          detail.country;
        formFields["borrowerSearch"].placeId = pid.place_id;
        formFields["borrowerSearch"].isDirty = true;
      } else {
        formFields["closingAddressLine1"].value = detail.address;
        formFields["closingAddressCity"].value = detail.city;
        formFields["closingAddressState"].value = detail.state;
        formFields["closingAddressZip"].value = detail.postal;
        formFields["closingSearch"].value =
          detail.address +
          "," +
          detail.city +
          "," +
          detail.postal +
          "," +
          detail.country;
        formFields["closingSearch"].placeId = pid.place_id;
        formFields["closingSearch"].isDirty = true;
      }

      this.setState({ formFields, show: true });
    }
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _updateClosing = async (type) => {
    try {
      await this._markAllFieldDirty();

      const isFormValid = await this._validateForm();

      if (isFormValid) {
        this._manageLoading("cardUpdateLoading", true);

        const { formFields, closingDetails, editing } = this.state;

        // const splitBorrowerName = formFields.borrowerName.value
        //   .trim()
        //   .split(" ");

        let payload = {};

        if (type === "appointment") {
          payload = {
            // borrower: {
            //   name: {
            //     first: splitBorrowerName.slice(0, 1).join(" "),
            //     last: splitBorrowerName[1]
            //       ? splitBorrowerName.slice(1).join(" ")
            //       : "",
            //   },
            //   email: formFields.email.value,
            //   phone: {
            //     home:
            //       formFields.homePhone.value &&
            //       formFields.homePhone.value.trim().length
            //         ? `(${formFields.phoneHomeDialCode.value})${formFields.homePhone.value}`
            //         : undefined,
            //     work:
            //       formFields.workPhone.value &&
            //       formFields.workPhone.value.trim().length
            //         ? `(${formFields.phoneWorkDialCode.value})${formFields.workPhone.value}`
            //         : undefined,
            //     mobile:
            //       formFields.phone.value && formFields.phone.value.trim().length
            //         ? `(${formFields.phoneMobileDialCode.value})${formFields.phone.value}`
            //         : undefined,
            //   },
            //   address: {},
            // },
            borrower: [],
            additionalSigner:
              formFields.additionalSigner?.value?.length > 0
                ? formFields.additionalSigner.value
                : undefined,
            closingAddress: {},
          };
          let borrower = await formFields.borrower.map(
            ({
              _id,
              borrowerName,
              email,
              workPhone,
              isForeignNational,
              language,
              languageTypeOther,
              timeZone,
            }) => {
              const splitBorrowerName = borrowerName.value.trim().split(" ");

              return {
                name: {
                  first: splitBorrowerName.slice(0, 1).join(" "),
                  last: splitBorrowerName[1]
                    ? splitBorrowerName.slice(1).join(" ")
                    : "",
                },
                _id: _id.value,
                email: email.value ? email.value : undefined,
                phone: { work: workPhone.value ? workPhone.value : undefined },
                isForeignNational: isForeignNational.value,

                language: language.value ? language.value : undefined,
                languageTypeOther: languageTypeOther.value,

                timeZone: timeZone.value ? timeZone.value : undefined,
                address: {},
              };
            }
          );
          payload["borrower"] = borrower;

          if (
            formFields.closingSearch.placeId &&
            formFields.closingSearch.placeId !== "initialValue"
          ) {
            const addressObj = await googlePlaceDetails(
              formFields.closingSearch.placeId
            );

            let abbr = usaStates.find(
              (item) => item.name === addressObj.state
            ).abbreviation;

            payload.closingAddress["line1"] = addressObj.address;
            payload.closingAddress["city"] = addressObj.city;
            payload.closingAddress["county"] = addressObj.county;
            payload.closingAddress["state"] =
              addressObj.state.length > 2 ? abbr : addressObj.state;
            payload.closingAddress["zip"] = addressObj.postal;
            payload.closingAddress["lat"] = addressObj.lat;
            payload.closingAddress["lng"] = addressObj.lng;
          } else {
            delete payload.closingAddress;
          }

          if (
            formFields.borrowerSearch.placeId &&
            formFields.borrowerSearch.placeId !== "initialValue"
          ) {
            const addressObj = await googlePlaceDetails(
              formFields.borrowerSearch.placeId
            );

            let abbr = usaStates.find(
              (item) => item.name === addressObj.state
            ).abbreviation;

            // payload.borrower.address["line1"] = addressObj.address;
            // payload.borrower.address["city"] = addressObj.city;
            // payload.borrower.address["county"] = addressObj.county;
            // payload.borrower.address["state"] =
            //   addressObj.state.length > 2 ? abbr : addressObj.state;
            // payload.borrower.address["zip"] = addressObj.postal;
            // payload.borrower.address["lat"] = addressObj.lat;
            // payload.borrower.address["lng"] = addressObj.lng;
            borrower.map(
              (item) => (
                // eslint-disable-next-line no-sequences
                (item.address["line1"] = addressObj.address),
                (item.address["city"] = addressObj.city),
                (item.address["county"] = addressObj.county),
                (item.address["state"] =
                  addressObj.state.length > 2 ? abbr : addressObj.state),
                (item.address["zip"] = addressObj.postal),
                (item.address["lat"] = addressObj.lat),
                (item.address["lng"] = addressObj.lng)
              )
            );
          }
        }

        if (type === "orderDetail") {
          payload = {
            fileNumber: formFields.fileNumber.value || undefined,
            signingCompanyFee: formFields.signingCompanyFee.value || undefined,
            signingCompanyId: formFields.signingCompanyId?.value?.trim().length
              ? formFields.signingCompanyId.value !==
                closingDetails?._signingCompany?.id
                ? formFields.signingCompanyId.value
                : undefined
              : undefined,
            lenderClientId: formFields.lenderClientId.value || undefined,
            personOfContact: {
              name: formFields.personOfContactName.value || undefined,
              email:
                formFields.personOfContactEmail.value.length > 0
                  ? formFields.personOfContactEmail.value.toString()
                  : [],
            },
            // loanType: formFields.loanType.value || undefined,
            loanTypeOther: formFields.loanTypeOther.value || undefined,
          };
          if (formFields.loanType.value) {
            payload["loanType"] = await formFields.loanType.value.map(
              (item) => item.value
            );
          }
          if (
            formFields.witnessCount.value &&
            formFields.witnessCount.value.trim().length
          ) {
            payload["witnessCount"] = Number(
              formFields.witnessCount.value.trim()
            );
          }
        }

        if (type === "instructions") {
          payload = {
            instructionId: formFields.instructionId.value || undefined,
            instructions: formFields.instructions.value || undefined,
          };
        }

        try {
          // await updateClosingDetailsById({ id: closingDetails?._id, payload });
          await this._updateClosingDetailsById(closingDetails.id, payload);

          showToast("Order has been updated", "success");

          Object.keys(editing).forEach((key) => {
            editing[key] = false;
          });
          this.setState({ editing });
          this._getClosingDetailsById();
          this._manageLoading("cardUpdateLoading", false);
        } catch (error) {
          errorHandler(error);
          this._manageLoading("cardUpdateLoading", false);
        }
      }
    } catch (error) {
      errorHandler(error);
      this._manageLoading("cardUpdateLoading", false);
    }
  };

  _activityLog = () => {
    const { activityLog, closingDetails } = this.state;

    return activityLog?.length ? (
      activityLog.map((item, key) => {
        return (
          <li key={key}>
            {item.activityType !== "ClosingAssigned"
              ? item.isDocumentCompleted
                ? null
                : item?._user?.name?.full
              : "New Order"}{" "}
            {item.activityType === "Documents"
              ? item.isDocumentCompleted
                ? "Documents Upload by Signing Company"
                : item.isDocumentCompletedByAgent
                ? "Documents Upload by Agent"
                : item.isDocumentVerified
                ? "has Verified"
                : "Uploaded"
              : item.activityType === "AppointmentStatus" ||
                item.activityType === "AppointmentDate" ||
                item.activityType === "ClosingStatus" ||
                item.activityType === "LeftMessage"
              ? "Changed"
              : item.activityType === "ClosingCreated"
              ? "Created the Closing"
              : item.activityType === "ClosingAssigned"
              ? "Assigned Closing"
              : "Updated"}{" "}
            {item.activityType === "Notes"
              ? item.notesType.replace(/([A-Z])/g, " $1").trim()
              : item.activityType === "Documents"
              ? item.isDocumentCompleted
                ? "Complete"
                : item.isDocumentCompletedByAgent
                ? "as Scanback Upload Complete"
                : item.isDocumentVerified
                ? "Agent Document"
                : "a New File"
              : item.activityType === "ClosingCreated"
              ? ""
              : item.activityType === "LeftMessage"
              ? "Appointment Notes"
              : item.activityType === "ClosingAssigned"
              ? null
              : item.activityType.replace(/([A-Z])/g, " $1").trim()}{" "}
            {item.activityType === "Documents"
              ? item.documents.map((each, index) => (
                  <Button
                    key={`Documents_${index}`}
                    color="link"
                    className="showMap"
                    // onClick={() => openUrlOnNewTab(each.url)}
                  >
                    {each.title}
                  </Button>
                ))
              : null}{" "}
            {item.to
              ? `To ${
                  item.activityType === "AppointmentDate"
                    ? formatDateAsPerTimeZOne(
                        item.to,
                        closingDetails.closingAddress.timeZone
                      )
                    : // ? `${formatDateHideYearIfSame(item.to)}, ${formatTime(
                      //     item.to
                      //   )}`
                      item.to
                }`
              : null}{" "}
            {item.activityType === "ClosingAssigned" ? (
              <>To {item._agent.name.full}</>
            ) : null}
            {/* {item.text ? (
                item.activityType === "Notes" ||
                item.activityType === "LeftMessage" ? (
                  <>
                    {" "}
                    To{" "}
                    <span
                      style={{
                        fontStyle: "italic",
                        marginLeft: 3,
                        marginRight: 5,
                      }}
                    >
                      "{item.text}"{" "}
                    </span>{" "}
                  </>
                ) : (
                  `To ${item.text}`
                )
              ) : null}{" "} */}
            {item.activityType === "LeftMessage" ? <>To Left Message </> : null}
            {item.text ? (
              item.activityType === "Notes" ? (
                <>
                  {" "}
                  To{" "}
                  <span
                    style={{
                      fontStyle: "italic",
                      marginLeft: 3,
                      marginRight: 5,
                    }}
                  >
                    "{item.text}"{" "}
                  </span>{" "}
                </>
              ) : null
            ) : null}{" "}
            | {formatDateHideYearIfSame(item.timeStamp)},{" "}
            {formatTime(item.timeStamp)}
          </li>
        );
      })
    ) : (
      <div className="nodata">
        <img
          src={require("../../../assets/img/noData.svg").default}
          alt="no data"
        />
        <p>There is no data to display</p>
      </div>
    );
  };

  _onToggleSignerAvailabilityModal = (isOpen = false, data = null) => {
    if (
      isOpen &&
      (data?._agent ||
        (data?.status !== "Pending" && data?.status !== "Cancelled"))
    ) {
      showToast("You cannot change the date & time.", "error");
      return;
    }

    this.setState({
      signerAvailabilityModal: {
        isOpen,
        data,
      },
    });
  };

  _onChangeOrderStatus = async (data, status) => {
    if (status !== "Pending" && status !== "Cancelled") {
      showToast("Invalid status", "error");
      return;
    }

    try {
      this._manageLoading("statusChangeLoading", true);

      // const updateAgentRes = await updateClosingDetailsById({
      //   id: data?._id,
      //   payload: { status },
      // });

      const updateAgentRes = await this._updateClosingDetailsById(data.id, {
        status: status,
      });

      showToast("Status has been updated", "success");

      const { closingDetails } = this.state;

      closingDetails["status"] = updateAgentRes?.closing?.status || "";

      this.setState({ closingDetails });

      this._manageLoading("statusChangeLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("statusChangeLoading", false);
    }
  };

  _updateClosingDetailsByIdStatus = async (data, status) => {
    try {
      this._manageLoading("statusChangeLoading", true);

      const updateAgentRes = await this._updateClosingDetailsById(
        data.id,
        status
      );
      showToast("Status has been updated", "success");

      const { formFields } = deepClone(this.state);

      formFields["isScanBackNeeded"].value =
        updateAgentRes.closing.isScanBackNeeded;

      this.setState({ formFields });

      this._handleActivityLogPagination();
      this._getClosingDetailsById();
      this._manageLoading("statusChangeLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("statusChangeLoading", false);
    }
    // }
  };
  _updateClosingDetailsById = (id, payload) => {
    return new Promise((resolve, reject) => {
      updateClosingDetailsById({ id, payload })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  _onChangeFormFieldBorrower = (fieldName, value, subField, index) => {
    const { formFields } = deepClone(this.state);
    if (fieldName === "borrower") {
      // formFields[fieldName][index][subField].value = value;
      if (subField === "workPhone" || subField === "alternatePhone") {
        formFields[fieldName][index][subField].value = value.replace(/\D/g, "");
      } else {
        formFields[fieldName][index][subField].value = value;
      }
    }
    this.setState({ formFields });
  };
  _toggleEditOrderDetailsModal = (isOpen = false, data = null) => {
    this.setState({
      editOrderDetailsModal: {
        isOpen,
        data,
      },
    });
  };

  _onChipSelect = (chips) => {
    let isValid = chips.length
      ? this._isValidEmail(chips[chips.length - 1])
      : true;
    if (isValid) {
      this.setState({
        formFields: {
          ...this.state.formFields,
          personOfContactEmail: {
            ...this.state.formFields.personOfContactEmail,
            value: chips,
          },
        },
      });
    }
  };

  _isValidEmail = (value) => {
    if (RegexConfig.email.test(String(value).toLowerCase())) {
      return true;
    } else {
      showToast("Invalid Email", "error");
      return false;
    }
  };

  _ClientGetSigningCompanyCustomOrderFormId = async (id) => {
    // console.log("iddddd", id);
    if (id.trim().length > 0) {
      try {
        let response = await ClientGetSigningCompanyCustomOrderFormId(id);
        // console.log("rt", response);
        // let questionsArray =
        //   response?.questions?.length > 0 &&
        //   response?.questions?.map((each) => ({
        //     ...each,
        //     reply: false,
        //   }));

        if (response?.productTypes) {
          this._setProductType(response?.productTypes);
        }
        // this.setState({
        //   questions: questionsArray,
        // });

        // this.setState({
        //     questions: response.cutomOrderForm.questions,
        //   });
      } catch (error) {
        errorHandler(error);
      }
    }
  };

  _setProductType = (products) => {
    // console.log("loantype",products)

    const groupedProducts = products.reduce((acc, product) => {
      const { productCategory } = product;

      if (!acc[productCategory]) {
        acc[productCategory] = [];
      }

      acc[productCategory].push(product.productType);

      return acc;
    }, {});
    this.setState({ loanType: groupedProducts });
  };

  render() {
    const {
      formFields,
      closingDetails,
      loading,
      editing,
      // eslint-disable-next-line no-unused-vars
      appointmentMapType,
      lenderClients,
      signingCompanies,
      activityLog,
      activityLogPage,
      signingInstruction,
      signerAvailabilityModal,
      editOrderDetailsModal,
    } = this.state;

    console.log("closingDetails >>", closingDetails);

    return (
      <div id="scroll">
        <div className="content">
          <div className="responsiveTitle">
            <div className="d-flex align-items-center">
              <Button
                color="link"
                className="backBtn"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../../../assets/img/arrowLeft.svg").default}
                  alt="backbutton"
                  height={14}
                />
              </Button>
              <h2>
                Order Details
                {loading.dataLoading ? <Spinner /> : null}
                {closingDetails?._createdBy?.name?.full ||
                closingDetails?._createdByClient?.name?.full ? (
                  <span>
                    Created by{" "}
                    {closingDetails?._createdBy?.name?.full ||
                      closingDetails?._createdByClient?.name?.full}
                  </span>
                ) : null}
              </h2>
            </div>

            <div className="rightSide">
              <Button
                className="floatingButton"
                color="primary"
                onClick={() =>
                  this.props.history.push(
                    `/client/create-order?${closingDetails?.id}`
                  )
                }
              >
                Clone
              </Button>

              {/* <Button color="link" onClick={this._resetFilter}>
                <img
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="Filter"
                  height={14}
                />
              </Button> */}
            </div>
          </div>

          {closingDetails ? (
            <>
              <div className="CardWrapper">
                <div className="CardTitle">
                  <h2 className="sectionTtile">
                    {" "}
                    Appointment{" "}
                    {closingDetails?.status !== "Closed" &&
                    closingDetails?.status !== "DNC" &&
                    // only open if closing is create by Client
                    closingDetails &&
                    closingDetails?._createdByClient !== undefined ? (
                      <Button
                        color="link"
                        onClick={() =>
                          this._toggleEditOrderDetailsModal(
                            true,
                            closingDetails
                          )
                        }
                        // onClick={() => this._editModeOn("appointment", true)}
                        disabled={
                          !checkPermission("dashboard", "canEditOrderEntry")
                        }
                      >
                        <img
                          src={
                            require("../../../assets/img/pencil.svg").default
                          }
                          alt="edit "
                        />
                      </Button>
                    ) : null}
                  </h2>
                </div>
              </div>

              <div className="CustomTableWrapper withAccordian">
                <Table striped responsive className="hideMobile">
                  <thead>
                    <tr>
                      <th>Signer</th>
                      <th>File#</th>
                      <th>Product Type </th>
                      <th>Signing Type </th>
                      <th>Fee</th>
                      <th>Location</th>
                      {/* <th>Order on behalf of</th> */}
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <>
                          <div className="tableUserInfo">
                            {/* <div className="userImage">
                              <img
                                className="clientImg"
                                src={
                                  closingDetails?._lenderClient?.picUrl
                                    ? closingDetails?._lenderClient?.picUrl
                                    : require("../../../assets/img/default_user_icon.svg")
                                        .default
                                }
                                loading="lazy"
                                alt="avatar"
                              /> 
                            </div>*/}
                            <div className="userContent">
                              <span
                                id={`borrower_table_agent_closing_details_${closingDetails?._id}`}
                                className="signerName"
                              >
                                {closingDetails?._borrower[0]?.name?.full
                                  ? closingDetails?._borrower[0]?.name?.full
                                  : "N/A"}
                                {closingDetails?._borrower?.length > 1
                                  ? `+${closingDetails?._borrower?.length - 1}`
                                  : null}
                              </span>
                              {closingDetails?._borrower?.length ? (
                                <>
                                  <UncontrolledPopover
                                    trigger="legacy"
                                    placement="bottom"
                                    target={`borrower_table_agent_closing_details_${closingDetails?._id}`}
                                    className="signerPopover"
                                  >
                                    <PopoverHeader>Signers</PopoverHeader>
                                    <PopoverBody>
                                      <Table>
                                        <thead>
                                          <tr>
                                            <th>Name</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Language</th>
                                            <th>Time Zone</th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {closingDetails?._borrower?.map(
                                            (each) => (
                                              <tr key={each._id}>
                                                <td>
                                                  {each.name?.full || "N/A"}
                                                </td>
                                                <td>{each.email || "N/A"}</td>
                                                <td>
                                                  {each.phone?.work || "N/A"}
                                                </td>
                                                <td>
                                                  {each.language || "N/A"}
                                                </td>
                                                <td>
                                                  {each.timeZone || "N/A"}
                                                </td>
                                              </tr>
                                            )
                                          )}
                                        </tbody>
                                      </Table>
                                    </PopoverBody>
                                  </UncontrolledPopover>
                                </>
                              ) : null}
                              <DateTimeShowWithClosingDetails
                                closingDetails={closingDetails}
                              />
                              {/* <span className="dateTimeFormat">
                                {formatDateAsPerTimeZOne(
                                  closingDetails?.appointmentDate,
                                  closingDetails?.closingAddress?.timeZone
                                )}
                                ,
                                <span>
                                  {getTimeZoneAbbr(
                                    closingDetails?.appointmentDate,
                                    closingDetails?.closingAddress?.timeZone
                                  )}
                                </span>
                              </span>{" "} */}
                            </div>
                          </div>
                        </>
                      </td>
                      <td>
                        <span
                          style={{
                            width: 150,
                            display: "block",
                            fontWeight: 600,
                          }}
                        >
                          {closingDetails?.fileNumber || "N/A"}
                        </span>
                      </td>
                      <td>
                        {closingDetails?.loanType?.length ? (
                          closingDetails?.loanType?.indexOf("Other") > -1 ? (
                            <span
                              style={{
                                marginRight: 6,
                                display: "inline-block",
                                fontWeight: 600,
                              }}
                            >
                              {closingDetails?.loanTypeOther}
                            </span>
                          ) : null
                        ) : null}

                        {React.Children.toArray(
                          closingDetails?.loanType
                            ?.filter((each) => each !== "Other")
                            .map((item) => (
                              <span
                                key={item._id}
                                style={{
                                  marginRight: 6,
                                  display: "inline-block",
                                  fontWeight: 600,
                                }}
                              >
                                {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                              </span>
                            ))
                        )}
                      </td>
                      <td>
                        <span
                          style={{
                            marginRight: 6,
                            display: "inline-block",
                            fontWeight: 600,
                          }}
                        >
                          {closingDetails?.signingType}
                        </span>
                      </td>
                      <td>
                        <span style={{ fontWeight: 600 }}>
                          {closingDetails?.signingCompanyFee
                            ? formatCurrencyValue(
                                closingDetails?.signingCompanyFee
                              )
                            : formatCurrencyValue(0)}
                        </span>
                      </td>
                      <td>
                        <span
                          style={{
                            fontWeight: 600,
                            width: 200,
                            display: "block",
                          }}
                        >
                          {formatAddressInSingleText(
                            closingDetails?.closingAddress
                          ) || "N/A"}
                        </span>
                        <Button
                          color="link"
                          className="p-0"
                          title={formatAddressInSingleText(
                            closingDetails?.closingAddress
                          )}
                          onClick={() =>
                            openGoogleMapOnNewTab(
                              closingDetails?.closingAddress
                            )
                          }
                        >
                          <img
                            src={
                              require("../../../assets/img/map-marker.svg")
                                .default
                            }
                            alt="map icon"
                            className="mr-1"
                          />
                          Map
                        </Button>
                      </td>
                      {/* <td>
                        
                        <span style={{ fontWeight: 600 }}>
                          {closingDetails?.lender
                            ? capitalize(closingDetails?.lender)
                            : "N/A"}
                        </span>{" "}
                      </td> */}
                      <td>
                        <div
                          className="customSelectWrap"
                          style={{ width: 100 }}
                        >
                          <Input
                            type="select"
                            name="status"
                            className={`status ${getDropdownColor(
                              closingDetails.status
                            )}`}
                            disabled={
                              loading.statusChangeLoading ? true : false
                            }
                            value={closingDetails.status}
                            onChange={(event) =>
                              this._updateClosingDetailsByIdStatus(
                                closingDetails,
                                {
                                  status: event.target.value,
                                }
                              )
                            }
                          >
                            {agentClosingStatus?.map((obj) => (
                              <option
                                key={obj.value}
                                value={obj.value}
                                disabled={
                                  obj.value === "CCA" ||
                                  obj.value === "Arrived" ||
                                  obj.value === "Closed" ||
                                  obj.value === "DNC" ||
                                  closingDetails?._agent
                                }
                              >
                                {obj.label}
                              </option>
                            ))}
                          </Input>
                          <div className="downArrow">
                            <i className="fa fa-chevron-down"></i>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>

                <Card className="responsiveTableCard hideDesktop">
                  <CardHeader>
                    <div className="tableUserInfo">
                      {/* <div className="userImage">
                        <img
                          className="clientImg"
                          src={
                            closingDetails?._lenderClient?.picUrl
                              ? closingDetails?._lenderClient?.picUrl
                              : require("../../../assets/img/default_user_icon.svg")
                                  .default
                          }
                          loading="lazy"
                          alt="avatar"
                        />
                      </div> */}
                      <div className="userContent" style={{ flex: 1 }}>
                        <span
                          id={`borrower_table_agent_closing_details_appointment${closingDetails?._id}`}
                          className="signerName"
                        >
                          {closingDetails?._borrower[0]?.name?.full
                            ? closingDetails?._borrower[0]?.name?.full
                            : "N/A"}
                          {closingDetails?._borrower?.length > 1
                            ? `+${closingDetails?._borrower?.length - 1}`
                            : null}
                        </span>
                        {closingDetails?._borrower?.length ? (
                          <>
                            <UncontrolledPopover
                              trigger="legacy"
                              placement="bottom"
                              target={`borrower_table_agent_closing_details_appointment${closingDetails?._id}`}
                              className="signerPopover"
                            >
                              <PopoverHeader>Signers</PopoverHeader>
                              <PopoverBody>
                                {closingDetails?._borrower?.map((each) => (
                                  <ul key={each._id} className="dataList">
                                    <li>
                                      <Label>Name</Label>
                                      <div>{each.name?.full || "N/A"}</div>
                                    </li>
                                    <li>
                                      <Label>Email</Label>
                                      <div>{each.email || "N/A"}</div>
                                    </li>
                                    <li>
                                      <Label>Phone</Label>
                                      <div>{each.phone?.work || "N/A"}</div>
                                    </li>
                                    <li>
                                      <Label>Language</Label>
                                      <div>{each.language || "N/A"}</div>
                                    </li>
                                    <li>
                                      <Label>Time Zone</Label>
                                      <div>{each.timeZone || "N/A"}</div>
                                    </li>
                                  </ul>
                                ))}
                              </PopoverBody>
                            </UncontrolledPopover>
                          </>
                        ) : null}
                        {/* <span className="dateTimeFormat">
                          {formatDateAsPerTimeZOne(
                            closingDetails?.appointmentDate,
                            closingDetails?.closingAddress?.timeZone
                          )}
                          ,
                          <span>
                            {getTimeZoneAbbr(
                              closingDetails?.appointmentDate,
                              closingDetails?.closingAddress?.timeZone
                            )}
                          </span>
                        </span>{" "} */}
                        <DateTimeShowWithClosingDetails
                          closingDetails={closingDetails}
                        />
                      </div>
                    </div>
                  </CardHeader>
                  <ul className="cardInfo">
                    {console.log("sdasa ", closingDetails)}
                    <li>
                      <div className="dataWrap">
                        <Label>File# </Label>
                        <div style={{ fontWeight: 600 }}>
                          {closingDetails?.fileNumber || "N/A"}
                        </div>
                      </div>
                      <div className="dataWrap">
                        <Label>Fee </Label>
                        <div style={{ fontWeight: 600 }}>
                          {closingDetails?.signingCompanyFee
                            ? formatCurrencyValue(
                                closingDetails?.signingCompanyFee
                              )
                            : formatCurrencyValue(0)}
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="dataWrap">
                        <Label>Product Type </Label>
                        <div>
                          <span
                            style={{
                              marginRight: 6,
                              fontWeight: 600,
                              display: "inline-block",
                            }}
                          >
                            {closingDetails?.loanType &&
                            closingDetails?.loanType?.length > 0
                              ? closingDetails?.loanType?.indexOf("Other") > -1
                                ? closingDetails?.loanTypeOther
                                : null
                              : null}

                            {closingDetails?.loanType
                              ?.filter((each) => each !== "Other")
                              ?.map((item) => (
                                <>{item?.replace(/([a-z])([A-Z])/g, "$1 $2")}</>
                              ))}
                          </span>
                        </div>
                      </div>

                      <div className="dataWrap">
                        <Label>Signing Type </Label>

                        <div>
                          <span
                            style={{
                              marginRight: 6,
                              fontWeight: 600,
                              display: "inline-block",
                            }}
                          >
                            {closingDetails?.signingType}
                          </span>

                          {closingDetails?.length ? (
                            closingDetails?.indexOf("Other") > -1 ? (
                              <span
                                style={{
                                  marginRight: 6,
                                  fontWeight: 600,
                                  display: "inline-block",
                                }}
                              >
                                {closingDetails?.loanTypeOther}
                              </span>
                            ) : null
                          ) : null}

                          {React.Children.toArray(
                            closingDetails?.loanType
                              ?.filter((each) => each !== "Other")
                              .map((item) => (
                                <span
                                  key={item._id}
                                  style={{
                                    marginRight: 6,
                                    fontWeight: 600,
                                    display: "inline-block",
                                  }}
                                >
                                  {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                                </span>
                              ))
                          )}
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="dataWrap">
                        <div className="d-flex justify-content-between">
                          <Label>Location </Label>
                          <Button
                            color="link"
                            className="p-0 fs-12"
                            title={formatAddressInSingleText(
                              closingDetails?.closingAddress
                            )}
                            onClick={() =>
                              openGoogleMapOnNewTab(
                                closingDetails?.closingAddress
                              )
                            }
                          >
                            <img
                              src={
                                require("../../../assets/img/map-marker.svg")
                                  .default
                              }
                              alt="map icon"
                              className="mr-1"
                            />
                            Map
                          </Button>
                        </div>
                        <div
                          style={{
                            fontWeight: 600,
                            display: "block",
                          }}
                        >
                          {formatAddressInSingleText(
                            closingDetails?.closingAddress
                          ) || "N/A"}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dataWrap">
                        <Label>Order On Behalf Of </Label>
                        <div style={{ fontWeight: 600 }}>
                          {closingDetails?.lender
                            ? capitalize(closingDetails?.lender)
                            : "N/A"}
                        </div>
                      </div>
                    </li>
                    <li>
                      <div className="dataWrap d-flex align-items-center justify-content-between">
                        <Label className="mb-0">Status</Label>
                        <div
                          className="customSelectWrap"
                          style={{ width: 120 }}
                        >
                          <Input
                            type="select"
                            name="status"
                            className={`status ${getDropdownColor(
                              closingDetails.status
                            )}`}
                            disabled={
                              loading.statusChangeLoading ? true : false
                            }
                            value={closingDetails.status}
                            onChange={(event) =>
                              this._updateClosingDetailsByIdStatus(
                                closingDetails,
                                {
                                  status: event.target.value,
                                }
                              )
                            }
                          >
                            {agentClosingStatus.map((obj) => (
                              <option
                                key={obj.value}
                                value={obj.value}
                                disabled={
                                  obj.value === "CCA" ||
                                  obj.value === "Arrived" ||
                                  obj.value === "Closed" ||
                                  obj.value === "DNC" ||
                                  closingDetails?._agent
                                }
                              >
                                {obj.label}
                              </option>
                            ))}
                          </Input>
                          <div className="downArrow">
                            <i className="fa fa-chevron-down"></i>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Card>

                <Collapse
                  className="accordianCollapse"
                  isOpen={this.state.isOpenCollapse}
                >
                  <ul className="clientDetails">
                    {/*  <li>
                     <div className="userInfo">
                         <div className="userImage">
                          <img
                            // src={
                            //   require("../../../assets/img/multi.png").default
                            // }
                            src={
                              closingDetails?._lenderClient?.picUrl
                                ? closingDetails?._lenderClient?.picUrl
                                : require("../../../assets/img/default_user_icon.svg")
                                    .default
                            }
                            alt="logo"
                          />
                        </div> */}
                    {/* <div className="userContent">
                          <span>Client</span>
                          <h5>
                          
                            {closingDetails?.lender
                              ? capitalize(closingDetails?.lender)
                              : "N/A"}
                          </h5>
                        </div> 
                      </div>
                    </li>*/}
                    {closingDetails?._clientAssistantTeam?.teamName ? (
                      <li>
                        <span>Team Name</span>
                        <h5>
                          {closingDetails?._clientAssistantTeam?.teamName ||
                            "N/A"}
                        </h5>
                      </li>
                    ) : null}
                    <li>
                      <span>Contact Name</span>
                      <h5>
                        {capitalize(closingDetails?.personOfContact?.name) ||
                          "N/A"}
                      </h5>
                    </li>
                    <li>
                      <span>Contact Email</span>
                      <h5>
                        {closingDetails?.personOfContact?.email?.length > 0
                          ? closingDetails?.personOfContact?.email?.join(", ")
                          : "N/A"}
                      </h5>
                    </li>
                    <li>
                      <span>Signing Type</span>{" "}
                      <h5>{closingDetails?.signingType || "N/A"}</h5>
                    </li>
                    <li>
                      <span>Witness Number</span>
                      <h5>{closingDetails?.witnessCount || "N/A"}</h5>
                    </li>
                    {closingDetails?.signingType === "RON" ? (
                      <li>
                        <span>RON Platform Types</span>{" "}
                        <h5>
                          {closingDetails?.ronPlatformTypes?.length
                            ? closingDetails?.ronPlatformTypes?.map(
                                (each) => each + ", "
                              )
                            : "N/A"}
                        </h5>
                      </li>
                    ) : null}
                    {/* <li>
                      <span>Signing Company</span>
                      <h5>
                        {closingDetails?._signingCompany?.companyName || "N/A"}
                      </h5>
                    </li> */}
                  </ul>
                  <ul className="questionPoint">
                    {closingDetails?.questions.length > 0 &&
                      closingDetails?.questions.map((question) => (
                        <li className={question.reply ? "yes" : "No"}>
                          {question.query}
                          <div className="answer">
                            {" "}
                            {question.reply ? (
                              <img
                                src={
                                  require("../../../assets/img/checkIcon.svg")
                                    .default
                                }
                                alt="yes check"
                              />
                            ) : (
                              <img
                                src={
                                  require("../../../assets/img/cross.png")
                                    .default
                                }
                                alt="No cross"
                              />
                            )}{" "}
                            {question.reply ? "Yes" : "No"}
                          </div>
                        </li>
                      ))}
                  </ul>
                </Collapse>

                <div className="text-center">
                  <Button
                    color="link"
                    className={`accordianButton ${
                      this.state.isOpenCollapse ? "" : "show"
                    }`}
                    onClick={() =>
                      this.setState({
                        isOpenCollapse: !this.state.isOpenCollapse,
                      })
                    }
                  >
                    {this.state.isOpenCollapse ? "Hide" : "Show"} Other Details
                    <img
                      src={
                        require("../../../assets/img/arrow-blue.svg").default
                      }
                      alt="details"
                    />
                  </Button>
                </div>
              </div>

              <Row className="mt-4">
                <Col xl="6">
                  <div className="CardWrapper">
                    <div className="CardTitle">
                      <h2 className="sectionTtile">
                        Secure Documents{" "}
                        {/* {loading ? <Spinner size="sm" /> : null}{" "} */}
                      </h2>
                    </div>
                    <SecureDocumentCardComponent
                      closingDetails={closingDetails}
                      updateClosingState={(closingDetails) =>
                        this._updateClosingState(closingDetails)
                      }
                    />
                  </div>
                </Col>

                <Col xl="6">
                  <Card className={`noataryAgent`}>
                    <CardHeader>
                      <CardTitle>
                        {closingDetails?._agent?.workType
                          ? closingDetails?._agent.workType
                          : "Agent"}{" "}
                        Details
                      </CardTitle>
                    </CardHeader>
                    <CardBody className="p-0">
                      {closingDetails?._agent ? (
                        <>
                          <Label className="uploadProfile">
                            <Input
                              type="file"
                              style={{ display: "none" }}
                              accept="image/x-png,image/gif,image/jpeg"
                              value=""
                              onChange={(event) =>
                                this._updateFileImage("logo", event)
                              }
                            />
                            <img
                              src={
                                require("../../../assets/img/placeholder-img.png")
                                  .default
                              }
                              alt="logo"
                              style={{ objectFit: "contain" }}
                            />
                            <i className="fa fa-pencil" />
                          </Label>

                          <ul className="noataryAgentList">
                            <li>
                              Name
                              <div className="value">
                                {capitalize(closingDetails?._agent?.name?.full)}
                              </div>
                            </li>
                            {/* <li>
                            Email
                            <div>
                              <Button color="link">
                                {closingDetails?._agent?.email
                                  ? closingDetails._agent.email
                                  : "N/A"}
                              </Button>
                            </div>
                          </li>
                          <li>
                            Phone
                            <div>
                              {closingDetails?._agent?.phone
                                ? formatPhoneNumber(closingDetails._agent.phone)
                                : "N/A"}
                            </div>
                          </li> */}
                            <li>
                              Location
                              <div className="value">
                                {closingDetails?._agent?.address
                                  ? formatAddressInSingleText(
                                      closingDetails._agent.address
                                    ) || "N/A"
                                  : "N/A"}
                              </div>
                            </li>
                            <li>
                              Rating
                              <div className="rating">
                                <StarRatings
                                  rating={closingDetails?._agent?.totalRating}
                                  starRatedColor="#FED82F"
                                  changeRating={
                                    closingDetails._agent.totalRating
                                  }
                                  starHoverColor="#FED82F"
                                  numberOfStars={5}
                                  name="rating"
                                />
                              </div>
                              {/* <div>
                                {closingDetails?._agent?.totalRating
                                  ? `${closingDetails._agent.totalRating}/5`
                                  : "N/A"}
                              </div> */}
                            </li>
                          </ul>
                        </>
                      ) : (
                        <div className="p-3">
                          Agent has not been assigned yet.
                        </div>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>

              {/* chat log */}
              <ChatLogCardComponent closingDetails={closingDetails} />

              {/* Order Instructions */}
              <div className="CardWrapper">
                <div className="CardTitle">
                  <h2 className="sectionTtile">
                    {" "}
                    Order Instructions
                    {editing.instructions ? (
                      <>
                        <Button
                          color="link"
                          disabled={loading.cardUpdateLoading}
                          onClick={() => this._updateClosing("instructions")}
                        >
                          {loading.cardUpdateLoading ? (
                            <i className="fa fa-spinner fa-spin" />
                          ) : null}{" "}
                          <img
                            src={
                              require("../../../assets/img/checkBlueIcon.svg")
                                .default
                            }
                            alt="save"
                          />
                        </Button>
                        <Button
                          color="link"
                          onClick={() =>
                            this._editModeOn("instructions", false)
                          }
                        >
                          <img
                            src={
                              require("../../../assets/img/close.svg").default
                            }
                            height={16}
                            alt="close"
                          />
                        </Button>
                      </>
                    ) : (
                      <Button
                        color="link"
                        onClick={() => this._editModeOn("instructions", true)}
                        disabled={
                          !checkPermission("dashboard", "canEditOrderEntry")
                        }
                      >
                        <img
                          src={
                            require("../../../assets/img/pencil.svg").default
                          }
                          alt="edit"
                        />
                      </Button>
                    )}
                  </h2>
                </div>
                <Card>
                  <CardBody>
                    <CustomInput
                      className="mb-2"
                      type="checkbox"
                      id="isScanBackNeeded"
                      label="Scan Backs Needed"
                      // checked={closingDetails.isScanBackNeeded}
                      checked={formFields.isScanBackNeeded.value}
                      onChange={(event) =>
                        this._updateClosingDetailsByIdStatus(closingDetails, {
                          isScanBackNeeded: event.target.checked,
                        })
                      }
                    />
                    {editing.instructions ? (
                      <FormGroup className="floatingLabel custom-select-wrapper">
                        <Input
                          type="select"
                          value={formFields.instructionId.value}
                          name="instructionId"
                          onChange={(event) =>
                            this._onChangeFormField(
                              "instructionId",
                              event.target.value
                            )
                          }
                        >
                          <option value="">select</option>
                          {signingInstruction.map((each) => (
                            <option key={each._id} value={each._id}>
                              {capitalize(each.name)}
                            </option>
                          ))}
                        </Input>
                        <Label>Instructions Type</Label>
                      </FormGroup>
                    ) : null}

                    <FormGroup
                      className={`mb-0 floatingLabel reactQuill ${
                        formFields.instructions.value !== "<p><br></p>" ||
                        this.state.isFocusTextEditor
                          ? "valueAdded"
                          : ""
                      }`}
                    >
                      {editing.instructions ? (
                        <TextEditor
                          content={formFields.instructions.value}
                          onChange={(event) =>
                            this._onChangeFormField("instructions", event)
                          }
                          onFocus={() =>
                            this.setState({ isFocusTextEditor: true })
                          }
                          onBlur={() =>
                            this.setState({ isFocusTextEditor: false })
                          }
                        />
                      ) : (
                        <TextEditor
                          readOnly={true}
                          content={closingDetails?.instructions}
                          onChange={(event) =>
                            this._onChangeFormField("instructions", event)
                          }
                        />
                      )}
                      <Label>Instructions</Label>
                    </FormGroup>
                  </CardBody>
                </Card>
              </div>

              {/* activity */}
              <div className="CardWrapper">
                <div className="CardTitle">
                  <h2 className="sectionTtile">Activity Log</h2>
                </div>
                <Card>
                  <CardBody>
                    <ul className="activityLog">{this._activityLog()}</ul>
                    <CustomPagination
                      data={activityLog}
                      dataCount={activityLogPage.totalCount}
                      pageNumber={
                        parseInt(activityLogPage.skip / activityLogPage.limit) +
                        1
                      }
                      pageSize={activityLogPage.limit}
                      onPageChange={(pageNo) =>
                        this._handleActivityLogPagination(pageNo)
                      }
                      isPageStartFromOne
                      isHideForLessData
                    />
                  </CardBody>
                </Card>
              </div>
            </>
          ) : (
            <Card>
              <CardBody className="text-center">
                {loading.dataLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <div className="nodata">
                      <img
                        src={require("../../../assets/img/noData.svg").default}
                        alt="no data"
                      />
                      <p>There is no data to display</p>
                    </div>
                  </>
                )}
              </CardBody>
            </Card>
          )}
        </div>
        {/* modal */}
        {signerAvailabilityModal.isOpen ? (
          <SignerAvailabilityModal
            isOpen={signerAvailabilityModal.isOpen}
            data={signerAvailabilityModal.data}
            resetDetails={this._getClosingDetailsById}
            toggle={() => this._onToggleSignerAvailabilityModal()}
            AppointmentSchedulerConfig={{
              regular: { disable: false, show: true },
              dateRange: { disable: true, show: true },
              tbd: { disable: false, show: true },
            }}
          />
        ) : null}
        <EditOrderDetailsModal
          isOpen={editOrderDetailsModal.isOpen}
          data={editOrderDetailsModal.data}
          toggle={() => this._toggleEditOrderDetailsModal()}
          updateClosingDetailsById={this._updateClosingDetailsById}
          resetDetails={this._getClosingDetailsById}
          clients={lenderClients}
          signingCompanies={signingCompanies}
          loanType={this.state.loanType}
          AppointmentSchedulerConfig={{
            regular: { disable: false, show: true },
            dateRange: { disable: true, show: true },
            tbd: { disable: false, show: true },
          }}
        />
      </div>
    );
  }
}

export default OrderDetails;
