import React from "react";
import {
  Input,
  Button,
  InputGroup,
  FormGroup,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  InputGroupText,
  InputGroupAddon,
  Table,
  Label,
} from "reactstrap";
import SigningStatusModal from "../components/signing-status";
import SignerAvailabilityAgentModal from "../components/Modals/signer-availability-for-agents-modal";
import {
  deepClone,
  formatCurrencyValue,
  showToast,
  formatDateHideYearIfSame,
  agentTodayOrFutureDate,
  formatDateAsPerTimeZOne,
  getTimeZoneAbbr,
  enableTimeBetweenStartTimeAndEndTime,
  formatOnlyDateMoment,
  convertIsoToUtc,
  formatAddressInSingleText,
  getDropdownColor,
  openGoogleMapOnNewTab,
  openAppleMapOnNewTab,
  refreshFunc,
  deleteLocalStorageData,
  errorHandler,
} from "../../../helper-methods";
import { connect } from "react-redux";
import {
  getAllAgentClosing,
  updateClosingDetailsById,
  getLoanType,
  getAgentDraftClosings,
} from "../../../http/http-calls";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { agentClosingStatus } from "../../../config";
import CustomTable from "../components/CustomTable";
import {
  HeaderEventEmitter,
  // subscribeResetFilterEvent,
  // unsubscribeResetFilterEvent,
} from "../../../helper-methods/HeaderEvents";
import CustomCard from "../components/CustomCard";
import ShowVideoModal from "../components/ShowVideoModal";
import AgentDashboardimportModal from "../components/AgentDashboardimportModal";
import SvgIcons from "../components/SvgIcons";
import { DateTimeShowWithCellRow } from "../components/Date-time-show";

class AgentDashboard extends React.Component {
  state = {
    agentClosingList: [],
    draftClosingsList: [],
    draftClosingTotalCount: 0,
    agentClosingTotalCount: 0,
    agentClosingHeaderKeys: [
      { id: "id", label: "id" },
      { id: "appointmentDate", label: "signer" },
      { id: "fileNumber", label: "File#" },
      { id: "loanType", label: "Product Type" },
      { id: "agentFee", label: "Fee" },
      { id: "docs", label: "Docs" },
      { id: "closingAddress", label: "Location" },
      { id: "status", label: "Status" },
    ],
    agentClosingCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "appointmentDate", label: "signer" },
      // { id: "fileNumber", label: "File#" },
      // { id: "loanType", label: "Product Type" },
      // { id: "agentFee", label: "Fee" },
      // { id: "docs", label: "Docs" },
      // { id: "closingAddress", label: "Location" },
      // { id: "status", label: "Status" },
    ],
    agentClosingCardBodyKeys: [
      { id: "id", label: "id" },
      // { id: "appointmentDate", label: "signer" },
      { id: "fileNumber", label: "File#" },
      { id: "loanType", label: "Product Type" },
      { id: "agentFee", label: "Fee" },
      { id: "docs", label: "Docs" },
      { id: "closingAddress", label: "Location" },
      { id: "status", label: "Status" },
    ],
    draftClosingHeaderKeys: [
      { id: "id", label: "id" },
      { id: "appointmentDate", label: "signer" },
      { id: "fileNumber", label: "File#" },
      { id: "loanType", label: "Product Type" },
      { id: "agentFee", label: "Fee" },
      { id: "closingAddress", label: "Location" },
      { id: "action", label: "Action" },
    ],
    signingStatusModal: {
      isOpen: false,
      data: null,
      status: null,
    },
    signerAvailabilityModal: {
      isOpen: false,
      data: null,
    },
    importModal: {
      isOpen: false,
      data: null,
    },
    filters: {
      status: "",
      appointmentDate: "",
      search: "",
    },
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    },
    draftTableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    },
    dateRangeValue: null,
    loading: {
      statusChangeLoading: false,
      showTableLoading: false,
    },
    loanType: [],

    isClassAdded: false,
    isOpen: false,
    role: "",
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  componentDidMount = () => {
    this.searchTimer = null;
    // this._getLoanType();
    this._getAgentDraftClosings();
    this._setFilterDataFromLocalStorage();

    let path = this.props.location.search?.split("/")[1];
    if (path) {
      this._onToggleSignerAvailabilityModal(true, { id: path });
    }

    // subscribeResetFilterEvent(() => {
    //   console.log("filter reset hit");
    //   this._resetFilter()
    //   // unsubscribeResetFilterEvent();
    // });

    HeaderEventEmitter.subscribe("reset-filter", () => {
      this._resetFilter();
      refreshFunc("agent-dashboard");
    });
    HeaderEventEmitter.subscribe("create-closing", () => {
      this._redirectToCreateClosing();
    });

    HeaderEventEmitter.subscribe("import-modal", () => {
      this._onToggleImportModal(true);
    });

    if (!this.props.userData.user?.isWelcomeVideo) {
      this.setState({
        isOpen: true,
        role: this.props.userData.type,
      });
    }

    document.querySelector("#scroll").scrollTo(0, 0);
  };

  _getLoanType = () => {
    getLoanType()
      .then((res) => {
        let options =
          res?.loanType.map((item) => ({
            value: item,
            label: item.replace(/([A-Z])/g, " $1").trim(),
          })) || [];
        this.setState({ loanType: options });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getAllAgentClosing = () => {
    this._manageLoading("showTableLoading", true);

    const { filters, tableConfig, dateRangeValue } = deepClone(this.state);

    if (filters.status && filters.status.length) {
      Object.assign(tableConfig, { status: filters.status });
    } else {
      delete tableConfig.status;
    }

    if (filters.search && filters.search.trim().length) {
      Object.assign(tableConfig, { search: filters.search.trim() });
    } else {
      delete tableConfig.search;
    }

    if (
      dateRangeValue !== null &&
      dateRangeValue[0]?.getDate() === dateRangeValue[1]?.getDate()
    )
      Object.assign(tableConfig, { isReverse: true });

    delete tableConfig.pageNumber;

    let tableConfigData = this._structureQueryParams(tableConfig);

    let params = `appointmentDate[start]=${
      dateRangeValue !== null
        ? formatOnlyDateMoment(dateRangeValue[0])
        : undefined
    }&appointmentDate[end]=${
      dateRangeValue !== null
        ? formatOnlyDateMoment(dateRangeValue[1])
        : undefined
    }${tableConfigData}`;

    getAllAgentClosing(params)
      .then((res) => {
        this.setState(
          {
            agentClosingList: res?.closings || [],
            agentClosingTotalCount: res?.totalCount || 0,
          },
          () => {
            this._manageLoading("showTableLoading", false);
            // document.querySelector(".responsiveTitle").scrollIntoView();
          }
        );
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _structureQueryParams = (params) => {
    let queryStrings = "&";
    const keys = Object.keys(params);
    keys.forEach((key, index) => {
      queryStrings += key + "=" + params[key];
      if (params[keys[index + 1]]) {
        queryStrings += "&";
      }
    });
    return queryStrings;
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllAgentClosing();
    });
    // document.querySelector(".content").scrollIntoView();
  };

  _onToggleSigningStatusModal = (
    isOpen = false,
    data = null,
    status = null
  ) => {
    this.setState({
      signingStatusModal: {
        isOpen,
        data,
        status,
      },
    });
  };

  _onToggleSignerAvailabilityModal = (isOpen = false, data = null) => {
    this.setState({
      signerAvailabilityModal: {
        isOpen,
        data,
      },
    });
  };

  _onToggleImportModal = (isOpen = false, data = null) => {
    this.setState({
      importModal: {
        isOpen,
        data,
      },
    });
  };

  _filterOnChange = (type, value, isDelay = false) => {
    // throw new Error("Invalid");
    if (isDelay) clearTimeout(this.searchTimer);

    const { filters } = deepClone(this.state);

    filters[type] = value;

    this.setState({ filters }, () => {
      if (isDelay) {
        this.searchTimer = setTimeout(() => {
          this._paginate();
          this._persistFilter();
        }, 1000);
      } else {
        this._paginate();
        this._persistFilter();
      }
    });
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          status: "",
          appointmentDate: "",
          search: "",
        },
        dateRangeValue: null,
      },
      () => {
        this._paginate();
      }
    );
    refreshFunc("agent-dashboard-responsive");

    deleteLocalStorageData("agentClosingdashboard");
  };

  _redirectToClosingDetails = (data) => {
    const { userData } = this.props;
    this.props.history.push(
      `/${userData.type}/closing-details-for-agents/${data.id}`
    );
  };

  _redirectToCreateClosing = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/create-closing`);
  };

  _updateClosingDetailsById = (id, payload) => {
    return new Promise((resolve, reject) => {
      updateClosingDetailsById({ id, payload })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _updateClosingDetailsByIdStatus = async (data, status) => {
    try {
      if (
        status === "Closed" ||
        status === "DNC" ||
        status === "CCA" ||
        status === "Cancelled"
      ) {
        this._onToggleSigningStatusModal(true, data, status);
      } else {
        this._manageLoading("statusChangeLoading", true);

        const updateAgentRes = await this._updateClosingDetailsById(data.id, {
          status,
        });

        showToast("Status Updated Successfully", "success");
        const { agentClosingList } = deepClone(this.state);

        if (updateAgentRes.closing.status === "CCA") {
          this._getAllAgentClosing();
        } else {
          const closing = agentClosingList.find(
            (closing) => closing.id === data.id
          );
          closing["status"] = updateAgentRes.closing.status;
          this.setState({ agentClosingList });
        }
        this._manageLoading("statusChangeLoading", false);
      }
    } catch (error) {
      errorHandler(error);
      this._manageLoading("statusChangeLoading", false);
    }
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "appointmentDate": {
        return row ? (
          <>
            <div className="tableUserInfo">
              <div className="userImage">
                <img
                  className="clientImg"
                  src={
                    row?._signingCompany?.logo
                      ? row?._signingCompany?.logo
                      : row?._client?.logo
                      ? row?._client?.logo
                      : require("../../../assets/img/default_user_icon.svg")
                          .default
                  }
                  loading="lazy"
                  alt="avatar"
                />
              </div>
              <div className="userContent">
                <span
                  id={`borrower_table_agent_dashboard_${row._id}`}
                  className="signerName"
                >
                  {row?._borrower[0]?.name?.full
                    ? row?._borrower[0]?.name?.full
                    : "N/A"}
                  {row?._borrower?.length > 1
                    ? `+${row?._borrower?.length - 1}`
                    : null}
                </span>

                {row?._borrower?.length ? (
                  <>
                    <UncontrolledPopover
                      trigger="legacy"
                      placement="bottom"
                      target={`borrower_table_agent_dashboard_${row._id}`}
                      className="signerPopover"
                    >
                      <PopoverHeader>Signers</PopoverHeader>
                      <PopoverBody>
                        <Table className="responsive-sm">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>Language</th>
                              <th>Time Zone</th>
                            </tr>
                          </thead>
                          <tbody>
                            {row?._borrower.map((each) => (
                              <>
                                <tr key={each._id}>
                                  <td>{each.name?.full || "N/A"}</td>
                                  <td>{each.email || "N/A"}</td>
                                  <td>
                                    {each?.phone?.home ||
                                      each?.phone?.work ||
                                      "N/A"}
                                  </td>
                                  <td>{each.language || "N/A"}</td>
                                  <td>{each.timeZone || "N/A"}</td>
                                </tr>
                              </>
                            ))}
                          </tbody>
                        </Table>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </>
                ) : null}

                <DateTimeShowWithCellRow cell={cell} row={row} />

                <Button
                  color={`${
                    row?.appointmentStatus === "Confirmed" && !row.leftMessage
                      ? "success "
                      : row?.appointmentStatus === "Rescheduled" &&
                        !row.leftMessage
                      ? "danger "
                      : row.leftMessage
                      ? "warning"
                      : "danger "
                  }`}
                  className={`confirmButton `}
                  onClick={() =>
                    this._onToggleSignerAvailabilityModal(true, row)
                  }
                >
                  {row?.appointmentStatus === "Confirmed"
                    ? "Confirmed"
                    : "Confirm"}
                </Button>
              </div>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "fileNumber": {
        return row ? (
          <>
            <div
              className="tableLink"
              style={{
                minWidth: 80,
                whiteSpace: "normal",
                textDecoration: "underline",
              }}
              onClick={() => this._redirectToClosingDetails(row)}
            >
              {row.fileNumber || "N/A"}
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "loanType": {
        return (
          <>
            <div style={{ width: 100 }}>
              <span
                style={{
                  marginRight: 6,
                  display: "inline-block",
                  fontWeight: 600,
                }}
              >
                {row.signingType} {","}
              </span>

              {cell && cell.length > 0 ? (
                cell.indexOf("Other") > -1 ? (
                  <span
                    style={{
                      marginRight: 6,
                      display: "inline-block",
                      fontWeight: 600,
                    }}
                  >
                    {row?.loanTypeOther}
                  </span>
                ) : null
              ) : null}

              {React.Children.toArray(
                cell
                  .filter((each) => each !== "Other")
                  .map((item) => (
                    <span
                      key={item._id}
                      style={{
                        marginRight: 6,
                        display: "inline-block",
                        fontWeight: 600,
                      }}
                    >
                      {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                    </span>
                  ))
              )}
            </div>
          </>
        );
      }
      case "agentFee": {
        return (
          <>
            <div style={{ fontWeight: 600, width: 60 }}>
              {/* {cell ? formatCurrencyValue(cell) : formatCurrencyValue(0)} */}
              {row?.isAgentWebsiteClosing
                ? row?._mainForwardAgent !== undefined &&
                  row?._mainForwardAgent !== row?._agent?.id
                  ? formatCurrencyValue(row.agentFeeSharingAmount)
                  : formatCurrencyValue(cell || 0)
                : formatCurrencyValue(cell || 0)}
            </div>
          </>
        );
      }
      case "closingAddress": {
        return (
          <>
            <span style={{ fontWeight: 600, width: 120, display: "block" }}>
              {formatAddressInSingleText(cell) || "N/A"}
            </span>
            <Button
              color="link"
              className="p-0"
              title={formatAddressInSingleText(cell)}
              onClick={() => openGoogleMapOnNewTab(row.closingAddress)}
            >
              <img
                src={
                  require("../../../assets/img/Google_Maps_Logo_2020.svg")
                    .default
                }
                alt="map icon"
                className="mr-1"
              />
              Google Map
            </Button>
            <Button
              color="link"
              className="p-0"
              title={formatAddressInSingleText(cell)}
              onClick={() => openAppleMapOnNewTab(row.closingAddress)}
            >
              <img
                src={
                  require("../../../assets/img/Apple_Maps_(WatchOS).svg")
                    .default
                }
                alt="map icon"
                className="mr-1"
              />
              Apple Map
            </Button>
          </>
        );
      }
      case "docs": {
        return row ? (
          <div className="docStatus">
            {/* Last Uploaded by Company: $DateTime */}
            <Button
              color="link"
              className="actionBtn"
              data-toggle="tooltip"
              data-placement="top"
              title={
                row?.companyLastDocumentUploadedAt &&
                `Last Uploaded by Company: ${formatDateAsPerTimeZOne(
                  row?.companyLastDocumentUploadedAt,
                  row?.closingAddress?.timeZone
                )}`
              }
              onClick={() => this._redirectToClosingDetails(row)}
            >
              {row?.documents?.length && !row.documents[0].isPrivate ? (
                row.isDocumentCompleted ? (
                  <span
                    className="fa fa-circle text-success"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                ) : row?.isDocumentUploadedByCompanyOrClient ? (
                  <span
                    className="fa fa-circle text-warning"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                ) : (
                  <span
                    className="fa fa-circle text-primary"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )
              ) : (
                <span
                  className="fa fa-circle text-primary"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )}
              <span className="value">
                {row.isDocumentCompleted
                  ? "Docs Ready"
                  : row?.isDocumentUploadedByCompanyOrClient
                  ? "Pending"
                  : "Not Ready"}
              </span>
            </Button>

            {/* First downloaded by Agent: DateTime */}
            {/* turns green only when agent downloaded all documents  */}
            <Button
              color="link"
              className="actionBtn"
              data-toggle="tooltip"
              data-placement="top"
              title={
                row?.isAgentViewDocumentTime &&
                `First downloaded by Agent:  ${formatDateAsPerTimeZOne(
                  row?.isAgentViewDocumentTime,
                  row?.closingAddress?.timeZone
                )}`
              }
            >
              {row.isAllDocumentsDownloaded ? (
                <span
                  className="fa fa-circle text-success"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              ) : (
                <span
                  className="fa fa-circle text-warning"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )}
              <span className="value">
                {row?.isAllDocumentsDownloaded ? "Docs Downloaded" : "Pending"}
              </span>
            </Button>

            {/* Last Uploaded by Agent: DateTime */}
            <Button
              color="link"
              className="actionBtn"
              data-toggle="tooltip"
              data-placement="top"
              title={
                row?.agentLastDocumentUploadedAt &&
                `Last Uploaded by Agent:  ${formatDateAsPerTimeZOne(
                  row?.agentLastDocumentUploadedAt,
                  row?.closingAddress?.timeZone
                )}`
              }
            >
              {row.isScanBackNeeded ? (
                row.isDocumentVerified ? (
                  <>
                    <span
                      className="fa fa-circle text-success"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span className="value">Shipping Approved</span>
                  </>
                ) : row.isDocumentCompletedByAgent ? (
                  <>
                    <span
                      className="fa fa-circle text-warning"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span
                      className="value"
                      onClick={() => this._redirectToClosingDetails(row)}
                    >
                      Scan Upload
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className="fa fa-circle text-danger"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span className="value">Scan Needed</span>
                  </>
                )
              ) : row?.documents?.length && !row.documents[0].isPrivate ? (
                row.isDocumentCompletedByAgent ? (
                  row.isDocumentVerified ? (
                    <>
                      <span
                        className="fa fa-circle text-success"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                      <span className="value">Shipping Approved</span>
                    </>
                  ) : (
                    <>
                      <span
                        className="fa fa-circle text-primary"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                      <span className="value">Scan Upload</span>
                    </>
                  )
                ) : (
                  <span
                    className="fa fa-circle"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )
              ) : (
                <span
                  className="fa fa-circle"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )}
            </Button>
          </div>
        ) : (
          "N/A"
        );
      }
      case "status": {
        return cell ? (
          <div className="customSelectWrap" style={{ width: 120 }}>
            {/* {console.log({ row }, { cell })} */}
            <Input
              type="select"
              name="status"
              className={`status ${getDropdownColor(cell)}`}
              value={cell}
              // disabled={this.state.loading.statusChangeLoading ? true : false}
              disabled={
                row?.isAgentWebsiteClosing
                  ? row?.status !== "Pending" && row?.status !== "Arrived"
                    ? true
                    : this.state.loading.statusChangeLoading
                    ? true
                    : false
                  : this.state.loading.statusChangeLoading
                  ? true
                  : false
              }
              onChange={(event) =>
                this._updateClosingDetailsByIdStatus(row, event.target.value)
              }
            >
              <option key="CCA" value="CCA">
                Can't Complete
              </option>
              <option
                key="Pending"
                value="Pending"
                // disabled={row._signingCompany !== undefined}
                disabled={!row.isCreatedForAgent}
              >
                Pending
              </option>
              <option
                key="Closed"
                value="Closed"
                disabled={agentTodayOrFutureDate(row) && cell !== "Arrived"}
              >
                Closed
              </option>
              <option
                key="Cancelled"
                value="Cancelled"
                disabled={!row.isCreatedForAgent}
              >
                Cancelled
              </option>
              <option
                key="DNC"
                value="DNC"
                disabled={agentTodayOrFutureDate(row) && cell !== "Arrived"}
              >
                Did not close
              </option>
              <option
                key="Arrived"
                value="Arrived"
                disabled={
                  this._isDisabledCheckStatusDropdown(row) || cell === "Closed"
                }
              >
                Arrived
              </option>
            </Input>
            <div className="downArrow">
              <i className="fa fa-chevron-down"></i>
            </div>
          </div>
        ) : (
          "N/A"
        );
      }
      case "createdAt": {
        return cell ? formatDateHideYearIfSame(cell) : "N/A";
      }
      default: {
        return cell;
      }
    }
  };

  _isDisabledCheckStatusDropdown = (itemData) => {
    let result = enableTimeBetweenStartTimeAndEndTime(
      itemData.appointmentDate,
      itemData.closingAddress?.timeZone
    );
    return !result;
  };

  _rowClassName = (row, rowIdx) => {
    const statusEnum = ["Closed", "DNC", "Cancelled", "CCA"];
    if (row?.status && statusEnum.includes(row.status)) {
      return "rowStatus";
    }
    return "";
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => {
      this._paginate();
      this._persistFilter();
    });
  };

  _persistFilter = () => {
    const { filters, dateRangeValue, tableConfig } = this.state;
    if (
      (filters &&
        (filters.status || (filters.search && filters.search.length))) ||
      dateRangeValue !== null ||
      tableConfig.pageNumber !== 1
    ) {
      let data = { filters, dateRangeValue, tableConfig };
      localStorage.agentClosingdashboard = JSON.stringify(data);
    } else {
      delete localStorage.agentClosingdashboard;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    // const { filters, tableConfig } = deepClone(this.state);

    if (localStorage && localStorage.agentClosingdashboard) {
      try {
        const filter = JSON.parse(localStorage.agentClosingdashboard);
        if (filter.dateRangeValue !== null) {
          let dateRange = [
            new Date(convertIsoToUtc(filter?.dateRangeValue[0])),
            new Date(convertIsoToUtc(filter?.dateRangeValue[1])),
          ];
          this.setState({ dateRangeValue: dateRange }, () => {
            this._getAllAgentClosing();
          });
        }
        this.setState(
          { filters: filter.filters, tableConfig: filter?.tableConfig },
          () => {
            this._getAllAgentClosing();
          }
        );
      } catch (e) {
        this._getAllAgentClosing();
      }
    } else {
      this._getAllAgentClosing();
    }
  };

  _prepareFilterData = () => {
    const { filters } = deepClone(this.state);

    if (!filters.states) delete filters.agent;
    if (!filters.status) delete filters.status;
    // if(dateRangeValue===null) delete dateRangeValue;

    return filters;
  };

  _cardHeaderFormat = (cell, row, header) => {
    // console.log("weerer", cell, header);
    switch (header) {
      case "appointmentDate": {
        return row ? (
          <>
            <div className="tableUserInfo">
              <div className="userImage">
                <img
                  className="clientImg"
                  src={
                    row?._signingCompany?.logo
                      ? row?._signingCompany?.logo
                      : row?._client?.logo
                      ? row?._client?.logo
                      : require("../../../assets/img/default_user_icon.svg")
                          .default
                  }
                  loading="lazy"
                  alt="avatar"
                />
              </div>
              <div className="userContent flex-0">
                <span
                  id={`borrower_table_agent_dashboard_Signer${row._id}`}
                  className="signerName"
                >
                  {row?._borrower[0]?.name?.full
                    ? row?._borrower[0]?.name?.full
                    : "N/A"}
                  {row?._borrower?.length > 1
                    ? `+${row?._borrower?.length - 1}`
                    : null}
                </span>

                {row?._borrower?.length ? (
                  <>
                    <UncontrolledPopover
                      trigger="legacy"
                      placement="bottom"
                      target={`borrower_table_agent_dashboard_Signer${row._id}`}
                      className="signerPopover "
                    >
                      <PopoverHeader>Signers</PopoverHeader>
                      <PopoverBody>
                        {row?._borrower.map((each) => (
                          <ul key={each._id} className="dataList">
                            <li>
                              <Label>Name</Label>
                              <div>{each.name?.full || "N/A"}</div>
                            </li>
                            <li>
                              <Label>Email</Label>
                              <div>{each.email || "N/A"}</div>
                            </li>
                            <li>
                              <Label>Phone</Label>
                              <div>
                                {each?.phone?.home ||
                                  each?.phone?.work ||
                                  "N/A"}
                              </div>
                            </li>
                            <li>
                              <Label>Language</Label>
                              <div>{each.language || "N/A"}</div>
                            </li>
                            <li>
                              <Label>Time Zone</Label>
                              {each.timeZone || "N/A"}
                            </li>
                          </ul>
                        ))}
                      </PopoverBody>
                    </UncontrolledPopover>
                  </>
                ) : null}
                {/* <span className="dateTimeFormat">
                  {formatDateAsPerTimeZOne(cell, row.closingAddress?.timeZone)}{" "}
                  <span>
                    {getTimeZoneAbbr(cell, row.closingAddress?.timeZone)}
                  </span>
                </span> */}
                <DateTimeShowWithCellRow
                  cell={row?.appointmentDate}
                  row={row}
                />
              </div>
            </div>
            <Button
              color={`${
                row?.appointmentStatus === "Confirmed" && !row.leftMessage
                  ? "success "
                  : row?.appointmentStatus === "Rescheduled" && !row.leftMessage
                  ? "danger "
                  : row.leftMessage
                  ? "warning"
                  : "danger "
              }`}
              className={`confirmButton btnConfirm`}
              onClick={() => this._onToggleSignerAvailabilityModal(true, row)}
            >
              {row?.appointmentStatus === "Confirmed" ? "Confirmed" : "Confirm"}
            </Button>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };
  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>File</Label>
            <div
              className="text-primary cursorPointer"
              style={{ fontWeight: 600 }}
            >
              <div
                className="tableLink"
                style={{
                  minWidth: 80,
                  whiteSpace: "normal",
                  textDecoration: "underline",
                }}
                onClick={() => this._redirectToClosingDetails(row)}
              >
                {row.fileNumber || "N/A"}
              </div>
            </div>
          </div>
          <div className="dataWrap">
            <Label>Fee</Label>
            <div style={{ fontWeight: 600 }}>
              {row.agentFee
                ? formatCurrencyValue(row.agentFee)
                : formatCurrencyValue(0)}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap">
            <Label>Product Type</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                  display: "inline-block",
                }}
              >
                {row.signingType} {","}
              </span>

              {row.loanType && row.loanType.length > 0 ? (
                row.loanType.indexOf("Other") > -1 ? (
                  <span
                    style={{
                      marginRight: 5,
                      fontWeight: 600,
                      display: "inline-block",
                    }}
                  >
                    {row?.loanTypeOther}
                  </span>
                ) : null
              ) : null}

              {React.Children.toArray(
                row.loanType
                  .filter((each) => each !== "Other")
                  .map((item) => (
                    <span
                      key={item._id}
                      style={{
                        marginRight: 5,
                        fontWeight: 600,
                      }}
                    >
                      {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                    </span>
                  ))
              )}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap">
            <Label>Location</Label>
            <div className="d-flex justify-content-between">
              <div style={{ fontWeight: 600, flex: 1, marginRight: 10 }}>
                {formatAddressInSingleText(row.closingAddress) || "N/A"}
              </div>
              <div className="d-flex">
                <Button
                  color="link"
                  className="p-0"
                  title={formatAddressInSingleText(row.closingAddress)}
                  onClick={() => openGoogleMapOnNewTab(row.closingAddress)}
                >
                  <img
                    src={
                      require("../../../assets/img/Google_Maps_Logo_2020.svg")
                        .default
                    }
                    alt="map icon"
                    className="mr-3"
                  />
                </Button>
                <Button
                  color="link"
                  className="p-0"
                  title={formatAddressInSingleText(row.closingAddress)}
                  onClick={() => openAppleMapOnNewTab(row.closingAddress)}
                >
                  <img
                    src={
                      require("../../../assets/img/Apple_Maps_(WatchOS).svg")
                        .default
                    }
                    alt="map icon"
                  />
                </Button>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap">
            <Label>Doc Status</Label>
            <div className="docStatus">
              {/* Last Uploaded by Company: $DateTime */}
              <Button
                color="link"
                className="actionBtn"
                data-toggle="tooltip"
                data-placement="top"
                title={
                  row?.companyLastDocumentUploadedAt &&
                  `Last Uploaded by Company: ${formatDateAsPerTimeZOne(
                    row?.companyLastDocumentUploadedAt,
                    row?.closingAddress?.timeZone
                  )}`
                }
                onClick={() => this._redirectToClosingDetails(row)}
              >
                {row?.documents?.length && !row.documents[0].isPrivate ? (
                  row.isDocumentCompleted ? (
                    <span
                      className="fa fa-circle text-success"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  ) : row?.isDocumentUploadedByCompanyOrClient ? (
                    <span
                      className="fa fa-circle text-warning"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  ) : (
                    <span
                      className="fa fa-circle text-primary"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  )
                ) : (
                  <span
                    className="fa fa-circle text-primary"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )}
                <span className="value">
                  {row.isDocumentCompleted
                    ? "Docs Ready"
                    : row?.isDocumentUploadedByCompanyOrClient
                    ? "Pending"
                    : "Not Ready"}
                </span>
              </Button>

              {/* First downloaded by Agent: DateTime */}
              {/* turns green only when agent downloaded all documents  */}
              <Button
                color="link"
                className="actionBtn"
                data-toggle="tooltip"
                data-placement="top"
                title={
                  row?.isAgentViewDocumentTime &&
                  `First downloaded by Agent:  ${formatDateAsPerTimeZOne(
                    row?.isAgentViewDocumentTime,
                    row?.closingAddress?.timeZone
                  )}`
                }
              >
                {row.isAllDocumentsDownloaded ? (
                  <span
                    className="fa fa-circle text-success"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                ) : (
                  <span
                    className="fa fa-circle text-warning"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )}
                <span className="value">
                  {row?.isAllDocumentsDownloaded
                    ? "Docs Downloaded"
                    : "Pending"}
                </span>
              </Button>

              {/* Last Uploaded by Agent: DateTime */}
              <Button
                color="link"
                className="actionBtn"
                data-toggle="tooltip"
                data-placement="top"
                title={
                  row?.agentLastDocumentUploadedAt &&
                  `Last Uploaded by Agent:  ${formatDateAsPerTimeZOne(
                    row?.agentLastDocumentUploadedAt,
                    row?.closingAddress?.timeZone
                  )}`
                }
              >
                {row.isScanBackNeeded ? (
                  row.isDocumentVerified ? (
                    <>
                      <span
                        className="fa fa-circle text-success"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                      <span className="value">Shipping Approved</span>
                    </>
                  ) : row.isDocumentCompletedByAgent ? (
                    <>
                      <span
                        className="fa fa-circle text-primary"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                      <span
                        className="value"
                        onClick={() => this._redirectToClosingDetails(row)}
                      >
                        Scan Upload
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        className="fa fa-circle text-danger"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                      <span className="value">Scan Needed</span>
                    </>
                  )
                ) : row?.documents?.length && !row.documents[0].isPrivate ? (
                  row.isDocumentCompletedByAgent ? (
                    <>
                      <span
                        className="fa fa-circle text-success"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                      <span className="value">Verified</span>
                    </>
                  ) : (
                    <span
                      className="fa fa-circle"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  )
                ) : (
                  <span
                    className="fa fa-circle"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )}
              </Button>
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap  d-flex align-items-center justify-content-between">
            <Label className="mb-0">Status</Label>
            <div
              className="customSelectWrap"
              style={{ width: 120, marginRight: "0" }}
            >
              <Input
                type="select"
                name="status"
                className={`status ${getDropdownColor(row?.status)}`}
                value={row.status}
                // disabled={this.state.loading.statusChangeLoading ? true : false}
                disabled={
                  row?.isAgentWebsiteClosing
                    ? row?.status !== "Pending" && row?.status !== "Arrived"
                      ? true
                      : this.state.loading.statusChangeLoading
                      ? true
                      : false
                    : this.state.loading.statusChangeLoading
                    ? true
                    : false
                }
                onChange={(event) =>
                  this._updateClosingDetailsByIdStatus(row, event.target.value)
                }
              >
                <option key="CCA" value="CCA">
                  Can't Complete
                </option>
                <option
                  selected
                  key="Pending"
                  value="Pending"
                  // disabled={row._signingCompany !== undefined}
                  disabled={!row.isCreatedForAgent}
                >
                  Pending
                </option>
                <option
                  key="Closed"
                  value="Closed"
                  disabled={
                    agentTodayOrFutureDate(row) && row.status !== "Arrived"
                  }
                >
                  Closed
                </option>
                <option
                  key="Cancelled"
                  value="Cancelled"
                  disabled={!row.isCreatedForAgent}
                >
                  Cancelled
                </option>
                <option
                  key="DNC"
                  value="DNC"
                  disabled={
                    agentTodayOrFutureDate(row) && row.status !== "Arrived"
                  }
                >
                  Did not close
                </option>
                <option
                  key="Arrived"
                  value="Arrived"
                  disabled={
                    this._isDisabledCheckStatusDropdown(row) ||
                    row.status === "Closed"
                  }
                >
                  Arrived
                </option>
              </Input>
              <div className="downArrow">
                <i className="fa fa-chevron-down"></i>
              </div>
            </div>
          </div>
        </li>
      </ul>
    );
  };
  _getAgentDraftClosings = () => {
    const { draftTableConfig } = this.state;

    let payload = {
      skip: draftTableConfig.skip,
      limit: draftTableConfig.limit,
    };
    getAgentDraftClosings(payload)
      .then((res) => {
        this.setState({ draftClosingsList: res?.closings || [] });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _paginateDraft = (pageNumber = 1, pageSize = 10) => {
    const { draftTableConfig } = this.state;
    draftTableConfig.skip = (pageNumber - 1) * pageSize;
    draftTableConfig.limit = pageSize;
    draftTableConfig["pageNumber"] = pageNumber;
    this.setState({ draftTableConfig }, () => {
      // this._getAllAgentClosing();
      this._getAgentDraftClosings();
    });
    // document.querySelector(".content").scrollIntoView();
  };

  render() {
    const {
      filters,
      agentClosingList,
      agentClosingTotalCount,
      agentClosingHeaderKeys,
      agentClosingCardHeaderKeys,
      loading,
      signerAvailabilityModal,
      signingStatusModal,
      tableConfig,
      dateRangeValue,
      isClassAdded,
      agentClosingCardBodyKeys,
      isOpen,
      role,
      importModal,
      draftClosingsList,
      draftTableConfig,
      draftClosingHeaderKeys,
      draftClosingTotalCount,
    } = this.state;
    console.log("hh", this.state);
    const { userData } = this.props;
    return (
      <div id="scroll">
        <div className="content">
          <div className="responsiveTitle">
            <h2>Order Dashboard</h2>

            <div className="rightSide">
              <Button color="link" onClick={this._resetFilter}>
                <img
                  id="agent-dashboard-responsive"
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                />
              </Button>

              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="Filter"
                />
              </Button>

              <Button
                color="primary"
                size="sm"
                onClick={() => this._onToggleImportModal(true)}
              >
                Import
              </Button>
              <Button
                className="floatingButton"
                color="primary"
                onClick={this._redirectToCreateClosing}
              >
                + Create
              </Button>
            </div>
          </div>

          {/* search filed for responsive will show below 767px */}
          <div className="mobileSearchFiled">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img
                    src={require("../../../assets/img/searchIcon.svg").default}
                    alt="searchIcon"
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="search"
                value={filters.search}
                onChange={(e) =>
                  this._filterOnChange("search", e.target.value, true)
                }
                placeholder="Search Signer, File"
              />
            </InputGroup>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.filterShowMobile}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer  responsiveFilter ${
              isClassAdded ? "show" : ""
            }`}
          >
            <div className="filterIcon">
              {/* <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
              /> */}
              <SvgIcons type={"filterIcon"} />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>
            <div className="filterWrapper">
              <FormGroup className="searchTable">
                <Label>Search</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <img
                        src={
                          require("../../../assets/img/searchIcon.svg").default
                        }
                        alt="searchIcon"
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    name="search"
                    value={filters.search}
                    onChange={(e) =>
                      this._filterOnChange("search", e.target.value, true)
                    }
                    placeholder="Search Signer, File"
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="dateRange">
                <Label>Date Range</Label>
                <DateRangePicker
                  // clearIcon={null}
                  className="dateRange"
                  format="MM-dd-y"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  // onChange={(dateRangeValue) =>
                  //   this._filterOnChange("appointmentDate", dateRangeValue)
                  // }
                  // value={filters.appointmentDate}
                  onChange={(dateRangeValue) =>
                    this._onChangeDatePicker(dateRangeValue)
                  }
                  value={dateRangeValue}
                />
              </FormGroup>
              <FormGroup>
                <Label>Status</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="status"
                    value={filters.status}
                    onChange={(e) =>
                      this._filterOnChange("status", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {agentClosingStatus.map((obj) => (
                      <option key={obj.value} value={obj.value}>
                        {obj.label}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
            </div>
            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>

          <div className="hideDesktop">
            <CustomCard
              isPageStartFromOne={true}
              pageNumber={tableConfig.pageNumber}
              tableData={agentClosingList}
              headerKeys={agentClosingCardHeaderKeys}
              bodyKeys={agentClosingCardBodyKeys}
              dataFormat={this._dataFormat}
              totalCount={agentClosingTotalCount}
              onPaginate={(pageNumber, pageSize) =>
                this._paginate(pageNumber, pageSize)
              }
              rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
              showTableLoading={loading.showTableLoading}
              cardHeaderFormat={this._cardHeaderFormat}
              cardDataFormat={this._cardDataFormat}
            />
          </div>
          <div className="hideMobile">
            <CustomTable
              striped
              isPageStartFromOne={true}
              pageNumber={tableConfig.pageNumber}
              tableData={agentClosingList}
              headerKeys={agentClosingHeaderKeys}
              dataFormat={this._dataFormat}
              totalCount={agentClosingTotalCount}
              onPaginate={(pageNumber, pageSize) =>
                this._paginate(pageNumber, pageSize)
              }
              rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
              showTableLoading={loading.showTableLoading}
              isHideForLessData
            />
          </div>

          {signingStatusModal.isOpen && (
            <SigningStatusModal
              isOpen={signingStatusModal.isOpen}
              data={signingStatusModal.data}
              status={signingStatusModal.status}
              userData={userData}
              updateClosingDetailsById={this._updateClosingDetailsById}
              resetDetails={() => this._getAllAgentClosing()}
              toggle={() => this._onToggleSigningStatusModal()}
            />
          )}

          {signerAvailabilityModal.isOpen && (
            <SignerAvailabilityAgentModal
              isOpen={signerAvailabilityModal.isOpen}
              data={signerAvailabilityModal.data}
              updateClosingDetailsById={this._updateClosingDetailsById}
              resetDetails={() => this._getAllAgentClosing()}
              toggle={() => this._onToggleSignerAvailabilityModal()}
            />
          )}
          <AgentDashboardimportModal
            isOpen={importModal.isOpen}
            draftClosingsList={draftClosingsList}
            draftTableConfig={draftTableConfig}
            draftClosingHeaderKeys={draftClosingHeaderKeys}
            draftClosingTotalCount={draftClosingTotalCount}
            toggle={() => this._onToggleImportModal()}
            resetData={() => this._getAgentDraftClosings()}
            paginateDraft={(pageNumber, pageSize) =>
              this._paginateDraft(pageNumber, pageSize)
            }
          />
          {/* {isOpen && (
            <ShowVideoModal
              isOpen={isOpen}
              role={role}
              toggle={() => this.setState({ isOpen: false })}
              userData={userData}
            />
          )} */}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

export default connect(mapStateToProps, null)(AgentDashboard);
