import React, { Component } from "react";
import {
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Label,
  FormGroup,
  Card,
  CardBody,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import { deepClone, errorHandler, showToast } from "../../../helper-methods";
import StandardFeeTab from "./StandardFeeTab";
import { getAllLoanTypes } from "../../../http/http-calls";

class StandardFeeSetting extends Component {
  state = {
    // formData: {
    // standardFee: {
    //   value: "",
    //   error: null,
    //   isDirty: false,
    // },

    standardFee: {
      RealEstateDocuments: {
        Refinance: {
          value: "",
          error: null,
          isDirty: false,
        },
        HomeEquity: {
          value: "",
          error: null,
          isDirty: false,
        },
        AttorneyPhone: {
          value: "",
          error: null,
          isDirty: false,
        },
        Purchase: {
          value: "",
          error: null,
          isDirty: false,
        },
        LoanMod: {
          value: "",
          error: null,
          isDirty: false,
        },
        ReverseMtg: {
          value: "",
          error: null,
          isDirty: false,
        },
        HybridESign: {
          value: "",
          error: null,
          isDirty: false,
        },
        DeedInLieu: {
          value: "",
          error: null,
          isDirty: false,
        },
        LoanApplication: {
          value: "",
          error: null,
          isDirty: false,
        },
        SellerPackage: {
          value: "",
          error: null,
          isDirty: false,
        },
        BuyerPackage: {
          value: "",
          error: null,
          isDirty: false,
        },
      },
      LegalGeneralDocuments: {
        "2PartyAgreement": {
          value: "",
          error: null,
          isDirty: false,
        },
        DivorcePapers: {
          value: "",
          error: null,
          isDirty: false,
        },
        CourtFiling: {
          value: "",
          error: null,
          isDirty: false,
        },
        SingleDoc: {
          value: "",
          error: null,
          isDirty: false,
        },
        PowerOfAttorney: {
          value: "",
          error: null,
          isDirty: false,
        },
      },
      EstatePlanning: {
        Wills: {
          value: "",
          error: null,
          isDirty: false,
        },
        CustodyGuardianship: {
          value: "",
          error: null,
          isDirty: false,
        },
        ExecutionerShips: {
          value: "",
          error: null,
          isDirty: false,
        },
        ProbatePetitions: {
          value: "",
          error: null,
          isDirty: false,
        },
      },
      Apostille: {
        Trust: {
          value: "",
          error: null,
          isDirty: false,
        },
      },
      // Other: {
      //   WitnessFee: {
      //     value: "",
      //     error: null,
      //     isDirty: false,
      //   },
      // },
    },
    Other: {
      WitnessFee: {
        value: "",
        error: null,
        isDirty: false,
      },
      // ForeignNationalSigner: {
      //   value: "222",
      //   error: null,
      //   isDirty: false,
      // },
    },
    // WitnessFee: {
    //   value: "",
    //   error: null,
    //   isDirty: false,
    // },
    // }
    activeTab: "1",
    loading: false,
    isEdit: false,
    // options1: [],
    // options2: [],
    // options3: [],
    // options4: [],
    // options5: [],
    loanTypes: {},
  };

  _toggleTab = (tab) => {
    const { activeTab } = deepClone(this.state);
    if (activeTab !== tab) this.setState({ activeTab: tab });
  };
  _setStandardFee = (data) => {
    const standardFees = data?.standardFees;
    const { standardFee, Other } = deepClone(this.state);

    standardFees?.forEach((each) => {
      if (each?.productCategory !== "Other") {
        standardFee[each?.productCategory][each?.productType].value =
          each?.productValue;
      }
    });

    Other.WitnessFee.value = data?.WitnessFee ? data?.WitnessFee : "";

    // formData["standardFee"].value = data.standardFee
    //   ? data.standardFee.toString()
    //   // : "";
    // this.setState({ formData });
    // standardFee["Refinance"].value = data?.standardFee?.Refinance || "";
    // standardFee["HomeEquity"].value = data?.standardFee?.HomeEquity || "";
    // standardFee["AttorneyPhone"].value = data?.standardFee?.AttorneyPhone || "";
    // standardFee["Purchase"].value = data?.standardFee?.Purchase || "";
    // standardFee["LoanMod"].value = data?.standardFee?.LoanMod || "";
    // standardFee["ReverseMtg"].value = data?.standardFee?.ReverseMtg || "";
    // standardFee["HybridESign"].value = data?.standardFee?.HybridESign || "";
    // standardFee["DeedInLieu"].value = data?.standardFee?.DeedInLieu || "";
    // standardFee["SingleDoc"].value = data?.standardFee?.SingleDoc || "";
    // standardFee["LoanApplication"].value =
    //   data?.standardFee?.LoanApplication || "";
    // standardFee["Other"].value = data?.standardFee?.Other || "";
    // standardFee["WitnessFee"].value = data?.standardFee?.WitnessFee || "";
    // standardFee["ForeignNationalSigner"].value =
    //   data?.standardFee?.ForeignNationalSigner || "";

    this.setState({ standardFee, Other });
  };

  componentDidMount = () => {
    const { agentDetails } = this.props;

    this._setStandardFee(agentDetails);

    this._fetchLoanTypes();
  };

  // _validateFormData = () => {
  //   return new Promise((resolve, reject) => {
  //     const { formData, standardFee } = this.state;

  //     let isFormValid = true;

  //     // Object.keys(formData).forEach((key) => {
  //     //   if (formData[key].isDirty) {
  //     //     switch (key) {
  //     //       case "standardFee": {
  //     //         if (formData[key].value && formData[key].value.trim().length) {
  //     //           if (
  //     //             isNaN(formData[key].value) ||
  //     //             Number(formData[key].value) < 1
  //     //           ) {
  //     //             formData[key].isDirty = true;
  //     //             formData[key].error = "*Invalid Fee";
  //     //             isFormValid = false;
  //     //           } else {
  //     //             formData[key].isDirty = false;
  //     //             formData[key].error = null;
  //     //           }
  //     //         } else {
  //     //           formData[key].isDirty = true;
  //     //           formData[key].error = "*Required";
  //     //           isFormValid = false;
  //     //         }
  //     //         break;
  //     //       }
  //     //       default: {
  //     //       }
  //     //     }
  //     //   }
  //     // });

  //     this.setState({ standardFee }, () => {
  //       resolve(isFormValid);
  //     });
  //   });
  // };

  // _onChangeStandardFee = (fieldName, value = "") => {
  //   const { formData } = deepClone(this.state);
  //   if (isNaN(value) || (value.includes(".") && value.split(".")[1].length > 2))
  //     return;
  //   formData[fieldName].value = value;
  //   formData[fieldName].isDirty = true;
  //   this.setState({ formData }, () => {
  //     this._validateFormData();
  //   });
  // };

  _fetchLoanTypes = async () => {
    try {
      const res = await getAllLoanTypes();
      console.log({ res });

      this.setState({ loanTypes: res?.loanType });
    } catch (err) {
      errorHandler(err);
      console.log({ err });
    }
  };

  _onChangeStandardFee = (key, value, category) => {
    console.log(key, value, category);
    if (
      isNaN(value) ||
      (value.includes(".") && value.split(".")[1].length > 2) ||
      value.length > 20 ||
      Number(value) < 0
    ) {
      showToast("Invalid Input", "error", 4000, true);
      return;
    }

    if (category !== "Other") {
      this.setState(
        {
          standardFee: {
            ...this.state.standardFee,
            [category]: {
              // ...this.state.standardFee[category][key],
              ...this.state.standardFee[category],
              [key]: {
                value: value,
                isDirty: true,
              },
            },
          },
        },
        () => console.log(this.state.standardFee)
      );
    } else {
      this.setState(
        {
          Other: {
            ...this.state.Other,
            [key]: {
              value: value,
              isDirty: true,
            },
          },
        },
        () => console.log(this.state)
      );
    }
  };

  // _markAllFormDataFieldDirty = () => {
  //   return new Promise((resolve, reject) => {
  //     const { formData } = this.state;
  //     Object.keys(formData).forEach((e) => {
  //       formData[e].isDirty = true;
  //     });
  //     this.setState({ formData }, () => resolve(true));
  //   });
  // };

  _updateStandardFee = async (e) => {
    try {
      if (e) e.preventDefault();

      // await this._markAllFormDataFieldDirty();

      // const isFormValid = await this._validateFormData();

      // if (isFormValid) {
      this.setState({ loading: true });

      const { standardFee, Other } = deepClone(this.state);

      // let payload = {
      //   //   // standardFee: Number(formData.standardFee.value.trim()),
      //   // standardFees: [{productCategory: }],
      //   // standardFee: {
      //   //   Refinance:
      //   //     standardFee.Refinance.value !== "" &&
      //   //     Number(standardFee.Refinance.value) > 0
      //   //       ? Number(standardFee.Refinance.value)
      //   //       : undefined,
      //   //   HomeEquity:
      //   //     standardFee.HomeEquity.value !== "" &&
      //   //     Number(standardFee.HomeEquity.value) > 0
      //   //       ? Number(standardFee.HomeEquity.value)
      //   //       : undefined,
      //   //   AttorneyPhone:
      //   //     standardFee.AttorneyPhone.value !== "" &&
      //   //     Number(standardFee.AttorneyPhone.value) > 0
      //   //       ? Number(standardFee.AttorneyPhone.value)
      //   //       : undefined,
      //   //   Purchase:
      //   //     standardFee.Purchase.value !== "" &&
      //   //     Number(standardFee.Purchase.value) > 0
      //   //       ? Number(standardFee.Purchase.value)
      //   //       : undefined,
      //   //   LoanMod:
      //   //     standardFee.LoanMod.value !== "" &&
      //   //     Number(standardFee.LoanMod.value) > 0
      //   //       ? Number(standardFee.LoanMod.value)
      //   //       : undefined,
      //   //   ReverseMtg:
      //   //     standardFee.ReverseMtg.value !== "" &&
      //   //     Number(standardFee.ReverseMtg.value) > 0
      //   //       ? Number(standardFee.ReverseMtg.value)
      //   //       : undefined,
      //   //   HybridESign:
      //   //     standardFee.HybridESign.value !== "" &&
      //   //     Number(standardFee.HybridESign.value) > 0
      //   //       ? Number(standardFee.HybridESign.value)
      //   //       : undefined,
      //   //   DeedInLieu:
      //   //     standardFee.DeedInLieu.value !== "" &&
      //   //     Number(standardFee.DeedInLieu.value) > 0
      //   //       ? Number(standardFee.DeedInLieu.value)
      //   //       : undefined,
      //   //   SingleDoc:
      //   //     standardFee.SingleDoc.value !== "" &&
      //   //     Number(standardFee.SingleDoc.value) > 0
      //   //       ? Number(standardFee.SingleDoc.value)
      //   //       : undefined,
      //   //   LoanApplication:
      //   //     standardFee.LoanApplication.value !== "" &&
      //   //     Number(standardFee.LoanApplication.value) > 0
      //   //       ? Number(standardFee.LoanApplication.value)
      //   //       : undefined,
      //   //   Other:
      //   //     standardFee.Other.value !== "" &&
      //   //     Number(standardFee.Other.value) > 0
      //   //       ? Number(standardFee.Other.value)
      //   //       : undefined,
      //   //   ForeignNationalSigner:
      //   //     standardFee.ForeignNationalSigner.value !== "" &&
      //   //     Number(standardFee.ForeignNationalSigner.value) > 0
      //   //       ? Number(standardFee.ForeignNationalSigner.value)
      //   //       : undefined,
      //   //   WitnessFee:
      //   //     standardFee.WitnessFee.value !== "" &&
      //   //     Number(standardFee.WitnessFee.value) > 0
      //   //       ? Number(standardFee.WitnessFee.value)
      //   //       : undefined,
      //   // },
      // };
      const payload = {
        standardFees: [],
      };

      console.log({ standardFee });

      Object.keys(standardFee).forEach((loanCategory) =>
        Object.keys(standardFee[loanCategory])?.forEach((loanType) => {
          // console.log(standardFee?.[loanCategory]?.[loanType]?.value);
          if (standardFee?.[loanCategory]?.[loanType]?.value) {
            // console.log(standardFee?.[loanCategory]?.loanType?.value);
            payload.standardFees.push({
              productCategory: loanCategory,
              productType: loanType,
              productValue: +standardFee?.[loanCategory]?.[loanType]?.value,
            });
          }
        })
      );

      if (Other?.WitnessFee?.value?.length) {
        payload["WitnessFee"] = +Other?.WitnessFee?.value;
      } else {
        payload["WitnessFee"] = "";
      }

      console.log(payload);

      await this.props.onSumbit(payload, "Standard Fee Updated");

      this.setState({ loading: false, isEdit: false });

      // }
    } catch (error) {
      errorHandler(error);
      this.setState({ loading: false });
    }
  };

  _toggleIsEdit = () => {
    this.setState({ isEdit: !this.state.isEdit }, () => {
      if (this.state.isEdit) {
        const { agentDetails } = this.props;
        // this._setStandardFee(agentDetails);
      }
    });
  };

  render() {
    const { loading, standardFee, isEdit, activeTab, loanTypes, Other } =
      deepClone(this.state);

    return (
      <div className="CardWrapper">
        <div className="CardTitle">
          <h2 className="sectionTtile">
            Standard Fee
            {!isEdit ? (
              <Button color="link" onClick={() => this._toggleIsEdit()}>
                <img
                  src={require("../../../assets/img/pencil.svg").default}
                  alt="edit"
                />
              </Button>
            ) : null}
          </h2>
        </div>

        <Card>
          <CardBody>
            <Nav tabs className="greyBg">
              <NavItem>
                <NavLink
                  className={activeTab === "1" ? "active" : ""}
                  onClick={() => this._toggleTab("1")}
                >
                  Real Estate Documents
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "2" ? "active" : ""}
                  onClick={() => this._toggleTab("2")}
                >
                  Legal/General Documents
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "3" ? "active" : ""}
                  onClick={() => this._toggleTab("3")}
                >
                  Estate Planning
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "4" ? "active" : ""}
                  onClick={() => this._toggleTab("4")}
                >
                  Apostille
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={activeTab === "5" ? "active" : ""}
                  onClick={() => this._toggleTab("5")}
                >
                  Others
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <StandardFeeTab
                  optionsArr={loanTypes["RealEstateDocuments"]}
                  isEdit={isEdit}
                  category={"RealEstateDocuments"}
                  onChangeFunc={(key, value, category) =>
                    this._onChangeStandardFee(key, value, category)
                  }
                  value={standardFee["RealEstateDocuments"]}
                />
              </TabPane>

              <TabPane tabId="2">
                <StandardFeeTab
                  optionsArr={loanTypes["LegalGeneralDocuments"]}
                  isEdit={isEdit}
                  category={"LegalGeneralDocuments"}
                  onChangeFunc={(key, value, category) =>
                    this._onChangeStandardFee(key, value, category)
                  }
                  value={standardFee["LegalGeneralDocuments"]}
                />
              </TabPane>

              <TabPane tabId="3">
                <StandardFeeTab
                  optionsArr={loanTypes["EstatePlanning"]}
                  isEdit={isEdit}
                  category={"EstatePlanning"}
                  onChangeFunc={(key, value, category) =>
                    this._onChangeStandardFee(key, value, category)
                  }
                  value={standardFee["EstatePlanning"]}
                />
              </TabPane>

              <TabPane tabId="4">
                <StandardFeeTab
                  optionsArr={loanTypes["Apostille"]}
                  isEdit={isEdit}
                  category={"Apostille"}
                  onChangeFunc={(key, value, category) =>
                    this._onChangeStandardFee(key, value, category)
                  }
                  value={standardFee["Apostille"]}
                />
              </TabPane>

              <TabPane tabId="5">
                <StandardFeeTab
                  optionsArr={["WitnessFee"]}
                  isEdit={isEdit}
                  category={"Other"}
                  onChangeFunc={(key, value, category) =>
                    this._onChangeStandardFee(key, value, category)
                  }
                  error={standardFee}
                  value={Other}
                />
              </TabPane>
            </TabContent>
            {/* <div className="standardFeeWrap">
              {Object.keys(standardFee).length &&
                Object.entries(standardFee).map(([key, value], index) => (
                  <React.Fragment key={index}>
                    <FormGroup className="floatingLabel withInputGroup">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <img
                              src={
                                require("../../../assets/img/dollar.svg")
                                  .default
                              }
                              height={14}
                              alt="doller"
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          name="standardFee"
                          onChange={(e) =>
                            this._onChangeStandardFee(key, e.target.value)
                          }
                          value={value.value}
                          placeholder=" "
                          disabled={!isEdit ? true : false}
                        />
                        <Label>
                          {key
                            .replace(
                              /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g,
                              "$1$4 $2$3$5"
                            )
                            .charAt(0)
                            .toUpperCase() +
                            key
                              .replace(
                                /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g,
                                "$1$4 $2$3$5"
                              )
                              .slice(1)}
                        </Label>
                      </InputGroup>
                      {key.error && (
                        <div className="validation-error">{key.error}</div>
                      )}
                    </FormGroup>
                  </React.Fragment>
                ))}
            </div> */}

            {isEdit ? (
              <div className="text-center">
                <Button
                  size="md"
                  color="primary"
                  disabled={loading}
                  onClick={this._updateStandardFee}
                >
                  {loading ? (
                    <i className="fa fa-spinner fa-spin mr-2" />
                  ) : null}{" "}
                  Update
                </Button>
              </div>
            ) : null}
          </CardBody>
        </Card>
      </div>
    );
  }
}

export default StandardFeeSetting;
