import React, { Component, createRef } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Input,
  FormGroup,
  CustomInput,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  showToast,
  formatTime,
  formatPhoneNumber,
  formatDateAndTime,
  deepClone,
  formatDateMoment,
  formatDateAsPerTimeZOne,
  formatDateAsPerTimeZoneWithDateAndTime,
  errorHandler,
  openGoogleMapOnNewTab,
  openAppleMapOnNewTab,
  getTimeZoneAbbr,
  formatDateOnlyAsPerTimeZone,
} from "../../../../helper-methods";
import {
  createChatLog,
  getClosingDetailsById,
} from "../../../../http/http-calls";
import { capitalize } from "@material-ui/core";
import RegularAppointmentScheduler from "../RegularAppointmentScheduler";
import AppointmentScheduler from "../AppointmentScheduler";
import { DateTimeShowWithClosingDetails } from "../Date-time-show";

class SignerAvailabilityAgentModal extends Component {
  constructor(props) {
    super(props);
    this.appointmentSchedulerRef = createRef();
    this.regularSchedulerRef = createRef();
  }
  state = {
    closingDetails: null,
    mapType: "google",
    appointmentNotes: "",
    appointmentStatus: "Confirmed",
    time: {
      value: "",
      error: null,
      isDirty: false,
    },
    loading: false,
    appointmentSchedulerData: {
      appointmentDate: null,
      endAppointmentDate: null,
      tBD: false,
      isRangeDated: false,
    },
  };

  _resetModalState = () => {
    this.setState({
      closingDetails: null,
      appointmentNotes: "",
      appointmentStatus: "Confirmed",
      time: {
        value: "",
        error: null,
        isDirty: false,
      },
      loading: false,
    });
  };

  _closeModal = () => {
    this._resetModalState();
    this.props.toggle();
  };

  _getClosingDetailsById = (id) => {
    this.setState({ loading: true });

    getClosingDetailsById(id)
      .then((res) => {
        this.setState({
          closingDetails: res?.closing || {},
          loading: false,
          time: {
            value: res?.closing?.appointmentDate
              ? formatDateAsPerTimeZoneWithDateAndTime(
                  res?.closing?.appointmentDate,
                  res?.closing?.closingAddress?.timeZone
                )
              : "",
            error: null,
            isDirty: false,
          },
          appointmentStatus: res?.closing?.appointmentStatus
            ? res?.closing.appointmentStatus
            : "Confirmed",
          appointmentSchedulerData: {
            appointmentDate: res?.closing?.appointmentDate,
            endAppointmentDate: null,
            tBD: res?.closing?.tBD,
            isRangeDated: res?.closing?.isRangeDated,
          },
        });
      })
      .catch((error) => {
        errorHandler(error);
        this._closeModal();
      });
  };

  // componentDidUpdate = (prevProps, prevState) => {
  //   const { isOpen, data } = this.props;
  //   if (isOpen && data && prevProps.isOpen !== isOpen) {
  //     let Id = data?.id?.includes("guestuser")
  //       ? data?.id?.replace("&guestuser", "")
  //       : data?.id;
  //     this._getClosingDetailsById(Id);
  //   }
  // };

  componentDidMount() {
    const { isOpen, data } = this.props;
    if (isOpen && data) {
      let Id = data.id.includes("guestuser")
        ? data.id.replace("&guestuser", "")
        : data.id;
      this._getClosingDetailsById(Id);
    }
  }

  _onChangeAppointmentStatus = (appointmentStatus = "Confirmed") => {
    this.setState({ appointmentStatus });
  };

  _onChangeAppointmentTime = (value = "") => {
    const { time } = deepClone(this.state);
    time["value"] = value ? new Date(value).toISOString() : "";
    time["isDirty"] = true;
    this.setState({ time });
  };

  _updateClosingDetailsById = async (id, data) => {
    try {
      this.setState({ loading: true });

      await this.props.updateClosingDetailsById(id, data);
      showToast("Status Updated", "success");
      this.props.resetDetails();
      this._closeModal();
    } catch (error) {
      errorHandler(error);
      this.setState({ loading: false });
    }
  };

  _confirmStatus = async () => {
    const { closingDetails, appointmentStatus, time, appointmentNotes } =
      deepClone(this.state);

    let payload = {};

    if (appointmentStatus !== "Pending") {
      if (appointmentStatus === "Confirmed") {
        const regularSchedulerResponse =
          await this.regularSchedulerRef.current.getAppointmentSchedulerData();
        if (!regularSchedulerResponse?.isValid) {
          return;
        }
        payload = {
          ...regularSchedulerResponse.appointmentSchedulerData,
        };
        payload["appointmentStatus"] = "Confirmed";
        payload["appointmentNotes"] = appointmentNotes ? appointmentNotes : "";
        payload["leftMessage"] = false;

        if (time.value && time.isDirty) {
          payload["appointmentDate"] = formatDateMoment(time.value);
        }
      } else if (appointmentStatus === "Rescheduled") {
        payload["leftMessage"] = true;
        payload["appointmentNotes"] = appointmentNotes
          ? appointmentNotes
          : undefined;
      } else if (appointmentStatus === "ChangeDateTime") {
        const appointmentSchedulerResponse =
          await this.appointmentSchedulerRef.current.getAppointmentSchedulerData();
        if (!appointmentSchedulerResponse?.isValid) {
          return;
        }
        payload = {
          ...appointmentSchedulerResponse.appointmentSchedulerData,
        };
        payload["appointmentStatus"] = "Rescheduled";
        // if (time.value && time.isDirty) {
        //   payload["appointmentDate"] = formatDateMoment(time.value);
        // }
      }

      this._updateClosingDetailsById(closingDetails.id, payload);
      if (payload?.appointmentNotes?.trim()?.length) {
        this._sendComment();
      }
    }
  };

  _onChangeFormField = (type, value) => {
    this.setState({ [type]: value });
  };

  _sendComment = async (comment) => {
    const { closingDetails, appointmentStatus, time, appointmentNotes } =
      deepClone(this.state);

    try {
      if (!closingDetails?._id) {
        showToast("Closing not found", "error");
        return;
      }

      let commentText;
      const payload = {
        closingId: closingDetails?._id,
        isVisibleAgent: true,
      };
      if (
        appointmentStatus === "Rescheduled" ||
        appointmentStatus === "Confirmed"
      ) {
        commentText =
          appointmentStatus === "Rescheduled"
            ? "Agent changed the appointment status to left a message \n "
            : "Agent changed the appointment status to confirmed. \n ";
      }
      if (appointmentStatus === "Confirmed" && time.value) {
        closingDetails?._signingCompany
          ? (commentText += `Revised Time: ${formatTime(time.value)} \n`)
          : (commentText += `Revised Date And Time:${formatDateAndTime(
              time.value
            )} \n`);
      }
      if (appointmentNotes.trim().length > 0) {
        commentText += ` Appointment Note:${appointmentNotes}`;
      }
      payload["text"] = commentText;
      if (commentText) {
        await createChatLog(payload);
        if (window.location.pathname !== "/agent/dashboard") {
          this.props.refeshChatLog();
        }
        showToast(`Comment has been added`, "success");
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  render() {
    const {
      closingDetails,
      time,
      appointmentStatus,
      loading,
      appointmentNotes,
      mapType,
    } = this.state;
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this._closeModal}
        scrollable
        centered
      >
        <ModalHeader toggle={this._closeModal}>Confirm Signing</ModalHeader>
        <ModalBody>
          <div className="signingWrapper">
            {closingDetails?._borrower?.map((each) => (
              <div className="signingUser">
                <div className="phoneIcon">
                  <i className="fa fa-phone" />
                </div>
                <div className="content">
                  <span>{capitalize(each?.name?.full)}</span>
                  <a
                    href={`tel:${formatPhoneNumber(
                      each?.phone?.home || each?.phone?.work
                    )}`}
                  >
                    {" "}
                    {formatPhoneNumber(each?.phone?.home || each?.phone?.work)}
                  </a>
                </div>
              </div>
            ))}
          </div>

          {closingDetails ? (
            <>
              <div className="SigningAvailability">
                <p>
                  Please call to confirm their availability for this signing
                  appointment and location:
                  <strong>
                    {" "}
                    {closingDetails.closingAddress &&
                    closingDetails.closingAddress ? (
                      <>
                        {closingDetails.closingAddress.line1
                          ? `${closingDetails.closingAddress.line1}, `
                          : ""}
                        {closingDetails.closingAddress.line2
                          ? `${closingDetails.closingAddress.line2}, `
                          : ""}
                        {closingDetails.closingAddress.city
                          ? `${closingDetails.closingAddress.city}, `
                          : ""}
                        {closingDetails.closingAddress.state
                          ? `${closingDetails.closingAddress.state}, `
                          : ""}
                        {closingDetails.closingAddress.zip
                          ? `${closingDetails.closingAddress.zip}`
                          : ""}
                      </>
                    ) : (
                      "N/A"
                    )}
                  </strong>
                </p>
              </div>

              <div className="scheduledTime">
                <Label>Scheduled Appointment:</Label>
                <h6>
                  {/* {closingDetails.appointmentDate
                    ? //  formatDateAndTime(closingDetails.appointmentDate)
                      formatDateAsPerTimeZOne(
                        closingDetails.appointmentDate,
                        closingDetails.closingAddress.timeZone
                      )
                    : "N/A"} */}
                  <DateTimeShowWithClosingDetails
                    closingDetails={closingDetails}
                  />
                  {getTimeZoneAbbr(
                    closingDetails?.appointmentDate,
                    closingDetails?.closingAddress?.timeZone
                  )}
                </h6>
              </div>

              <div className="signingAppointment">
                <CustomInput
                  id="appointmentStatus_Confirmed"
                  name="appointmentStatus"
                  value="Confirmed"
                  checked={appointmentStatus === "Confirmed"}
                  onChange={(event) =>
                    this._onChangeAppointmentStatus("Confirmed")
                  }
                  label={` Confirm Appointment`}
                  type="radio"
                  className="mr-3"
                />

                <CustomInput
                  id="appointmentStatus_Rescheduled"
                  name="appointmentStatus"
                  value="Rescheduled"
                  checked={appointmentStatus === "Rescheduled"}
                  onChange={(event) =>
                    this._onChangeAppointmentStatus("Rescheduled")
                  }
                  label="Left A Message"
                  type="radio"
                />
                {/* {closingDetails.isCreatedForAgent && (
                  <CustomInput
                    id="appointmentStatus_ChangeDateTime"
                    name="appointmentStatus"
                    value="Rescheduled"
                    checked={appointmentStatus === "ChangeDateTime"}
                    onChange={(event) =>
                      this._onChangeAppointmentStatus("ChangeDateTime")
                    }
                    label="Change Appointment Date &amp; Time"
                    type="radio"
                  />
                )} */}
              </div>

              {/* Show the below div only when user selects the "Signer is unavailable" option above */}
              {/* {((appointmentStatus === "Confirmed" &&
                !closingDetails.isCreatedForAgent) ||
                appointmentStatus === "ChangeDateTime") && (
                <>
                  <FormGroup
                    className={`floatingLabel ${
                      time.value || this.state.isOpenReactDatetime
                        ? "valueAdded"
                        : ""
                    }`}
                  >
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: " ",
                        value: !closingDetails?.isCreatedForAgent
                          ? formatTime(time.value)
                          : formatDateAndTime(time.value),
                      }}
                      onOpen={() =>
                        this.setState({ isOpenReactDatetime: true })
                      }
                      onClose={() =>
                        this.setState({ isOpenReactDatetime: false })
                      }
                      value={time.value ? new Date(time.value) : ""}
                      onChange={(e) => this._onChangeAppointmentTime(e._d)}
                      dateFormat={
                        !closingDetails?.isCreatedForAgent ? false : true
                      }
                      timeFormat={true}
                      timeConstraints={{ minutes: { step: 15 } }}
                    />
                    <Label>Revised Time</Label>
                  </FormGroup>
                </>
              )} */}

              {appointmentStatus === "Confirmed" ? (
                <>
                  <RegularAppointmentScheduler
                    ref={this.regularSchedulerRef}
                    isDateFormat={false}
                    initialViewMode={"time"}
                    data={this.state.appointmentSchedulerData}
                    timezone={closingDetails?.closingAddress?.timeZone}
                  />
                </>
              ) : null}

              <div className="mb-3 mt-2">
                <FormGroup className="floatingLabel">
                  <Input
                    placeholder=" "
                    type="textarea"
                    rows="3"
                    name="appointmentNotes"
                    value={appointmentNotes}
                    onChange={(e) =>
                      this._onChangeFormField(
                        "appointmentNotes",
                        e.target.value
                      )
                    }
                  />
                  <Label>Appointment Note</Label>
                </FormGroup>
              </div>

              <div className="signerLocation">
                {closingDetails.closingAddress ? (
                  <>
                    <div className="locationDetails">
                      <Label>Location</Label>
                      <h6
                        onClick={() =>
                          mapType === "apple"
                            ? openAppleMapOnNewTab(
                                closingDetails.closingAddress
                              )
                            : openGoogleMapOnNewTab(
                                closingDetails.closingAddress
                              )
                        }
                      >
                        <img
                          src={
                            require("../../../../assets/img/map-marker.svg")
                              .default
                          }
                          alt="location"
                        />{" "}
                        {closingDetails.closingAddress.line1
                          ? `${closingDetails.closingAddress.line1}, `
                          : ""}
                        {closingDetails.closingAddress.line2
                          ? `${closingDetails.closingAddress.line2}, `
                          : ""}
                        {closingDetails.closingAddress.city
                          ? `${closingDetails.closingAddress.city}, `
                          : ""}
                        {closingDetails.closingAddress.state
                          ? `${closingDetails.closingAddress.state}, `
                          : ""}
                        {closingDetails.closingAddress.zip
                          ? `${closingDetails.closingAddress.zip}`
                          : ""}
                      </h6>
                    </div>
                    {/* <Button
                      color="link"
                      style={{
                        padding: "0 6px",
                        fontSize: 16,
                        verticalAlign: 0,
                      }}
                      onClick={() =>
                        mapType === "apple"
                          ? openAppleMapOnNewTab(closingDetails.closingAddress)
                          : openGoogleMapOnNewTab(closingDetails.closingAddress)
                      }>
                      <i className="fa fa-map-marker" />
                    </Button> */}

                    <div className="locationLinks">
                      <div className="form-check-radio">
                        <Label check>
                          <Input
                            type="radio"
                            name="mapType"
                            value="google"
                            checked={mapType === "google"}
                            onChange={() =>
                              this.setState({ mapType: "google" })
                            }
                          />
                          <div className="maps">
                            <img
                              src={
                                require("../../../../assets/img/Google_Maps_Logo_2020.svg")
                                  .default
                              }
                              alt="google map"
                            />
                          </div>
                        </Label>
                      </div>
                      <div className="form-check-radio">
                        <Label check>
                          <Input
                            type="radio"
                            name="mapType"
                            value="apple"
                            checked={mapType === "apple"}
                            onChange={() => this.setState({ mapType: "apple" })}
                          />
                          <div className="maps">
                            <img
                              src={
                                require("../../../../assets/img/Apple_Maps_(WatchOS).svg")
                                  .default
                              }
                              alt="apple map"
                            />
                          </div>
                        </Label>
                      </div>
                    </div>
                  </>
                ) : (
                  "N/A"
                )}
              </div>
            </>
          ) : (
            <div
              className="text-center mb-3"
              style={{ fontSize: "16px", fontWeight: 500 }}
            >
              {loading ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                "Closing Not found"
              )}
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button size="lg" color="primary" outline onClick={this._closeModal}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={loading || appointmentStatus === "Pending"}
            onClick={this._confirmStatus}
          >
            {loading ? <i className="fa fa-spinner fa-spin" /> : null} Confirm
            Status
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default SignerAvailabilityAgentModal;
