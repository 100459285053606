import React, { Component } from "react";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import { deepClone, errorHandler, showToast } from "../../../helper-methods";
import { createAgentLeaves } from "../../../http/http-calls";

class NewLeaveModal extends Component {
  state = {
    formData: {
      dateRangeValue: {
        value: null,
        error: null,
        isDirty: false,
      },
      description: {
        value: "",
        error: null,
        isDirty: false,
      },
    },
    loading: false,
  };

  _resetStateModal = () => {
    this.setState({
      formData: {
        dateRangeValue: {
          value: null,
          error: null,
          isDirty: false,
        },
        description: {
          value: "",
          error: null,
          isDirty: false,
        },
      },
      loading: false,
    });
  };

  _closeModal = () => {
    this._resetStateModal();
    this.props.toggle();
  };

  _validateFormData = () => {
    return new Promise((resolve, reject) => {
      const { formData } = this.state;

      let isFormValid = true;

      Object.keys(formData).forEach((key) => {
        if (formData[key].isDirty) {
          switch (key) {
            case "description": {
              if (formData[key].value && formData[key].value.trim().length) {
                formData[key].isDirty = false;
                formData[key].error = null;
              } else {
                formData[key].isDirty = true;
                formData[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "dateRangeValue": {
              if (formData[key].value && formData[key].value.length) {
                formData[key].isDirty = false;
                formData[key].error = null;
              } else {
                formData[key].isDirty = true;
                formData[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default: {
            }
          }
        }
      });

      this.setState({ formData }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeFormData = (fieldName, value) => {
    const { formData } = deepClone(this.state);
    formData[fieldName].value = value;
    formData[fieldName].isDirty = true;
    this.setState({ formData }, () => {
      // Validation
      this._validateFormData();
    });
  };

  _markAllFormDataFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formData } = this.state;
      Object.keys(formData).forEach((e) => {
        formData[e].isDirty = true;
      });
      this.setState({ formData }, () => resolve(true));
    });
  };

  _createAgentLeaves = async (e) => {
    try {
      if (e) e.preventDefault();

      await this._markAllFormDataFieldDirty();

      const isFormValid = await this._validateFormData();

      if (isFormValid) {
        this.setState({ loading: true });

        const { formData } = deepClone(this.state);

        let from = new Date(formData.dateRangeValue.value[0]).toISOString();

        let to = new Date(formData.dateRangeValue.value[1]).toISOString();

        let payload = {
          from,
          to,
          description: formData.description.value.trim(),
        };

        createAgentLeaves(payload)
          .then((res) => {
            showToast("Leave added", "success");
            this._closeModal();
            this.props.resetDetails();
          })
          .catch((error) => {
            errorHandler(error);
            this.setState({ loading: false });
          });
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  render() {
    const { formData, loading } = deepClone(this.state);

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this._closeModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={this._closeModal}>New Leave</ModalHeader>
        <ModalBody>
          <FormGroup>
            <DateRangePicker
              minDate={new Date()}
              format="MM-dd-y"
              dayPlaceholder="dd"
              monthPlaceholder="mm"
              yearPlaceholder="yyyy"
              disabled={loading}
              onChange={(dateRangeValue) =>
                this._onChangeFormData("dateRangeValue", dateRangeValue)
              }
              value={formData.dateRangeValue.value}
            />
            {formData.dateRangeValue.error && (
              <div className="validation-error">
                {formData.dateRangeValue.error}
              </div>
            )}
          </FormGroup>

          <FormGroup className="floatingLabel">
            <Input
              type="textarea"
              rows="3"
              value={formData.description.value}
              name="description"
              onChange={(event) =>
                this._onChangeFormData("description", event.target.value)
              }
              placeholder=" "
            />
            <Label>Description</Label>
            {formData.description.error && (
              <div className="validation-error">
                {formData.description.error}
              </div>
            )}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={this._closeModal}
            style={{ width: 160 }}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={loading}
            onClick={this._createAgentLeaves}
            style={{ width: 160 }}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null} Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default NewLeaveModal;
