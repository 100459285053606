import React, { useEffect, useState } from "react";
import {
  Button,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  errorHandler,
  getPhoneNumberFromBrackets,
} from "../../../../../helper-methods";
import { getClientTeamMembers } from "../../../../../http/http-calls";
import CustomCard from "../../CustomCard";
import CustomTable from "../../CustomTable";

const clientMembersHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "name", label: "Name", noSort: true },
  { id: "email", label: "Email", noSort: true },
  { id: "phone", label: "Phone", noSort: true },
];

const teamMembersCardHeaderKeys = [
  { id: "id", label: "id" },
  { id: "name", label: "Name" },
];

export const ClientMembers = ({ isOpen, data, toggle }) => {
  console.log({ data });

  const [teamMembers, setTeamMembers] = useState({
    data: [],
    totalCount: null,
  });
  const [loading, setLoading] = useState({
    dataLoading: false,
  });
  const [tableConfig, setTableConfig] = useState({
    skip: 0,
    limit: 10,
    pageNumber: 1,
  });

  const _manageLoadingState = (key = "", value = false) => {
    setLoading((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const _closeModal = () => {
    // this._setModalState();
    toggle();
  };

  const _getClientTeamMembers = async (newTableConfig) => {
    try {
      _manageLoadingState("dataLoading", true);

      const payload = {
        ...newTableConfig,
        clientId: data?.id,
      };

      const res = await getClientTeamMembers(payload);

      setTeamMembers({ data: res?.users || [], totalCount: res?.totalCount });
      _manageLoadingState("dataLoading", false);
    } catch (error) {
      errorHandler(error);
      _manageLoadingState("dataLoading", false);
    }
  };

  const _paginate = (pageNumber = 1, pageSize = 10) => {
    const newTableConfig = { ...tableConfig };

    newTableConfig["skip"] = (pageNumber - 1) * pageSize;
    newTableConfig["limit"] = pageSize;
    newTableConfig["pageNumber"] = pageNumber;

    setTableConfig(newTableConfig);
    _getClientTeamMembers(newTableConfig);
  };

  useEffect(() => {
    _getClientTeamMembers(tableConfig);
  }, []);

  const _dataFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return row?.name?.full ? <p>{row?.name?.full}</p> : "N/A";
      }

      case "email": {
        return row?.email ? <p>{row?.email}</p> : "N/A";
      }

      case "phone": {
        return row?.phone ? (
          <p>{getPhoneNumberFromBrackets(row?.phone)}</p>
        ) : (
          "N/A"
        );
      }

      default: {
        return cell;
      }
    }
  };

  const _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return row ? <span>{row?.name?.full || "N/A"}</span> : "N/A";
      }

      default: {
        return cell;
      }
    }
  };

  const _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Email</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row?.email ? (
                  <>
                    <div
                      style={{
                        minWidth: 80,
                        whiteSpace: "normal",
                      }}
                    >
                      {row?.email || "N/A"}
                    </div>
                  </>
                ) : (
                  "N/A"
                )}
              </span>
            </div>
          </div>

          <div className="dataWrap">
            <Label>Phone</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row?.phone ? (
                  <>
                    <div
                      style={{
                        minWidth: 80,
                        whiteSpace: "normal",
                      }}
                    >
                      {row?.phone || "N/A"}
                    </div>
                  </>
                ) : (
                  "N/A"
                )}
              </span>
            </div>
          </div>
        </li>
      </ul>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalHeader toggle={() => _closeModal()}>Client Members</ModalHeader>
      <ModalBody>
        <div className="hideMobile">
          {clientMembersHeaderKeys && clientMembersHeaderKeys?.length && (
            <CustomTable
              striped
              isPageStartFromOne={true}
              pageNumber={tableConfig?.pageNumber}
              pageSize={tableConfig?.limit}
              tableData={teamMembers?.data}
              headerKeys={clientMembersHeaderKeys}
              dataFormat={_dataFormat}
              totalCount={teamMembers?.totalCount}
              rowSelection={false}
              onPaginate={(pageNumber, pageSize) =>
                _paginate(pageNumber, pageSize)
              }
              showTableLoading={loading?.dataLoading}
            />
          )}
        </div>

        <div className="hideDesktop">
          <CustomCard
            columnSizeMD={12}
            isPageStartFromOne={true}
            pageNumber={tableConfig?.pageNumber}
            tableData={teamMembers?.data}
            dataFormat={_dataFormat}
            headerKeys={teamMembersCardHeaderKeys}
            totalCount={teamMembers?.totalCount}
            onPaginate={(pageNumber, pageSize) =>
              _paginate(pageNumber, pageSize)
            }
            showTableLoading={loading?.dataLoading}
            cardHeaderFormat={_cardHeaderFormat}
            cardDataFormat={_cardDataFormat}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline onClick={() => _closeModal()}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
