import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  CustomInput,
  Label,
  Row,
} from "reactstrap";
import TextEditor from "../../private/components/TextEdittor";
import { errorHandler, openUrlOnNewTab } from "../../../helper-methods";
import {
  createPaymentLink,
  fetchInvoiceDetailsById,
} from "../../../http/http-calls";

const PublicInvoicePayment = () => {
  const [invoiceData, setInvoiceData] = useState(null);
  const { id } = useParams();

  useEffect(() => {
    _fetchInvoiceDetailsById();
    // console.log("first",this.props)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _fetchInvoiceDetailsById = async () => {
    try {
      let invoiceId = id;
      let response = await fetchInvoiceDetailsById(invoiceId);

      setInvoiceData(response?.invoice);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _createLink = async () => {
    try {
      let payload = {
        invoiceId: id,
        totalCheckAmount: Number((invoiceData?.amount + invoiceData?.amount * 0.05).toFixed(2)),
      };
      let link = await createPaymentLink(payload);
      // console.log("res", link);
      window.open(link.paymentLink, "_self");
      // showToast(`Teupdated" : "added"} successfully`, "success");
      return true;
    } catch (err) {
      errorHandler(err);
    }
  };

  return (
    <>
      <Row className="justify-content-center p-4">
        <Col md={6}>
          <div className="CardWrapper">
            <Card>
              <CardHeader>
                {/* Invoice Card Number */}
                Payment for invoice #{invoiceData?.invoiceUniqueNumber||"N/A"}
              </CardHeader>
              <CardBody>
                <ul className="payCard">
                  <li>
                    <span>Invoice fee :</span>
                    <span>${invoiceData?.amount}</span>
                  </li>
                  <li>
                    <span>Processing fee :</span>
                    <span>${(invoiceData?.amount * 0.05).toFixed(2)}</span>
                  </li>
                  <li>
                    <span>Total fee :</span>
                    <span>
                      ${(invoiceData?.amount + invoiceData?.amount * 0.05).toFixed(2)}
                    </span>
                  </li>
                </ul>
              </CardBody>
            </Card>
            <Button
              color="primary"
              className="w-100 d-block"
              type="button"
              onClick={() => _createLink()}
              disabled={
                invoiceData?.amount === 0 || invoiceData?.amount === undefined
              }
            >
              Pay
            </Button>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default PublicInvoicePayment;
