import React from "react";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroupItem,
  ListGroup,
  CustomInput,
  UncontrolledTooltip,
  CardBody,
  Card,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import { connect } from "react-redux";
import {
  createClientClosing,
  getAllSigningCompanyForClient,
  getAllLenderClients,
  getClientSettings,
  getClosingDetailsById,
  getRonPlatformTypes,
  getCompanyStandardFee,
  ClientGetSigningCompanyCustomOrderFormId,
  clientGetListTeamsOfClient,
  FileNumberDuplicateCheck,
  FileNumberDuplicateMatchCheck,
} from "../../../http/http-calls";
import {
  deepClone,
  showToast,
  formatDateMoment,
  formatDateAndTime,
  errorHandler,
  capitalize,
  formatDate,
  formatOnlyDateMoment,
  formatDateAsPerTimeZoneWithDateAndTime,
  getPhoneNumberFromBrackets,
  timezoneList,
} from "../../../helper-methods";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { RegexConfig } from "../../../config/RegexConfig";
import usaStates from "../../../config/usa_states_titlecase";
import {
  googlePlaceSearch,
  googlePlaceDetails,
} from "../../../helper-methods/googleService";
import TextEditor from "../components/TextEdittor";
import Chips from "react-chips";
import { LanguageList, loanTypeConfig } from "../../../config";
import AddOrderClient from "../components/AddOrderClient";
import FileChecked from "../components/FileChecked";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import ProductType from "../components/ProductType";
import Select from "react-select";
import FileNumberDuplicateModal from "../components/FileNumberDuplicateModal";
const animatedComponents = makeAnimated();

export const chipTheme = {
  chip: {
    padding: 5,
    background: "red",
    margin: "2.5px",
    borderRadius: 3,
    cursor: "default",
  },
  chipSelected: {
    background: "#888",
  },
  chipRemove: {
    fontWeight: "bold",
    cursor: "pointer",
    ":hover": {
      color: "red",
    },
  },
};

class CreateClosing extends React.Component {
  state = {
    formFields: {
      borrower: [
        {
          borrowerName: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          email: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          workPhone: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          alternatePhone: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          isForeignNational: {
            value: false,
            isDirty: false,
            isValidate: true,
          },
          language: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          languageTypeOther: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          timeZone: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
        },
      ],
      // borrowerName: {
      //   value: "",
      //   isDirty: false,
      //   isValidate: true,
      // },
      additionalSigner: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      // email: {
      //   value: "",
      //   isDirty: false,
      //   isValidate: true,
      // },
      // phoneHomeDialCode: {
      //   value: "+1",
      //   error: null,
      //   isDirty: false,
      //   isValidate: false,
      // },
      // phoneWorkDialCode: {
      //   value: "+1",
      //   error: null,
      //   isDirty: false,
      //   isValidate: false,
      // },
      // phoneMobileDialCode: {
      //   value: "+1",
      //   error: null,
      //   isDirty: false,
      //   isValidate: false,
      // },
      // homePhone: {
      //   value: "",
      //   isDirty: false,
      //   isValidate: true,
      // },
      // workPhone: {
      //   value: "",
      //   isDirty: false,
      //   isValidate: true,
      // },
      // phone: {
      //   value: "",
      //   isDirty: false,
      //   isValidate: true,
      // },
      borrowerSearch: {
        value: "",
        placeId: null,
        isDirty: false,
        isValidate: true,
      },
      closingSearch: {
        value: "",
        placeId: null,
        isDirty: false,
        isValidate: true,
      },
      line1: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      line2: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      city: {
        value: "",
        isValidate: true,
        isDirty: false,
      },
      state: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      zip: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      loanOfficer: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      fileNumber: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      titleCompany: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      lender: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      personOfContactName: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      personOfContactEmail: {
        value: [],
        isDirty: false,
        isValidate: true,
      },
      signingType: {
        value: "Mobile",
        isDirty: false,
        isValidate: true,
      },
      ronPlatformType: {
        value: [],
        isDirty: false,
        isValidate: true,
      },
      witnessCount: {
        value: 0,
        isDirty: false,
        isValidate: true,
      },
      loanType: {
        value: [],
        isDirty: false,
        isValidate: true,
      },
      loanTypeOther: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      loanCategories: {
        value: [],
        error: null,
        isDirty: false,
        isValidate: false,
      },
      signingCompanyId: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      signingCompanyFee: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      lenderClientId: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      clientTeams: {
        value: "",
        isValidate: true,
        isDirty: false,
      },
      appointmentDate: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      closingAddressLine1: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      closingAddressLine2: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      closingAddressCity: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      closingAddressState: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      closingAddressZip: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      closingAddressTimeZone: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      files: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      notes: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      instructionId: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      instructionSignerData: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      instructions: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      internalNotes: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      companyNotes: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      isScanBackNeeded: {
        value: false,
        isDirty: false,
        isValidate: false,
      },
    },
    addClientModalData: {
      isOpen: false,
      data: null,
    },
    pdfFile: null,
    loading: {
      fileUploadLoading: false,
      submitLoading: false,
    },
    show: null,
    searchResult: [],
    loanType: [],
    isBorrowerAddressSame: false,
    isAppointmentAddressSame: false,
    isBorrowerSearch: true,
    isAppointmentSearch: true,
    inputLoanType: false,
    signingCompanies: [],
    lenderClients: [],
    clientTeamsList: [],
    signingInstruction: [],
    tBD: false, // Time to be determined
    isOpenAddInstruction: false,
    closingDetails: {},
    fileChecked: {
      isOpen: false,
      data: null,
    },
    FileNumberUniqueChecked: {
      isOpen: false,
    },
    textInstruction: "",
    ronPlatformTypeOptions: {},
    companyStandardFee: [],
    isChecked: false,
    questions: [],
    existingFileNos: null,
  };

  handleCheckboxChange = () => {
    this.setState({ isChecked: !this.state.isChecked });
  };
  _resetForm = () => {
    const { formFields } = deepClone(this.state);
    Object.keys(formFields).forEach((field) => {
      if (field !== "personOfContactEmail" && field !== "loanType") {
        formFields[field].value = "";
      } else {
        formFields[field].value = [];
      }
      formFields[field].isDirty = false;
      formFields[field].isValidate = true;
      if (formFields[field].placeId) {
        formFields[field].placeId = null;
      }
    });
    this.setState({ formFields });
  };

  _getClientSettings = () => {
    getClientSettings()
      .then((res) => {
        const { formFields } = this.state;
        if (res?.clientSetting?.signingInstruction?.length) {
          const defaultInstruction = res.clientSetting?.signingInstruction.find(
            (each) => each.isDefault
          );

          formFields.instructionId.value = defaultInstruction?._id || "";
          formFields.instructions.value = defaultInstruction?.description || "";
          formFields.instructionSignerData.value =
            defaultInstruction?.description || "";
        }

        formFields.isScanBackNeeded.value =
          res.clientSetting?.scanBackDocument.isAlwaysRequired;

        this.setState({
          formFields,
          signingInstruction: res?.clientSetting?.signingInstruction?.length
            ? res.clientSetting.signingInstruction
            : [],
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getAllLenderClients = () => {
    getAllLenderClients()
      .then((res) => {
        this.setState({
          lenderClients: res?.lenderClients?.length ? res.lenderClients : [],
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getClientListTeamsOfClient = async () => {
    try {
      const clientId = this.props.userData?.user?.id;
      const res = await clientGetListTeamsOfClient(clientId);

      this.setState({ clientTeamsList: res?.teams || [] });
    } catch (err) {
      errorHandler(err);
    }
  };

  _getCompanyStandardFee = (id) => {
    if (id.trim().length > 0) {
      getCompanyStandardFee(id)
        .then((res) => {
          this.setState(
            {
              companyStandardFee:
                res.standardFees.length > 0 ? res.standardFees : [],
            },
            () => {
              this._findStandardrate();
            }
          );
        })
        .catch((error) => {
          errorHandler(error);
        });
    }
  };

  _ClientGetSigningCompanyCustomOrderFormId = async (id) => {
    const { formFields } = deepClone(this.state);

    if (id.trim().length > 0) {
      try {
        let response = await ClientGetSigningCompanyCustomOrderFormId(id);
        // console.log("rt", response);
        let questionsArray =
          response?.questions?.length > 0 &&
          response?.questions?.map((each) => ({
            ...each,
            reply: false,
          }));

        if (response?.productTypes) {
          this._setProductType(response?.productTypes);
        }

        formFields["signingCompanyId"].value = id;
        this.setState({
          formFields,
          questions: questionsArray || loanTypeConfig,
        });

        // this.setState({
        //     questions: response.cutomOrderForm.questions,
        //   });
      } catch (error) {
        errorHandler(error);
      }
    }
  };

  _setProductType = (products) => {
    // console.log("loantype",products)
    const { formFields } = deepClone(this.state);

    const groupedProducts = products.reduce((acc, product) => {
      const { productCategory } = product;

      if (!acc[productCategory]) {
        acc[productCategory] = [];
      }

      acc[productCategory].push(product.productType);

      return acc;
    }, {});

    if (formFields.loanType.value.length > 0) {
      const newArray = formFields.loanType.value.filter((item) =>
        products.some((each) => each.productType === item.value)
      );
      console.log("newArray", newArray);
      formFields["loanType"].value = newArray;
    }

    this.setState({ loanType: groupedProducts, formFields });
  };

  _getAllSigningCompanyForClient = () => {
    getAllSigningCompanyForClient()
      .then((res) => {
        this.setState({
          signingCompanies: res?.signingCompany?.length
            ? res.signingCompany
            : [],
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  componentDidMount = async () => {
    this._getAllSigningCompanyForClient();
    this._getAllLenderClients();
    this._getClientSettings();
    this._getRonPlatformType();
    this._getClientListTeamsOfClient();

    HeaderEventEmitter.subscribe("back-button", () => {
      this.props.history.goBack();
    });
    document.querySelector("#scroll").scrollTo(0, 0);

    const res = await getRonPlatformTypes();
    console.log({ res });
    const ronOptions = Object.keys(res?.ronPlatformTypes)?.map((each) => ({
      label: each,
      value: res?.ronPlatformTypes[each],
    }));
    console.log({ ronOptions });
    this.setState({
      ronPlatformTypeOptions: ronOptions,
    });

    if (this.props.location.search) {
      let params = this.props.location.search.split("&");
      let id = params[0].replace(/[^\w\s]/gi, "");
      this._getSigningClosingById(id);
    }

    if (this.props.userData?.user?.siteOwnerDetail) {
      let whiteLabelId = this.props.userData?.user?.siteOwnerDetail?._id;
      this._ClientGetSigningCompanyCustomOrderFormId(whiteLabelId);
      this._getCompanyStandardFee(whiteLabelId);
    }
  };
  _getRonPlatformType = async () => {
    try {
      const response = await getRonPlatformTypes();

      if (!response.error) {
        const convertedArray = Object.entries(response.ronPlatformTypes).map(
          ([label, value]) => ({ label, value })
        );
        this.setState({ ronPlatformOptions: convertedArray });
      }
    } catch (error) {
      console.error("Error fetching access token:", error);
    }
  };
  _getSigningClosingById = (id) => {
    return new Promise((resolve, reject) => {
      getClosingDetailsById(id)
        .then((res) => {
          this.setState({
            closingDetails: res?.closing || {},
          });
          this._setForm(res?.closing);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _setForm = (closingDetail) => {
    const { formFields, ronPlatformTypeOptions } = deepClone(this.state);
    let borrower = [];
    closingDetail?._borrower?.forEach((each) => {
      borrower.push({
        _id: {
          value: each._id || "",
        },
        borrowerName: {
          value: each.name.full || "",
          isDirty: false,
          isValidate: true,
        },
        email: {
          value: each.email || "",
          isDirty: false,
          isValidate: true,
        },
        workPhone: {
          value: getPhoneNumberFromBrackets(each?.phone?.work) || "",
          isDirty: false,
          isValidate: true,
        },
        alternatePhone: {
          value: getPhoneNumberFromBrackets(each?.phone?.alternate) || "",
          isDirty: false,
          isValidate: true,
        },
        isForeignNational: {
          value: each.isForeignNational,
          isDirty: false,
          isValidate: true,
        },
        language: {
          value: each.language || "",
          isDirty: false,
          isValidate: true,
        },
        languageTypeOther: {
          value: each.languageTypeOther || "",
          isDirty: false,
          isValidate: true,
        },
        timeZone: {
          value: each.timeZone || "",
          isDirty: false,
          isValidate: true,
        },
      });
    });
    formFields["borrower"] =
      borrower.length > 0
        ? borrower
        : [
            {
              _id: {
                value: "",
              },
              borrowerName: {
                value: "",
                isDirty: false,
                isValidate: true,
              },
              email: {
                value: "",
                isDirty: false,
                isValidate: true,
              },
              workPhone: {
                value: "",
                isDirty: false,
                isValidate: true,
              },
              phoneHome: {
                value: "",
                error: null,
                isDirty: false,
                isValidate: true,
              },
              isForeignNational: {
                value: false,
                isDirty: false,
                isValidate: true,
              },
              language: {
                value: "",
                isDirty: false,
                isValidate: true,
              },
              languageTypeOther: {
                value: "",
                isDirty: false,
                isValidate: true,
              },
              timeZone: {
                value: "",
                isDirty: false,
                isValidate: true,
              },
            },
          ];

    formFields["fileNumber"].value = closingDetail?.fileNumber || "";
    formFields["lender"].value = closingDetail?.lender || "";

    formFields["personOfContactName"].value =
      closingDetail?.personOfContact?.name || "";
    formFields["personOfContactEmail"].value =
      closingDetail?.personOfContact?.email[0]?.trim()?.length
        ? closingDetail?.personOfContact?.email
        : [];
    formFields["signingType"].value = closingDetail?.signingType
      ? closingDetail?.signingType
      : "Mobile";

    formFields["closingAddressTimeZone"].value =
      closingDetail?.closingAddress?.timeZone || "";

    if (Object.keys(ronPlatformTypeOptions)?.length) {
      const temp = [];
      ronPlatformTypeOptions?.forEach((each) => {
        closingDetail?.ronPlatformTypes.forEach((subEach) => {
          if (subEach === each?.value) {
            temp.push(each);
          }
        });
      });

      console.log({ temp });
      formFields["ronPlatformType"].value = temp || [];
    }

    formFields["loanTypeOther"].value = closingDetail?.loanTypeOther || "";

    formFields["companyNotes"].value = closingDetail?.companyNotes || "";
    formFields["internalNotes"].value = closingDetail?.internalNotes || "";
    formFields["signingCompanyFee"].value =
      closingDetail?.signingCompanyFee?.toString() || "";
    formFields["instructions"].value = closingDetail?.instructions || "";

    // formFields["client"].value = closingDetail?._client?.id || "";

    formFields["line1"].value = closingDetail?._borrower[0]?.address?.line1;
    formFields["line2"].value = closingDetail?._borrower[0]?.address?.line2;
    formFields["city"].value = closingDetail?._borrower[0]?.address?.city;
    formFields["state"].value = closingDetail?._borrower[0]?.address?.state;
    formFields["zip"].value = closingDetail?._borrower[0]?.address?.zip;
    formFields["borrowerSearch"].value =
      closingDetail?._borrower[0]?.address !== undefined
        ? closingDetail?._borrower[0]?.address?.line1 !== undefined
          ? closingDetail?._borrower[0]?.address?.line1
          : "," + closingDetail?._borrower[0]?.address?.line2 !== undefined
          ? closingDetail?._borrower[0]?.address?.line2
          : "" + "," + closingDetail?._borrower[0]?.address?.city !== undefined
          ? closingDetail?._borrower[0]?.address?.city
          : "" + "," + closingDetail?._borrower[0]?.address?.state !== undefined
          ? closingDetail?._borrower[0]?.address?.state
          : ""
        : "";

    formFields["closingAddressLine1"].value =
      closingDetail?.closingAddress?.line1;
    formFields["closingAddressLine2"].value =
      closingDetail?.closingAddress?.line2;
    formFields["closingAddressCity"].value =
      closingDetail?.closingAddress?.city;
    formFields["closingAddressState"].value =
      closingDetail?.closingAddress?.state;
    formFields["closingAddressZip"].value = closingDetail?.closingAddress?.zip;
    formFields["closingSearch"].value =
      closingDetail?.closingAddress?.line1 +
      "," +
      closingDetail?.closingAddress?.line2 +
      "," +
      closingDetail?.closingAddress?.city +
      "," +
      closingDetail?.closingAddress?.state;
    formFields["loanType"].value = closingDetail?.loanType?.map((each) => ({
      label: each,
      value: each,
    }));
    formFields["loanCategories"].value = closingDetail?.loanCategories || [];

    formFields["ronPlatformType"].value = closingDetail?.ronPlatformTypes.map(
      (each) => ({
        label: each,
        value: each,
      })
    );

    if (closingDetail?.appointmentDate) {
      formFields["appointmentDate"].value =
        formatDateAsPerTimeZoneWithDateAndTime(
          closingDetail?.appointmentDate,
          closingDetail?.closingAddress?.timeZone
        );
    }

    this.setState(
      {
        formFields,
        isBorrowerSearch: false,
        isAppointmentSearch: false,
      },
      () => {
        this._findStandardrate();
      }
    );
  };

  _toggleAddClientModal = (isOpen = false, data = null) => {
    this.setState({
      addClientModalData: {
        isOpen,
        data,
      },
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (
          key === "borrower" ||
          (formFields[key].isDirty && formFields[key].isValidate)
        ) {
          switch (key) {
            // case "line1":
            // case "city":
            // case "state":
            // case "zip": {
            //   if (!isAppointmentAddressSame && isBorrowerSearch) {
            //     if (
            //       formFields[key].value &&
            //       formFields[key].value.trim().length
            //     ) {
            //       formFields[key].isDirty = false;
            //       formFields[key].error = null;
            //     } else {
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Required";
            //       isFormValid = false;
            //     }
            //   } else {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   }
            //   break;
            // }
            // case "borrowerName":
            case "borrower": {
              formFields.borrower.forEach((each, index) => {
                if (each.borrowerName?.value.trim().length) {
                  formFields[key][index]["borrowerName"].isDirty = false;
                  formFields[key][index]["borrowerName"].error = null;
                } else {
                  formFields[key][index]["borrowerName"].isDirty = true;
                  formFields[key][index]["borrowerName"].error = "*Required";
                  isFormValid = false;
                }

                if (
                  formFields[key][index]["email"].value &&
                  formFields[key][index]["email"].value.trim().length
                ) {
                  if (
                    RegexConfig.email.test(
                      String(
                        formFields[key][index]["email"].value
                      ).toLowerCase()
                    )
                  ) {
                    formFields[key][index]["email"].isDirty = false;
                    formFields[key][index]["email"].error = null;
                  } else {
                    formFields[key][index]["email"].isDirty = true;
                    formFields[key][index]["email"].error = "*Invalid Email";
                    isFormValid = false;
                  }
                } else {
                  formFields[key][index]["email"].isDirty = false;
                  formFields[key][index]["email"].error = null;
                }

                if (
                  formFields[key][index]["workPhone"].value &&
                  formFields[key][index]["workPhone"].value.length
                ) {
                  if (
                    RegexConfig.phone.test(
                      String(
                        formFields[key][index]["workPhone"].value
                      ).toLowerCase()
                    )
                  ) {
                    formFields[key][index]["workPhone"].isDirty = false;
                    formFields[key][index]["workPhone"].error = null;
                  } else {
                    isFormValid = false;
                    formFields[key][index]["workPhone"].isDirty = true;
                    formFields[key][index]["workPhone"].error =
                      "*Invalid Phone Number";
                  }
                } else {
                  formFields[key][index]["workPhone"].isDirty = false;
                  formFields[key][index]["workPhone"].error = null;
                }

                if (
                  formFields?.[key]?.[index]?.["alternatePhone"]?.value &&
                  formFields?.[key]?.[index]?.["alternatePhone"]?.value?.length
                ) {
                  if (
                    RegexConfig.phone.test(
                      String(
                        formFields?.[key]?.[index]?.["alternatePhone"]?.value
                      )?.toLowerCase()
                    )
                  ) {
                    formFields[key][index]["alternatePhone"].isDirty = false;
                    formFields[key][index]["alternatePhone"].error = null;
                  } else {
                    isFormValid = false;
                    formFields[key][index]["alternatePhone"].isDirty = true;
                    formFields[key][index]["alternatePhone"].error =
                      "*Invalid Phone Number";
                  }
                } else {
                  formFields[key][index]["alternatePhone"].isDirty = false;
                  formFields[key][index]["alternatePhone"].error = null;
                }
              });
              break;
            }
            case "closingAddressLine1":
            case "closingAddressCity":
            case "closingAddressState":
            case "closingAddressZip": {
              if (
                formFields?.signingType?.value === "RON" ||
                (formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone") ||
                (formFields[key]?.value &&
                  formFields[key]?.value?.trim()?.length)
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "closingAddressTimeZone": {
              if (
                formFields.signingType.value === "RON" ||
                (formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone")
              ) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            case "fileNumber": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            // case "lender":
            // case "personOfContactName": {
            //   if (pdfFile === null) {
            //     if (
            //       formFields[key].value &&
            //       formFields[key].value.trim().length
            //     ) {
            //       formFields[key].isDirty = false;
            //       formFields[key].error = null;
            //     } else {
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Required";
            //       isFormValid = false;
            //     }
            //   }
            //   break;
            // }
            // case "personOfContactEmail":
            // case "personOfContactEmail": {
            //   if (
            //     // formFields[key].value &&
            //     formFields[key].value.length
            //   ) {
            //     // if (
            //     //   RegexConfig.email.test(
            //     //     String(formFields[key].value).toLowerCase()
            //     //   )
            //     // ) {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //     // } else {
            //     //   formFields[key].isDirty = true;
            //     //   formFields[key].error = "*Invalid Email";
            //     //   isFormValid = false;
            //     // }
            //   } else {
            //     formFields[key].isDirty = true;
            //     formFields[key].error = "*Required";
            //     isFormValid = false;
            //   }
            //   break;
            // }

            case "email": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                if (
                  RegexConfig.email.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            case "loanType": {
              if (formFields[key].value && formFields[key].value.length) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "appointmentDate": {
              // if (!tBD) {
              if (formFields[key].value && formFields[key].value.length) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              // } else {
              // formFields[key].isDirty = false;
              // formFields[key].error = null;
              // }
              break;
            }
            case "loanTypeOther": {
              if (
                formFields?.loanType?.value.find((e) => e.value === "Other")
              ) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            // case "homePhone":
            // case "workPhone":
            // case "phone": {
            //   if (
            //     formFields[key].value &&
            //     formFields[key].value.trim().length
            //   ) {
            //     if (
            //       RegexConfig.phone.test(
            //         String(formFields[key].value).toLowerCase()
            //       )
            //     ) {
            //       formFields[key].isDirty = false;
            //       formFields[key].error = null;
            //     } else {
            //       isFormValid = false;
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Invalid Phone Number";
            //     }
            //   } else {
            //     if (
            //       (formFields["homePhone"].value &&
            //         formFields["homePhone"].value.length) ||
            //       (formFields["workPhone"].value &&
            //         formFields["workPhone"].value.length) ||
            //       (formFields["phone"].value &&
            //         formFields["phone"].value.length)
            //     ) {
            //       formFields[key].isDirty = false;
            //       formFields[key].error = null;
            //     } else {
            //       isFormValid = false;
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Required";
            //     }
            //   }
            //   break;
            // }
            case "location": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            // case "lenderClientId": {
            //   if (
            //     formFields[key].value &&
            //     formFields[key].value.trim().length
            //   ) {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   } else {
            //     formFields[key].isDirty = true;
            //     formFields[key].error = "*Required";
            //     isFormValid = false;
            //   }
            //   break;
            // }

            case "clientTeams": {
              if (this.state?.clientTeamsList?.length) {
                if (
                  formFields[key]?.value &&
                  formFields[key]?.value?.trim()?.length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            case "signingCompanyFee": {
              if (formFields.signingCompanyId.value.trim().length) {
                if (formFields[key].value && formFields[key].value.length) {
                  if (
                    isNaN(formFields[key].value) ||
                    Number(formFields[key].value) < 0
                  ) {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Fee must be a positive number";
                    isFormValid = false;
                  } else {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  }
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            // case "borrowerSearch": {
            //   if (!isAppointmentAddressSame && isBorrowerSearch) {
            //     if (
            //       formFields[key].value &&
            //       formFields[key].value.trim().length
            //     ) {
            //       if (formFields[key].placeId) {
            //         formFields[key].isDirty = false;
            //         formFields[key].error = null;
            //       } else {
            //         formFields[key].isDirty = true;
            //         formFields[key].error = "*Invalid Address";
            //         isFormValid = false;
            //       }
            //     } else {
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Required";
            //       isFormValid = false;
            //     }
            //   } else {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   }
            //   break;
            // }
            case "closingSearch": {
              if (
                formFields.signingType.value !== "RON" &&
                !(
                  formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone"
                )
              ) {
                if (
                  this.props.location.search.length === 0 &&
                  this.state.isAppointmentSearch
                ) {
                  if (
                    formFields[key].value &&
                    formFields[key].value.trim().length
                  ) {
                    if (formFields[key].placeId) {
                      formFields[key].isDirty = false;
                      formFields[key].error = null;
                    } else {
                      formFields[key].isDirty = true;
                      formFields[key].error = "*Invalid Address";
                      isFormValid = false;
                    }
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Required";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            case "signingCompanyId": {
              if (formFields?.signingCompanyId?.value?.trim()?.length) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }

              break;
            }

            default:
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeFormField = (fieldName, value) => {
    console.log("fff", fieldName, value);
    const { formFields, signingInstruction, textInstruction } = deepClone(
      this.state
    );

    if (fieldName === "signingCompanyId") {
      this._getCompanyStandardFee(value);
      this._ClientGetSigningCompanyCustomOrderFormId(value);
    }

    if (fieldName === "signingCompanyFee") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }

    if (fieldName === "closingSearch" || fieldName === "borrowerSearch") {
      googlePlaceSearch(value)
        .then((res) => {
          this.setState({ searchResult: res, show: fieldName });
        })
        .catch((error) => {
          errorHandler(error);
        });
    }

    if (fieldName === "instructionId") {
      const findInstruction = signingInstruction.find(
        (each) => each._id === value
      );

      formFields.instructionId.value = findInstruction?._id || "";
      formFields.instructions.value = findInstruction?.description || "";
      formFields.instructionSignerData.value =
        findInstruction?.description || "";
    }

    if (fieldName === "appointmentDate")
      formFields[fieldName].value = value ? new Date(value).toISOString() : "";
    else formFields[fieldName].value = value;

    if (
      fieldName === "homePhone" ||
      fieldName === "phone" ||
      fieldName === "workPhone" ||
      fieldName === "alternatePhone"
    )
      formFields[fieldName].value = value.replace(
        /\([^]*\)|[^a-z0-9\s]|\D|\s/gi,
        ""
      );

    formFields[fieldName].isDirty = true;

    if (fieldName === "witnessCount") {
      let text =
        value !== ""
          ? !textInstruction.includes("witness")
            ? `<p>#${value} witness required.</p>`.concat(textInstruction)
            : textInstruction.replace(/\d+(?= witness required)/, value)
          : textInstruction.replace(/<p>#.*?<\/p>/, "");

      this.setState({
        textInstruction: text,
      });
    }

    if (fieldName === "ronPlatformType") {
      let text = value?.length
        ? !formFields.instructions.value.includes("MUST BE A RON APPROVED")
          ? `MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON ${value?.map(
              (each) => each?.value
            )}`.concat(formFields.instructions.value)
          : formFields.instructions.value.replace(
              /MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON \s*([\w\s,]+)/,
              `MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON ${value?.map(
                (each) => each?.value
              )}`
            )
        : formFields.instructions.value.replace(
            /MUST BE A RON APPROVED NOTARY WITH UPLOADED RON CREDENTIAL IN PROFILE. MUST CONDUCT RON SIGNING ON \s*([\w\s,]+)/,
            ""
          );

      formFields["instructions"].value = text;
    }

    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // validation
      this._validateForm();

      if (fieldName === "witnessCount" || fieldName === "instructionId") {
        this._signerDataInstruction();
      }

      if (
        fieldName === "loanType" ||
        fieldName === "closingAddressState" ||
        fieldName === "closingAddressCity" ||
        fieldName === "closingSearch" ||
        fieldName === "signingType" ||
        (fieldName === "signingCompanyId" &&
          formFields.loanType.value.length > 0)
      ) {
        // console.log("standardRate");
        this._findStandardrate();
      }
    });
  };

  _signerDataInstruction = () => {
    const { formFields, textInstruction } = deepClone(this.state);
    let finalText = textInstruction.concat(
      formFields.instructionSignerData.value
    );
    formFields["instructions"].value = this._sortText(finalText);

    this.setState({ formFields });
  };

  _submitCreateSigningClosing = async () => {
    this._manageLoading("submitLoading", true);
    await this._markAllFieldDirty();

    const isFormValid = await this._validateForm();

    if (!isFormValid) {
      // showToast("Please Fill The Required Fields", "error");
      Object.keys(this.state.formFields).forEach((key) => {
        if (this.state.formFields[key].error) {
          const result = key.replace(/([A-Z])/g, " $1");
          const finalResult = result.charAt(0).toUpperCase() + result.slice(1);
          showToast(`Please provide ${finalResult} `, "error");
        }
      });
      if (!this.state.formFields.fileNumber.value.trim().length > 0) {
        this._toggleFileCheckedModal(true);
      }
      this._manageLoading("submitLoading", false);
      return;
    }

    this.props.showLoader("Create order...");

    const {
      formFields,
      isBorrowerAddressSame,
      isAppointmentAddressSame,
      tBD,
      questions,
    } = deepClone(this.state);

    // const splitBorrowerName = formFields.borrowerName.value.trim().split(" ");

    const payload = {
      borrower: [],
      // borrower: {
      //   email: formFields.email.value ? formFields.email.value : undefined,
      //   phone: {},
      //   address: {
      //     line1: formFields.line1.value,
      //     line2: formFields.line2.value,
      //     city: formFields.city.value,
      //     state: formFields.state.value,
      //     zip: formFields.zip.value,
      //   },
      //   name: {
      //     first: splitBorrowerName.slice(0, 1).join(" "),
      //     last: splitBorrowerName[1]
      //       ? splitBorrowerName.slice(1).join(" ")
      //       : "",
      //   },
      // },
      signingCompanyFee: formFields.signingCompanyFee.value
        ? formFields.signingCompanyFee.value
        : undefined,
      fileNumber: formFields.fileNumber.value
        ? formFields.fileNumber.value
        : undefined,
      lender: formFields.lender.value ? formFields.lender.value : undefined,

      personOfContact: {
        name: formFields.personOfContactName.value,
        email: formFields.personOfContactEmail.value,
      },

      // loanType:
      //   formFields.loanType.value !== "Other"
      //     ? formFields.loanType.value
      //       ? formFields.loanType.value
      //       : undefined
      //     : "Other",

      // loanTypeOther: formFields.loanTypeOther.value
      //   ? formFields.loanTypeOther.value
      //   : undefined,
      loanTypeOther:
        formFields.loanType.value.filter((item) => item.value === "Other")
          .length > 0
          ? formFields.loanTypeOther.value
          : undefined,
      tBD,

      closingAddress: {
        line1: formFields.closingAddressLine1.value,
        line2: formFields.closingAddressLine2.value,
        city: formFields.closingAddressCity.value,
        state: formFields.closingAddressState.value,
        zip: formFields.closingAddressZip.value,
        timeZone: formFields.closingAddressTimeZone.value
          ? formFields?.closingAddressTimeZone?.value
          : undefined,
      },

      instructions: formFields.instructions.value
        ? formFields.instructions.value
        : undefined,
      isScanBackNeeded: formFields.isScanBackNeeded.value,
    };
    let borrower = await formFields.borrower.map(
      ({
        borrowerName,
        email,
        workPhone,
        alternatePhone,
        isForeignNational,
        language,
        languageTypeOther,
        timeZone,
      }) => {
        const splitBorrowerName = borrowerName.value.trim().split(" ");

        return {
          name: {
            first: splitBorrowerName.slice(0, 1).join(" "),
            last: splitBorrowerName[1]
              ? splitBorrowerName.slice(1).join(" ")
              : "",
          },
          email: email.value ? email.value : undefined,
          phone: {
            work: workPhone.value ? workPhone.value : undefined,
            alternate: alternatePhone.value ? alternatePhone.value : undefined,
          },
          isForeignNational: isForeignNational.value,
          language: language.value ? language.value : undefined,
          languageTypeOther: languageTypeOther.value
            ? languageTypeOther.value
            : undefined,
          timeZone: timeZone.value ? timeZone.value : undefined,
          address: {},
        };
      }
    );
    payload["borrower"] = borrower;
    if (formFields.loanType.value) {
      payload["loanType"] = await formFields.loanType.value.map(
        (item) => item.value
      );
    }
    if (formFields.loanCategories.value) {
      payload["loanCategories"] = await formFields?.loanCategories?.value;
    }
    if (
      formFields.loanOfficer.value &&
      formFields.loanOfficer.value.trim().length
    ) {
      payload["loanOfficer"] = formFields.loanOfficer.value.trim();
    }

    if (
      formFields.signingType.value &&
      formFields.signingType.value.trim().length
    ) {
      payload["signingType"] = formFields.signingType.value.trim();
    }

    if (
      formFields.witnessCount.value &&
      formFields.witnessCount.value.trim().length
    ) {
      payload["witnessCount"] = Number(formFields.witnessCount.value.trim());
    }

    if (formFields.appointmentDate.value) {
      if (!tBD) {
        payload["appointmentDate"] = formatDateMoment(
          formFields.appointmentDate.value
        );
      } else {
        payload["appointmentDate"] = formatOnlyDateMoment(
          formFields.appointmentDate.value
        );
      }
    }

    if (formFields.additionalSigner.value) {
      payload["additionalSigner"] = formFields.additionalSigner.value;
    }

    if (formFields?.signingCompanyId?.value) {
      payload["signingCompanyId"] = formFields.signingCompanyId.value;
    }

    // if (formFields.lenderClientId.value) {
    //   payload["lenderClientId"] = formFields.lenderClientId.value;
    // }

    if (
      formFields?.clientTeams?.value &&
      formFields?.clientTeams?.value?.trim()?.length
    ) {
      payload["_clientAssistantTeam"] = formFields?.clientTeams?.value?.trim();
    }

    if (
      formFields.internalNotes.value &&
      formFields.internalNotes.value.trim().length
    ) {
      payload["internalNotes"] = formFields.internalNotes.value.trim();
    }

    if (formFields.signingType.value === "RON")
      payload.ronPlatformTypes = formFields?.ronPlatformType?.value?.length
        ? formFields?.ronPlatformType?.value?.map((each) => each?.value)
        : undefined;

    if (
      formFields.companyNotes.value &&
      formFields.companyNotes.value.trim().length
    ) {
      payload["companyNotes"] = formFields.companyNotes.value.trim();
    }

    if (
      formFields.loanOfficer.value &&
      formFields.loanOfficer.value.trim().length
    ) {
      payload["loanOfficer"] = formFields.loanOfficer.value.trim();
    }

    if (formFields.borrowerSearch.placeId) {
      const addressObj = await googlePlaceDetails(
        formFields.borrowerSearch.placeId
      );
      let abbr = usaStates.find(
        (item) => item.name === addressObj.state
      ).abbreviation;

      // payload.borrower.address["line1"] = addressObj.address;
      // payload.borrower.address["city"] = addressObj.city;
      // payload.borrower.address["county"] = addressObj.county;
      // payload.borrower.address["state"] =
      //   addressObj.state.length > 2 ? abbr : addressObj.state;
      // payload.borrower.address["zip"] = addressObj.postal;
      // payload.borrower.address["lat"] = addressObj.lat;
      // payload.borrower.address["lng"] = addressObj.lng;
      await borrower.map(
        (item) => (
          // eslint-disable-next-line no-sequences
          (item.address["line1"] = addressObj.address),
          (item.address["city"] = addressObj.city),
          (item.address["county"] = addressObj.county),
          (item.address["state"] =
            addressObj.state.length > 2 ? abbr : addressObj.state),
          (item.address["zip"] = addressObj.postal),
          (item.address["lat"] = addressObj.lat),
          (item.address["lng"] = addressObj.lng)
        )
      );
      if (isBorrowerAddressSame) {
        payload.closingAddress["line1"] = addressObj.address;
        payload.closingAddress["city"] = addressObj.city;
        payload.closingAddress["county"] = addressObj.county;
        payload.closingAddress["state"] =
          addressObj.state.length > 2 ? abbr : addressObj.state;
        payload.closingAddress["zip"] = addressObj.postal;
        payload.closingAddress["lat"] = addressObj.lat;
        payload.closingAddress["lng"] = addressObj.lng;
        payload.closingAddress["timeZone"] =
          formFields.closingAddressTimeZone.value;
      }
    }

    if (isBorrowerAddressSame) {
      if (formFields.closingSearch.placeId) {
        const addressObj = await googlePlaceDetails(
          formFields.closingSearch.placeId
        );
        let abbr = usaStates.find(
          (item) => item.name === addressObj.state
        ).abbreviation;

        payload.closingAddress["line1"] = addressObj.address;
        payload.closingAddress["city"] = addressObj.city;
        payload.closingAddress["county"] = addressObj.county;
        payload.closingAddress["state"] =
          addressObj.state.length > 2 ? abbr : addressObj.state;
        payload.closingAddress["zip"] = addressObj.postal;
        payload.closingAddress["lat"] = addressObj.lat;
        payload.closingAddress["lng"] = addressObj.lng;
        payload.closingAddress["timeZone"] =
          formFields.closingAddressTimeZone.value;
      }
    }

    if (isAppointmentAddressSame) {
      if (formFields.closingSearch.placeId) {
        const addressObj = await googlePlaceDetails(
          formFields.closingSearch.placeId
        );
        let abbr = usaStates.find(
          (item) => item.name === addressObj.state
        ).abbreviation;
        await borrower.map(
          (item) => (
            // eslint-disable-next-line no-sequences
            (item.address["line1"] = addressObj.address),
            (item.address["city"] = addressObj.city),
            (item.address["state"] =
              addressObj.state.length > 2 ? abbr : addressObj.state),
            (item.address["zip"] = addressObj.postal),
            (item.address["lat"] = addressObj.lat),
            (item.address["lng"] = addressObj.lng)
          )
        );
      }
    }
    if (formFields.ronPlatformType.value) {
      payload["ronPlatformTypes"] = await formFields.ronPlatformType.value.map(
        (item) => item.value
      );
    }

    if (questions.length > 0) {
      payload["questions"] = questions;
    }
    // if (
    //   formFields.closingSearch.placeId ||
    //   (formFields.closingAddressCity.value &&
    //     formFields.closingAddressState.value)
    // ) {
    //   delete payload.closingAddress["timeZone"];
    // }
    // if (formFields.homePhone.value) {
    //   payload.borrower.phone[
    //     "home"
    //   ] = `(${formFields.phoneHomeDialCode.value})${formFields.homePhone.value}`;
    // }
    // if (formFields.workPhone.value) {
    //   payload.borrower.phone[
    //     "work"
    //   ] = `(${formFields.phoneWorkDialCode.value})${formFields.workPhone.value}`;
    // }
    // if (formFields.phone.value) {
    //   payload.borrower.phone[
    //     "mobile"
    //   ] = `(${formFields.phoneMobileDialCode.value})${formFields.phone.value}`;
    // }

    this._createClientClosing(payload);
  };

  _createClientClosing = (data) => {
    createClientClosing(data)
      .then((res) => {
        showToast("Order has been created", "success");
        this.props.hideLoader();
        this._manageLoading("submitLoading", false);
        this.props.history.replace("/client/order-dashboard");
      })
      .catch((error) => {
        errorHandler(error);
        this.props.hideLoader();
        this._manageLoading("submitLoading", false);
      });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        // formFields[e].isDirty = true;
        if (formFields[e].isDirty !== undefined) formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _getPlaceDetail = async (pid, type) => {
    const { formFields } = deepClone(this.state);
    let detail = await googlePlaceDetails(pid.place_id);

    if (detail) {
      if (type === "borrowerAddress") {
        if (detail.postal) {
          formFields["line1"].value = detail.address;
          formFields["city"].value = detail.city;
          let state = usaStates.filter(
            (state) => state.name === detail.state
          )[0]?.abbreviation;
          formFields["state"].value = state;
          formFields["zip"].value = detail.postal;
          formFields["borrowerSearch"].value =
            detail.address +
            "," +
            detail.city +
            "," +
            detail.postal +
            "," +
            detail.country;
          formFields["borrowerSearch"].placeId = pid.place_id;
          formFields["borrowerSearch"].isDirty = true;
        } else {
          showToast("Invalid Address", "error");
        }
      } else {
        if (detail.postal) {
          formFields["closingAddressLine1"].value = detail.address;
          formFields["closingAddressCity"].value = detail.city;
          let cState = usaStates.filter(
            (state) => state.name === detail.state
          )[0]?.abbreviation;
          formFields["closingAddressState"].value = cState;
          formFields["closingAddressZip"].value = detail.postal;
          formFields["closingSearch"].value =
            detail.address +
            "," +
            detail.city +
            "," +
            detail.postal +
            "," +
            detail.country;
          formFields["closingSearch"].placeId = pid.place_id;
          formFields["closingSearch"].isDirty = true;
        } else {
          showToast("Invalid Address", "error");
        }
      }
      this.setState({ formFields, show: true }, () => {
        this._validateForm();
        this._findStandardrate();
      });
    }
  };

  _useAppointmentAddress = (event) => {
    const { formFields } = deepClone(this.state);
    formFields["line1"].value = event
      ? formFields.closingAddressLine1.value
      : "";
    formFields["line2"].value = event
      ? formFields.closingAddressLine2.value
      : "";
    formFields["city"].value = event ? formFields.closingAddressCity.value : "";
    formFields["state"].value = event
      ? formFields.closingAddressState.value
      : "";
    formFields["zip"].value = event ? formFields.closingAddressZip.value : "";
    formFields["borrowerSearch"].value = event
      ? formFields.closingSearch.value
      : "";

    this.setState({ formFields, isAppointmentAddressSame: event }, () =>
      this._validateForm()
    );
  };

  _onChipSelect = (chips) => {
    let isValid = chips.length
      ? this._isValidEmail(chips[chips.length - 1])
      : true;
    if (isValid) {
      this.setState({
        formFields: {
          ...this.state.formFields,
          personOfContactEmail: {
            ...this.state.formFields.personOfContactEmail,
            value: chips,
          },
        },
      });
    }
  };

  _isValidEmail = (value) => {
    if (RegexConfig.email.test(String(value).toLowerCase())) {
      return true;
    } else {
      showToast("Invalid Email", "error");
      return false;
    }
  };

  _onChangeTBD = (tBD) => {
    // if (tBD) {
    //   const { formFields } = this.state;
    //   // formFields.appointmentDate.value = "";
    //   formFields.appointmentDate.isDirty = false;
    //   formFields.appointmentDate.error = null;

    //   // formFields.signingCompanyId.value = "";
    //   formFields.signingCompanyId.isDirty = false;
    //   formFields.signingCompanyId.error = null;
    //   this.setState({ formFields });
    // }
    this.setState({ tBD });
  };

  _toggleFileCheckedModal = (isOpen = false, data = null) => {
    this.setState({
      fileChecked: {
        isOpen,
        data,
      },
    });
  };

  _toggleFileNumberUniqueCheckedModal = (isOpen = false) => {
    this.setState({
      FileNumberUniqueChecked: {
        isOpen,
      },
    });
  };

  _approvedFileNumber = (value) => {
    const { formFields } = deepClone(this.state);
    formFields["fileNumber"].value = "CW-" + value?.FileNumber;
    this.setState({ formFields }, () => {
      this._toggleFileCheckedModal();
      this._validateForm();
    });
  };

  _addBorrower = () => {
    const { formFields } = this.state;
    formFields.borrower.push({
      borrowerName: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      email: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      workPhone: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      alternatePhone: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      isForeignNational: {
        value: false,
        isDirty: false,
        isValidate: true,
      },
      language: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      languageTypeOther: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      timeZone: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
    });
    this.setState({ formFields });
  };

  _removeBorrower = (index) => {
    const { formFields } = this.state;
    formFields.borrower.splice(index, 1);
    this.setState({ formFields });
  };

  _onChangeFormFieldBorrower = (fieldName, value, subField, index) => {
    const { formFields } = deepClone(this.state);
    if (fieldName === "borrower") {
      // formFields[fieldName][index][subField].value = value;
      if (subField === "workPhone" || subField === "alternatePhone") {
        formFields[fieldName][index][subField].value = value.replace(/\D/g, "");
      } else {
        formFields[fieldName][index][subField].value = value;
      }
    }

    this.setState({ formFields }, () => {
      this._validateForm();
    });
    this._addTextInInstruction(subField, value, index);
  };

  _addTextInInstruction = (subField, value, index) => {
    const { textInstruction } = deepClone(this.state);

    if (subField === "isForeignNational") {
      let text = value
        ? `<p>Signer ${index + 1} is a foreign National.</p>`.concat(
            textInstruction
          )
        : textInstruction.replace(
            `<p>Signer ${index + 1} is a foreign National.</p>`,
            ""
          );

      this.setState(
        {
          textInstruction: text,
        },
        () => {
          this._signerDataInstruction();
        }
      );
    }
    if (subField === "timeZone") {
      let text =
        value !== ""
          ? !textInstruction.match(
              new RegExp(`<p>Signer ${index + 1} is in [\\w/]+.</p>`, "g")
            )
            ? `<p>Signer ${index + 1} is in ${value}.</p>`.concat(
                textInstruction
              )
            : textInstruction.replace(
                new RegExp(`<p>Signer ${index + 1} is in [\\w/]+.</p>`, "g"),
                `<p>Signer ${index + 1} is in ${value}.</p>`
              )
          : textInstruction.replace(
              new RegExp(`<p>Signer ${index + 1} is in [\\w/]+.</p>`, "g"),
              ""
            );

      this.setState(
        {
          textInstruction: text,
        },
        () => {
          this._signerDataInstruction();
        }
      );
    }
    if (subField === "language") {
      let text =
        value !== ""
          ? !textInstruction.match(
              new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g")
            )
            ? `<p>Signer ${index + 1} speaks ${value}.</p>`.concat(
                textInstruction
              )
            : textInstruction.replace(
                new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g"),
                `<p>Signer ${index + 1} speaks ${value}.</p>`
              )
          : textInstruction.replace(
              new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g"),
              ""
            );

      this.setState(
        {
          textInstruction: text,
        },
        () => {
          this._signerDataInstruction();
        }
      );
    }
  };

  _sortText = (input) => {
    const signerRegex = /<p>.*?\bSigner\s+\d+\b.*?<\/p>/g;
    const signerMatches = input.match(signerRegex);

    // sort the signer strings in ascending order
    if (signerMatches !== null) {
      signerMatches.sort();

      // replace the original signer strings with the sorted ones
      let output = input.replace(signerRegex, () => signerMatches.shift());

      return output;
    } else {
      return input;
    }
  };

  _isNumber = (e) => {
    var charCode = e.which ? e.which : e.keyCode;

    if (charCode > 31 && (charCode < 48 || charCode > 57))
      return e.preventDefault();
    return;
  };

  _findStandardrate = () => {
    const { formFields } = deepClone(this.state);

    let companySettingStandardFee = this._filterObject(
      formFields?.closingAddressState?.value,
      formFields?.closingAddressCity?.value,
      formFields.loanType.value,
      formFields?.signingType?.value,
      this.props?.userData?.user?._id
    );

    // countyMatched !== undefined ? countyMatched : noCounty;
    console.log(
      "companySettingStandardFee",
      companySettingStandardFee
      // companySettingStandardFee[0]?.offerRate !== undefined
      //   ? companySettingStandardFee[0]?.offerRate?.toString()
      //   : formFields?.agentFee?.value
      //   ? formFields?.agentFee?.value?.toString()
      //   : ""
    );
    // formFields["agentFee"].value =
    //   companySettingStandardFee[0]?.offerRate !== undefined
    //     ? companySettingStandardFee[0]?.offerRate?.toString()
    //     : formFields?.agentFee?.value
    //     ? formFields?.agentFee?.value?.toString()
    //     : "";
    formFields["signingCompanyFee"].value =
      companySettingStandardFee[0]?.offerRate !== undefined
        ? companySettingStandardFee[0]?.offerRate?.toString()
        : formFields.signingCompanyFee.value;
    this.setState({ formFields }, () => {
      // console.log("1qw", formFields.signingCompanyFee);
    });
  };

  _filterObject = (state, city, productType, signingType, clientId) => {
    const { companyStandardFee, formFields } = this.state;
    // console.log("qa", state, city, productType, signingType);

    let loanTypes = productType.map((item) => item.value);

    let filteredData =
      Array.isArray(companyStandardFee) && companyStandardFee.length > 0
        ? companyStandardFee.filter(
            (item) =>
              item.signingType === signingType &&
              loanTypes.includes(item.productType)
          )
        : [];

    // console.log("filteredData1", filteredData);

    if (state) {
      filteredData = filteredData.filter((item) => item.state === state);
    } else {
      filteredData = filteredData.filter((item) => item?.state === "");
    }
    // console.log("filteredData2", filteredData);

    if (city) {
      filteredData = filteredData.filter((item) =>
        item.county.trim().length > 0
          ? item.county
              .toLowerCase()
              .includes(formFields?.closingAddressCity?.value.toLowerCase())
          : true
      );
    } else {
      filteredData = filteredData.filter((item) => !item?.county);
    }

    if (clientId) {
      const matched = filteredData.find(
        (item) => item?.isForClient && item?._client === clientId
      );

      filteredData = matched
        ? [matched]
        : filteredData.filter(
            (item) => item?.isForClient && item?._client === undefined
          );
    } else {
      filteredData = filteredData.filter(
        (item) => item?.isForClient && item?._client === undefined
      );
    }

    // console.log("filteredData3", filteredData);
    return filteredData;
  };

  _onChangeQuestionFormField = (value, index) => {
    const { questions } = deepClone(this.state);
    // questions[index] = Object.assign({}, questions[index], {
    //   reply: !questions[index].hasOwnProperty("reply") || !questions[index].reply,
    // });
    questions[index].reply = !questions[index].reply;
    this.setState({ questions });
  };

  _duplicateFileNumberCheck = async () => {
    let { fileNumber } = deepClone(this.state.formFields);
    try {
      this._manageLoading("submitLoading", true);
      const res = await FileNumberDuplicateMatchCheck({
        fileNumber: fileNumber.value,
      });

      if (res?.existingFileNos.length > 0) {
        this._toggleFileNumberUniqueCheckedModal(true);
        this.setState({ existingFileNos: res.existingFileNos });
        this._manageLoading("submitLoading", false);
      } else {
        this._submitCreateSigningClosing();
      }
    } catch (error) {
      errorHandler(error);
      this._manageLoading("submitLoading", false);
    }
  };

  render() {
    const {
      formFields,
      isBorrowerAddressSame,
      isAppointmentAddressSame,
      isBorrowerSearch,
      isAppointmentSearch,
      signingCompanies,
      tBD,
      isOpenAddInstruction,
      lenderClients,
      signingInstruction,
      fileChecked,
      ronPlatformTypeOptions,
      clientTeamsList,
      FileNumberUniqueChecked,
      loading,
      existingFileNos,
    } = deepClone(this.state);
    console.log("state", this.state);
    return (
      <>
        <div id="scroll">
          <div className="content">
            <div className="responsiveTitle">
              <div className="d-flex align-items-center">
                <Button
                  color="link"
                  className="backBtn"
                  onClick={() => this.props.history.goBack()}
                >
                  <img
                    src={require("../../../assets/img/arrowLeft.svg").default}
                    alt="backbutton"
                    height={14}
                  />
                </Button>
                <h2>Create Order</h2>
              </div>

              {/* <div className="rightSide">
                <Button color="link" onClick={this._resetFilter}>
                  <img
                    src={require("../../../assets/img/refresh.svg").default}
                    alt="Filter"
                    height={14}
                  />
                </Button>
              </div> */}
            </div>
            {formFields.borrower.map((each, index) => (
              <div className="ClosingWrapper">
                <Row>
                  <Col sm={6} md={6} xl={4}>
                    <FormGroup className="floatingLabel">
                      {/* add a searchable text field below in place of the input field */}
                      <Input
                        type="text"
                        placeholder=" "
                        value={each.borrowerName.value}
                        name="borrowerName"
                        onChange={(event) =>
                          this._onChangeFormFieldBorrower(
                            "borrower",
                            event.target.value,
                            "borrowerName",
                            index
                          )
                        }
                        onBlur={() => this._validateForm()}
                      />
                      <Label>Signer Name</Label>
                      {each.borrowerName.error && (
                        <div className="validation-error">
                          {each.borrowerName.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={6} xl={4}>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="email"
                        placeholder=" "
                        value={each.email.value}
                        name="email"
                        onChange={(event) =>
                          this._onChangeFormFieldBorrower(
                            "borrower",
                            event.target.value,
                            "email",
                            index
                          )
                        }
                        onBlur={() => this._validateForm()}
                      />
                      <Label>Email</Label>
                      {each.email.error && (
                        <div className="validation-error">
                          {each.email.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={6} xl={4}>
                    <FormGroup className="floatingLabel withInputGroup">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>+1</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder=" "
                          value={each.workPhone.value}
                          name="phone"
                          onChange={(event) =>
                            this._onChangeFormFieldBorrower(
                              "borrower",
                              event.target.value,
                              "workPhone",
                              index
                            )
                          }
                          onKeyPress={(e) => this._isNumber(e)}

                          // onBlur={() => this._validateForm()}
                        />
                        <Label>Phone</Label>
                      </InputGroup>
                      {each.workPhone.error && (
                        <div className="validation-error">
                          {each.workPhone.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm={6} md={6} xl={4}>
                    <FormGroup className="floatingLabel withInputGroup">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>+1</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder=" "
                          value={each.alternatePhone.value}
                          name="phone"
                          onChange={(event) =>
                            this._onChangeFormFieldBorrower(
                              "borrower",
                              event.target.value,
                              "alternatePhone",
                              index
                            )
                          }
                          onKeyPress={(e) => this._isNumber(e)}

                          // onBlur={() => this._validateForm()}
                        />
                        <Label>Alternate Phone</Label>
                      </InputGroup>
                      {each.alternatePhone.error && (
                        <div className="validation-error">
                          {each.alternatePhone.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>

                  <Col sm={6} md={6} xl={4}>
                    <FormGroup className="floatingLabel">
                      <div className="custom-select-wrapper">
                        <Input
                          type="select"
                          name="language"
                          onChange={(event) =>
                            this._onChangeFormFieldBorrower(
                              "borrower",
                              event.target.value,
                              "language",
                              index
                            )
                          }
                          value={each.language.value}
                        >
                          <option value="">Select</option>

                          {LanguageList.map((lang, index) => (
                            <option key={index} value={lang.value}>
                              {lang.label}
                            </option>
                          ))}
                        </Input>
                        <Label>Language</Label>
                      </div>
                    </FormGroup>
                  </Col>
                  {each.language.value === "Other" && (
                    <Col sm={6} md={6} xl={4}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          placeholder=" "
                          value={each.languageTypeOther.value}
                          name="languageOther"
                          onChange={(event) =>
                            this._onChangeFormFieldBorrower(
                              "borrower",
                              event.target.value,
                              "languageTypeOther",
                              index
                            )
                          }
                        />
                        <Label>Other Language</Label>
                      </FormGroup>
                    </Col>
                  )}
                  {formFields.signingType.value === "RON" ||
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone" ? (
                    <Col sm={6} md={6} xl={4}>
                      <FormGroup className="floatingLabel">
                        <div className="custom-select-wrapper">
                          <Input
                            type="select"
                            name="timeZone"
                            value={each.timeZone.value}
                            onChange={(event) =>
                              this._onChangeFormFieldBorrower(
                                "borrower",
                                event.target.value,
                                "timeZone",
                                index
                              )
                            }
                          >
                            <option value="">Select</option>
                            {timezoneList().map((item) => (
                              <option>{item}</option>
                            ))}
                          </Input>
                          <Label>Time Zone</Label>
                        </div>
                      </FormGroup>
                    </Col>
                  ) : null}
                  <Col sm={6} md={6} xl={4}>
                    <CustomInput
                      type="checkbox"
                      id={`"isSignerForeigner+${index}"`}
                      label="Is the signer a foreign national?"
                      checked={each.isForeignNational.value}
                      onChange={(event) =>
                        this._onChangeFormFieldBorrower(
                          "borrower",
                          event.target.checked,
                          "isForeignNational",
                          index
                        )
                      }
                    />
                  </Col>
                  <Col className="text-right">
                    {index === formFields.borrower.length - 1 && (
                      <Button color="link" onClick={() => this._addBorrower()}>
                        <img
                          src={
                            require("../../../assets/img/addItem.svg").default
                          }
                          alt="add item"
                        />
                      </Button>
                    )}
                    {formFields.borrower.length > 1 ? (
                      <Button
                        color="link"
                        onClick={() => this._removeBorrower(index)}
                      >
                        <img
                          src={
                            require("../../../assets/img/removeItem.svg")
                              .default
                          }
                          alt="remove item"
                        />
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              </div>
            ))}
            <hr />

            <div className="ClosingWrapper">
              <Row>
                <Col sm="12">
                  {!isAppointmentAddressSame ? (
                    <CustomInput
                      type="switch"
                      className="mb-3"
                      checked={!isBorrowerSearch}
                      onChange={(e) =>
                        this.setState({
                          isBorrowerSearch: !e.target.checked,
                        })
                      }
                      id="borrowerAddress_Custom_Switch"
                      name="isBorrowerSearch"
                      label="Enter Address Manually"
                    />
                  ) : null}
                </Col>

                {(isAppointmentAddressSame && !isAppointmentSearch) ||
                !isBorrowerSearch ? (
                  <>
                    <Col md={6} xl={4}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          placeholder=" "
                          value={
                            isAppointmentAddressSame
                              ? formFields.closingAddressLine1.value
                              : formFields.line1.value
                          }
                          disabled={isAppointmentAddressSame}
                          name="line1"
                          onChange={(event) =>
                            this._onChangeFormField("line1", event.target.value)
                          }
                          onBlur={() => this._validateForm()}
                        />
                        <Label>Property Address Line 1</Label>
                        {formFields.line1.error && (
                          <div className="validation-error">
                            {formFields.line1.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6} xl={4}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          placeholder=" "
                          value={
                            isAppointmentAddressSame
                              ? formFields.closingAddressLine2.value
                              : formFields.line2.value
                          }
                          disabled={isAppointmentAddressSame}
                          name="line2"
                          onChange={(event) =>
                            this._onChangeFormField("line2", event.target.value)
                          }
                        />
                        <Label>Property Address Line 2</Label>
                      </FormGroup>
                    </Col>
                    <Col md={6} xl={4}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          placeholder=" "
                          value={
                            isAppointmentAddressSame
                              ? formFields.closingAddressCity.value
                              : formFields.city.value
                          }
                          disabled={isAppointmentAddressSame}
                          name="city"
                          onChange={(event) =>
                            this._onChangeFormField("city", event.target.value)
                          }
                          onBlur={() => this._validateForm()}
                        />
                        <Label>City</Label>
                        {formFields.city.error && (
                          <div className="validation-error">
                            {formFields.city.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6} xl={4}>
                      <FormGroup className="floatingLabel">
                        <div className="custom-select-wrapper">
                          <Input
                            type="select"
                            placeholder=" "
                            value={
                              isAppointmentAddressSame
                                ? formFields.closingAddressState.value
                                : formFields.state.value
                            }
                            disabled={isAppointmentAddressSame}
                            name="state"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "state",
                                event.target.value
                              )
                            }
                            onBlur={() => this._validateForm()}
                          >
                            <option value="">Select</option>
                            {usaStates.map((state) => (
                              <option
                                key={state.abbreviation}
                                value={state.abbreviation}
                              >
                                {state.name}
                              </option>
                            ))}
                          </Input>
                          <Label>State</Label>
                        </div>
                        {formFields.state.error && (
                          <div className="validation-error">
                            {formFields.state.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col md={6} xl={4}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="number"
                          placeholder=" "
                          value={
                            isAppointmentAddressSame
                              ? formFields.closingAddressZip.value
                              : formFields.zip.value
                          }
                          disabled={isAppointmentAddressSame}
                          name="zip"
                          onChange={(event) =>
                            this._onChangeFormField("zip", event.target.value)
                          }
                          onBlur={() => this._validateForm()}
                        />
                        <Label>Zip</Label>
                        {formFields.zip.error && (
                          <div className="validation-error">
                            {formFields.zip.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col lg={6} md={6}>
                      <FormGroup className="floatingLabel withInputGroup">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-map-marker" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder=" "
                            value={
                              isAppointmentAddressSame
                                ? formFields.closingSearch.value
                                : formFields.borrowerSearch.value
                            }
                            disabled={isAppointmentAddressSame}
                            name="search"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "borrowerSearch",
                                event.target.value
                              )
                            }
                            onBlur={() => this._validateForm()}
                          />
                          <Label>Property Address (Optional)</Label>
                        </InputGroup>
                        {formFields.borrowerSearch.error && (
                          <div className="validation-error">
                            {formFields.borrowerSearch.error}
                          </div>
                        )}
                        {this.state.show === "borrowerSearch" ? (
                          <ListGroup flush className="customSearchOptions">
                            {this.state.searchResult &&
                              this.state.searchResult.map((i) => (
                                <ListGroupItem
                                  key={i.place_id}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this._getPlaceDetail(i, "borrowerAddress")
                                  }
                                >
                                  {i.description}
                                </ListGroupItem>
                              ))}
                          </ListGroup>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </>
                )}

                <Col sm="12" className="mt-2 mb-3">
                  <CustomInput
                    type="checkbox"
                    id="isAppointmentAddressSameAsBorrower_checkbox"
                    label="Appointment Address Same Location"
                    checked={isAppointmentAddressSame}
                    onChange={(event) =>
                      this._useAppointmentAddress(event.target.checked)
                    }
                  />
                </Col>
                {!isBorrowerAddressSame ? (
                  <Col sm="12">
                    <CustomInput
                      type="switch"
                      className="mb-3"
                      checked={!isAppointmentSearch}
                      onChange={(e) =>
                        this.setState({
                          isAppointmentSearch: !e.target.checked,
                        })
                      }
                      id="closingAddress_Custom_Switch"
                      name="closingAddress"
                      label="Enter Address Manually"
                    />
                  </Col>
                ) : null}
                {isAppointmentSearch ? (
                  <>
                    {" "}
                    <Col xl={4} md={6}>
                      <FormGroup className="floatingLabel withInputGroup">
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="fa fa-map-marker" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder=" "
                            value={
                              isBorrowerAddressSame
                                ? formFields.borrowerSearch.value
                                : formFields.closingSearch.value
                            }
                            disabled={isBorrowerAddressSame}
                            name="search"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "closingSearch",
                                event.target.value
                              )
                            }
                            onBlur={() => this._validateForm()}
                          />
                          <Label>Appointment Address</Label>
                        </InputGroup>
                        {formFields.closingSearch.error && (
                          <div className="validation-error">
                            {formFields.closingSearch.error}
                          </div>
                        )}
                        {this.state.show === "closingSearch" ? (
                          <ListGroup flush className="customSearchOptions">
                            {this.state.searchResult &&
                              this.state.searchResult.map((i) => (
                                <ListGroupItem
                                  key={i.place_id}
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this._getPlaceDetail(i, "closingAddress")
                                  }
                                >
                                  {i.description}
                                </ListGroupItem>
                              ))}
                          </ListGroup>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </>
                ) : (
                  <>
                    <Col xl={4} md={6}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          placeholder=" "
                          value={
                            isBorrowerAddressSame
                              ? formFields.line1.value
                              : formFields.closingAddressLine1.value
                          }
                          disabled={isBorrowerAddressSame}
                          name="closingAddressLine1"
                          onChange={(event) =>
                            this._onChangeFormField(
                              "closingAddressLine1",
                              event.target.value
                            )
                          }
                          onBlur={() => this._validateForm()}
                        />
                        <Label>Appointment Address Line 1</Label>
                        {formFields.closingAddressLine1.error && (
                          <div className="validation-error">
                            {formFields.closingAddressLine1.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl={4} md={6}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          placeholder=" "
                          value={
                            isBorrowerAddressSame
                              ? formFields.line2.value
                              : formFields.closingAddressLine2.value
                          }
                          disabled={isBorrowerAddressSame}
                          name="closingAddressLine2"
                          onChange={(event) =>
                            this._onChangeFormField(
                              "closingAddressLine2",
                              event.target.value
                            )
                          }
                          onBlur={() => this._validateForm()}
                        />
                        <Label>Appointment Address Line 2</Label>
                        {formFields.closingAddressLine2.error && (
                          <div className="validation-error">
                            {formFields.closingAddressLine2.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl={4} md={6}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          placeholder=" "
                          value={
                            isBorrowerAddressSame
                              ? formFields.city.value
                              : formFields.closingAddressCity.value
                          }
                          disabled={isBorrowerAddressSame}
                          name="closingAddressCity"
                          onChange={(event) =>
                            this._onChangeFormField(
                              "closingAddressCity",
                              event.target.value
                            )
                          }
                          onBlur={() => this._validateForm()}
                        />
                        <Label>City</Label>
                        {formFields.closingAddressCity.error && (
                          <div className="validation-error">
                            {formFields.closingAddressCity.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl={4} md={6}>
                      <FormGroup className="floatingLabel">
                        <div className="custom-select-wrapper">
                          <Input
                            type="select"
                            placeholder=" "
                            value={
                              isBorrowerAddressSame
                                ? formFields.state.value
                                : formFields.closingAddressState.value
                            }
                            disabled={isBorrowerAddressSame}
                            name="closingAddressState"
                            onChange={(event) =>
                              this._onChangeFormField(
                                "closingAddressState",
                                event.target.value
                              )
                            }
                            onBlur={() => this._validateForm()}
                          >
                            <option value="">Select</option>
                            {usaStates.map((state) => (
                              <option
                                key={state.abbreviation}
                                value={state.abbreviation}
                              >
                                {state.name}
                              </option>
                            ))}
                          </Input>
                          <Label>State</Label>
                        </div>
                        {formFields.closingAddressState.error && (
                          <div className="validation-error">
                            {formFields.closingAddressState.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl={4} md={6}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="number"
                          placeholder=" "
                          value={
                            isBorrowerAddressSame
                              ? formFields.zip.value
                              : formFields.closingAddressZip.value
                          }
                          disabled={isBorrowerAddressSame}
                          name="zip"
                          onChange={(event) =>
                            this._onChangeFormField(
                              "closingAddressZip",
                              event.target.value
                            )
                          }
                          onBlur={() => this._validateForm()}
                        />
                        <Label>Zip</Label>
                        {formFields.closingAddressZip.error && (
                          <div className="validation-error">
                            {formFields.closingAddressZip.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </>
                )}
                <Col xl={4} md={6}>
                  {!tBD ? (
                    <FormGroup
                      className={`floatingLabel ${
                        formFields.appointmentDate.value ||
                        this.state.isOpenReactDatetime
                          ? "valueAdded"
                          : ""
                      }`}
                    >
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: " ",
                          value: formatDateAndTime(
                            formFields.appointmentDate.value
                          ),
                          // disabled: tBD,
                        }}
                        onChange={(e) =>
                          this._onChangeFormField("appointmentDate", e._d)
                        }
                        onOpen={() =>
                          this.setState({ isOpenReactDatetime: true })
                        }
                        onClose={() => {
                          this._validateForm();
                          this.setState({ isOpenReactDatetime: false });
                        }}
                        // onClose={}
                        // isValidDate={(current) =>
                        //   current.isAfter(getYesterdayDate())
                        // }
                        timeFormat={true}
                        timeConstraints={{ minutes: { step: 15 } }}
                      />
                      <Label>Appointment Date & Time</Label>
                      {formFields.appointmentDate.error && (
                        <div className="validation-error">
                          {formFields.appointmentDate.error}
                        </div>
                      )}
                    </FormGroup>
                  ) : (
                    <FormGroup
                      //  className="floatingLabel"
                      className={`floatingLabel ${
                        formFields.appointmentDate.value ||
                        tBD ||
                        this.state.isOpenReactDatetime
                          ? "valueAdded"
                          : ""
                      }`}
                    >
                      <ReactDatetime
                        inputProps={{
                          className: "form-control",
                          placeholder: "Select",
                          value: formatDate(formFields.appointmentDate.value),
                        }}
                        // value={time.value ? new Date(time.value) : ""}
                        onChange={(e) =>
                          // this._onChangeAppointmentTime(e._d)
                          this._onChangeFormField("appointmentDate", e._d)
                        }
                        dateFormat={true}
                        // dateFormat={
                        //   closingDetails?._signingCompany ? false : true
                        // }
                        timeFormat={false}
                        // timeConstraints={{ minutes: { step: 15 } }}
                      />
                      <Label>Appointment Date</Label>
                    </FormGroup>
                  )}
                </Col>

                <Col xl={4} md={6}>
                  <CustomInput
                    className="mt-3"
                    type="checkbox"
                    id="appointmentDatetime_tBD"
                    label="Time T.B.D"
                    name="tBD"
                    checked={tBD}
                    onChange={(e) => this._onChangeTBD(e.target.checked)}
                  />
                </Col>
              </Row>
            </div>
            <hr />
            <div className="ClosingWrapper">
              <Row>
                <Col xl={4} md={6}>
                  <FormGroup className="floatingLabel">
                    <Input
                      type="text"
                      placeholder=" "
                      value={formFields.fileNumber.value}
                      name="fileNumber"
                      onChange={(event) =>
                        this._onChangeFormField(
                          "fileNumber",
                          event.target.value
                        )
                      }
                      onBlur={() => this._validateForm()}
                    />
                    <Label>File Number</Label>
                    {formFields.fileNumber.error && (
                      <div className="validation-error">
                        {formFields.fileNumber.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                {/* <Col xl={4} md={6}>
                  <div className="InputWithButton">
                    <FormGroup className="floatingLabel">
                      <div className="custom-select-wrapper">
                        <Input
                          type="select"
                          placeholder=" "
                          value={formFields.lenderClientId.value}
                          name="lenderClientId"
                          onChange={(event) =>
                            this._onChangeFormField(
                              "lenderClientId",
                              event.target.value
                            )
                          }
                        >
                          <option value="">Select</option>
                          {lenderClients.map((each) => (
                            <option key={each._id} value={each._id}>
                              {capitalize(each.name.full)}
                            </option>
                          ))}
                        </Input>
                        <Label>My Client</Label>
                      </div>
                      {formFields.lenderClientId.error && (
                        <div className="validation-error">
                          {formFields.lenderClientId.error}
                        </div>
                      )}
                    </FormGroup>
                    <Button
                      color="primary"
                      outline
                      onClick={() =>
                        this.setState({ isOpenAddInstruction: true })
                      }
                    >
                      Add Client
                    </Button>
                  </div>
                </Col> */}

                <Col xl={4} md={6}>
                  <FormGroup className="floatingLabel">
                    <div
                      className={`custom-select-wrapper ${
                        !clientTeamsList?.length ? "disabled" : ""
                      }`}
                    >
                      <Input
                        type="select"
                        placeholder=" "
                        value={formFields?.clientTeams?.value}
                        onChange={(event) =>
                          this._onChangeFormField(
                            "clientTeams",
                            event.target.value
                          )
                        }
                        name="clientTeamsId"
                        disabled={!clientTeamsList?.length}
                      >
                        <option value="">Select</option>
                        {clientTeamsList?.map((team) => (
                          <option key={team?._id} value={team?._id}>
                            {team?.teamName ? capitalize(team?.teamName) : ""}
                          </option>
                        ))}
                      </Input>
                      <Label>My Teams</Label>
                    </div>
                    {formFields?.clientTeams?.error && (
                      <div className="validation-error">
                        {formFields?.clientTeams?.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col xl={4} md={6}>
                  <FormGroup className="floatingLabel">
                    {/* add a searchable text field below in place of the input field */}
                    <Input
                      type="text"
                      placeholder=" "
                      value={formFields.personOfContactName.value}
                      name="line2"
                      onChange={(event) =>
                        this._onChangeFormField(
                          "personOfContactName",
                          event.target.value
                        )
                      }
                      onBlur={() => this._validateForm()}
                    />
                    <Label>Person Of Contact (Name)</Label>
                    {formFields.personOfContactName.error && (
                      <div className="validation-error">
                        {formFields.personOfContactName.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col xl={4} md={6}>
                  <FormGroup className="ChipsWrap floatingLabel valueAdded">
                    <UncontrolledTooltip placement="top" target="pocEmail">
                      Add emails to send status updates to
                    </UncontrolledTooltip>
                    <InputGroup>
                      <Chips
                        value={formFields.personOfContactEmail.value}
                        onChange={(arr) => this._onChipSelect(arr, "chips")}
                        createChipKeys={[9, 13, 32]} //  Key codes to add chips
                        placeholder={"Additional update emails for clients"}
                      />
                      <Label>Person Of Contact (Email) </Label>
                      <InputGroupAddon addonType="append">
                        <InputGroupText href="#" id="pocEmail">
                          <i className="fa fa-info-circle"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    {formFields.personOfContactEmail.error && (
                      <div className="validation-error">
                        {formFields.personOfContactEmail.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col xl={4} md={6}>
                  <FormGroup className="floatingLabel">
                    <div className="custom-select-wrapper">
                      <Input
                        type="select"
                        placeholder=" "
                        name="signingType"
                        value={formFields.signingType.value}
                        onChange={(event) =>
                          this._onChangeFormField(
                            "signingType",
                            event.target.value
                          )
                        }
                      >
                        <option value="Mobile">Mobile Signing</option>
                        <option value="RON">RON</option>
                      </Input>
                      <Label>Signing Type</Label>
                    </div>
                  </FormGroup>
                </Col>

                {/* {formFields?.signingType?.value === "RON" ? (
                  <Col xl={4} md={6}>
                    <FormGroup className="floatingLabel mb-3">
                      <Select
                        isClearable={true}
                        options={this.state.ronPlatformOptions}
                        value={formFields?.ronPlatformType?.value}
                        onChange={(event) =>
                          this._onChangeFormField("ronPlatformType", event)
                        }
                        placeholder="RON Platform Type"
                        className="customSelect"
                        isMulti={true}
                      />
                    </FormGroup>
                  </Col>
                ) : null} */}

                {(formFields.signingType.value === "RON" ||
                  (formFields?.loanType?.value?.length === 1 &&
                    formFields?.loanType?.value[0]?.value ===
                      "AttorneyPhone")) && (
                  <Col sm={6} md={6} xl={4}>
                    <FormGroup className="floatingLabel">
                      <div className="custom-select-wrapper">
                        <Input
                          type="select"
                          name="timeZone"
                          value={formFields.closingAddressTimeZone.value}
                          onChange={(event) =>
                            this._onChangeFormField(
                              "closingAddressTimeZone",
                              event.target.value
                            )
                          }
                        >
                          <option value="">Select</option>
                          {timezoneList().map((item) => (
                            <option>{item}</option>
                          ))}
                        </Input>
                        <Label>Time Zone</Label>
                      </div>
                      {formFields.closingAddressTimeZone.error && (
                        <div className="validation-error">
                          {formFields.closingAddressTimeZone.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                )}

                {formFields?.signingType?.value === "RON" ? (
                  <Col xl={4} md={6}>
                    <FormGroup className="withChips floatingLabel valueAdded">
                      <Select
                        isClearable={true}
                        placeholder="Select"
                        options={ronPlatformTypeOptions}
                        value={formFields?.ronPlatformType?.value}
                        onChange={(event) =>
                          this._onChangeFormField("ronPlatformType", event)
                        }
                        // placeholder="RON Platform Type"
                        className="customMultiSelect"
                        isMulti={true}
                      />
                      <Label style={{ zIndex: 3 }}>RON Platform Type</Label>
                    </FormGroup>
                  </Col>
                ) : null}

                <Col xl={4} md={6}>
                  {/* <FormGroup className={`withChips floatingLabel valueAdded`}>
                    <CreatableSelect
                      isClearable
                      placeholder="Select"
                      className="customMultiSelect"
                      isMulti
                      components={animatedComponents}
                      value={formFields.loanType.value}
                      options={loanTypeConfig}
                      onChange={(event) =>
                        this._onChangeFormField("loanType", event)
                      }
                      onBlur={() => this._validateForm()}
                    />
                    <Label style={{ zIndex: 3 }}>Product Type</Label>

                    {formFields.loanType.error && (
                      <div className="validation-error">
                        {formFields.loanType.error}
                      </div>
                    )}
                  </FormGroup>  */}
                  <ProductType
                    value={formFields?.loanType?.value}
                    onChange={(type, event) =>
                      this._onChangeFormField(type, event)
                    }
                    productTypeField={"loanType"}
                    selectedCategoriesField={"loanCategories"}
                    // blur={() => {
                    //   console.log("blur function called");
                    //   // this._validateForm();
                    // }}
                    // isCompanyFeeSettings={true}
                    error={formFields?.loanType?.error}
                    onClickOutside={() => this._validateForm()}
                    isClientCreateOrder={true}
                    clientCreateOrderOptions={
                      Object.keys(this.state.loanType).length > 0
                        ? this.state.loanType
                        : loanTypeConfig
                    }
                  />
                </Col>
                {formFields?.loanType?.value.find(
                  (e) => e.value === "Other"
                ) && (
                  <Col xl={4} md={6}>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        placeholder=" "
                        value={formFields.loanTypeOther.value}
                        name="loanType"
                        onChange={(event) =>
                          this._onChangeFormField(
                            "loanTypeOther",
                            event.target.value
                          )
                        }
                        blur={() => this._validateForm()}
                      />
                      <Label>Other Product Type</Label>
                      {formFields.loanTypeOther.error && (
                        <div className="validation-error">
                          {formFields.loanTypeOther.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                )}

                <Col xl={4} md={6}>
                  {/* only signing company gets to see the below data */}
                  <FormGroup className="floatingLabel">
                    <div className="custom-select-wrapper">
                      <Input
                        type="select"
                        placeholder=" "
                        value={formFields?.signingCompanyId?.value}
                        name="signingCompanyId"
                        onChange={(event) =>
                          this._onChangeFormField(
                            "signingCompanyId",
                            event.target.value
                          )
                        }
                        // disabled={tBD}
                      >
                        <option value="">Select</option>
                        {signingCompanies.map((each) => (
                          <option key={each._id} value={each._id}>
                            {capitalize(each.companyName)}
                          </option>
                        ))}
                      </Input>
                      <Label>Scheduling Company</Label>
                    </div>
                    {formFields?.signingCompanyId?.error && (
                      <div className="validation-error">
                        {formFields?.signingCompanyId?.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                {formFields.signingCompanyId.value !== "" && (
                  <Col xl={4} md={6}>
                    {/* only signing company gets to see the below data */}
                    <FormGroup className="floatingLabel withInputGroup">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          placeholder=" "
                          value={formFields.signingCompanyFee.value}
                          name="signingCompanyFee"
                          onChange={(event) =>
                            this._onChangeFormField(
                              "signingCompanyFee",
                              event.target.value
                            )
                          }
                          onBlur={() => this._validateForm()}
                        />
                        <Label>Signing Company Fee</Label>
                      </InputGroup>
                      {formFields.signingCompanyFee.error && (
                        <div className="validation-error">
                          {formFields.signingCompanyFee.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                )}

                <Col xl={4} md={6}>
                  <FormGroup className="floatingLabel">
                    <Input
                      type="number"
                      placeholder=" "
                      value={formFields.witnessCount.value}
                      name="witnessCount"
                      onChange={(event) =>
                        this._onChangeFormField(
                          "witnessCount",
                          event.target.value
                        )
                      }
                      // disabled={formFields.signingType.value === "Mobile"}
                    />
                    <Label>Witness Number</Label>
                  </FormGroup>
                </Col>
              </Row>
            </div>
            <hr />

            <FormGroup style={{ maxWidth: 300 }} className="floatingLabel">
              <div className="custom-select-wrapper">
                <Input
                  type="select"
                  placeholder="Enter"
                  value={formFields.instructionId.value}
                  name="instructionId"
                  onChange={(event) =>
                    this._onChangeFormField("instructionId", event.target.value)
                  }
                >
                  <option value="">select</option>
                  {signingInstruction.map((each) => (
                    <option key={each._id} value={each._id}>
                      {capitalize(each.name)}
                    </option>
                  ))}
                </Input>
                <Label>Instruction Type</Label>
              </div>
            </FormGroup>

            <Card>
              <CardBody>
                <FormGroup
                  className={`mb-0 floatingLabel reactQuill ${
                    (formFields.instructions.value &&
                      formFields.instructions.value !== "<p><br></p>") ||
                    this.state.isFocusTextEditor
                      ? "valueAdded"
                      : ""
                  }`}
                >
                  <Label>Instructions</Label>
                  <TextEditor
                    content={formFields.instructions.value}
                    onChange={(event) =>
                      this._onChangeFormField("instructions", event)
                    }
                    onFocus={() => this.setState({ isFocusTextEditor: true })}
                    onBlur={() => this.setState({ isFocusTextEditor: false })}
                  />
                </FormGroup>
                <h2
                  style={{
                    fontSize: 14,
                    fontWeight: 500,
                    marginBottom: 20,
                    marginTop: 20,
                  }}
                >
                  Additional Instruction
                </h2>
                <CustomInput
                  type="checkbox"
                  id="isScanBackNeeded"
                  label="Scan Backs Needed"
                  className="mb-2"
                  checked={formFields.isScanBackNeeded.value}
                  onChange={(event) =>
                    this._onChangeFormField(
                      "isScanBackNeeded",
                      event.target.checked
                    )
                  }
                />
                {/* <CustomInput
                  type="checkbox"
                  id="other"
                  label="Other"
                  className="mb-2"
                  checked={this.state.isChecked}
                  onChange={this.handleCheckboxChange}
                /> */}
                {this.state.questions.length > 0
                  ? this.state.questions.map((question, index) => (
                      <CustomInput
                        type="checkbox"
                        id={`demoQuestion
                      +${index}`}
                        label={question.query}
                        className="mb-2"
                        checked={question.reply}
                        onChange={(event) =>
                          this._onChangeQuestionFormField("question", index)
                        }
                      />
                    ))
                  : ""}
              </CardBody>
            </Card>

            <Button
              color="primary"
              className="mx-auto d-block mt-4"
              size="lg"
              onClick={() =>
                this.state.formFields?.fileNumber?.value?.length
                  ? this._duplicateFileNumberCheck()
                  : this._submitCreateSigningClosing()
              }
              disabled={loading?.submitLoading}
            >
              {loading?.submitLoading ? (
                <i className="fa fa-spinner fa-spin mr-2" />
              ) : null}{" "}
              Create
            </Button>
          </div>

          <AddOrderClient
            isOpen={isOpenAddInstruction}
            toggle={() => this.setState({ isOpenAddInstruction: false })}
            onSuccess={() => this._getAllLenderClients()}
          />
          <FileChecked
            isOpen={fileChecked.isOpen}
            approved={this._approvedFileNumber}
            toggle={() => this._toggleFileCheckedModal()}
          />
          {/* {FileNumberUniqueChecked?.isOpen ? (
            <FileNumberDuplicateModal
              isOpen={FileNumberUniqueChecked.isOpen}
              toggle={() => this._toggleFileNumberUniqueCheckedModal()}
              FileNumberSubmitHandler={() => this._submitCreateSigningClosing()}
            />
          ) : null} */}

          {FileNumberUniqueChecked?.isOpen ? (
            <FileNumberDuplicateModal
              isOpen={FileNumberUniqueChecked.isOpen}
              fileNumber={formFields.fileNumber.value}
              toggle={() => this._toggleFileNumberUniqueCheckedModal()}
              existingFileNos={existingFileNos}
              FileNumberSubmitHandler={
                (value) => this._onChangeFormField("fileNumber", value)
                // this._submitCreateSigningClosing(e)
              }
            />
          ) : null}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateClosing);
