import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
} from "reactstrap";

const TemplateCommentModal = ({ isOpen, data, toggle }) => {
  
  const _onClose = () => {
    toggle();
  };

  return (
    <Modal isOpen={isOpen} toggle={_onClose} scrollable centered>
      <ModalHeader toggle={_onClose}>View Comment</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Input
            placeholder="Enter"
            value={data?.comment}
            type="textarea"
            rows={7}
            disabled
          />
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" className="modalBtnSave" onClick={_onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TemplateCommentModal;
