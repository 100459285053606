import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  CardHeader,
  CardTitle,
  Label,
} from "reactstrap";
import {
  capitalize,
  deepClone,
  errorHandler,
  formatDateHideYearIfSame,
  showToast,
} from "../../../helper-methods";
import { deleteAgentLeaves, getAllAgentLeaves } from "../../../http/http-calls";
import NewLeaveModal from "../components/new-leave-modal";
import { dayNames } from "../../../config";
import CustomTable from "./CustomTable";
import WorkingTimeSetting from "./WorkingTimeSetting";
import StandardFeeSetting from "./StandardFeeSetting";
import CustomCard from "./CustomCard";

export class AgentProfileSchedule extends Component {
  state = {
    agentLeaveList: [],
    agentLeaveTotalCount: 0,
    agentLeaveHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      { id: "date", label: "Date", noSort: true },
      { id: "description", label: "Description", noSort: true },
      { id: "action", label: "Action", noSort: true },
    ],
    agentLeaveCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    newLeaveModal: {
      isOpen: false,
      data: null,
    },
    loading: {
      showTableLoading: false,
      deleteLeaveLoadingId: null,
    },
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    },
    availability: {},
  };

  componentDidMount = () => {
    this.changeDayTimer = null;
    this._paginate();
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _setAvailabilityData = (data) => {
    this.setState({
      availability: data?.availability ? data?.availability : {},
    });
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { activeTab, agentDetails } = deepClone(this.props);

    if (
      activeTab === "3" &&
      (JSON.stringify(agentDetails) !==
        JSON.stringify(prevProps.agentDetails) ||
        prevProps.activeTab !== "3")
    ) {
      this._setAvailabilityData(agentDetails);
    }
  };

  _getAllAgentLeaves = () => {
    this._manageLoading("showTableLoading", true);
    const { tableConfig } = this.state;
    getAllAgentLeaves(tableConfig)
      .then((res) => {
        this.setState({
          agentLeaveList: res?.leaves || [],
          agentLeaveTotalCount: res?.totalCount || 0,
        });
        this._manageLoading("showTableLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _deleteAgentLeaves = (leaveId) => {
    this._manageLoading("deleteLeaveLoadingId", leaveId);
    deleteAgentLeaves(leaveId)
      .then((res) => {
        showToast("Leave deleted", "success");
        this._paginate();
        this._manageLoading("deleteLeaveLoadingId", null);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("deleteLeaveLoadingId", null);
      });
  };

  _onChangeDay = (day) => {
    clearTimeout(this.changeDayTimer);
    const { availability } = deepClone(this.state);
    if (availability[day]) {
      availability[day] = false;
    } else {
      availability[day] = true;
    }
    this.setState({ availability }, () => {
      this.changeDayTimer = setTimeout(() => {
        this._onSaveSchedule({ availability }, "Working Days Updated");
      }, 1000);
    });
  };

  _onToggleNewLeaveModal = (isOpen = false, data = null) => {
    this.setState({
      newLeaveModal: {
        isOpen,
        data,
      },
    });
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfig } = this.state;
    tableConfig["skip"] = (pageNumber - 1) * pageSize;
    tableConfig["limit"] = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._getAllAgentLeaves();
    });
  };

  _onSortChange = (sortName, sortOrder) => {
    this._paginate();
  };

  _onSaveSchedule = (payload, toastSuccessMsg = null) => {
    return new Promise(async (resolve, reject) => {
      const isSuccess = await this.props.onSubmitSchedule(
        payload,
        toastSuccessMsg
      );
      resolve(isSuccess);
    });
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "description": {
        return (
          <>
            <div className="leaveDescription">
              {cell ? capitalize(cell) : "N/A"}
            </div>
          </>
        );
      }
      case "date": {
        return row ? (
          <>
            <div style={{ width: 130 }}>
              {(row.from && formatDateHideYearIfSame(row.from)) || "N/A"} -{" "}
              {(row.to && formatDateHideYearIfSame(row.to)) || "N/A"}
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "action": {
        return row && row.to && new Date(row.to) > new Date() ? (
          <Button
            color="link"
            disabled={
              this.state.loading.deleteLeaveLoadingId === row._id ? true : false
            }
            onClick={() => this._deleteAgentLeaves(row._id)}
          >
            <img
              src={require("../../../assets/img/deleteIcon.svg").default}
              alt="delete"
            />
          </Button>
        ) : (
          "-"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Date</Label>
            <div>
              {row?.to || row?.from ? (
                <>
                  {(row?.from && formatDateHideYearIfSame(row?.from)) || "N/A"}{" "}
                  - {(row?.to && formatDateHideYearIfSame(row?.to)) || "N/A"}
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Description: </Label>
            <div className="leaveDescription">
              {row?.description ? <>{capitalize(row?.description)}</> : "N/A"}
            </div>
          </div>
        </li>
        {row && row?.to && new Date(row?.to) > new Date() ? (
          <li>
            <div className="dataWrap text-center">
              <Button
                color="link"
                disabled={
                  this.state.loading.deleteLeaveLoadingId === row._id
                    ? true
                    : false
                }
                onClick={() => this._deleteAgentLeaves(row._id)}
              >
                <img
                  src={require("../../../assets/img/deleteIcon.svg").default}
                  alt="delete"
                />
              </Button>
            </div>
          </li>
        ) : null}
      </ul>
    );
  };

  render() {
    const {
      agentLeaveHeaderKeys,
      agentLeaveList,
      agentLeaveTotalCount,
      loading,
      newLeaveModal,
      tableConfig,
      availability,
      agentLeaveCardHeaderKeys,
    } = this.state;

    const { agentDetails } = this.props;

    return (
      <>
        <div className="scheduleWrapper">
          <h2>Schedule Details</h2>
          <p>
            Please update your working schedule to reflect when you want to be
            available to receive order request. You can also add leave dates to
            block your calendar for special occasions.
          </p>
        </div>
        <Row>
          <Col md={6}>
            <Card className="h-100">
              <CardHeader>
                <CardTitle>Regular Working Days</CardTitle>
                <p>Highlight your working days in Blue</p>
              </CardHeader>
              <CardBody>
                <div className="workingDays">
                  {dayNames.map((day) => (
                    <Button
                      className={`${availability[day.value] ? "selected" : ""}`}
                      key={day.value}
                      onClick={() => this._onChangeDay(day.value)}
                    >
                      {day.label}
                    </Button>
                  ))}
                </div>
              </CardBody>
            </Card>
          </Col>

          {agentDetails && (
            <Col md={6} className="mt-4 mt-md-0">
              <WorkingTimeSetting
                agentDetails={agentDetails}
                onSumbit={this._onSaveSchedule}
              />
            </Col>
          )}

          {agentDetails && (
            <Col md={12} className="mt-4">
              <StandardFeeSetting
                agentDetails={agentDetails}
                onSumbit={this._onSaveSchedule}
              />
            </Col>
          )}
        </Row>
        <div className="CardWrapper">
          <div className="CardTitle">
            <h2 className="sectionTtile">Leaves</h2>
          </div>
        </div>

        <div className="hideDesktop">
          <CustomCard
            isPageStartFromOne={true}
            pageNumber={tableConfig.pageNumber}
            tableData={agentLeaveList}
            // headerKeys={agentLeaveCardHeaderKeys}
            // bodyKeys={agentClosingCardBodyKeys}
            dataFormat={this._dataFormat}
            totalCount={agentLeaveTotalCount}
            onPaginate={(pageNumber, pageSize) =>
              this._paginate(pageNumber, pageSize)
            }
            rowSelection={false}
            // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
            showTableLoading={loading.showTableLoading}
            cardHeaderFormat={this._cardHeaderFormat}
            cardDataFormat={this._cardDataFormat}
          />
        </div>

        <div className="hideMobile">
          {agentLeaveHeaderKeys && agentLeaveHeaderKeys.length && (
            <CustomTable
              striped
              className={""}
              pageNumber={tableConfig.pageNumber}
              tableData={agentLeaveList}
              headerKeys={agentLeaveHeaderKeys}
              dataFormat={this._dataFormat}
              totalCount={agentLeaveTotalCount}
              rowSelection={false}
              onPaginate={(pageNumber, pageSize) =>
                this._paginate(pageNumber, pageSize)
              }
              onSortChange={(sortName, sortOrder) =>
                this._onSortChange(sortName, sortOrder)
              }
              showTableLoading={loading.showTableLoading}
            />
          )}
        </div>
        <div className="text-center mt-2">
          <Button
            size="md"
            color="primary"
            onClick={() => this._onToggleNewLeaveModal(true)}
          >
            ADD LEAVE
          </Button>
        </div>
        {/* modal */}
        <NewLeaveModal
          isOpen={newLeaveModal.isOpen}
          data={newLeaveModal.data}
          toggle={this._onToggleNewLeaveModal}
          resetDetails={this._paginate}
        />
      </>
    );
  }
}

export default AgentProfileSchedule;
