import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  FormGroup,
  Input,
  Button,
  CustomInput,
  Collapse,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import {
  deepClone,
  showToast,
  getPhoneNumberFromBrackets,
  errorHandler,
} from "../../../helper-methods";
import { RegexConfig } from "../../../config/RegexConfig";
import { countryCodes } from "../../../config/country-codes";
import {
  createSigningcompanyUser,
  updateSigningcompanyUser,
  getUserById,
} from "../../../http/http-calls";

class AddUserModal extends Component {
  state = {
    formFields: {
      name: {
        value: "",
        error: null,
        isDirty: false,
      },
      email: {
        value: "",
        error: null,
        isDirty: false,
      },
      phone: {
        value: "",
        error: null,
        isDirty: false,
      },
      phoneCountry: {
        value: "+1",
        error: null,
        isDirty: false,
      },
    },
    masterSchedule: {
      canView: false,
    },
    dashboard: {
      canUpdateClosingStatusOrScheduling: false,
      canEditOrderEntry: false,
      canReviewDocumentsOrOrderDetail: false,
    },
    accounting: {
      invoicing: false,
      agentPayPeriod: false,
      income: false,
      expense: false,
    },
    reporting: {
      agentSchedule: false,
      statusReport: false,
      profitLoss: false,
      // reciept: false,
      summaries: false,
    },
    agents: {
      canAdd: false,
      canUpdate: false,
    },
    clients: {
      canAdd: false,
      canUpdate: false,
    },
    users: {
      canView: false,
      canAdd: false,
    },
    settings: {
      canViewOrEditOrderAutomation: false,
      canViewOrEditJobsAndFees: false,
      canViewOrEditEmailCustomization: false,
      canViewOrEditAccountingAutomation: false,
    },
    isOpenCollapse: "",
  };

  _resetModalState = () => {
    this.setState({
      formFields: {
        name: {
          value: "",
          error: null,
          isDirty: false,
        },
        email: {
          value: "",
          error: null,
          isDirty: false,
        },
        phone: {
          value: "",
          error: null,
          isDirty: false,
        },
        phoneCountry: {
          value: "+1",
          isValid: false,
          isDirty: false,
        },
      },
      masterSchedule: {
        canView: false,
      },
      dashboard: {
        canUpdateClosingStatusOrScheduling: false,
        canEditOrderEntry: false,
        canReviewDocumentsOrOrderDetail: false,
      },
      accounting: {
        invoicing: false,
        agentPayPeriod: false,
        income: false,
        expense: false,
      },
      reporting: {
        agentSchedule: false,
        statusReport: false,
        profitLoss: false,
        // reciept: false,
        summaries: false,
      },
      agents: {
        canAdd: false,
        canUpdate: false,
      },
      clients: {
        canAdd: false,
        canUpdate: false,
      },
      users: {
        canView: false,
        canAdd: false,
      },
      settings: {
        canViewOrEditOrderAutomation: false,
        canViewOrEditJobsAndFees: false,
        canViewOrEditEmailCustomization: false,
        canViewOrEditAccountingAutomation: false,
      },
      isOpenCollapse: "",
    });
  };

  _closeModal = (action) => {
    console.log("in _closeModal");
    this._resetModalState();
    this.props.toggle();
  };

  componentDidUpdate(previousProps, previousState) {
    const { isOpen, data } = this.props;

    if (isOpen && data && previousProps.isOpen !== isOpen) {
      this._getUserById(data.id);
    }
  }
  _getUserById = (id) => {
    this.setState({ loading: true });

    getUserById(id)
      .then((res) => {
        // console.log(res);
        this._setFormData(res?.user || {});
      })
      .catch((error) => {
        errorHandler(error);
        this._closeModal();
      });
  };

  _setFormData = (data) => {
    const { formFields } = deepClone(this.state);

    formFields["name"].value = data.name.full;

    formFields["phone"].value = data.phone
      ? getPhoneNumberFromBrackets(data.phone)
      : "";

    formFields["email"].value = data.email ? data.email : "";

    this.setState({
      masterSchedule: {
        canView: data.permissionSettings.masterSchedule.canView,
      },
      dashboard: {
        canUpdateClosingStatusOrScheduling:
          data.permissionSettings.dashboard.canUpdateClosingStatusOrScheduling,
        canEditOrderEntry: data.permissionSettings.dashboard.canEditOrderEntry,
        canReviewDocumentsOrOrderDetail:
          data.permissionSettings.dashboard.canReviewDocumentsOrOrderDetail,
      },
      accounting: {
        invoicing: data.permissionSettings.accounting.invoicing,
        agentPayPeriod: data.permissionSettings.accounting.agentPayPeriod,
        income: data.permissionSettings.accounting.income,
        expense: data.permissionSettings.accounting.expense,
      },
      reporting: {
        agentSchedule: data.permissionSettings.reporting.agentSchedule,
        statusReport: data.permissionSettings.reporting.statusReport,
        profitLoss: data.permissionSettings.reporting.profitLoss,
        // reciept: data.permissionSettings.reporting.reciept,
        summaries: data.permissionSettings.reporting.summaries,
      },
      agents: {
        canAdd: data.permissionSettings.agents.canAdd,
        canUpdate: data.permissionSettings.agents.canUpdate,
      },
      clients: {
        canAdd: data.permissionSettings.clients.canAdd,
        canUpdate: data.permissionSettings.clients.canUpdate,
      },
      users: {
        canView: data.permissionSettings.users.canView,
        canAdd: data.permissionSettings.users.canAdd,
      },
      settings: {
        canViewOrEditOrderAutomation:
          data.permissionSettings.settings.canViewOrEditOrderAutomation,
        canViewOrEditJobsAndFees:
          data.permissionSettings.settings.canViewOrEditJobsAndFees,
        canViewOrEditEmailCustomization:
          data.permissionSettings.settings.canViewOrEditEmailCustomization,
        canViewOrEditAccountingAutomation:
          data.permissionSettings.settings.canViewOrEditAccountingAutomation,
      },
    });
    this.setState({ formFields, loading: false });
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // Validation
      this._validateForm();
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty) {
          switch (key) {
            case "name": {
              if (formFields[key]?.value?.trim().length) {
                if (
                  formFields[key]?.value?.trim().length > 1 &&
                  /^[0-9!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]*$/.test(
                    //eslint-disable-line
                    String(formFields[key]?.value).toLowerCase()
                  )
                ) {
                  formFields[key].error =
                    "*Name containing only number and special character are not allowed";
                  isFormValid = false;
                  formFields[key].isDirty = true;
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            // case "name": {
            //   if (
            //     formFields[key].value &&
            //     formFields[key].value.trim().length
            //   ) {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   } else {
            //     formFields[key].isDirty = true;
            //     formFields[key].error = "*Required";
            //     isFormValid = false;
            //   }
            //   break;
            // }
            // case "type": {
            //   if (
            //     formFields[key].value &&
            //     formFields[key].value.trim().length
            //   ) {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   } else {
            //     formFields[key].isDirty = true;
            //     formFields[key].error = "*Required";
            //     isFormValid = false;
            //   }
            //   break;
            // }
            case "email": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                if (
                  RegexConfig.email.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "phone": {
              if (formFields[key].value.length) {
                if (
                  RegexConfig.phone.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  isFormValid = false;
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Phone Number";
                }
              } else {
                // formFields[key].isDirty = false;
                // formFields[key].error = null;
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default: {
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _saveUserData = async () => {
    await this._markAllFieldDirty();

    const isFormValid = await this._validateForm();

    if (isFormValid) {
      this.setState({ loading: true });

      const { formFields } = deepClone(this.state);
      const splitName = formFields.name.value.trim().split(" ");
      const countryDialCode = countryCodes.find(
        (obj) => obj.dial_code === formFields.phoneCountry.value
      )?.code;

      let data = {
        // name: {
        // first: splitName[0],
        // last: splitName[1] ? splitName[1] : "",
        // },
        name: {
          first: splitName.slice(0, 1).join(" "),
          last: splitName[1] ? splitName.slice(1).join(" ") : "",
        },
        email: formFields.email.value,
        phone: `(${formFields.phoneCountry.value})${formFields.phone.value}`,
        phoneCountry: countryDialCode,
        permissionSettings: {
          accounting: this.state.accounting,
          dashboard: this.state.dashboard,
          masterSchedule: this.state.masterSchedule,
          reporting: this.state.reporting,
          agents: this.state.agents,
          clients: this.state.clients,
          users: this.state.users,
          settings: this.state.settings,
        },
      };

      this._callAddOrEditApi(data);
    }
  };
  _callAddOrEditApi = (payload) => {
    if (this.props.data) {
      this._updateUser(payload, this.props.data.id);
    } else {
      this._createUser(payload);
    }
  };

  _createUser = (payload) => {
    createSigningcompanyUser(payload)
      .then((res) => {
        showToast("Team member added successfully", "success");
        this.props.resetTable();
        this._closeModal();
        this.setState({ loading: false });
      })
      .catch((error) => {
        errorHandler(error);
        this.setState({ loading: false });
      });
  };

  _updateUser = (payload, id) => {
    updateSigningcompanyUser(id, payload)
      .then((res) => {
        showToast("Team member details updated successfully", "success");
        this.props.resetTable();
        this._closeModal();
        this.setState({ loading: false });
      })
      .catch((error) => {
        errorHandler(error);
        this.setState({ loading: false });
      });
  };

  _onChangePermission = (key, each, value) => {
    // console.log(key,each,value)
    this.setState({
      [key]: {
        ...this.state[key],
        [each]: value,
      },
    });

    // accounting:{
    //   invoicing:false,
    // agentPayPeriod:false,
    // income:false,
    // expense:false,
    // },
    // reporting:{
    // agentSchedule:false,
    // statusReport:false,
    // profitLoss:false,
    // reciept:false,
    // },
    // agents:{
    //     canAdd:false,
    //     canUpdate:false
    // },
    // clients:{
    //     canAdd:false,
    //     canUpdate:false
    // },
    // users:{
    // canView:false
    // }
  };

  _handleCollapse = (value) => {
    this.setState({
      isOpenCollapse: this.state.isOpenCollapse !== value ? value : "",
    });
  };

  _renderPermission = () => {
    const { isTitleCompany } = deepClone(this.props);
    // let permissions = {
    //   masterSchedule: ["canView"],
    //   dashboard: [
    //     "canUpdateClosingStatusOrScheduling",
    //     "canEditOrderEntry",
    //     "canReviewDocumentsOrOrderDetail",
    //   ],
    //   accounting: ["invoicing", "agentPayPeriod", "income", "expense"],
    //   reporting: ["agentSchedule", "statusReport", "profitLoss", "summaries"],
    //   agents: ["canAdd", "canUpdate"],
    //   clients: ["canAdd", "canUpdate"],
    //   users: ["canView", "canAdd"],
    //   settings: [
    //     "canViewOrEditOrderAutomation",
    //     "canViewOrEditJobsAndFees",
    //     "canViewOrEditEmailCustomization",
    //     "canViewOrEditAccountingAutomation",
    //   ],
    // };

    return Object.entries(this.props.permissions).map(([key, item], index) => {
      if (isTitleCompany && key === "accounting") {
        // Check your condition and remove "income" and "expense" if necessary
        item = item.filter(
          (permission) =>
            permission !== "invoicing" &&
            permission !== "income" &&
            permission !== "expense"
        );
      }
      if (isTitleCompany && key === "reporting") {
        // Check your condition and remove "income" and "expense" if necessary
        item = item.filter((permission) => permission !== "profitLoss");
      }
      return (
        <>
          {console.log("object", key, item)}
          {/* <div>
            <div id={`toggler${index}`} className="permissionHead">
            <i className={`fa fa-plus`} />
              <span>
                {key
                  .replace(/([a-z])([A-Z])/, "$1 $2")
                  .charAt(0)
                  .toUpperCase() +
                  key.replace(/([a-z])([A-Z])/, "$1 $2").slice(1)}
              </span>
              
            </div>

            <UncontrolledCollapse
              toggler={`#toggler${index}`}
              className="permissionBody"
            >
              {item.map((each) => {
                return (
                  <FormGroup check>
                    <Label check>
                      <Input
                        defaultValue=""
                        type="checkbox"
                        checked={this.state[key][each]}
                        onChange={(e) =>
                          this._onChangePermission(key, each, e.target.checked)
                        }
                      />
                      {each.includes("can")
                        ? each
                            .slice(3)
                            .replace(
                              /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g,
                              "$1$4 $2$3$5"
                            )
                            .charAt(0)
                            .toUpperCase() +
                          each
                            .slice(3)
                            .replace(
                              /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g,
                              "$1$4 $2$3$5"
                            )
                            .slice(1)
                        : each
                            .replace(
                              /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g,
                              "$1$4 $2$3$5"
                            )
                            .charAt(0)
                            .toUpperCase() +
                          each
                            .replace(
                              /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g,
                              "$1$4 $2$3$5"
                            )
                            .slice(1)}
                      <span className="form-check-sign" />
                    </Label>
                  </FormGroup>
                );
              })}
            </UncontrolledCollapse>
          </div> */}
          <div
            className={`permissionWrapper ${
              this.state.isOpenCollapse === key ? "open" : ""
            }`}
          >
            <div
              onClick={() => this._handleCollapse(key)}
              className="permissionHead"
            >
              <i
                className={`fa fa-${
                  this.state.isOpenCollapse === key ? "minus" : "plus"
                }`}
              />

              <span>
                {key
                  .replace(/([a-z])([A-Z])/, "$1 $2")
                  .charAt(0)
                  .toUpperCase() +
                  key.replace(/([a-z])([A-Z])/, "$1 $2").slice(1)}
              </span>
            </div>
            <Collapse
              className="permissionBody"
              isOpen={this.state.isOpenCollapse === key}
            >
              {item.map((each, eachIndex) => {
                return (
                  <>
                    <CustomInput
                      type="checkbox"
                      checked={this.state[key][each]}
                      // onChange={(e) =>
                      //   this._onChangePermission(
                      //     this.state.isOpenCollapse,
                      //     permission.value,
                      //     e.target.checked
                      //   )
                      // }
                      onChange={(e) =>
                        this._onChangePermission(key, each, e.target.checked)
                      }
                      id={key + each + eachIndex}
                      label={
                        each.includes("can")
                          ? each
                              .slice(3)
                              .replace(
                                /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g,
                                "$1$4 $2$3$5"
                              )
                              .charAt(0)
                              .toUpperCase() +
                            each
                              .slice(3)
                              .replace(
                                /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g,
                                "$1$4 $2$3$5"
                              )
                              .slice(1)
                          : each
                              .replace(
                                /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g,
                                "$1$4 $2$3$5"
                              )
                              .charAt(0)
                              .toUpperCase() +
                            each
                              .replace(
                                /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g,
                                "$1$4 $2$3$5"
                              )
                              .slice(1)
                      }
                    />
                  </>
                );
              })}
            </Collapse>
          </div>
        </>
      );
    });
  };

  render() {
    const { formFields, loading } = deepClone(this.state);
    console.log("fc", this.state);
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._closeModal()}
        className="modal-dialog-scrollable"
      >
        <ModalHeader toggle={() => this._closeModal()}>
          {this.props.data ? "Update" : "Add"} Team Members
        </ModalHeader>
        <ModalBody>
          <FormGroup className="floatingLabel">
            <Input
              type="text"
              placeholder=" "
              value={formFields.name.value}
              onChange={(e) => this._onChangeFormField("name", e.target.value)}
            />
            <Label>Name</Label>
            {formFields.name.error && (
              <div className="validation-error">{formFields.name.error}</div>
            )}
          </FormGroup>
          <FormGroup className="floatingLabel">
            <Input
              type="email"
              placeholder=" "
              value={formFields.email.value}
              onChange={(e) => this._onChangeFormField("email", e.target.value)}
            />
            <Label>Email</Label>
            {formFields.email.error && (
              <div className="validation-error">{formFields.email.error}</div>
            )}
          </FormGroup>
          <FormGroup className="floatingLabel withInputGroup">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>+1</InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder=" "
                value={formFields.phone.value}
                onChange={(e) =>
                  this._onChangeFormField("phone", e.target.value)
                }
              />
              <Label>Phone</Label>
            </InputGroup>
            {formFields.phone.error && (
              <div className="validation-error">{formFields.phone.error}</div>
            )}
          </FormGroup>
          <Label>Permissions</Label>
          {this._renderPermission()}

          {/* <div className="d-flex align-items-center">
              <Label className="mb-0 mr-2">Presets</Label>
              <Input type="select" name="select">
                <option>Select</option>
                <option>Accounting</option>
                <option>Agents</option>
                <option>Clients</option>
                <option>Dashboard</option>
                <option>Master Schedule</option>
                <option>Reporting</option>
                <option>Users</option>
              </Input>
            </div> */}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={() => this._closeModal()}>
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={loading}
            onClick={this._saveUserData}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
            {this.props.data ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddUserModal;
