import React, { Component } from "react";
import {
  Button,
  Label,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "reactstrap";
import { deepClone, errorHandler, formatTime } from "../../../helper-methods";
import ReactDatetime from "react-datetime";
import moment from "moment";

class WorkingTimeSetting extends Component {
  state = {
    formData: {
      from: {
        value: "",
        error: null,
        isDirty: false,
      },
      to: {
        value: "",
        error: null,
        isDirty: false,
      },
    },
    loading: false,
  };

  _setWorkTimimg = (data) => {
    const { formData } = deepClone(this.state);

    formData["from"].value = data?.workTiming?.from
      ? moment(data.workTiming.from, "HH:mm").toISOString()
      : "";
    formData["to"].value = data?.workTiming?.to
      ? moment(data.workTiming.to, "HH:mm").toISOString()
      : "";

    this.setState({ formData });
  };

  componentDidMount = () => {
    const { agentDetails } = this.props;

    this._setWorkTimimg(agentDetails);
  };

  _validateFormData = () => {
    return new Promise((resolve, reject) => {
      const { formData } = this.state;

      let isFormValid = true;

      Object.keys(formData).forEach((key) => {
        if (formData[key].isDirty) {
          switch (key) {
            case "to":
            case "from": {
              if (formData[key].value && formData[key].value.length) {
                if (
                  formData["to"].value &&
                  formData["from"].value &&
                  (formData["from"].value === formData["to"].value ||
                    new Date(formData["from"].value) >
                      new Date(formData["to"].value))
                ) {
                  formData[key].isDirty = true;
                  formData[key].error = "*Invalid Time";
                  isFormValid = false;
                } else {
                  formData[key].isDirty = false;
                  formData[key].error = null;
                }
              } else {
                formData[key].isDirty = true;
                formData[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default: {
            }
          }
        }
      });

      this.setState({ formData }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeWorkTime = (fieldName, value = "") => {
    const { formData } = deepClone(this.state);
    formData[fieldName].value = value ? new Date(value).toISOString() : "";
    formData[fieldName].isDirty = true;
    this.setState({ formData }, () => {
      this._validateFormData();
    });
  };

  _markAllFormDataFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formData } = this.state;
      Object.keys(formData).forEach((e) => {
        formData[e].isDirty = true;
      });
      this.setState({ formData }, () => resolve(true));
    });
  };

  _updateWorkTiming = async (e) => {
    try {
      if (e) e.preventDefault();

      await this._markAllFormDataFieldDirty();

      const isFormValid = await this._validateFormData();

      if (!isFormValid) {
        return;
      }

      this.setState({ loading: true });

      const { formData } = deepClone(this.state);

      let payload = {
        workTiming: {
          from: moment(formData.from.value).format("HH:mm"),
          to: moment(formData.to.value).format("HH:mm"),
        },
      };

      await this.props.onSumbit(payload, "Work Timing Updated");

      this.setState({ loading: false });
    } catch (error) {
      errorHandler(error);
      this.setState({ loading: false });
    }
  };

  render() {
    const { formData, loading } = deepClone(this.state);

    return (
      <Card className="h-100">
        <CardHeader>
          <CardTitle>Work Timings</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col xl={6} lg={12} md={12}>
              <div className="mb-3">
                <Label>Start Time</Label>
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Select",
                    value: formatTime(formData.from.value),
                    disabled: loading,
                  }}
                  value={
                    formData.from.value ? new Date(formData.from.value) : ""
                  }
                  onChange={(e) => this._onChangeWorkTime("from", e._d)}
                  dateFormat={false}
                  timeFormat={true}
                  timeConstraints={{ minutes: { step: 30 } }}
                />
                {formData.from.error && (
                  <div className="validation-error">{formData.from.error}</div>
                )}
              </div>
            </Col>

            <Col xl={6} lg={12} md={12}>
              <div className="mb-3">
                <Label>End Time</Label>
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Select",
                    value: formatTime(formData.to.value),
                    disabled: loading,
                  }}
                  value={formData.to.value ? new Date(formData.to.value) : ""}
                  onChange={(e) => this._onChangeWorkTime("to", e._d)}
                  dateFormat={false}
                  timeFormat={true}
                  timeConstraints={{ minutes: { step: 30 } }}
                />
                {formData.to.error && (
                  <div className="validation-error">{formData.to.error}</div>
                )}
              </div>
            </Col>
          </Row>
          <div className="text-center">
            <Button
            size="md"
              color="primary"
              disabled={loading}
              onClick={this._updateWorkTiming}
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
              Update
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default WorkingTimeSetting;
