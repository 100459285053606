import * as _deepClone from "clone-deep";
import { toast as ToastsStore } from "react-toastify";
import moment from "moment";
import { clearUserData } from "../redux/actions/user-data";
import { countryCodes } from "../config/country-codes";
import S3BucketUploader from "../s3-bucket-uploader";
import { getToken } from "../http/token-interceptor";
import { cloudinaryImageUpload } from "../http/http-calls";
import { uploadFilePercentage } from "../redux/actions/upload-file-percentage";
import { PostManager } from "../post-manager";
import { UploadQueueManager } from "../upload-queue-manager";
import momenttz from "moment-timezone";
import { store as REDUX_STORE } from "../redux/store";
import {
  AWS_IMAGE_BUCKET_NAME,
  AWS_PDF_BUCKET_NAME,
  AWS_VIDEO_BUCKET_NAME,
  BASE_URL,
} from "../config";
import Gleap from "gleap";
import { clearListData } from "../redux/actions/list";

export const logout = (navRef = null) => {
  Gleap.clearIdentity();
  REDUX_STORE.dispatch(clearUserData());
  REDUX_STORE.dispatch(clearListData());
  // Clear other reducers data also
  // let removeLocalstorage = [
  //   "companyexpensesummary",
  //   "companyClosingdashboard",
  //   "agentClosingdashboard",
  //   "agentpayperiod",
  //   "agentschedule",
  //   "agents",
  //   "agentschedule",
  //   "companyschedule",
  //   "agentexpense",
  //   "companyexpense",
  //   "agentIncome",
  //   "companyIncome",
  //   "agentinvoice",
  //   "companyinvoice",
  //   "agentmileage",
  //   "agentprofitloss",
  //   "companyprofitloss",
  //   "companystatusreport",
  //   "agentstatusreport",
  //   "agentexpensesummary",
  //   "companyexpensesummary",
  //   "amountPerAct",
  // ];
  // removeLocalstorage.forEach((item) => item && localStorage.removeItem(item));
  localStorage.clear();

  if (navRef) {
    navRef.replace("/login");
    window.location.reload();
  }
};

export const deepClone = (data) => {
  return _deepClone(data);
};

export const showToast = (
  message,
  type = "error",
  duration = 4000,
  isDismissMessage = false,
  toastId
) => {
  if (isDismissMessage) {
    ToastsStore.dismiss();
  }

  ToastsStore[type](message, {
    position: ToastsStore.POSITION.BOTTOM_RIGHT,
    autoClose: duration,
    toastId,
  });
};

export const errorHandler = (error, statusCode) => {
  showToast(
    error?.reason?.length || error?.message?.length || error?.length
      ? error?.reason || error?.message || error
      : "Something went wrong, Try again later.",
    "error",
    4000,
    false,
    error?.reason || error?.message || statusCode
  );
  console.log(error);
};

export const sleepTime = (n) => new Promise((r) => setTimeout(() => r(), n));

export const sortedThreads = (arr, attr) => {
  return arr.sort((t1, t2) => {
    return new Date(t2[attr]) - new Date(t1[attr]);
  });
};

export const formatCurrencyValue = (data) => {
  if (!data && data !== 0) return "";

  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  });
  let currency = formatter.format(data);
  if (currency && currency.split(".")[1] === "00") {
    return currency.split(".")[0]; /* $2,500 */
  }
  return currency; /* $2,500.15 */
};

export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

export const getPhoneNumberFromBrackets = (number) => {
  let phone = "";
  if (number) {
    if (number.includes(")")) {
      phone = number.split(")")[1];
    } else {
      phone = number;
    }
  }
  return phone;
};

/**
 * if year is same then only return month and date in format
 *
 * @param {Date} date
 * @returns - date format MMM DD or MMM DD, YYYY
 */
export const formatDateHideYearIfSame = (date) => {
  if (moment().isSame(date, "year")) {
    return moment(date).format("MMM DD");
  } else {
    return moment(date).format("MMM DD, YYYY");
  }
};

export const formatDate = (date, isHideSameYear = false) => {
  if (!date) return "";

  if (isHideSameYear && moment().isSame(new Date(date), "year")) {
    return moment(new Date(date)).format("MMM DD");
  }

  return moment(new Date(date)).format("MMM DD, YYYY");
};

export const formatDateAndTime = (date) => {
  if (!date) return "";

  return moment(date).format("MMM DD, YYYY - hh:mm a");
};

export const formatDateAndTimeForIncomeList = (date) => {
  if (!date) return "";
  var m = moment(new Date(date));
  // Use moment(Date) if your input is a JS Date
  //var m = moment(date);
  m.set({ h: 6 });
  console.log(m.format());
  return m.format();
  // return moment(new Date(date)).format("MMM DD, YYYY - hh:mm a");
};

export const formatTime = (date) => {
  if (!date) return "";

  return moment(date).format("hh:mm A");
};

export const formatTime24hour = (date) => {
  if (!date) return "";

  return moment(new Date(date)).format("HH:mm");
};

// export const formatDateAndTimeForNotificationPage = (inputTime) => {
//   if (!inputTime) return "";

//   // return moment(new Date(date)).format("MMM DD, hh:mm A");
//   const currentDate = moment().format("YYYY-MM-DD");

//   // Get yesterday's date
//   const yesterdayDate = moment().subtract(1, "day").format("YYYY-MM-DD");

//   // Parse and format input time
//   const inputDate = moment(inputTime).format("YYYY-MM-DD");

//   // Check if input date is today, yesterday, or other
//   if (inputDate === currentDate) {
//     // console.log("The input time is today.");
//     return "Today";
//   } else if (inputDate === yesterdayDate) {
//     // console.log("The input time is yesterday.");
//     return "Yesterday";
//   } else {
//     return moment(new Date(inputTime)).format("MMM DD, hh:mm A");

//     // console.log("The input time is neither today nor yesterday.");
//   }
// };

export const formatDateAndTimeForNotificationPage = (date) => {
  if (!date) return "";

  return moment(new Date(date)).format("MMM DD, hh:mm A");
};

export const formatPhoneNumber = (phone) => {
  if (phone) {
    if (phone.includes(")")) {
      let phoneSplit = phone.split(")");
      return `${phoneSplit[0].slice(1)} ${phoneSplit[1]}`;
    } else {
      return phone;
    }
  } else {
    return "N/A";
  }
};

export const getCountryDialCodeFromCountryCode = (code) => {
  return new Promise((resolve, reject) => {
    const countryCode = countryCodes.find((obj) => obj.code === code);
    resolve(countryCode ? countryCode.dial_code : "");
  });
};

export const getYesterdayDate = () => {
  return moment().subtract(1, "day");
};

export const extractQueryParams = () => {
  let {
    location: { search: queryParamString },
  } = window;
  let params = {};
  if (queryParamString.length > 1 && queryParamString.indexOf("?") > -1) {
    queryParamString = queryParamString.replace("?", "");
    queryParamString = decodeURIComponent(queryParamString);
    if (queryParamString.indexOf("&") === -1) {
      // Contains only one param
      const paramParts = queryParamString.split("=");
      params[paramParts[0]] = paramParts[1];
    } else {
      // Contains multiple params
      const queryParams = queryParamString.split("&");
      queryParams.forEach((queryParam) => {
        const paramParts = queryParam.split("=");
        params[paramParts[0]] = paramParts[1];
      });
    }
  }
  return params;
};

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  let byteCharacters = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const convertb64Image = (ImageURL, fileName) => {
  // Split the base64 string in data and contentType
  let block = ImageURL.split(";");
  // Get the content type
  let contentType = block[0].split(":")[1]; // In this case "image/gif"
  // get the real base64 content of the file
  let realData = block[1].split(",")[1]; // In this case "iVBORw0KGg...."

  // Convert to blob
  let blob = b64toBlob(realData, contentType);

  // Create a FormData and append the file
  let fd = new FormData();
  fd.append("file", blob, fileName);
  return fd;
};

export const onUploadProgress = (evt, onProgressCallback) => {
  let uploadPercentage = parseInt((evt.loaded * 100) / evt.total) + "%";

  onProgressCallback(uploadPercentage);
};

export const onComplete = (error, success) => {
  console.log("error, success :", error, success);
};

// program to get the file extension
export const getFileExtension = (filename) => {
  // get file extension
  const extension = filename.split(".").pop();
  return extension;
};

export const uploadPhotoToCloudinary = (
  photo,
  type = "blob",
  fileName = null,
  fileType = "image",
  // isLargeFile = false,
  bucketName,
  onProgressCallback = (uploadPercentage) => {
    REDUX_STORE.dispatch(uploadFilePercentage(uploadPercentage));
  },
  isFakeCall = false
) => {
  console.log("type,photo, fileType :>> ", type, photo, fileType);
  return new Promise(async (resolve, reject) => {
    if (isFakeCall) {
      resolve("");
    } else {
      if (type === "blob") {
        fileName = Math.random().toString(36).substring(2);

        if (photo.name) {
          fileName = `${fileName}.${getFileExtension(photo.name)}`;
        } else {
          fileName = fileName + (fileType === "image" ? ".jpg" : ".pdf");
        }
        console.log("fileName >> ", fileName);
        if (fileType === "image" || fileType === "pdf") {
          const token = await getToken();

          try {
            const config = await S3BucketUploader.getCreds(
              BASE_URL + "/awstempcreds",
              token
            );
            //  Initialize S3 Uploader
            const s3Uploader = new S3BucketUploader(config);
            const s3Response = await s3Uploader.uploadFile(
              photo,
              onComplete,
              (e) => onUploadProgress(e, onProgressCallback),
              fileType,
              bucketName
            );
            resolve(s3Response.Location);
          } catch (error) {
            reject(error);
          }
        }
      } else {
        // Check if filename provided
        if (!fileName || !fileName.length) {
          // Not provided, so generate random one
          if (fileType === "video") {
            fileName = photo.name;
          } else {
            fileName =
              Math.random().toString(36).substring(2) + fileType === "image"
                ? ".jpg"
                : ".pdf";
          }
        }
        // this.convertb64Image(photo, fileName);
        if (fileType === "video") {
          const token = await getToken();

          const config = await S3BucketUploader.getCreds(
            BASE_URL + "/awstempcreds",
            token
          );
          //  Initialize S3 Uploader
          const s3Uploader = new S3BucketUploader(config);
          try {
            const s3Response = await s3Uploader.uploadFile(
              // convertb64Image(photo, fileName),
              photo,
              onComplete,
              (e) => onUploadProgress(e, onProgressCallback),
              fileType,
              bucketName
            );

            resolve(s3Response.Location);
          } catch (error) {
            reject(error);
          }
        } else {
          cloudinaryImageUpload(convertb64Image(photo, fileName)).then(
            (cloudinaryResponse) => {
              resolve(cloudinaryResponse.secure_url);
            }
          );
        }
      }
    }
  });
};

export const getAWSBucketName = (type) => {
  switch (type) {
    case "image":
      return AWS_IMAGE_BUCKET_NAME;
    case "pdf":
      return AWS_PDF_BUCKET_NAME;
    case "video":
      return AWS_VIDEO_BUCKET_NAME;
    default:
      return null;
  }
};

/**
 * input - address object;
 * address = {
 *  street: String,
 *  line1: String,
 *  line2: String,
 *  city: String,
 *  state: String,
 *  zip: String,
 * }
 *
 * @param {*} address - Object
 * @returns - address in single line text
 */
export const formatAddressInSingleText = (address) => {
  if (!address || !Object.keys(address)?.length) {
    return "";
  }

  let addressFormat = "";

  if (address?.street) {
    addressFormat += `${address?.street} `;
  }

  if (address?.line1) {
    addressFormat += `${address?.line1} `;
  }

  if (address?.line2) {
    addressFormat += `${address?.line2} `;
  }

  if (address?.city) {
    addressFormat += `${address?.city} `;
  }

  if (address?.state) {
    addressFormat += `${address?.state} `;
  }

  if (address?.zip) {
    addressFormat += address?.zip;
  }

  if (address?.country) {
    addressFormat = addressFormat.trim() + ` ${address?.country}`;
  }

  return addressFormat;
};

/**
 * input - address object;
 * address = {
 *  line1: String,
 *  line2: String,
 *  city: String,
 *  state: String,
 *  zip: String,
 * }
 *
 * @param {*} address - Object
 * @returns - open google map on new tab
 */
export const openGoogleMapOnNewTab = (address) => {
  let addressFormat = formatAddressInSingleText(address);

  // if(addressFormat.includes('%')) {
  //   addressFormat = addressFormat.split('%').join(' ')
  // }

  if (addressFormat) {
    window.open(`https://www.google.com/maps/place/${addressFormat}`, "_blank");
  }
};

/**
 * input - address object;
 * address = {
 *  line1: String,
 *  line2: String,
 *  city: String,
 *  state: String,
 *  zip: String,
 * }
 *
 * @param {*} address - Object
 * @returns - open apple map on new tab
 */
export const openAppleMapOnNewTab = (address) => {
  let addressFormat = formatAddressInSingleText(address);

  if (addressFormat) {
    window.open(`https://maps.apple.com/?address=${addressFormat}`, "_blank");
  }
};

export const openUrlOnNewTab = (url) => {
  if (!url) return;

  window.open(url, "_blank");
};

export const extractSiteNameFromUrl = (url) => {
  if (!url) return;

  return url?.replace(/^https?:\/\//, "")?.replace(/^www\./, "");
};

/**
 * uploadFiles is Object Array;
 * object key is;
 * - uploadData
 * - previewBlob
 * - type
 * - forKeyName (optional) return same value for file matching
 *
 * @param {Array} uploadFiles - file Object Array
 * @returns Array Object; object key is;
 * - title
 * - url
 * - docType
 * - forKeyName (return if provided)
 */
export const uploadFileOnServer = (uploadFiles) => {
  return new Promise((resolve, reject) => {
    const uploadedFiles = [];

    if (uploadFiles && uploadFiles.length) {
      let postID = PostManager.addMediaFilesCount(uploadFiles.length);

      PostManager.onAllMediaFilesUploadCompleted(postID, async (id) => {
        if (id.postID === postID) {
          console.log("uploadedFiles :>> ", uploadedFiles);
          await sleepTime(500);
          resolve(uploadedFiles);
          PostManager.deletePostID(postID);
        } else {
          return;
        }
      });

      uploadFiles.forEach((uploadFile, index) => {
        let mediaData = {
          file: uploadFile.uploadData,
          blobObject: uploadFile.previewBlob,
        };

        if (!uploadFile.type) {
          uploadFile.type = uploadFile.uploadData.type.split("/")[0];
        }

        const uploadId = UploadQueueManager.addMediaToQueue(
          mediaData,
          uploadFile.type
        );

        // Listen for upload complete
        UploadQueueManager.onUploadComplete(uploadId, async (mediaResponse) => {
          PostManager.onSingleMediaFileUploadCompleted(postID);
          console.log("mediaResponse", mediaResponse, mediaResponse.fileUrl);
          // Upload complete
          // Get content id from backend
          uploadedFiles.push({
            title: addUnixTimeToString(mediaResponse.data.media.file.name),
            url: mediaResponse.fileUrl,
            docType:
              uploadFile.type === "pdf"
                ? getFileExtension(uploadFile.uploadData.name)
                : uploadFile.type,
            forKeyName: uploadFile.forKeyName,
          });
        });
      });
    }
  });
};

export const copyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  showToast("Copy to clipboard", "success");
};

export const getDateRangeValue = (value = "month") => {
  let dateRangeValue = null;

  const currentDate = moment().endOf("day")._d;

  if (value) {
    const startOfDate = moment().startOf(value)._d;
    dateRangeValue = [startOfDate, currentDate];
  }

  return dateRangeValue;
};

export const getDateRangeValueSummarie = (value = "month") => {
  let dateRangeValue = null;
  // const currentDate = moment().endOf("month")._d;

  // if (value) {
  //   const startOfDate = moment().startOf(value)._d;
  //   dateRangeValue = [startOfDate, currentDate];
  // }

  switch (value) {
    case "day": {
      const currentDate = moment().endOf("day")._d;
      const startOfDate = moment().startOf(value)._d;
      return (dateRangeValue = [startOfDate, currentDate]);
    }
    case "week": {
      const currentDate = moment().endOf("week")._d;
      const startOfDate = moment().startOf(value)._d;
      return (dateRangeValue = [startOfDate, currentDate]);
    }
    case "month": {
      const currentDate = moment().endOf("month")._d;
      const startOfDate = moment().startOf(value)._d;
      return (dateRangeValue = [startOfDate, currentDate]);
    }
    case "year": {
      const currentDate = moment().endOf("year")._d;
      const startOfDate = moment().startOf(value)._d;
      return (dateRangeValue = [startOfDate, currentDate]);
    }
    default: {
      return dateRangeValue;
    }
  }
  // return dateRangeValue;
};

// dates excluding present and future dates
export const isPastDate = (date) => {
  try {
    if (!date) return "";

    return moment(date).isBefore(moment().subtract(1, "day"));
  } catch (error) {
    console.log("error>>", error);
    return "";
  }
};

export const isTodayOrfutureDate = (itemData) => {
  try {
    if (!itemData?.appointmentDate || !itemData?.closingAddress?.timeZone)
      return "";

    return !moment()
      .tz(itemData.closingAddress.timeZone)
      .isAfter(
        momenttz.tz(itemData.appointmentDate, itemData.closingAddress.timeZone),
        "days"
      );
  } catch (error) {
    console.log("error>>", error);
    return "";
  }
};

export const agentTodayOrFutureDate = (itemData) => {
  try {
    if (!itemData?.appointmentDate || !itemData?.closingAddress?.timeZone)
      return "";

    return moment()
      .tz(itemData.closingAddress.timeZone)
      .isSameOrBefore(
        momenttz.tz(itemData.appointmentDate, itemData.closingAddress.timeZone),
        "days"
      );
  } catch (error) {
    console.log("error>>", error);
    return "";
  }
};

export const formatDateMoment = (value) => {
  return moment(value).format("DD/MM/YYYY HH:mm");
};

export const formatOnlyDateMoment = (value) => {
  return value && moment(value).format("DD/MM/YYYY");
};

export const formatDateMomentMonthFirst = (value) => {
  return value && moment(value).format("MM/DD/YYYY");
};

export const formatDateAsPerTimeZOne = (value, timezone) => {
  if (!value) return "";

  if (moment().isSame(value, "year")) {
    return momenttz.tz(value, timezone).format("MMM DD, hh:mm A");
  } else {
    return momenttz.tz(value, timezone).format("MMM DD, YYYY, hh:mm A");
  }
};

export const formatDateOnlyAsPerTimeZone = (value, timeZone) => {
  if (moment().isSame(value, "year")) {
    return momenttz.tz(value, timeZone).format("MMM DD");
  } else {
    return momenttz.tz(value, timeZone).format("MMM DD, YYYY");
  }
};

export const formatDateAsPerTimeZoneWithDateAndTime = (value, timezone) => {
  return momenttz.tz(value, timezone).format("MMM DD, YYYY, hh:mm A");
};

export const formatDateAsPerTimeZoneWithYear = (value, timezone) => {
  return momenttz.tz(value, timezone).format("MMM DD YYYY");
};

export const formatDateAsPerTimeZoneIsoFormat = (value, timezone) => {
  return momenttz.tz(value, timezone).toISOString();
};

export const formatDateAndTimeAsPerTimeZone = (date, timeZone) => {
  if (!date) return "";

  return moment(date).tz(timeZone).format("MMM DD, YYYY - hh:mm a");
};

export const formatTimeAsPerTimeZone = (date, timeZone) => {
  if (!date) return "";

  return moment(date).tz(timeZone).format("hh:mm A");
};

export const getTimeZoneAbbr = (value, timezone) => {
  return momenttz.tz(value, timezone).zoneAbbr();
};

export const getDayFromDate = (value) => {
  return moment(value).format("dddd").toLowerCase();
};

export const enableTimeBetweenStartTimeAndEndTime = (date, timeZone) => {
  const currentTime = moment.tz(timeZone);
  // const startTime = moment.tz(date, timeZone);
  const startTime = moment.tz(date, timeZone).startOf("day");
  const endTime = moment.tz(date, timeZone).endOf("day");

  return moment(currentTime).isBetween(startTime, endTime, "second", []);
};

export const checkPermission = (pageName, featureName) => {
  const { userData } = REDUX_STORE.getState();
  if (userData.user?.permissionSettings) {
    // if (
    //   featureName === "canEditOrderEntry" ||
    //   featureName === "canReviewDocumentsOrOrderDetail"
    // ) {
    //   return (
    //     featureName &&
    //     pageName &&
    //     userData.user.permissionSettings &&
    //     userData.user.permissionSettings[pageName][
    //       "canUpdateClosingStatusOrScheduling"
    //     ] &&
    //     userData.user.permissionSettings[pageName][featureName]
    //   );
    // } else {
    return (
      featureName &&
      pageName &&
      userData.user.permissionSettings &&
      userData.user.permissionSettings[pageName][featureName]
    );
    // }
  } else {
    return true;
  }
};

export const isRegularUser = () => {
  const { userData } = REDUX_STORE.getState();

  if (userData?.user?.isSubscribed) {
    if (
      userData?.user?._subscription?.plan === "Premium" ||
      userData?.user?._subscription?.plan === "Pro-Plus"
    ) {
      if (userData?.user?._subscription?.isExpired) {
        return false;
      } else {
        return true;
      }
    } else {
      console.log("first");
      return false;
    }
  } else {
    console.log("second");
    return false;
  }
};

export const isProPlusUser = () => {
  const { userData } = REDUX_STORE.getState();

  if (userData?.user?.isSubscribed) {
    if (userData?.user?._subscription?.plan === "Pro-Plus") {
      if (userData?.user?._subscription?.isExpired) {
        if (userData?.user?._subscription?.isBetaMode) {
          return true;
        }
        return false;
      } else {
        return true;
      }
    } else {
      console.log("first");
      return false;
    }
  } else {
    console.log("second");
    return false;
  }
};

export const structureQueryParams = (params) => {
  let queryStrings = "?";
  const keys = Object.keys(params);
  keys.forEach((key, index) => {
    queryStrings += key + "=" + params[key];
    if (params[keys[index + 1]]) {
      queryStrings += "&";
    }
  });
  return queryStrings;
};

export const getLoggedInUserId = () => {
  try {
    const userId = REDUX_STORE.getState().userData?.user?._id;
    return userId;
  } catch (error) {
    console.log("error>>", error);
    return null;
  }
};

// export const formatNumberInShort = (num, digits = 1) => {
//   const lookup = [
//     { value: 1e12, symbol: "T" },
//     { value: 1e9, symbol: "B" },
//     { value: 1e6, symbol: "M" },
//     { value: 1e3, symbol: "k" },
//     { value: 1, symbol: "" },
//   ];
//   var item = lookup.find(function (item) {
//     return num >= item.value;
//   });
//   if (item) {
//     num = num / item.value;
//     if (num && num.toString().split(".")[1] > 0) {
//       return `${num} ${item.symbol}`;
//     }
//     return `${num.split(".")[0]} ${item.symbol}`;
//   }
//   return "0";
// };

export const formatNumberInShort = (num, digits = 1) => {
  const lookup = [
    { value: 1e12, symbol: "T" },
    { value: 1e9, symbol: "B" },
    { value: 1e6, symbol: "M" },
    { value: 1e3, symbol: "k" },
    { value: 1, symbol: "" },
  ];
  var item = lookup.find(function (item) {
    return num >= item.value;
  });
  if (item) {
    num = num / item.value;
    if (num && num?.toString()?.split(".")[1] > 0) {
      return `${num} ${item.symbol}`;
    }
    return `${num?.toString()?.split(".")[0]} ${item.symbol}`;
  }

  return "0";
};

export const convertIsoToUtc = (date) => {
  return moment(date).toDate().toString();
};
export const getMiles = (meters) => {
  return Math.floor(meters * 0.000621371192);
};

export const splitName = (name = "") => {
  name = name.trim().split(" ");

  if (name?.length) {
    name = {
      first: name.length > 1 ? name.slice(0, -1).join(" ") : name[0],
      last: name.length > 1 ? name[name.length - 1] : "",
    };
  }
  return name;
};

// name object mush have keys first and last
export const getFullName = (name = {}) => {
  let fullName = "";

  if (Object.keys(name)) {
    fullName = name?.first + " " + name?.last;
  }

  return fullName;
};

export const fourteenDaysTrialPeriod = (date) => {
  let startDate = moment(date);
  let difference = moment(startDate).diff(moment(), "days");
  // console.log("fromNow",difference>14)
  return difference > 14;
};

export const timezoneList = () => {
  const timeZones = momenttz.tz.names();
  // console.log(timeZones)
  return timeZones;
};

export const addUnixTimeToString = (inputString) => {
  // console.log("unix", inputString);

  // const currentTime = Math.floor(Date.now() / 1000); // Get current Unix time in seconds
  // const formattedString = inputString.replace(/\s/g, "_"); // Replace all blank spaces with underscores
  // const outputString = `${inputString}_${currentTime}`; // Append Unix time to end of string
  // console.log("unix", outputString);
  // return outputString;

  const fileParts = inputString.split(".");
  const extension = fileParts.pop();
  const baseFilename = fileParts.join(".");

  // Generate the Unix timestamp
  const unixTimestamp = Math.floor(Date.now() / 1000);
  // Construct the new filename with the timestamp
  const newFilename = `${baseFilename}_${unixTimestamp}.${extension}`;
  console.log("unix", newFilename);

  return newFilename;
};

export const formattedStr = (camelCaseStr) => {
  if (camelCaseStr === "biWeekly") {
    return "BiWeekly (1st/15th Day Of Month)";
  }

  return camelCaseStr
    .replace(/([a-z])([A-Z])/g, "$1 $2")
    .replace(/^./, (str) => str.toUpperCase())
    .replace(/Of/g, "Of ");
};

export const formatDateTimeFlexible = (date, inputFormat, format) => {
  return moment(date, inputFormat).format(format);
};

export const checkIsDateValid = (date) => {
  // console.log(date)
  if (date !== null) {
    let momentDate = new Date(date).getFullYear();
    // console.log(momentDate);
    return momentDate.toString().length === 4;
  }
};

export const getDropdownColor = (status = "") => {
  if (!status) return "";

  status = status.toLowerCase();

  switch (status) {
    case "active":
    case "arrived":
    case "answered":
    case "closed": {
      return "success";
    }
    case "pending":
    case "cca":
    case "in-progress":
    case "inactive": {
      return "warning";
    }
    case "cancelled":
    case "dnc":
    case "did not close": {
      return "danger";
    }
    default: {
      return "primary";
    }
  }
};
export const checkForSameDateTime = (oldTime, newTime) => {
  return moment(newTime).isSame(oldTime);
};

export const isDateTimeToday = (dateTime) => {
  // Parse the given date and time
  const parsedDateTime = moment(dateTime);

  // Get current date and time
  const currentDateTime = moment();

  // Check if the given date and time is today
  return parsedDateTime.isSame(currentDateTime, "day");
};

export const convertToTitleCase = (str) => {
  if (str === "" || str === null) return;
  return str.replace(/([A-Z])/g, " $1").replace(/^./, function (match) {
    return match.toUpperCase();
  });
};

export const splitCamelCase = (string) => {
  return (
    string?.charAt(0).toUpperCase() +
    string?.slice(1).replace(/([a-z0-9])([A-Z])/g, "$1 $2")
  );
};

export const toCamelCase = (input) => {
  const words = input.split(/\s+/);
  const camelCaseString = words
    .map((word, index) => {
      if (index === 0) {
        return word.toLowerCase();
      }
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    })
    .join("");

  return camelCaseString;
};
export const getFileTypeFromName = (fileName) => {
  const fileExtension = fileName
    .slice(fileName.lastIndexOf(".") + 1)
    .toLowerCase();

  const fileTypeMapping = {
    jpg: "image",
    jpeg: "image",
    png: "image",
    gif: "image",
    bmp: "image",
    mp3: "audio",
    wav: "audio",
    ogg: "audio",
    mp4: "video",
    mov: "video",
    avi: "video",
    mkv: "video",
  };

  return fileTypeMapping[fileExtension] || "Unknown";
};

export const removeHTMLTags = (str) => {
  if (str === null || str === "") return false;
  else str = str.toString();

  // Regular expression to identify HTML tags in
  // the input string. Replacing the identified
  // HTML tag with a null string.
  return str.replace(/(<([^>]+)>)/gi, "");
};

export const refreshFunc = (id) => {
  document.getElementById(id).classList.add("refershSpin");

  setTimeout(() => {
    document.getElementById(id).classList.remove("refershSpin");
  }, 3000);
};

export const deleteLocalStorageData = (key) => {
  if (key) {
    delete localStorage[key];
  }
};

export const convertHexToRgbColor = (hexColor) => {
  try {
    if (!hexColor) return {};

    const r = parseInt(hexColor.slice(1, 3), 16);
    const g = parseInt(hexColor.slice(3, 5), 16);
    const b = parseInt(hexColor.slice(5), 16);

    return { r, g, b };
  } catch (error) {
    console.log({ error });
    return {};
  }
};

export const getImageDimensionClass = (ref) => {
  try {
    const ratio = ref.width / ref.height;

    if (ratio <= 1.5) {
      return "square";
    } else {
      return "rectangle";
    }
  } catch (error) {
    return "rectangle";
  }
};

export const updateFavIcon = (data) => {
  if (data?.favIcon) {
    // Change the href attribute
    var appleTouchIcon = document.getElementById("titleLogo");
    var appleTouchIconsi = document.getElementById("titleLogosi");
    appleTouchIcon.href = data?.favIcon;
    appleTouchIconsi.href = data?.favIcon;
    // appleTouchIcon.href="https://web.dev/static/articles/building/an-adaptive-favicon/image/example-dark-theme-favico-6a6f6f67bc3e7.png"
  }
};

export const updateCompanyName = (data) => {
  if (data.companyName) {
    // Get the title element by its ID
    var pageTitle = document.getElementById("pageTitle");

    // Change the text content of the title
    pageTitle.textContent = data.companyName;
  }
};
export const getBrightness = (hexColor) => {
  // Convert hex color to RGB
  var r = parseInt(hexColor.slice(1, 3), 16);
  var g = parseInt(hexColor.slice(3, 5), 16);
  var b = parseInt(hexColor.slice(5, 7), 16);

  // Calculate brightness using the formula
  return (r * 299 + g * 587 + b * 114) / 1000;
};

export const setThemeColor = (theme) => {
  // console.log("plo",theme)
  if (theme) {
    try {
      document.documentElement.style.setProperty(
        "--primaryColor",
        theme.primary
      );
      document.documentElement.style.setProperty("--DarkBlue", theme.secondary);
      document.documentElement.style.setProperty("--naviBlue", theme.tertiary);
      document.documentElement.style.setProperty(
        "--textColor",
        theme.secondaryText
      );

      // Calculate the brightness of the color
      var brightness = getBrightness(theme.tertiary);

      // Set the text color based on brightness
      var activeText = brightness > 140 ? "#000000" : "#ffffff";

      document.documentElement.style.setProperty(
        "--activeTextColor",
        activeText
      );

      const colorPrimary = convertHexToRgbColor(theme.primary);
      const colorSecondaryText = convertHexToRgbColor(theme.secondaryText);

      const rgbaColorPrimary = `${colorPrimary.r}, ${colorPrimary.g}, ${colorPrimary.b}`;
      const rgbaColorSecondaryText = `${colorSecondaryText.r}, ${colorSecondaryText.g}, ${colorSecondaryText.b}`;

      document.documentElement.style.setProperty(
        "--primaryColor-rgb",
        rgbaColorPrimary
      );
      document.documentElement.style.setProperty(
        "--textColor-rgb",
        rgbaColorSecondaryText
      );
    } catch (error) {
      errorHandler(error);
    }
  }
};

export const calculateTotalPermissionsCount = (permissions) => {
  let total = 0;

  Object.keys(permissions).forEach(
    (each) => (total += Object.keys(permissions[each]).length)
  );

  return total;
};

export const capitalizeFirstLetter = (name) => {
  if (!name) return name;
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const sortByNameInAscOrder = (arr) => {
  arr?.sort((a, b) => {
    let fa = a?.client?.companyName?.toLowerCase()
        ? a?.client?.companyName?.toLowerCase()
        : getFullName(a?.client?.name)?.toLowerCase(),
      fb = b?.client?.companyName?.toLowerCase()
        ? b?.client?.companyName?.toLowerCase()
        : getFullName(b?.client?.name)?.toLowerCase();

    if (fa < fb) {
      return -1;
    }
    if (fa > fb) {
      return 1;
    }
    return 0;
  });

  return arr;
};

export const formatDateForSearchConsole = (date) => {
  // console.log("TCL: Subscribers -> _formatTime -> date", date);
  if (!date) return "";

  return moment(new Date(date)).format("yyyy-MM-DD");
};

export const countTrueValues = (array) => {
  return array.filter(Boolean).length;
};

export const safeDivide = (numerator, denominator) => {
  if (denominator === 0) {
    return 0;
  }
  return numerator / denominator;
};

export const generateGraphData = (dates, keys, isChecked) => {
  // Filter keys based on isChecked array
  const filteredKeys = keys.filter((_, index) => isChecked[index]);

  // Create the header row
  const headerRow = ["Date", ...filteredKeys];

  // Create data rows with initial values
  const dataRows = dates.map((date) => [
    date,
    ...new Array(filteredKeys.length).fill(0),
  ]);

  // Combine header row and data rows
  return [headerRow, ...dataRows];
};

export const generateDateRange = (startDate, endDate) => {
  const start = new Date(startDate);
  const end = new Date(endDate);

  const dateArray = [];
  const options = { month: "long", day: "numeric" };

  let currentDate = start;

  while (currentDate <= end) {
    const formattedDate = currentDate.toLocaleDateString("en-US", options);
    dateArray.push(formattedDate);

    // Increment the current date by one day
    currentDate.setDate(currentDate.getDate() + 1);
  }

  return dateArray;
};

export const compareDateObjects = (obj1, obj2, timeZone) => {
  console.log("obj >>", obj1, obj2, timeZone);

  try {
    const isSame = momenttz
      .tz(obj1.appointmentDate, "DD/MM/YYYY HH:mm", timeZone)
      .isSame(
        momenttz.tz(obj2.appointmentDate, "MMM DD, YYYY, hh:mm A", timeZone)
      );

    // Compare dates and all other fields
    return (
      isSame && obj1.isRangeDated === obj2.isRangeDated && obj1.tBD === obj2.tBD
    );
  } catch (error) {
    console.error(error.message);
    return false;
  }
};
export const isMMDDYYYYhhmmAMPM = (dateString) => {
  // Regular expression pattern to match MM/DD/YY or MM/DD/YYYY format with optional time zone
  const pattern =
    /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(\d{2}|\d{4})\s+((0[0-9]|1[0-9]|2[0-3]):([0-5][0-9])\s?(AM|PM|am|pm))\s?(\([A-Z]{2,4}\))?$/;
  return pattern.test(dateString);
};

export const generateUniqueFileNo = (existingFileNos, newFileNo) => {
  // Helper function to extract the base file number and suffix
  function extractBaseAndSuffix(fileNo) {
    const match = fileNo.match(/^(\D+-\d+)-(\d+)$/);
    return match
      ? { base: match[1], suffix: parseInt(match[2]) }
      : { base: fileNo, suffix: 0 };
  }

  const existingBaseFileNos = existingFileNos.map(extractBaseAndSuffix);

  // Find the base file number and its highest suffix
  const { base, suffix } = existingBaseFileNos.reduce(
    (acc, curr) => {
      if (curr.base === newFileNo) {
        return curr.suffix > acc.suffix ? curr : acc;
      }
      return acc;
    },
    { base: "", suffix: 0 }
  );

  // Generate the new file number
  if (base === newFileNo) {
    return `${newFileNo}-${suffix + 1}`;
  } else {
    return `${newFileNo}-1`;
  }
};
