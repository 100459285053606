import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  CustomInput,
  Card,
  CardBody,
} from "reactstrap";

import { errorHandler, showToast } from "../../../helper-methods";

import {
  companyEditQuickbookSetting,
  getQuickbooksSettingDetails,
} from "../../../http/http-calls";

const initialValues = {
  billsDue: "",
  invoicesDue: "",
  labelOfServices: "",
  expenseAccount: "",
  quickbookClass: "",
  softwareUsed: "",
  exportFileOrderToInvoiceAndBill: "",
  exportVendorIdAndVendorName: "",
};
export default function Quickbooksetting() {
  const [formFields, setFormFields] = useState(initialValues);
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const _onChange = (key, value) => {
    const updatedObj = { ...formFields };
    const newIsDirty = { ...isDirty };

    updatedObj[key] = value;

    setFormFields(updatedObj);
    setIsDirty(newIsDirty);
    _validateForm(updatedObj, newIsDirty);
  };

  const _settingQuickBook = async () => {
    const newIsDirty = { ...isDirty };
    Object.keys(isDirty).forEach((each) => (newIsDirty[each] = true));

    const error = await _validateForm(formFields, newIsDirty);

    if (error) {
      setLoading(true);

      let payload = {
        billsDue: formFields.billsDue,
        invoicesDue: formFields.invoicesDue,
        labelOfServices: formFields.labelOfServices,
        expenseAccount: formFields.expenseAccount,
        quickbookClass: formFields.quickbookClass,
        softwareUsed: formFields.softwareUsed,
        exportFileOrderToInvoiceAndBill:
          formFields.exportFileOrderToInvoiceAndBill,
        exportVendorIdAndVendorName: formFields.exportVendorIdAndVendorName,
      };
      try {
        await companyEditQuickbookSetting(payload);
        _getQuickbooksSettingDetails();
        showToast("Settings are updated", "success");
        setLoading(false);
      } catch (error) {
        errorHandler(error);
        setLoading(false);
      }
    }
  };

  // eslint-disable-next-line no-unused-vars

  const _validateForm = (newFormFields, isDirty) => {
    return new Promise((resolve, reject) => {
      const error = { ...errors };
      let isFormValid = true;

      Object.keys(newFormFields).forEach((fieldName) => {
        switch (fieldName) {
          case "frequency": {
            if (!newFormFields[fieldName].trim().length && isDirty[fieldName]) {
              error[fieldName] = "*Required";
              isFormValid = false;
            } else {
              delete error[fieldName];
              isDirty[fieldName] = false;
            }
            break;
          }
          case "day": {
            if (
              (newFormFields["frequency"] === "weekly" ||
                newFormFields["frequency"] === "biWeekly" ||
                newFormFields["frequency"] === "monthly") &&
              isDirty[fieldName]
            ) {
              if (!newFormFields[fieldName].trim().length) {
                error[fieldName] = "*Required";
                isFormValid = false;
              } else {
                delete error[fieldName];
                isDirty[fieldName] = false;
              }
            } else {
              delete error[fieldName];
              isDirty[fieldName] = false;
            }

            break;
          }

          default: {
          }
        }
      });
      setIsDirty(isDirty);
      setErrors(error);
      resolve(isFormValid);
    });
  };

  const _setForm = (data) => {
    const newFormFields = { ...formFields };

    newFormFields.billsDue = data?.billsDue;
    newFormFields.invoicesDue = data?.invoicesDue;
    newFormFields.labelOfServices = data?.labelOfServices;
    newFormFields.expenseAccount = data?.expenseAccount;
    newFormFields.quickbookClass = data?.quickbookClass;
    newFormFields.softwareUsed = data?.softwareUsed;
    newFormFields.exportFileOrderToInvoiceAndBill =
      data?.exportFileOrderToInvoiceAndBill;
    newFormFields.exportVendorIdAndVendorName =
      data?.exportVendorIdAndVendorName;

    setFormFields(newFormFields);
  };

  const _getQuickbooksSettingDetails = async () => {
    try {
      const res = await getQuickbooksSettingDetails();
      console.log({ res });
      if (res?.quickbookSetting) {
        _setForm(res?.quickbookSetting);
      }
      // showToast("Settings are", "success");
      setLoading(false);
    } catch (error) {
      errorHandler(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    _getQuickbooksSettingDetails();
  }, []);

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={4}>
            <Label style={{ fontWeight: 500 }}>
              When do you want your bills due?
            </Label>
            <FormGroup className="floatingLabel">
              <div className="custom-select-wrapper">
                <Input
                  type="select"
                  name="billsDue"
                  placeholder=" "
                  value={formFields.billsDue}
                  onChange={(e) => _onChange("billsDue", e.target.value)}
                >
                  {/* <option>All</option> */}
                  <option value="Net 7">Net 7</option>
                  <option value="Net 15">Net 15</option>
                  <option value="Net 25">Net 25</option>
                  <option value="Net 30">Net 30</option>
                  <option value="Net 40">Net 40</option>
                  <option value="Net 45">Net 45</option>
                  <option value="Net 60">Net 60</option>
                </Input>
                <Label>Bills Due</Label>
              </div>
            </FormGroup>
          </Col>
          <Col md={4}>
            <Label style={{ fontWeight: 500 }}>
              When do you want your invoices due?
            </Label>
            <FormGroup className="floatingLabel">
              <div className="custom-select-wrapper">
                <Input
                  type="select"
                  name="invoicesDue"
                  placeholder=" "
                  value={formFields.invoicesDue}
                  onChange={(e) => _onChange("invoicesDue", e.target.value)}
                >
                  {/* <option>All</option> */}
                  <option value="Net 7">Net 7</option>
                  <option value="Net 15">Net 15</option>
                  <option value="Net 25">Net 25</option>
                  <option value="Net 30">Net 30</option>
                  <option value="Net 40">Net 40</option>
                  <option value="Net 45">Net 45</option>
                  <option value="Net 60">Net 60</option>
                </Input>
                <Label>Invoices Due</Label>
              </div>
            </FormGroup>
          </Col>

          <Col md={4}>
            <Label style={{ fontWeight: 500 }}>
              When is the label of your services provided?
            </Label>
            <FormGroup className="floatingLabel">
              <Input
                type="text"
                name="frequency"
                placeholder=" "
                value={formFields.labelOfServices}
                onChange={(e) => _onChange("labelOfServices", e.target.value)}
              ></Input>
              <Label>Label of Services</Label>
            </FormGroup>
          </Col>

          <Col md={4}>
            <Label style={{ fontWeight: 500 }}>
              When is the name of the expense account used to pay notaries in
              Quickbooks?
            </Label>
            <FormGroup className="floatingLabel">
              <Input
                type="text"
                name="frequency"
                placeholder=" "
                value={formFields.expenseAccount}
                onChange={(e) => _onChange("expenseAccount", e.target.value)}
              ></Input>
              <Label>Expense Account</Label>
            </FormGroup>
          </Col>
          <Col md={4}>
            <Label style={{ fontWeight: 500 }}>
              When do you want your bills due?
            </Label>
            <FormGroup className="floatingLabel">
              <Input
                type="text"
                name="quickbookClass"
                placeholder=" "
                value={formFields.quickbookClass}
                onChange={(e) => _onChange("quickbookClass", e.target.value)}
              ></Input>
              <Label>Class</Label>
            </FormGroup>
          </Col>

          {/* <Col md={12}>
            <Label style={{ fontWeight: 500 }}>
              Which Software are you using?
            </Label>
            <div className="automationPoint">
              <div>
                <CustomInput
                  type="radio"
                  name="softwareUsed"
                  id="quickbooksOnline"
                  label="Quickbooks Online"
                  // defaultChecked
                  value={formFields.softwareUsed}
                  checked={formFields.softwareUsed === "quickbooksOnline"}
                  onChange={(event) =>
                    _onChange("softwareUsed", "quickbooksOnline")
                  }
                />
              </div>
              <div>
                <CustomInput
                  type="radio"
                  name="softwareUsed"
                  id="quickbooksDesktop"
                  label="Quickbooks Desktop"
                  value={formFields.softwareUsed}
                  checked={formFields.softwareUsed === "quickbooksDesktop"}
                  onChange={(event) =>
                    _onChange("softwareUsed", "quickbooksDesktop")
                  }
                />
              </div>
              <div>
                <CustomInput
                  type="radio"
                  name="softwareUsed"
                  id="softwareUsedNA"
                  label="N/A"
                  value={formFields.softwareUsed}
                  checked={formFields.softwareUsed === "na"}
                  onChange={(event) => _onChange("softwareUsed", "na")}
                />
              </div>
            </div>
          </Col> */}

          <Col md={12}>
            <Label style={{ fontWeight: 500 }}>
              Do you want to export your client's file # or your internal order
              # to your Quickbooks invoices and bills?
            </Label>
            <div className="automationPoint">
              <div>
                <CustomInput
                  type="radio"
                  name="exportFileOrderToInvoiceAndBill"
                  id="file"
                  label="File#"
                  // defaultChecked
                  value={formFields.exportFileOrderToInvoiceAndBill}
                  checked={
                    formFields.exportFileOrderToInvoiceAndBill === "file"
                  }
                  onChange={(event) =>
                    _onChange("exportFileOrderToInvoiceAndBill", "file")
                  }
                />
              </div>
              <div>
                <CustomInput
                  type="radio"
                  name="exportFileOrderToInvoiceAndBill"
                  id="order"
                  label="Order#"
                  value={formFields.exportFileOrderToInvoiceAndBill}
                  checked={
                    formFields.exportFileOrderToInvoiceAndBill === "order"
                  }
                  onChange={(event) =>
                    _onChange("exportFileOrderToInvoiceAndBill", "order")
                  }
                />
              </div>

              <div>
                <CustomInput
                  type="radio"
                  name="exportFileOrderToInvoiceAndBill"
                  id="exportFileOrderToInvoiceAndBillNA"
                  label="N/A"
                  value={formFields.exportFileOrderToInvoiceAndBill}
                  checked={formFields.exportFileOrderToInvoiceAndBill === "na"}
                  onChange={(event) =>
                    _onChange("exportFileOrderToInvoiceAndBill", "na")
                  }
                />
              </div>
            </div>
          </Col>

          <Col md={12}>
            <Label style={{ fontWeight: 500 }}>
              Do you want to export a unique vendor (notary) ID (NOT
              RECOMMENDED) or vendor (notary) name in Quickbooks?
            </Label>
            <div className="automationPoint">
              <div>
                <CustomInput
                  type="radio"
                  name="exportVendorIdAndVendorName"
                  id="uniqueId"
                  label="Unique ID"
                  // defaultChecked
                  value={formFields.exportVendorIdAndVendorName}
                  checked={
                    formFields.exportVendorIdAndVendorName === "uniqueId"
                  }
                  onChange={(event) =>
                    _onChange("exportVendorIdAndVendorName", "uniqueId")
                  }
                />
              </div>
              <div>
                <CustomInput
                  type="radio"
                  name="exportVendorIdAndVendorName"
                  id="name"
                  label="Name"
                  value={formFields.exportVendorIdAndVendorName}
                  checked={formFields.exportVendorIdAndVendorName === "name"}
                  onChange={(event) =>
                    _onChange("exportVendorIdAndVendorName", "name")
                  }
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="text-center mt-3">
          <Button
            color="primary"
            size="lg"
            disabled={loading}
            onClick={() => _settingQuickBook()}
          >
            Save
          </Button>
        </div>
      </CardBody>
    </Card>
  );
}
