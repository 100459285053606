import React, { useRef, useState } from "react";
import QRCode from "qrcode.react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { APP_URL } from "../../../../config";
import { showToast } from "../../../../helper-methods";
import ShareLinkModal from "./shareLinkModal";

const QRCodeModal = ({ isOpen, toggle, agentDetails }) => {
  const qrCodeRef = useRef(null);
  const [toggleShareLinkModal, setToggleShareLinkModal] = useState(false);

  const downloadQRCode = () => {
    const canvas = qrCodeRef.current.querySelector("canvas");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    const downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "qrcode.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={() => toggle()}
        size="md"
        centered
        scrollbar
      >
        <ModalHeader toggle={() => toggle()}>Digital Business Card</ModalHeader>
        <ModalBody>
          <div className="barcodeWrap">
            <div className="barcode" ref={qrCodeRef}>
              <QRCode
                id="qr-gen"
                value={`${APP_URL}/agent-about/${agentDetails?._id}`}
                size={310}
                level={"H"}
                includeMargin={true}
              />
            </div>

            <h3>
              <span>{`${APP_URL}/agent-about/${
                agentDetails?.userName
                  ? agentDetails?.userName
                  : agentDetails?._id
              }`}</span>
              <CopyToClipboard
                text={`${APP_URL}/agent-about/${
                  agentDetails?.userName
                    ? agentDetails?.userName
                    : agentDetails?._id
                }`}
                onCopy={() => showToast("Copied", "success")}
                style={{ cursor: "pointer" }}
              >
                <i className="fa fa-clone"></i>
              </CopyToClipboard>
            </h3>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => setToggleShareLinkModal(true)}
          >
            SHARE
            <img
              src={require(`../../../../assets/img/shareIcon.svg`).default}
              className="shareIconBlue ml-2"
              alt="download"
            />
            <img
              className="ml-2"
              src={
                require(`../../../../assets/img/shareIcon-white.svg`).default
              }
              alt="download"
            />
          </Button>
          <Button color="primary" onClick={() => downloadQRCode()}>
            DOWNLOAD{" "}
            <img
              src={require("../../../../assets/img/download.svg").default}
              alt="download"
              className="ml-2"
            />
          </Button>
        </ModalFooter>
      </Modal>
      <ShareLinkModal
        isOpen={toggleShareLinkModal}
        shareableLink={`${APP_URL}/agent-about/${agentDetails?._id}`}
        toggle={() => setToggleShareLinkModal(false)}
      ></ShareLinkModal>
    </>
  );
};

export default QRCodeModal;
