import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  CardFooter,
  Button,
  Collapse,
  Label,
} from "reactstrap";
import {
  showToast,
  deepClone,
  getLoggedInUserId,
  structureQueryParams,
  openUrlOnNewTab,
  convertIsoToUtc,
  formatOnlyDateMoment,
  formatCurrencyValue,
  errorHandler,
} from "../../../helper-methods";
import {
  getAllFiltersData,
  signingCompanyProfitAndLoss,
} from "../../../http/http-calls";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import FormGroup from "reactstrap/lib/FormGroup";
import { BASE_URL, monthShortNames } from "../../../config";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";

class ProfitAndLossReportingCompany extends React.Component {
  state = {
    groupBarGraphDemoData: {
      type: "bar",
      chartData: {
        labels: monthShortNames,
        datasets: [
          {
            label: "A",
            backgroundColor: "rgba(30, 41, 92, 0.3)",
            borderColor: "rgba(30, 41, 92, 1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(30, 41, 92, 0.5)",
            hoverBorderColor: "rgba(30, 41, 92, 1)",
            data: [2, 3, 4, 6, 8, 2, 1, 6, 3, 4, 2, 6],
          },
          {
            label: "B",
            backgroundColor: "rgba(7, 104, 157, 0.3)",
            borderColor: "rgba(7, 104, 157, 1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(7, 104, 157, 0.5)",
            hoverBorderColor: "rgba(7, 104, 157, 1)",
            data: [6, 2, 5, 3, 6, 3, 10, 2, 8, 6, 4, 3],
          },
        ],
      },
    },
    pieGraphDemoData: {
      options: {
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false,
          },
        },
        scales: {
          x: {
            display: false,
          },
          y: {
            display: false,
          },
        },
      },
      type: "pie",
      chartData: {
        labels: monthShortNames,
        datasets: [
          {
            label: "",
            backgroundColor: "rgba(30, 41, 92, 0.3)",
            borderColor: "rgba(30, 41, 92, 1)",
            borderWidth: 1,
            hoverBackgroundColor: "rgba(30, 41, 92, 0.5)",
            hoverBorderColor: "rgba(30, 41, 92, 1)",
            data: [2, 3, 4, 6, 8, 2, 1, 6, 3, 4, 2, 6],
          },
        ],
      },
    },
    dateRangeValue: null,
    addExpense: {
      isOpen: false,
      data: null,
    },
    filterDropdownValues: {
      clients: [],
    },
    filters: {
      status: "",
      search: "",
    },
    incomes: [],
    expenses: [],
    totalIncome: 0,
    totalExpense: 0,
    netIncome: 0,
    loading: false,
    isOpenCollapse: "income",
    isClassAdded: false,
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  componentDidMount() {
    this._getAllFiltersData();
    // this._getAllProfitLossReport();
    this._setFilterDataFromLocalStorage();

    HeaderEventEmitter.subscribe("download-profit-loss", () => {
      this._downloadData();
    });
  }

  _getAllFiltersData = () => {
    getAllFiltersData()
      .then((res) => {
        const { filterDropdownValues } = deepClone(this.state);
        filterDropdownValues["clients"] = res?.clients;
        this.setState({ filterDropdownValues });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getAllProfitLossReport = async () => {
    try {
      this.setState({ loading: true });
      const { filters, dateRangeValue } = deepClone(this.state);

      const filterPayload = {};

      if (filters.client && filters.client.trim().length)
        filterPayload["client"] = filters.client.trim();

      if (dateRangeValue) {
        filterPayload["startDate"] = dateRangeValue[0];
        filterPayload["endDate"] = dateRangeValue[1];
      }

      let payload = {
        filters: filterPayload,
      };
      let response = await signingCompanyProfitAndLoss(payload);
      this.setState({
        incomes: response?.incomes || [],
        expenses: response?.expenses || [],
        totalIncome: response?.totalIncome || 0,
        totalExpense: response?.totalExpense || 0,
        netIncome: response?.netIncome || 0,
        loading: false,
      });
    } catch (error) {
      this.setState({ loading: false });
      errorHandler(error);
    }
  };

  _downloadData = (action = "download") => {
    const {
      filters,
      dateRangeValue,
      // totalIncome,
      //  totalExpense, netIncome
    } = this.state;

    // if (!totalIncome || !totalExpense || !netIncome) {
    // if (!totalIncome) {
    //   showToast(`No data for ${action}`, "error");
    //   return;
    // }

    const payload = {
      userId: getLoggedInUserId(),
    };

    if (filters.client && filters.client.trim().length)
      payload["client"] = filters.client.trim();

    if (dateRangeValue) {
      // payload["startDate"] = dateRangeValue[0];
      // payload["endDate"] = dateRangeValue[1];
      payload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      payload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
    }

    const queryParams = structureQueryParams(payload);

    if (action === "download")
      openUrlOnNewTab(
        `${BASE_URL}/signingcompany/download/profitloss${queryParams}`
      );
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => {
      this._getAllProfitLossReport();
      this._persistFilter();
    });
  };

  _filterOnChange = (type, value) => {
    const { filters } = this.state;
    filters[type] = value;
    this.setState({ filters }, () => {
      this._getAllProfitLossReport();
    });
  };

  _persistFilter = () => {
    const { dateRangeValue } = this.state;

    if (dateRangeValue !== null) {
      let data = { dateRangeValue };
      localStorage.companyprofitloss = JSON.stringify(data);
    } else {
      delete localStorage.companyprofitloss;
    }
  };
  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.companyprofitloss) {
      try {
        const filters = JSON.parse(localStorage.companyprofitloss);
        let dateRange = [
          new Date(convertIsoToUtc(filters.dateRangeValue[0])),
          new Date(convertIsoToUtc(filters.dateRangeValue[1])),
        ];
        this.setState({ dateRangeValue: dateRange }, () => {
          this._getAllProfitLossReport();
        });
      } catch (e) {
        this._getAllProfitLossReport();
      }
    } else {
      this._getAllProfitLossReport();
    }
  };
  _toggleCollapse = (isOpen = false) => {
    if (isOpen === this.state.isOpenCollapse) {
      this.setState({ isOpenCollapse: false });
    } else {
      this.setState({ isOpenCollapse: isOpen });
    }
  };

  render() {
    const {
      dateRangeValue,
      totalIncome,
      totalExpense,
      netIncome,
      expenses,
      incomes,
      isClassAdded,
      isOpenCollapse,
    } = deepClone(this.state);

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>Profit & Loss</h2>

            <div className="rightSide">
              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="Filter"
                />
              </Button>
            </div>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.filterShowMobile}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer  responsiveFilter ${
              isClassAdded ? "show" : ""
            }`}
          >
            <div className="filterIcon">
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
              />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>
            <div className="filterWrapper">
              <FormGroup>
                <Label>Date Range</Label>
                <DateRangePicker
                  className="dateRange"
                  format="MM-dd-y"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  onChange={(dateRangeValue) =>
                    this._onChangeDatePicker(dateRangeValue)
                  }
                  value={dateRangeValue}
                />
              </FormGroup>

              <Button
                color="link"
                outline
                className="downloadButton ml-auto"
                onClick={() => this._downloadData()}
              >
                Download
                <img
                  src={require(`../../../assets/img/download_blue.png`).default}
                  alt="download"
                />
              </Button>
            </div>
            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>

          <div className="downloadButtonPos">
            <Button color="link" outline onClick={() => this._downloadData()}>
              Download
              <img
                src={require("../../../assets/img/download_blue.png").default}
                alt="download"
              />
            </Button>
          </div>

          {/* Net Income card */}
          <Card className="profitLossCard netIncome">
            <CardHeader>
              <p className="income">Net Income</p>
              <p className="income">
                {netIncome ? formatCurrencyValue(netIncome) : "$0"}
              </p>
            </CardHeader>
          </Card>

          {/* Income detail card */}
          <Card className="profitLossCard">
            <CardHeader>
              <CardTitle tag="h6">Income</CardTitle>
              <div>
                {isOpenCollapse === "income" ? (
                  ""
                ) : (
                  <span className="income">
                    {totalIncome ? formatCurrencyValue(totalIncome) : "$0"}
                  </span>
                )}

                <Button
                  color="link"
                  className={`${isOpenCollapse === "income" ? "show" : null}`}
                  onClick={() => this._toggleCollapse("income")}
                >
                  <img
                    src={
                      require("../../../assets/img/arrow-dropdown.svg").default
                    }
                    alt="arrow"
                  />
                </Button>
              </div>
            </CardHeader>
            <Collapse
              className="profitCollapse"
              isOpen={isOpenCollapse === "income"}
            >
              <CardBody className="p-0">
                <ul className="profitLossReport">
                  {incomes.length !== 0 ? (
                    incomes.map((each, index) => {
                      return (
                        <li key={index}>
                          {each.name.full}
                          <span>
                            {each.amount
                              ? formatCurrencyValue(each.amount)
                              : "$0"}
                          </span>
                        </li>
                      );
                    })
                  ) : (
                    <li className="text-center">There is no data to display</li>
                  )}
                </ul>
              </CardBody>
              <CardFooter>
                <p className="income">Total Income</p>
                <p className="income">
                  {totalIncome ? formatCurrencyValue(totalIncome) : "$0"}
                </p>
              </CardFooter>
            </Collapse>
          </Card>

          <Card className="profitLossCard">
            <CardHeader>
              <CardTitle tag="h6">Expenses</CardTitle>
              <div>
                {isOpenCollapse === "expenses" ? (
                  ""
                ) : (
                  <span className="income">
                    {totalExpense ? formatCurrencyValue(totalExpense) : "$0"}
                  </span>
                )}

                <Button
                  color="link"
                  className={`${isOpenCollapse === "expenses" ? "show" : null}`}
                  onClick={() => this._toggleCollapse("expenses")}
                >
                  <img
                    src={
                      require("../../../assets/img/arrow-dropdown.svg").default
                    }
                    alt="arrow"
                  />
                </Button>
              </div>
            </CardHeader>
            <Collapse isOpen={isOpenCollapse === "expenses"}>
              <CardBody className="p-0">
                <ul className="profitLossReport">
                  {expenses.length !== 0 ? (
                    expenses.map((each, index) => {
                      return (
                        <li key={index}>
                          {each.item}
                          {each.amount
                            ? formatCurrencyValue(each.amount)
                            : "$0"}
                        </li>
                      );
                    })
                  ) : (
                    <li className="text-center">There is no data to display</li>
                  )}
                </ul>
              </CardBody>
              <CardFooter>
                <p className="income">Total Expenses</p>
                <p className="income">
                  {" "}
                  {totalExpense ? formatCurrencyValue(totalExpense) : "$0"}
                </p>
              </CardFooter>
            </Collapse>
          </Card>
        </div>
      </>
    );
  }
}

export default ProfitAndLossReportingCompany;
