import React, { Component, createRef } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import {
  capitalize,
  deepClone,
  showToast,
  formatCurrencyValue,
  errorHandler,
  getTimeZoneAbbr,
  formatDateAsPerTimeZOne,
  formatDateOnlyAsPerTimeZone,
} from "../../../helper-methods";
import {
  getClosingDetailsById,
  updateClosingDetailsById,
  sendEmailOnStatusChange,
  createChatLog,
  editDraft,
} from "../../../http/http-calls";
import Chips from "react-chips";
import { RegexConfig } from "../../../config/RegexConfig";
import { agentClosingStatus } from "../../../config";
import RegularAppointmentScheduler from "./RegularAppointmentScheduler";
import { DateTimeShowWithClosingDetails } from "./Date-time-show";

class SigningStatusSigningModal extends Component {
  constructor(props) {
    super(props);
    this.regularSchedulerRef = createRef();
  }
  state = {
    formField: {
      // signingStatusDetail: "",
      chips: [],
      shippingCompany: "",
      trackingInfo: "",
      agentFee: "",
      signingCompanyFee: "",
      companyAcceptDncFee: false,
      agentDncFee: "",
    },
    closingDetails: null,
    loading: false,
  };

  _resetModalState = () => {
    this.setState({
      formField: {
        companyNote: "",
        shippingCompany: "",
        trackingInfo: "",
        agentFee: "",
        companyAcceptDncFee: false,
        agentDncFee: "",
      },
      closingDetails: null,
      loading: false,
    });
  };

  _closeModal = () => {
    this._resetModalState();
    this.props.toggle();
  };

  _updateState = (closing) => {
    const { formField } = deepClone(this.state);
    console.log(closing);
    // formField["signingStatusDetail"] = closing.signingStatusDetail
    //   ? closing.signingStatusDetail
    //   : "";
    formField["shippingCompany"] = closing.shippingCompany
      ? closing.shippingCompany
      : "";
    formField["trackingInfo"] = closing.trackingInfo
      ? closing.trackingInfo
      : "";

    formField["companyAcceptDncFee"] =
      closing.companyAcceptDncFee !== undefined
        ? closing.companyAcceptDncFee
        : "";

    formField["agentDncFee"] = closing.agentDncFee ? closing.agentDncFee : "";

    // if (
    //   this.props.status === "DNC" ||
    //   this.props.status === "Closed" ||
    //   this.props.status === "Cancelled"
    // ) {
    //   formField["chips"] = [
    //     // this.props.data._client.email,
    //     this.props.data?.personOfContact?.email,
    //     this.props.data.otherRecipients,
    //   ]
    //     .flat()
    //     .filter((item) => item !== "" && item !== undefined);
    // }

    this.setState({
      closingDetails: closing,
      loading: false,
      formField,
    });
  };

  _getClosingDetailsById = (id) => {
    this.setState({ loading: true });

    getClosingDetailsById(id)
      .then((res) => {
        console.log("rersd", res?.closing);
        this._updateState(res?.closing);
      })
      .catch((error) => {
        console.log("ads", error);
        errorHandler(error);
        this._closeModal();
      });
  };

  // componentDidUpdate = (prevProps, prevState) => {
  //   const { isOpen, data } = this.props;
  //   // console.log(this.props);
  //   if (isOpen && data && prevProps.isOpen !== isOpen) {
  //     this._getClosingDetailsById(data.id);
  //   }
  // };

  componentDidMount = () => {
    const { isOpen, data } = this.props;
    if (isOpen && data) {
      this._getClosingDetailsById(data.id);
    }
  };

  _onChangeFormField = (type, value) => {
    const { formField } = this.state;
    formField[type] = value;

    if (type === "companyAcceptDncFee") {
      formField["agentFee"] = value ? formField.agentDncFee.toString() : "";
    }

    this.setState({ formField });
  };

  _createChatLog = async () => {
    const { closingDetails } = deepClone(this.state);
    try {
      let payload = {
        closingId: closingDetails.id,
        isVisibleAgent: true,
        isVisibleClient: true,
        text: this.state.formField.companyNote,
      };
      await createChatLog(payload);
    } catch (error) {
      errorHandler(error);
    }
  };

  _updateClosingDetailsById = async (id, payload) => {
    const { closingDetails } = this.state;

    try {
      this.setState({ loading: true });

      // await updateClosingDetailsById({ id, payload });
      closingDetails.isSavedAsDraft
        ? await editDraft(closingDetails.id, payload)
        : await updateClosingDetailsById({
            id,
            payload,
          });

      if (this.state.formField.companyNote) {
        await this._createChatLog();
      }
      showToast("Signing Status Updated", "success");
      this._sendEmailOnStatusChange();
      this.props.resetDetails();
      this._closeModal();
    } catch (error) {
      errorHandler(error);
      this.setState({ loading: false });
    }
  };

  _sendEmailOnStatusChange = async () => {
    let { closingDetails, formField } = deepClone(this.state);
    let otherRecipient = formField.chips.filter(
      (item) =>
        !this.props.data.personOfContact.email.includes(item) &&
        item !== closingDetails?._signingCompany?.email
    );

    let data = {
      recipients: otherRecipient,
    };
    try {
      this.setState({ loading: true });

      await sendEmailOnStatusChange(closingDetails.id, data);
      // showToast("Email Sent", "success");
      this.props.resetDetails();
      this._closeModal();
    } catch (error) {
      errorHandler(error);
      this.setState({ loading: false });
    }
  };

  _confirmStatus = async () => {
    const { closingDetails, formField } = deepClone(this.state);

    let payload = {};

    // status = "Closed" or "DNC"

    const { status } = this.props;

    if (status === "Closed") {
      payload["status"] = "Closed";
      if (closingDetails?.isRangeDated || closingDetails?.tBD) {
        const regularSchedulerResponse =
          await this.regularSchedulerRef.current.getAppointmentSchedulerData();
        if (!regularSchedulerResponse?.isValid) {
          return;
        }
        payload = {
          ...regularSchedulerResponse.appointmentSchedulerData,
          status: "Closed",
        };
      }

      payload["signingStatus"] = true;

      if (formField.shippingCompany && formField.shippingCompany.trim().length)
        payload["shippingCompany"] = formField.shippingCompany.trim();

      if (formField.trackingInfo && formField.trackingInfo.trim().length)
        payload["trackingInfo"] = formField.trackingInfo.trim();

      payload["companystatusClosedNotes"] = formField.companyNote?.trim().length
        ? formField.companyNote.trim()
        : undefined;
    } else if (status === "Cancelled") {
      payload["status"] = "Cancelled";

      payload["companyCancelledComment"] = formField.companyNote?.trim().length
        ? formField.companyNote.trim()
        : undefined;
    } else {
      payload["status"] = "DNC";
      if (closingDetails?.isRangeDated || closingDetails?.tBD) {
        const regularSchedulerResponse =
          await this.regularSchedulerRef.current.getAppointmentSchedulerData();
        if (!regularSchedulerResponse?.isValid) {
          return;
        }
        payload = {
          ...regularSchedulerResponse.appointmentSchedulerData,
          status: "DNC",
        };
      }

      payload["signingStatus"] = false;

      if (formField.signingCompanyFee?.trim()) {
        payload["signingCompanyFee"] = formField.signingCompanyFee.trim();
      } else {
        showToast("Signing Company Fee is Required", "error");
        return;
      }

      payload["companyNotes"] = formField.companyNote?.trim().length
        ? formField.companyNote.trim()
        : undefined;

      if (formField.agentFee?.trim()) {
        payload["agentFee"] = formField.agentFee.trim();
      } else {
        showToast("Agent Fee is Required", "error");
        return;
      }
    }

    // if (status !== "Cancelled") {
    //   payload["companyAcceptDncFee"] = formField.companyAcceptDncFee;
    // }
    // if (
    //   formField.signingStatusDetail &&
    //   formField.signingStatusDetail.trim().length
    // )
    //   payload["signingStatusDetail"] = formField.signingStatusDetail.trim();

    if (
      (status === "DNC" && payload.agentFee && payload.signingCompanyFee) ||
      status === "Closed" ||
      status === "Cancelled"
    ) {
      this._updateClosingDetailsById(closingDetails.id, payload);
    }
  };

  _onChipSelect = (chips, arrName = "chips") => {
    // const {formField} =deepClone(this.state)
    // console.log("chips >>", chips);
    // console.log("chips length >", chips.length);
    // console.log("chips[1] >", chips[0]);
    let isValid = chips.length
      ? this._isValidEmail(chips[chips.length - 1])
      : true;
    if (isValid) {
      this.setState({
        formField: {
          ...this.state.formField,
          chips: chips,
        },
      });
    }
  };
  _isValidEmail = (value) => {
    // console.log(value);
    if (RegexConfig.email.test(String(value).toLowerCase())) {
      // console.log("into");
      return true;
    } else {
      // console.log("into else");
      showToast("Invalid Email", "error");

      return false;
    }
  };

  _isNumber = (e) => {
    var charCode = e.which ? e.which : e.keyCode;

    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return e.preventDefault();
    return;
  };

  render() {
    const { closingDetails, formField, loading } = this.state;

    const { status } = this.props;
    // console.log(this.state);
    return (
      <Modal isOpen={this.props.isOpen} toggle={this._closeModal} scrollable>
        <ModalHeader toggle={this._closeModal}>
          Change Status:{" "}
          {agentClosingStatus?.find((each) => each.value === status)?.label}
        </ModalHeader>

        <ModalBody>
          {closingDetails ? (
            <>
              <div className="signingCompanyInfo">
                <p>
                  Shared with{" "}
                  {closingDetails._client?.name?.full
                    ? capitalize(closingDetails._client.name.full)
                    : ""}
                </p>
                <p>
                  Signer Name :{" "}
                  {closingDetails?._borrower &&
                  closingDetails?._borrower?.length > 0
                    ? closingDetails?._borrower
                        ?.map((item) => capitalize(item?.name?.full))
                        .join(", ")
                    : "N/A"}
                  ,{" "}
                </p>

                <p>
                  File Number :{" "}
                  {closingDetails.fileNumber
                    ? capitalize(closingDetails.fileNumber)
                    : ""}
                </p>
                <p>
                  Date:{" "}
                  <DateTimeShowWithClosingDetails
                    closingDetails={closingDetails}
                  />
                  {getTimeZoneAbbr(
                    closingDetails?.appointmentDate,
                    closingDetails?.closingAddress?.timeZone
                  )}
                </p>
              </div>
              {status === "DNC" ? (
                <>
                  {!formField.agentDncFee ? (
                    <>
                      {/* <p className="fs-12 mb-2">
                        Proposed Agent Fee{" "}
                        {formatCurrencyValue(formField.agentDncFee)}{" "}
                      </p>
                      <CustomInput
                      className="mb-3"
                        id="agentFeeApprove"
                        type="checkbox"
                        checked={formField.companyAcceptDncFee}
                        onChange={(e) =>
                          this._onChangeFormField(
                            "companyAcceptDncFee",
                            e.target.checked
                          )
                        }
                        label="Approve"
                      /> */}

                      {/* <FormGroup>
                        <Label>
                          <Input
                            type="checkbox"
                            checked={formField.companyAcceptDncFee}
                            
                            onChange={(e) =>
                              this._onChangeFormField(
                                "companyAcceptDncFee",
                                e.target.checked
                              )
                            }
                          />
                          Approve
                        </Label>
                      </FormGroup> */}
                    </>
                  ) : null}
                  <FormGroup className="floatingLabel withInputGroup">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <img
                            src={
                              require("../../../assets/img/dollar.svg").default
                            }
                            alt="doller"
                            height={14}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="agentFee"
                        placeholder=" "
                        disabled={formField.companyAcceptDncFee}
                        value={formField.agentFee}
                        onChange={(e) =>
                          this._onChangeFormField("agentFee", e.target.value)
                        }
                        onKeyPress={(e) => this._isNumber(e)}
                      />

                      <Label>Agent Fee</Label>
                    </InputGroup>
                  </FormGroup>
                  <FormGroup className="floatingLabel withInputGroup">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <img
                            src={
                              require("../../../assets/img/dollar.svg").default
                            }
                            alt="doller"
                            height={14}
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="signingCompanyFee"
                        placeholder=" "
                        value={formField.signingCompanyFee}
                        onChange={(e) =>
                          this._onChangeFormField(
                            "signingCompanyFee",
                            e.target.value
                          )
                        }
                        onKeyPress={(e) => this._isNumber(e)}
                      />
                      <Label>Signing Company Fee</Label>
                    </InputGroup>
                  </FormGroup>
                </>
              ) : null}

              <FormGroup className="floatingLabel">
                <Input
                  type="textarea"
                  rows="3"
                  style={{ maxHeight: "unset" }}
                  placeholder=" "
                  name="companyNote"
                  value={formField.companyNote}
                  onChange={(e) =>
                    this._onChangeFormField("companyNote", e.target.value)
                  }
                />
                <Label>
                  {status === "Closed" ? "How was the signing?" : "Comments"}
                </Label>
              </FormGroup>

              {status === "Closed" ? (
                <>
                  {/* Note: Hide Everything Below if Signing is Unsuccessful */}

                  <p className="mb-2 mt-4">
                    You can add shipping information later (Optional)
                  </p>

                  <FormGroup className="floatingLabel">
                    <div className="custom-select-wrapper">
                      <Input
                        type="select"
                        name="shippingCompany"
                        value={formField.shippingCompany}
                        onChange={(e) =>
                          this._onChangeFormField(
                            "shippingCompany",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select</option>
                        <option value="USPS">USPS</option>
                        <option value="UPS">UPS</option>
                        <option value="FedEx">FedEx</option>
                        <option value="DHL">DHL</option>
                      </Input>
                      <Label>Shipping Company</Label>
                    </div>
                  </FormGroup>
                  <FormGroup className="floatingLabel">
                    <Input
                      type="text"
                      placeholder=" "
                      name="trackingInfo"
                      value={formField.trackingInfo}
                      onChange={(e) =>
                        this._onChangeFormField("trackingInfo", e.target.value)
                      }
                    />
                    <Label>Tracking #</Label>
                  </FormGroup>

                  {/* <p className="mb-2">
                    *The Closer LLC will automatically be invoiced
                  </p> */}
                  {/* Note: Hide Everything Above if Signing is Unsuccessful */}
                </>
              ) : null}

              {status === "Closed" ||
              status === "DNC" ||
              status === "Cancelled" ? (
                <FormGroup className="ChipsWrap floatingLabel valueAdded">
                  <Chips
                    value={formField.chips}
                    onChange={(arr) => this._onChipSelect(arr, "chips")}
                    getChipValue={(e) => console.log(e)}
                    createChipKeys={[9, 13]} //  Key codes to add chips
                    placeholder={"+ Add Email"}
                  />
                  <Label> Send Email To</Label>
                </FormGroup>
              ) : null}
            </>
          ) : (
            <div
              className="text-center"
              style={{ fontSize: "24px", margin: "20px 0" }}
            >
              <i className="fa fa-spinner fa-spin text-primary" />
            </div>
          )}

          {(status === "Closed" || status === "DNC") &&
          (closingDetails?.isRangeDated || closingDetails?.tBD) ? (
            <RegularAppointmentScheduler
              {...this.props}
              ref={this.regularSchedulerRef}
              // data={this.state.appointmentSchedulerData}
            />
          ) : null}
        </ModalBody>

        <ModalFooter>
          <Button color="primary" outline onClick={this._closeModal}>
            Cancel
          </Button>

          <Button
            color="primary"
            disabled={loading}
            onClick={() => this._confirmStatus()}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null} Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default SigningStatusSigningModal;
