import React from "react";
import { useState } from "react";
import { Button } from "reactstrap";
import {
  openUrlOnNewTab,
  showToast,
  errorHandler,
} from "../../../helper-methods";
import { getUserKycLink } from "../../../http/http-calls";

const KYCAlertCard = () => {
  const [loading, setLoading] = useState(false);

  const _getAndRedirectUserKycLink = async () => {
    setLoading(true);

    getUserKycLink()
      .then((res) => {
        setLoading(false);
        if (res?.accountLink?.url) {
          // props.onSuccess();
          openUrlOnNewTab(res.accountLink.url);
        } else {
          showToast("Server error. Try again after sometime.", "error");
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log("sdfdfdfdf", error);

        errorHandler(error);
      });
  };

  return (
    <div className="completeKyc" color="danger">
      <h1>Complete Your KYC</h1>

      <p className="text-danger">
        CloseWise cannot accept payments on your behalf currently. Please fill
        the needed information correctly to active payments for your account.
      </p>
      <Button
        color="primary"
        size="md"
        disabled={loading}
        onClick={() => _getAndRedirectUserKycLink()}
      >
        {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null} Proceed
      </Button>
    </div>
  );
};

export default KYCAlertCard;
