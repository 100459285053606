/*!

=========================================================
* Paper Dashboard PRO React - v1.2.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";
// used for making the prop types of this component
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { store as REDUX_STORE } from "../../../redux/store";

class Footer extends React.Component {
  render() {
    const { userData } = REDUX_STORE.getState();

    return (
      <footer
        className={"footer" + (this.props.default ? " footer-default" : "")}
      >
        <Container fluid={this.props.fluid ? true : false}>
          <Row>
            <div>
              <span className="copyright">
                &copy; {1900 + new Date().getYear()}{" "}
                {userData?.user?.companyName
                  ? userData?.user?.companyName
                  : ""}
                .
              </span>
              <Link to={`/${userData.type}/terms`}>Terms</Link>
              <a
                href="https://www.closewise.com/privacy-policy/"
                target="_blank"
              >
                Privacy
              </a>
            </div>

            {/* <div className="credits ml-auto">
              Powered By{" "}
              <a href="https://www.logic-square.com" target="_blank">
                Logic Square
              </a>
            </div> */}
          </Row>
        </Container>
      </footer>
    );
  }
}

Footer.propTypes = {
  default: PropTypes.bool,
  fluid: PropTypes.bool,
};

export default Footer;
