import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { DEFAULT_COVER_PICTURE } from "../../../config";

class ViewReceiptModal extends Component {
  state = {};

  _closeModal = () => {
    this.props.toggle();
  };

  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this._closeModal} centered
      scrollbar>
        <ModalHeader toggle={this._closeModal}>View Receipt</ModalHeader>
        <ModalBody>
          <div
            className="border rounded text-center"
            style={{ height: 352, background: "#f9f9f9" }}
          >
            <img
              src={DEFAULT_COVER_PICTURE}
              alt="Receipt"
              style={{ height: 350, objectFit: "contain", cursor: "pointer" }}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button className="modalBtnCancel" onClick={this._closeModal}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ViewReceiptModal;
