import React from "react";
import {
  Row,
  Col,
  Input,
  Button,
  FormGroup,
  Badge,
  Card,
  CardBody,
  Label,
  UncontrolledTooltip,
} from "reactstrap";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import JobsIncludedModal from "../components/jobs-included-invoicing-agent-modal";
import CreateInvoiceModal from "../components/create-invoice-agent-modal";
import {
  getAllAgentInvoices,
  agentUpdateInvoiceById,
  updateAgentInvoiceForRecordPayment,
  getClientListForInvoice,
  agentGetAllRegisterClients,
} from "../../../http/http-calls";
import {
  capitalize,
  deepClone,
  formatCurrencyValue,
  showToast,
  getDateRangeValue,
  getLoggedInUserId,
  openUrlOnNewTab,
  formatDateAsPerTimeZOne,
  getTimeZoneAbbr,
  formatDateAndTimeForNotificationPage,
  formatPhoneNumber,
  formatAddressInSingleText,
  getDropdownColor,
  checkPermission,
  refreshFunc,
  errorHandler,
} from "../../../helper-methods/index";
import { connect } from "react-redux";
import { updateUserObj } from "../../../redux/actions/user-data";
import UpgradeAccountModal from "../components/Modals/upgradeAccountModal";
import { BASE_URL, invoiceStatus } from "../../../config";
import CustomTable from "../components/CustomTable";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomCard from "../components/CustomCard";
import SvgIcons from "../components/SvgIcons";

class InvoicingAgent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      kyc: {
        paymentEnabled: true,
        payoutEnabled: true,
        lastKycUpdatedAt: null,
      },
      paidAmount: 0,
      pendingAmount: 0,
      agentInvoiceList: [],
      agentInvoiceTotalCount: 0,
      agentInvoiceHeaderKeys: [
        { id: "id", label: "id", noSort: true },
        { id: "client", label: "Client", noSort: true },
        { id: "createdAt", label: "Date Created", noSort: true },
        { id: "paymentDate", label: "payment Date", noSort: true },
        { id: "closings", label: "Jobs", noSort: true },
        {
          id: "amount",
          label: "Amount",
          noSort: true,
          tooltip:
            "The amount displayed is the pending amount at the time of invoice creation.",
          // tooltip: (
          //   <>
          //     <span href="#" id="UncontrolledTooltipExample1" className="ml-2">
          //       <SvgIcons type="info" />
          //     </span>
          //     <UncontrolledTooltip
          //       placement="right"
          //       target="UncontrolledTooltipExample1"
          //     >
          //       <p>
          //         The amount displayed is the pending amount at the time of
          //         invoice creation.
          //       </p>
          //     </UncontrolledTooltip>
          //   </>
          // ),
        },
        { id: "status", label: "Status", noSort: true },
        {
          id: "action",
          label: "Action",
          noSort: true,
          tooltip: " The amounts displayed in the PDF reflect real-time data.",
          // tooltip: (
          //   <>
          //     <span href="#" id="UncontrolledTooltipExample" className="ml-2">
          //       <SvgIcons type="info" />
          //     </span>
          //     <UncontrolledTooltip
          //       placement="right"
          //       target="UncontrolledTooltipExample"
          //     >
          //       <p>
          //       The amounts displayed in the PDF reflect real-time data.
          //       </p>
          //     </UncontrolledTooltip>
          //   </>
          // ),
        },
      ],
      agentCardHeaderKeys: [
        { id: "id", label: "id" },
        { id: "name", label: "Name" },
      ],
      filters: {
        companyId: "",
        status: "",
        amount: "",
      },
      dateRangeValue: null,
      filterDropdownValues: {
        amount: [
          { name: "0 - $50", value: { min: 0, max: 50 } },
          { name: "$50 - $75", value: { min: 50, max: 75 } },
          { name: "$75 - $100", value: { min: 75, max: 100 } },
          { name: "$100+", value: { min: 100 } },
        ],
        signingCompany: [],
        signingCompanyForDashboard: [],
      },
      jobsIncludedModal: {
        isOpen: false,
        data: null,
      },
      createInvoiceModal: {
        isOpen: false,
        data: null,
      },
      tableConfig: {
        skip: 0,
        limit: 10,
        pageNumber: 1,
      },
      loading: {
        showTableLoading: false,
        statusUpdateLoading: false,
        markPaidLoading: false,
      },
      upgradeAccountModal: {
        isOpen: false,
        data: null,
      },
      selectedRows: [],
      amount: "",
      incomeObj: [],
      referenceNumber: "",
      checkDate: "",
      isClassAdded: false,
    };
    this.tableRef = React.createRef();
  }
  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  componentDidMount = () => {
    this._getClientListForInvoice();
    this._agentGetAllRegisterClients();
    this._setFilterDataFromLocalStorage();
    HeaderEventEmitter.subscribe("reset-filter", () => {
      this._resetFilter();
      refreshFunc("agent-accounting-invoicing");
    });
    HeaderEventEmitter.subscribe("add-invoice", () => {
      this._onToggleCreateInvoiceModal(true);
    });
  };

  _getClientListForInvoice = () => {
    getClientListForInvoice()
      .then((res) => {
        const { filterDropdownValues } = deepClone(this.state);
        filterDropdownValues["signingCompany"] = res?.list || [];
        this.setState({ filterDropdownValues });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _agentGetAllRegisterClients = () => {
    // getClientListForInvoice
    agentGetAllRegisterClients()
      .then((res) => {
        const { filterDropdownValues } = deepClone(this.state);
        filterDropdownValues["signingCompanyForDashboard"] = res?.listAll || [];
        this.setState({ filterDropdownValues });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };
  _getAllAgentInvoices = () => {
    this._manageLoading("showTableLoading", true);

    const { tableConfig, filters, dateRangeValue, filterDropdownValues } =
      deepClone(this.state);

    const payload = {
      ...tableConfig,
      filters: {},
    };

    if (dateRangeValue && dateRangeValue.length) {
      payload.filters["startDate"] = dateRangeValue[0];
      payload.filters["endDate"] = dateRangeValue[1];
    }

    // if (filters.companyId) payload.filters["companyId"] = filters.companyId;
    if (filters.companyId && filters.companyId.length > 0) {
      filterDropdownValues.signingCompanyForDashboard.find((each) => {
        if (each._id === filters.companyId) {
          if (each.hasOwnProperty("_client") && each._client !== "") {
            payload.filters["companyId"] = each._client;
            payload.filters["company"] = "unregisteredClient";
          } else if (
            each.hasOwnProperty("_signingCompany") &&
            each._signingCompany !== ""
          ) {
            payload.filters["companyId"] = each._signingCompany;
          } else {
            payload.filters["assistantId"] = each._assistant;
          }
        }
      });
    }
    if (filters.status) payload.filters["status"] = filters.status;
    if (filters.amount) {
      try {
        payload.filters["amount"] = JSON.parse(filters.amount);
      } catch (error) {
        filters.amount = "";
        this.setState({ filters });
        delete payload.filters["amount"];
      }
    }

    getAllAgentInvoices(payload)
      .then((res) => {
        this.setState(
          {
            kyc: res?.kyc,
            paidAmount: res?.paidAmount,
            pendingAmount: res?.pendingAmount,
            agentInvoiceList: res?.invoices,
            agentInvoiceTotalCount: res?.totalCount,
          },
          () => {
            this._manageLoading("showTableLoading", false);
          }
        );
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllAgentInvoices();
    });
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => {
      this._paginate();
    });
  };

  _filterOnChange = (type, value) => {
    const { filters } = this.state;
    filters[type] = value;
    this.setState({ filters }, () => {
      this._paginate();
      this._persistFilter();
    });
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          companyId: "",
          status: "",
          amount: "",
        },
        dateRangeValue: null,
      },
      () => {
        this._paginate();
        refreshFunc("agent-accounting-invoicing-responsive");
      }
    );
  };

  _onToggleJobsIncludedModal = (isOpen = false, data = null) => {
    this.setState({
      jobsIncludedModal: {
        isOpen,
        data,
      },
    });
  };

  _onToggleCreateInvoiceModal = (isOpen = false, data = null) => {
    this.setState({
      createInvoiceModal: {
        isOpen,
        data,
      },
    });
  };

  _agentUpdateInvoiceStatusById = (id, status) => {
    this._manageLoading("statusUpdateLoading", true);

    agentUpdateInvoiceById(id, { status })
      .then((res) => {
        this._getAllAgentInvoices();
        this._manageLoading("statusUpdateLoading", false);
        showToast("Status Updated", "success");
      })
      .catch((error) => {
        this._paginate();
        this._manageLoading("statusUpdateLoading", false);
        errorHandler(error);
      });
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "client": {
        if (row?._signingCompany) {
          return row?._signingCompany?.companyName
            ? capitalize(row?._signingCompany?.companyName)
            : "N/A";
        }
        if (row?._assistant?.name) {
          return row?._assistant?.name?.full
            ? capitalize(row?._assistant?.name?.full)
            : "N/A";
        }
        return row?._client?.name
          ? capitalize(row?._client?.name?.full)
          : "N/A";
      }

      // how to render it for row.incomes array
      case "paymentDate": {
        return cell && row?.closings ? (
          <>
            <div>
              {formatDateAsPerTimeZOne(
                cell,
                row?.closings[0]?._closing?.closingAddress?.timeZone
              )}
              {" | "}
              <span color="primary" className="mr-2">
                {getTimeZoneAbbr(
                  cell,
                  row?.closings[0]?._closing?.closingAddress?.timeZone
                )}
              </span>
            </div>
          </>
        ) : (
          "N/A"
        );
      }

      case "createdAt": {
        return cell ? formatDateAndTimeForNotificationPage(cell) : "N/A";
      }

      case "amount": {
        return cell !== undefined ? formatCurrencyValue(cell) : "$0";
      }
      case "closings": {
        return (row?.closings && row?.closings.length) ||
          (row?.incomes && row?.incomes.length) ? (
          <span
            className="text-primary cursorPointer"
            onClick={() => this._onToggleJobsIncludedModal(true, row)}
          >
            {row?.closings?.length
              ? row?.closings?.length
              : row?.incomes?.length
              ? row?.incomes?.length
              : "0"}
          </span>
        ) : (
          "0"
        );
      }
      case "status": {
        if (cell) {
          if (row?._signingCompany || cell.toLowerCase() === "paid") {
            if (cell.toLowerCase() === "pending") {
              return (
                <Badge color="warning" className="outline" pill>
                  {cell}
                </Badge>
              );
            } else if (cell.toLowerCase() === "paid") {
              return (
                <Badge color="success" className="outline" pill>
                  {cell}
                </Badge>
              );
            } else if (cell.toLowerCase() === "cancelled") {
              return (
                <Badge color="danger" className="outline" pill>
                  {cell}
                </Badge>
              );
            } else {
              return capitalize(cell);
            }
          } else {
            return cell === "Pending" ? (
              <Badge color="warning" className="outline" pill>
                {cell}
              </Badge>
            ) : (
              <Badge color="success" className="outline" pill>
                {cell}
              </Badge>
            );
          }
        } else {
          return "N/A";
        }
      }
      case "action": {
        // !row.isPaid ? (
        return (
          <Button
            color="link"
            outline
            onClick={() => this._downloadInvoiceData(row.id)}
          >
            Download{" "}
            <img
              src={require("../../../assets/img/download_blue.png").default}
              alt="download"
            />
          </Button>
        );
      }
      case "duePayment": {
        return cell ? formatCurrencyValue(cell) : "$0";
      }

      default: {
        return cell;
      }
    }
  };

  _downloadInvoiceData = (invoiceId) => {
    this._manageLoading("showTableLoading", true);

    openUrlOnNewTab(
      `${BASE_URL}/agent/download/invoice/${invoiceId}?userId=${getLoggedInUserId()}`
    );

    this._manageLoading("showTableLoading", false);
  };

  _updateIncomeForRecordPayment = () => {
    const { incomeObj, referenceNumber, checkDate, selectedRows } = this.state;

    let commanArray = selectedRows.reduce((arr, item1) => {
      const match = incomeObj.find((item2) => item1 === item2.id);
      match && arr.push(match);
      return arr;
    }, []);

    let invoiceList = commanArray.map((item) => ({
      id: item.id,
      paymentAmount: item.paymentAmount,
    }));

    let noPaymentAmount = invoiceList.find(
      (item) => item.paymentAmount === undefined
    );

    if (noPaymentAmount) {
      showToast("Payment Amount Should Not be Empty", "error");
    } else {
      if (incomeObj?.length && checkDate && referenceNumber) {
        this._manageLoading("markPaidLoading", true);

        const payload = {
          invoiceList,
          checkDate,
          referenceNumber,
        };

        const totalCheckAmount = this._calTotalCheckAmount();

        if (totalCheckAmount) payload["totalCheckAmount"] = totalCheckAmount;

        updateAgentInvoiceForRecordPayment(payload)
          .then((res) => {
            this.setState({ checkDate: "", referenceNumber: "" });
            this.tableRef.current.onDeSelectAll();
            this._getAllAgentInvoices();
            this._manageLoading("markPaidLoading", false);
            showToast("Recorded Successfully", "success");
          })
          .catch((error) => {
            this._setDateRangeValue();
            errorHandler(error);
            this._manageLoading("markPaidLoading", false);
          });
      }
    }
  };
  _setSelectedRows = (selectedRows = []) => {
    let obj = selectedRows.map((each) =>
      this.state.agentInvoiceList.find((item) => item.id === each)
    );
    let isObjectPaymentDue = obj.find(
      (each) => each.duePayment === 0 || each.duePayment === undefined
    );

    if (isObjectPaymentDue)
      this.tableRef.current.onDeRowSelect(isObjectPaymentDue);

    this.setState({
      selectedRows,
      incomeObj: obj,
    });
  };

  _calTotalCheckAmount = () => {
    const { incomeObj } = this.state;

    return incomeObj?.length
      ? incomeObj.reduce((acc, each) => {
          acc = (+acc || 0) + (+each?.paymentAmount || 0);
          return acc;
        }, 0)
      : null;
  };

  _setDateRangeValue = (value = "month") => {
    const { filters } = this.state;

    switch (value) {
      case "day":
      case "week":
      case "month":
      case "year": {
        filters["dateRangeValue"] = getDateRangeValue(value);
        break;
      }
      case "allTime": {
        filters["dateRangeValue"] = null;
        break;
      }
      case "customDateRange": {
        filters["dateRangeValue"] = null;
        filters["customDateRangeValue"] = value;
        this.setState({ filters });
        return;
      }
      default: {
        showToast("Something went wrong. Try again after sometime.", "error");
        return;
      }
    }

    filters["customDateRangeValue"] = value;
    this.setState({ filters }, () => this._paginate());
  };

  _toggleUpgradeAccountModal = (isOpen = false, data = null) => {
    this.setState({
      upgradeAccountModal: {
        isOpen,
        data,
      },
    });
  };

  _persistFilter = () => {
    const { filters, tableConfig } = this.state;
    if (
      (filters && (filters.status || filters.companyId || filters.amount)) ||
      tableConfig.pageNumber !== 1
    ) {
      const data = { filters, tableConfig };
      localStorage.agentinvoice = JSON.stringify(data);
    } else {
      delete localStorage.agentinvoice;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.agentinvoice) {
      try {
        const filters = JSON.parse(localStorage.agentinvoice);
        this.setState(
          { filters: filters?.filters, tableConfig: filters?.tableConfig },
          () => {
            this._getAllAgentInvoices();
          }
        );
      } catch (e) {
        this._getAllAgentInvoices();
      }
    } else {
      this._getAllAgentInvoices();
    }
  };

  // _cardHeaderFormat = (cell, row, header) => {
  //   switch (header) {
  //     case "name": {
  //       return row ? (
  //         <>
  //           <div className="tableUserInfo">
  //             <span style={{ fontSize: 13, fontWeight: 600 }}>
  //               {row?._signingCompany
  //                 ? row?._signingCompany?.companyName
  //                   ? capitalize(row?._signingCompany?.companyName)
  //                   : "N/A"
  //                 : row?._client?.name
  //                   ? capitalize(row?._client?.name?.full)
  //                   : "N/A"}
  //             </span>
  //           </div>
  //         </>
  //       ) : (
  //         "N/A"
  //       );
  //     }
  //     default: {
  //       return cell;
  //     }
  //   }
  // };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Client</Label>
            <div style={{ fontWeight: 600 }}>
              {row?._signingCompany
                ? row?._signingCompany?.companyName
                  ? capitalize(row?._signingCompany?.companyName)
                  : "N/A"
                : row?._client?.name
                ? capitalize(row?._client?.name?.full)
                : capitalize(row?._assistant?.name?.full) || "N/A"}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Date Created</Label>
            <div style={{ fontWeight: 600 }}>
              {row.createdAt
                ? formatDateAndTimeForNotificationPage(row.createdAt)
                : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Payment Date</Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row.paymentDate && row?.closings ? (
                <>
                  <div>
                    {formatDateAsPerTimeZOne(
                      row.paymentDate,
                      row?.closings[0]?._closing?.closingAddress?.timeZone
                    )}
                    {" | "}
                    <span color="primary" className="mr-2">
                      {getTimeZoneAbbr(
                        row.paymentDate,
                        row?.closings[0]?._closing?.closingAddress?.timeZone
                      )}
                    </span>
                  </div>
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Jobs</Label>
            <div style={{ fontWeight: 600 }}>
              {row?.closings && row?.closings?.length ? (
                <span
                  className="text-primary cursorPointer"
                  onClick={() => this._onToggleJobsIncludedModal(true, row)}
                >
                  {row?.closings?.length}
                </span>
              ) : row?.incomes && row?.incomes?.length ? (
                <span
                  className="text-primary cursorPointer"
                  onClick={() => this._onToggleJobsIncludedModal(true, row)}
                >
                  {row?.incomes?.length}
                </span>
              ) : (
                "0"
              )}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap">
            <Label>Amount</Label>
            <i
              id={`amountTooltip_${row?._id}`}
              className="fa fa-info-circle ml-2"
              style={{
                fontSize: "14px",
                color: "blue",
                cursor: "pointer",
              }}
            />
            <UncontrolledTooltip
              placement="bottom"
              target={`amountTooltip_${row?._id}`}
            >
              The amount displayed is the pending amount at the time of invoice
              creation.
            </UncontrolledTooltip>
            <div style={{ fontWeight: 600 }}>
              {row.amount !== undefined
                ? formatCurrencyValue(row.amount)
                : "$0"}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap d-flex align-items-center justify-content-between">
            <Label className="mb-0">Status</Label>
            <div className="customSelectWrap">{this._statusDiv(row)}</div>
          </div>
        </li>
        <li>
          <div className="dataWrap text-center">
            <Button
              id={`downloadTooltip_${row?._id}`}
              color="link"
              outline
              onClick={() => this._downloadInvoiceData(row.id)}
            >
              Download{" "}
              <img
                src={require("../../../assets/img/download_blue.png").default}
                alt="download"
              />
            </Button>
            <UncontrolledTooltip
              placement="bottom"
              target={`downloadTooltip_${row?._id}`}
            >
              The amounts displayed in the PDF reflect real-time data.
            </UncontrolledTooltip>
          </div>
        </li>
      </ul>
    );
  };

  _statusDiv = (row) => {
    if (row.status) {
      if (row?._signingCompany || row.status.toLowerCase() === "paid") {
        if (row.status.toLowerCase() === "pending") {
          return (
            <Badge color="warning" className="outline" pill>
              {row.status}
            </Badge>
          );
        } else if (row.status.toLowerCase() === "paid") {
          return (
            <Badge color="success" className="outline" pill>
              {row.status}
            </Badge>
          );
        } else if (row.status.toLowerCase() === "cancelled") {
          return (
            <Badge color="danger" className="outline" pill>
              {row.status}
            </Badge>
          );
        } else {
          return capitalize(row.status);
        }
      } else {
        return row.status === "Pending" ? (
          <Badge color="warning" className="outline" pill>
            {row.status}
          </Badge>
        ) : (
          <Badge color="success" className="outline" pill>
            {row.status}
          </Badge>
        );
      }
    } else {
      return "N/A";
    }
  };

  render() {
    const {
      dateRangeValue,
      filters,
      filterDropdownValues,
      loading,
      agentInvoiceList,
      agentInvoiceTotalCount,
      tableConfig,
      agentInvoiceHeaderKeys,
      jobsIncludedModal,
      createInvoiceModal,
      paidAmount,
      pendingAmount,
      upgradeAccountModal,
      isClassAdded,
      agentCardHeaderKeys,
    } = deepClone(this.state);

    return (
      <div className="content">
        <div className="responsiveTitle">
          <h2>Invoicing</h2>

          <div className="rightSide">
            <Button color="link" onClick={this._resetFilter}>
              <img
                id="agent-accounting-invoicing-responsive"
                src={require("../../../assets/img/refresh.svg").default}
                alt="refresh"
              />
            </Button>

            <Button
              className="filterBarIcon"
              color="link"
              onClick={this.filterShowMobile}
            >
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="Filter"
              />
            </Button>
            <Button
              color="primary"
              className="floatingButton"
              onClick={() => this._onToggleCreateInvoiceModal(true)}
            >
              New Invoice
            </Button>
          </div>
        </div>

        <Row>
          <Col md="12">
            {/* <div className="pgTitleWrap">
              <h4></h4>
              <div>
                <Button
                  className="resetBtn d-none d-md-inline"
                  onClick={this._resetFilter}
                >
                  <i className="fa fa-refresh" />
                </Button>

                //  this btn will show below 768px  
                <Button className="resetBtn d-inline d-md-none" id="toggler">
                  <img
                    src={require("../../../assets/img/filter-icon.png").default}
                    alt="Filter"
                  />
                </Button>

                <Button
                  color="primary"
                  onClick={() => this._onToggleCreateInvoiceModal(true)}
                >
                  New Invoice
                </Button>
              </div>
            </div> */}
            {/* filter for tablet and web */}
            <div
              onClick={this.handleButtonClick}
              className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
            ></div>
            <div
              className={`filterContainer responsiveFilter ${
                isClassAdded ? "show" : ""
              }`}
            >
              <div className="filterIcon">
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="filter icon"
                />
                Filter by
              </div>
              <div className="mobileTitle">
                <h2>Filter by</h2>
                <Button
                  color="link"
                  className="closeButton"
                  onClick={this.filterShowMobile}
                >
                  <img
                    src={require("../../../assets/img/close_grey.svg").default}
                    alt="close"
                    height={12}
                  />
                </Button>
              </div>
              <div className="filterWrapper">
                <FormGroup className="dateRange">
                  <Label>Date Range</Label>
                  <DateRangePicker
                    clearIcon={null}
                    className="dateRange"
                    format="MM-dd-y"
                    dayPlaceholder="dd"
                    monthPlaceholder="mm"
                    yearPlaceholder="yyyy"
                    onChange={(dateRangeValue) =>
                      this._onChangeDatePicker(dateRangeValue)
                    }
                    value={dateRangeValue}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Company</Label>
                  <div className="custom-select-wrapper">
                    <Input
                      type="select"
                      name="companyId"
                      value={filters.companyId}
                      onChange={(e) =>
                        this._filterOnChange("companyId", e.target.value)
                      }
                    >
                      <option value="">All</option>
                      {/* <option value="unregisteredClient">
                        Unregistered Client
                      </option> */}
                      {filterDropdownValues.signingCompanyForDashboard.map(
                        (obj) => (
                          <option key={obj.id} value={obj.id}>
                            {/* {(obj.companyName && capitalize(obj.companyName)) ||
                            "N/A Company"} */}
                            {obj?._signingCompany
                              ? obj.companyName
                              : obj?._client
                              ? obj.name?.full
                              : obj?._assistant
                              ? obj?.name?.full
                              : "N/A"}
                          </option>
                        )
                      )}
                    </Input>
                  </div>
                </FormGroup>
                <FormGroup>
                  <Label>Status</Label>
                  <div className="custom-select-wrapper">
                    <Input
                      type="select"
                      name="status"
                      value={filters.status}
                      onChange={(e) =>
                        this._filterOnChange("status", e.target.value)
                      }
                    >
                      <option value="">All</option>
                      {invoiceStatus.map((obj) => (
                        <option key={obj.value} value={obj.value}>
                          {obj.label}
                        </option>
                      ))}
                    </Input>
                  </div>
                </FormGroup>
              </div>
              <div className="clearButton">
                <Button
                  size="md"
                  color="primary"
                  onClick={this.filterShowMobile}
                >
                  Close
                </Button>
              </div>
            </div>

            {paidAmount || pendingAmount ? (
              <Row>
                <Col xl={3} lg={6} xs={6}>
                  <Card className="infoCard">
                    <CardBody>
                      <div className="">
                        <span>Paid</span>
                        <h4>
                          {paidAmount ? formatCurrencyValue(paidAmount) : "$0"}
                        </h4>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            require("../../../assets/img/invoicePaid.svg")
                              .default
                          }
                          alt="notaryActs"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={3} lg={6} xs={6}>
                  <Card className="infoCard">
                    <CardBody>
                      <div className="">
                        <span>Pending</span>
                        <h4 className="text-danger">
                          {pendingAmount
                            ? formatCurrencyValue(pendingAmount)
                            : "$0"}
                        </h4>
                      </div>
                      <div className="icon">
                        <img
                          src={
                            require("../../../assets/img/invoicepending.svg")
                              .default
                          }
                          alt="notaryActs"
                        />
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : null}
            <div className="hideDesktop">
              <CustomCard
                isPageStartFromOne={true}
                pageNumber={tableConfig.pageNumber}
                tableData={agentInvoiceList}
                // headerKeys={agentCardHeaderKeys}
                // bodyKeys={agentClosingCardBodyKeys}
                dataFormat={this._dataFormat}
                totalCount={agentInvoiceTotalCount}
                onPaginate={(pageNumber, pageSize) =>
                  this._paginate(pageNumber, pageSize)
                }
                // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
                showTableLoading={loading.showTableLoading}
                cardHeaderFormat={this._cardHeaderFormat}
                cardDataFormat={this._cardDataFormat}
              />
            </div>
            <div className="hideMobile">
              {agentInvoiceHeaderKeys && agentInvoiceHeaderKeys.length && (
                <CustomTable
                  striped
                  isPageStartFromOne={true}
                  pageNumber={tableConfig.pageNumber}
                  tableData={agentInvoiceList}
                  headerKeys={agentInvoiceHeaderKeys}
                  dataFormat={this._dataFormat}
                  totalCount={agentInvoiceTotalCount}
                  rowSelection={false}
                  onPaginate={(pageNumber, pageSize) =>
                    this._paginate(pageNumber, pageSize)
                  }
                  onSortChange={(sortName, sortOrder) =>
                    // sort function is disabled
                    this._paginate()
                  }
                  showTableLoading={loading.showTableLoading}
                />
              )}
            </div>
          </Col>
        </Row>

        <JobsIncludedModal
          isOpen={jobsIncludedModal.isOpen}
          data={jobsIncludedModal.data}
          toggle={() => this._onToggleJobsIncludedModal()}
          userData={this.props.userData}
        />

        <CreateInvoiceModal
          isOpen={createInvoiceModal.isOpen}
          data={createInvoiceModal.data}
          signingCompany={filterDropdownValues.signingCompany}
          toggle={() => this._onToggleCreateInvoiceModal()}
          resetDetails={() => this._paginate()}
        />

        <UpgradeAccountModal
          isOpen={upgradeAccountModal.isOpen}
          toggle={this._toggleUpgradeAccountModal}
        />
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserObj: (userData) => dispatch(updateUserObj(userData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(InvoicingAgent);
// export default InvoicingAgent;
