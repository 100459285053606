import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Row,
  Col,
  Label,
  FormGroup,
} from "reactstrap";
import { deepClone, showToast, errorHandler } from "../../../helper-methods";

class StandardFeeModal extends Component {
  state = {
    // formData: {
    // standardFee: {
    //   value: "",
    //   error: null,
    //   isDirty: false,
    // },

    standardFee: {
      Refinance: {
        value: "",
        error: null,
        isDirty: false,
      },
      HomeEquity: {
        value: "",
        error: null,
        isDirty: false,
      },
      AttorneyPhone: {
        value: "",
        error: null,
        isDirty: false,
      },
      Purchase: {
        value: "",
        error: null,
        isDirty: false,
      },
      LoanMod: {
        value: "",
        error: null,
        isDirty: false,
      },
      ReverseMtg: {
        value: "",
        error: null,
        isDirty: false,
      },
      HybridESign: {
        value: "",
        error: null,
        isDirty: false,
      },
      DeedInLieu: {
        value: "",
        error: null,
        isDirty: false,
      },
      SingleDoc: {
        value: "",
        error: null,
        isDirty: false,
      },
      LoanApplication: {
        value: "",
        error: null,
        isDirty: false,
      },
      Other: {
        value: "",
        error: null,
        isDirty: false,
      },
      ForeignNationalSigner: {
        value: "",
        error: null,
        isDirty: false,
      },
      WitnessFee: {
        value: "",
        error: null,
        isDirty: false,
      },
      // }
    },
    loading: false,
  };

  _resetStateModal = () => {
    this.setState({
      standardFee: {
        Refinance: {
          value: "",
          error: null,
          isDirty: false,
        },
        HomeEquity: {
          value: "",
          error: null,
          isDirty: false,
        },
        AttorneyPhone: {
          value: "",
          error: null,
          isDirty: false,
        },
        Purchase: {
          value: "",
          error: null,
          isDirty: false,
        },
        LoanMod: {
          value: "",
          error: null,
          isDirty: false,
        },
        ReverseMtg: {
          value: "",
          error: null,
          isDirty: false,
        },
        HybridESign: {
          value: "",
          error: null,
          isDirty: false,
        },
        DeedInLieu: {
          value: "",
          error: null,
          isDirty: false,
        },
        SingleDoc: {
          value: "",
          error: null,
          isDirty: false,
        },
        LoanApplication: {
          value: "",
          error: null,
          isDirty: false,
        },
        Other: {
          value: "",
          error: null,
          isDirty: false,
        },
        ForeignNationalSigner: {
          value: "",
          error: null,
          isDirty: false,
        },
        WitnessFee: {
          value: "",
          error: null,
          isDirty: false,
        },
        // }
      },
      loading: false,
    });
  };

  _setStandardFee = (data) => {
    const { standardFee } = deepClone(this.state);
    // formData["standardFee"].value = data.standardFee
    //   ? data.standardFee.toString()
    //   // : "";
    // this.setState({ formData });
    standardFee["Refinance"].value = data?.standardFee?.Refinance || "";
    standardFee["HomeEquity"].value = data?.standardFee?.HomeEquity || "";
    standardFee["AttorneyPhone"].value = data?.standardFee?.AttorneyPhone || "";
    standardFee["Purchase"].value = data?.standardFee?.Purchase || "";
    standardFee["LoanMod"].value = data?.standardFee?.LoanMod || "";
    standardFee["ReverseMtg"].value = data?.standardFee?.ReverseMtg || "";
    standardFee["HybridESign"].value = data?.standardFee?.HybridESign || "";
    standardFee["DeedInLieu"].value = data?.standardFee?.DeedInLieu || "";
    standardFee["SingleDoc"].value = data?.standardFee?.SingleDoc || "";
    standardFee["LoanApplication"].value =
      data?.standardFee?.LoanApplication || "";
    standardFee["Other"].value = data?.standardFee?.Other || "";
    standardFee["WitnessFee"].value = data?.standardFee?.WitnessFee || "";
    standardFee["ForeignNationalSigner"].value =
      data?.standardFee?.ForeignNationalSigner || "";

    this.setState({ standardFee });
  };

  componentDidUpdate = (prevProps) => {
    const { isOpen, data } = this.props;
    if (isOpen && data && isOpen !== prevProps.isOpen) {
      this._setStandardFee(data);
    }
  };

  _closeModal = () => {
    this._resetStateModal();
    this.props.toggle();
  };

  // _validateFormData = () => {
  //   return new Promise((resolve, reject) => {
  //     const { formData, standardFee } = this.state;

  //     let isFormValid = true;

  //     // Object.keys(formData).forEach((key) => {
  //     //   if (formData[key].isDirty) {
  //     //     switch (key) {
  //     //       case "standardFee": {
  //     //         if (formData[key].value && formData[key].value.trim().length) {
  //     //           if (
  //     //             isNaN(formData[key].value) ||
  //     //             Number(formData[key].value) < 1
  //     //           ) {
  //     //             formData[key].isDirty = true;
  //     //             formData[key].error = "*Invalid Fee";
  //     //             isFormValid = false;
  //     //           } else {
  //     //             formData[key].isDirty = false;
  //     //             formData[key].error = null;
  //     //           }
  //     //         } else {
  //     //           formData[key].isDirty = true;
  //     //           formData[key].error = "*Required";
  //     //           isFormValid = false;
  //     //         }
  //     //         break;
  //     //       }
  //     //       default: {
  //     //       }
  //     //     }
  //     //   }
  //     // });

  //     this.setState({ standardFee }, () => {
  //       resolve(isFormValid);
  //     });
  //   });
  // };

  // _onChangeStandardFee = (fieldName, value = "") => {
  //   const { formData } = deepClone(this.state);
  //   if (isNaN(value) || (value.includes(".") && value.split(".")[1].length > 2))
  //     return;
  //   formData[fieldName].value = value;
  //   formData[fieldName].isDirty = true;
  //   this.setState({ formData }, () => {
  //     this._validateFormData();
  //   });
  // };

  _onChangeStandardFee = (key, value) => {
    console.log(key, value);
    this.setState({
      standardFee: {
        ...this.state.standardFee,
        [key]: {
          ...this.state.standardFee[key],
          value: value,
          isDirty: true,
        },
      },
    });
    var regex = /^\d+(\.\d{0,2})?$/g;
    if (!regex.test(value)) {
      showToast("Invalid Input", "error");

      this.setState({
        standardFee: {
          ...this.state.standardFee,
          [key]: {
            ...this.state.standardFee[key],
            value: "",
            isDirty: true,
          },
        },
      });
    }
  };

  // _markAllFormDataFieldDirty = () => {
  //   return new Promise((resolve, reject) => {
  //     const { formData } = this.state;
  //     Object.keys(formData).forEach((e) => {
  //       formData[e].isDirty = true;
  //     });
  //     this.setState({ formData }, () => resolve(true));
  //   });
  // };

  _updateStandardFee = async (e) => {
    try {
      if (e) e.preventDefault();

      // await this._markAllFormDataFieldDirty();

      // const isFormValid = await this._validateFormData();

      // if (isFormValid) {
      this.setState({ loading: true });

      const { standardFee } = deepClone(this.state);

      console.log(
        standardFee.Refinance.value !== "" &&
          Number(standardFee.Refinance.value) > 0
      );
      let payload = {
        //   // standardFee: Number(formData.standardFee.value.trim()),
        standardFee: {
          Refinance:
            standardFee.Refinance.value !== "" &&
            Number(standardFee.Refinance.value) > 0
              ? Number(standardFee.Refinance.value)
              : undefined,
          HomeEquity:
            standardFee.HomeEquity.value !== "" &&
            Number(standardFee.HomeEquity.value) > 0
              ? Number(standardFee.HomeEquity.value)
              : undefined,
          AttorneyPhone:
            standardFee.AttorneyPhone.value !== "" &&
            Number(standardFee.AttorneyPhone.value) > 0
              ? Number(standardFee.AttorneyPhone.value)
              : undefined,
          Purchase:
            standardFee.Purchase.value !== "" &&
            Number(standardFee.Purchase.value) > 0
              ? Number(standardFee.Purchase.value)
              : undefined,
          LoanMod:
            standardFee.LoanMod.value !== "" &&
            Number(standardFee.LoanMod.value) > 0
              ? Number(standardFee.LoanMod.value)
              : undefined,
          ReverseMtg:
            standardFee.ReverseMtg.value !== "" &&
            Number(standardFee.ReverseMtg.value) > 0
              ? Number(standardFee.ReverseMtg.value)
              : undefined,
          HybridESign:
            standardFee.HybridESign.value !== "" &&
            Number(standardFee.HybridESign.value) > 0
              ? Number(standardFee.HybridESign.value)
              : undefined,
          DeedInLieu:
            standardFee.DeedInLieu.value !== "" &&
            Number(standardFee.DeedInLieu.value) > 0
              ? Number(standardFee.DeedInLieu.value)
              : undefined,
          SingleDoc:
            standardFee.SingleDoc.value !== "" &&
            Number(standardFee.SingleDoc.value) > 0
              ? Number(standardFee.SingleDoc.value)
              : undefined,
          LoanApplication:
            standardFee.LoanApplication.value !== "" &&
            Number(standardFee.LoanApplication.value) > 0
              ? Number(standardFee.LoanApplication.value)
              : undefined,
          Other:
            standardFee.Other.value !== "" &&
            Number(standardFee.Other.value) > 0
              ? Number(standardFee.Other.value)
              : undefined,
          ForeignNationalSigner:
            standardFee.ForeignNationalSigner.value !== "" &&
            Number(standardFee.ForeignNationalSigner.value) > 0
              ? Number(standardFee.ForeignNationalSigner.value)
              : undefined,
          WitnessFee:
            standardFee.WitnessFee.value !== "" &&
            Number(standardFee.WitnessFee.value) > 0
              ? Number(standardFee.WitnessFee.value)
              : undefined,
        },
      };

      await this.props.onSumbit(payload, "Standard Fee Updated");

      this._closeModal();
      // }
    } catch (error) {
      errorHandler(error);
    }
  };

  render() {
    const { loading, standardFee } = deepClone(this.state);
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={this._closeModal}
        className="modal-dialog-centered modal-dialog-scrollable"
      >
        <ModalHeader toggle={this._closeModal}>Update Standard Fee</ModalHeader>

        <ModalBody>
          <Row>
            <Col sm="12">
              {/* <InputGroup className="phoneNumberWrap">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  name="standardFee"
                  onChange={(event) =>
                    this._onChangeStandardFee(
                      "standardFee",
                      event.target.value
                    )
                  }
                  value={formData.standardFee.value}
                />
              </InputGroup> */}
              {/* {formData.standardFee.error && (
                <div className="validation-error">
                  {formData.standardFee.error}
                </div>
              )} */}
              <div className="standardFeeWrap">
                {Object.keys(standardFee).length &&
                  Object.entries(standardFee).map(([key, value], index) => (
                    <React.Fragment key={index}>
                      <FormGroup>
                        {/* {console.log(key, value)} */}
                        <Label>
                          {key
                            .replace(
                              /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g,
                              "$1$4 $2$3$5"
                            )
                            .charAt(0)
                            .toUpperCase() +
                            key
                              .replace(
                                /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g,
                                "$1$4 $2$3$5"
                              )
                              .slice(1)}
                        </Label>
                        <InputGroup className="phoneNumberWrap">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>$</InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            name="standardFee"
                            onChange={(e) =>
                              this._onChangeStandardFee(key, e.target.value)
                            }
                            value={value.value}
                            disabled={
                              this.props.hideButton
                                ? this.props.hideButton
                                : false
                            }
                          />
                        </InputGroup>
                        {key.error && (
                          <div className="validation-error">{key.error}</div>
                        )}
                      </FormGroup>
                    </React.Fragment>
                  ))}
              </div>
            </Col>
          </Row>
        </ModalBody>

        {!this.props.hideButton ? (
          <ModalFooter>
            <Button className="modalBtnCancel" onClick={this._closeModal}>
              Cancel
            </Button>
            <Button
              className="modalBtnSave"
              disabled={loading}
              onClick={this._updateStandardFee}
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
              Update
            </Button>
          </ModalFooter>
        ) : null}
      </Modal>
    );
  }
}

export default StandardFeeModal;
