import React from "react";
import ReactDOM from "react-dom";

//theme css
import "bootstrap/dist/css/bootstrap.css";
import "./assets/scss/paper-dashboard.scss?v=1.2.0";
import "./assets/demo/demo.css";
import "perfect-scrollbar/css/perfect-scrollbar.css";

// custom css
import "./index.css";
import App from "./App";
import "./App.scss";
import "./scss/index.scss";

// Gleap For User Feedback and Custom Issues
// import Gleap from "gleap";

// Please make sure to call this method only once!
// Gleap.initialize("6Nin5otkRe5rcZBuoWSp9BOZE7fE8Jro");

ReactDOM.render(<App />, document.getElementById("root"));
