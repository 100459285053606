import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
// import { showToast, formatDateMomentMonthFirst } from "../../../helper-methods";
import ReactDatetime from "react-datetime";
import usaStates from "../../../config/usa_states_titlecase";
import {
  // capitalize,
  convertToTitleCase,
  deepClone,
  errorHandler,
  // formatCurrencyValue,
  formatDate,
  getYesterdayDate,
  // openUrlOnNewTab,
  showToast,
  uploadFileOnServer,
} from "../../../helper-methods";
import {
  addAgentProfileDetail,
  editAgentProfileDetail,
  updateAgentProfileDetail,
} from "../../../http/http-calls";

const initialData = {
  _id: {
    value: "",
    error: null,
    isDirty: false,
  },
  licenseNumber: {
    value: "",
    error: null,
    isDirty: false,
  },
  state: {
    value: "",
    error: null,
    isDirty: false,
  },
  expiredOn: {
    value: "",
    error: null,
    isDirty: false,
  },
  title: {
    value: "",
    error: null,
    isDirty: false,
  },
  docType: {
    value: "",
    error: null,
    isDirty: false,
  },
  document: {
    file: {
      previewBlob: null,
      uploadData: null,
      type: null,
    },
    error: null,
    isDirty: false,
  },
  companyName: {
    value: "",
    error: null,
    isDirty: false,
  },
  nameOfCompany: {
    value: "",
    error: null,
    isDirty: false,
  },
  policyNumber: {
    value: "",
    error: null,
    isDirty: false,
  },
  amount: {
    value: "",
    error: null,
    isDirty: false,
  },
  certficationYear: {
    value: new Date().getFullYear()?.toString() || "",
    error: null,
    isDirty: false,
  },
};
const EditCredentialModal = ({
  isOpen,
  data,
  toggle,
  collapseCardName,
  agentDetails,
  getAgentProfileDetail,
}) => {
  // const [isOpenReactDatetime, setIsOpenReactDatetime] = useState(false);
  const [loading, setLoading] = useState(false);
  const [credentialData, setCredentialData] = useState(initialData);
  const currentYear = new Date().getFullYear();

  // const _resetStateModal = () => {
  // };

  const _closeModal = () => {
    // _resetStateModal();
    setCredentialData(deepClone(initialData));
    setLoading(false);
    toggle();
  };

  useEffect(() => {
    if (isOpen && data !== null) {
      if (typeof data === "object") {
        console.log("data", data);
        credentialData["_id"].value = data._id;
        credentialData["licenseNumber"].value = data.licenseNumber;
        credentialData["state"].value = data.state;
        credentialData["expiredOn"].value = data.expiredOn;
        credentialData["amount"].value = data?.amount?.toString();
        credentialData["companyName"].value = data.companyName;
        credentialData["nameOfCompany"].value = data.nameOfCompany;
        credentialData["policyNumber"].value = data.policyNumber;
        credentialData["title"].value = data.title;
        credentialData["certficationYear"].value = data?.certficationYear
          ? data.certficationYear
          : "";
        credentialData["docType"].value = data.docType;
        credentialData.document.file.previewBlob = data.url ? data.url : "";
        credentialData.document.file.type = data.docType ? data.docType : "";
        setCredentialData({ ...credentialData });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps

    return () => {
      setCredentialData(deepClone(initialData));
    };
  }, [isOpen, data]);

  const _onChangeFormField = (cardName, fieldName, value, index) => {
    console.log("first", cardName, fieldName, value);
    const newState = { ...credentialData };
    if (
      cardName !== "notaryLicense" &&
      cardName !== "barLicense" &&
      cardName !== "ronCredentials"
    ) {
      if (fieldName === "amount") {
        if (
          isNaN(value) ||
          (value.includes(".") && value.split(".")[1].length > 2)
        )
          return;
      }

      if (fieldName === "expiredOn")
        newState[fieldName].value = value ? new Date(value).toISOString() : "";
      else newState[fieldName].value = value;

      newState[fieldName].isDirty = true;

      setCredentialData(newState);
      // validation
      // validateForm(cardName);
      // });
    } else {
      newState[fieldName].value = value;
      newState[fieldName].isDirty = true;
      setCredentialData(newState);

      // setState({ ...newState }, () => {
      //   // validation
      // validateNotaryLicense(cardName);
      // });
    }
  };
  const _updateFile = async (cardName, event) => {
    const newState = deepClone({ ...credentialData });
    console.log("dsfdf", event);
    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      let objFile = event.target.files[0];
      // console.log("file: ", objFile);
      let objFileType = objFile.type.split("/")[0];
      if (objFileType === "image" || objFile.type.includes("pdf")) {
        if (objFile?.size / 1e6 <= 5) {
          // dividing filesize (in bytes) by 1e+6 (1000000) -> to convert it into Mb
          newState.document.file = {
            previewBlob: URL.createObjectURL(objFile),
            uploadData: objFile,
            type: objFileType === "application" ? "pdf" : objFileType,
          };
          newState.document.isDirty = true;
          console.log("state", newState);
          setCredentialData(newState);

          // this.setState({ : newState }, () => {
          // validation
          _validateForm(newState);
          // });
        } else {
          showToast("File size must be less than 5Mb", "error");
        }
      } else {
        showToast("Only Image or PDF file is allowed", "error");
      }
    }
  };
  const _markAllFieldDirty = (cardName) => {
    return new Promise((resolve, reject) => {
      const newState = { ...credentialData };

      Object.keys(credentialData).forEach((e) => {
        newState[e].isDirty = true;
      });
      setCredentialData({ ...credentialData });
      // this.setState({ [cardName]: newState[cardName] }, () =>
      resolve(true);
      //  );
    });
  };

  const _validateForm = (newCredentialData) => {
    return new Promise((resolve, reject) => {
      // const newState = deepClone({ ...credentialData });

      let isFormValid = true;
      // const data = cardName === 'notaryLicense' ? newState.notaryLicense : newState.ronCredentials;

      Object.keys(newCredentialData).forEach((each) => {
        // console.log("first", newCredentialData[each].value);

        if (newCredentialData[each].isDirty) {
          switch (each) {
            case "certficationYear": {
              if (collapseCardName === "w9Credentials") {
                if (
                  newCredentialData[each].value &&
                  newCredentialData[each].value.trim().length
                ) {
                  newCredentialData[each].isDirty = false;
                  newCredentialData[each].error = null;
                } else {
                  newCredentialData[each].isDirty = true;
                  newCredentialData[each].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }

            case "policyNumber":
            case "companyName": {
              if (collapseCardName === "eoInsurance") {
                if (
                  newCredentialData[each].value &&
                  newCredentialData[each].value.trim().length
                ) {
                  newCredentialData[each].isDirty = false;
                  newCredentialData[each].error = null;
                } else {
                  newCredentialData[each].isDirty = true;
                  newCredentialData[each].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            case "amount": {
              if (collapseCardName === "eoInsurance") {
                if (
                  newCredentialData[each].value &&
                  newCredentialData[each].value.trim().length
                ) {
                  if (
                    isNaN(newCredentialData[each].value) ||
                    Number(newCredentialData[each].value) < 1
                  ) {
                    newCredentialData[each].isDirty = true;
                    newCredentialData[each].error = "*Invalid Amount";
                    isFormValid = false;
                  } else {
                    newCredentialData[each].isDirty = false;
                    newCredentialData[each].error = null;
                  }
                } else {
                  newCredentialData[each].isDirty = true;
                  newCredentialData[each].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            case "nameOfCompany": {
              if (collapseCardName === "backgroundCheckReport") {
                if (
                  newCredentialData[each].value &&
                  newCredentialData[each].value.trim().length
                ) {
                  newCredentialData[each].isDirty = false;
                  newCredentialData[each].error = null;
                } else {
                  newCredentialData[each].isDirty = true;
                  newCredentialData[each].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }

            case "expiredOn": {
              if (
                collapseCardName === "notaryLicense" ||
                collapseCardName === "backgroundCheckReport" ||
                collapseCardName === "eoInsurance"
              ) {
                if (newCredentialData[each].value) {
                  newCredentialData[each].isDirty = false;
                  newCredentialData[each].error = null;
                } else {
                  newCredentialData[each].isDirty = true;
                  newCredentialData[each].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            // case "state":
            case "licenseNumber": {
              if (collapseCardName === "notaryLicense") {
                if (
                  newCredentialData[each].value &&
                  newCredentialData[each].value.trim().length
                ) {
                  newCredentialData[each].isDirty = false;
                  newCredentialData[each].error = null;
                } else {
                  newCredentialData[each].isDirty = true;
                  newCredentialData[each].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }

            case "state": {
              if (
                collapseCardName === "notaryLicense" ||
                collapseCardName === "ronCredentials"
              ) {
                if (
                  newCredentialData[each].value &&
                  newCredentialData[each].value.trim().length
                ) {
                  newCredentialData[each].isDirty = false;
                  newCredentialData[each].error = null;
                } else {
                  newCredentialData[each].isDirty = true;
                  newCredentialData[each].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }

            case "document": {
              if (data !== null) {
                if (
                  newCredentialData[each].file &&
                  newCredentialData[each].file.previewBlob
                ) {
                  newCredentialData[each].isDirty = false;
                  newCredentialData[each].error = null;
                } else {
                  newCredentialData[each].isDirty = true;
                  newCredentialData[each].error = "*Required";
                  isFormValid = false;
                }
              } else {
                if (
                  newCredentialData[each].file &&
                  newCredentialData[each].file.uploadData
                ) {
                  newCredentialData[each].isDirty = false;
                  newCredentialData[each].error = null;
                } else {
                  newCredentialData[each].isDirty = true;
                  newCredentialData[each].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            default: {
            }
          }
        }
      });
      setCredentialData(newCredentialData);
      // this.setState(
      //   {
      //     [cardName]: data,
      //   },
      //   () => {
      resolve(isFormValid);
      //   }
      // );
    });
  };

  const _onSaveCredentail = (cardName) => {
    return new Promise(async (resolve, reject) => {
      try {
        //         if (cardName !== "notaryLicense" && cardName !== "barLicense") {
        //           cardName === "ronCredentials"
        //             ? await _markDirtyRonCredentials()
        // : await
        await _markAllFieldDirty(cardName);
        //         } else {
        //           await _markDirtyNotaryLicense();
        // }

        const isFormValid = await _validateForm(credentialData);
        //           cardName === "notaryLicense"
        //             ? await _validateNotaryLicense(cardName)
        //             : cardName === "ronCredentials"
        //             ? await _validateRonCredentials()
        //             : await _validateForm(cardName);

        //         let isSuccess = false;

        if (isFormValid) {
          // if (
          //   cardName !== "notaryLicense" &&
          //   cardName !== "barLicense" &&
          //   cardName !== "ronCredentials"
          // ) {
          //   _onSubmitCredentialDetail(collapseCardName, credentialData);
          //   // isSuccess = await props.onSubmitCredentialDetail(
          //   //   cardName,
          //   //   newState[cardName]
          //   // );
          //   // if (isSuccess) setState({ cardName: null });
          // } else {
          _onSubmitCredentialDetail(collapseCardName, credentialData);
          // for (
          //   let index = 0;
          //   index <= newState[cardName].length - 1;
          //   index++
          // ) {
          //   isSuccess = await props.onSubmitCredentialDetail(
          //     cardName,
          //     newState[cardName][index]
          //   );
          // }
          //             // if (isSuccess) setState({ cardName: null });
          // }
        }

        //         resolve(isSuccess);
      } catch (error) {
        console.log("error", error);
      }
    });
  };

  const _onSubmitCredentialDetail = (collapseCardName, credentialDetail) => {
    return new Promise(async (resolve, reject) => {
      try {
        // this.setState({ loading: true });
        setLoading(true);

        const payload = {};

        if (
          collapseCardName === "notaryLicense" ||
          collapseCardName === "barLicense"
        ) {
          payload["_id"] = credentialDetail?._id?.value
            ? credentialDetail?._id?.value
            : undefined;
          payload["licenseNumber"] =
            credentialDetail.licenseNumber.value.trim();
          payload["state"] = credentialDetail.state.value.trim();
        }

        if (collapseCardName === "ronCredentials") {
          payload["_id"] = credentialDetail?._id?.value
            ? credentialDetail?._id?.value
            : undefined;
          payload["state"] = credentialDetail.state.value.trim();
        }

        if (collapseCardName === "eoInsurance") {
          payload["companyName"] = credentialDetail.companyName.value.trim();
          payload["policyNumber"] = credentialDetail.policyNumber.value.trim();
          payload["amount"] = credentialDetail.amount.value.trim();
        }

        if (collapseCardName === "backgroundCheckReport")
          payload["nameOfCompany"] =
            credentialDetail.nameOfCompany.value.trim();

        if (
          collapseCardName === "notaryLicense" ||
          collapseCardName === "eoInsurance" ||
          collapseCardName === "backgroundCheckReport" ||
          collapseCardName === "barLicense" ||
          collapseCardName === "ronCredentials"
        ) {
          payload["title"] = credentialDetail?.title?.value?.trim().length
            ? credentialDetail.title.value
            : undefined;
          payload["url"] = credentialDetail?.document.file.previewBlob
            ? credentialDetail.document.file.previewBlob
            : undefined;
          payload["docType"] = credentialDetail?.docType.value
            ? credentialDetail.docType.value
            : undefined;
        }

        if (collapseCardName === "w9Credentials")
          payload["certficationYear"] = credentialDetail.certficationYear.value;

        if (
          collapseCardName === "notaryLicense" ||
          collapseCardName === "eoInsurance" ||
          collapseCardName === "backgroundCheckReport"
        )
          payload["expiredOn"] = credentialDetail.expiredOn.value;

        if (
          credentialDetail.document.file &&
          credentialDetail.document.file.uploadData
        ) {
          const uploadedFilesRes = await uploadFileOnServer([
            credentialDetail.document.file,
          ]);
          payload["url"] = uploadedFilesRes[0].url;
          payload["docType"] = uploadedFilesRes[0].docType;
          payload["title"] = uploadedFilesRes[0].title;
        }

        let isSuccess;

        if (payload && Object.keys(payload).length) {
          if (
            collapseCardName === "notaryLicense" ||
            collapseCardName === "barLicense" ||
            collapseCardName === "ronCredentials"
          ) {
            isSuccess = _agentLicense(
              { [collapseCardName]: payload },
              collapseCardName,
              "Agent Credential Updated"
            );
          } else {
            isSuccess = await _updateOrAddAgentProfileDetail(
              { [collapseCardName]: payload },
              "Agent Credential Updated"
            );
          }
        } else {
          isSuccess = true;
          // this.setState({ loading: false });
          setLoading(false);
        }
        // this._getAgentProfileDetail();
        resolve(isSuccess);
      } catch (error) {
        console.log("error", error);

        errorHandler(error);
        // this.setState({ loading: false });
        setLoading(false);
        resolve(false);
      }
    });
  };

  const _agentLicense = (payload, credentialName, toastSuccessMsg) => {
    if (payload[collapseCardName]._id) {
      payload.type = "edit";
      _editAgentLicense(payload, toastSuccessMsg);
    } else {
      _addAgentLicense(payload, toastSuccessMsg);
    }
  };

  const _addAgentLicense = (data, toastSuccessMsg = null) => {
    console.log("payload", data);

    return new Promise((resolve, reject) => {
      // this.setState({ loading: true });
      setLoading(true);
      // const { agentDetails } = deepClone(this.state);
      addAgentProfileDetail(agentDetails._id, data)
        .then(async (res) => {
          await getAgentProfileDetail();
          showToast(
            toastSuccessMsg ? toastSuccessMsg : "Add Agent Detail Successfully",
            "success"
          );
          setLoading(false);
          _closeModal();
          // this.setState({ loading: false });
          resolve(true);
        })
        .catch((error) => {
          errorHandler(error);
          setLoading(false);
          // this.setState({ loading: false });
          resolve(false);
        });
    });
  };

  const _editAgentLicense = (data, toastSuccessMsg = null) => {
    console.log("payload", data);

    return new Promise((resolve, reject) => {
      // this.setState({ loading: true });
      setLoading(true);
      // const { agentDetails } = deepClone(this.state);
      editAgentProfileDetail(agentDetails._id, data)
        .then(async (res) => {
          await getAgentProfileDetail();
          showToast(
            toastSuccessMsg ? toastSuccessMsg : "Add Agent Detail Successfully",
            "success"
          );
          // this.setState({ loading: false });
          setLoading(false);
          _closeModal();
          resolve(true);
        })
        .catch((error) => {
          errorHandler(error);
          // this.setState({ loading: false });
          setLoading(false);
          resolve(false);
        });
    });
  };

  const _deleteFile = (cardName) => {
    const newState = deepClone({ ...credentialData });
    newState.document.file = {
      previewBlob: null,
      uploadData: null,
      type: null,
    };
    newState.document.isDirty = true;
    // this.setState({ [cardName]: newState[cardName] }, () => {
    // validation
    setCredentialData(newState);
    _validateForm(newState);
    // });
  };

  const _updateOrAddAgentProfileDetail = (data, toastSuccessMsg = null) => {
    return new Promise((resolve, reject) => {
      // this.setState({ loading: true });
      setLoading(true);

      // FOR EDIT
      updateAgentProfileDetail(agentDetails._id, data)
        .then(async (res) => {
          await getAgentProfileDetail();
          showToast(
            toastSuccessMsg ? toastSuccessMsg : "Agent Details Updated",
            "success"
          );
          // this.setState({ loading: false });
          setLoading(false);
          _closeModal();
          resolve(true);
        })
        .catch((error) => {
          errorHandler(error);
          setLoading(false);
          _closeModal();
          // this.setState({ loading: false });
          resolve(false);
        });
    });
  };
  return (
    <Modal isOpen={isOpen} toggle={_closeModal} centered scrollbar>
      <ModalHeader toggle={_closeModal}>
        {collapseCardName && collapseCardName === "nnaCertification" ? (
          "NNA Signing Agent Course Certificate"
        ) : collapseCardName === "lssCertification" ? (
          "Notary Training Certification"
        ) : (
          <>
            {typeof data === "object" ? "Edit" : "Add"}{" "}
            {collapseCardName && convertToTitleCase(collapseCardName)}
          </>
        )}
      </ModalHeader>
      {console.log("qw", credentialData)}
      <ModalBody>
        {collapseCardName === "notaryLicense" ||
        collapseCardName === "barLicense" ? (
          <>
            {" "}
            <FormGroup className="floatingLabel">
              <Input
                type="text"
                placeholder=" "
                value={credentialData?.licenseNumber?.value}
                name="licenseNumber"
                onChange={(e) =>
                  _onChangeFormField(
                    collapseCardName,
                    "licenseNumber",
                    e.target.value
                  )
                }
              />
              <Label>Licence Number</Label>
            </FormGroup>
          </>
        ) : null}
        {credentialData.licenseNumber.error && (
          <div className="validation-error">
            {credentialData.licenseNumber.error}
          </div>
        )}
        {collapseCardName === "notaryLicense" ||
        collapseCardName === "barLicense" ||
        collapseCardName === "ronCredentials" ? (
          <FormGroup className="floatingLabel">
            <div className="custom-select-wrapper">
              <Input
                type="select"
                value={credentialData.state.value}
                name="state"
                onChange={(e) =>
                  _onChangeFormField(collapseCardName, "state", e.target.value)
                }
              >
                <option value="">Select</option>
                {usaStates.map((state) => (
                  <option key={state.abbreviation} value={state.abbreviation}>
                    {state.name}
                  </option>
                ))}
              </Input>
              <Label>State</Label>
            </div>
          </FormGroup>
        ) : null}
        {credentialData.state.error && (
          <div className="validation-error">{credentialData.state.error}</div>
        )}

        {/*FOR Background Check Report* */}
        {collapseCardName === "backgroundCheckReport" && (
          <FormGroup className="floatingLabel">
            <Input
              type="text"
              placeholder=" "
              value={credentialData.nameOfCompany.value}
              name="nameOfCompany"
              onChange={(e) =>
                _onChangeFormField(
                  collapseCardName,
                  "nameOfCompany",
                  e.target.value
                )
              }
            />
            <Label>Company</Label>
          </FormGroup>
        )}
        {credentialData.nameOfCompany.error && (
          <div className="validation-error">
            {credentialData.nameOfCompany.error}
          </div>
        )}
        {/*FOR E&O Insurance */}
        {collapseCardName === "eoInsurance" && (
          <>
            <FormGroup className="floatingLabel">
              <Input
                type="text"
                placeholder=" "
                value={credentialData.companyName.value}
                name="companyName"
                onChange={(e) =>
                  _onChangeFormField(
                    collapseCardName,
                    "companyName",
                    e.target.value
                  )
                }
              />
              <Label>Company</Label>
            </FormGroup>
            {credentialData.companyName.error && (
              <div className="validation-error">
                {credentialData.companyName.error}
              </div>
            )}

            <FormGroup className="floatingLabel">
              <Input
                type="text"
                value={credentialData.policyNumber.value}
                name="policyNumber"
                placeholder=" "
                onChange={(e) =>
                  _onChangeFormField(
                    collapseCardName,
                    "policyNumber",
                    e.target.value
                  )
                }
              />
              <Label>Policy Number</Label>
            </FormGroup>
            {credentialData.policyNumber.error && (
              <div className="validation-error">
                {credentialData.policyNumber.error}
              </div>
            )}

            <FormGroup className="floatingLabel withInputGroup">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                <Input
                  type="text"
                  value={credentialData.amount.value}
                  name="amount"
                  placeholder=" "
                  onChange={(e) =>
                    _onChangeFormField(
                      collapseCardName,
                      "amount",
                      e.target.value
                    )
                  }
                />
                <Label>Amount</Label>
              </InputGroup>
              {/* <div className="validation-error">{formFields.amount.error}</div> */}
              {credentialData.amount.error && (
                <div className="validation-error">
                  {credentialData.amount.error}
                </div>
              )}
            </FormGroup>
          </>
        )}

        {/* RON Credentials */}
        {collapseCardName === "w9Credentials" ? (
          <FormGroup className="floatingLabel">
            <div className="custom-select-wrapper">
              <Input
                type="select"
                placeholder=" "
                value={credentialData.certficationYear.value}
                name="certficationYear"
                onChange={(e) =>
                  _onChangeFormField(
                    collapseCardName,
                    "certficationYear",
                    e.target.value
                  )
                }
              >
                <option value={currentYear - 1}>{currentYear - 1}</option>
                <option value={currentYear}>{currentYear}</option>
                <option value={currentYear + 1}>{currentYear + 1}</option>
              </Input>
              <Label>Year</Label>
            </div>
            {credentialData.certficationYear.error && (
              <div className="validation-error">
                {credentialData.certficationYear.error}
              </div>
            )}
          </FormGroup>
        ) : null}
        {/*FOR E&O Insurance */}
        {/* <FormGroup className="floatingLabel">
          <Input type="text" />
          <Label>State</Label>
        </FormGroup> */}
        {collapseCardName === "notaryLicense" ||
        collapseCardName === "backgroundCheckReport" ||
        collapseCardName === "eoInsurance" ? (
          <FormGroup className="floatingLabel valueAdded">
            <ReactDatetime
              inputProps={{
                className: "form-control",
                placeholder: "Select",
                value: formatDate(credentialData.expiredOn.value),
              }}
              closeOnSelect={true}
              timeFormat={false}
              onChange={(e) =>
                _onChangeFormField(collapseCardName, "expiredOn", e._d)
              }
              isValidDate={(current) => {
                return collapseCardName === "backgroundCheckReport"
                  ? !current.isAfter(getYesterdayDate())
                  : current.isAfter(getYesterdayDate());
              }}
            />
            <Label>
              {collapseCardName === "backgroundCheckReport"
                ? "Issued Date"
                : "Expiration Date"}
            </Label>
            {/* {formFields.date.error && ( */}
            {/* <div className="validation-error">Required</div> */}
            {/* )} */}
            {credentialData.expiredOn.error && (
              <div className="validation-error">
                {credentialData.expiredOn.error}
              </div>
            )}
          </FormGroup>
        ) : null}

        {/* upload  */}

        <Label
          className="uploadDocument"
          for="uploadFile_add_expense_modal"
          // onClick={() =>
          //   !isRegularUser() && this._toggleUpgradeAccountModal(true)
          // }
        >
          <Input
            type="file"
            id="uploadFile_add_expense_modal"
            accept="image/x-png,image/gif,image/jpeg,.pdf"
            value=""
            disabled={loading}
            onChange={(event) => _updateFile(collapseCardName, event)}
          />
          <img
            src={require("../../../assets/img/uploadIcon.png").default}
            alt="file upload"
          />
          <div className="uploadLabel">
            <h4>Upload Document</h4>
            <p>File size must be less than 5mb</p>
          </div>
        </Label>
        {credentialData.document.error && (
          <div className="validation-error">
            {credentialData.document.error}
          </div>
        )}

        {/* upload Preview */}
        {credentialData.document.file &&
          credentialData.document.file.previewBlob && (
            <div className="uploadPreview">
              <div className="uploadedImg">
                <img
                  src={
                    credentialData.document.file.type === "image"
                      ? credentialData.document.file.previewBlob
                      : require("../../../assets/img/pdf.svg").default
                  }
                  alt="file Icon"
                />
              </div>

              <span>
                {credentialData.document.file.uploadData
                  ? credentialData.document.file.uploadData.name
                  : credentialData.document.file.previewBlob
                  ? credentialData.title.value
                  : null}
              </span>

              <Button
                color="danger"
                className="deletePreview"
                onClick={_deleteFile}
              >
                <img
                  src={require("../../../assets/img/close.png").default}
                  alt="deletePreview"
                />
              </Button>
            </div>
          )}
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="lg" outline onClick={_closeModal}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={loading}
          size="lg"
          onClick={_onSaveCredentail}
        >
          {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
// }

export default EditCredentialModal;
