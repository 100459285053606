import React, { Component } from "react";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Progress,
  CustomInput,
  ListGroupItem,
  ListGroup,
  Tooltip,
} from "reactstrap";
import usaStates from "../../../config/usa_states_titlecase";
import {
  deepClone,
  formatAddressInSingleText,
  getPhoneNumberFromBrackets,
  showToast,
} from "../../../helper-methods";
import { RegexConfig } from "../../../config/RegexConfig";
import { googlePlaceSearch } from "../../../helper-methods/googleService";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import {
  APP_URL,
  DEFAULT_PROFILE_PICTURE,
  LanguageList,
  profilePercentageType,
} from "../../../config";
import { getRonPlatformTypes } from "../../../http/http-calls";
import UpgradeAccountModal from "./Modals/upgradeAccountModal";
import QRCodeModal from "./Modals/QRCodeModal";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const animatedComponents = makeAnimated();
const profilePercentageFields = {
  "Profile Picture": 10,
  Email: 10,
  Phone: 10,
  "Language Known": 5,
  Address: 10,
  "ID Verification Approved": 10,
  "BCR Approved": 10,
  "EO Insurance Approved": 10,
  // "Notary Bond Cred Approved": 5,
  "W9 Cred Approved": 5,
  "Notary License (All should be approved)": 10,
  "Bank KYC": 10,
};

export class AgentProfileDetails extends Component {
  state = {
    basicDetails: {
      name: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      businessName: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      isBusinessNameUsed: {
        value: false,
        error: null,
        isDirty: false,
        isValidate: true,
      },
      email: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      phoneCountry: {
        value: "US",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      phone: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      // google full adress
      agentAddress: {
        value: "",
        placeId: null,
        error: null,
        isDirty: false,
        isValidate: true,
      },
      // manual address
      address: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      city: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      state: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      zip: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      country: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      // google full adress
      storeGoogleAddress: {
        value: "",
        placeId: null,
        error: null,
        isDirty: false,
        isValidate: true,
      },
      // manual address
      storeAddress: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      storeCity: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      storeState: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      storeZip: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      storeCountry: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      isProvideMobileServices: {
        value: true,
        error: null,
        isDirty: false,
        isValidate: false,
      },
      isProvideInStoreServices: {
        value: false,
        error: null,
        isDirty: false,
        isValidate: false,
      },
      password: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      profilePicture: {
        file: {
          previewBlob: null,
          uploadData: null,
          type: null,
        },
        error: null,
        isDirty: false,
      },
      coverPhoto: {
        file: {
          previewBlob: null,
          uploadData: null,
          type: null,
        },
        error: null,
        isDirty: false,
      },
      workingStates: {
        value: [],
        error: null,
        isDirty: false,
        isValidate: false,
      },
      languagesKnown: {
        value: [],
        error: null,
        isDirty: false,
        isValidate: false,
      },
      nnaProfile: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      websiteUrl: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      userName: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      bio: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      linkedInUrl: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      ronPlatformTypes: {
        value: [],
        isDirty: false,
        isValidate: true,
      },
    },
    agentDetails: {},
    manuallyTypeAddress: false,
    googleAddressResult: [],
    isAddressListShow: false, // boolean
    manuallyTypeStoreAddress: false,
    googleStoreAddressResult: [],
    isStoreAddressListShow: false, // boolean
    showPassword: {
      password: false,
    },
    changePassword: false,
    // agentDetails:{},
    upgradeAccountModal: {
      isOpen: false,
      data: null,
    },
    QRCodeModal: {
      isOpen: false,
      data: null,
    },
    profileCompletePercentage: 0,
    ronPlatformTypeOptions: {},
    placeholder: " ",
    tooltipOpen: false,
  };

  _toggleTooltip = () =>
    this.setState({ tooltipOpen: !this.state.tooltipOpen });

  _resetBasicDetailsErrorDirty = () => {
    const { basicDetails } = this.state;
    Object.keys(basicDetails).forEach((key) => {
      basicDetails[key].error = null;
      basicDetails[key].isDirty = false;
    });
    this.setState({ basicDetails });
  };

  _setFormData = (data, ronPlatformTypeOptions) => {
    let { basicDetails, manuallyTypeAddress, manuallyTypeStoreAddress } =
      deepClone(this.state);

    basicDetails["name"].value = data.name.full;
    basicDetails["businessName"].value = data?.businessName;
    basicDetails["isBusinessNameUsed"].value = data?.isBusinessNameUsed;
    basicDetails["email"].value = data.email;

    basicDetails["phone"].value = data.phone
      ? getPhoneNumberFromBrackets(data.phone)
      : "";
    basicDetails["phoneCountry"].value = data.phoneCountry
      ? data.phoneCountry
      : "US";

    if (data.address) {
      if (!data.address.lat && !data.address.lng) {
        manuallyTypeAddress = true;
      }
      console.log(data.address);
      basicDetails["agentAddress"].value = formatAddressInSingleText(
        data.address
      );
      basicDetails["agentAddress"].placeId = "initialValue";
      basicDetails["address"].value = data.address.street
        ? data.address.street
        : "";
      basicDetails["city"].value = data.address.city ? data.address.city : "";
      basicDetails["state"].value = data.address.state
        ? data.address.state
        : "";
      basicDetails["zip"].value = data.address.zip ? data.address.zip : "";
      basicDetails["country"].value = data.address.country
        ? data.address.country
        : "";
    }

    if (data?.storeAddress) {
      if (!data?.storeAddress?.lat && !data?.storeAddress?.lng) {
        manuallyTypeStoreAddress = true;
      }
      basicDetails["storeGoogleAddress"].value = formatAddressInSingleText(
        data?.storeAddress
      );
      basicDetails["storeGoogleAddress"].placeId = "initialValue";
      basicDetails["storeAddress"].value = data?.storeAddress?.street
        ? data?.storeAddress?.street
        : "";
      basicDetails["storeCity"].value = data?.storeAddress?.city
        ? data?.storeAddress?.city
        : "";
      basicDetails["storeState"].value = data?.storeAddress?.state
        ? data?.storeAddress?.state
        : "";
      basicDetails["storeZip"].value = data?.storeAddress?.zip
        ? data?.storeAddress?.zip
        : "";
      basicDetails["storeCountry"].value = data?.storeAddress?.country
        ? data?.storeAddress?.country
        : "";
    }

    basicDetails["isProvideMobileServices"].value =
      data?.isProvideMobileServices || data?.isProvideMobileServices === false
        ? data?.isProvideMobileServices
        : true;
    basicDetails["isProvideInStoreServices"].value =
      data?.isProvideInStoreServices || false;

    basicDetails["profilePicture"].file = {
      previewBlob: data.profilePicture ? data.profilePicture : null,
      uploadData: null,
      type: null,
    };
    basicDetails["coverPhoto"].file = {
      previewBlob: data.coverPhoto ? data.coverPhoto : null,
      uploadData: null,
      type: null,
    };
    basicDetails["workingStates"].value = data.workingStates
      ? data.workingStates
      : "";
    basicDetails["languagesKnown"].value =
      data?.languagesKnown?.length > 0 ? data.languagesKnown : ["English"];

    basicDetails["ronPlatformTypes"].value = data?.ronPlatformTypes?.length
      ? data?.ronPlatformTypes?.map((each) => ({
          label: each,
          value: each,
        }))
      : [];

    basicDetails["nnaProfile"].value = data.nnaProfile ? data.nnaProfile : "";

    basicDetails["websiteUrl"].value = data.websiteUrl ? data.websiteUrl : "";
    basicDetails["userName"].value = data.userName ? data.userName : data?._id;
    basicDetails["bio"].value = data.bio ? data.bio : "";
    basicDetails["linkedInUrl"].value = data.linkedInUrl
      ? data.linkedInUrl
      : "";

    if (Object.keys(ronPlatformTypeOptions)?.length) {
      const temp = [];
      ronPlatformTypeOptions?.forEach((each) => {
        data?.ronPlatformTypes.forEach((subEach) => {
          if (subEach === each?.value) {
            temp.push(each);
          }
        });
      });

      basicDetails["ronPlatformTypes"].value = temp || [];
    }
    this.setState({
      basicDetails,
      manuallyTypeAddress,
      manuallyTypeStoreAddress,
    });
  };

  handleFocus = () => {
    this.setState({ placeholder: "https://www.closewise.com" });
  };

  // Function to handle blur event
  handleBlur = () => {
    this.setState({ placeholder: " " });
  };

  delayedForEach = (array, delay) => {
    let index = 0;
    const intervalId = setInterval(() => {
      if (index >= array.length) {
        clearInterval(intervalId);
        return;
      }
      this._calculateProfilePercentage(array[index]);
      index++;
    }, delay);
  };

  componentDidMount = async () => {
    const res = await getRonPlatformTypes();
    const ronOptions = Object.keys(res?.ronPlatformTypes)?.map((each) => ({
      label: each,
      value: res?.ronPlatformTypes[each],
    }));
    this.setState({
      ronPlatformTypeOptions: ronOptions,
    });

    setTimeout(() => {
      this.delayedForEach(profilePercentageType, 1000);
    }, 0);
  };

  _calculateProfilePercentage = (type) => {
    const { agentDetails } = deepClone(this.props);
    const { profileCompletePercentage } = deepClone(this.state);

    switch (type) {
      case "profilePicture":
        if (agentDetails?.profilePicture) {
          this.setState({
            profileCompletePercentage: profileCompletePercentage + 10,
          });
        }
        break;

      case "email":
        if (agentDetails?.email) {
          this.setState({
            profileCompletePercentage: profileCompletePercentage + 10,
          });
        }
        break;

      case "phone":
        if (agentDetails?.phone) {
          this.setState({
            profileCompletePercentage: profileCompletePercentage + 10,
          });
        }
        break;

      case "languagesKnown":
        if (agentDetails?.languagesKnown) {
          this.setState({
            profileCompletePercentage: profileCompletePercentage + 5,
          });
        }
        break;

      case "address":
        if (
          agentDetails?.address &&
          Object.keys(agentDetails?.address).length > 0
        ) {
          this.setState({
            profileCompletePercentage: profileCompletePercentage + 10,
          });
        }
        break;
      case "idVerification":
        if (agentDetails?.idVerification?.isVerified === "Approved") {
          this.setState({
            profileCompletePercentage: profileCompletePercentage + 10,
          });
        }
        break;
      case "backgroundCheckReport":
        if (agentDetails?.backgroundCheckReport?.isVerified === "Approved") {
          this.setState({
            profileCompletePercentage: profileCompletePercentage + 10,
          });
        }
        break;
      case "eoInsurance":
        if (agentDetails?.eoInsurance?.isVerified === "Approved") {
          this.setState({
            profileCompletePercentage: profileCompletePercentage + 10,
          });
        }
        break;
      // case "notaryBond":
      //   if (agentDetails?.notaryBond?.isVerified === "Approved") {
      //     this.setState({
      //       profileCompletePercentage: profileCompletePercentage + 5,
      //     });
      //   }
      //   break;
      case "w9Credentials":
        if (agentDetails?.w9Credentials?.isVerified === "Approved") {
          this.setState({
            profileCompletePercentage: profileCompletePercentage + 5,
          });
        }
        break;
      case "bank":
        if (
          agentDetails?.kyc?.paymentEnabled &&
          agentDetails?.kyc?.payoutEnabled
        ) {
          this.setState({
            profileCompletePercentage: profileCompletePercentage + 10,
          });
        }
        break;
      case "notaryLicense":
        if (
          agentDetails?.notaryLicense?.length > 0 &&
          agentDetails?.notaryLicense.every(
            (obj) => obj?.isVerified === "Approved"
          )
        ) {
          this.setState({
            profileCompletePercentage: profileCompletePercentage + 10,
          });
        }
        break;
      default:
        break;
    }

    // if (agentDetails?.profilePicture) {
    //   percentage += 5;
    // }
    // if (agentDetails.email) {
    //   percentage += 10;
    // }
    // if (agentDetails.phone) {
    //   percentage += 10;
    // }
    // if (agentDetails.languagesKnown) {
    //   percentage += 5;
    // }
    // if (Object.keys(agentDetails.address).length>0) {
    //   percentage += 10;
    // }
    // Notary Commission => 10%
    // if (agentDetails?.idVerification?.isVerified === "Approved") {
    //   percentage += 10;
    // }
    // if (agentDetails?.backgroundCheckReport?.isVerified === "Approved") {
    //   percentage += 10;
    // }
    // if (agentDetails?.eoInsurance?.isVerified === "Approved") {
    //   percentage += 10;
    // }
    // if (agentDetails?.notaryBond?.isVerified === "Approved") {
    //   percentage += 5;
    // }
    // if (agentDetails?.w9Credentials?.isVerified === "Approved") {
    //   percentage += 5;
    // }
    // if (Object.keys(agentDetails?.bank)) {
    //   percentage += 10;
    // }
    // return percentage;
  };
  componentDidUpdate = (prevProps, prevState) => {
    const { activeTab, agentDetails } = deepClone(this.props);
    const { ronPlatformTypeOptions } = deepClone(this.state);

    if (
      activeTab === "1" &&
      (JSON.stringify(agentDetails) !==
        JSON.stringify(prevProps.agentDetails) ||
        prevProps.activeTab !== "1")
    ) {
      this.setState({ agentDetails });
      this._resetBasicDetailsErrorDirty();
      this._setFormData(agentDetails, ronPlatformTypeOptions);
    }
  };

  _validateBasicDetailForm = () => {
    return new Promise((resolve, reject) => {
      const { basicDetails, manuallyTypeAddress, manuallyTypeStoreAddress } =
        this.state;

      let isFormValid = true;

      Object.keys(basicDetails).forEach((key) => {
        if (basicDetails[key].isDirty && basicDetails[key].isValidate) {
          switch (key) {
            case "address":
            case "city":
            case "country":
            case "state":
            case "zip": {
              if (manuallyTypeAddress) {
                if (
                  basicDetails[key].value &&
                  basicDetails[key].value.trim().length
                ) {
                  basicDetails[key].isDirty = false;
                  basicDetails[key].error = null;
                } else {
                  basicDetails[key].isDirty = true;
                  basicDetails[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                basicDetails[key].isDirty = false;
                basicDetails[key].error = null;
              }
              break;
            }

            case "storeAddress":
            case "storeCity":
            case "storeCountry":
            case "storeState":
            case "storeZip": {
              if (
                manuallyTypeStoreAddress &&
                basicDetails?.isProvideInStoreServices?.value
              ) {
                if (
                  basicDetails[key]?.value &&
                  basicDetails[key]?.value?.trim()?.length
                ) {
                  basicDetails[key].isDirty = false;
                  basicDetails[key].error = null;
                } else {
                  basicDetails[key].isDirty = true;
                  basicDetails[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                basicDetails[key].isDirty = false;
                basicDetails[key].error = null;
              }
              break;
            }

            case "name": {
              if (
                basicDetails[key].value &&
                basicDetails[key].value.trim().length
              ) {
                basicDetails[key].isDirty = false;
                basicDetails[key].error = null;
              } else {
                basicDetails[key].isDirty = true;
                basicDetails[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "businessName": {
              if (
                basicDetails?.[key]?.value &&
                basicDetails?.[key]?.value?.trim()?.length
              ) {
                if (
                  basicDetails?.[key]?.value?.trim()?.length >= 2 &&
                  basicDetails?.[key]?.value?.trim()?.length <= 50
                ) {
                  basicDetails[key].isDirty = false;
                  basicDetails[key].error = null;
                } else {
                  basicDetails[key].isDirty = true;
                  basicDetails[key].error = "*must be 2-50 chars long";
                  isFormValid = false;
                }
              } else {
                basicDetails[key].isDirty = false;
                basicDetails[key].error = null;
              }
              break;
            }

            case "agentAddress": {
              if (!manuallyTypeAddress) {
                if (
                  basicDetails[key].value &&
                  basicDetails[key].value.trim().length
                ) {
                  if (basicDetails[key].placeId) {
                    basicDetails[key].isDirty = false;
                    basicDetails[key].error = null;
                  } else {
                    basicDetails[key].isDirty = true;
                    basicDetails[key].error = "*Invalid Address";
                    isFormValid = false;
                  }
                } else {
                  basicDetails[key].isDirty = true;
                  basicDetails[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                basicDetails[key].isDirty = false;
                basicDetails[key].error = null;
              }
              break;
            }

            case "storeGoogleAddress": {
              if (
                !manuallyTypeStoreAddress &&
                basicDetails?.isProvideInStoreServices?.value
              ) {
                if (
                  basicDetails[key]?.value &&
                  basicDetails[key]?.value?.trim()?.length
                ) {
                  if (basicDetails[key]?.placeId) {
                    basicDetails[key].isDirty = false;
                    basicDetails[key].error = null;
                  } else {
                    basicDetails[key].isDirty = true;
                    basicDetails[key].error = "*Invalid Address";
                    isFormValid = false;
                  }
                } else {
                  basicDetails[key].isDirty = true;
                  basicDetails[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                basicDetails[key].isDirty = false;
                basicDetails[key].error = null;
              }
              break;
            }

            case "email": {
              if (
                basicDetails[key].value &&
                basicDetails[key].value.trim().length
              ) {
                if (
                  RegexConfig.email.test(
                    String(basicDetails[key].value).toLowerCase()
                  )
                ) {
                  basicDetails[key].isDirty = false;
                  basicDetails[key].error = null;
                } else {
                  basicDetails[key].isDirty = true;
                  basicDetails[key].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                basicDetails[key].isDirty = true;
                basicDetails[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "phone": {
              if (basicDetails[key].value && basicDetails[key].value.length) {
                if (
                  RegexConfig.phone.test(
                    String(basicDetails[key].value).toLowerCase()
                  )
                ) {
                  basicDetails[key].isDirty = false;
                  basicDetails[key].error = null;
                } else {
                  isFormValid = false;
                  basicDetails[key].isDirty = true;
                  basicDetails[key].error = "*Invalid Phone Number";
                }
              } else {
                basicDetails[key].isDirty = false;
                basicDetails[key].error = null;
              }
              break;
            }

            case "bio": {
              if (
                basicDetails[key].value &&
                basicDetails[key].value.trim().length
              ) {
                if (
                  !(
                    basicDetails[key].value.trim().length <= 2 ||
                    basicDetails[key].value.trim().length >= 500
                  )
                ) {
                  basicDetails[key].isDirty = false;
                  basicDetails[key].error = null;
                } else {
                  basicDetails[key].isDirty = true;
                  basicDetails[key].error =
                    "*Bio should contain 2 to 500 character";
                  isFormValid = false;
                }
              } else {
                basicDetails[key].isDirty = false;
                basicDetails[key].error = null;
              }
              break;
            }

            // case "linkedInUrl": {
            //   if (
            //     basicDetails[key].value &&
            //     basicDetails[key].value.trim().length
            //   ) {
            //     if (
            //       new RegExp(
            //         "^(http(s)?://)?([w]+.)?linkedin.com/(pub|in|profile)"
            //       ).test(String(basicDetails[key].value).toLowerCase())
            //     ) {
            //       basicDetails[key].isDirty = false;
            //       basicDetails[key].error = null;
            //     } else {
            //       basicDetails[key].isDirty = true;
            //       basicDetails[key].error = "*Invalid linkedIn Url";
            //       isFormValid = false;
            //     }
            //   } else {
            //     basicDetails[key].isDirty = false;
            //     basicDetails[key].error = null;
            //   }
            //   break;
            // }

            case "nnaProfile":
            case "websiteUrl": {
              if (
                basicDetails[key]?.value &&
                basicDetails[key]?.value?.trim()?.length
              ) {
                if (
                  RegexConfig.url.test(
                    String(basicDetails[key].value).toLowerCase()
                  )
                ) {
                  basicDetails[key].isDirty = false;
                  basicDetails[key].error = null;
                } else {
                  basicDetails[key].isDirty = true;
                  basicDetails[key].error = "*Invalid Url";
                  isFormValid = false;
                }
              } else {
                basicDetails[key].isDirty = false;
                basicDetails[key].error = null;
              }
              break;
            }

            case "userName": {
              if (
                basicDetails[key].value &&
                basicDetails[key].value.trim().length
              ) {
                if (basicDetails[key].value.trim().length < 3) {
                  basicDetails[key].isDirty = true;
                  basicDetails[key].error =
                    "*This should contain atleast 3 character";
                  isFormValid = false;
                } else {
                  basicDetails[key].isDirty = false;
                  basicDetails[key].error = null;
                }
              } else {
                basicDetails[key].isDirty = true;
                basicDetails[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            default: {
            }
          }
        }
      });

      this.setState({ basicDetails }, () => {
        resolve(isFormValid);
      });
    });
  };

  _updateFileImageBasicDetail = (fieldName, event) => {
    const { basicDetails } = deepClone(this.state);

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      let objFile = event.target.files[0];
      let objFileType = objFile.type.split("/")[0];
      if (objFileType === "image") {
        basicDetails[fieldName].file = {
          previewBlob: URL.createObjectURL(objFile),
          uploadData: objFile,
          type: objFileType === "application" ? "pdf" : objFileType,
        };
        this.setState({ basicDetails });
      } else {
        showToast("Only Image file is allowed", "error");
      }
    }
  };

  _deleteFile = () => {
    const { basicDetails } = deepClone(this.state);
    basicDetails.profilePicture.file = {
      previewBlob: null,
      uploadData: null,
      type: null,
    };
    basicDetails.profilePicture.isDirty = true;
    basicDetails.profilePicture.error = null;

    this.setState({ basicDetails });
  };

  _toggleManualOrGoogleAddress = (fieldName) => {
    this._resetBasicDetailsErrorDirty();

    this.setState({
      [fieldName]: !this.state[fieldName],
      googleAddressResult: [],
      isAddressListShow: false,
    });
  };

  _onSelectAddress = (fieldName, addr) => {
    const { basicDetails } = deepClone(this.state);

    basicDetails[fieldName].value = addr.description;
    basicDetails[fieldName].placeId = addr.place_id;
    basicDetails[fieldName].isDirty = true;

    this.setState(
      {
        basicDetails,
        isAddressListShow:
          fieldName === "agentAddress" ? false : this.state.isAddressListShow,
        isStoreAddressListShow:
          fieldName === "storeGoogleAddress"
            ? false
            : this.state.isStoreAddressListShow,
      },
      () => {
        this._validateBasicDetailForm();
      }
    );
  };

  _googlePlaceSearch = async (searchValue, type = "agentAddress") => {
    const googleAddressResult = await googlePlaceSearch(searchValue);

    if (type === "agentAddress") {
      this.setState({ googleAddressResult, isAddressListShow: true });
    } else if (type === "storeGoogleAddress") {
      this.setState({
        googleStoreAddressResult: googleAddressResult,
        isStoreAddressListShow: true,
      });
    }
  };

  _onChangeBasicDetail = (fieldName, value) => {
    const { basicDetails } = this.state;
    // if (fieldName === "email") {
    //   return;
    // }

    if (fieldName === "agentAddress" || fieldName === "storeGoogleAddress") {
      this._googlePlaceSearch(value, fieldName);
      basicDetails[fieldName].placeId = null;
    }

    if (fieldName === "phone") {
      basicDetails[fieldName].value = value.replace(/\D/g, "");
    } else {
      basicDetails[fieldName].value = value;
    }
    basicDetails[fieldName].isDirty = true;

    if (fieldName === "workingStates" || fieldName === "languagesKnown") {
      let val = value.map((item) => item.value);
      basicDetails[fieldName].value = val;
    }

    this.setState({ basicDetails }, () => {
      // Validation
      this._validateBasicDetailForm();
    });
  };

  _markAllBasicDetailFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { basicDetails } = this.state;
      Object.keys(basicDetails).forEach((e) => {
        basicDetails[e].isDirty = true;
      });
      this.setState({ basicDetails }, () => resolve(true));
    });
  };

  _onSubmitBasicDetail = async (e) => {
    if (e) e.preventDefault();

    await this._markAllBasicDetailFieldDirty();

    const isFormValid = await this._validateBasicDetailForm();

    if (isFormValid) {
      const { basicDetails, manuallyTypeAddress, manuallyTypeStoreAddress } =
        deepClone(this.state);

      this.props.onSubmitBasicDetail(
        basicDetails,
        manuallyTypeAddress,
        manuallyTypeStoreAddress
      );
      // basicDetails["password"].value = "";

      this.setState({
        basicDetails,
        showPassword: {
          password: false,
        },
        changePassword: false,
      });
    }
  };

  downloadQRCode = () => {
    // Generate download with use canvas and stream
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${this?.props?.agentDetails?.id}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  _toggleUpgradeAccountModal = (isOpen = false, data = null) => {
    this.setState({
      upgradeAccountModal: {
        isOpen,
        data,
      },
    });
  };

  _toggleUpgradeQRCode = (isOpen = false, data = null) => {
    this.setState({
      QRCodeModal: {
        isOpen,
        data,
      },
    });
  };

  render() {
    const {
      basicDetails,
      manuallyTypeAddress,
      googleAddressResult,
      isAddressListShow,
      showPassword,
      upgradeAccountModal,
      profileCompletePercentage,
      ronPlatformTypeOptions,
      manuallyTypeStoreAddress,
      googleStoreAddressResult,
      isStoreAddressListShow,
      tooltipOpen,
    } = this.state;

    const { loading, percentage } = this.props;
    return (
      <>
        <Card>
          {/* cover image */}
          <div
            className={`coverImageWrapper ${
              basicDetails.coverPhoto?.file?.previewBlob ? "" : "notUpdated"
            }`}
          >
            <img
              src={
                basicDetails.coverPhoto.file &&
                basicDetails.coverPhoto.file.previewBlob
                  ? basicDetails.coverPhoto.file.previewBlob
                  : require("../../../assets/img/background_image.png").default
              }
              alt="cover "
              className="coverImage"
              style={{ objectFit: "contain" }}
            />
            <Label className="uploadUserInfo">
              <Input
                type="file"
                hidden
                disabled={loading}
                accept="image/x-png,image/gif,image/jpeg"
                value=""
                onChange={(event) =>
                  this._updateFileImageBasicDetail("coverPhoto", event)
                }
              />
              <h3>Replace Banner Image</h3>
              <span>
                <img
                  src={require("../../../assets/img/replaceimg.svg").default}
                  alt="replace"
                />{" "}
                {basicDetails.coverPhoto?.file?.previewBlob
                  ? "Replace"
                  : "Upload"}
              </span>
            </Label>
          </div>
          {/* user profile */}

          <div className="profileInfo">
            <div className="d-flex">
              <Label className="uploadProfile ">
                <div className="circularProgress">
                  <CircularProgressbar
                    value={profileCompletePercentage}
                    // text={`${profileCompletePercentage}%`}
                    strokeWidth={4}
                    styles={buildStyles({
                      pathColor: "#2FE47F",
                    })}
                  />
                  <span className="currentPercentage">
                    {profileCompletePercentage}%
                  </span>
                </div>
                <Input
                  type="file"
                  style={{ display: "none" }}
                  disabled={loading}
                  accept="image/x-png,image/gif,image/jpeg"
                  value=""
                  onChange={(event) =>
                    this._updateFileImageBasicDetail("profilePicture", event)
                  }
                />
                <img
                  src={
                    basicDetails.profilePicture.file &&
                    basicDetails.profilePicture.file.previewBlob
                      ? basicDetails.profilePicture.file.previewBlob
                      : DEFAULT_PROFILE_PICTURE
                  }
                  alt="Profile Img"
                />
                <div className="replacePhoto">
                  <img
                    // src={require("../../../assets/img/camera.svg").default}
                    alt="camera"
                    style={{ objectFit: "contain" }}
                    src={require("../../../assets/img/camera.svg").default}
                    // alt="cover "
                  />

                  <span>
                    {basicDetails.profilePicture?.file?.previewBlob
                      ? "Change Profile"
                      : "Upload"}
                  </span>
                </div>
              </Label>

              <i
                id="amountTooltip"
                className="fa fa-info-circle ml-2"
                style={{
                  fontSize: "14px",
                  color: "blue",
                  cursor: "pointer",
                }}
                onMouseEnter={() => this.setState({ tooltipOpen: true })}
                onMouseLeave={() => this.setState({ tooltipOpen: false })}
              />
              <Tooltip
                isOpen={tooltipOpen}
                target="amountTooltip"
                toggle={this._toggleTooltip}
                placement="right"
              >
                <p className="font-weight-bold">
                  Profile Completion Weightage:
                </p>
                <ul>
                  {Object.keys(profilePercentageFields)?.map((key) => (
                    <li>
                      {key}: {profilePercentageFields?.[key]}%
                    </li>
                  ))}
                </ul>
              </Tooltip>
            </div>

            {/* button to remove profile picture */}
            {/* {basicDetails?.profilePicture?.file &&
            basicDetails?.profilePicture?.file?.previewBlob ? (
              <Button
                color="danger"
                className="deletePreview"
                onClick={this._deleteFile}
              >
                <img
                  src={require("../../../assets/img/close.png").default}
                  alt="deletePreview"
                />
              </Button>
            ) : null} */}

            {this.state.agentDetails?.isNotaryPromo ? (
              <Button
                color="primary"
                outline
                onClick={() =>
                  // !this.state.agentDetails?.isNotaryPromo &&
                  this._toggleUpgradeQRCode(true)
                }
              >
                <i className="fa fa-qrcode mr-2" aria-hidden="true"></i>
                QR Code
              </Button>
            ) : (
              <Button
                color="primary"
                outline
                onClick={() =>
                  !this.state.agentDetails?.isNotaryPromo &&
                  this._toggleUpgradeAccountModal(true)
                }
              >
                <i className="fa fa-qrcode mr-2" aria-hidden="true"></i>
                Generate Bar Code
              </Button>
            )}
          </div>

          <CardBody>
            <Row>
              <Col xl={6} lg={12} md={6}>
                <FormGroup className="floatingLabel">
                  <Input
                    type="text"
                    value={basicDetails.name.value}
                    name="name"
                    onChange={(event) =>
                      this._onChangeBasicDetail("name", event.target.value)
                    }
                    placeholder=" "
                  />
                  <Label>Name</Label>
                  {basicDetails.name.error && (
                    <div className="validation-error">
                      {basicDetails.name.error}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col xl={6} lg={12} md={6}>
                <FormGroup className="floatingLabel">
                  <Input
                    type="email"
                    value={basicDetails.email.value}
                    // disabled={true}
                    name="email"
                    onChange={(event) =>
                      this._onChangeBasicDetail("email", event.target.value)
                    }
                    placeholder=" "
                  />
                  <Label>Email</Label>
                  {basicDetails.email.error && (
                    <div className="validation-error">
                      {basicDetails.email.error}
                    </div>
                  )}
                </FormGroup>
              </Col>
              <Col xl={6} lg={12} md={6}>
                <FormGroup className="floatingLabel withInputGroup">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>+1</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="phone"
                      value={basicDetails.phone.value}
                      name="phone"
                      onChange={(event) =>
                        this._onChangeBasicDetail("phone", event.target.value)
                      }
                      placeholder=" "
                    />
                    <Label>Phone</Label>
                  </InputGroup>
                  {basicDetails.phone.error && (
                    <div className="validation-error">
                      {basicDetails.phone.error}
                    </div>
                  )}
                </FormGroup>
              </Col>
              {/* <Col xl={6} lg={12} md={6}>
                <FormGroup className="floatingLabel">
                  <Input
                    type="text"
                    value={basicDetails?.nnaProfile?.value}
                    name="nnaProfile"
                    onChange={(event) =>
                      this._onChangeBasicDetail(
                        "nnaProfile",
                        event.target.value
                      )
                    }
                    placeholder=" "
                  />
                  <Label>NNA Profile</Label>
                  {basicDetails?.nnaProfile?.error && (
                    <div className="validation-error">
                      {basicDetails?.nnaProfile?.error}
                    </div>
                  )}
                </FormGroup>
              </Col> */}
              {/* <Col xl={6} lg={12} md={6}>
                <FormGroup className="floatingLabel withInputGroup">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="fa fa-linkedin"></i>
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      name="linkedin"
                      value={basicDetails.linkedInUrl.value}
                      onChange={(event) =>
                        this._onChangeBasicDetail(
                          "linkedInUrl",
                          event.target.value
                        )
                      }
                      placeholder=" "
                    />
                    <Label>Linkedin</Label>
                  </InputGroup>
                  {basicDetails.linkedInUrl.error && (
                    <div className="validation-error">
                      {basicDetails.linkedInUrl.error}
                    </div>
                  )}
                </FormGroup>
              </Col> */}
              <Col xl={6} lg={12} md={6}>
                <div className="showInputBusiness">
                  <FormGroup className="floatingLabel">
                    <Input
                      type="text"
                      value={basicDetails?.businessName?.value}
                      name="businessname"
                      onChange={(event) =>
                        this._onChangeBasicDetail(
                          "businessName",
                          event.target.value
                        )
                      }
                      placeholder=" "
                    />
                    <Label>Business Name</Label>
                  </FormGroup>

                  {basicDetails?.businessName?.value?.length ? (
                    <CustomInput
                      id="isBusinessNameUsedCheckbox"
                      type="checkbox"
                      name="isBusinessNameUsedCheckbox"
                      className="mb-2"
                      checked={basicDetails?.isBusinessNameUsed?.value}
                      onChange={(e) =>
                        this._onChangeBasicDetail(
                          "isBusinessNameUsed",
                          e.target.checked
                        )
                      }
                      label="Use as Vendor Name"
                    />
                  ) : null}
                </div>
              </Col>
              <Col xl={6} lg={12} md={6}>
                <FormGroup className="floatingLabel withInputGroup">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <img
                          src={
                            require("../../../assets/img/globeIcon.svg").default
                          }
                          alt="globe"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="website"
                      // placeholder="https://www.closewise.com"
                      placeholder={this.state.placeholder}
                      onFocus={this.handleFocus}
                      onBlur={this.handleBlur}
                      value={basicDetails.websiteUrl.value}
                      onChange={(event) =>
                        this._onChangeBasicDetail(
                          "websiteUrl",
                          event.target.value
                        )
                      }
                    />
                    <Label>Website</Label>
                  </InputGroup>

                  {basicDetails?.websiteUrl?.error && (
                    <div className="validation-error">
                      {basicDetails?.websiteUrl?.error}
                    </div>
                  )}
                </FormGroup>
              </Col>
              {this.state.agentDetails?.isNotaryPromo ? (
                <Col xl={6} lg={12} md={6}>
                  <FormGroup className="floatingLabel">
                    {/* <div>{`${APP_URL}/agent-about/`} </div> */}
                    <InputGroup>
                      <Input
                        type="text"
                        name=""
                        value={`${APP_URL}/agent-about/`}
                        placeholder="n "
                        disabled
                      />
                      <Input
                        type="text"
                        name="userName"
                        value={basicDetails.userName.value}
                        onChange={(event) =>
                          this._onChangeBasicDetail(
                            "userName",
                            event.target.value
                          )
                        }
                        placeholder=" "
                      />
                      <Label>Custom Profile Name</Label>
                    </InputGroup>

                    {basicDetails.userName.error && (
                      <div className="validation-error">
                        {basicDetails.userName.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              ) : null}

              <Col sm="12">
                <FormGroup className="floatingLabel">
                  <Input
                    type="textarea"
                    name="bio"
                    value={basicDetails.bio.value}
                    onChange={(event) =>
                      this._onChangeBasicDetail("bio", event.target.value)
                    }
                    placeholder=" "
                  />
                  <Label>Bio</Label>
                  {basicDetails.bio.error && (
                    <div className="validation-error">
                      {basicDetails.bio.error}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col sm="12">
                <CustomInput
                  type="switch"
                  className="mt-2 mb-3"
                  checked={manuallyTypeAddress}
                  onChange={() =>
                    this._toggleManualOrGoogleAddress("manuallyTypeAddress")
                  }
                  id="agent_profile_address_Custom_Switch"
                  name="borrowerAddress"
                  label="Enter Address Manually"
                />
              </Col>

              {manuallyTypeAddress ? (
                <>
                  <Col sm="12">
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        value={basicDetails.address.value}
                        name="address"
                        onChange={(event) =>
                          this._onChangeBasicDetail(
                            "address",
                            event.target.value
                          )
                        }
                        placeholder=" "
                      />
                      <Label>Address</Label>
                      {basicDetails.address.error && (
                        <div className="validation-error">
                          {basicDetails.address.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl={6} lg={12} md={6}>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        value={basicDetails.city.value}
                        name="city"
                        onChange={(event) =>
                          this._onChangeBasicDetail("city", event.target.value)
                        }
                        placeholder=" "
                      />
                      <Label>City</Label>
                      {basicDetails.city.error && (
                        <div className="validation-error">
                          {basicDetails.city.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl={6} lg={12} md={6}>
                    <FormGroup className="floatingLabel custom-select-wrapper">
                      <Input
                        type="select"
                        value={basicDetails.state.value}
                        name="state"
                        onChange={(event) =>
                          this._onChangeBasicDetail("state", event.target.value)
                        }
                        placeholder=" "
                      >
                        <option value="">Select</option>
                        {usaStates.map((state) => (
                          <option
                            key={state.abbreviation}
                            value={state.abbreviation}
                          >
                            {state.name}
                          </option>
                        ))}
                      </Input>
                      <Label>State</Label>
                      {basicDetails.state.error && (
                        <div className="validation-error">
                          {basicDetails.state.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl={6} lg={12} md={6}>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        value={basicDetails.zip.value}
                        name="zip"
                        onChange={(event) =>
                          this._onChangeBasicDetail("zip", event.target.value)
                        }
                        placeholder=" "
                      />
                      <Label>Zip</Label>
                      {basicDetails.zip.error && (
                        <div className="validation-error">
                          {basicDetails.zip.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl={6} lg={12} md={6}>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        value={basicDetails.country.value}
                        name="country"
                        onChange={(event) =>
                          this._onChangeBasicDetail(
                            "country",
                            event.target.value
                          )
                        }
                        placeholder=" "
                      />
                      <Label>Country</Label>
                      {basicDetails.country.error && (
                        <div className="validation-error">
                          {basicDetails.country.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </>
              ) : (
                <Col sm="12">
                  <FormGroup className="floatingLabel withInputGroup">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-map-marker" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        value={basicDetails.agentAddress.value}
                        name="agentAddress"
                        placeholder=" "
                        autoComplete="off"
                        onChange={(event) =>
                          this._onChangeBasicDetail(
                            "agentAddress",
                            event.target.value
                          )
                        }
                      />
                      <Label>Address</Label>
                    </InputGroup>
                    {basicDetails.agentAddress.error && (
                      <div className="validation-error">
                        {basicDetails.agentAddress.error}
                      </div>
                    )}
                    {/* address suggestion list for input tag - borrower address */}
                    {googleAddressResult &&
                    googleAddressResult.length &&
                    isAddressListShow ? (
                      <ListGroup flush className="customSearchOptions">
                        {googleAddressResult.map((addr, index) => {
                          return (
                            <ListGroupItem
                              key={addr.place_id || index}
                              className="cursorPointer"
                              onClick={() =>
                                this._onSelectAddress("agentAddress", addr)
                              }
                            >
                              {addr.description}
                            </ListGroupItem>
                          );
                        })}
                      </ListGroup>
                    ) : null}
                  </FormGroup>
                </Col>
              )}

              <Col sm="12 mb-2">
                <CustomInput
                  id="isProvideMobileServices"
                  type="checkbox"
                  name="isProvideMobileServices"
                  className="mb-2"
                  checked={basicDetails?.isProvideMobileServices?.value}
                  onChange={(event) =>
                    this._onChangeBasicDetail(
                      "isProvideMobileServices",
                      event.target.checked
                    )
                  }
                  label="I provide Mobile services"
                />
              </Col>

              {/* {basicDetails?.isProvideInStoreServices?.value ? ( */}
              {/* <> */}
              <Col sm="12">
                <CustomInput
                  type="switch"
                  className="mt-2 mb-3"
                  checked={manuallyTypeStoreAddress}
                  onChange={() =>
                    this._toggleManualOrGoogleAddress(
                      "manuallyTypeStoreAddress"
                    )
                  }
                  id="agent_store_address_Custom_Switch"
                  name="storeAddress"
                  label="Enter Store Address Manually"
                />
              </Col>

              {manuallyTypeStoreAddress ? (
                <>
                  <Col sm="12">
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        value={basicDetails?.storeAddress?.value}
                        name="storeAddress"
                        onChange={(event) =>
                          this._onChangeBasicDetail(
                            "storeAddress",
                            event.target.value
                          )
                        }
                        placeholder=" "
                      />
                      <Label>Store Address</Label>
                      {basicDetails?.storeAddress?.error && (
                        <div className="validation-error">
                          {basicDetails?.storeAddress?.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl={6} lg={12} md={6}>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        value={basicDetails?.storeCity?.value}
                        name="storeCity"
                        onChange={(event) =>
                          this._onChangeBasicDetail(
                            "storeCity",
                            event.target.value
                          )
                        }
                        placeholder=" "
                      />
                      <Label>City</Label>
                      {basicDetails?.storeCity?.error && (
                        <div className="validation-error">
                          {basicDetails?.storeCity?.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl={6} lg={12} md={6}>
                    <FormGroup className="floatingLabel custom-select-wrapper">
                      <Input
                        type="select"
                        value={basicDetails?.storeState?.value}
                        name="storeState"
                        onChange={(event) =>
                          this._onChangeBasicDetail(
                            "storeState",
                            event.target.value
                          )
                        }
                        placeholder=" "
                      >
                        <option value="">Select</option>
                        {usaStates?.map((state) => (
                          <option
                            key={state?.abbreviation}
                            value={state?.abbreviation}
                          >
                            {state?.name}
                          </option>
                        ))}
                      </Input>
                      <Label>State</Label>
                      {basicDetails?.storeState?.error && (
                        <div className="validation-error">
                          {basicDetails?.storeState?.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl={6} lg={12} md={6}>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        value={basicDetails?.storeZip?.value}
                        name="storeZip"
                        onChange={(event) =>
                          this._onChangeBasicDetail(
                            "storeZip",
                            event.target.value
                          )
                        }
                        placeholder=" "
                      />
                      <Label>Zip</Label>
                      {basicDetails?.storeZip?.error && (
                        <div className="validation-error">
                          {basicDetails?.storeZip?.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col xl={6} lg={12} md={6}>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        value={basicDetails?.storeCountry?.value}
                        name="storeCountry"
                        onChange={(event) =>
                          this._onChangeBasicDetail(
                            "storeCountry",
                            event.target.value
                          )
                        }
                        placeholder=" "
                      />
                      <Label>Country</Label>
                      {basicDetails?.storeCountry?.error && (
                        <div className="validation-error">
                          {basicDetails?.storeCountry?.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </>
              ) : (
                <Col sm="12">
                  <FormGroup className="floatingLabel withInputGroup">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="fa fa-map-marker" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        value={basicDetails?.storeGoogleAddress?.value}
                        name="storeGoogleAddress"
                        placeholder=" "
                        autoComplete="off"
                        onChange={(event) =>
                          this._onChangeBasicDetail(
                            "storeGoogleAddress",
                            event.target.value
                          )
                        }
                      />
                      <Label>Store Address</Label>
                    </InputGroup>
                    {basicDetails?.storeGoogleAddress?.error && (
                      <div className="validation-error">
                        {basicDetails?.storeGoogleAddress?.error}
                      </div>
                    )}
                    {/* address suggestion list for input tag - borrower address */}
                    {googleStoreAddressResult &&
                    googleStoreAddressResult?.length &&
                    isStoreAddressListShow ? (
                      <ListGroup flush className="customSearchOptions">
                        {googleStoreAddressResult?.map((addr, index) => {
                          return (
                            <ListGroupItem
                              key={addr?.place_id || index}
                              className="cursorPointer"
                              onClick={() =>
                                this._onSelectAddress(
                                  "storeGoogleAddress",
                                  addr
                                )
                              }
                            >
                              {addr?.description}
                            </ListGroupItem>
                          );
                        })}
                      </ListGroup>
                    ) : null}
                  </FormGroup>
                </Col>
              )}
              {/* </> */}
              {/* ) : null} */}
              <Col sm="12 mb-2">
                <CustomInput
                  id="isProvideInStoreServices"
                  type="checkbox"
                  name="isProvideInStoreServices"
                  className="mb-2"
                  checked={basicDetails?.isProvideInStoreServices?.value}
                  onChange={(event) =>
                    this._onChangeBasicDetail(
                      "isProvideInStoreServices",
                      event.target.checked
                    )
                  }
                  label="I provide In-store services"
                />
              </Col>

              <Col md={12}>
                <FormGroup
                  className="withChips floatingLabel valueAdded form-group"
                  style={{ zIndex: 5 }}
                >
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    options={usaStates.map((item) => ({
                      value: item.abbreviation,
                      label: item.name,
                    }))}
                    value={usaStates
                      .map((each) => ({
                        value: each.abbreviation,
                        label: each.name,
                      }))
                      .filter((item) =>
                        basicDetails.workingStates.value.includes(item.value)
                      )}
                    isMulti
                    onChange={(event) =>
                      this._onChangeBasicDetail("workingStates", event)
                    }
                    placeholder=" + Add State"
                    className="customMultiSelect"
                  />
                  <Label style={{ zIndex: "3" }}>Working States</Label>
                </FormGroup>
              </Col>
              <Col sm="12">
                <FormGroup
                  className="withChips floatingLabel valueAdded form-group"
                  style={{ zIndex: 4 }}
                >
                  <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    options={LanguageList.map(({ label, value }) => ({
                      value,
                      label,
                    }))}
                    value={LanguageList.filter((item) =>
                      basicDetails.languagesKnown.value.includes(item.value)
                    )}
                    isMulti
                    onChange={(event) =>
                      this._onChangeBasicDetail("languagesKnown", event)
                    }
                    className="customMultiSelect zIndex2"
                  />
                  <Label style={{ zIndex: 2 }}>Add Language</Label>
                </FormGroup>
              </Col>
              <Col sm="12">
                <FormGroup
                  className="withChips floatingLabel valueAdded form-group"
                  style={{ zIndex: 3 }}
                >
                  <Select
                    // closeMenuOnSelect={false}
                    components={animatedComponents}
                    isClearable={true}
                    placeholder="Select"
                    options={ronPlatformTypeOptions || []}
                    value={basicDetails?.ronPlatformTypes?.value}
                    onChange={(event) =>
                      this._onChangeBasicDetail("ronPlatformTypes", event)
                    }
                    // placeholder="RON Platform Type "
                    className="customMultiSelect zIndex2"
                    isMulti={true}
                  />
                  <Label style={{ zIndex: 2 }}>RON Platform Type</Label>
                </FormGroup>
              </Col>
              {/* 
              <Col sm="12">
                <FormGroup className="withChips floatingLabel form-group">
                  <Select
                    // closeMenuOnSelect={false}
                    components={animatedComponents}
                    isClearable={true}
                    options={this.props.ronPlatformOptions}
                    value={basicDetails?.ronPlatformTypes?.value}
                    onChange={(event) =>
                      this._onChangeBasicDetail("ronPlatformTypes", event)
                    }
                    placeholder="RON Platform Type "
                    className="customMultiSelect zIndex2"
                    isMulti={true}
                  />
                  <Label>RON Platform Type</Label>
                </FormGroup>
              </Col> */}

              <Col xl={4} lg={12} md={12}>
                <CustomInput
                  id="unassigned"
                  type="checkbox"
                  name="unassigned"
                  className="mb-2"
                  checked={this.state.changePassword}
                  onChange={(e) =>
                    this.setState({
                      changePassword: !this.state.changePassword,
                    })
                  }
                  label="Change Password"
                />
                {this.state.changePassword ? (
                  <FormGroup className=" floatingLabel ">
                    <InputGroup>
                      <Input
                        type={showPassword.password ? "text" : "password"}
                        value={basicDetails.password.value}
                        name="password"
                        onChange={(event) =>
                          this._onChangeBasicDetail(
                            "password",
                            event.target.value
                          )
                        }
                        placeholder=" "
                      />
                      <Label style={{ zIndex: 2 }}>Enter New Password</Label>
                      <InputGroupAddon addonType="append">
                        <InputGroupText
                          onClick={() =>
                            this.setState({
                              showPassword: {
                                ...this.state.showPassword,
                                password: !this.state.showPassword.password,
                              },
                            })
                          }
                        >
                          <img
                            src={
                              require(`../../../assets/img/${
                                showPassword.password
                                  ? "eyeIcon.svg"
                                  : "eyeIconSlash.svg"
                              }`).default
                            }
                            alt="eye icon"
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>

                    {basicDetails.password.error && (
                      <div className="validation-error">
                        {basicDetails.password.error}
                      </div>
                    )}
                  </FormGroup>
                ) : null}
              </Col>
            </Row>
            <div className="text-center mt-3">
              <Button
                color="primary"
                disabled={loading}
                size="lg"
                onClick={this._onSubmitBasicDetail}
              >
                {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
                Save
              </Button>
            </div>
          </CardBody>

          {loading && Number(percentage) > 0 && (
            <Progress animated color="success" value={percentage} />
          )}
        </Card>
        {/* modal */}
        <UpgradeAccountModal
          {...this.props}
          isOpen={upgradeAccountModal.isOpen}
          toggle={this._toggleUpgradeAccountModal}
        />
        <QRCodeModal
          agentDetails={this.state.agentDetails}
          isOpen={this.state.QRCodeModal.isOpen}
          toggle={this._toggleUpgradeQRCode}
        />
      </>
    );
  }
}

export default AgentProfileDetails;
