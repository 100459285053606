import React, { memo, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  Row,
  Col,
  FormGroup,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  CardHeader,
  Card,
  CardBody,
} from "reactstrap";
import TextEditor from "./TextEdittor";
import { useSelector } from "react-redux";
import {
  EmailCustomizationSubject,
  // EmailCustomizationTitle,
  EmailCustomizationVariable,
} from "../../../config";
import { useState } from "react";
import { deepClone, errorHandler, showToast } from "../../../helper-methods";
import {
  createCustomEmailAgent,
  createCustomEmailClient,
  createCustomEmailCompany,
  updateCustomEmailAgent,
  updateCustomEmailClient,
  updateCustomEmailCompany,
} from "../../../http/http-calls";
// import CopyToClipboard from "react-copy-to-clipboard";
import { TeamMemberInvitation } from "../../../emails/Team member invitation(company)";
import { customClientTeamMemberInvitaion } from "../../../emails/custom-client-team-member-invitaion";
import { customAgentPayPeriod } from "../../../emails/custom-agent-pay-period";
import { customAgentRegistrationInvite } from "../../../emails/custom-agent-registration-invite";
import { footer } from "../../../emails/footer";
import { cutomPaymentReceivedByCompanyFromClient } from "../../../emails/cutom-payment-received-by-company-from-client";
import { customAgentCreateInvoice } from "../../../emails/custom-agent-create-invoice";
import { customCompanyInvoice } from "../../../emails/custom-company-invoice";
import { customAgentInvoice } from "../../../emails/custom-agent-invoice";
import { customClientInvitesCompanyToConnect } from "../../../emails/custom-client-invites-company-to-connect";
import { customClientInvitesUnregisteredSiginingCompanyToConnectOnClosewise } from "../../../emails/custom-client-invites-unregistered-sigining-company-to-connect-on-closewise";
import { customCompanyInvitesClientToCreateClosewiseConnectAccount } from "../../../emails/custom-company-invites-client-to-create-closewise-connect-account";
import { customCompanyInvitesClientToConnect } from "../../../emails/custom-company-invites-client-to-connect";
import { customAgentFeeUpdate } from "../../../emails/custom-agent-fee-update";
import { customCompanyUploadedDocument } from "../../../emails/custom-company-uploaded-document";
import { customCompanyVerifiedDocs } from "../../../emails/custom-company-verified-docs";
import { customConfirmAppointmentReminder } from "../../../emails/custom-confirm-appointment-reminder";
import { customClosingStatusAgent } from "../../../emails/custom-closing-status-agent";
import { customClosingAssigned } from "../../../emails/custom-closing-assigned";
import { customClosingUnassigned } from "../../../emails/custom-closing-unassigned";
import { customClosingStatusCompany } from "../../../emails/custom-closing-status-company";
import { customNewMatchedClosing } from "../../../emails/custom-new-matched-closing";
import { customAppointmentPreReminder } from "../../../emails/custom-appointment-pre-reminder";
import { customAppointmentPostReminder } from "../../../emails/custom-appointment-post-reminder";

import {
  customAgentCreateInvoiceClone,
  customAgentFeeUpdateClone,
  customAgentInvoiceClone,
  customAgentPayPeriodClone,
  customAgentRegistrationInviteClone,
  customClientInvitesCompanyToConnectClone,
  customClientInvitesUnregisteredSiginingCompanyToConnectOnClosewiseClone,
  customClientTeamMemberInvitaionClone,
  customClosingStatusAgentClone,
  customCompanyInvitesClientToConnectClone,
  customCompanyInvitesClientToCreateClosewiseConnectAccountClone,
  customCompanyInvoiceClone,
  customCompanyUploadedDocumentClone,
  customCompanyVerifiedDocsClone,
  customConfirmAppointmentReminderClone,
  cutomPaymentReceivedByCompanyFromClientClone,
  TeamMemberInvitationClone,
  customAgentScheduleClone,
  customAgentUploadedDocumentClone,
  customAchPaymentClone,
  customAppointmentPostReminderClone,
  customAppointmentPreReminderClone,
  customNewMatchedClosingClone,
  customClosingStatusCompanyClone,
  customClosingUnassignedClone,
  customClosingAssignedClone,
} from "../../../emails/bodyToClone";
import { customAchPayment } from "../../../emails/custom-ach-payment";
import { customAgentSchedule } from "../../../emails/custom-agent-schedule";
import { customAgentUploadedDocument } from "../../../emails/custom-agent-uploaded-document";

// const bodyToClone = `Dear companyName ,

// I hope this email finds you well. I am writing to let you know that I have created a new invoice for services rendered. The invoice is attached to this email for your review.

// The invoice includes all the relevant details, such as the order number, and the amount due. If you have any questions or concerns regarding the invoice, please do not hesitate to reach out to me.

// I appreciate your business and look forward to working with you in the future.

// Thank you,

// agentName`;
let initialFields = {
  from: "",
  to: "",
  replyTo: "",
  body: "",
  subject: "",
  footer: "",
  title: "",
  buttonText: "",
};

let initialIsDirty = {
  body: false,
  from: false,
  footer: false,
  subject: false,
  title: false,
  replyTo: false,
};

function CustomizeEmailModal(props) {
  const userData = useSelector((state) => state.userData);
  const [emailFields, setEmailFields] = useState(deepClone(initialFields));
  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState(deepClone(initialIsDirty));
  const [isLoading, setIsLoading] = useState(false);
  const [isFocusTextEditor, setIsFocusTextEditor] = useState(false);
  const [htmlStringData, setHtmlStringData] = useState("");
  // const [bodyToCloneData, setBodyToCloneData] = useState("");
  // const [subjectToCloneData, setSubjectToCloneData] = useState("");
  // const [seed, setSeed] = useState(1); // used to re-render component

  const _onClose = () => {
    _resetFields();
    props.toggle();
    setHtmlStringData("");
    // setBodyToCloneData("");
    // setSubjectToCloneData("");
  };

  useEffect(() => {
    if (props.isOpen && props.data !== null) {
      // console.log("7458",typeof props.data === "string")
      if (typeof props.data === "string") {
        setEmailFields({
          ...initialFields,
          title: props?.data || "",
          // ...emailFields,
        });
        // _onChangeFormField("title",props?.data)
        // console.log("ee", props?.data);
        htmlString(props?.data);
        buttonText(props?.data);
        subjectText(props?.data);
      } else if (typeof props.data === "object") {
        _setDataInState(props.data);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const _setDataInState = () => {
    emailFields.to = props?.data?.to || "";
    emailFields.from = props?.data?.from || "";
    emailFields.replyTo = props?.data?.replyTo || "";
    emailFields.body = props?.data?.body || "";
    emailFields.footer = props?.data?.footer || "";
    emailFields.subject = props?.data?.subject || "";
    emailFields.title = props?.data?.title || "";
    emailFields.buttonText = props?.data?.buttonText || "";

    setEmailFields({
      ...emailFields,
    });
    htmlString(props?.data?.title);
    buttonText(props?.data?.title);
  };
  const _resetFields = () => {
    setEmailFields(deepClone(initialFields));
    setIsDirty(deepClone(initialIsDirty));
    setErrors({});
    // console.log("reset",emailFields);
  };
  const _onChangeFormField = (fieldName, value) => {
    // console.log("dd", fieldName, value);
    const newEmailFields = { ...emailFields };
    const newIsDirty = { ...isDirty };
    newEmailFields[fieldName] = value;
    setEmailFields(newEmailFields);
    setIsDirty(newIsDirty);
    _validateForm(newEmailFields, newIsDirty);
  };

  const editOrUpdate = async () => {
    const newIsDirty = { ...isDirty };
    Object.keys(isDirty).forEach((each) => (newIsDirty[each] = true));

    const error = await _validateForm(emailFields, newIsDirty);
    let payload = {
      from: emailFields.from,
      // to: emailFields.to,
      replyTo: emailFields.replyTo,
      body: emailFields.body,
      footer: emailFields.footer,
      title: emailFields.title,
      buttonText: emailFields.buttonText,
      subject: emailFields.subject,
    };
    if (error) {
      if (props.isOpen && typeof props.data !== "string") {
        _updateEmail(payload);
      } else {
        _createEmail(payload);
      }
    }
  };

  const _createEmail = (payload) => {
    const userType = userData?.user?.type;

    switch (userType) {
      case "SigningCompany":
        _createEmailCompany(payload);
        break;
      case "Agent":
        _createEmailAgent(payload);
        break;
      case "Client":
        _createEmailClient(payload);
        break;
      default:
        break;
    }
  };

  const _updateEmail = (payload) => {
    const userType = userData?.user?.type;

    switch (userType) {
      case "SigningCompany":
        _updateEmailCompany(payload);
        break;
      case "Agent":
        _updateEmailAgent(payload);
        break;
      case "Client":
        _updateEmailClient(payload);
        break;
      default:
        break;
    }
  };

  const _createEmailCompany = async (payload) => {
    setIsLoading(true);
    try {
      await createCustomEmailCompany(payload);
      showToast("Created Successfully", "success");
      props.getCustomizedEmail();
      setIsLoading(false);
      _onClose();
    } catch (error) {
      errorHandler(error);
      setIsLoading(false);
    }
  };
  const _createEmailAgent = async (payload) => {
    setIsLoading(true);
    try {
      await createCustomEmailAgent(payload);
      showToast("Created Successfully", "success");
      props.getCustomizedEmail();
      setIsLoading(false);
      _onClose();
    } catch (error) {
      errorHandler(error);
      setIsLoading(false);
    }
  };
  const _createEmailClient = async (payload) => {
    setIsLoading(true);
    try {
      await createCustomEmailClient(payload);
      showToast("Created Successfully", "success");
      props.getCustomizedEmail();
      setIsLoading(false);
      _onClose();
    } catch (error) {
      errorHandler(error);
      setIsLoading(false);
    }
  };
  const _updateEmailCompany = async (payload) => {
    setIsLoading(true);
    try {
      let id = props?.data?._id;
      await updateCustomEmailCompany(id, payload);
      showToast("Created Successfully", "success");
      props.getCustomizedEmail();
      setIsLoading(false);
      _onClose();
    } catch (error) {
      errorHandler(error);
      setIsLoading(false);
    }
  };
  const _updateEmailAgent = async (payload) => {
    setIsLoading(true);
    try {
      let id = props?.data?._id;
      await updateCustomEmailAgent(id, payload);
      showToast("Created Successfully", "success");
      props.getCustomizedEmail();
      setIsLoading(false);
      _onClose();
    } catch (error) {
      errorHandler(error);
      setIsLoading(false);
    }
  };
  const _updateEmailClient = async (payload) => {
    setIsLoading(true);
    try {
      let id = props?.data?._id;
      await updateCustomEmailClient(id, payload);
      showToast("Created Successfully", "success");
      props.getCustomizedEmail();
      setIsLoading(false);
      _onClose();
    } catch (error) {
      errorHandler(error);
      setIsLoading(false);
    }
  };

  const _validateForm = (emailFields, isDirty) => {
    return new Promise((resolve, reject) => {
      const error = { ...errors };
      let isFormValid = true;

      Object.keys(emailFields).forEach((fieldName) => {
        switch (fieldName) {
          case "replyTo":
          case "from": {
            if (
              (!emailFields[fieldName].trim().length && isDirty[fieldName]) ||
              emailFields[fieldName] === "Select"
            ) {
              error[fieldName] = "*Required";
              isFormValid = false;
            } else {
              delete error[fieldName];
              isDirty[fieldName] = false;
            }
            break;
          }

          case "body":
          case "subject":
          case "title": {
            if (!emailFields[fieldName].trim().length && isDirty[fieldName]) {
              error[fieldName] = "*Required";
              isFormValid = false;
            } else {
              delete error[fieldName];
              isDirty[fieldName] = false;
            }
            break;
          }

          default: {
          }
        }
      });
      setIsDirty(isDirty);
      setErrors(error);
      resolve(isFormValid);
    });
  };

  const _handleOnCopy = (fieldName, text) => {
    // console.log("45", fieldName, text);
    _onChangeFormField(fieldName, `${emailFields[fieldName]} ${text}`);
  };

  const htmlString = (key) => {
    // console.log("htgkl",key)
    let body;
    switch (key) {
      case "custom-team-member-invitaion":
        body = TeamMemberInvitation;
        break;

      case "custom-client-team-member-invitaion":
        body = customClientTeamMemberInvitaion;
        break;

      case "custom-agent-pay-period":
        body = customAgentPayPeriod;
        break;

      case "custom-agent-registration-invite":
        body = customAgentRegistrationInvite;
        break;

      case "cutom-payment-received-by-company-from-client":
        body = cutomPaymentReceivedByCompanyFromClient;
        break;
      case "custom-agent-create-invoice":
        body = customAgentCreateInvoice;
        break;

      case "custom-company-invoice":
        body = customCompanyInvoice;
        break;

      case "custom-agent-invoice":
        body = customAgentInvoice;
        break;
      case "custom-client-invites-company-to-connect":
        body = customClientInvitesCompanyToConnect;
        break;

      case "custom-client-invites-unregistered-sigining-company-to-connect-on-closewise":
        body =
          customClientInvitesUnregisteredSiginingCompanyToConnectOnClosewise;
        break;
      case "custom-company-invites-client-to-create-closewise-connect-account":
        body = customCompanyInvitesClientToCreateClosewiseConnectAccount;
        break;
      case "custom-company-invites-client-to-connect":
        body = customCompanyInvitesClientToConnect;
        break;
      case "custom-agent-fee-update":
        body = customAgentFeeUpdate;
        break;
      case "custom-company-uploaded-document":
        body = customCompanyUploadedDocument;
        break;
      case "custom-company-verified-docs":
        body = customCompanyVerifiedDocs;
        break;

      case "custom-confirm-appointment-reminder":
        body = customConfirmAppointmentReminder;
        break;

      case "custom-closing-status-agent":
        body = customClosingStatusAgent;
        break;

      case "custom-ach-payment":
        body = customAchPayment;
        break;

      case "custom-agent-schedule":
        body = customAgentSchedule;
        break;

      case "custom-closing-assigned":
        body = customClosingAssigned;
        break;

      case "custom-closing-unassigned":
        body = customClosingUnassigned;
        break;

      case "custom-closing-status-company":
        body = customClosingStatusCompany;
        break;

      case "custom-new-matched-closing":
        body = customNewMatchedClosing;
        break;

      case "custom-appointment-pre-reminder":
        body = customAppointmentPreReminder;
        break;

      case "custom-appointment-post-reminder":
        body = customAppointmentPostReminder;
        break;

      case "custom-agent-uploaded-document":
        body = customAgentUploadedDocument;
        break;

      default:
        break;
    }
    setHtmlStringData(body);
    // emailFields.title === TeamMemberInvitation(company);
  };
  const _bodyToClone = (key) => {
    // console.log("key", key);

    let body;
    switch (key) {
      case "custom-team-member-invitaion":
        body = TeamMemberInvitationClone;
        break;

      case "custom-client-team-member-invitaion":
        body = customClientTeamMemberInvitaionClone;
        break;

      case "custom-agent-pay-period":
        body = customAgentPayPeriodClone;
        break;

      case "custom-agent-registration-invite":
        body = customAgentRegistrationInviteClone;
        break;

      case "cutom-payment-received-by-company-from-client":
        body = cutomPaymentReceivedByCompanyFromClientClone;
        break;
      case "custom-agent-create-invoice":
        body = customAgentCreateInvoiceClone;
        break;

      case "custom-company-invoice":
        body = customCompanyInvoiceClone;
        break;
      case "custom-agent-invoice":
        body = customAgentInvoiceClone;
        break;
      case "custom-client-invites-company-to-connect":
        body = customClientInvitesCompanyToConnectClone;
        break;

      case "custom-client-invites-unregistered-sigining-company-to-connect-on-closewise":
        body =
          customClientInvitesUnregisteredSiginingCompanyToConnectOnClosewiseClone;
        break;
      case "custom-company-invites-client-to-create-closewise-connect-account":
        body = customCompanyInvitesClientToCreateClosewiseConnectAccountClone;
        break;
      case "custom-company-invites-client-to-connect":
        body = customCompanyInvitesClientToConnectClone;
        break;
      case "custom-agent-fee-update":
        body = customAgentFeeUpdateClone;
        break;
      case "custom-company-uploaded-document":
        body = customCompanyUploadedDocumentClone;
        break;
      case "custom-company-verified-docs":
        body = customCompanyVerifiedDocsClone;
        break;

      case "custom-confirm-appointment-reminder":
        body = customConfirmAppointmentReminderClone;
        break;

      case "custom-closing-status-agent":
        body = customClosingStatusAgentClone;
        break;

      case "custom-ach-payment":
        body = customAchPaymentClone;
        break;

      case "custom-agent-schedule":
        body = customAgentScheduleClone;
        break;

      case "custom-closing-assigned":
        body = customClosingAssignedClone;
        break;

      case "custom-closing-unassigned":
        body = customClosingUnassignedClone;
        break;

      case "custom-closing-status-company":
        body = customClosingStatusCompanyClone;
        break;

      case "custom-new-matched-closing":
        body = customNewMatchedClosingClone;
        break;

      case "custom-appointment-pre-reminder":
        body = customAppointmentPreReminderClone;
        break;

      case "custom-appointment-post-reminder":
        body = customAppointmentPostReminderClone;
        break;

      case "custom-agent-uploaded-document":
        body = customAgentUploadedDocumentClone;
        break;

      case "footer":
        body = footer;
        break;
      default:
        break;
    }

    // console.log("787",body)
    // setBodyToCloneData(body);
    // setSeed(Math.random());
    showToast("Copied successfully", "success");
    return body;
    // return body;
    // setHtmlStringData(body);
    // emailFields.title === TeamMemberInvitation(company);
  };
  const buttonText = (key) => {
    // console.log("dvdv", key);
    //
    if (emailFields?.buttonText) return;
    let button;
    switch (key) {
      case "custom-team-member-invitaion":
        button = "Click Here to Sign-Up";
        break;

      case "custom-client-team-member-invitaion":
        button = "Click Here to Sign-Up";
        break;

      case "custom-agent-registration-invite":
        button = "CREATE ACCOUNT";
        break;

      case "custom-client-invites-company-to-connect":
        button = "Connect";
        break;

      case "custom-client-invites-unregistered-sigining-company-to-connect-on-closewise":
        button = "Connect";

        break;
      case "custom-company-invites-client-to-create-closewise-connect-account":
        button = "Connect";

        break;
      case "custom-company-invites-client-to-connect":
        button = "Connect";

        break;
      case "custom-agent-fee-update":
        button = "Click here to review";

        break;

      case "custom-company-uploaded-document":
        button = "Go To Download";
        break;

      case "custom-confirm-appointment-reminder":
        button = "Confirm Appointment";
        break;

      case "custom-closing-status-agent":
        button = "Review";
        break;
      case "custom-closing-assigned":
        button = "CONFIRM APPOINTMENT";
        break;
      case "custom-closing-status-company":
        button = "Review";
        break;

      case "custom-new-matched-closing":
        button = "RESPOND";
        break;
      case "custom-appointment-pre-reminder":
        button = "Check In";
        break;
      case "custom-appointment-post-reminder":
        button = "Update Status";
        break;

      case "custom-agent-uploaded-document":
        button = "Click here to review";
        break;

      default:
        break;
    }
    // console.log("45", button);

    setEmailFields((prevState) => ({
      ...prevState,
      buttonText: button,
    }));
  };
  const subjectText = (key) => {
    // console.log("htgkl",key)
    let subject;
    switch (key) {
      case "custom-team-member-invitaion":
        subject = "Invitation to Join Company Name Account on CloseWise";
        break;

      case "custom-client-team-member-invitaion":
        subject = "Invitation to join Company Name Connect Portal";
        break;

      case "custom-agent-pay-period":
        subject = "Pay Period Breakdown for Approval";
        break;

      case "custom-agent-registration-invite":
        subject = "Company Name Invites You to Connect with CloseWise";
        break;

      case "cutom-payment-received-by-company-from-client":
        subject = "ACH Payment Initiated";
        break;
      case "custom-agent-create-invoice":
        subject = "New invoice attached for services from Agent Name";
        break;

      case "custom-company-invoice":
        subject = "New Invoice from Company Name";
        break;
      case "custom-agent-invoice":
        subject = "Invoice Attached for $signingCompany companyName";
        break;
      case "custom-client-invites-company-to-connect":
        subject = "companyName has sent you a CloseWise Connect Invitation";
        break;

      case "custom-client-invites-unregistered-sigining-company-to-connect-on-closewise":
        subject = "companyName has sent you a CloseWise Connect Invitation";
        break;
      case "custom-company-invites-client-to-create-closewise-connect-account":
        subject = "Company Name Invites You to Connect with CloseWise";
        break;
      case "custom-company-invites-client-to-connect":
        subject = "companyName has sent you a CloseWise Connect Invitation";
        break;
      case "custom-agent-fee-update":
        subject = "Update on Agent Fee for Order [Order Number]";
        break;
      case "custom-company-uploaded-document":
        subject =
          "Docs for Signername Ready for Download Closing appointDayForEmail at appointTimeForEmail";
        break;
      case "custom-company-verified-docs":
        subject = "Your Documents Have Been Approved";
        break;

      case "custom-confirm-appointment-reminder":
        subject = "Reminder to Confirm Appointment with Signers";
        break;

      case "custom-closing-status-agent":
        subject = "Status Update for signersName file#fileNumber status";
        break;

      case "custom-ach-payment":
        subject = "ACH Payment Initiated";
        break;

      case "custom-agent-schedule":
        subject = "Schedule attached from company.companyName";
        break;
      case "custom-closing-assigned":
        subject = "NEW ASSIGNMENT RECEIVED File #Order File Number";
        break;
      case "custom-closing-unassigned":
        subject = "signersName Order Unassigned #fileNumber`";
        break;
      case "custom-closing-status-company":
        subject = "{Company Name} Status Update {Status} - Order {File Number}";
        break;
      case "custom-new-matched-closing":
        subject =
          "{Company Name} Request for Availability - {Date and Time} at {CITY,STATE}";
        break;
      case "custom-appointment-pre-reminder":
        subject = "Reminder: Appointment Check-In Time";
        break;
      case "custom-appointment-post-reminder":
        subject = "Reminder: Please Update Completion Status on Closewise";
        break;
      case "custom-agent-uploaded-document":
        subject =
          "SignerName SCANBACKS UPLOADED by agent full Name File #fileNumber";
        break;

      default:
        break;
    }
    // setEmailFields({
    //   ...initialFields,
    //   subject,
    // });
    setEmailFields((prevState) => ({
      ...prevState,
      subject,
    }));
    // setHtmlStringData(body);
    // emailFields.title === TeamMemberInvitation(company);
  };

  const _subjectToClone = (key) => {
    // console.log("key", key);

    let subject;
    switch (key) {
      case "custom-team-member-invitaion":
        subject = "Invitation to Join Company Name Account on CloseWise";
        break;

      case "custom-client-team-member-invitaion":
        subject = "Invitation to join Company Name Connect Portal";
        break;

      case "custom-agent-pay-period":
        subject = "Pay Period Breakdown for Approval";
        break;

      case "custom-agent-registration-invite":
        subject = "Company Name Invites You to Connect with CloseWise";
        break;

      case "cutom-payment-received-by-company-from-client":
        subject = "ACH Payment Initiated";
        break;
      case "custom-agent-create-invoice":
        subject = "New invoice attached for services from Agent Name";
        break;

      case "custom-company-invoice":
        subject = "New Invoice from Company Name";
        break;
      case "custom-agent-invoice":
        subject = "Invoice Attached for $signingCompany companyName";
        break;
      case "custom-client-invites-company-to-connect":
        subject = "companyName has sent you a CloseWise Connect Invitation";
        break;

      case "custom-client-invites-unregistered-sigining-company-to-connect-on-closewise":
        subject = "companyName has sent you a CloseWise Connect Invitation";
        break;
      case "custom-company-invites-client-to-create-closewise-connect-account":
        subject = "Company Name Invites You to Connect with CloseWise";
        break;
      case "custom-company-invites-client-to-connect":
        subject = "companyName has sent you a CloseWise Connect Invitation";
        break;
      case "custom-agent-fee-update":
        subject = "Update on Agent Fee for Order [Order Number]";
        break;
      case "custom-company-uploaded-document":
        subject =
          "Docs for Signername Ready for Download Closing appointDayForEmail at appointTimeForEmail";
        break;
      case "custom-company-verified-docs":
        subject = "Your Documents Have Been Approved";
        break;

      case "custom-confirm-appointment-reminder":
        subject = "Reminder to Confirm Appointment with Signers";
        break;

      case "custom-closing-status-agent":
        subject = "Status Update for signersName file#fileNumber status";
        break;

      case "custom-ach-payment":
        subject = "ACH Payment Initiated";
        break;

      case "custom-agent-schedule":
        subject = "Schedule attached from company.companyName";
        break;
      case "custom-closing-assigned":
        subject = "NEW ASSIGNMENT RECEIVED File #Order File Number";
        break;
      case "custom-closing-unassigned":
        subject = "signersName Order Unassigned #fileNumber`";
        break;
      case "custom-closing-status-company":
        subject = "{Company Name} Status Update {Status} - Order {File Number}";
        break;
      case "custom-new-matched-closing":
        subject =
          "{Company Name} Request for Availability - {Date and Time} at {CITY,STATE}";
        break;
      case "custom-appointment-pre-reminder":
        subject = "Reminder: Appointment Check-In Time";
        break;
      case "custom-appointment-post-reminder":
        subject = "Reminder: Please Update Completion Status on Closewise";
        break;
      case "custom-agent-uploaded-document":
        subject =
          "SignerName SCANBACKS UPLOADED by agent full Name File #fileNumber";
        break;

      default:
        break;
    }

    // setSubjectToCloneData(subject);
    // setSeed(Math.random());

    showToast("Copied successfully", "success");
    return subject;
  };
  return (
    <Modal isOpen={props.isOpen} scrollable centered size="xl">
      <ModalHeader toggle={() => _onClose()}> Customize Email</ModalHeader>
      {/* {console.log("123", typeof props.data !== "string")} */}
      <ModalBody>
        <Row>
          <Col lg={6} className="mb-4 mb-lg-0">
            <Row form>
              <Col md={6}>
                <FormGroup className="floatingLabel">
                  <div className="custom-select-wrapper">
                    <Input
                      type="select"
                      value={emailFields.from}
                      placeholder=" "
                      onChange={(e) =>
                        _onChangeFormField("from", e.target.value)
                      }
                    >
                      <option>Select</option>
                      <option value="email">GeneralEmail</option>
                      <option value="accountingEmail">Accounting Email</option>
                      <option value="schedulingEmail">Scheduling Email</option>
                    </Input>
                    <Label>From</Label>
                  </div>
                  {isDirty.from ? (
                    <span className="error-message">
                      <div className="validation-error"> {errors.from} </div>
                    </span>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup className="floatingLabel">
                  <div className="custom-select-wrapper">
                    <Input
                      type="select"
                      value={emailFields.replyTo}
                      onChange={(e) =>
                        _onChangeFormField("replyTo", e.target.value)
                      }
                    >
                      <option>Select</option>
                      <option value="email">GeneralEmail</option>
                      <option value="accountingEmail">Accounting Email</option>
                      <option value="schedulingEmail">Scheduling Email</option>
                    </Input>
                    <Label>Reply To</Label>
                  </div>
                  {isDirty.replyTo ? (
                    <span className="error-message">
                      <div className="validation-error"> {errors.replyTo} </div>
                    </span>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={12}>
                <FormGroup className="floatingLabel">
                  <Input
                    type="text"
                    // value={emailFields.to}
                    value={"agentemailid"}
                    onChange={(e) => _onChangeFormField("to", e.target.value)}
                    disabled
                  />
                  <Label>To</Label>
                </FormGroup>
              </Col>

              <Col md={12}>
                <UncontrolledDropdown className="customDropdown rightSide">
                  <DropdownToggle>
                    Insert Variables{" "}
                    <img
                      src={
                        require("../../../assets/img/arrow-dropdown.svg")
                          .default
                      }
                      alt="arrow"
                    />
                  </DropdownToggle>
                  <DropdownMenu right>
                    {emailFields.title.trim().length > 0 &&
                      EmailCustomizationSubject[emailFields.title].length > 0 &&
                      EmailCustomizationSubject[emailFields.title].map(
                        (item, index) => (
                          <>
                            <DropdownItem
                              key={index}
                              onClick={() => _handleOnCopy("subject", item)}
                            >
                              {item}
                            </DropdownItem>
                          </>
                        )
                      )}
                  </DropdownMenu>
                </UncontrolledDropdown>

                <FormGroup className="floatingLabel">
                  <Input
                    type="textarea"
                    placeholder=" "
                    value={emailFields.subject}
                    onChange={(e) =>
                      _onChangeFormField("subject", e.target.value)
                    }
                  />
                  <Label>Subject</Label>
                  {isDirty.subject ? (
                    <span className="error-message">
                      <div className="validation-error"> {errors.subject} </div>
                    </span>
                  ) : null}
                </FormGroup>
              </Col>
              <Col md={12}>
                <UncontrolledDropdown className="customDropdown rightSide">
                  <DropdownToggle>
                    Insert Variables{" "}
                    <img
                      src={
                        require("../../../assets/img/arrow-dropdown.svg")
                          .default
                      }
                      alt="arrow"
                    />
                  </DropdownToggle>
                  <DropdownMenu right>
                    {emailFields.title.trim().length > 0 &&
                      EmailCustomizationVariable[emailFields.title].length >
                        0 &&
                      EmailCustomizationVariable[emailFields.title].map(
                        (item, index) => (
                          <>
                            <DropdownItem
                              key={index}
                              onClick={() => _handleOnCopy("body", item)}
                            >
                              {item}
                            </DropdownItem>
                          </>
                        )
                      )}
                  </DropdownMenu>
                </UncontrolledDropdown>
                <FormGroup
                  // className="floatingLabel"
                  className={`mb-0 floatingLabel reactQuill ${
                    (emailFields.body && emailFields.body !== "<p><br></p>") ||
                    isFocusTextEditor
                      ? "valueAdded"
                      : ""
                  }`}
                >
                  <TextEditor
                    content={emailFields.body}
                    onChange={(event) => _onChangeFormField("body", event)}
                    onFocus={() => setIsFocusTextEditor(true)}
                    onBlur={() => setIsFocusTextEditor(false)}
                  />
                  <Label>Body</Label>
                  {isDirty.body ? (
                    <span className="error-message">
                      <div className="validation-error"> {errors.body} </div>
                    </span>
                  ) : null}
                </FormGroup>
              </Col>
              {emailFields?.buttonText?.trim().length ? (
                <Col md={12}>
                  <FormGroup className="floatingLabel mt-3">
                    <Input
                      type="text"
                      placeholder="Enter"
                      value={emailFields.buttonText}
                      onChange={(e) =>
                        _onChangeFormField("buttonText", e.target.value)
                      }
                    />
                    <Label>Button Text</Label>
                  </FormGroup>
                </Col>
              ) : null}
            </Row>
          </Col>
          <Col lg={6}>
            <Card>
              <CardHeader className="greybg">Existing Email</CardHeader>
              <CardBody>
                <div className="emailItem">
                  <Label>From</Label>
                  <p>
                    {emailFields.from === "email"
                      ? props.userData.email
                      : emailFields.from === "schedulingEmail"
                      ? props.userData.schedulingEmail
                      : emailFields.from === "accountingEmail"
                      ? props.userData.accountingEmail
                      : ""}
                  </p>
                </div>
                <div className="emailItem">
                  <Label>To</Label>
                  <p>agentemailid</p>
                </div>
                <div className="emailItem">
                  <Label>Reply To</Label>
                  <p>
                    {emailFields.replyTo === "email"
                      ? props.userData.email
                      : emailFields.replyTo === "schedulingEmail"
                      ? props.userData.schedulingEmail
                      : emailFields.replyTo === "accountingEmail"
                      ? props.userData.accountingEmail
                      : ""}
                  </p>
                </div>
                <div className="emailItem">
                  <Label>
                    Subject{" "}
                    <Button
                      color="link"
                      // onClick={() =>
                      //   navigator.clipboard.writeText(
                      //     _subjectToClone(emailFields?.title)
                      //   )
                      // }
                      onClick={() => {
                        navigator.clipboard.writeText(emailFields?.subject);
                        showToast("Copied successfully", "success");
                      }}
                    >
                      {/* <CopyToClipboard
                        key={seed}
                        text={subjectToCloneData}
                        onCopy={() => showToast("Copied", "success")}
                      > */}
                      <i className="fa fa-clone" />
                      {/* </CopyToClipboard> */}
                    </Button>
                  </Label>

                  <p>
                    {emailFields.subject.trim().length
                      ? emailFields.subject
                      : "No Subject"}
                  </p>
                </div>
                <div className="emailItem">
                  <Label>
                    Body{" "}
                    <Button
                      color="link"
                      onClick={() =>
                        navigator.clipboard.writeText(
                          _bodyToClone(emailFields?.title)
                        )
                      }
                    >
                      {/* <CopyToClipboard
                        key={seed}
                        text={bodyToCloneData}
                        onCopy={() => showToast("Copied", "success")}
                      > */}
                      <i className="fa fa-clone" />
                      {/* </CopyToClipboard> */}
                    </Button>
                  </Label>
                  <div className="longContent">
                    {emailFields.body.trim().length ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: emailFields.body }}
                      />
                    ) : (
                      <div
                        className="EmailBodyContent"
                        dangerouslySetInnerHTML={{ __html: htmlStringData }}
                      />
                    )}
                  </div>
                </div>
                {emailFields?.buttonText?.trim().length ? (
                  <div className="text-center mt-4">
                    <Button color="primary" size="md">
                      {emailFields.buttonText.trim().length
                        ? emailFields.buttonText
                        : "No Button Text"}
                    </Button>
                  </div>
                ) : null}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="lg" outline onClick={() => _onClose()}>
          Cancel
        </Button>
        <Button
          color="primary"
          size="lg"
          onClick={() => editOrUpdate()}
          disabled={isLoading}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default memo(CustomizeEmailModal);
