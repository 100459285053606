import React, { useEffect, useState } from "react";

import {
  Modal,
  ModalBody,
  ModalFooter,
  Button,
  Badge,
  Row,
  Col,
} from "reactstrap";
import { DEFAULT_PROFILE_PICTURE, credentialsData } from "../../../config";
import {
  formatAddressInSingleText,
  openUrlOnNewTab,
  formatDate,
  errorHandler,
} from "../../../helper-methods";
import { getAgentDetailForAssignmentModal } from "../../../http/http-calls";
import ReadMore from "./readMore";
const AgentsDetailModal = (props) => {
  const [agentDetails, setAgentDetails] = useState({});

  const _closeModal = () => {
    props.toggle();
  };

  useEffect(() => {
    _getAgentDetailsForAssignmentModal();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen]);

  const _getAgentDetailsForAssignmentModal = async () => {
    if (props?.data?._id) {
      let agentId = props.data !== null && props?.data?._id;
      try {
        let response = await getAgentDetailForAssignmentModal(agentId);

        setAgentDetails(response?.agent);
      } catch (error) {
        errorHandler(error);
      }
    }
  };
  const _isCredentialUploaded = (credentialName) => {
    if (
      credentialName === "notaryLicense" ||
      credentialName === "barLicense" ||
      credentialName === "ronCredentials"
    ) {
      return (
        agentDetails &&
        agentDetails[credentialName]?.length &&
        agentDetails[credentialName]?.map((item) => item.url)
      );
    } else {
      return (
        agentDetails &&
        agentDetails[credentialName] &&
        agentDetails[credentialName].url
      );
    }
  };
  const _countTotalUnverifiedCredentials = () => {
    if (agentDetails) {
      let count;
      let credentialsObject;
      if (agentDetails?.workType?.toLowerCase() === "attorney") {
        count = 6;

        credentialsObject = credentialsData.filter((obj) => obj.isRequired);
      } else if (agentDetails?.workType?.toLowerCase() === "notary") {
        count = 5;

        credentialsObject = credentialsData.filter(
          (obj) => obj.value !== "barLicense" && obj.isRequired
        );
      }

      credentialsObject?.forEach((obj) => {
        if (Array.isArray(agentDetails[obj.value])) {
          let isApproved =
            agentDetails[obj.value].length > 0 &&
            agentDetails[obj.value].every(
              (item) => item.isVerified === "Approved"
            );
          return isApproved ? (count -= 1) : count;
        }

        if (
          agentDetails[obj.value] &&
          agentDetails[obj.value].url &&
          agentDetails[obj.value].isVerified === "Approved"
        ) {
          count--;
        }
      });
      return count;
    }
    return null;
  };
  const credentialUnverifiedCount = _countTotalUnverifiedCredentials();

  const isWorkedWorkedWithThisAgent = () => {
    return agentDetails?.agentWorkedWithCompanys?.some(
      (item) => item === props?.company?.user?.id
    );
  };
  return (
    <>
      <Modal
        isOpen={props.isOpen}
        toggle={() => _closeModal()}
        size="lg"
        className="agentDetail"
        centered
        scrollable
      >
        {/* <ModalHeader toggle={() => _closeModal()}>Agent Detail</ModalHeader> */}
        <ModalBody>
          <div
            className={`aboutAgent ${
              credentialUnverifiedCount === 0 ? "bg-success " : "bg-warning"
            }`}
          >
            <div className="uploadProfile">
              <img
                // src={DEFAULT_PROFILE_PICTURE}
                src={
                  agentDetails?.profilePicture
                    ? agentDetails.profilePicture
                    : DEFAULT_PROFILE_PICTURE
                }
                alt="Profile Img"
              />
            </div>

            <div className="agentInfo">
              <h3>
                {agentDetails?.name?.full}{" "}
                <Badge
                  pill
                  color={
                    credentialUnverifiedCount === 0 ? "success" : "warning"
                  }
                  className="mr-2"
                >
                  {credentialUnverifiedCount === 0 ? (
                    <>
                      <i className="fa fa-check mr-2"></i> Verified
                    </>
                  ) : (
                    <>Pending</>
                  )}
                </Badge>
              </h3>

              {agentDetails?.email ? (
                <h3>Email: {agentDetails?.email}</h3>
              ) : null}
              {agentDetails?.phone ? (
                <h3>Phone: {agentDetails?.phone}</h3>
              ) : null}

              <div className="userRatingProfile">
                {agentDetails?.totalRating && (
                  <span>
                    {agentDetails?.totalRating}/5 <i className="fa fa-star"></i>
                  </span>
                )}
                <Button
                  color="link"
                  onClick={() => {
                    agentDetails?.nnaProfile &&
                      openUrlOnNewTab(agentDetails?.nnaProfile);
                  }}
                >
                  NNA Profile
                </Button>
              </div>
              <div className="description">
                <ReadMore text={agentDetails?.bio} />
              </div>
              <strong>Address</strong>
              <p className="mb-0">
                {formatAddressInSingleText(agentDetails?.address)}
              </p>
            </div>
          </div>

          {agentDetails._ratings &&
            agentDetails?._ratings.map((item, index) => {
              return (
                <div className="commentWrap">
                  <div className="date">
                    <span>{formatDate(item?.createdAt)} </span>

                    <span className="rating">
                      {" "}
                      {item?.rating}/5 <i className="fa fa-star"></i>
                    </span>
                  </div>
                  <p>{item?.comments !== "" ? item?.comments : "No Comment"}</p>
                </div>
              );
            })}

          <Row className="m-0 mt-4">
            <Col lg={6} className="order-1 order-lg-0">
              <ul className="credentialsList">
                <li>
                  <div>
                    {_isCredentialUploaded("notaryLicense") ? (
                      <>
                        {!agentDetails?.notaryLicense?.filter(
                          (item) => item?.isVerified !== "Approved"
                        ).length ? (
                          <img
                            src={
                              require("../../../assets/img/verify.svg").default
                            }
                            alt="varify"
                          />
                        ) : (
                          <img
                            src={
                              require("../../../assets/img/pending.svg").default
                            }
                            alt="pending"
                          />
                        )}
                      </>
                    ) : (
                      <img
                        src={
                          require("../../../assets/img/not-verify.svg").default
                        }
                        alt="not-verify.svg"
                      />
                    )}{" "}
                    Notary License<span className="text-danger">*</span>
                  </div>
                  {/* <Button color="link">View</Button> */}
                  {isWorkedWorkedWithThisAgent() && (
                    <>
                      {" "}
                      {_isCredentialUploaded("notaryLicense") ? (
                        <Button
                          color="link"
                          onClick={() => {
                            // agentDetails?.notaryLicense?.url &&
                            //   openUrlOnNewTab(agentDetails?.notaryLicense?.url);
                            agentDetails?.notaryLicense.map((item) =>
                              openUrlOnNewTab(item?.url)
                            );
                          }}
                        >
                          {/* {_isCredentialUploaded("notaryLicense") ? ( */}
                          <>View</>
                        </Button>
                      ) : (
                        <span>N/A</span>
                      )}
                    </>
                  )}
                </li>
                {agentDetails && agentDetails?.workType === "Attorney" && (
                  <li>
                    <div>
                      {_isCredentialUploaded("barLicense") ? (
                        <>
                          {!agentDetails?.barLicense?.filter(
                            (item) => item?.isVerified !== "Approved"
                          ).length ? (
                            <img
                              src={
                                require("../../../assets/img/verify.svg")
                                  .default
                              }
                              alt="varify"
                            />
                          ) : (
                            <img
                              src={
                                require("../../../assets/img/pending.svg")
                                  .default
                              }
                              alt="pending"
                            />
                          )}
                        </>
                      ) : (
                        <img
                          src={
                            require("../../../assets/img/not-verify.svg")
                              .default
                          }
                          alt="not-verify.svg"
                        />
                      )}{" "}
                      Bar License
                    </div>
                    {isWorkedWorkedWithThisAgent() && (
                      <>
                        {_isCredentialUploaded("barLicense") ? (
                          <Button
                            color="link"
                            onClick={() => {
                              // agentDetails?.barLicense?.url &&
                              //   openUrlOnNewTab(agentDetails?.barLicense?.url);
                              agentDetails?.barLicense.map((item) =>
                                openUrlOnNewTab(item?.url)
                              );
                            }}
                          >
                            {/* {_isCredentialUploaded("barLicense") ? ( */}
                            <>View</>
                            {/* ) : ( */}
                            {/* "Can't View" */}
                            {/* )} */}
                          </Button>
                        ) : (
                          <span>N/A</span>
                        )}
                      </>
                    )}
                  </li>
                )}
                <li>
                  <div>
                    {_isCredentialUploaded("idVerification") ? (
                      <>
                        {agentDetails?.idVerification?.isVerified ===
                        "Approved" ? (
                          <img
                            src={
                              require("../../../assets/img/verify.svg").default
                            }
                            alt="varify"
                          />
                        ) : (
                          <img
                            src={
                              require("../../../assets/img/pending.svg").default
                            }
                            alt="pending"
                          />
                        )}
                      </>
                    ) : (
                      <img
                        src={
                          require("../../../assets/img/not-verify.svg").default
                        }
                        alt="not-verify.svg"
                      />
                    )}{" "}
                    ID Verification<span className="text-danger">*</span>
                  </div>
                  {isWorkedWorkedWithThisAgent() && (
                    <>
                      {_isCredentialUploaded("idVerification") ? (
                        <Button
                          color="link"
                          onClick={() => {
                            agentDetails?.idVerification?.url &&
                              openUrlOnNewTab(
                                agentDetails?.idVerification?.url
                              );
                          }}
                        >
                          <>View</>
                        </Button>
                      ) : (
                        <span>N/A</span>
                      )}
                    </>
                  )}
                </li>
                <li>
                  <div>
                    {_isCredentialUploaded("backgroundCheckReport") ? (
                      <>
                        {agentDetails?.backgroundCheckReport?.isVerified ===
                        "Approved" ? (
                          <img
                            src={
                              require("../../../assets/img/verify.svg").default
                            }
                            alt="varify"
                          />
                        ) : (
                          <img
                            src={
                              require("../../../assets/img/pending.svg").default
                            }
                            alt="pending"
                          />
                        )}
                      </>
                    ) : (
                      <img
                        src={
                          require("../../../assets/img/not-verify.svg").default
                        }
                        alt="not-verify.svg"
                      />
                    )}{" "}
                    Background Check Report
                    <span className="text-danger">*</span>
                  </div>
                  {isWorkedWorkedWithThisAgent() && (
                    <>
                      {_isCredentialUploaded("backgroundCheckReport") ? (
                        <Button
                          color="link"
                          onClick={() => {
                            agentDetails?.backgroundCheckReport?.url &&
                              openUrlOnNewTab(
                                agentDetails?.backgroundCheckReport?.url
                              );
                          }}
                        >
                          <>View</>
                        </Button>
                      ) : (
                        <span>N/A</span>
                      )}
                    </>
                  )}
                </li>
                <li>
                  <div>
                    {_isCredentialUploaded("eoInsurance") ? (
                      <>
                        {agentDetails?.eoInsurance.isVerified === "Approved" ? (
                          <img
                            src={
                              require("../../../assets/img/verify.svg").default
                            }
                            alt="varify"
                          />
                        ) : (
                          <img
                            src={
                              require("../../../assets/img/pending.svg").default
                            }
                            alt="pending"
                          />
                        )}
                      </>
                    ) : (
                      <img
                        src={
                          require("../../../assets/img/not-verify.svg").default
                        }
                        alt="not-verify.svg"
                      />
                    )}{" "}
                    E&O Insurance<span className="text-danger">*</span>
                  </div>
                  {isWorkedWorkedWithThisAgent() && (
                    <>
                      {_isCredentialUploaded("eoInsurance") ? (
                        <Button
                          color="link"
                          onClick={() => {
                            agentDetails?.eoInsurance?.url &&
                              openUrlOnNewTab(agentDetails?.eoInsurance?.url);
                          }}
                        >
                          <>View</>
                        </Button>
                      ) : (
                        <span>N/A</span>
                      )}
                    </>
                  )}
                </li>
                {agentDetails?.w9Credentials !== undefined ? (
                  <li>
                    <div>
                      {_isCredentialUploaded("w9Credentials") ? (
                        <>
                          {agentDetails?.w9Credentials?.isVerified ===
                          "Approved" ? (
                            <img
                              src={
                                require("../../../assets/img/verify.svg")
                                  .default
                              }
                              alt="varify"
                            />
                          ) : (
                            <img
                              src={
                                require("../../../assets/img/pending.svg")
                                  .default
                              }
                              alt="pending"
                            />
                          )}
                        </>
                      ) : (
                        <img
                          src={
                            require("../../../assets/img/not-verify.svg")
                              .default
                          }
                          alt="not-verify.svg"
                        />
                      )}{" "}
                      W-9<span className="text-danger">*</span>
                    </div>
                    {isWorkedWorkedWithThisAgent() && (
                      <>
                        {_isCredentialUploaded("w9Credentials") ? (
                          <Button
                            color="link"
                            onClick={() => {
                              agentDetails?.w9Credentials?.url &&
                                openUrlOnNewTab(
                                  agentDetails?.w9Credentials?.url
                                );
                            }}
                          >
                            <>View</>
                          </Button>
                        ) : (
                          <span>N/A</span>
                        )}
                      </>
                    )}
                  </li>
                ) : null}

                <li>
                  <div>
                    {_isCredentialUploaded("nnaCertification") ? (
                      <>
                        {agentDetails.nnaCertification.isVerified ===
                        "Approved" ? (
                          <img
                            src={
                              require("../../../assets/img/verify.svg").default
                            }
                            alt="varify"
                          />
                        ) : (
                          <img
                            src={
                              require("../../../assets/img/pending.svg").default
                            }
                            alt="pending"
                          />
                        )}
                      </>
                    ) : (
                      <img
                        src={
                          require("../../../assets/img/not-verify.svg").default
                        }
                        alt="not-verify.svg"
                      />
                    )}{" "}
                    NNA Certification<span className="text-danger">*</span>
                  </div>
                  {isWorkedWorkedWithThisAgent() && (
                    <>
                      {_isCredentialUploaded("nnaCertification") ? (
                        <Button
                          color="link"
                          onClick={() => {
                            agentDetails?.nnaCertification?.url &&
                              openUrlOnNewTab(
                                agentDetails?.nnaCertification?.url
                              );
                          }}
                        >
                          <>View</>
                        </Button>
                      ) : (
                        <span>N/A</span>
                      )}
                    </>
                  )}
                </li>

                <li>
                  <div>
                    {_isCredentialUploaded("lssCertification") ? (
                      <>
                        {agentDetails.lssCertification.isVerified ===
                        "Approved" ? (
                          <img
                            src={
                              require("../../../assets/img/verify.svg").default
                            }
                            alt="varify"
                          />
                        ) : (
                          <img
                            src={
                              require("../../../assets/img/pending.svg").default
                            }
                            alt="pending"
                          />
                        )}
                      </>
                    ) : (
                      <img
                        src={
                          require("../../../assets/img/not-verify.svg").default
                        }
                        alt="not-verify.svg"
                      />
                    )}{" "}
                    LSS Certification<span className="text-danger">*</span>
                  </div>
                  {isWorkedWorkedWithThisAgent() && (
                    <>
                      {_isCredentialUploaded("lssCertification") ? (
                        <Button
                          color="link"
                          onClick={() => {
                            agentDetails?.lssCertification?.url &&
                              openUrlOnNewTab(
                                agentDetails?.lssCertification?.url
                              );
                          }}
                        >
                          <>View</>
                        </Button>
                      ) : (
                        <span>N/A</span>
                      )}
                    </>
                  )}
                </li>

                <li>
                  <div>
                    {_isCredentialUploaded("mdTitleProducerLicense") ? (
                      <>
                        {agentDetails.mdTitleProducerLicense.isVerified ===
                        "Approved" ? (
                          <img
                            src={
                              require("../../../assets/img/verify.svg").default
                            }
                            alt="varify"
                          />
                        ) : (
                          <img
                            src={
                              require("../../../assets/img/pending.svg").default
                            }
                            alt="pending"
                          />
                        )}
                      </>
                    ) : (
                      <img
                        src={
                          require("../../../assets/img/not-verify.svg").default
                        }
                        alt="not-verify.svg"
                      />
                    )}{" "}
                    MD Title Producer License
                    <span className="text-danger">*</span>
                  </div>
                  {isWorkedWorkedWithThisAgent() && (
                    <>
                      {_isCredentialUploaded("mdTitleProducerLicense") ? (
                        <Button
                          color="link"
                          onClick={() => {
                            agentDetails?.mdTitleProducerLicense?.url &&
                              openUrlOnNewTab(
                                agentDetails?.mdTitleProducerLicense?.url
                              );
                          }}
                        >
                          <>View</>
                        </Button>
                      ) : (
                        <span>N/A</span>
                      )}
                    </>
                  )}
                </li>

                <li>
                  <div>
                    {_isCredentialUploaded("titleProducerLicense") ? (
                      <>
                        {agentDetails.titleProducerLicense.isVerified ===
                        "Approved" ? (
                          <img
                            src={
                              require("../../../assets/img/verify.svg").default
                            }
                            alt="varify"
                          />
                        ) : (
                          <img
                            src={
                              require("../../../assets/img/pending.svg").default
                            }
                            alt="pending"
                          />
                        )}
                      </>
                    ) : (
                      <img
                        src={
                          require("../../../assets/img/not-verify.svg").default
                        }
                        alt="not-verify.svg"
                      />
                    )}{" "}
                    IN Title Producer License
                    <span className="text-danger">*</span>
                  </div>
                  {isWorkedWorkedWithThisAgent() && (
                    <>
                      {_isCredentialUploaded("titleProducerLicense") ? (
                        <Button
                          color="link"
                          onClick={() => {
                            agentDetails?.titleProducerLicense?.url &&
                              openUrlOnNewTab(
                                agentDetails?.titleProducerLicense?.url
                              );
                          }}
                        >
                          <>View</>
                        </Button>
                      ) : (
                        <span>N/A</span>
                      )}
                    </>
                  )}
                </li>

                <li>
                  <div>
                    {_isCredentialUploaded("nyTotalCloser") ? (
                      <>
                        {agentDetails?.nyTotalCloser?.isVerified ===
                        "Approved" ? (
                          <img
                            src={
                              require("../../../assets/img/verify.svg").default
                            }
                            alt="varify"
                          />
                        ) : (
                          <img
                            src={
                              require("../../../assets/img/pending.svg").default
                            }
                            alt="pending"
                          />
                        )}
                      </>
                    ) : (
                      <img
                        src={
                          require("../../../assets/img/not-verify.svg").default
                        }
                        alt="not-verify.svg"
                      />
                    )}{" "}
                    NY Title Closer<span className="text-danger">*</span>
                  </div>
                  {isWorkedWorkedWithThisAgent() && (
                    <>
                      {_isCredentialUploaded("nyTotalCloser") ? (
                        <Button
                          color="link"
                          onClick={() => {
                            agentDetails?.nyTotalCloser?.url &&
                              openUrlOnNewTab(agentDetails?.nyTotalCloser?.url);
                          }}
                        >
                          <>View</>
                        </Button>
                      ) : (
                        <span>N/A</span>
                      )}
                    </>
                  )}
                </li>
                <li>
                  <div>
                    {_isCredentialUploaded("notaryBond") ? (
                      <>
                        {agentDetails?.notaryBond?.isVerified === "Approved" ? (
                          <img
                            src={
                              require("../../../assets/img/verify.svg").default
                            }
                            alt="varify"
                          />
                        ) : (
                          <img
                            src={
                              require("../../../assets/img/pending.svg").default
                            }
                            alt="pending"
                          />
                        )}
                      </>
                    ) : (
                      <img
                        src={
                          require("../../../assets/img/not-verify.svg").default
                        }
                        alt="not-verify.svg"
                      />
                    )}{" "}
                    Notary Bond <span className="text-danger">*</span>
                  </div>
                  {isWorkedWorkedWithThisAgent() && (
                    <>
                      {_isCredentialUploaded("notaryBond") ? (
                        <Button
                          color="link"
                          onClick={() => {
                            agentDetails?.notaryBond?.url &&
                              openUrlOnNewTab(agentDetails?.notaryBond?.url);
                          }}
                        >
                          View
                        </Button>
                      ) : (
                        <span>N/A</span>
                      )}
                    </>
                  )}
                </li>
                <li>
                  {/* <div>
                        <i className="fa fa-check text-warning"></i> Commision
                      </div> */}
                  <div>
                    {_isCredentialUploaded("ronCredentials") ? (
                      <>
                        {!agentDetails?.ronCredentials?.filter(
                          (item) => item?.isVerified !== "Approved"
                        ).length ? (
                          <img
                            src={
                              require("../../../assets/img/verify.svg").default
                            }
                            alt="varify"
                          />
                        ) : (
                          <img
                            src={
                              require("../../../assets/img/pending.svg").default
                            }
                            alt="pending"
                          />
                        )}
                      </>
                    ) : (
                      <img
                        src={
                          require("../../../assets/img/not-verify.svg").default
                        }
                        alt="not-verify.svg"
                      />
                    )}{" "}
                    RON Credentials
                  </div>
                  {/* <Button color="link">View</Button> */}

                  {isWorkedWorkedWithThisAgent() && (
                    <>
                      {_isCredentialUploaded("ronCredentials") ? (
                        <Button
                          color="link"
                          onClick={() => {
                            // agentDetails?.ronCredentials?.url &&
                            //   openUrlOnNewTab(agentDetails?.ronCredentials?.url);

                            agentDetails?.ronCredentials.map((item) =>
                              openUrlOnNewTab(item?.url)
                            );
                          }}
                        >
                          <>View</>
                        </Button>
                      ) : (
                        <span>N/A</span>
                      )}
                    </>
                  )}
                </li>
              </ul>
            </Col>
            <Col lg={6} className="mb-4 mb-lg-0 order-0 order-lg-1">
              <ul className="agentInfoList">
                <li>
                  <div>Signings :</div>
                  <span>#{agentDetails?.closingCompleted}</span>
                </li>
                <li>
                  <div>For You :</div>
                  <span>{agentDetails?.totalSigningsForYou}</span>
                </li>
                <li>
                  <div>Comment :</div>
                  <span>{agentDetails?._ratings?.length}</span>
                </li>
              </ul>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={() => props.toggle()}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default AgentsDetailModal;
