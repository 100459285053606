import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Collapse,
  CustomInput,
} from "reactstrap";
import Swal from "sweetalert2";
import { clientTeamMembersConfig } from "../../../config";
import { errorHandler, showToast } from "../../../helper-methods";
import {
  clientDeleteAssistantTeam,
  clientGetAssistantTeams,
} from "../../../http/http-calls";
import AddEditTeamMembers from "../components/Modals/Company/AddEditTeamMembers";
import SvgIcons from "../components/SvgIcons";

const ClientTeamPage = () => {
  const userData = useSelector((state) => state?.userData);

  const [isOpenCollapse, setIsOpenCollapse] = useState("");
  const [assistantTeams, setAssistantTeams] = useState([]);
  const [teamMemberModal, setTeamMemberModal] = useState({
    isOpen: false,
    data: null,
  });

  const _onToggleTeamMemberModal = (isOpen = false, data = null) => {
    setTeamMemberModal({ isOpen, data });
  };

  const _handleCollapse = (key) => {
    setIsOpenCollapse((prev) => (prev === key ? "" : key));
  };

  const _deleteClientAssistantTeamAlert = (assistantTeamId, index) => {
    if (!assistantTeamId) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this team.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _deleteCompanyAssistantTeam(assistantTeamId, index);
      }
    });
  };

  const _deleteCompanyAssistantTeam = async (assistantTeamId, index) => {
    // const newAssistantTeams = [...assistantTeams];
    // let deletedItem;
    try {
      // deletedItem = newAssistantTeams?.splice(index, 1); // deleting item beforehand, for better ux
      // setAssistantTeams(newAssistantTeams);

      await clientDeleteAssistantTeam(assistantTeamId);
      await _getClientListTeamsOfClient(); // api to refetch data
      showToast("Team deleted successfully", "success");
    } catch (err) {
      // newAssistantTeams.splice(index, 0, deletedItem[0]);
      // setAssistantTeams(newAssistantTeams); // setting deleted item again, if api fails
      errorHandler(err);
    }
  };

  const _getClientListTeamsOfClient = async () => {
    try {
      const res = await clientGetAssistantTeams();

      setAssistantTeams(res?.assistantTeams || []);
    } catch (err) {
      errorHandler(err);
    }
  };

  useEffect(() => {
    _getClientListTeamsOfClient();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Card className="teamMembers">
        <CardHeader>
          <CardTitle>Teams</CardTitle>
          <Button
            color="link"
            className="readMore"
            onClick={() => _onToggleTeamMemberModal(true)}
          >
            Add Teams
          </Button>
        </CardHeader>
        <CardBody>
          {assistantTeams?.length ? (
            assistantTeams?.map((each, index) => (
              <div
                className={`teamsAccordian ${
                  isOpenCollapse === each?._id ? "open" : ""
                }`}
              >
                <div className="teamsHead">
                  <span onClick={() => _handleCollapse(each?._id)}>
                    {each?.teamName}
                  </span>
                  <div>
                    <Button
                      className="pencilIcon"
                      color="link"
                      onClick={() => _onToggleTeamMemberModal(true, each)}
                    >
                      <SvgIcons type={"pencil"} />
                    </Button>
                    <Button
                      className="remove"
                      color="link"
                      onClick={() =>
                        _deleteClientAssistantTeamAlert(each?._id, index)
                      }
                    >
                      <SvgIcons type={"remove"} />
                    </Button>
                    <i
                      onClick={() => _handleCollapse(each?._id)}
                      className={`fa fa-${
                        isOpenCollapse === each?._id
                          ? "chevron-up"
                          : "chevron-down"
                      }`}
                    />
                  </div>
                </div>
                <Collapse
                  className="teamsBody"
                  isOpen={isOpenCollapse === each?._id ? true : false}
                >
                  {each?.teamMembers?.filter((subEach) => subEach?._user)
                    ?.length ? (
                    <>
                      <h2>Members</h2>
                      {each?.teamMembers
                        ?.filter((subEach) => subEach?._user)
                        ?.map((teamMember) => (
                          <div className="">
                            <ul>
                              <li>
                                <h3>
                                  {teamMember?.name}{" "}
                                  {teamMember?._user?.isActive === false ? (
                                    <span className="text-danger">
                                      (Inactive)
                                    </span>
                                  ) : null}
                                </h3>
                                {clientTeamMembersConfig?.map(
                                  (permissionKey, index) => (
                                    <CustomInput
                                      key={permissionKey?.value}
                                      type="checkbox"
                                      id={`memberPermissionCheckbox_${permissionKey?.value}}`}
                                      label={permissionKey?.label}
                                      inline
                                      checked={teamMember?.roles?.includes(
                                        index + 1
                                      )}
                                      disabled={true}
                                    />
                                  )
                                )}
                              </li>
                            </ul>
                          </div>
                        ))}
                    </>
                  ) : null}

                  {each?.teamMembers?.filter((subEach) => !subEach?._user)
                    ?.length ? (
                    <>
                      <h2>CC Members</h2>
                      {each?.teamMembers
                        ?.filter((subEach) => !subEach?._user)
                        ?.map((teamMember) => (
                          <div className="">
                            <ul>
                              <li>
                                <h3>{teamMember?.name}</h3>
                                {clientTeamMembersConfig?.map(
                                  (permissionKey, index) => (
                                    <CustomInput
                                      key={permissionKey?.value}
                                      type="checkbox"
                                      id={`memberPermissionCheckbox_${permissionKey?.value}}`}
                                      label={permissionKey?.label}
                                      inline
                                      checked={teamMember?.roles?.includes(
                                        index + 1
                                      )}
                                      disabled={true}
                                    />
                                  )
                                )}
                              </li>
                            </ul>
                          </div>
                        ))}
                    </>
                  ) : null}
                </Collapse>
              </div>
            ))
          ) : (
            <div className="nodata">
              <img
                src={require("../../../assets/img/noData.svg").default}
                alt="no data"
              />
              <p>There is no data to display</p>
            </div>
          )}
        </CardBody>
      </Card>

      {teamMemberModal?.isOpen && (
        <AddEditTeamMembers
          isOpen={teamMemberModal?.isOpen}
          data={teamMemberModal?.data}
          onSuccess={_getClientListTeamsOfClient}
          toggle={() => _onToggleTeamMemberModal()}
          clientId={userData?.user?.id}
          type={"client"}
        />
      )}
    </>
  );
};

export default ClientTeamPage;
