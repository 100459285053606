import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  ListGroupItem,
  ListGroup,
} from "reactstrap";
import StarRatings from "react-star-ratings";
import ReadMore from "./readMore";

class ViewRatingModal extends Component {
  state = { rating: 5, comment: "", loading: false, showMore: false };

  _closeModal = (action) => {
    console.log("in _closeModal");
    if (!this.state.loading) {
      this.props.toggle();
    }
  };

  componentDidUpdate(previousProps, previousState) {
    const { isOpen, data } = this.props;

    if (isOpen && data && previousProps.isOpen !== isOpen) {
      // console.log("props==>", data);
      this.setState({
        rating: data.previousRating !== null ? data.previousRating.rating : 5,
        comment:
          data.previousRating !== null ? data.previousRating.comments : "",
      });
    }
  }

  changeRating = (newRating, name) => {
    this.setState({
      rating: newRating,
    });
  };

  handleOnChange = (e) => {
    this.setState({
      comment: e.target.value,
    });
  };

  render() {
    const { loading } = this.state;

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this.props.toggle(null)}
        className="modal-dialog-centered modal-dialog-scrollable"
      >
        <ModalHeader toggle={() => this.props.toggle(null)}>Rating</ModalHeader>
        <ModalBody>
          {/* <div className="rating-stars mb-4 mt-1">
            <StarRatings
              rating={this.state.rating}
              starRatedColor="#FED82F"
              changeRating={this.changeRating}
              starHoverColor="#FED82F"
              numberOfStars={5}
              name="rating"
            />
          </div>

          <FormGroup>
            <Label>Comments</Label>
            <Input
              type="textarea"
              rows="3"
              placeholder=""
              value={this.state.comment}
              onChange={this.handleOnChange}
            />
          </FormGroup> */}

          <ListGroup className="viewRatingWrap">
            {this.props?.data !== null &&
              this.props?.data?.reviews?.slice(0,5)?.map((item) => (
                <ListGroupItem>
                  <div className="d-flex justify-content-between mb-2">
                    <span style={{ color: "#2c4199" }}>
                      {item?._signingCompany?.companyName}
                    </span>
                    <StarRatings
                      rating={item.rating}
                      starRatedColor="#FED82F"
                      numberOfStars={5}
                      name="rating"
                    />
                  </div>
                  {/* {this._getText(item?.comments)} */}
                  <ReadMore text={item?.comments}/>
                </ListGroupItem>
              ))}
          </ListGroup>
          {!this.props?.data?.reviews?.length && (
            <p className="text-center">No Reviews</p>
          )}
        </ModalBody>
        <ModalFooter>
          <Button
            className="modalBtnCancel"
            onClick={() => this.props.toggle()}
          >
            Close
          </Button>
          {/* <Button
            className="modalBtnSave"
            disabled={loading ? true : false}
            onClick={() => this._callAddOrEditApi()}
          >
            {this.props.mode}
          </Button> */}
        </ModalFooter>
        {loading && (
          <div className="table-overlay">
            <div>
              <i className="fa fa-spinner fa-spin" />
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

export default ViewRatingModal;
