import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Input,
  FormGroup,
  Table,
  CustomInput,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Label,
} from "reactstrap";
import { HeaderEventEmitter } from "../../../../helper-methods/HeaderEvents";
import AddTemplateModal from "../../components/add-template-modal";
import CustomTable from "../../components/CustomTable";
import {
  deleteAgentBulkEmailMessageTemmplate,
  getAllAgentTemplates,
  updateStatusAgentBulkEmailMessageTemmplate,
} from "../../../../http/http-calls";
import {
  deleteLocalStorageData,
  errorHandler,
  formatDate,
  formatDateAndTime,
  isProPlusUser,
  refreshFunc,
  showToast,
} from "../../../../helper-methods";
import Swal from "sweetalert2";
import TemplateCommentModal from "../../components/Modals/TemplateCommentModal";
import CustomCard from "../../components/CustomCard";
import { capitalize } from "@material-ui/core";

const agentTemplateHeaderKeys = [
  { id: "id", label: "id", noSort: true },
  { id: "title", label: "Title", noSort: true },
  { id: "type", label: "Type", noSort: true },
  { id: "createdOn", label: "Created On", noSort: true },
  { id: "noOfUse", label: "No. of use", noSort: true },
  { id: "isActive", label: "Status", noSort: true },
  { id: "comment", label: "Comment", noSort: true },
  { id: "status", label: "Verification Status", noSort: true },
  { id: "action", label: "Action", noSort: true },
];

const agentTemplateCardHeaderKeys = [
  { id: "id", label: "id" },
  { id: "title", label: "Title" },
];

const Templates = () => {
  const history = useHistory();
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [addTemplateModalData, setAddTemplateModalData] = useState({
    isOpen: false,
    data: null,
  });
  const [templateCommentModal, setTemplateCommentModal] = useState({
    isOpen: false,
    data: null,
  });

  const [filters, setFilters] = useState({
    type: "",
    isActive: "",
  });

  const [templatesData, setTemplatesData] = useState({
    data: [],
    totalCount: 0,
  });
  const [tableConfig, setTableConfig] = useState({
    skip: 0,
    limit: 10,
    pageNumber: 1,
  });
  const [loading, setLoading] = useState({
    statusChangeLoading: false,
    showTableLoading: false,
  });

  const _resetFilter = () => {
    const newFilters = {
      type: "",
      isActive: "",
    };
    const newTableConfig = {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    };

    setFilters(newFilters);
    setTableConfig(newTableConfig);

    const payload = {
      ...newFilters,
      ...newTableConfig,
    };
    delete payload.pageNumber;
    _getAllTemplates(payload);
    refreshFunc("agent-templates-responsive");
    deleteLocalStorageData("agentTemplatePage");
  };

  useEffect(() => {
    try {
      if (!isProPlusUser()) {
        history.goBack();
        return;
      }

      HeaderEventEmitter.subscribe("add-template", () => {
        console.log("add template");
        _toggleAddtemplateModal(true);
      });
      HeaderEventEmitter.subscribe("reset-filter", () => {
        console.log("filter reset hit");
        _resetFilter();
        refreshFunc("agent-templates");
      });

      let newTableConfig = { ...tableConfig };
      let newFilters = { ...filters };
      if (localStorage && localStorage.agentTemplatePage) {
        const filters = JSON.parse(localStorage.agentTemplatePage);
        newTableConfig = filters?.tableConfig;
        newFilters = filters?.filters;
      }

      setTableConfig(newTableConfig);
      setFilters(newFilters);

      const payload = {
        ...filters,
        ...newTableConfig,
      };
      _getAllTemplates(payload);

      document.querySelector("#scroll").scrollTo(0, 0);
    } catch (err) {
      errorHandler(err);
    }
  }, []);

  const filterShowMobile = () => {
    setIsClassAdded(!isClassAdded);
  };

  const _toggleAddtemplateModal = (isOpen = false, data = null) => {
    setAddTemplateModalData({ isOpen, data });
  };
  const _toggleCommentModal = (isOpen = false, data = null) => {
    setTemplateCommentModal({ isOpen, data });
  };

  const _manageLoading = (loaderName, value) => {
    loading[loaderName] = value;
    setLoading({ ...loading });
  };

  const _getAllTemplates = async (payload) => {
    console.log("payload ", payload);
    try {
      _manageLoading("showTableLoading", true);

      if (!Object.keys(payload)?.length) {
        _manageLoading("showTableLoading", false);
        errorHandler({ reason: "Something went wrong!" });
        return;
      }

      delete payload?.pageNumber;

      const res = await getAllAgentTemplates(payload);
      // console.log(res);

      setTemplatesData({
        data: res?.bulkEmailMessagetemplates ? [...res?.bulkEmailMessagetemplates] : [],
        totalCount: res?.totalCount || 0,
      });
      _manageLoading("showTableLoading", false);
    } catch (error) {
      console.log(error);
      _manageLoading("showTableLoading", false);
      errorHandler(error);
    }
  };

  const _paginate = (pageNumber = 1, pageSize = 10) => {
    console.log("pageNumber, pageSize :", pageNumber, pageSize);

    const newTableConfig = { ...tableConfig };

    newTableConfig.pageNumber = pageNumber;
    newTableConfig.skip = (pageNumber - 1) * pageSize;
    newTableConfig.limit = pageSize;

    setTableConfig(newTableConfig);
    _persistFilter(newTableConfig, filters);

    const payload = {
      ...filters,
      ...newTableConfig,
    };
    _getAllTemplates(payload);
    document.querySelector("#scroll").scrollTo(0, 0);
  };

  const _onFilterChange = (key, value) => {
    const newTableConfig = {};
    newTableConfig["pageNumber"] = 1;
    newTableConfig["skip"] = 0;
    newTableConfig["limit"] = 10;
    setTableConfig(newTableConfig);

    const newFilters = { ...filters };

    if (key) {
      newFilters[key] = value ? value : "";

      setFilters({ ...newFilters });
      console.log("new filters ", newFilters);
    }

    const finalPayload = { ...newTableConfig, ...newFilters };

    _getAllTemplates(finalPayload);
    _persistFilter(
      {
        skip: 0,
        limit: 10,
        pageNumber: 1,
      },
      newFilters
    );
  };

  const _templateStatusUpdate = async (row, value) => {
    try {
      _manageLoading("statusChangeLoading", true);

      await updateStatusAgentBulkEmailMessageTemmplate(row?.id);

      const payload = {
        ...filters,
        ...tableConfig,
      };
      await _getAllTemplates(payload);
      _manageLoading("statusChangeLoading", false);
      showToast("Status updated", "success");
    } catch (err) {
      _manageLoading("statusChangeLoading", false);
      errorHandler(err);
    }
  };

  const _deleteTemplate = async (template) => {
    try {
      _manageLoading("showTableLoading", true);
      await deleteAgentBulkEmailMessageTemmplate(template?.id);
      const payload = {
        ...filters,
        ...tableConfig,
      };
      await _getAllTemplates(payload);

      _manageLoading("showTableLoading", false);
    } catch (err) {
      errorHandler(err);
      _manageLoading("showTableLoading", false);
    }
  };

  const _deleteAlert = (template) => {
    if (!template?._id) {
      errorHandler({ reason: "Template not found" });
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this Template.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // _deleteDocument(document._id);
        _deleteTemplate(template);
      }
    });
  };

  const _dataFormat = (cell, row, header) => {
    // console.log(cell, row, header);

    switch (header) {
      case "title": {
        return row?.title ? row?.title : "N/A";
      }
      case "type": {
        return row?.type ? row?.type : "N/A";
      }
      case "createdOn": {
        return row?.createdAt ? formatDate(row?.createdAt) : "N/A";
      }
      case "noOfUse": {
        return row?.noOfUse || row?.noOfUse === 0 ? (
          <>
            <span
              id={`PopoverLegacy_id_${row?.id}`}
              className="text-priamry cursorPointer"
              style={{ fontWeight: "500" }}
            >
              {row?.noOfUse}
            </span>

            {row?._bulkActionsUser?.length ? (
              <UncontrolledPopover
                trigger="legacy"
                placement="top"
                target={`PopoverLegacy_id_${row?.id}`}
                className="signerPopover"
              >
                <PopoverHeader
                  style={
                    {
                      // margin: "0 auto",
                      // width: "fit-content",
                      // background: "red",
                    }
                  }
                >
                  Receiver's
                </PopoverHeader>
                <PopoverBody
                  style={{
                    maxHeight: "200px",
                    overflowY: "auto",
                  }}
                >
                  <Table className="responsive-sm">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {row?._bulkActionsUser?.length ? (
                        row?._bulkActionsUser?.map((each) => (
                          <tr>
                            <td>{each?.nameOrCompanyname}</td>
                            <td>{formatDateAndTime(each?.createdAt)}</td>
                          </tr>
                        ))
                      ) : (
                        <tr>No data to display</tr>
                      )}
                    </tbody>
                  </Table>
                </PopoverBody>
              </UncontrolledPopover>
            ) : null}
          </>
        ) : (
          "N/A"
        );
      }
      case "isActive": {
        return (
          <CustomInput
            type="switch"
            id={`exampleCustomSwitch_${row?.id}`}
            name="customSwitch"
            disabled={loading.statusChangeLoading}
            checked={row?.isActive}
            onChange={(event) => _templateStatusUpdate(row, event.target.value)}
          />
        );
      }

      case "comment": {
        return row?.comment ? (
          <i
            className="fa fa-comment"
            style={{ cursor: "pointer" }}
            onClick={() => _toggleCommentModal(true, row)}
          />
        ) : (
          "N/A"
        );
      }

      case "status": {
        return row?.status ? (
          <Input
            type="select"
            value={row?.status}
            style={{ width: 120 }}
            disabled
          >
            <option value={"Pending"}>Pending</option>
            <option value={"InProgress"}>In Progress</option>
            <option value={"Rejected"}>Rejected</option>
            <option value={"Verified"}>Verified</option>
          </Input>
        ) : (
          "N/A"
        );
      }

      case "action": {
        return (
          <>
            <Button
              color="link"
              onClick={() => _toggleAddtemplateModal(true, row)}
            >
              <img
                src={require("../../../../assets/img/pencil.svg").default}
                alt="edit"
              />
            </Button>
            <Button color="link" onClick={() => _deleteAlert(row)}>
              <img
                src={require("../../../../assets/img/deleteIcon.svg").default}
                alt="delete"
              />
            </Button>
          </>
        );
      }

      default: {
        return cell;
      }
    }
  };

  const _persistFilter = (tableConfig, filters) => {
    if (
      (filters && (filters?.type?.length || filters?.isActive?.length)) ||
      tableConfig.pageNumber !== 1
    ) {
      let data = { filters, tableConfig };
      localStorage.agentTemplatePage = JSON.stringify(data);
    } else {
      delete localStorage.agentTemplatePage;
    }
  };

  const _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "title": {
        return row ? (
          <>
            <div className="tableUserInfo">
              <div className="userContent">
                <span className="signerName text-black">
                  {row?.title ? capitalize(row?.title) : "N/A"}
                </span>
              </div>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  const _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Type</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row?.type ? row?.type : "N/A"}
              </span>
            </div>
          </div>

          <div className="dataWrap">
            <Label>Created On</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row?.createdAt ? formatDate(row?.createdAt) : "N/A"}
              </span>
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>No. of use</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row?.noOfUse || row?.noOfUse === 0 ? (
                  <>
                    <span
                      id={`PopoverLegacy_id_${row?.id}`}
                      className="text-priamry cursorPointer"
                      style={{ fontWeight: "500" }}
                    >
                      {row?.noOfUse}
                    </span>

                    {row?._bulkActionsUser?.length ? (
                      <UncontrolledPopover
                        trigger="legacy"
                        placement="top"
                        target={`PopoverLegacy_id_${row?.id}`}
                        className="signerPopover"
                      >
                        <PopoverHeader
                          style={
                            {
                              // margin: "0 auto",
                              // width: "fit-content",
                              // background: "red",
                            }
                          }
                        >
                          Receiver's
                        </PopoverHeader>
                        <PopoverBody
                          style={{
                            maxHeight: "200px",
                            overflowY: "auto",
                          }}
                        >
                          <Table className="responsive-sm">
                            <thead>
                              <tr>
                                <th>Name</th>
                                <th>date</th>
                              </tr>
                            </thead>
                            <tbody>
                              {row?._bulkActionsUser?.length ? (
                                row?._bulkActionsUser?.map((each) => (
                                  <tr>
                                    <td>{each?.nameOrCompanyname}</td>
                                    <td>
                                      {formatDateAndTime(each?.createdAt)}
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>No data to display</tr>
                              )}
                            </tbody>
                          </Table>
                        </PopoverBody>
                      </UncontrolledPopover>
                    ) : null}
                  </>
                ) : (
                  "N/A"
                )}
              </span>
            </div>
          </div>

          <div className="dataWrap">
            <Label>Status</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                <CustomInput
                  type="switch"
                  id={`exampleCustomSwitch_${row?.id}`}
                  name="customSwitch"
                  disabled={loading?.statusChangeLoading}
                  checked={row?.isActive}
                  onChange={(event) =>
                    _templateStatusUpdate(row, event.target.value)
                  }
                />
              </span>
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Comment</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row?.comment ? (
                  <i
                    className="fa fa-comment"
                    style={{ cursor: "pointer" }}
                    onClick={() => _toggleCommentModal(true, row)}
                  />
                ) : (
                  "N/A"
                )}
              </span>
            </div>
          </div>

          <div className="dataWrap">
            <Label>Verification Status</Label>
            <div>
              <span
                style={{
                  marginRight: 5,
                  fontWeight: 600,
                }}
              >
                {row?.status ? (
                  <Input
                    type="select"
                    value={row?.status}
                    style={{ width: 120 }}
                    disabled
                  >
                    <option value={"Pending"}>Pending</option>
                    <option value={"InProgress"}>In Progress</option>
                    <option value={"Rejected"}>Rejected</option>
                    <option value={"Verified"}>Verified</option>
                  </Input>
                ) : (
                  "N/A"
                )}
              </span>
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap d-flex align-items-center justify-content-center">
            <Button
              color="link"
              onClick={() => _toggleAddtemplateModal(true, row)}
            >
              <img
                src={require("../../../../assets/img/pencil.svg").default}
                alt="edit"
              />
            </Button>
            <Button color="link" onClick={() => _deleteAlert(row)}>
              <img
                src={require("../../../../assets/img/deleteIcon.svg").default}
                alt="delete"
              />
            </Button>
          </div>
        </li>
      </ul>
    );
  };

  return (
    <div id="scroll">
      <div className="content">
        <div className="responsiveTitle">
          <h2>Templates</h2>

          <div className="rightSide">
            <Button color="link" onClick={_resetFilter}>
              <img
                id="agent-templates-responsive"
                src={require("../../../../assets/img/refresh.svg").default}
                alt="refresh"
              />
            </Button>

            <Button
              className="filterBarIcon"
              color="link"
              onClick={filterShowMobile}
            >
              <img
                src={require("../../../../assets/img/filter_icon.svg").default}
                alt="Filter"
              />
            </Button>

            <Button
              className="floatingButton"
              color="primary"
              onClick={() => _toggleAddtemplateModal(true)}
            >
              Add Templates
            </Button>
          </div>
        </div>

        {/* filter for tablet and web */}
        <div
          onClick={filterShowMobile}
          className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
        ></div>
        <div
          className={`filterContainer  responsiveFilter ${
            isClassAdded ? "show" : ""
          }`}
        >
          <div className="filterIcon">
            <img
              src={require("../../../../assets/img/filter_icon.svg").default}
              alt="filter icon"
            />
            Filter by
          </div>
          <div className="mobileTitle">
            <h2>Filter</h2>
            <Button
              color="link"
              className="closeButton"
              onClick={filterShowMobile}
            >
              <img
                src={require("../../../../assets/img/close_grey.svg").default}
                alt="close"
                height={12}
              />
            </Button>
          </div>
          <div className="filterWrapper">
            <FormGroup>
              <Label>Template Type</Label>
              <div className="custom-select-wrapper">
                <Input
                  type="select"
                  name="type"
                  value={filters?.type}
                  onChange={(e) => _onFilterChange("type", e.target.value)}
                >
                  <option value="">All</option>
                  <option value="Email">Email</option>
                  <option value="Message">Message</option>
                </Input>
              </div>
            </FormGroup>
            <FormGroup>
              <Label>Status</Label>
              <div className="custom-select-wrapper">
                <Input
                  type="select"
                  name="status"
                  value={filters?.isActive}
                  onChange={(e) => _onFilterChange("isActive", e.target.value)}
                >
                  <option value="">All</option>
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </Input>
              </div>
            </FormGroup>
          </div>
          <div className="clearButton">
            <Button size="md" color="primary" onClick={filterShowMobile}>
              Close
            </Button>
          </div>
        </div>

        <div className="hideMobile">
          {agentTemplateHeaderKeys && agentTemplateHeaderKeys?.length && (
            <CustomTable
              striped
              isPageStartFromOne={true}
              className=""
              pageNumber={tableConfig?.pageNumber}
              pageSize={tableConfig?.limit}
              tableData={templatesData?.data}
              headerKeys={agentTemplateHeaderKeys}
              dataFormat={_dataFormat}
              totalCount={templatesData?.totalCount}
              rowSelection={false}
              onPaginate={(pageNumber, pageSize) =>
                _paginate(pageNumber, pageSize)
              }
              // onSortChange={(sortName, sortOrder) =>
              //   _onSortChange(sortName, sortOrder)
              // }
              showTableLoading={loading?.showTableLoading}
            />
          )}
        </div>

        {agentTemplateCardHeaderKeys && agentTemplateCardHeaderKeys?.length && (
          <div className="hideDesktop">
            <CustomCard
              isPageStartFromOne={true}
              pageNumber={tableConfig?.pageNumber}
              tableData={templatesData?.data}
              headerKeys={agentTemplateCardHeaderKeys}
              dataFormat={_dataFormat}
              totalCount={templatesData?.totalCount}
              // rowSelection={true}
              // selectedRows={clientsSelectedRows}
              // setSelectedRows={(selectedRows) =>
              //   _setSelectedRows(selectedRows)
              // }
              onPaginate={(pageNumber, pageSize) =>
                _paginate(pageNumber, pageSize)
              }
              showTableLoading={loading?.showTableLoading}
              cardHeaderFormat={_cardHeaderFormat}
              cardDataFormat={_cardDataFormat}
            />
          </div>
        )}

        <AddTemplateModal
          isOpen={addTemplateModalData?.isOpen}
          data={addTemplateModalData?.data}
          toggle={_toggleAddtemplateModal}
          onSuccess={_paginate}
        />

        <TemplateCommentModal
          isOpen={templateCommentModal?.isOpen}
          data={templateCommentModal?.data}
          toggle={_toggleCommentModal}
        />
      </div>
    </div>
  );
};

export default Templates;
