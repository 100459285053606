import React from "react";
import { Row, Col, Button, Input, Label } from "reactstrap";
import {
  showToast,
  deepClone,
  formatDateHideYearIfSame,
  checkPermission,
  getDropdownColor,
  refreshFunc,
  calculateTotalPermissionsCount,
  errorHandler,
} from "../../../helper-methods";
import AddUserModal from "../components/add-user-modal";
import { capitalize } from "@material-ui/core";
import {
  getAllSigningCompanyUsers,
  updateSigningcompanyUserStatus,
} from "../../../http/http-calls";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomTable from "../components/CustomTable";
import CustomCard from "../components/CustomCard";
import { connect } from "react-redux";

const allPermissions = {
  masterSchedule: ["canView"],
  dashboard: [
    "canUpdateClosingStatusOrScheduling",
    "canEditOrderEntry",
    "canReviewDocumentsOrOrderDetail",
  ],
  accounting: ["invoicing", "agentPayPeriod", "income", "expense"],
  reporting: ["agentSchedule", "statusReport", "profitLoss", "summaries"],
  agents: ["canAdd", "canUpdate"],
  clients: ["canAdd", "canUpdate"],
  users: ["canView", "canAdd"],
  settings: [
    "canViewOrEditOrderAutomation",
    "canViewOrEditJobsAndFees",
    "canViewOrEditEmailCustomization",
    "canViewOrEditAccountingAutomation",
  ],
};
class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      usersTotalCount: 0,
      usersHeaderKeys: [
        { id: "id", label: "id", noSort: true },
        { id: "name", label: "Name", noSort: true },
        { id: "email", label: "Email", noSort: true },
        { id: "phone", label: "Phone", noSort: true },
        { id: "permissionSettings", label: "Permission", noSort: true },
        { id: "added", label: "Added By", noSort: true },
        { id: "lastLogin", label: "Last Login", noSort: true },
        { id: "isActive", label: "Status", noSort: true },
        { id: "action", label: "Action", noSort: true },
      ],
      usersCardHeaderKeys: [
        { id: "id", label: "id" },
        { id: "name", label: "Name" },
      ],
      loading: {
        statusChangeLoading: false,
        showTableLoading: false,
      },
      addUserModal: {
        isOpen: false,
        data: null,
      },
      tableConfig: {
        skip: 0,
        limit: 10,
        pageNumber: 1,
        sort: {
          sortBy: "createdAt",
          sortOrder: "desc",
        },
      },
    };
  }

  _onToggleAddUserModal = (bool, data) => {
    let { addUserModal } = JSON.parse(JSON.stringify(this.state));
    addUserModal.isOpen = !addUserModal.isOpen;
    addUserModal.data = data;
    this.setState({
      addUserModal,
    });
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  componentDidMount = () => {
    // this._getAllUsers();
    this._setFilterDataFromLocalStorage();
    HeaderEventEmitter.subscribe("reset-filter", () => {
      console.log("filter reset hit");
      this._resetFilter();
      refreshFunc("company-users");
    });
    HeaderEventEmitter.subscribe("add-team-member", () => {
      console.log("add team member modal");
      this._onToggleAddUserModal();
    });
  };

  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.companyTeamMembers) {
      try {
        const filters = JSON.parse(localStorage.companyTeamMembers);

        this.setState({ tableConfig: filters.tableConfig }, () => {
          this._getAllUsers();
        });
      } catch (e) {
        console.log("error", e);
        this._getAllUsers();
      }
    } else {
      this._getAllUsers();
    }
  };

  _resetFilter = () => {
    // this._getAllUsers();
    this._paginate(1, 10);
    refreshFunc("company-users-responsive");
  };

  _getAllUsers = () => {
    const { tableConfig } = this.state;
    this._manageLoading("showTableLoading", true);
    let data = {
      skip: tableConfig.skip,
      limit: tableConfig.limit,
    };
    getAllSigningCompanyUsers(data)
      .then((res) => {
        // console.log(res);
        this._manageLoading("showTableLoading", false);
        this.setState({
          users: res?.users || [],
          usersTotalCount: res?.totalCount || 0,
        });
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "workType": {
        return cell ? cell : "N/A";
      }
      case "name": {
        return (
          <>
            <div style={{ width: 100 }}>
              {cell && cell.full ? <span>{capitalize(cell.full)}</span> : "N/A"}
            </div>
          </>
        );
      }
      case "email": {
        return (
          <>
            <div style={{ width: 120 }}>
              {cell ? <a href={`mailto:${cell}`}>{cell}</a> : "N/A"}
            </div>
          </>
        );
      }
      case "lastLogin": {
        return cell
          ? formatDateHideYearIfSame(row.lastLogin)
          : "Not Logged In Yet!";
      }
      case "added": {
        return row._createdBy ? (
          <>{`${formatDateHideYearIfSame(row.createdAt)} By ${
            row._createdBy.name.full
          }`}</>
        ) : (
          "N/A"
        );
      }
      case "permissionSettings": {
        return cell
          ? `${this._calculatePermissionCount(
              row?.permissionSettings
            )}/${calculateTotalPermissionsCount(allPermissions)}`
          : "N/A";
      }

      case "isActive": {
        return row ? (
          <>
            <div className="customSelectWrap" style={{ width: 100 }}>
              <Input
                type="select"
                name="status"
                className={`status ${getDropdownColor(
                  row.isActive ? "active" : "inactive"
                )}`}
                disabled={
                  this.state.loading.statusChangeLoading ||
                  !checkPermission("users", "canAdd")
                }
                value={row.isActive ? "active" : "inactive"}
                onChange={(event) =>
                  this._userStatusUpdate(row, event.target.value)
                }
              >
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </Input>
              <div className="downArrow">
                <i className="fa fa-chevron-down"></i>
              </div>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "action": {
        return row ? (
          <Button
            title="Edit"
            color="link"
            className="actionBtn"
            onClick={() => this._onToggleAddUserModal(true, row)}
            disabled={!checkPermission("users", "canAdd")}
          >
            <img
              src={require("../../../assets/img/pencil.svg").default}
              alt="edit"
            />
          </Button>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _userStatusUpdate = (data, value) => {
    this._manageLoading("statusChangeLoading", true);

    updateSigningcompanyUserStatus(data.id)
      .then((res) => {
        showToast("Status Updated Successfully", "success");
        const { users } = deepClone(this.state);
        const user = users.find((user) => user.id === data.id);
        user["isActive"] = res?.user?.isActive;
        this.setState({ users });
        this._manageLoading("statusChangeLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("statusChangeLoading", false);
      });
  };

  _paginate = (pageNumber, pageSize) => {
    console.log("pageNumber, pageSize :", pageNumber, pageSize);
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllUsers();
    });
  };

  _onSortChange = (sortName, sortOrder) => {
    console.log("sortName, sortOrder :", sortName, sortOrder);
    this._paginate(1, 10);
  };

  _calculatePermissionCount = (permissionSettings) => {
    let count = 0;
    permissionSettings !== undefined &&
      Object.entries(permissionSettings).forEach(([key, value]) => {
        Object.keys(value).forEach(function (key) {
          if (value[key] === true) count += 1;
        });
      });
    return count;
  };

  _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return (
          <>
            {row?.name?.full ? (
              <div className="userContent">
                {row?.name?.full && (
                  <span className="signerName">
                    {capitalize(row?.name?.full)}
                  </span>
                )}
              </div>
            ) : (
              "N/A"
            )}
            {row ? (
              <Button
                title="Edit"
                color="link"
                className="actionBtn"
                onClick={() => this._onToggleAddUserModal(true, row)}
                disabled={!checkPermission("users", "canAdd")}
              >
                <img
                  src={require("../../../assets/img/pencil.svg").default}
                  alt="edit"
                />
              </Button>
            ) : (
              "N/A"
            )}
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Email </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.email ? (
                <a href={`mailto:${row?.email}`}>{row?.email}</a>
              ) : (
                "N/A"
              )}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Phone </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.phone ? row?.phone : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Permission </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.permissionSettings
                ? `${this._calculatePermissionCount(
                    row?.permissionSettings
                  )}/18`
                : "N/A"}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Added By </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?._createdBy ? (
                <>{`${formatDateHideYearIfSame(row?.createdAt)} By ${
                  row?._createdBy?.name?.full
                }`}</>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Last Login </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.lastLogin
                ? formatDateHideYearIfSame(row?.lastLogin)
                : "Not Logged In Yet!"}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap d-flex align-items-center justify-content-between">
            <Label>Status </Label>

            {row ? (
              <>
                <div className="customSelectWrap" style={{ width: 100 }}>
                  <Input
                    type="select"
                    name="status"
                    className={`status ${getDropdownColor(
                      row.isActive ? "active" : "inactive"
                    )}`}
                    disabled={
                      this.state.loading.statusChangeLoading ||
                      !checkPermission("users", "canAdd")
                    }
                    value={row.isActive ? "active" : "inactive"}
                    onChange={(event) =>
                      this._userStatusUpdate(row, event.target.value)
                    }
                  >
                    <option value="active">Active</option>
                    <option value="inactive">Inactive</option>
                  </Input>
                  <div className="downArrow">
                    <i className="fa fa-chevron-down"></i>
                  </div>
                </div>
              </>
            ) : (
              "N/A"
            )}
          </div>
        </li>
      </ul>
    );
  };

  _persistFilter = () => {
    const { tableConfig } = this.state;

    if (tableConfig.pageNumber !== 1) {
      let data = { tableConfig };
      localStorage.companyTeamMembers = JSON.stringify(data);
    } else {
      delete localStorage.companyTeamMembers;
    }
  };

  render() {
    const {
      users,
      usersTotalCount,
      usersHeaderKeys,
      loading,
      tableConfig,
      usersCardHeaderKeys,
    } = deepClone(this.state);

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>Team Members</h2>

            <div className="rightSide">
              <Button color="link" onClick={this._resetFilter}>
                <img
                  id="company-users-responsive"
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                />
              </Button>

              {checkPermission("users", "canAdd") && (
                <Button
                  color="primary"
                  className="floatingButton"
                  onClick={() => this._onToggleAddUserModal()}
                >
                  Invite Team Member
                </Button>
              )}
            </div>
          </div>
          <Row>
            <Col md="12">
              <div className="hideDesktop">
                <CustomCard
                  isPageStartFromOne={true}
                  pageNumber={tableConfig.pageNumber}
                  tableData={users}
                  headerKeys={usersCardHeaderKeys}
                  // bodyKeys={agentClosingCardBodyKeys}
                  dataFormat={this._dataFormat}
                  totalCount={usersTotalCount}
                  onPaginate={(pageNumber, pageSize) =>
                    this._paginate(pageNumber, pageSize)
                  }
                  rowSelection={false}
                  // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
                  showTableLoading={loading.showTableLoading}
                  cardHeaderFormat={this._cardHeaderFormat}
                  cardDataFormat={this._cardDataFormat}
                />
              </div>

              <div className="hideMobile">
                {usersHeaderKeys && usersHeaderKeys.length && (
                  <CustomTable
                    striped
                    isPageStartFromOne={true}
                    pageNumber={tableConfig.pageNumber}
                    tableData={users}
                    headerKeys={usersHeaderKeys}
                    dataFormat={this._dataFormat}
                    totalCount={usersTotalCount}
                    rowSelection={false}
                    onPaginate={(pageNumber, pageSize) =>
                      this._paginate(pageNumber, pageSize)
                    }
                    onSortChange={(sortName, sortOrder) =>
                      this._onSortChange(sortName, sortOrder)
                    }
                    showTableLoading={loading.showTableLoading}
                  />
                )}
              </div>
            </Col>
          </Row>
        </div>
        <AddUserModal
          isOpen={this.state.addUserModal.isOpen}
          data={this.state.addUserModal.data}
          resetTable={this._getAllUsers}
          toggle={() => this._onToggleAddUserModal()}
          isTitleCompany={this.props.userData?.user?.isTitleCompany}
          permissions={allPermissions}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

export default connect(mapStateToProps, null)(Users);
