import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CustomInput,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  capitalize,
  formatDate,
  showToast,
  formatDateAndTimeForIncomeList,
  errorHandler,
} from "../../../helper-methods";
import {
  agentCreateIncome,
  agentGetAllUnregisteredClient,
} from "../../../http/http-calls";

class AddIncomeModalAgent extends Component {
  state = {
    unregisterClientList: [],
    formFields: {
      fileNumber: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      date: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      signer: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      amount: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      status: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      company: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      clientId: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      isSendInvoice: {
        value: false,
        error: null,
        isDirty: false,
        isValidate: false,
      },
      notes: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
    },
    loading: false,
  };

  _resetStateModal = () => {
    this.setState({
      formFields: {
        fileNumber: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        date: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        signer: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        amount: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        company: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        clientId: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        isSendInvoice: {
          value: false,
          error: null,
          isDirty: false,
          isValidate: false,
        },
        status: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        notes: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
      },
      loading: false,
    });
  };

  _closeModal = () => {
    this._resetStateModal();
    this.props.toggle();
  };

  _agentGetAllUnregisteredClient = () => {
    agentGetAllUnregisteredClient()
      .then((res) => {
        this.setState({ unregisterClientList: res?.clients || [] });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  componentDidUpdate = (prevProps) => {
    const { isOpen } = this.props;
    if (isOpen && isOpen !== prevProps.isOpen) {
      this._agentGetAllUnregisteredClient();
    }
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty && formFields[key].isValidate) {
          switch (key) {
            case "date": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "amount": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                if (
                  isNaN(formFields[key].value) ||
                  Number(formFields[key].value) < 0
                ) {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Amount must be a positive number";
                  isFormValid = false;
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            // case "company": {
            case "clientId": {
              if (formFields[key].value?.trim().length) {
                // if (formFields[key].value === "unregisteredClient") {
                // if (formFields.clientId.value?.trim().length) {
                //     formFields[key].isDirty = false;
                //     formFields[key].error = null;
                //   } else {
                // formFields[key].isDirty = true;
                // formFields[key].error = "*Required";
                // isFormValid = false;
                // }
                // } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
                // }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });
      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields } = this.state;
    if (fieldName === "amount") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }
    if (fieldName === "company") {
      formFields[fieldName].value = value;
      formFields["clientId"].value = "";
    }

    if (fieldName === "date") {
      formFields[fieldName].value = value ? new Date(value).toISOString() : "";
    } else {
      formFields[fieldName].value = value;
    }

    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // Validation
      this._validateForm();
    });
  };

  _onChangeInvoiceEmailToggle = () => {
    const { formFields } = this.state;
    let temp = formFields?.isSendInvoice?.value;
    // console.log("temp: ", temp);

    formFields.isSendInvoice.value = !temp || false;

    this.setState({ formFields });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _agentCreateIncome = (payload) => {
    agentCreateIncome(payload)
      .then((res) => {
        this._closeModal();
        showToast("Income Created", "success");
        this.props.resetDetails();
      })
      .catch((error) => {
        this.setState({ loading: false });
        errorHandler(error);
      });
  };

  _onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();

      await this._markAllFieldDirty();

      const isFormValid = await this._validateForm();

      if (isFormValid) {
        this.setState({ loading: true });

        const { formFields, unregisterClientList } = this.state;

        const payload = {
          appointmentDate: formatDateAndTimeForIncomeList(
            formFields.date.value
          ),
          amount: formFields.amount.value?.trim(),
          notes: formFields.notes.value?.trim() || "",
          fileNumber: formFields.fileNumber.value?.trim() || "",
          status: formFields.status.value?.trim() || "",
        };

        let borrower = [];
        if (formFields.signer.value?.trim()) {
          let temp = formFields?.signer?.value?.trim()?.split(" ");
          let firstName = temp[0];
          temp.shift();
          let lastName = temp?.join(" ");
          let tempBorrower = {
            name: {
              first: firstName,
              last: lastName || "",
            },
          };
          borrower.push(tempBorrower);
        }
        payload["borrower"] = borrower;

        if (formFields?.isSendInvoice) {
          payload["isSendInvoice"] = formFields?.isSendInvoice.value || false;
        }

        if (
          formFields.clientId.value &&
          formFields.clientId.value.trim().length
        ) {
          unregisterClientList.find((each) => {
            if (each._id === formFields.clientId.value) {
              // console.log("each => ", each);
              if (each.hasOwnProperty("_client") && each._client !== "") {
                payload["clientId"] = each._client;
                payload["clientEmail"] = each?.email;
              } else if (
                each.hasOwnProperty("_signingCompany") &&
                each._signingCompany !== ""
              ) {
                payload["companyId"] = each._signingCompany;
              } else {
                payload["assistantId"] = each._assistant;
                payload["clientEmail"] = each?.email;
              }
            }
          });
        }
        console.log("payload: ", payload);
        this._agentCreateIncome(payload);
      }
    } catch (error) {
      this.setState({ loading: false });
      errorHandler(error);
    }
  };

  render() {
    const { formFields, loading } = this.state;

    const { isOpen, signingCompany, unregisterClientList } = this.props;
    console.log("rer", this.state);
    return (
      <Modal isOpen={isOpen} toggle={this._closeModal}>
        <ModalHeader toggle={this._closeModal}>Add Income</ModalHeader>
        <ModalBody>
          <Row form>
            <Col sm="12">
              <FormGroup className="floatingLabel">
                <Input
                  type="text"
                  value={formFields?.fileNumber?.value}
                  onChange={(e) =>
                    this._onChangeFormField("fileNumber", e.target.value)
                  }
                  placeholder=" "
                />
                <Label>File Number</Label>
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup
                className={`floatingLabel ${
                  formFields.date.value || this.state.isOpenReactDatetime
                    ? "valueAdded"
                    : ""
                }`}
              >
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: " ",
                    value: formatDate(formFields.date.value),
                  }}
                  onChange={(e) => this._onChangeFormField("date", e._d)}
                  closeOnSelect={true}
                  isValidDate={(current) => current.isBefore(new Date())}
                  timeFormat={false}
                  onOpen={() => this.setState({ isOpenReactDatetime: true })}
                  onClose={() => this.setState({ isOpenReactDatetime: false })}
                />
                <Label>Date</Label>
                {formFields.date.error && (
                  <div className="validation-error">
                    {formFields.date.error}
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup className="floatingLabel">
                <Input
                  type="text"
                  value={formFields?.signer?.value}
                  onChange={(e) =>
                    this._onChangeFormField("signer", e.target.value)
                  }
                  placeholder=" "
                />
                <Label>Signer</Label>
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup className="floatingLabel withInputGroup">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>$</InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    placeholder=" "
                    value={formFields.amount.value}
                    name="amount"
                    onChange={(event) =>
                      this._onChangeFormField("amount", event.target.value)
                    }
                  />
                  <Label>Amount</Label>
                </InputGroup>
                {formFields.amount.error && (
                  <div className="validation-error">
                    {formFields.amount.error}
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col sm="12">
              {/* <FormGroup className="floatingLabel">
                <Label>Client</Label> */}

              {/* {formFields.company.value === "unregisteredClient" ? ( */}

              {/* ) : null} */}
              {/* {formFields.company.error && (
                  <div className="validation-error">
                    {formFields.company.error}
                  </div>
                )} */}
              {/* </FormGroup> */}
              {/* {formFields.company.value === "unregisteredClient" ? (
                <FormGroup className="floatingLabel custom-select-wrapper">
                  <>
                    <Input
                      type="select"
                      name="clientId"
                      className="mt-3"
                      value={formFields.clientId.value}
                      onChange={(event) =>
                        this._onChangeFormField("clientId", event.target.value)
                      }
                    >
                      <option value="">Select</option>
                      {unregisterClientList.map((Obj) => (
                        <option key={Obj.id} value={Obj.id}>
                          {(Obj && Obj.name && capitalize(Obj.name.full)) ||
                            "N/A"}
                        </option>
                      ))}
                    </Input>
                    <Label>Clients</Label>
                  </>
                  {formFields.company.error && (
                    <div className="validation-error">
                      {formFields.company.error}
                    </div>
                  )}
                </FormGroup>
              ) : null} */}
              {/* <Input
                type="select"
                name="clientId"
                className="mt-3"
                value={formFields.clientId.value}
                onChange={(event) =>
                  this._onChangeFormField("clientId", event.target.value)
                }
              >
                <option value="">Select</option>
                {unregisterClientList.map((Obj) => (
                  <option key={Obj.id} value={Obj.id}>
                    {Obj?._signingCompany
                      ? Obj.companyName
                      : Obj?._client
                      ? Obj.name?.full
                      : "N/A"}
                  </option>
                ))}
              </Input>

              {formFields.clientId.error && (
                <div className="validation-error">
                  {formFields.clientId.error}
                </div>
              )} */}
            </Col>
            <Col sm="12">
              <FormGroup className="floatingLabel">
                <Input
                  type="select"
                  name="clientId"
                  className="mt-3"
                  value={formFields.clientId.value}
                  onChange={(event) =>
                    this._onChangeFormField("clientId", event.target.value)
                  }
                >
                  <option value="">Select</option>
                  {unregisterClientList.map((Obj) => (
                    <option key={Obj.id} value={Obj.id}>
                      {Obj?._signingCompany
                        ? Obj.companyName
                        : Obj?._client
                        ? Obj.name?.full
                        : Obj?._assistant
                        ? // : Obj?._clientAssistant
                          // ? Obj.name?.full
                          // : Obj?._companyAssistant
                          Obj.name?.full
                        : "N/A"}
                    </option>
                  ))}
                </Input>
                <Label>Client</Label>
                {formFields.clientId.error && (
                  <div className="validation-error">
                    {formFields.clientId.error}
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="floatingLabel">
                <Input
                  type="select"
                  value={formFields?.status?.value}
                  onChange={(e) =>
                    this._onChangeFormField("status", e.target.value)
                  }
                  placeholder=" "
                >
                  <option value="">Select</option>
                  <option value="Closed">Closed</option>
                  <option value="DNC">DNC (Do Not Close)</option>
                </Input>
                {formFields?.status?.error && (
                  <div className="validation-error">
                    {formFields?.status?.error}
                  </div>
                )}
                <Label>Status</Label>
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup className="floatingLabel">
                <Input
                  type="textarea"
                  rows="3"
                  value={formFields.notes.value}
                  name="notes"
                  onChange={(event) =>
                    this._onChangeFormField("notes", event.target.value)
                  }
                  placeholder=" "
                />
                <Label>Notes</Label>
              </FormGroup>
            </Col>
            <Col md={12}>
              <CustomInput
                type="switch"
                id="exampleCustomCheckbox"
                label="Send Invoice"
                value={formFields?.isSendInvoice}
                onChange={() => this._onChangeInvoiceEmailToggle()}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline size="lg" onClick={this._closeModal}>
            Cancel
          </Button>

          <Button
            color="primary"
            size="lg"
            disabled={loading}
            onClick={this._onSubmit}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null} Add
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddIncomeModalAgent;
