import React from "react";
import {
  Button,
  FormGroup,
  Form,
  Input,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  CustomInput,
  Spinner,
} from "reactstrap";
import { Link } from "react-router-dom";
import { RegexConfig } from "../../../config/RegexConfig";
import { addUserData } from "../../../redux/actions/user-data";
import {
  convertHexToRgbColor,
  deepClone,
  errorHandler,
  formatAddressInSingleText,
  setThemeColor,
  updateCompanyName,
  updateFavIcon,
} from "../../../helper-methods";
import {
  signupAgentViaEmail,
  signupSigningCompanyViaEmail,
  signupSigningCompanyViaInvitation,
  signupAgentViaInvitation,
  signupClientViaEmail,
  signupClientViaInvitation,
  customWebsiteDetail,
} from "../../../http/http-calls";
import { countryCodes } from "../../../config/country-codes";
import { showToast, getPhoneNumberFromBrackets } from "../../../helper-methods";
import jwt_decode from "jwt-decode";
import { connect } from "react-redux";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { app_name } from "../../../config";
import Swal from "sweetalert2";
import PublicSideCarousel from "../components/PublicCarousel/PublicSideCarousel";
import usaStates from "../../../config/usa_states_titlecase";
import {
  googlePlaceDetails,
  googlePlaceSearch,
} from "../../../helper-methods/googleService";
import TermsAndConditionModal from "../components/termsAndConditionModal";
import Gleap from "gleap";

class SignUp extends React.Component {
  state = {
    formFields: {
      // Attorney address field
      searchAddress: {
        value: "",
        placeId: null,
        error: null,
        isDirty: false,
        isRequired: true,
      },
      street: {
        value: "",
        error: null,
        isDirty: false,
        isRequired: false,
      },
      line1: {
        value: "",
        error: null,
        isDirty: false,
        isRequired: false,
      },
      // line2: {
      //   value: "",
      //   error: null,
      //   isDirty: false,
      //   isRequired: false,
      // },
      city: {
        value: "",
        error: null,
        isDirty: false,
        isRequired: false,
      },
      state: {
        value: "",
        error: null,
        isDirty: false,
        isRequired: false,
      },
      country: {
        value: "",
        error: null,
        isDirty: false,
        isRequired: true,
      },
      lat: {
        value: "",
        error: null,
        isDirty: false,
        isRequired: false,
      },
      lng: {
        value: "",
        error: null,
        isDirty: false,
        isRequired: false,
      },
      // zip: {
      //   value: "",
      //   error: null,
      //   isDirty: false,
      //   isRequired: false,
      // },
      userType: {
        value: "", // Attorney, SigningCompany, Client
        error: null,
        isDirty: false,
        isRequired: true,
      },
      name: {
        value: "",
        error: null,
        isDirty: false,
        isRequired: true,
      },
      email: {
        value: "",
        error: null,
        isDirty: false,
        isRequired: true,
      },
      phoneCountry: {
        value: "US",
        error: null,
        isDirty: false,
        isRequired: true,
      },
      phone: {
        value: "",
        error: null,
        isDirty: false,
        isRequired: true,
      },
      password: {
        value: "",
        error: null,
        isDirty: false,
        isRequired: true,
      },
      // for company
      companyName: {
        value: "",
        error: null,
        isDirty: false,
        isRequired: true,
      },
      // for company
      websiteURL: {
        value: "",
        error: null,
        isDirty: false,
        isRequired: false,
      },
      // for company
      jobTitle: {
        value: "",
        error: null,
        isDirty: false,
        isRequired: false,
      },
      zip: {
        value: "",
        error: null,
        isDirty: false,
        isRequired: false,
      },
      // for agent
      licenseNumber: {
        value: "",
        error: null,
        isDirty: false,
        isRequired: false,
      },
      // for all
      isTermsAndConditionsAccepted: {
        value: "",
        error: null,
        isDirty: false,
        isRequired: false,
      },
    },
    isShowPassword: false,
    showMenu: false,
    termsAndConditionModal: {
      isOpen: false,
      data: null,
    },
    companyDetail: {},
    decodedData: null,
  };

  _resetFormField = () => {
    this.setState({
      formFields: {
        userType: {
          value: "",
          error: null,
          isDirty: false,
          isRequired: true,
        },
        name: {
          value: "",
          error: null,
          isDirty: false,
          isRequired: true,
        },
        email: {
          value: "",
          error: null,
          isDirty: false,
          isRequired: true,
        },
        phoneCountry: {
          value: "US",
          error: null,
          isDirty: false,
          isRequired: true,
        },
        phone: {
          value: "",
          error: null,
          isDirty: false,
          isRequired: true,
        },
        password: {
          value: "",
          error: null,
          isDirty: false,
          isRequired: true,
        },
        // for company
        companyName: {
          value: "",
          error: null,
          isDirty: false,
          isRequired: true,
        },
        // for company
        websiteURL: {
          value: "",
          error: null,
          isDirty: false,
          isRequired: false,
        },
        // for company
        jobTitle: {
          value: "",
          error: null,
          isDirty: false,
          isRequired: false,
        },
        zip: {
          value: "",
          error: null,
          isDirty: false,
          isRequired: false,
        },
        // for agent
        licenseNumber: {
          value: false,
          error: null,
          isDirty: false,
          isRequired: false,
        },
      },
      isShowPassword: false,
      showMenu: false,
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((e) => {
        if (formFields[e].isRequired && formFields[e].isDirty) {
          switch (e) {
            case "name": {
              if (formFields[e].value && formFields[e].value.trim().length) {
                if (formFields[e]?.value?.trim()?.length <= 40) {
                  if (
                    /[a-zA-Z]/g.test(String(formFields[e].value).toLowerCase())
                  ) {
                    formFields[e].isDirty = false;
                    formFields[e].error = null;
                  } else {
                    formFields[e].isDirty = true;
                    formFields[e].error =
                      "*Name containing only number and special character are not allowed";
                    isFormValid = false;
                  }
                } else {
                  formFields[e].isDirty = true;
                  formFields[e].error = "*Only upto 40 characters are allowed";
                  isFormValid = false;
                }
              } else {
                formFields[e].isDirty = true;
                formFields[e].error = "*Required 2321";
                isFormValid = false;
              }
              break;
            }
            case "email": {
              if (formFields[e].value && formFields[e].value.trim().length) {
                if (
                  RegexConfig.email.test(
                    String(formFields[e].value).toLowerCase()
                  )
                ) {
                  formFields[e].isDirty = false;
                  formFields[e].error = null;
                } else {
                  formFields[e].isDirty = true;
                  formFields[e].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                formFields[e].isDirty = true;
                formFields[e].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "phone": {
              if (formFields[e].value && formFields[e].value.trim().length) {
                if (
                  RegexConfig.phone.test(
                    String(formFields[e].value).toLowerCase()
                  )
                ) {
                  formFields[e].isDirty = false;
                  formFields[e].error = null;
                } else {
                  formFields[e].isDirty = true;
                  formFields[e].error = "*Invalid Phone Number";
                  isFormValid = false;
                }
              } else {
                formFields[e].isDirty = true;
                formFields[e].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "password": {
              if (formFields[e].value && formFields[e].value.trim().length) {
                if (
                  RegexConfig.password.test(
                    String(formFields[e].value).toLowerCase()
                  )
                ) {
                  formFields[e].isDirty = false;
                  formFields[e].error = null;
                } else {
                  formFields[e].isDirty = true;
                  formFields[e].error =
                    "*Minimum eight characters, at least one letter, one number and one special character";
                  isFormValid = false;
                }
              } else {
                formFields[e].isDirty = true;
                formFields[e].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "companyName": {
              if (
                formFields.userType.value === "ClientLaw" ||
                formFields.userType.value === "ClientTitle&Escrow" ||
                formFields.userType.value === "ClientLender" ||
                formFields.userType.value === "SigningCompanyLawfirms" ||
                formFields.userType.value === "SigningCompanyTitle&Escrow" ||
                formFields.userType.value === "SigningCompanySigningService"
              ) {
                if (formFields[e].value && formFields[e].value.trim().length) {
                  if (
                    /[a-zA-Z]/g.test(String(formFields[e].value).toLowerCase())
                  ) {
                    formFields[e].isDirty = false;
                    formFields[e].error = null;
                  } else {
                    formFields[e].isDirty = true;
                    formFields[e].error = "At least one Alphabet is Required";
                    isFormValid = false;
                  }
                } else {
                  formFields[e].isDirty = true;
                  formFields[e].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[e].isDirty = false;
                formFields[e].error = null;
              }
              break;
            }

            case "isTermsAndConditionsAccepted": {
              if (formFields[e].value === true) {
                formFields[e].isDirty = false;
                formFields[e].error = null;
              } else {
                formFields[e].isDirty = true;
                formFields[e].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "searchAddress": {
              if (
                formFields.userType.value !== "SigningCompanyLawfirms" &&
                formFields.userType.value !== "SigningCompanyTitle&Escrow" &&
                formFields.userType.value !== "SigningCompanySigningService" &&
                formFields.userType.value !== "ClientLaw" &&
                formFields.userType.value !== "ClientTitle&Escrow" &&
                formFields.userType.value !== "ClientLender"
              ) {
                console.log("in", formFields.userType.value);
                if (formFields[e].value && formFields[e].value.trim().length) {
                  formFields[e].isDirty = false;
                  formFields[e].error = null;
                } else {
                  formFields[e].isDirty = true;
                  formFields[e].error = "*Required";
                  isFormValid = false;
                }
              }

              break;
            }

            default: {
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeFormField = (type, value) => {
    const { formFields } = this.state;
    formFields[type].value = value;
    formFields[type].isDirty = true;
    this.setState({ formFields }, () => {
      this._validateForm();
    });

    if (type === "searchAddress") {
      googlePlaceSearch(value)
        .then((res) => {
          // console.log(res);
          this.setState({ searchResult: res, show: type });
        })
        .catch((err) => {
          // console.log(err);
          showToast("Errors");
        });
    }

    if (type === "userType") {
      this.setState({
        showMenu: !this.state.showMenu,
      });
      const urlParams = new URLSearchParams(window.location.search);
      urlParams.set(type, value);
      const newUrl = window.location.pathname + "?" + urlParams.toString();
      window.history.pushState(null, "", newUrl);
    }
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => {
        resolve();
      });
    });
  };

  _userSignup = async (e) => {
    if (e) e.preventDefault();

    await this._markAllFieldDirty();
    const isFormValid = await this._validateForm();

    if (isFormValid) {
      this.props.showLoader("Loading...");

      const { formFields } = deepClone(this.state);

      const splitName = formFields.name.value.trim().split(" ");
      const countryCode = countryCodes.find(
        (obj) => obj.code === formFields.phoneCountry.value
      );

      if (
        formFields.userType.value === "Attorney" ||
        formFields.userType.value === "Notary"
      ) {
        let token = this.props.history.location.pathname.split("/")[2];
        if (token) {
          let data = {
            email: formFields.email.value,
            phoneCountry: formFields.phoneCountry.value,
            name: {
              first: splitName[0],
              last: splitName.slice(1).join(" ")
                ? splitName.slice(1).join(" ")
                : "",
            },
            password: formFields.password.value,
            workType: formFields.userType.value,
            licenseNumber: formFields.licenseNumber.value,
            invitationToken: token,
            isTermsAndConditionsAccepted:
              formFields.isTermsAndConditionsAccepted.value,
            address: {
              street: formFields.line1.value.trim(),
              city: formFields.city.value.trim(),
              state: formFields.state.value.trim(),
              zip: formFields.zip.value.trim(),
              country: formFields.country.value.trim(),
              lat: formFields.lat.value,
              lng: formFields.lng.value,
            },
          };

          if (formFields.zip.value && formFields.zip.value.trim().length) {
            data["zip"] = formFields.zip.value;
          }
          if (countryCode && formFields.phone.value) {
            data[
              "phone"
            ] = `(${countryCode.dial_code})${formFields.phone.value}`;
          }

          signupAgentViaInvitation(data)
            .then((res) => {
              showToast("Successfully signed up!", "success");
              let decoded = jwt_decode(res?.token);
              const userData = {
                token: res?.token,
                type: decoded.type.toLowerCase(),
                handle: res?.handle,
                user: { ...decoded },
              };
              if (
                window.location.hostname !== "localhost" &&
                decoded.type?.toLowerCase() === "agent" &&
                decoded?.siteOwnerDetail?._id !== undefined
              ) {
                // <Redirect to={`https://app.closewise.com?token=${res.token}`} />
                window.location.href = `https://app.closewise.com/login?token=${res?.token}`;
                // window.location.href = `https://closewise-nna.surge.sh/login?token=${res.token}`;
                this.props.hideLoader();
              } else {
                this._resetFormField();
                this.props.addUserData(userData);
                this.props.hideLoader();
                this.props.history.push(`/agent/subscription`);
              }
            })
            .catch((error) => {
              errorHandler(error);
              this.props.hideLoader();
            });
        } else {
          let data = {
            email: formFields.email.value,
            phoneCountry: formFields.phoneCountry.value,
            name: {
              first: splitName[0],
              last: splitName.slice(1).join(" ")
                ? splitName.slice(1).join(" ")
                : "",
            },
            password: formFields.password.value,
            workType: formFields.userType.value,
            licenseNumber: formFields.licenseNumber.value,
            isTermsAndConditionsAccepted:
              formFields.isTermsAndConditionsAccepted.value,
            address: {
              street: formFields.line1.value.trim(),
              city: formFields.city.value.trim(),
              state: formFields.state.value.trim(),
              zip: formFields.zip.value.trim(),
              country: formFields.country.value.trim(),
              lat: formFields.lat.value,
              lng: formFields.lng.value,
            },
          };

          if (formFields.zip.value && formFields.zip.value.trim().length) {
            data["zip"] = formFields.zip.value;
          }
          if (countryCode && formFields.phone.value) {
            data[
              "phone"
            ] = `(${countryCode.dial_code})${formFields.phone.value}`;
          }
          if (this.props.history?.location?.search?.split("=")) {
            data["code"] = this.props.history?.location?.search?.split("=")[1];
          }
          signupAgentViaEmail(data)
            .then((res) => {
              const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                  popup: "alert-swal-modal",
                },
              });
              // console.log(res);
              // showToast("Successfully signed up!", "success");
              swalWithBootstrapButtons.fire({
                imageUrl: require("../../../assets/img/rightThump.svg").default,
                imageAlt: "A tall image",
                title: "Request Sent Successfully!",
                text: "Please check your email to verify your account. Once verified you can begin to login. Thank you!",
                // icon: 'success'
                confirmButtonText: "OKAY",
              });
              this._resetFormField();
              this.props.hideLoader();
              this.props.history.push(`/login`);
              // showToast(
              //   "Please check your email to verify your account. Once verified you can begin to login. Thank you!"
              // );

              // let decoded = jwt_decode(res.token);
              // const userData = {
              //   token: res.token,
              //   type: decoded.type.toLowerCase(),
              //   handle: res.handle,
              //   user: { ...decoded },
              // };
              // this.props.addUserData(userData);
            })
            .catch((error) => {
              errorHandler(error);
              this.props.hideLoader();
            });
        }
      } else if (
        formFields.userType.value === "SigningCompanyLawfirms" ||
        formFields.userType.value === "SigningCompanyTitle&Escrow" ||
        formFields.userType.value === "SigningCompanySigningService"
      ) {
        let token = this.props.history.location.pathname.split("/")[2];
        if (token) {
          let data = {
            email: formFields.email.value,
            phoneCountry: formFields.phoneCountry.value,
            isTitleCompany:
              formFields.userType.value === "SigningCompanyTitle&Escrow"
                ? true
                : false,
            name: {
              first: splitName[0],
              last: splitName.slice(1).join(" ")
                ? splitName.slice(1).join(" ")
                : "",
            },
            password: formFields.password.value,
            companyName: formFields.companyName.value,
            websiteURL: formFields.websiteURL.value,
            jobTitle: formFields.jobTitle.value,
            invitationToken: token,
            isTermsAndConditionsAccepted:
              formFields.isTermsAndConditionsAccepted.value,
          };

          if (formFields.zip.value && formFields.zip.value.trim().length) {
            data["zip"] = formFields.zip.value;
          }
          if (countryCode && formFields.phone.value) {
            data[
              "phone"
            ] = `(${countryCode.dial_code})${formFields.phone.value}`;
          }

          signupSigningCompanyViaInvitation(data)
            .then((res) => {
              const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                  popup: "alert-swal-modal",
                },
              });

              swalWithBootstrapButtons.fire({
                imageUrl: require("../../../assets/img/rightThump.svg").default,
                imageAlt: "A tall image",
                title: "Request Sent Successfully!",
                // text: `A ${app_name} account executive will review your submission and contact you shortly to help you get started and answer any additional question you may have.`,
                text: `Please check your email to verify your account submission. A ${app_name} account executive will contact you shortly to schedule your account activation and answer any questions you may have. Thank you!`,
                // icon: 'success'
                confirmButtonText: "OKAY",
              });
              this._resetFormField();
              this.props.hideLoader();
              this.props.history.push(`/login`);
            })
            .catch((error) => {
              errorHandler(error);
              this.props.hideLoader();
            });
        } else {
          let data = {
            email: formFields.email.value,
            phoneCountry: formFields.phoneCountry.value,
            name: {
              first: splitName[0],
              last: splitName.slice(1).join(" ")
                ? splitName.slice(1).join(" ")
                : "",
            },
            password: formFields.password.value,
            companyName: formFields.companyName.value,
            websiteURL: formFields.websiteURL.value,
            jobTitle: formFields.jobTitle.value,
            isTermsAndConditionsAccepted:
              formFields.isTermsAndConditionsAccepted.value,
            isTitleCompany:
              formFields.userType.value === "SigningCompanyTitle&Escrow"
                ? true
                : false,
          };

          if (formFields.zip.value && formFields.zip.value.trim().length) {
            data["zip"] = formFields.zip.value;
          }
          if (countryCode && formFields.phone.value) {
            data[
              "phone"
            ] = `(${countryCode.dial_code})${formFields.phone.value}`;
          }

          signupSigningCompanyViaEmail(data)
            .then((res) => {
              this._resetFormField();
              const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                  popup: "alert-swal-modal",
                },
              });

              swalWithBootstrapButtons.fire({
                imageUrl: require("../../../assets/img/rightThump.svg").default,
                imageAlt: "A tall image",
                title: "Request Sent Successfully!",
                // text: `A ${app_name} account executive will review your submission and contact you shortly to help you get started and answer any additional question you may have.`,
                text: `Please check your email to verify your account submission. A ${app_name} account executive will contact you shortly to schedule your account activation and answer any questions you may have. Thank you!`,
                // icon: 'success'
                confirmButtonText: "OKAY",
              });
              this.props.hideLoader();
              this.props.history.push(`/login`);
            })
            .catch((error) => {
              errorHandler(error);
              this.props.hideLoader();
            });
        }
      } else if (
        formFields.userType.value === "ClientLaw" ||
        formFields.userType.value === "ClientTitle&Escrow" ||
        formFields.userType.value === "ClientLender"
      ) {
        let token = this.props.history.location.pathname.split("/")[2];
        let payload = {
          email: formFields.email.value,
          phoneCountry: formFields.phoneCountry.value,
          businessCountry: formFields.phoneCountry.value,
          name: {
            first: splitName[0],
            last: splitName.slice(1).join(" ")
              ? splitName.slice(1).join(" ")
              : "",
          },
          password: formFields.password.value,
          workType: formFields.userType.value,
          companyName: formFields.companyName.value,
          isTermsAndConditionsAccepted:
            formFields.isTermsAndConditionsAccepted.value,
        };
        if (countryCode && formFields.phone.value) {
          payload[
            "phone"
          ] = `(${countryCode.dial_code})${formFields.phone.value}`;
        }
        if (formFields.zip.value && formFields.zip.value.trim().length) {
          payload["zip"] = formFields.zip.value;
        }
        if (token) {
          payload["invitationToken"] = token;
          signupClientViaInvitation(payload)
            .then((res) => {
              this._resetFormField();
              this.props.hideLoader();
              this.props.history.replace(`/login`);
              const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                  popup: "alert-swal-modal",
                },
              });

              swalWithBootstrapButtons.fire({
                imageUrl: require("../../../assets/img/rightThump.svg").default,
                imageAlt: "A tall image",
                title: "Request Sent Successfully!",
                // text: `A ${app_name} account executive will review your submission and contact you shortly to help you get started and answer any additional question you may have.`,
                text: `Please check your email to verify your account submission. A ${app_name} account executive will contact you shortly to schedule your account activation and answer any questions you may have. Thank you!`,
                // icon: 'success'
                confirmButtonText: "OKAY",
              });
            })
            .catch((error) => {
              errorHandler(error);
              this.props.hideLoader();
            });
        } else {
          signupClientViaEmail(payload)
            .then((res) => {
              const swalWithBootstrapButtons = Swal.mixin({
                customClass: {
                  popup: "alert-swal-modal",
                },
              });

              swalWithBootstrapButtons.fire({
                imageUrl: require("../../../assets/img/rightThump.svg").default,
                imageAlt: "A tall image",
                title: "Request Sent Successfully!",
                // text: `A ${app_name} account executive will review your submission and contact you shortly to help you get started and answer any additional question you may have.`,
                text: `Please check your email to verify your account submission. A ${app_name} account executive will contact you shortly to schedule your account activation and answer any questions you may have. Thank you!`,
                // icon: 'success'
                confirmButtonText: "OKAY",
              });
              this.props.hideLoader();
              this.props.history.replace(`/login`);
            })
            .catch((error) => {
              errorHandler(error);
              this.props.hideLoader();
            });
        }
      } else {
        showToast("User type is invalid", "error");
      }
    }
  };

  _showPasswordToggle = () => {
    this.setState({ isShowPassword: !this.state.isShowPassword });
  };

  componentDidMount = () => {
    // Hide Gleap in this page
    Gleap.showFeedbackButton(false);

    let token = this.props.history.location.pathname.split("/")[2];
    const { formFields } = this.state;

    if (token !== undefined) {
      var decoded = jwt_decode(token);

      Object.entries(decoded).forEach(([key, val]) => {
        Object.entries(formFields).forEach(([item, each]) => {
          if (key === "invitedUserType") {
            if (val === "Agent") {
              formFields["userType"].value = decoded.workType;
              formFields["searchAddress"].value = formatAddressInSingleText(
                decoded?.address
              );
              formFields["line1"].value = decoded?.address?.street;
              formFields["city"].value = decoded?.address?.city;
              formFields["state"].value = decoded?.address?.state;
              formFields["country"].value = decoded?.address?.country;
              formFields["zip"].value = decoded?.address?.zip;
              formFields["lat"].value = decoded?.address?.lat;
              formFields["lng"].value = decoded?.address?.lng;
            } else {
              formFields[`userType`].value =
                val === "Client"
                  ? "ClientTitle&Escrow"
                  : "SigningCompanySigningService";
            }
          }
          if (item === key) {
            formFields[key].value = val;
            if (item === "name") {
              formFields[key].value = `${val?.first ? val?.first : ""} ${
                val?.last ? val?.last : ""
              }`;
            }
            if (item === "phone") {
              formFields[key].value = getPhoneNumberFromBrackets(val);
            }
          }
        });
      });
      this.setState({
        formFields,
        showMenu:
          decoded.invitedUserType === "Agent" && decoded.workType === undefined
            ? true
            : false,
        decodedData: decoded,
      });
    } else {
      this.setState({ showMenu: true });
    }
    let userType = this.extractUserType(this.props.history.location.search);
    if (userType) {
      formFields["userType"].value = userType;

      this.setState({
        showMenu: false,
        formFields,
      });
    }

    this._customWebsiteDetail();
  };

  extractUserType(url) {
    const queryString = url.split("?")[1];
    const params = new URLSearchParams(queryString);
    return params.get("userType");
  }

  _customWebsiteDetail = () => {
    let payload = {
      site:
        window.location.hostname !== "localhost"
          ? window.location.host
          : "customizedwl.logic-square.com",
    };

    customWebsiteDetail(payload)
      .then((res) => {
        this.setState({ companyDetail: res?.detail }, () => {
          if (
            !this.state.companyDetail?.whiteLabelSetting?.isDefaultUiSelected
          ) {
            let whiteLabelSetting =
              this.state.companyDetail?.whiteLabelSetting?.customizedUiSetting;

            setThemeColor(whiteLabelSetting);
          } else {
            let whiteLabelSetting =
              this.state.companyDetail?.whiteLabelSetting?.defaultUiSetting;

            // let matchingColorScheme = _findMatchingColorScheme(
            //   whiteLabelSetting,
            //   colorsUI
            // );
            setThemeColor(whiteLabelSetting);
          }
          if (this.state?.companyDetail) {
            updateCompanyName(this.state.companyDetail);
            updateFavIcon(this.state.companyDetail?.whiteLabelSetting);
          }
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  getPlaceDetail = async (pid, type) => {
    const { formFields } = deepClone(this.state);
    let detail = await googlePlaceDetails(pid.place_id);
    console.log(detail);

    if (detail) {
      if (detail.postal) {
        formFields["line1"].value = detail?.address;
        formFields["city"].value = detail?.city;
        let state = usaStates.filter((state) => state.name === detail.state)[0]
          ?.abbreviation;
        formFields["state"].value = state;
        formFields["zip"].value = detail?.postal;
        formFields["country"].value = detail?.country;
        formFields["lat"].value = detail?.lat;
        formFields["lng"].value = detail?.lng;
        formFields["searchAddress"].value =
          detail?.address +
          "," +
          detail?.city +
          "," +
          detail?.postal +
          "," +
          detail?.country;
        formFields["searchAddress"].placeId = pid.place_id;
        formFields["searchAddress"].isDirty = true;
      } else {
        showToast("Invalid Address", "error");
        formFields["searchAddress"].value = "";
        formFields["searchAddress"].placeId = null;
      }

      this.setState({ formFields, show: true }, () => {
        this._validateForm();
      });
    }
  };

  _termsAndConditionModalToggle = (isOpen = false, data = {}) => {
    const { termsAndConditionModal } = this.state;

    termsAndConditionModal.isOpen = isOpen;
    termsAndConditionModal.data = data;

    this.setState({ termsAndConditionModal });
  };

  render() {
    const {
      formFields,
      isShowPassword,
      showMenu,
      termsAndConditionModal,
      companyDetail,
      decodedData,
    } = this.state;
    console.log("df", this.state);
    return (
      <>
        <div className="loginWrapper">
          <div
            className={`loginLeft ${
              window.location.host !== "app.closewise.com" ? "whiteLabel" : ""
            }`}
          >
            {/* <PublicSideCarousel companyDetail={companyDetail}/> */}
            {window.location.host === "app.closewise.com" ? (
              <PublicSideCarousel companyDetail={companyDetail} />
            ) : // <img
            //   src={
            //     companyDetail.logo
            //       ? companyDetail.logo
            //       : require("../../../assets/img/logBigIcon.png").default
            //   }
            //   alt="logo big"
            //   className="bigLogo"
            // />
            window.location.host === "app.closewise.com" ? (
              <img
                src={require("../../../assets/img/logBigIcon.png").default}
                alt="logo big"
                className="bigLogo"
              />
            ) : companyDetail.logo ? (
              <img
                src={companyDetail.logo}
                alt="logo big"
                className="bigLogo"
              />
            ) : (
              <Spinner />
            )}
          </div>
          <div className="loginRight signUpPage">
            <img
              src={require("../../../assets/img/logBigIcon.png").default}
              alt="logo big"
              className="bigLogo"
            />
            <div className="FormWrap">
              <div className="accountType">
                <h1>Create Account</h1>

                {formFields?.userType?.value?.trim().length ? (
                  <div className="currentType">
                    <Button
                      color="link"
                      onClick={() => {
                        this._onChangeFormField("userType", "");
                      }}
                    >
                      <img
                        src={
                          require("../../../assets/img/arrowLeftBlack.png")
                            .default
                        }
                        alt="arrow"
                      />
                    </Button>{" "}
                    <span>
                      {formFields?.userType?.value === "ClientTitle&Escrow"
                        ? "Client Title & Escrow"
                        : formFields?.userType?.value ===
                          "SigningCompanyTitle&Escrow"
                        ? "Signing Company Title & Escrow"
                        : formFields?.userType.value.replace(
                            /([a-z])([A-Z])/g,
                            "$1 $2"
                          )}
                    </span>
                  </div>
                ) : null}

                {/* <div className="chooseType">
                  <span
                    onClick={() =>
                      this.setState({ showMenu: !this.state.showMenu })
                    }
                  >
                    {formFields?.userType?.value?.trim().length
                      ? formFields?.userType?.value === "ClientTitle&Escrow"
                        ? "Client Title & Escrow"
                        : formFields?.userType?.value ===
                          "SigningCompanyTitle&Escrow"
                        ? "Signing Company Title & Escrow"
                        : formFields?.userType.value.replace(
                            /([a-z])([A-Z])/g,
                            "$1 $2"
                          )
                      : "Choose Type"}
                    {showMenu ? (
                      <i className="fa fa-chevron-down" />
                    ) : (
                      <i className="fa fa-chevron-up" />
                    )}
                  </span>
                </div> */}

                {showMenu && (
                  <>
                    {(decodedData === null ||
                      decodedData?.invitedUserType === "SigningCompany") &&
                    !this.props?.history?.location?.search.includes("code") &&
                    (window.location.host === "app.closewise.com" ||
                      window.location.hostname === "localhost" ||
                      window.location.hostname === "devapp.closewise.com") ? (
                      <div className="registrationWrap">
                        <h2>For Scheduling &amp; Business Management</h2>
                        <div className="registrationItem">
                          <Label for="titleEscrow">
                            <Input
                              hidden
                              name="business management"
                              type="radio"
                              id="titleEscrow"
                              checked={
                                formFields.userType.value ===
                                "SigningCompanyTitle&Escrow"
                              }
                              onChange={() =>
                                this._onChangeFormField(
                                  "userType",
                                  "SigningCompanyTitle&Escrow"
                                )
                              }
                            />
                            <img
                              src={
                                require("../../../assets/img/title&escrow.svg")
                                  .default
                              }
                              alt="Title & Escrow"
                            />
                            <img
                              src={
                                require("../../../assets/img/Title&Escrow_white.svg")
                                  .default
                              }
                              className="whiteIcon"
                              alt="Title & Escrow"
                            />
                            <span>Title &amp; Escrow</span>
                          </Label>
                          <Label for="signingService">
                            <Input
                              hidden
                              name="business management"
                              type="radio"
                              id="signingService"
                              checked={
                                formFields.userType.value ===
                                "SigningCompanySigningService"
                              }
                              onChange={() =>
                                this._onChangeFormField(
                                  "userType",
                                  "SigningCompanySigningService"
                                )
                              }
                            />
                            <img
                              src={
                                require("../../../assets/img/signingservice.svg")
                                  .default
                              }
                              alt="Signing Service"
                            />
                            <img
                              src={
                                require("../../../assets/img/SigningService_white.svg")
                                  .default
                              }
                              className="whiteIcon"
                              alt="Signing Service"
                            />
                            <span>Signing Service</span>
                          </Label>
                          <Label for="lawfirms">
                            <Input
                              hidden
                              name="business management"
                              type="radio"
                              id="lawfirms"
                              checked={
                                formFields.userType.value ===
                                "SigningCompanyLawfirms"
                              }
                              onChange={() =>
                                this._onChangeFormField(
                                  "userType",
                                  "SigningCompanyLawfirms"
                                )
                              }
                            />
                            <img
                              className="lawFirms"
                              src={
                                require("../../../assets/img/lawfirms&others.svg")
                                  .default
                              }
                              alt="law firms"
                            />
                            <img
                              src={
                                require("../../../assets/img/LawFirms_white.svg")
                                  .default
                              }
                              className="whiteIcon lawFirms"
                              alt="law firms"
                            />
                            <span>
                              Law Firms & <br /> Others
                            </span>
                          </Label>
                        </div>
                      </div>
                    ) : null}
                  </>
                )}
                {showMenu &&
                this.props.history.location.pathname.split("/")[2] !==
                  undefined &&
                (decodedData === null ||
                  decodedData?.invitedUserType === "Client") ? (
                  <div className="registrationWrap">
                    <h2>For CloseWise Connect Order Placement </h2>
                    <div className="registrationItem company">
                      <Label for="CloseWiseTitle">
                        <Input
                          hidden
                          name="business management"
                          type="radio"
                          id="CloseWiseTitle"
                          checked={
                            formFields.userType.value === "ClientTitle&Escrow"
                          }
                          onChange={() =>
                            this._onChangeFormField(
                              "userType",
                              "ClientTitle&Escrow"
                            )
                          }
                        />
                        <img
                          src={
                            require("../../../assets/img/title&escrow.svg")
                              .default
                          }
                          alt="Title & Escrow"
                        />
                        <img
                          src={
                            require("../../../assets/img/Title&Escrow_white.svg")
                              .default
                          }
                          className="whiteIcon"
                          alt="Title & Escrow"
                        />
                        <span>Title &amp; Escrow</span>
                      </Label>
                      <Label for="CloseWiselenders">
                        <Input
                          hidden
                          name="business management"
                          type="radio"
                          id="CloseWiselenders"
                          checked={formFields.userType.value === "ClientLender"}
                          onChange={() =>
                            this._onChangeFormField("userType", "ClientLender")
                          }
                        />
                        <img
                          src={
                            require("../../../assets/img/lenders.svg").default
                          }
                          alt="lender"
                        />
                        <img
                          src={
                            require("../../../assets/img/lenders_white.svg")
                              .default
                          }
                          className="whiteIcon lawFirms"
                          alt="law firms"
                        />
                        <span>Lenders</span>
                      </Label>
                      <Label for="CloseWiseLaw">
                        <Input
                          hidden
                          name="business management"
                          type="radio"
                          id="CloseWiseLaw"
                          checked={formFields.userType.value === "ClientLaw"}
                          onChange={() =>
                            this._onChangeFormField("userType", "ClientLaw")
                          }
                        />
                        <img
                          className="mt-4"
                          src={
                            require("../../../assets/img/lawfirms&others.svg")
                              .default
                          }
                          alt="law firms"
                        />
                        <img
                          src={
                            require("../../../assets/img/LawFirms_white.svg")
                              .default
                          }
                          className="whiteIcon mt-4"
                          alt="law firms"
                        />
                        <span>
                          Law Firms & <br /> Others
                        </span>
                      </Label>
                    </div>
                  </div>
                ) : null}

                {(decodedData === null ||
                  decodedData?.invitedUserType === "Agent") &&
                  showMenu && (
                    <div className="registrationWrap">
                      <h2>For Notaries, Attorney, & Signing Agents</h2>
                      {/* formFields.userType.value === undefined */}
                      <div className="registrationItem notaries">
                        <Label for="Notaries">
                          <Input
                            hidden
                            name="business management"
                            type="radio"
                            id="Notaries"
                            checked={formFields.userType.value === "Notary"}
                            onChange={() =>
                              this._onChangeFormField("userType", "Notary")
                            }
                          />
                          <img
                            src={
                              require("../../../assets/img/notaries.svg")
                                .default
                            }
                            alt="Notaries"
                          />
                          <img
                            src={
                              require("../../../assets/img/Notaries_white.svg")
                                .default
                            }
                            className="whiteIcon"
                            alt="Notaries"
                          />
                          <span>Notaries</span>
                        </Label>
                        <Label for="Attorney">
                          <Input
                            hidden
                            name="business management"
                            type="radio"
                            id="Attorney"
                            checked={formFields.userType.value === "Attorney"}
                            onChange={() =>
                              this._onChangeFormField("userType", "Attorney")
                            }
                          />
                          <img
                            src={
                              require("../../../assets/img/attorney.svg")
                                .default
                            }
                            alt="Attorney"
                          />
                          <img
                            src={
                              require("../../../assets/img/Attorney_white.svg")
                                .default
                            }
                            className="whiteIcon"
                            alt="Attorney"
                          />
                          <span>Attorney</span>
                        </Label>
                      </div>
                    </div>
                  )}
                {/* </> */}
                {/* )} */}
                {/* {!showMenu &&
                  !formFields.userType?.value?.trim().length > 0 && (
                    <p className="createInfo">
                      Please choose your type to create an account
                    </p>
                  )} */}
              </div>

              {formFields.userType.value === "Notary" ||
              formFields.userType.value === "Attorney" ? (
                <>
                  {/* the below fields is for Notary/Attorney */}
                  <Form
                    className="signUpForm"
                    onSubmit={this._userSignup}
                    spellCheck="false"
                    autoComplete="off"
                  >
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        name="name"
                        value={formFields.name.value}
                        onChange={(e) =>
                          this._onChangeFormField("name", e.target.value)
                        }
                        autoComplete="off"
                        placeholder=" "
                      />
                      <Label>Name</Label>
                      {formFields.name.error && (
                        <div className="validation-error">
                          {formFields.name.error}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup className="floatingLabel withInputGroup">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>+1</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          name="phone"
                          value={formFields.phone.value}
                          onChange={(event) =>
                            this._onChangeFormField("phone", event.target.value)
                          }
                          autoComplete="off"
                          placeholder=" "
                        />
                        <Label>Contact Number</Label>
                      </InputGroup>
                      <small style={{ fontSize: 11 }}>
                        Notes: By providing your phone number. you agree to
                        receive text messages from CloseWise. Message and data
                        rates may apply. Message frequency varies.
                      </small>
                      {formFields.phone.error && (
                        <div className="validation-error">
                          {formFields.phone.error}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="email"
                        value={formFields.email.value}
                        onChange={(e) =>
                          this._onChangeFormField("email", e.target.value)
                        }
                        autoComplete="username"
                        placeholder=" "
                      />
                      <Label>Work Email</Label>

                      {formFields.email.error && (
                        <div className="validation-error">
                          {formFields.email.error}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup className="floatingLabel">
                      <InputGroup>
                        <Input
                          type={isShowPassword ? "text" : "password"}
                          value={formFields.password.value}
                          onChange={(e) =>
                            this._onChangeFormField("password", e.target.value)
                          }
                          autoComplete="new-password"
                          placeholder=" "
                        />
                        <Label>Password</Label>
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <img
                              src={
                                require(`../../../assets/img/${
                                  isShowPassword
                                    ? "eyeIcon.svg"
                                    : "eyeIconSlash.svg"
                                }`).default
                              }
                              onClick={this._showPasswordToggle}
                              alt="eye icon"
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>

                      {formFields.password.error && (
                        <div className="validation-error">
                          {formFields.password.error}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup className="floatingLabel withInputGroup">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <img
                              src={
                                require("../../../assets/img/mapMarker_blue.svg")
                                  .default
                              }
                              alt="map marker"
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                        {console.log(
                          "formFields?.searchAddress",
                          formFields?.searchAddress
                        )}
                        <Input
                          type="text"
                          placeholder=" "
                          value={formFields?.searchAddress?.value}
                          name="searchAddress"
                          onChange={(event) =>
                            this._onChangeFormField(
                              "searchAddress",
                              event.target.value
                            )
                          }
                        />
                        <Label>Search Address</Label>
                      </InputGroup>
                      {formFields?.searchAddress?.error && (
                        <div className="validation-error">
                          {formFields?.searchAddress?.error}
                        </div>
                      )}

                      {this.state.show === "searchAddress" ? (
                        <ListGroup flush className="customSearchOptions">
                          {" "}
                          {this.state.searchResult &&
                            this.state.searchResult.map((i) => {
                              return (
                                <ListGroupItem
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.getPlaceDetail(i, "closingAddress")
                                  }
                                >
                                  {i.description}
                                </ListGroupItem>
                              );
                            })}
                        </ListGroup>
                      ) : null}
                    </FormGroup>
                    <div className=" mt-4 mb-4">
                      <FormGroup className="d-flex">
                        <CustomInput
                          type="checkbox"
                          id="termsAndCondition"
                          // label="terms and Condition"
                          checked={
                            formFields?.isTermsAndConditionsAccepted?.value
                          }
                          onChange={(e) =>
                            this._onChangeFormField(
                              "isTermsAndConditionsAccepted",
                              e.target.checked
                            )
                          }
                        />
                        <Label>
                          I have read and agree to the
                          <Button
                            color="link"
                            onClick={() =>
                              // this._termsAndConditionModalToggle(true)
                              window.open(
                                "https://www.closewise.com/terms-and-conditions/"
                              )
                            }
                          >
                            terms and Condition{" "}
                          </Button>
                          <Button
                            color="link"
                            onClick={() =>
                              window.open(
                                "https://www.closewise.com/privacy-policy/"
                              )
                            }
                          >
                            And Privacy policy{" "}
                          </Button>
                        </Label>
                      </FormGroup>
                    </div>
                    <div className="text-center mb-4 mt-4">
                      <Button
                        color="primary"
                        className="d-block w-100"
                        type="submit"
                        disabled={
                          !formFields?.isTermsAndConditionsAccepted?.value
                        }
                      >
                        Request Account
                      </Button>
                    </div>
                  </Form>
                </>
              ) : null}

              {formFields.userType.value === "SigningCompanyLawfirms" ||
              formFields.userType.value === "SigningCompanyTitle&Escrow" ||
              formFields.userType.value === "SigningCompanySigningService" ? (
                <>
                  {/* the below fields is for Signing Company */}
                  <Form
                    onSubmit={this._userSignup}
                    spellCheck="false"
                    autoComplete="off"
                  >
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        name="name"
                        value={formFields.name.value}
                        onChange={(e) =>
                          this._onChangeFormField("name", e.target.value)
                        }
                        autoComplete="off"
                        placeholder=" "
                      />
                      <Label>Name</Label>

                      {formFields.name.error && (
                        <div className="validation-error">
                          {formFields.name.error}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup className="floatingLabel withInputGroup">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>+1</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          name="phone"
                          value={formFields.phone.value}
                          onChange={(event) =>
                            this._onChangeFormField("phone", event.target.value)
                          }
                          autoComplete="off"
                          placeholder=" "
                        />
                        <Label>Contact Number</Label>
                      </InputGroup>

                      {formFields.phone.error && (
                        <div className="validation-error">
                          {formFields.phone.error}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="email"
                        value={formFields.email.value}
                        onChange={(e) =>
                          this._onChangeFormField("email", e.target.value)
                        }
                        autoComplete="username"
                        placeholder=" "
                      />
                      <Label>Work Email</Label>

                      {formFields.email.error && (
                        <div className="validation-error">
                          {formFields.email.error}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup className="floatingLabel">
                      <InputGroup>
                        <Input
                          type={isShowPassword ? "text" : "password"}
                          value={formFields.password.value}
                          onChange={(e) =>
                            this._onChangeFormField("password", e.target.value)
                          }
                          autoComplete="new-password"
                          placeholder=" "
                        />
                        <Label>Password</Label>
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <img
                              src={
                                require(`../../../assets/img/${
                                  isShowPassword
                                    ? "eyeIcon.svg"
                                    : "eyeIconSlash.svg"
                                }`).default
                              }
                              onClick={this._showPasswordToggle}
                              alt="eye icon"
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>

                      {formFields.password.error && (
                        <div className="validation-error">
                          {formFields.password.error}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        name="companyName"
                        value={formFields.companyName.value}
                        onChange={(e) =>
                          this._onChangeFormField("companyName", e.target.value)
                        }
                        autoComplete="off"
                        placeholder=" "
                      />
                      <Label>Company Name</Label>

                      {formFields.companyName.error && (
                        <div className="validation-error">
                          {formFields.companyName.error}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup className="floatingLabel withInputGroup">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <img
                              src={
                                require("../../../assets/img/globeIcon.svg")
                                  .default
                              }
                              alt=""
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          name="websiteURL"
                          value={formFields.websiteURL.value}
                          onChange={(e) =>
                            this._onChangeFormField(
                              "websiteURL",
                              e.target.value
                            )
                          }
                          autoComplete="off"
                          placeholder=" "
                        />
                        <Label>Website (Optional)</Label>
                      </InputGroup>
                    </FormGroup>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        name="jobTitle"
                        value={formFields.jobTitle.value}
                        onChange={(e) =>
                          this._onChangeFormField("jobTitle", e.target.value)
                        }
                        autoComplete="off"
                        placeholder=" "
                      />
                      <Label>Job Title (Optional)</Label>
                    </FormGroup>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        name="zip"
                        value={formFields.zip.value}
                        onChange={(e) =>
                          this._onChangeFormField("zip", e.target.value)
                        }
                        autoComplete="off"
                        placeholder=" "
                      />
                      <Label>Zip (Optional)</Label>
                    </FormGroup>

                    <div className=" mt-4 mb-4">
                      <FormGroup className="d-flex">
                        <CustomInput
                          type="checkbox"
                          id="termsAndCondition"
                          // label="terms and Condition"
                          checked={
                            formFields.isTermsAndConditionsAccepted.value
                          }
                          onChange={(e) =>
                            this._onChangeFormField(
                              "isTermsAndConditionsAccepted",
                              e.target.checked
                            )
                          }
                        />
                        <Label>
                          I have read and agree to the
                          <Button
                            color="link"
                            onClick={() =>
                              // this._termsAndConditionModalToggle(true)
                              window.open(
                                "https://www.closewise.com/terms-and-conditions/"
                              )
                            }
                          >
                            terms and Condition{" "}
                          </Button>
                          <Button
                            color="link"
                            onClick={() =>
                              window.open(
                                "https://www.closewise.com/privacy-policy/"
                              )
                            }
                          >
                            And Privacy policy{" "}
                          </Button>
                        </Label>
                      </FormGroup>
                    </div>
                    <div className="text-center mt-4 mb-4">
                      <Button
                        color="primary"
                        className="d-block w-100"
                        type="submit"
                        disabled={
                          !formFields.isTermsAndConditionsAccepted.value
                        }
                      >
                        Request Account
                      </Button>
                    </div>
                  </Form>
                </>
              ) : null}

              {formFields.userType.value === "ClientLaw" ||
              formFields.userType.value === "ClientTitle&Escrow" ||
              formFields.userType.value === "ClientLender" ? (
                <>
                  {/* the below fields is for Client */}
                  <Form
                    onSubmit={this._userSignup}
                    spellCheck="false"
                    autoComplete="off"
                  >
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        name="name"
                        value={formFields.name.value}
                        onChange={(e) =>
                          this._onChangeFormField("name", e.target.value)
                        }
                        autoComplete="off"
                        placeholder=" "
                      />
                      <Label>Name</Label>

                      {formFields.name.error && (
                        <div className="validation-error">
                          {formFields.name.error}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup className="floatingLabel withInputGroup">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>+1</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          name="phone"
                          value={formFields.phone.value}
                          onChange={(event) =>
                            this._onChangeFormField("phone", event.target.value)
                          }
                          autoComplete="off"
                          placeholder=" "
                        />
                        <Label>Contact Number</Label>
                      </InputGroup>

                      {formFields.phone.error && (
                        <div className="validation-error">
                          {formFields.phone.error}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="email"
                        value={formFields.email.value}
                        onChange={(e) =>
                          this._onChangeFormField("email", e.target.value)
                        }
                        autoComplete="username"
                        placeholder=" "
                        disabled
                      />
                      <Label>Work Email</Label>

                      {formFields.email.error && (
                        <div className="validation-error">
                          {formFields.email.error}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup className="floatingLabel">
                      <InputGroup>
                        <Input
                          type={isShowPassword ? "text" : "password"}
                          value={formFields.password.value}
                          onChange={(e) =>
                            this._onChangeFormField("password", e.target.value)
                          }
                          autoComplete="new-password"
                          placeholder=" "
                        />
                        <Label>Password</Label>
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <img
                              src={
                                require(`../../../assets/img/${
                                  isShowPassword
                                    ? "eyeIcon.svg"
                                    : "eyeIconSlash.svg"
                                }`).default
                              }
                              onClick={this._showPasswordToggle}
                              alt="eye icon"
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                      {formFields.password.error && (
                        <div className="validation-error">
                          {formFields.password.error}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        name="companyName"
                        value={formFields.companyName.value}
                        onChange={(e) =>
                          this._onChangeFormField("companyName", e.target.value)
                        }
                        autoComplete="off"
                        placeholder=" "
                      />
                      <Label>Company Name</Label>

                      {formFields.companyName.error && (
                        <div className="validation-error">
                          {formFields.companyName.error}
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        name="zip"
                        value={formFields.zip.value}
                        onChange={(e) =>
                          this._onChangeFormField("zip", e.target.value)
                        }
                        autoComplete="off"
                        placeholder=" "
                      />
                      <Label>Zip (Optional)</Label>
                    </FormGroup>

                    <div className=" mt-4 mb-4">
                      <FormGroup className="d-flex">
                        <CustomInput
                          type="checkbox"
                          id="termsAndCondition"
                          // label="I have read and agree to the terms and Condition"
                          checked={
                            formFields.isTermsAndConditionsAccepted.value
                          }
                          onChange={(e) =>
                            this._onChangeFormField(
                              "isTermsAndConditionsAccepted",
                              e.target.checked
                            )
                          }
                        />
                        <Label>
                          I have read and agree to the
                          <Button
                            color="link"
                            onClick={() =>
                              // this._termsAndConditionModalToggle(true)
                              window.open(
                                "https://www.closewise.com/terms-and-conditions/"
                              )
                            }
                          >
                            terms and Condition{" "}
                          </Button>
                          <Button
                            color="link"
                            onClick={() =>
                              window.open(
                                "https://www.closewise.com/privacy-policy/"
                              )
                            }
                          >
                            And Privacy policy{" "}
                          </Button>
                        </Label>
                      </FormGroup>
                    </div>
                    <div className="text-center mt-4 mb-4">
                      <Button
                        color="primary"
                        className="d-block w-100"
                        type="submit"
                        disabled={
                          !formFields.isTermsAndConditionsAccepted.value
                        }
                      >
                        Request Account
                      </Button>
                    </div>
                  </Form>
                </>
              ) : null}

              <p style={{ fontWeight: 500, textAlign: "center" }}>
                Already have an account? <Link to="/login">Login</Link>
              </p>
            </div>
          </div>
        </div>
        <TermsAndConditionModal
          isOpen={termsAndConditionModal?.isOpen}
          data={termsAndConditionModal?.data}
          toggle={(isOpen, data) =>
            this._termsAndConditionModalToggle(isOpen, data)
          }
          hideAcceptButton={true}
          // termLogin={(termAccepted) => this._termLogin(termAccepted)}
        />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    addUserData: (userData) => dispatch(addUserData(userData)),
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(null, mapDispatchToProps)(SignUp);
