import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  FormGroup,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Progress,
  ListGroupItem,
  ListGroup,
} from "reactstrap";
import {
  deepClone,
  errorHandler,
  formatAddressInSingleText,
  getCountryDialCodeFromCountryCode,
  getPhoneNumberFromBrackets,
  sleepTime,
} from "../../../../helper-methods";
import { showToast } from "../../../../helper-methods";
import { RegexConfig } from "../../../../config/RegexConfig";
import {
  createAgentClient,
  updateAgentClient,
  getAgentClientById,
  enquiryClientDetailsByEmail,
  getAgentClientTagsList,
} from "../../../../http/http-calls";
import { PostManager } from "../../../../post-manager";
import { UploadQueueManager } from "../../../../upload-queue-manager";
import { connect } from "react-redux";
import {
  googlePlaceDetails,
  googlePlaceSearch,
} from "../../../../helper-methods/googleService";
import usaStates from "../../../../config/usa_states_titlecase";
import CreatableSelect from "react-select/creatable";
class AddClientAgentModal extends Component {
  state = {
    formFields: {
      name: {
        value: "",
        error: null,
        isDirty: false,
      },
      phone: {
        value: "",
        error: null,
        isDirty: false,
      },
      phoneCountry: {
        value: "US",
        error: null,
        isDirty: false,
      },
      email: {
        value: "",
        error: null,
        isDirty: false,
      },
      tags: {
        value: [],
        error: null,
        isDirty: false,
      },
      pointOfContact: {
        value: "",
        error: null,
        isDirty: false,
      },
      clientId: {
        value: "",
        error: null,
        isDirty: false,
      },
      // location: {
      //   value: "",
      //   error: null,
      //   isDirty: false,
      // },
      // rates: {
      //   value: "",
      //   error: null,
      //   isDirty: false,
      // },
      logo: {
        file: {
          previewBlob: null,
          uploadData: null,
          type: "image",
          uploadUrl: null,
        },
        error: null,
        isDirty: false,
      },
      // google full address
      clientAddress: {
        value: "",
        placeId: null,
        error: null,
        isDirty: false,
        isValidate: true,
      },
    },
    googleAddressResult: [],
    isAddressListShow: false, // boolean
    loading: false,
    isEnquired: false,
    enquiryLoading: false,
    tagsList: [],
  };

  _resetModalState = (data = null) => {
    this.setState({
      formFields: {
        name: {
          value: data?.name?.full || "",
          isValid: false,
          isDirty: false,
        },
        phone: {
          value: data?.phone ? getPhoneNumberFromBrackets(data.phone) : "",
          isValid: false,
          isDirty: false,
        },
        phoneCountry: {
          value: data?.phoneCountry || "US",
          isValid: false,
          isDirty: false,
        },
        email: {
          value: data?.email || "",
          isValid: false,
          isDirty: false,
        },
        tags: {
          value: data?.tags || [],
          isValid: false,
          isDirty: false,
        },
        pointOfContact: {
          value: data?.pointOfContact || "",
          isValid: false,
          isDirty: false,
        },
        clientId: {
          value: "",
          error: null,
          isDirty: false,
        },
        // location: {
        //   value: data?.location || "",
        //   isValid: false,
        //   isDirty: false,
        // },
        // rates: {
        //   value: data?.rates || "",
        //   isValid: false,
        //   isDirty: false,
        // },
        logo: {
          file: {
            previewBlob: null,
            uploadData: null,
            type: "image",
            uploadUrl: data?.logo || null,
          },
          error: null,
          isDirty: false,
        },
        clientAddress: {
          value: "",
          placeId: null,
          error: null,
          isDirty: false,
          isValidate: true,
        },
      },
      googleAddressResult: [],
      isAddressListShow: false, // boolean
      loading: false,
      isEnquired: false,
      enquiryLoading: false,
      tagsList: [],
    });
  };

  componentDidMount = () => {
    this.searchTimer = null;
  };

  _onSelectAddress = (fieldName, addr) => {
    const { formFields } = deepClone(this.state);
    formFields[fieldName].value = addr.description;
    formFields[fieldName].placeId = addr.place_id;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields, isAddressListShow: false }, () => {
      this._validateForm();
    });
  };

  _googlePlaceSearch = (searchValue) => {
    clearTimeout(this.searchTimer);

    this.searchTimer = setTimeout(async () => {
      const googleAddressResult = await googlePlaceSearch(searchValue);
      this.setState({ googleAddressResult, isAddressListShow: true });
    }, 1000);
  };

  _closeModal = () => {
    this._resetModalState();
    this.props.toggle();
  };

  _setFormData = (data) => {
    const { formFields } = deepClone(this.state);

    formFields["name"].value = data?._signingCompany
      ? data.companyName
      : data?._client || data?._assistant
      ? data.name?.full
      : "";

    // data.name.full;

    formFields["phone"].value = data.phone
      ? getPhoneNumberFromBrackets(data.phone)
      : "";

    if (data.address) {
      formFields["clientAddress"].value = formatAddressInSingleText(
        data.address
      );
      formFields["clientAddress"].placeId = "initialValue";
    }

    formFields["email"].value = data.email ? data.email : "";
    // formFields["location"].value = data.location ? data.location : "";
    formFields["phoneCountry"].value = data.phoneCountry
      ? data.phoneCountry
      : "US";
    formFields["pointOfContact"].value = data.pointOfContact
      ? data.pointOfContact
      : "";
    formFields["clientId"].value = data.clientId ? data.clientId : "";

    formFields["logo"].file = {
      previewBlob: data.logo ? data.logo : null,
      uploadData: null,
    };

    formFields["tags"].value = data?.tags
      ? data?.tags?.map((each) => ({
          value: each,
          label: each,
        }))
      : [];

    // formFields["rates"].value =
    //   data.rates || data.rates === 0 ? String(data.rates) : "";

    this.setState({ formFields, loading: false });
  };

  _getAgentClientById = (id) => {
    this.setState({ loading: true });

    getAgentClientById(id)
      .then((res) => {
        this._setFormData(res.client);
      })
      .catch((error) => {
        errorHandler(error);
        this._closeModal();
      });
  };

  _getAllTagsList = async () => {
    try {
      const res = await getAgentClientTagsList();

      const tagsListOptions =
        res?.tags?.map((each) => ({
          label: each,
          value: each,
        })) || [];

      this.setState({ tagsList: tagsListOptions });
    } catch (err) {
      errorHandler(err);
    }
  };

  componentDidUpdate(previousProps, previousState) {
    const { isOpen, data } = this.props;

    if (isOpen && previousProps.isOpen !== isOpen) {
      this._getAllTagsList();
    }

    if (isOpen && data && previousProps.isOpen !== isOpen) {
      this._getAgentClientById(data.id);
    }
  }

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty) {
          switch (key) {
            case "name": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            // case "pointOfContact": {
            //   if (this.props.data) {
            //     if (
            //       formFields[key].value &&
            //       formFields[key].value.trim().length
            //     ) {
            //       formFields[key].isDirty = false;
            //       formFields[key].error = null;
            //     } else {
            //       formFields[key].isDirty = true;
            //       formFields[key].error = "*Required";
            //       isFormValid = false;
            //     }
            //   }
            //   break;
            // }
            case "email": {
              if (!formFields["clientId"].value?.trim()?.length) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  if (
                    RegexConfig.email.test(
                      String(formFields[key].value).toLowerCase()
                    )
                  ) {
                    this.props.data === null &&
                      this._enquiryClientDetailsByEmail();
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Invalid Email";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            case "phone": {
              if (formFields[key].value && formFields[key].value.length) {
                if (
                  RegexConfig.phone.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  isFormValid = false;
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Phone Number";
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "clientId": {
              if (!formFields["email"].value?.trim()?.length) {
                if (
                  formFields[key].value &&
                  formFields[key].value?.trim()?.length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            default: {
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _updateLogoImage = (event) => {
    const { formFields } = deepClone(this.state);

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      let objFile = event.target.files[0];
      formFields.logo.file = {
        previewBlob: URL.createObjectURL(objFile),
        uploadData: objFile,
      };
    }
    this.setState({ formFields });
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = deepClone(this.state);

    if (fieldName === "clientAddress") {
      this._googlePlaceSearch(value);
      formFields[fieldName].placeId = null;
    }

    formFields[fieldName].value = value;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      this._validateForm();
    });
  };

  _onBlurFormFields = (key) => {
    let { formFields, isEnquired } = deepClone(this.state);

    if (key === "email") isEnquired = false;

    formFields[key].isDirty = true;

    this.setState({ formFields, isEnquired }, () => {
      // Validation
      this._validateForm();
    });
  };

  _enquiryClientDetailsByEmail = async () => {
    try {
      const { formFields, isEnquired } = this.state;

      if (isEnquired) return;

      this.setState({ enquiryLoading: true, isEnquired: true });

      const { client } = await enquiryClientDetailsByEmail({
        email: formFields.email.value.trim(),
      });

      this._resetModalState(client);

      this.setState({ enquiryLoading: false });
    } catch (error) {
      this.setState({ enquiryLoading: false });
    }
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _createAgentClient = (payload) => {
    createAgentClient(payload)
      .then((res) => {
        showToast("Client Added Successfully", "success");
        this.props.resetTable();
        this._closeModal();
      })
      .catch((error) => {
        errorHandler(error);
        this.setState({ loading: false });
      });
  };

  _updateAgentClient = (payload, id) => {
    updateAgentClient(id, payload)
      .then((res) => {
        showToast("Client Details Updated Successfully", "success");
        this.props.resetTable();
        this._closeModal();
      })
      .catch((error) => {
        errorHandler(error);
        this.setState({ loading: false });
      });
  };

  _callAddOrEditApi = (payload) => {
    if (this.props.data) {
      this._updateAgentClient(payload, this.props.data.id);
    } else {
      this._createAgentClient(payload);
    }
  };

  _saveClientData = async () => {
    await this._markAllFieldDirty();

    const isFormValid = await this._validateForm();

    if (isFormValid) {
      this.setState({ loading: true });

      const { formFields } = deepClone(this.state);

      const splitName = formFields.name.value.trim().split(" ");

      const countryDialCode = await getCountryDialCodeFromCountryCode(
        formFields.phoneCountry.value
      );

      const payload = {
        name: {
          first: splitName.slice(0, 1).join(" "),
          last: splitName[1] ? splitName.slice(1).join(" ") : "",
        },
        // email: formFields.email.value.trim(),
        phoneCountry: formFields.phoneCountry.value,
        pointOfContact: formFields.pointOfContact.value.trim(),
        // clientId: formFields?.clientId?.value?.trim()
        //   ? formFields?.clientId?.value?.trim()
        //   : undefined,
        // location: formFields.location.value.trim(),
        // rates: formFields.rates.value.trim(),
        address: {},
      };

      payload["tags"] = formFields?.tags?.value?.length
        ? formFields?.tags?.value?.map((each) => each?.value)
        : [];
      // if (!this.props.data?.email && formFields?.email?.value?.trim()) {
      if (formFields?.email?.value?.trim()) {
        payload["email"] = formFields?.email?.value?.trim();
      }
      if (formFields?.clientId?.value?.trim()) {
        payload["clientId"] = formFields?.clientId?.value?.trim();
      }

      if (countryDialCode && formFields.phone.value) {
        payload["phone"] = `(${countryDialCode})${formFields.phone.value}`;
      }

      if (
        formFields.clientAddress.placeId &&
        formFields.clientAddress.placeId !== "initialValue"
      ) {
        const addressObj = await googlePlaceDetails(
          formFields.clientAddress.placeId
        );

        payload.address["line1"] = addressObj.address.trim() || "";
        payload.address["line2"] = "";
        // payload.address["street"] = addressObj.address.trim();
        payload.address["city"] = addressObj.city || "";
        const findStateObj = usaStates.find(
          (state) =>
            state.name === addressObj.state ||
            state.abbreviation === addressObj.state
        );
        payload.address["state"] = findStateObj
          ? findStateObj.abbreviation
          : addressObj.state;
        payload.address["zip"] = addressObj.postal || "";
        payload.address["country"] = addressObj.country || "";
        payload.address["lat"] = addressObj.lat || 0;
        payload.address["lng"] = addressObj.lng || 0;
        payload.address["timeZone"] = "";
      } else {
        delete payload.address;
      }

      if (formFields.logo.file && formFields.logo.file.uploadData) {
        let postID = PostManager.addMediaFilesCount(1);

        PostManager.onAllMediaFilesUploadCompleted(postID, async (id) => {
          if (id.postID === postID) {
            await sleepTime(500);
            this._callAddOrEditApi(payload);
            PostManager.deletePostID(postID);
          } else {
            return;
          }
        });

        // for logo images
        let mediaData = {
          file: formFields.logo.file.uploadData,
          blobObject: formFields.logo.file.previewBlob,
        };
        const uploadId = UploadQueueManager.addMediaToQueue(mediaData, "image");

        // Listen for upload complete
        UploadQueueManager.onUploadComplete(uploadId, async (mediaResponse) => {
          PostManager.onSingleMediaFileUploadCompleted(postID);

          // Upload complete
          // Get content id from backend
          payload["logo"] = mediaResponse.fileUrl;
        });
      } else {
        this._callAddOrEditApi(payload);
      }
    }
  };

  render() {
    const {
      formFields,
      loading,
      googleAddressResult,
      isAddressListShow,
      tagsList,
    } = this.state;

    const { isOpen, data } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={() => this._closeModal()}
        centered
        scrollable
      >
        <ModalHeader toggle={() => this._closeModal()}>
          {data ? "Edit" : "Add"} Client
        </ModalHeader>
        <ModalBody>
          <div className="uploadProfile">
            <Label>
              <Input
                type="file"
                style={{ display: "none" }}
                accept="image/x-png,image/gif,image/jpeg"
                value=""
                onChange={this._updateLogoImage}
                placeholder=" "
              />
              <img
                src={
                  formFields.logo.file && formFields.logo.file.previewBlob
                    ? formFields.logo.file.previewBlob
                    : require("../../../../assets/img/placeholder-img.png")
                        .default
                }
                loading="lazy"
                alt="Client Img"
              />
              <div className="icon">
                <img
                  src={
                    require("../../../../assets/img/pencil_white.png").default
                  }
                  alt="pencil icon"
                />
              </div>
            </Label>
          </div>

          <FormGroup className="floatingLabel">
            <Input
              type="text"
              value={formFields.name.value}
              onChange={(e) => this._updateFieldValue("name", e.target.value)}
              placeholder=" "
            />
            {formFields.name.error && (
              <div className="validation-error">{formFields.name.error}</div>
            )}
            <Label>Name</Label>
          </FormGroup>

          <FormGroup className="floatingLabel withInputGroup">
            {/* country code static (+1) not change by user */}
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>+1</InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder=" "
                value={formFields.phone.value}
                onChange={(e) =>
                  this._updateFieldValue("phone", e.target.value)
                }
              />
              <Label>Phone</Label>
            </InputGroup>

            {formFields.phone.error && (
              <div className="validation-error">{formFields.phone.error}</div>
            )}
          </FormGroup>

          <FormGroup className="floatingLabel">
            <Input
              type="text"
              value={formFields?.email?.value}
              onChange={(e) => this._updateFieldValue("email", e.target.value)}
              onBlur={() => this._onBlurFormFields("email")}
              placeholder=" "
              disabled={data?.email ? true : false}
            />
            <Label>Client Email</Label>
            {formFields.email.error && (
              <div className="validation-error">{formFields.email.error}</div>
            )}
          </FormGroup>

          <FormGroup className="floatingLabel">
            <Input
              type="text"
              value={formFields?.clientId?.value}
              onChange={(e) =>
                this._updateFieldValue("clientId", e.target.value)
              }
              placeholder=" "
              disabled={data?.clientId ? true : false}
            />
            <Label>Client Id</Label>
            {formFields.clientId.error && (
              <div className="validation-error">
                {formFields.clientId.error}
              </div>
            )}
          </FormGroup>

          <FormGroup className="floatingLabel">
            <Input
              type="text"
              value={formFields.pointOfContact.value}
              onChange={(e) =>
                this._updateFieldValue("pointOfContact", e.target.value)
              }
              placeholder=" "
            />
            <Label>Point Of Contact</Label>
            {formFields.pointOfContact.error && (
              <div className="validation-error">
                {formFields.pointOfContact.error}
              </div>
            )}
          </FormGroup>

          {/* <FormGroup className="floatingLabel">
            <Input
              type="text"
              value={formFields.location?.value}
              onChange={(e) =>
                this._updateFieldValue("location", e.target.value)
              }
              placeholder=" "
            />
            <Label>Zip Code</Label>
          </FormGroup> */}

          <FormGroup className="floatingLabel withInputGroup">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img
                    src={
                      require("../../../../assets/img/mapMarker_blue.svg")
                        .default
                    }
                    alt="map icon"
                    height={14}
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                placeholder=" "
                value={formFields.clientAddress.value}
                name="clientAddress"
                autoComplete="off"
                onChange={(event) =>
                  this._updateFieldValue("clientAddress", event.target.value)
                }
                // value={formFields.rates?.value}
                // onChange={(e) =>
                //   this._updateFieldValue("rates", e.target.value)
                // }
              />
              <Label>Location</Label>
              {formFields.clientAddress.error && (
                <div className="validation-error">
                  {formFields.clientAddress.error}
                </div>
              )}
              {googleAddressResult &&
              googleAddressResult.length &&
              isAddressListShow ? (
                <ListGroup flush className="customSearchOptions">
                  {googleAddressResult.map((addr, index) => {
                    return (
                      <ListGroupItem
                        key={addr.place_id || index}
                        className="cursorPointer"
                        onClick={() =>
                          this._onSelectAddress("clientAddress", addr)
                        }
                      >
                        {addr.description}
                      </ListGroupItem>
                    );
                  })}
                </ListGroup>
              ) : null}
            </InputGroup>
          </FormGroup>

          <FormGroup className={`withChips floatingLabel valueAdded`}>
            <CreatableSelect
              isClearable
              placeholder="select"
              className="customMultiSelect"
              isMulti
              // components={["dummy texct", "demo1"]}
              value={
                formFields?.tags?.value?.length ? formFields?.tags?.value : []
              }
              options={tagsList || []}
              onChange={(value) => this._updateFieldValue("tags", value)}
              blur={() => {
                this._validateForm();
              }}
            />
            <Label style={{ zIndex: 3 }}>Tags</Label>
            {/* 
            {formFields.loanType.error && (
              <div className="validation-error">
                {formFields.loanType.error}
              </div>
            )} */}
          </FormGroup>

          {/* {data ? (
            <FormGroup className="floatingLabel withInputGroup">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>$</InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder=" "
                  value={formFields.rates?.value}
                  onChange={(e) =>
                    this._updateFieldValue("rates", e.target.value)
                  }
                />
                <Label>Standard Rates</Label>
              </InputGroup>
              {formFields.rates?.error && (
                <div className="validation-error">
                  {formFields.rates?.error}
                </div>
              )}
            </FormGroup>
          ) : null} */}
        </ModalBody>

        <ModalFooter>
          <Button
            color="primary"
            size="lg"
            outline
            onClick={() => this._closeModal()}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            size="lg"
            disabled={loading}
            onClick={this._saveClientData}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
            {data ? "Update" : "Save"}
          </Button>
        </ModalFooter>

        {loading && Number(this.props.percentage) > 0 && (
          <Progress animated color="success" value={this.props.percentage} />
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    percentage:
      state.uploadFilePercentage && state.uploadFilePercentage.percentage
        ? state.uploadFilePercentage.percentage
        : "0",
  };
};

export default connect(mapStateToProps, null)(AddClientAgentModal);
