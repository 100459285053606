import React, { useState } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { CustomInput, Card, CardHeader, CardTitle, CardBody } from "reactstrap";
import { errorHandler, showToast } from "../../../../helper-methods";
import { updateClientSettings } from "../../../../http/http-calls";

const ClientNotificationSettingTab = ({ clientSetting, activeTab }) => {
  const saveTimerRef = useRef({ current: null });

  const [notificationSettings, setNotificationSettings] = useState({
    isEmail: false,
    email: {
      closingStatus: false,
      agentInvoice: false,
      bidRevieved: false,
      documentsUpload: false,
    },
    isPush: false,
    push: {
      closingStatus: false,
      documentsUpload: false,
      newBid: false,
    },
  });

  const _setNotificationSetting = (data) => {
    let isEmail = false,
      isPush = false;

    if (data?.email) {
      isEmail = Object.values(data.email).some((each) => each === true);
    }
    if (data?.push) {
      isPush = Object.values(data.push).some((each) => each === true);
    }

    setNotificationSettings({
      isEmail,
      email: {
        closingStatus: data?.email?.closingStatus ? true : false,
        agentInvoice: data?.email?.agentInvoice ? true : false,
        bidRevieved: data?.email?.bidRevieved ? true : false,
        documentsUpload: data?.email?.documentsUpload ? true : false,
      },
      isPush,
      push: {
        newBid: data?.push?.newBid ? true : false,
        closingStatus: data?.push?.closingStatus ? true : false,
        documentsUpload: data?.push?.documentsUpload ? true : false,
      },
    });
  };

  const _onToggleNotification = ({ key, subKey, checked }) => {
    const newNotificationSettings = { ...notificationSettings };
    if (key === "isEmail" || key === "isPush") {
      newNotificationSettings[key] = checked;
      Object.keys(newNotificationSettings[subKey]).forEach((each) => {
        newNotificationSettings[subKey][each] = checked;
      });
    } else {
      newNotificationSettings[key][subKey] = checked;
    }
    setNotificationSettings(newNotificationSettings);
  };

  const _updateClientSettings = () => {
    updateClientSettings({ notificationSettings })
      .then(() => {
        showToast("Setting has been updated", "success");
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  const _saveClientNotificationSettings = () => {
    if (saveTimerRef?.current) clearTimeout(saveTimerRef.current);

    saveTimerRef.current = setTimeout(() => {
      _updateClientSettings();
    }, 1000);
  };

  useEffect(() => {
    _setNotificationSetting(clientSetting?.notificationSettings);
  }, [clientSetting?.notificationSettings]);

  useEffect(() => {
    if (activeTab === "2") _saveClientNotificationSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notificationSettings]);

  return (
    <>
      <Card className="settingCard">
        <CardHeader>
          <div>
            <CardTitle>Email Notifications</CardTitle>
            <p>Receive E-mails About</p>
          </div>
          <CustomInput
            type="switch"
            id="email"
            name="customSwitch"
            label=""
            key=""
            checked={notificationSettings.isEmail}
            onChange={(e) =>
              _onToggleNotification({
                key: "isEmail",
                subKey: "email",
                checked: e.target.checked,
              })
            }
          />
        </CardHeader>
        <CardBody>
          <ul className="profitLossReport">
            <li>
              Order Status Updated
              <CustomInput
                type="switch"
                id="2"
                name="customSwitch5"
                label=""
                checked={notificationSettings.email.closingStatus}
                onChange={(e) =>
                  _onToggleNotification({
                    key: "email",
                    subKey: "closingStatus",
                    checked: e.target.checked,
                  })
                }
                disabled={!notificationSettings.isEmail}
              />
            </li>
            <li>
              Invoice
              <CustomInput
                type="switch"
                id="3"
                name="customSwitch5"
                label=""
                checked={notificationSettings.email.agentInvoice}
                onChange={(e) =>
                  _onToggleNotification({
                    key: "email",
                    subKey: "agentInvoice",
                    checked: e.target.checked,
                  })
                }
                disabled={!notificationSettings.isEmail}
              />
            </li>

            <li>
              Bid Received
              <CustomInput
                type="switch"
                id="4"
                name="customSwitch5"
                label=""
                checked={notificationSettings.email.bidRevieved}
                onChange={(e) =>
                  _onToggleNotification({
                    key: "email",
                    subKey: "bidRevieved",
                    checked: e.target.checked,
                  })
                }
                disabled={!notificationSettings.isEmail}
              />
            </li>

            <li>
              Documents Uploaded
              <CustomInput
                type="switch"
                id="5"
                name="customSwitch5"
                label=""
                checked={notificationSettings.email.documentsUpload}
                onChange={(e) =>
                  _onToggleNotification({
                    key: "email",
                    subKey: "documentsUpload",
                    checked: e.target.checked,
                  })
                }
                disabled={!notificationSettings.isEmail}
              />
            </li>
          </ul>
        </CardBody>
      </Card>
      <Card className="settingCard">
        <CardHeader>
          <div>
            <CardTitle>Push Notifications</CardTitle>
            <p>Receive Website Alerts About</p>
          </div>
          <CustomInput
            type="switch"
            id="push"
            name="customSwitch"
            label=""
            key=""
            checked={notificationSettings.isPush}
            onChange={(e) =>
              _onToggleNotification({
                key: "isPush",
                subKey: "push",
                checked: e.target.checked,
              })
            }
          />
        </CardHeader>
        <CardBody>
          <ul className="profitLossReport">
            <li>
              Order Status Updated
              <CustomInput
                type="switch"
                id="6"
                name="customSwitch5"
                label=""
                checked={notificationSettings.push.closingStatus}
                onChange={(e) =>
                  _onToggleNotification({
                    key: "push",
                    subKey: "closingStatus",
                    checked: e.target.checked,
                  })
                }
                disabled={!notificationSettings.isPush}
              />
            </li>
            <li>
              Signed Documents Uploaded
              <CustomInput
                type="switch"
                id="7"
                name="customSwitch5"
                label=""
                checked={notificationSettings.push.documentsUpload}
                onChange={(e) =>
                  _onToggleNotification({
                    key: "push",
                    subKey: "documentsUpload",
                    checked: e.target.checked,
                  })
                }
                disabled={!notificationSettings.isPush}
              />
            </li>
            <li>
              New Bid Received
              <CustomInput
                type="switch"
                id="8"
                name="customSwitch5"
                label=""
                checked={notificationSettings.push.newBid}
                onChange={(e) =>
                  _onToggleNotification({
                    key: "push",
                    subKey: "newBid",
                    checked: e.target.checked,
                  })
                }
                disabled={!notificationSettings.isPush}
              />
            </li>
          </ul>
        </CardBody>
      </Card>
    </>
  );
};

export default ClientNotificationSettingTab;
