import { capitalize } from "@material-ui/core";
import React, { useState } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  FormGroup,
  Input,
  Button,
  Table,
} from "reactstrap";
import { formatCurrencyValue, formatDate } from "../../../helper-methods";
import CustomCard from "./CustomCard";

const ClientMakePaymentModal = ({
  isOpen,
  data,
  toggle,
  openAchModal,
  recordPayment,
}) => {
  const [note, setNote] = useState("");

  const _closeModal = () => {
    setNote("");
    toggle();
  };

  const _calculateCompanyTotalFee = () => {
    if (!data?.length) return 0;

    let arr = data.map((item) => item.signingCompanyFee || 0);

    return arr.reduce((a, b) => a + b, 0);
  };

  const _openAchModal = () => {
    openAchModal(note);
    _closeModal();
  };

  const _recordPayment = () => {
    recordPayment(note);
    _closeModal();
  };

  const _cardDataFormat = (row) => {
    console.log("qwerty ", row);
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>File #: </Label>

            <span
              style={{
                marginLeft: 5,
                fontWeight: 600,
              }}
            >
              {row?.fileNumber ? row?.fileNumber : "N/A"}
            </span>
          </div>

          <div className="dataWrap">
            <Label>Appointment Date: </Label>

            <span
              style={{
                marginLeft: 5,
                fontWeight: 600,
              }}
            >
              {row?.appointmentDate ? formatDate(row?.appointmentDate) : "N/A"}
            </span>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Fee: </Label>

            <span
              style={{
                marginLeft: 5,
                fontWeight: 600,
              }}
            >
              {row?.signingCompanyFee
                ? formatCurrencyValue(row?.signingCompanyFee)
                : "N/A"}
            </span>
          </div>

          <div className="dataWrap">
            <Label>Company: </Label>

            <span
              style={{
                marginLeft: 5,
                fontWeight: 600,
              }}
            >
              {row?._signingCompany?.companyName
                ? capitalize(row?._signingCompany.companyName)
                : "N/A"}
            </span>
          </div>
        </li>
      </ul>
    );
  };

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} scrollable centered>
      <ModalHeader toggle={() => _closeModal()}>Make Payment</ModalHeader>
      <ModalBody>
        <div className="hideDesktop">
          <CustomCard
            isPageStartFromOne={true}
            // pageNumber={tableConfig.pageNumber}
            tableData={data}
            // headerKeys={dataCardHeaderKeys}
            // bodyKeys={agentClosingCardBodyKeys}
            // dataFormat={this._dataFormat}
            totalCount={data?.length}
            onPaginate={(pageNumber, pageSize) =>
              this._paginate(pageNumber, pageSize)
            }
            rowSelection={false}
            // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
            // showTableLoading={loading.showTableLoading}
            // cardHeaderFormat={this._cardHeaderFormat}
            cardDataFormat={_cardDataFormat}
            isPagination={false}
          />
        </div>
        <div className={`CustomTableWrapper hideMobile`}>
          <Table responsive striped>
            <thead>
              <tr>
                <th>File #</th>
                <th>Appointment Date</th>
                <th>Fee</th>
                <th>Company</th>
              </tr>
            </thead>
            <tbody>
              {data?.length ? (
                data.map((each) => (
                  <tr key={each._id}>
                    <td>{each.fileNumber}</td>
                    <td>
                      {each.appointmentDate
                        ? formatDate(each.appointmentDate)
                        : "N/A"}
                    </td>
                    <td>
                      {each.signingCompanyFee
                        ? formatCurrencyValue(each.signingCompanyFee)
                        : "N/A"}
                    </td>
                    <td>
                      {each._signingCompany?.companyName
                        ? capitalize(each._signingCompany.companyName)
                        : "N/A"}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4">
                    <div className="nodata">
                      <img
                        src={require("../../../assets/img/noData.svg").default}
                        alt="no data"
                      />
                      <p>There is no data to display</p>
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        <FormGroup className="floatingLabel mt-3">
          <Input
            type="textarea"
            rows="4"
            placeholder=" "
            value={note}
            onChange={(e) => setNote(e.target.value)}
          />
          <Label>Notes</Label>
        </FormGroup>
        <p className="text-primary text-center" style={{ fontWeight: 600 }}>
          Total: {formatCurrencyValue(_calculateCompanyTotalFee())}
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" outline onClick={() => _recordPayment(note)}>
          Record Payment
        </Button>
        <Button color="primary" size="lg" onClick={() => _openAchModal(note)}>
          Pay ACH
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ClientMakePaymentModal;
