import React from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  CardHeader,
  CardTitle,
  Label,
} from "reactstrap";
import {
  clientResponseToConnectByCompany,
  // deleteRequestConnectWithCompany,
  getCompanyConnectByClient,
  requestCompanyConnectByClient,
  reRequestCompanyConnectByClient,
} from "../../../http/http-calls";
import {
  errorHandler,
  formatAddressInSingleText,
  formatPhoneNumber,
  capitalize,
  formatCurrencyValue,
  checkPermission,
} from "../../../helper-methods";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { DEFAULT_PROFILE_PICTURE } from "../../../config";
import CompanyNotes from "../components/CompanyNotes";
import CompanyDocuments from "../components/CompanyDocuments";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";

class SigningCompanyDetails extends React.Component {
  state = {
    company: null,
    loading: {
      requestLoading: false,
    },
  };

  _manageLoading = (key, value) => {
    const { loading } = this.state;
    loading[key] = value;
    this.setState({ loading });
  };

  _getCompanyConnectByClient = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { id } = this.props.match.params;

        const res = await getCompanyConnectByClient({ companyId: id });

        this.setState({ company: res?.company || null });

        resolve(res?.company || null);
      } catch (error) {
        reject(error);
      }
    });
  };

  componentDidMount = async () => {
    try {
      this.props.showLoader("Loading...");

      await this._getCompanyConnectByClient();

      this.props.hideLoader();
    } catch (error) {
      this.props.hideLoader();
      errorHandler(error);
      this.props.history.goBack();
    }
    HeaderEventEmitter.subscribe("back-button", () => {
      this.props.history.goBack();
    });
  };

  _requestCompanyConnectByClient = async (company) => {
    try {
      if (!company?._id) return;

      const companyId = company._id;

      this._manageLoading("requestLoading", true);

      if (company.connectStatus === "rejected") {
        await reRequestCompanyConnectByClient(companyId);
      } else {
        await requestCompanyConnectByClient({ companyId });
      }

      await this._getCompanyConnectByClient();

      this._manageLoading("requestLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("requestLoading", false);
    }
  };

  _clientResponseToConnectByCompany = async ({ id, status }) => {
    try {
      this._manageLoading("requestLoading", status);

      await clientResponseToConnectByCompany({ companyId: id, status });

      await this._getCompanyConnectByClient();

      this._manageLoading("requestLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("requestLoading", false);
    }
  };

  // _clientDeleteRequestConnectWithCompany = async () => {
  //   try {
  //     this._manageLoading("requestLoading", "Delete");

  //     await deleteRequestConnectWithCompany(this.state.company._id);

  //     await this._getCompanyConnectByClient();

  //     this._manageLoading("requestLoading", false);
  //   } catch (error) {
  //     errorHandler(error);
  //     this._manageLoading("requestLoading", false);
  //   }
  // };

  render() {
    const { company, loading } = this.state;

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <div className="d-flex align-items-center">
              <Button
                color="link"
                className="backBtn"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../../../assets/img/arrowLeft.svg").default}
                  alt="backbutton"
                  height={14}
                />
              </Button>
              <h2>Company Details</h2>
            </div>
          </div>
          <Row>
            <Col xl={3} lg={4}>
              <Card className="aboutCompany">
                <CardBody>
                  <div className="companyImage">
                    <img
                      src={
                        company?.logo ? company.logo : DEFAULT_PROFILE_PICTURE
                      }
                      alt="company Img"
                    />
                  </div>
                  <h5>
                    {company?.companyName
                      ? capitalize(company.companyName)
                      : "N/A"}
                  </h5>
                  <p>{formatAddressInSingleText(company?.address)}</p>
                  <div className="action">
                    {company?.connectStatus === "accepted" ? (
                      <Button color="success" outline>
                        Connected{" "}
                      </Button>
                    ) : null}
                    {company?.connectStatus === "pending" ||
                      company?.connectStatus === "rejected"
                      ? //  &&
                      // company?.connectResquestBy?.toLowerCase() === "client")
                      checkPermission("connectCompanies", "canUpdate") && (
                        <Button
                          color="primary"
                          disabled={loading.requestLoading}
                          onClick={() =>
                            this._requestCompanyConnectByClient(company)
                          }
                        >
                          {loading.requestLoading ? (
                            <i className="fa fa-spinner fa-spin mr-2" />
                          ) : null}{" "}
                          {company?.connectStatus === "rejected" &&
                            company?.connectResquestBy?.toLowerCase() ===
                            "client"
                            ? "Re-"
                            : null}
                          Request Connection
                        </Button>
                      )
                      : null}
                    {company?.connectStatus === "requested" ? (
                      company?.connectResquestBy?.toLowerCase() ===
                        "signingcompany" ? (
                        checkPermission("connectCompanies", "canUpdate") && (
                          <>
                            <Button
                              color="success"
                              outline
                              disabled={loading.requestLoading ? true : false}
                              onClick={() =>
                                this._clientResponseToConnectByCompany({
                                  id: company?._id,
                                  status: "accepted",
                                })
                              }
                            >
                              {loading.requestLoading === "accepted" ? (
                                <i className="fa fa-spinner fa-spin mr-2" />
                              ) : null}{" "}
                              Accept
                            </Button>
                            <Button
                              color="danger"
                              outline
                              disabled={loading.requestLoading ? true : false}
                              onClick={() =>
                                this._clientResponseToConnectByCompany({
                                  id: company?._id,
                                  status: "rejected",
                                })
                              }
                            >
                              {loading.requestLoading === "rejected" ? (
                                <i className="fa fa-spinner fa-spin mr-2" />
                              ) : null}{" "}
                              Reject
                            </Button>
                          </>
                        )
                      ) : (
                        <Button color="primary" outline>
                          Request Sent
                        </Button>
                      )
                    ) : null}
                  </div>
                </CardBody>
              </Card>
            </Col>
            <Col xl={9} lg={8}>
              <Card>
                <CardHeader>
                  <CardTitle>Contact</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xl={3} sm={6} className="mb-3 mb-xl-0">
                      <div className="clientContactInfo">
                        <h6>Scheduling</h6>
                        <div className="infoWrap">
                          <Label>Email</Label>
                          <p>{company?.schedulingEmail || "N/A"}</p>
                        </div>
                        <div className="infoWrap">
                          <Label>Work Phone</Label>
                          <p>
                            {company?.workPhone
                              ? formatPhoneNumber(company.workPhone)
                              : "N/A"}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col xl={3} sm={6} className="mb-3 mb-xl-0">
                      <div className="clientContactInfo">
                        <h6>Accounting</h6>
                        <div className="infoWrap">
                          <Label>Email</Label>
                          <p>{company?.accountingEmail || "N/A"}</p>
                        </div>
                        <div className="infoWrap">
                          <Label>Home Phone</Label>
                          <p>
                            {company?.homePhone
                              ? formatPhoneNumber(company.homePhone)
                              : "N/A"}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col xl={3} sm={6} className="mb-3 mb-xl-0">
                      <div className="clientContactInfo">
                        <h6>General</h6>
                        <div className="infoWrap">
                          <Label>Email</Label>
                          <p>{company?.email || "N/A"}</p>
                        </div>
                        <div className="infoWrap">
                          <Label>Phone</Label>
                          <p>
                            {company?.phone
                              ? formatPhoneNumber(company.phone)
                              : "N/A"}
                          </p>
                        </div>
                      </div>
                    </Col>
                    <Col xl={3} sm={6}>
                      <div className="clientContactInfo">
                        <h6>After Hours</h6>
                        <div className="infoWrap">
                          <Label>Name</Label>
                          <p>
                            {company?.emergencyContact?.name
                              ? capitalize(company?.emergencyContact.name)
                              : "N/A"}
                          </p>
                        </div>
                        <div className="infoWrap">
                          <Label>Phone</Label>
                          <p>
                            {company?.emergencyContact?.phone
                              ? formatPhoneNumber(
                                company?.emergencyContact.phone
                              )
                              : "N/A"}
                          </p>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>

              <Card>
                <CardHeader>
                  <CardTitle>Stats</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xl={3} xs={6} className="mb-4 mb-xl-0">
                      <div className="CompanyStats">
                        <div>
                          <h6>Jobs Completed</h6>
                          <p>{company?.jobsCompleted}</p>
                        </div>
                        <div className="icon">
                          <img
                            src={
                              require("../../../assets/img/JobCompleted.png")
                                .default
                            }
                            alt="JobCompleted"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={3} xs={6} className="mb-4 mb-xl-0">
                      <div className="CompanyStats">
                        <div>
                          <h6>Jobs Scheduled</h6>
                          <p>{company?.jobsScheduled}</p>
                        </div>
                        <div className="icon">
                          <img
                            src={
                              require("../../../assets/img/JobsScheduled.png")
                                .default
                            }
                            alt="JobCompleted"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={3} xs={6} className="mb-4 mb-xl-0">
                      <div className="CompanyStats">
                        <div>
                          <h6>Invoices Paid</h6>
                          <p>
                            {company?.invoicesPaid
                              ? formatCurrencyValue(company?.invoicesPaid)
                              : "-"}
                          </p>
                        </div>

                        <div className="icon">
                          <img
                            src={
                              require("../../../assets/img/TotalIncome.svg")
                                .default
                            }
                            alt="JobCompleted"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={3} xs={6} className="mb-4 mb-xl-0">
                      <div className="CompanyStats">
                        <div>
                          <h6>Invoices Pending</h6>
                          <p>
                            {company?.invoicesPending
                              ? formatCurrencyValue(company?.invoicesPending)
                              : "-"}
                          </p>
                        </div>
                        <div className="icon">
                          <img
                            src={
                              require("../../../assets/img/invoicepending.svg")
                                .default
                            }
                            alt="JobCompleted"
                          />
                        </div>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <CompanyDocuments data={company} />
              <CompanyNotes data={company} />
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SigningCompanyDetails);
