import React, { Component } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import {
  showToast,
  deepClone,
  openUrlOnNewTab,
  errorHandler,
} from "../../../helper-methods";
import { addUserBank, getUserKycLink } from "../../../http/http-calls";

class AddBankInfoModal extends Component {
  state = {
    formFields: {
      accountNumber: {
        value: "",
        isDirty: false,
        error: null,
      },
      confirmAccountNumber: {
        value: "",
        isDirty: false,
        error: null,
      },
      routingNumber: {
        value: "",
        isDirty: false,
        error: null,
      },
      confirmRoutingNumber: {
        value: "",
        isDirty: false,
        error: null,
      },
    },
    loading: false,
  };

  _resetStateModal = () => {
    this.setState({
      formFields: {
        accountNumber: {
          value: "",
          isDirty: false,
          error: null,
        },
        confirmAccountNumber: {
          value: "",
          isDirty: false,
          error: null,
        },
        routingNumber: {
          value: "",
          isDirty: false,
          error: null,
        },
        confirmRoutingNumber: {
          value: "",
          isDirty: false,
          error: null,
        },
      },
      loading: false,
    });
  };

  _onDismiss = () => {
    this._resetStateModal();
    this.props.toggle();
  };

  _hideLoader = () => {
    if (this.props.loadingModal) {
      this.props.manageLoading(false);
    }
    this.setState({ loading: false });
  };

  _validateForm = () => {
    return new Promise((resolve) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((fieldName) => {
        switch (fieldName) {
          case "accountNumber": {
            if (formFields.accountNumber.value?.trim().length) {
              formFields.accountNumber.isDirty = false;
              formFields.accountNumber.error = null;
            } else {
              formFields.accountNumber.error = "*Provide valid account number";
              isFormValid = false;
            }
            break;
          }
          case "confirmAccountNumber": {
            if (formFields.confirmAccountNumber.value?.trim().length) {
              if (formFields.accountNumber.value?.trim().length) {
                if (
                  formFields.accountNumber.value.trim() ===
                  formFields.confirmAccountNumber.value.trim()
                ) {
                  formFields.confirmAccountNumber.isDirty = false;
                  formFields.confirmAccountNumber.error = null;
                } else {
                  formFields.confirmAccountNumber.error =
                    "*Account number doesn't match";
                  isFormValid = false;
                }
              } else {
                formFields.confirmAccountNumber.isDirty = false;
                formFields.confirmAccountNumber.error = null;
              }
            } else {
              formFields.confirmAccountNumber.error =
                "*Re-enter account number";
              isFormValid = false;
            }
            break;
          }
          case "routingNumber": {
            if (formFields.routingNumber.value?.trim().length) {
              formFields.routingNumber.isDirty = false;
              formFields.routingNumber.error = null;
            } else {
              formFields.routingNumber.error = "*Provide valid routing number";
              isFormValid = false;
            }
            break;
          }
          case "confirmRoutingNumber": {
            if (formFields.confirmRoutingNumber.value?.trim().length) {
              if (formFields.routingNumber.value?.trim().length) {
                if (
                  formFields.routingNumber.value.trim() ===
                  formFields.confirmRoutingNumber.value.trim()
                ) {
                  formFields.confirmRoutingNumber.isDirty = false;
                  formFields.confirmRoutingNumber.error = null;
                } else {
                  formFields.confirmRoutingNumber.error =
                    "*Routing number doesn't match";
                  isFormValid = false;
                }
              } else {
                formFields.confirmRoutingNumber.isDirty = false;
                formFields.confirmRoutingNumber.error = null;
              }
            } else {
              formFields.confirmRoutingNumber.error =
                "*Re-enter routing number";
              isFormValid = false;
            }
            break;
          }
          default:
        }
      });
      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      this._validateForm();
    });
  };

  _makeAllFieldDirty = () => {
    return new Promise((resolve) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((fieldName) => {
        formFields[fieldName].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _updateBankInfo = async () => {
    try {
      await this._makeAllFieldDirty();
      const isFormValid = await this._validateForm();

      if (isFormValid) {
        this.setState({ loading: true });

        const { formFields } = deepClone(this.state);

        await addUserBank({
          routingNo: formFields.routingNumber.value.trim(),
          accountNo: formFields.accountNumber.value.trim(),
        });

        if (
          !this.props?.agentDetails?.kyc?.paymentEnabled &&
          !this.props?.agentDetails?.kyc?.payoutEnabled
        ) {
          this._getUserKycLink();
        }
        await this.props.getAgentProfileDetail();

        showToast("Bank info updated", "success");

        this._onDismiss();
      }
    } catch (error) {
      this.setState({ loading: false });
      errorHandler(error);
    }
  };

  _getUserKycLink = () => {
    getUserKycLink()
      .then((response) => {
        if (response?.accountLink?.url) {
          openUrlOnNewTab(response.accountLink.url);
        } else {
          showToast("Server error. Try again after sometime.", "error");
        }
        this._hideLoader();
      })
      .catch((error) => {
        errorHandler(error);
        this._hideLoader();
      });
  };

  render() {
    const { isOpen, agentDetails } = this.props;

    const { formFields, loading } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        toggle={() => this._onDismiss()}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={() => this._onDismiss()}>
          {agentDetails?.hasBank ? "Update" : "Add"} Bank Info
        </ModalHeader>
        <ModalBody>
          <FormGroup className="floatingLabel">
            <Input
              type="text"
              autoComplete={"off"}
              placeholder=" "
              value={formFields.accountNumber.value}
              onChange={(e) =>
                this._updateFieldValue("accountNumber", e.target.value)
              }
            />
            <Label>Account Number</Label>

            {formFields.accountNumber.error ? (
              <p className="validation-error">
                {formFields.accountNumber.error}
              </p>
            ) : null}
          </FormGroup>
          <FormGroup className="floatingLabel">
            <Input
              type="text"
              autoComplete={"off"}
              placeholder=" "
              value={formFields.confirmAccountNumber.value}
              onChange={(e) =>
                this._updateFieldValue("confirmAccountNumber", e.target.value)
              }
            />
            <Label>Confirm Account Number</Label>
            {formFields.confirmAccountNumber.error ? (
              <p className="validation-error">
                {formFields.confirmAccountNumber.error}
              </p>
            ) : null}
          </FormGroup>
          <FormGroup className="floatingLabel">
            <Input
              type="text"
              autoComplete={"off"}
              placeholder=" "
              value={formFields.routingNumber.value}
              onChange={(e) =>
                this._updateFieldValue("routingNumber", e.target.value)
              }
            />
            <Label>Routing Number</Label>
            {formFields.routingNumber.error ? (
              <p className="validation-error">
                {formFields.routingNumber.error}
              </p>
            ) : null}
          </FormGroup>
          <FormGroup className="floatingLabel">
            <Input
              type="text"
              autoComplete={"off"}
              placeholder=" "
              value={formFields.confirmRoutingNumber.value}
              onChange={(e) =>
                this._updateFieldValue("confirmRoutingNumber", e.target.value)
              }
            />
            <Label>Confirm Routing Number</Label>
            {formFields.confirmRoutingNumber.error ? (
              <p className="validation-error">
                {formFields.confirmRoutingNumber.error}
              </p>
            ) : null}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            size="lg"
            outline
            onClick={() => this._onDismiss()}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            disabled={loading}
            onClick={() => this._updateBankInfo()}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null} Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddBankInfoModal;
