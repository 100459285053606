import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalHeader,
  Button,
  Card,
  CardBody,
} from "reactstrap";
import moment from "moment";
import {
  upgradeSigningCompanySubscription,
  buySigningCompanySubscription,
  renewSigningCompanySubscription,
  downgradeSigningCompanySubscription,
} from "../../../http/http-calls";
import {
  showToast,
  deepClone,
  formatDate,
  formatCurrencyValue,
  errorHandler,
} from "../../../helper-methods";
import Payment from "../components/payment";

class BuySubscriptionModal extends Component {
  state = {
    detail: {},
    loading: false,
  };

  _closeModal = (action) => {
    console.log("in _closeModal");
    if (!this.state.loading) {
      this.props.toggle();
    }
  };

  _buySubscription = async (payload) => {
    try {
      const { data } = this.props;
      let successText = "Subscription Purchased";
      switch (data?.type) {
        case "buy": {
          await buySigningCompanySubscription(payload);
          break;
        }
        case "renew": {
          await renewSigningCompanySubscription(payload);
          successText = "Subscription Renewed";
          break;
        }
        case "upgrade": {
          await upgradeSigningCompanySubscription(payload);
          successText = "Subscription Upgraded";
          break;
        }
        case "downgrade": {
          delete payload.billingCycle;
          await downgradeSigningCompanySubscription(payload);
          successText = "Subscription Downgraded";
          break;
        }
        default:
      }
      await this.props.reload();

      showToast(successText, "success");
      this.setState({ loading: false }, () => {
        if (this.props.data.type === "buy") {
          this.props.redirect();
        }
        this._closeModal();
      });
    } catch (error) {
      errorHandler(error);
      this.setState({ loading: false });
    }
  };

  _onBuySubscription = () => {
    this.setState({ loading: true });
    const { data, billingCycle } = this.props;

    const payload = {
      billingCycle:
        data.plan === "Basic"
          ? billingCycle.Basic === false
            ? 30
            : 365
          : data.plan === "Premium"
          ? billingCycle.Premium === false
            ? 30
            : 365
          : 30,
      plan: data.plan,
    };

    this._buySubscription(payload);
  };

  _fetchCompanyAmount = () => {
    const { isTitleCompany, data } = this.props;
    const plan = data?.plan;
    const subscriptionPlan = isTitleCompany
      ? this.props.titleCompanySubsPlan
      : this.props.companySubscriptionPlan;

    return subscriptionPlan?.find((item) => item.plan === plan)?.amount;
  };

  render() {
    const { loading } = deepClone(this.state);
    const { data, billingCycle, subscriptionPlan } = deepClone(this.props);
    console.log("stateprops", this.props, this.state);
    return (
      <Modal
        size="lg"
        isOpen={this.props.isOpen}
        toggle={this._closeModal}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={this._closeModal}>
          {/* {data?.plan === "Free"
            ? "Pay As You Grow"
            : data?.plan === "Basic"
            ? "Professional"
            : "Advanced"}{" "} */}
          {data?.type?.charAt(0).toUpperCase() + data?.type?.slice(1)}{" "}
          Subscription
        </ModalHeader>
        <ModalBody>
          <Card className="selectedPlan">
            <CardBody>
              <h6>
                Plan Selected <br />
                <span>
                  {this._fetchCompanyAmount()}
                  {/* {this.props.data?.plan === "Basic"
                    ? formatCurrencyValue(subscriptionPlan?.basicPlan?.amount)
                    : this.props.data?.plan === "Premium"
                    ? formatCurrencyValue(subscriptionPlan?.premiumPlan?.amount)
                    : formatCurrencyValue(subscriptionPlan?.freePlan?.amount)} */}
                </span>
                /month
              </h6>

              <div>
                Next Billing On:{" "}
                <span>
                  {formatDate(
                    moment().add(
                      data?.plan === "Basic"
                        ? billingCycle.Basic === false
                          ? 30
                          : 365
                        : data?.plan === "Premium"
                        ? billingCycle.Premium === false
                          ? 30
                          : 365
                        : 30,
                      "days"
                    )
                  )}
                </span>
              </div>
            </CardBody>
          </Card>

          {data?.type === "downgrade" ? (
            <div className="text-center mt-3">
              <Button
                color="primary"
                outline
                className="mr-3"
                onClick={() => this._closeModal()}
              >
                Cancel
              </Button>
              <Button
                outline
                color="primary"
                disabled={loading ? true : false}
                onClick={() => this._onBuySubscription()}
              >
                {data?.type}
              </Button>
            </div>
          ) : (
            <Payment
              loading={loading}
              manageLoading={(value) => this.setState({ loading: value })}
              cancelButton={true}
              onCancelButton={this._closeModal}
              submitButtonText={data?.type || ""}
              onPaymentConfirmation={() => this._onBuySubscription()}
            />
          )}
        </ModalBody>

        {loading && (
          <div className="table-overlay">
            <div>
              <i className="fa fa-spinner fa-spin" />
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

export default BuySubscriptionModal;
