import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Col,
  CustomInput,
  Label,
  Row,
} from "reactstrap";
import WhiteLabelUIColor from "../../components/Modals/Company/WhiteLabelUIColor";
import { updateSigningCompanyProfile } from "../../../../http/http-calls";
import {
  convertHexToRgbColor,
  errorHandler,
  setThemeColor,
  showToast,
} from "../../../../helper-methods";
import { useDispatch, useSelector } from "react-redux";
import { updateUserObj } from "../../../../redux/actions/user-data";

const colorsUI = {
  demoUI1: {
    primary: "#4c96ce", // default color
    secondary: "#16346c",
    tertiary: "#0f2550",
    secondaryText: "#ffffff",
    activeText: "#fff",
  },
  demoUI2: {
    primary: "#4d648d",
    secondary: "#0f1c2e",
    tertiary: "#172c48",
    secondaryText: "#ffffff",
    activeText: "#fff",
  },
  demoUI3: {
    primary: "#c53628",
    secondary: "#062536",
    tertiary: "#093953",
    secondaryText: "#ffffff",
    activeText: "#fff",
  },
  demoUI4: {
    primary: "#ffc700",
    secondary: "#115080",
    tertiary: "#0d3e63",
    secondaryText: "#ffffff",
    activeText: "#fff",
  },
};

const WhiteLabelUI = (props) => {
  const reduxState = useSelector((state) => state?.userData);
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [isDefaultUiSelected, setIsDefaultUiSelected] = useState(true);
  const [currentUI, setCurrentUI] = useState("demoUI1");
  const [currentColorUI, setCurrentColorUI] = useState({
    primary: "#4c96ce", // default color
    secondary: "#16346c",
    secondaryText: "#ffffff",
    tertiary: "#0f2550",
    activeText: "#fff",
  });
  const [whiteLabelUIColor, setWhiteLabelUIColor] = useState({ isOpen: false });

  const _toggleWhiteLabelUIColor = (isOpen = false) => {
    setWhiteLabelUIColor({ isOpen });
  };

  const _getBrightness = (hexColor) => {
    // Convert hex color to RGB
    var r = parseInt(hexColor.slice(1, 3), 16);
    var g = parseInt(hexColor.slice(3, 5), 16);
    var b = parseInt(hexColor.slice(5, 7), 16);

    // Calculate brightness using the formula
    return (r * 299 + g * 587 + b * 114) / 1000;
  };

  const _toggleThemeName = (themeName = "demoUI1") => {
    try {
      setCurrentUI(themeName);

      setThemeColor(colorsUI[themeName]);
      setCurrentColorUI(colorsUI[themeName]);
    } catch (error) {
      errorHandler(error);
    }
  };

  const _onSaveWhiteLabelUIColor = (data) => {
    // console.log({ data });
    setIsDefaultUiSelected(false);
    setThemeColor(data);
    setCurrentColorUI(data);
  };

  const _onSave = async (data) => {
    try {
      let demopayload = {
        whiteLabelSetting: {
          isDefaultUiSelected,
          // customizedUiSetting: {
          //   // primary: currentUI,
          //   primary: currentColorUI.primary,
          //   secondary: currentColorUI.secondary,
          //   tertiary:  currentColorUI.tertiary,
          //   // forButtons: currentColorUI.tertiary,
          // },
        },
      };

      if (!isDefaultUiSelected) {
        // Use customizedUiSetting
        demopayload.whiteLabelSetting.customizedUiSetting = {
          primary: currentColorUI.primary,
          secondary: currentColorUI.secondary,
          tertiary: currentColorUI.tertiary,
          secondaryText: currentColorUI.secondaryText,
        };
      } else {
        // Use defaultUiSetting
        demopayload.whiteLabelSetting.defaultUiSetting = {
          primary: currentColorUI.primary,
          secondary: currentColorUI.secondary,
          tertiary: currentColorUI.tertiary,
          secondaryText: currentColorUI.secondaryText,
          activeText: currentColorUI.activeText,
        };
      }

      setLoading(true);

      let payload = data.whiteLabelSetting ? data : demopayload;

      await updateSigningCompanyProfile(reduxState.user.id, payload);

      showToast("WhiteLabel has been updated", "success");

      // const updatedObject = {
      //   siteOwnerDetail: {
      //     ...reduxState.user.siteOwnerDetail,
      //     whiteLabelSetting: {
      //       ...reduxState.user.siteOwnerDetail.whiteLabelSetting,
      //       customizedUiSetting: {
      //         ...reduxState.user.siteOwnerDetail.whiteLabelSetting
      //           .customizedUiSetting,
      //         primary: currentUI,
      //       },
      //     },
      //   },
      // };
      console.log("aqw", isDefaultUiSelected);
      if (!isDefaultUiSelected) {
        // Use customizedUiSetting

        const updatedObject = {
          // siteOwnerDetail: {
          // ...reduxState.user,
          whiteLabelSetting: {
            ...reduxState.user.whiteLabelSetting,
            isDefaultUiSelected,
            customizedUiSetting: {
              primary: currentColorUI.primary,
              secondary: currentColorUI.secondary,
              tertiary: currentColorUI.tertiary,
              secondaryText: currentColorUI.secondaryText,
            },
            // },
          },
        };
        dispatch(updateUserObj(updatedObject));
      } else {
        // Use defaultUiSetting

        const updatedObject = {
          // siteOwnerDetail: {
          // ...reduxState.user,
          whiteLabelSetting: {
            ...reduxState.user.whiteLabelSetting,
            isDefaultUiSelected,
            defaultUiSetting: {
              primary: currentColorUI.primary,
              secondary: currentColorUI.secondary,
              tertiary: currentColorUI.tertiary,
              secondaryText: currentColorUI.secondaryText,
              activeText: currentColorUI.activeText,
            },
            // },
          },
        };

        dispatch(updateUserObj(updatedObject));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  useEffect(() => {
    console.log("cui", reduxState.user?.whiteLabelSetting);
    if (!reduxState.user?.whiteLabelSetting?.isDefaultUiSelected) {
      console.log("tyujrhertcetgernvtejrdc");

      let whiteLabelSetting =
        reduxState.user?.whiteLabelSetting?.customizedUiSetting;
      console.log("qwed", whiteLabelSetting);
      setCurrentUI("");
      setCurrentColorUI(whiteLabelSetting);
      setThemeColor(whiteLabelSetting);
    } else {
      let whiteLabelSetting =
        reduxState.user?.whiteLabelSetting?.defaultUiSetting;

      let matchingColorScheme = findMatchingColorScheme(
        whiteLabelSetting,
        colorsUI
      );
      console.log("qasw", matchingColorScheme);
      setCurrentUI(matchingColorScheme);
      setCurrentColorUI(whiteLabelSetting);
      _toggleThemeName(matchingColorScheme);
    }

    return () => {
      // if (!isDefaultUiSelected) {
      if (!reduxState.user?.whiteLabelSetting?.isDefaultUiSelected) {
        let whiteLabelSetting =
          reduxState.user?.whiteLabelSetting?.customizedUiSetting;

        setThemeColor(whiteLabelSetting);
      } else {
        let whiteLabelSetting =
          reduxState.user?.whiteLabelSetting?.defaultUiSetting;
        console.log("ftn", reduxState.user);
        let matchingColorScheme = findMatchingColorScheme(
          whiteLabelSetting,
          colorsUI
        );

        _toggleThemeName(matchingColorScheme);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reduxState]);

  const findMatchingColorScheme = (targetObject, colorsUI) => {
    console.log("wer", targetObject, colorsUI);

    let foundKey = null;

    for (const key in colorsUI) {
      if (JSON.stringify(colorsUI[key]) === JSON.stringify(targetObject)) {
        foundKey = key;
        break;
      }
    }
    return foundKey;
  };

  const _nameOfDefaultUI = (name) => {
    switch (name) {
      case "demoUI1":
        return "Default";

      case "demoUI2":
        return "Charcoal gray";

      case "demoUI3":
        return "Dark blue";

      case "demoUI4":
        return "Olive green";

      default:
        break;
    }
  };

  return (
    <>
      <div className="CardWrapper">
        {console.log("qwer", isDefaultUiSelected, currentUI)}
        <Card>
          <CardHeader>
            <CardTitle>UI Settings</CardTitle>
          </CardHeader>
          <CardBody>
            {/* <p>Customize your UI</p> */}
            {
              <p>
                UI Color Type :{" "}
                {reduxState.user?.whiteLabelSetting?.isDefaultUiSelected
                  ? _nameOfDefaultUI(
                      findMatchingColorScheme(
                        reduxState.user?.whiteLabelSetting?.defaultUiSetting,
                        colorsUI
                      )
                    )
                  : "Custom UI Colour"}
              </p>
            }
            <Row>
              <Col xl={6} lg={12} className="mb-4">
                <div className="uiDesign">
                  <h1>
                    <CustomInput
                      type="radio"
                      id="demo1"
                      name="customRadio"
                      checked={currentUI === "demoUI1"}
                      onClick={() => _toggleThemeName("demoUI1")}
                    />
                    Default
                  </h1>
                  <Label for="demo1">
                    <img
                      src={
                        require("../../../../assets/img/OrderDashboard1.png")
                          .default
                      }
                      alt="background"
                    />
                  </Label>
                </div>
              </Col>
              <Col xl={6} lg={12} className="mb-4">
                <div className="uiDesign">
                  <h1>
                    <CustomInput
                      type="radio"
                      id="demo2"
                      name="customRadio"
                      checked={currentUI === "demoUI2"}
                      onClick={() => _toggleThemeName("demoUI2")}
                    />
                    Charcoal gray
                  </h1>
                  <Label for="demo2">
                    <img
                      src={
                        require("../../../../assets/img/OrderDashboard2.png")
                          .default
                      }
                      alt="background"
                    />
                  </Label>
                </div>
              </Col>
              <Col xl={6} lg={12} className="mb-4">
                <div className="uiDesign">
                  <h1>
                    <CustomInput
                      type="radio"
                      id="demo3"
                      name="customRadio"
                      checked={currentUI === "demoUI3"}
                      onClick={() => _toggleThemeName("demoUI3")}
                    />
                    Dark blue
                  </h1>
                  <Label for="demo3">
                    <img
                      src={
                        require("../../../../assets/img/OrderDashboard3.png")
                          .default
                      }
                      alt="background"
                    />
                  </Label>
                </div>
              </Col>
              <Col xl={6} lg={12} className="mb-4">
                <div className="uiDesign">
                  <h1>
                    <CustomInput
                      type="radio"
                      id="demo4"
                      name="customRadio"
                      checked={currentUI === "demoUI4"}
                      onClick={() => _toggleThemeName("demoUI4")}
                    />
                    Olive green
                  </h1>
                  <Label for="demo4">
                    <img
                      src={
                        require("../../../../assets/img/OrderDashboard4.png")
                          .default
                      }
                      alt="background"
                    />
                  </Label>
                </div>
              </Col>
              <Col
                md={12}
                className="d-flex align-items-center justify-content-center"
              >
                <Button
                  color="primary"
                  siz="md"
                  className="mr-4"
                  onClick={() => _toggleWhiteLabelUIColor(true)}
                >
                  Customize
                </Button>
                <Button color="primary" disabled={loading} onClick={_onSave}>
                  {loading && <i className="fa fa-spinner fa-spin" />} Save
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>

      <WhiteLabelUIColor
        currentColorUI={currentColorUI}
        isOpen={whiteLabelUIColor.isOpen}
        toggle={() => _toggleWhiteLabelUIColor()}
        onSave={_onSaveWhiteLabelUIColor}
        customizedUIData={
          reduxState.user?.whiteLabelSetting?.customizedUiSetting
        }
      />
    </>
  );
};

export default WhiteLabelUI;
