import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const UIColorChangePreview = ({ isOpen1, toggle, onSaveData }) => {
  return (
    <>
      <Modal isOpen={isOpen1} toggle={toggle} centered size="xl">
        <ModalHeader toggle={toggle}>Select UI Color</ModalHeader>
        <ModalBody>
          <img
            src={require("../../../../assets/img/background_image.jpg").default}
            style={{ width: "100%" }}
            alt="preview"
          />
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={toggle}>
            Cancel
          </Button>
          <Button color="primary" onClick={onSaveData}>
            Save
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default UIColorChangePreview;
