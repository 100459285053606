export const footer = `<!DOCTYPE html>
<html lang="en">

<head>
    <meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link rel="preconnect" href="https://fonts.googleapis.com" />
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
    <link href="https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;600;700&display=swap" rel="stylesheet" />

    <title>Agent Registration</title>
    <style>
        body {
            margin: 0;
            padding: 0;
            font-family: "Nunito", sans-serif;
            font-weight: 400;
            max-width: 680px;
            background-color: #f5f5f5;
        }

        p {
            font-size: 14px;
        }

        .table {
            max-width: 650px;
            margin: auto;
            background-color: #e1c8fe;
            border: 1px solid #8148cc48;
        }

        .socialList {
            margin: 0;
            padding: 0;
            clear: both;
            margin-top: 2px;
        }

        .socialList li {
            display: inline-block;
            margin-right: 10px;
            margin-left: 0px;
        }

        .socialList li a {
            width: 30px;
            height: 30px;
            display: block;
        }

        .socialList li a img {
            width: 100%;
            height: 100%;
        }
    </style>
</head>

<body class="body" style="
      padding: 0 !important;
      margin: 0 !important;
      display: block !important;
      min-width: 100% !important;
      width: 100% !important;
      -webkit-text-size-adjust: none;
    ">
    <table style="
        padding: 15px 8px;
        color: #414141;
        background-color: #f5f5f5;
        border-radius: 10px;" cellpadding="0" cellspacing="0" height="100%" width="100%">
        <tbody>
            <tr>
                <td align="center" valign="top">
                    <table style="
                width: 100%;
                max-width: 500px;
                border-radius: 10px;
                border-spacing: 0px;
                display: block;
                padding:15px 10px 0px;
                overflow: hidden;
                background-color: #ffffff;
              " cellpadding="0" cellspacing="0" width="100%">                      
                    </table>
                    <table style="
                    width: 100%;
                    max-width: 500px;
                    border-radius: 10px;
                    border-spacing: 0px;
                    display: block;
                    padding:10px 10px 0px;
                    overflow: hidden;
                    /* background-color: #ffffff; */
                  " cellpadding="0" cellspacing="0" width="100%">
                  <tr>
                    <td colspan="2" align="center" style="padding-top: 10px; padding-bottom: 0px;">
                        <p style="margin-bottom: 0; margin-top: 0; font-size: 13px;">CloseWise | 501 W Broadway San Diego,
                            CA 92101 | (619)800-4426</p>
                            <p style="margin-bottom: 0; margin-top: 0; font-size: 13px;">http://CloseWise.com</p>
                        <p style="margin-top: 10px; font-size: 13px;"> Copyright © 2023 CloseWise, LLC. All
                            rights reserved.</p>
                    </td>
                </tr>
                <tr>
                    <td colspan="2">
                        
                            <p
                                style="margin-top: 0px; margin-bottom: 0; font-size: 12px; line-height: 1.5; color: gray;">
                                Confidentiality Notice: This email and any attachments may contain
                                confidential and proprietary information. If you are not the intended
                                recipient, you are hereby notified that any use, dissemination,
                                distribution, or copying of this email and its attachments is strictly
                                prohibited. If you have received this email in error, please delete the
                                message and its attachments and notify us immediately.</p>
                            <p
                            style="margin-top: 0px; margin-bottom: 0; font-size: 12px; line-height: 1.5; color: gray;">
                                This email is intended only for the use of the person to whom it is
                                addressed and may contain information that is privileged, confidential, and
                                exempt from disclosure under applicable law. Any unauthorized review, use,
                                disclosure or distribution is strictly prohibited. If you have received this
                                email in error, please immediately delete this email and notify the sender.
                            </p>
                    </td>
                </tr>
                </table>
                </td>
            </tr>
        </tbody>
    </table>
</body>

</html>`


