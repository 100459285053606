import React, { useEffect, useState } from "react";
import { Button, FormGroup, Input, Label, Table } from "reactstrap";
import { errorHandler, formatCurrencyValue } from "../../../../helper-methods";
import {
  getAvailableDomainsList,
  saveDomainDetailForAgentWebsite,
  verifyAgentWebsiteDomain,
} from "../../../../http/http-calls";
import TablePagination from "../TablePagination";
import { RegexConfig } from "../../../../config/RegexConfig";

const BuyNewDomain = ({
  toggle,
  agentWebsiteDetails,
  getAgentWebsiteDetails,
}) => {
  const [domainNameInp, setDomainNameInp] = useState("");
  const [filterVal, setFilterVal] = useState("");
  const [availableDomainsList, setAvailableDomainsList] = useState([]);
  const [availableDomainsListOriginal, setAvailableDomainsListOriginal] =
    useState([]);
  const [exactMatchDomainsList, setExactMatchDomainsList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableConfig, setTableConfig] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const _getAvailableDomainsList = async () => {
    try {
      if (!domainNameInp) {
        errorHandler("Please enter domain name");
        return;
      }

      setLoading(true);
      setTableConfig({
        pageNumber: 1,
        pageSize: 10,
      });
      setFilterVal("");

      const payload = {
        domainName: domainNameInp,
      };

      const res = await getAvailableDomainsList(payload);
      console.log(res);

      setAvailableDomainsList([
        ...res?.domainList?.exactMatch,
        ...res?.domainList?.suggestDomain,
      ]);
      setAvailableDomainsListOriginal([
        ...res?.domainList?.exactMatch,
        ...res?.domainList?.suggestDomain,
      ]);

      setExactMatchDomainsList(res?.domainList?.exactMatch);
      setLoading(false);
    } catch (err) {
      if (err?.reason || err?.message) {
        errorHandler(err);
      } else {
        errorHandler({
          reason: "Something went wrong! Please try another domain name",
        });
      }
      setAvailableDomainsList([]);
      setAvailableDomainsListOriginal([]);
      setLoading(false);
    }
  };

  const _handleOnChange = (value) => {
    setDomainNameInp(value);
  };

  const _handleOnFilterChange = (value) => {
    setFilterVal(value);
    setTableConfig({
      pageNumber: 1,
      pageSize: 10,
    });
  };

  const _toggleDomainCheckoutModal = (domainDetail) => {
    const data = {
      domainName: domainDetail?.DomainName,
      amount: domainDetail?.Price?.Price,
      currency: domainDetail?.Price?.Currency,
    };

    toggle(true, data);
  };

  const _onChangePagination = (pageNumber, pageSize) => {
    console.log({ pageNumber });
    const newTableConfig = { ...tableConfig };
    newTableConfig["pageNumber"] = pageNumber;

    setTableConfig(newTableConfig);
  };

  const _handleEnterPressed = (e) => {
    if (e.charCode === 13) {
      _getAvailableDomainsList();
    }
  };

  const _onSaveDomainDetails = async () => {
    try {
      setLoading(true);
      const payload = {
        domainName: agentWebsiteDetails?.domainName,
        // domainName: "testing.closewise-surge.click",
        domainProvider: agentWebsiteDetails?.domainProvider,
        isAutoGeneratedAwsRoute53: true,
      };

      await saveDomainDetailForAgentWebsite(payload);

      setLoading(false);
      getAgentWebsiteDetails();
    } catch (err) {
      setLoading(false);
      errorHandler(err);
    }
  };

  const _onVerifyEmail = async () => {
    try {
      await verifyAgentWebsiteDomain();

      await getAgentWebsiteDetails();
    } catch (err) {
      errorHandler({ reason: "Email is not verified" });
    }
  };

  useEffect(() => {
    if (filterVal === "") {
      setAvailableDomainsList(availableDomainsListOriginal);
      return;
    }

    const filterValArr = filterVal.split(",");

    const newAvailaibleDomainsList = availableDomainsListOriginal?.filter(
      (each) =>
        each?.Price?.Price >= Number(filterValArr[0]) &&
        each?.Price?.Price <= Number(filterValArr[1])
    );

    setAvailableDomainsList(newAvailaibleDomainsList);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterVal]);

  return (
    <>
      <div className="BuyAdomain">
        <div className="searchFiled">
          <div className="floatingLabel w-100">
            <Input
              placeholder=" "
              value={
                agentWebsiteDetails?.domainName?.length
                  ? agentWebsiteDetails?.domainName
                  : domainNameInp
              }
              onChange={(e) => _handleOnChange(e.target.value)}
              onKeyPress={(e) => _handleEnterPressed(e)}
              disabled={loading || agentWebsiteDetails?.domainName?.length}
            />
            <Label>
              {agentWebsiteDetails?.domainName?.length
                ? "Domain Name"
                : "Enter domain name or keyword"}
            </Label>
          </div>
          {!agentWebsiteDetails?.domainName?.length ? (
            <Button
              color="primary"
              outline
              onClick={() => _getAvailableDomainsList()}
              disabled={loading || agentWebsiteDetails?.domainName?.length}
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
              Search
            </Button>
          ) : /* <Button
              color="primary"
              outline
              // onClick={() => _onSaveDomainDetails()}
              onClick={() => _onVerifyEmail()}
              disabled={
                loading ||
                agentWebsiteDetails?.websiteDeployementProcessStatus !==
                  "Pending"
              }
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
              Email Verify
            </Button> */
          null}
        </div>

        {/* {agentWebsiteDetails?.domainName?.length &&
        !agentWebsiteDetails?.isEmailVerified ? (
          <div className="d-flex justify-content-center my-2">
            <Button
              color="primary"
              outline
              // onClick={() => _onSaveDomainDetails()}
              onClick={() => _onVerifyEmail()}
              disabled={
                loading ||
                agentWebsiteDetails?.websiteDeployementProcessStatus !==
                  "Pending"
              }
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
              Email Verify
            </Button>
          </div>
        ) : null} */}

        <div>
          {agentWebsiteDetails?.domainName?.length &&
          !agentWebsiteDetails?.isEmailVerified ? (
            <div className="text-center mb-2">
              <Button
                color="primary"
                outline
                // onClick={() => _onSaveDomainDetails()}
                onClick={() => _onVerifyEmail()}
                disabled={
                  loading ||
                  agentWebsiteDetails?.websiteDeployementProcessStatus !==
                    "Pending"
                }
              >
                Email Verified
              </Button>

              <p className="text-danger">
                *Please verify your domain from the email sent to your
                registered email address.
              </p>
            </div>
          ) : null}
        </div>

        {!agentWebsiteDetails?.domainName?.length ? (
          /* || !agentWebsiteDetails?.isAutoGeneratedAwsRoute53  */
          <div className="filterContainer">
            <div className="filterIcon">
              <img
                src={require("../../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
              />
              Filter by
            </div>
            <div className="filterWrapper">
              <FormGroup>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="status"
                    disabled={
                      loading ||
                      (availableDomainsListOriginal?.length ? false : true)
                    }
                    value={filterVal}
                    onChange={(e) => _handleOnFilterChange(e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="0,20">0 - $20</option>
                    <option value="21,40">$21 - $40</option>
                    <option value="41,60">$41 - $60</option>
                    <option value="61,80">$61 - $80</option>
                    <option value="81,100">$81 - $100</option>
                  </Input>
                </div>
              </FormGroup>
            </div>
          </div>
        ) : null}

        {!agentWebsiteDetails?.domainName?.length ? (
          /* || !agentWebsiteDetails?.isAutoGeneratedAwsRoute53 */
          <div className="CustomTableWrapper">
            <Table>
              <thead>
                <tr>
                  <th>Domain Name</th>
                  <th>Service Provider</th>
                  <th>Price</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <tr>
                    <td colSpan={4}>
                      <i className="fa fa-spinner fa-spin mr-1" />
                    </td>
                  </tr>
                ) : availableDomainsList?.length ? (
                  availableDomainsList
                    ?.slice(
                      (tableConfig?.pageNumber - 1) * tableConfig?.pageSize,
                      tableConfig?.pageNumber * tableConfig?.pageSize
                    )
                    ?.map((each) => (
                      <tr>
                        <td>{each?.DomainName}</td>
                        <td>Amazon Route 53</td>
                        <td>
                          {each?.Availability === "AVAILABLE"
                            ? formatCurrencyValue(each?.Price?.Price)
                            : each?.Availability}
                        </td>
                        <td>
                          <Button
                            color="link"
                            onClick={() => _toggleDomainCheckoutModal(each)}
                            disabled={each?.Availability !== "AVAILABLE"}
                          >
                            Buy
                          </Button>
                        </td>
                      </tr>
                    ))
                ) : (
                  <tr>
                    <td colSpan={4}>
                      <div className="nodata">
                        <img
                          src={
                            require("../../../../assets/img/noData.svg").default
                          }
                          alt="no data"
                        />
                        <p>There is no data to display</p>
                      </div>
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
            {availableDomainsList?.length ? (
              <div className="mx-auto p-2">
                <TablePagination
                  data={availableDomainsList?.slice(
                    (tableConfig?.pageNumber - 1) * tableConfig?.pageSize,
                    tableConfig?.pageNumber * tableConfig?.pageSize
                  )}
                  dataCount={availableDomainsList?.length}
                  pageNumber={tableConfig?.pageNumber}
                  pageSize={tableConfig?.pageSize}
                  onPageChange={_onChangePagination}
                  isPageStartFromOne={true}
                />
              </div>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  );
};

export default BuyNewDomain;
