import React from "react";
import {
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
} from "reactstrap";

const StandardFeeTab = ({
  optionsArr,
  isEdit,
  onChangeFunc = null,
  category,
  // error,
  value,
}) => {
  console.log({ value });
  return (
    <div className="standardFeeWrap">
      {optionsArr?.map((each) => (
        <FormGroup className="floatingLabel withInputGroup">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText>
                <img
                  src={require("../../../assets/img/dollar.svg").default}
                  height={14}
                  alt="doller"
                />
              </InputGroupText>
            </InputGroupAddon>
            <Input
              type="text"
              name="standardFee"
              onChange={(e) => onChangeFunc(each, e.target.value, category)}
              value={value?.[each]?.value || ""}
              placeholder=" "
              disabled={!isEdit ? true : false}
            />
            <Label>{each?.replace(/([a-z])([A-Z])/g, "$1 $2")}</Label>
          </InputGroup>

          {/* {error[category][each]?.error && (
            <div className="validation-error">
              {error[category][each]?.error}
            </div>
          )} */}
        </FormGroup>
      ))}
    </div>
  );
};

export default StandardFeeTab;
