import React from "react";
import {
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  PopoverBody,
  PopoverHeader,
  UncontrolledPopover,
  InputGroupText,
  CustomInput,
  Label,
} from "reactstrap";
import SigningStatusSigningModal from "../components/signing-status-signing";
import SignerAvailabilityModal from "../components/signer-availability-modal";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { connect } from "react-redux";
import {
  getAllClientClosing,
  getAllSigningCompanyForClient,
  getAllLenderClients,
  updateClosingDetailsById,
  clientGetListTeamsOfClient,
} from "../../../http/http-calls";
import {
  deepClone,
  formatCurrencyValue,
  showToast,
  // eslint-disable-next-line no-unused-vars
  isTodayOrfutureDate,
  formatDateAsPerTimeZOne,
  getTimeZoneAbbr,
  checkPermission,
  formatOnlyDateMoment,
  errorHandler,
  capitalize,
  // eslint-disable-next-line no-unused-vars
  formatDateOnlyAsPerTimeZone,
  getDropdownColor,
  formatAddressInSingleText,
  openAppleMapOnNewTab,
  openGoogleMapOnNewTab,
  refreshFunc,
} from "../../../helper-methods";

import usaStates from "../../../config/usa_states_titlecase";
import { agentClosingStatus } from "../../../config";
import ClientAssignOrder from "../components/ClientAssignOrder";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomTable from "../components/CustomTable";
import CustomCard from "../components/CustomCard";
import { DateTimeShowWithCellRow } from "../components/Date-time-show";

class OrderDashboard extends React.Component {
  state = {
    clientOrderList: [],
    clientOrderTotalCount: 0,
    clientOrderHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      { id: "appointmentDate", label: "Signer", noSort: true },
      { id: "fileNumber", label: "File#", noSort: true },
      { id: "loanType", label: "Product Type", noSort: true },
      { id: "_signingCompany", label: "Assigned Company", noSort: true },
      // { id: "lender", label: "Client", noSort: true },
      { id: "docs", label: "Docs", noSort: true },
      { id: "closingAddress", label: "Location", noSort: true },
      { id: "createdBy", label: "Created On Behalf Of", noSort: true },
      { id: "status", label: "Status", noSort: true },
    ],
    clientOrderCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    clientAssignOrder: {
      isOpen: false,
      data: null,
    },
    editAgentModal: {
      isOpen: false,
      data: null,
    },
    signingStatusModal: {
      isOpen: false,
      data: null,
      status: null,
    },
    signerAvailabilityModal: {
      isOpen: false,
      data: null,
    },
    isFilterCollapseOpen: false,
    isEditingAgent: false,
    tablePayload: {
      page: 1,
      rowsPerPage: 10,
    },
    loading: {
      statusChangeLoading: false,
      showTableLoading: false,
    },
    filters: {
      status: "",
      search: "",
      signingCompanyId: "",
      clientTeam: "",
      state: "",
      startDate: "",
      endDate: "",
      unassigned: false,
    },
    tableConfig: {
      skip: 0,
      limit: 30,
      pageNumber: 1,
    },
    dateRangeValue: null,
    clientTeamsList: [],
    signingCompanies: [],
    agents: [],
    loanType: [],
    isClassAdded: false,
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  componentDidMount = () => {
    this.searchTimer = null;

    // this._getAllClientClosing();
    this._setFilterDataFromLocalStorage();
    this._getAllSigningCompanyForClient();
    this._getClientListTeamsOfClient();

    HeaderEventEmitter.subscribe("reset-filter", () => {
      this._resetFilter();
      refreshFunc("client-order-dashboard");
    });
    HeaderEventEmitter.subscribe("create-closing", () => {
      this._redirectToCreateOrder();
    });

    // document.querySelector(".content").scrollIntoView();
    document.querySelector("#scroll").scrollTo(0, 0);
  };

  _redirectToClosingDetails = (data) => {
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/order-details/${data.id}`);
  };

  _getAllSigningCompanyForClient = () => {
    getAllSigningCompanyForClient()
      .then((res) => {
        this.setState({
          signingCompanies: res?.signingCompany?.length
            ? res.signingCompany
            : [],
        });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getAllClientClosing = () => {
    this._manageLoading("showTableLoading", true);

    const { filters, tableConfig, dateRangeValue } = deepClone(this.state);

    if (filters.status && filters.status.length)
      Object.assign(tableConfig, { status: filters.status });

    if (filters?.clientTeam && filters?.clientTeam?.length)
      Object.assign(tableConfig, { clientAssistantTeam: filters?.clientTeam });

    if (filters.state && filters.state.length)
      Object.assign(tableConfig, { state: filters.state });

    if (filters.signingCompanyId && filters.signingCompanyId.length)
      Object.assign(tableConfig, {
        signingCompanyId: filters.signingCompanyId,
      });

    if (filters.unassigned)
      Object.assign(tableConfig, { unassigned: filters.unassigned });

    if (filters.closingDeadline)
      Object.assign(tableConfig, { closingDeadline: filters.closingDeadline });

    if (filters.assignedDate)
      Object.assign(tableConfig, { assignedDate: filters.assignedDate });

    if (filters.loanType)
      Object.assign(tableConfig, { loanType: filters.loanType });

    if (filters.search && filters.search.trim().length)
      Object.assign(tableConfig, { search: filters.search.trim() });

    if (dateRangeValue && dateRangeValue[0])
      Object.assign(tableConfig, {
        startDate: formatOnlyDateMoment(dateRangeValue[0]),
      });

    if (dateRangeValue && dateRangeValue[1])
      Object.assign(tableConfig, {
        endDate: formatOnlyDateMoment(dateRangeValue[1]),
      });

    if (
      dateRangeValue &&
      dateRangeValue[0]?.getDate() === dateRangeValue[1]?.getDate()
    )
      Object.assign(tableConfig, { isReverse: true });

    delete tableConfig.sort;
    delete tableConfig.pageNumber;

    getAllClientClosing(tableConfig)
      .then((res) => {
        this.setState(
          {
            clientOrderList: res?.closings || [],
            clientOrderTotalCount: res?.totalCount || 0,
          },
          () => {
            this._manageLoading("showTableLoading", false);
          }
        );
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _onToggleClientAssignOrder = (isOpen = false, data = null) => {
    this.setState({
      clientAssignOrder: {
        isOpen,
        data,
      },
    });
  };

  _onToggleEditAgentModal = (data) => {
    let { editAgentModal } = deepClone(this.state);
    editAgentModal.isOpen = !editAgentModal.isOpen;
    editAgentModal.data = data;
    this.setState({
      editAgentModal,
    });
  };

  _onToggleSigningStatusModal = (
    isOpen = false,
    data = null,
    status = null
  ) => {
    this.setState({
      signingStatusModal: {
        isOpen,
        data,
        status,
      },
    });
  };

  _onToggleSignerAvailabilityModal = (isOpen = false, data = null) => {
    if (
      isOpen &&
      (data?._agent ||
        (data?.status !== "Pending" && data?.status !== "Cancelled"))
    ) {
      showToast("You cannot change the date & time.", "error");
      return;
    }

    this.setState({
      signerAvailabilityModal: {
        isOpen,
        data,
      },
    });
  };

  _toggleFilterCollapse = () =>
    this.setState({ isFilterCollapseOpen: !this.state.isFilterCollapseOpen });

  _paginate = (pageNumber = 1, pageSize = 30) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllClientClosing();
    });
    document.querySelector(".content").scrollIntoView();
  };

  _getClientListTeamsOfClient = async () => {
    try {
      const clientId = this.props.userData?.user?.id;
      const res = await clientGetListTeamsOfClient(clientId);
      // const res = await clientGetListTeamsOfClient();

      this.setState({ clientTeamsList: res?.teams || [] });
    } catch (err) {
      errorHandler(err);
    }
  };

  _onSortChange = (sortName, sortOrder) => {
    this._paginate();
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => {
      this._paginate();
    });
  };

  _filterOnChange = (type, value, isDelay = false) => {
    if (isDelay) clearTimeout(this.searchTimer);

    const { filters } = deepClone(this.state);
    filters[type] = value;

    this.setState({ filters }, () => {
      if (isDelay) {
        this.searchTimer = setTimeout(() => {
          this._paginate();
        }, 1000);
      } else {
        this._paginate();
      }
    });
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          status: "",
          search: "",
          signingCompanyId: "",
          clientTeam: "",
          state: "",
          startDate: "",
          endDate: "",
          unassigned: false,
        },
        dateRangeValue: null,
      },
      () => {
        this._paginate();
      }
    );
    refreshFunc("client-order-dashboard-responsive");
  };

  _redirectToCreateOrder = () => {
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/create-order`);
  };

  _redirectToOrderDetails = (data) => {
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/order-details/${data.id}`);
  };

  _onChangeOrderStatus = async (data, status) => {
    if (status !== "Pending" && status !== "Cancelled") {
      showToast("Invalid status", "error");
      return;
    }

    try {
      this._manageLoading("statusChangeLoading", true);

      const updateAgentRes = await updateClosingDetailsById({
        id: data?._id,
        payload: { status },
      });

      showToast("Status has been updated", "success");

      const { clientOrderList } = this.state;

      const findOrder = clientOrderList.find((each) => each.id === data.id);

      findOrder["status"] = updateAgentRes?.closing?.status;

      this.setState({ clientOrderList });

      this._manageLoading("statusChangeLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("statusChangeLoading", false);
    }
  };

  _multiLineText = (str) => {
    return str.split(" ").join("\n");
  };

  _dataFormat = (cell, row, header) => {
    document.querySelector(".content").scrollIntoView();

    switch (header) {
      case "appointmentDate": {
        return row ? (
          <>
            <div className="tableUserInfo">
              {/* <div className="userImage">
                <img
                  className="clientImg"
                  src={
                    row?._lenderClient?.picUrl
                      ? row?._lenderClient?.picUrl
                      : require("../../../assets/img/default_user_icon.svg")
                          .default
                  }
                  loading="lazy"
                  alt="avatar"
                />
              </div> */}
              <div className="userContent">
                <span
                  id={`borrower_table_agent_dashboard_Client${row._id}`}
                  className="signerName"
                >
                  {row?._borrower[0]?.name?.full
                    ? row?._borrower[0]?.name?.full
                    : "N/A"}
                  {row?._borrower?.length > 1
                    ? `+${row?._borrower?.length - 1}`
                    : null}
                </span>

                {row?._borrower?.length ? (
                  <>
                    <UncontrolledPopover
                      trigger="legacy"
                      placement="bottom"
                      target={`borrower_table_agent_dashboard_Client${row._id}`}
                      className="signerPopover"
                    >
                      <PopoverHeader>Signers</PopoverHeader>
                      <PopoverBody>
                        {row?._borrower.map((each) => (
                          <ul key={each._id} className="dataList">
                            <li>
                              <Label>Name</Label>
                              <div>{each.name?.full || "N/A"}</div>
                            </li>
                            <li>
                              <Label>Email</Label>
                              <div>{each.email || "N/A"}</div>
                            </li>
                            <li>
                              <Label>Phone</Label>
                              <div>{each.phone?.work || "N/A"}</div>
                            </li>
                            <li>
                              <Label>Language</Label>
                              <div>{each.language || "N/A"}</div>
                            </li>
                            <li>
                              <Label>Time Zone</Label>
                              <div>{each.timeZone || "N/A"}</div>
                            </li>
                          </ul>
                        ))}
                      </PopoverBody>
                    </UncontrolledPopover>
                  </>
                ) : null}
                {/* <span className="dateTimeFormat">
                  {formatDateAsPerTimeZOne(cell, row.closingAddress?.timeZone)},{" "}
                  <span>
                    {getTimeZoneAbbr(cell, row.closingAddress?.timeZone)}
                  </span>
                </span> */}
                <DateTimeShowWithCellRow cell={cell} row={row} />

                <Button
                  color={`${
                    row?.appointmentStatus === "Confirmed" && !row.leftMessage
                      ? "success "
                      : row?.appointmentStatus === "Rescheduled" &&
                        !row.leftMessage
                      ? "danger "
                      : row.leftMessage
                      ? "warning"
                      : "danger "
                  }`}
                  className={`confirmButton `}
                  onClick={() =>
                    this._onToggleSignerAvailabilityModal(true, row)
                  }
                >
                  {row?.appointmentStatus === "Confirmed"
                    ? "Confirmed"
                    : "Confirm"}
                </Button>
              </div>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "docs": {
        return row ? (
          <div className="docStatus">
            {/* Last Uploaded by Company: $DateTime */}
            <Button
              color="link"
              className="actionBtn"
              data-toggle="tooltip"
              data-placement="top"
              title={
                row?.companyLastDocumentUploadedAt &&
                `Last Uploaded by Company: ${formatDateAsPerTimeZOne(
                  row?.companyLastDocumentUploadedAt,
                  row?.closingAddress?.timeZone
                )}`
              }
              onClick={() =>
                row.isDocumentCompleted ? this._redirectToOrderDetails(row) : {}
              }
            >
              {/* {row.fileNumber || "N/A"} */}
              {row?.documents?.length && !row.documents[0].isPrivate ? (
                row.isDocumentCompleted ? (
                  <span
                    className="fa fa-circle text-success"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                ) : row?.isDocumentUploadedByCompanyOrClient ? (
                  <span
                    className="fa fa-circle text-warning"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                ) : (
                  <span
                    className="fa fa-circle text-primary"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )
              ) : (
                <span
                  className="fa fa-circle text-primary"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )}
              <span className="value">
                {row.isDocumentCompleted
                  ? "Docs Ready"
                  : row?.isDocumentUploadedByCompanyOrClient
                  ? "Pending"
                  : "Not Ready"}
              </span>
            </Button>

            {/* First downloaded by Agent: DateTime */}
            {/* turns green only when agent downloaded all documents  */}
            {/* isAgentDocumentDownloaded */}
            <Button
              color="link"
              className="actionBtn"
              data-toggle="tooltip"
              data-placement="top"
              title={
                row?.isAgentViewDocumentTime &&
                `First downloaded by Agent: ${formatDateAsPerTimeZOne(
                  row?.isAgentViewDocumentTime,
                  row?.closingAddress?.timeZone
                )}`
              }
            >
              {row.isAllDocumentsDownloaded ? (
                <span
                  className="fa fa-circle text-success"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              ) : (
                <span
                  className="fa fa-circle text-warning"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )}
              {/* <span className="fs-14 ml-1">Docs Downloaded</span> */}
              <span className="value">
                {row?.isAllDocumentsDownloaded ? "Docs Downloaded" : "Pending"}
              </span>
            </Button>

            {/* Last Uploaded by Agent: DateTime */}

            {/* once a company create a closing and Selects the "scan back is required" check box and assigns the closing, 
            1. The third button will turn red(the rest two will remain gray), 
            2. When the agent uploads any doc and selects "scan back upload completed" it will turn yellow then 
            3. When the company selects "verified agent doc" it will turn green. */}

            <Button
              color="link"
              className="actionBtn"
              data-toggle="tooltip"
              data-placement="top"
              title={
                row?.agentLastDocumentUploadedAt &&
                `Last Uploaded by Agent:  ${formatDateAsPerTimeZOne(
                  row?.agentLastDocumentUploadedAt,
                  row?.closingAddress?.timeZone
                )}`
              }
            >
              {row.isScanBackNeeded ? (
                row.isDocumentVerified ? (
                  <>
                    <span
                      className="fa fa-circle text-success"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span className="value">Shipping Approved</span>
                  </>
                ) : row.isDocumentCompletedByAgent ? (
                  <>
                    <span
                      className="fa fa-circle text-warning"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span
                      className="value"
                      onClick={() => this._redirectToOrderDetails(row)}
                    >
                      Scan Upload
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className="fa fa-circle text-danger"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span className="value">Scan Needed</span>
                  </>
                )
              ) : row?.documents?.length && !row.documents[0].isPrivate ? (
                row.isDocumentCompletedByAgent ? (
                  row.isDocumentVerified ? (
                    <>
                      <span
                        className="fa fa-circle text-success"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                      <span className="value">Shipping Approved</span>
                    </>
                  ) : (
                    <>
                      <span
                        className="fa fa-circle text-warning"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                      <span className="value">Scan Upload</span>
                    </>
                  )
                ) : (
                  <span
                    className="fa fa-circle"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )
              ) : (
                <span
                  className="fa fa-circle"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )}
              {/* <span className="fs-14 ml-1">Verified</span> */}
            </Button>
          </div>
        ) : (
          "N/A"
        );
      }
      case "fileNumber": {
        return row ? (
          <>
            <div
              className="tableLink"
              style={{
                minWidth: 75,
                whiteSpace: "normal",
              }}
              onClick={() => this._redirectToClosingDetails(row)}
            >
              {row.fileNumber || "N/A"}
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "_signingCompany": {
        return (
          <>
            {cell?.companyName ? (
              <span style={{ fontWeight: 600 }}>
                {cell?.companyName && capitalize(cell.companyName)}
              </span>
            ) : (
              "N/A"
            )}

            {/* {row.appointmentDate &&
            !row?._agent && (
              <span>
                {row?.status === "Pending" || row?.status === "Cancelled"}
              </span>
            ) ? (
              <Button
                color="link"
                onClick={() => this._onToggleClientAssignOrder(true, row)}
                disabled={!checkPermission("dashboard", "canEditOrderEntry")}
                className="px-1"
              >
                <img
                  src={require("../../../assets/img/pencil.svg").default}
                  alt="pencil"
                />
              </Button>
            ) : null} */}
          </>
        );
      }
      case "loanType": {
        return (
          <>
            <div style={{ width: 100, fontWeight: 600 }}>
              <span style={{ marginRight: 6, display: "inline-block" }}>
                {" "}
                {row.signingType}
              </span>

              {cell && cell.length > 0 ? (
                cell.indexOf("Other") > -1 ? (
                  <span style={{ marginRight: 6, display: "inline-block" }}>
                    {row?.loanTypeOther}
                  </span>
                ) : null
              ) : null}
              {cell
                .filter((each) => each !== "Other")
                .map((item) => (
                  <>
                    <span style={{ marginRight: 6, display: "inline-block" }}>
                      {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                    </span>
                  </>
                ))}
            </div>
          </>
        );
      }
      case "lender": {
        return (
          <>
            <div style={{ width: 80, fontWeight: 600 }}>
              {cell ? capitalize(cell) : "N/A"}
            </div>
          </>
        );
      }
      case "createdBy": {
        return (
          <>
            <span style={{ fontWeight: 600 }}>
              {row?._createdBy && row?._createdBy?.companyName
                ? capitalize(row?._createdBy?.companyName)
                : row?._createdByClient && row?._createdByClient?.companyName
                ? capitalize(row?._createdByClient?.companyName)
                : "N/A"}
            </span>
          </>
        );
      }
      case "agentFee": {
        return cell ? formatCurrencyValue(cell) : "N/A";
      }
      case "closingAddress": {
        return (
          <>
            <span
              style={{
                fontWeight: 600,
                width: 140,
                display: "block",
                marginBottom: 5,
              }}
            >
              {formatAddressInSingleText(cell) || "N/A"}
            </span>
            <Button
              color="link"
              className="p-0"
              title={formatAddressInSingleText(cell)}
              onClick={() => openGoogleMapOnNewTab(row.closingAddress)}
            >
              <img
                src={
                  require("../../../assets/img/Google_Maps_Logo_2020.svg")
                    .default
                }
                alt="map icon"
                className="mr-1"
              />
              Google Map
            </Button>
            <Button
              color="link"
              className="p-0"
              title={formatAddressInSingleText(cell)}
              onClick={() => openAppleMapOnNewTab(row.closingAddress)}
            >
              <img
                src={
                  require("../../../assets/img/Apple_Maps_(WatchOS).svg")
                    .default
                }
                alt="map icon"
                className="mr-1"
              />
              Apple Map
            </Button>
          </>
        );
      }
      case "status": {
        return cell ? (
          <div className="customSelectWrap" style={{ width: 100 }}>
            <Input
              type="select"
              name="status"
              className={`status ${getDropdownColor(cell)}`}
              disabled={
                this.state.loading.statusChangeLoading ||
                !(
                  checkPermission(
                    "dashboard",
                    "canUpdateClosingStatusOrScheduling"
                  ) || checkPermission("dashboard", "canEditOrderEntry")
                )
              }
              value={cell}
              onChange={(event) =>
                this._onChangeOrderStatus(row, event.target.value)
              }
            >
              {agentClosingStatus.map((obj) => (
                <option
                  key={obj.value}
                  value={obj.value}
                  disabled={
                    obj.value === "CCA" ||
                    obj.value === "Arrived" ||
                    obj.value === "Closed" ||
                    obj.value === "DNC" ||
                    cell === "Closed" ||
                    row?._agent
                  }
                >
                  {obj.label}
                </option>
              ))}
            </Input>
            <div className="downArrow">
              <i className="fa fa-chevron-down"></i>
            </div>
          </div>
        ) : (
          "N/A"
        );
      }
      case "loanOfficer": {
        return cell ? capitalize(cell) : "N/A";
      }
      default: {
        return cell;
      }
    }
  };

  _rowStyleFormat = (row, rowIdx) => {
    const statusEnum = ["Closed", "DNC", "Cancelled"];
    if (row?.status && statusEnum.includes(row.status)) {
      return { backgroundColor: "#eee" };
    }
    if (row?.isSavedAsDraft) {
      return { backgroundColor: "#edf0ff" };
    }
    if (row?._signingCompany?.whiteLabelRequestStatus === "Done") {
      return {
        backgroundColor: "#d7eafd",
      };
    }
    return {};
  };

  _cardHeaderFormat = (cell, row, header) => {
    // console.log("weerer", cell, header, row);
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="tableUserInfo">
              {/* <div className="userImage">
                <img
                  className="clientImg"
                  src={
                    row?._lenderClient?.picUrl
                      ? row?._lenderClient?.picUrl
                      : require("../../../assets/img/default_user_icon.svg")
                          .default
                  }
                  loading="lazy"
                  alt="avatar"
                />
              </div> */}
              <div className="userContent flex-0">
                <span
                  id={`borrower_table_agent_dashboard_Signer${row._id}`}
                  className="signerName"
                >
                  {row?._borrower[0]?.name?.full
                    ? row?._borrower[0]?.name?.full
                    : "N/A"}
                  {row?._borrower?.length > 1
                    ? `+${row?._borrower?.length - 1}`
                    : null}
                </span>

                {row?._borrower?.length ? (
                  <>
                    <UncontrolledPopover
                      trigger="legacy"
                      placement="bottom"
                      target={`borrower_table_agent_dashboard_Signer${row._id}`}
                      className="signerPopover"
                    >
                      <PopoverHeader>Signers</PopoverHeader>
                      <PopoverBody>
                        {row?._borrower.map((each) => (
                          <ul key={each._id} className="dataList">
                            <li>
                              <Label>Name</Label>
                              <div>{each.name?.full || "N/A"}</div>
                            </li>
                            <li>
                              <Label>Email</Label>
                              <div>{each.email || "N/A"}</div>
                            </li>
                            <li>
                              <Label>Phone</Label>
                              <div>{each.phone?.work || "N/A"}</div>
                            </li>
                            <li>
                              <Label>Language</Label>
                              <div>{each.language || "N/A"}</div>
                            </li>
                            <li>
                              <Label>Time Zone</Label>
                              {each.timeZone || "N/A"}
                            </li>
                          </ul>
                        ))}
                      </PopoverBody>
                    </UncontrolledPopover>
                  </>
                ) : null}
                {/* <span className="dateTimeFormat">
                  {formatDateAsPerTimeZOne(
                    row?.appointmentDate,
                    row?.closingAddress?.timeZone
                  )}
                  ,{" "}
                  <span>
                    {getTimeZoneAbbr(
                      row?.appointmentDate,
                      row.closingAddress?.timeZone
                    )}
                  </span> 
                </span>*/}
                <DateTimeShowWithCellRow
                  cell={row?.appointmentDate}
                  row={row}
                />
              </div>

              <Button
                color={`${
                  row?.appointmentStatus === "Confirmed" && !row?.leftMessage
                    ? "success "
                    : row?.appointmentStatus === "Rescheduled" &&
                      !row?.leftMessage
                    ? "danger "
                    : row?.leftMessage
                    ? "warning"
                    : "danger "
                }`}
                className={`confirmButton btnConfirm`}
                onClick={() => this._onToggleSignerAvailabilityModal(true, row)}
              >
                {row?.appointmentStatus === "Confirmed"
                  ? "Confirmed"
                  : "Confirm"}
              </Button>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>File </Label>
            <div
              className="text-primary cursorPointer"
              style={{ fontWeight: 600 }}
            >
              {row?.fileNumber ? (
                <div
                  className="tableLink"
                  style={{
                    whiteSpace: "normal",
                    textDecoration: "underline",
                  }}
                  onClick={() => this._redirectToClosingDetails(row)}
                >
                  {row?.fileNumber || "N/A"}
                </div>
              ) : (
                "N/A"
              )}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Product Type </Label>
            <div>
              {
                <>
                  <span
                    style={{
                      marginRight: 5,
                      fontWeight: 600,
                    }}
                  >
                    {" "}
                    {row.signingType}
                  </span>

                  {row?.loanType && row?.loanType.length > 0 ? (
                    row?.loanType.indexOf("Other") > -1 ? (
                      <span
                        style={{
                          marginRight: 5,
                          fontWeight: 600,
                        }}
                      >
                        {row?.loanTypeOther}
                      </span>
                    ) : null
                  ) : null}
                  {row?.loanType
                    .filter((each) => each !== "Other")
                    .map((item) => (
                      <>
                        <span
                          style={{
                            marginRight: 5,
                            fontWeight: 600,
                          }}
                        >
                          {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                        </span>
                      </>
                    ))}
                </>
              }
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Assigned Company </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {
                <>
                  {(row?._signingCompany?.companyName &&
                    capitalize(row?._signingCompany?.companyName)) || (
                    <>
                      N/A <span className="mr-1">{row?._noOfBidding}</span>{" "}
                    </>
                  )}

                  {/* {row?.appointmentDate &&
                  !row?._agent &&
                  (row?.status === "Pending" || row?.status === "Cancelled") ? (
                    <Button
                      color="link"
                      onClick={() => this._onToggleClientAssignOrder(true, row)}
                      disabled={
                        !checkPermission("dashboard", "canEditOrderEntry")
                      }
                      className="px-1"
                    >
                      <img
                        src={require("../../../assets/img/pencil.svg").default}
                        style={{ height: 12 }}
                        alt="edit"
                      />
                    </Button>
                  ) : null} */}
                </>
              }
            </div>
          </div>

          <div className="dataWrap">
            <Label>Created on Behalf Of</Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?._createdBy && row?._createdBy?.companyName
                ? capitalize(row?._createdBy?.companyName)
                : row?._createdByClient && row?._createdByClient?.companyName
                ? capitalize(row?._createdByClient?.companyName)
                : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Docs</Label>

            <div className="docStatus">
              {row ? (
                <>
                  {/* Last Uploaded by Company: $DateTime */}
                  <Button
                    color="link"
                    className="actionBtn"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={
                      row?.companyLastDocumentUploadedAt &&
                      `Last Uploaded by Company: ${formatDateAsPerTimeZOne(
                        row?.companyLastDocumentUploadedAt,
                        row?.closingAddress?.timeZone
                      )}`
                    }
                    onClick={() =>
                      row.isDocumentCompleted
                        ? this._redirectToOrderDetails(row)
                        : {}
                    }
                  >
                    {/* {row.fileNumber || "N/A"} */}
                    {row?.documents?.length && !row.documents[0].isPrivate ? (
                      row.isDocumentCompleted ? (
                        <span
                          className="fa fa-circle text-success"
                          style={{ fontSize: 10, verticalAlign: 1 }}
                        />
                      ) : row?.isDocumentUploadedByCompanyOrClient ? (
                        <span
                          className="fa fa-circle text-warning"
                          style={{ fontSize: 10, verticalAlign: 1 }}
                        />
                      ) : (
                        <span
                          className="fa fa-circle text-primary"
                          style={{ fontSize: 10, verticalAlign: 1 }}
                        />
                      )
                    ) : (
                      <span
                        className="fa fa-circle text-primary"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                    )}
                    <span className="value">
                      {row.isDocumentCompleted
                        ? "Docs Ready"
                        : row?.isDocumentUploadedByCompanyOrClient
                        ? "Pending"
                        : "Not Ready"}
                    </span>
                  </Button>

                  {/* First downloaded by Agent: DateTime */}
                  {/* turns green only when agent downloaded all documents  */}
                  {/* isAgentDocumentDownloaded */}
                  <Button
                    color="link"
                    className="actionBtn"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={
                      row?.isAgentViewDocumentTime &&
                      `First downloaded by Agent: ${formatDateAsPerTimeZOne(
                        row?.isAgentViewDocumentTime,
                        row?.closingAddress?.timeZone
                      )}`
                    }
                  >
                    {row.isAllDocumentsDownloaded ? (
                      <span
                        className="fa fa-circle text-success"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                    ) : (
                      <span
                        className="fa fa-circle text-warning"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                    )}
                    {/* <span className="fs-14 ml-1">Docs Downloaded</span> */}
                    <span className="value">
                      {row?.isAllDocumentsDownloaded
                        ? "Docs Downloaded"
                        : "Pending"}
                    </span>
                  </Button>

                  {/* Last Uploaded by Agent: DateTime */}

                  {/* once a company create a closing and Selects the "scan back is required" check box and assigns the closing, 
            1. The third button will turn red(the rest two will remain gray), 
            2. When the agent uploads any doc and selects "scan back upload completed" it will turn yellow then 
            3. When the company selects "verified agent doc" it will turn green. */}

                  <Button
                    color="link"
                    className="actionBtn"
                    data-toggle="tooltip"
                    data-placement="top"
                    title={
                      row?.agentLastDocumentUploadedAt &&
                      `Last Uploaded by Agent:  ${formatDateAsPerTimeZOne(
                        row?.agentLastDocumentUploadedAt,
                        row?.closingAddress?.timeZone
                      )}`
                    }
                  >
                    {row.isScanBackNeeded ? (
                      row.isDocumentVerified ? (
                        <>
                          <span
                            className="fa fa-circle text-success"
                            style={{ fontSize: 10, verticalAlign: 1 }}
                          />
                          <span className="value">Shipping Approved</span>
                        </>
                      ) : row.isDocumentCompletedByAgent ? (
                        <>
                          <span
                            className="fa fa-circle text-primary"
                            style={{ fontSize: 10, verticalAlign: 1 }}
                          />
                          <span
                            className="value"
                            onClick={() => this._redirectToOrderDetails(row)}
                          >
                            Scan Upload
                          </span>
                        </>
                      ) : (
                        <>
                          <span
                            className="fa fa-circle text-danger"
                            style={{ fontSize: 10, verticalAlign: 1 }}
                          />
                          <span className="value">Scan Needed</span>
                        </>
                      )
                    ) : row?.documents?.length &&
                      !row.documents[0].isPrivate ? (
                      row.isDocumentCompletedByAgent ? (
                        <>
                          <span
                            className="fa fa-circle text-success"
                            style={{ fontSize: 10, verticalAlign: 1 }}
                          />
                          <span className="value">Verified</span>
                        </>
                      ) : (
                        <span
                          className="fa fa-circle"
                          style={{ fontSize: 10, verticalAlign: 1 }}
                        />
                      )
                    ) : (
                      <span
                        className="fa fa-circle"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                    )}
                    {/* <span className="fs-14 ml-1">Verified</span> */}
                  </Button>
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <div className="d-flex justify-content-between">
              <Label>Location </Label>
              <div>
                <Button
                  color="link"
                  className="p-0"
                  title={formatAddressInSingleText(row?.closingAddress)}
                  onClick={() => openGoogleMapOnNewTab(row?.closingAddress)}
                >
                  <img
                    src={
                      require("../../../assets/img/Google_Maps_Logo_2020.svg")
                        .default
                    }
                    alt="map icon"
                    className="mr-1"
                  />
                  {/* Google Map */}
                </Button>
                <Button
                  color="link"
                  className="p-0"
                  title={formatAddressInSingleText(row?.closingAddress)}
                  onClick={() => openAppleMapOnNewTab(row.closingAddress)}
                >
                  <img
                    src={
                      require("../../../assets/img/Apple_Maps_(WatchOS).svg")
                        .default
                    }
                    alt="map icon"
                    className="mr-1"
                  />
                  {/* Apple Map */}
                </Button>
              </div>
            </div>
            <div style={{ fontWeight: 600, flex: 1, marginRight: 10 }}>
              {formatAddressInSingleText(row?.closingAddress) || "N/A"}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap  d-flex align-items-center justify-content-between">
            <Label>Status: </Label>

            {row?.status ? (
              <div
                className="customSelectWrap"
                style={{ width: 120, marginRight: "0" }}
              >
                <Input
                  type="select"
                  name="status"
                  className={`status ${getDropdownColor(row?.status)}`}
                  disabled={
                    this.state.loading.statusChangeLoading ||
                    !(
                      checkPermission(
                        "dashboard",
                        "canUpdateClosingStatusOrScheduling"
                      ) || checkPermission("dashboard", "canEditOrderEntry")
                    )
                  }
                  value={row?.status}
                  onChange={(event) =>
                    this._onChangeOrderStatus(row, event.target.value)
                  }
                >
                  {agentClosingStatus.map((obj) => (
                    <option
                      key={obj.value}
                      value={obj.value}
                      disabled={
                        obj.value === "CCA" ||
                        obj.value === "Arrived" ||
                        obj.value === "Closed" ||
                        obj.value === "DNC" ||
                        row?.status === "Closed" ||
                        row?._agent
                      }
                    >
                      {obj.label}
                    </option>
                  ))}
                </Input>
                <div className="downArrow">
                  <i className="fa fa-chevron-down"></i>
                </div>
              </div>
            ) : (
              "N/A"
            )}
          </div>
        </li>
      </ul>
    );
  };

  _persistFilter = () => {
    const { tableConfig } = this.state;

    if (tableConfig.pageNumber !== 1) {
      let data = { tableConfig };
      localStorage.clientClosingdashboard = JSON.stringify(data);
    } else {
      delete localStorage.clientClosingdashboard;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.clientClosingdashboard) {
      try {
        const filters = JSON.parse(localStorage.clientClosingdashboard);

        this.setState({ tableConfig: filters.tableConfig }, () => {
          this._getAllClientClosing();
        });
      } catch (e) {
        console.log("error", e);
        this._getAllClientClosing();
      }
    } else {
      this._getAllClientClosing();
    }
  };

  render() {
    const {
      clientOrderTotalCount,
      // isFilterCollapseOpen,
      dateRangeValue,
      filters,
      clientOrderHeaderKeys,
      clientOrderList,
      loading,
      signingStatusModal,
      clientAssignOrder,
      signerAvailabilityModal,
      tableConfig,
      signingCompanies,
      isClassAdded,
      clientOrderCardHeaderKeys,
      clientTeamsList,
    } = this.state;

    return (
      <div id="scroll">
        <div className="content">
          <div className="responsiveTitle">
            <h2>
              Order Dashboard
              <span>{this.props?.userData?.user?.companyName}</span>
            </h2>

            <div className="rightSide">
              <Button color="link" onClick={this._resetFilter}>
                <img
                  id="client-order-dashboard-responsive"
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                />
              </Button>

              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="Filter"
                />
              </Button>

              {checkPermission("dashboard", "canCreateOrder") && (
                <Button
                  color="primary"
                  className="floatingButton"
                  onClick={() => this._redirectToCreateOrder()}
                >
                  Create
                </Button>
              )}
            </div>
          </div>
          <h3 className="mainHeading d-none d-lg-block">
            {this.props?.userData?.user?.companyName}
          </h3>

          {/* search filed for responsive will show below 767px */}
          <div className="mobileSearchFiled">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img
                    src={require("../../../assets/img/searchIcon.svg").default}
                    alt="searchIcon"
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                name="search"
                value={filters.search}
                onChange={(e) =>
                  this._filterOnChange("search", e.target.value, true)
                }
                placeholder="Search Signer, Product type & File"
              />
            </InputGroup>
          </div>
          {/* filter for tablet and web */}
          <div
            onClick={this.filterShowMobile}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer  responsiveFilter ${
              isClassAdded ? "show" : ""
            }`}
          >
            <div className="filterIcon">
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
              />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>
            <div className="filterWrapper">
              <FormGroup className="searchTable">
                <Label>Search</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <img
                        src={
                          require("../../../assets/img/searchIcon.svg").default
                        }
                        alt="searchIcon"
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    placeholder="Search Ex. File No. or Signer"
                    value={filters.search}
                    onChange={(e) =>
                      this._filterOnChange("search", e.target.value, true)
                    }
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label>Status</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="status"
                    value={filters.status}
                    onChange={(e) =>
                      this._filterOnChange("status", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {agentClosingStatus.map((obj) => (
                      <option key={obj.value} value={obj.value}>
                        {obj.label}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>

              <FormGroup style={{ minWidth: "150px" }}>
                <Label>Teams</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    placeholder=" "
                    value={filters?.clientTeam}
                    onChange={(event) =>
                      this._filterOnChange("clientTeam", event.target.value)
                    }
                    name="clientTeamsId"
                  >
                    <option value="">All Teams</option>
                    {clientTeamsList?.map((team) => (
                      <option key={team?._id} value={team?._id}>
                        {team?.teamName ? capitalize(team?.teamName) : ""}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup className="dateRange">
                <Label>Date Range</Label>
                <DateRangePicker
                  className="dateRange"
                  format="MM-dd-y"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  onChange={(dateRangeValue) =>
                    this._onChangeDatePicker(dateRangeValue)
                  }
                  value={dateRangeValue}
                />
              </FormGroup>
              {/* <FormGroup>
                <Label>Signing Company</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="signingCompanyId"
                    value={filters.signingCompanyId}
                    onChange={(e) =>
                      this._filterOnChange("signingCompanyId", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {signingCompanies.map((each) => (
                      <option key={each._id} value={each._id}>
                        {capitalize(each.companyName)}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup> */}
              <FormGroup>
                <Label>State</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="state"
                    value={filters.state}
                    onChange={(e) =>
                      this._filterOnChange("state", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {usaStates.map((state) => (
                      <option
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.name}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup className="noshadow">
                <CustomInput
                  id="unassigned"
                  label="Show Unassigned Only"
                  type="checkbox"
                  name="unassigned"
                  className="mt-4"
                  checked={filters?.unassigned}
                  onChange={(e) =>
                    this._filterOnChange("unassigned", e.target.checked)
                  }
                />
              </FormGroup>
            </div>
            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>

          <div className="hideDesktop">
            <CustomCard
              isPageStartFromOne={true}
              pageNumber={tableConfig.pageNumber}
              tableData={clientOrderList}
              headerKeys={clientOrderCardHeaderKeys}
              // bodyKeys={agentClosingCardBodyKeys}
              dataFormat={this._dataFormat}
              pageSize={tableConfig.limit}
              totalCount={clientOrderTotalCount}
              onPaginate={(pageNumber, pageSize) =>
                this._paginate(pageNumber, pageSize)
              }
              rowSelection={false}
              // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
              showTableLoading={loading.showTableLoading}
              cardHeaderFormat={this._cardHeaderFormat}
              cardDataFormat={this._cardDataFormat}
            />
          </div>
          <div className="hideMobile">
            {clientOrderHeaderKeys && clientOrderHeaderKeys.length && (
              <CustomTable
                pageNumber={tableConfig.pageNumber}
                tableData={clientOrderList}
                headerKeys={clientOrderHeaderKeys}
                dataFormat={this._dataFormat}
                totalCount={clientOrderTotalCount}
                rowSelection={false}
                pageSize={tableConfig.limit}
                onPaginate={(pageNumber, pageSize) =>
                  this._paginate(pageNumber, pageSize)
                }
                onSortChange={(sortName, sortOrder) =>
                  this._onSortChange(sortName, sortOrder)
                }
                rowStyleFormat={(row, rowIdx) =>
                  this._rowStyleFormat(row, rowIdx)
                }
                showTableLoading={loading.showTableLoading}
                isPageStartFromOne
              />
            )}
          </div>
          <ClientAssignOrder
            isOpen={clientAssignOrder.isOpen}
            data={clientAssignOrder.data}
            signingCompanies={signingCompanies}
            resetDetails={() => this._getAllClientClosing()}
            toggle={() => this._onToggleClientAssignOrder()}
          />
          {signerAvailabilityModal.isOpen ? (
            <SignerAvailabilityModal
              isOpen={signerAvailabilityModal.isOpen}
              data={signerAvailabilityModal.data}
              resetDetails={() => this._paginate()}
              toggle={() => this._onToggleSignerAvailabilityModal()}
              AppointmentSchedulerConfig={{
                regular: { disable: false, show: true },
                dateRange: { disable: true, show: true },
                tbd: { disable: false, show: true },
              }}
            />
          ) : null}
          <SigningStatusSigningModal
            isOpen={signingStatusModal.isOpen}
            data={signingStatusModal.data}
            status={signingStatusModal.status}
            resetDetails={() => this._getAllClientClosing()}
            toggle={() => this._onToggleSigningStatusModal()}
          />
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

export default connect(mapStateToProps, null)(OrderDashboard);
