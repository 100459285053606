import React, { useEffect } from "react";
import { Button } from "reactstrap";
import {
  LinkedInClientId,
  LinkedInClientSecret,
  LinkedInRedirectUri,
} from "../../../config";
import { linkdinLogin } from "../../../http/http-calls";
import { makePostRequest } from "../../../http/http-service";

const LinkedInOAuthComponent = ({ setAccessTokenFor, usersocialMediaData }) => {
  const clientId = LinkedInClientId;
  const redirectUri = LinkedInRedirectUri; // This should match the one you specified in your LinkedIn App settings.
  const scope = "openid email profile w_member_social";

  //   const responseType = 'token';

  const handleLinkedInLogin = () => {
    setAccessTokenFor("linkedin");

    const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}`;

    window.location.href = authUrl;
  };

  // After successful login, LinkedIn will redirect to your specified 'redirectUri' with the access token as a hash fragment.
  useEffect(() => {
    const handleAuthCallback = async () => {
      const clientId = LinkedInClientId;
      const redirectUri = LinkedInRedirectUri;
      const clientSecret = LinkedInClientSecret;

      // Parse the authorization code from the URL
      //   const code = new URLSearchParams(window.location.search).get("code");
      const parts = window.location.pathname.split("/");

      // Extract the last part
      const code = parts[parts.length - 1];
      // Exchange the authorization code for an access token
      const tokenUrl = `https://www.linkedin.com/oauth/v2/accessToken?grant_type=authorization_code&code=${code}&redirect_uri=${redirectUri}&client_id=${clientId}&client_secret=${clientSecret}`;
      console.log("to", tokenUrl);
      try {
        // const response = await axios.post(tokenUrl);
        const response = await linkdinLogin(tokenUrl);
        const { access_token } = response.data;
        console.log("res", response);
        // return access_token;
      } catch (error) {
        console.error("Error fetching access token:", error);
      }
    };
    const parts = window.location.pathname.split("/");
    console.log("13323", parts);

    // Extract the last part
    const code = parts[3];
    if (code) {
      handleAuthCallback();
      console.log("efvewvwv");
    }
  }, []);

  return (
    <div>
      {/* <h2>LinkedIn OAuth</h2> */}
      <Button
        color={"link"}
        onClick={handleLinkedInLogin}
        disabled={usersocialMediaData?.isAuthorizedFromLinkedIn}
      >
        <img
          src={require("../../../assets/img/linkedin.png").default}
          alt="linkedin"
          height={16}
          className="socialIcon"
        />{" "}
        Login with LinkedIn
        {usersocialMediaData?.isAuthorizedFromLinkedIn && (
          <img
            src={require("../../../assets/img/verify.svg").default}
            alt="check"
            height={18}
          />
        )}
      </Button>
    </div>
  );
};

export default LinkedInOAuthComponent;
