import cuid from "cuid";
import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  Col,
  CustomInput,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import {
  companyGetAllProductTypes,
  updateCustomProductVisibility,
} from "../../../http/http-calls";
import { errorHandler, showToast } from "../../../helper-methods";
import { loanTypeConfig } from "../../../config";

const CustomProductVisibility = ({ client, refresh }) => {
  const [activeTab, setActiveTab] = useState(0);
  const [key, setKey] = useState("RealEstateDocuments");
  const [customSelectedProducts, setCustomSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedOption, setSelectedOption] = useState("all");
  const [customProducts, setCustomProducts] = useState([]);

  const _toggleTab = (tab, index) => {
    if (activeTab !== tab) {
      setActiveTab(index);
      setKey(tab);
    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    _companyGetAllProductTypes();
  }, []);

  useEffect(() => {
    setCustomSelectedProducts(client?.customSelectedProducts || []);
    setSelectedOption(client?.isShowAllProductTypes ? "all" : "customSelect");
  }, [client]);

  const _companyGetAllProductTypes = async () => {
    try {
      const res = await companyGetAllProductTypes();
      console.log({ res });
      if (res?.customProductTypes) {
        _setProductType(res?.customProductTypes?.products);
      } else {
        setCustomProducts(loanTypeConfig);
      }
      // showToast("Settings are", "success");
      // setLoading(false);
    } catch (error) {
      errorHandler(error);
      // setLoading(false);
    }
  };

  const _setProductType = (products) => {
    // console.log("loantype",products)

    const groupedProducts = products.reduce((acc, product) => {
      const { productCategory } = product;

      if (!acc[productCategory]) {
        acc[productCategory] = [];
      }

      acc[productCategory].push(product.productType);

      return acc;
    }, {});
    setCustomProducts(groupedProducts);
  };

  const _onChangeFormField = (fieldName, value) => {
    // Check if the item already exists in the array
    const existingItem = customSelectedProducts.find(
      (item) => item.productType === value
    );

    if (existingItem) {
      // If it exists, remove it from the array
      setCustomSelectedProducts((prevArray) =>
        prevArray.filter((item) => item.productType !== value)
      );
    } else {
      // If it doesn't exist, add it to the array
      const newObject = {
        id: cuid(),
        productCategory: key,
        productType: value,
      };

      setCustomSelectedProducts((prevArray) => [...prevArray, newObject]);
    }
  };

  const _onSaveProductType = async () => {
    let newProductType = customSelectedProducts
      .filter((item) => item.productType !== "")
      .map((item) => ({
        _id: item._id,
        productCategory: item.productCategory,
        productType: item.productType,
      }));
    if (newProductType.length > 0) {
      let payload = {
        customSelectedProducts: newProductType,
        isShowAllProductTypes: selectedOption === "all" ? true : false,
      };
      let clientId = client?.id;

      try {
        setLoading(true);
        await updateCustomProductVisibility(clientId, payload);
        refresh();

        // _companyGetAllProductTypes();
        showToast("Product Types are Set!", "success");
        setLoading(false);
      } catch (error) {
        errorHandler(error);
        setLoading(false);
      }
    } else {
      showToast("No Product Type Added Or its invalid Input!", "error");
    }
  };

  return (
    <>
      {/* {console.log("object", client)} */}
      <Card>
        <CardBody>
          <legend className="col-form-label">
            Select which product type to show to client
          </legend>
          <FormGroup check>
            <Label for="all" check>
              <Input
                id="all"
                type="radio"
                name="radio"
                value="all"
                onChange={handleOptionChange}
                checked={selectedOption === "all"}
              />{" "}
              All
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label for="customSelect" check>
              <Input
                id="customSelect"
                type="radio"
                name="radio"
                value="customSelect"
                onChange={handleOptionChange}
                checked={selectedOption === "customSelect"}
              />{" "}
              Custom Select
            </Label>
          </FormGroup>
          {console.log("object", customProducts)}
          {selectedOption === "customSelect" && (
            <>
              <Nav tabs>
                {Object.entries(customProducts).map(
                  ([category, types], index) => {
                    // console.log("bb",category,types)
                    return (
                      <>
                        <NavItem>
                          <NavLink
                            className={activeTab === index ? "active" : ""}
                            onClick={() => {
                              _toggleTab(category, index);
                            }}
                          >
                            {category}
                          </NavLink>
                        </NavItem>
                      </>
                    );
                  }
                )}
              </Nav>
              <TabContent activeTab={activeTab}>
                {/* basic detail page */}
                <TabPane tabId={activeTab} className="pt-4">
                  <Row>
                    {Object.keys(customProducts).length > 0 &&
                      customProducts[key].map((loan, typeIndex) => (
                        <Col xl={6}>
                          <FormGroup className="d-flex align-items-center">
                            <CustomInput
                              type="checkbox"
                              id={`exampleCustomCheckbox${typeIndex}`}
                              //  checked={loan.}
                              checked={customSelectedProducts.some(
                                (selectedLoan) =>
                                  selectedLoan.productType === loan
                              )}
                              onChange={(event) =>
                                _onChangeFormField(
                                  "loanType",
                                  loan
                                  // event.target.checked
                                )
                              }
                            />
                            <Input value={loan} disabled />
                          </FormGroup>
                        </Col>
                      ))}
                  </Row>
                </TabPane>
              </TabContent>
            </>
          )}
          <div className="text-center">
          <Button
            color="primary"
            className="pl-3 mt-3"
            onClick={() => _onSaveProductType()}
            disabled={loading ? true : false}
          >
            Save
          </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
};

export default CustomProductVisibility;
