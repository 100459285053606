import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  FormGroup,
  Label,
  Row,
  Col,
  CustomInput,
} from "reactstrap";

import ReactDatetime from "react-datetime";
import {
  capitalize,
  errorHandler,
  formatDateTimeFlexible,
  formattedStr,
  formatTime24hour,
  showToast,
} from "../../../helper-methods";
import { dayNames, FrequencyEnum } from "../../../config";
import { updateSigningCompanySetting } from "../../../http/http-calls";
// import SettingFormfields from "../microComponents/settingFormfields";

const initialValues = {
  frequency: "1stDayOfMonth",
  day: "",
  time: "10:00",
  isActive: false,
};
export default function SettingAgentPayPeriod({
  companySettings,
  getAllCompanySettings,
}) {
  const [agentPayPeriodAutomationSetting, setInvoiceAutomationSetting] =
    useState(initialValues);
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const _onChangeFormFields = (key, value, index) => {
    // const newArray = agentPayPeriodAutomationSetting.map((item, i) => {
    //   if (index === i) {
    //     return { ...item, [key]: value };
    //   } else {
    //     return item;
    //   }
    // });
    const updatedObj = { ...agentPayPeriodAutomationSetting };
    const newIsDirty = { ...isDirty };

    updatedObj[key] = value;
    if (
      value === "1stDayOfMonth" ||
      value === "lastDayOfMonth" ||
      value === "15thOfMonth" ||
      value === "biWeekly" ||
      value === "eachOrder"
    ) {
      updatedObj["day"] = "";
    }
    if (value === "eachOrder") {
      updatedObj["time"] = "10:00";
    }

    setInvoiceAutomationSetting(updatedObj);
    setIsDirty(newIsDirty);
    _validateForm(updatedObj, newIsDirty);
  };

  const _settingAgentPayPeriod = async () => {
    const newIsDirty = { ...isDirty };
    Object.keys(isDirty).forEach((each) => (newIsDirty[each] = true));

    const error = await _validateForm(
      agentPayPeriodAutomationSetting,
      newIsDirty
    );

    if (error) {
      setLoading(true);
      setLoading(true);

      let payload = {
        autoSendAgentPayPeriodBreakdownSetting: {
          frequency: agentPayPeriodAutomationSetting.frequency,
          day: agentPayPeriodAutomationSetting.day.trim().length
            ? agentPayPeriodAutomationSetting.day
            : undefined,
          time:
            agentPayPeriodAutomationSetting.frequency !== "eachOrder"
              ? agentPayPeriodAutomationSetting.time
              : undefined,
          isActive: agentPayPeriodAutomationSetting?.isActive || false,
        },
      };
      try {
        await updateSigningCompanySetting(payload);
        getAllCompanySettings();
        showToast("Settings are updated", "success");
        setLoading(false);
      } catch (error) {
        errorHandler(error);
        setLoading(false);
      }
    }
  };

  // eslint-disable-next-line no-unused-vars
  const _addInvoiceAutomationSetting = () => {
    const newAgentPayPeriodAutomationSetting = [
      ...agentPayPeriodAutomationSetting,
    ];

    newAgentPayPeriodAutomationSetting.push(initialValues);
    setInvoiceAutomationSetting(newAgentPayPeriodAutomationSetting);
  };

  const _deleteInvoiceAutomationSetting = (index) => {
    const newAgentPayPeriodAutomationSetting = [
      ...agentPayPeriodAutomationSetting,
    ];

    newAgentPayPeriodAutomationSetting.splice(index, 1);
    setInvoiceAutomationSetting(newAgentPayPeriodAutomationSetting);
  };

  const _validateForm = (newFormFields, isDirty) => {
    return new Promise((resolve, reject) => {
      const error = { ...errors };
      let isFormValid = true;

      Object.keys(newFormFields).forEach((fieldName) => {
        switch (fieldName) {
          case "frequency": {
            if (!newFormFields[fieldName].trim().length && isDirty[fieldName]) {
              error[fieldName] = "*Required";
              isFormValid = false;
            } else {
              delete error[fieldName];
              isDirty[fieldName] = false;
            }
            break;
          }
          case "day": {
            if (
              (newFormFields["frequency"] === "weekly" ||
                newFormFields["frequency"] === "biWeekly" ||
                newFormFields["frequency"] === "monthly") &&
              isDirty[fieldName]
            ) {
              if (!newFormFields[fieldName].trim().length) {
                error[fieldName] = "*Required";
                isFormValid = false;
              } else {
                delete error[fieldName];
                isDirty[fieldName] = false;
              }
            } else {
              delete error[fieldName];
              isDirty[fieldName] = false;
            }

            break;
          }

          default: {
          }
        }
      });
      setIsDirty(isDirty);
      setErrors(error);
      resolve(isFormValid);
    });
  };

  useEffect(() => {
    if (companySettings?.autoSendAgentPayPeriodBreakdownSetting) {
      setInvoiceAutomationSetting({
        frequency:
          companySettings?.autoSendAgentPayPeriodBreakdownSetting?.frequency ||
          "",
        day: companySettings?.autoSendAgentPayPeriodBreakdownSetting?.day || "",
        time:
          companySettings?.autoSendAgentPayPeriodBreakdownSetting?.time ||
          "10:00",
        isActive:
          companySettings?.autoSendAgentPayPeriodBreakdownSetting?.isActive ||
          false,
      });
    }
  }, [companySettings]);
  return (
    <>
      {/* <h6 className="mt-4 mb-3">Autosend Agent Pay Breakdown</h6> */}
      {/* <Button className="btn" onClick={() => _addInvoiceAutomationSetting()}>
        Add
      </Button> */}
      {/* {agentPayPeriodAutomationSetting.map((item, index) => ( */}
      <div className="invoicingFrequency">
        <Row>
          <Col xl={6} md={6}>
            <FormGroup className="floatingLabel custom-select-wrapper">
              <Input
                type="select"
                name="frequency"
                value={agentPayPeriodAutomationSetting.frequency}
                onChange={(e) =>
                  _onChangeFormFields("frequency", e.target.value)
                }
              >
                {FrequencyEnum.map((item) => (
                  <option value={item}>{formattedStr(item)}</option>
                ))}
              </Input>
              <Label>Select</Label>
            </FormGroup>
          </Col>
          <Col xl={6} md={6}>
            <FormGroup
              className={`floatingLabel custom-select-wrapper ${
                agentPayPeriodAutomationSetting.frequency === "1stDayOfMonth" ||
                agentPayPeriodAutomationSetting.frequency ===
                  "lastDayOfMonth" ||
                agentPayPeriodAutomationSetting.frequency === "15thOfMonth" ||
                agentPayPeriodAutomationSetting.frequency === "biWeekly" ||
                agentPayPeriodAutomationSetting.frequency === "eachOrder"
                  ? "xyzabc"
                  : ""
              }`}
            >
              <Input
                type="select"
                name="day"
                value={agentPayPeriodAutomationSetting.day}
                onChange={(e) => _onChangeFormFields("day", e.target.value)}
                disabled={
                  agentPayPeriodAutomationSetting.frequency ===
                    "1stDayOfMonth" ||
                  agentPayPeriodAutomationSetting.frequency ===
                    "lastDayOfMonth" ||
                  agentPayPeriodAutomationSetting.frequency === "15thOfMonth" ||
                  agentPayPeriodAutomationSetting.frequency === "biWeekly" ||
                  agentPayPeriodAutomationSetting.frequency === "eachOrder"
                }
              >
                <option>Select</option>
                {dayNames.map((item, index) => (
                  <option value={item.value} key={index}>
                    {capitalize(item.value)}
                  </option>
                ))}
              </Input>
              <Label>Start Day</Label>

              {errors?.day && (
                <div className="validation-error">{errors?.day}</div>
              )}
            </FormGroup>
          </Col>
          <Col xl={6} md={6}>
            <FormGroup className="floatingLabel valueAdded">
              <ReactDatetime
                dateFormat={false}
                inputProps={{
                  className: "form-control customTimeWrap",
                  placeholder: "Select Time",
                  // value: agentPayPeriodAutomationSetting.time,
                  value: formatDateTimeFlexible(
                    agentPayPeriodAutomationSetting.time,
                    "HH:mm",
                    "hh:mm A"
                  ),
                  disabled:
                    agentPayPeriodAutomationSetting.frequency === "eachOrder",
                }}
                onChange={(e) =>
                  e &&
                  e > 0 &&
                  _onChangeFormFields(
                    "time",
                    formatTime24hour(new Date(e._d).toISOString())
                  )
                }
                timeConstraints={{ minutes: { step: 15 } }}
                timeFormat="HH:mm A"
                closeOnSelect={true}
              />
              <Label>Time</Label>
              {errors?.time && (
                <div className="validation-error">{errors?.time}</div>
              )}
            </FormGroup>
          </Col>
          <Col md="6" xl="6">
            <CustomInput
              type="switch"
              id="agentPayPeriodCustomSwitch"
              name="agentPayPeriodCustomSwitch"
              label=""
              className="mt-2"
              checked={agentPayPeriodAutomationSetting?.isActive}
              onChange={(e) =>
                _onChangeFormFields("isActive", e.target.checked)
              }
            />
          </Col>
          {agentPayPeriodAutomationSetting.length > 1 && (
            <Button
              className="btn btn-danger"
              disabled={loading}
              onClick={() => _deleteInvoiceAutomationSetting()}
            >
              Delete
            </Button>
          )}
        </Row>
        <div className="text-center mt-3">
          <Button
            color="primary"
            size="lg"
            disabled={loading}
            onClick={() => _settingAgentPayPeriod()}
          >
            Save
          </Button>
        </div>
      </div>
    </>
  );
}
