import React from "react";
import Pagination from "react-js-pagination";

const TablePagination = ({
  data,
  dataCount,
  pageNumber,
  pageSize,
  onPageChange,
  isPageStartFromOne = false,
}) => {
  if (!data?.length || !dataCount) {
    return <></>;
  }

  return (
    <div className="paginationWrap">
      <span className="showng_entries">
        Showing {(pageNumber - 1) * pageSize + 1} -{" "}
        {(pageNumber - 1) * pageSize + data?.length} entries out of {dataCount}
      </span>
      <Pagination
        activePage={isPageStartFromOne ? pageNumber : pageNumber + 1}
        itemsCountPerPage={pageSize}
        totalItemsCount={dataCount}
        pageRangeDisplayed={5}
        itemClass="page-item"
        linkClass="page-link"
        onChange={(pageNumber) =>
          onPageChange(isPageStartFromOne ? pageNumber : pageNumber - 1)
        }
      />
    </div>
  );
};

export default TablePagination;
