import React, { Component } from "react";
import { connect } from "react-redux";
import { Elements } from "react-stripe-elements";
import { Button, CustomInput } from "reactstrap";
import { deepClone, errorHandler, showToast } from "../../../helper-methods";
import {
  fetchAllCards,
  makeCardDefault,
  addCardAndMakeDefault,
  deleteCard,
} from "../../../http/http-calls";
import StripeElement from "./stripeElement";

class Payment extends Component {
  state = {
    cards: [],
    preferredType: "exisiting", // 'new'
    isLoaderActive: false,
    defaultCard: null,
    selectedCard: null,
    error: null,
  };

  _onTypeChange = (preferredType = "new", selectedCard = null) => {
    this.setState({
      preferredType,
      selectedCard,
    });
  };

  componentDidMount = async () => {
    try {
      await this._getDefaultCard();
    } catch (error) {
      this.setState({ error });
      errorHandler(error);
    }
  };

  componentWillUnmount = () => {
    this._hideLoader();
  };

  componentDidUpdate(prevProps) {
    if (this.props.loading !== prevProps.loading) {
      // console.log("Parent value has changed:", this.props.loading);
      this.setState({
        isLoaderActive: this.props.loading,
      });
    }
  }

  _getDefaultCard = () => {
    return new Promise(async (resolve, reject) => {
      this._showLoader();

      try {
        let res = await fetchAllCards();
        if (res.cards?.length) {
          this.setState({ cards: res.cards });
          await this._setDefaultCard(res.cards);
          this._hideLoader();
          resolve();
        } else {
          // No cards available
          this.setState({ preferredType: "new", defaultCard: null }, () => {
            this._hideLoader();
            resolve();
          });
        }
      } catch (error) {
        this._hideLoader();
        reject(error);
      }
    });
  };

  _setDefaultCard = (cards) => {
    return new Promise((resolve, reject) => {
      let defaultCard = cards.find((card) => card.isDefault);

      this.setState({ defaultCard, selectedCard: defaultCard }, () => {
        resolve();
      });
    });
  };

  _onDismiss = () => {
    // Reset state
    this.setState({
      preferredType: "exisiting",
      isLoaderActive: false,
    });
    this.props.onDismiss();
  };

  _showLoader = () => {
    if (this.props.loading) {
      this.props.manageLoading(true);
    }
    this.setState({ isLoaderActive: true });
  };

  _hideLoader = () => {
    if (this.props.loading) {
      this.props.manageLoading(false);
    }
    this.setState({ isLoaderActive: false });
  };

  _processPayment = async () => {
    const { preferredType, selectedCard } = this.state;
    if (preferredType === "new") {
      // Try to add card
      this._addCard();
    } else {
      // preferredType === 'existing'
      if (selectedCard) {
        try {
          this.setState({ isLoaderActive: true });
          await this._makeCardAsDefault();
        } catch (error) {
          this.setState({ isLoaderActive: false });
        }
      }
      this.props.onPaymentConfirmation();
    }
  };

  _makeCardAsDefault = () => {
    const { selectedCard } = this.state;

    const { type: userType } = this.props.userData;

    return new Promise((resolve, reject) => {
      makeCardDefault(selectedCard.id, userType)
        .then((res) => {
          if (!res.error) {
            resolve();
          }
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  };

  _onStripeError = (e) => {
    this._hideLoader();
  };

  _onCardAdded = async (payload) => {
    try {
      // Add card
      await addCardAndMakeDefault(payload.token.id);
      // Card added
      // Process payment
      this.props.onPaymentConfirmation();
    } catch (error) {
      errorHandler(error);
      this._hideLoader();
    }
  };

  _deleteCard = (cardId) => {
    this._showLoader();

    deleteCard(cardId)
      .then(async (res) => {
        await this._getDefaultCard();
        this._hideLoader();
      })
      .catch((error) => {
        errorHandler(error);
        this._hideLoader();
      });
  };

  render() {
    const {
      preferredType,
      isLoaderActive,
      defaultCard,
      cards,
      error,
      selectedCard,
    } = deepClone(this.state);

    const { cancelButton, submitButtonText } = this.props;
    return (
      <>
        <div className="saveCardList">
          {defaultCard
            ? cards
                .filter((item) => item.object === "card")
                .map((card) => (
                  <>
                    {/* <div className="form-check-radio mb-3" key={card.id}>
                    <Label
                      check
                      className="cursorPointer"
                      htmlFor={`cards_data_${card.id}`}>
                      <Input
                        type="radio"
                        name="cardType"
                        value="exisiting"
                        checked={
                          preferredType === "exisiting" &&
                          selectedCard.id === card.id
                            ? true
                            : false
                        }
                        onChange={() => this._onTypeChange("exisiting", card)}
                        id={`cards_data_${card.id}`}
                      />

                      {card.isDefault
                        ? `Primary Card ending with ${card.last4} (${
                            card.exp_month
                          }/${card.exp_year.toString().substr(2)})`
                        : `Card ending with ${card.last4} (${
                            card.exp_month
                          }/${card.exp_year.toString().substr(2)})`}

                      <span className="form-check-sign" />
                    </Label>
                    {!card.isDefault ? (
                      <Button
                        color="link"
                        title="Remove Card"
                        className="mb-0 ml-2"
                        style={{ marginTop: -3 }}
                        onClick={() => this._deleteCard(card.id)}>
                        <i className="fa fa-trash-o text-danger" />
                      </Button>
                    ) : null}
                  </div> */}
                    <div className="CardDetail">
                      <CustomInput
                        key={card.id}
                        checked={
                          preferredType === "exisiting" &&
                          selectedCard.id === card.id
                            ? true
                            : false
                        }
                        onChange={() => this._onTypeChange("exisiting", card)}
                        id={`cards_data_${card.id}`}
                        type="radio"
                        label={
                          card.isDefault
                            ? `Primary Card ending with ${card.last4} (${
                                card.exp_month
                              }/${card.exp_year.toString().substr(2)})`
                            : `Card ending with ${card.last4} (${
                                card.exp_month
                              }/${card.exp_year.toString().substr(2)})`
                        }
                      />
                      {!card.isDefault ? (
                        <Button
                          color="link"
                          title="Remove Card"
                          className="mb-0 ml-2"
                          style={{ marginTop: -3 }}
                          onClick={() => this._deleteCard(card.id)}
                        >
                          <img
                            src={
                              require("../../../assets/img/deleteIcon.svg")
                                .default
                            }
                            alt="delete"
                          />
                        </Button>
                      ) : null}
                    </div>
                  </>
                ))
            : null}
          <div className="CardDetail">
            {defaultCard ? (
              <>
                <CustomInput
                  type="radio"
                  id="radio2"
                  onChange={() => this._onTypeChange("new")}
                  checked={preferredType === "new" ? true : false}
                  name="customRadio"
                  label="Add New Card"
                />
              </>
            ) : null}
          </div>
        </div>
        <>
          {preferredType === "new" ? (
            <div className="stripeWrapper">
              <Elements>
                <StripeElement
                  {...this.props}
                  onCardAdded={(payload) => this._onCardAdded(payload)}
                  onStripeError={this._onStripeError}
                  showLoader={this._showLoader}
                  hidePostalCode={false}
                  hideLoader={this._hideLoader}
                  addCard={(ref) => (this._addCard = ref)}
                  className="stripeCard"
                />
              </Elements>
            </div>
          ) : null}
        </>
        <div className="mt-4 text-center">
          {cancelButton && (
            <Button
              color="primary"
              outline
              className="mr-2"
              onClick={() => this.props.onCancelButton()}
            >
              Cancel
            </Button>
          )}

          <Button
            color="primary"
            disabled={error || isLoaderActive ? true : false}
            onClick={() => this._processPayment()}
          >
            {submitButtonText ? submitButtonText : "Save & Pay Now"}
          </Button>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps, null)(Payment);
