import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  Row,
  Col,
  FormGroup,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CustomInput,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import {
  capitalize,
  formatDate,
  showToast,
  formatDateAndTimeForIncomeList,
  errorHandler,
} from "../../../helper-methods";
import {
  agentCreateIncome,
  agentGetAllUnregisteredClient,
  agentUpdateNotarialLog,
  createNotarialLog,
} from "../../../http/http-calls";

class AddNotarialModalAgent extends Component {
  state = {
    unregisterClientList: [],
    formFields: {
      date: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      purpose: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      amountPerAct: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      signer: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      notarialAct: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      clientEmail: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      totalPotential: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      agentFee: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
    },
    loading: false,
  };

  _resetStateModal = () => {
    this.setState({
      formFields: {
        date: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        purpose: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        signer: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        clientEmail: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        amountPerAct: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        notarialAct: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        clientEmail: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        totalPotential: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        agentFee: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
      },
      loading: false,
    });
  };

  _closeModal = () => {
    this._resetStateModal();
    this.props.toggle();
  };

  _agentGetAllUnregisteredClient = () => {
    agentGetAllUnregisteredClient()
      .then((res) => {
        console.log("res: ", res);
        this.setState({ unregisterClientList: res?.clients });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  componentDidUpdate = (prevProps) => {
    const { isOpen, data } = this.props;
    const { formFields } = this.state;
    console.log("rrtd", data);
    if (isOpen && isOpen !== prevProps.isOpen && data) {
      // this._agentGetAllUnregisteredClient();
      formFields["date"].value = data?.closingAppointmentDate;
      formFields["signer"].value =
        data?._borrower[0]?.name?.full ||
        data?._closing?._borrower[0]?.name?.full;
      formFields["purpose"].value = data?.isManuallyCreatedByAgent
        ? data.purpose
        : data?._closing
        ? data?._closing?.fileNumber
        : data.purpose;

      formFields["clientEmail"].value = !data.isManuallyCreatedByAgent
        ? data?._closing?._signingCompany
          ? data._closing._signingCompany.email
          : data?._closing?._client?.clientId || data?._closing?._client?.email
        : data._signingCompany
        ? data?._signingCompany?.email || data?.clientEmail
        : data?._assistant
        ? data?._assistant?.email
        : data?._client?.clientId
        ? data?._client?.clientId
        : data?._client?.email;
      // : data?.clientId
      // ? data.clientId
      // : data.clientEmail;

      // !data.isManuallyCreatedByAgent
      //   ? data?_closing?._signingCompany
      //     ? data?_closing?._signingCompany?.email
      //     : data?._closing?._client?.clientId||data?._closing?._client?.email
      //   : data?.clientId
      //   ? data?.clientId
      //   : data?.clientEmail;
      formFields["notarialAct"].value = data?.notarialActs?.toString();
      formFields["amountPerAct"].value = data?.amountPerAct?.toString();
      formFields["agentFee"].value = data?.isManuallyCreatedByAgent
        ? data?.agentFee?.toString()
        : data?._closing
        ? data?._closing?.agentFee?.toString()
        : "N/A";

      console.log("fomefields ", formFields);
      this.setState({
        formFields,
      });
    }

    if (isOpen && isOpen !== prevProps.isOpen) {
      this._agentGetAllUnregisteredClient();
    }
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty && formFields[key].isValidate) {
          switch (key) {
            case "date": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "notarialAct":
            case "amountPerAct": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                if (
                  isNaN(formFields[key].value) ||
                  Number(formFields[key].value) < 0
                ) {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Amount must be a positive number";
                  isFormValid = false;
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "purpose":
            case "clientEmail": {
              if (formFields[key].value?.trim().length) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });
      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _onChangeFormField = (fieldName, value) => {
    // console.log(fieldName, value);
    const { formFields } = this.state;
    if (
      (fieldName === "notarialAct" ||
        fieldName === "amountPerAct" ||
        fieldName === "agentFee") &&
      value?.trim()?.length
    ) {
      if (value?.split(".")?.[0]?.trim()?.length <= 4) {
        if (value?.split(".")?.[1]?.length > 2) {
          return;
        }
      } else {
        return;
      }
    }

    if (fieldName === "amount") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }
    if (fieldName === "company") {
      formFields[fieldName].value = value;
      formFields["clientId"].value = "";
    }

    if (fieldName === "date") {
      formFields[fieldName].value = value ? new Date(value).toISOString() : "";
    } else {
      formFields[fieldName].value = value;
    }

    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // Validation
      this._validateForm();
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _createNotarialLog = (payload) => {
    createNotarialLog(payload)
      .then((res) => {
        this._closeModal();
        showToast("Notarial Log Created", "success");
        this.props.resetDetails();
      })
      .catch((error) => {
        this.setState({ loading: false });
        errorHandler(error);
      });
  };

  _updateNotarialLog = (id, payload) => {
    agentUpdateNotarialLog(id, payload)
      .then((res) => {
        this._closeModal();
        showToast("Notarial Log Updated", "success");
        this.props.resetDetails();
      })
      .catch((error) => {
        this.setState({ loading: false });
        errorHandler(error);
      });
  };

  _onSubmit = async (e) => {
    try {
      if (e) e.preventDefault();

      await this._markAllFieldDirty();

      const isFormValid = await this._validateForm();

      if (isFormValid) {
        this.setState({ loading: true });

        const { formFields, unregisterClientList } = this.state;

        const payload = {
          closingAppointmentDate: formatDateAndTimeForIncomeList(
            formFields.date.value
          ),
          purpose: formFields.purpose.value?.trim(),
          clientEmail: formFields.clientEmail.value?.trim(),
          notarialActs: Number(formFields.notarialAct.value),
          amountPerAct: Number(formFields.amountPerAct.value),
          // agentFee: Number(formFields.agentFee.value),
          // notes: formFields.notes.value?.trim() || "",
        };
        if (formFields?.agentFee?.value?.length) {
          payload["agentFee"] = Number(formFields?.agentFee?.value);
        }

        if (formFields.signer.value?.trim()) {
          let borrower = [];

          let temp = formFields?.signer?.value?.trim()?.split(" ");
          let firstName = temp[0];
          temp.shift();
          let lastName = temp?.join(" ");
          let tempBorrower = {
            name: {
              first: firstName,
              last: lastName || "",
            },
            _id: this.props.data?._borrower[0]?.id,
          };
          borrower.push(tempBorrower);

          payload["borrower"] = borrower;
        }

        // console.log("rrt", this.props);
        console.log("payload: ", payload);
        if (this.props.data !== null) {
          this._updateNotarialLog(this.props.data.id, payload);
        } else {
          this._createNotarialLog(payload);
        }
      }
    } catch (error) {
      console.log("ssd", error);
      this.setState({ loading: false });
      errorHandler(error);
    }
  };

  _isNumber = (e) => {
    var charCode = e.which ? e.which : e.keyCode;

    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return e.preventDefault();
    return;
  };

  render() {
    const { formFields, loading, unregisterClientList } = this.state;

    const {
      isOpen,
      signingCompany,
      //  unregisterClientList
      data,
    } = this.props;

    console.log("w2456ytre", formFields.clientEmail.value);
    // console.log("unregisterClientList", unregisterClientList);
    console.log({ data });
    return (
      <Modal isOpen={isOpen} toggle={this._closeModal} backdrop="static">
        <ModalHeader toggle={this._closeModal}>
          {this.props.data ? "Edit" : "Add"} Notarial
        </ModalHeader>
        <ModalBody>
          <Row form>
            <Col sm="12">
              <FormGroup
                className={`floatingLabel ${
                  formFields.date.value || this.state.isOpenReactDatetime
                    ? "valueAdded"
                    : ""
                }`}
              >
                <ReactDatetime
                  inputProps={{
                    className: "form-control",
                    placeholder: " ",
                    value: formatDate(formFields.date.value),
                    disabled:
                      this.props.data && !data?.isManuallyCreatedByAgent,
                  }}
                  onChange={(e) => this._onChangeFormField("date", e._d)}
                  closeOnSelect={true}
                  isValidDate={(current) => current.isBefore(new Date())}
                  timeFormat={false}
                  onOpen={() => this.setState({ isOpenReactDatetime: true })}
                  onClose={() => this.setState({ isOpenReactDatetime: false })}
                />
                <Label>Date</Label>
                {formFields.date.error && (
                  <div className="validation-error">
                    {formFields.date.error}
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="floatingLabel">
                <Input
                  type="input"
                  // rows="3"
                  value={formFields.purpose.value}
                  name="notes"
                  onChange={(event) =>
                    this._onChangeFormField("purpose", event.target.value)
                  }
                  placeholder=" "
                  disabled={this.props.data && !data?.isManuallyCreatedByAgent}
                />
                <Label>File Number</Label>
                {formFields.purpose.error && (
                  <div className="validation-error">
                    {formFields.purpose.error}
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="floatingLabel">
                <Input
                  type="text"
                  value={formFields?.signer?.value}
                  onChange={(e) =>
                    this._onChangeFormField("signer", e.target.value)
                  }
                  placeholder=" "
                  disabled={this.props.data && !data?.isManuallyCreatedByAgent}
                />
                <Label>Signer</Label>
              </FormGroup>
            </Col>

            <Col sm="12">
              {/* <FormGroup className="floatingLabel">
                <Input
                  type="email"
                  name="clientEmail"
                  // className="mt-3"
                  value={formFields.clientEmail.value}
                  onChange={(event) =>
                    this._onChangeFormField("clientEmail", event.target.value)
                  }
                ></Input>
                <Label>Client Email</Label>
              </FormGroup> */}
              <FormGroup className="floatingLabel">
                <Input
                  type="select"
                  name="clientEmail"
                  className="mt-3"
                  value={formFields.clientEmail.value}
                  onChange={(event) =>
                    this._onChangeFormField("clientEmail", event.target.value)
                  }
                  disabled={
                    (this.props?.data?.clientEmail || this.props?.data?.clientId
                      ? true
                      : false) ||
                    (this.props.data && !data?.isManuallyCreatedByAgent)
                  }
                >
                  <option value="">Select</option>

                  {unregisterClientList?.map((Obj) => (
                    <>
                      <option
                        key={Obj.id}
                        value={Obj?.clientId ? Obj?.clientId : Obj?.email}
                      >
                        {Obj?._signingCompany
                          ? Obj.companyName
                          : Obj?._client
                          ? Obj.name?.full
                          : Obj?._assistant
                          ? // : Obj?._clientAssistant
                            // ? Obj.name?.full
                            // : Obj?._companyAssistant
                            Obj.name?.full
                          : "N/A"}
                      </option>
                    </>
                  ))}
                </Input>
                <Label>Client</Label>
                {formFields.clientEmail.error && (
                  <div className="validation-error">
                    {formFields.clientEmail.error}
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col sm="12">
              <FormGroup className="floatingLabel">
                <Input
                  type="number"
                  // rows="3"
                  value={formFields.notarialAct.value}
                  name="notes"
                  onChange={(event) =>
                    this._onChangeFormField("notarialAct", event.target.value)
                  }
                  placeholder=" "
                />
                <Label>Notarial Act</Label>
                {formFields.notarialAct.error && (
                  <div className="validation-error">
                    {formFields.notarialAct.error}
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup className="floatingLabel">
                <Input
                  type="number"
                  // rows="3"
                  value={formFields.amountPerAct.value}
                  name="notes"
                  onChange={(event) =>
                    this._onChangeFormField("amountPerAct", event.target.value)
                  }
                  placeholder=" "
                />
                <Label>Amount Per Act</Label>
                {formFields.amountPerAct.error && (
                  <div className="validation-error">
                    {formFields.amountPerAct.error}
                  </div>
                )}
              </FormGroup>
            </Col>
            <Col sm="12">
              <FormGroup className="floatingLabel">
                <Input
                  type="number"
                  // rows="3"
                  value={formFields.agentFee.value}
                  name="agentFee"
                  onChange={(event) =>
                    this._onChangeFormField("agentFee", event.target.value)
                  }
                  placeholder=" "
                  onKeyPress={(e) => this._isNumber(e)}
                  disabled={this.props.data && !data?.isManuallyCreatedByAgent}
                />
                <Label>Agent Fee</Label>
                {formFields.agentFee.error && (
                  <div className="validation-error">
                    {formFields.agentFee.error}
                  </div>
                )}
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline size="lg" onClick={this._closeModal}>
            Cancel
          </Button>

          <Button
            color="primary"
            size="lg"
            disabled={loading}
            onClick={this._onSubmit}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
            {this.props.data ? "Update" : "Add"}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default AddNotarialModalAgent;
