import React, { Component } from "react";
import ReactDatetime from "react-datetime";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardTitle,
  CardHeader,
  FormGroup,
  Label,
  Input,
  Button,
  CardSubtitle,
  ListGroup,
  ListGroupItem,
  Progress,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Badge,
  Collapse,
  Table,
} from "reactstrap";
import Swal from "sweetalert2";
import { credentialsData } from "../../../config";
import usaStates from "../../../config/usa_states_titlecase";
import {
  capitalize,
  deepClone,
  formatCurrencyValue,
  formatDate,
  getYesterdayDate,
  openUrlOnNewTab,
  // openUrlOnNewTab,
  showToast,
} from "../../../helper-methods";
import EditCredentialModal from "../components/edit-credential-modal";
import moment from "moment";
export class AgentProfileCredentials extends Component {
  state = {
    currentYear: new Date().getFullYear(),
    collapseCardName: null,
    cardName: null,
    editMode: null,
    // Notary License
    notaryLicense: [
      {
        isChanged: false,
        _id: {
          value: "",
          error: null,
          isDirty: false,
        },
        licenseNumber: {
          value: "",
          error: null,
          isDirty: false,
        },
        state: {
          value: "",
          error: null,
          isDirty: false,
        },
        expiredOn: {
          value: "",
          error: null,
          isDirty: false,
        },
        title: {
          value: "",
          error: null,
          isDirty: false,
        },
        docType: {
          value: "",
          error: null,
          isDirty: false,
        },
        document: {
          file: {
            previewBlob: null,
            uploadData: null,
            type: null,
          },
          error: null,
          isDirty: false,
        },
      },
    ],
    //RON credentials
    ronCredentials: [
      {
        _id: {
          value: "",
          error: null,
          isDirty: false,
        },

        state: {
          value: "",
          error: null,
          isDirty: false,
        },

        title: {
          value: "",
          error: null,
          isDirty: false,
        },
        docType: {
          value: "",
          error: null,
          isDirty: false,
        },
        document: {
          file: {
            previewBlob: null,
            uploadData: null,
            type: null,
          },
          error: null,
          isDirty: false,
        },
      },
    ],
    // Bar License (only for Attorney)
    barLicense: [
      {
        _id: {
          value: "",
          error: null,
          isDirty: false,
        },
        licenseNumber: {
          value: "",
          error: null,
          isDirty: false,
        },
        state: {
          value: "",
          error: null,
          isDirty: false,
        },
        title: {
          value: "",
          error: null,
          isDirty: false,
        },
        docType: {
          value: "",
          error: null,
          isDirty: false,
        },
        document: {
          file: {
            previewBlob: null,
            uploadData: null,
            type: null,
          },
          error: null,
          isDirty: false,
        },
      },
    ],
    // ID Verification (Driving License or Passport etc.)
    idVerification: {
      document: {
        file: {
          previewBlob: null,
          uploadData: null,
          type: null,
        },
        error: null,
        isDirty: false,
      },
    },
    // Background Check Report (report from National Notary Association)
    backgroundCheckReport: {
      nameOfCompany: {
        value: "",
        error: null,
        isDirty: false,
      },
      expiredOn: {
        value: "",
        error: null,
        isDirty: false,
      },
      title: {
        value: "",
        error: null,
        isDirty: false,
      },
      docType: {
        value: "",
        error: null,
        isDirty: false,
      },
      document: {
        file: {
          previewBlob: null,
          uploadData: null,
          type: null,
        },
        error: null,
        isDirty: false,
      },
    },
    // E&O Insurance
    eoInsurance: {
      companyName: {
        value: "",
        error: null,
        isDirty: false,
      },
      policyNumber: {
        value: "",
        error: null,
        isDirty: false,
      },
      amount: {
        value: "",
        error: null,
        isDirty: false,
      },
      expiredOn: {
        value: "",
        error: null,
        isDirty: false,
      },
      document: {
        file: {
          previewBlob: null,
          uploadData: null,
          type: null,
        },
        error: null,
        isDirty: false,
      },
    },
    // w-9 Credentials
    w9Credentials: {
      certficationYear: {
        value: new Date().getFullYear()?.toString() || "",
        error: null,
        isDirty: false,
      },
      document: {
        file: {
          previewBlob: null,
          uploadData: null,
          type: null,
        },
        error: null,
        isDirty: false,
      },
    },
    // NNA Certification Signing Agent (optional)
    nnaCertification: {
      document: {
        file: {
          previewBlob: null,
          uploadData: null,
          type: null,
        },
        error: null,
        isDirty: false,
      },
    },
    // LSS Certification Signing Agent (optional)
    lssCertification: {
      document: {
        file: {
          previewBlob: null,
          uploadData: null,
          type: null,
        },
        error: null,
        isDirty: false,
      },
    },
    // MD Title Producer License (optional)
    mdTitleProducerLicense: {
      document: {
        file: {
          previewBlob: null,
          uploadData: null,
          type: null,
        },
        error: null,
        isDirty: false,
      },
    },
    // IN Title Producer License (optional)
    titleProducerLicense: {
      document: {
        file: {
          previewBlob: null,
          uploadData: null,
          type: null,
        },
        error: null,
        isDirty: false,
      },
    },
    // NY Title Closer (optional)
    nyTotalCloser: {
      document: {
        file: {
          previewBlob: null,
          uploadData: null,
          type: null,
        },
        error: null,
        isDirty: false,
      },
    },
    // notary bond (optional)
    notaryBond: {
      document: {
        file: {
          previewBlob: null,
          uploadData: null,
          type: null,
        },
        error: null,
        isDirty: false,
      },
    },
    editCredentialModal: {
      isOpen: false,
      data: null,
    },
  };

  _resetStateModal = () => {
    this.setState(
      {
        cardName: null,
        editMode: null,
        // Notary License
        notaryLicense: [
          {
            _id: {
              value: "",
              error: null,
              isDirty: false,
            },
            licenseNumber: {
              value: "",
              error: null,
              isDirty: false,
            },
            state: {
              value: "",
              error: null,
              isDirty: false,
            },
            expiredOn: {
              value: "",
              error: null,
              isDirty: false,
            },
            title: {
              value: "",
              error: null,
              isDirty: false,
            },
            docType: {
              value: "",
              error: null,
              isDirty: false,
            },
            document: {
              file: {
                previewBlob: null,
                uploadData: null,
                type: null,
              },
              error: null,
              isDirty: false,
            },
          },
        ],
        // Bar License (only for Attorney)
        barLicense: [
          {
            _id: {
              value: "",
              error: null,
              isDirty: false,
            },
            licenseNumber: {
              value: "",
              error: null,
              isDirty: false,
            },
            state: {
              value: "",
              error: null,
              isDirty: false,
            },
            title: {
              value: "",
              error: null,
              isDirty: false,
            },
            docType: {
              value: "",
              error: null,
              isDirty: false,
            },
            document: {
              file: {
                previewBlob: null,
                uploadData: null,
                type: null,
              },
              error: null,
              isDirty: false,
            },
          },
        ],
        //RON credentials
        ronCredentials: [
          {
            _id: {
              value: "",
              error: null,
              isDirty: false,
            },

            state: {
              value: "",
              error: null,
              isDirty: false,
            },

            title: {
              value: "",
              error: null,
              isDirty: false,
            },
            docType: {
              value: "",
              error: null,
              isDirty: false,
            },
            document: {
              file: {
                previewBlob: null,
                uploadData: null,
                type: null,
              },
              error: null,
              isDirty: false,
            },
          },
        ],
        // ID Verification (Driving License or Passport etc.)
        idVerification: {
          document: {
            file: {
              previewBlob: null,
              uploadData: null,
              type: null,
            },
            error: null,
            isDirty: false,
          },
        },
        // Background Check Report (report from National Notary Association)
        backgroundCheckReport: {
          nameOfCompany: {
            value: "",
            error: null,
            isDirty: false,
          },
          expiredOn: {
            value: "",
            error: null,
            isDirty: false,
          },
          title: {
            value: "",
            error: null,
            isDirty: false,
          },
          docType: {
            value: "",
            error: null,
            isDirty: false,
          },
          document: {
            file: {
              previewBlob: null,
              uploadData: null,
              type: null,
            },
            error: null,
            isDirty: false,
          },
        },
        // E&O Insurance
        eoInsurance: {
          companyName: {
            value: "",
            error: null,
            isDirty: false,
          },
          policyNumber: {
            value: "",
            error: null,
            isDirty: false,
          },
          amount: {
            value: "",
            error: null,
            isDirty: false,
          },
          expiredOn: {
            value: "",
            error: null,
            isDirty: false,
          },
          title: {
            value: "",
            error: null,
            isDirty: false,
          },
          docType: {
            value: "",
            error: null,
            isDirty: false,
          },
          document: {
            file: {
              previewBlob: null,
              uploadData: null,
              type: null,
            },
            error: null,
            isDirty: false,
          },
        },
        // w-9 Credentials
        w9Credentials: {
          certficationYear: {
            value: new Date().getFullYear()?.toString() || "",
            error: null,
            isDirty: false,
          },
          document: {
            file: {
              previewBlob: null,
              uploadData: null,
              type: null,
            },
            error: null,
            isDirty: false,
          },
        },
        // NNA Certification Signing Agent (optional)
        nnaCertification: {
          document: {
            file: {
              previewBlob: null,
              uploadData: null,
              type: null,
            },
            error: null,
            isDirty: false,
          },
        },
        // LSS Certification Signing Agent (optional)
        lssCertification: {
          document: {
            file: {
              previewBlob: null,
              uploadData: null,
              type: null,
            },
            error: null,
            isDirty: false,
          },
        },
        // MD Title Producer License (optional)
        mdTitleProducerLicense: {
          document: {
            file: {
              previewBlob: null,
              uploadData: null,
              type: null,
            },
            error: null,
            isDirty: false,
          },
        },
        // IN Title Producer License (optional)
        titleProducerLicense: {
          document: {
            file: {
              previewBlob: null,
              uploadData: null,
              type: null,
            },
            error: null,
            isDirty: false,
          },
        },
        // NY Title Closer (optional)
        nyTotalCloser: {
          document: {
            file: {
              previewBlob: null,
              uploadData: null,
              type: null,
            },
            error: null,
            isDirty: false,
          },
        },
        // notary bond (optional)
        notaryBond: {
          document: {
            file: {
              previewBlob: null,
              uploadData: null,
              type: null,
            },
            error: null,
            isDirty: false,
          },
        },
      },
      () => {
        if (this.props.agentDetails.barLicense.length > 0) {
          this._setCredentialFormdata("barLicense");
        }
        if (this.props.agentDetails.notaryLicense.length > 0) {
          this._setCredentialFormdata("notaryLicense");
        }
        if (this.props.agentDetails.ronCredentials.length > 0) {
          this._setCredentialFormdata("ronCredentials");
        }
      }
    );
  };

  _setCredentialFormdata = (credentialName) => {
    const { agentDetails } = deepClone(this.props);

    const newState = deepClone({ ...this.state });

    if (
      credentialName !== "notaryLicense" &&
      credentialName !== "barLicense" &&
      credentialName !== "ronCredentials"
    ) {
      Object.keys(newState[credentialName]).forEach((key) => {
        if (key === "document") {
          newState[credentialName][key].file["previewBlob"] =
            agentDetails[credentialName] && agentDetails[credentialName].url
              ? agentDetails[credentialName].url
              : "";
        } else if (key === "amount") {
          newState[credentialName][key].value =
            agentDetails[credentialName] && agentDetails[credentialName][key]
              ? agentDetails[credentialName][key].toString()
              : "";
        } else {
          newState[credentialName][key].value =
            agentDetails[credentialName] && agentDetails[credentialName][key]
              ? agentDetails[credentialName][key]
              : "";
        }
        newState[credentialName][key].error = null;
        newState[credentialName][key].isDirty = false;
      });
      this.setState({ [credentialName]: newState[credentialName] });
    } else if (credentialName === "ronCredentials") {
      const data = [];
      agentDetails[credentialName].map((each, index) => {
        const cred = {
          _id: {
            value: "",
            error: null,
            isDirty: false,
          },

          state: {
            value: "",
            error: null,
            isDirty: false,
          },

          title: {
            value: "",
            error: null,
            isDirty: false,
          },
          docType: {
            value: "",
            error: null,
            isDirty: false,
          },
          document: {
            file: {
              previewBlob: null,
              uploadData: null,
              type: null,
            },
            error: null,
            isDirty: false,
          },
        };
        Object.keys(cred).forEach((key) => {
          cred["isChanged"] = false;
          if (key === "document") {
            cred[key].file["previewBlob"] =
              agentDetails[credentialName] && each.url ? each.url : "";
          } else if (key === "amount") {
            cred[key].value = each && each[key] ? each[key].toString() : "";
          } else {
            cred[key].value = each && each[key] ? each[key] : "";
          }
          cred[key].error = null;
          cred[key].isDirty = false;
        });
        return data.push(cred);
      });

      this.setState({ [credentialName]: data });
    } else {
      const data = [];
      agentDetails[credentialName].map((each, index) => {
        const cred = {
          _id: {
            value: "",
            error: null,
            isDirty: false,
          },
          licenseNumber: {
            value: "",
            error: null,
            isDirty: false,
          },
          state: {
            value: "",
            error: null,
            isDirty: false,
          },
          expiredOn: {
            value: "",
            error: null,
            isDirty: false,
          },
          title: {
            value: "",
            error: null,
            isDirty: false,
          },
          docType: {
            value: "",
            error: null,
            isDirty: false,
          },
          document: {
            file: {
              previewBlob: null,
              uploadData: null,
              type: null,
            },
            error: null,
            isDirty: false,
          },
        };
        Object.keys(cred).forEach((key) => {
          cred["isChanged"] = false;
          if (key === "document") {
            cred[key].file["previewBlob"] =
              agentDetails[credentialName] && each.url ? each.url : "";
          } else if (key === "amount") {
            cred[key].value = each && each[key] ? each[key].toString() : "";
          } else {
            cred[key].value = each && each[key] ? each[key] : "";
          }
          cred[key].error = null;
          cred[key].isDirty = false;
        });
        return data.push(cred);
      });

      this.setState({ [credentialName]: data });
    }
  };

  componentDidUpdate = (prevProps, prevState) => {
    const { activeTab, agentDetails } = deepClone(this.props);

    if (
      activeTab === "2" &&
      agentDetails &&
      prevProps.agentDetails &&
      (JSON.stringify(agentDetails) !==
        JSON.stringify(prevProps.agentDetails) ||
        prevProps.activeTab !== "2")
    ) {
      this._resetStateModal();
    }
  };

  _getTitleByCredentialKeyName = (credentialKeyName) => {
    let cardTitle = "";
    switch (credentialKeyName) {
      case "notaryLicense": {
        cardTitle = "Notary License";
        break;
      }
      case "barLicense": {
        cardTitle = "Bar License";
        break;
      }
      case "idVerification": {
        cardTitle = "ID Verification";
        break;
      }
      case "backgroundCheckReport": {
        cardTitle = "Background Check Report";
        break;
      }
      case "eoInsurance": {
        cardTitle = "E&O Insurance";
        break;
      }
      case "w9Credentials": {
        cardTitle = "W-9";
        break;
      }
      case "nnaCertification": {
        cardTitle = "NNA Certification";
        break;
      }
      case "lssCertification": {
        // cardTitle = "LSS Certification";
        cardTitle = "Notary Training Certification";
        break;
      }
      case "mdTitleProducerLicense": {
        cardTitle = "MD Title Producer License";
        break;
      }
      case "titleProducerLicense": {
        cardTitle = "IN Title Producer License";
        break;
      }
      case "nyTotalCloser": {
        cardTitle = "NY Title Closer";
        break;
      }
      case "notaryBond": {
        cardTitle = "Notary Bond";
        break;
      }
      case "ronCredentials": {
        cardTitle = "RON Credentials";
        break;
      }
      default:
    }
    return cardTitle;
  };

  _toggleEditCredential = async (credentialName, isSave = true) => {
    if (!this.props.loading) {
      let { editMode } = deepClone(this.state);

      if (credentialName && editMode !== credentialName) {
        editMode = credentialName;
        this._setCredentialFormdata(credentialName);
      } else {
        if (editMode && isSave) {
          const isSuccess = await this._onSaveCredentail(editMode);
          if (isSuccess) {
            editMode = null;
          }
        } else {
          editMode = null;
        }
      }
      this.setState({ editMode, cardName: null });
    }
  };

  _showAddCard = (cardName) => {
    if (!this.props.loading) {
      if (cardName === this.state.collapseCardName) {
        this.setState({
          collapseCardName: null,
          cardName: null,
          editMode: null,
        });
      } else {
        this.setState({ collapseCardName: cardName, cardName, editMode: null });
      }
    }
  };

  _showViewEditCard = (cardName) => {
    console.log("card", cardName);
    if (!this.props.loading) {
      if (cardName === this.state.collapseCardName) {
        this.setState({
          collapseCardName: null,
          cardName: null,
          editMode: null,
        });
      } else {
        this.setState({
          collapseCardName: cardName,
          cardName: null,
          editMode: null,
        });
      }
    }
  };

  _validateForm = (cardName) => {
    return new Promise((resolve, reject) => {
      const newState = deepClone({ ...this.state });

      let isFormValid = true;

      Object.keys(newState[cardName]).forEach((key) => {
        if (newState[cardName][key].isDirty) {
          switch (key) {
            case "certficationYear":
            case "policyNumber":
            case "companyName":
            case "nameOfCompany":
            case "expiredOn":
            case "state":
            case "licenseNumber": {
              if (
                newState[cardName][key].value &&
                newState[cardName][key].value.trim().length
              ) {
                newState[cardName][key].isDirty = false;
                newState[cardName][key].error = null;
              } else {
                newState[cardName][key].isDirty = true;
                newState[cardName][key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "amount": {
              if (
                newState[cardName][key].value &&
                newState[cardName][key].value.trim().length
              ) {
                if (
                  isNaN(newState[cardName][key].value) ||
                  Number(newState[cardName][key].value) < 1
                ) {
                  newState[cardName][key].isDirty = true;
                  newState[cardName][key].error = "*Invalid Amount";
                  isFormValid = false;
                } else {
                  newState[cardName][key].isDirty = false;
                  newState[cardName][key].error = null;
                }
              } else {
                newState[cardName][key].isDirty = true;
                newState[cardName][key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "document": {
              if (newState.editMode) {
                if (
                  newState[cardName][key].file &&
                  newState[cardName][key].file.previewBlob
                ) {
                  newState[cardName][key].isDirty = false;
                  newState[cardName][key].error = null;
                } else {
                  newState[cardName][key].isDirty = true;
                  newState[cardName][key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                if (
                  newState[cardName][key].file &&
                  newState[cardName][key].file.uploadData
                ) {
                  newState[cardName][key].isDirty = false;
                  newState[cardName][key].error = null;
                } else {
                  newState[cardName][key].isDirty = true;
                  newState[cardName][key].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            default: {
            }
          }
        }
      });

      this.setState({ ...newState }, () => {
        resolve(isFormValid);
      });
    });
  };

  _deleteFile = (cardName) => {
    const newState = deepClone({ ...this.state });
    newState[cardName].document.file = {
      previewBlob: null,
      uploadData: null,
      type: null,
    };
    newState[cardName].document.isDirty = true;
    this.setState({ [cardName]: newState[cardName] }, () => {
      // validation
      this._validateForm(cardName);
    });
  };

  _deleteNotaryFile = (cardName, index) => {
    const newState = deepClone({ ...this.state });
    newState[cardName][index].document.file = {
      previewBlob: null,
      uploadData: null,
      type: null,
    };
    newState[cardName][index].document.isDirty = true;
    this.setState({ [cardName]: newState[cardName] }, () => {
      // validation
      this._validateNotaryLicense(cardName);
    });
  };

  _updateFile = async (cardName, event) => {
    const newState = deepClone({ ...this.state });

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      let objFile = event.target.files[0];
      let objFileType = objFile.type.split("/")[0];
      if (objFileType === "image" || objFile.type.includes("pdf")) {
        newState[cardName].document.file = {
          previewBlob: URL.createObjectURL(objFile),
          uploadData: objFile,
          type: objFileType === "application" ? "pdf" : objFileType,
        };
        newState[cardName].document.isDirty = true;
        this.setState({ [cardName]: newState[cardName] }, () => {
          // validation
          this._validateForm(cardName);
        });
      } else {
        showToast("Only Image or PDF file is allowed", "error");
      }
    }
  };

  _updateNotaryFile = async (cardName, event, index) => {
    const newState = deepClone({ ...this.state });

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      let objFile = event.target.files[0];
      let objFileType = objFile.type.split("/")[0];
      if (objFileType === "image" || objFile.type.includes("pdf")) {
        newState[cardName][index].document.file = {
          previewBlob: URL.createObjectURL(objFile),
          uploadData: objFile,
          type: objFileType === "application" ? "pdf" : objFileType,
        };
        newState[cardName][index].document.isDirty = true;
        this.setState({ [cardName]: newState[cardName] }, () => {
          // validation
          this._validateNotaryLicense(cardName);
        });
      } else {
        showToast("Only Image or PDF file is allowed", "error");
      }
    }
  };

  _onChangeFormField = (cardName, fieldName, value, index) => {
    const newState = deepClone({ ...this.state });
    if (
      cardName !== "notaryLicense" &&
      cardName !== "barLicense" &&
      cardName !== "ronCredentials"
    ) {
      if (fieldName === "amount") {
        if (
          isNaN(value) ||
          (value.includes(".") && value.split(".")[1].length > 2)
        )
          return;
      }

      if (fieldName === "expiredOn")
        newState[cardName][fieldName].value = value
          ? new Date(value).toISOString()
          : "";
      else newState[cardName][fieldName].value = value;

      newState[cardName][fieldName].isDirty = true;

      this.setState({ [cardName]: newState[cardName] }, () => {
        // validation
        this._validateForm(cardName);
      });
    } else {
      newState[cardName][index][fieldName].value = value;
      newState[cardName][index][fieldName].isDirty = true;
      newState[cardName][index]["isChanged"] = true;
      this.setState({ [cardName]: newState[cardName] }, () => {
        // validation
        this._validateNotaryLicense(cardName);
      });
    }
  };

  _markAllFieldDirty = (cardName) => {
    return new Promise((resolve, reject) => {
      const newState = deepClone({ ...this.state });

      Object.keys(newState[cardName]).forEach((e) => {
        newState[cardName][e].isDirty = true;
      });

      this.setState({ [cardName]: newState[cardName] }, () => resolve(true));
    });
  };

  _markDirtyNotaryLicense = () => {
    return new Promise((resolve, reject) => {
      const newState = deepClone({ ...this.state });
      newState.notaryLicense.forEach((each) => {
        Object.keys(each).forEach((e) => {
          each[e].isDirty = true;
        });

        this.setState({ notaryLicense: newState.notaryLicense }, () =>
          resolve(true)
        );
      });
    });
  };
  _markDirtyRonCredentials = () => {
    return new Promise((resolve, reject) => {
      const newState = deepClone({ ...this.state });
      newState.ronCredentials.forEach((each) => {
        Object.keys(each).forEach((e) => {
          console.log(each);
          if (e !== "isChanged") {
            each[e].isDirty = true;
          }
        });

        this.setState({ ronCredentials: newState.ronCredentials }, () =>
          resolve(true)
        );
      });
    });
  };
  _onSaveCredentail = (cardName) => {
    return new Promise(async (resolve, reject) => {
      try {
        if (cardName !== "notaryLicense" && cardName !== "barLicense") {
          cardName === "ronCredentials"
            ? await this._markDirtyRonCredentials()
            : await this._markAllFieldDirty(cardName);
        } else {
          await this._markDirtyNotaryLicense();
        }

        const isFormValid =
          cardName === "notaryLicense"
            ? await this._validateNotaryLicense(cardName)
            : cardName === "ronCredentials"
            ? await this._validateRonCredentials()
            : await this._validateForm(cardName);

        let isSuccess = false;

        if (isFormValid) {
          const newState = deepClone({ ...this.state });
          if (
            cardName !== "notaryLicense" &&
            cardName !== "barLicense" &&
            cardName !== "ronCredentials"
          ) {
            isSuccess = await this.props.onSubmitCredentialDetail(
              cardName,
              newState[cardName]
            );
            if (isSuccess) this.setState({ cardName: null });
          } else {
            for (
              let index = 0;
              index <= newState[cardName].length - 1;
              index++
            ) {
              isSuccess = await this.props.onSubmitCredentialDetail(
                cardName,
                newState[cardName][index]
              );
            }
            if (isSuccess) this.setState({ cardName: null });
          }
        }

        resolve(isSuccess);
      } catch (error) {}
    });
  };

  _isCredentialUploaded = (credentialName) => {
    const { agentDetails } = deepClone(this.props);
    if (
      credentialName === "notaryLicense" ||
      credentialName === "barLicense" ||
      credentialName === "ronCredentials"
    ) {
      return (
        agentDetails &&
        agentDetails[credentialName]?.length &&
        agentDetails[credentialName]?.map((item) => item.url)
      );
    } else {
      return (
        agentDetails &&
        agentDetails[credentialName] &&
        agentDetails[credentialName].url
      );
    }
  };

  _countTotalUnverifiedCredentials = () => {
    const { agentDetails } = deepClone(this.props);
    if (agentDetails) {
      let count;
      let credentialsObject;
      if (agentDetails.workType?.toLowerCase() === "attorney") {
        count = 6;

        credentialsObject = credentialsData.filter((obj) => obj.isRequired);
      } else if (agentDetails.workType?.toLowerCase() === "notary") {
        count = 5;

        credentialsObject = credentialsData.filter(
          (obj) => obj.value !== "barLicense" && obj.isRequired
        );
      }

      credentialsObject.forEach((obj) => {
        if (Array.isArray(agentDetails[obj.value])) {
          let isApproved =
            agentDetails[obj.value].length > 0 &&
            agentDetails[obj.value].every(
              (item) => item.isVerified === "Approved"
            );
          return isApproved ? (count -= 1) : count;
        }

        if (
          agentDetails[obj.value] &&
          agentDetails[obj.value].url &&
          agentDetails[obj.value].isVerified === "Approved"
        ) {
          count--;
        }
      });
      return count;
    }
    return null;
  };

  _addAnotherCard = (cardName) => {
    if (
      cardName === "notaryLicense" &&
      this.props.agentDetails.workingStates.length >
        this.state.notaryLicense.length
    ) {
      let notary = this.state.notaryLicense;

      notary.push({
        licenseNumber: {
          value: "",
          error: null,
          isDirty: false,
        },
        state: {
          value: "",
          error: null,
          isDirty: false,
        },
        expiredOn: {
          value: "",
          error: null,
          isDirty: false,
        },
        title: {
          value: "",
          error: null,
          isDirty: false,
        },
        docType: {
          value: "",
          error: null,
          isDirty: false,
        },
        document: {
          file: {
            previewBlob: null,
            uploadData: null,
            type: null,
          },
          error: null,
          isDirty: false,
        },
      });
      this.setState({ notaryLicense: notary });
    } else if (
      cardName === "barLicense" &&
      this.props.agentDetails.workingStates.length >
        this.state.barLicense.length
    ) {
      let bar = this.state.barLicense;

      bar.push({
        licenseNumber: {
          value: "",
          error: null,
          isDirty: false,
        },
        state: {
          value: "",
          error: null,
          isDirty: false,
        },
        title: {
          value: "",
          error: null,
          isDirty: false,
        },
        docType: {
          value: "",
          error: null,
          isDirty: false,
        },
        document: {
          file: {
            previewBlob: null,
            uploadData: null,
            type: null,
          },
          error: null,
          isDirty: false,
        },
      });

      this.setState({ barLicense: bar });
    } else if (cardName === "ronCredentials") {
      let ron = this.state.ronCredentials;
      ron.push({
        _id: {
          value: "",
          error: null,
          isDirty: false,
        },

        state: {
          value: "",
          error: null,
          isDirty: false,
        },

        title: {
          value: "",
          error: null,
          isDirty: false,
        },
        docType: {
          value: "",
          error: null,
          isDirty: false,
        },
        document: {
          file: {
            previewBlob: null,
            uploadData: null,
            type: null,
          },
          error: null,
          isDirty: false,
        },
      });
      this.setState({ ronCredentials: ron });
    }
  };

  _deleteNotaryLicense = (cardName, index) => {
    if (cardName === "notaryLicense") {
      if (this.state.notaryLicense.length > 1) {
        let notary = this.state.notaryLicense;
        notary.splice(index, 1);
        this.setState({
          notaryLicense: notary,
        });
      }
    } else if (cardName === "barLicense") {
      if (this.state.barLicense.length > 1) {
        let bar = this.state.barLicense;
        bar.splice(index, 1);
        this.setState({
          barLicense: bar,
        });
      }
    } else if (cardName === "ronCredentials") {
      if (this.state.ronCredentials.length > 1) {
        let ron = this.state.ronCredentials;
        ron.splice(index, 1);
        this.setState({
          ronCredentials: ron,
        });
      }
    }
  };

  _validateNotaryLicense = () => {
    return new Promise((resolve, reject) => {
      const newState = deepClone({ ...this.state });

      let isFormValid = true;

      newState.notaryLicense.forEach((key) => {
        Object.keys(key).forEach((each) => {
          if (key[each].isDirty) {
            switch (each) {
              case "certficationYear":
              case "policyNumber":
              case "companyName":
              case "nameOfCompany":
              case "expiredOn": {
                if (key[each].value) {
                  key[each].isDirty = false;
                  key[each].error = null;
                } else {
                  key[each].isDirty = true;
                  key[each].error = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "state":
              case "licenseNumber": {
                if (key[each].value && key[each].value.trim().length) {
                  key[each].isDirty = false;
                  key[each].error = null;
                } else {
                  key[each].isDirty = true;
                  key[each].error = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "document": {
                if (newState.editMode) {
                  if (key[each].file && key[each].file.previewBlob) {
                    key[each].isDirty = false;
                    key[each].error = null;
                  } else {
                    key[each].isDirty = true;
                    key[each].error = "*Required";
                    isFormValid = false;
                  }
                } else {
                  if (key[each].file && key[each].file.uploadData) {
                    key[each].isDirty = false;
                    key[each].error = null;
                  } else {
                    key[each].isDirty = true;
                    key[each].error = "*Required";
                    isFormValid = false;
                  }
                }
                break;
              }
              default: {
              }
            }
          }
        });
      });
      this.setState(
        {
          notaryLicense: newState.notaryLicense,
        },
        () => {
          resolve(isFormValid);
        }
      );
    });
  };

  _validateRonCredentials = () => {
    return new Promise((resolve, reject) => {
      const newState = deepClone({ ...this.state });

      let isFormValid = true;

      newState.ronCredentials.forEach((key) => {
        Object.keys(key).forEach((each) => {
          if (key[each].isDirty) {
            switch (each) {
              case "state": {
                if (key[each].value && key[each].value.trim().length) {
                  key[each].isDirty = false;
                  key[each].error = null;
                } else {
                  key[each].isDirty = true;
                  key[each].error = "*Required";
                  isFormValid = false;
                }
                break;
              }
              case "document": {
                if (newState.editMode) {
                  if (key[each].file && key[each].file.previewBlob) {
                    key[each].isDirty = false;
                    key[each].error = null;
                  } else {
                    key[each].isDirty = true;
                    key[each].error = "*Required";
                    isFormValid = false;
                  }
                } else {
                  if (key[each].file && key[each].file.uploadData) {
                    key[each].isDirty = false;
                    key[each].error = null;
                  } else {
                    key[each].isDirty = true;
                    key[each].error = "*Required";
                    isFormValid = false;
                  }
                }
                break;
              }
              default: {
              }
            }
          }
        });
      });
      this.setState(
        {
          ronCredentials: newState.ronCredentials,
        },
        () => {
          resolve(isFormValid);
        }
      );
    });
  };
  _deleteLicense = (credentialName, each, index) => {
    // console.log("sjhd", credentialName, each, index);
    if (each?._id) {
      let payload = {
        [credentialName]: {
          docType: each.docType,
          expiredOn: each?.expiredOn ? each?.expiredOn : undefined,
          licenseNumber: each?.licenseNumber ? each?.licenseNumber : undefined,
          state: each.state,
          title: each.title,
          _id: each._id,
        },
        type: "remove",
      };

      this.props.deleteLicense(payload, "Deleted Successfully");
      this.setState({
        collapseCardName: null,
      });
    } else {
      this._deleteNotaryLicense(credentialName, index);
    }
  };
  _deleteAlert = (credentialName, each, index) => {
    if (!each?._id) {
      showToast("Document not found", "error");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this document.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        // _deleteDocument(document._id);
        this._deleteLicense(credentialName, each, index);
      }
    });
  };
  _onToggleEditCredentialModal = (isOpen = false, data = null) => {
    console.log("mhjj", isOpen, typeof data);
    this.setState({
      editCredentialModal: {
        isOpen,
        data,
      },
      cardName: typeof data === "string" ? data : null,
    });
    // if (typeof data === "string" || data === null) {
    //   this.setState({
    //     collapseCardName: data || "",
    //   });
    // }
  };
  render() {
    const newState = deepClone({ ...this.state });

    const {
      cardName,
      // eslint-disable-next-line no-unused-vars
      editMode,
      currentYear,
      collapseCardName,
      editCredentialModal,
    } = this.state;
    console.log("qwer", this.state);
    const { agentDetails, loading, percentage } = this.props;

    const credentialUnverifiedCount = this._countTotalUnverifiedCredentials();

    // eslint-disable-next-line no-unused-vars
    const addCredentialCardDetails = cardName && (
      <Card>
        <CardHeader>
          <CardTitle tag="h5">
            Add Your {this._getTitleByCredentialKeyName(cardName)}
          </CardTitle>
          <CardSubtitle tag="h6" className="mb-2 text-muted">
            89% of all signing orders are offered to notaries who already have
            their notary commissions uploaded to their profile
          </CardSubtitle>
          {cardName === "notaryLicense" ||
          cardName === "barLicense" ||
          cardName === "ronCredentials" ? (
            <Button onClick={(e) => this._addAnotherCard(cardName)}>Add</Button>
          ) : null}
        </CardHeader>
        <CardBody>
          {cardName === "notaryLicense"
            ? this.state.notaryLicense.map((item, index) => {
                return (
                  <>
                    <Row className="notaryLicenseRow">
                      <Col>
                        <Row>
                          <Col sm="6" lg="4">
                            <FormGroup>
                              <Label>License Number</Label>
                              <Input
                                type="text"
                                value={item.licenseNumber.value}
                                name="licenseNumber"
                                onChange={(e) =>
                                  this._onChangeFormField(
                                    cardName,
                                    "licenseNumber",
                                    e.target.value,
                                    index
                                  )
                                }
                              />
                              {item?.licenseNumber?.error && (
                                <div className="validation-error">
                                  {item?.licenseNumber?.error}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col sm="6" lg="4">
                            <FormGroup className="custom-select-wrapper">
                              <Label>State</Label>
                              <Input
                                type="select"
                                // value={newState[cardName].state.value}
                                name="state"
                                onChange={(e) =>
                                  this._onChangeFormField(
                                    cardName,
                                    "state",
                                    e.target.value,
                                    index
                                  )
                                }
                              >
                                <option value="">Select</option>
                                {usaStates.map((state) => (
                                  <option
                                    key={state.abbreviation}
                                    value={state.abbreviation}
                                  >
                                    {state.name}
                                  </option>
                                ))}
                              </Input>
                              {item?.state?.error && (
                                <div className="validation-error">
                                  {item?.state?.error}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col sm="6" md="4">
                            <FormGroup>
                              <Label>Expiration Date</Label>
                              <ReactDatetime
                                inputProps={{
                                  className: "form-control",
                                  placeholder: "Select",
                                  // value: formatDate(
                                  //   newState[cardName].expiredOn.value
                                  // ),
                                }}
                                closeOnSelect={true}
                                timeFormat={false}
                                onChange={(e) =>
                                  this._onChangeFormField(
                                    cardName,
                                    "expiredOn",
                                    e._d,
                                    index
                                  )
                                }
                                isValidDate={(current) =>
                                  current.isAfter(getYesterdayDate())
                                }
                              />
                              {item?.expiredOn?.error && (
                                <div className="validation-error">
                                  {item?.expiredOn?.error}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col sm={12}>
                            <div className="d-flex flex-column mb-2 mt-1">
                              <Label className="cursorPointer mb-0">
                                <Input
                                  type="file"
                                  style={{ display: "none" }}
                                  accept="image/x-png,image/gif,image/jpeg,.pdf"
                                  value=""
                                  disabled={loading}
                                  // onChange={(event) => this._updateFile(cardName, event)}
                                  onChange={(e) =>
                                    this._updateNotaryFile(cardName, e, index)
                                  }
                                />
                                <i className="fa fa-upload" /> Attachment
                              </Label>

                              {item.document?.file &&
                                item.document?.file.previewBlob && (
                                  <div className="msgPreview mt-2 ml-0">
                                    <img
                                      src={
                                        item.document.file.type === "image"
                                          ? item.document.file.previewBlob
                                          : require("../../../assets/img/file_icon.png")
                                              .default
                                      }
                                      alt="file Icon"
                                    />
                                    {!loading && (
                                      <Button
                                        onClick={() =>
                                          this._deleteNotaryFile(
                                            cardName,
                                            index
                                          )
                                        }
                                        className="deletePreview"
                                      >
                                        <i className="fa fa-times" />
                                      </Button>
                                    )}

                                    {/* file name */}
                                    <div>
                                      {item.document.file.uploadData &&
                                      item.document.file.uploadData.name
                                        ? item.document.file.uploadData.name
                                        : null}
                                    </div>
                                  </div>
                                )}

                              {item?.document?.error && (
                                <div className="validation-error">
                                  {item?.document?.error}
                                </div>
                              )}

                              {/* <small className="text-muted font-italic">
                  Please add your full notary commission license. We are
                  unable to verify notary stamps or NNA certificates
                </small> */}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="auto">
                        <Button
                          onClick={() =>
                            this._deleteNotaryLicense(cardName, index)
                          }
                          className="deleteNotary"
                          color="link"
                        >
                          <i className="fa fa-trash-o"></i>
                        </Button>
                      </Col>
                    </Row>
                  </>
                );
              })
            : null}

          {/* bar license */}

          {cardName === "barLicense"
            ? this.state.barLicense.map((item, index) => {
                return (
                  <>
                    <Row className="notaryLicenseRow">
                      <Col>
                        <Row>
                          <Col sm="6" lg="4">
                            <FormGroup>
                              <Label>License Number</Label>
                              <Input
                                type="text"
                                value={item.licenseNumber.value}
                                name="licenseNumber"
                                onChange={(e) =>
                                  this._onChangeFormField(
                                    cardName,
                                    "licenseNumber",
                                    e.target.value,
                                    index
                                  )
                                }
                              />
                              {item?.licenseNumber?.error && (
                                <div className="validation-error">
                                  {item?.licenseNumber?.error}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col sm="6" lg="4">
                            <FormGroup className="custom-select-wrapper">
                              <Label>State</Label>
                              <Input
                                type="select"
                                value={item.state.value}
                                name="state"
                                onChange={(e) =>
                                  this._onChangeFormField(
                                    cardName,
                                    "state",
                                    e.target.value,
                                    index
                                  )
                                }
                              >
                                <option value="">Select</option>
                                {usaStates.map((state) => (
                                  <option
                                    key={state.abbreviation}
                                    value={state.abbreviation}
                                  >
                                    {state.name}
                                  </option>
                                ))}
                              </Input>
                              {item?.state?.error && (
                                <div className="validation-error">
                                  {item?.state?.error}
                                </div>
                              )}
                            </FormGroup>
                          </Col>

                          {cardName !== "barLicense" && (
                            <Col sm="6" md="4">
                              <FormGroup>
                                <Label>Expiration Date</Label>
                                <ReactDatetime
                                  inputProps={{
                                    className: "form-control",
                                    placeholder: "Select",
                                    value: formatDate(item.expiredOn.value),
                                  }}
                                  closeOnSelect={true}
                                  timeFormat={false}
                                  onChange={(e) =>
                                    this._onChangeFormField(
                                      cardName,
                                      "expiredOn",
                                      e._d,
                                      index
                                    )
                                  }
                                  isValidDate={(current) =>
                                    current.isAfter(getYesterdayDate())
                                  }
                                />
                                {item?.expiredOn?.error && (
                                  <div className="validation-error">
                                    {item?.expiredOn?.error}
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                          )}

                          <div className="d-flex align-items-center">
                            <Label className="cursorPointer mb-0">
                              <Input
                                type="file"
                                style={{ display: "none" }}
                                accept="image/x-png,image/gif,image/jpeg,.pdf"
                                value=""
                                disabled={loading}
                                // onChange={(event) => this._updateFile(cardName, event)}
                                onChange={(e) =>
                                  this._updateNotaryFile(cardName, e, index)
                                }
                              />
                              <i className="fa fa-upload" /> Attachment
                            </Label>

                            {item.document?.file &&
                              item.document?.file.previewBlob && (
                                <div className="msgPreview ml-2 ml-0">
                                  <img
                                    src={
                                      item.document.file.type === "image"
                                        ? item.document.file.previewBlob
                                        : require("../../../assets/img/file_icon.png")
                                            .default
                                    }
                                    alt="file Icon"
                                  />
                                  {!loading && (
                                    <Button
                                      onClick={() =>
                                        this._deleteNotaryFile(cardName, index)
                                      }
                                      className="deletePreview"
                                    >
                                      <i className="fa fa-times" />
                                    </Button>
                                  )}

                                  {/* file name */}
                                  <div>
                                    {item.document.file.uploadData &&
                                    item.document.file.uploadData.name
                                      ? item.document.file.uploadData.name
                                      : null}
                                  </div>
                                </div>
                              )}

                            {item?.document?.error && (
                              <div className="validation-error">
                                {item?.document?.error}
                              </div>
                            )}

                            {/* <small className="text-muted font-italic">
                  Please add your full notary commission license. We are
                  unable to verify notary stamps or NNA certificates
                </small> */}
                          </div>
                        </Row>
                      </Col>
                      <Col sm="auto">
                        <Button
                          onClick={() =>
                            this._deleteNotaryLicense(cardName, index)
                          }
                          className="deleteNotary"
                          color="link"
                        >
                          <i className="fa fa-trash-o"></i>
                        </Button>
                      </Col>
                    </Row>
                  </>
                );
              })
            : null}

          {/* RON Credentials */}
          {cardName === "ronCredentials"
            ? this.state.ronCredentials.map((item, index) => {
                return (
                  <>
                    <Row className="ronCredentialsRow">
                      <Col>
                        <Row>
                          <Col sm="6" lg="4">
                            <FormGroup className="custom-select-wrapper">
                              <Label>State</Label>
                              <Input
                                type="select"
                                // value={newState[cardName].state.value}
                                name="state"
                                onChange={(e) =>
                                  this._onChangeFormField(
                                    cardName,
                                    "state",
                                    e.target.value,
                                    index
                                  )
                                }
                              >
                                <option value="">Select</option>
                                {usaStates.map((state) => (
                                  <option
                                    key={state.abbreviation}
                                    value={state.abbreviation}
                                  >
                                    {state.name}
                                  </option>
                                ))}
                              </Input>
                              {item?.state?.error && (
                                <div className="validation-error">
                                  {item?.state?.error}
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                          <Col sm={12}>
                            <div className="d-flex flex-column mb-2 mt-1">
                              <Label className="cursorPointer mb-0">
                                <Input
                                  type="file"
                                  style={{ display: "none" }}
                                  accept="image/x-png,image/gif,image/jpeg,.pdf"
                                  value=""
                                  disabled={loading}
                                  // onChange={(event) => this._updateFile(cardName, event)}
                                  onChange={(e) =>
                                    this._updateNotaryFile(cardName, e, index)
                                  }
                                />
                                <i className="fa fa-upload" /> Attachment
                              </Label>

                              {item.document?.file &&
                                item.document?.file.previewBlob && (
                                  <div className="msgPreview mt-2 ml-0">
                                    <img
                                      src={
                                        item.document.file.type === "image"
                                          ? item.document.file.previewBlob
                                          : require("../../../assets/img/file_icon.png")
                                              .default
                                      }
                                      alt="file Icon"
                                    />
                                    {!loading && (
                                      <Button
                                        onClick={() =>
                                          this._deleteNotaryFile(
                                            cardName,
                                            index
                                          )
                                        }
                                        className="deletePreview"
                                      >
                                        <i className="fa fa-times" />
                                      </Button>
                                    )}

                                    {/* file name */}
                                    <div>
                                      {item.document.file.uploadData &&
                                      item.document.file.uploadData.name
                                        ? item.document.file.uploadData.name
                                        : null}
                                    </div>
                                  </div>
                                )}

                              {item?.document?.error && (
                                <div className="validation-error">
                                  {item?.document?.error}
                                </div>
                              )}

                              {/* <small className="text-muted font-italic">
                  Please add your full notary commission license. We are
                  unable to verify notary stamps or NNA certificates
                </small> */}
                            </div>
                          </Col>
                        </Row>
                      </Col>
                      <Col sm="auto">
                        <Button
                          onClick={() =>
                            this._deleteNotaryLicense(cardName, index)
                          }
                          className="deleteNotary"
                          color="link"
                        >
                          <i className="fa fa-trash-o"></i>
                        </Button>
                      </Col>
                    </Row>
                  </>
                );
              })
            : null}
          {/* || cardName === "barLicense" */}
          {/* {cardName === "notaryLicense" || cardName === "barLicense" ? (
                <Row>
                  <Col sm="6" lg="4">
                    <FormGroup>
                      <Label>License Number</Label>
                      <Input
                        type="text"
                        value={newState[cardName].licenseNumber.value}
                        name="licenseNumber"
                        onChange={(e) =>
                          this._onChangeFormField(
                            cardName,
                            "licenseNumber",
                            e.target.value
                          )
                        }
                      />
                      {newState[cardName].licenseNumber.error && (
                        <div className="validation-error">
                          {newState[cardName].licenseNumber.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col sm="6" lg="4">
                    <FormGroup>
                      <Label>State</Label>
                      <Input
                        type="select"
                        value={newState[cardName].state.value}
                        name="state"
                        onChange={(e) =>
                          this._onChangeFormField(
                            cardName,
                            "state",
                            e.target.value
                          )
                        }
                      >
                        <option value="">Select</option>
                        {usaStates.map((state) => (
                          <option
                            key={state.abbreviation}
                            value={state.abbreviation}
                          >
                            {state.name}
                          </option>
                        ))}
                      </Input>
                      {newState[cardName].state.error && (
                        <div className="validation-error">
                          {newState[cardName].state.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                </Row>
              ) : null} */}

          {cardName === "w9Credentials" ? (
            <Row>
              <Col sm="6" md="4">
                <FormGroup className="custom-select-wrapper">
                  <Label>Year</Label>
                  <Input
                    type="select"
                    value={newState[cardName].certficationYear.value}
                    name="certficationYear"
                    onChange={(e) =>
                      this._onChangeFormField(
                        cardName,
                        "certficationYear",
                        e.target.value
                      )
                    }
                  >
                    <option value={currentYear - 1}>{currentYear - 1}</option>
                    <option value={currentYear}>{currentYear}</option>
                    <option value={currentYear + 1}>{currentYear + 1}</option>
                  </Input>
                  {newState[cardName].certficationYear.error && (
                    <div className="validation-error">
                      {newState[cardName].certficationYear.error}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          ) : null}

          {cardName === "backgroundCheckReport" ? (
            <Row>
              <Col sm="6" md="4">
                <FormGroup>
                  <Label>Company</Label>
                  <Input
                    type="text"
                    value={newState[cardName].nameOfCompany.value}
                    name="nameOfCompany"
                    onChange={(e) =>
                      this._onChangeFormField(
                        cardName,
                        "nameOfCompany",
                        e.target.value
                      )
                    }
                  />
                  {newState[cardName].nameOfCompany.error && (
                    <div className="validation-error">
                      {newState[cardName].nameOfCompany.error}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          ) : null}

          {cardName === "eoInsurance" ? (
            <Row>
              <Col sm="6" md="4">
                <FormGroup>
                  <Label>Company</Label>
                  <Input
                    type="text"
                    value={newState[cardName].companyName.value}
                    name="companyName"
                    onChange={(e) =>
                      this._onChangeFormField(
                        cardName,
                        "companyName",
                        e.target.value
                      )
                    }
                  />
                  {newState[cardName].companyName.error && (
                    <div className="validation-error">
                      {newState[cardName].companyName.error}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col sm="6" md="4">
                <FormGroup>
                  <Label>Policy Number</Label>
                  <Input
                    type="text"
                    value={newState[cardName].policyNumber.value}
                    name="policyNumber"
                    onChange={(e) =>
                      this._onChangeFormField(
                        cardName,
                        "policyNumber",
                        e.target.value
                      )
                    }
                  />
                  {newState[cardName].policyNumber.error && (
                    <div className="validation-error">
                      {newState[cardName].policyNumber.error}
                    </div>
                  )}
                </FormGroup>
              </Col>

              <Col sm="6" md="4">
                <FormGroup>
                  <Label>Amount</Label>
                  <InputGroup className="phoneNumberWrap">
                    <InputGroupAddon addonType="prepend" style={{ width: 40 }}>
                      <InputGroupText>
                        <i className="fa fa-usd" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      value={newState[cardName].amount.value}
                      name="amount"
                      onChange={(e) =>
                        this._onChangeFormField(
                          cardName,
                          "amount",
                          e.target.value
                        )
                      }
                    />
                  </InputGroup>
                  {newState[cardName].amount.error && (
                    <div className="validation-error">
                      {newState[cardName].amount.error}
                    </div>
                  )}
                </FormGroup>
              </Col>
            </Row>
          ) : null}

          {
            // cardName === "notaryLicense" ||
            cardName === "eoInsurance" ||
            cardName === "backgroundCheckReport" ? (
              <Row>
                <Col sm="6" md="4">
                  <FormGroup>
                    <Label>Expiration Date</Label>
                    <ReactDatetime
                      inputProps={{
                        className: "form-control",
                        placeholder: "Select",
                        value: formatDate(newState[cardName].expiredOn.value),
                      }}
                      closeOnSelect={true}
                      timeFormat={false}
                      onChange={(e) =>
                        this._onChangeFormField(cardName, "expiredOn", e._d)
                      }
                      isValidDate={(current) =>
                        current.isAfter(getYesterdayDate())
                      }
                    />
                    {newState[cardName].expiredOn.error && (
                      <div className="validation-error">
                        {newState[cardName].expiredOn.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              </Row>
            ) : null
          }

          {cardName !== "notaryLicense" &&
          cardName !== "barLicense" &&
          cardName !== "ronCredentials" ? (
            <div className="d-flex flex-column mb-2 mt-1">
              <Label className="cursorPointer mb-0">
                <Input
                  type="file"
                  style={{ display: "none" }}
                  accept="image/x-png,image/gif,image/jpeg,.pdf"
                  value=""
                  disabled={loading}
                  onChange={(event) => this._updateFile(cardName, event)}
                />
                <i className="fa fa-upload" /> Attachment
              </Label>

              {newState[cardName].document?.file &&
                newState[cardName].document?.file.previewBlob && (
                  <div className="msgPreview mt-2 ml-0">
                    <img
                      src={
                        newState[cardName].document.file.type === "image"
                          ? newState[cardName].document.file.previewBlob
                          : require("../../../assets/img/file_icon.png").default
                      }
                      alt="file Icon"
                    />
                    {!loading && (
                      <Button
                        onClick={() => this._deleteFile(cardName)}
                        className="deletePreview"
                      >
                        <i className="fa fa-times" />
                      </Button>
                    )}

                    {/* file name */}
                    <div>
                      {newState[cardName].document.file.uploadData &&
                      newState[cardName].document.file.uploadData.name
                        ? newState[cardName].document.file.uploadData.name
                        : null}
                    </div>
                  </div>
                )}

              {newState[cardName]?.document?.error && (
                <div className="validation-error">
                  {newState[cardName]?.document?.error}
                </div>
              )}

              {/* <small className="text-muted font-italic">
                  Please add your full notary commission license. We are
                  unable to verify notary stamps or NNA certificates
                </small> */}
            </div>
          ) : null}

          <div className="mb-2 mt-2 text-center">
            <Button
              className="modalBtnCancel"
              onClick={() => this._resetStateModal()}
            >
              Cancel
            </Button>
            <Button
              className="saveBtn"
              disabled={loading}
              onClick={() => this._onSaveCredentail(cardName)}
            >
              {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
              Save
            </Button>
          </div>

          {/* <div className="mt-2 font-italic text-muted">
                  <p>It will take several business days to review and verify your documents.</p>
                  <p className="mb-2">
                    Note that for your security, we cannot accept documents via email, fax, or mail. If you have trouble, please reference our <a href="#">document upload help article</a>
                  </p>
                </div> */}
        </CardBody>

        {loading && Number(percentage) > 0 && (
          <Progress animated color="success" value={percentage} />
        )}
      </Card>
    );
    console.log("state", this.props);
    return (
      <>
        <Card>
          <div
            className={`verificationWrapper  ${
              credentialUnverifiedCount === 0 ? "bg-success" : "bg-warning"
            }`}
          >
            <h3>
              {/* {credentialUnverifiedCount ? (
                <Badge color="warning" className="ml-0 mr-2">
                  {credentialUnverifiedCount}
                </Badge>
              ) : null} */}
              Profile Verification
            </h3>

            {credentialUnverifiedCount === 0 ? (
              <Badge color="success" pill>
                Verified
              </Badge>
            ) : (
              <Badge color="warning" pill>
                {credentialUnverifiedCount ? (
                  <span className="mr-1">{credentialUnverifiedCount}</span>
                ) : null}
                Pending
              </Badge>
            )}
            <p>
              Over 90% of orders on CloseWise are completed by signing agents
              with the following verified credentials:
            </p>
          </div>
          <ListGroup className="verifiedCredentials">
            {/* notaryLicense */}
            <ListGroupItem>
              <div className="accordianHeader">
                <div>
                  {this._isCredentialUploaded("notaryLicense") ? (
                    <>
                      {agentDetails?.notaryLicense?.filter(
                        (item) => item?.isVerified === "Approved"
                      )?.length ? (
                        <img
                          src={
                            require("../../../assets/img/verify.svg").default
                          }
                          alt="varify"
                        />
                      ) : agentDetails?.notaryLicense?.filter(
                          (item) => item?.isVerified === "Pending"
                        )?.length ? (
                        <img
                          src={
                            require("../../../assets/img/pending.svg").default
                          }
                          alt="pending"
                        />
                      ) : (
                        <>
                          <img
                            src={
                              require("../../../assets/img/cross.png").default
                            }
                            alt="rejected"
                          />
                        </>
                      )}
                    </>
                  ) : (
                    <img
                      src={
                        require("../../../assets/img/not-verify.svg").default
                      }
                      alt="not-verify.svg"
                    />
                  )}{" "}
                  Notary License <span className="text-danger">*</span>
                  {agentDetails?.notaryLicense?.length ? (
                    agentDetails?.notaryLicense?.filter((each) =>
                      moment(new Date()).isBefore(each?.expiredOn)
                    )?.length ? null : (
                      <span class="badge badge-danger">EXPIRED</span>
                    )
                  ) : null}
                </div>
                <Button
                  color="link"
                  onClick={() => {
                    this._isCredentialUploaded("notaryLicense")
                      ? this._showViewEditCard("notaryLicense")
                      : this._onToggleEditCredentialModal(
                          true,
                          "notaryLicense"
                        );
                  }}
                >
                  {collapseCardName === "notaryLicense"
                    ? "Hide"
                    : this._isCredentialUploaded("notaryLicense")
                    ? "View"
                    : "Add"}
                </Button>
              </div>

              <Collapse
                isOpen={collapseCardName === "notaryLicense" ? true : false}
                className="accordionBody"
              >
                {/* notaryLicense view and edit card */}

                {this.props.agentDetails?.notaryLicense.length > 0 && (
                  <div className="CustomTableWrapper hideMobile">
                    <Table borderless responsive>
                      <thead>
                        <tr>
                          <th>License Number</th>
                          <th>State</th>
                          <th>Expiration Date</th>
                          <th>Attachment</th>
                          <th style={{ width: 150 }}>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.agentDetails?.notaryLicense.map(
                          (each, index) => (
                            <tr>
                              <td>
                                <div>{each?.licenseNumber}</div>
                              </td>
                              <td>
                                <div style={{ width: 30 }}>{each?.state}</div>
                              </td>
                              <td>
                                <div>{formatDate(each?.expiredOn)}</div>
                              </td>
                              <td>
                                {" "}
                                <div
                                  className="credentialImage"
                                  onClick={() =>
                                    openUrlOnNewTab(
                                      agentDetails?.notaryLicense[index]?.url
                                    )
                                  }
                                >
                                  <div className="imageWrap">
                                    {each.docType === "image" ? (
                                      <img src={each.url} alt="pdf" />
                                    ) : (
                                      <img
                                        className="pdf"
                                        src={
                                          require("../../../assets/img/pdf.svg")
                                            .default
                                        }
                                        alt="notary"
                                      />
                                    )}
                                  </div>
                                  <span>{each.title}</span>
                                </div>
                              </td>
                              <td>
                                <div className="d-flex">
                                  <Button
                                    color="link"
                                    className="p-0"
                                    onClick={() =>
                                      this._onToggleEditCredentialModal(
                                        true,
                                        each
                                      )
                                    }
                                  >
                                    <img
                                      src={
                                        require("../../../assets/img/pencil.svg")
                                          .default
                                      }
                                      alt="edit"
                                      style={{ height: 15 }}
                                      disabled={loading}
                                    />
                                  </Button>
                                  <Button color="link">
                                    <img
                                      src={
                                        require("../../../assets/img/deleteIcon.svg")
                                          .default
                                      }
                                      style={{ height: 15 }}
                                      alt="delete"
                                      onClick={() =>
                                        this._deleteAlert(
                                          "notaryLicense",
                                          each,
                                          index
                                        )
                                      }
                                    />
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </div>
                )}

                {this.props.agentDetails?.notaryLicense.length > 0 && (
                  <>
                    {this.props.agentDetails?.notaryLicense.map(
                      (each, index) => (
                        <Card className="responsiveTableCard hideDesktop">
                          <ul className="cardInfo">
                            <li>
                              <div className="dataWrap">
                                <Label>License Number</Label>
                                <div>{each?.licenseNumber}</div>
                              </div>
                              <div className="dataWrap">
                                <Label>State</Label>
                                <div style={{ width: 30 }}>{each?.state}</div>
                              </div>
                            </li>
                            <li>
                              <div className="dataWrap">
                                <Label>Expiration Date</Label>
                                <div>{formatDate(each?.expiredOn)}</div>
                              </div>
                              <div className="dataWrap">
                                <Label>Attachment</Label>
                                <div
                                  className="credentialImage"
                                  onClick={() =>
                                    openUrlOnNewTab(
                                      agentDetails?.notaryLicense[index]?.url
                                    )
                                  }
                                >
                                  <div className="imageWrap">
                                    {each.docType === "image" ? (
                                      <img src={each.url} alt="pdf" />
                                    ) : (
                                      <img
                                        className="pdf"
                                        src={
                                          require("../../../assets/img/pdf.svg")
                                            .default
                                        }
                                        alt="notary"
                                      />
                                    )}
                                  </div>
                                  <span>{each?.title}</span>
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="dataWrap d-flex justify-space-between">
                                <Button
                                  color="primary"
                                  className="h-auto px-3 py-2"
                                  onClick={() =>
                                    this._onToggleEditCredentialModal(
                                      true,
                                      each
                                    )
                                  }
                                >
                                  Edit
                                </Button>
                                <Button
                                  color="danger"
                                  className="h-auto px-3 py-2"
                                  onClick={() =>
                                    this._deleteAlert(
                                      "notaryLicense",
                                      each,
                                      index
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </div>
                            </li>
                          </ul>
                        </Card>
                      )
                    )}
                    <div className="text-center p-3">
                      <Button
                        color="primary"
                        size="md"
                        onClick={() => {
                          this._onToggleEditCredentialModal(
                            true,
                            "notaryLicense"
                          );
                        }}
                      >
                        ADD LICENSE
                      </Button>
                    </div>
                  </>
                )}
              </Collapse>
            </ListGroupItem>

            {/* barLicense (only for Attorney) */}
            {agentDetails && agentDetails.workType === "Attorney" && (
              <>
                {/* barLicense */}
                <ListGroupItem>
                  <div className="accordianHeader">
                    <div>
                      {this._isCredentialUploaded("barLicense") ? (
                        <>
                          {agentDetails?.barLicense?.filter(
                            (item) => item?.isVerified === "Approved"
                          )?.length ? (
                            <img
                              src={
                                require("../../../assets/img/verify.svg")
                                  .default
                              }
                              alt="varify"
                            />
                          ) : agentDetails?.barLicense?.filter(
                              (item) => item?.isVerified === "Pending"
                            )?.length ? (
                            <img
                              src={
                                require("../../../assets/img/pending.svg")
                                  .default
                              }
                              alt="pending"
                            />
                          ) : (
                            <img
                              src={
                                require("../../../assets/img/cross.png").default
                              }
                              alt="rejected"
                            />
                          )}
                        </>
                      ) : (
                        <img
                          src={
                            require("../../../assets/img/not-verify.svg")
                              .default
                          }
                          alt="not-verify.svg"
                        />
                      )}{" "}
                      Bar License<span className="text-danger">*</span>
                    </div>

                    <Button
                      color="link"
                      onClick={() => {
                        this._isCredentialUploaded("barLicense")
                          ? this._showViewEditCard("barLicense")
                          : this._onToggleEditCredentialModal(
                              true,
                              "barLicense"
                            );
                      }}
                    >
                      {collapseCardName === "barLicense"
                        ? "Hide"
                        : this._isCredentialUploaded("barLicense")
                        ? "View"
                        : "Add"}
                    </Button>
                  </div>
                  {/* barLicense Collapse */}
                  <Collapse
                    isOpen={collapseCardName === "barLicense" ? true : false}
                    className="accordionBody"
                  >
                    {/* bar License view and edit card */}

                    {this.props.agentDetails?.barLicense.length > 0 && (
                      <div className="CustomTableWrapper credentialsList hideMobile">
                        <Table borderless>
                          <thead>
                            <tr>
                              <th>License Number</th>
                              <th>State</th>
                              <th>Expiration Date</th>
                              <th>Attachment</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.props.agentDetails?.barLicense.map(
                              (each, index) => (
                                <tr>
                                  <td>{each?.licenseNumber}</td>
                                  <td>{each?.state}</td>
                                  <td>
                                    {formatDate(each?.expiredOn) || "N/A"}
                                  </td>
                                  <td>
                                    <div
                                      className="credentialImage"
                                      onClick={() =>
                                        openUrlOnNewTab(
                                          agentDetails?.barLicense[index]?.url
                                        )
                                      }
                                    >
                                      {/* when show image remove pdf class in img tag */}
                                      <div className="imageWrap">
                                        <img
                                          src={
                                            require("../../../assets/img/pdf.svg")
                                              .default
                                          }
                                          className="pdf"
                                          alt="pdf"
                                        />
                                      </div>
                                      <span>{each.title}</span>
                                    </div>{" "}
                                  </td>
                                  <td>
                                    <Button
                                      color="link"
                                      onClick={() =>
                                        this._onToggleEditCredentialModal(
                                          true,
                                          each
                                        )
                                      }
                                    >
                                      <img
                                        src={
                                          require("../../../assets/img/pencil.svg")
                                            .default
                                        }
                                        alt="delete"
                                      />
                                    </Button>
                                    <Button
                                      color="link"
                                      onClick={() =>
                                        this._deleteAlert(
                                          "barLicense",
                                          each,
                                          index
                                        )
                                      }
                                    >
                                      <img
                                        src={
                                          require("../../../assets/img/deleteIcon.svg")
                                            .default
                                        }
                                        alt="delete"
                                      />
                                    </Button>
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </Table>
                      </div>
                    )}

                    {this.props.agentDetails?.barLicense?.length > 0 && (
                      <>
                        {this.props.agentDetails?.barLicense?.map(
                          (each, index) => (
                            <Card className="responsiveTableCard hideDesktop">
                              <ul className="cardInfo">
                                <li>
                                  <div className="dataWrap">
                                    <Label>License Number</Label>
                                    <div>{each?.licenseNumber || "N/A"}</div>
                                  </div>
                                  <div className="dataWrap">
                                    <Label>State</Label>
                                    <div>{each?.state || "N/A"}</div>
                                  </div>
                                </li>

                                <li>
                                  <div className="dataWrap">
                                    <Label>Expiration Date</Label>
                                    <div>
                                      {each?.expiredOn
                                        ? formatDate(each?.expiredOn)
                                        : "N/A"}
                                    </div>
                                  </div>
                                  <div className="dataWrap">
                                    <Label>Attachment</Label>
                                    <div
                                      className="credentialImage"
                                      onClick={() =>
                                        openUrlOnNewTab(
                                          agentDetails?.barLicense[index]?.url
                                        )
                                      }
                                    >
                                      {/* when show image remove pdf class in img tag */}
                                      <div className="imageWrap">
                                        <img
                                          src={
                                            require("../../../assets/img/pdf.svg")
                                              .default
                                          }
                                          className="pdf"
                                          alt="pdf"
                                        />
                                      </div>
                                      <span>{each?.title}</span>
                                    </div>
                                  </div>
                                </li>

                                <li>
                                  <div className="dataWrap d-flex justify-content-center">
                                    <Button
                                      color="primary"
                                      className="h-auto px-3 py-2"
                                      onClick={() =>
                                        this._onToggleEditCredentialModal(
                                          true,
                                          each
                                        )
                                      }
                                    >
                                      Edit
                                    </Button>
                                    <Button
                                      color="danger"
                                      className="h-auto px-3 py-2"
                                      onClick={() =>
                                        this._deleteAlert(
                                          "barLicense",
                                          each,
                                          index
                                        )
                                      }
                                    >
                                      Delete
                                    </Button>
                                  </div>
                                </li>
                              </ul>
                            </Card>
                          )
                        )}
                        {/* {this.props.agentDetails?.barLicense.length > 0 && (
                          <div className="text-center p-3">
                            <Button
                              color="primary"
                              size="md"
                              onClick={() => {
                                this._onToggleEditCredentialModal(
                                  true,
                                  "barLicense"
                                );
                              }}
                            >
                              Add Bar License
                            </Button>
                          </div>
                        )} */}
                      </>
                    )}
                  </Collapse>
                </ListGroupItem>
              </>
            )}

            {/* idVerification */}
            <ListGroupItem>
              <div className="accordianHeader">
                <div>
                  {this._isCredentialUploaded("idVerification") ? (
                    <>
                      {agentDetails.idVerification.isVerified === "Approved" ? (
                        <img
                          src={
                            require("../../../assets/img/verify.svg").default
                          }
                          alt="varify"
                        />
                      ) : agentDetails.idVerification.isVerified ===
                        "Rejected" ? (
                        <img
                          src={require("../../../assets/img/cross.png").default}
                          alt="rejected"
                        />
                      ) : (
                        <img
                          src={
                            require("../../../assets/img/pending.svg").default
                          }
                          alt="pending"
                        />
                      )}
                    </>
                  ) : (
                    <img
                      src={
                        require("../../../assets/img/not-verify.svg").default
                      }
                      alt="not-verify.svg"
                    />
                  )}{" "}
                  ID Verification<span className="text-danger">*</span>
                </div>

                <Button
                  color="link"
                  // onClick={() => {
                  //   this._isCredentialUploaded("idVerification")
                  //     ? this._showViewEditCard("idVerification")
                  //     : this._showAddCard("idVerification");
                  // }}
                  onClick={() => {
                    this._isCredentialUploaded("idVerification")
                      ? this._showViewEditCard("idVerification")
                      : this._onToggleEditCredentialModal(
                          true,
                          "idVerification"
                        );
                  }}
                >
                  {collapseCardName === "idVerification"
                    ? "Hide"
                    : this._isCredentialUploaded("idVerification")
                    ? "View"
                    : "Add"}
                </Button>
              </div>

              {/* idVerification Collapse */}
              <Collapse
                isOpen={collapseCardName === "idVerification" ? true : false}
                className="accordionBody"
              >
                {/* id Verification view and edit card */}

                {
                  // this.props.agentDetails?.idVerification.isVerified !==
                  //   "Pending" &&
                  <div className="CustomTableWrapper credentialsList hideMobile">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th>Attachment</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <div
                              className="credentialImage"
                              onClick={() =>
                                openUrlOnNewTab(
                                  agentDetails?.idVerification?.url
                                )
                              }
                            >
                              {/* when show image remove pdf class in image tag */}
                              <div className="imageWrap">
                                <img
                                  src={
                                    require("../../../assets/img/pdf.svg")
                                      .default
                                  }
                                  className="pdf"
                                  alt="pdf"
                                />
                              </div>
                              <span>
                                {this.props.agentDetails?.idVerification.title}
                              </span>
                            </div>
                          </td>
                          <td>
                            <Button
                              color="link"
                              onClick={() =>
                                this._onToggleEditCredentialModal(
                                  true,
                                  this.props.agentDetails?.idVerification
                                )
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/pencil.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button>
                            {/* <Button color="link">
                              <img
                                src={
                                  require("../../../assets/img/deleteIcon.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button> */}
                          </td>
                        </tr>
                        {/* )
                        )} */}
                      </tbody>
                    </Table>
                  </div>
                }

                {/* for mobile devices */}
                <Card className="responsiveTableCard hideDesktop">
                  <ul className="cardInfo">
                    <li>
                      <div className="dataWrap">
                        <Label>Attachment</Label>
                        <div
                          className="credentialImage"
                          onClick={() =>
                            openUrlOnNewTab(agentDetails?.idVerification?.url)
                          }
                        >
                          {/* when show image remove pdf class in image tag */}
                          <div className="imageWrap">
                            <img
                              src={
                                require("../../../assets/img/pdf.svg").default
                              }
                              className="pdf"
                              alt="pdf"
                            />
                          </div>
                          <span>
                            {this.props.agentDetails?.idVerification.title}
                          </span>
                        </div>
                      </div>
                      <div className="dataWrap ">
                        <Label>Action</Label>
                        <div>
                          <Button
                            color="primary"
                            className="h-auto px-3 py-2"
                            onClick={() =>
                              this._onToggleEditCredentialModal(
                                true,
                                this.props.agentDetails?.idVerification
                              )
                            }
                          >
                            Edit
                          </Button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Card>
              </Collapse>
            </ListGroupItem>

            {/* backgroundCheckReport */}
            <ListGroupItem>
              <div className="accordianHeader">
                <div>
                  {this._isCredentialUploaded("backgroundCheckReport") ? (
                    <>
                      {agentDetails.backgroundCheckReport.isVerified ===
                      "Approved" ? (
                        <img
                          src={
                            require("../../../assets/img/verify.svg").default
                          }
                          alt="varify"
                        />
                      ) : agentDetails.backgroundCheckReport.isVerified ===
                        "Rejected" ? (
                        <img
                          src={require("../../../assets/img/cross.png").default}
                          alt="rejected"
                        />
                      ) : (
                        <img
                          src={
                            require("../../../assets/img/pending.svg").default
                          }
                          alt="pending"
                        />
                      )}
                    </>
                  ) : (
                    <img
                      src={
                        require("../../../assets/img/not-verify.svg").default
                      }
                      alt="not-verify.svg"
                    />
                  )}{" "}
                  Background Check Report
                  <span className="text-danger">*</span>
                  {agentDetails?.backgroundCheckReport?.expiredOn ? (
                    moment(new Date()).isBefore(moment(agentDetails?.backgroundCheckReport?.expiredOn)
                      .add(1, "Y")) ?  null :(
                      <span class="badge badge-danger">EXPIRED</span>
                    ) 
                  ) : null}
                </div>

                <Button
                  color="link"
                  // onClick={() => {
                  //   this._isCredentialUploaded("backgroundCheckReport")
                  //     ? this._showViewEditCard("backgroundCheckReport")
                  //     : this._showAddCard("backgroundCheckReport");
                  // }}
                  onClick={() => {
                    this._isCredentialUploaded("backgroundCheckReport")
                      ? this._showViewEditCard("backgroundCheckReport")
                      : this._onToggleEditCredentialModal(
                          true,
                          "backgroundCheckReport"
                        );
                  }}
                >
                  {collapseCardName === "backgroundCheckReport"
                    ? "Hide"
                    : this._isCredentialUploaded("backgroundCheckReport")
                    ? "View"
                    : "Add"}
                </Button>
              </div>
              {/* backgroundCheckReport Collapse */}
              <Collapse
                isOpen={
                  collapseCardName === "backgroundCheckReport" ? true : false
                }
                className="accordionBody"
              >
                {/* Background Check Report view and edit card */}

                {
                  // this.props.agentDetails?.backgroundCheckReport.isVerified !==
                  //   "Pending" &&
                  <div className="CustomTableWrapper credentialsList hideMobile">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th>Company</th>
                          <th>Issued Date</th>
                          <th>Attachment</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {(agentDetails?.backgroundCheckReport
                              ?.nameOfCompany &&
                              capitalize(
                                agentDetails?.backgroundCheckReport
                                  ?.nameOfCompany
                              )) ||
                              "N/A"}
                          </td>

                          <td>
                            {agentDetails?.backgroundCheckReport?.expiredOn
                              ? formatDate(
                                  agentDetails.backgroundCheckReport.expiredOn
                                )
                              : "N/A"}
                          </td>

                          <td>
                            {" "}
                            <div
                              className="credentialImage"
                              onClick={() =>
                                openUrlOnNewTab(
                                  agentDetails?.backgroundCheckReport?.url
                                )
                              }
                            >
                              {/* when show image remove pdf class in img tag */}
                              <div className="imageWrap">
                                <img
                                  src={
                                    require("../../../assets/img/pdf.svg")
                                      .default
                                  }
                                  className="pdf"
                                  alt="pdf"
                                />
                              </div>
                              <span>
                                {
                                  this.props.agentDetails?.backgroundCheckReport
                                    .title
                                }
                              </span>
                            </div>
                          </td>
                          <td style={{ width: 100 }}>
                            <Button
                              color="link"
                              onClick={() =>
                                this._onToggleEditCredentialModal(
                                  true,
                                  this.props.agentDetails?.backgroundCheckReport
                                )
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/pencil.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button>
                            {/* <Button color="link">
                              <img
                                src={
                                  require("../../../assets/img/deleteIcon.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button> */}
                          </td>
                        </tr>
                        {/* )
                        )} */}
                      </tbody>
                    </Table>
                  </div>
                }

                {/* for mobile devices */}
                <Card className="responsiveTableCard hideDesktop">
                  <ul className="cardInfo">
                    <li>
                      <div className="dataWrap">
                        <Label>Company</Label>
                        <div>
                          {(agentDetails?.backgroundCheckReport
                            ?.nameOfCompany &&
                            capitalize(
                              agentDetails?.backgroundCheckReport?.nameOfCompany
                            )) ||
                            "N/A"}
                        </div>
                      </div>

                      <div className="dataWrap">
                        <Label>Issued Date</Label>
                        <div>
                          {agentDetails?.backgroundCheckReport?.expiredOn
                            ? formatDate(
                                agentDetails?.backgroundCheckReport?.expiredOn
                              )
                            : "N/A"}
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="dataWrap">
                        <Label>Attachment</Label>
                        <div
                          className="credentialImage"
                          onClick={() =>
                            openUrlOnNewTab(
                              agentDetails?.backgroundCheckReport?.url
                            )
                          }
                        >
                          {/* when show image remove pdf class in img tag */}
                          <div className="imageWrap">
                            <img
                              src={
                                require("../../../assets/img/pdf.svg").default
                              }
                              className="pdf"
                              alt="pdf"
                            />
                          </div>
                          <span>
                            {
                              this.props.agentDetails?.backgroundCheckReport
                                .title
                            }
                          </span>
                        </div>
                      </div>
                      <div className="dataWrap ">
                        <Label>Action</Label>
                        <div>
                          <Button
                            color="primary"
                            className="h-auto px-3 py-2"
                            onClick={() =>
                              this._onToggleEditCredentialModal(
                                true,
                                this.props.agentDetails?.backgroundCheckReport
                              )
                            }
                          >
                            Edit
                          </Button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Card>
              </Collapse>
            </ListGroupItem>

            {/* eoInsurance */}
            <ListGroupItem>
              <div className="accordianHeader">
                <div>
                  {this._isCredentialUploaded("eoInsurance") ? (
                    <>
                      {agentDetails.eoInsurance.isVerified === "Approved" ? (
                        <img
                          src={
                            require("../../../assets/img/verify.svg").default
                          }
                          alt="varify"
                        />
                      ) : agentDetails.eoInsurance.isVerified === "Rejected" ? (
                        <img
                          src={require("../../../assets/img/cross.png").default}
                          alt="rejected"
                        />
                      ) : (
                        <img
                          src={
                            require("../../../assets/img/pending.svg").default
                          }
                          alt="pending"
                        />
                      )}
                    </>
                  ) : (
                    <img
                      src={
                        require("../../../assets/img/not-verify.svg").default
                      }
                      alt="not-verify.svg"
                    />
                  )}{" "}
                  E&O Insurance<span className="text-danger">*</span>
                  {agentDetails?.eoInsurance?.expiredOn ? (
                    moment(new Date()).isBefore(
                      agentDetails?.eoInsurance?.expiredOn
                    ) ? null : (
                      <span class="badge badge-danger">EXPIRED</span>
                    )
                  ) : null}
                </div>

                <Button
                  color="link"
                  // onClick={() => {
                  //   this._isCredentialUploaded("eoInsurance")
                  //     ? this._showViewEditCard("eoInsurance")
                  //     : this._showAddCard("eoInsurance");
                  // }}
                  onClick={() => {
                    this._isCredentialUploaded("eoInsurance")
                      ? this._showViewEditCard("eoInsurance")
                      : this._onToggleEditCredentialModal(true, "eoInsurance");
                  }}
                >
                  {collapseCardName === "eoInsurance"
                    ? "Hide"
                    : this._isCredentialUploaded("eoInsurance")
                    ? "View"
                    : "Add"}
                </Button>
              </div>
              {/* eoInsurance Collapse */}
              <Collapse
                isOpen={collapseCardName === "eoInsurance" ? true : false}
                className="accordionBody"
              >
                {/* E&O Insurance view and edit card */}

                {
                  // this.props.agentDetails?.eoInsurance.isVerified !==
                  // "Pending" &&
                  <div className="CustomTableWrapper credentialsList hideMobile">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th>Company</th>
                          <th>Policy Number</th>
                          <th>Amount</th>
                          <th>Expiration Date</th>
                          <th>Attachment</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {(agentDetails?.eoInsurance?.companyName &&
                              capitalize(
                                agentDetails?.eoInsurance?.companyName
                              )) ||
                              "N/A"}
                          </td>

                          <td>
                            {agentDetails?.eoInsurance?.policyNumber || "N/A"}
                          </td>

                          <td>
                            {agentDetails?.eoInsurance?.amount
                              ? formatCurrencyValue(
                                  agentDetails.eoInsurance.amount
                                )
                              : "N/A"}
                          </td>

                          <td>
                            {agentDetails?.eoInsurance?.expiredOn
                              ? formatDate(agentDetails.eoInsurance.expiredOn)
                              : "N/A"}
                          </td>

                          <td>
                            {" "}
                            <div
                              className="credentialImage"
                              onClick={() =>
                                openUrlOnNewTab(agentDetails?.eoInsurance?.url)
                              }
                            >
                              {/* when show image remove pdf class in img tag */}
                              <div className="imageWrap">
                                <img
                                  src={
                                    require("../../../assets/img/pdf.svg")
                                      .default
                                  }
                                  className="pdf"
                                  alt="pdf"
                                />
                              </div>
                              <span>
                                {this.props.agentDetails?.eoInsurance.title}
                              </span>
                            </div>
                          </td>
                          <td>
                            <Button
                              color="link"
                              onClick={() =>
                                this._onToggleEditCredentialModal(
                                  true,
                                  this.props.agentDetails?.eoInsurance
                                )
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/pencil.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button>
                            {/* <Button color="link">
                              <img
                                src={
                                  require("../../../assets/img/deleteIcon.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button> */}
                          </td>
                        </tr>
                        {/* )
                        )} */}
                      </tbody>
                    </Table>
                  </div>
                }

                {/* for mobile devices */}
                <Card className="responsiveTableCard hideDesktop">
                  <ul className="cardInfo">
                    <li>
                      <div className="dataWrap">
                        <Label>Company</Label>
                        <div>
                          {(agentDetails?.eoInsurance?.companyName &&
                            capitalize(
                              agentDetails?.eoInsurance?.companyName
                            )) ||
                            "N/A"}
                        </div>
                      </div>

                      <div className="dataWrap">
                        <Label>Policy Number</Label>
                        <div>
                          {agentDetails?.eoInsurance?.policyNumber
                            ? agentDetails?.eoInsurance?.policyNumber
                            : "N/A"}
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="dataWrap">
                        <Label>Amount</Label>
                        <div>
                          {agentDetails?.eoInsurance?.amount
                            ? formatCurrencyValue(
                                agentDetails?.eoInsurance?.amount
                              )
                            : "N/A"}
                        </div>
                      </div>

                      <div className="dataWrap">
                        <Label>Expiration Date</Label>
                        <div>
                          {agentDetails?.eoInsurance?.expiredOn
                            ? formatDate(agentDetails?.eoInsurance?.expiredOn)
                            : "N/A"}
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="dataWrap">
                        <Label>Attachment</Label>
                        <div
                          className="credentialImage"
                          onClick={() =>
                            openUrlOnNewTab(agentDetails?.eoInsurance?.url)
                          }
                        >
                          {/* when show image remove pdf class in img tag */}
                          <div className="imageWrap">
                            <img
                              src={
                                require("../../../assets/img/pdf.svg").default
                              }
                              className="pdf"
                              alt="pdf"
                            />
                          </div>
                          <span>
                            {this.props.agentDetails?.eoInsurance.title}
                          </span>
                        </div>
                      </div>
                      <div className="dataWrap">
                        <Label>Action</Label>
                        <div>
                          <Button
                            color="primary"
                            className="h-auto px-3 py-2"
                            onClick={() =>
                              this._onToggleEditCredentialModal(
                                true,
                                this.props.agentDetails?.eoInsurance
                              )
                            }
                          >
                            Edit
                          </Button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Card>
              </Collapse>
            </ListGroupItem>

            {/* w9Credentials */}
            <ListGroupItem>
              <div className="accordianHeader">
                <div>
                  {this._isCredentialUploaded("w9Credentials") ? (
                    <>
                      {agentDetails.w9Credentials.isVerified === "Approved" ? (
                        <img
                          src={
                            require("../../../assets/img/verify.svg").default
                          }
                          alt="varify"
                        />
                      ) : (
                        <img
                          src={
                            require("../../../assets/img/pending.svg").default
                          }
                          alt="pending"
                        />
                      )}
                    </>
                  ) : (
                    <img
                      src={
                        require("../../../assets/img/not-verify.svg").default
                      }
                      alt="not-verify.svg"
                    />
                  )}{" "}
                  W-9<span className="text-danger">*</span>
                </div>
                <Button
                  color="link"
                  onClick={() => {
                    this._isCredentialUploaded("w9Credentials")
                      ? this._showViewEditCard("w9Credentials")
                      : this._onToggleEditCredentialModal(
                          true,
                          "w9Credentials"
                        );
                  }}
                >
                  {collapseCardName === "w9Credentials"
                    ? "Hide"
                    : this._isCredentialUploaded("w9Credentials")
                    ? "View"
                    : "Add"}
                </Button>
              </div>
              {/* w9Credentials Collapse */}
              <Collapse
                isOpen={collapseCardName === "w9Credentials" ? true : false}
                className="accordionBody"
              >
                {/* w9 credentials view and edit card */}

                {this.props.agentDetails?.w9Credentials.isVerified !==
                  "Pending" && (
                  <div className="CustomTableWrapper credentialsList hideMobile">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th>Year</th>
                          <th>Attachment</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {agentDetails?.w9Credentials?.certficationYear}
                          </td>
                          <td>
                            {" "}
                            <div
                              className="credentialImage"
                              onClick={() =>
                                openUrlOnNewTab(
                                  agentDetails?.w9Credentials?.url
                                )
                              }
                            >
                              {/* when show image remove pdf class in img tag */}
                              <div className="imageWrap">
                                <img
                                  src={
                                    require("../../../assets/img/pdf.svg")
                                      .default
                                  }
                                  className="pdf"
                                  alt="pdf"
                                />
                              </div>
                              <span>
                                {this.props.agentDetails?.w9Credentials.title}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div>
                              <Button
                                color="link"
                                onClick={() =>
                                  this._onToggleEditCredentialModal(
                                    true,
                                    this.props.agentDetails?.w9Credentials
                                  )
                                }
                              >
                                <img
                                  src={
                                    require("../../../assets/img/pdf.svg")
                                      .default
                                  }
                                  className="pdf"
                                  alt="pdf"
                                />
                              </Button>
                              <span>
                                {this.props.agentDetails?.w9Credentials.title}
                              </span>
                            </div>
                          </td>
                          <td>
                            <Button
                              color="link"
                              onClick={() =>
                                this._onToggleEditCredentialModal(
                                  true,
                                  this.props.agentDetails?.w9Credentials
                                )
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/pencil.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button>
                            {/* <Button color="link">
                              <img
                                src={
                                  require("../../../assets/img/deleteIcon.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button> */}
                          </td>
                        </tr>
                        {/* )
                        )} */}
                      </tbody>
                    </Table>
                  </div>
                )}

                {/* for mobile devices */}
                <Card className="responsiveTableCard hideDesktop">
                  <ul className="cardInfo">
                    <li>
                      <div className="dataWrap">
                        <Label>Year</Label>
                        <div>
                          {agentDetails?.w9Credentials?.certficationYear
                            ? agentDetails?.w9Credentials?.certficationYear
                            : "N/A"}
                        </div>
                      </div>

                      <div className="dataWrap">
                        <Label>Attachment</Label>

                        <div
                          className="credentialImage"
                          onClick={() =>
                            openUrlOnNewTab(agentDetails?.w9Credentials?.url)
                          }
                        >
                          {/* when show image remove pdf class in img tag */}
                          <div className="imageWrap">
                            <img
                              src={
                                require("../../../assets/img/pdf.svg").default
                              }
                              className="pdf"
                              alt="pdf"
                            />
                          </div>
                          <span>
                            {this.props.agentDetails?.w9Credentials.title}
                          </span>
                        </div>
                      </div>
                    </li>

                    <li>
                      <div className="dataWrap text-center">
                        <Button
                          color="primary"
                          className="h-auto px-3 py-2"
                          onClick={() =>
                            this._onToggleEditCredentialModal(
                              true,
                              this.props.agentDetails?.w9Credentials
                            )
                          }
                        >
                          Edit
                        </Button>
                      </div>
                    </li>
                  </ul>
                </Card>
              </Collapse>
            </ListGroupItem>

            {/* nnaCertification */}
            <ListGroupItem>
              <div className="accordianHeader">
                <div>
                  {this._isCredentialUploaded("nnaCertification") ? (
                    <>
                      {agentDetails.nnaCertification.isVerified ===
                      "Approved" ? (
                        <img
                          src={
                            require("../../../assets/img/verify.svg").default
                          }
                          alt="varify"
                        />
                      ) : agentDetails.nnaCertification.isVerified ===
                        "Rejected" ? (
                        <img
                          src={require("../../../assets/img/cross.png").default}
                          alt="rejected"
                        />
                      ) : (
                        <img
                          src={
                            require("../../../assets/img/pending.svg").default
                          }
                          alt="pending"
                        />
                      )}
                    </>
                  ) : (
                    <img
                      src={
                        require("../../../assets/img/not-verify.svg").default
                      }
                      alt="not-verify.svg"
                    />
                  )}{" "}
                  NNA Certification
                </div>
                <Button
                  color="link"
                  // onClick={() => {
                  //   this._isCredentialUploaded("nnaCertification")
                  //     ? this._showViewEditCard("nnaCertification")
                  //     : this._showAddCard("nnaCertification");
                  // }}
                  onClick={() => {
                    this._isCredentialUploaded("nnaCertification")
                      ? this._showViewEditCard("nnaCertification")
                      : this._onToggleEditCredentialModal(
                          true,
                          "nnaCertification"
                        );
                  }}
                >
                  {collapseCardName === "nnaCertification"
                    ? "Hide"
                    : this._isCredentialUploaded("nnaCertification")
                    ? "View"
                    : "Add"}
                </Button>
              </div>
              {/* nnaCertification Collapse */}
              <Collapse
                isOpen={collapseCardName === "nnaCertification" ? true : false}
                className="accordionBody"
              >
                {/* NNA Certification view and edit card */}

                {
                  // this.props.agentDetails?.nnaCertification.isVerified !==
                  //   "Pending" &&
                  <div className="CustomTableWrapper credentialsList hideMobile">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th>Attachment</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <div
                              className="credentialImage"
                              onClick={() =>
                                openUrlOnNewTab(
                                  agentDetails?.nnaCertification?.url
                                )
                              }
                            >
                              {/* when show image remove pdf class in img tag */}
                              <div className="imageWrap">
                                <img
                                  src={
                                    require("../../../assets/img/pdf.svg")
                                      .default
                                  }
                                  className="pdf"
                                  alt="pdf"
                                />
                              </div>
                              <span>
                                {
                                  this.props.agentDetails?.nnaCertification
                                    ?.title
                                }
                              </span>
                            </div>
                          </td>
                          <td>
                            <Button
                              color="link"
                              onClick={() =>
                                this._onToggleEditCredentialModal(
                                  true,
                                  this.props.agentDetails?.nnaCertification
                                )
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/pencil.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button>
                            {/* <Button color="link">
                              <img
                                src={
                                  require("../../../assets/img/deleteIcon.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button> */}
                          </td>
                        </tr>
                        {/* )
                        )} */}
                      </tbody>
                    </Table>
                  </div>
                }

                {/* for mobile devices */}
                <Card className="responsiveTableCard hideDesktop">
                  <ul className="cardInfo">
                    <li>
                      <div className="dataWrap">
                        <Label>Attachment</Label>

                        <div
                          className="credentialImage"
                          onClick={() =>
                            openUrlOnNewTab(agentDetails?.nnaCertification?.url)
                          }
                        >
                          {/* when show image remove pdf class in img tag */}
                          <div className="imageWrap">
                            <img
                              src={
                                require("../../../assets/img/pdf.svg").default
                              }
                              className="pdf"
                              alt="pdf"
                            />
                          </div>

                          <span>
                            {this.props.agentDetails?.nnaCertification.title}
                          </span>
                        </div>
                      </div>

                      <div className="dataWrap">
                        <Label>Action</Label>
                        <div>
                          <Button
                            color="primary"
                            className="h-auto px-3 py-2"
                            onClick={() =>
                              this._onToggleEditCredentialModal(
                                true,
                                this.props.agentDetails?.nnaCertification
                              )
                            }
                          >
                            Edit
                          </Button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Card>
              </Collapse>
            </ListGroupItem>

            {/* lssCertification */}
            <ListGroupItem>
              <div className="accordianHeader">
                <div>
                  {this._isCredentialUploaded("lssCertification") ? (
                    <>
                      {agentDetails.lssCertification.isVerified ===
                      "Approved" ? (
                        <img
                          src={
                            require("../../../assets/img/verify.svg").default
                          }
                          alt="varify"
                        />
                      ) : agentDetails.lssCertification.isVerified ===
                        "Rejected" ? (
                        <img
                          src={require("../../../assets/img/cross.png").default}
                          alt="rejected"
                        />
                      ) : (
                        <img
                          src={
                            require("../../../assets/img/pending.svg").default
                          }
                          alt="pending"
                        />
                      )}
                    </>
                  ) : (
                    <img
                      src={
                        require("../../../assets/img/not-verify.svg").default
                      }
                      alt="not-verify.svg"
                    />
                  )}{" "}
                  {/* LSS Certification */}
                  Notary Training Certification
                </div>

                <Button
                  color="link"
                  // onClick={() => {
                  //   this._isCredentialUploaded("lssCertification")
                  //     ? this._showViewEditCard("lssCertification")
                  //     : this._showAddCard("lssCertification");
                  // }}
                  onClick={() => {
                    this._isCredentialUploaded("lssCertification")
                      ? this._showViewEditCard("lssCertification")
                      : this._onToggleEditCredentialModal(
                          true,
                          "lssCertification"
                        );
                  }}
                >
                  {collapseCardName === "lssCertification"
                    ? "Hide"
                    : this._isCredentialUploaded("lssCertification")
                    ? "View"
                    : "Add"}
                </Button>
              </div>

              {/* lssCertification Collapse */}
              <Collapse
                isOpen={collapseCardName === "lssCertification" ? true : false}
                className="accordionBody"
              >
                {/* LSS Certification view and edit card */}

                {
                  // this.props.agentDetails?.lssCertification.isVerified !==
                  // "Pending" &&
                  <div className="CustomTableWrapper credentialsList hideMobile">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th>Attachment</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <div
                              className="credentialImage"
                              onClick={() =>
                                openUrlOnNewTab(
                                  agentDetails?.lssCertification?.url
                                )
                              }
                            >
                              <div className="imageWrap">
                                <img
                                  src={
                                    require("../../../assets/img/pdf.svg")
                                      .default
                                  }
                                  className="pdf"
                                  alt="pdf"
                                />
                              </div>
                              <span>
                                {
                                  this.props.agentDetails?.lssCertification
                                    .title
                                }
                              </span>
                            </div>
                          </td>
                          <td>
                            <Button
                              color="link"
                              onClick={() =>
                                this._onToggleEditCredentialModal(
                                  true,
                                  this.props.agentDetails?.lssCertification
                                )
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/pencil.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button>
                            {/* <Button color="link">
                              <img
                                src={
                                  require("../../../assets/img/deleteIcon.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button> */}
                          </td>
                        </tr>
                        {/* )
                        )} */}
                      </tbody>
                    </Table>
                  </div>
                }

                {/* for mobile devices */}
                <Card className="responsiveTableCard hideDesktop">
                  <ul className="cardInfo">
                    <li>
                      <div className="dataWrap">
                        <Label>Attachment</Label>
                        <div
                          className="credentialImage"
                          onClick={() =>
                            openUrlOnNewTab(agentDetails?.lssCertification?.url)
                          }
                        >
                          <div className="imageWrap">
                            <img
                              src={
                                require("../../../assets/img/pdf.svg").default
                              }
                              className="pdf"
                              alt="pdf"
                            />
                          </div>
                          <span>
                            {this.props.agentDetails?.lssCertification.title}
                          </span>
                        </div>
                      </div>

                      <div className="dataWrap">
                        <Label>Action</Label>
                        <div>
                          <Button
                            color="primary"
                            className="h-auto px-3 py-2"
                            onClick={() =>
                              this._onToggleEditCredentialModal(
                                true,
                                this.props.agentDetails?.lssCertification
                              )
                            }
                          >
                            Edit
                          </Button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Card>
              </Collapse>
            </ListGroupItem>

            {/* mdTitleProducerLicense */}
            <ListGroupItem>
              <div className="accordianHeader">
                <div>
                  {this._isCredentialUploaded("mdTitleProducerLicense") ? (
                    <>
                      {agentDetails.mdTitleProducerLicense.isVerified ===
                      "Approved" ? (
                        <img
                          src={
                            require("../../../assets/img/verify.svg").default
                          }
                          alt="varify"
                        />
                      ) : agentDetails.mdTitleProducerLicense.isVerified ===
                        "Rejected" ? (
                        <img
                          src={require("../../../assets/img/cross.png").default}
                          alt="rejected"
                        />
                      ) : (
                        <img
                          src={
                            require("../../../assets/img/pending.svg").default
                          }
                          alt="pending"
                        />
                      )}
                    </>
                  ) : (
                    <img
                      src={
                        require("../../../assets/img/not-verify.svg").default
                      }
                      alt="not-verify.svg"
                    />
                  )}{" "}
                  MD Title Producer License
                </div>
                <Button
                  color="link"
                  // onClick={() => {
                  //   this._isCredentialUploaded("mdTitleProducerLicense")
                  //     ? this._showViewEditCard("mdTitleProducerLicense")
                  //     : this._showAddCard("mdTitleProducerLicense");
                  // }}
                  onClick={() => {
                    this._isCredentialUploaded("mdTitleProducerLicense")
                      ? this._showViewEditCard("mdTitleProducerLicense")
                      : this._onToggleEditCredentialModal(
                          true,
                          "mdTitleProducerLicense"
                        );
                  }}
                >
                  {collapseCardName === "mdTitleProducerLicense"
                    ? "Hide"
                    : this._isCredentialUploaded("mdTitleProducerLicense")
                    ? "View"
                    : "Add"}
                </Button>
              </div>
              {/* mdTitleProducerLicense Collapse */}
              <Collapse
                isOpen={
                  collapseCardName === "mdTitleProducerLicense" ? true : false
                }
                className="accordionBody"
              >
                {/* MD Title Producer License view and edit card */}

                {
                  // this.props.agentDetails?.mdTitleProducerLicense.isVerified !==
                  // "Pending" &&
                  <div className="CustomTableWrapper credentialsList hideMobile">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th>Attachment</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <div
                              className="credentialImage"
                              onClick={() =>
                                openUrlOnNewTab(
                                  agentDetails?.mdTitleProducerLicense?.url
                                )
                              }
                            >
                              <div className="imageWrap">
                                <img
                                  src={
                                    require("../../../assets/img/pdf.svg")
                                      .default
                                  }
                                  className="pdf"
                                  alt="pdf"
                                />
                              </div>
                              <span>
                                {
                                  this.props.agentDetails
                                    ?.mdTitleProducerLicense.title
                                }
                              </span>
                            </div>
                          </td>
                          <td>
                            <Button
                              color="primary"
                              className="h-auto px-3 py-2"
                              onClick={() =>
                                this._onToggleEditCredentialModal(
                                  true,
                                  this.props.agentDetails
                                    ?.mdTitleProducerLicense
                                )
                              }
                            >
                              Edit
                            </Button>
                            {/* <Button color="link">
                              <img
                                src={
                                  require("../../../assets/img/deleteIcon.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button> */}
                          </td>
                        </tr>
                        {/* )
                        )} */}
                      </tbody>
                    </Table>
                  </div>
                }

                {/* for mobile devices */}
                <Card className="responsiveTableCard hideDesktop">
                  <ul className="cardInfo">
                    <li>
                      <div className="dataWrap">
                        <Label>Attachment</Label>
                        <div
                          className="credentialImage"
                          onClick={() =>
                            openUrlOnNewTab(
                              agentDetails?.mdTitleProducerLicense?.url
                            )
                          }
                        >
                          <div className="imageWrap">
                            <img
                              src={
                                require("../../../assets/img/pdf.svg").default
                              }
                              className="pdf"
                              alt="pdf"
                            />
                          </div>
                          <span>
                            {
                              this.props.agentDetails?.mdTitleProducerLicense
                                .title
                            }
                          </span>
                        </div>
                      </div>

                      <div className="dataWrap">
                        <Label>Action</Label>
                        <div>
                          <Button
                            color="primary"
                            className="h-auto px-3 py-2"
                            onClick={() =>
                              this._onToggleEditCredentialModal(
                                true,
                                this.props.agentDetails?.mdTitleProducerLicense
                              )
                            }
                          >
                            Edit
                          </Button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Card>
              </Collapse>
            </ListGroupItem>

            <ListGroupItem></ListGroupItem>

            {/* titleProducerLicense */}
            <ListGroupItem>
              <div className="accordianHeader">
                <div>
                  {this._isCredentialUploaded("titleProducerLicense") ? (
                    <>
                      {agentDetails.titleProducerLicense.isVerified ===
                      "Approved" ? (
                        <img
                          src={
                            require("../../../assets/img/verify.svg").default
                          }
                          alt="varify"
                        />
                      ) : agentDetails.titleProducerLicense.isVerified ===
                        "Rejected" ? (
                        <img
                          src={require("../../../assets/img/cross.png").default}
                          alt="rejected"
                        />
                      ) : (
                        <img
                          src={
                            require("../../../assets/img/pending.svg").default
                          }
                          alt="pending"
                        />
                      )}
                    </>
                  ) : (
                    <img
                      src={
                        require("../../../assets/img/not-verify.svg").default
                      }
                      alt="not-verify.svg"
                    />
                  )}{" "}
                  IN Title Producer License
                </div>

                <Button
                  color="link"
                  // onClick={() => {
                  //   this._isCredentialUploaded("titleProducerLicense")
                  //     ? this._showViewEditCard("titleProducerLicense")
                  //     : this._showAddCard("titleProducerLicense");
                  // }}
                  onClick={() => {
                    this._isCredentialUploaded("titleProducerLicense")
                      ? this._showViewEditCard("titleProducerLicense")
                      : this._onToggleEditCredentialModal(
                          true,
                          "titleProducerLicense"
                        );
                  }}
                >
                  {collapseCardName === "titleProducerLicense"
                    ? "Hide"
                    : this._isCredentialUploaded("titleProducerLicense")
                    ? "View"
                    : "Add"}
                </Button>
              </div>

              {/* titleProducerLicense Collapse */}
              <Collapse
                isOpen={
                  collapseCardName === "titleProducerLicense" ? true : false
                }
                className="accordionBody"
              >
                {/* IN Title Producer License view and edit card */}

                {
                  // this.props.agentDetails?.titleProducerLicense.isVerified !==
                  // "Pending" &&
                  <div className="CustomTableWrapper credentialsList hideMobile">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th>Attachment</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <div
                              className="credentialImage"
                              onClick={() =>
                                openUrlOnNewTab(
                                  agentDetails?.titleProducerLicense?.url
                                )
                              }
                            >
                              <div className="imageWrap">
                                <img
                                  src={
                                    require("../../../assets/img/pdf.svg")
                                      .default
                                  }
                                  className="pdf"
                                  alt="pdf"
                                />
                              </div>
                              <span>
                                {
                                  this.props.agentDetails?.titleProducerLicense
                                    ?.title
                                }
                              </span>
                            </div>
                          </td>
                          <td>
                            <Button
                              color="link"
                              onClick={() =>
                                this._onToggleEditCredentialModal(
                                  true,
                                  this.props.agentDetails?.titleProducerLicense
                                )
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/pencil.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button>
                            {/* <Button color="link">
                              <img
                                src={
                                  require("../../../assets/img/deleteIcon.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button> */}
                          </td>
                        </tr>
                        {/* )
                        )} */}
                      </tbody>
                    </Table>
                  </div>
                }

                {/* for mobile devices */}
                <Card className="responsiveTableCard hideDesktop">
                  <ul className="cardInfo">
                    <li>
                      <div className="dataWrap">
                        <Label>Attachment</Label>
                        <div
                          className="credentialImage"
                          onClick={() =>
                            openUrlOnNewTab(
                              agentDetails?.titleProducerLicense?.url
                            )
                          }
                        >
                          <div className="imageWrap">
                            <img
                              src={
                                require("../../../assets/img/pdf.svg").default
                              }
                              className="pdf"
                              alt="pdf"
                            />
                          </div>
                          <span>
                            {
                              this.props.agentDetails?.titleProducerLicense
                                ?.title
                            }
                          </span>
                        </div>
                      </div>

                      <div className="dataWrap">
                        <Label>Action</Label>
                        <div>
                          <Button
                            color="primary"
                            className="h-auto px-3 py-2"
                            onClick={() =>
                              this._onToggleEditCredentialModal(
                                true,
                                this.props.agentDetails?.titleProducerLicense
                              )
                            }
                          >
                            Edit
                          </Button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Card>
              </Collapse>
            </ListGroupItem>

            {/* nyTotalCloser */}
            <ListGroupItem>
              <div className="accordianHeader">
                <div>
                  {this._isCredentialUploaded("nyTotalCloser") ? (
                    <>
                      {agentDetails.nyTotalCloser.isVerified === "Approved" ? (
                        <img
                          src={
                            require("../../../assets/img/verify.svg").default
                          }
                          alt="varify"
                        />
                      ) : agentDetails.nyTotalCloser.isVerified ===
                        "Rejected" ? (
                        <img
                          src={require("../../../assets/img/cross.png").default}
                          alt="rejected"
                        />
                      ) : (
                        <img
                          src={
                            require("../../../assets/img/pending.svg").default
                          }
                          alt="pending"
                        />
                      )}
                    </>
                  ) : (
                    <img
                      src={
                        require("../../../assets/img/not-verify.svg").default
                      }
                      alt="not-verify.svg"
                    />
                  )}{" "}
                  NY Title Closer
                </div>

                <Button
                  color="link"
                  // onClick={() => {
                  //   this._isCredentialUploaded("nyTotalCloser")
                  //     ? this._showViewEditCard("nyTotalCloser")
                  //     : this._showAddCard("nyTotalCloser");
                  // }}
                  onClick={() => {
                    this._isCredentialUploaded("nyTotalCloser")
                      ? this._showViewEditCard("nyTotalCloser")
                      : this._onToggleEditCredentialModal(
                          true,
                          "nyTotalCloser"
                        );
                  }}
                >
                  {collapseCardName === "nyTotalCloser"
                    ? "Hide"
                    : this._isCredentialUploaded("nyTotalCloser")
                    ? "View"
                    : "Add"}
                </Button>
              </div>
              {/* nyTotalCloser Collapse */}
              <Collapse
                isOpen={collapseCardName === "nyTotalCloser" ? true : false}
                className="accordionBody"
              >
                {/* NY Title Closer view and edit card */}

                {
                  // this.props.agentDetails?.nyTotalCloser.isVerified !==
                  // "Pending" &&
                  <div className="CustomTableWrapper credentialsList hideMobile">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th>Attachment</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <div
                              className="credentialImage"
                              onClick={() =>
                                openUrlOnNewTab(
                                  agentDetails?.nyTotalCloser?.url
                                )
                              }
                            >
                              <div className="imageWrap">
                                <img
                                  src={
                                    require("../../../assets/img/pdf.svg")
                                      .default
                                  }
                                  className="pdf"
                                  alt="pdf"
                                />
                              </div>
                              <span>
                                {this.props.agentDetails?.nyTotalCloser.title}
                              </span>
                            </div>
                          </td>
                          <td>
                            <Button
                              color="link"
                              onClick={() =>
                                this._onToggleEditCredentialModal(
                                  true,
                                  this.props.agentDetails?.nyTotalCloser
                                )
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/pencil.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button>
                            {/* <Button color="link">
                              <img
                                src={
                                  require("../../../assets/img/deleteIcon.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button> */}
                          </td>
                        </tr>
                        {/* )
                        )} */}
                      </tbody>
                    </Table>
                  </div>
                }

                {/* for mobile devices */}
                <Card className="responsiveTableCard hideDesktop">
                  <ul className="cardInfo">
                    <li>
                      <div className="dataWrap">
                        <Label>Attachment</Label>
                        <div
                          className="credentialImage"
                          onClick={() =>
                            openUrlOnNewTab(agentDetails?.nyTotalCloser?.url)
                          }
                        >
                          <div className="imageWrap">
                            <img
                              src={
                                require("../../../assets/img/pdf.svg").default
                              }
                              className="pdf"
                              alt="pdf"
                            />
                          </div>
                          <span>
                            {this.props.agentDetails?.nyTotalCloser?.title}
                          </span>
                        </div>
                      </div>

                      <div className="dataWrap">
                        <Label>Action</Label>
                        <div>
                          <Button
                            color="primary"
                            className="h-auto px-3 py-2"
                            onClick={() =>
                              this._onToggleEditCredentialModal(
                                true,
                                this.props.agentDetails?.nyTotalCloser
                              )
                            }
                          >
                            Edit
                          </Button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Card>
              </Collapse>
            </ListGroupItem>

            {/* notaryBond */}
            <ListGroupItem>
              <div className="accordianHeader">
                <div>
                  {this._isCredentialUploaded("notaryBond") ? (
                    <>
                      {agentDetails.notaryBond.isVerified === "Approved" ? (
                        <img
                          src={
                            require("../../../assets/img/verify.svg").default
                          }
                          alt="varify"
                        />
                      ) : agentDetails.notaryBond.isVerified === "Rejected" ? (
                        <img
                          src={require("../../../assets/img/cross.png").default}
                          alt="rejected"
                        />
                      ) : (
                        <img
                          src={
                            require("../../../assets/img/pending.svg").default
                          }
                          alt="pending"
                        />
                      )}
                    </>
                  ) : (
                    <img
                      src={
                        require("../../../assets/img/not-verify.svg").default
                      }
                      alt="not-verify.svg"
                    />
                  )}{" "}
                  Notary Bond
                </div>

                <Button
                  color="link"
                  // onClick={() => {
                  //   this._isCredentialUploaded("notaryBond")
                  //     ? this._showViewEditCard("notaryBond")
                  //     : this._showAddCard("notaryBond");
                  // }}
                  onClick={() => {
                    this._isCredentialUploaded("notaryBond")
                      ? this._showViewEditCard("notaryBond")
                      : this._onToggleEditCredentialModal(true, "notaryBond");
                  }}
                >
                  {collapseCardName === "notaryBond"
                    ? "Hide"
                    : this._isCredentialUploaded("notaryBond")
                    ? "View"
                    : "Add"}
                </Button>
              </div>
              {/* notaryBond Collapse */}
              <Collapse
                isOpen={collapseCardName === "notaryBond" ? true : false}
                className="accordionBody"
              >
                {/* NotaryBond view and edit card */}

                {
                  // this.props.agentDetails?.notaryBond.isVerified !==
                  // "Pending" &&
                  <div className="CustomTableWrapper credentialsList hideMobile">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th>Attachment</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            {" "}
                            <div
                              className="credentialImage"
                              onClick={() =>
                                openUrlOnNewTab(agentDetails?.notaryBond?.url)
                              }
                            >
                              <div className="imageWrap">
                                <img
                                  src={
                                    require("../../../assets/img/pdf.svg")
                                      .default
                                  }
                                  className="pdf"
                                  alt="pdf"
                                />
                              </div>
                              <span>
                                {this.props.agentDetails?.notaryBond.title}
                              </span>
                            </div>
                          </td>
                          <td>
                            <Button
                              color="link"
                              onClick={() =>
                                this._onToggleEditCredentialModal(
                                  true,
                                  this.props.agentDetails?.notaryBond
                                )
                              }
                            >
                              <img
                                src={
                                  require("../../../assets/img/pencil.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button>
                            {/* <Button color="link">
                              <img
                                src={
                                  require("../../../assets/img/deleteIcon.svg")
                                    .default
                                }
                                alt="delete"
                              />
                            </Button> */}
                          </td>
                        </tr>
                        {/* )
                        )} */}
                      </tbody>
                    </Table>
                  </div>
                }
                {/* for mobile devices */}
                <Card className="responsiveTableCard hideDesktop">
                  <ul className="cardInfo">
                    <li>
                      <div className="dataWrap">
                        <Label>Attachment</Label>
                        <div
                          className="credentialImage"
                          onClick={() =>
                            openUrlOnNewTab(agentDetails?.notaryBond?.url)
                          }
                        >
                          <div className="imageWrap">
                            <img
                              src={
                                require("../../../assets/img/pdf.svg").default
                              }
                              className="pdf"
                              alt="pdf"
                            />
                          </div>
                          <span>
                            {this.props.agentDetails?.notaryBond?.title}
                          </span>
                        </div>
                      </div>

                      <div className="dataWrap">
                        <Label>Action</Label>
                        <div>
                          <Button
                            color="primary"
                            className="h-auto px-3 py-2"
                            onClick={() =>
                              this._onToggleEditCredentialModal(
                                true,
                                this.props.agentDetails?.notaryBond
                              )
                            }
                          >
                            Edit
                          </Button>
                        </div>
                      </div>
                    </li>
                  </ul>
                </Card>
              </Collapse>
            </ListGroupItem>

            {/* ronCredentials */}
            <ListGroupItem>
              <div className="accordianHeader">
                <div>
                  {this._isCredentialUploaded("ronCredentials") ? (
                    <>
                      {agentDetails?.ronCredentials?.filter(
                        (item) => item?.isVerified === "Approved"
                      )?.length ? (
                        <img
                          src={
                            require("../../../assets/img/verify.svg").default
                          }
                          alt="varify"
                        />
                      ) : agentDetails?.ronCredentials?.filter(
                          (item) => item?.isVerified === "Pending"
                        )?.length ? (
                        <img
                          src={
                            require("../../../assets/img/pending.svg").default
                          }
                          alt="pending"
                        />
                      ) : (
                        <img
                          src={require("../../../assets/img/cross.png").default}
                          alt="rejected"
                        />
                      )}
                    </>
                  ) : (
                    <img
                      src={
                        require("../../../assets/img/not-verify.svg").default
                      }
                      alt="not-verify.svg"
                    />
                  )}{" "}
                  RON Credentials
                </div>
                <Button
                  color="link"
                  // onClick={() => {
                  //   this._isCredentialUploaded("ronCredentials")
                  //     ? this._showViewEditCard("ronCredentials")
                  //     : this._showAddCard("ronCredentials");
                  // }}
                  onClick={() => {
                    this._isCredentialUploaded("ronCredentials")
                      ? this._showViewEditCard("ronCredentials")
                      : this._onToggleEditCredentialModal(
                          true,
                          "ronCredentials"
                        );
                  }}
                >
                  {collapseCardName === "ronCredentials"
                    ? "Hide"
                    : this._isCredentialUploaded("ronCredentials")
                    ? "View"
                    : "Add"}
                </Button>
              </div>
              {/* ronCredentials Collapse */}
              <Collapse
                isOpen={collapseCardName === "ronCredentials" ? true : false}
                className="accordionBody"
              >
                {/* RON Credentials view and edit card */}

                {this.props.agentDetails?.ronCredentials.length > 0 && (
                  <div className="CustomTableWrapper credentialsList hideMobile">
                    <Table borderless>
                      <thead>
                        <tr>
                          <th>State</th>
                          <th>Attachment</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.props.agentDetails?.ronCredentials.map(
                          (each, index) => (
                            <tr>
                              <td>{each?.state}</td>

                              <td>
                                {" "}
                                <div
                                  className="credentialImage"
                                  onClick={() =>
                                    openUrlOnNewTab(
                                      agentDetails?.ronCredentials[index]?.url
                                    )
                                  }
                                >
                                  <div className="imageWrap">
                                    <img
                                      src={
                                        require("../../../assets/img/pdf.svg")
                                          .default
                                      }
                                      className="pdf"
                                      alt="pdf"
                                    />
                                  </div>
                                  <span>{each.title}</span>
                                </div>
                              </td>
                              <td>
                                <Button
                                  color="link"
                                  onClick={() =>
                                    this._onToggleEditCredentialModal(
                                      true,
                                      each
                                    )
                                  }
                                >
                                  <img
                                    src={
                                      require("../../../assets/img/pencil.svg")
                                        .default
                                    }
                                    alt="delete"
                                  />
                                </Button>
                                <Button color="link">
                                  <img
                                    src={
                                      require("../../../assets/img/deleteIcon.svg")
                                        .default
                                    }
                                    alt="delete"
                                    disabled={loading}
                                    onClick={() =>
                                      // this._toggleEditCredential("notaryLicense")
                                      // this._deleteLicense(
                                      this._deleteAlert(
                                        "ronCredentials",
                                        each,
                                        index
                                      )
                                    }
                                  />
                                </Button>
                              </td>
                            </tr>
                          )
                        )}
                        {/* <tr>
                          <td colSpan={3} className="text-center">
                            {this.props.agentDetails?.ronCredentials.length >
                              0 && (
                              <Button
                                color="primary"
                                className="addButton"
                                onClick={() => {
                                  this._onToggleEditCredentialModal(
                                    true,
                                    "ronCredentials"
                                  );
                                }}
                              >
                                Add RON Credentials
                              </Button>
                            )}
                          </td>
                        </tr> */}
                      </tbody>
                    </Table>
                  </div>
                )}

                {this.props.agentDetails?.ronCredentials.length > 0 && (
                  <>
                    {this.props.agentDetails?.ronCredentials?.map(
                      (each, index) => (
                        <Card className="responsiveTableCard hideDesktop">
                          <ul className="cardInfo">
                            <li>
                              <div className="dataWrap">
                                <Label>State</Label>
                                <div>{each?.state || "N/A"}</div>
                              </div>
                              <div className="dataWrap">
                                <Label>Attachment</Label>
                                <div
                                  className="credentialImage"
                                  onClick={() =>
                                    openUrlOnNewTab(
                                      agentDetails?.ronCredentials[index]?.url
                                    )
                                  }
                                >
                                  <div className="imageWrap">
                                    {each?.docType === "image" ? (
                                      <img src={each?.url} alt="pdf" />
                                    ) : (
                                      <img
                                        className="pdf"
                                        src={
                                          require("../../../assets/img/pdf.svg")
                                            .default
                                        }
                                        alt="notary"
                                      />
                                    )}
                                  </div>
                                  <span>{each?.title}</span>
                                </div>
                              </div>
                            </li>

                            <li>
                              <div className="d-flex justify-content-center w-100">
                                <Button
                                  color="primary"
                                  className="h-auto px-3 py-2"
                                  onClick={() =>
                                    this._onToggleEditCredentialModal(
                                      true,
                                      each
                                    )
                                  }
                                >
                                  Edit
                                </Button>
                                <Button
                                  color="danger"
                                  className="h-auto px-3 py-2"
                                  disabled={loading}
                                  onClick={() =>
                                    // this._toggleEditCredential("notaryLicense")
                                    this._deleteAlert(
                                      "ronCredentials",
                                      each,
                                      index
                                    )
                                  }
                                >
                                  Delete
                                </Button>
                              </div>
                            </li>
                          </ul>
                        </Card>
                      )
                    )}
                    {/* {this.props.agentDetails?.ronCredentials.length > 0 && (
                      <div className="text-center p-3">
                        <Button
                          color="primary"
                          size="md"
                          onClick={() => {
                            this._onToggleEditCredentialModal(
                              true,
                              "ronCredentials"
                            );
                          }}
                        >
                          Add RON Credentials
                        </Button>
                      </div>
                    )} */}
                  </>
                )}
              </Collapse>
            </ListGroupItem>
          </ListGroup>
        </Card>
        <EditCredentialModal
          isOpen={editCredentialModal.isOpen}
          data={editCredentialModal.data}
          collapseCardName={cardName !== null ? cardName : collapseCardName}
          toggle={() => this._onToggleEditCredentialModal(false)}
          agentDetails={this.props.agentDetails}
          getAgentProfileDetail={() => this.props.getAgentProfileDetail()}
        />
      </>
    );
  }
}

export default AgentProfileCredentials;
