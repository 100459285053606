import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
  useRef,
  memo,
} from "react";
import { FormGroup, Label } from "reactstrap";
import momenttz from "moment-timezone";
import moment from "moment";
import ReactDatetime from "react-datetime";
import "react-datetime/css/react-datetime.css";
import {
  formatDateAndTime,
  // formatDateAsPerTimeZOne,
  formatDateMoment,
  formatTime,
} from "../../../helper-methods";

const RegularAppointmentScheduler = forwardRef(
  (
    {
      data,
      isDateFormat = true,
      initialViewMode = "days",
      timezone = "America/New_York",
    },
    ref
  ) => {
    const [isOpenReactDatetime, setIsOpenReactDatetime] = useState(false);
    const [validateTrigger, setValidateTrigger] = useState(false);
    const isMounted = useRef(false); // Ref to track mounting

    const [formFields, setFormFields] = useState({
      appointmentDate: { value: null, error: null },
    });

    // const onChangeFormField = (field, value) => {
    //   setFormFields((prevFields) => {
    //     if (field === "appointmentDate" && !isDateFormat) {
    //       // Extract the date part from the existing value
    //       const currentDate = new Date(prevFields[field].value);
    //       const newTime = new Date(value);

    //       console.log("currentDate", currentDate);

    //       // Merge the date part with the new time part
    //       currentDate.setHours(newTime.getHours());
    //       currentDate.setMinutes(newTime.getMinutes());
    //       currentDate.setSeconds(newTime.getSeconds());

    //       return {
    //         ...prevFields,
    //         [field]: { ...prevFields[field], value: currentDate },
    //       };
    //     }

    //     return {
    //       ...prevFields,
    //       [field]: { ...prevFields[field], value },
    //     };
    //   });
    //   setValidateTrigger((prev) => !prev);
    // };

    const onChangeFormField = (field, value) => {
      const newDate = value;
      setFormFields((prevFields) => {
        if (field === "appointmentDate" && !isDateFormat) {
          // Extract the date part from the existing value
          const currentDate = moment(prevFields[field].value);

          // console.log("currentDate", currentDate);

          // Merge the date part with the new time part using Moment
          currentDate.hours(newDate.hours());
          currentDate.minutes(newDate.minutes());
          currentDate.seconds(newDate.seconds());

          return {
            ...prevFields,
            [field]: { ...prevFields[field], value: currentDate },
          };
        }

        // For other fields or when isDateFormat is true
        return {
          ...prevFields,
          [field]: { ...prevFields[field], value: newDate },
        };
      });

      // Trigger validation
      setValidateTrigger((prev) => !prev);
    };

    const validateDates = () => {
      let errors = {};
      const appointmentDate = formFields.appointmentDate.value;

      if (!appointmentDate) {
        errors.appointmentDate = "*Required";
      } else {
        delete errors.appointmentDate;
      }

      setFormFields((prevFields) => ({
        ...prevFields,
        appointmentDate: {
          ...prevFields.appointmentDate,
          error: errors.appointmentDate || null,
        },
      }));

      return errors;
    };

    const createPayload = () => {
      return new Promise((resolve) => {
        let payload = {
          appointmentDate: null,
          isRangeDated: false,
          tBD: false,
        };

        payload.appointmentDate = formatDateMoment(
          formFields.appointmentDate.value
        );

        resolve(payload);
      });
    };

    const getAppointmentSchedulerData = async () => {
      const errors = validateDates();
      const res = await createPayload();
      if (errors && Object.keys(errors).length) {
        return { isValid: false, appointmentSchedulerData: res };
      } else return { isValid: true, appointmentSchedulerData: res };
    };

    const handleIncomingData = (data) => {
      if (data) {
        setFormFields((prevFields) => ({
          ...prevFields,
          appointmentDate: {
            value: momenttz.tz(data?.appointmentDate, timezone),
            error: null,
          },
        }));
      }
    };

    useImperativeHandle(ref, () => ({
      getAppointmentSchedulerData,
    }));

    useEffect(() => {
      if (isMounted?.current) {
        validateDates();
      } else {
        isMounted.current = true;
      }
    }, [validateTrigger]);

    useEffect(() => {
      handleIncomingData(data);
    }, [data]);

    // console.log(
    //   "initialViewMode >>",
    //   moment(data?.appointmentDate).format("MMM DD, hh:mm A")
    // );

    // console.log(formatDateAsPerTimeZOne(data?.appointmentDate, timezone));

    // console.log(
    //   "formFields.appointmentDate.value >>",
    //   moment(formFields.appointmentDate.value).format("MMM DD, hh:mm A")
    // );

    // console.log(
    //   "formatTime(formFields.appointmentDate.value) >>",
    //   formatTime(formFields.appointmentDate.value)
    // );

    return (
      <div>
        <FormGroup
          className={`floatingLabel ${
            formFields.appointmentDate.value || isOpenReactDatetime
              ? "valueAdded"
              : ""
          }`}
        >
          <ReactDatetime
            inputProps={{
              className: "form-control",
              placeholder: " ",
              value: !isDateFormat
                ? formatTime(formFields.appointmentDate.value)
                : formatDateAndTime(formFields.appointmentDate.value),
            }}
            value={formFields.appointmentDate.value}
            onChange={(e) => onChangeFormField("appointmentDate", e)}
            onOpen={() => setIsOpenReactDatetime(true)}
            onClose={() => setIsOpenReactDatetime(false)}
            timeFormat={true}
            timeConstraints={{ minutes: { step: 15 } }}
            initialViewMode={initialViewMode}
            dateFormat={isDateFormat ? true : false}
          />
          <Label>Appointment {isDateFormat ? "Date &" : ""} Time</Label>
          {formFields.appointmentDate.error && (
            <div className="validation-error">
              {formFields.appointmentDate.error}
            </div>
          )}
        </FormGroup>
      </div>
    );
  }
);

export default memo(RegularAppointmentScheduler);
