import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Input,
  FormGroup,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Badge,
  Label,
  CustomInput,
} from "reactstrap";
import {
  capitalize,
  deepClone,
  formatCurrencyValue,
  showToast,
  formatDateAsPerTimeZOne,
  getTimeZoneAbbr,
  formatOnlyDateMoment,
  getDropdownColor,
  formatDate,
  errorHandler,
} from "../../../helper-methods";
import {
  createAgentInvoice,
  getAllAgentClosingForInvoice,
  getUnregisteredClientListForInvoice,
} from "../../../http/http-calls";
import Chips from "react-chips";
import { RegexConfig } from "../../../config/RegexConfig";
import { agentClosingStatus } from "../../../config";
import CustomTable from "./CustomTable";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import CustomCard from "./CustomCard";

class CreateInvoiceModal extends Component {
  state = {
    closingHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      { id: "fileNumber", label: "File Number", noSort: true },
      { id: "appointmentDate", label: "Date", noSort: true },
      { id: "invoice", label: "Invoice", noSort: true },
      { id: "_borrower", label: "Signer", noSort: true },
      { id: "amount", label: "Amount", noSort: true },
      { id: "status", label: "Status", noSort: true },
    ],
    closingCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    chips: [],
    closingList: [],
    unregisterClientList: [],
    closingTotalCount: 0,
    closingSelectedRows: [],
    tableConfig: {
      skip: 0,
      limit: 200,
      pageNumber: 1,
    },
    filters: {
      clientId: "",
      company: "",
      status: "All",
    },
    dateRangeValue: null,
    isDoneInvoice: false,
    loading: {
      showTableLoading: false,
      createClosingLoading: false,
    },
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _resetStateModal = () => {
    this.setState({
      chips: [],
      closingList: [],
      closingTotalCount: 0,
      closingSelectedRows: [],
      filters: {
        clientId: "",
        company: "",
        status: "All",
      },
      dateRangeValue: null,
      isDoneInvoice: false,
      tableConfig: {
        skip: 0,
        limit: 200,
        pageNumber: 1,
      },
      loading: {
        showTableLoading: false,
        createClosingLoading: false,
      },
    });
  };

  _closeModal = () => {
    this._resetStateModal();
    this.props.toggle();
  };

  _getAllAgentClosingForInvoice = () => {
    this._manageLoading("showTableLoading", true);

    const { tableConfig, filters, dateRangeValue, isDoneInvoice } = deepClone(
      this.state
    );

    const payload = {
      ...tableConfig,
      filters: {},
    };

    if (dateRangeValue && dateRangeValue.length) {
      payload.filters["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      payload.filters["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
    }

    if (isDoneInvoice) payload.filters["isDoneInvoice"] = isDoneInvoice;

    if (filters.company) {
      // if (filters.company === "unregisteredClient") {
      //   if (filters.clientId) {
      //     payload.filters["clientId"] = filters.clientId;
      //     payload.filters["company"] = filters.company;
      //   }
      // } else {
      //   payload.filters["company"] = filters.company;
      // }
      this.props.signingCompany.find((each) => {
        if (each._id === filters.company) {
          if (each.hasOwnProperty("_client") && each._client !== "") {
            payload.filters["clientId"] = each._client;
            payload.filters["company"] = "unregisteredClient";
          } else if (
            each.hasOwnProperty("_signingCompany") &&
            each._signingCompany !== ""
          ) {
            payload.filters["company"] = each._signingCompany;
          } else {
            payload.filters["assistantId"] = each._assistant;
          }
        }
      });
    }
    if (filters.status)
      payload.filters["status"] =
        filters.status !== "All" ? filters.status : undefined;

    getAllAgentClosingForInvoice(payload)
      .then((res) => {
        // console.log({ res });
        this.setState(
          {
            // closingList: res.closings,
            closingList: res?.incomes || [],
            closingTotalCount: res?.totalCount || 0,
          },
          () => {
            this._manageLoading("showTableLoading", false);
          }
        );
      })
      .catch((error) => {
        errorHandler(error);
        this._closeModal();
      });
  };

  _getUnregisteredClientListForInvoice = () => {
    getUnregisteredClientListForInvoice()
      .then((res) => {
        this.setState({ unregisterClientList: res.list || [] });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  componentDidUpdate = (prevProps) => {
    const { isOpen } = this.props;
    if (isOpen && isOpen !== prevProps.isOpen) {
      this._paginate();
      // this._getUnregisteredClientListForInvoice();
    }
  };

  _paginate = (pageNumber = 1, pageSize = 5) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._getAllAgentClosingForInvoice();
    });
  };

  _setSelectedRows = (closingSelectedRows = []) => {
    this.setState({
      closingSelectedRows,
    });
  };

  _filterOnChange = (type, value) => {
    console.log(type, value);
    const { filters, unregisterClientList } = this.state;
    filters[type] = value;
    this.setState({ filters, closingSelectedRows: [] }, () => {
      // if (value !== "unregisteredClient" || filters.clientId)
      this._paginate();
    });

    if (type === "clientId") {
      let val = unregisterClientList.filter((client) => client._id === value)[0]
        ?.email;

      this.setState({ chips: [val] });
    }
    if (type === "company") {
      let company = this.props.signingCompany.find((item) => {
        return item.id === value;
      });

      let emailId = company?.accountingEmail
        ? company?.accountingEmail
        : company?.email;

      // console.log("12332 ", { emailId });
      if (emailId?.length) {
        this.setState({ chips: [emailId] });
      }
    }
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          clientId: "",
          company: "",
          status: "",
        },
        dateRangeValue: null,
        isDoneInvoice: false,
        closingSelectedRows: [],
      },
      () => {
        this._paginate();
      }
    );
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue, closingSelectedRows: [] }, () => {
      this._paginate();
    });
  };

  _onChangeInvoiceActive = () => {
    this.setState({ isDoneInvoice: !this.state.isDoneInvoice }, () => {
      this._paginate();
    });
  };

  _createClosing = () => {
    const { closingSelectedRows } = deepClone(this.state);

    if (closingSelectedRows?.length) {
      this._manageLoading("createClosingLoading", true);

      const incomeIds = closingSelectedRows?.map((each) => each?.id);
      let payload = {
        // closingId: closingSelectedRows,
        incomeId: incomeIds,
        emails: this.state.chips,
      };
      createAgentInvoice(payload)
        .then((res) => {
          this.props.resetDetails();
          showToast("Invoice Created", "success");
          this._closeModal();
        })
        .catch((error) => {
          errorHandler(error);
          this._manageLoading("createClosingLoading", false);
        });
    } else {
      showToast("Select at least one closing", "error");
    }
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "appointmentDate": {
        return row?._closing?.appointmentDate ? (
          <>
            {formatDateAsPerTimeZOne(
              row?._closing?.appointmentDate,
              row?._closing?.closingAddress?.timeZone
            )}{" "}
            {" | "}
            {getTimeZoneAbbr(
              row?._closing?.appointmentDate,
              row?._closing?.closingAddress?.timeZone
            )}
          </>
        ) : row?.displayAppointmentDate ? (
          formatDate(row?.displayAppointmentDate)
        ) : (
          "N/A"
        );
      }
      case "fileNumber": {
        return row?._closing?.fileNumber
          ? row?._closing?.fileNumber
          : row?.fileNumber
          ? row?.fileNumber
          : "N/A";
      }
      case "invoice": {
        return row?._invoice?.length
          ? row?._invoice?.map((item, index) => {
              if (index !== row?._invoice?.length - 1) {
                return item?.invoiceNumber + ", ";
              } else {
                return item?.invoiceNumber;
              }
            })
          : "N/A";
      }
      case "_borrower": {
        return (
          <div>
            {/* show the below span only when there are more than 1 signers  */}
            {row?._borrower[0]?.name?.full
              ? capitalize(row?._borrower[0]?.name?.full)
              : "N/A"}{" "}
            {row?._borrower && row?._borrower?.length > 1 && (
              <>
                <span
                  id={`PopoverLegacy${row._id}`}
                  className="themeColor cursorPointer"
                  style={{ fontWeight: "600" }}
                >
                  +{row?._borrower?.length - 1}
                </span>
                <UncontrolledPopover
                  trigger="legacy"
                  placement="bottom"
                  target={`PopoverLegacy${row._id}`}
                  className="signerPopover"
                >
                  <PopoverHeader>Signers</PopoverHeader>
                  <PopoverBody>
                    {row?._borrower?.map((item) => (
                      <p>{capitalize(item.name.full)}</p>
                    ))}
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            )}
          </div>
        );
      }

      // case "amount": {
      //   return row?._income[0]?.duePayment
      //     ? formatCurrencyValue(row?._income[0]?.duePayment)
      //     : "$0";
      // }
      case "amount": {
        return row?.duePayment ? formatCurrencyValue(row?.duePayment) : "$0";
      }
      case "status": {
        return cell ? (
          <>
            <Badge
              color={`${getDropdownColor(
                agentClosingStatus.find((obj) => obj.value === cell)?.label
              )}`}
              className="outline"
              pill
            >
              {agentClosingStatus.find((obj) => obj.value === cell)?.label ||
                "N/A"}
            </Badge>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _rowStyleFormat = (row, rowIdx) => {
    if (row?._invoice?.length) {
      return { backgroundColor: "rgba(107,208,152,0.4)" };
    }
    return {};
  };

  _isValidEmail = (value) => {
    if (RegexConfig.email.test(String(value).toLowerCase())) {
      return true;
    } else {
      showToast("Invalid Email", "error");

      return false;
    }
  };

  _onChipSelect = (chips, arrName = "chips") => {
    let isValid = chips.length
      ? this._isValidEmail(chips[chips.length - 1])
      : true;
    if (isValid) {
      this.setState({ chips: chips });
    }
  };

  _cardHeaderFormat = (cell, row, header) => {
    console.log("23456", row, cell, header);
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="cardTableTitle">
              {row?._borrower?.length > 0
                ? capitalize(row?._borrower[0]?.name?.full)
                : "N/A"}{" "}
              {row?._borrower && row?._borrower?.length > 1 && (
                <>
                  <span id={`PopoverLegacy${row?._id}`}>
                    +{row?._borrower?.length - 1}
                  </span>
                  <UncontrolledPopover
                    trigger="legacy"
                    placement="bottom"
                    target={`PopoverLegacy${row?._id}`}
                    className="signerPopover"
                  >
                    <PopoverHeader>Signers</PopoverHeader>
                    <PopoverBody>
                      {row?._borrower?.map((item) => (
                        <p>{capitalize(item?.name?.full)}</p>
                      ))}
                    </PopoverBody>
                  </UncontrolledPopover>
                </>
              )}
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>File Number: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?._closing?.fileNumber
                ? row?._closing?.fileNumber
                : row?.fileNumber
                ? row?.fileNumber
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Date: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?._closing?.appointmentDate ? (
                <>
                  {formatDateAsPerTimeZOne(
                    row?._closing?.appointmentDate,
                    row?._closing?.closingAddress?.timeZone
                  )}{" "}
                  {" | "}
                  {getTimeZoneAbbr(
                    row?._closing?.appointmentDate,
                    row?._closing?.closingAddress?.timeZone
                  )}
                </>
              ) : row?.displayAppointmentDate ? (
                formatDate(row?.displayAppointmentDate)
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Invoice: </Label>
            <div style={{ fontWeight: 600 }}>
              {row?._invoice?.length
                ? row?._invoice?.map((item, index) => {
                    if (index !== row?._invoice?.length - 1) {
                      return item?.invoiceNumber + ", ";
                    } else {
                      return item?.invoiceNumber;
                    }
                  })
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Amount: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.duePayment ? formatCurrencyValue(row?.duePayment) : "$0"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap d-flex align-items-center justify-content-between">
            <Label>Status</Label>
            <Badge
              color={`${getDropdownColor(
                agentClosingStatus.find((obj) => obj.value === row?.status)
                  ?.label
              )}`}
              className="outline"
              pill
            >
              {agentClosingStatus.find((obj) => obj.value === row?.status)
                ?.label || "N/A"}
            </Badge>
          </div>
        </li>
      </ul>
    );
  };
  render() {
    const {
      dateRangeValue,
      filters,
      isDoneInvoice,
      closingList,
      closingTotalCount,
      closingHeaderKeys,
      loading,
      tableConfig,
      closingSelectedRows,
      unregisterClientList,
      closingCardHeaderKeys,
    } = this.state;

    const { isOpen, signingCompany } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        toggle={this._closeModal}
        className="modal-xl"
        scrollable
        centered
      >
        <ModalHeader toggle={this._closeModal}>Create Invoice</ModalHeader>
        <ModalBody>
          <div className="filterContainer responsiveView">
            <div className="filterIcon">
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
              />
              Filter by
            </div>
            <div className="filterWrapper">
              {/* <FormGroup> */}
              {/* the below div will only be seen from 768px  */}
              {/* <div className="filterHead mt-1 row">
            <Col md="6" lg="4" xl="3"> */}
              <FormGroup>
                <Label>Company</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="company"
                    value={filters.company}
                    onChange={(e) =>
                      this._filterOnChange("company", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    {/* <option value="unregisteredClient">Unregistered Client</option> */}
                    {signingCompany.map((obj) => (
                      <option key={obj.id} value={obj.id}>
                        {obj?._signingCompany
                          ? obj.companyName
                          : obj?._client
                          ? obj.name?.full
                          : obj?._assistant
                          ? obj?.name?.full
                          : "N/A"}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              {/* {filters.company === "unregisteredClient" ? (
                <Input
                  type="select"
                  name="company"
                  value={filters.company}
                  onChange={(e) =>
                    this._filterOnChange("company", e.target.value)
                  }
                >
                  <option value="">Clients</option>
                  <option value="unregisteredClient">
                    Unregistered Client
                  </option>
                  {signingCompany.map((obj) => (
                    <option key={obj.id} value={obj.id}>
                      {(obj.companyName && capitalize(obj.companyName)) ||
                        "N/A Company"}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              <FormGroup>
                {filters.company === "unregisteredClient" ? (
                  <Input
                    type="select"
                    name="clientId"
                    value={filters.clientId}
                    onChange={(e) =>
                      this._filterOnChange("clientId", e.target.value)
                    }
                  >
                    <option value="">Select Client</option>
                    {unregisterClientList.map((Obj) => (
                      <option key={Obj.id} value={Obj.id}>
                        {capitalize(Obj.name.full) || "N/A"}
                      </option>
                    ))}
                  </Input>
                ) : null}
              </FormGroup>
              */}
              <FormGroup className="dateRange">
                <Label>Date Range</Label>
                <DateRangePicker
                  clearIcon={null}
                  className="dateRange"
                  format="MM-dd-y"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  onChange={(dateRangeValue) =>
                    this._onChangeDatePicker(dateRangeValue)
                  }
                  value={dateRangeValue}
                />
              </FormGroup>
              <FormGroup>
                <Label>Status</Label>
                <Input
                  type="select"
                  value={filters.status}
                  name="status"
                  onChange={(e) =>
                    this._filterOnChange("status", e.target.value)
                  }
                >
                  {/* <option value="" disabled>
                    Status
                  </option> */}
                  <option value="All">All</option>
                  {agentClosingStatus.map((obj) =>
                    obj.value === "Closed" || obj.value === "DNC" ? (
                      <option key={obj.value} value={obj.value}>
                        {obj.label}
                      </option>
                    ) : null
                  )}
                </Input>
              </FormGroup>

              <FormGroup>
                <Label>Already Invoiced</Label>
                <CustomInput
                  type="switch"
                  checked={isDoneInvoice}
                  onChange={this._onChangeInvoiceActive}
                  id="isDoneInvoice_Custom_Switch"
                  name="isDoneInvoice"
                />
              </FormGroup>
              {/* <Button className="resetBtn mr-0" onClick={this._resetFilter}>
                <i className="fa fa-refresh" />
              </Button> */}
            </div>
          </div>

          {closingList?.length && closingSelectedRows?.length ? (
            <div style={{ padding: 12 }}>
              {/* when the checkboxes in the below table are selected/checked 
              then below text is to be shown to indicate the count selected */}
              {closingSelectedRows.length} Records are selected.
            </div>
          ) : null}

          <div className="hideDesktop">
            <CustomCard
              isPageStartFromOne={true}
              pageNumber={tableConfig.pageNumber}
              tableData={closingList}
              headerKeys={closingCardHeaderKeys}
              // bodyKeys={agentClosingCardBodyKeys}
              dataFormat={this._dataFormat}
              totalCount={closingTotalCount}
              onPaginate={(pageNumber, pageSize) =>
                this._paginate(pageNumber, pageSize)
              }
              columnSizeMD={12}
              rowSelection={true}
              setSelectedRows={(selectedRows) =>
                this._setSelectedRows(selectedRows)
              }
              selectedRows={closingSelectedRows}
              // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
              showTableLoading={loading.showTableLoading}
              cardHeaderFormat={this._cardHeaderFormat}
              cardDataFormat={this._cardDataFormat}
            />
          </div>

          <div className="hideMobile">
            {closingHeaderKeys && closingHeaderKeys.length && (
              <CustomTable
                striped
                isPageStartFromOne={true}
                pageNumber={tableConfig.pageNumber}
                pageSize={tableConfig.limit}
                rowSelection={true}
                tableData={closingList}
                headerKeys={closingHeaderKeys}
                dataFormat={this._dataFormat}
                totalCount={closingTotalCount}
                onPaginate={(pageNumber, pageSize) =>
                  this._paginate(pageNumber, pageSize)
                }
                onSortChange={(sortName, sortOrder) =>
                  // sort function is disabled
                  this._paginate()
                }
                rowStyleFormat={(row, rowIdx) =>
                  this._rowStyleFormat(row, rowIdx)
                }
                selectedRows={closingSelectedRows}
                setSelectedRows={(selectedRows) =>
                  this._setSelectedRows(selectedRows)
                }
                showTableLoading={loading.showTableLoading}
              />
            )}
          </div>
          <FormGroup className="mt-3 ChipsWrap floatingLabel valueAdded">
            <Chips
              value={this.state.chips}
              onChange={(arr) => this._onChipSelect(arr, "chips")}
              getChipValue={(e) => console.log(e)}
              createChipKeys={[9, 13]} //  Key codes to add chips
              placeholder={"+Add Email"}
            />
            <Label> Send Email To</Label>
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button size="lg" color="primary" outline onClick={this._closeModal}>
            Close
          </Button>
          <Button
            color="primary"
            size="lg"
            disabled={loading.createClosingLoading}
            onClick={this._createClosing}
          >
            {loading.createClosingLoading ? (
              <i className="fa fa-spinner fa-spin mr-2" />
            ) : null}{" "}
            Create
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default CreateInvoiceModal;
