import React, { Component } from "react";
import { Modal, ModalBody, ModalHeader, Button, ModalFooter } from "reactstrap";
import { store as REDUX_STORE } from "../../../../redux/store";

class UpgradeProfessionalAccountModal extends Component {
  _closeModal = (action) => {
    this.props.toggle();
  };

  _redirect = (e) => {
    if (e) e.preventDefault();

    const { userData } = REDUX_STORE.getState();
    this.props.history.push(`/${userData.type}/subscription`);
    this._closeModal();
  };

  render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._closeModal()}
        scrollable
        centered
        className="upgradeAccountModal"
      >
        <ModalHeader toggle={() => this._closeModal()}>
          {/* CloseWise Paid Feature */}
          Closewise Professional features
        </ModalHeader>
        <ModalBody>
          <img
            src={require("../../../../assets/img/closewise.png").default}
            alt="logo"
            className="companyLogo"
          />
          <div className="joinProfessonals">
            <h2>
              {/* Join Our Paid Plans */}
              Upgrade Your Account
            </h2>
            {/* <p>
              Upgrade your account today and receive <br/>
              <strong>14 day free trial</strong> + paid features
            </p> */}
            <p>
              Unlock access to out exclusive professional features by upgrading
              your account today
            </p>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            style={{ maxWidth: "unset", width: "auto" }}
            onClick={(e) => this._redirect(e)}
            color="primary"
          >
            {/* UPGRADE FOR $15 / MONTH */}
            {/* <span> (14 day trial)</span> */}
            UPGRADE NOW
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default UpgradeProfessionalAccountModal;
