export const cutomPaymentReceivedByCompanyFromClient=`
<!DOCTYPE html>
<html lang="en">
  <head>
    <title>Template Title</title>
    <!-- Required meta tags -->
    <meta charset="utf-8" />
    <meta
      name="viewport"
      content="width=device-width, initial-scale=1, shrink-to-fit=no"
    />

    <!-- font-family used -->
    <link
      href="https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap"
      rel="stylesheet"
    />

    <style>
      * {
        box-sizing: border-box;
      }

      body {
        margin: 0;
      }

      .template-wrap {
        width: 98%;
        max-width: 620px;
        margin: 10px auto 0;
        padding: 20px;
        box-sizing: border-box;
        border: 2px solid #07689d;
        background: #fff;
      }

      .subjectTxt {
        font-size: 22px;
        color: #1e295c;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        margin: 6px auto 25px;
        text-align: center;
      }

      .company-logo {
        height: 70px;
        margin: 0 auto 35px;
        display: block;
      }

      .userName {
        font-size: 16px;
        font-weight: 600;
        color: #1e295c;
        letter-spacing: 0.3px;
        font-family: "Open Sans", sans-serif;
        margin-bottom: 10px;
      }

      .contentTxt {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: #4f4f4f;
        letter-spacing: 0.3px;
        font-family: "Open Sans", sans-serif;
        margin: 0 0 12px;
      }

      a {
        color: #4f4f4f;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        text-decoration: underline;
        letter-spacing: 0.3px;
      }

      .theme-btn {
        background: #07689d;
        text-decoration: none !important;
        min-width: 240px;
        width: 240px;
        height: 46px;
        line-height: 45px;
        text-align: center;
        display: table;
        margin: 25px auto 20px;
        cursor: pointer;
        color: #fff;
        border-radius: 6px;
        font-size: 16px;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
      }

      .emailFooterWrap {
        display: block;
        color: #4f4f4f;
        width: 98%;
        max-width: 620px;
        margin: 10px auto;
      }

      .emailFooterWrap * {
        font-size: 11px;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        text-transform: uppercase;
      }

      .emailFooter,
      .socialLinksFooter {
        display: inline-block;
      }

      .socialLinksFooter a {
        text-decoration: none !important;
        color: #4f4f4f !important;
        display: inline-block;
        margin-left: 5px;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: normal !important;
      }

      .powered-by {
        float: right;
        margin-top: 2px;
      }

      .powered-by a {
        color: #31c5c7;
        text-decoration: none !important;
        display: inline-block;
        margin-left: 3px;
        font-family: "Open Sans", sans-serif;
        text-transform: uppercase;
        font-weight: 400;
        letter-spacing: normal !important;
      }

      .projectLogo {
        width: 225px;
        display: block;
        margin: auto auto 20px;
      }

      .signatureLogo {
        width: 225px;
        align-self: flex-start;
      }
    </style>
  </head>
  <body>

      <h2 class="subjectTxt"></h2>
             
        <h5>companyName </h5>
    

      <p class="userName">Hello  companyName ,</p>

      <p class="contentTxt">A payment in the amount of $amount was sent to you by companyName . Please allow 2-3 days to process and show in your account. A Payment breakdown is attached in this email for your records.</p>

      <p class="contentTxt">Thank you!</p>
      

  </body>
</html>`