import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import React from "react";
import { HeaderEventEmitter } from "../../../../helper-methods/HeaderEvents";
import { Button, Input, FormGroup, Table, Tooltip, Label } from "reactstrap";
import AddPostModal from "../../components/Modals/Agents/AddPostModal";
import { handleLogin } from "../../../../helper-methods/linkdinOauth";
import LinkedInOAuthComponent from "../../components/linkdinModal";
import {
  deleteLocalStorageData,
  errorHandler,
  formatDateAndTime,
  isProPlusUser,
  refreshFunc,
} from "../../../../helper-methods";
import CustomTable from "../../components/CustomTable";
import FacebookOAuthComponent from "../../components/facebookModal";
import {
  fetchAllSocialMediaPosts,
  createSocialMediaPost,
  generateFacebookAccessToken,
  getFacebookPagesList,
  getInstagramBussinessId,
  agentGenerateLinkdinToken,
} from "../../../../http/http-calls";
import PagesModal from "../../components/Modals/Agents/pagesModal";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import SocialMediaLogout from "../../components/Modals/Agents/SocialMediaLogout";

const SocialMediaManagement = () => {
  const history = useHistory();

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const toggle = () => setTooltipOpen(!tooltipOpen);
  const [isClassAdded, setIsClassAdded] = useState(false);
  const [codeFor, setCodeFor] = useState("");
  const [addPostModalData, setAddPostModalData] = useState({
    isOpen: false,
    data: null,
  });
  const [socialMedia, setSocialMedia] = useState({
    isOpen: false,
    data: null,
  });
  const [pagesModal, setPagesModal] = useState({
    isOpen: false,
    data: null,
  });
  const [socialMediaData, setSocialMediaData] = useState(null);
  const [socialMediaDataTotalCount, setSocialMediaDataTotalCount] =
    useState(null);
  const [usersocialMediaData, setUserSocialMediaData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tableConfig, setTableConfig] = useState({
    skip: 0,
    limit: 10,
    pageNumber: 1,
  });
  const [dateRangeValue, setDateRangeValue] = useState(null);
  const [filters, setFilters] = useState({});
  const [seed, setSeed] = useState(0); // used to recall _fetchAllSocialMediaPosts function

  const socialMediaDataHeaderKeys = [
    { id: "id", label: "id" },
    { id: "date", label: "Date" },
    { id: "topic", label: "Topic" },
    { id: "scheduledAt", label: "Schedule Date" },
    { id: "postedOn", label: "Posted On" },
  ];

  const filterShowMobile = () => {
    setIsClassAdded(!isClassAdded);
  };

  const _toggleAddPostModal = (isOpen = false, data = null) => {
    setAddPostModalData({
      isOpen: isOpen,
      data: data,
    });
  };
  const _toggleSocialMedia = (isOpen = false, data = null) => {
    setSocialMedia({
      isOpen: isOpen,
      data: data,
    });
  };
  const _togglePagesModal = (isOpen = false, data = null) => {
    setPagesModal({
      isOpen: isOpen,
      data: data,
    });
  };
  useEffect(() => {
    // console.log({ filters });
    HeaderEventEmitter.subscribe("add-post", () => {
      _toggleAddPostModal(true);
    });
    HeaderEventEmitter.subscribe("reset-filter", () => {
      _resetFilter();
      refreshFunc("agent-social-media-management");
    });
    // handleLogin()
    _fetchAllSocialMediaPosts();
  }, [filters, dateRangeValue, tableConfig]);
  const _fetchAllSocialMediaPosts = async (
    tableConfig = {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    }
  ) => {
    setLoading(true);
    try {
      let payload = {
        // ...filters,
        ...tableConfig,
        // ...dateRangeValue,
      };

      const filterPayload = {};

      if (String(filters?.postedOn)?.length) {
        filterPayload["postedOn"] = filters?.postedOn;
      }

      if (dateRangeValue !== null) {
        filterPayload["startDate"] = dateRangeValue[0];
        filterPayload["endDate"] = dateRangeValue[1];
      }

      if (Object.keys(filterPayload)?.length) {
        payload["filters"] = filterPayload;
      }

      delete payload.pageNumber;
      // if (String(filters?.postedOn)?.length) {
      //   Object.assign(payload, {
      //     filters: {
      //       postedOn: filters?.postedOn,
      //     },
      //   });
      // }

      // if (dateRangeValue !== null) {
      //   Object.assign(payload, {
      //     filters: {
      //       startDate: dateRangeValue[0],
      //       endDate: dateRangeValue[1],
      //     },
      //   });
      // }
      let response = await fetchAllSocialMediaPosts(payload);
      setSocialMediaData(response?.posts || []);
      setSocialMediaDataTotalCount(response?.totalCount || 0);
      setUserSocialMediaData(response?.user || {});
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _dataFormat = (cell, row, header) => {
    switch (header) {
      case "date": {
        return (
          <>
            <div style={{ width: 150 }}>{formatDateAndTime(row.createdAt)}</div>
          </>
        );
      }

      case "topic": {
        return <div style={{ fontWeight: 600, width: 280 }}>{row.topic} </div>;
      }
      case "scheduledAt": {
        return (
          <>
            <div style={{ width: 150 }}>
              {formatDateAndTime(row.scheduledAt)}
            </div>
          </>
        );
      }
      case "postedOn": {
        return (
          row.postedOn.length > 0 &&
          row.postedOn.map((item) => (
            <>
              {/* // <div style={{ fontWeight: 600, width: 60 }}>{item} </div> */}
              {item === "facebook" && (
                <Button color="link">
                  <img
                    height={20}
                    src={require("../../../../assets/img/facebook.png").default}
                    alt="facebook"
                  />
                </Button>
              )}
              {item === "instagram" && (
                <Button color="link">
                  <img
                    height={20}
                    src={
                      require("../../../../assets/img/instagram.png").default
                    }
                    alt="instagram"
                  />
                </Button>
              )}
              {item === "linkedin" && (
                <Button color="link">
                  <img
                    height={20}
                    src={require("../../../../assets/img/linkedin.png").default}
                    alt="linkedin"
                  />
                </Button>
              )}
            </>
          ))
        );
      }

      default: {
        return cell;
      }
    }
  };

  useEffect(() => {
    _setFilterDataFromLocalStorage();
    // _fetchAllSocialMediaPosts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seed]);

  const _filterOnChange = (field, value) => {
    console.log("field", field, value);
    const newFilterData = { ...filters };
    newFilterData[field] = value;
    setFilters(newFilterData);

    //add api call sned data
    if (value === "") {
      delete newFilterData[field];
    }

    _persistFilter({
      skip: 0,
      limit: 10,
      pageNumber: 1,
    });
    // _fetchAllSocialMediaPosts(newFilterData);
    // props.getAllCompanySettings(newFilterData);
  };
  const _onChangeDatePicker = (dateRangeValue) => {
    setDateRangeValue(dateRangeValue);
  };

  const codeIndex = (url) => {
    if (!url) return;
    url.indexOf("?code=");
    if (codeIndex !== -1) {
      const codeStart = codeIndex + 6; // Length of "?code="
      const codeEnd =
        url.indexOf("&", codeStart) !== -1
          ? url.indexOf("&", codeStart)
          : url.length;
      const code = url.substring(codeStart, codeEnd);
      console.log(code);
      return code;
    }
  };

  useEffect(() => {
    if (!isProPlusUser()) {
      history.goBack();
      return;
    }

    const handleAuthCallback = async () => {
      console.log("ytyt", window.location.search);
      // Parse the authorization code from the URL
      const code = new URLSearchParams(window.location.search).get("code");
      // console.log("juy", code);

      if (window.location.search.includes("linkedin")) {
        // if (codeFor === "linkedin") {
        try {
          const response = await agentGenerateLinkdinToken({ code });
          console.log("response", response);
          if (!response.error) {
            _fetchAllSocialMediaPosts();

            // _togglePagesModal(true);
            // let pageList =getFacebookPagesList()
          }
          // }
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
      } else {
        try {
          const response = await generateFacebookAccessToken({ code });

          if (!response.error) {
            _togglePagesModal(true);
            // let pageList =getFacebookPagesList()
          }
        } catch (error) {
          console.error("Error fetching access token:", error);
        }
      }
    };
    // console.log("13323", window.location.search);

    if (window.location.search) {
      handleAuthCallback();
      console.log("efvewvwv");
    }
  }, []);

  const _setAccessTokenFor = (platform) => {
    setCodeFor(platform);
  };

  const _generateInstagramBussinessId = async () => {
    setLoading(true);
    try {
      await getInstagramBussinessId();
      // setFacebookPages(facebookPages.pages.data);
      setLoading(false);
      _fetchAllSocialMediaPosts();
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _paginate = (pageNumber = 1, pageSize = 10) => {
    const newtableConfig = tableConfig;
    newtableConfig.skip = (pageNumber - 1) * pageSize;
    newtableConfig.limit = pageSize;
    newtableConfig["pageNumber"] = pageNumber;
    setTableConfig(newtableConfig);
    _fetchAllSocialMediaPosts(tableConfig);
    _persistFilter(newtableConfig);
    // document.querySelector(".content").scrollIntoView();
  };

  const _resetFilter = () => {
    setDateRangeValue(null);
    setFilters({
      postedOn: "",
    });

    const newTableConfig = {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    };

    setTableConfig(newTableConfig);

    setSeed(Math.random());
    // _fetchAllSocialMediaPosts();
    deleteLocalStorageData("agentSocialMediaPage");
    refreshFunc("agent-social-media-management-responsive");
  };

  const _persistFilter = (tableConfig) => {
    if (tableConfig.pageNumber !== 1) {
      let data = { tableConfig };
      localStorage.agentSocialMediaPage = JSON.stringify(data);
    } else {
      delete localStorage.agentSocialMediaPage;
    }
  };

  const _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.agentSocialMediaPage) {
      try {
        const filters = JSON.parse(localStorage.agentSocialMediaPage);

        setTableConfig(filters?.tableConfig);
        _fetchAllSocialMediaPosts(filters?.tableConfig);
      } catch (e) {
        console.log("error", e);
        _fetchAllSocialMediaPosts();
      }
    } else {
      _fetchAllSocialMediaPosts();
    }
  };

  return (
    <>
      <div className="content">
        {console.log("lki", tableConfig)}
        <div className="responsiveTitle">
          <h2>Social Media Management</h2>

          <div className="rightSide">
            <Button
              color="link"
              // onClick={this._resetFilter}
              // onClick={() => _toggleAddPostModal(true)}
              onClick={_resetFilter}
            >
              <img
                id="agent-social-media-management-responsive"
                src={require("../../../../assets/img/refresh.svg").default}
                alt="refresh"
              />
            </Button>

            <Button
              className="filterBarIcon"
              color="link"
              onClick={filterShowMobile}
            >
              <img
                src={require("../../../../assets/img/filter_icon.svg").default}
                alt="Filter"
              />
            </Button>

            <Button
              className="floatingButton"
              color="primary"
              // onClick={this._redirectToCreateClosing}
              onClick={() => _toggleAddPostModal(true)}
            >
              Add Post
            </Button>
          </div>
        </div>

        {/* filter for tablet and web */}
        <div
          onClick={filterShowMobile}
          className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
        ></div>
        <div
          className={`filterContainer  responsiveFilter ${
            isClassAdded ? "show" : ""
          }`}
        >
          <div className="filterIcon">
            <img
              src={require("../../../../assets/img/filter_icon.svg").default}
              alt="filter icon"
            />
            Filter by
          </div>
          <div className="mobileTitle">
            <h2>Filter</h2>
            <Button
              color="link"
              className="closeButton"
              onClick={filterShowMobile}
            >
              <img
                src={require("../../../../assets/img/close_grey.svg").default}
                alt="close"
                height={12}
              />
            </Button>
          </div>
          <div className="filterWrapper">
            <FormGroup>
              <Label>Social Platform</Label>
              <Input
                type="select"
                name="postedOn"
                value={filters.postedOn}
                onChange={(e) => _filterOnChange("postedOn", e.target.value)}
                // onChange={(e) => this._filterOnChange("status", e.target.value)}
              >
                <option value="">All</option>
                <option value="facebook">Facebook</option>
                <option value="instagram">Instagram</option>
                <option value="linkedin">Linkedin</option>
              </Input>
            </FormGroup>
            <FormGroup>
              <Label>Date Range</Label>
              <div className="custom-select-wrapper">
                <DateRangePicker
                  // clearIcon={null}
                  className="dateRange"
                  format="MM-dd-y"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  onChange={(dateRangeValue) =>
                    _onChangeDatePicker(dateRangeValue)
                  }
                  value={dateRangeValue}
                />
              </div>
            </FormGroup>
          </div>
          <div className="clearButton">
            <Button size="md" color="primary" onClick={filterShowMobile}>
              Close
            </Button>
          </div>
        </div>

        <ul className="socialLoginList">
          <li>
            <FacebookOAuthComponent
              setAccessTokenFor={_setAccessTokenFor}
              usersocialMediaData={usersocialMediaData}
            />
          </li>
          <li>
            <LinkedInOAuthComponent
              setAccessTokenFor={_setAccessTokenFor}
              usersocialMediaData={usersocialMediaData}
            />
          </li>
          {
            <li>
              <Button
                color="link"
                onClick={() => _generateInstagramBussinessId()}
                disabled={usersocialMediaData?.isAuthorizedFromInstagram}
              >
                <img
                  src={require("../../../../assets/img/instagram.png").default}
                  alt="linkedin"
                  height={18}
                  className="socialIcon"
                />
                Generate Instagram Bussiness ID
                {usersocialMediaData?.isAuthorizedFromInstagram && (
                  <img
                    src={require("../../../../assets/img/verify.svg").default}
                    alt="check"
                    height={18}
                  />
                )}
              </Button>
              <i
                id="socialInfo"
                className="fs-16 text-primary  ml-2 fa fa-info-circle"
              ></i>

              <Tooltip
                placement="top"
                isOpen={tooltipOpen}
                className="socialToolTip"
                target="socialInfo"
                toggle={toggle}
              >
                <div>
                  <h2>For Facebook Page Post</h2>
                  <ul>
                    <li>
                      1. Facebook Account Mandatory and Create A Facebook Page
                    </li>
                  </ul>
                </div>

                <div>
                  <h2>For Instagram Post -</h2>
                  <ul>
                    <li>1. Facebook Account Mandatory</li>
                    <li>2. You have To Setup Business Instagram account</li>
                    <li>
                      3. You Have To Linked Business Instagram account to
                      Desired Facebook Page
                    </li>
                  </ul>
                </div>
              </Tooltip>
            </li>
          }
          {usersocialMediaData?.isAuthorizedFromFacebook ||
          usersocialMediaData?.isAuthorizedFromInstagram ||
          usersocialMediaData?.isAuthorizedFromLinkedIn ? (
            <Button
              color="danger"
              outline
              onClick={() => _toggleSocialMedia(true)}
            >
              Logout
            </Button>
          ) : null}
        </ul>

        <CustomTable
          striped
          isPageStartFromOne={true}
          pageNumber={tableConfig.pageNumber}
          tableData={socialMediaData}
          headerKeys={socialMediaDataHeaderKeys}
          dataFormat={_dataFormat}
          totalCount={socialMediaDataTotalCount}
          onPaginate={(pageNumber, pageSize) => _paginate(pageNumber, pageSize)}
          // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
          showTableLoading={loading.showTableLoading}
        />
      </div>
      <AddPostModal
        isOpen={addPostModalData?.isOpen}
        data={addPostModalData?.data}
        toggle={_toggleAddPostModal}
        socialMediaData={usersocialMediaData}
        onSuccess={() => _fetchAllSocialMediaPosts()}
      />
      <PagesModal
        isOpen={pagesModal?.isOpen}
        data={pagesModal?.data}
        toggle={_togglePagesModal}
        onSuccess={() => _fetchAllSocialMediaPosts()}
      />
      <SocialMediaLogout
        isOpen={socialMedia?.isOpen}
        data={socialMedia?.data}
        toggle={_toggleSocialMedia}
        onSuccess={() => _fetchAllSocialMediaPosts()}
      />
    </>
  );
};

export default SocialMediaManagement;
