import React, { useEffect } from "react";
import { Button } from "reactstrap";
import { FacebookClientId, FacebookRedirectUri } from "../../../config";

const FacebookOAuthComponent = ({ setAccessTokenFor, usersocialMediaData }) => {
  const clientId = FacebookClientId;
  const redirectUri = FacebookRedirectUri; // This should match the one you specified in your LinkedIn App settings.
  const scope = "email profile";

  //   const responseType = 'token';

  const handleLinkedInLogin = () => {
    setAccessTokenFor("facebook");
    // Construct the authorization URL
    // const clientId = "245789971783100";
    // const redirectUri = "https://devapp.closewise.com/";
    // const redirectUri = "http://localhost:3000/";
    // const redirectUri =
    // "https://closewise-pro.surge.sh/agent/marketing/social-media-management/";
    const authUrl = `https://www.facebook.com/v12.0/dialog/oauth?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=email,public_profile,publish_video,pages_show_list,ads_management,business_management,instagram_basic,instagram_content_publish,pages_read_engagement,pages_manage_metadata,pages_read_user_content,pages_manage_posts,pages_manage_engagement`;
    // Redirect the user to the Facebook login page
    window.location.href = authUrl;
  };

  // After successful login, LinkedIn will redirect to your specified 'redirectUri' with the access token as a hash fragment.

  return (
    <div>
      {/* <h2>LinkedIn OAuth</h2> */}
      <Button
        color={"link"}
        onClick={handleLinkedInLogin}
        disabled={usersocialMediaData?.isAuthorizedFromFacebook}
      >
        <img
          src={require("../../../assets/img/facebook.png").default}
          alt="Facebook"
          height={20}
          className="socialIcon"
        />
        Login with Facebook
        {usersocialMediaData?.isAuthorizedFromFacebook && (
          <img
            src={require("../../../assets/img/verify.svg").default}
            alt="check"
            height={18}
          />
        )}
      </Button>
    </div>
  );
};

export default FacebookOAuthComponent;
