import React, { Component, createRef } from "react";
import {
  Button,
  Col,
  CustomInput,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Label,
  ListGroup,
  ListGroupItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import {
  LanguageList,
  loanTypeConfig,
  loanTypeConfigAgent,
} from "../../../../config";
import { RegexConfig } from "../../../../config/RegexConfig";
import {
  capitalize,
  compareDateObjects,
  deepClone,
  errorHandler,
  formatAddressInSingleText,
  formatDateAndTime,
  formatDateAsPerTimeZoneWithDateAndTime,
  formatDateMoment,
  getPhoneNumberFromBrackets,
  showToast,
  timezoneList,
} from "../../../../helper-methods";
import {
  googlePlaceDetails,
  googlePlaceSearch,
} from "../../../../helper-methods/googleService";
import ProductType from "../ProductType";
import CreatableSelect from "react-select";
import Select from "react-select";
import Chips from "react-chips";
import { connect } from "react-redux";
import usaStates from "../../../../config/usa_states_titlecase";
import {
  clientGetListTeamsOfClient,
  companyGetListTeamsOfClient,
  getRonPlatformTypes,
} from "../../../../http/http-calls";
// import ReactDatetime from "react-datetime";
import AppointmentScheduler from "../AppointmentScheduler";
import RegularAppointmentScheduler from "../RegularAppointmentScheduler";

export class EditOrderDetailsModal extends Component {
  constructor(props) {
    super(props);
    this.appointmentSchedulerRef = createRef();
  }
  state = {
    formFields: {
      name: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      email: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      phoneHome: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      phoneWork: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      phoneMobile: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      borrower: [
        {
          _id: {
            value: "",
          },
          borrowerName: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          email: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          workPhone: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          alternatePhone: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          isForeignNational: {
            value: false,
            isDirty: false,
            isValidate: true,
          },
          language: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          languageTypeOther: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
          timeZone: {
            value: "",
            isDirty: false,
            isValidate: true,
          },
        },
      ],
      fileNumber: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      appointmentDate: {
        value: "",
        isDirty: false,
        isValidate: true,
        isFieldDirty: false,
      },
      clientName: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      clientEmail: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      clientId: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      clientTeams: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      signingCompanyFee: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      lenderClientId: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      signingCompanyId: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      lender: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      trackingInfo: {
        value: "",
        isValid: false,
        isDirty: false,
        isValidate: true,
      },
      internalNotes: {
        value: "",
        isValid: false,
        isDirty: false,
        isValidate: true,
        isFieldDirty: false,
      },
      personOfContactName: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      personOfContactEmail: {
        value: [],
        error: null,
        isDirty: false,
        isValidate: true,
      },
      signingType: {
        value: "Mobile",
        isDirty: false,
        isValidate: true,
      },
      witnessCount: {
        value: 0,
        isDirty: false,
        isValidate: true,
      },
      loanOfficer: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      titleCompany: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      agentFee: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: true,
      },
      closingAddress: {
        value: "",
        placeId: null,
        error: null,
        isDirty: false,
        isValidate: true,
      },
      agentNotes: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      personalNotes: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      instructionSignerData: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      instructions: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      miles: {
        value: "",
        error: null,
        isDirty: false,
        isValidate: false,
      },
      loanType: {
        value: [],
        isValid: false,
        isDirty: false,
        isValidate: true,
      },
      loanTypeOther: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      loanCategories: {
        value: [],
        error: null,
        isDirty: false,
        isValidate: false,
      },
      ronPlatformType: {
        value: [],
        isDirty: false,
        isValidate: true,
      },
      closingAddressLine1: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      closingAddressLine2: {
        value: "",
        isDirty: false,
        isValidate: false,
      },
      closingAddressCity: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      closingAddressState: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      closingAddressZip: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      closingAddressTimeZone: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
    },
    textInstruction: "",
    googleAddressResult: [],
    loading: false,
    isAddressListShow: false,
    ronPlatformTypeOptions: {},
    isChecked: false,
    questions: [],
    clientTeamsList: [],
    appointmentSchedulerData: {
      appointmentDate: null,
      // endAppointmentDate: null,
      tBD: false,
      isRangeDated: false,
    },
    previousAppointmentSchedulerData: undefined,
  };

  _setFormFields = async (data, ronPlatformTypeOptions = []) => {
    console.log({ data });
    const { formFields, questions, appointmentSchedulerData } = deepClone(
      this.state
    );
    const { clients } = this.props;

    console.log({ data }, { ronPlatformTypeOptions });
    if (!data) {
      return;
    }

    let borrower = [];
    console.log({ data });

    data._borrower.forEach((each) => {
      borrower.push({
        _id: {
          value: each._id,
        },
        borrowerName: {
          value: each.name.full || "",
          isDirty: false,
          isValidate: true,
        },
        email: {
          value: each.email || "",
          isDirty: false,
          isValidate: true,
        },
        workPhone: {
          value: getPhoneNumberFromBrackets(each?.phone?.work) || "",
          isDirty: false,
          isValidate: true,
        },
        alternatePhone: {
          value: getPhoneNumberFromBrackets(each?.phone?.alternate) || "",
          isDirty: false,
          isValidate: true,
        },
        isForeignNational: {
          value: each.isForeignNational,
          isDirty: false,
          isValidate: true,
        },
        language: {
          value: each.language || "English",
          isDirty: false,
          isValidate: true,
        },
        languageTypeOther: {
          value: each.languageTypeOther || "",
          isDirty: false,
          isValidate: true,
        },
        timeZone: {
          value: each.timeZone || "",
          isDirty: false,
          isValidate: true,
        },
      });
    });
    formFields["borrower"] = borrower;
    formFields["fileNumber"].value = data.fileNumber ? data.fileNumber : "";
    formFields["lender"].value = data.lender ? data.lender : "";
    formFields["loanOfficer"].value = data.loanOfficer ? data.loanOfficer : "";
    formFields["titleCompany"].value = data.titleCompany
      ? data.titleCompany
      : "";
    formFields["agentFee"].value = data?.agentFee?.toString();

    formFields["loanType"].value = data?.loanType.map((each) => ({
      label: each,
      value: each,
    }));
    formFields["loanCategories"].value = data?.loanCategories || [];
    formFields["loanTypeOther"].value = data?.loanTypeOther || "";
    formFields["internalNotes"].value = data?.internalNotes || "";
    formFields["signingType"].value = data?.signingType || "";

    if (ronPlatformTypeOptions) {
      const temp = [];
      ronPlatformTypeOptions?.forEach((each) => {
        data?.ronPlatformTypes?.forEach((subEach) => {
          if (subEach === each?.value) {
            temp.push(each);
          }
        });
      });

      console.log({ temp });
      formFields["ronPlatformType"].value = temp || [];
    }
    formFields["closingAddressTimeZone"].value =
      data?.closingAddress?.timeZone || "";

    if (data._client) {
      formFields["clientName"].value = data?._client?.name?.full
        ? data?._client?.name?.full
        : "";
      formFields["clientEmail"].value = data._client.email
        ? data._client.email
        : "";
      // formFields["clientId"].value = data._client.id ? data._client.id : "";
      formFields["clientId"].value =
        clients.length > 0 &&
        clients.find((item) =>
          data._client
            ? item._client === data._client._id
            : item._signingCompany === data._signingCompany._id
        )?._id;

      if (this.props.userData.type === "signingcompany") {
        this._getCompanyListTeamsOfClient(data?._client?._id);
      }
    }

    if (data?._clientAssistantTeam?._id) {
      formFields["clientTeams"].value = data?._clientAssistantTeam?._id || "";
    }

    if (this.props.userData.type === "agent") {
      // formFields["clientId"].value = data._client.id ? data._client.id : "";
      formFields["clientId"].value =
        clients.length > 0 &&
        clients.find((item) =>
          data._client
            ? item?._client === data?._client?._id
            : item?._signingCompany
            ? item?._signingCompany === data?._signingCompany?._id
            : item?._assistant === data?._assistant?._id
        )?._id;
    }

    if (this.props.userData.type === "client") {
      formFields["lenderClientId"].value = data?._lenderClient
        ? data?._lenderClient?._id
        : "";
      formFields["signingCompanyFee"].value =
        data?.signingCompanyFee || data?.signingCompanyFee === 0
          ? data?.signingCompanyFee.toString()
          : "";
      formFields["signingCompanyId"].value = data?._signingCompany?._id
        ? data?._signingCompany?._id
        : "";
    }

    if (this.props.userData.type === "signingcompany") {
      formFields["signingCompanyFee"].value =
        data?.signingCompanyFee || data?.signingCompanyFee === 0
          ? data?.signingCompanyFee.toString()
          : "";

      formFields["clientId"].value = data?._client?.id ? data?._client?.id : "";
    }

    if (data?.appointmentDate) {
      appointmentSchedulerData.appointmentDate = data?.appointmentDate;
    }
    // if (data?.isRangeDated && data?.endAppointmentDate) {
    //   appointmentSchedulerData.endAppointmentDate =
    //     formatDateAsPerTimeZoneWithDateAndTime(
    //       data?.endAppointmentDate,
    //       data?.closingAddress?.timeZone
    //     );
    //   appointmentSchedulerData.isRangeDated = true;
    // }

    if (data?.tBD) {
      appointmentSchedulerData.tBD = true;
    }

    formFields["trackingInfo"].value = data.trackingInfo
      ? data.trackingInfo
      : "";

    if (data.personOfContact) {
      formFields["personOfContactName"].value = data.personOfContact.name
        ? data.personOfContact.name
        : "";

      formFields["personOfContactEmail"].value = data.personOfContact.email
        ? data?.personOfContact?.email?.filter((e) => e !== "")
        : [];
    }

    if (data.closingAddress) {
      formFields["closingAddressLine1"].value =
        data?.closingAddress?.line1 || "";
      formFields["closingAddressCity"].value = data?.closingAddress?.city || "";
      formFields["closingAddressState"].value =
        data?.closingAddress?.state || "";
      formFields["closingAddressZip"].value = data?.closingAddress?.zip || "";
      formFields["closingAddress"].value = formatAddressInSingleText(
        data.closingAddress
      );
      formFields["closingAddress"].placeId = "initialValue";
    }

    formFields["agentNotes"].value = data.agentNotes || "";
    formFields["personalNotes"].value = data.personalNotes || "";
    formFields["instructions"].value = data.instructions
      ? data.instructions
      : "";

    formFields["miles"].value = data._mileage?.distance
      ? data._mileage.distance.toString()
      : "0";

    formFields["witnessCount"].value = data.witnessCount
      ? data.witnessCount.toString()
      : 0;

    this.setState({
      formFields,
      textInstruction: data.instructions,
      questions: data.questions,
      appointmentSchedulerData,
      previousAppointmentSchedulerData: appointmentSchedulerData,
    });
  };

  componentDidUpdate = async (prevProps) => {
    const { isOpen, data } = this.props;

    if (isOpen && prevProps.isOpen !== isOpen && data) {
      try {
        const res = await getRonPlatformTypes();
        // console.log({ res });
        const ronOptions = Object.keys(res?.ronPlatformTypes)?.map((each) => ({
          label: each,
          value: res?.ronPlatformTypes[each],
        }));

        this.setState({
          ronPlatformTypeOptions: ronOptions,
        });
        this._setFormFields(data, ronOptions);

        if (this.props.userData.type === "client") {
          const clientId = this.props.userData?.user?.id;
          this._getClientListTeamsOfClient(clientId);
        }
      } catch (err) {
        errorHandler(err);
        this._setFormFields(data);
      }
    }
  };

  _resetFormFields = () => {
    this.setState({
      formFields: {
        name: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        email: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        phoneHome: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        phoneWork: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        phoneMobile: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        borrower: [
          {
            _id: {
              value: "",
            },
            borrowerName: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            email: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            workPhone: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            alternatePhone: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            isForeignNational: {
              value: false,
              isDirty: false,
              isValidate: true,
            },
            language: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            languageTypeOther: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
            timeZone: {
              value: "",
              isDirty: false,
              isValidate: true,
            },
          },
        ],
        fileNumber: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        appointmentDate: {
          value: "",
          isDirty: false,
          isValidate: true,
          isFieldDirty: false,
        },
        clientName: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        clientEmail: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        clientId: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        clientTeams: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        signingCompanyFee: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
        lenderClientId: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
        signingCompanyId: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
        lender: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        trackingInfo: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        internalNotes: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
          isFieldDirty: false,
        },
        personOfContactName: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        personOfContactEmail: {
          value: [],
          error: null,
          isDirty: false,
          isValidate: true,
        },
        signingType: {
          value: "Mobile",
          isDirty: false,
          isValidate: true,
        },
        witnessCount: {
          value: 0,
          isDirty: false,
          isValidate: true,
        },
        loanOfficer: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        titleCompany: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        agentFee: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: true,
        },
        closingAddress: {
          value: "",
          placeId: null,
          error: null,
          isDirty: false,
          isValidate: true,
        },
        agentNotes: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        personalNotes: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        instructions: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        miles: {
          value: "",
          error: null,
          isDirty: false,
          isValidate: false,
        },
        loanType: {
          value: [],
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        loanTypeOther: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
        loanCategories: {
          value: [],
          error: null,
          isDirty: false,
          isValidate: false,
        },
        ronPlatformType: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
        closingAddressLine1: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
        closingAddressLine2: {
          value: "",
          isDirty: false,
          isValidate: false,
        },
        closingAddressCity: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
        closingAddressState: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
        closingAddressZip: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
        closingAddressTimeZone: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
      },
      googleAddressResult: [],
      loading: false,
      isAddressListShow: false,
      ronPlatformTypeOptions: {},
      isChecked: false,
      questions: [],
      clientTeamsList: [],
    });
  };

  _closeModal = () => {
    this._resetFormFields();
    this.props.toggle();
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (
          key === "borrower" ||
          (formFields[key].isDirty && formFields[key].isValidate)
        ) {
          switch (key) {
            case "borrower": {
              formFields.borrower.forEach((each, index) => {
                if (each.borrowerName?.value.trim().length) {
                  formFields[key][index]["borrowerName"].isDirty = false;
                  formFields[key][index]["borrowerName"].error = null;
                } else {
                  formFields[key][index]["borrowerName"].isDirty = true;
                  formFields[key][index]["borrowerName"].error = "*Required";
                  isFormValid = false;
                }

                if (
                  formFields[key][index]["email"].value &&
                  formFields[key][index]["email"].value.trim().length
                ) {
                  if (
                    RegexConfig.email.test(
                      String(
                        formFields[key][index]["email"].value
                      ).toLowerCase()
                    )
                  ) {
                    formFields[key][index]["email"].isDirty = false;
                    formFields[key][index]["email"].error = null;
                  } else {
                    formFields[key][index]["email"].isDirty = true;
                    formFields[key][index]["email"].error = "*Invalid Email";
                    isFormValid = false;
                  }
                } else {
                  formFields[key][index]["email"].isDirty = false;
                  formFields[key][index]["email"].error = null;
                }

                if (
                  formFields[key][index]["workPhone"].value &&
                  formFields[key][index]["workPhone"].value.length
                ) {
                  if (
                    RegexConfig.phone.test(
                      String(
                        formFields[key][index]["workPhone"].value
                      ).toLowerCase()
                    )
                  ) {
                    formFields[key][index]["workPhone"].isDirty = false;
                    formFields[key][index]["workPhone"].error = null;
                  } else {
                    isFormValid = false;
                    formFields[key][index]["workPhone"].isDirty = true;
                    formFields[key][index]["workPhone"].error =
                      "*Invalid Phone Number";
                  }
                } else {
                  formFields[key][index]["workPhone"].isDirty = false;
                  formFields[key][index]["workPhone"].error = null;
                }

                if (
                  formFields[key][index]["alternatePhone"].value &&
                  formFields[key][index]["alternatePhone"].value.length
                ) {
                  if (
                    RegexConfig.phone.test(
                      String(
                        formFields[key][index]["alternatePhone"].value
                      ).toLowerCase()
                    )
                  ) {
                    formFields[key][index]["alternatePhone"].isDirty = false;
                    formFields[key][index]["alternatePhone"].error = null;
                  } else {
                    isFormValid = false;
                    formFields[key][index]["alternatePhone"].isDirty = true;
                    formFields[key][index]["alternatePhone"].error =
                      "*Invalid Phone Number";
                  }
                } else {
                  formFields[key][index]["alternatePhone"].isDirty = false;
                  formFields[key][index]["alternatePhone"].error = null;
                }
              });
              break;
            }
            case "closingAddress": {
              if (formFields?.signingType?.value === "Mobile") {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  if (formFields[key].placeId) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Invalid Address";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                if (
                  formFields[key]?.value &&
                  formFields[key]?.value?.trim()?.length
                ) {
                  if (formFields[key].placeId) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Invalid Address";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              }
              break;
            }
            case "clientId": {
              if (this.props.userData.type !== "client") {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            // case "personOfContactName":
            // case "lender":
            case "fileNumber": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }

              break;
            }
            case "agentFee": {
              if (
                this.props.userData.type !== "client" &&
                this.props.userData.type !== "signingcompany"
              ) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  if (
                    isNaN(formFields[key].value.trim()) ||
                    Number(formFields[key].value.trim()) < 0
                  ) {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Fee must be a positive number";
                    isFormValid = false;
                  } else {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  }
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            case "loanTypeOther": {
              if (formFields.loanType.value === "Other") {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }
            // case "personOfContactEmail":
            // case "clientEmail":
            case "loanType": {
              if (formFields[key]?.value?.length) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "closingAddressTimeZone": {
              if (
                formFields.signingType.value === "RON" ||
                (formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone")
              ) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            case "clientTeams": {
              if (
                this.props.userData.type !== "agent" &&
                this.state?.clientTeamsList?.length
              ) {
                if (
                  formFields[key]?.value &&
                  formFields[key]?.value?.trim()?.length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            default:
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _validateDraftForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields, pdfFile } = this.state;
      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty && formFields[key].isValidate) {
          switch (key) {
            case "closingAddressLine1":
            case "closingAddressCity":
            case "closingAddressZip": {
              if (
                formFields.signingType.value === "RON" ||
                (formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone") ||
                (formFields[key].value && formFields[key].value.trim().length)
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            // case "closingAddressState": {
            //   if (
            //     formFields.signingType.value === "RON" ||
            //     (formFields[key].value && formFields[key].value.trim().length)
            //   ) {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   } else {
            //     formFields[key].isDirty = true;
            //     formFields[key].error = "*Required";
            //     isFormValid = false;
            //   }
            //   break;
            // }
            // case "appointmentDate": {
            //   if (formFields[key].value && formFields[key].value.length) {
            //     formFields[key].isDirty = false;
            //     formFields[key].error = null;
            //   } else {
            //     formFields[key].isDirty = true;
            //     formFields[key].error = "*Required";
            //     isFormValid = false;
            //   }
            //   break;
            // }
            case "closingSearch": {
              if (
                pdfFile === null &&
                this.props.location.search.length === 0 &&
                this.state.isAppointmentSearch
              ) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  if (formFields[key].placeId) {
                    formFields[key].isDirty = false;
                    formFields[key].error = null;
                  } else {
                    formFields[key].isDirty = true;
                    formFields[key].error = "*Invalid Address";
                    isFormValid = false;
                  }
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            case "closingAddressTimeZone": {
              if (
                formFields.signingType.value === "RON" ||
                (formFields?.loanType?.value?.length === 1 &&
                  formFields?.loanType?.value[0]?.value === "AttorneyPhone")
              ) {
                if (
                  formFields[key].value &&
                  formFields[key].value.trim().length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }

            case "clientTeams": {
              if (
                // draft is only for signing company
                this.props.userData.type === "signingcompany" &&
                this.state?.clientTeamsList?.length
              ) {
                if (
                  formFields[key]?.value &&
                  formFields[key]?.value?.trim()?.length
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Required";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            default: {
            }
          }
        }
      });
      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };
  _onSelectAddress = async (fieldName, pid) => {
    const { formFields } = deepClone(this.state);
    const addr = await googlePlaceDetails(pid.place_id);
    // console.log("Asd",addr)

    formFields["closingAddressLine1"].value = addr.address;
    formFields["closingAddressCity"].value = addr.city;
    let cState = usaStates.filter((state) => state.name === addr.state)[0]
      ?.abbreviation;
    formFields["closingAddressState"].value = cState;
    formFields["closingAddressZip"].value = addr.postal;

    formFields[fieldName].value = pid.description;
    formFields[fieldName].placeId = pid.place_id;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields, isAddressListShow: false }, () => {
      this._validateForm();
    });
  };

  _googlePlaceSearch = async (searchValue) => {
    if (!this.state.loading.cardUpdateLoading) {
      const googleAddressResult = await googlePlaceSearch(searchValue);
      this.setState({ googleAddressResult, isAddressListShow: true });
    }
  };

  // _onChangeFormField = (fieldName, value) => {
  //   const { formFields } = deepClone(this.state);

  //   if (fieldName === "agentFee" || fieldName === "miles") {
  //     if (
  //       isNaN(value) ||
  //       (value.includes(".") && value.split(".")[1].length > 2)
  //     ) {
  //       return;
  //     }
  //   }
  //   if (fieldName === "closingAddress") {
  //     this._googlePlaceSearch(value);
  //     formFields[fieldName].placeId = null;
  //   }
  //   formFields[fieldName].value = value;
  //   formFields[fieldName].isDirty = true;
  //   this.setState({ formFields }, () => {
  //     // validation
  //     this._validateForm();
  //   });
  // };

  _onChangeFormField = (fieldName, value) => {
    const { formFields, signingInstruction, textInstruction } = deepClone(
      this.state
    );
    console.log("ioioi", fieldName, value);
    if (fieldName === "signingCompanyFee") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }

    if (fieldName === "closingAddress" || fieldName === "borrowerSearch") {
      // googlePlaceSearch(value)
      //   .then((res) => {
      //     // console.log(res);
      //     this.setState({ googleAddressResult: res, show: fieldName });
      //   })
      //   .catch((err) => {
      //     // console.log(err);
      //     showToast("Errors");
      //   });
      this._googlePlaceSearch(value);

      if (value === "") {
        // formFields["closingAddressLine1"].value = "";
        // formFields["closingAddressCity"].value = "";
        // formFields["closingAddressState"].value = "";
        // formFields["closingAddressZip"].value = "";
        formFields["closingAddress"].placeId = null;
      }
    }

    if (fieldName === "instructionId") {
      const findInstruction = signingInstruction.find(
        (each) => each._id === value
      );
      formFields.instructionId.value = findInstruction?._id || "";
      formFields.instructions.value = findInstruction?.description || "";
      formFields.instructionSignerData.value =
        findInstruction?.description || "";
    }

    // if (fieldName === "appointmentDate") {
    //   formFields[fieldName].value = value ? new Date(value).toISOString() : "";
    //   formFields.appointmentDate.isFieldDirty = true;
    // } else formFields[fieldName].value = value;
    formFields[fieldName].value = value;

    if (fieldName === "internalNotes") {
      formFields.internalNotes.isFieldDirty = true;
    }
    if (
      fieldName === "homePhone" ||
      fieldName === "phone" ||
      fieldName === "workPhone" ||
      fieldName === "alternatePhone"
    )
      formFields[fieldName].value = value.replace(
        /\([^]*\)|[^a-z0-9\s]|\D|\s/gi,
        ""
      );

    if (fieldName === "agent") {
      formFields[fieldName].value = value;
      formFields["agentFee"].isDirty = true;
    }

    // if (fieldName === "closingAddressZip") {
    //   this._getAgentWithZipcode(value);
    // }

    if (fieldName === "witnessCount") {
      let text =
        value !== ""
          ? !textInstruction?.includes("witness")
            ? `<p>#${value} witness required.</p>`.concat(textInstruction)
            : textInstruction?.replace(/\d+(?= witness required)/, value)
          : textInstruction?.replace(/<p>#.*?<\/p>/, "");

      this.setState({
        textInstruction: text,
      });
    }

    if (this.props.userData.type !== "agent" && fieldName === "clientId") {
      formFields["clientTeams"].value = "";
      this._getCompanyListTeamsOfClient(value);
    }

    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // validation
      // this._validateForm();
      // if (fieldName === "signingType" && value) {
      //   this.setState(
      //     {
      //       isBorrowerSearch: false,
      //       isAppointmentSearch: false,
      //     },
      //     () => {
      //       this._validateForm();
      //     }
      //   );
      // }
      if (fieldName === "witnessCount" || fieldName === "instructionId") {
        this._signerDataInstruction();
      }
    });
  };

  // for signing company side
  _getCompanyListTeamsOfClient = async (clientId) => {
    try {
      const res = await companyGetListTeamsOfClient(clientId);

      this.setState({ clientTeamsList: res?.teams || [] });
    } catch (err) {
      errorHandler(err);
    }
  };

  // for client side
  _getClientListTeamsOfClient = async () => {
    try {
      const clientId = this.props.userData?.user?.id;
      const res = await clientGetListTeamsOfClient(clientId);

      this.setState({ clientTeamsList: res?.teams || [] });
    } catch (err) {
      errorHandler(err);
    }
  };

  _signerDataInstruction = () => {
    const { formFields, textInstruction } = deepClone(this.state);
    let finalText = textInstruction?.concat(
      formFields?.instructionSignerData?.value
    );
    formFields["instructions"].value = this._sortText(finalText);

    this.setState({ formFields });
  };

  _onChipSelect = (chips, arrName = "chips") => {
    let isValid = chips.length
      ? this._isValidEmail(chips[chips.length - 1])
      : true;
    if (isValid) {
      this.setState({
        formFields: {
          ...this.state.formFields,
          personOfContactEmail: {
            ...this.state.formFields.personOfContactEmail,
            value: chips,
          },
        },
      });
    }
  };

  _isValidEmail = (value) => {
    if (RegexConfig.email.test(String(value).toLowerCase())) {
      return true;
    } else {
      showToast("Invalid Email", "error");

      return false;
    }
  };

  // _onChangeFormFieldBorrower = (fieldName, value, subField, index) => {
  //   const { formFields } = deepClone(this.state);
  //   if (fieldName === "borrower") {
  //     formFields[fieldName][index][subField].value = value;
  //   }
  //   this.setState({ formFields });
  // };

  _onChangeFormFieldBorrower = (fieldName, value, subField, index) => {
    const { formFields } = deepClone(this.state);
    if (fieldName === "borrower") {
      // formFields[fieldName][index][subField].value = value;
      if (subField === "workPhone" || subField === "alternatePhone") {
        formFields[fieldName][index][subField].value = value.replace(/\D/g, "");
      } else {
        formFields[fieldName][index][subField].value = value;
      }
    }

    this.setState({ formFields }, () => {
      this._validateForm();
    });
    this._addTextInInstruction(subField, value, index);
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        if (formFields[e].isDirty !== undefined) formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _markDraftFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      formFields["closingAddressCity"].isDirty = true;
      formFields["closingAddressState"].isDirty = true;
      formFields["closingAddressZip"].isDirty = true;
      formFields["closingAddress"].isDirty = true;
      formFields["closingAddressTimeZone"].isDirty = true;
      formFields["clientTeams"].isDirty = true;

      this.setState({ formFields }, () => resolve(true));
    });
  };

  _onSubmit = async () => {
    const { formFields, questions } = this.state;
    const { data, clients, userData, updateClosingDetailsById, resetDetails } =
      this.props;

    try {
      const isAppointmentSchedulerResponse =
        await this.appointmentSchedulerRef.current.getAppointmentSchedulerData();
      const { appointmentSchedulerData, isValid } =
        isAppointmentSchedulerResponse;
      console.log("lo", appointmentSchedulerData);
      if (!data.isSavedAsDraft) {
        await this._markAllFieldDirty();
      } else {
        await this._markDraftFieldDirty();
      }

      const isFormValid = !data.isSavedAsDraft
        ? await this._validateForm()
        : await this._validateDraftForm();

      if (!isFormValid || !isValid) return;

      this.setState({ loading: true });

      const payload = {
        borrower: formFields.borrower.map((borrower) => {
          const [first, ...rest] = borrower.borrowerName.value
            .trim()
            .split(" ");
          return {
            _id: borrower._id?.value,
            name: { first, last: rest.join(" ") },
            email: borrower.email.value || undefined,
            phone: {
              work: borrower.workPhone.value || "",
              alternate: borrower.alternatePhone.value || "",
            },
            isForeignNational: borrower.isForeignNational.value,
            language: borrower.language.value || undefined,
            languageTypeOther: borrower.languageTypeOther.value || undefined,
            timeZone: borrower.timeZone.value || undefined,
          };
        }),
        loanTypeOther: formFields.loanTypeOther.value || undefined,
        fileNumber: formFields.fileNumber.value.trim(),
        lender: formFields.lender?.value?.trim(),
        trackingInfo: formFields.trackingInfo.value || undefined,
        agentFee:
          formFields.agentFee?.value?.trim() !== String(data.agentFee)
            ? formFields.agentFee?.value?.trim()
            : undefined,
        personOfContact: {
          name: formFields.personOfContactName.value.trim(),
          email: formFields.personOfContactEmail.value?.length
            ? formFields.personOfContactEmail.value
            : [],
        },
        instructions: formFields.instructions.value || undefined,
        agentNotes:
          formFields.agentNotes.value?.trim() !== data.agentNotes
            ? formFields.agentNotes?.value?.trim()
            : undefined,
        personalNotes: formFields.personalNotes.value?.trim(),
        signingType: formFields.signingType?.value?.trim(),
        // witnessCount: +formFields.witnessCount?.value?.trim(),
        internalNotes: formFields.internalNotes?.isFieldDirty
          ? formFields.internalNotes?.value?.trim()
          : undefined,
        loanType: formFields.loanType?.value?.map((item) => item.value),
        loanCategories: await formFields.loanCategories?.value,
        ronPlatformTypes:
          formFields.signingType?.value === "RON"
            ? formFields?.ronPlatformType?.value?.map((each) => each.value)
            : undefined,
        _clientAssistantTeam: formFields.clientTeams?.value?.trim(),
      };
      payload["witnessCount"] = formFields.witnessCount?.value
        ? +String(formFields.witnessCount.value).trim()
        : undefined;

      if (questions.length) payload.questions = questions;

      if (
        formFields.closingAddress.placeId &&
        formFields.closingAddress.placeId !== "initialValue"
      ) {
        const addressObj = await googlePlaceDetails(
          formFields.closingAddress.placeId
        );
        const abbr = usaStates.find(
          (item) => item.name === addressObj.state
        ).abbreviation;
        payload.closingAddress = {
          line1: addressObj.address,
          city: addressObj.city,
          county: addressObj.county,
          state: addressObj.state.length > 2 ? abbr : addressObj.state,
          zip: addressObj.postal,
          lat: addressObj.lat,
          lng: addressObj.lng,
          timeZone:
            formFields.signingType?.value === "RON" ||
            (formFields.loanType?.value?.length === 1 &&
              formFields.loanType?.value[0]?.value === "AttorneyPhone")
              ? formFields.closingAddressTimeZone.value
              : undefined,
        };
      }

      if (
        !compareDateObjects(
          appointmentSchedulerData,
          this.state.previousAppointmentSchedulerData,
          formFields.closingAddressTimeZone.value
        )
      ) {
        Object.assign(payload, appointmentSchedulerData);
      }

      if (userData.type === "client" || userData.type === "signingcompany") {
        payload.signingCompanyFee = userData?.user?.isTitleCompany
          ? "1000"
          : formFields.signingCompanyFee.value || undefined;
        payload.signingCompanyId = formFields.signingCompanyId?.value?.trim()
          .length
          ? formFields.signingCompanyId.value !== data?._signingCompany?.id
            ? formFields.signingCompanyId.value
            : undefined
          : undefined;
        payload.lenderClientId = formFields.lenderClientId.value || undefined;
      }

      if (formFields.clientId.value?.trim().length) {
        const client = clients.find(
          (each) => each._id === formFields.clientId.value
        );
        if (client) {
          if (client._client) payload.clientId = client._client;
          else if (client._signingCompany)
            payload.signingCompanyId = client._signingCompany;
          else payload.assistantId = client._assistant;
        }
      }

      if (data?._id && Object.keys(payload).length) {
        console.log({ payload });
        await updateClosingDetailsById(data._id, payload);
        await resetDetails();
        showToast("Closing Details Updated", "success");
      }

      this._closeModal();
    } catch (error) {
      errorHandler(error);
      this.setState({ loading: false });
    }
  };

  _addBorrower = () => {
    const { formFields } = this.state;
    formFields.borrower.push({
      borrowerName: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      email: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      workPhone: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      alternatePhone: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      isForeignNational: {
        value: false,
        isDirty: false,
        isValidate: true,
      },
      language: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      languageTypeOther: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      timeZone: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
    });
    this.setState({ formFields });
  };
  _removeBorrower = (index) => {
    const { formFields } = this.state;
    formFields.borrower.splice(index, 1);
    this.setState({ formFields });
  };

  _addTextInInstruction = (subField, value, index) => {
    const { textInstruction } = deepClone(this.state);

    if (subField === "isForeignNational") {
      let text = value
        ? `<p>Signer ${index + 1} is a foreign National.</p>`.concat(
            textInstruction
          )
        : textInstruction.replace(
            `<p>Signer ${index + 1} is a foreign National.</p>`,
            ""
          );
      // console.log("text", text);
      this.setState(
        {
          textInstruction: text,
        },
        () => {
          this._signerDataInstruction();
        }
      );
    }
    if (subField === "timeZone") {
      let text =
        value !== ""
          ? !textInstruction.match(
              new RegExp(`<p>Signer ${index + 1} is in [\\w/]+.</p>`, "g")
            )
            ? `<p>Signer ${index + 1} is in ${value}.</p>`.concat(
                textInstruction
              )
            : textInstruction.replace(
                new RegExp(`<p>Signer ${index + 1} is in [\\w/]+.</p>`, "g"),
                `<p>Signer ${index + 1} is in ${value}.</p>`
              )
          : textInstruction.replace(
              new RegExp(`<p>Signer ${index + 1} is in [\\w/]+.</p>`, "g"),
              ""
            );
      // console.log("text", text);
      this.setState(
        {
          textInstruction: text,
        },
        () => {
          this._signerDataInstruction();
        }
      );
    }
    if (subField === "language") {
      let text =
        value !== ""
          ? !textInstruction.match(
              new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g")
            )
            ? `<p>Signer ${index + 1} speaks ${value}.</p>`.concat(
                textInstruction
              )
            : textInstruction.replace(
                new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g"),
                `<p>Signer ${index + 1} speaks ${value}.</p>`
              )
          : textInstruction.replace(
              new RegExp(`<p>Signer ${index + 1} speaks \\w+\\.</p>`, "g"),
              ""
            );
      // console.log("text", text);
      this.setState(
        {
          textInstruction: text,
        },
        () => {
          this._signerDataInstruction();
        }
      );
    }
  };

  _sortText = (input) => {
    console.log("text", input);
    const signerRegex = /<p>.*?\bSigner\s+\d+\b.*?<\/p>/g;
    const signerMatches = input?.match(signerRegex);
    console.log("signerMatches", signerMatches);
    // sort the signer strings in ascending order
    if (signerMatches !== null) {
      signerMatches?.sort();

      // replace the original signer strings with the sorted ones
      let output = input?.replace(signerRegex, () => signerMatches.shift());

      // console.log(output);
      return output;
    } else {
      return input;
    }
  };

  handleCheckboxChange = () => {
    this.setState({ isChecked: !this.state.isChecked });
  };

  _onChangeQuestionFormField = (value, index) => {
    const { questions } = deepClone(this.state);
    questions[index].reply = !questions[index].reply;
    this.setState({ questions });
  };

  render() {
    const {
      formFields,
      loading,
      googleAddressResult,
      isAddressListShow,
      ronPlatformTypeOptions,
      clientTeamsList,
    } = this.state;

    const {
      isOpen,
      clients,
      signingCompanies,
      data,
      AppointmentSchedulerConfig,
    } = this.props;
    console.log("this", this.state.formFields);
    return (
      <Modal
        isOpen={isOpen}
        toggle={this._closeModal}
        className="modal-dialog-centered"
        scrollable
      >
        <ModalHeader toggle={this._closeModal}>
          Update Order Details
        </ModalHeader>

        <ModalBody>
          <Row form>
            <Col md="12">
              <FormGroup className="floatingLabel">
                <Input
                  type="text"
                  value={formFields.closingAddress.value}
                  name="closingAddress"
                  placeholder=" "
                  disabled={loading.cardUpdateLoading}
                  autoComplete="off"
                  onChange={(event) =>
                    this._onChangeFormField(
                      "closingAddress",
                      event.target.value
                    )
                  }
                />
                <Label>Signing Address</Label>
                {formFields.closingAddress.error && (
                  <div className="validation-error">
                    {formFields.closingAddress.error}
                  </div>
                )}

                {googleAddressResult &&
                googleAddressResult.length &&
                isAddressListShow ? (
                  <ListGroup flush className="customSearchOptions">
                    {googleAddressResult.map((addr, index) => {
                      return (
                        <ListGroupItem
                          key={addr.place_id || index}
                          className="cursorPointer"
                          onClick={() =>
                            this._onSelectAddress("closingAddress", addr)
                          }
                        >
                          {addr.description}
                        </ListGroupItem>
                      );
                    })}
                  </ListGroup>
                ) : null}
              </FormGroup>
            </Col>

            {formFields?.borrower?.length > 0 &&
              formFields?.borrower?.map((each, index) => (
                <>
                  <Col md="12">
                    <div className="CardTitle">
                      <h2 className="sectionTtile"> Signer {index + 1} </h2>
                    </div>
                  </Col>
                  <Col md={6}>
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        placeholder=" "
                        value={formFields?.borrower[index]?.borrowerName?.value}
                        name="borrowerName"
                        onChange={(event) =>
                          this._onChangeFormFieldBorrower(
                            "borrower",
                            event.target.value,
                            "borrowerName",
                            index
                          )
                        }
                      />
                      <Label>Name</Label>
                      {formFields?.borrower[index]?.borrowerName?.error && (
                        <div className="validation-error">
                          {formFields?.borrower[index]?.borrowerName?.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="floatingLabel">
                      <Input
                        type="text"
                        placeholder=" "
                        value={formFields?.borrower[index]?.email?.value}
                        name="email"
                        onChange={(event) =>
                          this._onChangeFormFieldBorrower(
                            "borrower",
                            event.target.value,
                            "email",
                            index
                          )
                        }
                      />
                      <Label>Email</Label>
                      {formFields?.borrower[index]?.email?.error && (
                        <div className="validation-error">
                          {formFields?.borrower[index]?.email?.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="floatingLabel withInputGroup">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>+1</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder=" "
                          value={formFields?.borrower[index]?.workPhone?.value}
                          name="phone"
                          onChange={(event) =>
                            this._onChangeFormFieldBorrower(
                              "borrower",
                              event.target.value,
                              "workPhone",
                              index
                            )
                          }
                        />
                        <Label>Phone</Label>
                      </InputGroup>
                      {formFields?.borrower[index]?.workPhone?.error && (
                        <div className="validation-error">
                          {formFields?.borrower[index]?.workPhone?.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="floatingLabel withInputGroup">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>+1</InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder=" "
                          value={
                            formFields?.borrower[index]?.alternatePhone?.value
                          }
                          name="phone"
                          onChange={(event) =>
                            this._onChangeFormFieldBorrower(
                              "borrower",
                              event.target.value,
                              "alternatePhone",
                              index
                            )
                          }
                        />
                        <Label>Alternate Phone</Label>
                      </InputGroup>
                      {formFields?.borrower[index]?.alternatePhone?.error && (
                        <div className="validation-error">
                          {formFields?.borrower[index]?.alternatePhone?.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                  <Col md="6">
                    <FormGroup className="floatingLabel custom-select-wrapper">
                      <Input
                        type="select"
                        name="language"
                        placeholder=" "
                        onChange={(event) =>
                          this._onChangeFormFieldBorrower(
                            "borrower",
                            event.target.value,
                            "language",
                            index
                          )
                        }
                        value={formFields?.borrower[index]?.language?.value}
                      >
                        <option>Select</option>

                        {LanguageList.map((lang, index) => (
                          <option key={index} value={lang.value}>
                            {lang.label}
                          </option>
                        ))}
                      </Input>
                      <Label>Language</Label>
                    </FormGroup>
                  </Col>

                  <Col md="6">
                    <FormGroup className="floatingLabel custom-select-wrapper">
                      <Input
                        type="select"
                        name="timeZone"
                        placeholder=" "
                        value={formFields?.borrower[index]?.timeZone?.value}
                        onChange={(event) =>
                          this._onChangeFormFieldBorrower(
                            "borrower",
                            event.target.value,
                            "timeZone",
                            index
                          )
                        }
                      >
                        <option>Select</option>
                        {timezoneList().map((item) => (
                          <option>{item}</option>
                        ))}
                      </Input>
                      <Label>Time Zone</Label>
                    </FormGroup>
                  </Col>

                  <Col md="6" className="mb-3">
                    <CustomInput
                      checked={
                        formFields?.borrower[index]?.isForeignNational?.value
                      }
                      type="checkbox"
                      id={`"isSignerForeigner_edit_order_modal_${index}"`}
                      label="Foreign National"
                      onChange={(event) =>
                        this._onChangeFormFieldBorrower(
                          "borrower",
                          event.target.checked,
                          "isForeignNational",
                          index
                        )
                      }
                    />
                  </Col>
                  <Col className="text-right mb-3">
                    {index === formFields.borrower.length - 1 && (
                      <Button color="link" onClick={() => this._addBorrower()}>
                        <img
                          src={
                            require("../../../../assets/img/addItem.svg")
                              .default
                          }
                          alt="add item"
                        />
                      </Button>
                    )}
                    {formFields.borrower.length > 1 ? (
                      <Button
                        color="link"
                        onClick={() => this._removeBorrower(index)}
                      >
                        <img
                          src={
                            require("../../../../assets/img/removeItem.svg")
                              .default
                          }
                          alt="remove item"
                        />
                      </Button>
                    ) : null}
                  </Col>
                </>
              ))}

            <Col md="12">
              <FormGroup className="floatingLabel">
                <Input
                  type="text"
                  placeholder=" "
                  value={formFields.fileNumber.value}
                  name="fileNumber"
                  onChange={(event) =>
                    this._onChangeFormField("fileNumber", event.target.value)
                  }
                />
                <Label>File#</Label>
                {formFields.fileNumber.error && (
                  <div className="validation-error">
                    {formFields.fileNumber.error}
                  </div>
                )}
              </FormGroup>
            </Col>

            {/* {this.props.userData.type !== "client" && (
              <Col md="6">
                <FormGroup className="floatingLabel">
                  <Input
                    type="text"
                    value={formFields.lender.value}
                    name="lender"
                    placeholder=" "
                    onChange={(event) =>
                      this._onChangeFormField("lender", event.target.value)
                    }
                  />
                  <Label>Order on behalf of</Label>
                  {formFields.lender.error && (
                    <div className="validation-error">
                      {formFields.lender.error}
                    </div>
                  )}
                </FormGroup>
              </Col>
            )} */}

            <Col md="12">
              {this.props.userData.type !== "client" && (
                <FormGroup className="floatingLabel custom-select-wrapper">
                  {
                    // <Input
                    //   type="select"
                    //   placeholder=""
                    //   value={formFields.lenderClientId.value}
                    //   name="lenderClientId"
                    //   onChange={(event) =>
                    //     this._onChangeFormField(
                    //       "lenderClientId",
                    //       event.target.value
                    //     )
                    //   }
                    // >
                    //   <option value="">Select</option>
                    //   {clients.map((each) => (
                    //     <option key={each._id} value={each._id}>
                    //       {capitalize(each.name.full)}
                    //     </option>
                    //   ))}
                    // </Input>
                    // ) :
                    this.props.userData.type === "signingcompany" ? (
                      <Input
                        type="select"
                        placeholder=""
                        value={formFields.clientId.value}
                        name="clientId"
                        onChange={(event) =>
                          this._onChangeFormField(
                            "clientId",
                            event.target.value
                          )
                        }
                        disabled={data?._createdByClient}
                      >
                        <option value="">Select</option>
                        {clients.map((each) => (
                          <option key={each._id} value={each._id}>
                            {capitalize(each.companyName)}
                          </option>
                        ))}
                      </Input>
                    ) : (
                      <Input
                        type="select"
                        placeholder=" "
                        value={formFields.clientId.value}
                        name="clientId"
                        onChange={(event) =>
                          this._onChangeFormField(
                            "clientId",
                            event.target.value
                          )
                        }
                      >
                        <option value="">Select</option>
                        {clients.map((client) => (
                          <option key={client._id} value={client._id}>
                            {client?._signingCompany
                              ? client?.companyName
                              : client?._client
                              ? client?.name?.full
                              : client?._assistant
                              ? // : client?._companyAssistant
                                // ? client.name?.full
                                // : client?._clientAssistant
                                client.name?.full
                              : "N/A"}
                          </option>
                        ))}
                      </Input>
                    )
                  }
                  <Label>Client</Label>
                  {formFields.clientId.error && (
                    <div className="validation-error">
                      {formFields.clientId.error}
                    </div>
                  )}
                </FormGroup>
              )}
            </Col>

            {this.props.userData.type === "signingcompany" ||
            this.props.userData.type === "client" ? (
              <Col md="12">
                <FormGroup className="floatingLabel">
                  <div
                    className={`custom-select-wrapper ${
                      (this.props.userData.type === "signingcompany" &&
                        formFields?.clientId?.value === "") ||
                      !clientTeamsList?.length
                        ? "disabled"
                        : ""
                    }`}
                  >
                    <Input
                      type="select"
                      placeholder=" "
                      value={formFields?.clientTeams?.value}
                      onChange={(event) =>
                        this._onChangeFormField(
                          "clientTeams",
                          event.target.value
                        )
                      }
                      name="clientTeamsId"
                      disabled={
                        (this.props.userData.type === "signingcompany" &&
                          (formFields?.clientId?.value === "" ||
                            data?._createdByClient)) ||
                        !clientTeamsList?.length ||
                        (this.props.userData.type === "client" &&
                          data?._createdBy)
                      }
                      // onBlur={() => this._validateForm()}
                    >
                      <option value="">Select</option>
                      {clientTeamsList?.map((team) => (
                        <option key={team?._id} value={team?._id}>
                          {team?.teamName ? capitalize(team?.teamName) : ""}
                        </option>
                      ))}
                    </Input>
                    <Label>Client Teams</Label>
                  </div>
                  {formFields?.clientTeams?.error && (
                    <div className="validation-error">
                      {formFields?.clientTeams?.error}
                    </div>
                  )}
                </FormGroup>
              </Col>
            ) : null}

            <Col md="12">
              <FormGroup className="floatingLabel">
                <Input
                  type="text"
                  placeholder=" "
                  value={formFields.personOfContactName.value}
                  name="line2"
                  onChange={(event) =>
                    this._onChangeFormField(
                      "personOfContactName",
                      event.target.value
                    )
                  }
                />
                <Label> Person Of Contact (Name)</Label>
                {formFields.personOfContactName.error && (
                  <div className="validation-error">
                    {formFields.personOfContactName.error}
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup className="ChipsWrap floatingLabel valueAdded">
                <Chips
                  value={formFields.personOfContactEmail.value}
                  onChange={(arr) => this._onChipSelect(arr, "chips")}
                  createChipKeys={[9, 13]} //  Key codes to add chips
                  placeholder={"+Add Email"}
                />
                <Label>Person Of Contact (Email)</Label>
                {formFields.personOfContactEmail.error && (
                  <div className="validation-error">
                    {formFields.personOfContactEmail.error}
                  </div>
                )}
              </FormGroup>
            </Col>

            <Col md="12">
              <FormGroup className="floatingLabel">
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    placeholder=" "
                    name="signingType"
                    value={formFields.signingType.value}
                    onChange={(event) =>
                      this._onChangeFormField("signingType", event.target.value)
                    }
                  >
                    <option value="Mobile">Mobile Signing</option>
                    <option value="RON">RON</option>
                  </Input>
                  <Label>Signing Type</Label>
                </div>
              </FormGroup>
            </Col>

            {formFields.signingType.value === "RON" ||
            (formFields?.loanType?.value?.length === 1 &&
              formFields?.loanType?.value[0]?.value === "AttorneyPhone") ? (
              <Col md={12}>
                <FormGroup className="floatingLabel">
                  <div className="custom-select-wrapper">
                    <Input
                      type="select"
                      name="timeZone"
                      value={formFields.closingAddressTimeZone.value}
                      onChange={(event) =>
                        this._onChangeFormField(
                          "closingAddressTimeZone",
                          event.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      {timezoneList().map((item) => (
                        <option>{item}</option>
                      ))}
                    </Input>
                    <Label>Time Zone</Label>
                  </div>
                  {formFields?.closingAddressTimeZone?.error && (
                    <div className="validation-error">
                      {formFields?.closingAddressTimeZone?.error}
                    </div>
                  )}
                </FormGroup>
              </Col>
            ) : null}

            {formFields?.signingType?.value === "RON" ? (
              <Col md="12">
                <FormGroup
                  className="withChips floatingLabel valueAdded form-group"
                  style={{ zIndex: 5 }}
                >
                  <Select
                    isClearable={true}
                    placeholder="Select"
                    options={ronPlatformTypeOptions}
                    value={formFields?.ronPlatformType?.value}
                    onChange={(event) =>
                      this._onChangeFormField("ronPlatformType", event)
                    }
                    // placeholder="RON Platform Type"
                    className="customMultiSelect zIndex2"
                    isMulti={true}
                  />
                  <Label style={{ zIndex: 2 }}>RON Platform Type</Label>
                </FormGroup>
              </Col>
            ) : null}

            <Col md="12">
              {/* <FormGroup
                className={`withChips floatingLabel valueAdded`}
                style={{ zIndex: 4 }}
              >
                <>
                  <CreatableSelect
                    isClearable
                    placeholder="Select"
                    className="customMultiSelect"
                    isMulti
                    style={{}}
                    value={formFields.loanType.value}
                    options={loanTypeConfig}
                    onChange={(event) =>
                      this._onChangeFormField("loanType", event)
                    }
                    onBlur={() => this._validateForm()}
                  />
                  <Label style={{ zIndex: 3 }}>Product Type</Label>
                </>
                {formFields.loanTypeOther.error && (
                  <div className="validation-error">
                    {formFields.loanTypeOther.error}
                  </div>
                )}
                {formFields.loanType.error && (
                  <div className="validation-error">
                    {formFields.loanType.error}
                  </div>
                )}
              </FormGroup>  */}
              <FormGroup className={`floatingLabel valueAdded mt-3`}>
                <ProductType
                  value={formFields?.loanType?.value}
                  loanCategories={formFields?.loanCategories?.value}
                  onChange={(type, event) =>
                    this._onChangeFormField(type, event)
                  }
                  // blur={() => this._validateForm()}
                  productTypeField={"loanType"}
                  selectedCategoriesField={"loanCategories"}
                  error={formFields?.loanType?.error}
                  // isClientCreateOrder={
                  //   this.props.userData?.type === "client" ? true : false
                  // }
                  clientCreateOrderOptions={
                    this.props.loanType
                    // this.props.userData?.type === "client"
                    //   ? loanTypeConfig
                    //   : null
                  }
                  // onClickOutside={() => this._validateForm()}
                />
              </FormGroup>
              {formFields?.loanType?.value?.filter((i) => i?.value === "Other")
                ?.length > 0 && (
                <FormGroup className={`floatingLabel mt-3`}>
                  <Input
                    type="text"
                    placeholder=" "
                    value={formFields.loanTypeOther.value}
                    name="loanType"
                    onChange={(event) =>
                      this._onChangeFormField(
                        "loanTypeOther",
                        event.target.value
                      )
                    }
                  />
                  <Label>Product Type Other</Label>
                </FormGroup>
              )}
            </Col>
            {formFields.loanType.value === "Other" && (
              <Col md={12}>
                <FormGroup className="floatingLabel">
                  <Input
                    type="text"
                    value={formFields.loanTypeOther.value}
                    name="loanType"
                    onChange={(event) =>
                      this._onChangeFormField(
                        "loanTypeOther",
                        event.target.value
                      )
                    }
                  />
                </FormGroup>
              </Col>
            )}

            {this.props.userData.type === "signingcompany" && (
              <Col md="12">
                <FormGroup className="floatingLabel">
                  <Input
                    type="text"
                    placeholder=""
                    value={formFields.trackingInfo.value}
                    name="line2"
                    onChange={(event) =>
                      this._onChangeFormField(
                        "trackingInfo",
                        event.target.value
                      )
                    }
                    disabled={data?.isSavedAsDraft}
                  />
                  <Label>TrackingInfo</Label>
                </FormGroup>
              </Col>
            )}
            <Col md="12">
              <FormGroup className="floatingLabel">
                <Input
                  type="number"
                  placeholder=" "
                  value={formFields.witnessCount.value}
                  name="witnessCount"
                  onChange={(event) =>
                    this._onChangeFormField("witnessCount", event.target.value)
                  }
                />
                <Label>Witness Number</Label>
              </FormGroup>
            </Col>

            {
              // (this.props.userData.type === "client" ||
              // this.props.userData.type === "signingcompany") &&
              // <Col md={12}>
              //   <FormGroup
              //     className={`floatingLabel ${
              //       formFields.appointmentDate.value ||
              //       this.state.isOpenReactDatetime
              //         ? "valueAdded"
              //         : ""
              //     }`}
              //   >
              //     <ReactDatetime
              //       inputProps={{
              //         className: "form-control",
              //         placeholder: " ",
              //         value: formatDateAndTime(
              //           formFields.appointmentDate.value
              //         ),
              //       }}
              //       onChange={(e) =>
              //         this._onChangeFormField("appointmentDate", e._d)
              //       }
              //       onOpen={() => this.setState({ isOpenReactDatetime: true })}
              //       onClose={() =>
              //         this.setState({ isOpenReactDatetime: false })
              //       }
              //       // onClose={this._validateForm}
              //       // isValidDate={(current) =>
              //       //   current.isAfter(getYesterdayDate())
              //       // }
              //       timeFormat={true}
              //       timeConstraints={{ minutes: { step: 15 } }}
              //     />
              //     <Label>Appointment Date & Time</Label>
              //     {formFields.appointmentDate.error && (
              //       <div className="validation-error">
              //         {formFields.appointmentDate.error}
              //       </div>
              //     )}
              //   </FormGroup>
              // </Col>
              <Col md={12}>
                {this?.props?.forAgent ? (
                  <RegularAppointmentScheduler
                    ref={this.appointmentSchedulerRef}
                    data={this.state.appointmentSchedulerData}
                    timezone={data?.closingAddress?.timeZone}
                  />
                ) : (
                  <AppointmentScheduler
                    ref={this.appointmentSchedulerRef}
                    data={this.state.appointmentSchedulerData}
                    AppointmentSchedulerConfig={AppointmentSchedulerConfig}
                    timezone={data?.closingAddress?.timeZone}
                  />
                )}
              </Col>
            }
            <Col md="12">
              <FormGroup className="floatingLabel custom-select-wrapper">
                {this.props.userData.type === "client" &&
                  (data?.appointmentDate &&
                  !data?._agent &&
                  (data?.status === "Pending" ||
                    data?.status === "Cancelled") ? (
                    <>
                      {" "}
                      <Input
                        type="select"
                        style={{ height: 40 }}
                        value={formFields.signingCompanyId.value}
                        name="signingCompanyId"
                        onChange={(event) =>
                          this._onChangeFormField(
                            "signingCompanyId",
                            event.target.value
                          )
                        }
                        disabled={
                          this.props.userData.type === "client" &&
                          data?._createdBy
                        }
                      >
                        <option value="">Select</option>
                        {signingCompanies.map((each) => (
                          <option key={each._id} value={each._id}>
                            {capitalize(each.companyName)}
                          </option>
                        ))}
                      </Input>
                      <Label>Signing Companies</Label>
                    </>
                  ) : null)}
              </FormGroup>
            </Col>

            {this.props.userData.type === "client" ||
            this.props.userData.type === "signingcompany" ? (
              !this.props.userData?.user?.isTitleCompany && (
                <Col md="12">
                  <FormGroup className="floatingLabel withInputGroup">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>$</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder=" "
                        value={formFields.signingCompanyFee.value}
                        name="signingCompanyFee"
                        onChange={(event) =>
                          this._onChangeFormField(
                            "signingCompanyFee",
                            event.target.value
                          )
                        }
                        disabled={
                          this.props.userData.type === "client" &&
                          !data?._createdByClient
                        }
                      />
                      <Label>Signing Fee</Label>
                    </InputGroup>
                    {formFields.signingCompanyFee.error && (
                      <div className="validation-error">
                        {formFields.signingCompanyFee.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>
              )
            ) : (
              <Col md="12">
                <FormGroup className="floatingLabel withInputGroup">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>$</InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      placeholder=" "
                      value={formFields.agentFee.value}
                      name="agentFee"
                      onChange={(event) =>
                        this._onChangeFormField("agentFee", event.target.value)
                      }
                    />
                    <Label>Fees Offered</Label>
                  </InputGroup>
                  {formFields.agentFee.error && (
                    <div className="validation-error">
                      {formFields.agentFee.error}
                    </div>
                  )}
                </FormGroup>
              </Col>
            )}

            {/* {this.props.userData?.type === "signingcompany" ? (
              <Col md="12">
                <FormGroup className="floatingLabel custom-select-wrapper">
                  <Input
                    type="textarea"
                    rows="3"
                    placeholder=" "
                    value={formFields?.internalNotes?.value}
                    name="internalNotes"
                    onChange={(event) =>
                      this._onChangeFormField(
                        "internalNotes",
                        event.target.value
                      )
                    }
                  />
                  <Label>Internal Notes</Label>
                </FormGroup>{" "}
              </Col>
            ) : null} */}
          </Row>
          {/* <CustomInput
            type="checkbox"
            id="other"
            label="Other"
            className="mb-2"
            checked={this.state.isChecked}
            onChange={this.handleCheckboxChange}
          /> */}
          {formFields.signingCompanyId.value !== ""
            ? this.state.questions.length > 0
              ? this.state.questions.map((question, index) => (
                  <CustomInput
                    type="checkbox"
                    id={`demoQuestion
                      +${index}`}
                    label={question.query}
                    className="mb-2"
                    checked={question.reply}
                    onChange={(event) =>
                      this._onChangeQuestionFormField("question", index)
                    }
                  />
                ))
              : ""
            : ""}
        </ModalBody>

        <ModalFooter>
          <Button color="primary" size="lg" outline onClick={this._closeModal}>
            Cancel
          </Button>

          <Button
            color="primary"
            size="lg"
            disabled={loading}
            onClick={this._onSubmit}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null}{" "}
            Update
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};
export default connect(mapStateToProps, null)(EditOrderDetailsModal);
