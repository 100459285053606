import React from "react";
import {
  Row,
  Col,
  Input,
  Button,
  Label,
  FormGroup,
  CardBody,
  Card,
  Spinner,
  Badge,
} from "reactstrap";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import AddExpense from "../components/add-expense-accounting-agent-modal";
import ViewReceiptModal from "../components/view-receipt-modal";
import {
  deepClone,
  formatCurrencyValue,
  formatDateHideYearIfSame,
  getLoggedInUserId,
  openUrlOnNewTab,
  showToast,
  structureQueryParams,
  uploadFileOnServer,
  isRegularUser,
  refreshFunc,
  formatOnlyDateMoment,
  errorHandler,
} from "../../../helper-methods";
import {
  deleteAgentExpense,
  getAllAgentExpense,
  terminateExpenseRecurring,
  updateAgentExpense,
} from "../../../http/http-calls";
import DocumentViewModal from "../components/documentViewModal";
import AddNotesModal from "../components/add-notes-income-list-modal";
import UpgradeAccountModal from "../components/Modals/upgradeAccountModal";
import { BASE_URL, itemListOptionsConfig } from "../../../config";
import CustomTable from "../components/CustomTable";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomCard from "../components/CustomCard";

class ExpensesAccountingAgent extends React.Component {
  state = {
    expensesList: [],
    expenseType: [],
    expensesTotalCount: 0,
    expensesTotalAmount: 0,
    expenseSelectedRows: [],
    expensesHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      { id: "date", label: "Date", noSort: true },
      { id: "amount", label: "Amount", noSort: true },
      { id: "item", label: "Type", noSort: true },
      { id: "notes", label: "Notes", noSort: true },
      { id: "receipt", label: "Receipt", noSort: true },
      { id: "action", label: "Action", noSort: true },
    ],
    expenseCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    },
    dateRangeValue: null,
    filters: {
      expenseType: "",
      search: "",
    },
    addExpense: {
      isOpen: false,
      data: null,
    },
    viewReceiptModal: {
      isOpen: false,
      data: null,
    },
    addNotesModal: {
      isOpen: false,
      data: null,
    },
    loading: {
      showTableLoading: false,
      receiptLoading: false,
      terminateExpenseLoading: false,
    },
    documentViewModalData: {
      isOpen: false,
      externalUrl: null,
      modalHeading: null,
      fileType: null,
    },
    upgradeAccountModal: {
      isOpen: false,
      data: null,
    },
    receipt: {
      file: {
        previewBlob: null,
        uploadData: null,
        type: null,
      },
      uploadingFileId: null,
      error: null,
      isValidate: false,
    },
    isClassAdded: false,
  };

  constructor(props) {
    super(props);
    this.tableRef = React.createRef();
  }

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  componentDidMount = () => {
    // this._getAllAgentExpense();
    this._setFilterDataFromLocalStorage();
    HeaderEventEmitter.subscribe("reset-filter", () => {
      console.log("filter reset hit");
      this._resetFilter();
      refreshFunc("agent-accounting-expenses");
    });
    HeaderEventEmitter.subscribe("add-expense", () => {
      console.log("add expense");
      this._onToggleAddExpense(true);
    });
  };

  _getAllAgentExpense = () => {
    this._manageLoading("showTableLoading", true);

    const { filters, tableConfig, dateRangeValue } = deepClone(this.state);

    const filterPayload = {};

    if (filters.expenseType && filters.expenseType.length)
      filterPayload["item"] = filters.expenseType;
    if (dateRangeValue) {
      filterPayload["startDate"] = dateRangeValue[0];
      filterPayload["endDate"] = dateRangeValue[1];
    }
    tableConfig["filters"] = { ...filterPayload };

    getAllAgentExpense(tableConfig)
      .then((res) => {
        this.setState(
          {
            expensesList: res?.expenses,
            expenseType: res?.expenseTypes?.map((item) => ({
              label: item,
              value: item,
            })),
            expensesTotalCount: res?.totalCount,
            expensesTotalAmount: res?.totalAmount,
          },
          () => {
            this._manageLoading("showTableLoading", false);
          }
        );
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllAgentExpense();
    });
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => {
      this._paginate();
      this._persistFilter();
    });
  };

  _onToggleAddExpense = (isOpen = false, data = null) => {
    this.setState({
      addExpense: {
        isOpen,
        data,
      },
    });
  };

  _onToggleViewReceiptModal = (isOpen = false, data = null) => {
    this.setState({
      viewReceiptModal: {
        isOpen,
        data,
      },
    });
  };

  _filterOnChange = (type, value) => {
    const { filters } = this.state;
    filters[type] = value;
    this.setState({ filters }, () => {
      this._paginate();
      this._persistFilter();
    });
  };

  _resetFilter = () => {
    this.setState(
      {
        filters: {
          expenseType: "",
          search: "",
        },
        dateRangeValue: null,
      },
      () => {
        this._paginate();
        refreshFunc("agent-accounting-expenses-responsive");
      }
    );
  };

  _onToggleDocumentViewModal = (isOpen = false, expense = null) => {
    let { documentViewModalData } = deepClone(this.state);
    if (expense && isOpen) {
      documentViewModalData = {
        isOpen: true,
        externalUrl: expense.receipt,
        modalHeading: "View Receipt",
        fileType: expense.docType,
      };
    } else {
      documentViewModalData = {
        isOpen: false,
        externalUrl: null,
        modalHeading: null,
        fileType: null,
      };
    }
    this.setState({ documentViewModalData });
  };

  _onToggleAddNotesModal = (isOpen = false, data = null) => {
    this.setState({
      addNotesModal: {
        isOpen,
        data,
      },
    });
  };

  _setSelectedRows = (expenseSelectedRows = []) => {
    // let obj = expenseSelectedRows.map((each) =>
    //   this.state.expensesList.find((item) => item.id === each)
    // );

    // let isObjectReceipt = obj.find((each) => each?.receipt === undefined);

    // if (isObjectReceipt) {
    //   this.tableRef.current.onDeRowSelect(isObjectReceipt);
    // }

    // this.setState({
    //   expenseSelectedRows,
    // });
    try {
      expenseSelectedRows = expenseSelectedRows.filter(
        (each) => each.receipt !== undefined || each.receipt !== null
      );

      this.setState({ expenseSelectedRows });
    } catch (error) {
      console.log({ error });
    }
  };

  _downloadDataAsZip = () => {
    const { expenseSelectedRows } = deepClone(this.state);
    if (expenseSelectedRows.length) {
      this._manageLoading("showTableLoading", true);
      openUrlOnNewTab(
        `${BASE_URL}/expense/download-receipts?expenseids=${JSON.stringify(
          expenseSelectedRows
        )}`
      );
      this._setSelectedRows();
      this._manageLoading("showTableLoading", false);
    } else {
      showToast("Please select expense", "error");
    }
  };

  _downloadData = (action = "download") => {
    const { expensesList, filters, dateRangeValue } = this.state;

    if (!expensesList.length) {
      showToast(`No data for ${action}`, "error");
      return;
    }

    const payload = {
      userId: getLoggedInUserId(),
    };

    if (dateRangeValue) {
      // payload["startDate"] = dateRangeValue[0];
      // payload["endDate"] = dateRangeValue[1];
      payload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      payload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
    }

    if (filters.expenseType) payload["item"] = filters.expenseType;

    const queryParams = structureQueryParams(payload);

    if (action === "download")
      openUrlOnNewTab(`${BASE_URL}/agent/download/expenses${queryParams}`);
  };

  _terminateExpenseRecurring = (expenseId) => {
    this._manageLoading("terminateExpenseLoading", expenseId);
    terminateExpenseRecurring(expenseId)
      .then((res) => {
        const { expensesList } = deepClone(this.state);
        const expense = expensesList.find((exp) => exp._id === expenseId);
        if (expense) {
          expense["recurring"] = false;
          this.setState({ expensesList });
        }
        showToast("Expense Recurring Terminated.", "success");
        this._manageLoading("terminateExpenseLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("terminateExpenseLoading", false);
      });
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "notes": {
        return row ? (
          <Button
            color="link"
            title="View Notes"
            onClick={() => this._onToggleAddNotesModal(true, row)}
          >
            <img
              src={require("../../../assets/img/nodes.svg").default}
              alt="document"
              style={{ height: 16 }}
            />
          </Button>
        ) : (
          "-"
        );
      }
      case "item": {
        return cell
          ? itemListOptionsConfig.find((obj) => obj.value === cell)?.label ||
              cell
          : "N/A";
      }
      case "amount": {
        // return cell ? formatCurrencyValue(cell) : "N/A";
        return cell || cell === 0 ? formatCurrencyValue(cell) : "N/A";
      }
      case "date": {
        return cell ? formatDateHideYearIfSame(cell) : "N/A";
      }
      case "receipt": {
        return row && row.receipt && row.docType ? (
          <>
            {/* need to dynamic this button */}
            <Button
              color="link"
              className="pl-0"
              title="View Receipt"
              style={{ width: 120 }}
              onClick={() => openUrlOnNewTab(row.receipt)}
            >
              <img
                src={require("../../../assets/img/pdf.svg").default}
                style={{ height: 14, marginRight: "6px" }}
                alt="uploaded items"
              />
              {row?.receiptName ? row.receiptName : "N/A"}
              {/* View Receipt */}
              {/* 12345789.pdf */}
              {/* {row && row.receipt && row.docType ? row.receipt || row.docType : <>N/A</>} */}
            </Button>
          </>
        ) : (
          <>
            <Label className="uploadLink">
              {isRegularUser() && (
                <Input
                  type="file"
                  accept="image/x-png,image/gif,image/jpeg,.pdf"
                  value=""
                  onChange={(event) => this._updateFile(event, row)}
                />
              )}
              <div
                onClick={() =>
                  !isRegularUser() && this._toggleUpgradeAccountModal(true)
                }
              >
                <img
                  style={{ height: 14, marginRight: "6px" }}
                  src={
                    require("../../../assets/img/uploadIconBlue.svg").default
                  }
                  alt=""
                />
                Upload Receipt
              </div>
            </Label>
            {this.state.loading.receiptLoading &&
              this.state.uploadingFileId === row._id && <Spinner />}
          </>
        );
      }
      case "action": {
        return (
          <>
            <Button
              color="link"
              onClick={() => this._onToggleAddExpense(true, row)}
              disabled={this.state.loading.showTableLoading ? true : false}
            >
              <img
                src={require("../../../assets/img/pencil.svg").default}
                alt="edit"
              />
            </Button>
            <Button
              color="link"
              className="pl-0"
              // onClick={() => _removeInstruction(index)}
              onClick={() => this._agentDeleteExpense(row?._id)}
              disabled={this.state.loading.showTableLoading ? true : false}
            >
              <img
                src={require("../../../assets/img/deleteIcon.svg").default}
                alt="delete"
              />
            </Button>
            {row.recurring && (
              <Button
                color="link"
                disabled={
                  this.state.loading.terminateExpenseLoading === row._id
                }
                onClick={() => this._terminateExpenseRecurring(row._id)}
              >
                {this.state.loading.terminateExpenseLoading === row._id ? (
                  <i className="fa fa-spinner fa-spin mr-1" />
                ) : null}
                END RECURRING
              </Button>
            )}
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  _agentDeleteExpense = (id) => {
    this._manageLoading("showTableLoading", true);

    deleteAgentExpense(id)
      .then((res) => {
        this._manageLoading("showTableLoading", false);
        showToast("Deleted Successfully!", "success");
        this._getAllAgentExpense();
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _onSaveNotes = async (id, notes) => {
    const { receipt, loading } = deepClone(this.state);
    let payload = {
      notes: notes?.trim() ? notes.trim() : "",
    };
    if (receipt && receipt.file && receipt.file.uploadData) {
      const uploadedFilesRes = await uploadFileOnServer([receipt.file]);
      payload["receipt"] = uploadedFilesRes[0].url;
      payload["docType"] = uploadedFilesRes[0].docType;
      payload["receiptName"] = uploadedFilesRes[0].title;
    }

    return new Promise((resolve, reject) => {
      updateAgentExpense(id, payload)
        .then((res) => {
          this._getAllAgentExpense();
          loading["receiptLoading"] = false;
          this.setState({
            receipt: {
              file: {
                previewBlob: null,
                uploadData: null,
                type: null,
              },
              error: null,
              isValidate: false,
            },
            uploadingFileId: null,
            loading,
          });
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _updateFile = async (event, cell) => {
    const { receipt, loading } = deepClone(this.state);

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      const objFile = event.target.files[0];
      const objFileType = objFile.type.split("/")[0];
      if (objFileType === "image" || objFile.type.includes("pdf")) {
        receipt.file = {
          previewBlob: URL.createObjectURL(objFile),
          uploadData: objFile,
          type: objFileType === "application" ? "pdf" : objFileType,
        };
        loading["receiptLoading"] = true;
        this.setState({ receipt, loading, uploadingFileId: cell._id }, () => {
          this._onSaveNotes(cell._id);
        });
      } else {
        showToast("Only Image or PDF file is allowed", "error");
      }
    }
  };

  _toggleUpgradeAccountModal = (isOpen = false, data = null) => {
    this.setState({
      upgradeAccountModal: {
        isOpen,
        data,
      },
    });
  };
  _persistFilter = () => {
    const { filters, tableConfig } = this.state;
    if ((filters && filters.expenseType) || tableConfig.pageNumber !== 1) {
      const data = { filters, tableConfig };
      localStorage.agentexpense = JSON.stringify(data);
    } else {
      delete localStorage.agentexpense;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    // const { filters, tableConfig } = deepClone(this.state);

    if (localStorage && localStorage.agentexpense) {
      try {
        const filters = JSON.parse(localStorage.agentexpense);
        // let dateRange = [
        //   convertIsoToUtc(filters.dateRangeValue[0]),
        //   convertIsoToUtc(filters.dateRangeValue[1]),
        // ];
        this.setState(
          { filters: filters?.filters, tableConfig: filters?.tableConfig },
          () => {
            this._getAllAgentExpense();
          }
        );
      } catch (e) {
        this._getAllAgentExpense();
      }
    } else {
      this._getAllAgentExpense();
    }
  };

  _prepareFilterData = () => {
    const { filters } = deepClone(this.state);

    if (!filters.client) delete filters.client;
    if (!filters.status) delete filters.status;
    if (!filters.search?.trim()?.length) delete filters.search;

    return filters;
  };

  _cardHeaderFormat = (cell, row, header) => {
    console.log("23456", cell, header);
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="cardTableTitle">
              <Label>Type</Label>
              <span>
                {row.item
                  ? itemListOptionsConfig.find((obj) => obj.value === row.item)
                      ?.label || row.item
                  : "N/A"}
              </span>
            </div>

            <>
              <Button
                color="link"
                onClick={() => this._onToggleAddExpense(true, row)}
                disabled={this.state.loading.showTableLoading ? true : false}
              >
                <img
                  src={require("../../../assets/img/pencil.svg").default}
                  alt="edit"
                />
              </Button>
              <Button
                color="link"
                className="pl-0"
                onClick={() => this._agentDeleteExpense(row?._id)}
                disabled={this.state.loading.showTableLoading ? true : false}
              >
                <img
                  src={require("../../../assets/img/deleteIcon.svg").default}
                  alt="delete"
                />
              </Button>
            </>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Amount</Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row.amount || row.amount === 0
                ? formatCurrencyValue(row.amount)
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Date</Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row.date ? formatDateHideYearIfSame(row.date) : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Notes</Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row ? (
                <Button
                  color="link"
                  className=""
                  onClick={() => this._onToggleAddNotesModal(true, row)}
                >
                  <img
                    src={require("../../../assets/img/nodes.svg").default}
                    alt="Add Notes"
                  />
                </Button>
              ) : (
                "-"
              )}
            </div>
          </div>
        </li>

        <li>
          {/* <div className="dataWrap">
            <Label>Type</Label>
            <span
              style={{
                marginRight: 5,
                fontWeight: 600,
              }}
            >
              {row.item
                ? itemListOptionsConfig.find((obj) => obj.value === row.item)
                  ?.label || row.item
                : "N/A"}
            </span>
          </div> */}

          <div className="dataWrap">
            <Label>Receipt</Label>
            <div>
              {row && row.receipt && row.docType ? (
                <>
                  {/* need to dynamic this button */}
                  <Button
                    color="link"
                    className="pl-0 fs-12"
                    title="View Receipt"
                    onClick={() => openUrlOnNewTab(row.receipt)}
                  >
                    <img
                      src={require("../../../assets/img/pdf.svg").default}
                      style={{ height: 14, marginRight: "6px" }}
                      alt="uploaded items"
                    />
                    {row?.receiptName ? row.receiptName : "N/A"}
                    {/* 12345789.pdf */}
                    {/* {row && row.receipt && row.docType ? row.receipt || row.docType : <>N/A</>} */}
                  </Button>
                </>
              ) : (
                <>
                  <Label className="uploadLink">
                    {isRegularUser() && (
                      <Input
                        type="file"
                        accept="image/x-png,image/gif,image/jpeg,.pdf"
                        value=""
                        onChange={(event) => this._updateFile(event, row)}
                      />
                    )}
                    <img
                      style={{ height: 14, marginRight: "6px" }}
                      onClick={() =>
                        !isRegularUser() &&
                        this._toggleUpgradeAccountModal(true)
                      }
                      src={
                        require("../../../assets/img/uploadIconBlue.svg")
                          .default
                      }
                      alt=""
                    />
                    Upload Receipt
                  </Label>
                  {this.state.loading.receiptLoading &&
                    this.state.uploadingFileId === row._id && <Spinner />}
                </>
              )}
            </div>
          </div>
        </li>

        {row.recurring && (
          <li>
            <div className="dataWrap text-center">
              <Button
                color="link"
                outline
                className="mr-2"
                disabled={
                  this.state.loading.terminateExpenseLoading === row._id
                }
                onClick={() => this._terminateExpenseRecurring(row._id)}
              >
                {this.state.loading.terminateExpenseLoading === row._id ? (
                  <i className="fa fa-spinner fa-spin mr-1" />
                ) : null}
                END REOCCURRING
              </Button>
            </div>
          </li>
        )}
      </ul>
    );
  };

  render() {
    const {
      dateRangeValue,
      filters,
      expensesList,
      expenseType,
      expensesTotalCount,
      expensesHeaderKeys,
      tableConfig,
      loading,
      addExpense,
      viewReceiptModal,
      expensesTotalAmount,
      documentViewModalData,
      addNotesModal,
      expenseSelectedRows,
      upgradeAccountModal,
      isClassAdded,
      expenseCardHeaderKeys,
    } = this.state;
    console.log("state", this.state);
    return (
      <div className="content">
        <div className="responsiveTitle">
          <h2>Expenses</h2>

          <div className="rightSide">
            <Button color="link" onClick={this._resetFilter}>
              <img
                id="agent-accounting-expenses-responsive"
                src={require("../../../assets/img/refresh.svg").default}
                alt="refresh"
              />
            </Button>

            <Button
              className="filterBarIcon"
              color="link"
              onClick={this.filterShowMobile}
            >
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="Filter"
              />
            </Button>
            <Button
              className="floatingButton"
              color="primary"
              // onClick={() => this._onToggleAddExpense(true)}
              onClick={() =>
                isRegularUser()
                  ? this._onToggleAddExpense(true)
                  : this._toggleUpgradeAccountModal(true)
              }
            >
              Add Expense
            </Button>
          </div>
        </div>

        {/* filter for tablet and web */}
        <div
          onClick={this.filterShowMobile}
          className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
        ></div>
        <div
          className={`filterContainer responsiveFilter ${
            isClassAdded ? "show" : ""
          }`}
        >
          <div className="filterIcon">
            <img
              src={require("../../../assets/img/filter_icon.svg").default}
              alt="filter icon"
            />
            Filter by
          </div>
          <div className="mobileTitle">
            <h2>Filter by</h2>
            <Button
              color="link"
              className="closeButton"
              onClick={this.filterShowMobile}
            >
              <img
                src={require("../../../assets/img/close_grey.svg").default}
                alt="close"
                height={12}
              />
            </Button>
          </div>
          <div className="filterWrapper">
            <FormGroup className="dateRange">
              <Label>Date Range</Label>
              <DateRangePicker
                clearIcon={null}
                className="dateRange"
                format="MM-dd-y"
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                onChange={(dateRangeValue) =>
                  this._onChangeDatePicker(dateRangeValue)
                }
                maxDate={new Date()}
                value={dateRangeValue}
              />
            </FormGroup>
            <FormGroup>
              <Label>Type</Label>
              <div className="custom-select-wrapper">
                <Input
                  type="select"
                  value={filters.expenseType}
                  name="expenseType"
                  onChange={(e) =>
                    isRegularUser()
                      ? this._filterOnChange("expenseType", e.target.value)
                      : this._toggleUpgradeAccountModal(true)
                  }
                >
                  <option value="">All</option>
                  {expenseType.map((item) => (
                    <option key={item.value} value={item.value}>
                      {item.label}
                    </option>
                  ))}
                </Input>
              </div>
            </FormGroup>
          </div>
          <div className="clearButton">
            <Button size="md" color="primary" onClick={this.filterShowMobile}>
              Close
            </Button>
          </div>
        </div>

        {expensesTotalCount || expensesTotalAmount ? (
          <>
            <Row className="mt-4">
              <Col xl={3} lg={6} xs={6}>
                <Card className="infoCard">
                  <CardBody>
                    <div className="">
                      <span>Transactions</span>
                      <h4> {expensesTotalCount ? expensesTotalCount : 0}</h4>
                    </div>
                    <div className="icon">
                      <img
                        src={
                          require("../../../assets/img/Transactions.svg")
                            .default
                        }
                        alt="notaryActs"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col xl={3} lg={6} xs={6}>
                <Card className="infoCard">
                  <CardBody>
                    <div className="">
                      <span>Total Expenses</span>
                      <h4>
                        {expensesTotalAmount
                          ? formatCurrencyValue(expensesTotalAmount)
                          : "$0"}
                      </h4>
                    </div>
                    <div className="icon">
                      <img
                        src={
                          require("../../../assets/img/TotalExpense.svg")
                            .default
                        }
                        alt="notaryActs"
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        ) : null}

        {expenseSelectedRows.length ? (
          <div
            className="d-flex align-items-center justify-content-between"
            style={{ marginBottom: 25 }}
          >
            {/* when the checkboxes in the below table are selected/checked then below text is to be shown */}
            <div style={{ fontWeight: 500, fontSize: 14 }}>
              {expenseSelectedRows.length}{" "}
              {expenseSelectedRows.length > 1 ? "Records" : "Record"} are
              selected
            </div>

            {/* { isRegularUser()&& */}

            <Button
              color="link"
              outline
              className="ml-auto"
              onClick={() =>
                isRegularUser()
                  ? this._downloadData()
                  : this._toggleUpgradeAccountModal(true)
              }
            >
              Download
              <img
                src={require(`../../../assets/img/download_blue.png`).default}
                alt="download"
              />
            </Button>
            {/* } */}
          </div>
        ) : null}

        <div className="hideDesktop">
          <CustomCard
            isPageStartFromOne={true}
            pageNumber={tableConfig.pageNumber}
            tableData={expensesList}
            headerKeys={expenseCardHeaderKeys}
            // bodyKeys={agentClosingCardBodyKeys}
            dataFormat={this._dataFormat}
            totalCount={expensesTotalCount}
            onPaginate={(pageNumber, pageSize) =>
              this._paginate(pageNumber, pageSize)
            }
            rowSelection={true}
            setSelectedRows={(selectedRows) =>
              this._setSelectedRows(selectedRows)
            }
            selectedRows={expenseSelectedRows}
            // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
            showTableLoading={loading.showTableLoading}
            cardHeaderFormat={this._cardHeaderFormat}
            cardDataFormat={this._cardDataFormat}
          />
        </div>

        <div className="hideMobile">
          {expensesHeaderKeys && expensesHeaderKeys.length && (
            <CustomTable
              striped
              isPageStartFromOne={true}
              pageNumber={tableConfig.pageNumber}
              tableData={expensesList}
              headerKeys={expensesHeaderKeys}
              dataFormat={this._dataFormat}
              totalCount={expensesTotalCount}
              rowSelection={true}
              onPaginate={(pageNumber, pageSize) =>
                this._paginate(pageNumber, pageSize)
              }
              onSortChange={(sortName, sortOrder) =>
                // sort function is disabled
                this._paginate()
              }
              selectedRows={expenseSelectedRows}
              setSelectedRows={(selectedRows) =>
                this._setSelectedRows(selectedRows)
              }
              showTableLoading={loading.showTableLoading}
              ref={this.tableRef}
            />
          )}
        </div>

        <AddNotesModal
          isOpen={addNotesModal.isOpen}
          data={addNotesModal.data}
          toggle={() => this._onToggleAddNotesModal()}
          onSaveNotes={(id, notes) => this._onSaveNotes(id, notes)}
        />

        <AddExpense
          {...this.props}
          isOpen={addExpense.isOpen}
          data={addExpense.data}
          toggle={() => this._onToggleAddExpense()}
          resetDetails={() => this._paginate()}
          expenseType={this.state.expenseType}
        />

        <ViewReceiptModal
          isOpen={viewReceiptModal.isOpen}
          data={viewReceiptModal.data}
          toggle={() => this._onToggleViewReceiptModal()}
        />

        {documentViewModalData.isOpen && (
          <DocumentViewModal
            isOpen={documentViewModalData.isOpen}
            externalUrl={documentViewModalData.externalUrl}
            fileType={documentViewModalData.fileType}
            modalHeading={documentViewModalData.modalHeading}
            toggle={this._onToggleDocumentViewModal}
          />
        )}

        <UpgradeAccountModal
          {...this.props}
          isOpen={upgradeAccountModal.isOpen}
          toggle={this._toggleUpgradeAccountModal}
        />
      </div>
    );
  }
}

export default ExpensesAccountingAgent;
