import React from "react";
import {
  Card,
  CardBody,
  Button,
  CardHeader,
  CardTitle,
  CardFooter,
  FormGroup,
  Collapse,
  Label,
  // Collapse,
} from "reactstrap";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { agentGetProfitAndLoss } from "../../../http/http-calls";
import {
  capitalize,
  deepClone,
  formatCurrencyValue,
  getLoggedInUserId,
  openUrlOnNewTab,
  showToast,
  structureQueryParams,
  isRegularUser,
  convertIsoToUtc,
  formatOnlyDateMoment,
  errorHandler,
} from "../../../helper-methods";
import { connect } from "react-redux";
import { hideLoader, showLoader } from "../../../redux/actions/loader-data";
import UpgradeAccountModal from "../components/Modals/upgradeAccountModal";
import { BASE_URL } from "../../../config";

class ProfitAndLossReportingAgent extends React.Component {
  state = {
    incomes: [],
    expenses: [],
    totalIncome: 0,
    totalExpense: 0,
    netIncome: 0,
    dateRangeValue: null,
    loading: false,
    upgradeAccountModal: {
      isOpen: false,
      data: null,
    },
    isOpenCollapse: "income",
    isClassAdded: false,
  };

  componentDidMount = async () => {
    // this._agentGetProfitAndLoss(true);
    this._setFilterDataFromLocalStorage();
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  _agentGetProfitAndLoss = (isFullPageLoading = false) => {
    if (isFullPageLoading) {
      this.props.showLoader("Fetching Data...");
    }

    this.setState({ loading: true });

    const { dateRangeValue } = deepClone(this.state);

    let payload = {
      filters: {},
    };

    if (dateRangeValue) {
      payload.filters["startDate"] = dateRangeValue[0];
      payload.filters["endDate"] = dateRangeValue[1];
    }

    agentGetProfitAndLoss(payload)
      .then((res) => {
        this.setState({
          incomes: res?.incomes || [],
          expenses: res?.expenses || [],
          totalIncome: res?.totalIncome || 0,
          totalExpense: res?.totalExpense || 0,
          netIncome: res?.netIncome || 0,
          loading: false,
        });
        this.props.hideLoader();
      })
      .catch((error) => {
        errorHandler(error);
        this.setState({ loading: false });
        this.props.hideLoader();
      });
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => {
      this._agentGetProfitAndLoss();
      this._persistFilter();
    });
  };

  _downloadData = (action = "download") => {
    const {
      dateRangeValue,
      // totalIncome,
      // totalExpense, netIncome
    } = this.state;

    // if (!totalIncome || !totalExpense || !netIncome) {
    // if (!totalIncome) {
    //   showToast(`No data for ${action}`, "error");
    //   return;
    // }

    const payload = {
      userId: getLoggedInUserId(),
    };

    if (dateRangeValue) {
      // payload["startDate"] = dateRangeValue[0];
      // payload["endDate"] = dateRangeValue[1];
      payload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      payload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
    }

    const queryParams = structureQueryParams(payload);

    if (action === "download")
      openUrlOnNewTab(`${BASE_URL}/agent/download/profit-loss${queryParams}`);
  };

  _toggleUpgradeAccountModal = (isOpen = false, data = null) => {
    this.setState({
      upgradeAccountModal: {
        isOpen,
        data,
      },
    });
  };

  _persistFilter = () => {
    const { dateRangeValue } = this.state;
    if (dateRangeValue !== null && dateRangeValue) {
      localStorage.agentprofitloss = JSON.stringify(dateRangeValue);
    } else {
      delete localStorage.agentprofitloss;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    // const { filters, tableConfig } = deepClone(this.state);

    if (localStorage && localStorage.agentprofitloss) {
      try {
        const filters = JSON.parse(localStorage.agentprofitloss);
        let dateRange = [
          new Date(convertIsoToUtc(filters.dateRangeValue[0])),
          new Date(convertIsoToUtc(filters.dateRangeValue[1])),
        ];
        this.setState({ dateRangeValue: dateRange }, () => {
          this._agentGetProfitAndLoss();
        });
      } catch (e) {
        this._agentGetProfitAndLoss();
      }
    } else {
      this._agentGetProfitAndLoss();
    }
  };

  _toggleCollapse = (isOpen = false) => {
    if (isOpen === this.state.isOpenCollapse) {
      this.setState({ isOpenCollapse: false });
    } else {
      this.setState({ isOpenCollapse: isOpen });
    }
  };

  render() {
    const {
      incomes,
      expenses,
      totalIncome,
      totalExpense,
      netIncome,
      dateRangeValue,
      // loading,
      upgradeAccountModal,
      isOpenCollapse,
      isClassAdded,
    } = this.state;

    return (
      <div className="content">
        <div className="responsiveTitle">
          <h2>Profit & Loss</h2>

          <div className="rightSide">
            <Button
              className="filterBarIcon"
              color="link"
              onClick={this.filterShowMobile}
            >
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="Filter"
              />
            </Button>
          </div>
        </div>

        {/* filter for tablet and web */}
        <div
          onClick={this.handleButtonClick}
          className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
        ></div>
        <div
          className={`filterContainer responsiveFilter ${
            isClassAdded ? "show" : ""
          }`}
        >
          <div className="filterIcon">
            <img
              src={require("../../../assets/img/filter_icon.svg").default}
              alt="filter icon"
            />
            Filter by
          </div>
          <div className="mobileTitle">
            <h2>Filter by</h2>
            <Button
              color="link"
              className="closeButton"
              onClick={this.filterShowMobile}
            >
              <img
                src={require("../../../assets/img/close_grey.svg").default}
                alt="close"
                height={12}
              />
            </Button>
          </div>
          <div className="filterWrapper">
            <FormGroup>
              <Label>Date Range</Label>
              <DateRangePicker
                className="dateRange"
                format="MM-dd-y"
                dayPlaceholder="dd"
                monthPlaceholder="mm"
                yearPlaceholder="yyyy"
                // onChange={(dateRangeValue) =>
                //   this._onChangeDatePicker(dateRangeValue)
                // }
                onChange={(dateRangeValue) =>
                  isRegularUser()
                    ? this._onChangeDatePicker(dateRangeValue)
                    : this._toggleUpgradeAccountModal(true)
                }
                value={dateRangeValue}
              />
            </FormGroup>

            <Button
              color="link"
              outline
              className="downloadButton ml-auto"
              onClick={() =>
                isRegularUser()
                  ? this._downloadData()
                  : this._toggleUpgradeAccountModal(true)
              }
            >
              Download
              <img
                src={require(`../../../assets/img/download_blue.png`).default}
                alt="download"
              />
            </Button>
          </div>
          <div className="clearButton">
            <Button size="md" color="primary" onClick={this.filterShowMobile}>
              Close
            </Button>
          </div>
        </div>

        <div className="downloadButtonPos">
          <Button
            color="link"
            outline
            onClick={() =>
              isRegularUser()
                ? this._downloadData()
                : this._toggleUpgradeAccountModal(true)
            }
            // onClick={() => this._downloadData()}
          >
            Download
            <img
              src={require(`../../../assets/img/download_blue.png`).default}
              alt="download"
            />
          </Button>
        </div>
        {/* Net Income card */}
        <Card className="profitLossCard netIncome">
          <CardHeader>
            <p className="income">Net Income</p>
            <p className="income">
              {netIncome ? formatCurrencyValue(netIncome) : "$0"}
            </p>
          </CardHeader>
        </Card>

        {/* Income detail card */}
        <Card className="profitLossCard">
          <CardHeader>
            <CardTitle tag="h6">Paid Income</CardTitle>
            <div>
              {isOpenCollapse === "income" ? (
                ""
              ) : (
                <span>
                  {totalIncome ? formatCurrencyValue(totalIncome) : "$0"}
                </span>
              )}

              <Button
                color="link"
                className={`${isOpenCollapse === "income" ? "show" : null}`}
                onClick={() => this._toggleCollapse("income")}
              >
                <img
                  src={
                    require("../../../assets/img/arrow-dropdown.svg").default
                  }
                  alt="arrow"
                />
              </Button>
            </div>
          </CardHeader>
          <Collapse
            className="profitCollapse"
            isOpen={isOpenCollapse === "income"}
          >
            <CardBody className="p-0">
              <ul className="profitLossReport">
                {incomes?.length ? (
                  incomes.map((income, index) => (
                    <li key={index}>
                      {income._signingCompany?.companyName
                        ? capitalize(income._signingCompany.companyName)
                        : income._client?.name
                        ? capitalize(income._client.name.full)
                        : income.name
                        ? capitalize(income.name)
                        : "N/A"}
                      <span>
                        {income.amount
                          ? formatCurrencyValue(income.amount)
                          : "$0"}
                      </span>
                    </li>
                  ))
                ) : (
                  <li>There is no data to display</li>
                )}
              </ul>
            </CardBody>
            <CardFooter>
              <p className="income">Total Paid Income</p>
              <p className="income">
                {totalIncome ? formatCurrencyValue(totalIncome) : "$0"}
              </p>
            </CardFooter>
          </Collapse>
        </Card>

        {/* Expenses detail card */}
        <Card className="profitLossCard">
          <CardHeader>
            <CardTitle tag="h6">Expenses</CardTitle>
            <div>
              {isOpenCollapse === "expenses" ? (
                ""
              ) : (
                <span>
                  {totalExpense ? formatCurrencyValue(totalExpense) : "$0"}
                </span>
              )}

              <Button
                color="link"
                className={`${isOpenCollapse === "expenses" ? "show" : null}`}
                onClick={() => this._toggleCollapse("expenses")}
              >
                <img
                  src={
                    require("../../../assets/img/arrow-dropdown.svg").default
                  }
                  alt="arrow"
                />
              </Button>
            </div>
          </CardHeader>
          <Collapse isOpen={isOpenCollapse === "expenses"}>
            <CardBody className="p-0">
              <ul className="profitLossReport">
                {expenses?.length ? (
                  expenses.map((expense, index) => (
                    <li key={index}>
                      {expense.item ? capitalize(expense.item) : "N/A"}
                      <span>
                        {expense.amount
                          ? formatCurrencyValue(expense.amount)
                          : "$0"}
                      </span>
                    </li>
                  ))
                ) : (
                  <li>There is no data to display</li>
                )}
              </ul>
            </CardBody>
            <CardFooter>
              <p className="income">Total Expenses</p>
              <p className="income">
                {totalExpense ? formatCurrencyValue(totalExpense) : "$0"}
              </p>
            </CardFooter>
          </Collapse>
        </Card>

        <UpgradeAccountModal
          {...this.props}
          isOpen={upgradeAccountModal.isOpen}
          toggle={this._toggleUpgradeAccountModal}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(null, mapDispatchToProps)(ProfitAndLossReportingAgent);
