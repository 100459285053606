import React, { useEffect, useState } from "react";
import {
  FormGroup,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
} from "reactstrap";
import TextEditor from "../../TextEdittor";
import { errorHandler } from "../../../../../helper-methods";

const initialFormFields = {
  topic: "",
  description: "",
};
const initialIsDirty = {
  topic: false,
  description: false,
};

const PersonalAddDescriptionModal = ({
  isOpen,
  data,
  serviceDetails,
  setServiceDetails,
  toggle,
  setIsFormDirty,
}) => {
  const [formFields, setFormFields] = useState(initialFormFields);
  const [isDirty, setIsDirty] = useState(initialIsDirty);
  const [errors, setErrors] = useState({});

  const _resetForm = () => {
    setFormFields(initialFormFields);
    setIsDirty(initialIsDirty);
    setErrors({});
  };
  const _closeModal = () => {
    toggle();
    _resetForm();
  };

  const _setForm = () => {
    if (data) {
      const newFormFields = { ...formFields };

      newFormFields["topic"] = data?.topic;
      newFormFields["description"] = data?.description;

      setFormFields(newFormFields);
    }
  };

  const _onChangeFormfields = (key, value) => {
    const newFormFields = { ...formFields };
    newFormFields[key] = value;

    setFormFields(newFormFields);
  };

  const _onBlurFormFields = (key) => {
    const newIsDirty = { ...isDirty };
    const newFormFields = { ...formFields };
    newIsDirty[key] = true;

    setIsDirty(newIsDirty);
    _validateFormfields({ newFormFields, newIsDirty });
  };

  const _validateFormfields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      let isFormValid = true;
      const newErrors = {};

      Object.keys(newFormFields)?.forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "position":
            case "description": {
              if (newIsDirty[key]) {
                if (newFormFields[key]?.trim()?.length) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }

            default:
          }
        }
      });

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));
      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));
      resolve(isFormValid);
    });
  };

  const _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const newIsDirty = { ...isDirty };
      Object.keys(newIsDirty).forEach((e) => {
        newIsDirty[e] = true;
      });

      setIsDirty(newIsDirty);

      resolve(newIsDirty);
      //   this.setState({ formFields }, () => resolve(true));
    });
  };

  const _onSubmit = async () => {
    try {
      const newFormFields = { ...formFields };

      //   setLoading(true);
      const newIsDirty = await _markAllFieldDirty();
      const isFormValid = await _validateFormfields({
        newFormFields,
        newIsDirty,
      });

      console.log({ isFormValid });
      if (!isFormValid) {
        errorHandler({ reason: "Enter form details correctly" });
        return;
      }

      const newService = {
        id: data?.id ? data?.id : Date.now(),
        topic: formFields?.topic,
        description: formFields?.description,
      };

      if (data) {
        const clientIndex = serviceDetails?.findIndex(
          (each) => each?.id === data?.id
        );

        if (clientIndex > -1) {
          serviceDetails?.splice(clientIndex, 1, newService);
          setServiceDetails(serviceDetails);
        }
      } else {
        setServiceDetails((prev) => [...prev, newService]);
      }
      setIsFormDirty(true);
      _closeModal();
    } catch (err) {
      errorHandler(err);
      console.log(err);
    }
  };

  useEffect(() => {
    if (isOpen && data && Object.keys(data)?.length) {
      _setForm();
    }
  }, [isOpen]);

  return (
    <>
      <Modal isOpen={isOpen} toggle={() => _closeModal()} centered scrollable>
        <ModalHeader toggle={() => _closeModal()}>
          {data ? "Update" : "Add"} Description
        </ModalHeader>
        <ModalBody>
          <FormGroup className="floatingLabel">
            <Input
              placeholder=" "
              value={formFields?.topic}
              onChange={(e) => _onChangeFormfields("topic", e.target.value)}
              onBlur={() => _onBlurFormFields("topic")}
            />
            <Label>Topic</Label>
            {errors?.topic ? (
              <p className="validation-error">{errors?.topic}</p>
            ) : null}
          </FormGroup>
          <FormGroup className="mb-0 floatingLabel reactQuill">
            <Input
              type="textarea"
              value={formFields?.description}
              onChange={(e) =>
                _onChangeFormfields("description", e.target.value)
              }
              onBlur={() => _onBlurFormFields("description")}
            />
            <Label>Description</Label>
            {errors?.description ? (
              <p className="validation-error">{errors?.description}</p>
            ) : null}
          </FormGroup>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={_closeModal}>
            Cancel
          </Button>
          <Button color="primary" onClick={_onSubmit}>
            {data ? "Update" : "Save"}
          </Button>{" "}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PersonalAddDescriptionModal;
