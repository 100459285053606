import React from "react";
import {
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  FormGroup,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  Table,
  InputGroupText,
  CustomInput,
  Label,
} from "reactstrap";
import ClosingAssignment from "../components/closing-assignment-modal";
import SigningStatusSigningModal from "../components/signing-status-signing";
import SignerAvailabilityModal from "../components/signer-availability-modal";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { connect } from "react-redux";
import {
  getAllSigningCompanyClosing,
  updateClosingDetailsById,
  getAllFiltersData,
  getLoanType,
  getAllWorkingAgentsForSigningCompany,
  companyGetListTeamsOfClient,
  editDraft,
} from "../../../http/http-calls";
import {
  deepClone,
  showToast,
  formatAddressInSingleText,
  // isTodayOrfutureDate,
  formatDateAsPerTimeZOne,
  getTimeZoneAbbr,
  checkPermission,
  formatOnlyDateMoment,
  // formatDateOnlyAsPerTimeZone,
  errorHandler,
  getDropdownColor,
  openGoogleMapOnNewTab,
  openAppleMapOnNewTab,
  refreshFunc,
  formatDateOnlyAsPerTimeZone,
} from "../../../helper-methods";
import { capitalize } from "@material-ui/core";
import usaStates from "../../../config/usa_states_titlecase";
import { agentClosingStatus } from "../../../config";
import moment from "moment";
import { addListData } from "../../../redux/actions/list";
import { connectToSocket } from "../../../socket-io";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomTable from "../components/CustomTable";
import CustomCard from "../components/CustomCard";
import OrderDashboardExport from "../components/Modals/Company/OrderDashboardExport";
import SvgIcons from "../components/SvgIcons";
import { DateTimeShowWithCellRow } from "../components/Date-time-show";

class ClosingDashboard extends React.Component {
  state = {
    signingCompanyClosingList: [],
    companySetting: [],
    signingCompanyClosingTotalCount: 0,
    signingCompanyClosingHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      { id: "appointmentDate", label: "Signer", noSort: true },
      { id: "fileNumber", label: "Order Details", noSort: true },
      { id: "_agent", label: "Agent", noSort: true },
      { id: "_client", label: "Client", noSort: true },
      // { id: "_borrower", label: "Signer", noSort: true },
      // { id: "loanType", label: "Product Type", noSort: true },
      { id: "docs", label: "Docs", noSort: true },
      { id: "closingAddress", label: "Location", noSort: true },
      // { id: "action", label: "Action", noSort: true },
      { id: "status", label: "Status", noSort: true },
    ],
    signingCompanyClosingCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    tableColumn: [
      {
        dataField: "borrower",
        text: "BORROWER",
        formatter: (cell, row) => this._tableFormatter(cell, row, "borrower"),
        sort: true,
      },
      {
        dataField: "file",
        text: "FILE#",
        formatter: (cell, row) => this._tableFormatter(cell, row, "file"),
        sort: true,
      },
      {
        dataField: "agent",
        text: "AGENT",
        formatter: (cell, row) => this._tableFormatter(cell, row, "agent"),
        sort: true,
      },
      {
        dataField: "client",
        text: "CLIENT",
        formatter: (cell, row) => this._tableFormatter(cell, row, "client"),
        sort: true,
      },
      {
        dataField: "lender",
        text: "LENDER",
        formatter: (cell, row) => this._tableFormatter(cell, row, "lender"),
        sort: true,
      },
      {
        dataField: "loanType",
        text: "LOAN TYPE",
        formatter: (cell, row) => this._tableFormatter(cell, row, "loanType"),
        sort: true,
      },
      {
        dataField: "appointment",
        text: "APPOINTMENT",
        formatter: (cell, row) =>
          this._tableFormatter(cell, row, "appointment"),
        sort: true,
      },
      {
        dataField: "status",
        text: "STATUS",
        formatter: (cell, row) => this._tableFormatter(cell, row, "status"),
        sort: true,
      },
      {
        dataField: "loanOfficer",
        text: "LOAN OFFICER",
        formatter: (cell, row) =>
          this._tableFormatter(cell, row, "loanOfficer"),
        sort: true,
      },
      {
        dataField: "action",
        text: "ACTION",
        formatter: (cell, row) => this._tableFormatter(cell, row, "action"),
      },
    ],
    tableData: [],
    tableDataTotalCount: 0,
    assignJobModal: {
      isOpen: false,
      data: null,
    },
    editAgentModal: {
      isOpen: false,
      data: null,
    },
    quickbookModal: {
      isOpen: false,
      data: null,
    },
    signingStatusModal: {
      isOpen: false,
      data: null,
      status: null,
    },
    signerAvailabilityModal: {
      isOpen: false,
      data: null,
    },
    isFilterCollapseOpen: false,
    isEditingAgent: false,
    tablePayload: {
      page: 1,
      rowsPerPage: 10,
    },
    loading: {
      statusChangeLoading: false,
      showTableLoading: false,
    },
    filters: {
      status: "",
      loanType: "",
      search: "",
      client: "",
      clientTeam: "",
      agentAssigned: "",
      attorneyAssigned: "",
      state: "",
      startDate: "",
      endDate: "",
      unassigned: false,
      showDraft: false,
      showUpcomingAppointment: false,
      // isReverse: false,
    },
    tableConfig: {
      skip: 0,
      limit: 30,
      pageNumber: 1,
      sort: {
        sortBy: "createdAt",
        sortOrder: "desc",
      },
    },
    dateRangeValue: null,
    filterDropdownValues: {
      clients: [],
      agents: [],
      clientTeamsList: [],
    },
    agents: [],
    loanType: [],
    mapType: "google",
    isClassAdded: false,
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  componentDidMount = () => {
    if (
      checkPermission("dashboard", "canUpdateClosingStatusOrScheduling") ||
      checkPermission("dashboard", "canEditOrderEntry") ||
      checkPermission("dashboard", "canReviewDocumentsOrOrderDetail")
      // checkPermission("dashboard", "canCreateOrder") ||
    ) {
      this.searchTimer = null;

      // this._getAllSigningClosing();
      this._getAllFiltersData();
      this._getAllWorkingAgents();
      // this._getLoanType();
      this._setFilterDataFromLocalStorage();
      // this._connectToSocket();
      let path = this.props.location.search?.split("/")[1];

      if (path) {
        this._onToggleAssignJobModal({ _id: path });
      }
      HeaderEventEmitter.subscribe("reset-filter", () => {
        this._resetFilter();
        refreshFunc("company-closing-dashboard");
      });
      HeaderEventEmitter.subscribe("create-closing", () => {
        console.log("create closing");
        this._redirectToCreateClosing();
      });
      HeaderEventEmitter.subscribe("quickbooks-invoice", () => {
        // console.log("create closing");
        // this._redirectToCreateClosing();
        this._onToggleQuickbookModal({ _id: path, type: "invoice" });
      });
      HeaderEventEmitter.subscribe("quickbooks-bill", () => {
        // console.log("create closing");
        // this._redirectToCreateClosing();
        this._onToggleQuickbookModal({ _id: path, type: "bill" });
      });

      document.querySelector("#scroll").scrollTo(0, 0);
    } else {
      this.props.history.push("/signingcompany/profile");
    }
  };
  // addRonTypesData

  _connectToSocket = () => {
    connectToSocket()
      .then((socket) => {
        this.setState({ socket });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _setFilterDataFromLocalStorage = () => {
    // const { filters, tableConfig } = deepClone(this.state);

    this.setState((prev) => console.log("first", prev));

    if (localStorage && localStorage.companyClosingdashboard) {
      try {
        const filters = JSON.parse(localStorage.companyClosingdashboard);
        // console.log("filters", filters.dateRangeValue[0])
        // console.log("filters",moment(filters.dateRangeValue[0]).toDate().toString())
        if (filters.dateRangeValue !== null) {
          let dateRange = [
            moment(filters.dateRangeValue[0]).toDate().toString(),
            moment(filters.dateRangeValue[1]).toDate().toString(),
          ];
          this.setState({ dateRangeValue: dateRange }, () => {
            // let filterData = this._prepareFilterData();
            this._getAllSigningClosing();
          });
        }
        this.setState(
          { filters: filters.filters, tableConfig: filters.tableConfig },
          () => {
            // let filterData = this._prepareFilterData();
            this._getAllSigningClosing();

            if (this.state.filters?.client) {
              this._getCompanyListTeamsOfClient(this.state.filters?.client);
            }
          }
        );
        // this.setState((prev)=>console.log("second",prev))
      } catch (e) {
        console.log("error", e);
        // let filterData = this._prepareFilterData();
        this._getAllSigningClosing();
      }
    } else {
      // let filterData = this._prepareFilterData();
      this._getAllSigningClosing();
    }
  };

  _prepareFilterData = () => {
    const { filters } = deepClone(this.state);

    if (!filters.status) delete filters.status;
    if (!filters.loanType) delete filters.loanType;
    if (!filters.unassigned) delete filters.unassigned;
    if (!filters.client) delete filters.client;
    if (!filters.agentAssigned) delete filters.agentAssigned;
    if (!filters.attorneyAssigned) delete filters.attorneyAssigned;
    if (!filters.state) delete filters.state;
    if (!filters.startDate) delete filters.startDate;
    if (!filters.endDate) delete filters.endDate;
    if (!filters.showDraft) delete filters.showDraft;
    if (!filters.showUpcomingAppointment)
      delete filters.showUpcomingAppointment;
    if (!filters.search?.trim()?.length) delete filters.search;
    // if(dateRangeValue===null) delete dateRangeValue;
    return filters;
  };

  _getLoanType = () => {
    getLoanType()
      .then((res) => {
        // console.log(res);
        let options =
          res?.loanType?.map((item) => ({
            value: item,
            label: item.replace(/([A-Z])/g, " $1").trim(),
          })) || [];
        this.setState({ loanType: options });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getAllFiltersData = () => {
    getAllFiltersData()
      .then((res) => {
        const { filterDropdownValues } = deepClone(this.state);
        filterDropdownValues["clients"] = res?.clients || [];
        // filterDropdownValues["agents"] = res.agents;
        // let options = res.agents.map((item) => ({
        //   value: item._id,
        //   label: item.name.full,
        // }));
        // options.splice(0, 0, { value: "", label: "Select" });
        // this.setState({ filterDropdownValues, agents: options });
        this.setState({ filterDropdownValues });
        console.log("io", filterDropdownValues);
        this.props.addListData(filterDropdownValues);
      })
      .catch((error) => {
        console.log("Closing Dashboard Error", error);
        errorHandler(error);
      });
  };

  _getCompanyListTeamsOfClient = async (clientId) => {
    try {
      const res = await companyGetListTeamsOfClient(clientId);
      const { filterDropdownValues } = deepClone(this.state);

      filterDropdownValues["clientTeamsList"] = res?.teams;

      this.setState({ filterDropdownValues });
    } catch (err) {
      errorHandler(err);
    }
  };

  _getAllWorkingAgents = async () => {
    try {
      const res = await getAllWorkingAgentsForSigningCompany();
      console.log({ res });

      const { filterDropdownValues } = deepClone(this.state);
      filterDropdownValues["agents"] = res?.agents;
      let options =
        res?.agents?.map((item) => ({
          value: item._id,
          label: item.name.full,
        })) || [];
      options?.splice(0, 0, { value: "", label: "Select" });
      this.setState({ filterDropdownValues, agents: options });
      this.props.addListData(filterDropdownValues);
    } catch (err) {
      console.log("Closing Dashboard Error", err);
      errorHandler(err);
      console.log({ err });
    }
  };

  _getAllSigningClosing = () => {
    this._manageLoading("showTableLoading", true);

    const { filters, tableConfig, dateRangeValue } = deepClone(this.state);

    if (filters.status && filters.status.length)
      Object.assign(tableConfig, { status: filters.status });

    if (filters.client && filters.client.length)
      Object.assign(tableConfig, { clientId: filters.client });

    if (filters?.client && filters?.clientTeam && filters?.clientTeam?.length)
      Object.assign(tableConfig, { clientAssistantTeam: filters?.clientTeam });

    if (filters.state && filters.state.length)
      Object.assign(tableConfig, { state: filters.state });

    if (filters.agentAssigned && filters.agentAssigned.length)
      Object.assign(tableConfig, { agentId: filters.agentAssigned });

    if (filters.unassigned)
      Object.assign(tableConfig, { unassigned: filters.unassigned });

    if (filters.showDraft)
      Object.assign(tableConfig, { showDraft: filters.showDraft });

    if (filters?.showUpcomingAppointment)
      Object.assign(tableConfig, {
        showUpcomingAppointment: filters.showUpcomingAppointment,
        currentDateTime: moment(new Date()).format("DD/MM/YYYY hh:mm"),
      });

    if (filters.closingDeadline)
      Object.assign(tableConfig, { closingDeadline: filters.closingDeadline });

    if (filters.assignedDate)
      Object.assign(tableConfig, { assignedDate: filters.assignedDate });

    if (filters.loanType)
      Object.assign(tableConfig, { loanType: filters.loanType });

    if (filters.search && filters.search.trim().length)
      Object.assign(tableConfig, { search: filters.search.trim() });

    if (dateRangeValue && dateRangeValue[0])
      Object.assign(tableConfig, {
        startDate: formatOnlyDateMoment(dateRangeValue[0]),
      });

    if (dateRangeValue && dateRangeValue[1])
      Object.assign(tableConfig, {
        endDate: formatOnlyDateMoment(dateRangeValue[1]),
      });

    if (
      dateRangeValue !== null &&
      moment(dateRangeValue[0]).isSame(dateRangeValue[1], "day")
      // dateRangeValue[0].getDate() === dateRangeValue[1].getDate()
    )
      Object.assign(tableConfig, { isReverse: true });

    delete tableConfig.sort;
    delete tableConfig.pageNumber;

    // console.log(tableConfig)

    getAllSigningCompanyClosing(tableConfig)
      .then((res) => {
        // console.log(res);
        this.setState(
          {
            signingCompanyClosingList: res?.closings || [],
            signingCompanyClosingTotalCount: res?.totalCount || 0,
            companySetting: res?.companySetting || [],
          },
          () => {
            this._manageLoading("showTableLoading", false);
          }
        );
      })
      .catch((error) => {
        console.log("Closing Dashboard Error", error);
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _onToggleAssignJobModal = (data) => {
    let { assignJobModal } = JSON.parse(JSON.stringify(this.state));
    assignJobModal.isOpen = !assignJobModal.isOpen;
    assignJobModal.data = data;
    this.setState({
      assignJobModal,
    });
    if (!assignJobModal.isOpen) {
      this._getAllSigningClosing();
    }
  };

  _onToggleEditAgentModal = (data) => {
    let { editAgentModal } = deepClone(this.state);
    editAgentModal.isOpen = !editAgentModal.isOpen;
    editAgentModal.data = data;
    this.setState({
      editAgentModal,
    });
  };

  _onToggleQuickbookModal = (data) => {
    let { quickbookModal } = deepClone(this.state);

    quickbookModal.isOpen = !quickbookModal.isOpen;
    quickbookModal.data = data;
    this.setState({
      quickbookModal,
    });
  };

  _onToggleSigningStatusModal = (
    isOpen = false,
    data = null,
    status = null
  ) => {
    this.setState({
      signingStatusModal: {
        isOpen,
        data,
        status,
      },
    });
  };

  _onToggleSignerAvailabilityModal = (isOpen = false, data = null) => {
    this.setState({
      signerAvailabilityModal: {
        isOpen,
        data,
      },
    });
  };

  _toggleFilterCollapse = () =>
    this.setState({ isFilterCollapseOpen: !this.state.isFilterCollapseOpen });

  _paginate = (pageNumber = 1, pageSize = 30) => {
    console.log("pageNumber, pageSize :", pageNumber, pageSize);
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllSigningClosing();
    });
    // document.querySelector(".content").scrollIntoView();
  };

  _onSortChange = (sortName, sortOrder) => {
    // console.log("sortName, sortOrder :", sortName, sortOrder);
    this._paginate();
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => {
      this._paginate();
      this._persistFilter();
    });
  };

  _filterOnChange = (type, value, isDelay = false) => {
    if (isDelay) clearTimeout(this.searchTimer);

    const { filters, filterDropdownValues } = deepClone(this.state);
    filters[type] = value;

    console.log({ type }, { value });
    if (type === "client") {
      filters["clientTeam"] = "";
      if (value?.length) {
        this._getCompanyListTeamsOfClient(value);
      } else {
        filterDropdownValues["clientTeamsList"] = [];
      }
    }

    if (type === "showUpcomingAppointment") {
      this.setState({ dateRangeValue: null });
    }

    this.setState({ filters, filterDropdownValues }, () => {
      if (isDelay) {
        this.searchTimer = setTimeout(() => {
          this._paginate();
          this._persistFilter();
        }, 1000);
      } else {
        this._paginate();
        this._persistFilter();
      }
    });
  };

  _resetFilter = () => {
    this.setState({
      filters: {
        status: "",
        loanType: "",
        search: "",
        client: "",
        clientTeam: "",
        agentAssigned: "",
        attorneyAssigned: "",
        state: "",
        startDate: "",
        endDate: "",
        unassigned: false,
        showDraft: false,
        showUpcomingAppointment: false,
        // isReverse: false,
      },
      dateRangeValue: null,
    });
    this._paginate();
  };

  _redirectToCreateClosing = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/create-closing`);
  };

  _redirectToClosingDetails = (data) => {
    const { userData } = this.props;
    if (
      !(
        checkPermission("dashboard", "canReviewDocumentsOrOrderDetail") ||
        checkPermission("dashboard", "canEditOrderEntry")
      )
    ) {
      return;
    }

    this.props.history.push(`/${userData.type}/closing-details/${data.id}`);
  };

  _updateClosingDetailsById = (id, payload) => {
    return new Promise((resolve, reject) => {
      updateClosingDetailsById({ id, payload })
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _updateSigningCompanyDraftClosing = (id, payload) => {
    return new Promise((resolve, reject) => {
      editDraft(id, payload)
        .then((res) => {
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _updateClosingDetailsByIdStatus = async (data, status) => {
    if (status === "DNC" || status === "Closed") {
      if (!data?._agent) {
        errorHandler({ reason: "No Agent was assigned in this order" });
        return;
      }
    }
    if (status === "DNC" || status === "Closed" || status === "Cancelled") {
      this._onToggleSigningStatusModal(true, data, status);
    } else {
      try {
        this._manageLoading("statusChangeLoading", true);

        const updatedRes = data.isSavedAsDraft
          ? await this._updateSigningCompanyDraftClosing(data.id, { status })
          : await this._updateClosingDetailsById(data.id, { status });

        showToast("Status Updated Successfully", "success");
        const { signingCompanyClosingList } = deepClone(this.state);
        const closing = signingCompanyClosingList.find(
          (closing) => closing.id === data.id
        );
        closing["status"] = updatedRes?.closing?.status;
        this.setState({ signingCompanyClosingList });
        this._manageLoading("statusChangeLoading", false);
      } catch (error) {
        errorHandler(error);
        this._manageLoading("statusChangeLoading", false);
      }
    }
  };

  multiLineText = (str) => {
    return str.split(" ").join("\n");
    // .map((item, idx) => (idx % 2 === 0 ? item : item + "<br>"))
  };

  _findScanbackDocument = (rowData) => {
    const { companySetting } = deepClone(this.state);
    // console.log("companySetting", companySetting);

    if (rowData?._client?._id && rowData?.loanType) {
      let companySettingScanBackDocument =
        companySetting &&
        companySetting?.scanBackDocument?.scanBackDocumentDetail?.some(
          (item) =>
            (item?._client?._id === rowData?._client?._id ||
              item?._client === rowData?._client?._id) &&
            item?.productType === rowData.loanType
        );
      // console.log("mm", companySettingScanBackDocument);

      // formFields["isScanBackNeeded"].value = companySetting?.scanBackDocument
      //   ?.isAlwaysRequired
      //   ? companySetting?.scanBackDocument?.isAlwaysRequired
      //   : companySettingScanBackDocument;
      // console.log("1111", companySettingScanBackDocument);
      return (
        companySettingScanBackDocument ||
        companySetting?.scanBackDocument?.isAlwaysRequired
      );

      // this.setState({ formFields });
    }
  };

  _rowClassName = (row, rowIdx) => {
    const statusEnum = ["Closed", "DNC", "Cancelled", "CCA"];
    if (row?.status && statusEnum.includes(row.status)) {
      return "rowStatus";
    }
    return "";
  };

  _dataFormat = (cell, row, header) => {
    console.log("_dataFormat >>", cell, row, header);
    const { mapType } = deepClone(this.state);
    switch (header) {
      // case "_borrower": {
      //   // return cell && cell.name && cell.name.full
      //   //   ? this.multiLineText(cell.name.full)
      //   //   : "N/A";
      //   return (
      //     <div style={{ wordBreak: "break-all" }}>
      //       {/* show the below span only when there are more than 1 signers  */}
      //       {Array.isArray(cell) && cell.length > 0
      //         ? this.multiLineText(cell[0]?.name?.full)
      //         : "N/A"}{" "}
      //       {cell && Array.isArray(cell) && cell.length > 1 && (
      //         <>
      //           <span
      //             id={`PopoverLegacy${row._id}`}
      //             className="themeColor cursorPointer"
      //             style={{ fontWeight: "600" }}
      //           >
      //             +{cell.length - 1}
      //           </span>
      //           <UncontrolledPopover
      //             trigger="legacy"
      //             placement="bottom"
      //             target={`PopoverLegacy${row._id}`}
      //             className="signerPopover"
      //           >
      //             <PopoverHeader>Signers</PopoverHeader>
      //             <PopoverBody>
      //               {cell.map((item) => (
      //                 <p>{this.multiLineText(item.name.full)}</p>
      //               ))}
      //             </PopoverBody>
      //           </UncontrolledPopover>
      //         </>
      //       )}
      //       {/* {cell && !cell[0]?.name?.full?.trim()?.length && "N/A"} */}
      //     </div>
      //   );
      // }

      case "appointmentDate": {
        return row ? (
          <>
            <div className="tableUserInfo">
              <div className="userContent">
                <span
                  id={`borrower_table_agent_dashboard_${row._id}`}
                  className="signerName"
                >
                  {row?._borrower[0]?.name?.full
                    ? row?._borrower[0]?.name?.full
                    : "N/A"}
                  {row?._borrower?.length > 1
                    ? `+${row?._borrower?.length - 1}`
                    : null}
                </span>

                {row?._borrower?.length ? (
                  <>
                    <UncontrolledPopover
                      trigger="legacy"
                      placement="bottom"
                      target={`borrower_table_agent_dashboard_${row._id}`}
                      className="signerPopover"
                    >
                      <PopoverHeader>Signers</PopoverHeader>
                      <PopoverBody>
                        <Table>
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Email</th>
                              <th>Phone</th>
                              <th>Language</th>
                              <th>Time Zone</th>
                            </tr>
                          </thead>
                          <tbody>
                            {row?._borrower.map((each) => (
                              <tr key={each._id}>
                                <td>{each.name?.full || "N/A"}</td>
                                <td>
                                  {each?.email ? (
                                    <a href={`mailto:${each?.email}`}>
                                      {each?.email}
                                    </a>
                                  ) : (
                                    "N/A"
                                  )}
                                </td>
                                <td>{each.phone?.work || "N/A"}</td>
                                <td>{each.language || "N/A"}</td>
                                <td>{each.timeZone || "N/A"}</td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </PopoverBody>
                    </UncontrolledPopover>
                  </>
                ) : null}
                <DateTimeShowWithCellRow cell={cell} row={row} />

                <Button
                  color={`${
                    (row?.appointmentStatus === "Confirmed" &&
                      !row.leftMessage) ||
                    row.status === "Closed"
                      ? "success "
                      : row?.appointmentStatus === "Rescheduled" &&
                        !row.leftMessage
                      ? "danger "
                      : row.leftMessage
                      ? "warning"
                      : "danger "
                  }`}
                  className={`confirmButton `}
                  onClick={() =>
                    this._onToggleSignerAvailabilityModal(true, row)
                  }
                >
                  {row?.appointmentStatus === "Confirmed"
                    ? "Confirmed"
                    : "Confirm"}
                </Button>
              </div>
            </div>
          </>
        ) : (
          "N/A"
        );
      }

      case "docs": {
        return row ? (
          <div className="docStatus">
            {/* Last Uploaded by Company: $DateTime */}
            <Button
              color="link"
              className="actionBtn"
              data-toggle="tooltip"
              data-placement="top"
              title={
                row?.companyLastDocumentUploadedAt &&
                `Last Uploaded by Company: ${formatDateAsPerTimeZOne(
                  row?.companyLastDocumentUploadedAt,
                  row?.closingAddress?.timeZone
                )}`
              }
            >
              {/* {row.fileNumber || "N/A"} */}
              {row?.documents?.length && !row.documents[0].isPrivate ? (
                row.isDocumentCompleted ? (
                  <span
                    className="fa fa-circle text-success"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                ) : row?.isDocumentUploadedByCompanyOrClient ? (
                  <span
                    className="fa fa-circle text-warning"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                ) : (
                  <span
                    className="fa fa-circle text-primary"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )
              ) : (
                <span
                  className="fa fa-circle text-primary"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )}
              <span
                className="value"
                onClick={() =>
                  row.isDocumentCompleted && this._redirectToClosingDetails(row)
                }
              >
                {row?.isDocumentCompleted
                  ? "Docs Ready"
                  : row?.isDocumentUploadedByCompanyOrClient
                  ? "Pending"
                  : "Not Ready"}
              </span>
            </Button>
            {/* First downloaded by Agent: DateTime */}
            {/* turns green only when agent downloaded all documents  */}
            {/* isAgentDocumentDownloaded */}
            <Button
              color="link"
              className="actionBtn"
              data-toggle="tooltip"
              data-placement="top"
              title={
                row?.isAgentViewDocumentTime &&
                `First downloaded by Agent: ${formatDateAsPerTimeZOne(
                  row?.isAgentViewDocumentTime,
                  row?.closingAddress?.timeZone
                )}`
              }
            >
              {row.isAllDocumentsDownloaded ? (
                <span
                  className="fa fa-circle text-success"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              ) : (
                <span
                  className="fa fa-circle text-warning"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )}
              {/* <span className="fs-14 ml-1">Docs Downloaded</span> */}
              <span className="value">
                {row?.isAllDocumentsDownloaded ? "Docs Downloaded" : "Pending"}
              </span>
            </Button>
            {/* Last Uploaded by Agent: DateTime */}
            {/* once a company create a closing and Selects the "scan back is required" check box and assigns the closing, 
            1. The third button will turn red(the rest two will remain gray), 
            2. When the agent uploads any doc and selects "scan back upload completed" it will turn yellow then 
            3. When the company selects "verified agent doc" it will turn green. */}
            <Button
              color="link"
              className="actionBtn"
              data-toggle="tooltip"
              data-placement="top"
              title={
                row?.agentLastDocumentUploadedAt &&
                `Last Uploaded by Agent:  ${formatDateAsPerTimeZOne(
                  row?.agentLastDocumentUploadedAt,
                  row?.closingAddress?.timeZone
                )}`
              }
            >
              {/* {row.isScanBackNeeded ? (
                row.isDocumentVerified ? (
                  <span
                    className="fa fa-circle text-success"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                ) : row.isDocumentCompletedByAgent ? (
                  <span
                    className="fa fa-circle text-warning"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                ) : (
                  <span
                    className="fa fa-circle text-danger"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )
              ) : row?.documents?.length && !row.documents[0].isPrivate ? (
                row.isDocumentCompletedByAgent ? (
                  <span
                    className="fa fa-circle text-success"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                ) : (
                  <span
                    className="fa fa-circle"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )
              ) : (
                <span
                  className="fa fa-circle"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )} */}
              {/* {console.log("ggggg",this._findScanbackDocument(row))} */}
              {/* {this._findScanbackDocument(row) && !row.isScanBackNeeded && (
                <>
                  <span
                    className="fa fa-circle text-danger"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                  <span className="fs-14 ml-1">Scan Needed</span>
                </>
              )} */}
              {/* {row.isScanBackNeeded ? (
                row.isDocumentVerified ? (
                  <>
                    <span
                      className="fa fa-circle text-success"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span className="fs-14 ml-1">Scans Approved</span>
                  </>
                ) : row.isDocumentCompletedByAgent ? (
                  <>
                    <span
                      className="fa fa-circle text-warning"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span
                      className="fs-14 ml-1"
                      onClick={() => this._redirectToClosingDetails(row)}
                    >
                      Scan Upload
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className="fa fa-circle text-danger"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span className="fs-14 ml-1">Scan Needed</span>
                  </>
                )
              ) : row?.documents?.length && !row.documents[0].isPrivate ? (
                row.isDocumentCompletedByAgent ? (
                  <>
                    <span
                      className="fa fa-circle text-success"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span className="fs-14 ml-1">Scan Upload</span>
                  </>
                ) : (
                  <span
                    className="fa fa-circle"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )
              ) :              
                <span
                  className="fa fa-circle"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />             
              } */}

              {row.isScanBackNeeded ? (
                row.isDocumentVerified ? (
                  <>
                    <span
                      className="fa fa-circle text-success"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span className="value">Shipping Approved</span>
                  </>
                ) : row.isDocumentCompletedByAgent ? (
                  <>
                    <span
                      className="fa fa-circle text-warning"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span
                      className="value"
                      onClick={() => this._redirectToClosingDetails(row)}
                    >
                      Scan Upload
                    </span>
                  </>
                ) : (
                  <>
                    <span
                      className="fa fa-circle text-danger"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                    <span className="value">Scan Needed</span>
                  </>
                )
              ) : row?.documents?.length && !row.documents[0].isPrivate ? (
                row.isDocumentCompletedByAgent ? (
                  row.isDocumentVerified ? (
                    <>
                      <span
                        className="fa fa-circle text-success"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                      <span className="value">Shipping Approved</span>
                    </>
                  ) : (
                    <>
                      <span
                        className="fa fa-circle text-warning"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                      <span className="value">Scan Upload</span>
                    </>
                  )
                ) : (
                  <span
                    className="fa fa-circle"
                    style={{ fontSize: 10, verticalAlign: 1 }}
                  />
                )
              ) : (
                <span
                  className="fa fa-circle"
                  style={{ fontSize: 10, verticalAlign: 1 }}
                />
              )}
            </Button>
          </div>
        ) : (
          "N/A"
        );
      }
      case "fileNumber": {
        return row ? (
          <>
            <div
              className="tableLink"
              style={{
                width: 100,
                whiteSpace: "normal",
                fontWeight: 600,
              }}
              // onClick={() => this._redirectToClosingDetails(row)}
              onClick={() => this._redirectToClosingDetails(row)}
              disabled={
                !(
                  checkPermission(
                    "dashboard",
                    "canReviewDocumentsOrOrderDetail"
                  ) || checkPermission("dashboard", "canEditOrderEntry")
                )
              }
            >
              {row.fileNumber || "N/A"}
            </div>
            <div style={{ width: 100 }}>
              <span
                style={{
                  marginRight: 6,
                  display: "inline-block",
                  fontWeight: 600,
                }}
              >
                {" "}
                {row.signingType} {","}
              </span>
              {row.loanType && row.loanType.length > 0 ? (
                row.loanType.indexOf("Other") > -1 ? (
                  <span
                    style={{
                      marginRight: 6,
                      display: "inline-block",
                      fontWeight: 600,
                    }}
                  >
                    {row?.loanTypeOther} {","}
                  </span>
                ) : null
              ) : null}
              {row.loanType
                .filter((each) => each !== "Other")
                .map((item) => (
                  <>
                    <span
                      style={{
                        marginRight: 6,
                        display: "inline-block",
                        fontWeight: 600,
                      }}
                    >
                      {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                    </span>
                  </>
                ))}
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "_agent": {
        return (
          <>
            <div style={{ width: 80, fontWeight: 600 }}>
              {(cell?.name?.full && capitalize(cell?.name?.full)) || (
                <>
                  N/A
                  <span className="text-primary ml-1">
                    {row?._noOfBidding}
                  </span>{" "}
                </>
              )}
              <Button
                className="pl-0"
                color="link"
                onClick={() => this._onToggleAssignJobModal(row)}
                disabled={!checkPermission("dashboard", "canEditOrderEntry")}
              >
                <div className="pencil">
                  <SvgIcons type="editIcon" />
                </div>
                {/* <img
                  height={13}
                  src={require("../../../assets/img/pencil.svg").default}
                  alt="edit"
                /> */}
              </Button>
            </div>
          </>
        );
      }
      case "_client": {
        return (
          <>
            {cell && cell.companyName ? (
              <>
                <div style={{ width: 90, fontWeight: 600 }}>
                  {capitalize(cell.companyName)}
                </div>
                <div style={{ width: 90, fontWeight: 600 }}>
                  {row?._clientAssistantTeam?.teamName
                    ? capitalize(row?._clientAssistantTeam?.teamName)
                    : "N/A"}
                </div>
              </>
            ) : (
              "N/A"
            )}
          </>
        );
      }
      // case "loanType": {
      //   return cell && cell.length > 0
      //     ? cell[0] === "Other"
      //       ? row?.loanTypeOther
      //       : cell.map((item) => item.replace(/([a-z])([A-Z])/g, "$1 $2"))
      //     : //  cell.replace(/([a-z])([A-Z])/g, "$1 $2") || "N/A"
      //       "N/A";
      //   // : this.state.loanType.find((obj) => obj.value === cell)?.label ||
      // }
      // case "loanType": {
      //   return (
      //     <>
      //       <div style={{ width: 100 }}>
      //         <span
      //           style={{
      //             marginRight: 6,
      //             display: "inline-block",
      //             fontWeight: 600,
      //           }}
      //         >
      //           {" "}
      //           {row.signingType} {","}
      //         </span>
      //         {cell && cell.length > 0 ? (
      //           cell.indexOf("Other") > -1 ? (
      //             <span
      //               style={{
      //                 marginRight: 6,
      //                 display: "inline-block",
      //                 fontWeight: 600,
      //               }}
      //             >
      //               {row?.loanTypeOther} {","}
      //             </span>
      //           ) : null
      //         ) : null}
      //         {cell
      //           .filter((each) => each !== "Other")
      //           .map((item) => (
      //             <>
      //               <span
      //                 style={{
      //                   marginRight: 6,
      //                   display: "inline-block",
      //                   fontWeight: 600,
      //                 }}
      //               >
      //                 {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
      //               </span>
      //             </>
      //           ))}
      //       </div>
      //     </>
      //   );
      // }
      // case "lender": {
      //   return cell ? capitalize(cell) : "N/A";
      // }
      // case "agentFee": {
      //   return cell ? formatCurrencyValue(cell) : "N/A";
      // }
      // case "appointmentDate": {
      //   return row ? (
      //     <>
      //       {cell ? (
      //         <>
      //           <div
      //             className="d-flex align-items-center"
      //             style={{ whiteSpace: "nowrap", fontSize: 13 }}
      //           >
      //             {isTodayOrfutureDate(row) ? (
      //               <i
      //                 style={{ fontSize: 10, marginTop: -2 }}
      //                 className={`${
      //                   row?.appointmentStatus === "Confirmed" &&
      //                   // !row.leftMessage &&
      //                   row?._agent !== undefined
      //                     ? "text-success fa fa-circle mr-1"
      //                     : row?.appointmentStatus === "Rescheduled" &&
      //                       // !row.leftMessage &&
      //                       row?._agent === undefined
      //                     ? "text-danger fa fa-circle mr-1"
      //                     : row.leftMessage
      //                     ? "text-warning fa fa-circle mr-1"
      //                     : "text-danger fa fa-circle mr-1"
      //                 }`}
      //               />
      //             ) : null}
      //             {!row.tBD
      //               ? formatDateAsPerTimeZOne(cell, row.closingAddress.timeZone)
      //               : formatDateOnlyAsPerTimeZone(
      //                   cell,
      //                   row.closingAddress.timeZone
      //                 )}
      //             , {""}
      //           </div>{" "}
      //         </>
      //       ) : (
      //         ""
      //       )}
      //       <Badge className="mr-1">
      //         {getTimeZoneAbbr(cell, row.closingAddress.timeZone)}
      //       </Badge>
      //       <Button
      //         color="primary"
      //         // className="confirmBtn"
      //         className={`confirmBtn ${
      //           row?.appointmentStatus === "Confirmed" &&
      //           // !row.leftMessage &&
      //           row?._agent !== undefined
      //             ? "bg-success "
      //             : row?.appointmentStatus === "Rescheduled" &&
      //               // !row.leftMessage &&
      //               row?._agent === undefined
      //             ? "bg-danger  "
      //             : row.leftMessage
      //             ? "bg-warning "
      //             : "bg-danger  "
      //         }`}
      //         onClick={() => this._onToggleSignerAvailabilityModal(true, row)}
      //         disabled={
      //           !(
      //             checkPermission(
      //               "dashboard",
      //               "canUpdateClosingStatusOrScheduling"
      //             ) || checkPermission("dashboard", "canEditOrderEntry")
      //           )
      //         }
      //       >
      //         Confirm
      //       </Button>
      //       <br />
      //       <span>
      //         {row.closingAddress.city} {row.closingAddress.state}
      //       </span>
      //     </>
      //   ) : (
      //     "N/A"
      //   );
      // }
      case "status": {
        return cell ? (
          <div className="customSelectWrap" style={{ width: 110 }}>
            <Input
              type="select"
              name="status"
              className={`status ${getDropdownColor(cell)}`}
              disabled={
                this.state.loading.statusChangeLoading ||
                !(
                  checkPermission(
                    "dashboard",
                    "canUpdateClosingStatusOrScheduling"
                  ) || checkPermission("dashboard", "canEditOrderEntry")
                )
              }
              value={cell}
              onChange={
                (event) =>
                  this._updateClosingDetailsByIdStatus(row, event.target.value)
                // !row?.tBD
                //   ? this._updateClosingDetailsByIdStatus(
                //       row,
                //       event.target.value
                //     )
                //   : errorHandler({
                //       reason:
                //         "TBD Order! Please update order with a confirmed closing date from Client Side.",
                //     })
              }
            >
              <option key="CCA" value="CCA" disabled={true}>
                Can't Complete
              </option>
              <option key="Pending" value="Pending">
                Pending
              </option>
              <option
                key="Closed"
                value="Closed"
                disabled={row?.isSavedAsDraft}
              >
                Closed
              </option>
              <option key="Cancelled" value="Cancelled">
                Cancelled
              </option>
              <option key="DNC" value="DNC" disabled={row?.isSavedAsDraft}>
                Did not close
              </option>
              <option key="Arrived" value="Arrived" disabled={true}>
                Arrived
              </option>
            </Input>
            <div className="downArrow">
              <i className="fa fa-chevron-down"></i>
            </div>
          </div>
        ) : (
          "N/A"
        );
      }
      case "loanOfficer": {
        return cell ? capitalize(cell) : "N/A";
      }
      case "closingAddress": {
        return (
          <>
            <span style={{ fontWeight: 600, width: 110, display: "block" }}>
              {formatAddressInSingleText(cell) || "N/A"}
            </span>
            <Button
              color="link"
              className="p-0"
              title={formatAddressInSingleText(cell)}
              onClick={() => openGoogleMapOnNewTab(row.closingAddress)}
            >
              <img
                src={
                  require("../../../assets/img/Google_Maps_Logo_2020.svg")
                    .default
                }
                alt="map icon"
                className="mr-1"
              />
              Google Map
            </Button>
            <Button
              color="link"
              className="p-0"
              title={formatAddressInSingleText(cell)}
              onClick={() => openAppleMapOnNewTab(row.closingAddress)}
            >
              <img
                src={
                  require("../../../assets/img/Apple_Maps_(WatchOS).svg")
                    .default
                }
                alt="map icon"
                className="mr-1"
              />
              Apple Map
            </Button>
          </>
        );
      }
      // case "action": {
      //   return row ? (
      //     <>
      //       <Button
      //         color="link"
      //         className="actionBtn"
      //         onClick={() => this._redirectToClosingDetails(row)}
      //         disabled={
      //           !(
      //             checkPermission(
      //               "dashboard",
      //               "canReviewDocumentsOrOrderDetail"
      //             ) || checkPermission("dashboard", "canEditOrderEntry")
      //           )
      //         }
      //       >
      //         <i className="fa fa-eye" />
      //       </Button>
      //     </>
      //   ) : (
      //     "N/A"
      //   );
      // }
      default: {
        return cell;
      }
    }
  };

  _rowStyleFormat = (row, rowIdx) => {
    const statusEnum = ["Closed", "DNC", "Cancelled"];
    if (row?.status && statusEnum.includes(row.status)) {
      return { backgroundColor: "#eee" };
    }
    if (row?.isSavedAsDraft) {
      return { backgroundColor: "#edf0ff" };
    }

    return {};
  };

  _persistFilter = () => {
    const { filters, dateRangeValue, tableConfig } = this.state;

    if (
      (filters &&
        (filters.status ||
          filters.loanType ||
          filters.unassigned ||
          filters.client ||
          filters.agentAssigned ||
          filters.attorneyAssigned ||
          filters.state ||
          // filters.startDate ||
          // filters.endDate ||
          filters.showDraft ||
          filters.showUpcomingAppointment ||
          (filters.search && filters.search.length))) ||
      dateRangeValue !== null ||
      tableConfig.pageNumber !== 1
    ) {
      let data = { filters, dateRangeValue, tableConfig };
      // console.log(data);
      localStorage.companyClosingdashboard = JSON.stringify(data);
    } else {
      delete localStorage.companyClosingdashboard;
    }
  };

  _cardHeaderFormat = (cell, row, header) => {
    console.log("weerer", cell, header, row);
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="tableUserInfo">
              <div className="userContent">
                <span
                  id={`borrower_table_agent_dashboard_company${row._id}`}
                  className="signerName"
                >
                  {row?._borrower[0]?.name?.full
                    ? row?._borrower[0]?.name?.full
                    : "N/A"}
                  {row?._borrower?.length > 1
                    ? `+${row?._borrower?.length - 1}`
                    : null}
                </span>

                {row?._borrower?.length ? (
                  <>
                    <UncontrolledPopover
                      trigger="legacy"
                      placement="bottom"
                      target={`borrower_table_agent_dashboard_company${row._id}`}
                      className="signerPopover"
                    >
                      <PopoverHeader>Signers</PopoverHeader>
                      <PopoverBody>
                        {row?._borrower.map((each) => (
                          <ul key={each._id} className="dataList">
                            <li>
                              <Label>Name</Label>
                              <div>{each.name?.full || "N/A"}</div>
                            </li>
                            <li>
                              <Label>Email</Label>
                              <div>
                                {each?.email ? (
                                  <a href={`mailto:${each?.email}`}>
                                    {each?.email}
                                  </a>
                                ) : (
                                  "N/A"
                                )}
                              </div>
                            </li>
                            <li>
                              <Label>Phone</Label>
                              <div>{each.phone?.work || "N/A"}</div>
                            </li>
                            <li>
                              <Label>Language</Label>
                              <div>{each.language || "N/A"}</div>
                            </li>
                            <li>
                              <Label>Time Zone</Label>
                              {each.timeZone || "N/A"}
                            </li>
                          </ul>
                        ))}
                      </PopoverBody>
                    </UncontrolledPopover>
                  </>
                ) : null}
                {/* <span className="dateTimeFormat">
                  {formatDateAsPerTimeZOne(
                    row?.appointmentDate,
                    row?.closingAddress?.timeZone
                  )}
                  ,{" "}
                  <span>
                    {getTimeZoneAbbr(
                      row?.appointmentDate,
                      row.closingAddress?.timeZone
                    )}
                  </span>
                </span> */}
                <DateTimeShowWithCellRow
                  cell={row?.appointmentDate}
                  row={row}
                />
              </div>

              <Button
                color={`${
                  row?.appointmentStatus === "Confirmed" && !row.leftMessage
                    ? "success "
                    : row?.appointmentStatus === "Rescheduled" &&
                      !row.leftMessage
                    ? "danger "
                    : row.leftMessage
                    ? "warning"
                    : "danger "
                }`}
                className={`confirmButton btnConfirm`}
                onClick={() => this._onToggleSignerAvailabilityModal(true, row)}
              >
                {row?.appointmentStatus === "Confirmed"
                  ? "Confirmed"
                  : "Confirm"}
              </Button>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Order Details </Label>

            {
              <>
                <div
                  className="text-primary"
                  style={{ fontWeight: 600 }}
                  onClick={() => this._redirectToClosingDetails(row)}
                  disabled={
                    !(
                      checkPermission(
                        "dashboard",
                        "canReviewDocumentsOrOrderDetail"
                      ) || checkPermission("dashboard", "canEditOrderEntry")
                    )
                  }
                >
                  {row?.fileNumber || "N/A"}
                </div>

                <div>
                  <span
                    style={{
                      marginRight: 6,
                      display: "inline-block",
                      fontWeight: 600,
                    }}
                  >
                    {" "}
                    {row.signingType} {","}
                  </span>
                  {row.loanType && row.loanType.length > 0 ? (
                    row.loanType.indexOf("Other") > -1 ? (
                      <span
                        style={{
                          marginRight: 6,
                          display: "inline-block",
                          fontWeight: 600,
                        }}
                      >
                        {row?.loanTypeOther} {","}
                      </span>
                    ) : null
                  ) : null}
                  {row.loanType
                    .filter((each) => each !== "Other")
                    .map((item) => (
                      <>
                        <span
                          style={{
                            marginRight: 6,
                            display: "inline-block",
                            fontWeight: 600,
                          }}
                        >
                          {item.replace(/([a-z])([A-Z])/g, "$1 $2")}
                        </span>
                      </>
                    ))}
                </div>
              </>
            }
          </div>

          <div className="dataWrap">
            <Label>Agent</Label>
            <div style={{ fontWeight: 600 }}>
              {(row?._agent?.name?.full &&
                capitalize(row?._agent?.name?.full)) || (
                <>
                  N/A
                  <span className="text-primary ml-1">
                    {row?._noOfBidding}
                  </span>{" "}
                </>
              )}
              <Button
                color="link"
                onClick={() => {
                  this._onToggleAssignJobModal(row);
                  // !row?.tBD
                  //   ? this._onToggleAssignJobModal(row)
                  //   : errorHandler({
                  //       reason:
                  //         "TBD Order! Please update order with a confirmed closing date from Client Side.",
                  //     });
                }}
                disabled={!checkPermission("dashboard", "canEditOrderEntry")}
              >
                <img
                  src={require("../../../assets/img/pencil.svg").default}
                  alt="edit"
                />
              </Button>
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Client </Label>
            <span
              style={{
                marginLeft: 5,
                fontWeight: 600,
              }}
            >
              {row?._client?.companyName ? (
                <>
                  <div style={{ width: 90, fontWeight: 600 }}>
                    {capitalize(row?._client?.companyName)}
                  </div>
                  <div style={{ width: 90, fontWeight: 600 }}>
                    {row?._clientAssistantTeam?.teamName
                      ? capitalize(row?._clientAssistantTeam?.teamName)
                      : "N/A"}
                  </div>
                </>
              ) : (
                "N/A"
              )}
            </span>
          </div>
        </li>
        <li>
          <div className="dataWrap">
            <Label>Docs Status </Label>

            {row ? (
              <div className="docStatus">
                {/* Last Uploaded by Company: $DateTime */}
                <Button
                  color="link"
                  className="actionBtn"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={
                    row?.companyLastDocumentUploadedAt &&
                    `Last Uploaded by Company: ${formatDateAsPerTimeZOne(
                      row?.companyLastDocumentUploadedAt,
                      row?.closingAddress?.timeZone
                    )}`
                  }
                >
                  {/* {row.fileNumber || "N/A"} */}
                  {row?.documents?.length && !row.documents[0].isPrivate ? (
                    row.isDocumentCompleted ? (
                      <span
                        className="fa fa-circle text-success"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                    ) : row?.isDocumentUploadedByCompanyOrClient ? (
                      <span
                        className="fa fa-circle text-warning"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                    ) : (
                      <span
                        className="fa fa-circle text-primary"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                    )
                  ) : (
                    <span
                      className="fa fa-circle text-primary"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  )}
                  <span
                    className="value"
                    onClick={() =>
                      row.isDocumentCompleted &&
                      this._redirectToClosingDetails(row)
                    }
                  >
                    {row.isDocumentCompleted
                      ? "Docs Ready"
                      : row?.isDocumentUploadedByCompanyOrClient
                      ? "Pending"
                      : "Not Ready"}
                  </span>
                </Button>

                {/* First downloaded by Agent: DateTime */}
                {/* turns green only when agent downloaded all documents  */}
                {/* isAgentDocumentDownloaded */}
                <Button
                  color="link"
                  className="actionBtn"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={
                    row?.isAgentViewDocumentTime &&
                    `First downloaded by Agent: ${formatDateAsPerTimeZOne(
                      row?.isAgentViewDocumentTime,
                      row?.closingAddress?.timeZone
                    )}`
                  }
                >
                  {row.isAllDocumentsDownloaded ? (
                    <span
                      className="fa fa-circle text-success"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  ) : (
                    <span
                      className="fa fa-circle text-warning"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  )}
                  {/* <span className="fs-14 ml-1">Docs Downloaded</span> */}
                  <span className="value">
                    {row?.isAllDocumentsDownloaded
                      ? "Docs Downloaded"
                      : "Pending"}
                  </span>
                </Button>

                {/* Last Uploaded by Agent: DateTime */}

                {/* once a company create a closing and Selects the "scan back is required" check box and assigns the closing, 
            1. The third button will turn red(the rest two will remain gray), 
            2. When the agent uploads any doc and selects "scan back upload completed" it will turn yellow then 
            3. When the company selects "verified agent doc" it will turn green. */}

                <Button
                  color="link"
                  className="actionBtn"
                  data-toggle="tooltip"
                  data-placement="top"
                  title={
                    row?.agentLastDocumentUploadedAt &&
                    `Last Uploaded by Agent:  ${formatDateAsPerTimeZOne(
                      row?.agentLastDocumentUploadedAt,
                      row?.closingAddress?.timeZone
                    )}`
                  }
                >
                  {row.isScanBackNeeded ? (
                    row.isDocumentVerified ? (
                      <>
                        <span
                          className="fa fa-circle text-success"
                          style={{ fontSize: 10, verticalAlign: 1 }}
                        />
                        <span className="value">Shipping Approved</span>
                      </>
                    ) : row.isDocumentCompletedByAgent ? (
                      <>
                        <span
                          className="fa fa-circle text-primary"
                          style={{ fontSize: 10, verticalAlign: 1 }}
                        />
                        <span
                          className="value"
                          onClick={() => this._redirectToClosingDetails(row)}
                        >
                          Scan Upload
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          className="fa fa-circle text-danger"
                          style={{ fontSize: 10, verticalAlign: 1 }}
                        />
                        <span className="value">Scan Needed</span>
                      </>
                    )
                  ) : row?.documents?.length && !row.documents[0].isPrivate ? (
                    row.isDocumentCompletedByAgent ? (
                      row.isDocumentVerified ? (
                        <>
                          <span
                            className="fa fa-circle text-success"
                            style={{ fontSize: 10, verticalAlign: 1 }}
                          />
                          <span className="value">Shipping Approved</span>
                        </>
                      ) : (
                        <>
                          <span
                            className="fa fa-circle text-primary"
                            style={{ fontSize: 10, verticalAlign: 1 }}
                          />
                          <span className="value">Scan Upload</span>
                        </>
                      )
                    ) : (
                      <span
                        className="fa fa-circle"
                        style={{ fontSize: 10, verticalAlign: 1 }}
                      />
                    )
                  ) : (
                    <span
                      className="fa fa-circle"
                      style={{ fontSize: 10, verticalAlign: 1 }}
                    />
                  )}
                </Button>
              </div>
            ) : (
              "N/A"
            )}
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <div className="d-flex justify-content-between">
              <Label>Location </Label>
              <div className="">
                <Button
                  color="link"
                  className="p-0 mr-2"
                  title={formatAddressInSingleText(row?.closingAddress)}
                  onClick={() => openGoogleMapOnNewTab(row?.closingAddress)}
                >
                  <img
                    src={
                      require("../../../assets/img/Google_Maps_Logo_2020.svg")
                        .default
                    }
                    alt="map icon"
                    className="mr-1"
                  />
                  {/* Google Map */}
                </Button>
                <Button
                  color="link"
                  className="p-0"
                  title={formatAddressInSingleText(row?.closingAddress)}
                  onClick={() => openAppleMapOnNewTab(row?.closingAddress)}
                >
                  <img
                    src={
                      require("../../../assets/img/Apple_Maps_(WatchOS).svg")
                        .default
                    }
                    alt="map icon"
                    className="mr-1"
                  />
                  {/* Apple Map */}
                </Button>
              </div>
            </div>

            <>
              <div style={{ fontWeight: 600 }}>
                {formatAddressInSingleText(row?.closingAddress) || "N/A"}
              </div>
            </>
          </div>
        </li>
        <li>
          <div className="dataWrap align-items-center d-flex justify-content-between">
            <Label className="mb-0">Status </Label>

            {row?.status ? (
              <div className="customSelectWrap">
                <Input
                  type="select"
                  name="status"
                  className={`status ${getDropdownColor(row?.status)}`}
                  disabled={
                    this.state.loading.statusChangeLoading ||
                    !(
                      checkPermission(
                        "dashboard",
                        "canUpdateClosingStatusOrScheduling"
                      ) || checkPermission("dashboard", "canEditOrderEntry")
                    )
                  }
                  value={row?.status}
                  onChange={
                    (event) =>
                      this._updateClosingDetailsByIdStatus(
                        row,
                        event.target.value
                      )
                    // !row?.tBD
                    //   ? this._updateClosingDetailsByIdStatus(
                    //       row,
                    //       event.target.value
                    //     )
                    //   : errorHandler({
                    //       reason:
                    //         "TBD Order! Please update order with a confirmed closing date from Client Side.",
                    //     })
                  }
                >
                  <option key="CCA" value="CCA" disabled={true}>
                    Can't Complete
                  </option>
                  <option key="Pending" value="Pending">
                    Pending
                  </option>
                  <option
                    key="Closed"
                    value="Closed"
                    disabled={(row?.tBD ? true : false) || row?.isSavedAsDraft}
                  >
                    Closed
                  </option>
                  <option key="Cancelled" value="Cancelled">
                    Cancelled
                  </option>
                  <option
                    key="DNC"
                    value="DNC"
                    disabled={(row?.tBD ? true : false) || row?.isSavedAsDraft}
                  >
                    Did not close
                  </option>
                  <option key="Arrived" value="Arrived" disabled={true}>
                    Arrived
                  </option>
                </Input>
                <div className="downArrow">
                  <i className="fa fa-chevron-down"></i>
                </div>
              </div>
            ) : (
              "N/A"
            )}
          </div>
        </li>
      </ul>
    );
  };
  render() {
    const {
      signingCompanyClosingTotalCount,
      isFilterCollapseOpen,
      dateRangeValue,
      filters,
      signingCompanyClosingHeaderKeys,
      signingCompanyClosingList,
      loading,
      signingStatusModal,
      assignJobModal,
      signerAvailabilityModal,
      tableConfig,
      filterDropdownValues,
      agents,
      isClassAdded,
      signingCompanyClosingCardHeaderKeys,
    } = this.state;

    const { userData } = this.props;

    return (
      <div id="scroll">
        <div className="content">
          <div className="responsiveTitle">
            <h2>Order Dashboard</h2>

            <div className="rightSide">
              <Button
                color="link"
                onClick={() => {
                  refreshFunc("company-closing-dashboard-responsive");
                  this._resetFilter();
                }}
              >
                {/* <img
                  id="company-closing-dashboard-responsive"
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                /> */}
                <SvgIcons
                  type="refresh"
                  id="company-closing-dashboard-responsive"
                />
              </Button>

              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <SvgIcons type={"filterIcon"} />
              </Button>

              <Button
                className="floatingButton"
                color="primary"
                onClick={this._redirectToCreateClosing}
              >
                + Create
              </Button>
            </div>
          </div>

          {/* search filed for responsive will show below 767px */}
          <div className="mobileSearchFiled">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <img
                    src={require("../../../assets/img/searchIcon.svg").default}
                    alt="searchIcon"
                  />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder="Ex.Lender or Product Officer or Order No."
                value={filters.search}
                onChange={(e) =>
                  this._filterOnChange("search", e.target.value, true)
                }
              />
            </InputGroup>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.filterShowMobile}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer  responsiveFilter ${
              isClassAdded ? "show" : ""
            }`}
          >
            <div className="filterIcon">
              {/* <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
              /> */}
              <SvgIcons type={"filterIcon"} />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>
            <div className="filterWrapper">
              <FormGroup className="searchTable">
                <Label>Search</Label>
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText>
                      <img
                        src={
                          require("../../../assets/img/searchIcon.svg").default
                        }
                        alt="searchIcon"
                      />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input
                    type="text"
                    placeholder="Ex.Lender or Product Officer or Order No."
                    value={filters.search}
                    onChange={(e) =>
                      this._filterOnChange("search", e.target.value, true)
                    }
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup className="dateRange">
                <Label>Date Range</Label>
                <DateRangePicker
                  className="dateRange"
                  format="MM-dd-y"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  onChange={(dateRangeValue) =>
                    this._onChangeDatePicker(dateRangeValue)
                  }
                  disabled={filters?.showUpcomingAppointment}
                  value={dateRangeValue}
                />
              </FormGroup>
              <FormGroup>
                <Label>Status</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="status"
                    value={filters.status}
                    onChange={(e) =>
                      this._filterOnChange("status", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {agentClosingStatus.map((obj) => (
                      <option key={obj.value} value={obj.value}>
                        {obj.label}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Client</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="client"
                    value={filters?.client}
                    onChange={(e) =>
                      this._filterOnChange("client", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {filterDropdownValues.clients.map((client) => (
                      <option key={client.id} value={client.id}>
                        {/* {(client.name &&
                        client.name.full &&
                        capitalize(client.name.full)) ||
                        "N/A Company"} */}
                        {(client?.companyName
                          ? capitalize(client.companyName)
                          : capitalize(client.name.full)) || "N/A Company"}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup style={{ minWidth: "150px" }}>
                <Label>Client Teams</Label>
                <div
                  className={`custom-select-wrapper ${
                    filters?.client === "" ? "disabled" : ""
                  }`}
                >
                  <Input
                    type="select"
                    placeholder=" "
                    value={filters?.clientTeam}
                    onChange={(event) =>
                      this._filterOnChange("clientTeam", event.target.value)
                    }
                    disabled={filters?.client === ""}
                    name="clientTeamsId"
                  >
                    <option value="">All Teams</option>
                    {filterDropdownValues?.clientTeamsList?.map((team) => (
                      <option key={team?._id} value={team?._id}>
                        {team?.teamName ? capitalize(team?.teamName) : ""}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>Agent</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="agentAssigned"
                    value={filters.agentAssigned}
                    onChange={(e) =>
                      this._filterOnChange("agentAssigned", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {filterDropdownValues.agents.map((agents) => (
                      <option key={agents.id} value={agents.id}>
                        {(agents.name &&
                          agents.name.full &&
                          capitalize(agents.name.full)) ||
                          "N/A Company"}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <Label>State</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    name="state"
                    value={filters.state}
                    onChange={(e) =>
                      this._filterOnChange("state", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {usaStates.map((state) => (
                      <option
                        key={state.abbreviation}
                        value={state.abbreviation}
                      >
                        {state.name}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup>
                <CustomInput
                  type="checkbox"
                  id="unassigned"
                  name="unassigned"
                  checked={filters?.unassigned}
                  onChange={(e) =>
                    this._filterOnChange("unassigned", e.target.checked)
                  }
                  label="Show Unassigned Only"
                />
              </FormGroup>
              <FormGroup>
                <CustomInput
                  type="checkbox"
                  id="showDraft"
                  name="showDraft"
                  checked={filters?.showDraft}
                  onChange={(e) =>
                    this._filterOnChange("showDraft", e.target.checked)
                  }
                  label="Show Draft Only"
                />
              </FormGroup>
              <FormGroup style={{ maxWidth: 300 }}>
                <CustomInput
                  type="checkbox"
                  id="showUpcomingAppointment"
                  name="showUpcomingAppointment"
                  checked={filters?.showUpcomingAppointment}
                  onChange={(e) =>
                    this._filterOnChange(
                      "showUpcomingAppointment",
                      e.target.checked
                    )
                  }
                  label="Show Upcoming Appointments"
                />
              </FormGroup>
            </div>
            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>

          <div className="hideDesktop">
            <CustomCard
              isPageStartFromOne={true}
              pageNumber={tableConfig?.pageNumber}
              tableData={signingCompanyClosingList}
              headerKeys={signingCompanyClosingCardHeaderKeys}
              // bodyKeys={agentClosingCardBodyKeys}
              dataFormat={this._dataFormat}
              totalCount={signingCompanyClosingTotalCount}
              onPaginate={(pageNumber, pageSize) =>
                this._paginate(pageNumber, pageSize)
              }
              pageSize={tableConfig?.limit}
              rowSelection={false}
              // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
              showTableLoading={loading.showTableLoading}
              cardHeaderFormat={this._cardHeaderFormat}
              cardDataFormat={this._cardDataFormat}
            />
          </div>

          <div className="hideMobile">
            {signingCompanyClosingHeaderKeys &&
              signingCompanyClosingHeaderKeys.length && (
                <CustomTable
                  striped
                  isPageStartFromOne={true}
                  pageNumber={tableConfig?.pageNumber}
                  tableData={signingCompanyClosingList}
                  headerKeys={signingCompanyClosingHeaderKeys}
                  dataFormat={this._dataFormat}
                  totalCount={signingCompanyClosingTotalCount}
                  // rowSelection={false}
                  pageSize={tableConfig?.limit}
                  onPaginate={(pageNumber, pageSize) =>
                    this._paginate(pageNumber, pageSize)
                  }
                  // onSortChange={(sortName, sortOrder) =>
                  //   this._onSortChange(sortName, sortOrder)
                  // }
                  // rowStyleFormat={(row, rowIdx) =>
                  //   this._rowStyleFormat(row, rowIdx)
                  // }
                  rowClassName={(row, rowIdx) =>
                    this._rowClassName(row, rowIdx)
                  }
                  showTableLoading={loading.showTableLoading}
                />
              )}
          </div>

          <ClosingAssignment
            {...this.props}
            isOpen={assignJobModal.isOpen}
            data={assignJobModal.data}
            agents={agents}
            resetDetails={() => this._getAllSigningClosing()}
            toggle={() => this._onToggleAssignJobModal()}
          />
          {signerAvailabilityModal.isOpen ? (
            <SignerAvailabilityModal
              isOpen={signerAvailabilityModal.isOpen}
              data={signerAvailabilityModal.data}
              resetDetails={() => this._paginate()}
              toggle={() => this._onToggleSignerAvailabilityModal()}
              AppointmentSchedulerConfig={{
                regular: { disable: false, show: true },
                dateRange: { disable: false, show: true },
                tbd: { disable: false, show: true },
              }}
            />
          ) : null}

          {signingStatusModal.isOpen && (
            <SigningStatusSigningModal
              isOpen={signingStatusModal.isOpen}
              data={signingStatusModal.data}
              status={signingStatusModal.status}
              resetDetails={() => this._getAllSigningClosing()}
              toggle={() => this._onToggleSigningStatusModal()}
              isDateFormat={false}
              initialViewMode={"time"}
            />
          )}
          <OrderDashboardExport
            isOpen={this.state.quickbookModal.isOpen}
            data={this.state.quickbookModal.data}
            toggle={() => this._onToggleQuickbookModal()}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addListData: (listData) => dispatch(addListData(listData)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClosingDashboard);
