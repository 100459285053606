import React, { Component } from "react";
import { Card, CardBody, Modal, ModalBody, ModalHeader } from "reactstrap";
import {
  errorHandler,
  formatCurrencyValue,
  formatDate,
  showToast,
} from "../../../helper-methods";
import Payment from "../components/payment";

class BuySubscriptionAgentModal extends Component {
  state = {
    loading: false,
  };

  _closeModal = () => {
    if (!this.state.loading) {
      this.props.toggle();
    }
  };

  _purchaseSubscription = async (payload) => {
    try {
      const { data } = this.props;

      const { successText } = await this.props.purchaseSubscription(
        data.type,
        payload
      );

      showToast(successText || "Subscription Activated", "success");

      this.setState({ loading: false }, () => {
        this._closeModal();
      });
    } catch (error) {
      errorHandler(error);
      this.setState({ loading: false });
    }
  };

  _onBuySubscription = () => {
    this.setState({ loading: true });

    const { data } = this.props;

    const payload = {
      billingCycle: data.billingCycle,
      plan: data.plan,
    };

    this._purchaseSubscription(payload);
  };

  render() {
    const { loading } = this.state;

    const { isOpen, data } = this.props;
    console.log("data", data);
    return (
      <Modal
        isOpen={isOpen}
        toggle={this._closeModal}
        size="lg"
        centered
        scrollable
      >
        <ModalHeader toggle={this._closeModal}>
          {data?.type} Subscription
        </ModalHeader>

        <ModalBody>
          <Card className="selectedPlan">
            <CardBody>
              <h2>
                {" "}
                {data?.detail?.amountToBeCharged > -1 ? (
                  <>
                    Amount To be Charged:
                    <span>
                      {formatCurrencyValue(data.detail.amountToBeCharged)}
                    </span>
                  </>
                ) : null}
              </h2>
              <div className="plan">
                <h6>
                  Plan Selected <br />
                  <span>${data.amount}</span>/
                  {data.amount >= 100 ? "Yearly" : "Monthly"}
                </h6>

                {data?.billingCycle ? (
                  <div>
                    Next Billing On:
                    <span>
                      {/* {formatDate(moment().add(data.billingCycle, "days"))} */}
                      {formatDate(data.detail.end)}
                    </span>
                  </div>
                ) : null}
              </div>
            </CardBody>
          </Card>

          {/* {data?.type === "Downgrade" ? (
            <div className="text-center">
              <Button
                color="primary"
                outline
                className="mr-3"
                onClick={() => this._closeModal()}
              >
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={loading ? true : false}
                onClick={() => this._onBuySubscription()}
              >
                {data?.type}
              </Button>
            </div>
          ) : ( */}
          <Payment
            loading={true}
            manageLoading={(value) => this.setState({ loading: value })}
            cancelButton={true}
            onCancelButton={this._closeModal}
            submitButtonText={data?.type || ""}
            onPaymentConfirmation={() => this._onBuySubscription()}
          />
          {/* )} */}
        </ModalBody>

        {loading && (
          <div className="table-overlay">
            <div>
              <i className="fa fa-spinner fa-spin" />
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

export default BuySubscriptionAgentModal;
