import React from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Row,
  Col,
  Button,
  CardHeader,
  CardTitle,
  Label,
  TabPane,
  Nav,
  NavItem,
  TabContent,
  NavLink,
  Table,
  Input,
  Collapse,
  CustomInput,
  UncontrolledTooltip,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  FormGroup,
} from "reactstrap";
import {
  getClientConnectByCompany,
  companyResponseToConnectByClient,
  reRequestClientConnectByCompany,
  // deleteRequestConnectWithClient,
  companyRequestClientForConnect,
  getClientTeamMembers,
  editClientTeamMemberStatus,
  companyGetAssistantTeams,
  companyDeleteAssistantTeam,
  companyResendLoginInvitation,
} from "../../../http/http-calls";
import {
  // formatTime,
  errorHandler,
  formatAddressInSingleText,
  formatPhoneNumber,
  capitalize,
  deepClone,
  formatDateHideYearIfSame,
  showToast,
  getDropdownColor,
  getFullName,
} from "../../../helper-methods";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
// import moment from "moment";
import {
  DEFAULT_PROFILE_PICTURE,
  clientTeamMembersConfig,
} from "../../../config";
import CompanyNotes from "../components/CompanyNotes";
import CompanyDocuments from "../components/CompanyDocuments";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import AddClientinSigningCompanyModal from "../components/addClient-insigningCompany-modal";
import CustomCard from "../components/CustomCard";
import CustomOrderQuestion from "../components/CustomOrderQuestion";
import CustomProductVisibility from "../components/CustomProductVisibility";
import SvgIcons from "../components/SvgIcons";
import AddEditTeamMembers from "../components/Modals/Company/AddEditTeamMembers";
import Swal from "sweetalert2";
import CustomPagination from "../components/CustomPagination";

class ClientDetails extends React.Component {
  state = {
    client: null,
    teamMembers: {
      data: [],
      totalCount: 0,
    },
    assistantTeams: [],
    isEditable: false,
    activeTab: "clientDetailsTab",
    loading: {
      requestLoading: false,
    },
    clientMembersCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    addUserModal: {
      isOpen: false,
      data: null,
    },
    teamMemberModal: {
      isOpen: false,
      data: null,
    },
    isOpenCollapse: "",
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    },
    filters: {
      search: "",
    },
  };

  _manageLoading = (key, value) => {
    const { loading } = this.state;
    loading[key] = value;
    this.setState({ loading });
  };

  _handleCollapse = (key) => {
    let { isOpenCollapse } = deepClone(this.state);

    isOpenCollapse = isOpenCollapse === key ? "" : key;

    this.setState({
      isOpenCollapse,
    });
  };

  _getClientConnectByCompany = () => {
    return new Promise(async (resolve, reject) => {
      try {
        const { id } = this.props.match.params;

        const res = await getClientConnectByCompany({ clientId: id });

        this.setState({ client: res?.client || {} });
        this._getClientTeamMembers(this.state.tableConfig);
        resolve(res?.client || {});
      } catch (error) {
        reject(error);
      }
    });
  };

  _getClientTeamMembers = async (tableConfig) => {
    try {
      const { id } = this.props.match.params;
      const { filters } = deepClone(this.state);

      const payload = {
        ...tableConfig,
        clientId: id,
      };

      if (filters?.search) {
        payload["search"] = filters?.search;
      }

      const res = await getClientTeamMembers(payload);
      this.setState({
        teamMembers: {
          data: res?.users || [],
          totalCount: res?.totalCount,
        },
        // isEditable: res?.client?.isEditable || false,
      });
    } catch (error) {
      console.log("error", error);
    }
  };

  _getCompanyAssistantTeams = async () => {
    try {
      const res = await companyGetAssistantTeams({
        clientId: this.state?.client?.id,
      });

      this.setState({ assistantTeams: res?.assistantTeams || [] });
    } catch (err) {
      errorHandler(err);
    }
  };

  componentDidMount = async () => {
    try {
      this.props.showLoader("Loading...");

      await this._getClientConnectByCompany();
      await this._getCompanyAssistantTeams();

      this.props.hideLoader();
    } catch (error) {
      this.props.hideLoader();
      errorHandler(error);
      this.props.history.goBack();
    }
    HeaderEventEmitter.subscribe("back-button", () => {
      this.props.history.goBack();
    });
  };

  _requestClientConnectByCompany = async (client) => {
    try {
      if (!client?._id) return;

      const clientId = client._id;

      this._manageLoading("requestLoading", true);

      if (client.connectStatus === "rejected") {
        await reRequestClientConnectByCompany(clientId);
      } else {
        await companyRequestClientForConnect({ clientId });
      }
      await this._getClientConnectByCompany();

      this._manageLoading("requestLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("requestLoading", false);
    }
  };

  _companyResponseToConnectByClient = async ({ id, status }) => {
    try {
      this._manageLoading("requestLoading", status);

      await companyResponseToConnectByClient({ clientId: id, status });

      await this._getClientConnectByCompany();

      this._manageLoading("requestLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("requestLoading", false);
    }
  };

  _toggle = (tab) => {
    const { activeTab } = deepClone(this.state);

    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }

    if (tab === "clientDetailsTab") {
      this._getCompanyAssistantTeams();
    }
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfig } = deepClone(this.state);

    tableConfig["skip"] = (pageNumber - 1) * pageSize;
    tableConfig["limit"] = pageSize;
    tableConfig["pageNumber"] = pageNumber;

    this.setState({ tableConfig });

    this._getClientTeamMembers(tableConfig);
  };

  _onToggleAddUserModal = (bool, data) => {
    let { addUserModal } = JSON.parse(JSON.stringify(this.state));
    addUserModal.isOpen = !addUserModal.isOpen;
    addUserModal.data = data;
    this.setState({
      addUserModal,
    });
  };

  _onToggleTeamMemberModal = (isOpen = false, data = null) => {
    let { client, teamMemberModal } = deepClone(this.state);

    // if (client?.connectStatus !== "accepted") {
    //   errorHandler({
    //     reason: "Please connect with the client to add new team",
    //   });
    //   return;
    // }

    teamMemberModal.isOpen = isOpen;
    teamMemberModal.data = data;

    this.setState({
      teamMemberModal,
    });
  };

  _calculatePermissionCount = (permissionSettings) => {
    let count = 0;
    permissionSettings !== undefined &&
      Object.entries(permissionSettings).forEach(([key, value]) => {
        Object.keys(value).forEach(function (key) {
          if (value[key] === true) count += 1;
        });
      });
    return count;
  };

  _userStatusUpdate = (data) => {
    this._manageLoading("requestLoading", true);

    editClientTeamMemberStatus(data.id, {
      clientId: this.state.client?._id,
    })
      .then((res) => {
        showToast("Status has been updated", "success");
        const { teamMembers } = deepClone(this.state);
        const user = teamMembers?.data?.find((user) => user.id === data.id);
        user["isActive"] = res?.user?.isActive || false;
        this.setState({ teamMembers });
        this._manageLoading("requestLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("requestLoading", false);
      });
  };

  _cardHeaderFormat = (cell, row, header) => {
    switch (header) {
      case "name": {
        return row ? (
          <>
            <div className="tableUserInfo">
              {row?.name ? getFullName(row?.name) : "N/A"}
            </div>

            <Button
              onClick={() => this._onToggleAddUserModal(true, row)}
              color="link"
              // disabled={!this.state.isEditable}
            >
              <SvgIcons type={"pencil"} />
            </Button>
            <Button
              color="link"
              className="remove resendInvitation"
              onClick={() => this._resendClientInvitation(row)}
              id="email_enevlope_open_card"
            >
              <SvgIcons type={"resendEmail"} />
              {/* Resend Invitation */}
            </Button>
            <UncontrolledTooltip
              placement="bottom"
              target="email_enevlope_open_card"
            >
              Resend invitation
            </UncontrolledTooltip>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Email</Label>
            <div style={{ fontWeight: 600 }}>
              {row?.email ? (
                <a href={`mailto:${row?.email}`}>{row?.email}</a>
              ) : null}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Phone</Label>
            <div style={{ fontWeight: 600 }}>{row?.phone}</div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Permission</Label>
            <div style={{ fontWeight: 600 }}>
              {row?.permissionSettings
                ? this._calculatePermissionCount(row?.permissionSettings)
                : 0}
              /6
            </div>
          </div>
          <div className="dataWrap">
            <Label>Added By</Label>
            <div style={{ fontWeight: 600 }}>
              {" "}
              {row?._createdBy || row?._createdByClient
                ? row?._createdBy?.name?.full ||
                  row?._createdByClient?.name?.full
                : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Last Login</Label>
            <div style={{ fontWeight: 600 }}>
              {row?.lastLogin
                ? formatDateHideYearIfSame(row?.lastLogin)
                : "Not Logged In Yet!"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Status</Label>
            <div style={{ fontWeight: 600 }}>
              <div className="customSelectWrap" style={{ width: 100 }}>
                <Input
                  type="select"
                  name="status"
                  className={`status ${getDropdownColor(
                    row?.isActive ? "active" : "inactive"
                  )}`}
                  disabled={
                    this.state.loading.requestLoading
                    // || !this.state.isEditable
                  }
                  value={row?.isActive ? "active" : "inactive"}
                  onChange={(event) => this._userStatusUpdate(row)}
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Input>
                <div className="downArrow">
                  <i className="fa fa-chevron-down"></i>
                </div>
              </div>
            </div>
          </div>
        </li>
      </ul>
    );
  };

  _deleteCompanyAssistantTeamAlert = (assistantTeamId, index) => {
    if (!assistantTeamId) {
      errorHandler();
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this team.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          clientId: this.state.client?.id,
        };
        this._deleteCompanyAssistantTeam(assistantTeamId, payload, index);
      }
    });
  };

  _deleteCompanyAssistantTeam = async (assistantTeamId, payload, index) => {
    const { assistantTeams } = deepClone(this.state);
    let deletedItem;
    try {
      deletedItem = assistantTeams.splice(index, 1); // deleting item beforehand, for better ux

      this.setState({ assistantTeams });

      await companyDeleteAssistantTeam(assistantTeamId, payload);

      // await this._getCompanyAssistantTeams();  // api to refetch data
    } catch (err) {
      assistantTeams.splice(index, 0, deletedItem[0]);
      this.setState({ assistantTeams }); // setting deleted item again, if api fails
      errorHandler(err);
    }
  };

  _resendClientInvitation = async (teamMember) => {
    console.log("team,", teamMember);
    try {
      const { id } = this.props.match.params;

      const payload = { clientId: id, userId: teamMember._id };

      await companyResendLoginInvitation(payload);
      showToast("Invitation sent successfully", "success");
    } catch (err) {
      errorHandler(err);
    }
  };

  _filterOnChange = (type, value, isDelay = false) => {
    try {
      if (isDelay) clearTimeout(this.searchTimer);

      const { filters } = this.state;
      filters[type] = value;

      this.setState({ filters }, () => {
        if (isDelay) {
          this.searchTimer = setTimeout(() => {
            this._paginate(1, 10);
          }, 1000);
        } else {
          this._paginate(1, 10);
        }
      });
    } catch (err) {
      errorHandler(err);
    }
  };

  render() {
    const {
      activeTab,
      client,
      loading,
      addUserModal,
      teamMembers,
      isEditable,
      clientMembersCardHeaderKeys,
      teamMemberModal,
      assistantTeams,
      tableConfig,
      filters,
    } = this.state;

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <div className="d-flex align-items-center">
              <Button
                color="link"
                className="backBtn"
                onClick={() => this.props.history.goBack()}
              >
                <img
                  src={require("../../../assets/img/arrowLeft.svg").default}
                  alt="backbutton"
                  height={14}
                />
              </Button>
              <h2>Client Details</h2>
            </div>
          </div>
          <div className="verticalTabs">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={`${
                    activeTab === "clientDetailsTab" ? "active" : ""
                  }`}
                  onClick={() => {
                    this._toggle("clientDetailsTab");
                  }}
                >
                  <img
                    src={
                      require(`../../../assets/img/${
                        activeTab === "clientDetailsTab"
                          ? "basicdetails-blue"
                          : "basicdetails-black"
                      }.svg`).default
                    }
                    alt="basic details"
                  />
                  Client Details
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  onClick={() => {
                    this._toggle("ManageMember");
                  }}
                  className={`${activeTab === "ManageMember" ? "active" : ""}`}
                >
                  <img
                    src={
                      require(`../../../assets/img/${
                        activeTab === "ManageMember"
                          ? "basicdetails-blue"
                          : "basicdetails-black"
                      }.svg`).default
                    }
                    alt="basic details"
                  />
                  Manage Member
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  onClick={() => {
                    this._toggle("customOrder");
                  }}
                  className={`${activeTab === "customOrder" ? "active" : ""}`}
                >
                  <img
                    src={
                      require(`../../../assets/img/${
                        activeTab === "customOrder"
                          ? "basicdetails-blue"
                          : "basicdetails-black"
                      }.svg`).default
                    }
                    alt="basic details"
                  />
                  Custom Order Detail
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  onClick={() => {
                    this._toggle("CustomProduct");
                  }}
                  className={`${activeTab === "CustomProduct" ? "active" : ""}`}
                >
                  <img
                    src={
                      require(`../../../assets/img/${
                        activeTab === "CustomProduct"
                          ? "basicdetails-blue"
                          : "basicdetails-black"
                      }.svg`).default
                    }
                    alt="basic details"
                  />
                  Custom Product Visibility
                </NavLink>
              </NavItem>
            </Nav>

            <TabContent activeTab={activeTab}>
              <TabPane tabId="clientDetailsTab">
                <Card className="aboutCompany signingClientDetails">
                  <CardBody>
                    <div className="companyImage">
                      <img
                        src={
                          client?.logo ? client.logo : DEFAULT_PROFILE_PICTURE
                        }
                        alt="Profile Img"
                      />
                    </div>
                    <div className="clientContent">
                      <h5>
                        {client?.companyName
                          ? capitalize(client.companyName)
                          : "N/A"}
                      </h5>

                      <ul>
                        <li>
                          <img
                            src={
                              require("../../../assets/img/mapMarker_blue.svg")
                                .default
                            }
                            alt="location"
                            height={14}
                          />
                          {formatAddressInSingleText(client?.address) || "N/A"}
                        </li>
                        {/* <li title="Point of Contact">
                          POC: {client?.pointOfContact || "N/A"}
                        </li> */}
                      </ul>
                    </div>
                    {/* <div className="action">
                      {client?.connectStatus === "accepted" ? (
                        <Button color="success" outline>
                          Connected
                        </Button>
                      ) : null}
                      {client?.connectStatus === "pending" ||
                      client?.connectStatus === "rejected" ||
                      // &&
                      //   client?.connectResquestBy?.toLowerCase() ===
                      //     "signingcompany")
                      (client?.connectResquestBy === undefined &&
                        client?.connectStatus === undefined) ? (
                        <Button
                          color="primary"
                          disabled={loading.requestLoading}
                          onClick={() =>
                            this._requestClientConnectByCompany(client)
                          }
                        >
                          {loading.requestLoading ? (
                            <i className="fa fa-spinner fa-spin mr-2" />
                          ) : null}{" "}
                          {client?.connectStatus === "rejected" &&
                          client?.connectResquestBy?.toLowerCase() ===
                            "signingcompany"
                            ? "Re-"
                            : null}
                          Request Connection
                        </Button>
                      ) : null}

                      {client?.connectStatus === "requested" ? (
                        client?.connectResquestBy?.toLowerCase() ===
                        "client" ? (
                          <div>
                            <Button
                              color="success"
                              outline
                              disabled={loading.requestLoading ? true : false}
                              onClick={() =>
                                this._companyResponseToConnectByClient({
                                  id: client?._id,
                                  status: "accepted",
                                })
                              }
                            >
                              {loading.requestLoading === "accepted" ? (
                                <i className="fa fa-spinner fa-spin mr-2" />
                              ) : null}{" "}
                              Accept
                            </Button>
                            <Button
                              color="danger"
                              outline
                              disabled={loading.requestLoading ? true : false}
                              onClick={() =>
                                this._companyResponseToConnectByClient({
                                  id: client?._id,
                                  status: "rejected",
                                })
                              }
                            >
                              {loading.requestLoading === "rejected" ? (
                                <i className="fa fa-spinner fa-spin mr-2" />
                              ) : null}{" "}
                              Reject
                            </Button>
                          </div>
                        ) : (
                          <Button color="primary" outline>
                            Request Sent
                          </Button>
                        )
                      ) : null}
                    </div> */}
                  </CardBody>
                </Card>

                <Card className="teamMembers">
                  <CardHeader>
                    <CardTitle>Teams</CardTitle>
                    <Button
                      color="link"
                      className="readMore"
                      onClick={() => this._onToggleTeamMemberModal(true)}
                      // disabled={!client?.isEditable}
                    >
                      Add Teams
                    </Button>
                  </CardHeader>
                  {console.log("qa", assistantTeams)}
                  <CardBody>
                    {assistantTeams?.length ? (
                      assistantTeams?.map((each, index) => (
                        <div
                          className={`teamsAccordian ${
                            this.state.isOpenCollapse === each?._id
                              ? "open"
                              : ""
                          }`}
                        >
                          <div className="teamsHead">
                            <span
                              onClick={() => this._handleCollapse(each?._id)}
                            >
                              {each?.teamName}{" "}
                              {each?.uniqueId ? `(${each?.uniqueId})` : ""}
                            </span>
                            <div>
                              <Button
                                className="pencilIcon"
                                color="link"
                                onClick={() =>
                                  this._onToggleTeamMemberModal(true, each)
                                }
                                // disabled={!client?.isEditable}
                              >
                                <SvgIcons type={"pencil"} />
                              </Button>
                              {each.teamName !== "Primary Team" && (
                                <Button
                                  className="remove"
                                  color="link"
                                  onClick={() =>
                                    this._deleteCompanyAssistantTeamAlert(
                                      each?._id,
                                      index
                                    )
                                  }
                                  // disabled={!client?.isEditable}
                                >
                                  <SvgIcons type={"remove"} />
                                </Button>
                              )}
                              <i
                                onClick={() => this._handleCollapse(each?._id)}
                                className={`fa fa-${
                                  this.state.isOpenCollapse === each?._id
                                    ? "chevron-up"
                                    : "chevron-down"
                                }`}
                              />
                            </div>
                          </div>
                          <Collapse
                            className="teamsBody"
                            isOpen={
                              this.state.isOpenCollapse === each?._id
                                ? true
                                : false
                            }
                          >
                            {each?.teamMembers?.filter(
                              (subEach) => subEach?._user
                            )?.length ? (
                              <>
                                <h2>Members</h2>
                                {each?.teamMembers
                                  ?.filter((subEach) => subEach?._user)
                                  ?.map((teamMember) => (
                                    <div className="">
                                      <ul>
                                        <li>
                                          <h3>
                                            {/* {teamMember?.name}{" "} */}
                                            {teamMember?._user?.name?.full
                                              ? teamMember?._user?.name?.full
                                              : teamMember?._user?.name?.first}
                                            {teamMember?._user?.isActive ===
                                            false ? (
                                              <span className="text-danger">
                                                (Inactive)
                                              </span>
                                            ) : null}
                                          </h3>
                                          {clientTeamMembersConfig?.map(
                                            (permissionKey, index) => (
                                              <CustomInput
                                                key={permissionKey?.value}
                                                type="checkbox"
                                                id={`memberPermissionCheckbox_${permissionKey?.value}}`}
                                                label={permissionKey?.label}
                                                inline
                                                checked={teamMember?.roles?.includes(
                                                  index + 1
                                                )}
                                                disabled={true}
                                              />
                                            )
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  ))}
                              </>
                            ) : null}

                            {each?.teamMembers?.filter(
                              (subEach) => !subEach?._user
                            )?.length ? (
                              <>
                                <h2>CC Members</h2>
                                {each?.teamMembers
                                  ?.filter((subEach) => !subEach?._user)
                                  ?.map((teamMember) => (
                                    <div className="">
                                      <ul>
                                        <li>
                                          <h3>{teamMember?.name?.full}</h3>
                                          {clientTeamMembersConfig?.map(
                                            (permissionKey, index) => (
                                              <CustomInput
                                                key={permissionKey?.value}
                                                type="checkbox"
                                                id={`memberPermissionCheckbox_${permissionKey?.value}}`}
                                                label={permissionKey?.label}
                                                inline
                                                checked={teamMember?.roles?.includes(
                                                  index + 1
                                                )}
                                                disabled={true}
                                              />
                                            )
                                          )}
                                        </li>
                                      </ul>
                                    </div>
                                  ))}
                              </>
                            ) : null}
                          </Collapse>
                        </div>
                      ))
                    ) : (
                      <div className="nodata">
                        <img
                          src={
                            require("../../../assets/img/noData.svg").default
                          }
                          alt="no data"
                        />
                        <p>There is no data to display</p>
                      </div>
                    )}
                  </CardBody>
                </Card>

                <Card>
                  <CardHeader>
                    <CardTitle>Stats</CardTitle>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col xs="6" md="3">
                        <div className="CompanyStats">
                          <div>
                            <h6>Jobs Completed</h6>
                            <p>
                              {client?.jobsCompleted >= 0
                                ? client?.jobsCompleted
                                : "N/A"}
                            </p>
                          </div>
                          <div className="icon">
                            <img
                              src={
                                require("../../../assets/img/JobCompleted.png")
                                  .default
                              }
                              alt="JobCompleted"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs="6" md="3">
                        <div className="CompanyStats">
                          <div>
                            <h6>Jobs Scheduled</h6>
                            <p>
                              {client?.jobsScheduled >= 0
                                ? client?.jobsScheduled
                                : "N/A"}
                            </p>
                          </div>
                          <div className="icon">
                            <img
                              src={
                                require("../../../assets/img/JobsScheduled.png")
                                  .default
                              }
                              alt="JobCompleted"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs="6" md="3">
                        <div className="CompanyStats">
                          <div>
                            <h6>Invoices Paid</h6>
                            <p>
                              {client?.invoicesPaid >= 0
                                ? client?.invoicesPaid
                                : "N/A"}
                            </p>
                          </div>
                          <div className="icon">
                            <img
                              src={
                                require("../../../assets/img/TotalIncome.svg")
                                  .default
                              }
                              alt="JobCompleted"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col xs="6" md="3">
                        <div className="CompanyStats">
                          <div>
                            <h6>Invoices Pending</h6>
                            <p>
                              {" "}
                              {client?.invoicesPending >= 0
                                ? client?.invoicesPending
                                : "N/A"}
                            </p>
                          </div>
                          <div className="icon">
                            <img
                              src={
                                require("../../../assets/img/invoicepending.svg")
                                  .default
                              }
                              alt="JobCompleted"
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <CompanyDocuments data={client} />
                <CompanyNotes data={client} />
              </TabPane>
              <TabPane tabId="ManageMember">
                <div className="d-flex justify-content-between align-items-end align-items-md-baseline flex-column flex-md-row">
                  <div className="mobileSearchFiled">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <img
                            src={
                              require("../../../assets/img/searchIcon.svg")
                                .default
                            }
                            alt="searchIcon"
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        name="search"
                        value={filters?.search}
                        onChange={(e) =>
                          this._filterOnChange("search", e.target.value, true)
                        }
                        placeholder="Search By Name"
                      />
                    </InputGroup>
                  </div>
                  <div className="filterContainer responsiveFilter">
                    <div className="filterIcon">
                      <img
                        src={
                          require("../../../assets/img/filter_icon.svg").default
                        }
                        alt="filter icon"
                      />
                      Filter by
                    </div>
                    <div className="filterWrapper">
                      <FormGroup className="searchTable">
                        <Label>Search</Label>
                        <InputGroup>
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <img
                                src={
                                  require("../../../assets/img/searchIcon.svg")
                                    .default
                                }
                                alt="searchIcon"
                              />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            type="text"
                            placeholder="Search By Name"
                            value={filters?.search}
                            onChange={(e) =>
                              this._filterOnChange(
                                "search",
                                e.target.value,
                                true
                              )
                            }
                          />
                        </InputGroup>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="text-right mb-3">
                    {/* {isEditable ? ( */}
                    <Button
                      color="primary"
                      size="md"
                      onClick={() => this._onToggleAddUserModal(true)}
                    >
                      ADD TEAM MEMBER
                    </Button>
                    {/* ) : null} */}
                  </div>
                </div>

                <div className="CustomTableWrapper hideMobile">
                  <Table striped responsive>
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Permission</th>
                        <th>Added by</th>
                        <th>Last Login</th>
                        <th>Status</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {teamMembers?.data?.length ? (
                        teamMembers?.data?.map((teamMember, index) => (
                          <tr key={index}>
                            <td>
                              <div style={{ width: 120 }}>
                                {teamMember?.name
                                  ? getFullName(teamMember?.name)
                                  : "N/A"}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div style={{ width: 120 }}>
                                {teamMember?.email ? (
                                  <a href={`mailto:${teamMember?.email}`}>
                                    {teamMember?.email}
                                  </a>
                                ) : null}
                              </div>
                            </td>
                            <td>
                              {" "}
                              <div style={{ width: 110 }}>
                                {teamMember?.phone}
                              </div>
                            </td>
                            <td>
                              <div style={{ width: 40 }}>
                                {teamMember?.permissionSettings
                                  ? this._calculatePermissionCount(
                                      teamMember?.permissionSettings
                                    )
                                  : 0}
                                /6
                              </div>
                            </td>
                            <td>
                              <div style={{ width: 150 }}>
                                {teamMember._createdBy ||
                                teamMember._createdByClient
                                  ? teamMember._createdBy?.name?.full ||
                                    teamMember._createdByClient?.name?.full
                                  : "N/A"}
                              </div>
                            </td>
                            <td>
                              {teamMember?.lastLogin
                                ? formatDateHideYearIfSame(teamMember.lastLogin)
                                : "Not Logged In Yet!"}
                            </td>
                            <td>
                              <div
                                className="customSelectWrap"
                                style={{ width: 100 }}
                              >
                                <Input
                                  type="select"
                                  name="status"
                                  className={`status ${getDropdownColor(
                                    teamMember.isActive ? "active" : "inactive"
                                  )}`}
                                  disabled={
                                    this.state.loading.requestLoading
                                    //|| !isEditable
                                  }
                                  value={
                                    teamMember.isActive ? "active" : "inactive"
                                  }
                                  onChange={(event) =>
                                    this._userStatusUpdate(teamMember)
                                  }
                                >
                                  <option value="active">Active</option>
                                  <option value="inactive">Inactive</option>
                                </Input>
                                <div className="downArrow">
                                  <i className="fa fa-chevron-down"></i>
                                </div>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex flex-wrap">
                                <Button
                                  onClick={() =>
                                    this._onToggleAddUserModal(true, teamMember)
                                  }
                                  color="link"
                                  // disabled={!isEditable}
                                  id="pencil_Edit"
                                >
                                  {/* <img
                                    src={
                                      require("../../../assets/img/pencil.svg")
                                        .default
                                    }
                                    alt="edit"
                                  /> */}
                                  <SvgIcons type={"pencil"} />
                                </Button>
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="pencil_Edit"
                                >
                                  Edit
                                </UncontrolledTooltip>
                                <Button
                                  color="link"
                                  className="remove resendInvitation"
                                  onClick={() =>
                                    this._resendClientInvitation(teamMember)
                                  }
                                  id="email_enevlope_open"
                                >
                                  <SvgIcons type={"resendEmail"} />
                                  {/* Resend Invitation */}
                                </Button>
                                <UncontrolledTooltip
                                  placement="bottom"
                                  target="email_enevlope_open"
                                >
                                  Resend invitation
                                </UncontrolledTooltip>
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <>
                          <tr>
                            <td colSpan="8" style={{ textAlign: "center" }}>
                              <div className="nodata">
                                <img
                                  src={
                                    require("../../../assets/img/noData.svg")
                                      .default
                                  }
                                  alt="no data"
                                />
                                <p>There is no data to display</p>
                              </div>
                            </td>
                          </tr>
                        </>
                      )}
                    </tbody>
                  </Table>
                  <CustomPagination
                    isPageStartFromOne={true}
                    data={teamMembers?.data}
                    dataCount={teamMembers?.totalCount}
                    pageNumber={tableConfig?.pageNumber}
                    pageSize={tableConfig?.limit}
                    onPageChange={(pageNumber) => this._paginate(pageNumber)}
                    isHideForLessData={true}
                  />
                </div>

                <div className="hideDesktop">
                  <CustomCard
                    isPageStartFromOne={true}
                    tableData={teamMembers?.data}
                    isPagination={true}
                    headerKeys={clientMembersCardHeaderKeys}
                    rowSelection={false}
                    pageNumber={tableConfig?.pageNumber}
                    pageSize={tableConfig?.limit}
                    totalCount={teamMembers?.totalCount}
                    onPaginate={(pageNumber, pageSize) =>
                      this._paginate(pageNumber, pageSize)
                    }
                    showTableLoading={loading.showTableLoading}
                    cardHeaderFormat={this._cardHeaderFormat}
                    cardDataFormat={this._cardDataFormat}
                  />
                </div>
              </TabPane>

              <TabPane tabId="customOrder">
                <CustomOrderQuestion
                  client={client}
                  refresh={() => this._getClientConnectByCompany()}
                />
              </TabPane>
              <TabPane tabId="CustomProduct">
                <CustomProductVisibility
                  client={client}
                  refresh={() => this._getClientConnectByCompany()}
                />
              </TabPane>
            </TabContent>

            {addUserModal.isOpen ? (
              <AddClientinSigningCompanyModal
                isOpen={addUserModal.isOpen}
                data={addUserModal.data}
                resetTable={this._getClientTeamMembers}
                toggle={() => this._onToggleAddUserModal()}
                id={client?._id}
              />
            ) : null}
          </div>
        </div>

        {teamMemberModal?.isOpen && (
          <AddEditTeamMembers
            isOpen={teamMemberModal?.isOpen}
            data={teamMemberModal?.data}
            onSuccess={this._getCompanyAssistantTeams}
            toggle={() => this._onToggleTeamMemberModal()}
            clientId={client?._id}
            type="signingcompany"
          />
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails);
