import React, { Component } from "react";
import { connect } from "react-redux";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Input,
  FormGroup,
} from "reactstrap";
import {
  deepClone,
  showToast,
  openUrlOnNewTab,
  errorHandler,
} from "../../../helper-methods";
import {
  addUserBank,
  addBankAccount,
  getUserKycLink,
  makeCardDefault,
  getUserVerifyBankAccount,
} from "../../../http/http-calls";
import { injectStripe } from "react-stripe-elements";

class AddAccountModal extends Component {
  state = {
    cards: [],
    accounts: [],
    type: "verify", // 'new'
    isLoaderActive: false,
    defaultAccount: null,
    selectedAccount: null,
    error: null,
    routingNo: "",
    accountNo: "",
    amount1: "",
    amount2: "",
  };

  componentDidUpdate = async (previousProps, previousState) => {
    const { isOpen, data } = this.props;
    if (isOpen && previousProps.isOpen !== isOpen) {
      console.log("data", data);
      this.setState({ type: data.type });
    }
  };

  _onChangeFormField = (fieldName, value) => {
    console.log(fieldName, value);
    if (fieldName === "routingNo") {
      if (value.length <= 9) {
        this.setState({ [fieldName]: value });
      }
    } else if (fieldName === "accountNo") {
      if (value.length <= 15) {
        this.setState({ [fieldName]: value });
      }
    } else {
      this.setState({ [fieldName]: value });
    }
  };

  _addBank = async (ev = null) => {
    if (ev) {
      ev.preventDefault();
    }

    if (this.state.routingNo && this.state.accountNo) {
      if (this.props.stripe) {
        this._showLoader();
        this.props.stripe
          .createToken("bank_account", {
            country: "US",
            currency: "usd",
            routing_number: this.state.routingNo,
            account_number: this.state.accountNo,
            account_holder_name: this.props?.userData?.user?.name?.full,
            account_holder_type: "company",
            // account_type: 'checking',
          })
          .then((payload) => {
            console.log(payload);
            if (payload?.error && payload.error.message) {
              errorHandler(payload.error);
              this._hideLoader();
            } else {
              this._onAccountAdded();
              this._userAddBankAccount(payload.token.id);
            }
          })
          .catch((err) => {
            console.log("errrr", err.error.message);
          });
      } else {
        console.log("Stripe.js hasn't loaded yet.");
      }
    } else {
      showToast("Account Number and Routing Number Cannot be Empty", "error");
    }
  };

  _onAccountAdded = async () => {
    this._showLoader();
    try {
      let payload = {
        routingNo: this.state.routingNo,
        accountNo: this.state.accountNo,
      };
      this.setState({ isLoaderActive: true });
      await addUserBank(payload);
      this.setState({ routingNo: "", accountNo: "" });

      this.props.resetTable();
      this._hideLoader();
      this._onDismiss();
    } catch (error) {
      errorHandler(error);
      this._hideLoader();
    }
  };

  _userAddBankAccount = async (token) => {
    this._showLoader();
    addBankAccount({ token })
      .then((response) => {
        console.log(response);
        this._getUserKycLink();
      })
      .catch((error) => {
        errorHandler(error);
        this._hideLoader();
      });
  };

  _getUserKycLink = () => {
    getUserKycLink()
      .then((response) => {
        if (response?.accountLink?.url) {
          openUrlOnNewTab(response.accountLink.url);
        } else {
          showToast("Server error. Try again after sometime.", "error");
        }
        this._hideLoader();
      })
      .catch((error) => {
        errorHandler(error);
        this._hideLoader();
      });
  };

  _onDismiss = () => {
    // Reset state
    this.setState({
      type: "add",
      isLoaderActive: false,
    });
    this.props.toggle();
  };

  _showLoader = () => {
    this.setState({ isLoaderActive: true });
  };

  _hideLoader = () => {
    this.setState({ isLoaderActive: false });
  };

  componentWillUnmount = () => {
    this._hideLoader();
  };

  _processPayment = async () => {
    const { type } = this.state;

    if (type === "add") {
      // Try to add account
      this._addBank();
    } else if (type === "delete") {
      this.props.deleteAccount(this.props?.data?.item);
    } else {
      this._getUserVerifyBankAccount();
    }
  };

  _getUserVerifyBankAccount = () => {
    this._showLoader();

    let payload = {
      amt1: this.state.amount1,
      amt2: this.state.amount2,
      bankId: this.props.data.item.id,
    };
    getUserVerifyBankAccount(payload)
      .then(async (res) => {
        this.setState({ amount1: "", amount2: "" });
        this._hideLoader();
        this.props.resetTable();
        this._onDismiss();
        showToast("Successfully Sent For Verification", "success");
      })
      .catch((error) => {
        errorHandler(error);

        this._hideLoader();
      });
  };

  _makeCardAsDefault = () => {
    const { selectedAccount } = this.state;

    return new Promise((resolve, reject) => {
      makeCardDefault(selectedAccount.id)
        .then((res) => {
          if (!res?.error) {
            resolve();
          }
        })
        .catch((err) => {
          console.log(err);
          reject();
        });
    });
  };

  render() {
    const { isLoaderActive, error, type } = deepClone(this.state);

    return (
      <Modal isOpen={this.props.isOpen} toggle={() => this.props.toggle(null)}>
        <ModalHeader toggle={() => this.props.toggle(null)}>
          {type === "verify" ? "Verify" : type === "delete" ? "Delete" : "Add"}{" "}
          Account
        </ModalHeader>
        <ModalBody>
          {type === "verify" ? (
            <>
              <FormGroup className="floatingLabel">
                <Input
                  type="text"
                  placeholder=" "
                  value={this.state.amount1}
                  onChange={(event) =>
                    this._onChangeFormField("amount1", event.target.value)
                  }
                />
                <Label>Amount 1</Label>
              </FormGroup>

              <FormGroup className="floatingLabel">
                <Input
                  type="text"
                  placeholder=" "
                  value={this.state.amount2}
                  onChange={(event) =>
                    this._onChangeFormField("amount2", event.target.value)
                  }
                />
                <Label>Amount 2</Label>
              </FormGroup>
            </>
          ) : null}

          {type === "add" ? (
            <>
              <FormGroup className="floatingLabel">
                <Input
                  type="text"
                  placeholder=" "
                  value={this.state.accountNo}
                  onChange={(event) =>
                    this._onChangeFormField("accountNo", event.target.value)
                  }
                />
                <Label>Account No.</Label>
              </FormGroup>

              <FormGroup className="floatingLabel">
                <Input
                  type="text"
                  placeholder=" "
                  value={this.state.routingNo}
                  onChange={(event) =>
                    this._onChangeFormField("routingNo", event.target.value)
                  }
                />
                <Label>Routing No.</Label>
              </FormGroup>
            </>
          ) : null}

          {type === "delete" ? (
            <h5 style={{ textAlign: "center", fontWeight: 600, fontSize: 14 }}>
              {" "}
              Do You Want To Delete This Account?{" "}
            </h5>
          ) : null}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            onClick={() => this.props.toggle(null)}
          >
            Cancel
          </Button>

          <Button
            color="primary"
            disabled={error || isLoaderActive ? true : false}
            onClick={() => this._processPayment()}
          >
            {type === "verify" ? "Verify" : type === "delete" ? "Yes" : "Save"}
          </Button>
        </ModalFooter>
        {isLoaderActive && (
          <div className="table-overlay">
            <div>
              <i className="fa fa-spinner fa-spin" />
            </div>
          </div>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData,
  };
};

export default connect(mapStateToProps, null)(injectStripe(AddAccountModal));
