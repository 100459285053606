import React, { Component } from "react";
import { Route, Switch, BrowserRouter, Redirect } from "react-router-dom";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
import PublicRoute from "./modules/general/components/public-route";
import ProtectedRoute from "./modules/general/components/protected-route";
import { persistor, store } from "./redux/store";
import Login from "./modules/public/pages/login";
import ForgotPassword from "./modules/public/pages/forgot-password";
import SignUp from "./modules/public/pages/signup";
import AgentLayout from "./modules/private/containers/agentLayout";
import CompanyLayout from "./modules/private/containers/companyLayout";
import FullPageLoader from "./modules/general/container/full-page-loader";
import ScrollHandler from "./ScrollHandler";
import { StripeProvider } from "react-stripe-elements";
import { createBrowserHistory } from "history";
import { ONE_SIGNAL_APPID, STRIPE_API_KEY } from "./config";
import clientLayout from "./modules/private/containers/clientLayout";
import AgentAbout from "./modules/private/pages/agentAbout";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import newClosingAgent from "./modules/private/pages/new-closing-agent";
import NewClosingAgentPublic from "./modules/private/pages/public-new-closing-agent";
import { checkAgentGuestUser } from "./http/http-calls";
import { errorHandler } from "./helper-methods";
import NnaAgentSignup from "./modules/public/pages/NnaAgentSignup";
import runOneSignal from "./onesignal-helper";
import PublicInvoicePayment from "./modules/public/pages/publicInvoicePayment";
import PublicConfirmation from "./modules/public/pages/publicConfirmation";
export const history = createBrowserHistory();
export const historyWithForceRefresh = createBrowserHistory({
  forceRefresh: true,
});

class App extends Component {
  componentDidMount = () => {
    // let path = history.location.search?.split("=")[1];
    // if (
    //   JSON.parse(localStorage.getItem("root")) &&
    //   JSON.parse(localStorage.getItem("root"))?.userData !== "{}" &&
    //   history.location.search
    // ) {
    //   history.push(path);
    // }
    console.log("cd", history, history.location);

    let path = history.location.search?.split("=")[1];
    let parsedToken = JSON.parse(localStorage.getItem("root"));
    if (
      parsedToken &&
      !(
        parsedToken?.userData === "{}" ||
        JSON.parse(parsedToken?.userData)?.token === null
      ) &&
      history.location.search
    ) {
      if (history.location.search.includes("code")) {
        history.push(`${history.location.pathname}${history.location.search}`);
      } else if (history.location.search.includes("token")) {
        history.push(`${history.location.pathname}${history.location.search}`);
      } else if (history.location.pathname.includes("/agent-about")) {
        history.push(history.location.pathname);
        console.log("aedfs")
      } else {
        history.push(path);
      }
      console.log("DFvdfvjb", path);
    } else if (history.location.search.includes("/new-closing")) {
      // if (history.location.search.includes("guestuser=true")) {
      console.log("dfdf", history.location.search.includes("/new-closing"));
      history.push({
        // pathname: `/agent/new-closing/6422ea28bd737f49a7ad95ab&guestuser=true&agentid=64dca1d2d811be8c4229068c`,
        pathname: this._extractUrl(
          history.location.search.replace("/agent", "")
        ),
        state: { from: history.location.search },
      });
    }
    // else {
    //   this._checkAgentGuestUser();
    // }
    // }

    runOneSignal({
      // appId: "f0ca63f1-0f62-4417-95dd-0daee23e4d49", //live
      // appId: "a04c1362-f6ef-4154-b4f9-89a3e6d3576c", //dev
      appId: ONE_SIGNAL_APPID,
    });
  };

  _extractUrl = (url) => {
    const queryString = url.split("?")[1];
    const params = new URLSearchParams(queryString);
    const cameViaValue = params.get("cameVia");
    const remainingParams = queryString.replace(`cameVia=${cameViaValue}&`, "");
    return cameViaValue + "&" + remainingParams;
    // console.log(cameViaValue + "&" + remainingParams);
  };
  _extractAgentId(url) {
    const match = url.match(/[?&]agentid=([^&]+)/);
    return match && match[1];
  }

  _checkAgentGuestUser = async () => {
    let agentId = this._extractAgentId(history.location.search);
    try {
      let response = await checkAgentGuestUser(agentId);
      if (response?.isGuest) {
        console.log("age", agentId);
        historyWithForceRefresh.push(`/nna-signup/${agentId}`);
        // historyWithForceRefresh.push("/nna-signup/64dca1d2d811be8c4229068c");
      } else {
        history.push("/login");
      }
    } catch (error) {
      errorHandler(error);
    }
  };

  render() {
    return (
      <StripeProvider apiKey={STRIPE_API_KEY}>
        <Provider store={store}>
          <PersistGate persistor={persistor}>
            <BrowserRouter history={history}>
              <ScrollHandler />
              <FullPageLoader />

              <ToastContainer />

              <div className="wrapper wrapper-full-page" ref="fullPages">
                <Switch>
                  <PublicRoute
                    path="/login"
                    component={Login}
                    redirectRoute={"/login"}
                  />
                  <PublicRoute
                    path="/forgot-password"
                    component={ForgotPassword}
                    redirectRoute={"/login"}
                  />

                  <PublicRoute
                    path="/nna-signup/:id"
                    component={NnaAgentSignup}
                    redirectRoute={"/login"}
                  />
                  <PublicRoute
                    path="/signup"
                    component={SignUp}
                    redirectRoute={"/login"}
                  />
                  <PublicRoute
                    path={`/new-closing/:id`}
                    component={NewClosingAgentPublic}
                    redirectRoute={"/login"}
                  />
                  <PublicRoute
                    path={`/public-invoice-payment/:id`}
                    component={PublicInvoicePayment}
                    redirectRoute={"/login"}
                  />
                  <PublicRoute
                    path={`/public-confirmation`}
                    component={PublicConfirmation}
                    redirectRoute={"/login"}
                  />
                  <ProtectedRoute path="/agent" component={AgentLayout} />

                  <ProtectedRoute
                    path="/signingcompany"
                    component={CompanyLayout}
                  />
                  <Route path={`/agent-about/:id`} component={AgentAbout} />
                  <ProtectedRoute path="/client" component={clientLayout} />

                  <Route path="*" render={() => <Redirect to="/login" />} />
                </Switch>
              </div>
            </BrowserRouter>
          </PersistGate>
        </Provider>
      </StripeProvider>
    );
  }
}

export default App;
