import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  DropdownItem,
  DropdownMenu,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import {
  TemplateCustomizationBodyVariable,
  TemplateCustomizationSubjectVariable,
} from "../../../config";
import { deepClone, errorHandler } from "../../../helper-methods";
import TextEditor from "./TextEdittor";
import {
  agentCreateBulkEmailMessageTemplate,
  agentUpdateBulkEmailMessageTemplate,
} from "../../../http/http-calls";

let initialFields = {
  type: "Email",
  title: "",
  subject: "",
  body: "",
};

let initialIsDirty = {
  type: false,
  title: false,
  subject: false,
  body: false,
};

const AddTemplateModal = ({ isOpen, data, toggle, onSuccess }) => {
  const [emailFields, setEmailFields] = useState(deepClone(initialFields));
  const [errors, setErrors] = useState({});
  const [isDirty, setIsDirty] = useState(deepClone(initialIsDirty));
  const [isLoading, setIsLoading] = useState(false);
  const [isFocusTextEditor, setIsFocusTextEditor] = useState(false);

  const _closeModal = () => {
    _resetFields();
    toggle();
  };

  const _resetFields = () => {
    setEmailFields(deepClone(initialFields));
    setIsDirty(deepClone(initialIsDirty));
    setErrors({});
    setIsLoading(false);
    // console.log("reset",emailFields);
  };

  const _setFormFields = () => {
    const newEmailFields = { ...emailFields };
    const newIsDirty = { ...isDirty };

    if (data?.type) {
      newEmailFields["type"] = data?.type || "Email";
    }

    if (data?.title) {
      newEmailFields["title"] = data?.title || "";
    }
    if (data?.subject) {
      newEmailFields["subject"] = data?.subject || "";
    }
    if (data?.body) {
      newEmailFields["body"] = data?.body || "";
    }

    setEmailFields(newEmailFields);

    _validateForm({ newEmailFields, newIsDirty });
  };

  const _onChangeFormField = (fieldName, value) => {
    // console.log("dd", fieldName, value);
    const newEmailFields = { ...emailFields };
    const newIsDirty = { ...isDirty };

    newEmailFields[fieldName] = value;
    newIsDirty[fieldName] = true;

    if (fieldName === "type") {
      newEmailFields["body"] = "";
    }

    setEmailFields(newEmailFields);
    setIsDirty(newIsDirty);

    _validateForm({ newEmailFields, newIsDirty });
  };

  const _markAllFieldDirty = () => {
    return new Promise((resolve) => {
      const newIsDirty = { ...isDirty };
      Object.keys(newIsDirty).forEach((each) => {
        newIsDirty[each] = true;
      });

      setIsDirty(newIsDirty);
      resolve(newIsDirty);
    });
  };

  const _validateForm = ({ newEmailFields, newIsDirty }) => {
    console.log("123", newEmailFields, newIsDirty);
    return new Promise((resolve) => {
      let isFormValid = true;
      const newErrors = { ...errors };

      Object.keys(newEmailFields).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "title": {
              console.log("11", newEmailFields?.title);
              if (
                newEmailFields?.title &&
                newEmailFields?.title?.trim()?.length
              ) {
                if (
                  newEmailFields?.title?.trim()?.length < 2 ||
                  newEmailFields?.title?.trim()?.length > 100
                ) {
                  isFormValid = false;
                  newErrors[key] = "*Title should be 2-100 characters long";
                } else {
                  newIsDirty[key] = false;
                  delete newErrors[key];
                }
              } else {
                isFormValid = false;
                newErrors[key] = "*Required";
              }

              break;
            }

            case "subject": {
              console.log("11", newEmailFields?.subject);
              if (newEmailFields?.type === "Email") {
                if (
                  newEmailFields?.subject &&
                  newEmailFields?.subject?.trim()?.length
                )
                  if (
                    newEmailFields?.subject?.trim()?.length < 2 ||
                    newEmailFields?.subject?.trim()?.length > 500
                  ) {
                    isFormValid = false;
                    newErrors[key] = "*Subject should be 2-500 characters long";
                  } else {
                    newIsDirty[key] = false;
                    delete newErrors[key];
                  }
                else {
                  isFormValid = false;
                  newErrors[key] = "*Required";
                }
              } else {
                newIsDirty[key] = false;
                delete newErrors[key];
              }

              break;
            }

            case "body": {
              console.log("11", newEmailFields?.body);
              if (newEmailFields?.body?.replace(/<[^>]+>/g, "")?.trim()?.length)
                if (newEmailFields?.type === "Email") {
                  if (
                    newEmailFields?.body?.replace(/<[^>]+>/g, "")?.trim()
                      ?.length < 2 ||
                    newEmailFields?.body?.replace(/<[^>]+>/g, "")?.trim()
                      ?.length > 2000
                  ) {
                    isFormValid = false;
                    newErrors[key] = "*Body should be 2-2000 characters long";
                  } else {
                    newIsDirty[key] = false;
                    delete newErrors[key];
                  }
                } else {
                  if (
                    newEmailFields?.body?.replace(/<[^>]+>/g, "")?.trim()
                      ?.length < 2 ||
                    newEmailFields?.body?.replace(/<[^>]+>/g, "")?.trim()
                      ?.length > 160
                  ) {
                    isFormValid = false;
                    newErrors[key] = "*Body should be 2-160 characters long";
                  } else {
                    newIsDirty[key] = false;
                    delete newErrors[key];
                  }
                }
              else {
                isFormValid = false;
                newErrors[key] = "*Required";
              }

              break;
            }

            default: {
            }
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _handleOnCopy = (fieldName, text) => {
    // console.log("45", fieldName, text);
    _onChangeFormField(fieldName, `${emailFields[fieldName]} ${text}`);
  };

  const _onSubmit = async () => {
    console.log("submit func called...");
    try {
      setIsLoading(true);
      const newEmailFields = { ...emailFields };

      const newIsDirty = await _markAllFieldDirty();
      const isFormValid = await _validateForm({ newEmailFields, newIsDirty });

      if (!isFormValid) {
        setIsLoading(false);
        return; // form not valid -> return
      }
      const payload = {};

      if (!data?.id) {
        payload["type"] = emailFields?.type || "";
        payload["title"] = emailFields?.title?.trim() || "";
      }

      payload["body"] = emailFields?.body || "";

      if (emailFields?.type === "Email") {
        payload["subject"] = emailFields?.subject || "";
      }

      console.log("payload: ", payload);

      if (data?.id) {
        await agentUpdateBulkEmailMessageTemplate(data?.id, payload);
      } else {
        await agentCreateBulkEmailMessageTemplate(payload);
      }

      onSuccess();
      _closeModal();
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      errorHandler(err);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (data?.id) {
      _setFormFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} centered scrollable>
      <ModalHeader toggle={() => _closeModal()}>
        {data?.id ? "Edit" : "Add"} Template
      </ModalHeader>
      <ModalBody>
        <FormGroup className="floatingLabel">
          <div className="custom-select-wrapper">
            <Input
              type="select"
              value={emailFields?.type}
              onChange={(e) => _onChangeFormField("type", e.target.value)}
              disabled={data?.id}
            >
              <option value="Email">Email</option>
              <option value="Message">Message</option>
            </Input>
            <Label>Type</Label>
          </div>
        </FormGroup>
        <FormGroup className="floatingLabel">
          <Input
            placeholder=" "
            value={emailFields?.title}
            onChange={(e) => _onChangeFormField("title", e.target.value)}
            disabled={data?.id}
          />
          <Label>Title</Label>

          {errors?.title && (
            <div className="validation-error">{errors?.title}</div>
          )}
        </FormGroup>
        {emailFields?.type && emailFields?.type === "Email" ? (
          <div>
            <UncontrolledDropdown className="customDropdown rightSide">
              <DropdownToggle>
                Insert Variables{" "}
                <img
                  src={
                    require("../../../assets/img/arrow-dropdown.svg").default
                  }
                  alt="arrow"
                />
              </DropdownToggle>
              <DropdownMenu right>
                {TemplateCustomizationSubjectVariable?.map((item, index) => (
                  <>
                    <DropdownItem
                      key={index}
                      onClick={() => _handleOnCopy("subject", item)}
                    >
                      {item}
                    </DropdownItem>
                  </>
                ))}
              </DropdownMenu>
            </UncontrolledDropdown>
            <FormGroup className="floatingLabel">
              <Input
                placeholder=" "
                value={emailFields?.subject}
                onChange={(e) => _onChangeFormField("subject", e.target.value)}
              />
              <Label>Subject</Label>
              {errors?.subject && (
                <div className="validation-error">{errors?.subject}</div>
              )}
            </FormGroup>
          </div>
        ) : null}
        <div>
          <UncontrolledDropdown className="customDropdown rightSide">
            <DropdownToggle>
              Insert Variables{" "}
              <img
                src={require("../../../assets/img/arrow-dropdown.svg").default}
                alt="arrow"
              />
            </DropdownToggle>
            <DropdownMenu right>
              {TemplateCustomizationBodyVariable?.map((item, index) => (
                <>
                  <DropdownItem
                    key={index}
                    onClick={() => _handleOnCopy("body", item)}
                  >
                    {item}
                  </DropdownItem>
                </>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>

          {/* change this text editor to WYSIWYG editor */}
          {/* <FormGroup className="floatingLabel"> */}
          {/* <Input type="textarea" row={10} placeholder=" " /> */}
          <FormGroup
            className={`mb-0 floatingLabel reactQuill ${
              (emailFields?.body && emailFields?.body !== "<p><br></p>") ||
              isFocusTextEditor
                ? "valueAdded"
                : ""
            }`}
          >
            {emailFields?.type && emailFields?.type === "Email" ? (
              <TextEditor
                content={emailFields?.body}
                onChange={(e) => _onChangeFormField("body", e)}
                onFocus={() => setIsFocusTextEditor(true)}
                onBlur={() => setIsFocusTextEditor(false)}
              />
            ) : (
              <Input
                style={{ height: "150px" }}
                type="textarea"
                row={10}
                placeholder=" "
                value={emailFields?.body}
                onChange={(e) => _onChangeFormField("body", e.target.value)}
              />
            )}
            <Label>Body</Label>
            {errors?.body && (
              <div className="validation-error">{errors?.body}</div>
            )}
          </FormGroup>
          {/* </FormGroup> */}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" size="lg" outline onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          color="primary"
          size="lg"
          disabled={isLoading}
          onClick={() => _onSubmit()}
        >
          {isLoading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}
          {data?.id ? "Update" : "Save"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddTemplateModal;
