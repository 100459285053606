import React from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import CalendarToolbar from "./CalendarToolbar";

const localizer = momentLocalizer(moment);

const ReactBigCalendar = ({
  events,
  calendarView,
  onSelectEvent,
  loading = false,
  eventPropGetter,
  onView,
  onRangeChange,
  onNavigate,
}) => {
  return (
    // <Card className="card-calendar">
      <>
        <Calendar
          selectable
          localizer={localizer}
          events={events}
          messages={{ previous: "Prev" }}
          views={["week", "month", "day"]}
          scrollToTime={new Date(1970, 1, 1, 6)}
          defaultDate={new Date()}
          onSelectEvent={onSelectEvent}
          // onSelectSlot={onSelectSlot}
          eventPropGetter={eventPropGetter}
          view={calendarView}
          onView={onView}
          onRangeChange={onRangeChange}
          onNavigate={onNavigate}
          components={{
            toolbar: CalendarToolbar,
          }}
        />

        {loading && (
          <div className="table-overlay">
            <div>
              <i className="fa fa-spinner fa-spin " />
            </div>
          </div>
        )}
      </>
    // </Card>
  );
};

export default ReactBigCalendar;
