import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Label,
  FormGroup,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CustomInput,
  Collapse,
} from "reactstrap";
import {
  deepClone,
  showToast,
  getPhoneNumberFromBrackets,
  errorHandler,
} from "../../../helper-methods";
import { RegexConfig } from "../../../config/RegexConfig";
import { countryCodes } from "../../../config/country-codes";
import {
  createClientUser,
  updateClientUser,
  getClientUserById,
} from "../../../http/http-calls";
import { clientPermissionConfig } from "../../../config";

class ClientAddUserModal extends Component {
  state = {
    formFields: {
      name: {
        value: "",
        error: null,
        isDirty: false,
      },
      email: {
        value: "",
        error: null,
        isDirty: false,
      },
      phone: {
        value: "",
        error: null,
        isDirty: false,
      },
      phoneCountry: {
        value: "+1",
        error: null,
        isDirty: false,
      },
    },
    dashboard: {
      canUpdateClosingStatusOrScheduling: false,
      canEditOrderEntry: false,
      canReviewDocumentsOrOrderDetail: false,
      canCreateOrder: false,
    },
    connectCompanies: {
      canView: false,
      canAdd: false,
      canUpdate: false,
    },
    payment: {
      canView: false,
      canPay: false,
    },
    lenderClient: {
      canView: false,
      canAdd: false,
      canUpdate: false,
    },
    isOpenCollapse: "",
  };

  _resetModalState = () => {
    this.setState({
      isOpenCollapse: "",
      formFields: {
        name: {
          value: "",
          error: null,
          isDirty: false,
        },
        email: {
          value: "",
          error: null,
          isDirty: false,
        },
        phone: {
          value: "",
          error: null,
          isDirty: false,
        },
        phoneCountry: {
          value: "+1",
          isValid: false,
          isDirty: false,
        },
      },
      dashboard: {
        canUpdateClosingStatusOrScheduling: false,
        canEditOrderEntry: false,
        canReviewDocumentsOrOrderDetail: false,
        canCreateOrder: false,
      },
      connectCompanies: {
        canView: false,
        canAdd: false,
        canUpdate: false,
      },
      payment: {
        canView: false,
        canPay: false,
      },
      lenderClient: {
        canView: false,
        canAdd: false,
        canUpdate: false,
      },
      // isOpenCollapse: "",
    });
  };

  _closeModal = () => {
    this._resetModalState();
    this.props.toggle();
  };

  componentDidUpdate(previousProps, previousState) {
    const { isOpen, data } = this.props;

    if (isOpen && data && previousProps.isOpen !== isOpen) {
      this._getClientUserById(data.id);
    }
  }

  _getClientUserById = (id) => {
    this.setState({ loading: true });

    getClientUserById(id)
      .then((res) => {
        this._setFormData(res?.user);
      })
      .catch((error) => {
        errorHandler(error);
        this._closeModal();
      });
  };

  _setFormData = (data) => {
    const { formFields } = deepClone(this.state);

    formFields["name"].value = data.name.full;

    formFields["phone"].value = data.phone
      ? getPhoneNumberFromBrackets(data.phone)
      : "";

    formFields["email"].value = data.email ? data.email : "";

    this.setState({
      loading: false,
      formFields,
      dashboard: {
        canUpdateClosingStatusOrScheduling:
          data.permissionSettings.dashboard.canUpdateClosingStatusOrScheduling,
        canEditOrderEntry: data.permissionSettings.dashboard.canEditOrderEntry,
        canReviewDocumentsOrOrderDetail:
          data.permissionSettings.dashboard.canReviewDocumentsOrOrderDetail,
        canCreateOrder: data.permissionSettings.dashboard.canCreateOrder,
      },
      connectCompanies: {
        canView: data.permissionSettings.connectCompanies.canView,
        canAdd: data.permissionSettings.connectCompanies.canAdd,
        canUpdate: data.permissionSettings.connectCompanies.canUpdate,
      },
      payment: {
        canView: data.permissionSettings.payment.canView,
        canPay: data.permissionSettings.payment.canPay,
      },
      lenderClient: {
        canView: data.permissionSettings.lenderClient.canView,
        canAdd: data.permissionSettings.lenderClient.canAdd,
        canUpdate: data.permissionSettings.lenderClient.canUpdate,
      },
    });
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields } = this.state;
    formFields[fieldName].value = value;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // Validation
      this._validateForm();
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty) {
          switch (key) {
            case "name": {
              if (formFields[key]?.value?.trim().length) {
                if (
                  formFields[key]?.value?.trim().length > 1 &&
                  /^[0-9!@#$%^&*()_+\-=\\[\]{};':"\\|,.<>\\/?]*$/.test(
                    //eslint-disable-line
                    String(formFields[key]?.value).toLowerCase()
                  )
                ) {
                  formFields[key].error =
                    "*Name containing only number and special character are not allowed";
                  isFormValid = false;
                  formFields[key].isDirty = true;
                } else {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "email": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                if (
                  RegexConfig.email.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "phone": {
              if (formFields[key].value.length) {
                if (
                  RegexConfig.phone.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  isFormValid = false;
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Phone Number";
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            default: {
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _saveUserData = async () => {
    await this._markAllFieldDirty();

    const isFormValid = await this._validateForm();

    if (!isFormValid) {
      return;
    }

    this.setState({ loading: true });

    const { formFields } = deepClone(this.state);
    const splitName = formFields.name.value.trim().split(" ");
    const countryDialCode = countryCodes.find(
      (obj) => obj.dial_code === formFields.phoneCountry.value
    )?.code;

    let data = {
      name: {
        first: splitName.slice(0, 1).join(" "),
        last: splitName[1] ? splitName.slice(1).join(" ") : "",
      },
      email: formFields.email.value,
      phone: `(${formFields.phoneCountry.value})${formFields.phone.value}`,
      phoneCountry: countryDialCode,
      permissionSettings: {
        dashboard: this.state.dashboard,
        connectCompanies: this.state.connectCompanies,
        payment: this.state.payment,
        lenderClient: this.state.lenderClient,
      },
    };

    this._callAddOrEditApi(data);
  };

  _callAddOrEditApi = (payload) => {
    if (this.props.data) {
      this._updateClientUser(payload, this.props.data.id);
    } else {
      this._createClientUser(payload);
    }
  };

  _createClientUser = (payload) => {
    createClientUser(payload)
      .then((res) => {
        showToast("Team member has been added", "success");
        this.props.resetTable();
        this._closeModal();
        this.setState({ loading: false });
      })
      .catch((error) => {
        errorHandler(error);
        this.setState({ loading: false });
      });
  };

  _updateClientUser = (payload, id) => {
    updateClientUser({ id, payload })
      .then((res) => {
        showToast("Team member has been updated", "success");
        this.props.resetTable();
        this._closeModal();
        this.setState({ loading: false });
      })
      .catch((error) => {
        errorHandler(error);
        this.setState({ loading: false });
      });
  };

  _onChangePermission = (key, each, value) => {
    console.log("az", key, each, value);
    this.setState({
      [key]: {
        ...this.state[key],
        [each]: value,
      },
    });
  };
  _handleCollapse = (value) => {
    console.log("value", value);
    this.setState({
      isOpenCollapse: this.state.isOpenCollapse !== value ? value : "",
    });
  };
  _renderPermission = () => {
    return clientPermissionConfig.map((each, index) => {
      return (
        <>
          <div
            className={`permissionWrapper ${
              this.state.isOpenCollapse === each.value ? "open" : ""
            }`}
          >
            <div
              onClick={() => this._handleCollapse(each.value)}
              className="permissionHead"
            >
              <i
                className={`fa fa-${
                  this.state.isOpenCollapse ? "minus" : "plus"
                }`}
              />

              <span>{each.label}</span>
            </div>
            <Collapse
              className="permissionBody"
              isOpen={this.state.isOpenCollapse === each.value}
            >
              {each.permissions.map((permission, permissionIndex) => {
                return (
                  <>
                    <CustomInput
                      type="checkbox"
                      checked={this.state[each.value][permission.value]}
                      onChange={(e) =>
                        this._onChangePermission(
                          this.state.isOpenCollapse,
                          permission.value,
                          e.target.checked
                        )
                      }
                      id={
                        each.value + permission.value + index + permissionIndex
                      }
                      label={permission.label}
                    />

                    {/* <FormGroup>
                      <Input
                        type="checkbox"
                        checked={this.state[each.value][permission.value]}
                        onChange={(e) =>
                          this._onChangePermission(
                            this.state.isOpenCollapse,
                            permission.value,
                            e.target.checked
                          )
                        }
                      />{" "}
                      <Label>{permission.label}</Label>
                    </FormGroup> */}
                  </>
                );
              })}
            </Collapse>
          </div>
        </>
      );
    });
  };

  render() {
    const { formFields, loading } = deepClone(this.state);
    console.log("state", this.state);
    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._closeModal()}
        scrollable
        centered
      >
        <ModalHeader toggle={() => this._closeModal()}>
          {this.props.data ? "Update" : "Add"} Team Members
        </ModalHeader>
        <ModalBody>
          <FormGroup className="floatingLabel">
            <Input
              type="text"
              placeholder=" "
              value={formFields.name.value}
              onChange={(e) => this._onChangeFormField("name", e.target.value)}
            />
            <Label>Name</Label>
            {formFields.name.error && (
              <div className="validation-error">{formFields.name.error}</div>
            )}
          </FormGroup>
          <FormGroup className="floatingLabel">
            <Input
              type="email"
              placeholder=" "
              value={formFields.email.value}
              onChange={(e) => this._onChangeFormField("email", e.target.value)}
            />
            <Label>Email</Label>
            {formFields.email.error && (
              <div className="validation-error">{formFields.email.error}</div>
            )}
          </FormGroup>
          <FormGroup className="floatingLabel withInputGroup">
            <InputGroup>
              <InputGroupAddon addonType="prepend">
                <InputGroupText>+1</InputGroupText>
              </InputGroupAddon>
              <Input
                type="text"
                placeholder=" "
                value={formFields.phone.value}
                onChange={(e) =>
                  this._onChangeFormField("phone", e.target.value)
                }
              />
              <Label>Phone</Label>
            </InputGroup>
            {formFields.phone.error && (
              <div className="validation-error">{formFields.phone.error}</div>
            )}
          </FormGroup>
          <Label>Permissions</Label>
          {this._renderPermission()}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            outline
            size="lg"
            onClick={() => this._closeModal()}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            size="lg"
            disabled={loading}
            onClick={() => this._saveUserData()}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null}{" "}
            {this.props.data ? "Update" : "Invite"}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ClientAddUserModal;
