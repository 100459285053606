import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { copyToClipboard, errorHandler } from "../../../../helper-methods";
import { saveDomainDetailForAgentWebsite } from "../../../../http/http-calls";
import { RegexConfig } from "../../../../config/RegexConfig";

const initalFormFields = {
  domainProvider: "",
  domainName: "",
  status: "",
  isAutoGeneratedAwsRoute53: null,
};
const initalIsDirty = {
  domainProvider: false,
  domainName: false,
};

const BuyOwnDomain = ({ agentWebsiteDetails, getAgentWebsiteDetails }) => {
  const [formFields, setFormFields] = useState(initalFormFields);
  const [isDirty, setIsDirty] = useState(initalIsDirty);
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  // const [agentWebsiteDetails, setAgentWebsiteDetails] = useState({});

  const _validateFormfields = ({ newFormFields, newIsDirty }) => {
    return new Promise((resolve) => {
      let isFormValid = true;
      const newErrors = {};

      Object.keys(newFormFields)?.forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "domainName": {
              if (newIsDirty[key]) {
                if (newFormFields[key]?.trim()?.length) {
                  if (
                    // newFormFields[key].length >= 2 &&
                    // newFormFields[key].length <= 50
                    RegexConfig.domainName.test(newFormFields[key])
                  ) {
                    newErrors[key] = null;
                    newIsDirty[key] = false;
                  } else {
                    newErrors[key] = "*Invalid domain name";
                    isFormValid = false;
                  }
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }

            case "domainProvider": {
              if (newIsDirty[key]) {
                if (newFormFields[key]?.trim()?.length) {
                  newErrors[key] = null;
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Required";
                  isFormValid = false;
                }
              }
              break;
            }

            default:
          }
        }
      });

      setIsDirty((prev) => ({
        ...prev,
        ...newIsDirty,
      }));
      setErrors((prev) => ({
        ...prev,
        ...newErrors,
      }));

      console.log({ newErrors });
      resolve(isFormValid);
    });
  };

  const _setForm = (data) => {
    setFormFields({
      domainName: data?.domainName,
      domainProvider: data?.domainProvider,
      status: data?.websiteDeployementProcessStatus,
    });
  };

  const _onChangeFormFields = (key, value, type) => {
    const newFormFields = { ...formFields };

    newFormFields[key] = value;

    setFormFields(newFormFields);
  };

  const _onBlurFormFields = (key) => {
    const newIsDirty = { ...isDirty };
    const newFormFields = { ...formFields };

    newIsDirty[key] = true;
    setIsDirty(newIsDirty);

    _validateFormfields({ newFormFields, newIsDirty });
  };

  // const _getAgentWebsiteDetails = async () => {
  //   try {
  //     const res = await getAgentWebsiteDetail();
  //     console.log({ res });

  //     if (res?.agentWebsite) {
  //       setAgentWebsiteDetails(res?.agentWebsite);
  //       _setForm(res?.agentWebsite);
  //     }
  //   } catch (err) {
  //     errorHandler(err);
  //   }
  // };

  const _onSaveDomainDetails = async () => {
    try {
      setLoading(true);
      const newFormFields = { ...formFields };
      const newIsDirty = { ...isDirty };

      newIsDirty["domainName"] = true;
      newIsDirty["domainProvider"] = true;

      console.log({ newIsDirty });
      const isFormValid = await _validateFormfields({
        newFormFields,
        newIsDirty,
      });
      console.log({ isFormValid });

      if (!isFormValid) {
        // errorHandler("Please enter all required fields");
        setLoading(false);
        return false;
      }

      const payload = {
        domainName: formFields?.domainName?.toLocaleLowerCase(),
        domainProvider: formFields?.domainProvider,
        isAutoGeneratedAwsRoute53: false,
      };

      await saveDomainDetailForAgentWebsite(payload);

      getAgentWebsiteDetails();
      setLoading(false);
    } catch (err) {
      errorHandler(err);
      console.log({ err });
      setLoading(false);
    }
  };

  useEffect(() => {
    // getAgentWebsiteDetails();
    if (agentWebsiteDetails) {
      _setForm(agentWebsiteDetails);
    }
  }, [agentWebsiteDetails]);

  return (
    <>
      <Row className="mt-3">
        <Col md={4}>
          <FormGroup className="floatingLabel">
            <div className="custom-select-wrapper">
              <Input
                type="select"
                value={formFields?.domainProvider || ""}
                onChange={(e) =>
                  _onChangeFormFields("domainProvider", e.target.value)
                }
                disabled={
                  agentWebsiteDetails?.isWebsiteLaunched ||
                  agentWebsiteDetails?.websiteDeployementProcessStatus !==
                    "Pending"
                }
                onBlur={() => _onBlurFormFields("domainProvider")}
              >
                <option value="">Select</option>
                <option value="goDaddy">Godaddy</option>
                <option value="hostinger">Hostinger</option>
                <option value="nameCheap">NameCheap</option>
                <option value="awsRoute53">AWS Route 53</option>
              </Input>
              <Label>Domain Provider</Label>
              {errors?.domainProvider ? (
                <p className="validation-error">{errors?.domainProvider}</p>
              ) : null}
            </div>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup className="floatingLabel">
            <Input
              placeholder=" "
              value={formFields?.domainName || ""}
              disabled={
                agentWebsiteDetails?.isWebsiteLaunched ||
                agentWebsiteDetails?.websiteDeployementProcessStatus !==
                  "Pending"
              }
              onChange={(e) =>
                _onChangeFormFields("domainName", e.target.value)
              }
              onBlur={() => _onBlurFormFields("domainName")}
            />
            <Label>Domain Name</Label>
            {errors?.domainName ? (
              <p className="validation-error">{errors?.domainName}</p>
            ) : null}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup className="floatingLabel">
            <div className="custom-select-wrapper">
              <Input type="select" disabled="true" value={formFields?.status}>
                <option value={"Pending"}>Pending</option>
                <option value={"Completed"}>Completed</option>
              </Input>
              <Label>Status</Label>
            </div>
          </FormGroup>
        </Col>
      </Row>
      {agentWebsiteDetails?.websiteDeployementProcessStatus === "Pending" ? (
        <div className="text-center">
          <Button
            color="primary"
            size="md"
            className="mb-3"
            onClick={() => _onSaveDomainDetails()}
            disabled={loading}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-1" /> : null} Save
          </Button>
        </div>
      ) : null}

      <p className="mb-2">Follow these steps to verify your domain</p>

      <div className="ownDomainInfo">
        <p>
          Step-1: Go to DNS provider and do the following record want to watch
          demo video?
          <Link className="ml-2">Click here</Link>
        </p>
        <div className="hostingProvider">
          <h2>Common Provider</h2>
          <ul>
            <li>
              <a
                href="https://closewise-nna.surge.sh/agent/topic/655c56d4e5b35da47df6aa03"
                target="_blank"
                rel="noreferrer"
              >
                Godaddy
              </a>
            </li>
            <li>
              <a
                href="https://closewise-nna.surge.sh/agent/topic/655c5660e5b35da47df6a726"
                target="_blank"
                rel="noreferrer"
              >
                Namechep
              </a>
            </li>
            <li>
              <a
                href="https://closewise-nna.surge.sh/agent/topic/655c5615e5b35da47df6a5cd"
                target="_blank"
                rel="noreferrer"
              >
                AWS Route 53
              </a>
            </li>
          </ul>
        </div>
        <div className="settingrecordList">
          <h1>Setting | Record List</h1>
          <div className="recordItems">
            <h1>Certificate Record</h1>
            <ul>
              <li>
                <Label>Name</Label>
                <div className="value">
                  <div className="nameCode">
                    {agentWebsiteDetails?.cNameRecordForCertificate?.name}
                  </div>
                </div>
              </li>
              <li>
                <Label>Value</Label>
                {agentWebsiteDetails?.cNameRecordForCertificate?.value ? (
                  <div className="value">
                    <span>
                      {agentWebsiteDetails?.cNameRecordForCertificate?.value ||
                        ""}
                    </span>
                    <Button
                      color="link"
                      disabled={
                        !agentWebsiteDetails?.cNameRecordForCertificate?.value
                      }
                      onClick={() =>
                        copyToClipboard(
                          agentWebsiteDetails?.cNameRecordForCertificate?.value
                        )
                      }
                    >
                      <i className="fa fa-clone"></i>
                    </Button>
                  </div>
                ) : null}
              </li>
            </ul>
          </div>
          <div className="recordItems">
            <h1>CloudFront Record</h1>
            <ul>
              <li>
                <Label>Name</Label>
                <div className="value">
                  <div className="nameCode">
                    {agentWebsiteDetails?.cNameRecordForCloufront?.name}
                  </div>
                </div>
              </li>
              <li>
                <Label>Value</Label>
                {agentWebsiteDetails?.cNameRecordForCloufront?.value ? (
                  <div className="value">
                    <span>
                      {agentWebsiteDetails?.cNameRecordForCloufront?.value ||
                        ""}
                    </span>
                    <Button
                      color="link"
                      disabled={
                        !agentWebsiteDetails?.cNameRecordForCloufront?.value
                      }
                      onClick={() =>
                        copyToClipboard(
                          agentWebsiteDetails?.cNameRecordForCloufront?.value
                        )
                      }
                    >
                      <i className="fa fa-clone"></i>
                    </Button>
                  </div>
                ) : null}
              </li>
              {/* <li>
                <Label>Status</Label>
                {agentWebsiteDetails?.cNameRecordForCloufront?.status ===
                "Pending" ? (
                  <div className="value text-danger">Unverified</div>
                ) : agentWebsiteDetails?.cNameRecordForCloufront?.status ===
                  "Issued" ? (
                  <div className="value text-success">Verified</div>
                ) : (
                  <div className="value text-danger">Unverified</div>
                )}
              </li> */}
            </ul>
          </div>
        </div>
      </div>

      {!agentWebsiteDetails?.isWebsiteLaunched ? (
        <div className="text-center">
          <Button
            color="primary"
            size="md"
            onClick={() => getAgentWebsiteDetails()}
          >
            Verify
          </Button>
        </div>
      ) : null}
    </>
  );
};

export default BuyOwnDomain;
