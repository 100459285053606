import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  CustomInput,
  Label,
  Input,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  UncontrolledTooltip,
} from "reactstrap";
import CreatableSelect from "react-select/creatable";
import { showToast } from "../../../helper-methods";

const initialFields = {
  automationType: "off",
  autoSendBidRequest: false,
  bidNotificationQueueRateInSecond: 30,
  bidQueueLimit: 15,
  autoAssignCounterResponse: true,
};

const OrderAutomationSettings = (props) => {
  const [options, setOptions] = useState({
    time: [
      { value: "5", label: "5" },
      { value: "10", label: "10" },
      // { value: "15", label: "15" },
      { value: "20", label: "20" },
      { value: "30", label: "30" },
      { value: "45", label: "45" },
      { value: "60", label: "60" },
      { value: "120", label: "120" },
      { value: "240", label: "240" },
      { value: "480", label: "480" },
    ],
    fee: [
      { value: "5", label: "5" },
      { value: "10", label: "10" },
      { value: "15", label: "15" },
      { value: "20", label: "20" },
      { value: "25", label: "25" },
      { value: "30", label: "30" },
      { value: "35", label: "35" },
      { value: "40", label: "40" },
      { value: "45", label: "45" },
      { value: "50", label: "50" },
    ],
  });

  const [biddingForm, setBiddingForm] = useState(initialFields);

  const [assignCounterResponses, setAssignCounterResponses] = useState([
    {
      _id: Date.now(),
      time: { value: "5", label: "5" },
      fee: { value: "5", label: "5" },
    },
  ]);

  const _addAssignCounterResponse = () => {
    const newAssignCounterResponses = [...assignCounterResponses];
    newAssignCounterResponses.push({
      _id: Date.now(),
      time: { value: "", isDirty: false, error: null },
      fee: { value: "", isDirty: false, error: null },
    });
    setAssignCounterResponses(newAssignCounterResponses);
  };

  const _removeAssignCounterResponse = (index) => {
    const newAssignCounterResponses = [...assignCounterResponses];
    if (newAssignCounterResponses.length > 1) {
      newAssignCounterResponses.splice(index, 1);
      setAssignCounterResponses(newAssignCounterResponses);
    }
  };

  const _handleChange = (object, actionMeta, key, index) => {
    if (actionMeta.action === "create-option" && object.__isNew__) {
      if (key === "fee") {
        if (
          isNaN(object.value) ||
          (object.value.includes(".") && object.value.split(".")[1].length > 2)
        ) {
          showToast("Invalid value", "error");
          return;
        }
      }
      if (key === "time") {
        if (isNaN(object.value) || object.value.includes(".")) {
          showToast("Invalid value", "error");
          return;
        }
      }
      const newOptions = { ...options };
      newOptions[key].push(object);
      setOptions(newOptions);
    }
    const newAssignCounterResponses = [...assignCounterResponses];
    delete object.__isNew__;
    // newAssignCounterResponses[index][key].value = object;
    newAssignCounterResponses[index][key] = object;
    setAssignCounterResponses(newAssignCounterResponses);
  };

  const _handleBiddingFormChange = (fieldName, value) => {
    let newBiddingForm = { ...biddingForm };
    // let newIsDirty={...isDirty}
    newBiddingForm[fieldName] = value;
    // newIsDirty[key] = true;
    setBiddingForm(newBiddingForm);
    if (fieldName === "autoSendBidRequest" && value === true) {
      // _addAssignCounterResponse();
      setAssignCounterResponses([
        {
          _id: Date.now(),
          time: { value: "5", label: "5" },
          fee: { value: "5", label: "5" },
        },
      ]);
    }
    // setIsDirty(newIsDirty);
    // _validateForm(newEmailData, newIsDirty);
  };

  const _handleSubmit = () => {
    let assignCounterResponseObject = assignCounterResponses.map((item) => ({
      afterMinutes: Number(item.time.value),
      feeIncrement: Number(item.fee.value),
    }));

    const payload = {
      bidAutomationSetting: {
        automationType: biddingForm.automationType,
        autoSendBidRequest: biddingForm.autoSendBidRequest,
        bidNotificationQueueRateInSecond:
          biddingForm.bidNotificationQueueRateInSecond,
        bidQueueLimit: biddingForm.bidQueueLimit,
        assignCounterResponse: assignCounterResponseObject,
        autoAssignCounterResponse: biddingForm.autoAssignCounterResponse,
      },
    };

    props.handleOnSubmit(payload);
  };
  useEffect(() => {
    let assignCounterResponseObject =
      props?.bidAutomationSetting?.assignCounterResponse?.map((item) => ({
        _id: item._id,
        time: { value: item.afterMinutes, label: item.afterMinutes },
        fee: { value: item.feeIncrement, label: item.feeIncrement },
      }));

    setBiddingForm({
      automationType: props?.bidAutomationSetting?.automationType
        ? props.bidAutomationSetting?.automationType
        : "off",
      autoSendBidRequest: props?.bidAutomationSetting?.autoSendBidRequest
        ? props.bidAutomationSetting?.autoSendBidRequest
        : false,
      bidNotificationQueueRateInSecond: props?.bidAutomationSetting
        ?.bidNotificationQueueRateInSecond
        ? props.bidAutomationSetting?.bidNotificationQueueRateInSecond
        : 30,
      bidQueueLimit: props?.bidAutomationSetting?.bidQueueLimit
        ? props.bidAutomationSetting?.bidQueueLimit
        : 15,
      autoAssignCounterResponse:
        props?.bidAutomationSetting?.autoAssignCounterResponse !== undefined
          ? props.bidAutomationSetting?.autoAssignCounterResponse
          : true,
      // assignCounterResponse: props.bidAutomationSetting?.assignCounterResponse,
      // assignCounterResponse: assignCounterResponseObject,
    });
    setAssignCounterResponses(assignCounterResponseObject);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <Card>
      <CardBody>
        <Row>
          <Col md={12}>
            <FormGroup>
              <Label style={{ fontWeight: 600, marginBottom: 12 }}>
                Auto Send Bid Request
              </Label>
              <div>
                <CustomInput
                  type="radio"
                  id="on"
                  name="name"
                  label="On"
                  inline
                  // defaultChecked
                  checked={biddingForm.autoSendBidRequest === true}
                  onChange={(event) =>
                    _handleBiddingFormChange("autoSendBidRequest", true)
                  }
                />
                <CustomInput
                  type="radio"
                  id="off"
                  name="name"
                  label="Off"
                  inline
                  checked={biddingForm.autoSendBidRequest === false}
                  onChange={(event) =>
                    _handleBiddingFormChange("autoSendBidRequest", false)
                  }
                />
              </div>
            </FormGroup>
          </Col>
          <Col md={12}>
            <Label style={{ fontWeight: 600, marginBottom: 12 }}>
              Auto Assign Options
            </Label>
            <div className="automationPoint">
              <div>
                <CustomInput
                  type="radio"
                  name="point"
                  id="Disabled"
                  label="Disabled"
                  // defaultChecked
                  checked={biddingForm.automationType === "off"}
                  onChange={(event) =>
                    _handleBiddingFormChange("automationType", "off")
                  }
                />
                <img
                  src={require("../../../assets/img/info.svg").default}
                  id="occur"
                  className="circleInfo"
                  alt="info"
                />
                <UncontrolledTooltip placement="top" target="occur">
                  no auto assign will occur
                </UncontrolledTooltip>
              </div>
              <div>
                <CustomInput
                  type="radio"
                  name="point"
                  id="AllAgents"
                  label="All Agents"
                  checked={biddingForm.automationType === "all"}
                  onChange={(event) =>
                    _handleBiddingFormChange("automationType", "all")
                  }
                />

                <img
                  src={require("../../../assets/img/info.svg").default}
                  id="agent"
                  className="circleInfo"
                  alt="info"
                />
                <UncontrolledTooltip placement="top" target="agent">
                  Auto assign first agent to reply yes
                </UncontrolledTooltip>
              </div>
              <div>
                <CustomInput
                  type="radio"
                  name="point"
                  id="preferred"
                  label="Preferred"
                  checked={biddingForm.automationType === "favourites"}
                  onChange={(event) =>
                    _handleBiddingFormChange("automationType", "favourites")
                  }
                />

                <img
                  src={require("../../../assets/img/info.svg").default}
                  id="preferredInfo"
                  className="circleInfo"
                  alt="info"
                />
                <UncontrolledTooltip placement="top" target="preferredInfo">
                  Auto assign first preferred agent to yes reply
                </UncontrolledTooltip>
              </div>
            </div>
          </Col>

          {/* {biddingForm.automationType !== "off" && ( */}
          <>
            {" "}
            <Col md={6}>
              <Label style={{ fontWeight: 600, marginBottom: 10 }}>
                Bid Notification Queue Rate
              </Label>
              <FormGroup>
                <Label className="mr-4">Every</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    value={biddingForm.bidNotificationQueueRateInSecond}
                    onChange={(event) =>
                      _handleBiddingFormChange(
                        "bidNotificationQueueRateInSecond",
                        event.target.value
                      )
                    }
                  >
                    <option value="30">30 Sec</option>
                    <option value="60">60 Sec</option>
                    {/* <option value="70">70 Sec</option> */}
                    <option value="300">5 min</option>
                    <option value="600">10 min</option>
                  </Input>
                </div>
              </FormGroup>
            </Col>
            <Col md={6}>
              <Label
                style={{
                  fontWeight: 600,
                  marginBottom: 10,
                }}
              >
                Bid Que Limit
              </Label>
              <FormGroup>
                <Label className="mr-4">Select</Label>
                <div className="custom-select-wrapper">
                  <Input
                    type="select"
                    value={biddingForm.bidQueueLimit}
                    onChange={(event) =>
                      _handleBiddingFormChange(
                        "bidQueueLimit",
                        event.target.value
                      )
                    }
                  >
                    <option value="15">15 Agents</option>
                    <option value="25">25 Agents</option>
                    <option value="35">35 Agents</option>
                  </Input>
                </div>
              </FormGroup>
            </Col>
          </>
          {/* // )} */}
          {/* {biddingForm.autoSendBidRequest && ( */}
          <Col md={12}>
            <Label
              style={{
                fontWeight: 600,
                marginBottom: 10,
              }}
            >
              Assign Counter Response
            </Label>

            <div>
              <CustomInput
                type="radio"
                id="autoAssignCounterResponseOn"
                name="autoAssignCounterResponse"
                label="On"
                inline
                checked={biddingForm?.autoAssignCounterResponse === true}
                onChange={(event) =>
                  _handleBiddingFormChange("autoAssignCounterResponse", true)
                }
              />
              <CustomInput
                type="radio"
                id="autoAssignCounterResponseOff"
                name="autoAssignCounterResponse"
                label="Off"
                inline
                checked={biddingForm?.autoAssignCounterResponse === false}
                onChange={(event) =>
                  _handleBiddingFormChange("autoAssignCounterResponse", false)
                }
              />
            </div>
          </Col>
        </Row>
        {biddingForm?.autoAssignCounterResponse && (
          <>
            {assignCounterResponses?.length > 0 &&
              assignCounterResponses.map((each, index) => (
                <Row className="align-items-center mt-3" key={each._id + index}>
                  <Col md="5">
                    <FormGroup
                      className={`floatingLabel withInputGroup createSelect`}
                      style={{ zIndex: 9 }}
                    >
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>Min</InputGroupText>
                        </InputGroupAddon>
                        <CreatableSelect
                          onChange={(object, actionMeta) =>
                            _handleChange(object, actionMeta, "time", index)
                          }
                          options={options.time}
                          value={each.time}
                          className="reactselect"
                        />
                        <Label style={{ zIndex: "3" }}>After</Label>
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Col md="5">
                    <FormGroup
                      className={`floatingLabel withInputGroup createSelect`}
                    >
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>$</InputGroupText>
                        </InputGroupAddon>
                        <CreatableSelect
                          onChange={(object, actionMeta) =>
                            _handleChange(object, actionMeta, "fee", index)
                          }
                          options={options.fee}
                          value={each.fee}
                          className="reactselect "
                        />
                        <Label style={{ zIndex: 2 }}>
                          With fee increase of
                        </Label>
                      </InputGroup>
                    </FormGroup>
                  </Col>

                  <Col md="2">
                    {assignCounterResponses.length > 1 ? (
                      <Button
                        color="link"
                        onClick={() => _removeAssignCounterResponse(index)}
                      >
                        <img
                          src={
                            require("../../../assets/img/removeItem.svg")
                              .default
                          }
                          alt="delete icon"
                        />
                      </Button>
                    ) : null}

                    {assignCounterResponses.length - 1 === index ? (
                      <Button
                        color="link"
                        onClick={() => _addAssignCounterResponse()}
                      >
                        <img
                          src={
                            require("../../../assets/img/addItem.svg").default
                          }
                          alt="plus icon"
                        />
                      </Button>
                    ) : null}
                  </Col>
                </Row>
              ))}
          </>
        )}
        <div className="text-center my-3">
          <Button
            color="primary"
            onClick={() => _handleSubmit()}
            disabled={props.loading}
          >
            Submit
          </Button>
        </div>
      </CardBody>
    </Card>
  );
};

export default OrderAutomationSettings;
