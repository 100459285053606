import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  Input,
  FormGroup,
  Form,
  Col,
  Row,
  Table,
} from "reactstrap";
import {
  capitalize,
  deepClone,
  errorHandler,
  showToast,
} from "../../../helper-methods";
import ProductType from "./ProductType";
import { loanTypeConfig } from "../../../config";

class ScanBackDocumentModal extends Component {
  state = {
    // loading: false,
    formFields: {
      client: {
        value: "",
        isDirty: false,
        isValidate: true,
      },
      productType: {
        value: {},
        isDirty: false,
        isValidate: true,
      },
      loanCategories: {
        value: [],
        error: null,
        isDirty: false,
        isValidate: false,
      },
    },
    scanbackLoading: {
      deleteLoading: false,
    },
  };

  _manageLoading = (loaderName, value) => {
    const { scanbackLoading } = deepClone(this.state);
    scanbackLoading[loaderName] = value;
    this.setState({ scanbackLoading });
  };

  _closeModal = (action) => {
    console.log("in _closeModal");
    // this._resetModalState();
    this.props.toggle();
  };
  _handleOnChange = (fieldName, value) => {
    const { formFields } = deepClone(this.state);

    formFields[fieldName].value = value;
    this.setState({ formFields });
  };
  // componentDidMount = () => {
  //   let LoanType = [
  //     "Refinance",
  //     "HomeEquity",
  //     "AttorneyPhone",
  //     "Purchase",
  //     "LoanMod",
  //     "ReverseMtg",
  //     "HybridESign",
  //     "DeedInLieu",
  //     "SingleDoc",
  //     "LoanApplication",
  //   ];

  //   let options = LoanType.map((item) => ({
  //     value: item,
  //     label: item.replace(/([A-Z])/g, " $1").trim(),
  //   }));
  //   options.splice(0, 0, { value: "", label: "Select" });
  //   this.setState({ loanType: options });
  // };

  componentDidUpdate = async (previousProps, previousState) => {
    const { isOpen, data } = this.props;
    if (isOpen && previousProps.isOpen !== isOpen) {
      console.log("data", data);
      // this.setState({ type: data.type });
      this._reset();
    }
  };

  _reset = () => {
    this.setState({
      formFields: {
        client: {
          value: "",
          isDirty: false,
          isValidate: true,
        },
        productType: {
          value: {},
          isDirty: false,
          isValidate: true,
        },
        loanCategories: {
          value: [],
          error: null,
          isDirty: false,
          isValidate: false,
        },
      },
    });
  };

  _createSettings = () => {
    const { formFields } = deepClone(this.state);

    let payload = {
      scanBackDocument: {
        scanBackDocumentDetail: {
          _client: formFields.client.value,
          productType: formFields?.productType?.value?.value,
          productCategories: formFields?.productType?.value?.category,
        },
      },
    };

    if (
      formFields?.client?.value !== "" &&
      formFields?.productType?.value?.value
    ) {
      this.props.handleSubmit(payload);
    } else {
      showToast("Please Select Client and Product Type", "error");
    }
  };

  _deleteScanBackDocument = async (scanBackDocumentId) => {
    try {
      this._manageLoading("deleteLoading", true);

      await this.props.deleteScanBackDocument(scanBackDocumentId);

      this._manageLoading("deleteLoading", false);
    } catch (err) {
      this._manageLoading("deleteLoading", false);
      errorHandler(err);
    }
  };

  render() {
    const { formFields, scanbackLoading } = deepClone(this.state);

    const { client, loading } = this.props;

    return (
      <Modal
        isOpen={this.props.isOpen}
        toggle={() => this._closeModal()}
        className="modal-dialog-centered"
      >
        <ModalHeader toggle={() => this._closeModal()}>
          Scan Back Document
        </ModalHeader>
        <ModalBody>
          <Form>
            <Row form>
              <Col sm="12">
                <FormGroup className="floatingLabel ">
                  <div className="custom-select-wrapper">
                    <Input
                      type="select"
                      value={formFields.client.value}
                      name="client"
                      onChange={(event) =>
                        this._handleOnChange("client", event.target.value)
                      }
                    >
                      <option value="">Select</option>
                      {client?.map((obj) => (
                        <option key={obj.id} value={obj.id}>
                          {/* {obj.name?.full
                          ? capitalize(obj.name.full)
                        : "N/A Client"} */}
                          {obj?.companyName
                            ? capitalize(obj.companyName)
                            : capitalize(obj.name.full)}
                        </option>
                      ))}
                    </Input>
                    <Label>Clients</Label>
                  </div>
                  {/* <div className="validation-error">
                    error msg
                  </div> */}
                </FormGroup>
              </Col>
              <Col sm="12">
                {/* <FormGroup className="floatingLabel ">
                  <div className="custom-select-wrapper">
                    <Input
                      type="select"
                      value={formFields.productType.value}
                      name="productType"
                      onChange={(event) =>
                        this._handleOnChange("productType", event.target.value)
                      }
                    >
                      {this.props.loanType &&
                        this.props.loanType.map((type, index) => (
                          <option key={index} value={type.value}>
                            {type.label}
                          </option>
                        ))}
                    </Input>
                    <Label>Product Type</Label>
                  </div>
                
                </FormGroup> */}
                <FormGroup>
                  <ProductType
                    isMulti={false}
                    value={
                      Object.keys(formFields?.productType?.value)?.length
                        ? [formFields?.productType?.value]
                        : []
                    }
                    loanCategories={formFields?.loanCategories?.value}
                    productTypeField={"productType"}
                    selectedCategoriesField={"loanCategories"}
                    onChange={(type, event) =>
                      this._handleOnChange(type, event)
                    }
                    error={formFields?.productType?.error}
                    clientCreateOrderOptions={loanTypeConfig}
                    isCompanyFeeSettings={true} // to hide "other" categories from dropdown
                  />
                </FormGroup>
              </Col>
            </Row>
          </Form>

          {/* the above selected values will be shown here(below) */}
          <div className="CustomTableWrapper">
            <Table stripe responsive>
              <thead>
                <tr>
                  <th>Client</th>
                  <th>Product Type</th>
                  <th>Action</th>
                </tr>
              </thead>

              <tbody>
                {this.props?.companySettings?.scanBackDocument &&
                this.props?.companySettings?.scanBackDocument
                  ?.scanBackDocumentDetail?.length > 0 ? (
                  this.props.companySettings?.scanBackDocument?.scanBackDocumentDetail?.map(
                    (item) => {
                      return (
                        <tr>
                          <td>{item?._client?.companyName || "NA"}</td>
                          <td>{item?.productType || "NA"}</td>
                          <td>
                            <Button
                              color="link"
                              className="px-2 ml-2"
                              onClick={() => {
                                this._deleteScanBackDocument(item?._id);
                              }}
                              disabled={scanbackLoading?.deleteLoading}
                            >
                              <i className="fa fa-trash-o text-danger" />
                            </Button>
                          </td>
                        </tr>
                      );
                    }
                  )
                ) : (
                  <>
                    <tr className="text-center">
                      <td colSpan={3}>
                        <div className="nodata">
                          <img
                            src={
                              require("../../../assets/img/noData.svg").default
                            }
                            alt="no data"
                          />
                          <p>There is no data to display</p>
                        </div>
                      </td>
                    </tr>
                  </>
                )}
              </tbody>
            </Table>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={() => this._closeModal()}>
            Cancel
          </Button>
          <Button
            color="primary"
            onClick={() => this._createSettings()}
            disabled={loading || scanbackLoading?.deleteLoading}
          >
            {loading ? <i className="fa fa-spinner fa-spin mr-2" /> : null} Save
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default ScanBackDocumentModal;
