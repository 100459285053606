import React, { Component } from "react";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Button,
  Label,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Input,
  Row,
  Col,
  FormGroup,
  Badge,
  CustomInput,
  UncontrolledTooltip,
} from "reactstrap";
import {
  showToast,
  deepClone,
  formatCurrencyValue,
  getTimeZoneAbbr,
  formatDateAsPerTimeZOne,
  getMiles,
  errorHandler,
  formatDateOnlyAsPerTimeZone,
} from "../../../helper-methods";
import {
  updateClosingDetailsById,
  getClosingDetailsById,
  getAllSigningCompanyBidding,
  signingCompanyCreateClosingBidding,
  getAllListOfAgentsForClosing,
  removeAgentFromClosingBySigningCompany,
  signingCompanyMarkFavouriteAgentById,
  getAgentWithZipcode,
  getAssignedAgentScheduleforClosing,
  stopAutomationForClosing,
  resumeAutomationForClosing,
  startAutomationForClosing,
  getAllCompanySettings,
  createChatLog,
} from "../../../http/http-calls";
import { SvgIcon, Tooltip, capitalize } from "@material-ui/core";
import Select from "react-select";
import TextEditor from "../components/TextEdittor";
import StandardFeeModal from "./standard-fee-modal";
import AgentsDetailModal from "./AgentsDetailModal";
// import { DEFAULT_PROFILE_PICTURE } from "../../../config";
import { Link } from "react-router-dom";
import {
  connectToSocket,
  disconnectToSocket,
  newSocket,
} from "../../../socket-io";
import CustomTable from "./CustomTable";
import makeAnimated from "react-select/animated";
import CreatableSelect from "react-select";
import CustomCard from "./CustomCard";
import InfiniteScrollDropdown from "./AgentDropdown";
// import SvgIcons from "./SvgIcons";
// import StarRating from "./StarRating";
import { DateTimeShowWithClosingDetails } from "./Date-time-show";
import AddEditNotesModal from "./Modals/add-edit-notes-modal";
import SvgIcons from "./SvgIcons";
const animatedComponents = makeAnimated();

function debounce(fn, time) {
  let timeoutId;
  return wrapper;
  function wrapper(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  }
}
class ClosingAssignment extends Component {
  state = {
    socket: null,
    startAutomation: true,
    PauseAutomation: true,
    agents: [],
    dropdownAgentList: [],
    agentsTotalCount: 0,
    agentsHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      { id: "name", label: "Name", noSort: true },
      { id: "address", label: "Location", noSort: true },
      { id: "distance", label: "Distance", noSort: true },
      {
        id: "credential",
        label: "Credential",
        noSort: true,
        // tooltip: (
        //   <>
        //     <span href="#" id="UncontrolledTooltipExample" className="ml-2">
        //       <SvgIcons type="info" />
        //     </span>
        //     <UncontrolledTooltip
        //       placement="right"
        //       target="UncontrolledTooltipExample"
        //     >
        //       <p>Verified credentials are indicated in Green</p>
        //       <p>Credential waiting for verification is indicated in Yellow</p>
        //       <p>Pending/Rejected credentials are indicated in Red</p>
        //     </UncontrolledTooltip>
        //   </>
        // ),
        tooltip:
          "Verified credentials are indicated in Green \n Credential waiting for verification is indicated in Yellow \n Pending/Rejected credentials are indicated in Red",
      },
      { id: "totalRating", label: "Rating", noSort: true },
      // { id: "feeStandard", label: "Standard Fee", noSort: true },
      // { id: "isFavorite", label: "Favorite", noSort: true },
      { id: "assign", label: "Create/Publish", noSort: true },
      { id: "response", label: "Response", noSort: true },
      { id: "notes", label: "Notes", noSort: true },
    ],
    agentsCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    agentSelectedRows: [],
    bidAutomationSetting: {
      automationType: "off",
      autoSendBidRequest: false,
      bidNotificationQueueRateInSecond: 30,
      bidQueueLimit: 15,
      isCounter: false,
      autoAssignCounterResponse: false,
    },
    closingDetails: {},
    scheduleClosing: [],
    companySettings: {},
    scheduleEvent: [],
    isOnWorkingTime: null,
    isOnLeave: false,
    biddings: [
      // {
      //   id: 1,
      //   name: "Kailash",
      //   location: "Delhi",
      //   distance: '1km',
      //   contact: "jone doe",
      //   credential: "qwerty",
      //   rating: "4.5",
      //   favourite: false,
      // },
      // {
      //   id: 2,
      //   name: "Pardeep",
      //   location: "Canada",
      //   distance: '1000km',
      //   contact: "jone doe",
      //   credential: "qwerty",
      //   rating: "4.5",
      //   favourite: true,
      // },
    ],
    biddingsTotalCount: 0,
    biddingHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      { id: "_agent", label: "Name", noSort: true },
      { id: "response", label: "Respone", noSort: true },
      { id: "address", label: "Location", noSort: true },
      { id: "distance", label: "Distance", noSort: true },
      { id: "credential", label: "Credential", noSort: true },
      { id: "totalRating", label: "Rating", noSort: true },
      { id: "feeStandard", label: "Standard Fee", noSort: true },
      { id: "favourite", label: "Favourite", noSort: true },
      { id: "assign", label: "Assign", noSort: true },
    ],
    tableConfigAgent: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
      sort: {
        sortBy: "createdAt",
        sortOrder: "desc",
      },
    },
    tableConfigResponse: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
      sort: {
        sortBy: "createdAt",
        sortOrder: "desc",
      },
    },
    loading: {
      statusChangeLoading: false,
      showTableLoading: false,
      agentSearchLoading: false,
    },
    standardFeeModal: {
      isOpen: false,
      data: null,
    },
    agentDetailModal: {
      isOpen: false,
      data: null,
    },
    addEditNotesModal: {
      isOpen: false,
      data: null,
    },
    formFields: {
      offeredFee: {
        value: "",
        isValid: false,
        isDirty: false,
        isValidate: true,
      },
      agent: {
        value: "",
        error: null,
        isDirty: false,
      },
      bidInstructions: {
        value: "",
        error: "",
      },
    },
    filters: {
      miles: "25",
      response: "all",
      search: "",
    },
    filterAgent: {
      backgroundCheck: "",
      type: "",
      shouldInclude: [],
    },
    agentsZipCodePage: {
      page: 1,
      limit: 30,
    },
    agentSuggestedTotalCount: 0,
    tooltipOpen: false,
  };

  toggle = () => {
    this.setState({
      tooltipOpen: !this.state.tooltipOpen,
    });
  };

  _closeModal = () => {
    // newSocket.emit("unsubscribe",{room: this.props?.data?._id})
    this.props.toggle();
    // this._disconnectToSocket();
  };

  _resetModal = () => {
    this.setState({
      formFields: {
        offeredFee: {
          value: "",
          isValid: false,
          isDirty: false,
          isValidate: true,
        },
        agent: {
          value: "",
          error: null,
          isDirty: false,
        },
        bidInstructions: {
          value: "",
          error: "",
        },
      },
      filters: {
        miles: "25",
        response: "all",
      },
      filterAgent: {
        backgroundCheck: "",
        type: "",
        shouldInclude: [],
      },
      isActive: false,
      tableConfigAgent: {
        skip: 0,
        limit: 10,
        pageNumber: 1,
        sort: {
          sortBy: "createdAt",
          sortOrder: "desc",
        },
      },
      agents: [],
      dropdownAgentList: [],
      agentsTotalCount: 0,
      agentSelectedRows: [],
      bidAutomationSetting: {
        automationType: "off",
        autoSendBidRequest: false,
        bidNotificationQueueRateInSecond: 60,
        bidQueueLimit: 25,
        isCounter: false,
      },
      biddings: [],
      closingDetails: {},
      scheduleClosing: [],
      scheduleEvent: [],
      isOnWorkingTime: null,
      isOnLeave: false,
      agentsZipCodePage: {
        page: 1,
        limit: 30,
      },
      agentSuggestedTotalCount: 0,
    });
  };

  _getAllAgents = () => {
    this._manageLoading("showTableLoading", true);
    let payload = {
      closingId: this.props.data?._id,
      miles: this.state.filters.miles,
      response: this.state.filters.response,
      skip: this.state.tableConfigAgent.skip,
      limit: this.state.tableConfigAgent.limit,
      type: this.state.filterAgent.type
        ? this.state.filterAgent.type
        : undefined,
      backgroundCheck: this.state.filterAgent.backgroundCheck
        ? this.state.filterAgent.backgroundCheck
        : undefined,
      shouldInclude:
        this.state.filterAgent.shouldInclude.length > 0
          ? this.state.filterAgent.shouldInclude.map((item) => item.value)
          : undefined,
      search: this.state.filters?.search?.length
        ? this.state.filters?.search
        : undefined,
    };
    getAllListOfAgentsForClosing(payload)
      .then((res) => {
        this.setState({
          agents: res?.agents || [],
          agentsTotalCount: res?.totalCount || 0,
        });
        this._manageLoading("showTableLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _filterOnChange = (type, value) => {
    const { filters } = deepClone(this.state);
    filters[type] = value;

    this.setState({ filters }, () => {
      console.log(filters);
      // this._getAllAgents();
      this._paginate();
      this._persistFilter();
    });
  };

  // componentDidUpdate(previousProps, previousState) {
  //   const { data } = this.props;
  //   // const { formFields } = deepClone(this.state);

  //   if (this.props.data && previousProps.isOpen !== this.props.isOpen) {
  //     this.setState({ jobDetails: this.props.data });
  //     console.log("componentDidupdat", this.props);
  //     this._manageLoading("showTableLoading", true);
  //     this._connectToSocket();

  //     // this._getAllAgents();
  //     // this._getAllSigningCompanyBidding(this.props.data._id);
  //     // this._getSigningClosingById(this.props.data._id);
  //     // this._getAllCompanySettings();
  //     this._setFilterDataFromLocalStorage();

  //     // formFields["agent"].value =data?._agent?.id? data?._agent?.id:"";
  //     // this.setState({ formFields }, () => {
  //     if (data?._agent?.id)
  //       this._getAssignedAgentScheduleforClosing(data?._agent?.id);
  //     // });
  //     // this._connectToSocket();
  //   }
  // }

  componentDidUpdate = async (previousProps, previousState) => {
    const { data } = this.props;

    if (this.props.data && previousProps.isOpen !== this.props.isOpen) {
      new Promise((resolve, reject) => {
        // resolve('I am a promise')
        this._manageLoading("showTableLoading", true);
        // this._connectToSocket();
        newSocket.emit("subscribe", { room: this.props?.data?._id });
        this._getCounterAutomation();
        this._getAcceptOrRejectAutomation();
        this._getBidAutomation();
        this._isSentToAllAgents();
        this._setFilterDataFromLocalStorage();

        if (data?._agent?.id) {
          this._getAssignedAgentScheduleforClosing(data?._agent?.id);
        } else {
          this.setState({
            // dropdownAgentList: options,
            scheduleClosing: [],
            scheduleEvent: [],
            isOnLeave: false,
            isOnWorkingTime: null,
          });
        }
        // this.setState({ jobDetails: this.props.data });
        console.log("componentDidupdat", this.props);
        resolve();
      });
      const filter = JSON.parse(localStorage?.companyClosingAssignment);
      if (filter.id !== this.props?.data?._id) {
        localStorage.removeItem("companyClosingAssignment");
        this._resetModal();
      }
    }
  };

  componentDidMount = () => {
    this._connectToSocket();
  };

  _setFilterDataFromLocalStorage = async () => {
    // const { filters, tableConfig,formFields } = deepClone(this.state);

    // this.setState((prev) => console.log("first", prev));

    if (localStorage && localStorage.companyClosingAssignment) {
      try {
        const filter = JSON.parse(localStorage.companyClosingAssignment);
        // console.log("filtrs", filter);
        // console.log("reset", filter.id , this.props.data._id,(filter.id !== this.props.data._id))
        // if (filter.id !== this.props.data._id) {
        //   localStorage.removeItem("companyClosingAssignment");
        //   this._resetModal();
        // this._getAllSigningCompanyBidding(this.props.data._id);
        // this._getSigningClosingById(this.props.data._id);
        // this._getAllAgents();
        // this._getAllCompanySettings();
        // } else {
        this.setState(
          {
            filters: filter.filters,
            formFields: filter.formFields,
            filterAgent: filter.filterAgent,
            bidAutomationSetting: filter.bidAutomationSetting,
          },
          () => {
            this._getAllSigningCompanyBidding(this.props.data._id);
            this._getSigningClosingById(this.props.data._id);
            this._getAllAgents();
            this._getAllCompanySettings();
          }
        );
        // }
        // this._getAllSigningCompanyBidding(this.props.data._id);
        // this._getSigningClosingById(this.props.data._id);
        // this._getAllAgents();
        // this._getAllCompanySettings();
      } catch (e) {
        console.log("error", e);
        this._getAllAgents();
        this._getAllSigningCompanyBidding(this.props.data._id);
        this._getSigningClosingById(this.props.data._id);
        this._getAllCompanySettings();
      }
    } else {
      // console.log("23232")
      this._getAllAgents();
      this._getAllSigningCompanyBidding(this.props.data._id);
      this._getSigningClosingById(this.props.data._id);
      this._getAllCompanySettings();
    }
  };

  // this._getAllAgents();
  // this._getAllSigningCompanyBidding(this.props.data._id);
  // this._getSigningClosingById(this.props.data._id);
  // this._getAllCompanySettings();

  _prepareFilterData = () => {
    const { filters, formFields, filterAgent, bidAutomationSetting } =
      deepClone(this.state);

    if (filters.miles === "10") delete filters.miles;
    if (!formFields.offeredFee) delete formFields.offeredFee;
    if (!filterAgent.backgroundCheck) delete filterAgent.backgroundCheck;
    if (!filterAgent.type) delete filterAgent.type;
    if (!bidAutomationSetting.automationType)
      delete bidAutomationSetting.automationType;
    if (!bidAutomationSetting.isCounter) delete bidAutomationSetting.isCounter;

    return filters;
  };

  _persistFilter = () => {
    const { filters, filterAgent, bidAutomationSetting, formFields } =
      this.state;
    // console.log("5555");
    if (
      filters.miles ||
      filters.response ||
      formFields.offeredFee ||
      filterAgent.backgroundCheck ||
      filterAgent.type ||
      bidAutomationSetting.automationType ||
      bidAutomationSetting.isCounter
    ) {
      let data = {
        filters,
        formFields,
        filterAgent,
        bidAutomationSetting,
        id: this.props?.data?._id,
      };
      // console.log(data);
      localStorage.companyClosingAssignment = JSON.stringify(data);
    } else {
      delete localStorage.companyClosingAssignment;
    }
  };

  _getAllCompanySettings = async () => {
    // const { closingDetails } = deepClone(this.state);

    try {
      let response = await getAllCompanySettings();
      this.setState(
        {
          companySettings: response?.companySetting || {},
        },
        () => {
          if (
            this.state.formFields.offeredFee.value === undefined ||
            !this.state.formFields?.offeredFee?.value.trim().length
          ) {
            this._findStandardrate();
          }
        }
      );
      //  let standardFee = response.companySetting.standardFeeRates.find(item=>item.county===closingDetails.city&&item.productType===closingDetails.loanType&&item.state===closingDetails.closingAddress.state)
      //   console.log("777",standardFee)
    } catch (error) {
      errorHandler(error);
    }
  };

  _findStandardrate = () => {
    const { formFields, closingDetails } = deepClone(this.state);
    if (
      closingDetails?.closingAddress?.state &&
      closingDetails?.closingAddress?.city &&
      closingDetails?.loanType &&
      closingDetails?.signingType
    ) {
      let companySettingStandardFee = this.filterData(
        closingDetails?.closingAddress?.state,
        closingDetails?.closingAddress?.city,
        closingDetails?.loanType,
        closingDetails?.signingType
      );
      console.log("121", companySettingStandardFee);
      formFields["offeredFee"].value =
        companySettingStandardFee.length > 0
          ? companySettingStandardFee[0]?.offerRate?.toString()
          : "";
      this.setState({ formFields }, () => {});
    }
  };

  filterData = (state, city, productType, signingType) => {
    const { companySettings } = deepClone(this.state);
    return (
      companySettings &&
      Object.keys(companySettings).length > 0 &&
      companySettings?.standardFeeRates?.filter(
        (obj) =>
          !obj.isForClient &&
          obj.signingType === signingType &&
          (obj.state !== "" ? obj.state === state : true) &&
          (obj.county !== ""
            ? city?.toLowerCase()?.includes(obj.county.toLowerCase())
            : true) &&
          productType.includes(obj.productType)
      )
    );
  };

  // _findStandardrate = () => {
  //   const { companySettings, formFields, closingDetails } = deepClone(
  //     this.state
  //   );
  //   let countyMatched =
  //     companySettings &&
  //     Object.keys(companySettings).length > 0 &&
  //     companySettings?.standardFeeRates.find(
  //       (item) =>
  //         item.state === closingDetails?.closingAddress?.state &&
  //         item.county
  //           .toLowerCase()
  //           .includes(closingDetails?.closingAddress?.city.toLowerCase()) &&
  //         item.productType === closingDetails?.loanType
  //     );
  //   let noCounty =
  //     companySettings &&
  //     Object.keys(companySettings).length > 0 &&
  //     companySettings?.standardFeeRates.find(
  //       (item) =>
  //         item.state === closingDetails?.closingAddress?.state &&
  //         item.county === "" &&
  //         item.productType === closingDetails?.loanType
  //     );
  //   let companySettingStandardFee =
  //     countyMatched !== undefined ? countyMatched : noCounty;
  //   // console.log("companySettingStandardFee", countyMatched, noCounty);
  //   formFields["offeredFee"].value =
  //     companySettingStandardFee?.offerRate?.toString();
  //   this.setState({ formFields }, () => {});
  // };
  // _findStandardrate = () => {
  //   const { companySettings, formFields, closingDetails } = deepClone(
  //     this.state
  //   );
  //   // if (
  //   //   formFields?.loanType?.value !== "" &&
  //   //   formFields?.closingAddressCity?.value !== "" &&
  //   //   formFields?.closingAddressState?.value !== ""
  //   // ) {
  //   console.log("inside standard rate", companySettings);
  //   let companySettingStandardFee =
  //     Object.keys(companySettings).length > 0 &&
  //     companySettings?.standardFeeRates.find(
  //       (item) =>
  //         // console.log("hgfdg",
  //         //  (item.state === closingDetails?.closingAddress?.state ||
  //         //     item.county === closingDetails?.closingAddress?.city) &&
  //         //     item.productType === closingDetails?.loanType
  //         // )
  //         // (item.state === closingDetails?.closingAddress?.state ||
  //         //   item.county
  //         //     .toLowerCase()
  //         //     .includes(closingDetails?.closingAddress?.city.toLowerCase())) &&
  //         // item.productType === closingDetails?.loanType
  //         item.state === closingDetails?.closingAddress?.state &&
  //         item.county.toLowerCase() ===
  //           closingDetails?.closingAddress?.city.toLowerCase() &&
  //         item.productType === closingDetails?.loanType
  //     );
  //   // console.log("companySettingStandardFee", companySettingStandardFee);
  //   // formFields["agentFee"].value =
  //   //   companySettingStandardFee?.offerRate.toString();
  //   formFields["offeredFee"].value =
  //     companySettingStandardFee?.offerRate?.toString();
  //   this.setState({ formFields }, () => {
  //     // console.log("22222", formFields);
  //   });
  //   // }
  // };
  _getAgentWithZipcode = (data) => {
    // console.log(this.state?.closingDetails?.closingAddress?.zip);
    const { agentsZipCodePage, closingDetails } = deepClone(this.state);

    this._manageLoading("agentSearchLoading", true);
    let payload = {
      closingId: this.props.data?._id,
      signingType: this.state?.closingDetails?.signingType,
      zipCode:
        closingDetails?.closingAddress?.zip?.trim()?.length > 0 &&
        closingDetails?.signingType !== "RON"
          ? closingDetails?.closingAddress?.zip
          : undefined,
      state:
        closingDetails?.signingType === "RON"
          ? closingDetails?.closingAddress?.state
          : undefined,
      type: this.state.filterAgent.type
        ? this.state.filterAgent.type
        : undefined,
      backgroundCheck: this.state.filterAgent.backgroundCheck
        ? this.state.filterAgent.backgroundCheck
        : undefined,
      shouldInclude:
        this.state.filterAgent.shouldInclude.length > 0
          ? this.state.filterAgent.shouldInclude.map((item) => item.value)
          : undefined,
      skip: 0,
      limit: agentsZipCodePage.page * agentsZipCodePage.limit,
      search: data?.search,
    };

    getAgentWithZipcode(payload)
      .then((res) => {
        // console.log(res);
        let options =
          res?.agents?.map((item) => ({
            value: item._id,
            label: capitalize(item?.name?.first + " " + item?.name?.last),
          })) || [];
        this.setState({
          dropdownAgentList: options,
          agentSuggestedTotalCount: res?.totalCount || 0,
        });
        this._manageLoading("agentSearchLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("agentSearchLoading", false);
      });
  };

  _getAssignedAgentScheduleforClosing = (id) => {
    let payload = {
      closingId: this.props.data?._id,
    };
    getAssignedAgentScheduleforClosing(id, payload)
      .then((res) => {
        console.log("schedule", res);
        this.setState({
          // dropdownAgentList: options,
          scheduleClosing: res?.data?.closings || [],
          scheduleEvent: res?.data?.events || [],
          isOnLeave: res?.data?.isOnLeave || false,
          isOnWorkingTime: res?.data?.isOnWorkingTime || null,
        });
      })
      .catch((error) => {
        errorHandler(error);
        // this._manageLoading("showTableLoading", false);
      });
  };

  _getSigningClosingById = (id = null) => {
    const { formFields, filters } = deepClone(this.state);
    if (!id && this.state.closingDetails && this.state.closingDetails.id) {
      id = this.state.closingDetails.id;
    }
    return new Promise((resolve, reject) => {
      getClosingDetailsById(id)
        .then((res) => {
          console.log("7412", res);
          // if biddingAmount exits then show biddingAmount otherwise check agentfee greater then -1 then show agentfee otherwise the value that user entered will be shown
          // formFields["offeredFee"].value = res?.closing?.biddingAmount
          //   ? res?.closing?.biddingAmount?.toString()
          //   : res?.closing?.agentFee > -1
          //   ? res?.closing?.agentFee?.toString()
          //   : formFields.offeredFee.value;

          // if agentfee is greater then -1 then show agent fee otherwise check if biddingAmount if found then we will show otherwise the value that user enter entered will be shown
          formFields["offeredFee"].value =
            // res?.closing._agent !== undefined &&  // removing this condition to check only agentFee exits
            res?.closing?.agentFee > -1
              ? res?.closing?.agentFee?.toString()
              : res?.closing?.biddingAmount
              ? res?.closing?.biddingAmount?.toString()
              : formFields.offeredFee.value;

          formFields["bidInstructions"].value = res.closing.instructions;
          formFields["agent"].value = this.props?.data?._agent?.id
            ? {
                label: this.props?.data?._agent?.name?.full || "",
                value: this.props?.data?._agent?.id,
              }
            : "";
          // filters["miles"] = res.closing.miles
          //   ? res.closing.miles.toString()
          //   : filters.miles;
          this.setState(
            {
              // agentSelectedRows: res.closing.biddingAgent,
              // bidAutomationSetting: res.bidAutomationSetting,
              bidAutomationSetting: {
                automationType: res.closing.automationType
                  ? res.closing.automationType
                  : res.bidAutomationSetting.automationType
                  ? res.bidAutomationSetting.automationType
                  : "all",
                autoSendBidRequest: res.bidAutomationSetting.autoSendBidRequest,
                bidNotificationQueueRateInSecond:
                  res.bidAutomationSetting.bidNotificationQueueRateInSecond,
                bidQueueLimit: res.bidAutomationSetting.bidQueueLimit,
                // isCounter: res.bidAutomationSetting.isCounter
                //   ? res.bidAutomationSetting.isCounter
                //   : res.closing.isCounter
                //   ? res.closing.isCounter
                //   : false,
                isCounter: res.bidAutomationSetting.autoSendBidRequest,
                autoAssignCounterResponse:
                  res.closing.autoAssignCounterResponse !== undefined
                    ? res.closing.autoAssignCounterResponse
                    : res.bidAutomationSetting.autoAssignCounterResponse,
              },
              closingDetails: res.closing,
              formFields,
              filters,
            },
            () => {
              this._getAgentWithZipcode();
              // if (
              //   this.state.formFields.offeredFee.value === undefined ||
              //   !this.state.formFields?.offeredFee?.value.trim().length
              // ) {
              //   this._findStandardrate();
              // }
              if (this.state.filters.miles !== "50") {
                this._getAllAgents();
              }
            }
          );
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _getAllSigningCompanyBidding = (id = null) => {
    if (!id && this.state.closingDetails && this.state.closingDetails.id) {
      id = this.state.closingDetails.id;
    }
    const { tableConfigResponse } = deepClone(this.state);

    let payload = {
      skip: tableConfigResponse.skip,
      limit: tableConfigResponse.limit,
    };
    getAllSigningCompanyBidding(id, payload)
      .then((res) => {
        // console.log(res);
        this._manageLoading("showTableLoading", false);
        this.setState({
          biddings: res?.biddings || [],
          biddingsTotalCount: res?.totalCount || 0,
        });
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _dataFormat = (cell, row, header) => {
    const { closingDetails } = deepClone(this.state);

    switch (header) {
      // case "name": {
      //   return cell ? (
      //     <>
      //       {capitalize(cell?.first + " " + cell?.last)} <br />
      //       {/* {row.idVerification.isVerified === "Approved" ? (
      //         <span className="statusSuccess">Verified</span>
      //       ) : null} */}
      //       {row?.notaryLicense?.isVerified === "Approved" &&
      //         row?.idVerification?.isVerified === "Approved" &&
      //         row?.backgroundCheckReport?.isVerified === "Approved" &&
      //         row?.eoInsurance?.isVerified === "Approved" &&
      //         // row?.nnaCertification?.isVerified === "Approved" &&
      //         // row?.titleProducerLicense?.isVerified === "Approved" &&
      //         // row?.mdTitleProducerLicense?.isVerified === "Approved" &&
      //         // row?.nyTotalCloser?.isVerified === "Approved" &&
      //         // row?.lssCertification?.isVerified === "Approved" &&
      //         row?.w9Credentials?.isVerified === "Approved" && (
      //           <span className="statusSuccess">Verified</span>
      //         )}
      //     </>
      //   ) : (
      //     "N/A"
      //   );
      // }

      case "name": {
        return cell ? (
          <>
            <div className="tableUserInfo">
              <div className="mr-2">
                {!row.isFavourite ? (
                  <img
                    onClick={() => this._markAgentAsFavorite(row.id)}
                    src={require("../../../assets/img/heart-blank.svg").default}
                    alt="fav"
                    className="cursorPointer"
                    height={14}
                  />
                ) : (
                  <img
                    onClick={() => this._markAgentAsFavorite(row.id)}
                    src={
                      require("../../../assets/img/heart-fillBlue.svg").default
                    }
                    alt="fav"
                    className="cursorPointer"
                    height={14}
                  />
                )}
              </div>
              <div className="userImage">
                <img
                  className="clientImg"
                  // src={require("../../../assets/img/default_user_icon.svg").default}
                  src={
                    row?.profilePicture
                      ? row?.profilePicture
                      : require("../../../assets/img/default_user_icon.svg")
                          .default
                  }
                  loading="lazy"
                  alt="avatar"
                />
              </div>
              <div className="userContent">
                <span
                  className="tableLink"
                  onClick={() => this._onToggleAgentDetailModal(true, row)}
                >
                  {capitalize(cell?.first + " " + cell?.last)}
                </span>
              </div>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "address": {
        return cell ? (
          <>
            <div style={{ width: 120 }}>
              {cell?.city !== "" && cell?.state !== "" ? (
                <>
                  {cell?.line1 ? `${cell?.line1}, ` : ""}
                  {cell?.line2 ? `${cell?.line2}, ` : ""}
                  {cell?.city ? `${cell?.city}, ` : ""}
                  {cell?.state ? `${cell?.state}, ` : ""}
                  {cell?.zip ? `${cell?.zip}` : ""}
                </>
              ) : (
                "N/A"
              )}
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      case "response": {
        return (
          <div className="">
            {row.biddingStatus === "Sent" ? (
              <Badge color="primary" className="outline" pill>
                Sent
              </Badge>
            ) : row.biddingStatus === "Accepted" ? (
              <Badge color="success" className="outline" pill>
                Accepted
              </Badge>
            ) : row.biddingStatus === "Counter" ? (
              <div className="counter">
                <Badge color="warning" className="outline" pill>
                  Counter
                </Badge>
                <span className="text ml-1">(${row.counterAmount})</span>

                {row.biddingStatus === "Counter" && (
                  <>
                    <div
                      style={{ width: 150, whiteSpace: "normal", fontSize: 11 }}
                    >
                      {formatDateAsPerTimeZOne(
                        row.appointmentTime,
                        this.props.data?.closingAddress?.timeZone
                      )}
                      <span className="text-primary ml-1">
                        {getTimeZoneAbbr(
                          row.appointmentTime,
                          this.props.data?.closingAddress?.timeZone
                        )}
                      </span>
                    </div>
                  </>
                )}

                {/* </span> */}
                {/* <span className="text">(
                  {formatDateAsPerTimeZOne(
                    row?.appointmentTime,
                    row?.address?.timeZone
                  )}
                  )</span> */}
              </div>
            ) : row.biddingStatus === "Rejected" ? (
              <Badge color="danger" className="outline" pill>
                Decline
              </Badge>
            ) : (
              "N/A"
            )}
          </div>
        );
      }
      case "distance": {
        return (
          <span>
            {row?.dist?.calculated > -1
              ? getMiles(row?.dist?.calculated) + " Miles"
              : "N/A"}
          </span>
        );
      }
      // case "credential": {
      //   return cell >= 0 ? (
      //     "N/A"
      //   ) : (
      //     <>
      //       <div className="credentialWrap">
      //         <Badge color="success">ID</Badge>
      //         <Badge color="success">NL</Badge>
      //         <Badge color="primary">BGC</Badge>
      //         <Badge color="primary">EO</Badge>
      //         <Badge color="secondary">W-9</Badge>
      //       </div>
      //     </>
      //   );
      // }
      case "credential": {
        return (
          <div className="credentialWrap">
            {/* <Badge
              color={`${
                row?.idVerification?.isVerified === "Approved"
                  ? "success"
                  : row?.idVerification?.isVerified === "Pending" &&
                    row?.idVerification?.url
                  ? "warning"
                  : "danger"
              }`}
              className="outline"
              pill
            >
              ID
            </Badge> */}

            <Badge
              color={`${
                row?.notaryLicense.length > 0 &&
                row?.notaryLicense?.filter(
                  (item) =>
                    item?.isVerified === "Approved" &&
                    closingDetails?.closingAddress?.state === item?.state
                ).length
                  ? "success"
                  : // <i className="fa fa-check text-success" />
                  row?.notaryLicense.length > 0 &&
                    row?.notaryLicense?.filter(
                      (item) => item?.isVerified === "Pending" && item?.url
                      // row?.notaryLicense?.url
                    ).length
                  ? "warning"
                  : // <i className="fa fa-check text-warning" />
                    "danger"
              }`}
              className="outline"
              pill
            >
              NL
            </Badge>
            {/* {console.log("888888",
                 !row?.notaryLicense?.some(
                  (item) => item?.isVerified !== "Pending"
                )
              )
              } */}
            {/* <div>
                      {this._isCredentialUploaded("notaryLicense") ? (
                        <>
                          {!agentDetails?.notaryLicense?.filter(
                            (item) => item?.isVerified !== "Approved"
                          ).length ? (
                            <i className="fa fa-check text-success" />
                          ) : (
                            <i className="fa fa-check text-warning" />
                          )}
                        </>
                      ) : (
                        <i className="fa fa-exclamation text-danger" />
                      )}{" "}
                      Notary License<span className="text-danger">*</span>
                    </div> */}
            <Badge
              color={`${
                row?.backgroundCheckReport?.isVerified === "Approved"
                  ? "success"
                  : row?.backgroundCheckReport?.isVerified === "Pending" &&
                    row?.backgroundCheckReport?.url
                  ? "warning"
                  : "danger"
              }`}
              className="outline"
              pill
            >
              BGC
            </Badge>

            <Badge
              color={`${
                row?.eoInsurance?.isVerified === "Approved"
                  ? "success"
                  : row?.eoInsurance?.isVerified === "Pending" &&
                    row?.eoInsurance?.url
                  ? "warning"
                  : "danger"
              }`}
              className="outline"
              pill
            >
              EO
            </Badge>
            {row?.ronCredentials?.length > 0 &&
            row?.ronCredentials?.filter(
              (item) => item?.isVerified === "Approved"
              /* &&
                closingDetails?.closingAddress?.state === item?.state */
            )?.length ? (
              <Badge
                color={`${
                  row?.ronCredentials?.length > 0 &&
                  row?.ronCredentials?.filter(
                    (item) => item?.isVerified === "Approved"
                    // &&
                    // closingDetails?.closingAddress?.state === item?.state
                  ).length
                    ? "success"
                    : // <i className="fa fa-check text-success" />
                      null
                  // row?.ronCredentials?.length > 0 &&
                  //   row?.ronCredentials?.filter(
                  //     (item) => item?.isVerified === "Pending" && item?.url
                  //     // row?.notaryLicense?.url
                  //   )?.length
                  // ? "warning"
                  // : // <i className="fa fa-check text-warning" />
                  //   "danger"
                }`}
                className="outline"
                pill
              >
                RON
              </Badge>
            ) : null}
          </div>
        );
      }

      case "closingCompleted": {
        return cell >= 0 ? (
          <div className="text-underline cursorPointer">{cell}</div>
        ) : (
          "N/A"
        );
      }
      case "totalRating": {
        return (
          <>
            {row?.totalRating !== undefined ? (
              <>
                {/* <StarRating rating={4.5} totalStars={5} /> */}
                <div className="starIcons">
                  {[...Array(Math.floor(row?.totalRating))].map((each) => (
                    <img
                      src={
                        require("../../../assets/img/ratingFill.svg").default
                      }
                      alt="rating"
                    />
                  ))}
                </div>
                {/* <div className="starIcons">
                    <SvgIcons type={"star"} />
                    <SvgIcons type={"star"} />
                    <SvgIcons type={"star"} />
                    <SvgIcons type={"star"} />
                    <SvgIcons type={"starHalf"} />
                    </div> 
                */}
                <div className="text-underline cursorPointer">
                  {row?.totalRating}
                </div>
              </>
            ) : (
              <div className="text-underline cursorPointer">N/A</div>
            )}

            <div className="mt-2">
              <Badge id="blacklisted" color="secondary">
                {row?.blockingCompanysCount}
              </Badge>

              <UncontrolledTooltip placement="bottom" target="blacklisted">
                No. of companies that have blacklisted the agent
              </UncontrolledTooltip>
              <Badge color="success" id="prefered">
                {row?.companysMarkAsFavouriteCount}
              </Badge>
              <UncontrolledTooltip placement="bottom" target="prefered">
                No. of companies that have prefered the agent
              </UncontrolledTooltip>
            </div>
          </>
        );
      }
      case "feeStandard": {
        //   let standardFee =
        //     row?.standardFee &&
        //     Object.entries(row?.standardFee).find(([key, value], index) =>
        //       key.includes(this.state?.closingDetails?.loanType)
        //     );
        // console.log("cd", this.state?.closingDetails?.loanType);
        //   return standardFee ? <> ${standardFee[1]} </> : "N/A";
        let calculatedStandardFee =
          Object.keys(this.state?.closingDetails)?.length > 0 &&
          row?.standardFee &&
          Object.keys(row?.standardFee)?.length > 0 &&
          this.state?.closingDetails?.loanType.reduce(
            (acc, cur) => acc + row?.standardFee[cur],
            0
          );
        // row?.standardFee &&
        // Object.entries(row?.standardFee).reduce((acc, cur) =>
        // console.log("ss",acc,cur)
        // key.includes(this.state?.closingDetails?.loanType)
        // );
        // console.log("standardFee", row?.standardFee);
        let standardFee =
          this.state?.closingDetails?.signingType === "RON"
            ? row?.standardFee &&
              Object.keys(row?.standardFee)?.length > 0 &&
              calculatedStandardFee +
                row?.standardFee["ForeignNationalSigner"] +
                row?.standardFee["WitnessFee"]
            : calculatedStandardFee;
        return standardFee ? <> ${standardFee} </> : "N/A";
      }

      case "states": {
        return cell ? row.address.state : "N/A";
      }
      case "notes": {
        return (
          <Button
            color="link"
            className="actionBtn"
            onClick={() =>
              this._onToggleAddEditNotesModal(
                !this.state.addEditNotesModal.isOpen,
                row
              )
            }
          >
            <SvgIcons
              type="notes"
              color={row?.isNotesExists ? "blue" : "black"}
            />
          </Button>
        );
      }
      // case "standardFee": {
      //   let fee =
      //     cell &&
      //     Object?.entries(cell)?.find(
      //       ([key, value]) => key === this?.props?.data?.loanType
      //     );

      //   return cell
      //     ? // <i
      //     //   className="fa fa-eye"
      //     //   onClick={() => this._onToggleStandardFeeModal(true,row)}
      //     // ></i>
      //     // return
      //     fee && (fee || fee === 0)
      //       ? formatCurrencyValue(fee[1])
      //       : "$0"
      //     : // console.log(fee)
      //     // console.log(
      //     //   Object.entries(cell).filter(([key, value]) =>{
      //     //   return this?.props?.data?.loanType===key? value:null
      //     //   // console.log(`${key} ${value}`)
      //     // }
      //     //   ))
      //     "N/A";
      //  {row?.standardFee ?
      //   Object.entries(row?.standardFee).map(
      //     ([key, value], index) => {
      //       return (
      //         <li>
      //           {key}-${value}
      //         </li>
      //       );
      //     }
      //   ):"N/A"}
      // }
      // case "isFavorite": {
      //   return row ? (
      //     !row.isFavourite ? (
      //       <i
      //         className="fa fa-heart-o"
      //         onClick={() => this._markAgentAsFavorite(row.id)}
      //       ></i>
      //     ) : (
      //       <i
      //         className="fa fa-heart"
      //         style={{ color: "#28A745" }}
      //         onClick={() => this._markAgentAsFavorite(row.id)}
      //       ></i>
      //     )
      //   ) : (
      //     "N/A"
      //   );
      // }
      // case "isFavorite": {
      //   return cell ? (

      //     <Button color="link">
      //       <i className="fa fa-heart"></i>
      //     </Button>

      //   ) : (
      //     <Button color="link">
      //       <i className="fa fa-heart-o"></i>
      //     </Button>
      //   );
      // }
      case "assign": {
        return (
          <Button
            color="link"
            className="h-auto"
            disabled={row.status === "Rejected"}
            onClick={() =>
              this._checkAllFieldsAreAvailable() &&
              this._updateClosingDetailsById(row)
            }
          >
            {/* Assign */}
            {/* {console.log("1111", row)} */}

            {this._checkAllFieldsAreAvailable() ? (
              this.state?.closingDetails?.isSavedAsDraft ? (
                "Publish"
              ) : (
                "Assign"
              )
            ) : (
              // "Publish"
              <Link
                to={`create-closing?${this.state?.closingDetails?._id}&${row._id}&${row.appointmentTime}&${row.counterAmount}`}
                className="ml-2"
              >
                Create
              </Link>
            )}
          </Button>
        );
      }

      default: {
        return cell;
      }
    }
  };

  _checkAllFieldsAreAvailable = () => {
    const { closingDetails } = deepClone(this.state);
    // console.log(
    //   "8745",
    //   closingDetails._client!==undefined,
    //   closingDetails.signingCompanyFee!==undefined,
    //   closingDetails?._borrower[0]?.name.full.trim().length!==undefined,
    //   closingDetails.fileNumber!==undefined
    // );
    return (
      closingDetails._client !== undefined &&
      closingDetails.signingCompanyFee !== undefined &&
      closingDetails?._borrower[0]?.name?.full.trim().length !== undefined &&
      closingDetails.fileNumber !== undefined
    );
  };

  _markAgentAsFavorite = async (id) => {
    try {
      await signingCompanyMarkFavouriteAgentById(id);
      this._getAllAgents();
      this._manageLoading("statusChangeLoading", false);
    } catch (error) {
      errorHandler(error);
      this._manageLoading("statusChangeLoading", false);
    }
  };

  _dataFormatBidding = (cell, row, header) => {
    switch (header) {
      case "_agent": {
        return cell && cell.name.full ? (
          <>
            {capitalize(cell.name.full)} <br />
          </>
        ) : (
          "N/A"
        );
      }

      case "feeStandard": {
        return cell ? <span>$20</span> : <span>$20</span>;
      }
      case "favourite": {
        return cell ? (
          <Button color="link">
            <i className="fa fa-heart"></i>
          </Button>
        ) : (
          <Button color="link">
            <i className="fa fa-heart-o"></i>
          </Button>
        );
      }
      case "response": {
        return cell >= 0 ? (
          <div className="">
            <Badge color="primary">Sent</Badge>
            <Badge color="success">Accepted</Badge>
            <Badge color="warning">Counter 18</Badge>
            <Badge color="danger">Decline</Badge>
          </div>
        ) : (
          <div className="">
            {row.status === "Accepted" && (
              <Badge color="success">Accepted</Badge>
            )}
            {row.status === "Decline" && <Badge color="danger">Decline</Badge>}
            {row.status === "Counter" && <Badge color="warning">Counter</Badge>}
            {/* <Badge color="warning">Counter ($20)</Badge> */}
          </div>
        );
      }
      // case "credential": {
      //   return  <div className="credentialWrap">
      //     {/* {row?._agent?.notaryLicense?.isVerified === "Approved" ? <Badge color="success">NL</Badge>:null, */}
      //             {/* row?._agent?.idVerification?.isVerified === "Approved" ? <Badge color="success">ID</Badge>:null, */}
      //             {/* // row?._agent?.backgroundCheckReport?.isVerified === "Approved" &&
      //             // row?._agent?.eoInsurance?.isVerified === "Approved" &&
      //             // row?._agent?.nnaCertification?.isVerified === "Approved" &&
      //             // row?_agent?.titleProducerLicense?.isVerified === "Approved" &&
      //             // row?_agent?.mdTitleProducerLicense?.isVerified === "Approved" &&
      //             // row?_agent?.nyTotalCloser?.isVerified === "Approved" &&
      //             // row?_agent?.lssCertification?.isVerified === "Approved" &&
      //             // row?_agent?.w9Credentials?.isVerified === "Approved" }  */}

      //              </div>
      //       </div>
      //              </div>

      //  cell >= 0 ? (
      //   "N/A"
      // ) : (
      //   <>
      //     <div className="credentialWrap">
      //       <Badge color="success">ID</Badge>
      //       <Badge color="success">NL</Badge>
      //       <Badge color="primary">BGC</Badge>
      //       <Badge color="primary">EO</Badge>
      //       <Badge color="secondary">W-9</Badge>
      //     </div>
      //   </>
      // )
      // }

      case "credential": {
        return (
          <div className="credentialWrap">
            <Badge
              color={`${
                row?._agent?.idVerification?.isVerified === "Approved"
                  ? "success"
                  : row?._agent?.idVerification?.isVerified === "Pending"
                  ? "primary"
                  : "secondary"
              }`}
            >
              ID
            </Badge>
            <Badge
              color={`${
                row?._agent?.notaryLicense?.isVerified === "Approved"
                  ? "success"
                  : row?._agent?.notaryLicense?.isVerified === "Pending"
                  ? "primary"
                  : "secondary"
              }`}
            >
              NL
            </Badge>
            <Badge
              color={`${
                row?._agent?.backgroundCheckReport?.isVerified === "Approved"
                  ? "success"
                  : row?._agent?.backgroundCheckReport?.isVerified === "Pending"
                  ? "primary"
                  : "secondary"
              }`}
            >
              BGC
            </Badge>

            <Badge
              color={`${
                row?._agent?.eoInsurance?.isVerified === "Approved"
                  ? "success"
                  : row?._agent?.eoInsurance?.isVerified === "Pending"
                  ? "primary"
                  : "secondary"
              }`}
            >
              EO
            </Badge>
            {/* <Badge
              color={`${
                row?._agent?.w9Credentials?.isVerified === "Approved"
                  ? "success"
                  : row?._agent?.w9Credentials?.isVerified === "Pending"
                  ? "primary"
                  : "secondary"
              }`}
            >
              W-9
            </Badge> */}
          </div>

          //
          //             // row?_agent?.titleProducerLicense?.isVerified === "Approved" &&
          //             // row?_agent?.mdTitleProducerLicense?.isVerified === "Approved" &&
          //             // row?_agent?.nyTotalCloser?.isVerified === "Approved" &&

          //             // row?_agent?.lssCertification?.isVerified === "Approved" &&
          //             // row?_agent?.w9Credentials?.isVerified === "Approved" }  */}
        );
      }

      case "assign": {
        return (
          <Button
            color="link"
            className="h-auto"
            disabled={row.status === "Rejected"}
            onClick={() => this._updateClosingDetailsById(row)}
          >
            {this.state?.closingDetails?.isSavedAsDraft ? "Publish" : "Assign"}
          </Button>
        );
      }
      case "totalRating": {
        return row?._agent?.totalRating ? (
          <div className="text-underline cursorPointer">
            {row?._agent?.totalRating}
          </div>
        ) : (
          "N/A"
        );
      }
      case "amount": {
        return row.status === "Counter"
          ? "$" + row.counterAmount
          : row.status === "Accepted"
          ? "$" + row.biddingAmount
          : "N/A";
      }

      // case "action": {
      //   return (
      //     <Button
      //       className="markPaid"
      //       disabled={row.status === "Rejected"}
      //       onClick={() => this._updateClosingDetailsById(row)}
      //     >
      //       Assign
      //     </Button>
      //   );
      // }
      case "appointmentTime": {
        return row.status === "Counter" ? (
          <>
            <div style={{ width: 120, whiteSpace: "normal" }}>
              {formatDateAsPerTimeZOne(
                cell,
                row?._closing?.closingAddress?.timeZone
              )}
            </div>
            <Badge color="primary">
              {getTimeZoneAbbr(cell, row?._closing?.closingAddress?.timeZone)}
            </Badge>
          </>
        ) : (
          "N/A"
        );
      }

      case "distance": {
        return <span>{getMiles(row?._agent?.dist?.calculated)}</span>;
      }

      case "address": {
        console.log(row);
        return row?._agent?.address ? (
          row?._agent?.address?.city !== "" &&
          row._agent?.address?.state !== "" ? (
            <>
              {row._agent?.address?.line1
                ? `${row._agent?.address?.line1}, `
                : ""}
              {row._agent?.address?.line2
                ? `${row._agent?.address?.line2}, `
                : ""}
              {row._agent?.address?.city
                ? `${row._agent?.address?.city}, `
                : ""}
              {row._agent?.address?.state
                ? `${row._agent?.address?.state}, `
                : ""}
              {row._agent?.address?.zip ? `${row._agent?.address?.zip}` : ""}
            </>
          ) : (
            "N/A"
          )
        ) : (
          "N/A"
        );
      }

      default: {
        return cell || "N/A";
      }
    }
  };

  _updateClosingDetailsById = (row) => {
    const { formFields } = deepClone(this.state);

    if (formFields.offeredFee.value?.trim()) {
      this._manageLoading("showTableLoading", true);
      let payload = {
        agentId: row?._agent?.id ? row?._agent?.id : row.id,
        assignAgentBiddingAppointmentTime:
          row.biddingStatus === "Counter" ? row.appointmentTime : undefined,
        agentFee:
          row.biddingStatus === "Counter"
            ? row.counterAmount
            : Number(formFields.offeredFee.value),
      };

      updateClosingDetailsById({ id: this.props.data?._id, payload })
        .then((res) => {
          if (res?.closing?.internalNotes) {
            const payload = {
              text: res?.closing?.internalNotes,
              closingId: res?.closing?.id,
              isVisibleClient: false,
              isVisibleAgent: false,
              isInternal: true,
            };
            createChatLog(payload);
          }

          showToast("Assigned Successfully", "success");
          this._getAllSigningCompanyBidding(this.props.data._id);
          this._manageLoading("showTableLoading", false);
          this._resetModal();
          this.props.toggle(null);
          this.props.resetDetails();
        })
        .catch((error) => {
          errorHandler(error);
          this._manageLoading("showTableLoading", false);
        });
    } else {
      showToast("Offered Price Required", "error");
    }
  };

  _setSelectedRows = (agentSelectedRows = []) => {
    this.setState({
      agentSelectedRows,
    });
  };

  _paginate = (pageNumber = 1, pageSize = 10) => {
    const { tableConfigAgent } = this.state;
    tableConfigAgent.skip = (pageNumber - 1) * pageSize;
    tableConfigAgent.limit = pageSize;
    tableConfigAgent["pageNumber"] = pageNumber;
    this.setState({ tableConfigAgent }, () => {
      this._getAllAgents();
    });
  };

  _paginateBidding = (pageNumber = 1, pageSize = 10) => {
    const { tableConfigResponse } = this.state;
    tableConfigResponse.skip = (pageNumber - 1) * pageSize;
    tableConfigResponse.limit = pageSize;
    tableConfigResponse["pageNumber"] = pageNumber;
    this.setState({ tableConfigResponse }, () => {
      // this._getAllAgents();
      this._getAllSigningCompanyBidding();
    });
  };

  _onChangeFormField = (fieldName, value) => {
    const { formFields } = deepClone(this.state);

    if (fieldName === "offeredFee") {
      if (
        isNaN(value) ||
        (value.includes(".") && value.split(".")[1].length > 2)
      ) {
        return;
      }
    }
    if (fieldName === "agent") {
      if (value) {
        this._getAssignedAgentScheduleforClosing(value.value);
      }
    }

    formFields[fieldName].value = value;
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      this._prepareFilterData();
      this._persistFilter();
    });
  };

  _signingCompanyCreateClosingBidding = () => {
    let { agentSelectedRows, formFields } = deepClone(this.state);
    if (
      formFields?.offeredFee?.value &&
      formFields?.offeredFee?.value > -1 &&
      agentSelectedRows.length > 0
    ) {
      this._manageLoading("showTableLoading", true);
      let data = {
        biddingAmount: Number(formFields.offeredFee.value),
        agentId: agentSelectedRows.map((item) => item._id),
        closingId: this.props.data._id,
        bidInstructions: formFields.bidInstructions.value,
      };
      signingCompanyCreateClosingBidding(data)
        .then((res) => {
          console.log(res);
          showToast("Bid Created Successfully", "success");
          this._getAllAgents();
          this._manageLoading("showTableLoading", false);
          this._setSelectedRows();
          // this._resetModal();
          // this.props.toggle(null);
        })
        .catch((error) => {
          errorHandler(error);
          this._manageLoading("showTableLoading", false);
        });
    } else {
      // formFields?.offeredFee?.value &&
      //   formFields?.offeredFee?.value.trim().length === 0 &&
      //   showToast("Offered Price Required", "error");
      // agentSelectedRows.length === 0 &&
      //   showToast("Please Select Agent", "error");

      if (formFields?.offeredFee?.value > -1) {
        showToast("Offered Price Required", "error");
      }

      if (agentSelectedRows.length === 0) {
        showToast("Please Select at least one Agent", "error");
      }
    }
  };

  _removeAgent = () => {
    const { formFields } = deepClone(this.state);
    if (formFields.agent?.value?.value?.trim()) {
      this._manageLoading("statusChangeLoading", true);

      removeAgentFromClosingBySigningCompany(this.props.data._id)
        .then((res) => {
          showToast("Removed Successfully", "success");
          this._manageLoading("statusChangeLoading", false);
          this._resetModal();
          this.props.resetDetails();
          this.props.toggle(null);
        })
        .catch((error) => {
          errorHandler(error);
          this._manageLoading("statusChangeLoading", false);
        });
    }
  };

  _updateAgent = () => {
    const { formFields } = deepClone(this.state);

    if (formFields.agent.value?.value?.trim()) {
      if (formFields.offeredFee.value?.trim()) {
        this._manageLoading("statusChangeLoading", true);
        let payload = {
          status: this.props.data.status === "CCA" ? "Pending" : undefined,
          agentFee: Number(formFields.offeredFee.value),
          agentId: formFields.agent.value.value,
        };
        updateClosingDetailsById({ id: this.props.data._id, payload })
          .then((res) => {
            showToast("Updated Successfully", "success");
            this._manageLoading("statusChangeLoading", false);
            this._resetModal();
            this.props.resetDetails();
            this.props.toggle(null);
          })
          .catch((error) => {
            errorHandler(error);
            this._manageLoading("statusChangeLoading", false);
          });
      } else {
        showToast("Offered Price Required", "error");
      }
    }
  };

  _onToggleStandardFeeModal = (isOpen = false, data = null) => {
    this.setState({
      standardFeeModal: {
        isOpen,
        data,
      },
    });
  };

  _onToggleAgentDetailModal = (isOpen = false, data = null) => {
    this.setState({
      agentDetailModal: {
        isOpen,
        data,
      },
    });
  };

  _toggleAutomation = () => {
    this.setState({ isActive: !this.state.isActive });
  };

  handleAutomation = () => {
    this.setState((prevState) => ({
      startAutomation: !prevState.startAutomation,
    }));
  };

  handlePause = () => {
    this.setState((prevState) => ({
      PauseAutomation: !prevState.PauseAutomation,
    }));
  };

  _handleBiddingFormChange = (fieldName, value) => {
    console.log("first", fieldName, value);
    let newBiddingForm = { ...this.state.bidAutomationSetting };
    // let newIsDirty={...isDirty}
    newBiddingForm[fieldName] = value;
    this.setState(
      {
        bidAutomationSetting: newBiddingForm,
      },
      () => {
        this._prepareFilterData();
        this._persistFilter();
      }
    );
    // newIsDirty[key] = true;
    // setBiddingForm(newBiddingForm);
    // setIsDirty(newIsDirty);
    // _validateForm(newEmailData, newIsDirty);
  };

  _startAutomation = () => {
    const { formFields, filters, bidAutomationSetting } = deepClone(this.state);

    if (formFields?.offeredFee?.value?.toString()?.trim()) {
      if (this.state.agents) {
        this._manageLoading("statusChangeLoading", true);
        let payload = {
          automationType: bidAutomationSetting.automationType,
          isCounter: bidAutomationSetting.isCounter,
          biddingAmount: formFields.offeredFee.value,
          miles: filters.miles,
          bidInstructions: formFields.bidInstructions.value,
          type: this.state.filterAgent.type
            ? this.state.filterAgent.type
            : undefined,
          backgroundCheck: this.state.filterAgent.backgroundCheck
            ? this.state.filterAgent.backgroundCheck
            : undefined,
          autoAssignCounterResponse:
            bidAutomationSetting.autoAssignCounterResponse,
          shouldInclude:
            this.state.filterAgent.shouldInclude.length > 0
              ? this.state.filterAgent.shouldInclude.map((item) => item.value)
              : undefined,
        };
        console.log(bidAutomationSetting);
        // this._connectToSocket();
        startAutomationForClosing(this.props.data._id, payload)
          .then((res) => {
            showToast("Automation started Successfully", "success");
            this._manageLoading("statusChangeLoading", false);
            // this._getAllAgents();
            this._getSigningClosingById(this.props.data._id);

            // this._resetModal();
            // this.props.resetDetails();
            // this.props.toggle(null);
          })
          .catch((error) => {
            errorHandler(error);
            this._manageLoading("statusChangeLoading", false);
          });
      } else {
        showToast("No Agent is in the list", "error");
      }
    } else {
      showToast("Offered Fee is required", "error");
    }
  };

  _resumeAutomation = () => {
    const { formFields, filters, bidAutomationSetting } = deepClone(this.state);
    // if (formFields.agent.value?.trim()) {
    this._manageLoading("statusChangeLoading", true);

    let payload = {
      automationType: bidAutomationSetting.automationType,
      isCounter: bidAutomationSetting.isCounter,
      biddingAmount:
        formFields.offeredFee.value && formFields.offeredFee.value.trim().length
          ? formFields.offeredFee.value
          : undefined,
      miles: filters?.miles?.trim().length ? filters?.miles : undefined,
      bidInstructions: formFields.bidInstructions?.value?.trim().length
        ? formFields.bidInstructions.value
        : undefined,
      type: this.state.filterAgent.type
        ? this.state.filterAgent.type
        : undefined,
      backgroundCheck: this.state.filterAgent.backgroundCheck
        ? this.state.filterAgent.backgroundCheck
        : undefined,
      autoAssignCounterResponse: bidAutomationSetting.autoAssignCounterResponse,
      shouldInclude:
        this.state.filterAgent.shouldInclude.length > 0
          ? this.state.filterAgent.shouldInclude.map((item) => item.value)
          : undefined,
    };
    // this._connectToSocket();
    resumeAutomationForClosing(this.props.data._id, payload)
      .then((res) => {
        showToast("Automation Resumed Successfully", "success");
        this._manageLoading("statusChangeLoading", false);
        // this._getAllAgents();
        this._getSigningClosingById(this.props.data._id);

        // this._resetModal();
        // this.props.resetDetails();
        // this.props.toggle(null);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("statusChangeLoading", false);
      });
    // } else {
    //   showToast("No Agent is Available", "error");
    // }
  };

  _stopAutomation = () => {
    const { formFields } = deepClone(this.state);
    console.log("first", formFields.agent.value);

    this._manageLoading("statusChangeLoading", true);

    stopAutomationForClosing(this.props.data._id)
      .then((res) => {
        showToast("Automation Stopped Successfully", "success");
        this._manageLoading("statusChangeLoading", false);
        // this._resetModal();
        // this.props.resetDetails();
        // this.props.toggle(null);
        this._getAllAgents();
        this._getSigningClosingById(this.props.data._id);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("statusChangeLoading", false);
      });
  };

  _handleAutomation = () => {
    const { closingDetails } = deepClone(this.state);
    // console.log(closingDetails.automationStatus);
    if (closingDetails?.automationStatus === "off") {
      this._startAutomation();
    } else if (closingDetails?.automationStatus === "stop") {
      this._resumeAutomation();
    } else if (closingDetails?.automationStatus === "start") {
      this._stopAutomation();
    }
  };

  _onChangeAgentFilter = (fieldName, value, isDelay = false) => {
    const { filterAgent } = deepClone(this.state);
    if (isDelay) clearTimeout(this.searchTimer);

    filterAgent[fieldName] = value;

    this.setState({ filterAgent }, () => {
      if (isDelay) {
        this.searchTimer = setTimeout(() => {
          // this._paginate();
          this._getAllAgents();
          this._getAgentWithZipcode();
          this._prepareFilterData();
          this._persistFilter();
        }, 1000);
      } else {
        // this._paginate();
        this._getAllAgents();
        this._getAgentWithZipcode();
        this._prepareFilterData();
        this._persistFilter();
      }
    });
  };

  _connectToSocket = () => {
    connectToSocket()
      .then((socket) => {
        this.setState(
          { socket }
          // () => this._getCounterAutomation(),
          // this._getAcceptOrRejectAutomation(),
          // this._getBidAutomation()
        );
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _disconnectToSocket = () => {
    disconnectToSocket()
      .then((flag) => {
        console.log("disconnected>>", flag);
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _getCounterAutomation = () => {
    try {
      // let { agents } = this.state;
      newSocket.on(
        `automation/counter/${this.props?.data?._id}`,
        (response) => {
          console.log("1111", response);
          if (response.error) {
            errorHandler(response.error);
          } else {
            const updatedAgents = this.state.agents.map((each) => {
              if (each._id === response._agent) {
                // return { ...each, counterAmount: response.counterAmount };
                each.counterAmount = response?.counterAmount;
                each.appointmentTime = response?.appointmentTime;
                each.biddingStatus = "Counter";
              }

              return each;
            });
            this.setState({ agents: updatedAgents });
          }
        }
      );
    } catch (error) {
      console.log("error>>", error);
    }
  };

  _isSentToAllAgents = () => {
    try {
      newSocket.on(
        `automation/socketresponse/${this.props?.data?._id}`,
        (response) => {
          console.log("1111", response);
          if (response.error) {
            errorHandler(response.error);
          } else {
            this.setState({
              closingDetails: {
                ...this.state.closingDetails,
                automationStatus:
                  response?.isAllBidsSent || response?.isAutomationStopped // changed as per requirement
                    ? "stop"
                    : "off",
              },
            });
            // this.setState({ agents: updatedAgents });
          }
        }
      );
    } catch (error) {}
  };

  _getAcceptOrRejectAutomation = () => {
    // let { agents } = this.state;

    try {
      newSocket.on(
        `automation/acceptOrReject/${this.props?.data?._id}`,
        (response) => {
          // if (response.doSkip) return;

          if (response.error) {
            errorHandler(response?.error);
          } else {
            const updatedAgents = this.state.agents.map((each) => {
              if (each._id === response._agent) {
                // return { ...each, biddingStatus: response.status };
                each.biddingStatus = response?.status;
              }

              return each;
            });
            this.setState({ agents: updatedAgents });
          }
        }
      );
    } catch (error) {
      console.log("error>>", error);
    }
  };

  _getBidAutomation = () => {
    // let { agents } = deepClone(this.state);
    try {
      newSocket.on(`automation/${this.props?.data?._id}`, (response) => {
        // if (response.doSkip) return;
        console.log("connected", response);

        let updatedAgents = this.state.agents.map((each) => {
          if (each._id === response._agent) {
            // each.biddingStatus = "Sent";
            return { ...each, biddingStatus: "Sent" };
          }
          console.log("9999", each);
          return each;
        });
        this.setState({ agents: updatedAgents });
        console.log("idsd", updatedAgents);

        if (response.error) {
          errorHandler(response.error);
        }
        //  else {
        //   //   const updatedAgents = response.map((item) => {
        //   //     const obj = agents.find((each) => each._id === item);
        //   //     return { ...obj, biddingStatus: "Sent" };
        //   // });
        //   // agents.map(obj => updatedAgents.find(o => o.id === obj.id) || obj);
        //   // console.log("idsd", updatedAgents);
        //   // this.setState({ agents: updatedAgents });
        //   //  this.setState({agents})

        //   // agents = agents.map((item) => {
        //   //   if (response.indexOf(String(item.id)) > -1) {
        //   //     item.biddingStatus = "Sent"
        //   //   }
        //   //   return item
        //   // });

        //    agents = agents.map((each) => {
        //     if (each._id === response._agent) {
        //       each.biddingStatus = "Sent";
        //     }
        //     console.log("9999",each)
        //     return each;
        //   });
        //   this.setState({ agents });
        //   console.log("idsd", agents);

        //   // this.setState({ agents });
        // }
      });
    } catch (error) {
      console.log("error>>", error);
    }
  };

  _cardHeaderFormat = (cell, row, header) => {
    // console.log("23456", row, cell, header);
    switch (header) {
      case "name": {
        return row?.name ? (
          <>
            <div className="tableUserInfo">
              {!row?.isFavourite ? (
                <img
                  onClick={() => this._markAgentAsFavorite(row.id)}
                  src={require("../../../assets/img/heart-blank.svg").default}
                  alt="fav"
                  height={12}
                />
              ) : (
                // <i
                //   className="fa fa-heart-o"
                //   onClick={() => this._markAgentAsFavorite(row.id)}
                // ></i>
                <img
                  onClick={() => this._markAgentAsFavorite(row.id)}
                  src={
                    require("../../../assets/img/heart-fillBlue.svg").default
                  }
                  alt="fav"
                  height={12}
                />
                // <i
                //   className="fa fa-heart"
                //   style={{ color: "#28A745" }}
                //   onClick={() => this._markAgentAsFavorite(row.id)}
                // ></i>
              )}
              <div className="userImage ml-2">
                <img
                  className="clientImg"
                  // src={
                  //   require("../../../assets/img/default_user_icon.svg").default
                  // }
                  src={
                    row?.profilePicture
                      ? row?.profilePicture
                      : require("../../../assets/img/default_user_icon.svg")
                          .default
                  }
                  loading="lazy"
                  alt="avatar"
                />
              </div>
              <div className="userContent">
                <span
                  className="tableLink"
                  onClick={() => this._onToggleAgentDetailModal(true, row)}
                >
                  {capitalize(row?.name?.first + " " + row?.name?.last)}
                </span>
              </div>
            </div>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _displayStandardFee = (row) => {
    let calculatedStandardFee =
      Object.keys(this.state?.closingDetails)?.length > 0 &&
      row?.standardFee &&
      Object.keys(row?.standardFee)?.length > 0 &&
      this.state?.closingDetails?.loanType.reduce(
        (acc, cur) => acc + row?.standardFee[cur],
        0
      );

    let standardFee =
      this.state?.closingDetails?.signingType === "RON"
        ? row?.standardFee &&
          Object.keys(row?.standardFee)?.length > 0 &&
          calculatedStandardFee +
            row?.standardFee["ForeignNationalSigner"] +
            row?.standardFee["WitnessFee"]
        : calculatedStandardFee;

    return standardFee ? <> ${standardFee} </> : "N/A";
  };

  _cardDataFormat = (row) => {
    const { closingDetails } = deepClone(this.state);

    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Location </Label>
            <span
              style={{
                marginLeft: 5,
                fontWeight: 600,
              }}
            >
              {row?.address ? (
                <>
                  <div style={{ width: 120 }}>
                    {row?.address?.city !== "" && row?.address?.state !== "" ? (
                      <>
                        {row?.address?.line1 ? `${row?.address?.line1}, ` : ""}
                        {row?.address?.line2 ? `${row?.address?.line2}, ` : ""}
                        {row?.address?.city ? `${row?.address?.city}, ` : ""}
                        {row?.address?.state ? `${row?.address?.state}, ` : ""}
                        {row?.address?.zip ? `${row?.address?.zip}` : ""}
                      </>
                    ) : (
                      "N/A"
                    )}
                  </div>
                </>
              ) : (
                "N/A"
              )}
            </span>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Distance </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.dist?.calculated > -1
                ? getMiles(row?.dist?.calculated) + " Miles"
                : "N/A"}
            </div>
          </div>
          <div className="dataWrap">
            <Label>Credential: </Label>
            <div className="">
              {/* <Badge
                color={`${
                  row?.idVerification?.isVerified === "Approved"
                    ? "success"
                    : row?.idVerification?.isVerified === "Pending" &&
                      row?.idVerification?.url
                    ? "warning"
                    : "danger"
                }`}
                className="outline"
                pill
              >
                ID
              </Badge> */}

              <Badge
                color={`${
                  row?.notaryLicense?.length > 0 &&
                  row?.notaryLicense?.filter(
                    (item) =>
                      item?.isVerified === "Approved" &&
                      closingDetails?.closingAddress?.state === item?.state
                  ).length
                    ? "success"
                    : // <i className="fa fa-check text-success" />
                    row?.notaryLicense?.length > 0 &&
                      row?.notaryLicense?.filter(
                        (item) => item?.isVerified === "Pending" && item?.url
                        // row?.notaryLicense?.url
                      ).length
                    ? "warning"
                    : "danger"
                }`}
                className="outline"
                pill
              >
                NL
              </Badge>

              <Badge
                color={`${
                  row?.backgroundCheckReport?.isVerified === "Approved"
                    ? "success"
                    : row?.backgroundCheckReport?.isVerified === "Pending" &&
                      row?.backgroundCheckReport?.url
                    ? "warning"
                    : "danger"
                }`}
                className="outline"
                pill
              >
                BGC
              </Badge>

              <Badge
                color={`${
                  row?.eoInsurance?.isVerified === "Approved"
                    ? "success"
                    : row?.eoInsurance?.isVerified === "Pending" &&
                      row?.eoInsurance?.url
                    ? "warning"
                    : "danger"
                }`}
                className="outline"
                pill
              >
                EO
              </Badge>

              {row?.ronCredentials?.length > 0 &&
              row?.ronCredentials?.filter(
                (item) => item?.isVerified === "Approved"
                /* &&
                  closingDetails?.closingAddress?.state === item?.state */
              )?.length ? (
                <Badge
                  color={`${
                    row?.ronCredentials?.length > 0 &&
                    row?.ronCredentials?.filter(
                      (item) => item?.isVerified === "Approved"
                      // &&
                      // closingDetails?.closingAddress?.state === item?.state
                    ).length
                      ? "success"
                      : // <i className="fa fa-check text-success" />
                        null
                    // row?.ronCredentials?.length > 0 &&
                    //   row?.ronCredentials?.filter(
                    //     (item) => item?.isVerified === "Pending" && item?.url
                    //     // row?.notaryLicense?.url
                    //   )?.length
                    // ? "warning"
                    // : // <i className="fa fa-check text-warning" />
                    //   "danger"
                  }`}
                  className="outline"
                  pill
                >
                  RON
                </Badge>
              ) : null}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Rating: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.totalRating ? (
                <>
                  <img
                    src={require("../../../assets/img/ratingFill.svg").default}
                    alt="rating"
                  />
                  <img
                    src={require("../../../assets/img/ratingFill.svg").default}
                    alt="rating"
                  />
                  <img
                    src={require("../../../assets/img/ratingFill.svg").default}
                    alt="rating"
                  />
                  <img
                    src={require("../../../assets/img/ratingFill.svg").default}
                    alt="rating"
                  />
                  <img
                    src={require("../../../assets/img/rating.svg").default}
                    alt="rating"
                  />
                  <div className="text-underline cursorPointer">
                    {row?.totalRating}
                  </div>
                </>
              ) : (
                <div className="text-underline cursorPointer">N/A</div>
              )}

              <div className="mt-2">
                <Badge id="blacklisted" color="secondary">
                  {row?.blockingCompanysCount}
                </Badge>

                <UncontrolledTooltip placement="bottom" target="blacklisted">
                  No. of companies that have blacklisted the agent
                </UncontrolledTooltip>
                <Badge color="success" id="prefered">
                  {row?.companysMarkAsFavouriteCount}
                </Badge>
                <UncontrolledTooltip placement="bottom" target="prefered">
                  No. of companies that have prefered the agent
                </UncontrolledTooltip>
              </div>
            </div>
          </div>
          <div className="dataWrap">
            <Label>Standard Fee: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {this._displayStandardFee(row)}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Create/Publish: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {
                <Button
                  color="link"
                  size="md"
                  className="p-0"
                  disabled={row?.status === "Rejected"}
                  onClick={() =>
                    this._checkAllFieldsAreAvailable() &&
                    this._updateClosingDetailsById(row)
                  }
                >
                  {/* Assign */}
                  {/* {console.log("1111", row)} */}

                  {this._checkAllFieldsAreAvailable() ? (
                    this.state?.closingDetails?.isSavedAsDraft ? (
                      "Publish"
                    ) : (
                      "Assign"
                    )
                  ) : (
                    // "Publish"
                    <Link
                      to={`create-closing?${this.state?.closingDetails?._id}&${row?._id}&${row?.appointmentTime}&${row?.counterAmount}`}
                    >
                      Create
                    </Link>
                  )}
                </Button>
              }
            </div>
          </div>
          <div className="dataWrap">
            <Label>Response: </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {
                <>
                  {row?.biddingStatus === "Sent" ? (
                    <Badge color="primary" className="outline" pill>
                      Sent
                    </Badge>
                  ) : row?.biddingStatus === "Accepted" ? (
                    <Badge color="success" className="outline" pill>
                      Accepted
                    </Badge>
                  ) : row?.biddingStatus === "Counter" ? (
                    <div className="counter">
                      <Badge color="warning" className="outline" pill>
                        Counter
                      </Badge>
                      <span className="text">(${row?.counterAmount})</span>
                      {/* <span className="text"> */}
                      {row?.biddingStatus === "Counter" && (
                        <>
                          <div style={{ width: 120, whiteSpace: "normal" }}>
                            {formatDateAsPerTimeZOne(
                              row?.appointmentTime,
                              this.props.data?.closingAddress?.timeZone
                            )}
                          </div>
                          <Badge color="primary" className="outline" pill>
                            {getTimeZoneAbbr(
                              row?.appointmentTime,
                              this.props.data?.closingAddress?.timeZone
                            )}
                          </Badge>
                        </>
                      )}
                      {/* </span> */}
                      {/* <span className="text">(
                  {formatDateAsPerTimeZOne(
                    row?.appointmentTime,
                    row?.address?.timeZone
                  )}
                  )</span> */}
                    </div>
                  ) : row?.biddingStatus === "Rejected" ? (
                    <Badge color="danger" className="outline" pill>
                      Decline
                    </Badge>
                  ) : (
                    "N/A"
                  )}
                </>
              }
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap">
            <Label>Notes </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              <Button
                color="link"
                className="actionBtn"
                onClick={() =>
                  this._onToggleAddEditNotesModal(
                    !this.state.addEditNotesModal.isOpen,
                    row
                  )
                }
              >
                <SvgIcons
                  type="notes"
                  color={row?.isNotesExists ? "blue" : "black"}
                />
              </Button>
            </div>
          </div>
        </li>
      </ul>
    );
  };

  _onToggleAddEditNotesModal = (isOpen, data = "") => {
    this.setState({
      addEditNotesModal: {
        isOpen,
        data,
      },
    });
  };

  searchOnChange = debounce((value) => {
    // console.log(value);
    if (value.length > 0) {
      this._getAgentWithZipcode({ search: value });
    }
  }, 1000);

  render() {
    const {
      agents,
      agentsHeaderKeys,
      agentSelectedRows,
      loading,
      agentsTotalCount,
      tableConfigAgent,
      formFields,
      filters,
      standardFeeModal,
      agentDetailModal,
      closingDetails,
      dropdownAgentList,
      scheduleClosing,
      scheduleEvent,
      isOnWorkingTime,
      isOnLeave,
      bidAutomationSetting,
      filterAgent,
      agentsCardHeaderKeys,
      agentSuggestedTotalCount,
      agentsZipCodePage,
      addEditNotesModal,
    } = this.state;
    console.log("state", this.state);
    console.log("addEditNotesModal", addEditNotesModal);
    return (
      <>
        <Modal
          isOpen={this.props.isOpen}
          toggle={() => this._closeModal()}
          centered
          className="modal-xl modal-dialog-scrollable"
        >
          <ModalHeader toggle={() => this._closeModal()}>
            Closing Assignment
          </ModalHeader>
          <ModalBody>
            <div className="appointmentInfo">
              <Label style={{ fontWeight: 500, fontSize: 12 }}>
                Appointment Time:{" "}
                {/* <>
                  {formatDateAsPerTimeZOne(
                    closingDetails?.appointmentDate,
                    closingDetails?.closingAddress?.timeZone
                  )}
                </>{" "}
                <span style={{ fontWeight: 300 }}>
                  {getTimeZoneAbbr(
                    closingDetails?.appointmentDate,
                    closingDetails?.closingAddress?.timeZone
                  )}
                </span> */}
                <DateTimeShowWithClosingDetails
                  closingDetails={closingDetails}
                />{" "}
                <span style={{ fontWeight: 300 }}>
                  {getTimeZoneAbbr(
                    closingDetails?.appointmentDate,
                    closingDetails?.closingAddress?.timeZone
                  )}
                </span>
              </Label>
              {!this.props.userData?.user?.isTitleCompany && (
                <Label style={{ fontWeight: 500, fontSize: 12 }}>
                  Company Fee :
                  {closingDetails?.signingCompanyFee !== undefined
                    ? formatCurrencyValue(closingDetails?.signingCompanyFee)
                    : "N/A"}
                </Label>
              )}
            </div>
            <Row>
              <Col xl={4} lg={6}>
                <FormGroup className="floatingLabel">
                  <div className="custom-select-wrapper">
                    <Input
                      type="select"
                      placeholder=" "
                      value={filterAgent.type}
                      onChange={(event) =>
                        this._onChangeAgentFilter("type", event.target.value)
                      }
                    >
                      <option value="">All</option>
                      <option value="Notary">Notary</option>
                      <option value="Attorney">Attorney</option>
                    </Input>
                    <Label>Type</Label>
                  </div>
                </FormGroup>
              </Col>
              <Col xl={4} lg={6}>
                <FormGroup className="floatingLabel withInputGroup">
                  <InputGroup>
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <img
                          src={
                            require("../../../assets/img/dollar.svg").default
                          }
                          alt="doller"
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder=" "
                      value={formFields.offeredFee.value}
                      name="offeredFee"
                      onChange={(event) =>
                        this._onChangeFormField(
                          "offeredFee",
                          event.target.value
                        )
                      }
                    />
                    <Label>Offered Fee</Label>
                  </InputGroup>
                </FormGroup>
              </Col>
              <Col xl={4} lg={6}>
                <FormGroup
                  className={`withChips floatingLabel valueAdded`}
                  style={{ zIndex: 999 }}
                >
                  <CreatableSelect
                    isClearable
                    placeholder="Select"
                    className="customMultiSelect"
                    isMulti
                    components={animatedComponents}
                    // value={formFields.loanType.value}
                    options={[
                      { label: "Notary License", value: "notaryLicense" },
                      { label: "NNA Cert", value: "nnaCertification" },
                      {
                        label: "Background Check Report",
                        value: "backgroundCheckReport",
                      },
                      // { label: "LSS Cert", value: "lssCertification" },
                      {
                        label: "Notary Training Certification",
                        value: "lssCertification",
                      },
                      {
                        label: "Title Producer License",
                        value: "titleProducerLicense",
                      },
                      { label: "E&O Insurance", value: "eoInsurance" },
                    ]}
                    onChange={(event) =>
                      this._onChangeAgentFilter("shouldInclude", event)
                    }
                  />
                  <Label style={{ zIndex: 3 }}>Should Include</Label>
                </FormGroup>
                {/* <CustomInput
                  className="mt-2"
                  type="checkbox"
                  checked={filterAgent.backgroundCheck}
                  id="exampleCustomCheckbox"
                  onChange={(event) =>
                    this._onChangeAgentFilter(
                      "backgroundCheck",
                      event.target.checked
                    )
                  }
                  label="Background Check"
                /> */}
              </Col>
              <Col md={12}>
                <FormGroup
                  className={`mb-0 floatingLabel reactQuill ${
                    (formFields.bidInstructions.value &&
                      formFields.bidInstructions.value !== "<p><br></p>") ||
                    this.state.isFocusTextEditor
                      ? "valueAdded"
                      : ""
                  }`}
                >
                  <TextEditor
                    content={formFields.bidInstructions.value}
                    onChange={(event) =>
                      this._onChangeFormField("bidInstructions", event)
                    }
                    onFocus={() => this.setState({ isFocusTextEditor: true })}
                    onBlur={() => this.setState({ isFocusTextEditor: false })}
                  />
                  <Label>Instructions</Label>
                </FormGroup>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={12}>
                <div className="assingedAgent">
                  {/* <Select
                    className="customSelect"
                    options={dropdownAgentList}
                    value={dropdownAgentList.filter(
                      (item) => formFields.agent.value === item.value
                    )}
                    onChange={(event) =>
                      this._onChangeFormField("agent", event.value)
                    }
                  /> */}
                  <div className="customSelect">
                    <InfiniteScrollDropdown
                      optionsConfig={dropdownAgentList}
                      value={formFields.agent.value}
                      onChangeFunc={(event) =>
                        this._onChangeFormField("agent", event)
                      }
                      onInputChangeFunc={(value, action) => {
                        if (action === "menu-close") {
                          this._getAgentWithZipcode({ value });
                        }

                        if (action === "input-change") {
                          this.searchOnChange(value);
                        }
                      }}
                      isLoading={loading?.agentSearchLoading}
                      shouldMenuShouldScrollIntoView={true}
                      updateOptionsConfig={(payload) =>
                        this._getAgentWithZipcode()
                      }
                      totalDataCount={agentSuggestedTotalCount}
                      totalDataPages={agentsZipCodePage.page}
                      dropdownPayload={agentsZipCodePage}
                      setDropdownPayload={(newDropDownPayload) =>
                        this.setState(
                          {
                            agentsZipCodePage: newDropDownPayload,
                          },
                          () => this._getAgentWithZipcode(newDropDownPayload)
                        )
                      }
                      isMulti={false}
                      // isClearable={true}
                      placeholder="Agent"
                      isDisabled={
                        closingDetails?.status !== "Pending" ||
                        closingDetails?.isSavedAsDraft
                      }
                    />
                  </div>

                  <div>
                    {closingDetails?._agent &&
                      closingDetails.status !== "Closed" &&
                      closingDetails.status !== "DNC" && (
                        <Button
                          color="danger"
                          className="fs-12 mr-3"
                          onClick={this._removeAgent}
                          disabled={loading.statusChangeLoading}
                        >
                          Remove Agent
                        </Button>
                      )}

                    <Button
                      color="primary"
                      className="fs-12"
                      onClick={this._updateAgent}
                      disabled={
                        loading.statusChangeLoading ||
                        closingDetails.status === "Closed"
                        // closingDetails.status === "DNC"
                        // closingDetails?.tBD
                      }
                    >
                      Save
                    </Button>
                  </div>
                </div>
                <div className="alreadyAssinged">
                  <h3>Agent Schedule</h3>
                  <ul>
                    {scheduleClosing.length > 0 &&
                      scheduleClosing.map((item) => {
                        return (
                          <li>
                            <div>
                              <span>Closing No</span>
                              <span>#{item.closingNumber}</span>
                              {/* <span>#65489</span> */}
                            </div>
                            <div>
                              <span>Time</span>
                              {/* <span>12:00 AM EST</span> */}
                              <span>
                                <>
                                  {formatDateAsPerTimeZOne(
                                    item?.appointmentDate,
                                    item?.closingAddress?.timeZone
                                  )}
                                </>
                                , {""}
                                {getTimeZoneAbbr(
                                  item?.appointmentDate,
                                  item?.closingAddress?.timeZone
                                )}
                              </span>
                            </div>
                            <div>
                              <span>Zip</span>
                              <span>{item.closingAddress.zip}</span>
                            </div>
                          </li>
                        );
                      })}
                    {scheduleEvent.length > 0 &&
                      scheduleEvent.map((item) => {
                        return (
                          <li>
                            <div>
                              <span>Blocked time</span>
                              {/* <span>11:00 Am - 1:00 Pm</span> */}
                              <span>
                                {formatDateAsPerTimeZOne(
                                  item?.start,
                                  item?.timeZone
                                )}
                                -{" "}
                                {formatDateAsPerTimeZOne(
                                  item?.end,
                                  item?.timeZone
                                )}
                              </span>
                            </div>
                          </li>
                        );
                      })}
                  </ul>
                  {/* this will show If the Agent is not working on that day*/}
                  {this.props.data?._agent?.id?.length && isOnLeave ? (
                    <p>
                      <span>*</span>Agent not working on this date, please check
                      before assigning this Closing
                    </p>
                  ) : null}

                  {/* this will show  If the Agent is not working at the appointment time*/}
                  {isOnWorkingTime !== null &&
                    isOnWorkingTime !== undefined &&
                    !isOnWorkingTime && (
                      <p>
                        <span>*</span>Appointment Time is not in the working
                        hours set by the Agent, please check before assigning
                        this Closing
                      </p>
                    )}
                </div>
              </Col>
              <Col md={12}>
                <div className="assignWrapper">
                  <div>
                    <div className="assignWrap">
                      <h4>Auto Assign</h4>
                      <CustomInput
                        type="radio"
                        name="start"
                        id="off1"
                        label="Off"
                        inline
                        checked={
                          bidAutomationSetting.automationType === "off"
                            ? true
                            : false
                        }
                        onChange={(event) =>
                          this._handleBiddingFormChange("automationType", "off")
                        }
                      />
                      <CustomInput
                        type="radio"
                        name="start"
                        id="All"
                        label="All"
                        inline
                        checked={
                          bidAutomationSetting.automationType === "all"
                            ? true
                            : false
                        }
                        onChange={(event) =>
                          this._handleBiddingFormChange("automationType", "all")
                        }
                      />
                      <CustomInput
                        type="radio"
                        name="start"
                        id="Favorite"
                        label="Favorite"
                        inline
                        checked={
                          bidAutomationSetting.automationType === "favourites"
                            ? true
                            : false
                        }
                        onChange={(event) =>
                          this._handleBiddingFormChange(
                            "automationType",
                            "favourites"
                          )
                        }
                      />
                    </div>
                    <div className="assignWrap">
                      <h4>Assign Counter Response</h4>
                      <CustomInput
                        type="radio"
                        name="counter"
                        id="on"
                        label="on"
                        inline
                        checked={bidAutomationSetting.autoAssignCounterResponse}
                        onChange={(event) =>
                          !closingDetails?.isSavedAsDraft &&
                          this._handleBiddingFormChange(
                            "autoAssignCounterResponse",
                            true
                          )
                        }
                      />
                      <CustomInput
                        type="radio"
                        name="counter"
                        id="off"
                        label="off"
                        inline
                        checked={
                          !bidAutomationSetting.autoAssignCounterResponse
                        }
                        onChange={(event) =>
                          this._handleBiddingFormChange(
                            "autoAssignCounterResponse",
                            false
                          )
                        }
                      />
                    </div>
                  </div>
                  <div className="automationAction">
                    <Button
                      color="link"
                      onClick={() =>
                        this.props.history.push(`/signingcompany/settings`)
                      }
                    >
                      See Settings
                    </Button>
                    <Button
                      color="primary"
                      outline
                      onClick={() => this._handleAutomation()}
                    >
                      {/* ||closingDetails?.automationStatus==="start" */}
                      {closingDetails?.automationStatus === "off"
                        ? "Start"
                        : closingDetails?.automationStatus === "stop"
                        ? "Resume"
                        : "Stop"}{" "}
                      Automation
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xl={6}>
                <FormGroup className="floatingLabel">
                  <Input
                    type="text"
                    placeholder=" "
                    value={filters.search}
                    name="search"
                    onChange={(e) =>
                      this._filterOnChange("search", e.target.value)
                    }
                  />
                  <Label>Search</Label>
                </FormGroup>
              </Col>
              <Col xl={3}>
                <FormGroup className=" floatingLabel">
                  <div className="custom-select-wrapper">
                    <Input
                      type="select"
                      name="status"
                      value={
                        closingDetails?.signingType === "RON"
                          ? ""
                          : filters.miles
                      }
                      disabled={
                        closingDetails?.signingType === "RON" ? true : false
                      }
                      onChange={(e) =>
                        this._filterOnChange("miles", e.target.value)
                      }
                    >
                      {closingDetails?.signingType === "RON" ? (
                        <option value="">All</option>
                      ) : null}
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                      <option value="200">200</option>
                      <option value="500">500</option>
                    </Input>
                    <Label>Miles</Label>
                  </div>
                </FormGroup>
              </Col>
              <Col xl={3}>
                <FormGroup className="floatingLabel">
                  <div className="custom-select-wrapper">
                    <Input
                      type="select"
                      name="status"
                      value={filters.response}
                      onChange={(e) =>
                        this._filterOnChange("response", e.target.value)
                      }
                    >
                      <option value="all">All</option>
                      <option value="sent">Sent</option>
                      <option value="accepted">Accepted</option>
                      <option value="declined">Declined</option>
                      <option value="counter">Counter</option>
                    </Input>
                    <Label>Response</Label>
                  </div>
                </FormGroup>
              </Col>
            </Row>
            <h6
              style={{
                fontWeight: 400,
                fontSize: 14,
                marginBottom: 20,
                marginTop: 20,
              }}
            >
              Agents
            </h6>
            <div className="hideDesktop">
              <CustomCard
                isPageStartFromOne={true}
                pageNumber={tableConfigAgent.skip / tableConfigAgent.limit + 1}
                tableData={agents}
                headerKeys={agentsCardHeaderKeys}
                // bodyKeys={agentClosingCardBodyKeys}
                dataFormat={this._dataFormat}
                totalCount={agentsTotalCount}
                onPaginate={(pageNumber, pageSize) =>
                  this._paginate(pageNumber, pageSize)
                }
                columnSizeMD={12}
                rowSelection={true}
                selectedRows={agentSelectedRows}
                setSelectedRows={(selectedRows) =>
                  this._setSelectedRows(selectedRows)
                }
                // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
                showTableLoading={loading.showTableLoading}
                cardHeaderFormat={this._cardHeaderFormat}
                cardDataFormat={this._cardDataFormat}
              />
            </div>
            <div className="hideMobile">
              {agentsHeaderKeys && agentsHeaderKeys.length && (
                <CustomTable
                  striped
                  isPageStartFromOne={true}
                  tableData={agents}
                  headerKeys={agentsHeaderKeys}
                  dataFormat={this._dataFormat}
                  totalCount={agentsTotalCount}
                  pageNumber={
                    tableConfigAgent.skip / tableConfigAgent.limit + 1
                  }
                  rowSelection={true}
                  onPaginate={(pageNumber, pageSize) =>
                    this._paginate(pageNumber, pageSize)
                  }
                  onSortChange={(sortName, sortOrder) =>
                    this._onSortChange(sortName, sortOrder)
                  }
                  selectedRows={agentSelectedRows}
                  setSelectedRows={(selectedRows) =>
                    this._setSelectedRows(selectedRows)
                  }
                  showTableLoading={loading.showTableLoading}
                />
              )}
            </div>
            {/* <h6 className="mt-4 themeColor">Responses</h6>

          {biddingHeaderKeys && biddingHeaderKeys.length && (
            <CustomDataTable
              pageNumber={tableConfigResponse.pageNumber}
              tableData={biddings}
              headerKeys={biddingHeaderKeys}
              dataFormat={this._dataFormatBidding}
              totalCount={biddingsTotalCount}
              rowSelection={false}
              onPaginate={(pageNumber, pageSize) =>
                this._paginateBidding(pageNumber, pageSize)
              }
              onSortChange={(sortName, sortOrder) =>
                this._onSortChange(sortName, sortOrder)
              }
              showTableLoading={loading.showTableLoading}
            />
          )} */}
          </ModalBody>
          <ModalFooter>
            <Button
              size="lg"
              color="primary"
              outline
              onClick={() => this._closeModal()}
            >
              Close
            </Button>
            <Button
              color="primary"
              onClick={this._signingCompanyCreateClosingBidding}
            >
              Send Offer
            </Button>
          </ModalFooter>
        </Modal>
        <StandardFeeModal
          isOpen={standardFeeModal.isOpen}
          data={standardFeeModal.data}
          hideButton={true}
          toggle={this._onToggleStandardFeeModal}
          onSumbit={this._onSaveSchedule}
        />

        <AgentsDetailModal
          isOpen={agentDetailModal.isOpen}
          data={agentDetailModal.data}
          company={this.props.userData}
          // hideButton={true}
          toggle={this._onToggleAgentDetailModal}
        />

        {addEditNotesModal.isOpen ? (
          <AddEditNotesModal
            isOpen={addEditNotesModal.isOpen}
            data={addEditNotesModal.data}
            toggle={this._onToggleAddEditNotesModal}
          />
        ) : null}
      </>
    );
  }
}

export default ClosingAssignment;
