import React from "react";
import {
  Row,
  Col,
  Button,
  Input,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
  FormGroup,
  Badge,
  Label,
} from "reactstrap";
import {
  // getAllFiltersData,
  signingCompanyAgentSchedule,
  getAllAgentsWorkWith,
} from "../../../http/http-calls";
import {
  capitalize,
  showToast,
  deepClone,
  formatPhoneNumber,
  formatCurrencyValue,
  formatDateAsPerTimeZOne,
  getLoggedInUserId,
  formatAddressInSingleText,
  openGoogleMapOnNewTab,
  getTimeZoneAbbr,
  structureQueryParams,
  openUrlOnNewTab,
  formatOnlyDateMoment,
  getDropdownColor,
  refreshFunc,
  deleteLocalStorageData,
  errorHandler,
  // getDateRangeValueSummarie
} from "../../../helper-methods";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import { makeGetRequest } from "../../../http/http-service";
import moment from "moment";
import { agentClosingStatus, BASE_URL } from "../../../config";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomTable from "../components/CustomTable";
import CustomCard from "../components/CustomCard";

class AgentScheduleCompany extends React.Component {
  state = {
    dateRangeValue: null,
    filters: {
      agent: "",
      status: "Pending",
    },
    agents: [],
    agentScheduleCompanyTotalCount: 0,
    agentScheduleCompany: [],
    agentScheduleCompanyHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      {
        id: "appointmentDate",
        label: "Date",
        noSort: true,
        style: { width: 180 },
      },
      { id: "_borrower", label: "Signer", noSort: true, style: { width: 200 } },
      { id: "phone", label: "Phone", noSort: true, style: { width: 250 } },
      { id: "address", label: "Address", noSort: true, style: { width: 250 } },
      { id: "_client", label: "Title", noSort: true, style: { width: 180 } },
      { id: "signingCompanyFee", label: "Fee", noSort: true },
      { id: "status", label: "Status", noSort: true },
      // { id: "companyNotes", label: "External Notes", noSort: true, style: { width: 170 } },
      { id: "agentFee", label: "Agent Fee", noSort: true },
    ],
    agentScheduleCompanyCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    loading: {
      statusChangeLoading: false,
      showTableLoading: false,
      isEmailLoading: false,
    },
    isClassAdded: false,
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
    },
  };

  _onChangeDatePicker = (dateRangeValue) => {
    this.setState({ dateRangeValue }, () => {
      this._fetchAllAgentSchedule();
    });
  };

  filterShowMobile = () => {
    this.setState((prevState) => ({
      isClassAdded: !prevState.isClassAdded,
    }));
  };

  // _filterOnChange = (type, value) => {
  //   const { filters } = this.state;
  //   filters[type] = value;
  //   this.setState({ filters });
  // };

  _resetFilter = () => {
    const currentDate = "";
    const startOfDate = moment().startOf("day")._d;
    this.setState(
      {
        filters: {
          agent: "",
          status: "Pending",
        },
        agentScheduleCompany: [],
        dateRangeValue: [startOfDate, currentDate],
      },
      () => {
        this._fetchAllAgentSchedule();
        deleteLocalStorageData("agentschedule");
      }
    );
    refreshFunc("company-reporting-agent-schedule-responsive");
  };

  componentDidMount = () => {
    this._getAllFiltersData();
    // this._fetchAllAgentSchedule();
    this._setFilterDataFromLocalStorage();
    // const currentDate = moment().endOf("day")._d;
    const currentDate = "";
    const startOfDate = moment().startOf("day")._d;
    this.setState({
      dateRangeValue: [startOfDate, currentDate],
      // dateRangeValue: getDateRangeValueSummarie("day")
    });
    HeaderEventEmitter.subscribe("reset-filter", () => {
      this._resetFilter();
      refreshFunc("company-reporting-agent-schedule");
    });
    HeaderEventEmitter.subscribe("email", () => {
      this._downloadData("email");
      // this._resetFilter();
    });
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  _fetchAllAgentSchedule = async () => {
    try {
      this._manageLoading("showTableLoading", true);
      const { filters, dateRangeValue } = deepClone(this.state);

      const filterPayload = {};

      if (dateRangeValue) {
        filterPayload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
        filterPayload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
      }

      if (filters.agent && filters.agent.trim().length)
        filterPayload["agentId"] = filters.agent.trim();

      if (filters.status && filters.status.trim().length)
        filterPayload["status"] = filters.status.trim();

      let payload = {
        filters: filterPayload,
      };
      let response = await signingCompanyAgentSchedule(payload);

      this.setState({ agentScheduleCompany: response?.closings || [] });
      this._manageLoading("showTableLoading", false);
    } catch (error) {
      errorHandler(error);

      this._manageLoading("showTableLoading", false);
    }
  };

  _getAllFiltersData = () => {
    getAllAgentsWorkWith()
      .then((res) => {
        this.setState({ agents: res?.agents || [] });
      })
      .catch((error) => {
        errorHandler(error);
      });
  };

  _filterOnChange = (type, value) => {
    const { filters } = this.state;
    filters[type] = value;
    this.setState({ filters }, () => {
      this._fetchAllAgentSchedule();
      this._persistFilter();
    });
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      case "_borrower": {
        return (
          <>
            {/* show the below span only when there are more than 1 signers  */}
            {(cell && capitalize(cell[0]?.name?.full)) || "N/A"}{" "}
            {cell && cell?.length > 1 && (
              <>
                <span
                  id={`PopoverLegacy${row._id}`}
                  className="text-primary cursorPointer"
                >
                  +{cell.length - 1}
                </span>
                <UncontrolledPopover
                  trigger="legacy"
                  placement="bottom"
                  target={`PopoverLegacy${row._id}`}
                  className="signerPopover"
                >
                  <PopoverHeader>Signers</PopoverHeader>
                  <PopoverBody>
                    {cell.map((item) => (
                      <p>{capitalize(item.name.full)}</p>
                    ))}
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            )}
          </>
        );
      }
      case "appointmentDate": {
        return cell ? (
          <>
            {formatDateAsPerTimeZOne(cell, row.closingAddress.timeZone)}
            {", "}
            {getTimeZoneAbbr(cell, row.closingAddress.timeZone)}
          </>
        ) : (
          "N/A"
        );
      }
      case "phone": {
        return row?._borrower?.phone ? (
          <>
            <p style={{ marginBottom: 5 }}>
              {formatPhoneNumber(row?._borrower?.phone?.mobile)},
            </p>

            <p style={{ marginBottom: 5 }}>
              {formatPhoneNumber(row?._borrower?.phone?.home)},
            </p>

            <p style={{ marginBottom: 5 }}>
              {formatPhoneNumber(row?._borrower?.phone?.work)},
            </p>
          </>
        ) : (
          "N/A"
        );
      }
      case "_client": {
        return cell ? cell?.companyName : "N/A";
      }

      case "address": {
        return row.closingAddress ? (
          <>
            <span class="d-block">
              {formatAddressInSingleText(row.closingAddress)}
            </span>
            <Button
              className="pl-0"
              color="link"
              title={formatAddressInSingleText(row.closingAddress)}
              onClick={() => openGoogleMapOnNewTab(row.closingAddress)}
            >
              <img
                src={require("../../../assets/img/map-marker.svg").default}
                alt="address"
              />
            </Button>
          </>
        ) : (
          "N/A"
        );
      }
      case "signingCompanyFee":
      case "agentFee": {
        return (
          <div style={{ width: 50 }}>
            {cell ? formatCurrencyValue(cell) : "N/A"}
          </div>
        );
      }
      case "status": {
        // return cell ? <Badge color="warning">Pending</Badge> : "N/A";
        return cell ? (
          <>
            <Badge
              color={`${getDropdownColor(
                agentClosingStatus.find((obj) => obj.value === cell)?.label
              )}`}
              className="outline"
              pill
            >
              {agentClosingStatus.find((obj) => obj.value === cell)?.label ||
                "N/A"}
            </Badge>
          </>
        ) : (
          "N/A"
        );
      }
      // case "companyNotes": {
      //   return cell ? cell : "N/A";
      // }

      default: {
        return cell;
      }
    }
  };

  _paginate = (pageNumber, pageSize) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    this.setState({ tableConfig }, () => {
      this._fetchAllAgentSchedule();
    });
  };

  _downloadData = (action) => {
    const { agentScheduleCompany, filters, dateRangeValue } = this.state;

    if (!agentScheduleCompany.length) {
      showToast(`No data for ${action}`, "error");
      return;
    }

    const payload = {
      userId: getLoggedInUserId(),
    };

    if (dateRangeValue) {
      payload["startDate"] = formatOnlyDateMoment(dateRangeValue[0]);
      if (dateRangeValue !== null && dateRangeValue[1]) {
        payload["endDate"] = formatOnlyDateMoment(dateRangeValue[1]);
      }
    }

    if (filters.agent) payload["agentId"] = filters.agent;

    if (filters.status) payload["status"] = filters.status;

    payload["action"] = action;
    const queryParams = structureQueryParams(payload);

    const apiUrl = `${BASE_URL}/signingcompany/download/agent-schedule${queryParams}`;

    if (action === "download") {
      openUrlOnNewTab(apiUrl);
    } else if (action === "email") {
      // this._manageLoading("isEmailLoading", true);
      HeaderEventEmitter.dispatch("isEmailLoading", true);
      makeGetRequest(apiUrl, true)
        .then((res) => {
          // this._manageLoading("isEmailLoading", false);
          HeaderEventEmitter.dispatch("isEmailLoading", false);
          showToast("Email sent successfully.", "success");
        })
        .catch((error) => {
          // this._manageLoading("isEmailLoading", false);
          HeaderEventEmitter.dispatch("isEmailLoading", false);
          errorHandler(error);
        });
    }
  };

  _persistFilter = () => {
    const { filters, tableConfig } = this.state;
    if (
      (filters && (filters.agent || filters.status !== "")) ||
      tableConfig.pageNumber !== 1
    ) {
      let data = { filters, tableConfig };
      localStorage.agentschedule = JSON.stringify(data);
    } else {
      delete localStorage.agentschedule;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    // const { filters, tableConfig } = deepClone(this.state);

    if (localStorage && localStorage.agentschedule) {
      try {
        const filters = JSON.parse(localStorage.agentschedule);
        this.setState(
          { filters: filters?.filters, tableConfig: filters?.tableConfig },
          () => {
            this._fetchAllAgentSchedule();
          }
        );
      } catch (e) {
        this._fetchAllAgentSchedule();
      }
    } else {
      this._fetchAllAgentSchedule();
    }
  };

  _prepareFilterData = () => {
    const { filters } = deepClone(this.state);

    if (!filters.agent) delete filters.agent;
    if (filters.status === "") delete filters.status;

    return filters;
  };

  _cardHeaderFormat = (cell, row, header) => {
    console.log("23456", row, cell, header);
    switch (header) {
      case "name": {
        return (
          <>
            <div className="tableUserInfo">
              <div className="userContent">
                <span className="">Signer</span>

                <div
                  style={{
                    fontWeight: 600,
                  }}
                >
                  {(row?._borrower &&
                    capitalize(row?._borrower?.[0]?.name?.full)) ||
                    "N/A"}
                </div>
              </div>
            </div>
          </>
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Date </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.appointmentDate ? (
                <>
                  {formatDateAsPerTimeZOne(
                    row?.appointmentDate,
                    row.closingAddress.timeZone
                  )}
                  {", "}
                  {getTimeZoneAbbr(
                    row?.appointmentDate,
                    row.closingAddress.timeZone
                  )}
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap">
            <div className="d-flex align-items-center justify-content-between">
              <Label className="mb-0">Address </Label>
              <Button
                className="pl-0"
                color="link"
                title={formatAddressInSingleText(row?.closingAddress)}
                onClick={() => openGoogleMapOnNewTab(row?.closingAddress)}
              >
                <img
                  src={
                    require("../../../assets/img/Google_Maps_Logo_2020.svg")
                      .default
                  }
                  alt="address"
                />
              </Button>
            </div>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.closingAddress ? (
                <>{formatAddressInSingleText(row?.closingAddress)}</>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap">
            <Label>Fee </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.signingCompanyFee
                ? formatCurrencyValue(row?.signingCompanyFee)
                : "N/A"}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Agent Fee </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.agentFee ? formatCurrencyValue(row?.agentFee) : "N/A"}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap">
            <Label>Phone </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?._borrower?.phone ? (
                <>
                  <p style={{ marginBottom: 5 }}>
                    {formatPhoneNumber(row?._borrower?.phone?.mobile)},
                  </p>

                  <p style={{ marginBottom: 5 }}>
                    {formatPhoneNumber(row?._borrower?.phone?.home)},
                  </p>

                  <p style={{ marginBottom: 5 }}>
                    {formatPhoneNumber(row?._borrower?.phone?.work)},
                  </p>
                </>
              ) : (
                "N/A"
              )}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Title </Label>
            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?._client?.companyName ? row?._client?.companyName : "N/A"}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap d-flex align-items-center justify-content-between">
            <Label>Status</Label>
            {row?.status ? (
              <>
                <Badge
                  color={`${getDropdownColor(
                    agentClosingStatus.find((obj) => obj.value === row?.status)
                      ?.label
                  )}`}
                  className="outline"
                  pill
                >
                  {agentClosingStatus.find((obj) => obj.value === row?.status)
                    ?.label || "N/A"}
                </Badge>
              </>
            ) : (
              "N/A"
            )}
          </div>
        </li>
      </ul>
    );
  };

  render() {
    const {
      agentScheduleCompanyHeaderKeys,
      agentScheduleCompany,
      agentScheduleCompanyTotalCount,
      loading,
      dateRangeValue,
      agents,
      isClassAdded,
      filters,
      tableConfig,
      agentScheduleCompanyCardHeaderKeys,
    } = this.state;

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>Agent Schedule</h2>

            <div className="rightSide">
              <Button color="link" onClick={this._resetFilter}>
                <img
                  id="company-reporting-agent-schedule-responsive"
                  src={require("../../../assets/img/refresh.svg").default}
                  alt="refresh"
                />
              </Button>

              <Button className="floatingButton" color="primary">
                Email
              </Button>

              <Button
                className="filterBarIcon"
                color="link"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/filter_icon.svg").default}
                  alt="Filter"
                />
              </Button>
            </div>
          </div>

          {/* filter for tablet and web */}
          <div
            onClick={this.filterShowMobile}
            className={`mobileFilterView ${isClassAdded ? "show" : ""}`}
          ></div>
          <div
            className={`filterContainer  responsiveFilter ${
              isClassAdded ? "show" : ""
            }`}
          >
            <div className="filterIcon">
              <img
                src={require("../../../assets/img/filter_icon.svg").default}
                alt="filter icon"
              />
              Filter by
            </div>
            <div className="mobileTitle">
              <h2>Filter by</h2>
              <Button
                color="link"
                className="closeButton"
                onClick={this.filterShowMobile}
              >
                <img
                  src={require("../../../assets/img/close_grey.svg").default}
                  alt="close"
                  height={12}
                />
              </Button>
            </div>
            <div className="filterWrapper">
              <FormGroup>
                <Label>Agent</Label>
                <div class="custom-select-wrapper">
                  <Input
                    type="select"
                    value={filters.agent}
                    name="agent"
                    onChange={(e) =>
                      this._filterOnChange("agent", e.target.value)
                    }
                  >
                    <option value="">Select</option>
                    {agents.map((agent) => (
                      <option key={agent._id} value={agent._id}>
                        {capitalize(agent.name.full)}
                      </option>
                    ))}
                  </Input>
                </div>
              </FormGroup>
              <FormGroup className="dateRange">
                <Label>Date Range</Label>
                <DateRangePicker
                  className="dateRange"
                  format="MM-dd-y"
                  dayPlaceholder="dd"
                  monthPlaceholder="mm"
                  yearPlaceholder="yyyy"
                  onChange={(dateRangeValue) =>
                    this._onChangeDatePicker(dateRangeValue)
                  }
                  value={dateRangeValue}
                />
              </FormGroup>
              <FormGroup>
                <Label>Status</Label>
                <div class="custom-select-wrapper">
                  <Input
                    type="select"
                    value={filters.status}
                    name="agent"
                    onChange={(e) =>
                      this._filterOnChange("status", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    <option value="Pending">Pending</option>
                    <option value="CCA">Cannot Complete Assignment</option>
                    <option value="Closed">Closed</option>
                    <option value="Cancelled">Cancelled</option>
                    <option value="DNC">Did not close</option>
                  </Input>
                </div>
              </FormGroup>
              <Button
                color="link"
                outline
                className="ml-auto downloadButton"
                onClick={() => this._downloadData("download")}
              >
                Download All{" "}
                <img
                  src={require("../../../assets/img/download_blue.png").default}
                  alt="download"
                />
              </Button>
            </div>
            <div className="clearButton">
              <Button size="md" color="primary" onClick={this.filterShowMobile}>
                Close
              </Button>
            </div>
          </div>

          <div className="downloadButtonPos">
            <Button
              color="link"
              outline
              onClick={() => this._downloadData("download")}
            >
              Download All
              <img
                src={require(`../../../assets/img/download_blue.png`).default}
                alt="download"
              />
            </Button>
          </div>

          <Row>
            <Col md="12">
              <div
                className="d-flex align-items-center justify-content-between"
                style={{ marginBottom: 15 }}
              >
                {/* <div className="fs-16" style={{ color: "#767676" }}>
                  <span className="font-weight-bold themeColor">Status:</span>{" "}
                  {filters.status || "All"}
                </div> */}

                {/* <div>
                  <Button
                    className="my-0 themeBtn"
                    onClick={() => this._downloadData("email")}
                    disabled={loading.isEmailLoading}
                  >
                    {loading.isEmailLoading ? (
                      <i className="fa fa-spinner fa-spin mr-2" />
                    ) : null}{" "}
                    Email
                  </Button>
                </div> */}
              </div>

              <div className="hideDesktop">
                <CustomCard
                  isPageStartFromOne={true}
                  pageNumber={tableConfig.pageNumber}
                  tableData={agentScheduleCompany}
                  headerKeys={agentScheduleCompanyCardHeaderKeys}
                  // bodyKeys={agentClosingCardBodyKeys}
                  dataFormat={this._dataFormat}
                  totalCount={agentScheduleCompanyTotalCount}
                  onPaginate={(pageNumber, pageSize) =>
                    this._paginate(pageNumber, pageSize)
                  }
                  rowSelection={false}
                  // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
                  showTableLoading={loading.showTableLoading}
                  cardHeaderFormat={this._cardHeaderFormat}
                  cardDataFormat={this._cardDataFormat}
                />
              </div>

              <div className="hideMobile">
                {agentScheduleCompanyHeaderKeys &&
                  agentScheduleCompanyHeaderKeys.length && (
                    <CustomTable
                      striped
                      isPageStartFromOne={true}
                      tableData={agentScheduleCompany}
                      headerKeys={agentScheduleCompanyHeaderKeys}
                      dataFormat={this._dataFormat}
                      totalCount={agentScheduleCompanyTotalCount}
                      rowSelection={false}
                      onPaginate={(pageNumber, pageSize) =>
                        this._paginate(pageNumber, pageSize)
                      }
                      onSortChange={(sortName, sortOrder) =>
                        this._onSortChange(sortName, sortOrder)
                      }
                      showTableLoading={loading.showTableLoading}
                    />
                  )}
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export default AgentScheduleCompany;
