/* for auto deployemnt process ( buy new domain):

  on step 2, if user has not added any domain -> then we will restrict it from going to 
  next steps (check from agentWebsiteDetails?.domainName)

  On buy new domain, will have a verify btn which will call verifyAgentWebsiteDomain api, to check for email verification

  On step 4 -> after addUpdateClientsForWebsitePage api call, will call saveDomainDetailForAgentWebsite api to save agent detail


  ---updated---
  on step 2: if agentWebsiteDetails?.domainName not present will not move to next step for both buy new domain and add new domain
  now on step 4, if agentWebsiteDetails?.isAutoGeneratedAwsRoute53 is true, then will check for agentWebsiteDetails?.isEmailVerified, 
  if not verified, then will thow an error and will not call saveDomainDetailForAgentWebsite api
*/

/*
  For manual deployment process (add own domain):
  
  on step 2, if user has not added any domain -> then we will restrict it from going to 
  next steps (check from agentWebsiteDetails?.domainName)

  On add own domain, will have a save btn which will call saveDomainDetailForAgentWebsite api fr saving detailss followed by a getAgentWebsiteDetails api to refetch data

  Will also have a Verify btn, which will only refetch the data, i.e call getAgentWebsiteDetails api
*/

import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { Card, CardBody, CustomInput, Spinner } from "reactstrap";
import { errorHandler } from "../../../../helper-methods";
import { getAgentWebsiteDetail } from "../../../../http/http-calls";
import BuyDomainCheckout from "../Modals/Agents/BuyDomainCheckout";
import BuyNewDomain from "./BuyNewDomain";
import BuyOwnDomain from "./BuyOwnDomain";
import WebsiteVerifyDomainModal from "./WebsiteVerifyDomainModal";

const PersonalWebsiteSetp2 = ({ manageLoadingState }, ref) => {
  const [domainCheckoutModal, setDomainCheckoutModal] = useState({
    isOpen: false,
    data: null,
  });
  const [selectedValue, setSelectedValue] = useState("newDomain"); // Initialize with an empty string
  const [agentWebsiteDetails, setAgentWebsiteDetails] = useState({});

  const [websiteVerifyModal, setWebsiteVerifyModal] = useState({
    isOpen: false,
    data: null,
  });

  const [loading, setLoading] = useState(false);

  const toggle = (isOpen = false, data = false) =>
    setDomainCheckoutModal({ isOpen, data });

  const handleRadioChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const _toggleWebsiteVerifyModal = (isOpen = false, data = null) => {
    setWebsiteVerifyModal({ isOpen, data });
  };

  const _getAgentWebsiteDetails = async () => {
    try {
      setLoading(true);
      const res = await getAgentWebsiteDetail();

      if (res?.agentWebsite) {
        setAgentWebsiteDetails(res?.agentWebsite);
        if (res?.agentWebsite?.domainName?.length) {
          const newSelectedValue =
            res?.agentWebsite?.isAutoGeneratedAwsRoute53 === true
              ? "newDomain"
              : "ownDomain";
          setSelectedValue(newSelectedValue);
        }
      }
      setLoading(false);
    } catch (err) {
      errorHandler(err);
      setLoading(false);
    }
  };

  const _onSubmit = async () => {
    try {
      manageLoadingState("stepLoading", true);
      // const verifyRes = await verifyAgentWebsiteDomain();

      const res = await getAgentWebsiteDetail();

      if (res?.agentWebsite) {
        if (res?.agentWebsite?.isWebsiteLaunched) {
          manageLoadingState("stepLoading", false);
          return true;
        } else {
          manageLoadingState("stepLoading", false);
          errorHandler("Configure your domain settings first");
          return false;
        }
      }
    } catch (err) {
      errorHandler(err);
      manageLoadingState("stepLoading", false);
      return false;
    }
  };

  useImperativeHandle(ref, () => ({
    isDomainNamePresent: agentWebsiteDetails?.domainName?.length ? true : false,
    _onSubmit,
  }));

  useEffect(() => {
    _getAgentWebsiteDetails();
  }, []);

  return (
    <>
      <Card>
        <CardBody>
          <div className="text-center">
            {agentWebsiteDetails?.isWebsiteLaunched &&
            agentWebsiteDetails?.websiteDeployementProcessStatus ===
              "Completed" ? (
              <p className="text-danger text-center">
                *Your Domain has already been created please use domain link to
                access website.
              </p>
            ) : null}

            <h5 style={{ fontSize: 18, fontWeight: 600, marginBottom: 5 }}>
              Add Your Domain
            </h5>
            <p>Once Domain Setting were done you can't change that</p>
          </div>

          {loading ? (
            <div className="text-center">
              <Spinner />
            </div>
          ) : (
            <>
              <div className="text-center">
                <CustomInput
                  type="radio"
                  value="newDomain"
                  id="exampleCustomInline"
                  name="domainInfo"
                  label="Buy A New Domain"
                  checked={selectedValue === "newDomain"}
                  onChange={handleRadioChange}
                  inline
                  disabled={
                    (agentWebsiteDetails?.domainName?.length &&
                      !agentWebsiteDetails?.isAutoGeneratedAwsRoute53) ||
                    false
                  }
                />
                <CustomInput
                  type="radio"
                  value="ownDomain"
                  id="exampleCustomInline2"
                  name="domainInfo"
                  label="Add Your Own"
                  checked={selectedValue === "ownDomain"}
                  onChange={handleRadioChange}
                  inline
                  disabled={
                    (agentWebsiteDetails?.domainName?.length &&
                      agentWebsiteDetails?.isAutoGeneratedAwsRoute53) ||
                    false
                  }
                />
              </div>

              {selectedValue === "newDomain" ? (
                <BuyNewDomain
                  toggle={toggle}
                  agentWebsiteDetails={agentWebsiteDetails}
                  getAgentWebsiteDetails={_getAgentWebsiteDetails}
                />
              ) : (
                <BuyOwnDomain
                  agentWebsiteDetails={agentWebsiteDetails}
                  getAgentWebsiteDetails={_getAgentWebsiteDetails}
                />
              )}
            </>
          )}
        </CardBody>
      </Card>
      <BuyDomainCheckout
        isOpen={domainCheckoutModal?.isOpen}
        data={domainCheckoutModal?.data}
        toggle={toggle}
        toggleWebsiteVerifyModal={_toggleWebsiteVerifyModal}
        onSuccess={_getAgentWebsiteDetails}
      />

      <WebsiteVerifyDomainModal
        isOpen={websiteVerifyModal?.isOpen}
        data={websiteVerifyModal?.data}
        toggle={_toggleWebsiteVerifyModal}
      />
    </>
  );
};

export default forwardRef(PersonalWebsiteSetp2);
