import React from "react";
import { connect } from "react-redux";
import {
  Card,
  CardBody,
  Row,
  Col,
  CardHeader,
  CardTitle,
  Button,
} from "reactstrap";
import {
  getAgentDetailById,
  signingCompanyMarkFavouriteAgentById,
  updateAgentBlacklist,
} from "../../../http/http-calls";
import {
  deepClone,
  showToast,
  openUrlOnNewTab,
  formatTime,
  formatAddressInSingleText,
  formatDate,
  errorHandler,
  checkPermission,
} from "../../../helper-methods";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import moment from "moment";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import { credentialsData, DEFAULT_PROFILE_PICTURE } from "../../../config";

class AgentDetails extends React.Component {
  state = {
    agent: {},
  };

  _redirectToAgents = (e) => {
    if (e) e.preventDefault();
    const { userData } = this.props;
    this.props.history.push(`/${userData.type}/agents`);
  };

  _getAgentDetails = (id) => {
    this.props.showLoader("Loading...");
    getAgentDetailById(id)
      .then((res) => {
        this.props.hideLoader();
        this.setState({
          agent: res?.agent || {},
        });
      })
      .catch((error) => {
        errorHandler(error);
        this.props.hideLoader();
        this._redirectToAgents();
      });

    HeaderEventEmitter.subscribe("back-button", () => {
      this.props.history.goBack();
    });
  };

  componentDidMount = () => {
    try {
      const agentId = this.props.match.params.id;
      this._getAgentDetails(agentId);
    } catch (error) {
      errorHandler(error);
      this._redirectToAgents();
    }
  };

  // _isCredentialsExist = () => {
  //   const { agent } = deepClone(this.state);
  //   for (let i = 0; i < credentialsData.length; i++) {
  //     let obj = credentialsData[i];
  //     if (agent && agent[obj.value] && agent[obj.value].url) {
  //       return true;
  //     }
  //   }
  //   return false;
  // };

  // _isCredentialsExist = () => {
  //   const { agent } = deepClone(this.state);
  //   for (let i = 0; i < credentialsData.length; i++) {
  //     let obj = credentialsData[i];
  //     if (obj.value === "notaryLicense") {
  //       return (
  //         agent &&
  //         agent[obj.value]?.length &&
  //         agent[obj.value]?.map((item) => item.url)
  //       );
  //     } else if (obj.value === "barLicense") {
  //       return (
  //         agent &&
  //         agent[obj.value].length &&
  //         agent[obj.value].map((item) => item.url)
  //       );
  //     } else {
  //       return agent && agent[obj.value] && agent[obj.value].url;
  //     }
  //   }
  // };
  _isCredentialsExist = () => {
    const { agent } = deepClone(this.state);

    for (let i = 0; i < credentialsData.length; i++) {
      const obj = credentialsData[i];
      const value = agent && agent[obj.value];

      if (
        obj.value === "notaryLicense" ||
        obj.value === "barLicense" ||
        obj.value === "ronCredentials"
      ) {
        if (value && value.length > 0) {
          const urls = value.map((item) => item.url);
          if (urls.length > 0) {
            return true;
          }
        }
      } else if (value && value.url) {
        return true;
      }
    }

    return false;
  };

  _blockUnblockAgent = async () => {
    const { agent } = deepClone(this.state);
    try {
      const agentId = this.props.match.params.id;
      const payload = {
        type: agent?.isBlocked === true ? "unblock" : "block",
      };
      await updateAgentBlacklist(agentId, payload);
      this._getAgentDetails(agentId);
    } catch (err) {
      console.log(err);
      showToast(err);
    }
  };

  _markAgentAsFavorite = async (id) => {
    const { agent } = deepClone(this.state);
    let boolean = !agent?.isFavourite;
    this.setState({
      agent: { ...agent, isFavourite: boolean },
    });
    try {
      await signingCompanyMarkFavouriteAgentById(id);
    } catch (error) {
      let boolean = agent?.isFavourite;
      this.setState({
        agent: { ...agent, isFavourite: boolean },
      });
      errorHandler(error);
    }
  };

  render() {
    const { agent } = deepClone(this.state);

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <div className="d-flex align-items-center">
              <Button
                color="link"
                className="backBtn"
                onClick={this._redirectToAgents}
              >
                <img
                  src={require("../../../assets/img/arrowLeft.svg").default}
                  alt="backbutton"
                  height={14}
                />
              </Button>
              <h2>Agent Details</h2>
            </div>
          </div>

          <Row>
            <Col xl={3} lg={4}>
              <Card className="aboutCompany agentDetails">
                <CardBody>
                  <div className="companyImage ">
                    <img
                      src={
                        agent && agent.profilePicture
                          ? agent.profilePicture
                          : DEFAULT_PROFILE_PICTURE
                      }
                      alt="Profile Img"
                      className=""
                    />
                  </div>
                  <h5>{agent.name?.full}</h5>
                  <h5>({agent?.vendorId||"N/A"})</h5>
                  <ul>
                    <li>
                      <img
                        src={require("../../../assets/img/phone.svg").default}
                        alt="phone"
                      />
                      <span>{agent.phone}</span>
                    </li>
                    <li>
                      <img
                        src={
                          require("../../../assets/img/enevlope.svg").default
                        }
                        alt="email"
                      />
                      <span>
                        <a href={`mailto:${agent?.email}`}>{agent?.email}</a>
                      </span>
                    </li>
                    <li>
                      {agent ? (
                        !agent.isFavourite ? (
                          <Button
                            color="link"
                            className="actionBtn"
                            disabled={!checkPermission("agents", "canUpdate")}
                            onClick={() => this._markAgentAsFavorite(agent.id)}
                          >
                            <img
                              src={
                                require("../../../assets/img/iconFav.svg")
                                  .default
                              }
                              alt="fav icon"
                            />
                          </Button>
                        ) : (
                          <Button
                            color="link"
                            className="actionBtn"
                            onClick={() => this._markAgentAsFavorite(agent.id)}
                          >
                            <img
                              src={
                                require("../../../assets/img/iconFavFill.svg")
                                  .default
                              }
                              alt="fav icon"
                            />
                          </Button>
                        )
                      ) : (
                        "N/A"
                      )}

                      {/* <img
                        src={
                          require("../../../assets/img/ratingBlack.svg").default
                        }
                        alt="rating"
                      /> */}
                      <span>
                        {agent.totalRating
                          ? agent?.totalRating + "/" + 5
                          : "N/A"}
                      </span>
                    </li>
                    <li>
                      <img
                        src={
                          require("../../../assets/img/location.svg").default
                        }
                        alt="location"
                      />
                      <span>{formatAddressInSingleText(agent?.address)}</span>
                    </li>
                  </ul>
                  {/* <div className="action">
                    {client?.connectStatus === "accepted" ? (
                      <Button color="success" outline>
                        Connected
                      </Button>
                    ) : null}
                    {client?.connectStatus === "pending" ||
                    client?.connectStatus === "rejected" ||
                   
                    (client?.connectResquestBy === undefined &&
                      client?.connectStatus === undefined) ? (
                      <Button
                        color="primary"
                        disabled={loading.requestLoading}
                        onClick={() =>
                          this._requestClientConnectByCompany(client)
                        }
                      >
                        {loading.requestLoading ? (
                          <i className="fa fa-spinner fa-spin mr-2" />
                        ) : null}{" "}
                        {client?.connectStatus === "rejected" &&
                        client?.connectResquestBy?.toLowerCase() ===
                          "signingcompany"
                          ? "Re-"
                          : null}
                        Request Connection
                      </Button>
                    ) : null}
                    
                    {client?.connectStatus === "requested" ? (
                      client?.connectResquestBy?.toLowerCase() === "client" ? (
                        <div>
                          <Button
                            color="success"
                            outline
                            disabled={loading.requestLoading ? true : false}
                            onClick={() =>
                              this._companyResponseToConnectByClient({
                                id: client?._id,
                                status: "accepted",
                              })
                            }
                          >
                            {loading.requestLoading === "accepted" ? (
                              <i className="fa fa-spinner fa-spin mr-2" />
                            ) : null}{" "}
                            Accept
                          </Button>
                          <Button
                            color="danger"
                            outline
                            disabled={loading.requestLoading ? true : false}
                            onClick={() =>
                              this._companyResponseToConnectByClient({
                                id: client?._id,
                                status: "rejected",
                              })
                            }
                          >
                            {loading.requestLoading === "rejected" ? (
                              <i className="fa fa-spinner fa-spin mr-2" />
                            ) : null}{" "}
                            Reject
                          </Button>
                        </div>
                      ) : (
                        <Button color="primary" outline>
                          Request Sent
                        </Button>
                      )
                    ) : null}
                  </div> */}

                  {/* once you click button it will change to unblock  */}
                  <Button
                    color="primary"
                    size="md"
                    onClick={() => this._blockUnblockAgent()}
                  >
                    {agent?.isBlocked === true ? "Unblock" : "Blacklist"}
                  </Button>
                </CardBody>
              </Card>
            </Col>
            <Col xl={9} lg={8}>
              <Card>
                <CardHeader>
                  <CardTitle>Stats</CardTitle>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col xl={4} lg={6} xs={6} className="mb-4">
                      <div className="CompanyStats">
                        <div>
                          <h6>Jobs Completed</h6>
                          <p>{agent?.closingCompleted || "N/A"}</p>
                        </div>
                        <div className="icon">
                          <img
                            src={
                              require("../../../assets/img/JobCompleted.png")
                                .default
                            }
                            alt="JobCompleted"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={4} lg={6} xs={6} className="mb-4">
                      <div className="CompanyStats">
                        <div>
                          <h6>Jobs Scheduled</h6>
                          <p>{agent?.closingScheduled || "N/A"}</p>
                        </div>
                        <div className="icon">
                          <img
                            src={
                              require("../../../assets/img/JobsScheduled.png")
                                .default
                            }
                            alt="JobCompleted"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={4} lg={12} xs={12} className="mb-4">
                      <div className="CompanyStats">
                        <div>
                          <h6>Schedule</h6>
                          <ul className="weekName">
                            {agent?.availability &&
                              Object.entries(agent.availability).map(
                                ([key, val]) => (
                                  <li className={val ? "selected" : ""}>
                                    {key[0].toUpperCase()}
                                  </li>
                                )
                              )}
                          </ul>

                          <div className="scheduleTime">
                            (
                            {agent?.workTiming?.from
                              ? formatTime(
                                  moment(agent.workTiming.from, "HH:mm")
                                )
                              : "N/A"}
                            {" - "}
                            {agent?.workTiming?.to
                              ? formatTime(moment(agent.workTiming.to, "HH:mm"))
                              : "N/A"}
                            )
                          </div>
                        </div>
                        <div className="icon">
                          <img
                            src={
                              require("../../../assets/img/TotalIncome.svg")
                                .default
                            }
                            alt="JobCompleted"
                          />
                        </div>
                      </div>
                    </Col>
                    <Col xl={12} lg={12} xs={12}>
                      <h6>Standard Fee</h6>
                      <div className="CompanyStats" style={{ height: "auto" }}>
                        <ul className="standardFee">
                          {agent?.standardFee
                            ? Object.entries(agent?.standardFee).map(
                                ([key, value], index) => (
                                  <>
                                    <li>
                                      {key} <span>${value || "N/A"}</span>
                                    </li>
                                  </>
                                )
                              )
                            : "N/A"}
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
              <Card>
                <CardHeader>
                  <CardTitle>Credentials</CardTitle>
                </CardHeader>

                <ul className="agentCredentials">
                  {credentialsData.map((obj, index) =>
                    obj.value === "notaryLicense" ||
                    obj.value === "barLicense" ||
                    obj.value === "ronCredentials" ? (
                      agent[obj.value] &&
                      agent[obj.value]
                        .filter((item) => item.isVerified === "Approved")
                        .map((each, index) => (
                          <>
                            <li>
                              <div className="credentialItem">
                                <span>
                                  {obj.label}{" "}
                                  {agent[obj.value][index].state ? (
                                    <>
                                      ({agent[obj.value][index].state || "NA"})
                                    </>
                                  ) : null}
                                </span>

                                {agent.agentWorkedWithCompanys.some(
                                  (item) => item === this.props.userData.user.id
                                ) && (
                                  <div>
                                    <div
                                      onClick={() =>
                                        openUrlOnNewTab(
                                          agent[obj.value][index].url
                                        )
                                      }
                                      className="fileInfo"
                                    >
                                      <img
                                        src={
                                          require("../../../assets/img/pdf.svg")
                                            .default
                                        }
                                        alt="file"
                                      />
                                      <span>
                                        {agent[obj.value][index]?.title}
                                      </span>
                                    </div>
                                    {agent[obj.value][index].expiredOn ? (
                                      <>
                                        <span className="otherInfo">
                                          Expiration{" "}
                                          {formatDate(
                                            agent[obj.value][index]?.expiredOn
                                          )}
                                        </span>
                                      </>
                                    ) : null}
                                  </div>
                                )}
                              </div>
                              {agent.agentWorkedWithCompanys.some(
                                (item) => item === this.props.userData.user.id
                              ) && (
                                <div className="credentialItem">
                                  <span className="text-muted">
                                    Licence Number
                                  </span>
                                  {agent[obj.value][index].licenseNumber ? (
                                    <>
                                      <span className="otherInfo">
                                        {agent[obj.value][index]
                                          .licenseNumber || "NA"}
                                      </span>
                                    </>
                                  ) : null}
                                </div>
                              )}
                            </li>
                          </>
                        ))
                    ) : agent[obj.value] &&
                      agent[obj.value]?.url &&
                      agent[obj.value]?.isVerified === "Approved" ? (
                      <>
                        <li>
                          <div className="credentialItem">
                            <span>
                              {obj.label}
                              {agent[obj.value]?.state ? (
                                <>({agent[obj.value]?.state || "NA"})</>
                              ) : null}
                            </span>
                            {agent.agentWorkedWithCompanys.some(
                              (item) => item === this.props.userData.user.id
                            ) && (
                              <div>
                                <div
                                  onClick={() =>
                                    openUrlOnNewTab(agent[obj.value]?.url)
                                  }
                                  className="fileInfo"
                                >
                                  <img
                                    src={
                                      require("../../../assets/img/pdf.svg")
                                        .default
                                    }
                                    alt="file"
                                  />
                                  <span>{agent[obj.value]?.title}</span>
                                </div>

                                {agent[obj.value]?.expiredOn ? (
                                  <>
                                    <span className="otherInfo">
                                      Expiration{" "}
                                      {formatDate(agent[obj.value]?.expiredOn)}
                                    </span>
                                  </>
                                ) : null}
                              </div>
                            )}
                          </div>
                          {agent[obj.value]?.licenseNumber ? (
                            <div className="credentialItem">
                              <>
                                <span>Licence Number</span>
                              </>

                              <>
                                <span className="otherInfo">
                                  {agent[obj.value].licenseNumber || "NA"}
                                </span>
                              </>
                            </div>
                          ) : null}
                        </li>
                      </>
                    ) : null
                  )}
                </ul>

                {this._isCredentialsExist() ? null : (
                  <div className="nodata">
                    <img
                      src={require("../../../assets/img/noData.svg").default}
                      alt="no data"
                    />
                    <p> There is no credentials to display</p>
                  </div>
                )}
              </Card>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AgentDetails);
