import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  Input,
  Spinner,
  Label,
} from "reactstrap";
import {
  checkPermission,
  errorHandler,
  formatDate,
  openUrlOnNewTab,
  showToast,
  uploadFileOnServer,
} from "../../../helper-methods";
import {
  clientGetAllCompanyDocuments,
  clientAddCompanyDocument,
  clientDeleteCompanyDocument,
} from "../../../http/http-calls";
import DocumentViewModal from "./documentViewModal";
import cuid from "cuid";
import Swal from "sweetalert2";
import { useSelector } from "react-redux";
import CustomPagination from "./CustomPagination";

const CompanyDocuments = ({ data }) => {
  const userData = useSelector((state) => state?.userData);

  const userType = useMemo(() => {
    return userData?.user?.type?.toLowerCase();
  }, [userData]);

  const [documents, setDocuments] = useState([]);
  const [documentsCount, setDocumentsCount] = useState(0);
  const [documentsPayload, setDocumentsPayload] = useState({
    skip: 0,
    limit: 5,
    companyId: data?._id,
    clientId: data?._id,
  });
  const [loading, setLoading] = useState(false);
  const [addDocumentLoading, setAddDocumentLoading] = useState(false);
  const [deleteDocumentLoading, setDeleteDocumentLoading] = useState(false);
  const [documentViewModalData, setDocumentViewModalData] = useState({
    isOpen: false,
    externalUrl: null,
    modalHeading: null,
    fileType: null,
  });

  const _onToggleDocumentViewModal = (doc = null, isOpen = false) => {
    let newDocumentViewModalData = { ...documentViewModalData };

    if (doc && isOpen) {
      newDocumentViewModalData = {
        isOpen: true,
        externalUrl: doc.url,
        modalHeading: doc.title,
        fileType: doc.docType,
      };
    } else {
      newDocumentViewModalData = {
        isOpen: false,
        externalUrl: null,
        modalHeading: null,
        fileType: null,
      };
    }

    setDocumentViewModalData(newDocumentViewModalData);
  };

  const _downloadDocument = (row) => {
    if (row?.url) openUrlOnNewTab(row.url);
  };

  const _rowTypeFormat = (row) => {
    if (row?._signingCompany) {
      return <span>by company</span>;
    } else if (row?._agent) {
      return <span>by agent</span>;
    }
    return null;
  };

  const _getAllDocuments = async (payload) => {
    try {
      setLoading(true);
      let res = await clientGetAllCompanyDocuments({ userType, payload });
      setDocuments(res?.contents?.length ? res.contents : []);
      setDocumentsCount(res?.totalCount || 0);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      errorHandler(error);
    }
  };

  const _onChangePagination = (pageNo = 1) => {
    const newDocumentsPayload = { ...documentsPayload };
    newDocumentsPayload.skip = (pageNo - 1) * newDocumentsPayload.limit;
    setDocumentsPayload(newDocumentsPayload);
    _getAllDocuments(newDocumentsPayload);
  };

  const _addDocument = (files) => {
    const newDocuments = [...documents];
    files.forEach((each) => {
      newDocuments.unshift({
        _id: cuid(),
        isLoading: true,
        createdAt: new Date(),
        document: {
          ...each,
        },
        ...each,
      });
    });
    setDocuments(newDocuments);

    const payload = {
      companyId: data?._id,
      clientId: data?._id,
      files,
    };

    clientAddCompanyDocument({ userType, payload })
      .then((res) => {
        _onChangePagination();
        showToast("Document has been uploaded", "success");
        setAddDocumentLoading(false);
      })
      .catch((error) => {
        _onChangePagination();
        errorHandler(error);
        setAddDocumentLoading(false);
      });
  };

  const _updateFile = async (event) => {
    let objFile, objFileType;

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      let uploadFiles = [];

      for (let index = 0; index < event.target.files.length; index++) {
        objFile = event.target.files[index];
        objFileType = objFile.type.split("/")[0];
        uploadFiles.push({
          previewBlob: URL.createObjectURL(objFile),
          uploadData: objFile,
          type: objFileType === "application" ? "pdf" : objFileType,
        });
      }

      if (uploadFiles?.length) {
        setAddDocumentLoading(true);
        let documents = await uploadFileOnServer(uploadFiles);
        _addDocument(documents);
      }
    }
  };

  const _deleteDocument = (documentId) => {
    setDeleteDocumentLoading(documentId);

    const newDocuments = [...documents];
    const findDocumentIndex = newDocuments.findIndex(
      (each) => each._id === documentId
    );
    if (findDocumentIndex > -1) {
      newDocuments.splice(findDocumentIndex, 1);
      setDocuments(newDocuments);
    }

    clientDeleteCompanyDocument({ userType, id: documentId })
      .then(async (res) => {
        Swal.fire("Deleted!", "Document has been deleted.", "success");
        if (newDocuments.length === 0) {
          _onChangePagination();
        }
        setDeleteDocumentLoading(false);
      })
      .catch((error) => {
        _onChangePagination();
        errorHandler(error);
        setDeleteDocumentLoading(false);
      });
  };

  const _deleteDocumentAlert = (document) => {
    if (!document?._id) {
      showToast("Document not found", "error");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this document.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _deleteDocument(document._id);
      }
    });
  };

  useEffect(() => {
    if (data?._id) {
      const newDocumentsPayload = { ...documentsPayload };
      newDocumentsPayload.companyId = data?._id;
      newDocumentsPayload.clientId = data?._id;
      setDocumentsPayload(newDocumentsPayload);
      _getAllDocuments(newDocumentsPayload);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?._id]);

  return (
    <Card>
      <CardHeader>
        <CardTitle>
          Upload File/Contracts {loading ? <Spinner size="sm" /> : null}
        </CardTitle>
      </CardHeader>
      <CardBody>
        <ul className="documentList signingDocument">
          {documents?.length ? (
            documents.map((each, index) => (
              <li key={each._id + index}>
                <div className="documentItem">
                  <div className="userInfo">
                    <div
                      className={`userImage ${
                        each.docType === "pdf" ? "pdfFile" : "docFile"
                      }`}
                    >
                      {each.docType === "pdf" ? (
                        <img
                          src={
                            require("../../../assets/img/pdf-white.svg").default
                          }
                          alt="pdf file"
                        />
                      ) : (
                        <img
                          src={
                            require("../../../assets/img/nodes-white.svg")
                              .default
                          }
                          alt="doc file"
                        />
                      )}
                    </div>
                    <div className="userContent">
                      <span>Title</span>
                      <h6> {each.document?.title}</h6>
                    </div>
                  </div>
                </div>
                <div className="documentItem">
                  <span>Added on</span>
                  <h6>
                    {formatDate(each.createdAt)}
                    <span>
                      {","} {_rowTypeFormat(each, index)}
                    </span>
                  </h6>
                </div>
                <div className="actionItem">
                  {/* <Button
                    color="link"
                    className="actionBtn"
                    disabled={each.isLoading ? true : false}
                    onClick={() =>
                      _onToggleDocumentViewModal(each.document, true)
                    }
                  >
                    <i className="fa fa-eye" />
                  </Button> */}
                  <Button
                    color="link"
                    disabled={each.isLoading ? true : false}
                    onClick={() => _downloadDocument(each.document)}
                  >
                    <img
                      src={
                        require("../../../assets/img/download_blue.png").default
                      }
                      alt="download"
                    />
                  </Button>
                  {checkPermission("connectCompanies", "canUpdate") ? (
                    <Button
                      color="link"
                      className="actionBtn"
                      disabled={
                        deleteDocumentLoading || each.isLoading ? true : false
                      }
                      onClick={() => _deleteDocumentAlert(each)}
                    >
                      <img
                        src={
                          require("../../../assets/img/deleteIcon.svg").default
                        }
                        alt="download"
                      />
                    </Button>
                  ) : null}
                </div>
              </li>
            ))
          ) : (
            <li>
              {loading.documentsLoading ? (
                <Spinner />
              ) : (
                <div className="nodata">
                  <img
                    src={require("../../../assets/img/noData.svg").default}
                    alt="no data"
                  />
                  <p>There is no data to display</p>
                </div>
              )}
            </li>
          )}
        </ul>
        <div className="text-center my-3">
          {checkPermission("connectCompanies", "canUpdate") ? (
            <Label className="uploadBtn">
              <Input
                type="file"
                className="uploadDocumentInputFile"
                accept=".pdf,.doc,.docx,image/*"
                disabled={addDocumentLoading}
                title="Add Documents"
                multiple
                value=""
                onChange={(event) => _updateFile(event)}
              />
              {addDocumentLoading ? (
                <i className="fa fa-spinner fa-spin" />
              ) : (
                <>
                  <img
                    src={require("../../../assets/img/uploadIcon.svg").default}
                    alt="upload"
                  />
                  Upload
                </>
              )}
            </Label>
          ) : null}
        </div>

        <CustomPagination
          data={documents}
          dataCount={documentsCount}
          pageNumber={
            parseInt(documentsPayload.skip / documentsPayload.limit) + 1
          }
          pageSize={documentsPayload.limit}
          onPageChange={(pageNo) => _onChangePagination(pageNo)}
          isPageStartFromOne
          isHideForLessData
        />
      </CardBody>

      <DocumentViewModal
        isOpen={documentViewModalData.isOpen}
        externalUrl={documentViewModalData.externalUrl}
        fileType={documentViewModalData.fileType}
        modalHeading={documentViewModalData.modalHeading}
        toggle={() => _onToggleDocumentViewModal()}
      />
    </Card>
  );
};

export default CompanyDocuments;
