import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import { updateUserObj } from "../../../redux/actions/user-data";
import UserProfile from "./user-profile";
import ClientProfileDetail from "../components/ClientProfileDetail";
import AchSettings from "./ach-settings";
import ClientTeamPage from "./ClientTeamPage";

class ClientProfile extends React.Component {
  state = {
    activeTab: "1",
  };

  _toggleTab = (tab = "1") => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  render() {
    const { activeTab } = this.state;

    const { userData } = this.props;

    if (userData?.user?.isAssistant) {
      return (
        <div className="content">
          <UserProfile />
        </div>
      );
    }

    return (
      <div className="content">
        <div className="responsiveTitle">
          <h2>Client Profile </h2>
        </div>
        <div className="verticalTabs">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={activeTab === "1" ? "active" : ""}
                onClick={() => {
                  this._toggleTab("1");
                }}
              >
                Basic Details
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={activeTab === "2" ? "active" : ""}
                onClick={() => {
                  this._toggleTab("2");
                }}
              >
                Bank Info
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "3" ? "active" : ""}
                onClick={() => {
                  this._toggleTab("3");
                }}
              >
                Teams
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            {activeTab === "1" ? (
              <TabPane tabId="1">
                <ClientProfileDetail activeTab={activeTab} />
              </TabPane>
            ) : null}
            {activeTab === "2" ? (
              <TabPane tabId="2">
                <AchSettings activeTab={activeTab} />
              </TabPane>
            ) : null}
            {activeTab === "3" ? (
              <TabPane tabId="3">
                <ClientTeamPage />
              </TabPane>
            ) : null} 
          </TabContent>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    userData: state.userData ? state.userData : {},
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserObj: (user) => dispatch(updateUserObj(user)),
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ClientProfile);
