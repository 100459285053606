import React from "react";
import { Button, Input, Label } from "reactstrap";
import {
  showToast,
  deepClone,
  formatDateHideYearIfSame,
  checkPermission,
  errorHandler,
  getDropdownColor,
} from "../../../helper-methods";
import { capitalize } from "@material-ui/core";
import {
  getAllClientUsers,
  updateClientStatusUser,
} from "../../../http/http-calls";
import ClientAddUserModal from "../components/ClientAddUserModal";
import { HeaderEventEmitter } from "../../../helper-methods/HeaderEvents";
import CustomTable from "../components/CustomTable";
import CustomCard from "../components/CustomCard";

class ClientTeamMember extends React.Component {
  state = {
    users: [],
    usersTotalCount: 0,
    usersHeaderKeys: [
      { id: "id", label: "id", noSort: true },
      { id: "name", label: "Name", noSort: true },
      { id: "email", label: "Email", noSort: true },
      { id: "phone", label: "Phone", noSort: true },
      { id: "permissionSettings", label: "Permission", noSort: true },
      { id: "added", label: "Added By", noSort: true },
      { id: "lastLogin", label: "Last Login", noSort: true },
      { id: "isActive", label: "Status", noSort: true },
      {
        id: "action",
        label: "Action",
        noSort: true,
        style: { width: 100, textAlign: "center" },
      },
    ],
    usersCardHeaderKeys: [
      { id: "id", label: "id" },
      { id: "name", label: "Name" },
    ],
    loading: {
      statusChangeLoading: false,
      showTableLoading: false,
    },
    clientAddUserModal: {
      isOpen: false,
      data: null,
    },
    tableConfig: {
      skip: 0,
      limit: 10,
      pageNumber: 1,
      sort: {
        sortBy: "createdAt",
        sortOrder: "desc",
      },
    },
  };

  _onToggleClientAddUserModal = (isOpen = false, data = null) => {
    this.setState({
      clientAddUserModal: {
        isOpen,
        data,
      },
    });
  };

  _manageLoading = (loaderName, value) => {
    const { loading } = deepClone(this.state);
    loading[loaderName] = value;
    this.setState({ loading });
  };

  componentDidMount = () => {
    // this._getAllClientUsers();
    this._setFilterDataFromLocalStorage();
    HeaderEventEmitter.subscribe("add-team", () => {
      // this._toggleAddOrderClient(true)
      this._onToggleClientAddUserModal(true);
    });
  };

  _getAllClientUsers = () => {
    const { tableConfig } = this.state;
    this._manageLoading("showTableLoading", true);
    let data = {
      skip: tableConfig.skip,
      limit: tableConfig.limit,
    };
    getAllClientUsers(data)
      .then((res) => {
        this._manageLoading("showTableLoading", false);
        this.setState({
          users: res?.users || {},
          usersTotalCount: res?.totalCount || 0,
        });
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("showTableLoading", false);
      });
  };

  _dataFormat = (cell, row, header) => {
    switch (header) {
      // case "workType": {
      //   return (
      //     <>
      //       <div style={{ width: 250 }}>{cell ? cell : "N/A"}</div>
      //     </>
      //   );
      // }
      case "name": {
        return (
          <>
            <div style={{ width: 110 }}>
              {cell && cell.full ? capitalize(cell.full) : "N/A"}
            </div>
          </>
        );
      }
      case "email": {
        return (
          <>
            <div style={{ width: 110 }}>{cell ? cell : "N/A"}</div>
          </>
        );
      }
      case "lastLogin": {
        return cell
          ? formatDateHideYearIfSame(row.lastLogin)
          : "Not Logged In Yet!";
      }
      case "added": {
        return row._createdBy || row._createdByClient ? (
          <>{`${formatDateHideYearIfSame(row.createdAt)} By ${
            row._createdBy?.companyName || row._createdByClient?.companyName
          }`}</>
        ) : (
          "N/A"
        );
      }
      case "permissionSettings": {
        return cell
          ? `${this._calculatePermissionCount(row?.permissionSettings)}/12`
          : "N/A";
      }

      case "isActive": {
        return row ? (
          <div className="customSelectWrap" style={{ width: 100 }}>
            <Input
              type="select"
              name="status"
              className={`status ${getDropdownColor(
                row.isActive ? "active" : "inactive"
              )}`}
              disabled={
                this.state.loading.statusChangeLoading ||
                !checkPermission("users", "canUpdate")
              }
              value={row.isActive ? "active" : "inactive"}
              onChange={(event) =>
                this._userStatusUpdate(row, event.target.value)
              }
            >
              <option value="active">Active</option>
              <option value="inactive">Inactive</option>
            </Input>
            <div className="downArrow">
              <i className="fa fa-chevron-down"></i>
            </div>
          </div>
        ) : (
          "N/A"
        );
      }
      case "action": {
        return row ? (
          <Button
            title="Edit"
            color="link"
            onClick={() => this._onToggleClientAddUserModal(true, row)}
            disabled={!checkPermission("users", "canUpdate")}
          >
            <img
              src={require("../../../assets/img/pencil.svg").default}
              alt="edit"
            />
          </Button>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _userStatusUpdate = (data, value) => {
    this._manageLoading("statusChangeLoading", true);

    const payload = {
      isActive: value === "active" ? true : false,
    };

    updateClientStatusUser({ id: data.id, payload })
      .then((res) => {
        showToast("Status has been updated", "success");
        const { users } = deepClone(this.state);
        const user = users.find((user) => user.id === data.id);
        user["isActive"] = res?.user?.isActive || false;
        this.setState({ users });
        this._manageLoading("statusChangeLoading", false);
      })
      .catch((error) => {
        errorHandler(error);
        this._manageLoading("statusChangeLoading", false);
      });
  };

  _paginate = (pageNumber, pageSize) => {
    const { tableConfig } = this.state;
    tableConfig.skip = (pageNumber - 1) * pageSize;
    tableConfig.limit = pageSize;
    tableConfig["pageNumber"] = pageNumber;
    this.setState({ tableConfig }, () => {
      this._persistFilter();
      this._getAllClientUsers();
    });
  };

  _onSortChange = (sortName, sortOrder) => {
    this._paginate(1, 10);
  };

  _calculatePermissionCount = (permissionSettings) => {
    let count = 0;
    permissionSettings !== undefined &&
      Object.entries(permissionSettings).forEach(([key, value]) => {
        Object.keys(value).forEach(function (key) {
          if (value[key] === true) count += 1;
        });
      });
    return count;
  };

  _cardHeaderFormat = (cell, row, header) => {
    const { loading } = deepClone(this.state);

    switch (header) {
      case "name": {
        return row ? (
          <>
            <div
              className="tableUserInfo"
              onClick={() =>
                row?._company?._id
                  ? this.props.history.push(
                      `/client/signing-company-details/${row._company._id}`
                    )
                  : {}
              }
            >
              <div className="userImage">
                <img
                  className="clientImg"
                  src={
                    row?._company?.logo
                      ? row?._company?.logo
                      : require("../../../assets/img/default_user_icon.svg")
                          .default
                  }
                  loading="lazy"
                  alt="avatar"
                />
              </div>
              <div className="userContent">
                <span className="signerName">
                  {row?.name ? capitalize(row?.name?.full) : "N/A"}
                </span>
              </div>
            </div>
            {/* <Button
              color={`${
                row?.appointmentStatus === "Confirmed" && !row.leftMessage
                  ? "success "
                  : row?.appointmentStatus === "Rescheduled" && !row.leftMessage
                  ? "danger "
                  : row.leftMessage
                  ? "warning"
                  : "danger "
              }`}
              className={`confirmButton btnConfirm`}
              onClick={() => this._onToggleSignerAvailabilityModal(true, row)}
            >
              {row?.appointmentStatus === "Confirmed" ? "Confirmed" : "Confirm"}
            </Button> */}

            <Button
              title="Edit"
              color="link"
              onClick={() => this._onToggleClientAddUserModal(true, row)}
              disabled={!checkPermission("users", "canUpdate")}
            >
              <img
                src={require("../../../assets/img/pencil.svg").default}
                alt="edit"
              />
            </Button>
          </>
        ) : (
          "N/A"
        );
      }
      default: {
        return cell;
      }
    }
  };

  _cardDataFormat = (row) => {
    return (
      <ul className="cardInfo">
        <li>
          <div className="dataWrap">
            <Label>Email </Label>

            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.email ? row?.email : "N/A"}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Phone </Label>

            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.phone ? row?.phone : "N/A"}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Permission: </Label>

            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.permissionSettings
                ? `${this._calculatePermissionCount(
                    row?.permissionSettings
                  )}/12`
                : "N/A"}
            </div>
          </div>

          <div className="dataWrap">
            <Label>Added By: </Label>

            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?._createdBy || row?._createdByClient ? (
                <>{`${formatDateHideYearIfSame(row?.createdAt)} By ${
                  row?._createdBy?.companyName ||
                  row?._createdByClient?.companyName
                }`}</>
              ) : (
                "N/A"
              )}
            </div>
          </div>
        </li>

        <li>
          <div className="dataWrap">
            <Label>Last Login: </Label>

            <div
              style={{
                fontWeight: 600,
              }}
            >
              {row?.lastLogin
                ? formatDateHideYearIfSame(row.lastLogin)
                : "Not Logged In Yet!"}
            </div>
          </div>
        </li>
        <li>
          <div className="dataWrap d-flex align-items-center justify-content-between">
            <Label>Status </Label>

            {row ? (
              <div className="customSelectWrap" style={{ width: 100 }}>
                <Input
                  type="select"
                  name="status"
                  className={`status ${getDropdownColor(
                    row.isActive ? "active" : "inactive"
                  )}`}
                  disabled={
                    this.state.loading.statusChangeLoading ||
                    !checkPermission("users", "canUpdate")
                  }
                  value={row.isActive ? "active" : "inactive"}
                  onChange={(event) =>
                    this._userStatusUpdate(row, event.target.value)
                  }
                >
                  <option value="active">Active</option>
                  <option value="inactive">Inactive</option>
                </Input>
                <div className="downArrow">
                  <i className="fa fa-chevron-down"></i>
                </div>
              </div>
            ) : (
              "N/A"
            )}
          </div>
        </li>
      </ul>
    );
  };

  _persistFilter = () => {
    const { tableConfig } = this.state;

    if (tableConfig.pageNumber !== 1) {
      let data = { tableConfig };
      localStorage.clientTeamMembersPage = JSON.stringify(data);
    } else {
      delete localStorage.clientTeamMembersPage;
    }
  };

  _setFilterDataFromLocalStorage = () => {
    if (localStorage && localStorage.clientTeamMembersPage) {
      try {
        const filters = JSON.parse(localStorage.clientTeamMembersPage);

        this.setState({ tableConfig: filters.tableConfig }, () => {
          this._getAllClientUsers();
        });
      } catch (e) {
        console.log("error", e);
        this._getAllClientUsers();
      }
    } else {
      this._getAllClientUsers();
    }
  };

  render() {
    const {
      users,
      usersTotalCount,
      usersHeaderKeys,
      loading,
      tableConfig,
      clientAddUserModal,
      usersCardHeaderKeys,
    } = deepClone(this.state);

    return (
      <>
        <div className="content">
          <div className="responsiveTitle">
            <h2>Team Members</h2>

            <div className="rightSide">
              <Button
                color="primary"
                className="floatingButton"
                onClick={() => this._onToggleClientAddUserModal(true)}
              >
                Add Team Members
              </Button>
            </div>
          </div>

          <div className="hideDesktop">
            <CustomCard
              isPageStartFromOne={true}
              pageNumber={tableConfig.pageNumber}
              tableData={users}
              headerKeys={usersCardHeaderKeys}
              // bodyKeys={agentClosingCardBodyKeys}
              dataFormat={this._dataFormat}
              totalCount={usersTotalCount}
              onPaginate={(pageNumber, pageSize) =>
                this._paginate(pageNumber, pageSize)
              }
              rowSelection={false}
              // rowClassName={(row, rowIdx) => this._rowClassName(row, rowIdx)}
              showTableLoading={loading.showTableLoading}
              cardHeaderFormat={this._cardHeaderFormat}
              cardDataFormat={this._cardDataFormat}
            />
          </div>

          <div className="hideMobile">
            {usersHeaderKeys && usersHeaderKeys.length && (
              <CustomTable
                striped
                isPageStartFromOne={true}
                pageNumber={tableConfig.pageNumber}
                tableData={users}
                headerKeys={usersHeaderKeys}
                dataFormat={this._dataFormat}
                totalCount={usersTotalCount}
                rowSelection={false}
                onPaginate={(pageNumber, pageSize) =>
                  this._paginate(pageNumber, pageSize)
                }
                onSortChange={(sortName, sortOrder) =>
                  this._onSortChange(sortName, sortOrder)
                }
                showTableLoading={loading.showTableLoading}
              />
            )}
          </div>
        </div>

        <ClientAddUserModal
          isOpen={clientAddUserModal.isOpen}
          data={clientAddUserModal.data}
          resetTable={this._getAllClientUsers}
          toggle={() => this._onToggleClientAddUserModal()}
        />
      </>
    );
  }
}

export default ClientTeamMember;
