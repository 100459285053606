import React from 'react';
import {
  Carousel,
  CarouselItem,
  CarouselCaption
} from 'reactstrap';

const items = [
  {
    src: require('../../../../assets/img/banner1.png').default,
    altText: 'Slide 1',
  },
  {
    src: require('../../../../assets/img/banner2.png').default,
    altText: 'Slide 2',
  },
  {
    src: require('../../../../assets/img/banner3.png').default,
    altText: 'Slide 3',
  }
];

const PublicImageCarousel = ({ activeIndex, next, previous, goToIndex }) => {

  const slides = items.map((item) => {
    return (
      <CarouselItem
        key={item.src}
      >
        <img src={item.src} alt={item.altText} />
        <CarouselCaption captionText={item.caption} captionHeader={item.caption} />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      activeIndex={activeIndex}
      next={next}
      previous={previous}
      interval={null}

    >
      {/* <CarouselIndicators items={items} activeIndex={activeIndex} onClickHandler={goToIndex} /> */}
      {slides}
    </Carousel>
  );
}

export default PublicImageCarousel;