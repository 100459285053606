import React from "react";
import {
  Card,
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  ListGroup,
  ListGroupItem,
  CustomInput,
  Tooltip,
  NavItem,
  Nav,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";
import {
  signingCompanyDetail,
  updateSigningCompanyProfile,
} from "../../../http/http-calls";
import {
  getPhoneNumberFromBrackets,
  showToast,
  deepClone,
  sleepTime,
  getImageDimensionClass,
  updateFavIcon,
  errorHandler,
} from "../../../helper-methods";
import { showLoader, hideLoader } from "../../../redux/actions/loader-data";
import { connect } from "react-redux";
import { RegexConfig } from "../../../config/RegexConfig";
import { PostManager } from "../../../post-manager";
import { UploadQueueManager } from "../../../upload-queue-manager";
import { updateUserObj } from "../../../redux/actions/user-data";
import usaStates from "../../../config/usa_states_titlecase";
import { store as REDUX_STORE } from "../../../redux/store";
import CopyToClipboard from "react-copy-to-clipboard";
// import UserProfile from "./user-profile";
import TextEditor from "../components/TextEdittor";
import {
  googlePlaceSearch,
  googlePlaceDetails,
} from "../../../helper-methods/googleService";
import { DEFAULT_PROFILE_PICTURE, APP_URL } from "../../../config";
// import AchSettings from "./ach-settings";

class CompanyProfileDetail extends React.Component {
  state = {
    companyProfileDetail: null,
    formFields: {
      search: {
        value: "",
        placeId: null,
        isDirty: false,
        isValidate: true,
      },
      street: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      line1: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      line2: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      city: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      state: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      zip: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      country: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      companyName: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      email: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      jobTitle: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      firstname: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      lastname: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      homePhone: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      workPhone: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      phone: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      phoneCountry: {
        value: "US",
        isValid: false,
        isDirty: false,
      },
      websiteURL: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      emergencyContactName: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      emergencyContactPhone: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      accountingEmail: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      schedulingEmail: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      overView: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      password: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      logo: {
        file: {
          previewBlob: null,
          uploadData: null,
        },
        error: null,
        isDirty: false,
      },
      profilePicture: {
        file: {
          previewBlob: null,
          uploadData: null,
        },
        error: null,
        isDirty: false,
      },
      favIcon: {
        file: {
          previewBlob: null,
          uploadData: null,
        },
        error: null,
        isDirty: false,
      },
      companySignature: {
        file: {
          previewBlob: null,
          uploadData: null,
        },
        error: null,
        isDirty: false,
      },
      signature: {
        value: "",
        isValid: false,
        isDirty: false,
      },
      isSchedulingEmailAvailable: {
        value: false,
        isValid: false,
        isDirty: false,
      },
    },
    showPassword: {
      password: false,
    },
    changePassword: false,
    isSearch: true,
    tooltipOpen1: false,
    tooltipOpen: false,
    activeTab: "1",
    show: null,
    searchResult: [],
  };
  _toggleTab = (tab = "1") => {
    const { activeTab } = this.state;
    if (activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  toggle1 = () => {
    this.setState({ tooltipOpen1: !this.state.tooltipOpen1 });
  };

  toggle = () => {
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  };
  componentDidMount = async () => {
    try {
      this.props.showLoader("Fetching Details...");
      await this._getSigningCompanyDetail();
      this.props.hideLoader();
    } catch (error) {
      errorHandler(error);
      this.props.hideLoader();
    }
  };

  _setFormData = (data) => {
    console.log("fetched", data.isSchedulingEmailAvailable);
    const { formFields } = deepClone(this.state);

    // if data value is present then set the value to formFields
    if (data && Object.keys(data).length) {
      formFields["firstname"].value = data.name.first;
      formFields["lastname"].value = data.name.last;

      formFields["companyName"].value = data.companyName
        ? data.companyName
        : "";
      formFields["email"].value = data.email ? data.email : "";
      formFields["accountingEmail"].value = data.accountingEmail
        ? data.accountingEmail
        : "";
      formFields["schedulingEmail"].value = data.schedulingEmail
        ? data.schedulingEmail
        : "";
      formFields["websiteURL"].value = data.websiteURL ? data.websiteURL : "";
      formFields["overView"].value = data.overView ? data.overView : "";

      formFields["phoneCountry"].value = data.phoneCountry
        ? data.phoneCountry
        : "US";
      formFields["homePhone"].value = data.homePhone
        ? getPhoneNumberFromBrackets(data.homePhone)
        : "";
      formFields["workPhone"].value = data.workPhone
        ? getPhoneNumberFromBrackets(data.workPhone)
        : "";
      formFields["phone"].value = data.phone
        ? getPhoneNumberFromBrackets(data.phone)
        : "";

      if (data.address) {
        formFields["line1"].value = data.address.line1
          ? data.address.line1
          : "";
        formFields["line2"].value = data.address.line2
          ? data.address.line2
          : "";
        formFields["city"].value = data.address.city ? data.address.city : "";
        formFields["state"].value = data.address.state
          ? data.address.state
          : "";
        formFields["zip"].value = data.address.zip ? data.address.zip : "";
        formFields["country"].value = data.address.country
          ? data.address.country
          : "";
        formFields["search"].value = Object.values({
          line1: data.address.line1,
          line2: data.address.line2,
          city: data.address.city,
          state: data.address.state,
          county: data.address.country,
          zip: data.address.zip,
        })
          .filter(Boolean)
          .join(",");
      }

      if (data.emergencyContact) {
        formFields["emergencyContactName"].value = data.emergencyContact.name
          ? data.emergencyContact.name
          : "";
        formFields["emergencyContactPhone"].value = data.emergencyContact.phone
          ? getPhoneNumberFromBrackets(data.emergencyContact.phone)
          : "";
      }

      formFields["logo"].file = {
        previewBlob: data.logo ? data.logo : null,
        uploadData: null,
      };
      formFields["profilePicture"].file = {
        previewBlob: data.profilePicture ? data.profilePicture : null,
        uploadData: null,
      };
      formFields["favIcon"].file = {
        previewBlob: data?.whiteLabelSetting?.favIcon
          ? data?.whiteLabelSetting?.favIcon
          : null,
        uploadData: null,
      };
      formFields["companySignature"].file = {
        previewBlob: data.companySignature ? data.companySignature : null,
        uploadData: null,
      };
      formFields["signature"].value = data.signature ? data.signature : "";
      formFields["isSchedulingEmailAvailable"].value =
        data.isSchedulingEmailAvailable;

      this.setState({
        formFields,
        loading: false,
      });
    } else {
      this.setState({
        loading: false,
      });
    }
  };

  _getSigningCompanyDetail = () => {
    const { userData } = REDUX_STORE.getState();

    return new Promise((resolve, reject) => {
      signingCompanyDetail()
        .then((res) => {
          this.setState({ companyProfileDetail: res?.signingCompany || {} });
          this._setFormData(res?.signingCompany || {});
          if (!userData?.user.isAssistant) {
            this.props.updateUserObj(res?.signingCompany);
          }
          if (res?.signingCompany?.whiteLabelSetting?.favIcon) {
            updateFavIcon(res?.signingCompany?.whiteLabelSetting);
          }
          this.props.hideLoader();
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  _validateForm = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;

      let isFormValid = true;

      Object.keys(formFields).forEach((key) => {
        if (formFields[key].isDirty) {
          switch (key) {
            // case "line1":
            // case "city":
            // case "state":
            // case "zip":
            // case "country":
            case "companyName":
            case "lastname":
            case "firstname": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "websiteURL": {
              if (
                formFields[key]?.value &&
                formFields[key]?.value?.trim()?.length
              ) {
                if (
                  RegexConfig.url.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid URL";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            case "email": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                if (
                  RegexConfig.email.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }

            case "accountingEmail":
            case "schedulingEmail": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                if (
                  RegexConfig.email.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Email";
                  isFormValid = false;
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            // phone number is not required - validation
            case "homePhone":
            case "workPhone":
            case "emergencyContactPhone": {
              if (formFields[key].value && formFields[key].value.length) {
                if (
                  RegexConfig.phone.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  isFormValid = false;
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Phone Number";
                }
              } else {
                formFields[key].isDirty = false;
                formFields[key].error = null;
              }
              break;
            }
            // phone number is required - validation
            case "phone": {
              if (formFields[key].value && formFields[key].value.length) {
                if (
                  RegexConfig.phone.test(
                    String(formFields[key].value).toLowerCase()
                  )
                ) {
                  formFields[key].isDirty = false;
                  formFields[key].error = null;
                } else {
                  isFormValid = false;
                  formFields[key].isDirty = true;
                  formFields[key].error = "*Invalid Phone Number";
                }
              } else {
                formFields[key].isDirty = true;
                formFields[key].error = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "password": {
              if (
                formFields[key].value &&
                formFields[key].value.trim().length
              ) {
                if (!formFields[key].value.trim().length) {
                  formFields[key].error = "Password is Required";
                } else if (
                  !new RegExp("^(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$").test(
                    formFields[key].value
                  )
                ) {
                  formFields[key].error =
                    "Min password length should be 8 , Req 1 digit , a special char";
                } else {
                  formFields[key].error = null;
                  formFields[key].isDirty = false;
                }
              } else {
                formFields[key].error = null;
                formFields[key].isDirty = false;
              }
              break;
            }
            default: {
            }
          }
        }
      });

      this.setState({ formFields }, () => {
        resolve(isFormValid);
      });
    });
  };

  _updateFileImage = (fieldName, event) => {
    const { formFields } = deepClone(this.state);

    if (
      event &&
      event.target &&
      event.target.files &&
      event.target.files.length
    ) {
      let objFile = event.target.files[0];
      let objFileType = objFile.type.split("/")[0];
      if (objFileType === "image") {
        formFields[fieldName].file = {
          previewBlob: URL.createObjectURL(objFile),
          uploadData: objFile,
        };
        // formFields["signature"].value = "";
        this.setState({ formFields });
      } else {
        showToast("Only Image file is allowed", "error");
      }
    }
    this.setState({ formFields });
  };

  _updateFieldValue = (fieldName, value) => {
    const { formFields } = this.state;
    if (
      fieldName === "homePhone" ||
      fieldName === "phone" ||
      fieldName === "workPhone" ||
      fieldName === "emergencyContactPhone"
    ) {
      formFields[fieldName].value = value.replace(/\D/g, "");
    } else {
      formFields[fieldName].value = value;
    }
    formFields[fieldName].isDirty = true;
    this.setState({ formFields }, () => {
      // Validate
      this._validateForm();
    });
  };

  _markAllFieldDirty = () => {
    return new Promise((resolve, reject) => {
      const { formFields } = this.state;
      Object.keys(formFields).forEach((e) => {
        formFields[e].isDirty = true;
      });
      this.setState({ formFields }, () => resolve(true));
    });
  };

  _updateSigningCompanyProfile = (id, data) => {
    const { formFields } = this.state;
    updateSigningCompanyProfile(id, data)
      .then(async (res) => {
        await this._getSigningCompanyDetail();
        showToast("Company Profile Updated", "success");
        this.props.hideLoader();
        formFields["password"].value = "";
        this.setState({
          formFields,
          showPassword: {
            password: false,
          },
          changePassword: false,
        });
      })
      .catch((error) => {
        errorHandler(error);
        this.props.hideLoader();
      });
  };

  _onSaveProfileData = async () => {
    await this._markAllFieldDirty();

    const isFormValid = await this._validateForm();

    if (!isFormValid) {
      showToast("Please Fill The Required Fields", "error");
    }

    if (isFormValid) {
      this.props.showLoader("Update Company Profile...");

      const { formFields, companyProfileDetail } = this.state;

      const payload = {
        companyName: formFields.companyName.value.trim(),
        websiteURL: formFields.websiteURL.value.trim(),
        email: formFields.email.value.trim(),
        name: {
          first: formFields.firstname.value.trim(),
          last: formFields.lastname.value.trim(),
        },
        address: {
          line1: formFields.line1.value.trim(),
          line2: formFields?.line2?.value?.trim() || "",
          city: formFields.city.value.trim(),
          state: formFields.state.value.trim(),
          zip: formFields.zip.value.trim(),
          country: formFields.country.value.trim(),
        },
        accountingEmail: formFields.accountingEmail.value?.trim(),
        schedulingEmail: formFields.schedulingEmail.value?.trim(),
        // phoneCountry is static - set to -> 'US'
        phoneCountry: formFields.phoneCountry.value,
        password: formFields.password?.value?.trim().length
          ? formFields.password.value
          : undefined,
        companySignature: formFields.companySignature?.value?.trim().length
          ? formFields.companySignature.value
          : "",
        signature: formFields.signature?.value?.trim().length
          ? formFields.signature.value
          : undefined,
        isSchedulingEmailAvailable:
          formFields.schedulingEmail.value?.trim().length > 0
            ? formFields.isSchedulingEmailAvailable.value
            : false,

        // isSignatureImage: formFields.signature?.value?.trim().length
        //   ? formFields.companySignature.file?.previewBlob!==null ? true : false
        //   : true,
      };

      // if (formFields.line2.value) {
      //   payload.address["line2"] = formFields.line2.value.trim();
      // }
      if (formFields.phone.value) {
        payload["phone"] = `(+1)${formFields.phone.value}`;
      }
      if (formFields.homePhone.value) {
        payload["homePhone"] = `(+1)${formFields.homePhone.value}`;
      }
      if (formFields.workPhone.value) {
        payload["workPhone"] = `(+1)${formFields.workPhone.value}`;
      }
      if (
        formFields.emergencyContactName.value ||
        formFields.emergencyContactPhone.value
      ) {
        payload["emergencyContact"] = {
          name: formFields.emergencyContactName.value
            ? formFields.emergencyContactName.value.trim()
            : "",
          phone: formFields.emergencyContactPhone.value
            ? `(+1)${formFields.emergencyContactPhone.value}`
            : "",
        };
      }
      if (formFields.overView.value) {
        payload["overView"] = formFields.overView.value.trim();
      }

      const uploadImages = [];
      if (formFields.logo.file && formFields.logo.file.uploadData) {
        uploadImages.push({
          for: "logo",
          ...formFields.logo.file,
        });
      }
      if (
        formFields.profilePicture.file &&
        formFields.profilePicture.file.uploadData
      ) {
        uploadImages.push({
          for: "profilePicture",
          ...formFields.profilePicture.file,
        });
      }

      if (formFields?.favIcon?.file && formFields?.favIcon?.file?.uploadData) {
        uploadImages.push({
          for: "favIcon",
          isWhiteLabelSetting: true,
          ...formFields.favIcon.file,
        });
      }

      if (
        formFields.companySignature.file &&
        formFields.companySignature.file.uploadData
      ) {
        uploadImages.push({
          for: "companySignature",
          ...formFields.companySignature.file,
        });
      }

      if (
        !(
          formFields.line1.value.trim().length &&
          formFields.city.value.trim().length &&
          formFields.state.value.trim().length &&
          formFields.zip.value.trim().length &&
          formFields.country.value.trim().length
        )
      ) {
        delete payload.address;
      }

      if (uploadImages && uploadImages.length) {
        let postID = PostManager.addMediaFilesCount(uploadImages.length);

        PostManager.onAllMediaFilesUploadCompleted(postID, async (id) => {
          if (id.postID === postID) {
            console.log("payload :>> ", payload);
            await sleepTime(500);
            this._updateSigningCompanyProfile(
              companyProfileDetail._id,
              payload
            );
            // this.props.hideLoader();
            PostManager.deletePostID(postID);
          } else {
            return;
          }
        });

        uploadImages.forEach((uploadImage) => {
          // for logo images
          let mediaData = {
            file: uploadImage.uploadData,
            blobObject: uploadImage.previewBlob,
          };
          const uploadId = UploadQueueManager.addMediaToQueue(
            mediaData,
            "image"
          );

          // Listen for upload complete
          UploadQueueManager.onUploadComplete(
            uploadId,
            async (mediaResponse) => {
              PostManager.onSingleMediaFileUploadCompleted(postID);
              console.log(
                "mediaResponse",
                mediaResponse,
                mediaResponse.fileUrl
              );
              // Upload complete
              // Get content id from backend
              if (uploadImage?.isWhiteLabelSetting) {
                payload["whiteLabelSetting"] = {};

                payload.whiteLabelSetting[uploadImage?.for] =
                  mediaResponse.fileUrl;
              } else {
                payload[uploadImage?.for] = mediaResponse.fileUrl;
              }
            }
          );
        });
      } else {
        this._updateSigningCompanyProfile(companyProfileDetail._id, payload);
      }
    }
  };

  _onDeleteSignatureImage = () => {
    const { formFields } = this.state;

    formFields["companySignature"].file = {
      previewBlob: null,
      uploadData: null,
    };
    this.setState({ formFields });
    // this.setState({ ...formFields,
    //   companySignature: {
    //     file: {
    //       previewBlob: null,
    //       uploadData: null,
    //     },
    //     error: null,
    //     isDirty: false,
    //   },
    // });
  };
  _onChangeFormField = (fieldName, value) => {
    const { formFields } = deepClone(this.state);

    console.log(fieldName, value);
    formFields[fieldName].value = value;

    // if (fieldName === "closingSearch" || fieldName === "borrowerSearch") {
    googlePlaceSearch(value)
      .then((res) => {
        // console.log(res);
        this.setState({ searchResult: res, show: fieldName });
      })
      .catch((err) => {
        // console.log(err);
        showToast("Errors");
      });
    // }
    this.setState({ formFields });
  };
  getPlaceDetail = async (pid, type) => {
    const { formFields } = deepClone(this.state);
    let detail = await googlePlaceDetails(pid.place_id);
    console.log(detail);

    if (detail) {
      if (detail.postal) {
        formFields["line1"].value = detail.address;
        formFields["line2"].value = "";
        formFields["city"].value = detail.city;
        let state = usaStates.filter((state) => state.name === detail.state)[0]
          ?.abbreviation;
        formFields["state"].value = state;
        formFields["zip"].value = detail.postal;
        formFields["country"].value = detail.country;
        formFields["search"].value =
          detail.address +
          "," +
          detail.city +
          "," +
          detail.postal +
          "," +
          detail.country;
        formFields["search"].placeId = pid.place_id;
        formFields["search"].isDirty = true;
      } else {
        showToast("Invalid Address", "error");
      }

      this.setState({ formFields, show: true }, () => {
        this._validateForm();
      });
    }
  };

  _toggleTab = async (tab) => {
    const { loading, activeTab } = deepClone(this.state);
    if (!loading && tab !== activeTab) {
      try {
        this.setState({ loading: true, activeTab: tab });
        // await this._getAgentProfileDetail();
        this.setState({ loading: false });
      } catch (error) {
        errorHandler(error);
        this.setState({ loading: false, activeTab: "" });
      }
    }
  };
  render() {
    const {
      activeTab,
      companyProfileDetail,
      formFields,
      showPassword,
      isSearch,
    } = this.state;

    // const { userData } = REDUX_STORE.getState();
    console.log("oiu", this.state);

    return (
      <>
        <Card>
          <Nav tabs className="greyBg">
            <NavItem>
              <NavLink
                className={activeTab === "1" ? "active" : ""}
                onClick={() => {
                  this._toggleTab("1");
                }}
              >
                Address Info
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                className={activeTab === "2" ? "active" : ""}
                onClick={() => {
                  this._toggleTab("2");
                }}
              >
                Contact Info
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "3" ? "active" : ""}
                onClick={() => {
                  this._toggleTab("3");
                }}
              >
                Personal Info
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={activeTab === "4" ? "active" : ""}
                onClick={() => {
                  this._toggleTab("4");
                }}
              >
                Referral Link
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <Row>
                <Col md={12} className="text-center">
                  {/* you have to add class according to there length  the class rectangle and square*/}
                  <Label
                    className={`profileCompanyLogo my-5 ${
                      this.state.imageLogoClass || "rectangle"
                    }`}
                  >
                    <Input
                      type="file"
                      style={{ display: "none" }}
                      accept="image/x-png,image/gif,image/jpeg"
                      value=""
                      onChange={(event) => this._updateFileImage("logo", event)}
                    />
                    <img
                      ref={(ref) => (this.ImageLogoRef = ref)}
                      src={
                        formFields.logo.file && formFields.logo.file.previewBlob
                          ? formFields.logo.file.previewBlob
                          : require("../../../assets/img/company-logo.png")
                              .default
                      }
                      alt="logo"
                      onLoad={() =>
                        this.setState({
                          imageLogoClass: getImageDimensionClass(
                            this.ImageLogoRef
                          ),
                        })
                      }
                    />
                    <i className="fa fa-pencil"></i>
                  </Label>

                  {/* <Label className="uploadProfile" style={{ margin: "0" }}>
                    <Input
                      type="file"
                      style={{ display: "none" }}
                      accept="image/x-png,image/gif,image/jpeg"
                      value=""
                      onChange={(event) => this._updateFileImage("logo", event)}
                    />
                    <img
                      src={
                        formFields.logo.file && formFields.logo.file.previewBlob
                          ? formFields.logo.file.previewBlob
                          : require("../../../assets/img/company-logo.png")
                              .default
                      }
                      alt="logo"
                    />
                    <i className="fa fa-pencil"></i>
                  </Label> */}
                </Col>

                <Col xl={6}>
                  <FormGroup className="floatingLabel">
                    <Input
                      type="text"
                      name="companyName"
                      placeholder=" "
                      value={formFields.companyName.value}
                      onChange={(e) =>
                        this._updateFieldValue("companyName", e.target.value)
                      }
                    />
                    <Label>Company Name</Label>
                    {formFields.companyName.error && (
                      <div className="validation-error">
                        {formFields.companyName.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col xl={6}>
                  <FormGroup className="floatingLabel">
                    <div className="custom-select-wrapper">
                      <Input
                        type="select"
                        name="invoicesbilled"
                        placeholder=" "
                        // value={formFields.companyName.value}
                        // onChange={(e) =>
                        //   this._updateFieldValue("companyName", e.target.value)
                        // }
                      >
                        <option>Company Name</option>
                        <option>My Personal name </option>
                      </Input>
                      <Label>Invoices are Billed From</Label>
                    </div>
                    {formFields.companyName.error && (
                      <div className="validation-error">
                        {formFields.companyName.error}
                      </div>
                    )}
                  </FormGroup>

                  {/* <CustomInput
                    defaultChecked
                    defaultValue="option1"
                    id="exampleRadios11"
                    name="exampleRadioz"
                    type="radio"
                    label="My Company's Name"
                    inline
                  />
                  <CustomInput
                    defaultValue="option2"
                    id="exampleRadios12"
                    name="exampleRadioz"
                    type="radio"
                    label=" My Personal Name"
                    inline
                  /> */}

                  {/* <div className="d-flex mt-1">
                    <div className="form-check-radio mb-0">
                      <Label check className="mb-0">
                        <Input
                          defaultChecked
                          defaultValue="option1"
                          id="exampleRadios11"
                          name="exampleRadioz"
                          type="radio"
                        />
                        My Company's Name <span className="form-check-sign" />
                      </Label>
                    </div>
                    <div className="form-check-radio ml-2 mb-0">
                      <Label check className="mb-0">
                        <Input
                          defaultValue="option2"
                          id="exampleRadios12"
                          name="exampleRadioz"
                          type="radio"
                        />
                        My Personal Name <span className="form-check-sign" />
                      </Label>
                    </div>
                  </div> */}
                </Col>

                <Col md={12}>
                  <FormGroup className="floatingLabel withInputGroup">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <img
                            src={
                              require("../../../assets/img/globeIcon.svg")
                                .default
                            }
                            alt="website"
                          />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        name="websiteURL"
                        placeholder=" "
                        value={formFields?.websiteURL?.value}
                        onChange={(e) =>
                          this._updateFieldValue("websiteURL", e.target.value)
                        }
                      />
                      <Label>Website</Label>
                    </InputGroup>

                    {formFields?.websiteURL?.error && (
                      <div className="validation-error">
                        {formFields?.websiteURL?.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col md={12}>
                  <CustomInput
                    type="switch"
                    className="mb-3 mt-2"
                    checked={!isSearch}
                    onChange={(e) =>
                      this.setState({
                        isSearch: !e.target.checked,
                      })
                    }
                    id="closingAddress_Custom_Switch"
                    name="closingAddress"
                    label="Enter Manually"
                  />
                </Col>

                {isSearch ? (
                  <Col xl={6}>
                    <FormGroup className="floatingLabel withInputGroup">
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <img
                              src={
                                require("../../../assets/img/mapMarker_blue.svg")
                                  .default
                              }
                              alt="map marker"
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          type="text"
                          placeholder=" "
                          // value={
                          //   isBorrowerAddressSame
                          //     ? formFields.borrowerSearch.value
                          //     : formFields.closingSearch.value
                          // }
                          // disabled={isBorrowerAddressSame}
                          value={formFields.search.value}
                          name="search"
                          onChange={(event) =>
                            this._onChangeFormField(
                              "search",
                              event.target.value
                            )
                          }
                        />
                        <Label>Search Address</Label>
                      </InputGroup>
                      {/* {formFields.closingSearch.error && (
                              <div className="validation-error">
                                {formFields.closingSearch.error}
                              </div>
                            )} */}
                      {this.state.show === "search" ? (
                        <ListGroup flush className="customSearchOptions">
                          {" "}
                          {this.state.searchResult &&
                            this.state.searchResult.map((i) => {
                              return (
                                <ListGroupItem
                                  style={{ cursor: "pointer" }}
                                  onClick={() =>
                                    this.getPlaceDetail(i, "closingAddress")
                                  }
                                >
                                  {i.description}
                                </ListGroupItem>
                              );
                            })}
                        </ListGroup>
                      ) : null}
                    </FormGroup>
                  </Col>
                ) : (
                  <>
                    <Col xl={6}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          placeholder=" "
                          value={formFields.line1.value}
                          onChange={(e) =>
                            this._updateFieldValue("line1", e.target.value)
                          }
                        />
                        <Label>Address Line 1</Label>
                        {formFields.line1.error && (
                          <div className="validation-error">
                            {formFields.line1.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col xl={6}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          placeholder=" "
                          value={formFields.line2.value}
                          onChange={(e) =>
                            this._updateFieldValue("line2", e.target.value)
                          }
                        />
                        <Label>Address Line 2</Label>
                      </FormGroup>
                    </Col>

                    <Col sm={6} xl={4}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          name="city"
                          placeholder=" "
                          value={formFields.city.value}
                          onChange={(e) =>
                            this._updateFieldValue("city", e.target.value)
                          }
                        />
                        <Label>City</Label>
                        {formFields.city.error && (
                          <div className="validation-error">
                            {formFields.city.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm={6} xl={4}>
                      <FormGroup className="floatingLabel custom-select-wrapper">
                        <Input
                          type="select"
                          name="state"
                          placeholder=" "
                          value={formFields.state.value}
                          onChange={(e) =>
                            this._updateFieldValue("state", e.target.value)
                          }
                        >
                          <option value="">Select</option>
                          {usaStates.map((state) => (
                            <option
                              key={state.abbreviation}
                              value={state.abbreviation}
                            >
                              {state.name}
                            </option>
                          ))}
                        </Input>
                        <Label>State</Label>
                        {formFields.state.error && (
                          <div className="validation-error">
                            {formFields.state.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm={6} xl={4}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          name="zip"
                          placeholder=" "
                          value={formFields.zip.value}
                          onChange={(e) =>
                            this._updateFieldValue("zip", e.target.value)
                          }
                        />
                        <Label>Zip</Label>
                        {formFields.zip.error && (
                          <div className="validation-error">
                            {formFields.zip.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col sm={6} xl={12}>
                      <FormGroup className="floatingLabel">
                        <Input
                          type="text"
                          name="country"
                          placeholder=" "
                          value={formFields.country.value}
                          onChange={(e) =>
                            this._updateFieldValue("country", e.target.value)
                          }
                        />
                        <Label>Country</Label>
                        {formFields.country.error && (
                          <div className="validation-error">
                            {formFields.country.error}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </>
                )}
                <Col md={12} className="text-center my-3">
                  <Button
                    color="primary"
                    style={{ width: 200 }}
                    onClick={this._onSaveProfileData}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2" className="p-3">
              <Row>
                <Col xl={6}>
                  <FormGroup className="floatingLabel withInputGroup">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>+1</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder=" "
                        value={formFields.homePhone.value}
                        onChange={(e) =>
                          this._updateFieldValue("homePhone", e.target.value)
                        }
                      />
                      <Label>Scheduling Phone</Label>
                    </InputGroup>
                    {formFields.homePhone.error && (
                      <div className="validation-error">
                        {formFields.homePhone.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col xl={6}>
                  <FormGroup className="floatingLabel withInputGroup">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>+1</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder=" "
                        value={formFields.phone.value}
                        onChange={(e) =>
                          this._updateFieldValue("phone", e.target.value)
                        }
                      />
                      <Label>General Phone </Label>
                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          {companyProfileDetail &&
                          companyProfileDetail.isPhoneVerified ? (
                            <span className="text-success fs-12">Verified</span>
                          ) : /* <span className="text-danger fs-12">
                              Unverified
                            </span> */
                          null}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    {formFields.phone.error && (
                      <div className="validation-error">
                        {formFields.phone.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col xl={6}>
                  <FormGroup className="floatingLabel withInputGroup">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>+1</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder=" "
                        value={formFields.workPhone.value}
                        onChange={(e) =>
                          this._updateFieldValue("workPhone", e.target.value)
                        }
                      />
                      <Label>Accounting Phone</Label>
                    </InputGroup>
                    {formFields.workPhone.error && (
                      <div className="validation-error">
                        {formFields.workPhone.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col xl={6}>
                  <FormGroup className="floatingLabel">
                    <InputGroup>
                      <Input
                        type="text"
                        name="email"
                        placeholder=" "
                        value={formFields.schedulingEmail.value}
                        onChange={(e) =>
                          this._updateFieldValue(
                            "schedulingEmail",
                            e.target.value
                          )
                        }
                      />
                      <Label>Scheduling Email</Label>
                      <InputGroupAddon addonType="append">
                        <InputGroupText id="TooltipExample">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    <Tooltip
                      placement="top"
                      isOpen={this.state.tooltipOpen}
                      target="TooltipExample"
                      toggle={this.toggle}
                    >
                      If left blank this email defaults to general email
                    </Tooltip>
                    {formFields?.schedulingEmail?.error && (
                      <div className="validation-error">
                        {formFields?.schedulingEmail?.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col xl={6}>
                  <FormGroup className="floatingLabel">
                    <InputGroup>
                      <Input
                        type="text"
                        name="email"
                        placeholder=" "
                        value={formFields.accountingEmail.value}
                        onChange={(e) =>
                          this._updateFieldValue(
                            "accountingEmail",
                            e.target.value
                          )
                        }
                      />
                      <Label>Accounting Email</Label>
                      <InputGroupAddon addonType="append">
                        <InputGroupText id="TooltipExample1">
                          <i class="fa fa-info-circle" aria-hidden="true"></i>
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                    <Tooltip
                      placement="right"
                      isOpen={this.state.tooltipOpen1}
                      target="TooltipExample1"
                      toggle={this.toggle1}
                    >
                      If left blank this email defaults to general email
                    </Tooltip>
                    {formFields?.accountingEmail?.error && (
                      <div className="validation-error">
                        {formFields?.accountingEmail?.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col xl={6}>
                  <FormGroup className="floatingLabel">
                    <InputGroup>
                      <Input
                        type="text"
                        name="email"
                        placeholder=" "
                        value={formFields.email.value}
                        onChange={(e) =>
                          this._updateFieldValue("email", e.target.value)
                        }
                        disabled={true}
                      />
                      <Label>General Email </Label>

                      <InputGroupAddon addonType="append">
                        <InputGroupText>
                          {companyProfileDetail &&
                          companyProfileDetail.isEmailVerified ? (
                            <span className="text-success fs-12">Verified</span>
                          ) : (
                            <span className="text-danger fs-12">
                              Unverified
                            </span>
                          )}
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>

                    {formFields.email.error && (
                      <div className="validation-error">
                        {formFields.email.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={12}>
                  <div className="CardTitle mt-2">
                    <h2 class="sectionTtile"> After Hours Contact </h2>
                  </div>
                </Col>
                <Col xl={6}>
                  <FormGroup className="floatingLabel">
                    <Input
                      type="text"
                      placeholder=" "
                      value={formFields.emergencyContactName.value}
                      onChange={(e) =>
                        this._updateFieldValue(
                          "emergencyContactName",
                          e.target.value
                        )
                      }
                    />
                    <Label>Name</Label>
                  </FormGroup>
                </Col>

                <Col xl={6}>
                  <FormGroup className="floatingLabel withInputGroup">
                    <InputGroup>
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>+1</InputGroupText>
                      </InputGroupAddon>
                      <Input
                        type="text"
                        placeholder=" "
                        value={formFields.emergencyContactPhone.value}
                        onChange={(e) =>
                          this._updateFieldValue(
                            "emergencyContactPhone",
                            e.target.value
                          )
                        }
                      />
                      <Label>Phone</Label>
                    </InputGroup>
                    {formFields.emergencyContactPhone.error && (
                      <div className="validation-error">
                        {formFields.emergencyContactPhone.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>

                <Col>
                  <CustomInput
                    id="isSchedulingEmail"
                    type="checkbox"
                    name="scheduling Email"
                    placeholder="Send all my chat emails to scheduling email id"
                    checked={
                      formFields?.schedulingEmail?.value?.trim().length
                        ? formFields.isSchedulingEmailAvailable.value
                        : false
                    }
                    onChange={(e) =>
                      this._updateFieldValue(
                        "isSchedulingEmailAvailable",
                        e.target.checked
                      )
                    }
                    disabled={
                      !formFields?.schedulingEmail?.value?.trim().length
                    }
                    label="Send all my chat emails to scheduling Email ID"
                  />
                  {/* <Input
                    type="checkbox"
                    placeholder="Send all my chat emails to scheduling email id"
                    value={formFields.isSchedulingEmailAvailable.value}
                    onChange={(e) =>
                      this._updateFieldValue(
                        "isSchedulingEmailAvailable",
                        e.target.value
                      )
                    }
                  /> */}
                  {/* <Label>Send all my chat emails to scheduling email id</Label> */}
                </Col>

                <Col md={12} className="text-center my-3">
                  <Button
                    color="primary"
                    style={{ width: 200 }}
                    onClick={this._onSaveProfileData}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="3">
              <Row className="align-items-start">
                <Col md={8} className="text-center mt-3 mb-4">
                  {/* you have to add class according to there length  the class is rectangle and square*/}
                  <Label className="d-block mb-2">Upload Profile Pic</Label>
                  <Label
                    className={`profileCompanyLogo rectangle`}
                    // className={`profileCompanyLogo ${
                    //   imageLogoClass || "rectangle"
                    // }`}
                  >
                    <Input
                      type="file"
                      style={{ display: "none" }}
                      accept="image/x-png,image/gif,image/jpeg"
                      value=""
                      onChange={(event) =>
                        this._updateFileImage("profilePicture", event)
                      }
                    />
                    <img
                      src={
                        formFields.profilePicture.file &&
                        formFields.profilePicture.file.previewBlob
                          ? formFields.profilePicture.file.previewBlob
                          : DEFAULT_PROFILE_PICTURE
                      }
                      alt="Profile Img"
                    />
                    <i className="fa fa-pencil"></i>
                  </Label>

                  <small className="text-center mt-3 d-block mx-auto themeColor">
                    Your photo will appear on your CloseWise profile and be
                    visible to consumers and companies.
                  </small>
                </Col>
                <Col md={4} className="text-center mt-3 mb-4">
                  {/* you have to add class according to there length  the class is rectangle and square*/}
                  <Label className="d-block mb-2">
                    Fav icon
                    <img
                      src={require("../../../assets/img/info.svg").default}
                      id="preferredInfo"
                      className="circleInfo ml-2"
                      alt="info"
                    />
                    <UncontrolledTooltip placement="top" target="preferredInfo">
                      The favicon feature is exclusive to white-label users, and
                      it will be visible when accessing a custom URL
                    </UncontrolledTooltip>
                  </Label>
                  <Label
                    className={`profileCompanyLogo rectangle`}
                    // className={`profileCompanyLogo ${
                    //   imageLogoClass || "rectangle"
                    // }`}
                  >
                    <Input
                      type="file"
                      style={{ display: "none" }}
                      accept="image/x-png,image/gif,image/jpeg"
                      value=""
                      onChange={(event) =>
                        this._updateFileImage("favIcon", event)
                      }
                      placeholder=" "
                    />
                    <img
                      src={
                        formFields?.favIcon?.file &&
                        formFields?.favIcon?.file?.previewBlob
                          ? formFields?.favIcon?.file?.previewBlob
                          : require("../../../assets/img/placeholder-img.png")
                              .default
                      }
                      alt="Fav Icon"
                    />
                    <i className="fa fa-pencil" />
                  </Label>
                </Col>
              </Row>

              <Row className="mt-4">
                <Col xl={6}>
                  <FormGroup className="floatingLabel">
                    <Input
                      type="text"
                      placeholder=" "
                      name="firstname"
                      value={formFields.firstname.value}
                      onChange={(e) =>
                        this._updateFieldValue("firstname", e.target.value)
                      }
                    />
                    <Label>First Name</Label>
                    {formFields.firstname.error && (
                      <div className="validation-error">
                        {formFields.firstname.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col xl={6}>
                  <FormGroup className="floatingLabel">
                    <Input
                      type="text"
                      placeholder=" "
                      name="lastname"
                      //   value={signingCompany.lastname}
                      value={formFields.lastname.value}
                      onChange={(e) =>
                        this._updateFieldValue("lastname", e.target.value)
                      }
                    />
                    <Label>Last Name</Label>
                    {formFields.lastname.error && (
                      <div className="validation-error">
                        {formFields.lastname.error}
                      </div>
                    )}
                  </FormGroup>
                </Col>
                <Col md={12} className="mb-4 mt-3">
                  {formFields.companySignature?.file?.previewBlob ? (
                    <>
                      <div className="signaturePreview">
                        <img
                          src={
                            formFields.companySignature.file &&
                            formFields.companySignature.file.previewBlob
                              ? formFields.companySignature.file.previewBlob
                              : require("../../../assets/img/company-logo.png")
                                  .default
                          }
                          alt="Signature"
                          className="signatureImg"
                        />
                        {formFields.companySignature.file &&
                        formFields.companySignature.file.previewBlob ? (
                          <Button
                            onClick={() => this._onDeleteSignatureImage()}
                            color="danger"
                            className="deletePreview"
                          >
                            <img
                              src={
                                require("../../../assets/img/close.png").default
                              }
                              alt="deletePreview"
                            />
                          </Button>
                        ) : null}
                      </div>
                    </>
                  ) : (
                    <>
                      <Label className="uploadLink text-primary">
                        <Input
                          type="file"
                          style={{ display: "none" }}
                          accept="image/x-png,image/gif,image/jpeg"
                          value=""
                          onChange={(event) =>
                            this._updateFileImage("companySignature", event)
                          }
                        />
                        Add company signature to automated emails.
                      </Label>
                    </>
                  )}
                </Col>
                <Col md={12}>
                  <TextEditor
                    content={formFields.signature.value}
                    onChange={(event) =>
                      this._updateFieldValue("signature", event)
                    }
                  ></TextEditor>
                  <div className="my-4">
                    <CustomInput
                      type="checkbox"
                      name="unassigned"
                      checked={this.state.changePassword}
                      onChange={(e) =>
                        this.setState({
                          changePassword: !this.state.changePassword,
                        })
                      }
                      id="passwordChange"
                      label=" Change Password"
                    />
                  </div>
                </Col>
                {this.state.changePassword ? (
                  <Col xl={6} lg={8} sm={8}>
                    <FormGroup className="floatingLabel">
                      <InputGroup>
                        <Input
                          type={showPassword.password ? "text" : "password"}
                          value={formFields.password.value}
                          name="password"
                          onChange={(event) =>
                            this._updateFieldValue(
                              "password",
                              event.target.value
                            )
                          }
                          placeholder=" "
                        />
                        <Label>Enter New Password</Label>
                        <InputGroupAddon addonType="append">
                          <InputGroupText>
                            <img
                              src={
                                require(`../../../assets/img/${
                                  showPassword.password
                                    ? "eyeIcon.svg"
                                    : "eyeIconSlash.svg"
                                }`).default
                              }
                              onClick={() =>
                                this.setState({
                                  showPassword: {
                                    ...this.state.showPassword,
                                    password: !this.state.showPassword.password,
                                  },
                                })
                              }
                              alt="eye icon"
                            />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>

                      {formFields.password.error && (
                        <div className="validation-error">
                          {formFields.password.error}
                        </div>
                      )}
                    </FormGroup>
                  </Col>
                ) : null}
                <Col md={12} className="text-center my-3">
                  <Button
                    color="primary"
                    style={{ width: 200 }}
                    onClick={this._onSaveProfileData}
                  >
                    Save
                  </Button>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="4" className="p-3">
              {companyProfileDetail?.uniqueReferralCode ? (
                <>
                  <div className="referralLink">
                    <a
                      href={`${APP_URL}/signup?code=${companyProfileDetail?.uniqueReferralCode}`}
                    >
                      {`${APP_URL}/signup?code=${companyProfileDetail?.uniqueReferralCode}`}
                    </a>
                    {companyProfileDetail?.uniqueReferralCode && (
                      <CopyToClipboard
                        text={`${APP_URL}/signup?code=${companyProfileDetail?.uniqueReferralCode}`}
                        onCopy={() => showToast("Copied", "success")}
                      >
                        <Button color="primary">COPY LINK</Button>
                      </CopyToClipboard>
                    )}
                  </div>
                </>
              ) : (
                "No Referral Code"
              )}
            </TabPane>
          </TabContent>
        </Card>

        {/* {userData?.user.isAssistant ? (
            <UserProfile />
          ) : ( */}
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateUserObj: (user) => dispatch(updateUserObj(user)),
    showLoader: (text) => dispatch(showLoader(text)),
    hideLoader: () => dispatch(hideLoader()),
  };
};

export default connect(null, mapDispatchToProps)(CompanyProfileDetail);
